import { Banner, Flex, Icon, Paragraph, styled } from '@kandji-inc/nectar-ui';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetOktaDeviceTrustDetails } from 'src/features/integrations/components-for-specific-types/okta/hooks/use-get-okta-device-trust';
import { LibraryItemContext } from 'src/features/library-items/common/library-item-page/library-item-page';
import OktaNavigateAwayModal from '../okta-navigate-away-modal';

const StyledLink = styled('a', {
  fontFamily: '$primary',
  color: 'LinkText',
  '&:hover': {
    color: '$blue60',
    cursor: 'pointer',
  },
});

const StyledCloseButton = styled('div', {
  marginLeft: 'auto',
  cursor: 'pointer',
});

const StyledBanner = styled(Banner, {
  '& span': {
    width: '100%',
  },
});

type Props = {
  isEditing: boolean;
};

const OktaDeviceTrustBanner = (props: Props) => {
  const BANNER_LOCAL_STORAGE_KEY = 'odt-lit-banner';
  const { isEditing } = props;

  const { data } = useGetOktaDeviceTrustDetails({ disableAutoFetch: false });

  const hasODTIntegrationConfigured = data?.results.length;

  const [isOktaNavigateAwayModalOpen, setIsOktaNavigateAwayModalOpen] =
    useState(false);

  const [isBannerClosed, setIsBannerClosed] = useState(() => {
    const savedBannerStatus = localStorage.getItem(BANNER_LOCAL_STORAGE_KEY);
    const initialValue = JSON.parse(savedBannerStatus);

    return initialValue || false;
  });

  const history = useHistory();

  const { onSave, onConfirmNavModalLeave } = useContext(LibraryItemContext);

  const handleNavigation = () => {
    onConfirmNavModalLeave(); // this disables the default navigate away modal in favor of ODT navigate away modal
    history.push('/integrations/marketplace');
  };

  const handleToggleModal = () => {
    setIsOktaNavigateAwayModalOpen((prevState) => !prevState);
  };

  const handleClose = () => {
    const isClosed = true;
    setIsBannerClosed(isClosed);
    localStorage.setItem(BANNER_LOCAL_STORAGE_KEY, JSON.stringify(isClosed));
  };

  const text =
    'This Library item can be configured with Okta Device Trust by setting up the integration in the marketplace.';

  const linkText = 'Go to Integration marketplace';

  if (!isEditing || isBannerClosed || hasODTIntegrationConfigured) {
    return null;
  }
  return (
    <>
      <StyledBanner
        css={{ marginBottom: '$6' }}
        theme="default"
        text={
          <Flex flow="column" gap="xs">
            <Flex flow="row">
              <Paragraph>{text}</Paragraph>
              <StyledCloseButton
                onClick={handleClose}
                data-testid="odt-banner:close-btn"
              >
                <Icon name="x" size="sm" />
              </StyledCloseButton>
            </Flex>

            <div>
              <StyledLink onClick={handleToggleModal}>{linkText}</StyledLink>
            </div>
          </Flex>
        }
      />
      <OktaNavigateAwayModal
        isModalOpen={isOktaNavigateAwayModalOpen}
        onClose={handleToggleModal}
        handleNext={handleNavigation}
        onSave={onSave}
        isModalForBannerNavigation
      />
    </>
  );
};

export default OktaDeviceTrustBanner;
