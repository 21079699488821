import { Button, Icon, kmodal as Kmodal, Radio } from '@kandji-inc/bumblebee';
import { func } from 'prop-types';
import React, { useState } from 'react';
import { i18n } from 'src/i18n';

const FlushModal = (props) => {
  const { onClose, onFlush } = props;
  const [isFlushAll, setIsFlushAll] = useState(false);

  return (
    <Kmodal>
      <a
        href=""
        className="decorate-off kb-modal-close"
        onClick={(e) => {
          e.preventDefault();
          onClose();
        }}
      >
        <Icon name="xmark" />
      </a>
      <div className="kb-modal-top">
        <div className="kb-modal-header">
          <h2 className="b-h2 b-mb">Flush all devices?</h2>
        </div>
        <div className="kb-modal-body">
          <div className="b-txt  b-mb2">
            {i18n.t(
              'This Library Item has been modified. Would you like to flush all devices and re-install the item upon next check-in?',
            )}
          </div>
          <div className="b-mb3">
            <div className="b-mb-tiny">
              <Radio
                label={i18n.t("Don't flush all devices")}
                checked={!isFlushAll}
                onChange={() => setIsFlushAll(false)}
              />
            </div>
            <div>
              <Radio
                label={i18n.t('Flush all devices')}
                checked={isFlushAll}
                onChange={() => setIsFlushAll(true)}
                id="flush-all"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="kb-modal-footer b-flex-g">
        <Button kind="outline" onClick={onClose}>
          {i18n.t('Cancel')}
        </Button>
        <Button onClick={isFlushAll ? onFlush : onClose}>
          {i18n.t('Continue')}
        </Button>
      </div>
    </Kmodal>
  );
};

FlushModal.propTypes = {
  onClose: func.isRequired,
  onFlush: func.isRequired,
};

export default FlushModal;
