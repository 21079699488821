/* istanbul ignore file */
// Just a bunch of support functions for redux

import { api } from 'app/api/base';
/* istanbul ignore file */
import { URL_PARAMETERS_META, URL_PARAMETERS_SEARCH } from '../api/urls';
import { params } from './action-types';

export const getParamMeta = () => (dispatch) =>
  api(URL_PARAMETERS_META)
    .get()
    .then((res) => {
      const paramsMap = {};

      res.data.parameters.forEach((el) => {
        paramsMap[el.id] = el;
      });

      dispatch({
        type: params.SET_PARAM_META,
        data: {
          paramsMap,
        },
      });
      return paramsMap;
    })
    .catch(() => {});

export const queryParamHistory = (paramsToQuery, url) =>
  api(url || URL_PARAMETERS_SEARCH)
    .get(url ? {} : paramsToQuery)
    .then((res) => res.data)
    .catch(() => ({ items: [] }));

export const deleteParam = (queryParams) =>
  api(URL_PARAMETERS_SEARCH)
    .delete(null, { params: queryParams })
    .then(() => true);

export const setTabsStatus = (tabsIsLoaded = false) => ({
  type: params.SET_TABS_STATUS,
  tabsIsLoaded,
});

export const setTabsScroll = (isScroll = false) => ({
  type: params.SET_TABS_SCROLL,
  isScroll,
});
