import { useEffect, useState } from 'react';

export const useRemovableValue = (value, setValue, needReset) => {
  const [isHidden, setIsHidden] = useState(!value);
  const [memoizedCustomValue, setMemoizedCustomValue] = useState(value);
  useEffect(() => {
    setIsHidden(!value);
  }, [needReset]);

  const handleChange = (newIsHidden = !isHidden) => {
    if (newIsHidden) {
      setMemoizedCustomValue(value);
      setValue(null);
    } else {
      setValue(memoizedCustomValue);
    }

    setIsHidden(!isHidden);
  };

  return [isHidden, handleChange];
};

export const useCustomOrDefaultValue = (
  defaultValue,
  value,
  setValue,
  needReset,
  { getIsDefault = (v) => v == null, emptyValue = null } = {},
) => {
  const [isDefault, setIsDefault] = useState(getIsDefault(value, defaultValue));
  const [memoizedCustomValue, setMemoizedCustomValue] = useState(value);
  useEffect(() => {
    setIsDefault(getIsDefault(value, defaultValue));
  }, [needReset]);

  const handleChange = (newIsDefault = !isDefault) => {
    if (newIsDefault) {
      setMemoizedCustomValue(value);
      setValue(emptyValue);
    } else {
      setValue(memoizedCustomValue ?? defaultValue);
    }

    setIsDefault(newIsDefault);
  };

  return [isDefault, handleChange];
};

export const useRemovableCustomOrDefaultValue = (
  defaultValue,
  value,
  setValue,
  needReset,
) => {
  const [isHidden, setIsHidden] = useState(value === '');
  const [memoizedCustomValue, setMemoizedCustomValue] = useState(value);
  useEffect(() => {
    setIsHidden(value === '');
  }, [needReset]);

  const handleChange = (newIsHide = !isHidden) => {
    if (newIsHide) {
      setMemoizedCustomValue(value);
      setValue('');
    } else {
      setValue(memoizedCustomValue ?? defaultValue);
    }

    setIsHidden(newIsHide);
  };

  return [
    ...useCustomOrDefaultValue(defaultValue, value, setValue, needReset, {
      getIsDefault: () => value === defaultValue,
      emptyValue: defaultValue,
    }),
    isHidden,
    handleChange,
  ];
};
