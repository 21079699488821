import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import {
  NULL_VALUE_N_DASH,
  ON_OFF_CELL_FILTER_OPTIONS,
  OnOffCell,
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  capitalizeFirstLetter,
  getFilteredDisabled as filterDisabled,
  getEnumFilterOptions,
} from '../column-utils';
import { getGlobalColumns } from './globalColumns';

export const getStartupSettingsColumns = (schemas) => {
  const globalSchema = schemas.find((schema) => schema.uri === 'globals');
  const startupSettingsSchema = schemas.find(
    (schema) => schema.uri === 'startup_settings',
  );

  if (!startupSettingsSchema || !globalSchema) {
    return { columnDefs: [], filterableColumns: [] };
  }

  const combinedProperties = {
    ...globalSchema.schema.properties,
    ...startupSettingsSchema.schema.properties,
  };

  const startupSettingsColumnHelper =
    createColumnHelper<z.infer<typeof combinedProperties>>();

  const globalColumns = getGlobalColumns(
    startupSettingsColumnHelper,
    combinedProperties,
  );

  const columnDefs = [
    ...globalColumns.startColumnDefs,

    startupSettingsColumnHelper.accessor((row) => row.sip, {
      id: 'sip',
      cell: OnOffCell,
      meta: {
        displayName: combinedProperties.sip.title,
        filterType: 'boolean',
        filterOptions: [...ON_OFF_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),
    startupSettingsColumnHelper.accessor((row) => row.ssv, {
      id: 'ssv',
      cell: OnOffCell,
      meta: {
        displayName: combinedProperties.ssv.title,
        filterType: 'boolean',
        filterOptions: [...ON_OFF_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    startupSettingsColumnHelper.accessor((row) => row.secure_boot_level, {
      id: 'secure_boot_level',
      cell: (info) => {
        const value = info.getValue();
        if (value === null) {
          return NULL_VALUE_N_DASH;
        }
        if (value) {
          return capitalizeFirstLetter(value);
        }
        return value;
      },
      meta: {
        displayName: combinedProperties.secure_boot_level.title,
        filterType: 'enum',
        filterOptions: getEnumFilterOptions(
          startupSettingsSchema.schema?.definitions.SecureBootLevel.enum || [],
        ),
        filterIcon: 'list-check',
        filterDisabled,
      },
    }),

    startupSettingsColumnHelper.accessor((row) => row.external_boot_level, {
      id: 'external_boot_level',
      cell: (info) => {
        const value = info.getValue();
        if (value === null) {
          return NULL_VALUE_N_DASH;
        }
        if (value) {
          return capitalizeFirstLetter(value);
        }
        return value;
      },
      meta: {
        displayName: combinedProperties.external_boot_level.title,
        filterType: 'enum',
        filterOptions: getEnumFilterOptions(
          startupSettingsSchema.schema?.definitions.ExternalBootLevel.enum ||
            [],
        ),
        filterIcon: 'list-check',
        filterDisabled,
      },
    }),

    startupSettingsColumnHelper.accessor(
      (row) => row.bootstrap_token_escrowed,
      {
        id: 'bootstrap_token_escrowed',
        cell: YesNoCell,
        meta: {
          displayName: combinedProperties.bootstrap_token_escrowed.title,
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    startupSettingsColumnHelper.accessor((row) => row.bootstrap_token_auth, {
      id: 'bootstrap_token_auth',
      cell: YesNoCell,
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.bootstrap_token_auth.title,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    startupSettingsColumnHelper.accessor((row) => row.kext_requires_bst, {
      id: 'kext_requires_bst',
      cell: YesNoCell,
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.kext_requires_bst.title,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    startupSettingsColumnHelper.accessor(
      (row) => row.software_update_requires_bst,
      {
        id: 'software_update_requires_bst',
        cell: YesNoCell,
        meta: {
          defaultHidden: true,
          displayName: combinedProperties.software_update_requires_bst.title,
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    startupSettingsColumnHelper.accessor((row) => row.any_signed_os, {
      id: 'any_signed_os',
      cell: YesNoCell,
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.any_signed_os.title,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    startupSettingsColumnHelper.accessor((row) => row.mdm_manages_kext, {
      id: 'mdm_manages_kext',
      cell: YesNoCell,
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.mdm_manages_kext.title,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    startupSettingsColumnHelper.accessor((row) => row.user_manages_kext, {
      id: 'user_manages_kext',
      cell: YesNoCell,
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.user_manages_kext.title,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    ...globalColumns.endColumnDefs,
  ];

  return { columnDefs };
};
