/* istanbul ignore file */
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

const portalId = '5058330';
const formId = '262c2681-3f11-4443-9f1a-d6556f3b7dff';

export const useProvideTrialFeedback = () =>
  useMutation({
    mutationKey: ['provide-trial-feedback'],
    mutationFn: async ({
      tenant,
      userEmail,
      userFirstName,
      userLastName,
      pageName,
      comments,
    }: {
      tenant: string;
      userEmail: string;
      userFirstName: string;
      userLastName: string;
      pageName: string;
      comments: string;
    }) => {
      return axios.post(
        `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,
        {
          submittedAt: Date.now(),
          context: {
            pageName,
          },
          fields: [
            {
              objectTypeId: '0-1',
              name: 'message',
              value: comments,
            },
            {
              objectTypeId: '0-1',
              name: 'email',
              value: userEmail,
            },
            {
              objectTypeId: '0-1',
              name: 'firstname',
              value: userFirstName,
            },
            {
              objectTypeId: '0-1',
              name: 'lastname',
              value: userLastName,
            },
            {
              objectTypeId: '0-1',
              name: 'company',
              value: tenant,
            },
          ],
        },
      );
    },
  });
