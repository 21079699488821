import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  Flex,
  Icon,
  ScrollArea,
  Tooltip,
  styled,
} from '@kandji-inc/nectar-ui';
import type { Column } from '@tanstack/react-table';
import { getIconName } from 'features/visibility/prism/components/PrismNav/prismNavUtils';
import { i18n } from 'i18n';
// istanbul ignore file
import * as React from 'react';
import { ColumnItem } from './ColumnItem';

const DISABLED_MSG =
  'This Prism category contains a one:many data set and you can only add data from a single one:many Prism category in a view.';
const DISABLED_ATTR_MSG =
  'Unavailable to use when data is turned on for a different one to many Prism category.';

const selectedInCategory = ({ category, columnVisibility }) =>
  category.columns.columnDefs?.reduce((memo, column) => {
    if (
      columnVisibility[column.id] &&
      !category.columns.pinnedColumns?.includes(column.id)
    ) {
      memo.push(column.id);
    }
    return memo;
  }, []);

export const ColumnCategoryList = ({
  categories,
  columnVisibility,
  expandedCategories,
  onCategoryExpandChange,
  onColumnVisibilityChange,
}: {
  categories: Array<{
    uri: string;
    display_name: string;
    is_many: boolean;
    columns: {
      pinnedColumns?: string[];
      columnDefs?: Column<{}, unknown>[];
      alwaysHiddenColumns?: string[];
    };
  }>;
  columnVisibility: Record<string, boolean>;
  expandedCategories: string[];
  onCategoryExpandChange: (expanded: string[]) => void;
  onColumnVisibilityChange: (visibility: Record<string, boolean>) => void;
}) => {
  const sortedCategories = React.useMemo(() => {
    return categories.sort((a, b) =>
      a.uri === 'device_information'
        ? -1
        : b.uri === 'device_information'
          ? 1
          : a.display_name.localeCompare(b.display_name),
    );
  }, [categories]);

  const selectedManyCategory = React.useMemo(
    () =>
      categories.find(
        (category) =>
          category.is_many &&
          Object.keys(columnVisibility).find(
            (key) =>
              key.split('.')[0] === category.uri &&
              columnVisibility[key] === true,
          ),
      ),
    [categories, columnVisibility],
  );

  return (
    <Container>
      <ScrollArea
        css={{ height: '100%', width: '100%' }}
        scrollbarPosition="right"
      >
        <StyledAccordion
          type="multiple"
          css={{ marginRight: '$2' }}
          onValueChange={onCategoryExpandChange}
          value={expandedCategories}
        >
          {sortedCategories.map((category) => (
            <AccordionItem css={{ width: '100%' }} value={category.uri}>
              <AccordionTrigger customIcon={<Icon name="fa-angle-up-small" />}>
                <Flex gap="xs" flex="1">
                  <Icon
                    size={20}
                    name={getIconName(category.uri)}
                    style={{ flex: 'none' }}
                  />
                  {category.display_name}
                </Flex>
                <Flex gap="xs" alignItems="center">
                  {selectedInCategory({ category, columnVisibility }).length >
                    0 && (
                    <Button
                      compact
                      css={{ fontSize: '$2' }}
                      variant="link"
                      onClick={(e) => {
                        e.stopPropagation();
                        const columnsToHide = selectedInCategory({
                          category,
                          columnVisibility,
                        }).reduce((memo, columnId) => {
                          memo[columnId] = false;
                          return memo;
                        }, {});
                        onColumnVisibilityChange({
                          ...columnVisibility,
                          ...columnsToHide,
                        });
                      }}
                    >
                      {i18n.t('Remove all ({ selectedCount })', {
                        selectedCount: selectedInCategory({
                          category,
                          columnVisibility,
                        }).length,
                      })}
                    </Button>
                  )}
                  {category.is_many && (
                    <Tooltip content={i18n.t(DISABLED_MSG)} side="left">
                      <Flex flex="none">
                        <Icon name="arrows-turn-to-dots" size="sm" />
                      </Flex>
                    </Tooltip>
                  )}
                </Flex>
              </AccordionTrigger>
              <AccordionContent>
                {category.columns.columnDefs
                  ?.sort((a, b) =>
                    category.columns.pinnedColumns?.includes(a.id)
                      ? -1
                      : category.columns.pinnedColumns?.includes(b.id)
                        ? 1
                        : a.meta?.displayName.localeCompare(
                            b.meta?.displayName,
                          ),
                  )
                  .map((column) => {
                    const isDisabled =
                      selectedManyCategory != null &&
                      category.uri !== selectedManyCategory.uri &&
                      category.is_many;
                    const tooltip = isDisabled ? i18n.t(DISABLED_ATTR_MSG) : '';
                    return (
                      <ColumnItem
                        key={column.id}
                        column={column}
                        isPinned={
                          category.columns.pinnedColumns?.includes(column.id) ??
                          false
                        }
                        isDisabled={isDisabled}
                        columnVisibility={columnVisibility}
                        onColumnVisibilityChange={onColumnVisibilityChange}
                        enableReorder={false}
                        tooltip={tooltip}
                      />
                    );
                  })}
              </AccordionContent>
            </AccordionItem>
          ))}
        </StyledAccordion>
      </ScrollArea>
    </Container>
  );
};

const Container = styled(Flex, {
  alignItems: 'flex-start',
  gap: '4px',
  alignSelf: 'stretch',
  height: '100%',
  overflow: 'hidden',
});

const StyledAccordion = styled(Accordion, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '4px',
  flex: '1 0 auto',
  background: '$neutral0',
  minHeight: '100%',
  maxHeight: '100%',
});
