import styled, { css } from 'styled-components';

export const PrimaryText = css`
  font-family: var(--font-family-primary);
`;

export const DefaultPrimaryText = styled('span')`
  ${PrimaryText};
  align-self: flex-start;
  font-size: 14px;
  font-weight: 400;
`;

export const LightPrimaryText = styled('span')`
  grid-area: info;
  ${PrimaryText};
  font-size: 14px;
  font-weight: 400;
  color: rgba(118, 119, 124, 0.6);
  @media only screen and (max-width: 1440px) {
    ${(props) => props.checkbox && 'margin-left: 36px;'};
    padding-bottom: 8px;
  }
`;

export const FormFieldTitle = styled('div')`
  ${PrimaryText};
  font-size: 14px;
  margin-bottom: 0.5rem;
`;

export const FormFieldSubtitle = styled('div')`
  ${PrimaryText};
  font-size: 14px;
  color: rgba(118, 119, 124, 0.6);
  margin-bottom: 0.5rem;
`;

export const FormFieldTitleNote = styled('div')`
  ${PrimaryText};
  color: rgba(118, 119, 124, 0.6);
  font-size: 14px;
  margin-left: 5px;
  margin-bottom: 0.5rem;
`;
