const initialState = {
  id: '',
  name: '',
  isActive: true,
  blueprints: [],
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,

  iconFile: null,
  iconSrc: '',

  general: {
    allowOpenFromManagedToUnmanaged: true,
    allowManagedToWriteUnmanagedContacts: false,
    allowUnmanagedToReadManagedContacts: false,
    forceAirDropUnmanaged: false,

    allowOpenFromUnmanagedToManaged: true,
    requireManagedPasteboard: false,
    allowManagedAppsCloudSync: true,
    allowFilesNetworkDriveAccess: true,
    allowFilesUSBDriveAccess: true,
  },
  managedDomains: {
    invalidationsMap: {},
    isUsingManagedDomains: false,
    domains: [],
  },

  supportsRules: true,
};

export default initialState;
