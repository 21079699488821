import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import { setBlueprintsToFormData } from '../../../data-service/library-item/transformers';
import CustomAppsService from './custom-apps-service';

export const transformFromApi = async (apiData) => {
  try {
    const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
    const excludedBlueprints = await getAllBlueprintOptions(
      apiData.excluded_blueprints,
    );

    const result = {
      _data: apiData,
      id: apiData.id,
      iconSrc: apiData.app_icon,
      name: apiData.name,
      isActive: apiData.active,
      selectedBlueprints,
      excludedBlueprints,
      isAllBlueprints: apiData.is_all_blueprints,
    };
    result.installation = {
      type: apiData.data.install_enforcement,
      auditScript: apiData.audit_script,
    };
    result.selfService = {
      isEnabled: apiData.data.show_in_self_service,
      category: apiData.data.self_service_data?.category,
      isRecommended: !!apiData.data.self_service_data?.isRecommended,
      displayName: apiData.data.self_service_data?.displayName,
      shortDescription: apiData.data.self_service_data?.description,
      longDescription: apiData.data.self_service_data?.bodyText,
      isRequireRead: apiData.data.self_service_data?.mustViewAgreement,
      iconUrl: apiData.data.self_service_data?.iconUrl,
      iconS3Key: apiData.data.self_service_data?.iconS3Key,
      iconFile: null,
      version: '-',
      size: apiData.file_size,
      installBtnText: apiData.data.self_service_data?.installBtnText, // ??
      displayInfoBtn: apiData.data.self_service_data?.displayInfoBtn, // ??
      infoBtnText: apiData.data.self_service_data?.infoBtnText, // ??
      infoBtnUrl: apiData.data.self_service_data?.infoBtnUrl, // ??
    };
    result.installDetails = {
      type: apiData.install_type,
      unzipLocation: apiData.unzip_location,
      preInstallScript: apiData.preinstall_script,
      postInstallScript: apiData.postinstall_script,
      isRestart: apiData.restart,
      file: apiData.file && {
        name: apiData.file_name,
        size: apiData.file_size,
        sha256: apiData.sha256,
        downloadLink: apiData.file_url,
        filePath: apiData.file,
      },
    };

    result.rules = apiData.rules;

    return {
      ...apiData,
      data: result,
    };
  } catch (e) {
    console.error(e);
    throw e.message;
  }
};

export const transformToApi = (model) => {
  const toSend = new FormData();
  toSend.set('active', model.isActive);
  toSend.set('type', 'custom-app'); // for creation
  setBlueprintsToFormData(toSend, model.selectedBlueprints);
  toSend.set('is_all_blueprints', model.isAllBlueprints);
  toSend.set('name', model.name);
  if (model.iconFile) {
    toSend.set('app_icon', model.iconFile);
  }
  if (!model.iconSrc) {
    toSend.set('app_icon', '');
  }

  toSend.set(
    'audit_script',
    model.installation.type ===
      CustomAppsService.installationTypes.CONTINUOUSLY_ENFORCE
      ? model.installation.auditScript
      : '',
  );
  toSend.set('file', model.installDetails.file?.filePath);
  toSend.set('sha256', model.installDetails.file?.sha256);
  toSend.set('file_name', model.installDetails.file?.name);
  toSend.set('file_size', model.installDetails.file?.size);
  toSend.set('install_type', model.installDetails.type);
  toSend.set('unzip_location', model.installDetails.unzipLocation);
  toSend.set('preinstall_script', model.installDetails.preInstallScript);
  toSend.set('postinstall_script', model.installDetails.postInstallScript);
  toSend.set('restart', model.installDetails.isRestart);
  toSend.set(
    'install_once',
    model.installation.type ===
      CustomAppsService.installationTypes.INSTALL_ONCE,
  );
  toSend.set('rules', JSON.stringify(model.rules) || null);

  const data = {
    install_enforcement: model.installation.type,
    show_in_self_service: model.selfService.isEnabled,
  };
  if (model.selfService.isEnabled) {
    data.self_service_data = {
      isRecommended: model.selfService.isRecommended,
      iconS3Key: model.selfService.iconS3Key,
      displayName: model.selfService.displayName,
      description: model.selfService.shortDescription,
      bodyText: model.selfService.longDescription,
      mustViewAgreement: model.selfService.isRequireRead,
      category: model.selfService.category,
      installBtnText: model.selfService.installBtnText,
      displayInfoBtn: model.selfService.displayInfoBtn,
      infoBtnText: model.selfService.infoBtnText,
      infoBtnUrl: model.selfService.infoBtnUrl,
    };
  }
  toSend.set('data', JSON.stringify(data));

  return toSend;
};
