import { Radio, RadioGroup } from '@kandji-inc/nectar-ui';
import { Setting } from 'features/library-items/template';
import type React from 'react';
import type { CheckboxRowProps } from './CheckboxRow';
import CheckboxRow from './CheckboxRow';

export type RadioRowProps = {
  options: Array<{ label: string; value: string }>;
  onRadioChange: (value: string) => void;
  value: string;
  radioChildren?: React.ReactNode;
} & CheckboxRowProps;

const RadioRow = (props: RadioRowProps) => {
  const { isDisabled, options, onRadioChange, value, radioChildren } = props;

  return (
    <CheckboxRow {...props}>
      {(isChecked) =>
        isChecked && (
          <Setting.SecondaryControls>
            <Setting.SecondaryControlsRow>
              <Setting.Controls>
                <RadioGroup
                  value={value}
                  onValueChange={onRadioChange}
                  orientation="vertical"
                >
                  {options.map((opt) => (
                    <Radio
                      disabled={isDisabled}
                      key={opt.value}
                      value={opt.value}
                      label={opt.label}
                      data-testid={opt.value}
                    />
                  ))}
                </RadioGroup>
              </Setting.Controls>
            </Setting.SecondaryControlsRow>
            {radioChildren}
          </Setting.SecondaryControls>
        )
      }
    </CheckboxRow>
  );
};

export default RadioRow;
