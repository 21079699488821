import {
  Button,
  Uploader,
  defaultRenderInfo,
  fileToBase64 as getBase64,
  useInputsValidators,
  useIsChanged,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import pkcs1Image from 'assets/img/library/pkcs1Cert.png';
import pkcs12Image from 'assets/img/library/pkcs12Cert.png';
import { getDownloadLink } from 'features/library-items/items/certificate/service/utils';
import { Setting } from 'features/library-items/template';
import React, { useEffect, useMemo } from 'react';
import { i18n } from 'src/i18n';
import CertificateService from '../../service/certificate-service';

const MAX_FILE_SIZE = 1e6;
const fieldsToValidate = ['file'];

const fileImages = {
  [CertificateService.certificateTypes.PKCS_1]: (
    <img src={pkcs1Image} style={{ objectFit: 'contain' }} alt="" />
  ),
  [CertificateService.certificateTypes.PKCS_12]: (
    <img src={pkcs12Image} style={{ objectFit: 'contain' }} alt="" />
  ),
};

const fileIcons = {
  '.cer': fileImages[CertificateService.certificateTypes.PKCS_1],
  '.crt': fileImages[CertificateService.certificateTypes.PKCS_1],
  '.der': fileImages[CertificateService.certificateTypes.PKCS_1],
  '.p12': fileImages[CertificateService.certificateTypes.PKCS_12],
  '.pfx': fileImages[CertificateService.certificateTypes.PKCS_12],
};

const CertificateFileRow = (props) => {
  const { isDisabled, setting, update, validationDep, allowedTypes } = props;
  const isSubmitted = useIsChanged(validationDep);
  const { refs, onInvalidate, invalidations } = useInputsValidators(
    fieldsToValidate,
    update,
  );
  useRemoveValidationOnUnmount(fieldsToValidate, update);

  useEffect(() => {
    if (!setting.file?.name) {
      onInvalidate('file')(i18n.t('Required'));
    } else {
      onInvalidate('file')(false);
    }
  }, [setting.file?.name]);

  const forceWithFile = useMemo(
    () =>
      (setting.file?.name && {
        file: {
          name: setting.file.name,
          size: setting.file.size,
        },
      }) ||
      null,
    [setting.file],
  );

  return (
    <Setting.Row testId="cert-file-row">
      <div style={{ gridColumn: '1/3' }}>
        <p ref={refs.file} className="b-txt-bold b-mb1">
          {i18n.t('Certificate')}
        </p>
        <Uploader
          withError={(isSubmitted && invalidations[0]) || ''}
          onUpload={async (file) => {
            try {
              const base64 = await getBase64(file);
              const downloadLink = await getDownloadLink({
                type: 'application/octet-stream',
                base64,
              });
              update('file', (p) => ({
                ...p,
                file,
                size: file.size,
                base64,
                downloadLink,
                updated_at: file.lastModified,
                // don't set `name` here to prevent forceWithFile before real upload
              }));
              return Promise.resolve(file);
            } catch (_) {
              return Promise.reject(new Error(''));
            }
          }}
          onUploaded={(file) => {
            update('file', (p) => ({
              ...p,
              name: file.name,
            }));
          }}
          onError={(error) => {
            if (error) {
              onInvalidate('file')(true);
            }
          }}
          onDelete={() => {
            update('file', null);
          }}
          allowedTypes={allowedTypes}
          className="b-mb2"
          isDisabled={isDisabled}
          uploadInstructions={
            <span className="b-txt">
              {i18n.ut(
                'Drag file here or <span class="b-alink">click to upload</span>',
              )}
            </span>
          }
          renderInfo={(args) => (
            <div>
              {defaultRenderInfo(args)}
              {setting.file?.downloadLink && (
                <Button
                  href={setting.file.downloadLink}
                  download={setting.file.name}
                  target="_blank"
                  kind="link"
                  style={{ marginLeft: -8 }}
                >
                  {i18n.t('Download')}
                </Button>
              )}
            </div>
          )}
          maxFileSize={MAX_FILE_SIZE}
          forceWithFile={forceWithFile}
          fileIcons={fileIcons}
        />
      </div>
    </Setting.Row>
  );
};

export default CertificateFileRow;
