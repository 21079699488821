/* istanbul ignore file */
import { getSelectedDevicesFromRunsOn } from '../../data-service/library-item/devices';
import {
  IPAD_SKIP_SCREENS,
  IPHONE_SKIP_SCREENS,
  MAC_SKIP_SCREENS,
} from '../auto-enroll/skip-screens/skip-screen-constants';

export default () => ({
  id: null,
  name: '',
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  supportsRules: true,
  isActive: true,

  invalidationsMap: {},

  devices: getSelectedDevicesFromRunsOn({
    runs_on_mac: true,
    runs_on_iphone: true,
    runs_on_ipad: true,
  }),

  mac: {
    isEnabled: false,
    screens: MAC_SKIP_SCREENS(),
    invalidationsMap: {},
  },
  iphone: {
    isEnabled: false,
    screens: IPHONE_SKIP_SCREENS(),
    invalidationsMap: {},
  },
  ipad: {
    isEnabled: false,
    screens: IPAD_SKIP_SCREENS(),
    invalidationsMap: {},
  },
});
