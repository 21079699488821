/* istanbul ignore file */
import { type MutableRefObject, useCallback, useRef, useState } from 'react';

export type ShowToast = (config: {
  title: string;
  variant: string;
  content?: string;
}) => void;

type UseVulnerabilityToast = (toastRef: MutableRefObject<any>) => {
  showToast: ShowToast;
};

const useVulnerabilityToast: UseVulnerabilityToast = (toastRef) => {
  const [lastToastTime, setLastToastTime] = useState<number>(0);
  const toastQueue = useRef<
    { title: string; variant: string; content?: string }[]
  >([]);

  // Process queue is used to make sure there is no "flashing" of toasts
  // and that each one is shown for a minimum of 2 seconds
  const processQueue = useCallback(() => {
    if (toastQueue.current.length > 0) {
      const nextToast = toastQueue.current.shift();
      if (nextToast) {
        showToast(nextToast);
      }
    }
  }, []);

  const showToast = useCallback(
    (config: { title: string; variant: string; content?: string }) => {
      const now = Date.now();
      if (config && toastRef.current) {
        if (now - lastToastTime >= 2000) {
          toastRef.current?.updateToast({
            open: true,
            duration: 5000,
            ...config,
          });
          setLastToastTime(now);
          setTimeout(processQueue, 2000); // Process the queue after 2 seconds
        } else {
          toastQueue.current.push(config);
        }
      }
    },
    [toastRef, lastToastTime, processQueue],
  );

  return { showToast };
};

export { useVulnerabilityToast };
