import { createReducer } from 'redux-create-reducer';
import { blueprint } from '../_actions/action-types';
import { scrollToTab } from '../components/common/helpers';

const initialState = {
  isReady: false,
  activeTab: localStorage.getItem('activeBlueprintTab') || 'computers',
  selectedConnectivity: 'ALL',
  editorMode: false,
  appEditorMode: false,
};

export default createReducer(initialState, {
  [blueprint.SET_BLUEPRINT](state, action) {
    const bpRecord = {
      ...state,
      ...action.blueprint,
      activeTab: initialState.activeTab,
      editorMode: state.editorMode,
      isReady: true,
    };

    return bpRecord;
  },
  [blueprint.CLEAR_BLUEPRINT]() {
    localStorage.removeItem('activeBlueprintTab');
    localStorage.removeItem('showEnabledParameters');
    return initialState;
  },
  [blueprint.UPDATE_BLUEPRINT](state, action) {
    if (action.updates.activeTab) {
      localStorage.setItem('activeBlueprintTab', action.updates.activeTab);
    }
    if (action.updates.isScroll) {
      scrollToTab();
    }

    return {
      ...state,
      ...action.updates,
    };
  },
  [blueprint.SET_BLUEPRINT_WITHOUT_CHANGING_TAB](state, action) {
    return {
      ...state,
      ...action.blueprint,
      activeTab: action.activeTab,
      isReady: true,
    };
  },
  [blueprint.DECREASE_BLUEPRINT_COMPUTERS_COUNT](state, action) {
    return {
      ...state,
      computers_count: state.computers_count - action.numberToDecrease,
    };
  },
});
