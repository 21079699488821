import { Checkbox, setClass } from '@kandji-inc/bumblebee';
import React, { useMemo } from 'react';
import { getTags } from '../../../fast-lane-card.model';

const renderTag = (tag, idx) => (
  <span key={`wifi-app-${idx}-tag-${tag}`} className="content__info-tag">
    <p className="b-txt">{tag}</p>
  </span>
);

const Item = ({ app, isAdded, toggleApp }) => {
  const { name, id, data } = app;
  const { icon, bundle_id } = data;
  const tags = useMemo(() => getTags(app), [app]);

  return (
    <div
      key={id}
      className={setClass([
        'k-wifiv2-fastlane-drawer__item',
        isAdded && '--checked',
      ])}
    >
      <div className="checkbox">
        <Checkbox checked={isAdded} onChange={() => toggleApp(bundle_id)} />
      </div>
      <div className="content">
        <div className="content__logo">
          <img src={icon} alt="" />
        </div>
        <div className="content__info">
          <div className="content__info-title">
            <p className="b-txt-bold">{name}</p>
          </div>
          <div className="content__info-bundle">
            <p className="b-txt-light">{bundle_id}</p>
          </div>
          <div className="content__info-tags">{tags.map(renderTag)}</div>
        </div>
      </div>
    </div>
  );
};

export default Item;
