import { rootApi } from 'app/api/base';
/* istanbul ignore file -- test/demo only */
import React, { useEffect, useState } from 'react';
import { links } from 'src/app/common/constants';
import AwesomeIconCircle from 'src/app/components/interface/AwesomeIconCircle';
import getDeviceImage from 'src/components/common/image-device';
import './style.scss';

const SEARCH_DEBOUNCE = 300;

const TYPE_LIBRARY_ITEM = 'library_item';
const TYPE_BLUEPRINT = 'blueprint';
const TYPE_USER = 'device_user';
const TYPE_DEVICE = 'device';

const formatBlueprintResult = (blueprint) => (
  <a href={`${links.blueprints}/${blueprint.id}`}>
    <div className="search-result-icon">
      <AwesomeIconCircle
        icon={`ss-icon ${blueprint.icon}`}
        color={blueprint.color}
        size="30px"
        border=""
      />
    </div>
    <div className="search-result-content">
      <h2>{blueprint.name}</h2>
      <span>{blueprint.num_devices} devices</span>
      <code>
        <span
          dangerouslySetInnerHTML={{ __html: blueprint._description_match }}
        />
      </code>
    </div>
  </a>
);

const formatUserResult = (user) => (
  <a href={`/users/all/${user.id}`}>
    <div className="search-result-icon">
      {user.photo && (
        <div className="device-user-photo">
          <img src={user.photo} alt={user.name} />
        </div>
      )}
      {!user.photo && (
        <div className="device-user-no-photo">
          <i className="ss-icon ss-user" />
        </div>
      )}
    </div>
    <div className="search-result-content">
      <h2>{user.name}</h2>
      <h3>{user.email}</h3>
      <span>{user.num_devices} devices</span>
    </div>
  </a>
);

const formatDeviceResult = (device) => (
  <a href={`/devices/${device.id}`}>
    <div className="search-result-icon">
      <div className="device-image">
        <img
          src={getDeviceImage(device.family, device.model_id)}
          alt={device.name}
        />
      </div>
    </div>
    <div className="search-result-content">
      <h2>
        {device.name} ({device.serial_number})
      </h2>
      <span>
        {device.model}, {device.os}
      </span>
      {device.asset_tag && (
        <span>
          <strong>Asset:</strong> {device.asset_tag}
        </span>
      )}
      {device.bp_name && (
        <span>
          <strong>Blueprint:</strong> {device.bp_name}
        </span>
      )}
      {device.user_name && (
        <span>
          <strong>User:</strong> {device.user_name}
        </span>
      )}
    </div>
  </a>
);

const formatUnknownResult = (result) => JSON.stringify(result, null, 2);

const ResultsSection = ({ title, type, formatter, results }) => (
  <section>
    <h2>{title}</h2>
    {results.map((result) => (
      <div className={`search-result ${type}`} key={`${type}-${result.id}`}>
        {formatter(result)}
      </div>
    ))}
  </section>
);

export const SearchTest = () => {
  const [loading, setLoading] = useState(false);
  const [phrase, setPhrase] = useState('');
  const [results, setResults] = useState({});
  const [duration, setDuration] = useState(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (phrase.length > 2) {
        setLoading(true);
        setDuration(null);
        const start = Date.now();
        rootApi('/search/v1/search-test')
          .get({ phrase })
          .then((result) => setResults(result.data))
          .finally(() => {
            setLoading(false);
            const seconds = (Date.now() - start) / 1000;
            setDuration(`${seconds} seconds`);
          });
      } else {
        setResults([]);
      }
    }, SEARCH_DEBOUNCE);

    return () => clearTimeout(timeout);
  }, [phrase]);

  return (
    <div className="search-test">
      <h1>Global Search</h1>
      <input
        value={phrase}
        placeholder="3 or more characters"
        onChange={(e) => setPhrase(e.target.value)}
      />
      {loading && <span style={{ marginLeft: '10px' }}>Loading ...</span>}
      {duration && <div>{duration}</div>}
      <div className="search-results">
        {Object.keys(results).length === 0 && 'No results!'}
        {results[TYPE_BLUEPRINT] && (
          <ResultsSection
            title="Blueprints"
            type={TYPE_BLUEPRINT}
            formatter={formatBlueprintResult}
            results={results[TYPE_BLUEPRINT]}
          />
        )}
        {results[TYPE_LIBRARY_ITEM] && (
          <ResultsSection
            title="Library Items"
            type={TYPE_LIBRARY_ITEM}
            formatter={formatUnknownResult}
            results={results[TYPE_LIBRARY_ITEM]}
          />
        )}
        {results[TYPE_DEVICE] && (
          <ResultsSection
            title="Devices"
            type={TYPE_DEVICE}
            formatter={formatDeviceResult}
            results={results[TYPE_DEVICE]}
          />
        )}
        {results[TYPE_USER] && (
          <ResultsSection
            title="Device Users"
            type={TYPE_USER}
            formatter={formatUserResult}
            results={results[TYPE_USER]}
          />
        )}
      </div>
    </div>
  );
};
