import { Chip, Select } from '@kandji-inc/bumblebee';
import React from 'react';
import { i18n } from 'src/i18n';

import { Setting } from 'features/library-items/template';

import EthernetService from '../../../service/ethernet-service';
import { keySizeOptions, keyUsageOptions } from '../../protocols-card.model';

const keys = EthernetService.keys.authentication.scep;

const renderTag = (tag) => (
  <Chip
    kind="tertiary-light"
    text={tag}
    className="k-ade-chip--m0 k-ethernetv2-protocols-general__tag"
    key={tag}
  />
);

const onMenuOpen = () => {};

const Key = ({ update, setting }) => {
  const tags = [
    i18n.t('macOS 10.11+'),
    i18n.t('iPadOS 13+'),
    i18n.t('tvOS 9+'),
    i18n.t('iOS 4.0+'),
    'CIS',
    'NIST',
    'STIG',
    i18n.t('Supervised'),
  ];

  return (
    <>
      <Setting.Header testId="scep-key" className="--no-padding --no-shadow">
        <h3 className="b-h3">{i18n.t('Key')}</h3>
      </Setting.Header>
      <Setting.Row>
        <Setting.Title>
          <p className="b-txt">{i18n.t('Key size')}</p>
        </Setting.Title>
        <Setting.Helpers>
          <p className="b-txt-light">{i18n.t('Key size, in bits.')}</p>
          <div className="b-flex-wrap-gtiny">{tags.map(renderTag)}</div>
        </Setting.Helpers>
        <Setting.Controls>
          <div>
            <Select
              options={keySizeOptions()}
              compact
              onChange={(v) => update(keys.keySize, v.value)}
              value={keySizeOptions().find(
                (option) => option.value === setting[keys.keySize],
              )}
              onMenuOpen={onMenuOpen}
              testId="key-size-options"
            />
          </div>
        </Setting.Controls>
      </Setting.Row>
      <Setting.Row>
        <Setting.Title>
          <p className="b-txt">{i18n.t('Key usage')}</p>
        </Setting.Title>
        <Setting.Helpers>
          <p className="b-txt-light">
            {i18n.t('Indicate the use of the key.')}
          </p>
          <div className="b-flex-wrap-gtiny">{tags.map(renderTag)}</div>
        </Setting.Helpers>
        <Setting.Controls>
          <div>
            <Select
              options={keyUsageOptions()}
              compact
              onChange={(v) => update(keys.keyUsage, v.value)}
              value={keyUsageOptions().find(
                (option) => option.value === setting[keys.keyUsage],
              )}
              testId="key-usage-options"
            />
          </div>
        </Setting.Controls>
      </Setting.Row>
    </>
  );
};

export default Key;
