import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const useDefaultTimezone = () => {
  const userTimezone = useSelector(
    (state) => state.account.user.settings?.timezone,
  );

  return useMemo(
    () => userTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
    [userTimezone],
  );
};

export default useDefaultTimezone;
