import { useEffect, useRef, useState } from 'react';

const stop = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

const useDragHelper = (disabled, onDrop) => {
  const ref = useRef();
  const [isOver, setIsOver] = useState(false);

  const onEnter = (e) => {
    stop(e);
    setIsOver(true);
  };

  const onLeave = (e) => {
    stop(e);
    setIsOver(false);
  };

  const onDragDrop = (e) => {
    stop(e);
    setIsOver(false);
    if (onDrop) {
      onDrop(e);
    }
  };

  useEffect(() => {
    if (ref.current && !disabled) {
      ref.current.addEventListener('dragleave', onLeave);
      ref.current.addEventListener('dragover', onEnter);
      ref.current.addEventListener('drop', onDragDrop);
    }

    return () => {
      if (ref.current && !disabled) {
        ref.current.removeEventListener('dragleave', onLeave);
        ref.current.removeEventListener('dragover', onEnter);
        ref.current.removeEventListener('drop', onDragDrop);
      }
    };
  }, [ref, disabled]);

  return [ref, isOver];
};

export default useDragHelper;
