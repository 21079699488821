import { Box, Flex, Icon, Text, styled } from '@kandji-inc/nectar-ui';
/* istanbul ignore file */
import React from 'react';

const Chip = styled(Box, {
  alignItems: 'center',
  display: 'inline-flex',
  justifyContent: 'space-between',
  cursor: 'grab',
  width: '188px',

  backgroundColor: '#EEF0F6',
  color: '#4D5A79',
  padding: '4px',
  borderRadius: '4px',

  '& svg': {
    width: '16px',
    height: '16px',
  },

  variants: {
    disabled: {
      true: {
        backgroundColor: '#EEF0F6',
        color: '#A5AAB5',
        cursor: 'not-allowed',
      },
    },
  },
});

const ChipIcon = styled(Icon, {});
const ChipText = styled(Text, {
  fontSize: '12px',
  fontWeight: '500',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flex: 1,
  width: 0,
});

const CustomChip = React.forwardRef((props: any, ref) => {
  const { canDrag, disabled, label, onDismiss, onEdit, ...rest } = props;

  return (
    <Chip ref={ref} disabled={disabled} {...rest}>
      <Flex alignItems="center" gap="sm" flex="1">
        {!canDrag && <ChipIcon name="grip-dots-vertical" />}
        <ChipText>{label}</ChipText>
      </Flex>
      <Flex gap="sm" alignItems="center">
        {onEdit && (
          <ChipIcon name="pencil" css={{ cursor: 'auto' }} onClick={onEdit} />
        )}
        {onDismiss && (
          <ChipIcon name="xmark" css={{ cursor: 'auto' }} onClick={onDismiss} />
        )}
      </Flex>
    </Chip>
  );
});

export default CustomChip;
