import { arrayOf, oneOf, string } from 'prop-types';
import './style.css';

import { useEffect } from 'react';
import HubspotService from './hubspot-service';

function HubSpotHandler({ modifiers, customModifiers }) {
  useEffect(() => {
    const modifierCSSClasses = modifiers.map(
      (i) => HubspotService.modifiers[i],
    );
    HubspotService.applyModifiers(...modifierCSSClasses, ...customModifiers);
    return () =>
      HubspotService.discardModifiers(
        ...modifierCSSClasses,
        ...customModifiers,
      );
  }, []);

  return null;
}

HubSpotHandler.propTypes = {
  modifiers: arrayOf(oneOf(Object.keys(HubspotService.modifiers))),
  customModifiers: arrayOf(string),
};

HubSpotHandler.defaultProps = {
  modifiers: ['moveUp'],
  customModifiers: [],
};

export default HubSpotHandler;
