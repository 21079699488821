import {
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import {
  Button,
  Checkbox,
  Flex,
  Grid,
  Heading,
  Hint,
  Radio,
  RadioGroup,
  Text,
  TextArea,
  TextField,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import * as React from 'react';

import { createDisplayableError, getInvalidIdentifierMessage } from '../common';
import { INITIAL_APP_ACCESS_PROPERTY } from '../initial-state';
import { privacyEnum } from '../schema/privacy.schema';
import { AppAccessProperty } from './AppAccessProperty';
import { StyledSetting } from './StyledLibraryItemSetting';

import type {
  AppAccessIdentifierType,
  AppAccessSetting,
  UpdatePrivacy,
} from '../privacy.types';

interface AppAccessCardProps {
  appAccessSetting: AppAccessSetting;
  appAccessIndex: number;
  update: UpdatePrivacy;
  isDisabled: boolean;
  showError: boolean;
}

const { appAccessKey, identifierType, validationMessage, updateActionType } =
  privacyEnum;
const appAccessKeyEnum = appAccessKey.enum;
const identifierTypeEnum = identifierType.enum;
const validationMessageEnum = validationMessage.enum;
const updateActionTypeEnum = updateActionType.enum;

export function AppAccessCard(props: Readonly<AppAccessCardProps>) {
  const { appAccessSetting, appAccessIndex, update, isDisabled, showError } =
    props;

  const {
    CodeRequirements,
    Identifier,
    IdentifierType,
    Properties,
    StaticCode,
  } = appAccessSetting;

  const validations = React.useMemo(() => {
    const fieldNames = [
      appAccessKeyEnum.IdentifierType,
      appAccessKeyEnum.Identifier,
      appAccessKeyEnum.CodeRequirements,
      appAccessKeyEnum.StaticCode,
      appAccessKeyEnum.Properties,
    ] as const;

    const fields = fieldNames.map(
      (name, position) => `appAccess-${appAccessIndex}_pos-${position}.${name}`,
    );
    const fieldKeys = Object.fromEntries(
      fieldNames.map((name, index) => [name, fields[index]]),
    );

    const getKey = (fieldName: keyof typeof appAccessKeyEnum) => {
      const fieldKey = fieldKeys[fieldName];
      return fieldKey || '';
    };

    return {
      fields,
      getKey,
    };
  }, [appAccessIndex]);

  const updateInvalidations = React.useCallback(
    (_key, invalidUpdater) =>
      update({
        type: updateActionTypeEnum.setting,
        updater: (setting) => {
          const invalidationsMap = invalidUpdater(
            setting.invalidations?.invalidationsMap,
          );
          return {
            ...setting,
            invalidations: {
              invalidationsMap,
            },
          };
        },
      }),
    [update],
  );

  const { refs, onInvalidate, invalidations } = useInputsValidators(
    validations.fields,
    updateInvalidations,
  );

  useRemoveValidationOnUnmount(validations.fields, updateInvalidations);

  const displayableError = React.useMemo(
    () => createDisplayableError(showError),
    [showError],
  );

  React.useEffect(() => {
    onInvalidate(validations.getKey(appAccessKeyEnum.IdentifierType))(
      !IdentifierType ? i18n.t(validationMessageEnum['Required.']) : false,
    );
    onInvalidate(validations.getKey(appAccessKeyEnum.Identifier))(
      getInvalidIdentifierMessage(Identifier, IdentifierType),
    );
    onInvalidate(validations.getKey(appAccessKeyEnum.CodeRequirements))(
      !CodeRequirements
        ? { label: i18n.t(validationMessageEnum['Required.']) }
        : false,
    );

    onInvalidate(validations.getKey(appAccessKeyEnum.StaticCode))(
      typeof StaticCode !== 'boolean'
        ? i18n.t(validationMessageEnum['Required.'])
        : false,
    );
    onInvalidate(validations.getKey(appAccessKeyEnum.Properties))(
      Properties.length <= 0
        ? i18n.t(
            validationMessageEnum[
              'At least one App or Service row is required to save.'
            ],
          )
        : false,
    );
  }, [
    CodeRequirements,
    Identifier,
    IdentifierType,
    Properties,
    StaticCode,
    appAccessIndex,
    validations,
  ]);

  return (
    <StyledSetting.Card>
      <StyledSetting.Header>
        <Flex>
          <Heading
            size="5"
            as="h3"
            css={{
              fontWeight: '$medium',
            }}
          >
            {i18n.t('App access')}
          </Heading>
        </Flex>

        {!isDisabled && appAccessIndex > 0 && (
          <Button
            compact
            variant="subtle"
            disabled={isDisabled}
            onClick={() =>
              update({
                type: updateActionTypeEnum.setting,
                updater: (setting) => ({
                  ...setting,
                  appAccess: setting.appAccess.filter(
                    (_, index) => index !== appAccessIndex,
                  ),
                }),
              })
            }
            icon={{
              name: 'fa-xmark-small',
              position: 'left',
            }}
          >
            {i18n.t('Remove')}
          </Button>
        )}
      </StyledSetting.Header>

      <StyledSetting.Rows>
        <StyledSetting.Row>
          <StyledSetting.Title>
            <Text>{i18n.t('Identifier type')}</Text>
          </StyledSetting.Title>

          <Flex flow="column" gap="xs">
            <RadioGroup
              ref={refs[validations.getKey(appAccessKeyEnum.IdentifierType)]}
              value={IdentifierType}
              onValueChange={(value: AppAccessIdentifierType) =>
                update({
                  type: updateActionTypeEnum.appAccess,
                  appAccessIndex,
                  updater: (appAccess) => ({
                    ...appAccess,
                    IdentifierType: value,
                  }),
                })
              }
            >
              <Radio
                value={identifierTypeEnum.bundleID}
                label={i18n.t('Bundle ID')}
                aria-label="Bundle ID"
                disabled={isDisabled}
              />
              <Radio
                value={identifierTypeEnum.path}
                label={i18n.t('Path')}
                aria-label="Path"
                disabled={isDisabled}
              />
            </RadioGroup>

            {showError &&
              !!invalidations[
                validations.getKey(appAccessKeyEnum.IdentifierType)
              ] && (
                <Hint
                  label={i18n.t(validationMessageEnum['Required.'])}
                  variant="error"
                />
              )}
          </Flex>
        </StyledSetting.Row>

        <StyledSetting.Row>
          <StyledSetting.Title>
            <Text>{i18n.t('Identifier')}</Text>
          </StyledSetting.Title>

          <TextField
            ref={refs[validations.getKey(appAccessKeyEnum.Identifier)]}
            value={Identifier}
            onChange={(e) =>
              update({
                type: updateActionTypeEnum.appAccess,
                appAccessIndex,
                updater: (appAccess) => ({
                  ...appAccess,
                  Identifier: e.currentTarget.value,
                }),
              })
            }
            placeholder="com.vmware.fusion"
            readOnly={isDisabled}
            state={displayableError(
              !!invalidations[validations.getKey(appAccessKeyEnum.Identifier)],
              'error',
              'default',
            )}
            hint={displayableError(
              !!invalidations[validations.getKey(appAccessKeyEnum.Identifier)],
              invalidations[validations.getKey(appAccessKeyEnum.Identifier)],
              null,
            )}
          />
        </StyledSetting.Row>

        <StyledSetting.Row>
          <StyledSetting.Title>
            <Text>{i18n.t('Code requirement')}</Text>
          </StyledSetting.Title>

          <Flex
            flow="column"
            gap="md"
            css={{
              '& textarea': {
                minHeight: 150,
                resize: 'vertical',
              },
            }}
          >
            <TextArea
              // @ts-expect-error -- TODO fix this
              ref={refs[validations.getKey(appAccessKeyEnum.CodeRequirements)]}
              value={CodeRequirements}
              onChange={(e) =>
                update({
                  type: updateActionTypeEnum.appAccess,
                  appAccessIndex,
                  updater: (appAccess) => ({
                    ...appAccess,
                    CodeRequirements: e.currentTarget.value,
                  }),
                })
              }
              placeholder='identifier "com.vmware.fusion" and anchor apple generic and certificate 1[field.1.2.840.113635.100.6.2.6] /* exists */ and certificate leaf[field.1.2.840.113635.100.6.1.13] /* exists */ and certificate leaf[subject.OU] = EG7KH642X6'
              readOnly={isDisabled}
              state={displayableError(
                !!invalidations[
                  validations.getKey(appAccessKeyEnum.CodeRequirements)
                ],
                'error',
                'default',
              )}
              hint={displayableError(
                !!invalidations[
                  validations.getKey(appAccessKeyEnum.CodeRequirements)
                ],
                invalidations[
                  validations.getKey(appAccessKeyEnum.CodeRequirements)
                ],
                null,
              )}
            />

            <Checkbox
              ref={refs[validations.getKey(appAccessKeyEnum.StaticCode)]}
              label={i18n.t('Statically validate the code requirement')}
              checked={StaticCode}
              onCheckedChange={(value: boolean) =>
                update({
                  type: updateActionTypeEnum.appAccess,
                  appAccessIndex,
                  updater: (appAccess) => ({
                    ...appAccess,
                    StaticCode: value,
                  }),
                })
              }
              disabled={isDisabled}
            />
          </Flex>
        </StyledSetting.Row>

        <StyledSetting.Row>
          <StyledSetting.SecondaryControls>
            <Grid
              gapY="3"
              css={{
                gridColumn: '1 / -1',

                [`& ${StyledSetting.SecondaryControlsRow}::after`]: {
                  display: 'none',
                },
              }}
            >
              {!!Properties.length && (
                <Grid
                  css={{
                    gridTemplateColumns: '320px 320px',
                    gridColumnGap: '$4',
                    pl: '$5',
                  }}
                >
                  <Text>{i18n.t('App or Service')}</Text>
                  <Text>{i18n.t('Access')}</Text>
                </Grid>
              )}

              <StyledSetting.SecondaryControlsRow>
                {Properties.map((appAccessPropertySetting, propertyIndex) => (
                  <AppAccessProperty
                    /* eslint-disable-next-line react/no-array-index-key --
                     * stable id key from API not available  */
                    key={`${appAccessIndex}-${propertyIndex}`}
                    appAccessPropertySetting={appAccessPropertySetting}
                    appAccessIndex={appAccessIndex}
                    appAccessPropertyIndex={propertyIndex}
                    appAccessProperties={Properties}
                    canDelete={Properties.length > 1}
                    update={update}
                    isDisabled={isDisabled}
                    showError={showError}
                  />
                ))}
              </StyledSetting.SecondaryControlsRow>

              {!isDisabled && (
                <Flex
                  justifyContent={
                    invalidations[
                      validations.getKey(appAccessKeyEnum.Properties)
                    ]
                      ? 'space-between'
                      : 'end'
                  }
                  css={{
                    [`& ${Hint}`]: {
                      flexGrow: 1,
                    },
                  }}
                >
                  {!!invalidations[
                    validations.getKey(appAccessKeyEnum.Properties)
                  ] && (
                    <Hint
                      ref={
                        refs[validations.getKey(appAccessKeyEnum.Properties)]
                      }
                      label={i18n.t(
                        validationMessageEnum[
                          'At least one App or Service row is required to save.'
                        ],
                      )}
                      variant="error"
                    />
                  )}

                  <Button
                    compact
                    variant="subtle"
                    disabled={isDisabled}
                    onClick={() =>
                      update({
                        type: updateActionTypeEnum.appAccess,
                        appAccessIndex,
                        updater: ({
                          Properties: appAccessProperties = [],
                          ...restAppAccess
                        }) => ({
                          ...restAppAccess,
                          Properties: [
                            ...appAccessProperties,
                            INITIAL_APP_ACCESS_PROPERTY,
                          ],
                        }),
                      })
                    }
                    icon={{
                      name: 'circle-plus',
                      position: 'left',
                    }}
                  >
                    {i18n.t('Add more')}
                  </Button>
                </Flex>
              )}
            </Grid>
          </StyledSetting.SecondaryControls>
        </StyledSetting.Row>
      </StyledSetting.Rows>
    </StyledSetting.Card>
  );
}
