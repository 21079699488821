/* istanbul ignore file */
import {
  Card,
  Flex,
  Heading,
  Icon,
  Separator,
  Text,
  styled,
} from '@kandji-inc/nectar-ui';
import React, { useContext, useState } from 'react';
import { AccountContext } from 'src/contexts/account';
import { getResources } from './common.data';

const IconContainer = styled(Flex, {
  alignItems: 'center',
  justifyContent: 'center',
  width: '22px',
  height: '22px',
  flexDirection: 'column',
  borderRadius: '4px',
  backgroundColor: '$blue05',
  padding: '3px',
  color: '$blue30',

  variants: {
    color: {
      blue: {
        backgroundColor: '$blue05',
        color: '$blue30',
      },

      yellow: {
        backgroundColor: '#FFF4E0',
        color: '#FFB952',
      },
    },
  },
});

const SingleResource = ({ color, icon, title, description, link }) => {
  const [isLinkHovered, setIsLinkHovered] = useState(false);

  return (
    <Flex css={{ padding: '10px 0', gap: '10px' }}>
      <IconContainer color={color}>
        <Icon name={icon} />
      </IconContainer>
      <Flex flow="column" gap="xs">
        <a
          href={link}
          id={link}
          target="_blank"
          rel="noreferrer"
          onMouseEnter={() => setIsLinkHovered(true)}
          onMouseLeave={() => setIsLinkHovered(false)}
        >
          <Flex alignItems="center" css={{ gap: '1px' }}>
            <Text css={{ fontSize: '12px' }}>{title}</Text>
            {isLinkHovered && (
              <Icon size="xs" name="arrow-up-right-from-square" />
            )}
          </Flex>
        </a>
        <Text variant="secondary" css={{ fontSize: '12px' }}>
          {description}
        </Text>
      </Flex>
    </Flex>
  );
};

const TrialResources = () => {
  const resources = getResources();

  return (
    <Card css={{ padding: '16px' }}>
      <Flex flow="column" css={{ gap: '26px' }}>
        {resources.map((resource) => {
          const { title, description, sectionResources } = resource;

          return (
            <Flex key={title} flow="column" gap="md">
              <Heading size="5">{title}</Heading>
              <Separator />
              {description && <Text>{description}</Text>}
              {sectionResources.map((sectionResource) => {
                const { color, icon, title, description, link, isHidden } =
                  sectionResource;

                if (isHidden) {
                  return null;
                }

                return (
                  <SingleResource
                    key={title}
                    color={color}
                    icon={icon}
                    title={title}
                    description={description}
                    link={link}
                  />
                );
              })}
            </Flex>
          );
        })}
      </Flex>
    </Card>
  );
};

export default TrialResources;
