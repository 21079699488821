import React from 'react';
import CertificateFileRow from '../common/certificate-file-row';
import CertificateNameRow from '../common/certificate-name-row';

const Pkcs1Rows = (props) => (
  <>
    <CertificateNameRow {...props} />
    <CertificateFileRow {...props} allowedTypes={['.cer', '.crt', '.der']} />
  </>
);

export default Pkcs1Rows;
