/* istanbul ignore file */
import React, { memo } from 'react';

import { Flex, SelectV2, Text } from '@kandji-inc/nectar-ui';
import { Setting } from 'src/features/library-items/template';
import { i18n } from 'src/i18n';
import type { KeyModelSetting, KeyProps } from '../../scep.types';
import ScepService from '../../service/scep-service';

const Key = (props: KeyProps) => {
  const { update, isDisabled, settings } = props;

  const keyUsage = ScepService.KeyUsages().find(
    ({ value }) => value === `${settings['Key Usage']}`,
  );
  const keySize = ScepService.KeySizes().find(
    ({ value }) => value === `${settings.Keysize}`,
  );
  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('Key')}</h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Title>
            <Flex gap="xs">
              <Text>{i18n.t('Key Size')}</Text>
            </Flex>
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light">{i18n.t('Key size, in bits.')}</p>
          </Setting.Helpers>
          <Setting.Controls>
            <SelectV2.Default
              options={ScepService.KeySizes()}
              onValueChange={(value) =>
                update(
                  'Keysize',
                  parseInt(value, 10) as KeyModelSetting['Keysize'],
                )
              }
              value={`${settings.Keysize}`}
              triggerProps={{
                value: keySize.label,
                variant: 'input',
                css: { width: '400px' },
                disabled: isDisabled,
              }}
            />
          </Setting.Controls>
        </Setting.Row>

        <Setting.Row>
          <Setting.Title>
            <Flex gap="xs">
              <Text>{i18n.t('Key Usage')}</Text>
            </Flex>
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t('Indicate the use of the key.')}
            </p>
          </Setting.Helpers>
          <Setting.Controls>
            <SelectV2.Default
              options={ScepService.KeyUsages()}
              onValueChange={(value) =>
                update(
                  'Key Usage',
                  parseInt(value, 10) as KeyModelSetting['Key Usage'],
                )
              }
              value={`${settings['Key Usage']}`}
              triggerProps={{
                value: keyUsage.label,
                variant: 'input',
                css: { width: '400px' },
                disabled: isDisabled,
              }}
            />
          </Setting.Controls>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default memo(Key);
