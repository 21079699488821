import { Button, Dialog, Flex } from '@kandji-inc/nectar-ui';
import type { Dispatch, SetStateAction } from 'react';

type Props = {
  isOpen: boolean;
  onClose: () => Dispatch<SetStateAction<boolean>>;
  onConfirm: () => Dispatch<SetStateAction<boolean>>;
};

export function ConfirmLogoutModal({ isOpen, onClose, onConfirm }: Props) {
  const footer = (
    <Flex gap="md" justifyContent="end" wrap="wrap">
      <Button variant="subtle" onClick={onClose}>
        Continue editing
      </Button>

      <Button variant="primary" onClick={onConfirm}>
        Log out
      </Button>
    </Flex>
  );

  return (
    <Dialog
      title="Log out"
      content="You will lose all unsaved work. Are you sure you want to log out?"
      footer={footer}
      isOpen={isOpen}
      onOpenChange={onClose}
      css={{ width: '500px', zIndex: 2000 }}
    />
  );
}
