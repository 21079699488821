// istanbul ignore file
import { useMutation } from '@tanstack/react-query';
import type {
  FiltersState,
  GetByThreatsResponse,
  PaginationState,
  SortColumnState,
} from '../../../threat.types';
import threatsGroupQuery from '../hooks/use-get-by-threats/threatsGroupQuery';

type UseSelectThreatsMutation = (
  onSuccess: (data: GetByThreatsResponse) => void,
  onError: () => void,
) => {
  fetchForSelect: (args: {
    filters: FiltersState | object;
    sort: SortColumnState | object;
    pagination: PaginationState | object;
  }) => void;
  isLoading: boolean;
};

const useSelectThreatsMutation: UseSelectThreatsMutation = (
  onSuccess,
  onError,
) => {
  const { mutate, isPending } = useMutation({
    mutationKey: ['select-threats'],
    mutationFn: ({
      filters,
      sort,
      pagination,
    }: {
      filters: FiltersState | object;
      sort: SortColumnState | object;
      pagination: PaginationState | object;
    }) => threatsGroupQuery(filters, sort, pagination),
    onSuccess(data) {
      onSuccess(data?.data);
    },
    onError() {
      onError();
    },
  });

  return {
    fetchForSelect: mutate as unknown as () => void,
    isLoading: isPending,
  };
};

export default useSelectThreatsMutation;
