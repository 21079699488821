import React from 'react';

function Volume() {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.87891 1.71875L3.44531 4.125H0.65625C0.273438 4.125 0 4.42578 0 4.78125V8.71875C0 9.10156 0.273438 9.375 0.65625 9.375H3.44531L5.87891 11.8086C6.28906 12.2188 7 11.9453 7 11.3438V2.18359C7 1.58203 6.28906 1.30859 5.87891 1.71875ZM12.25 0.296875C11.9492 0.105469 11.5391 0.1875 11.3203 0.488281C11.1289 0.816406 11.2109 1.22656 11.5117 1.41797C13.3438 2.59375 14.4102 4.61719 14.4102 6.77734C14.4102 8.91016 13.3438 10.9336 11.5117 12.1094C11.2109 12.3008 11.1289 12.7109 11.3203 13.0391C11.5117 13.3125 11.9219 13.4219 12.25 13.2305C14.4375 11.7812 15.75 9.375 15.75 6.75C15.75 4.15234 14.4375 1.74609 12.25 0.296875ZM13.125 6.75C13.125 5.02734 12.2227 3.44141 10.7734 2.48438C10.4727 2.29297 10.0625 2.375 9.87109 2.70312C9.67969 3.00391 9.76172 3.41406 10.0625 3.60547C11.1562 4.28906 11.8125 5.49219 11.8125 6.75C11.8125 8.03516 11.1562 9.23828 10.0625 9.92188C9.76172 10.1133 9.67969 10.5234 9.87109 10.8242C10.0352 11.125 10.4453 11.2344 10.7734 11.043C12.2227 10.0859 13.125 8.5 13.125 6.75ZM9.24219 4.67188C8.91406 4.48047 8.53125 4.58984 8.33984 4.91797C8.17578 5.24609 8.28516 5.62891 8.61328 5.82031C8.94141 6.01172 9.1875 6.36719 9.1875 6.75C9.1875 7.16016 8.94141 7.51562 8.61328 7.70703C8.28516 7.89844 8.17578 8.28125 8.33984 8.60938C8.53125 8.9375 8.91406 9.04688 9.24219 8.85547C10.0078 8.44531 10.5 7.625 10.5 6.75C10.5 5.90234 10.0078 5.08203 9.24219 4.67188Z"
        fill="#0057DA"
      />
    </svg>
  );
}

export default Volume;
