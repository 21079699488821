import type React from 'react';
import { memo } from 'react';

import { Checkbox, Chip, Flex, Select, Slider } from '@kandji-inc/bumblebee';

import { Setting } from 'features/library-items/template';

type CheckboxRowProps = {
  deviceFamily: string;
  label: string;
  apiProperty: string;
  isChecked: boolean;
  isDisabled: boolean;
  onUpdate: (key: string, cb: (currVal) => void) => void;
  helpText: React.ReactNode;
  subFieldType: string;
  subFieldLabel: string;
  subFieldOptions?: Array<{
    label: string;
    value: string | number;
  }>;
  subFieldValue: string;
  subFieldMin?: number;
  subFieldMax?: number;
};

const CheckboxRow = (props: CheckboxRowProps) => {
  const {
    apiProperty,
    deviceFamily,
    label,
    isChecked,
    onUpdate,
    isDisabled,
    helpText,
    subFieldType,
    subFieldLabel,
    subFieldOptions,
    subFieldValue,
    subFieldMin,
    subFieldMax,
  } = props;

  return (
    <Setting.Row>
      <Setting.Controls>
        <Checkbox
          disabled={isDisabled}
          label={label}
          id={`${deviceFamily}_${apiProperty}_checkbox`}
          testId={`${deviceFamily}_${apiProperty}_checkbox`}
          checked={isChecked}
          onChange={() =>
            onUpdate(apiProperty, (p) => ({
              ...p,
              checked: !p.checked,
            }))
          }
        />
      </Setting.Controls>
      <Setting.Helpers>{helpText}</Setting.Helpers>

      {subFieldType && isChecked && (
        <Setting.SecondaryControls>
          <Setting.SecondaryControlsRow>
            <Setting.Controls>
              {subFieldType === 'select' && (
                <Flex align="center">
                  <Select
                    disabled={isDisabled}
                    compact
                    value={subFieldOptions.find(
                      (opt) => opt.value === subFieldValue,
                    )}
                    inputId={`${deviceFamily}_${apiProperty}_select`}
                    options={subFieldOptions}
                    onChange={(selected) =>
                      onUpdate(apiProperty, (p) => ({
                        ...p,
                        value: selected.value,
                      }))
                    }
                  />
                  <p className="b-txt b-ml">{subFieldLabel}</p>
                </Flex>
              )}

              {subFieldType === 'slider' && (
                <>
                  <p className="b-txt-light">
                    <Chip
                      style={{ display: 'inline' }}
                      text={`${subFieldValue} ${subFieldLabel}`}
                    />
                  </p>
                  <Slider
                    className="mb-4"
                    value={parseFloat(subFieldValue)}
                    onChange={(value) =>
                      onUpdate(apiProperty, (p) => ({
                        ...p,
                        value,
                      }))
                    }
                    disabled={isDisabled}
                    min={subFieldMin}
                    max={subFieldMax}
                    markedValues={[
                      subFieldMin,
                      Math.round(subFieldMax / 2),
                      subFieldMax,
                    ]}
                  />
                </>
              )}
            </Setting.Controls>
          </Setting.SecondaryControlsRow>
        </Setting.SecondaryControls>
      )}
    </Setting.Row>
  );
};

export default memo(CheckboxRow);
