// istanbul ignore file
import { z } from 'zod';

const chatBotStatus = z.enum([
  'in_progress',
  'succeeded',
  'failed',
  'submitted',
]);

const messageConfidence = z.enum(['positive', 'negative']);

const messageSenderType = z.enum(['user', 'bot']);

export const componentTypeUnion = z.discriminatedUnion('component_type', [
  z.object({
    component_type: z.literal('text'),
    data: z.object({
      text: z.string(),
    }),
  }),
  z.object({
    component_type: z.literal('table'),
    data: z.object({
      table: z.array(z.any()),
    }),
  }),
  z.object({
    component_type: z.literal('url'),
    data: z.object({
      url: z.string(),
    }),
  }),
  z.object({
    component_type: z.literal('loading'),
    data: z.boolean().optional(),
  }),
]);

export const messageComponent = componentTypeUnion.nullable();

export const chatMessage = z.object({
  id: z.string(),
  created_at: z.string(),
  sender: messageSenderType,
  confidence: messageConfidence.nullable(),
  message_components: z.array(messageComponent),
});

export const chatBotResponse = z.object({
  id: z.string(),
  status: chatBotStatus,
  chat_message: chatMessage.nullable(),
});

export const chatBotSubmittedResponse = z.object({
  id: z.string(),
  status: chatBotStatus.nullable(),
  chat_message: chatMessage.nullable(),
});

export const chatHistoryResponse = z.object({
  chat_session_id: z.string(),
  messages: z.array(chatMessage),
});

export const chatListResponse = z.object({
  chat_sessions: z.array(
    z.object({
      id: z.string(),
      date_created: z.string(),
    }),
  ),
});

export const newChatresponse = z.object({
  id: z.string(),
});

export const rateChatRequest = z.object({
  liked: z.boolean(),
  optional_feedback: z.string().nullable(),
});
