import { Button, Flex } from '@kandji-inc/nectar-ui';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { i18n } from 'i18n';
import { ErrorBoundary, useErrorBoundary } from 'react-error-boundary';
import { logError } from 'src/app/common/logger';
import ThreatListEmptyTable from './ThreatListEmptyTable';

type ErrorBoundaryFallbackProps = Readonly<{
  preReset: (() => Promise<void>) | undefined;
  css?: any;
  compact: boolean;
}>;

const ErrorBoundaryFallback = (props: ErrorBoundaryFallbackProps) => {
  const { preReset, css, compact } = props;
  const { resetBoundary } = useErrorBoundary();
  const { reset } = useQueryErrorResetBoundary();

  const handleReset = async () => {
    if (preReset) {
      await preReset();
    }
    resetBoundary();
    reset();
  };

  return (
    <Flex css={css}>
      <ThreatListEmptyTable
        header={i18n.common.error()}
        body="Please try again. If the issue persists, contact support."
        compact={compact}
        slot={
          <Button variant="primary" onClick={handleReset}>
            Try again
          </Button>
        }
      />
    </Flex>
  );
};

type ThreatListErrorBoundaryProps = Readonly<{
  children: React.ReactNode;
  preReset?: () => Promise<void>;
  css?: any;
  compact?: boolean;
}>;

const ThreatListErrorBoundary = (props: ThreatListErrorBoundaryProps) => {
  const { children, css, preReset, compact = false } = props;

  return (
    <ErrorBoundary
      fallbackRender={() => (
        <ErrorBoundaryFallback
          css={css}
          preReset={preReset}
          compact={compact}
        />
      )}
      onError={logError}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ThreatListErrorBoundary;
