export default {
  name: '',
  isActive: true,
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  supportsRules: true,

  general: {
    isBlockMacAppStore: false,
    disableSoftwareUpdateNotifications: false,
    disableAppAdoption: false,
  },
};
