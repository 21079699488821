/* istanbul ignore file */
import { setClass } from '@kandji-inc/bumblebee';
import { Icon as NectarIcon } from '@kandji-inc/nectar-ui';
import cn from 'classnames';
import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import { dataTestId } from '../common/utils';

/* istanbul ignore next */
const SidebarItemLink = ({
  active,
  disable,
  icon,
  badge,
  name,
  link,
  sidebarOpened,
  withScrollBar,
  onClick,
  customEl,
}) => (
  <Link
    className={cn('nav-link', { active, disable })}
    onClick={onClick}
    to={link}
  >
    <div
      className="item"
      id={`sidebarItem${camelCase(link)}`}
      data-testid={dataTestId(`sidebar ${name}`)}
    >
      {icon && (
        <div className="d-flex flex-dir-row align-items-center position-relative">
          <NectarIcon name={icon} />
        </div>
      )}
      {customEl && (
        <div className="d-flex flex-dir-row align-items-center position-relative">
          {customEl}
        </div>
      )}
      {sidebarOpened && (
        <>
          <div className="kandji-sidebar__item--opened">{name}</div>
          <span className="ml-auto">
            {badge ? (
              <div className="kandji-sidebar__item--badge">{badge}</div>
            ) : null}
          </span>
        </>
      )}
      {!sidebarOpened && (
        <UncontrolledTooltip
          placement="right"
          delay={{ show: 400, hide: 0 }}
          className={setClass(
            'cover-all-helper tooltip-right kandji-sidebar__tooltip',
            withScrollBar && '--with-scrollbar',
          )}
          target={`sidebarItem${camelCase(link)}`}
        >
          {name}
          {badge && `: ${badge}`}
        </UncontrolledTooltip>
      )}
    </div>
  </Link>
);

SidebarItemLink.propTypes = {
  active: PropTypes.bool,
  disable: PropTypes.bool,
  icon: PropTypes.string,
  badge: PropTypes.number,
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  sidebarOpened: PropTypes.bool.isRequired,
  withScrollBar: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  customEl: PropTypes.element,
};

SidebarItemLink.defaultProps = {
  active: false,
  disable: false,
  icon: null,
  badge: null,
  /* istanbul ignore next */
  onClick: () => {},
  customEl: null,
};

export default memo(SidebarItemLink);
