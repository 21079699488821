import { z } from 'zod';

export const pulseCheckType = z.enum([
  'processes',
  'file_check',
  'logged_in_users',
  'preferences',
]);

export const fileCheckAgentFilterSchema = z.object({
  hashes: z.array(z.string()),
  paths: z.array(z.string()),
  collect_metadata: z.boolean(),
});

export const processCheckAgentFilterSchema = z.object({
  name: z.object({ like: z.array(z.string()) }),
  state: z.object({ like: z.array(z.string()) }),
  user: z.object({ like: z.array(z.string()) }),
});

export const preferencesCheckAgentFilterSchema = z.object({
  domain: z.string().nullish(),
  path: z.string().nullish(),
  username: z.string().nullish(),
  current_host: z.boolean().nullish(),
  keys: z.array(z.object({ key: z.string() })).nullish(),
});

export const createPulseTemplateRequestSchema = z.object({
  type: pulseCheckType,
  devices: z.array(z.string()).nullable(),
  device_families: z.array(z.string()).nullish(),
  blueprints: z.array(z.string()).nullable(),
  agent_filters: processCheckAgentFilterSchema
    .or(fileCheckAgentFilterSchema)
    .or(preferencesCheckAgentFilterSchema)
    .nullish(),
  name: z.string().nullable(),
  description: z.string().nullish(),
});

export const createPulseJobRequestSchema = z.object({
  devices: z.array(z.string()).nullable(),
  device_families: z.array(z.string()).nullable(),
  blueprints: z.array(z.string()).nullable(),
  agent_filters: z.string().nullable(),
});

export const pulseTemplateResponseSchema = z.object({
  name: z.string().nullable(),
  description: z.string().nullish(),
  type: pulseCheckType.nullable(),
  agent_filters: z.any().nullable(),
  created_at: z.string().nullable(),
  devices: z.array(z.string()).nullable(),
  blueprints: z.array(z.string()).nullable(),
  id: z.string().nullable(),
  device_families: z.array(z.string()).nullish(),
});

export const pulseCheckJobSchema = z.object({
  name: z.string().nullish(),
  description: z.string().nullish(),
  type: pulseCheckType.nullish(),
  progress: z
    .object({
      complete: z.number().nullable(),
      created: z.number().nullable(),
      failed: z.number().nullable(),
      total: z.number().nullable(),
    })
    .nullish(),
  job_id: z.string().nullish(),
  status: z.string().nullish(),
  agent_filters: z.any().nullable(),
  expires_at: z.string().nullish(),
  created_at: z.string().nullish(),
  total_devices_finished: z.number().nullish(),
  total_devices_targeted: z.number().nullish(),
  total_devices_with_results: z.number().nullish(),
  total_number_of_results: z.number().nullish(),
  devices: z.array(z.string()).nullish(),
  blueprints: z.array(z.string()).nullish(),
  pulse_check_id: z.string().nullish(),
});

const pulseCheckDeviceMeta = z.object({
  device__name: z.string().nullable(),
  device__user_id: z.string().nullable(),
  device__user_name: z.string().nullable(),
  model_id: z.string().nullable(),
  created_at: z.string().nullable(),
  device_id: z.string().nullable(),
  job_id: z.string().nullable(),
  status: z.string().nullable(),
  updated_at: z.any().nullable(),
});

export const pulseFileCheckDeviceSchema = pulseCheckDeviceMeta.extend({
  results: z
    .object({
      path: z.string().optional(),
      hash: z.string().optional(),
      extended_attributes: z.any().optional(),
      metadata: z.any().optional(),
      exists: z.boolean().nullable(),
    })
    .nullable(),
});

export const pulseLoggedInUsersDeviceSchema = pulseCheckDeviceMeta.extend({
  results: z
    .object({
      id: z.string().nullable(),
      pid: z.number().nullable(),
      timestamp: z.number().nullable(),
      type: z.string().nullable(),
      user: z
        .object({
          full_name: z.string().nullable(),
          home_directory: z.string().nullable(),
          is_admin: z.boolean().nullable(),
          is_hidden: z.boolean().nullable(),
          username: z.string().nullable(),
          node_location: z.string().nullable(),
          uid: z.number().nullable(),
        })
        .nullable(),
    })
    .or(
      z.object({
        code: z.number().nullish(),
        details: z.string().nullish(),
      }),
    )
    .nullable(),
});

const processMeta = z.object({
  cpu_percentage: z.number().nullish(),
  cpu_time: z.number().nullish(),
  idle_wake_ups: z.number().nullish(),
  name: z.string().nullish(),
  pid: z.number().nullish(),
  ppid: z.number().nullish(),
  real_memory_size: z.number().nullish(),
  state: z.string().nullish(),
  threads: z.number().nullish(),
  user: z.string().nullish(),
});

const preferencesMeta = z.object({
  username: z.string().nullish(),
  domain: z.string().nullish(),
  plist_path: z.string().nullish(),
  current_host: z.boolean().nullish(),
  preferences: z.any().nullish(),
  code: z.number().nullish(),
  details: z.string().nullish(),
});

const processMetaWithChildren = processMeta.extend({
  child_processes: z.array(processMeta).nullable(),
});

export const pulseProcessCheckDeviceSchema = pulseCheckDeviceMeta.extend({
  results: processMeta
    .extend({
      child_processes: z.array(processMetaWithChildren).nullable(),
    })
    .nullable(),
});

export const pulsePreferencesCheckDeviceSchema = pulseCheckDeviceMeta.extend({
  results: preferencesMeta.nullable(),
});

export const patchPulseNameAndDescriptionRequestSchema = z.object({
  pulse_check_id: z.string(),
  name: z.string().nullable(),
  description: z.string().nullish(),
});

export const pulseCheckResponseSchema = z.object({
  data: z.array(pulseCheckJobSchema),
  limit: z.number().nullable(),
  offset: z.number().nullable(),
  total: z.number().nullable(),
});

export const pulseCheckDetailsResponseSchema = z.object({
  data: z.array(pulseCheckJobSchema),
  limit: z.number().nullable(),
  offset: z.number().nullable(),
  total: z.number().nullable(),
});

export const pulseFileCheckDeviceResponseSchema = z.object({
  data: z.array(pulseFileCheckDeviceSchema),
  limit: z.number().nullable(),
  offset: z.number().nullable(),
  total: z.number().nullable(),
});

export const pulseProcessCheckDeviceResponseSchema = z.object({
  data: z.array(pulseProcessCheckDeviceSchema),
  limit: z.number().nullable(),
  offset: z.number().nullable(),
  total: z.number().nullable(),
});

export const pulseLoggedInUsersDeviceResponseSchema = z.object({
  data: z.array(pulseLoggedInUsersDeviceSchema),
  limit: z.number().nullable(),
  offset: z.number().nullable(),
  total: z.number().nullable(),
});

export const pulsePreferencesDeviceResponseSchema = z.object({
  data: z.array(pulsePreferencesCheckDeviceSchema),
  limit: z.number().nullable(),
  offset: z.number().nullable(),
  total: z.number().nullable(),
});

export const pulseExportRequestSchema = z.object({
  pulse_job_id: z.string(),
  with_results_only: z.boolean().nullish(),
  report_name: z.string().nullish(),
});

export const pulseExportResponseSchema = z.object({
  id: z.string(),
  status: z.enum(['pending', 'success', 'failed']),
  pulse_job_id: z.string(),
  args: z.any(),
  error_msg: z.string().nullish(),
  path: z.string().nullish(),
  signed_url: z.string().nullish(),
  created_at: z.string().nullish(),
  updated_at: z.string().nullish(),
});
