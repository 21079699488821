// istanbul ignore file
import { useCallback, useContext, useState } from 'react';
import { AccountContext } from 'src/contexts/account';

const STORAGE_KEY = 'kandji_onboarding_dialog_dismissed';

export const useDismissOnboardingDialog = () => {
  const { currentCompany } = useContext(AccountContext);
  const [isDismissed, setIsDismissed] = useState(() => {
    return localStorage.getItem(STORAGE_KEY) === 'true';
  });

  const dismissDialog = useCallback(() => {
    localStorage.setItem(STORAGE_KEY, 'true');
    if (process.env.NODE_ENV !== 'test') {
      window?.pendo?.track('Trial Dashboard - Onboarding dialog dismissed', {
        tenantId: currentCompany?.id,
      });
    }
    setIsDismissed(true);
  }, [currentCompany]);

  return {
    isDismissed,
    dismissDialog,
  };
};
