import {
  Checkbox,
  TextInput,
  onEmptyBlurValidator,
  urlValidator,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import React from 'react';
import { i18n } from 'src/i18n';

import { Setting } from 'features/library-items/template';

import WifiService from '../../service/wifi-service';

const keys = WifiService.keys.proxy.automatic;

const fieldsToValidate = [keys.proxyPacUrl];

const Automatic = ({ update, setting, isDisabled, validationDep }) => {
  const { refs, onInvalidate } = useInputsValidators(fieldsToValidate, update);
  useRemoveValidationOnUnmount(fieldsToValidate, update);
  return (
    <Setting.SecondaryControls
      testId="proxy-type-automatic"
      className="k-ade-secondary-ctrl-grid-gap-align"
    >
      <Setting.SecondaryControlsRow>
        <div>
          <p className="b-txt">{i18n.t('Proxy PAC URL')}</p>
        </div>
        <div>
          <p className="b-txt-light" ref={refs[keys.proxyPacUrl]}>
            {i18n.t('Full URL to the Proxy Auto-Configuration (PAC) file.')}
          </p>
        </div>
        <div>
          <TextInput
            disabled={isDisabled}
            value={setting[keys.proxyPacUrl]}
            onChange={(e) => update(keys.proxyPacUrl, e.target.value)}
            compact
            validator={(v) => [
              onEmptyBlurValidator(v, {
                trigger: ['onBlur', validationDep],
              }),
              urlValidator(v, {
                trigger: ['onBlur', validationDep],
              }),
            ]}
            onInvalidate={onInvalidate(keys.proxyPacUrl)}
            removeValidationOnUnmount
            placeholder="http://pac.accuhive.io:8082/proxy.pac"
            data-testid="proxy-automatic-pacurl"
          />
        </div>
      </Setting.SecondaryControlsRow>
      <Setting.SecondaryControlsRow>
        <Setting.Controls>
          <Checkbox
            checked={setting[keys.allowProxyPacFallback]}
            onChange={() => update(keys.allowProxyPacFallback, (p) => !p)}
            disabled={isDisabled}
            label={i18n.t('Proxy PAC fallback allowed')}
            testId="proxy-pac-fallback"
          />
        </Setting.Controls>
        <Setting.Helpers>
          <p className="b-txt-light">
            {i18n.t(
              'Allow connecting directly to the destination if the PAC file is unreachable.',
            )}
          </p>
        </Setting.Helpers>
      </Setting.SecondaryControlsRow>
    </Setting.SecondaryControls>
  );
};

export default Automatic;
