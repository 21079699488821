import './hover-tippy.css';

import Tippy from '@tippyjs/react';
import cn from 'classnames';
import React from 'react';
import { ReactSVG } from 'react-svg';

const HoverTippy = (props) => {
  const {
    text,
    icon,
    children = null,
    className = '',
    maxWidth = 350,
    disabled = false,
    placement = 'top',
  } = props;

  return (
    <Tippy
      maxWidth={maxWidth}
      content={<div className="b-txt">{text}</div>}
      theme="tippy"
      placement={placement}
      disabled={disabled}
    >
      <div className={cn('b-flex', className)}>
        {icon ? (
          <ReactSVG wrapper="span" className="tippy-svg" src={icon} />
        ) : (
          children
        )}
      </div>
    </Tippy>
  );
};

export default HoverTippy;
