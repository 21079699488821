import React from 'react';

function OpenOut() {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8125 9.5H10.9375C10.6914 9.5 10.5 9.71875 10.5 9.9375V13H1.75V4.25H5.6875C5.90625 4.25 6.125 4.05859 6.125 3.8125V2.9375C6.125 2.71875 5.90625 2.5 5.6875 2.5H1.3125C0.574219 2.5 0 3.10156 0 3.8125V13.4375C0 14.1758 0.574219 14.75 1.3125 14.75H10.9375C11.6484 14.75 12.25 14.1758 12.25 13.4375V9.9375C12.25 9.71875 12.0312 9.5 11.8125 9.5ZM12.9609 0.75H8.64062C8.20312 0.75 7.875 1.10547 7.875 1.51562V2.03516C7.875 2.44531 8.20312 2.77344 8.61328 2.77344C8.64062 2.77344 8.64062 2.77344 8.64062 2.77344L10.5 2.71875L3.69141 9.5C3.55469 9.60938 3.47266 9.77344 3.47266 9.96484C3.47266 10.1289 3.55469 10.293 3.69141 10.4297L4.32031 11.0586H4.34766C4.45703 11.1953 4.62109 11.25 4.78516 11.25C4.97656 11.25 5.14062 11.1953 5.25 11.0586L12.0312 4.25L11.9766 6.10938C11.9766 6.54688 12.3047 6.875 12.7422 6.875H13.2344C13.6445 6.875 14 6.54688 14 6.10938V1.78906C14 1.21484 13.5352 0.75 12.9609 0.75Z"
        fill="#0057DA"
      />
    </svg>
  );
}

export default OpenOut;
