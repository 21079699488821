export * from './auth0';

export const ignoredDomains = ['edu'];

export const DEMO_KEY_NAME = 'demoMode';

export const hasSubdomainMatch = (strings = []) =>
  strings.reduce(
    (acc, str) => (window.location.hostname.split('.')[0] === str ? true : acc),
    false,
  );
