import React from 'react';
import NewModal from './shared/NewModal';
import OldModal from './shared/OldModal';

export class ModalWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
    this.onHide = this.onHide.bind(this);
    this.onChange = this.onChange.bind(this);
    this.isDuplicatedName = this.isDuplicatedName.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
  }

  showCloseButton = false;

  isWide = false;

  centerHeader = false;

  trimWhitespaces = (str) => str.trim().replace(/\s+/g, ' ');

  isDuplicatedName = (blueprints, blueprintName) =>
    !!blueprints.find(
      (el) => el.name.toLowerCase() === blueprintName.toLowerCase(),
    );

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onHide = () => {
    const { onHide } = this.props;
    onHide();
  };

  renderHeader() {
    return null;
  }

  renderBody() {
    return null;
  }

  renderFooter() {
    return null;
  }

  render() {
    return this.props.isNewModal ? (
      <NewModal
        header={this.renderHeader()}
        body={this.renderBody()}
        footer={this.renderFooter()}
        isOpen={this.props.isOpen}
        isOverflowYVisible={this.props.isOverflowYVisible}
        onHide={this.onHide}
      />
    ) : (
      <OldModal
        header={this.renderHeader()}
        body={this.renderBody()}
        footer={this.renderFooter()}
        isLoading={this.state.isLoading}
        modalName={this.modalName}
        isWide={this.isWide}
        showCloseButton={this.showCloseButton}
        centerHeader={this.centerHeader}
        onHide={this.onHide}
      />
    );
  }
}
