import { Button, Checkbox, Chip, Flex } from '@kandji-inc/bumblebee';
import PropTypes from 'prop-types';
import React, { useState, useEffect, memo } from 'react';

import { Setting } from 'features/library-items/template';

import { i18n } from 'src/i18n';
import FirewallService from '../../service/firewall-service';

const ManagePf = ({ update, isDisabled, settings, selectAll, deselectAll }) => {
  function getAmountChecked() {
    return FirewallService.getManagePfOptions().filter(
      ({ serverField }) => settings[serverField],
    ).length;
  }

  const [amountChecked, setAmountChecked] = useState(getAmountChecked());

  useEffect(() => {
    setAmountChecked(getAmountChecked());
  }, [settings]);

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('Manage PF Firewall')}</h3>
        <Setting.HeaderToggle
          isEnabled={settings.is_managing_pf_firewall}
          onChange={() => update('is_managing_pf_firewall', (p) => !p)}
          chipText={{ enabled: i18n.t('On'), disabled: i18n.t('Off') }}
          isDisabled={isDisabled}
        />
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          {i18n.t(`macOS includes a highly configurable packet filter firewall for
          blocking network traffic by port or protocol. It is not active by
          default, and a user interface is not included. Kandji can
          automatically enable and configure the pf firewall using presets based
          on common ports and services, including all those under NIST
          specification.`)}
        </p>
        <Flex className="b-mt1">
          <Chip kind="tertiary-light" text={i18n.t('macOS 10.11+')} />
          <Chip kind="tertiary-light" text={i18n.t('NIST')} />
        </Flex>
      </Setting.SubHeader>
      {settings.is_managing_pf_firewall && (
        <Setting.Rows>
          <div>
            <Setting.Title className="b-mb2 b-flex-vc b-flex1">
              <p className="b-txt b-mr">
                {i18n.t(
                  'All services below are blocked by default. Select any services you want to allow.',
                )}
              </p>
              <div className="firewall__manage-pf-buttons">
                {amountChecked <
                  FirewallService.getManagePfOptions().length && (
                  <Button disabled={isDisabled} kind="link" onClick={selectAll}>
                    {i18n.t('Select All')}
                  </Button>
                )}
                {amountChecked > 1 &&
                  amountChecked < FirewallService.getManagePfOptions().length &&
                  ' / '}
                {amountChecked > 1 && (
                  <Button
                    disabled={isDisabled}
                    kind="link"
                    onClick={deselectAll}
                  >
                    {i18n.t('Deselect All')}
                  </Button>
                )}
              </div>
            </Setting.Title>
            <Setting.Controls>
              <div className="firewall__checkbox-grid">
                {FirewallService.getManagePfOptions().map((option) => (
                  <Checkbox
                    key={option.serverField}
                    disabled={isDisabled}
                    label={option.label}
                    id={`${option.serverField}_checkbox`}
                    testId={`${option.serverField}_checkbox`}
                    checked={settings[option.serverField]}
                    onChange={() =>
                      update(option.serverField, (isChecked) => !isChecked)
                    }
                  />
                ))}
              </div>
            </Setting.Controls>
          </div>
        </Setting.Rows>
      )}
    </Setting.Card>
  );
};

ManagePf.propTypes = {
  update: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  settings: PropTypes.object.isRequired,
  selectAll: PropTypes.func.isRequired,
  deselectAll: PropTypes.func.isRequired,
};

export default memo(ManagePf);
