import { useEffect, useState } from 'react';
/* istanbul ignore file */
const FC_FRAME_ID = '#fc_frame';

/**
 * This hook is a utility hook for moving the chat bubble out of the way. This
 * is primarily for the when the sidebar is open. It moves the chat bubble above
 * the 'Done' button.
 */

interface Margins {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

const useAdjustSidebarChatBubble = (
  props = { bottom: 64, left: 0, right: 0, top: 0 } as Margins,
) => {
  const { bottom, left, right, top } = props;

  const [chatBubble, setChatBubble] = useState<HTMLElement | null>(null);
  new MutationObserver((_, observer) => {
    const chatBubble = document?.querySelector(FC_FRAME_ID);
    if (chatBubble) {
      setChatBubble(chatBubble as HTMLElement);
      observer.disconnect();
    }
  }).observe(document.documentElement, {
    childList: true,
    subtree: true,
  });

  useEffect(() => {
    chatBubble?.style.setProperty('margin-right', `${right}px`);
    chatBubble?.style.setProperty('margin-left', `${left}px`);
    chatBubble?.style.setProperty('margin-bottom', `${bottom}px`);
    chatBubble?.style.setProperty('margin-top', `${top}px`);

    return () => {
      chatBubble?.style.removeProperty('margin-right');
      chatBubble?.style.removeProperty('margin-left');
      chatBubble?.style.removeProperty('margin-bottom');
      chatBubble?.style.removeProperty('margin-top');
    };
  }, [bottom, left, right, top, chatBubble]);
};

export default useAdjustSidebarChatBubble;
