import PropTypes from 'prop-types';
import React from 'react';

const SelectRowHeaderColumn = (props) => {
  const { rowCount, children } = props;
  return (
    <th
      rowSpan={rowCount}
      style={{ textAlign: 'center' }}
      data-is-only-head={false}
    >
      {children}
    </th>
  );
};

SelectRowHeaderColumn.propTypes = {
  children: PropTypes.node,
  rowCount: PropTypes.number,
};

export default SelectRowHeaderColumn;
