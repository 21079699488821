import {
  getRunsOnFromModel,
  getSelectedDevicesFromRunsOn,
} from 'src/features/library-items/data-service/library-item/devices';
import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import configs from '../../../library/library-item-configurations/items/profile';
import AppLockService from './app-lock-service';

const commonTransformationFromApi = (data) => {
  const result = {
    appIdentifier: {
      value: data.Identifier,
    },
    appOptions: {},
    userOptions: {},
  };

  Object.entries(AppLockService.getAppOptionsMap()).forEach(
    ([clientField, details]) => {
      result.appOptions[clientField] = AppLockService.getAppOptionsMap()[
        clientField
      ].needsInvert
        ? !data.Options[details.serverField]
        : data.Options[details.serverField];
    },
  );

  Object.entries(AppLockService.getUserOptionsMap()).forEach(
    ([clientField, details]) => {
      result.userOptions[clientField] =
        data.UserEnabledOptions[details.serverField];
    },
  );

  return result;
};

async function transformFromNewApi(apiData, preset) {
  const result = {
    ...preset,
    isActive: apiData.active,
    rules: apiData.rules,
    ...commonTransformationFromApi(apiData.data),
  };

  return result;
}

async function transformFromApi(apiData) {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const commonData = {
    id: apiData.id,
    name: apiData.name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
    devices: getSelectedDevicesFromRunsOn(apiData),
  };

  const transformer = transformFromNewApi;

  const result = await transformer(apiData, commonData);

  return {
    ...apiData,
    data: result,
  };
}

function transformToApi(model) {
  const toSend = {
    blueprints: model.selectedBlueprints.map(({ value }) => value),
    is_all_blueprints: model.isAllBlueprints,
    name: model.name,
    active: model.isActive,
    rules: model.rules || null,
  };

  // Populate `runs_on_*` fields
  const runsOn = getRunsOnFromModel(model);
  Object.keys(runsOn).forEach((runsOnKey) => {
    toSend[runsOnKey] = runsOn[runsOnKey];
  });

  toSend.data = {
    Identifier: model.appIdentifier.value,
    Options: {},
    UserEnabledOptions: {},
  };

  Object.entries(AppLockService.getAppOptionsMap()).forEach(
    ([clientField, details]) => {
      toSend.data.Options[details.serverField] =
        AppLockService.getAppOptionsMap()[clientField].needsInvert
          ? !model.appOptions[clientField]
          : model.appOptions[clientField];
    },
  );

  Object.entries(AppLockService.getUserOptionsMap()).forEach(
    ([clientField, details]) => {
      toSend.data.UserEnabledOptions[details.serverField] =
        model.userOptions[clientField];
    },
  );

  if (!model.id) {
    const itemConfig = configs['App Lock'];

    toSend.type = itemConfig.type;
    toSend.identifier = itemConfig.identifier;
  }

  if (model.skip_blueprint_conflict) {
    toSend.skip_blueprint_conflict = model.skip_blueprint_conflict;
  }

  return toSend;
}

export { transformToApi, transformFromApi };
