import type {
  ColumnFiltersState,
  ColumnOrderState,
  ColumnSizingInfoState,
  ColumnSizingState,
  VisibilityState,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { getColumnDefinitionDefaults } from '../utils/tableUtils';

export const useTableState = (columnDefs: any, viewColumns) => {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnSizing, setColumnSizing] = useState<ColumnSizingState>({});
  const [columnSizingInfo, setColumnSizingInfo] =
    useState<ColumnSizingInfoState>({
      startOffset: null,
      startSize: null,
      deltaOffset: null,
      deltaPercentage: null,
      isResizingColumn: false,
      columnSizingStart: [],
    });
  const [isDefaultDirty, setIsDefaultDirty] = useState<boolean>(false);

  const defaults = useMemo(
    () => getColumnDefinitionDefaults(columnDefs, viewColumns),
    [columnDefs, viewColumns],
  );

  useEffect(() => {
    setColumnOrder(defaults.columnOrder);
    setColumnVisibility(defaults.columnVisibility);
    setColumnSizing(defaults.columnSizing);
  }, [
    columnDefs,
    defaults.columnOrder,
    defaults.columnSizing,
    defaults.columnVisibility,
  ]);

  const isDefault = (current: any, defaultState: any) => {
    if (Array.isArray(current)) {
      return current.every((item, idx) => defaultState[idx] === item);
    }
    return Object.entries(defaultState).every(
      ([key, value]) => current[key] === value,
    );
  };

  useEffect(() => {
    // this prevents the Reset table button from flashing when the table is already in the default state
    if (
      Object.keys(columnVisibility).length !==
      Object.keys(defaults.columnVisibility).length
    ) {
      setIsDefaultDirty(false);
      return;
    }

    const isDefaultOrder = isDefault(columnOrder, defaults.columnOrder);
    const isDefaultVisibility = isDefault(
      columnVisibility,
      defaults.columnVisibility,
    );
    const isDefaultSizing = isDefault(columnSizing, defaults.columnSizing);

    setIsDefaultDirty(
      !(isDefaultOrder && isDefaultVisibility && isDefaultSizing),
    );
  }, [
    columnOrder,
    columnVisibility,
    columnSizing,
    columnDefs,
    defaults.columnOrder,
    defaults.columnVisibility,
    defaults.columnSizing,
  ]);

  return {
    columnFilters,
    setColumnFilters,
    columnVisibility,
    setColumnVisibility,
    columnOrder,
    setColumnOrder,
    columnSizing,
    isDefaultDirty,
    setIsDefaultDirty,
    setColumnSizing,
    columnSizingInfo,
    setColumnSizingInfo,
  };
};
