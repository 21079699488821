import React from 'react';

import { func, string } from 'prop-types';

import { Select as BumblebeeSelect } from '@kandji-inc/bumblebee';

const Select = ({ fieldKey, updateField, ariaLabel, ...rest }) => {
  const onChange = ({ value }) => updateField(fieldKey, value);

  return (
    <BumblebeeSelect {...rest} onChange={onChange} aria-label={ariaLabel} />
  );
};

Select.propTypes = {
  fieldKey: string.isRequired,
  updateField: func.isRequired,
  ariaLabel: string.isRequired,
};

export default Select;
