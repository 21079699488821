import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import {
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  extractFilterableColumns,
  getFilteredDisabled as filterDisabled,
  getCodeSnippetCell,
} from '../column-utils';
import { getGlobalColumns } from './globalColumns';

export const getInstalledProfilesColumns = (schemas) => {
  const globalSchema = schemas.find((schema) => schema.uri === 'globals');
  const installedProfilesSchema = schemas.find(
    (schema) => schema.uri === 'installed_profiles',
  );

  if (!installedProfilesSchema || !globalSchema) {
    return { columnDefs: [], filterableColumns: [] };
  }

  const combinedProperties = {
    ...globalSchema.schema.properties,
    ...installedProfilesSchema.schema.properties,
  };

  const installedProfilesColumnHelper =
    createColumnHelper<z.infer<typeof combinedProperties>>();

  const globalColumns = getGlobalColumns(
    installedProfilesColumnHelper,
    combinedProperties,
  );
  const columnDefs = [
    ...globalColumns.startColumnDefs,

    installedProfilesColumnHelper.accessor((row) => row.profile_display_name, {
      id: 'profile_display_name',
      meta: {
        displayName: combinedProperties.profile_display_name.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    installedProfilesColumnHelper.accessor((row) => row.payload_identifier, {
      id: 'payload_identifier',
      meta: {
        displayName: combinedProperties.payload_identifier.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    installedProfilesColumnHelper.accessor((row) => row.managed, {
      id: 'managed',
      cell: YesNoCell,
      meta: {
        displayName: combinedProperties.managed.title,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    installedProfilesColumnHelper.accessor((row) => row.organization, {
      id: 'organization',
      meta: {
        displayName: combinedProperties.organization.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    installedProfilesColumnHelper.accessor((row) => row.profile_data, {
      id: 'profile_data',
      cell: (info) => {
        const cellValue = info.getValue();
        const profileDataJSON = JSON.stringify(cellValue, null, 2);
        return getCodeSnippetCell(info, profileDataJSON);
      },
      meta: {
        displayName: combinedProperties.profile_data.title,
      },
    }),

    installedProfilesColumnHelper.accessor((row) => row.payload_uuid, {
      id: 'payload_uuid',
      meta: {
        displayName: combinedProperties.payload_uuid.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
        defaultHidden: true,
      },
    }),

    installedProfilesColumnHelper.accessor((row) => row.removal_passcode, {
      id: 'removal_passcode',
      cell: YesNoCell,
      meta: {
        displayName: combinedProperties.removal_passcode.title,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
        defaultHidden: true,
      },
    }),

    installedProfilesColumnHelper.accessor((row) => row.encrypted, {
      id: 'encrypted',
      cell: YesNoCell,
      meta: {
        displayName: combinedProperties.encrypted.title,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
        defaultHidden: true,
      },
    }),

    installedProfilesColumnHelper.accessor((row) => row.profile_description, {
      id: 'profile_description',
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.profile_description.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    installedProfilesColumnHelper.accessor((row) => row.removable, {
      id: 'removable',
      cell: YesNoCell,
      meta: {
        displayName: combinedProperties.removable.title,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
        defaultHidden: true,
      },
    }),

    installedProfilesColumnHelper.accessor((row) => row.signed, {
      id: 'signed',
      cell: YesNoCell,
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.signed.title,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    ...globalColumns.endColumnDefs,
  ];

  const filterableColumns = extractFilterableColumns(columnDefs);

  return { columnDefs, filterableColumns };
};
