export default {
  id: null,
  name: '',
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  isActive: true,
  volumeMounts: {
    isManaged: false,
    userAccess: 'read-write',
    appliesTo: 'all',
    requireEncryption: false,
    requireAdminPassword: false,
    deviceAlerts: false,
  },
  diskImages: {
    isManaged: false,
    userAccess: 'read-write',
    appliesTo: 'all',
    requireAdminPassword: false,
    deviceAlerts: false,
  },
  networkMounts: {
    isManaged: false,
    userAccess: 'read-write',
    appliesTo: 'all',
    requireAdminPassword: false,
    deviceAlerts: false,
  },
};
