import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import {
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  extractFilterableColumns,
  getFilteredDisabled as filterDisabled,
  getCodeSnippetCell,
} from '../column-utils';
import { getGlobalColumns } from './globalColumns';

export const getSystemExtensionsColumns = (schemas) => {
  const globalSchema = schemas.find((schema) => schema.uri === 'globals');
  const systemExtensionsSchema = schemas.find(
    (schema) => schema.uri === 'system_extensions',
  );

  if (!systemExtensionsSchema || !globalSchema) {
    return { columnDefs: [], filterableColumns: [] };
  }

  const combinedProperties = {
    ...globalSchema.schema.properties,
    ...systemExtensionsSchema.schema.properties,
  };

  const systemExtensionsColumnHelper =
    createColumnHelper<z.infer<typeof combinedProperties>>();

  const globalColumns = getGlobalColumns(
    systemExtensionsColumnHelper,
    combinedProperties,
  );

  const columnDefs = [
    ...globalColumns.startColumnDefs,

    systemExtensionsColumnHelper.accessor((row) => row.name, {
      id: 'name',
      meta: {
        displayName: combinedProperties.name.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    systemExtensionsColumnHelper.accessor((row) => row.identifier, {
      id: 'identifier',
      meta: {
        displayName: combinedProperties.identifier.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    systemExtensionsColumnHelper.accessor((row) => row.short_version, {
      id: 'short_version',
      meta: {
        displayName: combinedProperties.short_version.title,
        filterType: 'version',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    systemExtensionsColumnHelper.accessor((row) => row.is_mdm_managed, {
      id: 'is_mdm_managed',
      cell: YesNoCell,
      meta: {
        displayName: combinedProperties.is_mdm_managed.title,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    systemExtensionsColumnHelper.accessor((row) => row.state, {
      id: 'state',
      meta: {
        displayName: combinedProperties.state.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    systemExtensionsColumnHelper.accessor((row) => row.path, {
      id: 'path',
      cell: getCodeSnippetCell,
      meta: {
        displayName: combinedProperties.path.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    systemExtensionsColumnHelper.accessor((row) => row.bundle_version, {
      id: 'bundle_version',
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.bundle_version.title,
        filterType: 'version',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    systemExtensionsColumnHelper.accessor((row) => row.categories, {
      id: 'categories',
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.categories.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    systemExtensionsColumnHelper.accessor((row) => row.bundle_path, {
      id: 'bundle_path',
      cell: getCodeSnippetCell,
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.bundle_path.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    systemExtensionsColumnHelper.accessor((row) => row.team_id, {
      id: 'team_id',
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.team_id.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    ...globalColumns.endColumnDefs,
  ];

  const filterableColumns = extractFilterableColumns(columnDefs);

  return { columnDefs, filterableColumns };
};
