/* istanbul ignore file -- only for developer experience */
import { EnvironmentContext } from 'contexts/environment';
import React, { createRef, useContext } from 'react';
import { slugify } from 'theme/function';

const FastRefreshSpinner = (
  <svg
    className="environment__fast-refresh-loading-icon environment__fast-refresh-loading-icon--animate-spin"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="environment__fast-refresh-loading-icon-circle-bg"
      cx="12"
      cy="12"
      r="10"
      strokeWidth="3"
    />
    <path
      className="environment__fast-refresh-loading-icon-circle-fill"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    />
  </svg>
);

const fastRefreshRef = createRef();

const Environment = () => {
  const { environment, showEnvironment, isEnableFastRefresh } =
    useContext(EnvironmentContext);
  return showEnvironment ? (
    <aside
      className={`environment environment-${slugify(
        environment,
      )} d-flex align-items-center justify-content-center`}
    >
      {isEnableFastRefresh ? (
        <>
          <strong className="environment__fast-refresh-icon-container">
            <span className="environment__fast-refresh-env-char">
              {environment.charAt(0)}
            </span>
            <span className="environment__fast-refresh-icon">⚡️</span>
            <span ref={fastRefreshRef} className="environment__fast-refresh">
              {FastRefreshSpinner}
            </span>
          </strong>
        </>
      ) : (
        <strong>{environment.charAt(0)}</strong>
      )}
    </aside>
  ) : null;
};

if (module.hot) {
  module.hot.addStatusHandler((status) => {
    if (fastRefreshRef.current) {
      switch (status) {
        case 'check':
          return fastRefreshRef.current.classList.add(
            'environment__fast-refresh--loading',
          );
        case 'idle':
          return fastRefreshRef.current.classList.remove(
            'environment__fast-refresh--loading',
          );
        default:
          return fastRefreshRef.current.classList.remove(
            'environment__fast-refresh--loading',
          );
      }
    }

    return 'noop';
  });
}
export default Environment;
