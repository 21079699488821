import { newLibraryItemService } from 'features/library-items/data-service/library-item/new-library-item-service';
import { useCallback, useEffect, useState } from 'react';
import { apiTypes } from 'src/features/library-items/library/common';
import WifiService from '../service/wifi-service';
import { appsTypes } from './fast-lane-card.model';

const keys = WifiService.keys.fastlane;

const libraryAppsTypes = ['vpp-app'];

export const useGetApps = () => {
  const [apps, setApps] = useState([]);
  const [appsLoaded, setAppsLoaded] = useState(false);
  useEffect(() => {
    let cancelled = false;
    newLibraryItemService
      .list({
        include_data: false,
        limit: 1e6,
        type__in: apiTypes.VPP_APP,
      })
      .then((r) => r.data.results)
      .then((items) => {
        const filteredApps = items.filter((item) =>
          libraryAppsTypes.includes(item.type),
        );
        if (!cancelled) {
          setApps(filteredApps);
          setAppsLoaded(true);
        }
      });
    return () => {
      cancelled = true;
    };
  }, []);
  return [apps || [], appsLoaded];
};

export const useCustomAppsActions = ({ update, setting, apps }) => {
  const selectedApps = setting[keys.addedApps] || [];
  const addApp = useCallback(
    (name, bundleId) => {
      update(keys.addedApps, (p = []) => [
        ...p,
        { type: appsTypes.CUSTOM, name, bundleId },
      ]);
    },
    [update],
  );

  const removeApp = useCallback(
    (bundleId) =>
      update(keys.addedApps, (p = []) =>
        p.filter((app) => app?.bundleId !== bundleId),
      ),
    [update],
  );

  const isBundleIdValid = useCallback(
    (bundleId) =>
      !!selectedApps.find((app) => {
        if (app.type === appsTypes.VPP) {
          const foundApp = apps.find((i) => app.id === i.id);
          return foundApp && foundApp.data.bundle_id === bundleId;
        }
        if (app.type === appsTypes.CUSTOM) {
          return app.bundleId === bundleId;
        }
        // in case if something went wrong
        return false;
      }),
    [apps, selectedApps],
  );

  return {
    addApp,
    removeApp,
    isBundleIdValid,
  };
};

export const useSearchedAppsActions = ({ update, setting }) => {
  const addApp = useCallback(
    (id) =>
      update(keys.addedApps, (p = []) => [...p, { type: appsTypes.VPP, id }]),
    [update],
  );

  const removeApp = useCallback(
    (id) =>
      update(keys.addedApps, (p = []) => p.filter((item) => item?.id !== id)),
    [update],
  );

  const isAppAdded = useCallback(
    (id) => {
      const apps = setting[keys.addedApps] || [];
      return !!apps.find((app) => app?.id === id);
    },
    [setting],
  );

  const toggleApp = useCallback(
    (id) => {
      if (!isAppAdded(id)) {
        return addApp(id);
      }
      return removeApp(id);
    },
    [isAppAdded, addApp, removeApp],
  );

  return {
    addApp,
    removeApp,
    isAppAdded,
    toggleApp,
  };
};
