import { useEffect } from 'react';

const useOutsideClick = (ref, cb) => {
  useEffect(() => {
    const fn = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        cb(e);
      }
    };
    document.addEventListener('mousedown', fn);
    return () => {
      document.removeEventListener('mousedown', fn);
    };
  });
};

export default useOutsideClick;
