/* istanbul ignore file */
import { Button } from '@kandji-inc/nectar-ui';

function createTextFile(jsonData: any) {
  const fileData = JSON.stringify(jsonData, null, 2);
  const blob = new Blob([fileData], { type: 'text/plain' });
  return blob;
}

function downloadFile(blob, filename) {
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = filename;
  link.href = url;
  link.click();
  URL.revokeObjectURL(url);
}

export const JsonDownloadButton = ({
  data,
  label = 'Download',
}: { data: any; label?: string }) => {
  const handleDownload = () => {
    const blob = createTextFile(data);
    downloadFile(blob, 'data.txt');
  };

  return (
    <Button
      compact
      variant="subtle"
      icon={{ name: 'arrow-down-to-line' }}
      css={{ background: 'transparent' }}
      onClick={handleDownload}
    >
      {label}
    </Button>
  );
};
