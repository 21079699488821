import { Icon } from '@kandji-inc/bumblebee';
import { theme } from 'app/common/constants';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import styled from 'styled-components';
import SearchString from './SearchString';

const enhanceWithClickOutside = require('react-click-outside');
const classNames = require('classnames');

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const inputTopStyle = `
  border-radius: 0 0 5px 5px;
  border-top: none;
`;

const inputBottomStyle = `
  border-radius: 5px 5px 0 0;
  border-bottom: none;
`;

const Input = styled.section`
  display: flex;
  align-items: center;
  height: 100%;
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.8em;
  z-index: ${(props) => (props.opened ? 9999 : 1)};
  color: ${(props) =>
    props.opened
      ? props.theme.colors['grey-300']
      : props.theme.colors['grey-500']};
  background: ${(props) => (props.opened ? '#fff' : 'transparent')};
  border: ${(props) =>
    props.opened
      ? `1px solid ${props.theme.colors['grey-200']}`
      : '1px solid transparent'};
  transition: ${(props) =>
    props.opened
      ? 'border-radius .3s cubic-bezier(0, 1, 0.5, 1)'
      : '.3s .05s cubic-bezier(0, 1, 0.5, 1), border-radius 0s ease'};
  ${(props) =>
    (props.position === 'top left' || props.position === 'top right') &&
    inputTopStyle}
  ${(props) =>
    (props.position === 'bottom left' || props.position === 'bottom right') &&
    inputBottomStyle}
  &:not(.opened) {
    transition: border-radius 0s;
  }
  padding: 0.5em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  cursor: pointer;
  color: ${(props) =>
    props.theme.colors[props.opened ? 'grey-300' : 'grey-500']};
  &:after {
    font-family: 'Font Awesome 5 Pro', sans-serif;
    content: '${(props) => (props.opened ? '\\F0D8' : '\\F0D7')}';
  }
`;

const InputLabel = styled.section`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 10px;
`;

const bodyTopLeftStyle = `
  left: 0;
  bottom: calc(100% - 1px);
  border-radius: 5px 5px 5px 0;
`;

const bodyTopRightStyle = `
  right: 0;
  bottom: calc(100% - 1px);
  border-radius: 5px 5px 0 5px;
`;

const bodyBottomLeftStyle = `
  left: 0;
  top: calc(100% - 1px);
  border-radius: 0 5px 5px 5px;
`;

const bodyBottomRightStyle = `
  right: 0;
  top: calc(100% - 1px);
  border-radius: 5px 0 5px 5px;
`;

const Body = styled.section`
  ${(props) => props.position === 'top left' && bodyTopLeftStyle}
  ${(props) => props.position === 'top right' && bodyTopRightStyle}
  ${(props) => props.position === 'bottom left' && bodyBottomLeftStyle}
  ${(props) => props.position === 'bottom right' && bodyBottomRightStyle}
  z-index: 9998;
  display: flex;
  flex-direction: column;
  position: absolute;
  min-width: 350px;
  max-height: 400px;
  max-width: 420px;
  background: #fff;
  color: ${(props) => props.theme.colors['grey-400']};
  padding-top: 10px;
  padding-bottom: 10px;
  align-self: flex-start;
  border: 1px solid ${(props) => props.theme.colors['grey-200']};
  box-shadow: 0 10px 40px 0 ${(props) => props.theme.colors['grey-300']};
  transition:
    width 0.3s ease,
    max-height 0.4s ease;
  white-space: nowrap;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors['marengo-500']};
    border-radius: 4px;
  }
`;

const OptionIcon = styled.i``;

const OptionIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  margin-right: 0.7rem;
`;

const Option = styled.section`
  display: flex;
  min-height: 1.5rem;
  font-family: 'Atlas Grotesk Web';
  font-size: 0.9rem;
  font-weight: 400;
  color: #999;
  cursor: pointer;
  margin: 10px 20px;
  &:hover {
    color: #000;
  }
  &.active {
    color: #000;
  }
`;

const OptionLabelWrapper = styled.div`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Delimiter = styled.section`
  width: 100%;
  height: 0;
  border-bottom: 1px solid ${(props) => props.theme.colors['grey-200']};
  margin: 10px 0;
`;

const SelectedOptionIcon = styled(Icon)`
  // position: absolute;
  // right: 20px;
  margin-left: 0.7rem;
`;

const SearchInputWrapper = styled.section`
  display: flex;
  justify-content: flex-end;
  min-height: 50px;
  margin: 10px;
  margin-top: 20px;
`;

const DayPickerWrapper = styled.section`
  display: flex;
  flex-flow: column;
  min-height: 1.5rem;
  font-weight: 700;
  margin: 10px 20px;
`;

export class AwesomeMultiSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      selected: props.selected,
      filterString: '',
      showCalendar: false,
      from: undefined,
      to: undefined,
    };
    this.handleDayClick = this.handleDayClick.bind(this);
  }

  handleDayClick(day) {
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);
  }

  toggle = () => {
    const { multiple, onChange, searchable } = this.props;
    const { opened, selected } = this.state;
    this.setState((prevState) => ({ opened: !prevState.opened }));
    if (opened) {
      if (multiple) {
        onChange(selected);
      }
      if (searchable) {
        this.setState({ filterString: '' });
      }
    }
  };

  select = (value) => {
    const { options, multiple, onChange, defaultValue } = this.props;
    const optionsExcludeAll = options.filter(
      (option) =>
        option &&
        typeof option === 'object' &&
        option[0] !== 'ALL' &&
        option[0] !== 'ERROR,WARNING,MUTE',
    );
    const allOptionValue = options.find(
      (option) =>
        option &&
        typeof option === 'object' &&
        (option[0] === 'ALL' || option[0] === 'ERROR,WARNING,MUTE'),
    );
    const optionsLength = options.filter((option) => !isEmpty(option)).length;
    let result;
    const current = multiple ? this.state.selected : this.props.selected;
    if (multiple) {
      result = current ? [...current] : [];
      const index = result.indexOf(value);
      if (index >= 0) {
        result.splice(index, 1);
      } else {
        if (value === 'ALL' || value === 'ERROR,WARNING,MUTE') {
          // second value same ALL for alerts page
          result = [value];
        } else {
          const allSelected =
            result.indexOf('ALL') >= 0 ||
            result.indexOf('ERROR,WARNING,MUTE') >= 0;
          if (allSelected) {
            result.splice(result.indexOf('ALL'), 1);
            result.splice(result.indexOf('ERROR,WARNING,MUTE'), 1);
            result.push(value);
          } else {
            result.push(value);
          }
        }
        if (result.length === optionsExcludeAll.length) {
          result = [allOptionValue[0]];
        }
      }
      result = result.length ? result : null;
    } else {
      result = value === current ? null : value;
      this.setState(() => {
        if (result && result.toString().indexOf('@') > 0) {
          return { opened: false };
        }
        return {
          opened: false,
          from: undefined,
          to: undefined,
        };
      });
    }
    if (!result) {
      result = defaultValue;
    }
    if (multiple && result.length === optionsLength - 1) {
      result = defaultValue;
    } // '-1' - without 'ALL' option
    if (!multiple) {
      onChange(result);
    }
    if (multiple) {
      this.setState({ selected: result });
    }
  };

  // Not delete
  handleClickOutside = (event) => {
    const { opened } = this.state;
    opened && this.toggle();
  };

  getTitle = () => {
    const { from, to } = this.state;
    const { title, options, multiple, titlePlural, hasCustomRange } =
      this.props;
    let { selected } = this.props;
    if (multiple) {
      selected = this.state.selected;
    }

    let currentSelected = [];
    currentSelected = multiple
      ? selected || currentSelected
      : selected
        ? [selected]
        : [];
    let finalTitle = title;
    const selectedCount = currentSelected.length;
    if (selectedCount === 1) {
      const namesMap = {};
      options.forEach((el) =>
        typeof el === 'object' ? (namesMap[el.value] = el.label) : null,
      );
      finalTitle = namesMap[currentSelected[0]];
      if (hasCustomRange && selected.toString().indexOf('@') > 0) {
        finalTitle = 'Custom Range';
      }
    } else if (selectedCount > 1) {
      finalTitle = `${selectedCount} ${titlePlural} selected`;
    }
    return finalTitle;
  };

  renderCustomDate = () => {
    let { from, to, showCalendar } = this.state;
    const { selected, showCheck } = this.props;

    const current = !selected
      ? []
      : typeof selected === 'object'
        ? selected
        : [selected];

    if (!(from && to) && selected.toString().indexOf('@') > 0) {
      const dateFromSelected = selected.split('@');
      from = new Date(dateFromSelected[0]);
      to = new Date(dateFromSelected[1]);
    }

    const isSelected =
      from && to
        ? current.indexOf(`${from.toISOString()}@${to.toISOString()}`) >= 0
        : false;

    const modifiersStyles = {
      disabled: {
        color: theme.colors['grey-200'],
      },
      today: {
        color: '#000',
        fontWeight: 700,
      },
      selected: {
        backgroundColor: theme.colors['grey-400'],
        color: '#fff',
      },
    };

    return showCalendar ? (
      <DayPickerWrapper key="DayPickerSelector">
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          <button
            className="btn btn-primary"
            style={{ marginRight: '10px' }}
            disabled={!(from && to)}
            onClick={() => {
              if (from && to) {
                this.setState({ showCalendar: false }, () => {
                  from.setHours(0, 0, 0, 0);
                  to.setHours(23, 59, 59, 999);
                  this.select(`${from.toISOString()}@${to.toISOString()}`);
                });
              }
            }}
          >
            Select
          </button>
          <button
            className="btn btn-primary"
            disabled={!(from || to)}
            onClick={() => {
              this.setState({ from: undefined, to: undefined });
            }}
          >
            Reset
          </button>
        </div>
        <DayPicker
          selectedDays={[from, { from, to }]}
          toMonth={new Date()}
          modifiers={{ start: from, end: to, disabled: { after: new Date() } }}
          modifiersStyles={modifiersStyles}
          onDayClick={this.handleDayClick}
        />
      </DayPickerWrapper>
    ) : (
      <Option
        className={classNames({ active: isSelected && showCheck })}
        onClick={() => this.setState({ showCalendar: !showCalendar })}
      >
        <OptionLabelWrapper>
          {from && to
            ? `${from.toLocaleDateString('en-US')} - ${to.toLocaleDateString(
                'en-US',
              )}`
            : 'Custom Range'}
        </OptionLabelWrapper>
        {isSelected && showCheck && <SelectedOptionIcon name="check" />}
      </Option>
    );
  };

  render() {
    const {
      options,
      position,
      multiple,
      searchable,
      titlePlural,
      hasCustomRange,
    } = this.props;
    let { selected } = this.props;
    const { opened, filterString } = this.state;
    if (multiple) {
      selected = this.state.selected;
    }
    const current = !selected
      ? []
      : typeof selected === 'object'
        ? selected
        : [selected];

    return (
      <Wrapper theme={theme} ref={(node) => (this.selectFilter = node)}>
        <Input
          onClick={this.toggle}
          theme={theme}
          opened={opened}
          position={position}
        >
          <InputLabel>{this.getTitle()}</InputLabel>
        </Input>
        {opened && (
          <Body theme={theme} position={position}>
            {searchable && (
              <SearchInputWrapper>
                <SearchString
                  label={`Search ${titlePlural}...`}
                  searchFunc={(value) =>
                    this.setState({ filterString: value.toLowerCase() })
                  }
                  iconPosition="right"
                />
              </SearchInputWrapper>
            )}
            {options.map(
              (item, idx) =>
                !(
                  !isEmpty(item) &&
                  searchable &&
                  filterString &&
                  item.value !== 'ALL' &&
                  item.label.toLowerCase().indexOf(filterString) === -1
                ) &&
                (isEmpty(item) ? (
                  <Delimiter theme={theme} key={`del-${idx}`} />
                ) : (
                  <Option
                    key={item.value}
                    onClick={() => this.select(item.value)}
                    className={classNames({
                      active: current.indexOf(item.value.toString()) > -1,
                      opened,
                    })}
                  >
                    {item.iconClass && (
                      <OptionIconWrapper>
                        <OptionIcon className={item.iconClass} />
                      </OptionIconWrapper>
                    )}
                    <OptionLabelWrapper>{item.label}</OptionLabelWrapper>
                    {current.indexOf(item.value.toString()) > -1 && (
                      <SelectedOptionIcon name="check" />
                    )}
                  </Option>
                )),
            )}
            {hasCustomRange && this.renderCustomDate()}
          </Body>
        )}
      </Wrapper>
    );
  }
}

AwesomeMultiSelect.propTypes = {
  title: PropTypes.string.isRequired,
  titlePlural: PropTypes.string,
  position: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  theme: PropTypes.object,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  selected: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  hasCustomRange: PropTypes.bool,
  searchable: PropTypes.bool,
  isActivityPeriod: PropTypes.bool,
  showCheck: PropTypes.bool,
};

AwesomeMultiSelect.defaultProps = {
  theme,
  titlePlural: '',
  onChange: null,
  multiple: false,
  hasCustomRange: false,
  searchable: false,
  isActivityPeriod: false,
  showCheck: false,
};

export default enhanceWithClickOutside(AwesomeMultiSelect);
