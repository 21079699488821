import { Checkbox, Chip, Select } from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';
import {
  i18nOsRequirements,
  osRequirements,
} from 'src/features/library-items/library/common';
import { i18n } from 'src/i18n';

import {
  ACTIVATION_LOCK_OPTIONS,
  ACTIVATION_LOCK_OPTION_ITEM,
} from '../activation-lock';

const ActivationLockRow = ({ ipad, update, isDisabled }) => (
  <Setting.Row>
    <div>
      <p className="b-txt">{i18n.t('Activation Lock')}</p>
    </div>
    <Setting.Helpers>
      <p className="b-txt-light">
        {i18n.t(`Specify if users should be allowed to enable Activation Lock using Find
        My and a personal Apple Account.`)}{' '}
        &nbsp;
        <a
          href="https://support.kandji.io/support/solutions/articles/72000558685"
          rel="noopener noreferrer"
          target="_blank"
          className="b-alink"
        >
          {i18n.t('Learn More...')}
        </a>
      </p>
      <div className="b-flex-wrap-gtiny">
        <Chip
          kind="tertiary-light"
          text={i18nOsRequirements(osRequirements.IPAD_12_0)}
          className="k-ade-chip--m0"
        />
      </div>
    </Setting.Helpers>
    <div>
      <Select
        isDisabled={isDisabled}
        options={ACTIVATION_LOCK_OPTIONS()}
        compact
        onChange={(v) =>
          update(
            'userActivationLockAllowed',
            v.value === ACTIVATION_LOCK_OPTION_ITEM.ALLOW().value,
          )
        }
        value={
          ipad.userActivationLockAllowed
            ? ACTIVATION_LOCK_OPTION_ITEM.ALLOW()
            : ACTIVATION_LOCK_OPTION_ITEM.DISALLOW()
        }
      />
    </div>
    {!ipad.userActivationLockAllowed && (
      <Setting.SecondaryControls className="k-ade-secondary-ctrl-grid-gap-align">
        <Setting.SecondaryControlsRow>
          <div>
            <Checkbox
              checked={ipad.isEnableOrgActivationLock}
              label={i18n.t('Enable device-based Activation Lock')}
              onChange={() => update('isEnableOrgActivationLock', (p) => !p)}
              isDisabled={isDisabled}
            />
          </div>
          <div>
            <p className="b-txt-light">
              {i18n.t(
                'Automatically enable device-based Activation Lock through Apple Business Manager.',
              )}
            </p>
          </div>
        </Setting.SecondaryControlsRow>
      </Setting.SecondaryControls>
    )}
  </Setting.Row>
);

export default ActivationLockRow;
