import { newc, setClass } from '@kandji-inc/bumblebee';
import { node, object, string } from 'prop-types';
import React from 'react';

import HeaderToggle from './header-toggle';

const Card = (props: any) => newc(props, 'b-library__setting-card');
const Setting = (props: any) => newc(props, 'b-library__setting-card');
const Header = (props: any) => newc(props, 'b-library__setting-card__header');
const SubHeader = (props: any) =>
  newc(props, 'b-library__setting-card__subheader');
const Rows = (props: any) =>
  newc(
    props,
    setClass('b-library__setting-rows', props.noBorder && '--no-border'),
  );

const Row = (props: any) => {
  const { singleCol } = props;
  const k = singleCol
    ? 'b-library__setting-row-single-col'
    : 'b-library__setting-row';
  return newc(props, k);
};

const Helpers = (props: any) => newc(props, 'b-library__setting-helpers');
const Controls = (props: any) => newc(props, 'b-library__setting-controls');
const Title = (props: any) => newc(props, 'b-library__setting-title');
const SecondaryControls = (props: any) =>
  newc(props, 'b-library__setting-secondary-controls');

/* experimental: to make it easier for the consumer, wont need to remember
 * the order of the components or classes
 */
const secondRowClass = (index: any, totalCount: any) => {
  const classNames = {
    '0_3': 'b-library-second-ctrl-row-title --first',
    '0_2': 'b-library-second-ctrl-row-option --first',

    '1_3': 'b-library-second-ctrl-row-helper',
    '1_2': 'b-library-second-ctrl-row-helper --last',

    '2_3': 'b-library-second-ctrl-row-option --last',
    '2_2': 'b-library-second-ctrl-row-helper --last',
  };

  return classNames[`${index}_${totalCount}`];
};

const SecondaryControlsRow = (props: any) => {
  const { children, className, style } = props;
  const childrenArray = React.Children.toArray(children);
  const count = childrenArray.length;

  return (
    <div
      style={style}
      className={setClass(
        'b-library__setting-secondary-controls-row',
        className,
      )}
    >
      {React.Children.map(children, (child, i) =>
        child
          ? React.cloneElement(child, {
              className: setClass(
                secondRowClass(i, count),
                child.props.className,
              ),
            })
          : null,
      )}
    </div>
  );
};

SecondaryControlsRow.propTypes = {
  className: string,
  style: object,
  children: node,
};

SecondaryControlsRow.defaultProps = {
  className: null,
  style: null,
  children: null,
};

Setting.Card = Card;
Setting.Header = Header;
Setting.HeaderToggle = HeaderToggle;
Setting.SubHeader = SubHeader;
Setting.Rows = Rows;
Setting.Row = Row;
Setting.Helpers = Helpers;
Setting.Controls = Controls;
Setting.Title = Title;
Setting.SecondaryControls = SecondaryControls;
Setting.SecondaryControlsRow = SecondaryControlsRow;

export default Setting;
export {
  Card,
  Header,
  HeaderToggle,
  SubHeader,
  Row,
  Rows,
  Helpers,
  Controls,
  Title,
  SecondaryControls,
  SecondaryControlsRow,
};
