const imageToFile = (image, filename) => {
  if (!image) {
    return null;
  }
  const arr = image.split(',');
  // const mime = arr[0].match(/:(.*?);/)[1];
  const mimeMatch = image.split('base64')[0].split('data:')[1];
  if (!mimeMatch) {
    return null;
  }
  const mime = mimeMatch.replace(';', '');
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export default imageToFile;
