import React, { useContext } from 'react';

import { AccountContext } from 'contexts/account';
import { InterfaceContext } from 'contexts/interface';
import { Button } from 'theme/components/molecules/Action';
import { excludeByProperty } from 'theme/filter';
import Dropdown from '../../theme/components/atoms/Dropdown';
import Icon from '../../theme/components/atoms/Icon';
import Modal from '../../theme/components/atoms/Modal';
import Table from '../../theme/components/atoms/Table';
import Avatar from '../../theme/components/molecules/Avatar';

import { Context } from './context';
import iconAuthenticator from './icon-authenticator.png';

export default () => {
  const { list, isWorking, onDelete } = useContext(Context);
  const { formatTimeWithTZ } = useContext(AccountContext);
  const { onModalOpen, onModalClose } = useContext(InterfaceContext);

  const data = excludeByProperty(list, 'type', 'recovery-code');

  const formatterFactor = (cell, row) => {
    const field = row.id;

    return (
      <div className={`vendor vendor-${field}`}>
        <Avatar type="logo" title="TOTP Device" image={iconAuthenticator} />
        <span className="label">TOTP Device</span>
      </div>
    );
  };
  const formatterCreatedAt = (cell, row) =>
    row.created_at !== null ? formatTimeWithTZ(row.created_at) : null;
  const formatterLastUsedAt = (cell, row) =>
    row.last_used_at !== null ? formatTimeWithTZ(row.last_used_at) : null;
  const formatterAction = (cell, row) => {
    const { id } = row;

    const modalDelete = `delete-${id}`;

    const label = <Icon icon="ellipsis" />;
    const render = () => (
      <div className="dropdown-menu-body dropdown-menu-body-default">
        <ul className="dropdown-menu-list">
          <li className="dropdown-menu-list-item">
            <Button
              kind="hollow"
              tier="three"
              label="Delete"
              icon="trash"
              onClick={() => onModalOpen(modalDelete)}
            />
          </li>
        </ul>
      </div>
    );

    return (
      <>
        <Dropdown
          type="action"
          alignment="right"
          name={id}
          label={label}
          render={render}
        />
        <Modal name={modalDelete}>
          <div className="paper">
            <header className="modal-division division">
              <div className="node">
                <h3 className="modal-title">Delete OTP Authenticator</h3>
              </div>
            </header>
            <section className="modal-division division">
              <div className="node">
                <p>
                  You are removing your OTP Authenticator. The next time you log
                  in, you will be prompted to set up a new OTP Authenticator.
                </p>
              </div>
            </section>
            <footer className="modal-division division divide">
              <div className="node d-flex justify-content-end">
                <Button
                  tier="two"
                  label="Cancel"
                  disabled={isWorking}
                  onClick={onModalClose}
                />
                <Button
                  tier="four"
                  label="Delete"
                  disabled={
                    // BUG: modal within table "formatter" does not re-render so this button never gets disabled when isWorking is true
                    isWorking
                  }
                  onClick={() => onDelete(id)}
                  loader
                />
              </div>
            </footer>
          </div>
        </Modal>
      </>
    );
  };

  const columns = [
    {
      dataField: 'id',
      text: 'Factor',
      sort: false,
      searchable: true,
      headerStyle: {
        width: '50%',
      },
      formatter: formatterFactor,
    },
    {
      dataField: 'created_at',
      text: 'Creation Date',
      sort: false,
      searchable: true,
      formatter: formatterCreatedAt,
    },
    {
      dataField: 'last_auth_at',
      text: 'Last Used',
      sort: false,
      searchable: true,
      formatter: formatterLastUsedAt,
    },
    {
      dataField: '',
      text: '',
      isDummyField: true,
      headerStyle: {
        width: '60px',
        textAlign: 'right',
      },
      formatter: formatterAction,
    },
  ];

  return <Table columns={columns} data={data} filter={null} />;
};
