// istanbul ignore file
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { prismService } from '../data-service/prism-service';
import type { PrismViewSchema } from '../types/prism.types';

type UseCreatePrismExportParams = {
  onSuccess: (view: PrismViewSchema) => void;
};

export const useCreatePrismView = ({
  onSuccess,
}: UseCreatePrismExportParams) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: prismService.createCategoryView,
    onSuccess: (view) => {
      onSuccess(view);
      queryClient.invalidateQueries({
        queryKey: ['prism', 'savedViews'],
      });
    },
  });

  return mutation;
};
