import React, {
  useState,
  isValidElement,
  Fragment,
  useContext,
  useEffect,
  useRef,
} from 'react';

import { Icon, setClass } from '@kandji-inc/bumblebee';
import type { SomeReadonlyRecord } from '@shared/types/util.types';
import Tippy from '@tippyjs/react';

import { SET_TABLE_CURRENT_SORT_KEY, SET_TABLE_UI_STATE } from '../../Table';
import { TableContext } from '../../table.context';
import {
  ARIA_SORT_MAP,
  formatNextSort,
  getIsSortDescendOrNone,
} from '../../table.helpers';
import SortIndicator from './SortIndicator';

import type { ColProps } from '../../table.types';

const Col = <
  ColName extends string,
  Sortable extends boolean,
  Data extends SomeReadonlyRecord[],
>(
  props: ColProps<ColName, Sortable, Data>,
) => {
  const {
    colName,
    colType,
    colNum,
    rowNum,
    isRowExpanded = false,
    isSortable,
    initialSortDirection = 'none',
    onSort,
    datum,
    rowData,
    headerInfo,
    headerInfoIcon = 'circle-info',
    headerInfoProps = {},
    role = 'gridcell',
    children,
    className,
    style,
    component: Component,
    gridColumnDetails = {
      colTracks: '',
      fractionTrackList: [],
      pixelTrackList: [],
    },
    isLastCol,
    isFrozen, // only single frozen column is supported currently
    width,
    autoWidthFr = false,
    onClick,
    tableMetaRef,
  } = props;

  const [
    {
      sortKey,
      uiState: { isHoverColumnHeaders },
    },
    dispatch,
  ] = useContext(TableContext);
  const colRef = useRef<HTMLDivElement>(null);

  const [sortDirection, setSortDirection] = useState(initialSortDirection);
  const isCurrentSortKey = sortKey === colName;
  const isColumnHeader = role === 'columnheader';
  const hasHeaderInfo = Boolean(headerInfo);
  const currColHeight = colRef.current?.clientHeight;

  useEffect(() => {
    if (!isCurrentSortKey) {
      setSortDirection('none');
    }
  }, [isCurrentSortKey]);

  useEffect(() => {
    if (isColumnHeader) {
      tableMetaRef.current.tableHeaderHeight = currColHeight;
    }
  }, [currColHeight, isColumnHeader, tableMetaRef]);
  const renderCell = () => {
    const hasRenderPropsChildren = typeof children === 'function';

    if (hasRenderPropsChildren) {
      return children({
        children: datum as Data[number][ColName],
        rowData,
        colName,
        colNum,
        gridColumnDetails,
        tableMetaRef,
      });
    }

    const isReactElement = isValidElement(Component);
    if (isReactElement) {
      return Component;
    }

    if (!Component || Component === Fragment) {
      return <>{datum}</>;
    }

    return (
      // @ts-expect-error -- TODO fix this
      <Component
        rowData={rowData}
        colName={colName}
        colNum={colNum}
        gridColumnDetails={gridColumnDetails}
      >
        {datum as Data[number][ColName]}
      </Component>
    );
  };

  const dispatchHoverColumnHeaders = (isHover: boolean) => {
    if (isColumnHeader) {
      dispatch({
        type: SET_TABLE_UI_STATE,
        data: {
          uiState: {
            isHoverColumnHeaders: isHover,
          },
        },
      });
    }
  };

  const onSortIndicatorClick = () => {
    const nextSortDirection = getIsSortDescendOrNone(sortDirection)
      ? 'asc'
      : 'desc';

    setSortDirection((prev) => (getIsSortDescendOrNone(prev) ? 'asc' : 'desc'));

    const nextSort = formatNextSort(colName, nextSortDirection);

    onSort?.({
      colName,
      currSortDirection: sortDirection,
      nextSortDirection,
      nextSort,
    });

    dispatch({
      type: SET_TABLE_CURRENT_SORT_KEY,
      data: {
        sortKey: colName,
      },
    });
  };

  return (
    <div
      ref={colRef}
      className={setClass(className, '__v-table-ns')}
      style={style}
      role={role}
      data-v-table-row-num={rowNum}
      data-v-table-col-num={colNum}
      data-v-table-frozen={isFrozen}
      data-v-table-expanded={isRowExpanded}
      data-v-table-last-col={isLastCol}
      data-v-table-col-type={colType}
      data-v-table-col-width={width}
      data-v-table-col-auto-width-fr={autoWidthFr}
      onClick={onClick}
      onMouseOver={() => dispatchHoverColumnHeaders(true)}
      onFocus={() => dispatchHoverColumnHeaders(true)}
      onMouseOut={() => dispatchHoverColumnHeaders(false)}
      onBlur={() => dispatchHoverColumnHeaders(false)}
      {...(colName ? { 'data-v-table-col-name': colName } : {})}
      {...(isColumnHeader ? { 'aria-sort': ARIA_SORT_MAP[sortDirection] } : {})}
    >
      <>
        {renderCell()}
        {isSortable && isColumnHeader ? (
          <SortIndicator
            direction={sortDirection}
            isVisible={isCurrentSortKey || isHoverColumnHeaders}
            onClick={onSortIndicatorClick}
          />
        ) : null}

        {hasHeaderInfo && isColumnHeader ? (
          <span className="header__info __v-table-ns" aria-haspopup>
            <div className="header__info-content __v-table-ns">
              <Tippy
                content={<div className="b-txt">{headerInfo}</div>}
                theme="basic-white"
                placement="top"
                {...headerInfoProps}
              >
                <span
                  className="header__info-icon __v-table-ns"
                  aria-label="Learn more"
                  role="button"
                >
                  <Icon name={headerInfoIcon} />
                </span>
              </Tippy>
            </div>
          </span>
        ) : null}
      </>
    </div>
  );
};

Col.displayName = 'Col';
export default Col;
