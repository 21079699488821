/* istanbul ignore file */
import './notes.css';
import { Button } from '@kandji-inc/bumblebee';
import { withPermissions } from 'contexts/account';
import DOMPurify from 'dompurify';
import {
  ContentState,
  EditorState,
  SelectionState,
  convertToRaw,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { i18n } from 'src/i18n';
import styled from 'styled-components';
import { clearEditor as callClearEditor } from '../../_actions/editor';
import { setSnackbar as callSetSnackbar } from '../../_actions/ui';
import { userTypes as UserTypes } from '../../common/constants';
import { formatTime } from '../common/helpers';
import AwesomeDropdown from '../interface/AwesomeDropdown';
import { LineLoader } from '../interface/LineLoader';
import { addNote, deleteNote, getNotes, updateNote } from './api';

const TOOLBAR_OPTIONS = {
  options: ['inline', 'blockType', 'list', 'link'],
  inline: {
    options: ['bold', 'italic'],
  },
  blockType: {
    options: ['Normal', 'Blockquote', 'Code'],
    inDropdown: true,
  },
  list: {
    options: ['unordered', 'ordered'],
  },
};

const NotesWrapper = styled.section`
  padding-bottom: 150px;
`;

const CreateButtonWrapper = styled.section`
  padding: 35px 20px;
  border-bottom: 1px solid #ced4da;
`;

const NotesEditor = styled.section`
  min-height: 250px;
  padding: 40px 24px;
  border-bottom: 1px solid #ced4da;
`;

const EditorButtonsWrapper = styled.section`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
`;

const NotesListWrapper = styled.section`
  padding: 35px 0;
`;

const Note = styled.section`
  padding: 0 24px 24px;
  font-weight: 400;
  font-size: 16px;
  border-bottom: 1px solid #ced4da;
`;

const NoteHeader = styled.section`
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NoteTitle = styled.span`
  font-weight: 500;
  font-size: 16px;
  color: #212529;
`;

const NoteContent = styled.section`
  @media (min-width: 1080px) {
    padding-right: 150px;
    word-wrap: break-word;
  }
`;

export class DeviceNotesTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      charsCount: 0,
      charsLimit: 16000,
      noteId: null,
      notes: [],
      isLoading: true,
      isShowEditor: false,
    };
    this.onEdit = this.onEdit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.showEditor = this.showEditor.bind(this);
  }

  componentDidMount() {
    const { blueprintId, computerId, editor } = this.props;
    getNotes(blueprintId || computerId)
      .then((notes) => this.setState({ notes }))
      .catch(() => this.setState({ notes: [] }))
      .finally(() => this.setState({ isLoading: false }));

    if (editor) {
      const contentBlock = htmlToDraft(editor);
      const contentState = ContentState.createFromBlockArray(contentBlock);
      this.state.editorState = EditorState.createWithContent(contentState);
      const editorState = EditorState.createWithContent(contentState);
      const selectionState = this.moveSelectionToEnd(editorState);
      this.setState({
        editorState: EditorState.forceSelection(editorState, selectionState),
        isShowEditor: true,
      });
      this.countChars(editorState);
    }
  }

  componentWillUnmount() {
    const { clearEditor } = this.props;
    clearEditor();
  }

  moveSelectionToEnd = (editorState) => {
    const content = editorState.getCurrentContent();
    const blockMap = content.getBlockMap();

    const key = blockMap.last().getKey();
    const length = blockMap.last().getLength();

    return new SelectionState({
      anchorKey: key,
      anchorOffset: length,
      focusKey: key,
      focusOffset: length,
    });
  };

  onEditorStateChange = (editorState) => {
    this.countChars(editorState);
    this.setState({
      editorState,
    });
  };

  countChars = (editorState) => {
    const charsCount = editorState.getCurrentContent().getPlainText('').length;
    this.setState({
      charsCount,
    });
  };

  onSave() {
    const { blueprintId, computerId, setSnackbar } = this.props;
    const { editorState, noteId, charsCount, charsLimit, notes } = this.state;
    const htmlContent = draftToHtml(
      convertToRaw(editorState.getCurrentContent()),
    );
    const plainText = editorState.getCurrentContent().getPlainText();
    if (!plainText.replace(/\s/g, '').length) {
      setSnackbar('Note is empty. Enter text please.');
      return this.editor.focusEditor();
    }
    if (charsCount > charsLimit) {
      setSnackbar(i18n.t('Characters limit is exceeded.'));
      return this.editor.focusEditor();
    }
    this.setState({ isLoading: true });
    if (noteId) {
      updateNote(noteId, htmlContent)
        .then((updated) => {
          const newNotes = notes.map((note) =>
            note.id === updated.id ? updated : note,
          );
          this.setState({ notes: newNotes });
          this.onCancel();
        })
        .finally(() => this.setState({ isLoading: false }));
    } else {
      const data = { content: htmlContent };
      if (blueprintId) {
        data.blueprint_id = blueprintId;
      } else {
        data.computer_id = computerId;
      }
      addNote(data)
        .then((note) => {
          this.setState((prevState) => ({ notes: [note, ...prevState.notes] }));
          this.onCancel();
        })
        .finally(() => this.setState({ isLoading: false }));
    }
    return null;
  }

  onCancel() {
    this.setState({
      noteId: null,
      editorState: EditorState.createEmpty(),
      charsCount: 0,
      isShowEditor: false,
    });
  }

  onEdit(id) {
    const { notes, isShowEditor } = this.state;
    const note = notes.find((x) => x.id === id);
    if (!note) {
      return null;
    }
    const { content } = note;
    const contentBlock = htmlToDraft(content);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
      );
      const editorState = EditorState.createWithContent(contentState);
      const selectionState = this.moveSelectionToEnd(editorState);
      this.countChars(editorState);
      this.setState({
        editorState: EditorState.forceSelection(editorState, selectionState),
        noteId: id,
      });
    }
    if (!isShowEditor) {
      this.showEditor();
    }
    return null;
  }

  onDelete(id) {
    const { notes, noteId } = this.state;
    this.setState({ isLoading: true });
    deleteNote(id)
      .then(() => {
        this.setState({
          notes: notes.filter((note) => note.id !== id),
          noteId: id === noteId ? null : noteId,
        });
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  canEditNote = (userId, note) => {
    const { permissions } = this.props;
    if (userId === note.author.id) {
      return true;
    }
    return permissions.canManageDevices;
  };

  handleBeforeInput = () => {
    const { charsCount, charsLimit } = this.state;
    if (charsCount >= charsLimit) {
      return 'handled';
    }
  };

  renderNotes() {
    const { userId, permissions } = this.props;
    const { notes } = this.state;
    // notes.sort((a, b) => b.updated_at - a.updated_at, 0);
    notes.sort((a, b) =>
      a.updated_at < b.updated_at ? 1 : b.updated_at < a.updated_at ? -1 : 0,
    );
    return notes.map((note) => {
      const { id, author, created_at, updated_at } = note;
      const { first_name, last_name } = author;
      const authorId = author.id;
      const noteActions = [];
      noteActions.push({
        action: () => this.onEdit(id),
        iconClass: 'pencil',
        label: i18n.t('Edit Note'),
      });
      if (authorId === userId || permissions.canManageDevices) {
        noteActions.push({
          action: () => this.onDelete(id),
          iconClass: 'trash-can',
          label: i18n.t('Delete Note'),
        });
      }
      const timestamp = updated_at || created_at;
      const timestampVerbose = formatTime(timestamp);
      const authorVerbose =
        first_name && last_name
          ? `${first_name} ${last_name}`
          : i18n.t('Unknown user');
      return (
        <Note key={note.id}>
          <NoteHeader>
            <NoteTitle>{`${authorVerbose} ${timestampVerbose}`}</NoteTitle>
            {this.canEditNote(userId, note) && (
              <div style={{ width: 40 }}>
                <AwesomeDropdown horizontal="right" options={noteActions} />
              </div>
            )}
          </NoteHeader>
          <NoteContent
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(note.content),
            }}
            className="note-content"
          />
        </Note>
      );
    });
  }

  showEditor = () => {
    const { isShowEditor } = this.state;
    this.setState(() => ({ isShowEditor: !isShowEditor }));
  };

  render() {
    const { editorState, charsCount, charsLimit, isLoading, isShowEditor } =
      this.state;
    const { userType } = this.props;
    return (
      <NotesWrapper id="notes-tab">
        {!isShowEditor && userType !== UserTypes.super && (
          <CreateButtonWrapper>
            <Button icon="plus" onClick={this.showEditor}>
              {i18n.t('Create New Note')}
            </Button>
          </CreateButtonWrapper>
        )}
        {isShowEditor && (
          <NotesEditor>
            <Editor
              ref={(el) => {
                this.editor = el;
              }}
              editorClassName="notes-editor-input"
              editorState={editorState}
              handleBeforeInput={this.handleBeforeInput}
              onEditorStateChange={(state) => this.onEditorStateChange(state)}
              toolbar={TOOLBAR_OPTIONS}
            />
            <span className="c-grey-400">
              {`${charsCount}/${charsLimit} Characters`}
            </span>
            <EditorButtonsWrapper>
              <Button disabled={!charsCount || isLoading} onClick={this.onSave}>
                {i18n.t('Save')}
              </Button>
              <Button
                disabled={isLoading}
                onClick={this.onCancel}
                style={{ marginLeft: '10px' }}
                kind="outline"
              >
                {i18n.t('Cancel')}
              </Button>
            </EditorButtonsWrapper>
          </NotesEditor>
        )}
        <NotesListWrapper>
          {isLoading && <LineLoader />}
          {this.renderNotes()}
        </NotesListWrapper>
      </NotesWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.account.user.id,
  userType: state.account.user.type,
  editor: state.editor,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearEditor: callClearEditor,
      setSnackbar: callSetSnackbar,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withPermissions(DeviceNotesTab));
