import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../atoms/Icon';

function getRealClassName(
  defaultClassName,
  { className, replaceDefault } = {},
) {
  const classConfig = `${+!!className}${+!!replaceDefault}`;
  switch (classConfig) {
    case '10':
      return `${defaultClassName} ${className}`;
    case '11':
      return className;
    default:
      return defaultClassName;
  }
}

function EmptyContent({ text, classes, title, extra } = {}) {
  return (
    <div
      className={getRealClassName(
        'b-flex-col' +
          ' b-flex-align-center' +
          ' b-txt-center' +
          ' table-custom-no-data',
        classes?.wrapper,
      )}
    >
      <Icon
        icon="empty-beehive"
        className="table-custom-no-data--beehive b-mb2"
      />
      {title && (
        <div className={getRealClassName('b-h3-g', classes.title)}>{title}</div>
      )}
      <div
        className={getRealClassName(
          'table-custom-no-data--descriptions b-txt',
          classes?.description,
        )}
      >
        {text}
        {extra}
      </div>
    </div>
  );
}

const classConfigShape = PropTypes.shape({
  className: PropTypes.string,
  replaceDefault: PropTypes.bool,
});

EmptyContent.propTypes = {
  text: PropTypes.node,
  title: PropTypes.string,
  classes: PropTypes.shape({
    wrapper: classConfigShape,
    title: classConfigShape,
    description: classConfigShape,
  }),
  extra: PropTypes.node,
};

EmptyContent.defaultProps = {
  text: 'We couldn’t find a match. Try changing the filter.',
  title: 'No results found',
  classes: {},
  extra: null,
};

export default EmptyContent;
