import { clearGSuiteUser, retrieveUser } from 'app/_actions/gSuite';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LineLoader } from '../interface/LineLoader';
import GSuiteUserSummaryCard from './GSuiteUserSummaryCard';
import GSuiteUserComputers from './gSuiteUserComputers/GSuiteUserComputers';

export class GSuiteUserRecord extends React.Component {
  constructor(props) {
    super(props);
    this.gSuiteUserId = props.match.params.user_id;
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    const { retrieveUser: callRetrieveUser } = this.props;
    callRetrieveUser(this.gSuiteUserId).then(() =>
      this.setState({ isLoading: false }),
    );
  }

  componentWillUnmount() {
    const { clearGSuiteUser: callClearGSuiteUser } = this.props;
    callClearGSuiteUser();
  }

  render() {
    const { isLoading } = this.state;
    const { currentGSuiteUser } = this.props;
    if (isLoading) {
      return <LineLoader />;
    }
    const { name, email, photo, computers } = currentGSuiteUser;
    return (
      <div>
        <GSuiteUserSummaryCard name={name} email={email} photoUrl={photo} />
        <GSuiteUserComputers computers={computers} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentGSuiteUser: state.gSuite.currentGSuiteUser,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      retrieveUser,
      clearGSuiteUser,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(GSuiteUserRecord);
