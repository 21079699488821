import {
  Badge,
  Button,
  Icon,
  SelectV2Primitives as Select,
  Text,
  styled,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import * as React from 'react';
import {
  ADEListViewStore,
  useADEListViewStore,
} from '../store/ADEListViewStore';

const StyledContent = styled(Select.Content, {
  width: 'var(--radix-select-trigger-width)',
  margin: '4px 0',
  maxHeight: 320,
  zIndex: 9999,
});

const StyledStatus = styled('span', {
  fontWeight: '$regular',
});

const TriggerContainer = styled('span', {
  textTransform: 'capitalize',
});

export enum EnrollmentStatusFilters {
  ENROLLED = 'enrolled',
  AWAITING = 'awaiting',
  CLEAR = 'clear',
}

export function handleUpdateStatusFilter(
  enrollmentStatusValue: EnrollmentStatusFilters,
) {
  const updateEnrollmentStatusFilter =
    ADEListViewStore.getState().updateEnrollmentStatusFilter;

  const enrollmentStatus =
    enrollmentStatusValue === EnrollmentStatusFilters.CLEAR
      ? null
      : enrollmentStatusValue;

  updateEnrollmentStatusFilter(enrollmentStatus);
}

export function ADEStatusFilter() {
  const { enrollmentStatusFilter } = useADEListViewStore((state) => ({
    enrollmentStatusFilter: state.enrollmentStatusFilter,
  }));

  return (
    <Select.Root
      value={enrollmentStatusFilter}
      onValueChange={handleUpdateStatusFilter}
    >
      <Select.Trigger
        compact
        variant={enrollmentStatusFilter == null ? 'filter' : 'filterApplied'}
      >
        <TriggerContainer>
          {enrollmentStatusFilter == null ? (
            i18n.t('Enrollment Status')
          ) : (
            <div>
              <StyledStatus>{i18n.t('Enrollment Status: ')}</StyledStatus>
              {enrollmentStatusFilter}
            </div>
          )}
        </TriggerContainer>
        <Select.Icon style={{ marginLeft: 'auto' }}>
          <Icon name="fa-angle-down-small" style={{ width: 20, height: 20 }} />
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal>
        <StyledContent position="popper">
          <Select.Viewport>
            <Select.Item value={EnrollmentStatusFilters.ENROLLED}>
              <Badge color="green">{i18n.t('Enrolled')}</Badge>
            </Select.Item>
            <Select.Item value={EnrollmentStatusFilters.AWAITING}>
              <Badge color="neutral">{i18n.t('Awaiting')}</Badge>
            </Select.Item>
            <Select.Item value={EnrollmentStatusFilters.CLEAR}>
              <Button
                css={{
                  padding: 0,
                  background: 'none',
                  '&:hover': { background: 'none' },
                }}
                compact
                variant="subtle"
                disabled={enrollmentStatusFilter == null}
              >
                {i18n.t('Clear')}
              </Button>
            </Select.Item>
          </Select.Viewport>
        </StyledContent>
      </Select.Portal>
    </Select.Root>
  );
}
