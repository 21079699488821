import type {
  Active,
  DataRef,
  Modifier,
  Over,
  UniqueIdentifier,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { CSS as DndCSS } from '@dnd-kit/utilities';
import { css } from '@kandji-inc/nectar-ui';
import type { CSSProperties, MutableRefObject, RefObject } from 'react';

import type {
  DndContextKind,
  Folder,
  HomeScreenLayoutDeviceModelSettings,
  HomeScreenLayoutItem,
  HomeScreenLayoutPreviewContextKind,
  NonFolderHomeScreenLayoutItem,
} from '../../../home-screen-layout.types';
import { PREVIEW_CONFIG } from '../common';

type PagesArray = HomeScreenLayoutDeviceModelSettings['Pages'];
type PageItem = PagesArray[number][number];
type DockArray = HomeScreenLayoutDeviceModelSettings['Dock'];
type DockItem = DockArray[number];
type FolderItem = NonFolderHomeScreenLayoutItem;
type SomeItem = PageItem | DockItem | NonFolderHomeScreenLayoutItem;

type DndArrayOperationArgs<TItem extends SomeItem> = {
  pages: PagesArray;
  dock: DockArray;
  items: TItem[];
  currentPage: number;
  toPage: number;
  currentFolder: string;
  currentFolderPage: number;
  insertIndex: number;
  fromIndex: number;
  toIndex: number;
  removeId: UniqueIdentifier;
};

export interface ActiveProps extends Active {
  readonly data: DataRef<{
    readonly dndContext?: Extract<DndContextKind, 'draggable_app'>;
    readonly item?: HomeScreenLayoutItem;
    readonly nodeRef?: MutableRefObject<HTMLElement | null>;
  }>;
}

export function getDragEndActionType(
  active: Active | null | undefined,
  over: Over | null | undefined,
) {
  if (!active || !over) {
    return '';
  }

  const {
    common: {
      dndContext: {
        draggableApp,
        droppableDock,
        droppablePage,
        droppableFolder,
        sortableDockItem,
        sortablePageItem,
        sortablePageFolder,
        sortableFolderItem,
      },
    },
  } = PREVIEW_CONFIG;

  const activeDndContext = active.data.current.dndContext;
  const overDndContext = over.data.current.dndContext;
  const fromPage = active.data.current.pageIndex;
  const toPage = over.data.current.pageIndex;

  if (!activeDndContext || !overDndContext) {
    return '';
  }

  /**
   * Application List -> drag -> Preview Page conditions:
   */
  if (activeDndContext === draggableApp && overDndContext === droppablePage) {
    return 'append_app_to_page';
  }

  /**
   * Page -> drag -> append to different page:
   */
  if (
    (activeDndContext === sortablePageItem ||
      activeDndContext === sortablePageFolder) &&
    overDndContext === droppablePage &&
    fromPage !== toPage
  ) {
    return 'move_app_between_page';
  }

  /**
   * Page app -> drag -> append to folder:
   */
  if (
    (activeDndContext === sortablePageItem ||
      activeDndContext === draggableApp) &&
    overDndContext === sortablePageFolder
  ) {
    return 'move_app_to_folder';
  }

  /**
   * Page -> drag -> insert/sort into different page
   */
  if (
    (activeDndContext === sortablePageItem ||
      activeDndContext === sortablePageFolder) &&
    overDndContext === sortablePageItem &&
    fromPage !== toPage
  ) {
    return 'sort_app_between_page';
  }

  if (
    activeDndContext === draggableApp &&
    (overDndContext === sortablePageItem ||
      overDndContext === sortablePageFolder)
  ) {
    return 'sort_app_to_page';
  }

  /**
   * Application List -> drag -> Preview Dock conditions:
   */
  if (activeDndContext === draggableApp && overDndContext === droppableDock) {
    return 'append_app_to_dock';
  }

  if (
    activeDndContext === draggableApp &&
    overDndContext === sortableDockItem
  ) {
    return 'sort_app_to_dock';
  }

  /**
   * Application List -> drag -> Preview Folder conditions:
   */
  if (activeDndContext === draggableApp && overDndContext === droppableFolder) {
    return 'append_app_to_folder';
  }

  if (
    activeDndContext === draggableApp &&
    overDndContext === sortableFolderItem
  ) {
    return 'sort_app_to_folder';
  }

  /**
   * Preview Page -> drag + swap -> Preview Dock conditions:
   */
  if (
    activeDndContext === sortablePageItem &&
    overDndContext === droppableDock
  ) {
    return 'append_swap_page_to_dock';
  }

  if (
    activeDndContext === sortablePageItem &&
    overDndContext === sortableDockItem
  ) {
    return 'sort_swap_page_to_dock';
  }

  /**
   * Preview Dock -> drag + swap -> Preview Page conditions:
   */
  if (
    activeDndContext === sortableDockItem &&
    overDndContext === droppablePage
  ) {
    return 'append_swap_dock_to_page';
  }

  if (
    activeDndContext === sortableDockItem &&
    overDndContext === sortablePageItem
  ) {
    return 'sort_swap_dock_to_page';
  }

  /**
   * Preview Page <- sort within -> Preview Page conditions:
   */
  if (
    (activeDndContext === sortablePageItem ||
      activeDndContext === sortablePageFolder) &&
    overDndContext === sortablePageItem
  ) {
    return 'sort_within_page';
  }

  /**
   * Preview Dock <- sort within -> Preview Dock conditions:
   */
  if (
    activeDndContext === sortableDockItem &&
    overDndContext === sortableDockItem
  ) {
    return 'sort_within_dock';
  }

  /**
   * Preview Folder <- sort within -> Preview Folder conditions:
   */
  if (
    activeDndContext === sortableFolderItem &&
    overDndContext === sortableFolderItem
  ) {
    return 'sort_within_folder';
  }

  return '';
}

export function getDropContainerAtMax({
  over,
  selectedItems,
}: {
  over: Over | null;
  selectedItems?: readonly HomeScreenLayoutItem[];
}) {
  if (selectedItems?.length > 1) {
    const itemsCount = over?.data.current.items.length;
    const maxItems = over?.data.current.maxItems;

    if (typeof itemsCount === 'number' && typeof maxItems === 'number') {
      return selectedItems.length + itemsCount > maxItems;
    }

    return false;
  }

  const isOverAtMax = over?.data.current.isAtMax;
  return typeof isOverAtMax === 'boolean' ? isOverAtMax : false;
}

export function appendItemsToPage({
  pages,
  toPage,
  items,
}: Pick<DndArrayOperationArgs<PageItem>, 'pages' | 'toPage' | 'items'>) {
  const pageUpdate = pages[toPage].concat(items);
  return pages.map((page, pageIndex) =>
    pageIndex === toPage ? pageUpdate : page,
  );
}

export function moveItemsBetweenPages({ pages, fromPage, toPage, items }) {
  // Remove the items from the page they are being dragged from:
  const newFromPage = pages[fromPage].filter(
    (pageItem) => !items.some((item) => item.id === pageItem.id),
  );

  // Add the items to the page they're being dragged to:
  const newToPage = pages[toPage].concat(items);
  return pages.map((page, pageIndex) => {
    if (pageIndex === fromPage) {
      return newFromPage;
    }
    if (pageIndex === toPage) {
      return newToPage;
    }

    return page;
  });
}

export function moveItemsIntoFolder({ pages, toFolderId, items, kind }) {
  const previeConfig = PREVIEW_CONFIG[kind];

  return pages.map((page) =>
    page
      .map((pageItem) => {
        // Add items to the folder as long as there is room:
        if (
          pageItem.id === toFolderId &&
          pageItem?.Pages[0].length <= previeConfig.maxPerFolder - items.length
        ) {
          return {
            ...pageItem,
            Pages: [[...pageItem.Pages[0], ...items]],
          };
        }

        if (items.some((i) => i.id === pageItem.id)) {
          return null;
        }

        return pageItem;
      })
      .filter(Boolean),
  );
}

export function sortItemsBetweenPages({
  pages,
  fromPage,
  toPage,
  items,
  insertIndex,
}) {
  // Remove the items from the page they are being dragged from:
  const newFromPage = pages[fromPage].filter(
    (pageItem) => !items.some((item) => item.id === pageItem.id),
  );

  // Add the items to the page they're being dragged to:
  const newToPage = pages[toPage].flatMap((currItem, currIndex) =>
    currIndex === insertIndex ? items.concat(currItem) : currItem,
  );
  return pages.map((page, pageIndex) => {
    if (pageIndex === toPage) {
      return newToPage;
    }

    if (pageIndex === fromPage) {
      return newFromPage;
    }

    return page;
  }) as PagesArray;
}

export function insertItemsToPage({
  pages,
  toPage,
  insertIndex,
  items,
}: Pick<
  DndArrayOperationArgs<PageItem>,
  'pages' | 'toPage' | 'items' | 'insertIndex'
>) {
  const pageUpdate = pages[toPage].flatMap((currItem, currIndex) =>
    currIndex === insertIndex ? items.concat(currItem) : currItem,
  );
  return pages.map((page, pageIndex) =>
    pageIndex === toPage ? pageUpdate : page,
  ) as PagesArray;
}

export function removeItemFromPage({
  pages,
  currentPage,
  removeId,
}: Pick<
  DndArrayOperationArgs<PageItem>,
  'pages' | 'currentPage' | 'removeId'
>) {
  const pageUpdate = pages[currentPage].filter(
    (currItem) => currItem.id !== removeId,
  );
  return pages.map((page, pageIndex) =>
    pageIndex === currentPage ? pageUpdate : page,
  );
}

export function sortItemsWithinPage({
  pages,
  currentPage,
  fromIndex,
  toIndex,
}: Pick<
  DndArrayOperationArgs<PageItem>,
  'pages' | 'currentPage' | 'fromIndex' | 'toIndex'
>) {
  const sortedPage = arrayMove(
    pages[currentPage] as PageItem[],
    fromIndex,
    toIndex,
  );
  return pages.map((page, pageIndex) =>
    pageIndex === currentPage ? sortedPage : page,
  );
}

export function findFolderInCurrentPage({
  pages,
  currentPage,
  currentFolder,
  currentFolderPage,
}: Pick<
  DndArrayOperationArgs<PageItem>,
  'pages' | 'currentPage' | 'currentFolder' | 'currentFolderPage'
>) {
  const folderIndexOnPage = pages[currentPage].findIndex(
    (pageItem) => pageItem.Type === 'Folder' && pageItem.id === currentFolder,
  );

  const foundFolder =
    folderIndexOnPage > -1
      ? (pages[currentPage][folderIndexOnPage] as Folder)
      : null;
  const folderItems = foundFolder?.Pages?.[currentFolderPage];

  return [
    foundFolder,
    {
      folderItems,
      folderIndexOnPage,
    },
  ] as const;
}

export function appendItemsToFolder({
  pages,
  currentPage,
  currentFolder,
  currentFolderPage,
  items,
}: Pick<
  DndArrayOperationArgs<FolderItem>,
  'pages' | 'currentPage' | 'currentFolder' | 'currentFolderPage' | 'items'
>) {
  const [foundFolder, { folderItems, folderIndexOnPage }] =
    findFolderInCurrentPage({
      pages,
      currentPage,
      currentFolder,
      currentFolderPage,
    });

  if (folderItems) {
    const appendedFolderPageItems = folderItems.concat(
      items as readonly NonFolderHomeScreenLayoutItem[],
    );
    const folderUpdate = {
      ...foundFolder,
      Pages: foundFolder.Pages.map((currFolderPage, folderIndex) =>
        folderIndex === currentFolderPage
          ? appendedFolderPageItems
          : currFolderPage,
      ),
    };

    const pageUpdate = pages[currentPage].map((page, pageIndex) =>
      pageIndex === folderIndexOnPage ? folderUpdate : page,
    );

    return pages.map((page, pageIndex) =>
      pageIndex === currentPage ? pageUpdate : page,
    ) as PagesArray;
  }

  return pages;
}

export function insertItemsToFolder({
  pages,
  currentPage,
  currentFolder,
  currentFolderPage,
  items,
  insertIndex,
}: Pick<
  DndArrayOperationArgs<FolderItem>,
  | 'pages'
  | 'currentPage'
  | 'currentFolder'
  | 'currentFolderPage'
  | 'items'
  | 'insertIndex'
>) {
  const [foundFolder, { folderItems, folderIndexOnPage }] =
    findFolderInCurrentPage({
      pages,
      currentPage,
      currentFolder,
      currentFolderPage,
    });

  if (folderItems) {
    const insertedFolderPageItems = folderItems.flatMap(
      (currItem, currIndex) =>
        currIndex === insertIndex ? items.concat(currItem) : currItem,
    );

    const folderUpdate = {
      ...foundFolder,
      Pages: foundFolder.Pages.map((currFolderPage, folderIndex) =>
        folderIndex === currentFolderPage
          ? insertedFolderPageItems
          : currFolderPage,
      ),
    };

    const pageUpdate = pages[currentPage].map((page, pageIndex) =>
      pageIndex === folderIndexOnPage ? folderUpdate : page,
    );

    return pages.map((page, pageIndex) =>
      pageIndex === currentPage ? pageUpdate : page,
    ) as PagesArray;
  }

  return pages;
}

export function sortItemsWithinFolder({
  currentFolder,
  currentFolderPage,
  pages,
  currentPage,
  fromIndex,
  toIndex,
}: Pick<
  DndArrayOperationArgs<FolderItem>,
  | 'currentFolder'
  | 'currentFolderPage'
  | 'pages'
  | 'currentPage'
  | 'fromIndex'
  | 'toIndex'
>) {
  const [foundFolder, { folderItems, folderIndexOnPage }] =
    findFolderInCurrentPage({
      pages,
      currentPage,
      currentFolder,
      currentFolderPage,
    });

  if (folderItems) {
    const sortedFolderPageItems = arrayMove(
      folderItems as NonFolderHomeScreenLayoutItem[],
      fromIndex,
      toIndex,
    );

    const folderUpdate = {
      ...foundFolder,
      Pages: foundFolder.Pages.map((currFolderPage, folderIndex) =>
        folderIndex === currentFolderPage
          ? sortedFolderPageItems
          : currFolderPage,
      ),
    };

    const pageUpdate = pages[currentPage].map((page, pageIndex) =>
      pageIndex === folderIndexOnPage ? folderUpdate : page,
    );

    return pages.map((page, pageIndex) =>
      pageIndex === currentPage ? pageUpdate : page,
    ) as PagesArray;
  }

  return pages;
}

export function appendItemsToDock({
  dock,
  items,
}: Pick<DndArrayOperationArgs<DockItem>, 'dock' | 'items'>) {
  return dock.concat(items);
}

export function insertItemsToDock({
  dock,
  insertIndex,
  items,
}: Pick<DndArrayOperationArgs<DockItem>, 'dock' | 'items' | 'insertIndex'>) {
  const dockUpdate = dock.flatMap((currItem, currIndex) =>
    currIndex === insertIndex ? items.concat(currItem) : currItem,
  );
  return dockUpdate;
}

export function removeItemFromDock({
  dock,
  removeId,
}: Pick<DndArrayOperationArgs<DockItem>, 'dock' | 'removeId'>) {
  const dockUpdate = dock.filter((currItem) => currItem.id !== removeId);
  return dockUpdate;
}

export function sortItemsWithinDock({
  dock,
  fromIndex,
  toIndex,
}: Pick<DndArrayOperationArgs<DockItem>, 'dock' | 'fromIndex' | 'toIndex'>) {
  const sortedDock = arrayMove(dock as DockItem[], fromIndex, toIndex);
  return sortedDock;
}

export function createRestrictToRefElementModifier(
  ref: RefObject<HTMLElement>,
) {
  const modifier: Modifier = ({
    containerNodeRect,
    draggingNodeRect,
    transform,
  }) => {
    if (!draggingNodeRect || !containerNodeRect || !ref.current) {
      return transform;
    }

    const value = {
      ...transform,
    };

    const boundingNodeRect = ref.current.getBoundingClientRect();

    if (draggingNodeRect.top + transform.y <= boundingNodeRect.top) {
      value.y = boundingNodeRect.top - draggingNodeRect.top;
    } else if (
      draggingNodeRect.bottom + transform.y >=
      boundingNodeRect.top + boundingNodeRect.height
    ) {
      value.y =
        boundingNodeRect.top +
        boundingNodeRect.height -
        draggingNodeRect.bottom;
    }

    if (draggingNodeRect.left + transform.x <= boundingNodeRect.left) {
      value.x = boundingNodeRect.left - draggingNodeRect.left;
    } else if (
      draggingNodeRect.right + transform.x >=
      boundingNodeRect.left + boundingNodeRect.width
    ) {
      value.x =
        boundingNodeRect.left + boundingNodeRect.width - draggingNodeRect.right;
    }

    return value;
  };

  return modifier;
}

export function createDragIndicatorCss({
  dock,
  pageOrFolderItems,
  currentPageOrFolderPageNumber,
  pageOrFolderIndex,
  itemIndex,
  active,
  over,
  droppableContainerId,
  sortableItemId,
}: {
  dock?: DockArray;
  pageOrFolderItems?: PagesArray[number];
  currentPageOrFolderPageNumber?: number;
  pageOrFolderIndex?: number;
  itemIndex: number;
  active: Active | null;
  over: Over | null;
  droppableContainerId: UniqueIdentifier;
  sortableItemId: UniqueIdentifier;
}) {
  const overId = over ? over.id : Symbol('no_over_id_unique_key');
  const items = dock || pageOrFolderItems;
  const isLast = itemIndex === items.length - 1;
  const isLastOverDroppableZone = isLast && overId === droppableContainerId;

  const isOverSortableItem = overId === sortableItemId;

  const showDragIndicator =
    isLastOverDroppableZone ||
    (isOverSortableItem && over?.data?.current?.item?.Type !== 'Folder');

  const willSortWithOverItem = active?.data.current
    ? active.data.current?.sortable?.containerId ===
      over?.data.current?.sortable?.containerId
    : false;

  return css(
    showDragIndicator && !willSortWithOverItem
      ? {
          '&::after': {
            display: 'block',
            content: '',
            position: 'absolute',
            top: 0,
            left: isOverSortableItem ? 0 : 'auto',
            right: isLastOverDroppableZone ? 0 : 'auto',
            width: 4,
            height: 64,
            borderRadius: '$1',
            background: '$blue50',
          },
        }
      : {},
  );
}

export function keyboardDragStartCssTransform({
  active,
  droppableContainerNode,
  dragActivationEvent,
  defaultTransform = 'none',
}: {
  active: ActiveProps;
  droppableContainerNode: HTMLElement | null;
  dragActivationEvent: Event | null;
  defaultTransform?: CSSProperties['transform'];
}) {
  const isKeyDownEvent = dragActivationEvent.type === 'keydown';
  if (!isKeyDownEvent || !active || !droppableContainerNode) {
    return defaultTransform;
  }

  const activeRect =
    active.data.current.nodeRef?.current?.getBoundingClientRect();
  const previewRect = droppableContainerNode.getBoundingClientRect();

  if (activeRect && previewRect) {
    const [PAD_OFFSET_X, PAD_OFFSET_Y] = [2, 8] as const;
    return DndCSS.Translate.toString({
      x: previewRect.x - activeRect.x + PAD_OFFSET_X,
      y: previewRect.y - activeRect.y + PAD_OFFSET_Y,
      scaleX: 1,
      scaleY: 1,
    });
  }

  return defaultTransform;
}

export function getSortableItemTypeByPreviewContext(
  id: string,
  previewContext: HomeScreenLayoutPreviewContextKind,
) {
  const {
    common: {
      prefixSortablePageItemId,
      prefixSortablePageFolderId,
      prefixSortableDockItemId,
      prefixSortableFolderItemId,
    },
  } = PREVIEW_CONFIG;

  switch (previewContext) {
    case 'page':
      return `${prefixSortablePageItemId}${id}`;
    case 'pageFolder':
      return `${prefixSortablePageFolderId}${id}`;
    case 'dock':
      return `${prefixSortableDockItemId}${id}`;
    case 'folder':
      return `${prefixSortableFolderItemId}${id}`;
    default: {
      /* istanbul ignore next  -- only in dev env */
      if (process.env.NODE_ENV === 'development') {
        throw new Error(
          `Unknown dnd sortable preview context kind: ${previewContext}. Expected one of: 'page', 'dock', 'folder'.`,
        );
      }
      return undefined;
    }
  }
}

export function getDndContextPreviewContext(
  previewContext: HomeScreenLayoutPreviewContextKind,
) {
  const {
    common: {
      dndContext: {
        sortableDockItem,
        sortablePageItem,
        sortableFolderItem,
        sortablePageFolder,
      },
    },
  } = PREVIEW_CONFIG;

  switch (previewContext) {
    case 'page':
      return sortablePageItem;
    case 'pageFolder':
      return sortablePageFolder;
    case 'dock':
      return sortableDockItem;
    case 'folder':
      return sortableFolderItem;
    default: {
      /* istanbul ignore next  -- only in dev env */
      if (process.env.NODE_ENV === 'development') {
        throw new Error(
          `Unknown dnd sortable preview context kind: ${previewContext}. Expected one of: 'page', 'dock', 'folder'.`,
        );
      }
      return undefined;
    }
  }
}
