/* istanbul ignore file - legacy code, moved */
import { Button, Icon } from '@kandji-inc/bumblebee';
import { links } from 'app/common/constants';
import BootstrapTable from 'app/components/common/BootstrapTable/BootstrapTable';
import TableHeaderColumn from 'app/components/common/BootstrapTable/TableHeaderColumn';
import Table from 'app/components/common/Table';
import { formatTime } from 'app/components/common/helpers';
import React from 'react';
import styled from 'styled-components';

const Status = styled('div')`
  font-size: 10px;
  line-height: 7px;
  display: flex;
  letter-spacing: 0.15em;
  text-transform: uppercase;

  color: #1a1d25;
  i {
    font-size: 6px;
    color: ${(props) => props.iColor};
    margin-right: 5px;
  }
`;

const StatusLabel = styled('span')`
  line-height: 26px;
  width: 120px;
  display: flex;
  align-items: center;
`;

export const OldAppStoreStatusTable = (props) => {
  const {
    data,
    statuses,
    filteredStatus,
    reloadStatuses,
    setTable,
    config,
    successLabel,
    dateHeader,
    dateField,
    history,
  } = props;

  const getOnClickFlush = (row) => (e) => {
    e.stopPropagation();
    return config.flushItemStatus(row.status_id).then(reloadStatuses);
  };

  return (
    <BootstrapTable
      data={statuses.filter(({ status }) => {
        if (filteredStatus === 'all') {
          return true;
        }
        if (['PENDING', 'pending', 'installing'].includes(filteredStatus)) {
          return ['PENDING', 'pending', 'installing'].includes(status);
        }
        if (['success', 'PASS'].includes(filteredStatus)) {
          return ['success', 'PASS'].includes(status);
        }
        if (['failed', 'ERROR'].includes(filteredStatus)) {
          return ['failed', 'ERROR'].includes(status);
        }
        return filteredStatus.includes(status);
      })}
      version="4"
      options={{
        ...Table.defaultOptions,
        btnGroup: null,
        toolBar: null,
        defaultSortName: 'computer_name',
      }}
      ref={(table) => {
        setTable(table);
      }}
      expandableRow={config.statusIsExpandable}
      expandComponent={config.getStatusExpandedInfo}
      expandColumnOptions={{
        expandColumnVisible: true,
        expandColumnComponent: ({ isExpandableRow, isExpanded }) => {
          if (!isExpandableRow) {
            return '';
          }
          if (isExpanded) {
            return <i className="fas fa-caret-down c-grey-500" />;
          }
          return <i className="fas fa-caret-right c-grey-999" />;
        },
        columnWidth: 60,
      }}
      containerClass="old-table"
    >
      <TableHeaderColumn dataField="computer_id" hidden isKey />
      <TableHeaderColumn
        dataField="computer_name"
        dataSort
        dataFormat={(cell, row) => (
          <div
            role="presentation"
            className="cursor-pointer underline-cell"
            style={{ fontWeight: 500 }}
            onClick={() => history.push(`${links.devices}/${row.computer_id}`)}
            title={cell}
          >
            {cell}
          </div>
        )}
      >
        Name
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="blueprint_name"
        dataSort
        dataFormat={(cell, row) => (
          <div
            role="presentation"
            className="cursor-pointer underline-cell"
            onClick={() =>
              history.push(`${links.blueprints}/${row.blueprint_id}`)
            }
            title={cell}
          >
            {cell}
          </div>
        )}
      >
        Blueprint
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField={dateField}
        dataSort
        sortFunc={(a, b, order, sortField) => {
          const aDate = a[sortField] ? new Date(a[sortField]) : new Date();
          const bDate = b[sortField] ? new Date(b[sortField]) : new Date();
          if (order === 'asc') {
            return aDate - bDate;
          }
          return bDate - aDate;
        }}
        dataFormat={(cell) =>
          cell ? formatTime(cell, null, null, null, true) : ''
        }
      >
        {dateHeader}
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="status"
        dataSort
        dataFormat={(cell, row) => {
          const buttonStyle = {
            height: '26px',
            width: '80px',
            borderWidth: '1px',
            minWidth: '80px',
            padding: 0,
            margin: 0,
          };
          switch (cell) {
            case 'INCOMPATIBLE':
              return (
                <Status iColor="#D3D3D3">
                  <StatusLabel>
                    <Icon name="circle-xmark" />
                    Incompatible
                  </StatusLabel>
                </Status>
              );
            case 'INSTALLING':
            case 'pending':
            case 'PENDING':
              return (
                <Status iColor="#D3D3D3">
                  <StatusLabel>
                    <Icon name="laptop-mobile" />
                    Pending
                  </StatusLabel>
                </Status>
              );
            case 'success':
            case 'PASS':
              return (
                <Status iColor="#52C548">
                  <StatusLabel>
                    <Icon name="laptop-mobile" />
                    {successLabel}
                  </StatusLabel>
                  {config.statusCanBeFlushed(data, row) && (
                    <Button
                      kind="outline"
                      size="small"
                      onClick={getOnClickFlush(row)}
                    >
                      Flush
                    </Button>
                  )}
                </Status>
              );
            case 'REMEDIATED':
              return (
                <Status iColor="#3087e0">
                  <StatusLabel>
                    <Icon name="laptop-mobile" />
                    Remediated
                  </StatusLabel>
                  {config.statusCanBeFlushed(data, row) && (
                    <Button kind="outline" onClick={getOnClickFlush(row)}>
                      Flush
                    </Button>
                  )}
                </Status>
              );
            case 'failed':
            case 'ERROR':
              return (
                <Status iColor="#FF8A00">
                  <StatusLabel>
                    <Icon name="laptop-mobile" />
                    Error
                  </StatusLabel>
                  {config.statusCanBeFlushed(data, row) && (
                    <Button kind="outline" onClick={getOnClickFlush(row)}>
                      Flush
                    </Button>
                  )}
                </Status>
              );
            default:
              return '';
          }
        }}
      >
        Status
      </TableHeaderColumn>
    </BootstrapTable>
  );
};
