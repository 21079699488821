/* istanbul ignore file */
import { Uploader, useInputsValidators } from '@kandji-inc/bumblebee';
import Axios from 'axios';
import { i18n } from 'i18n';
import * as React from 'react';
import {
  IpaUploadStatus,
  getIPAS3Data,
  getIpaUploadStatus,
  uploadIpaApp,
} from '../in-house-apps-actions';
import type { IpaFile } from '../in-house-apps.types';
import { currentFileFromIpaFile } from '../utils/currentFileFromIpaFile';
import { getIpaUploaderIcon } from '../utils/getIpaUploaderIcon';
import {
  IPAUploadInstructions,
  IPAUploadRenderInfo,
} from './install-uploader-components';

const IPA_MAX_FILE_SIZE = 4e9;
const IPA_ALLOWED_TYPES = ['.ipa'];
const IPA_VALIDATION_FIELDS = ['ipaFile'];
export const CANCEL_ERROR = 'CANCEL TOKEN';

const IPA_FILE_ICONS = (bundleId: string | null | undefined, icon: string) => ({
  '.ipa': <img src={getIpaUploaderIcon(bundleId, icon)} alt="IPA App File" />,
});

export const IPA_EMPTY_FILE = {
  file: null,
  fileName: null,
  fileSize: null,
  appId: null,
  appVersion: null,
  sha256: null,
  appIcon: null,
  appName: null,
};

interface IPAUploaderProps {
  isDisabled: boolean;
  isSubmitted: boolean;
  ipaFile: IpaFile;
  libraryItemId: string | null | undefined;
  setShouldUpdateFile: React.Dispatch<React.SetStateAction<boolean>>;
  updateIpaFile: (updateBody: Partial<IpaFile>) => any;
  onValidate: (cb: any) => (isValid: any) => any;
}

export function IPAUploader({
  isDisabled,
  ipaFile,
  libraryItemId,
  updateIpaFile,
  onValidate,
  isSubmitted,
  setShouldUpdateFile,
}: IPAUploaderProps) {
  const { file, appIcon, appId } = ipaFile;
  const [cancelToken, setCancelToken] = React.useState(
    Axios.CancelToken.source(),
  );
  const [apiValidationError, setApiValidationError] = React.useState<
    null | string
  >(null);
  const setIsSaveEnabled = onValidate((isValid: boolean) => isValid);
  const currentFile = React.useMemo(
    () => currentFileFromIpaFile(ipaFile),
    [ipaFile],
  );

  const { onInvalidate, invalidations } = useInputsValidators(
    IPA_VALIDATION_FIELDS,
    updateIpaFile,
  );

  const validationError = (isSubmitted && invalidations[0]) || '';

  async function handleOnUpload(file, updateProgress) {
    setApiValidationError(null);

    const ipaS3Data = await getIPAS3Data(file.name, cancelToken.token);

    setIsSaveEnabled(false);

    const { upload: uploadFile } = uploadIpaApp(
      file,
      updateProgress,
      ipaS3Data,
      cancelToken.token,
    );

    updateIpaFile({
      file: ipaS3Data.post_data.key,
      id: ipaS3Data.id,
      fileName: file.name,
      fileSize: file.size,
      appIcon: null,
    });

    return uploadFile;
  }

  function handleOnUploaded(file) {
    setIsSaveEnabled(true);
    updateIpaFile({
      fileName: file.name,
      fileSize: file.size,
    });
  }

  async function handleOnValidate(
    _,
    { getUploadCurrentlyCancelled, CancelError },
  ) {
    const [isUploadCancelled, getUploadPrevCancelled] =
      getUploadCurrentlyCancelled({
        isTrackAlreadyCancelled: true,
      });

    if (isUploadCancelled || file == null) {
      const cancelError = new CancelError(
        i18n.t('Upload cancelled during validation'),
      );
      throw new Error(cancelError);
    }

    const data = await getIpaUploadStatus(
      ipaFile.id,
      cancelToken.token,
      libraryItemId,
    );

    if (data == null) {
      updateIpaFile(IPA_EMPTY_FILE);
      return;
    }

    const { sha256, metadata, status, reason } = data;
    const isAlreadyCancelled = getUploadPrevCancelled();
    const isCurrentlyCancelled = getUploadCurrentlyCancelled();
    const isCancelled = isAlreadyCancelled || isCurrentlyCancelled;

    if (!isCancelled && status !== IpaUploadStatus.VALIDATED) {
      setApiValidationError(reason);
      setIsSaveEnabled(false);
      return;
    }

    if (isCancelled) {
      updateIpaFile(IPA_EMPTY_FILE);
      return;
    }

    setShouldUpdateFile(true);
    updateIpaFile({
      appId: metadata.app_identifier,
      appIcon: metadata.app_icon,
      ipadIcon: metadata.ipad_icon,
      appVersion: metadata.app_version,
      appName: metadata.app_name,
      fileName: metadata.app_name,
      fileSize: metadata.file_size,
      sha256,
    });

    return sha256;
  }

  function handleOnError(error, { type, errorTypes }) {
    if (type !== errorTypes.fileSelectError) {
      onInvalidate(0)(error);
    }
  }

  function handleOnDelete() {
    cancelToken.cancel(CANCEL_ERROR);
    setCancelToken(Axios.CancelToken.source());
    setApiValidationError(null);
    updateIpaFile(IPA_EMPTY_FILE);
  }

  function handleOnCancel() {
    cancelToken.cancel(CANCEL_ERROR);
    setCancelToken(Axios.CancelToken.source());
    setApiValidationError(null);
    updateIpaFile(IPA_EMPTY_FILE);
  }

  return (
    <Uploader
      className="b-mb2"
      isDisabled={isDisabled}
      maxFileSize={IPA_MAX_FILE_SIZE}
      allowedTypes={IPA_ALLOWED_TYPES}
      withError={
        apiValidationError != null ? apiValidationError : validationError
      }
      fileIcons={IPA_FILE_ICONS(appId, appIcon)}
      onUpload={handleOnUpload}
      onUploaded={handleOnUploaded}
      onError={handleOnError}
      onValidate={handleOnValidate}
      onCancel={handleOnCancel}
      onDelete={handleOnDelete}
      forceWithFile={currentFile}
      uploadInstructions={IPAUploadInstructions()}
      renderInfo={() => (
        <IPAUploadRenderInfo isDisabled={isDisabled} ipaFile={ipaFile} />
      )}
    />
  );
}
