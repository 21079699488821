// src/constants.ts
var DEFAULT_LOCALE = "en_US";
var REACT_APP_TRANSIFEX_CDS_HOST = window.REACT_APP_TRANSIFEX_CDS_HOST || process.env.REACT_APP_TRANSIFEX_CDS_HOST;
var REACT_APP_TRANSIFEX_TOKEN = window.REACT_APP_TRANSIFEX_TOKEN || process.env.REACT_APP_TRANSIFEX_TOKEN;
var REACT_APP_TRANSIFEX_APP_TAG = window.REACT_APP_TRANSIFEX_APP_TAG || process.env.REACT_APP_TRANSIFEX_APP_TAG;

// src/providers/NoopProvider.tsx
import { escape as txEscape, isString as txIsString } from "@transifex/native";
import IntlMessageFormat from "intl-messageformat";
import React from "react";

// src/providers/BaseProvider.tsx
import { jsx } from "react/jsx-runtime";
var BaseProvider = class {
  constructor() {
    this.currentLocale = DEFAULT_LOCALE;
  }
  setLocale(localeCode) {
    this.currentLocale = localeCode;
    return Promise.resolve();
  }
  getLocale() {
    return this.currentLocale;
  }
  getJSLocale() {
    return this.currentLocale.split("_").join("-");
  }
  getLanguage() {
    return this.currentLocale.split("_")[0];
  }
  formatDateTime(date, options) {
    const locales = [];
    try {
      const converted = this.getJSLocale();
      Intl.DateTimeFormat.supportedLocalesOf([converted]);
      locales.push(converted);
    } catch (err) {
    }
    locales.push(this.getLanguage());
    return new Intl.DateTimeFormat(locales, options).format(date).toString();
  }
  formatNumber(num, options) {
    const locales = [];
    try {
      const converted = this.getJSLocale();
      Intl.NumberFormat.supportedLocalesOf([converted]);
      locales.push(converted);
    } catch (err) {
    }
    locales.push(this.getLanguage());
    return new Intl.NumberFormat(locales, options).format(num).toString();
  }
  translate(str, params) {
    throw new Error("Not implemented");
  }
  translateNode(str, params) {
    throw new Error("Not implemented");
  }
  translateWithHtml(str, params) {
    const newParams = { ...params, _escapeVars: true };
    const htmlStr = this.translate(str, newParams);
    return /* @__PURE__ */ jsx("span", {
      dangerouslySetInnerHTML: { __html: htmlStr }
    });
  }
};

// src/providers/NoopProvider.tsx
import { Fragment, jsx as jsx2 } from "react/jsx-runtime";
var NoopProvider = class extends BaseProvider {
  translate(str, params) {
    let safeParams = {};
    if (params === void 0) {
      safeParams = {};
    } else if (params._escapeVars) {
      Object.keys(params).forEach((param) => {
        const value = params[param];
        safeParams[param] = txIsString(value) ? txEscape(value) : value;
      });
    } else {
      safeParams = params;
    }
    let msg;
    try {
      msg = new IntlMessageFormat(str, this.getLanguage(), void 0, {
        ignoreTag: true
      });
    } catch (err) {
      msg = new IntlMessageFormat(str, void 0, void 0, {
        ignoreTag: true
      });
    }
    return msg.format(params);
  }
  translateNode(str, params) {
    const actualProps = {};
    const reactElements = [];
    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        if (React.isValidElement(value)) {
          actualProps[key] = `__txnative__${reactElements.length}__txnative__`;
          reactElements.push(value);
        } else {
          actualProps[key] = value;
        }
      });
    }
    const translation = this.translate(str, actualProps);
    const result = [];
    let lastEnd = 0;
    let lastKey = 0;
    const regexp = RegExp("__txnative__(\\d+)__txnative__", "g");
    let match = regexp.exec(translation);
    while (match !== null) {
      const chunk2 = translation.slice(lastEnd, match.index);
      if (chunk2) {
        result.push(/* @__PURE__ */ jsx2(React.Fragment, {
          children: chunk2
        }, lastKey));
        lastKey += 1;
      }
      result.push(
        React.cloneElement(reactElements[parseInt(match[1], 10)], {
          key: lastKey
        })
      );
      lastKey += 1;
      lastEnd = match.index + match[0].length;
      match = regexp.exec(translation);
    }
    const chunk = translation.slice(lastEnd);
    if (chunk) {
      result.push(/* @__PURE__ */ jsx2(React.Fragment, {
        children: chunk
      }, lastKey));
    }
    if (result.length === 0) {
      return "";
    }
    if (result.length === 1) {
      return result[0].props.children;
    }
    return /* @__PURE__ */ jsx2(Fragment, {
      children: result
    });
  }
};

// src/providers/QAProvider.tsx
var QAProvider = class extends NoopProvider {
  formatDateTime(date, options) {
    return `#/${super.formatDateTime(date, options)}/#`;
  }
  formatNumber(num, options) {
    return `#/${super.formatNumber(num, options)}/#`;
  }
  translate(str, params) {
    return `//${super.translate(str, params)}//`;
  }
};

// src/providers/TransifexProvider.tsx
import { t, tx } from "@transifex/native";
import { T } from "@transifex/react";
import { jsx as jsx3 } from "react/jsx-runtime";
var SOURCE_LANGUAGE = "en";
var TRANSIFEX_SUPPORTED_LANGUAGES = {
  en: "en",
  es: "es_419",
  de: "de",
  ja: "ja_JP"
};
var TransifexProvider = class extends BaseProvider {
  constructor(cdsHost, token, filterTags) {
    super();
    tx.init({ cdsHost, token, filterTags });
  }
  localeToLanguage(localeCode) {
    return localeCode.split("_")[0];
  }
  setLocale(localeCode) {
    this.currentLocale = localeCode;
    const language = this.localeToLanguage(localeCode);
    if (language === SOURCE_LANGUAGE) {
      return Promise.resolve();
    }
    const translationLocale = TRANSIFEX_SUPPORTED_LANGUAGES[language];
    if (!translationLocale) {
      console.error(`Unsupported Transifex locale: ${localeCode}`);
      return Promise.reject();
    }
    return tx.setCurrentLocale(translationLocale);
  }
  translate(str, params) {
    return t(str, params);
  }
  translateNode(str, params) {
    return /* @__PURE__ */ jsx3(T, {
      _str: str,
      ...params
    });
  }
};

// src/providers/index.ts
var ProviderNames = {
  noop: "noop",
  qa: "qa",
  transifex: "transifex"
};
var Providers = {
  [ProviderNames.noop]: new NoopProvider(),
  [ProviderNames.qa]: new QAProvider()
};
if (REACT_APP_TRANSIFEX_CDS_HOST && REACT_APP_TRANSIFEX_TOKEN && REACT_APP_TRANSIFEX_APP_TAG) {
  Providers[ProviderNames.transifex] = new TransifexProvider(
    REACT_APP_TRANSIFEX_CDS_HOST,
    REACT_APP_TRANSIFEX_TOKEN,
    REACT_APP_TRANSIFEX_APP_TAG
  );
}

// src/index.ts
var currentProviderName;
var currentProvider;
var setNoopProvider = () => {
  currentProviderName = ProviderNames.noop;
  currentProvider = Providers[ProviderNames.noop];
};
var setI18nProvider = (providerName) => {
  if (Providers[providerName] === void 0) {
    console.warn(
      `i18n provider '${providerName}' not found. Using noop provider`
    );
    setNoopProvider();
  } else {
    currentProviderName = providerName;
    currentProvider = Providers[providerName];
  }
};
var setI18nLocale = async (localeCode) => {
  if (!currentProvider) {
    throw new Error("i18n provider must be set before using setI18nLocale");
  }
  try {
    return await currentProvider.setLocale(localeCode);
  } catch (e) {
    console.error("i18n failed to set locale. Using noop provider");
    setNoopProvider();
  }
};
var getI18nProvider = () => {
  if (!currentProvider) {
    throw new Error("i18n provider must be set before using getI18nProvider");
  }
  return currentProviderName;
};
var getI18nLocale = () => {
  if (!currentProvider) {
    throw new Error("i18n provider must be set before using getI18nLocale");
  }
  return currentProvider.getLocale();
};
var getI18nJSLocale = () => {
  if (!currentProvider) {
    throw new Error("i18n provider must be set before using getI18nJSLocale");
  }
  return currentProvider.getJSLocale();
};
var getI18nLanguage = () => {
  if (!currentProvider) {
    throw new Error("i18n provider must be set before using getI18nLanguage");
  }
  return currentProvider.getLanguage();
};
var render = (cb) => {
  if (!currentProvider) {
    throw new Error("i18n provider must be set before using render");
  }
  return cb(getI18nLocale());
};
var formatDateTime = (date, options) => {
  if (!currentProvider) {
    throw new Error("i18n provider must be set before using formatDateTime");
  }
  return currentProvider.formatDateTime(date, options);
};
var formatNumber = (num, options) => {
  if (!currentProvider) {
    throw new Error("i18n provider must be set before using formatNumber");
  }
  return currentProvider.formatNumber(num, options);
};
var t2 = (str, params) => {
  if (!currentProvider) {
    throw new Error(
      `i18n provider must be set before using t('${str}'). Is it executing before runtime?`
    );
  }
  return currentProvider.translate(str, params);
};
var $t = (str, params) => {
  if (!currentProvider) {
    throw new Error(
      `i18n provider must be set before using $t('${str}'). Is it executing before runtime?`
    );
  }
  return currentProvider.translateNode(str, params);
};
var ut = (str, params) => {
  if (!currentProvider) {
    throw new Error(
      `i18n provider must be set before using ut('${str}'). Is it executing before runtime?`
    );
  }
  return currentProvider.translateWithHtml(str, params);
};
export {
  $t,
  ProviderNames,
  formatDateTime,
  formatNumber,
  getI18nJSLocale,
  getI18nLanguage,
  getI18nLocale,
  getI18nProvider,
  render,
  setI18nLocale,
  setI18nProvider,
  t2 as t,
  ut
};
