import { Toaster as toaster } from '@kandji-inc/bumblebee';
import { LineLoader } from 'app/components/interface/LineLoader';
import { usePermissions } from 'contexts/account';
import deepcopy from 'deepcopy';
import { usePageState } from 'features/library-items/template';
import { useGetFacetsForLibraryItem } from 'features/rules/use-rule-service';
import { i18n } from 'i18n';
import { Whoops404 } from 'pages/404';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { InterfaceContext } from 'src/contexts/interface';
import { transformToSelectOptions } from '../../data-service/blueprint/transformers';
import { useFindBlueprints } from '../../data-service/blueprint/use-blueprint-service';

const useLibraryItem = ({ initialState, useService }) => {
  const history = useHistory();
  const params = useParams();
  const permissions = usePermissions();
  const isEditable = permissions.canManageBlueprints;
  const { setIsEditingLibraryItem } = useContext(InterfaceContext);
  const [isResponseApplied, setIsResponseApplied] = useState(false);

  const itemId = params.item || params.profileId || params.templateId;

  // if we are getting an existing instance of a library item, we are not in an "adding" state
  const isAdding = !params.item && !params.profileId;

  const [savedModel, setSavedModel] = useState(initialState);
  const [model, setModel] = useState({
    ...initialState,
    id: itemId,
  });
  const { pageState, handlers, setPageState } = usePageState({
    isAdding,
  });

  // Update the InterfaceContext to match what exists in pageState
  useEffect(() => {
    setIsEditingLibraryItem(pageState.isEditing);
    return () => setIsEditingLibraryItem(false);
  }, [pageState]);

  const [bpErr, blueprintOptions] = useFindBlueprints(transformToSelectOptions);

  const itemSupportsRules = initialState.supportsRules;
  const facetMap = useGetFacetsForLibraryItem(itemSupportsRules);

  const [err, resp] = useService(model.id, { isAdding });
  useEffect(() => {
    if (resp && resp.data) {
      setModel((p) => {
        const newModel = { ...p, ...resp.data.data };
        setSavedModel(deepcopy(newModel));
        return newModel;
      });
      setIsResponseApplied(true);
    }
  }, [resp]);

  const reset = {
    add: () => {
      setPageState((p) => ({
        ...p,
        isAdding: true,
        isDisabled: false,
        isEditing: true,
      }));
    },
    edit: () => {
      setPageState((p) => ({
        ...p,
        isDisabled: true,
        isEditing: false,
      }));
    },
    validationError: () => {
      setPageState((p) => ({
        ...p,
        isDisabled: false,
        isEditing: true,
      }));
    },
  };

  const restoreModel = () => {
    setModel(deepcopy(savedModel));
  };

  if (err || bpErr) {
    toaster(err || bpErr || i18n.common.error());
  }

  let PlaceHolder;
  if (model.id && !isResponseApplied) {
    PlaceHolder = LineLoader;
  }

  if (err) {
    PlaceHolder = Whoops404;
  }

  const pageProps = {
    history,
    model,
    setModel,
    pageState,
    setPageState,
    handlers,
    reset,
    restoreModel,
    setSavedModel,
    savedModel,
    isEditable,
    blueprintOptions: blueprintOptions?.data ?? [],
    facetMap,
  };

  return { pageProps, PlaceHolder };
};

export { useLibraryItem };
export default useLibraryItem;
