import { i18n } from 'src/i18n';
import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';
import type {
  EventTypeLabel,
  EventTypeOptions,
  ItemTypeLabel,
  ItemTypeOptions,
  PostureLabel,
  PostureOptions,
} from '../avert.types';
import { EventTypeValue, ItemTypeValue, PostureValue } from '../avert.types';

class AvertService extends NewLibraryItemService {
  static postureOptions = (): PostureOptions => [
    { label: i18n.t('Protect') as PostureLabel, value: PostureValue.PROTECT },
    { label: i18n.t('Detect') as PostureLabel, value: PostureValue.DETECT },
  ];

  static itemTypeOptions = (): ItemTypeOptions => [
    { label: i18n.t('Hash') as ItemTypeLabel, value: ItemTypeValue.HASH },
    { label: i18n.t('Path') as ItemTypeLabel, value: ItemTypeValue.PATH },
  ];

  static eventTypeOptions = (): EventTypeOptions => [
    { label: i18n.t('Allowed') as EventTypeLabel, value: EventTypeValue.ALLOW },
    { label: i18n.t('Blocked') as EventTypeLabel, value: EventTypeValue.BLOCK },
  ];
}

export const avertService = new AvertService();

export default AvertService;
