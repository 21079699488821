import React from 'react';
import OktaDeviceTrustBanner from '../additional-settings/okta/components/okta-device-trust-banner';

type PageState = {
  isEditing: boolean;
};

type AdditionalProps = {
  pageState: PageState;
};
/**
 *
 * @description This utility will help return any custom banners you need displayed at the top of the app store apps page.
 * @param {string} bundleId - i.e 'com.okta.mobile'
 * @param {AdditionalProps} additionalProps - Add whatever you need here. Types may need updated
 * @return {*}  {JSX.Element}
 */
export function getExtraBanners(
  bundleId: string,
  additionalProps: AdditionalProps,
): JSX.Element {
  const { pageState } = additionalProps;

  const banners = {
    'com.okta.mobile': (
      <OktaDeviceTrustBanner isEditing={pageState.isEditing} />
    ),
  };
  return banners[bundleId];
}
