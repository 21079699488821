import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import type { PrismCategoryUri } from '../types/prism.types';

export const useLastVisitedPrismCategory = () => {
  const routeParams = useParams<{ prismCategory: PrismCategoryUri }>();
  const defaultUri = 'device_information';
  const [lastVisitedPrismCategory, setLastVisitedPrismCategory] = useState<
    string | null
  >(() => {
    // Retrieve the last visited Prism category from localStorage
    const lastVisited = localStorage.getItem('lastVisitedPrismCategory');
    return lastVisited || defaultUri;
  });

  useEffect(() => {
    const currentPrismCategory = routeParams.prismCategory;
    // Check if the current pathname is a Prism category page
    if (currentPrismCategory) {
      setLastVisitedPrismCategory(currentPrismCategory);
      // Persist the last visited Prism category in localStorage
      localStorage.setItem('lastVisitedPrismCategory', currentPrismCategory);
    } else {
      // If the current pathname is not a Prism category page, retrieve the last visited Prism category from localStorage
      const lastVisited = localStorage.getItem('lastVisitedPrismCategory');
      setLastVisitedPrismCategory(lastVisited || defaultUri);
    }
  }, [routeParams.prismCategory]);

  return lastVisitedPrismCategory;
};
