import { updateSetting as update } from '@kandji-inc/bumblebee';
import React, { useContext, useCallback, useMemo } from 'react';

import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import ActivityTab from 'features/library-items/common/activity-tab';
import NewStatusTab from 'features/library-items/common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from 'features/library-items/common/new-status-tab/util';
import { i18n } from 'i18n';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import LibraryContext from '../../routes/library.context';
import initial from './initial-state';
import ModernConfigurations from './sections/modern-configurations';
import { softwareUpdateService } from './service/software-update-service';
import { transformFromApi, transformToApi } from './service/transformers';
import useSoftwareUpdateService from './service/use-software-update-service';

const SoftwareUpdatePage = (props) => {
  const { model, setModel, pageState } = props;
  const [_, triggerValidation] = useUniqValue();

  const modernConfigurationsUpdate = useCallback(
    update('modernConfigurations', setModel),
    [],
  );

  const { itemConfig } = useContext(LibraryContext);

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.getName()}
      summaryInfoProps={{
        name: `${itemConfig.getName()} ${i18n.t('Profile')}`,
        description: itemConfig.getDescription(),
        publisher: itemConfig.getPublisher(),
        devices: itemConfig.devices,
        requirements: itemConfig.getOsRequirements(),
      }}
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={softwareUpdateService}
      defaultIcon={itemConfig.icon}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      supportsRules
      supportsDuplication
      supportsInstallOn
    >
      <ModernConfigurations
        settings={model.modernConfigurations}
        isDisabled={pageState.isDisabled}
        update={modernConfigurationsUpdate}
      />
    </LibraryItemPage>
  );
};

// SoftwareUpdatePage.propTypes = {};

const SoftwareUpdate = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState: initial(),
    useService: useSoftwareUpdateService,
  });

  const blueprintConflicts = useBlueprintConflicts();

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <SoftwareUpdatePage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
      testId="software-update-v2"
    />
  );
};

export default SoftwareUpdate;
