import { Button, Flex } from '@kandji-inc/bumblebee';
import { i18n } from 'i18n';
import React, { useRef } from 'react';

const AddableContent = ({
  max,
  ItemComponent,
  items,
  onChange,
  getEmptyItem,
  isDisabled,
  getItemProps,
  itemsClassName,
  rowClassName,
}) => {
  const itemsContainerRef = useRef();

  const removeItem = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    onChange(newItems);
  };
  const addItem = () => {
    const newItem = getEmptyItem();
    // newItem.uuid = newItem.uuid || uuidv4();
    onChange([...items, newItem]);
    setTimeout(() =>
      itemsContainerRef.current.scrollTo({
        top: itemsContainerRef.current.scrollHeight,
        behavior: 'smooth',
      }),
    );
  };

  return (
    <>
      <div className={itemsClassName} ref={itemsContainerRef}>
        {items.map((item, index) => (
          <Flex
            key={index}
            gapType="gap3"
            align="center"
            className={rowClassName}
          >
            <ItemComponent {...getItemProps(index)} />

            {items.length > 1 && (
              <Button
                theme="error"
                kind="link"
                icon="trash-can"
                onClick={() => removeItem(index)}
                disabled={isDisabled}
              />
            )}
          </Flex>
        ))}
      </div>

      <Button
        disabled={(max && items.length >= max) || isDisabled}
        className="b-mt"
        kind="link"
        icon="circle-plus"
        onClick={addItem}
      >
        {i18n.t('Add')}
      </Button>
    </>
  );
};

export default AddableContent;
