/* istanbul ignore file */
import {
  Banner,
  Select,
  SelectSearch,
  TextInput,
  useInvalidations,
} from '@kandji-inc/bumblebee';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import './styles.css';

import isEqual from 'lodash/isEqual';

import { AccountContext } from 'contexts/account';

import { updateUser } from 'app/_actions/users';
import { Setting } from 'features/library-items/template';
import { displayWeeklyEmailOptions } from 'features/user-profile/select-options';

// TODO: reimport once profile picture API is complete
// import LogoUploader from '../library-items/items/kandji-setup/v2/logo-uploader/logo-uploader';

import { timezones, userTypes } from 'app/common/constants';

import {
  findOption,
  optionsLocaleFactory,
} from 'features/company-settings/options';
import { i18n } from 'i18n';
import featureFlags from 'src/config/feature-flags';
import Actions from './actions';
import {
  initialPasswords,
  useInitialForm,
  useInitialPageState,
} from './initial-state';
import {
  dateFormatOptions,
  displayRelativeDatesOptions,
} from './select-options';
import {
  newPassword,
  passwordsMustMatch,
  requiredAndWithinCharLimit,
} from './validation';

const UserProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isEmailPasswordAuth0, userType } = useContext(AccountContext);

  const [initialForm, setInitialForm] = useState(useInitialForm());
  const [form, setForm] = useState(initialForm);
  const [page, setPage] = useState(useInitialPageState());

  // Note that password state is stored separately from the general form state because unlike
  // the general form, password field storage structure and payload structure differ.
  const [passwords, setPasswords] = useState(initialPasswords);
  const { invalidations, onInvalidate } = useInvalidations({ inputs: 4 });

  const showUserLocale = i18n.isEnabled();
  const optionsLocale = optionsLocaleFactory();

  const updatePageState = (k, v) =>
    setPage((prevState) => ({ ...prevState, [k]: v }));
  const updateForm = (k, v, isDateTimePref = false) => {
    if (isDateTimePref) {
      setForm((prevState) => ({
        ...prevState,
        settings: { ...prevState.settings, [k]: v },
      }));
    } else {
      setForm((prevState) => ({ ...prevState, [k]: v }));
    }
  };
  const updatePassword = (k, v) => setPasswords((p) => ({ ...p, [k]: v }));

  const isDisabled =
    !page.isEditing || page.isSaving || userType === userTypes.super;

  const onBack = () => {
    history.push('/devices');
  };

  const onCancel = () => {
    updatePageState('isEditing', false);
    setPasswords(initialPasswords);
    setForm(initialForm);
  };

  const onSave = () => {
    // Force validate that passwords match
    if (passwords.new !== passwords.confirmNew) {
      document.getElementById('confirm-new-password').focus();
    } else {
      // Add passwords to payload if they have been edited
      let payload = form;
      if (!isEqual(passwords, initialPasswords)) {
        payload = {
          ...payload,
          old_password: passwords.current,
          password: passwords.new,
        };
      }

      updatePageState('isSaving', true);
      updatePageState('isEditing', false);
      dispatch(updateUser(payload, true));

      // Update/reset form
      setInitialForm(form);
      setPasswords(initialPasswords);

      updatePageState('isSaving', false);
    }
  };

  // Enable/Disable the `Save` button
  useEffect(() => {
    // Either no passwords have been entered or all have been edited
    const passwordsAreValid =
      isEqual(initialPasswords, passwords) ||
      Object.values(passwords).every((v) => v.length);
    const formHasChanged =
      !isEqual(initialForm, form) || !isEqual(initialPasswords, passwords);

    updatePageState(
      'isValid',
      formHasChanged && passwordsAreValid && !invalidations.some(Boolean),
    );
  }, [initialForm, form, invalidations, passwords]);

  return (
    <div>
      <Setting.Card className="k-settings-card">
        <Setting.Header>
          <h3 className="b-h3">User Info</h3>
        </Setting.Header>
        <Setting.Rows>
          <div className="b-form-grid">
            <p className="b-txt">First name</p>
            <TextInput
              disabled={isDisabled}
              value={form.first_name}
              onChange={(e) => updateForm('first_name', e.target.value)}
              validator={requiredAndWithinCharLimit(25)}
              onInvalidate={onInvalidate(0)}
              compact
            />
            <p className="b-txt b-mt1">Last name</p>
            <TextInput
              disabled={isDisabled}
              value={form.last_name}
              onChange={(e) => updateForm('last_name', e.target.value)}
              validator={requiredAndWithinCharLimit(25)}
              onInvalidate={onInvalidate(1)}
              compact
            />
            <p className="b-txt b-mt1">Email</p>
            <TextInput
              disabled
              value={form.email}
              onChange={(e) => updateForm('email', e.target.value)}
              compact
            />
            {/* TODO: add back in once API for profile picture is complete */}
            {/* <div className="b-form-grid profile-picture-grid">
              <p className="b-txt">Profile picture</p>
              <p className="b-txt-light b-mb1">
                Upload your profile picture to be used universally across the
                web app.
                <br />
                We recommend 80x80 pixels in PNG format.
              </p>
              <LogoUploader
                className="b-mb2"
                disabled={!page.isEditing}
                icon={form.profilePicture}
                onImage={(e) => updateForm('profilePicture', e)}
              />
            </div> */}
            {isEmailPasswordAuth0 && (
              <div className="b-form-grid password-field">
                <p className="b-h3 b-mt1 b-mb1">Password</p>
                <Banner className="b-mb1" theme="info" icon="circle-info">
                  <p>Password must be at least 8 characters.</p>
                </Banner>
                {page.isEditing ? (
                  <>
                    <p className="b-txt">Current password</p>
                    <TextInput
                      type="password"
                      placeholder="Enter your existing password"
                      value={passwords.current}
                      onChange={(e) =>
                        updatePassword('current', e.target.value)
                      }
                    />
                    <p className="b-txt b-mt1">New password</p>
                    <TextInput
                      type="password"
                      placeholder="Password"
                      value={passwords.new}
                      onChange={(e) => updatePassword('new', e.target.value)}
                      validator={newPassword(
                        8,
                        'Your password',
                        passwords.current,
                      )}
                      onInvalidate={onInvalidate(2)}
                    />
                    <p className="b-txt b-mt1">Confirm new password</p>
                    <TextInput
                      id="confirm-new-password"
                      type="password"
                      placeholder="Password"
                      value={passwords.confirmNew}
                      onChange={(e) =>
                        updatePassword('confirmNew', e.target.value)
                      }
                      validator={passwordsMustMatch(passwords.new)}
                      onInvalidate={onInvalidate(3)}
                    />
                  </>
                ) : (
                  <>
                    <p className="b-txt">Current password</p>
                    <TextInput type="password" value="*********" disabled />
                  </>
                )}
              </div>
            )}
          </div>
        </Setting.Rows>
      </Setting.Card>
      <Setting.Card>
        <Setting.Header>
          <h3 className="b-h3">Preferences</h3>
        </Setting.Header>
        <Setting.Rows>
          <div className="b-form-grid">
            <p className="b-txt">Time zone</p>
            <SelectSearch
              placeholder="Search or Select From List"
              compact
              disabled={isDisabled}
              value={timezones.find(
                (option) => option.value === form.settings.timezone,
              )}
              onChange={(selected) =>
                updateForm('timezone', selected.value, true)
              }
              options={timezones}
            />
            {showUserLocale && (
              <>
                <p className="b-txt b-mt1">Language</p>
                <Select
                  placeholder="Search or Select From List"
                  compact
                  disabled={isDisabled}
                  value={optionsLocale.find(
                    (option) => option.value === form.locale,
                  )}
                  onChange={(v) => updateForm('locale', v.value)}
                  options={optionsLocale}
                />
              </>
            )}
            {!showUserLocale && (
              <>
                <p className="b-txt b-mt1">Date format</p>
                <Select
                  placeholder="Search or Select From List"
                  compact
                  disabled={isDisabled}
                  value={dateFormatOptions.find(
                    (option) =>
                      option.value === form.settings.preferred_date_format,
                  )}
                  onChange={(selected) =>
                    updateForm('preferred_date_format', selected.value, true)
                  }
                  options={dateFormatOptions}
                />
              </>
            )}
            <p className="b-txt b-mt1">Display relative dates</p>
            <Select
              placeholder="Search or Select From List"
              compact
              disabled={isDisabled}
              value={displayRelativeDatesOptions.find(
                (option) =>
                  option.value === form.settings.disable_relative_dates,
              )}
              onChange={(selected) =>
                updateForm('disable_relative_dates', selected.value, true)
              }
              options={displayRelativeDatesOptions}
            />
            <p className="b-txt b-mt1">Receive weekly status emails</p>
            <Select
              testId="send_weekly_emails_pref"
              placeholder="Search or Select From List"
              compact
              disabled={isDisabled}
              value={displayWeeklyEmailOptions.find(
                (option) => option.value === form.settings.send_weekly_emails,
              )}
              onChange={
                /* istanbul ignore next */
                (selected) =>
                  updateForm('send_weekly_emails', selected.value, true)
              }
              options={displayWeeklyEmailOptions}
            />
          </div>
        </Setting.Rows>
      </Setting.Card>
      <Actions
        pageState={page}
        onBack={onBack}
        onCancel={onCancel}
        onEdit={() => updatePageState('isEditing', true)}
        onSave={onSave}
      />
    </div>
  );
};

export default UserProfile;
