import moment from 'moment';
import uuid from 'uuid';
import initialState from '../../../../initial-state';
import { protocolOptions } from '../../../../protocols-card/protocols-card.model';
import WifiService from '../../../wifi-service';

const { authentication: authKeys } = WifiService.keys;

/* Security types are locally stored as a combination of the security and
enterprise/personal. When we send to the BE, we only send the security type with
a separate field for isEnterprise. This function returns the combination of the
two that the current UI expects for values, ex. WPA-PERSONAL */
const getSecurityType = (security, isEnterprise) => {
  const enterpriseSecurities = {
    WEP: WifiService.securityTypes.DYNAMIC_WEP,
    WPA: WifiService.securityTypes.WPA_ENTERPRISE,
    WPA2: WifiService.securityTypes.WPA2_ENTERPRISE,
    WPA3: WifiService.securityTypes.WPA3_ENTERPRISE,
    Any: WifiService.securityTypes.ANY_ENTERPRISE,
  };

  const nonEnterpriseSecurities = {
    WEP: WifiService.securityTypes.WEP,
    WPA: WifiService.securityTypes.WPA_PERSONAL,
    WPA2: WifiService.securityTypes.WPA2_PERSONAL,
    WPA3: WifiService.securityTypes.WPA3_PERSONAL,
    None: WifiService.securityTypes.NONE,
    Any: WifiService.securityTypes.ANY,
  };

  if (isEnterprise) {
    return enterpriseSecurities[security] || WifiService.securityTypes.NONE;
  }
  return nonEnterpriseSecurities[security] || WifiService.securityTypes.NONE;
};

const authentication = (data) => {
  if (!data) {
    return initialState.authentication;
  }

  const {
    EncryptionType = WifiService.securityTypes.NONE,
    Password = '',
    authentication: authenticationData = {},
    certificate = {},
  } = data;

  const { isEnterprise = false, settings = {} } = authenticationData || {};
  const { SetupModes = [], EAPClientConfiguration = {} } = settings || {};

  const {
    type = WifiService.identityCertificates.NONE,
    adcsUpdatedAt,
    scepUpdatedAt,
    settings: certificateSettings = {},
  } = certificate || {};

  const acceptEapTypes =
    EAPClientConfiguration?.AcceptEapTypes?.map((v) =>
      protocolOptions.find(({ value }) => v === value),
    )?.filter(Boolean) || [];

  const hasTLSVersions =
    isEnterprise &&
    [
      WifiService.protocols.TLS,
      WifiService.protocols.TTLS,
      WifiService.protocols.PEAP,
      WifiService.protocols.EAP_FAST,
    ].some((protocol) =>
      acceptEapTypes.find(({ value }) => protocol === value),
    );

  const isTTLS =
    isEnterprise &&
    acceptEapTypes.find(({ value }) => WifiService.protocols.TTLS === value);

  const hasRandValues =
    isEnterprise &&
    acceptEapTypes.find(({ value }) => WifiService.protocols.EAP_SIM === value);

  const withScepSubjectAltName = certificateSettings?.scep
    ? certificateSettings.scep.SubjectAltName !== null
    : false;

  const withAdcsSubjectAltName = certificateSettings?.adcs
    ? certificateSettings.adcs.san !== null
    : false;

  const getSubjectAltName = (withSan, san) =>
    withSan
      ? Object.keys(san).reduce(
          (a, c) => [
            ...a,
            ...(san[c]
              ? san[c].map((alt) => ({
                  _id: uuid(),
                  type: c,
                  value: alt,
                }))
              : []),
          ],
          [],
        )
      : [{ _id: uuid(), type: null, value: '' }];

  const getSubjectAltNameForAdcs = (withSan, san) => {
    const sanWindowsToNormal = Object.keys(
      WifiService.subjectAlternativeNameTypesWindows,
    ).reduce((ret, key) => {
      ret[WifiService.subjectAlternativeNameTypesWindows[key]] = key;
      return ret;
    }, {});

    return withSan
      ? san.map((s) => ({
          ...s,
          _id: uuid(),
          type: sanWindowsToNormal[s.type],
        }))
      : [{ _id: uuid(), type: null, value: '' }];
  };

  return {
    [authKeys.securityType]: getSecurityType(
      EncryptionType || WifiService.securityTypes.NONE,
      isEnterprise,
    ),
    [authKeys.securityPassword]: Password || '',
    [authKeys.useLoginWindow]: SetupModes?.includes('Loginwindow'),
    [authKeys.identityCertificate]:
      type || WifiService.identityCertificates.NONE,

    [authKeys.acceptedEapTypes.acceptedEapTypes]: acceptEapTypes,
    [authKeys.acceptedEapTypes.username]:
      EAPClientConfiguration?.UserName || '',
    [authKeys.acceptedEapTypes.password]:
      EAPClientConfiguration?.UserPassword || '',
    [authKeys.acceptedEapTypes.authentication]:
      EAPClientConfiguration?.SystemModeCredentialsSource === 'ActiveDirectory'
        ? WifiService.authentications.COMPUTER_AD
        : EAPClientConfiguration?.SystemModeUseOpenDirectoryCredentials
          ? WifiService.authentications.COMPUTER_OD
          : WifiService.authentications.USERNAME_AND_PASSWORD,
    [authKeys.acceptedEapTypes.innerAuthentication]: isTTLS
      ? EAPClientConfiguration?.TTLSInnerAuthentication
      : WifiService.innerAuthentications.MSCHAPv2,
    [authKeys.acceptedEapTypes.outerIdentity]:
      EAPClientConfiguration?.OuterIdentity || '',
    [authKeys.acceptedEapTypes.tlsMinimumVersion]: hasTLSVersions
      ? EAPClientConfiguration?.TLSMinimumVersion
      : WifiService.tlsVersions.v1_0,
    [authKeys.acceptedEapTypes.tlsMaximumVersion]: hasTLSVersions
      ? EAPClientConfiguration?.TLSMaximumVersion
      : WifiService.tlsVersions.v1_2,
    [authKeys.acceptedEapTypes.usePac]:
      EAPClientConfiguration?.EAPFASTUsePAC || false,
    [authKeys.acceptedEapTypes.provisionPac]:
      EAPClientConfiguration?.EAPFASTProvisionPAC || false,
    [authKeys.acceptedEapTypes.provisionPacAnonymously]:
      EAPClientConfiguration?.EAPFASTProvisionPACAnonymously || false,
    [authKeys.acceptedEapTypes.randValue]: hasRandValues
      ? EAPClientConfiguration?.EAPSIMNumberOfRANDs
      : WifiService.randValues[3],
    [authKeys.acceptedEapTypes.twoFactorAuth]:
      EAPClientConfiguration?.TLSCertificateIsRequired || false,

    // PKCS12
    [authKeys.pkcs.allowAccessToThePrivateKey]:
      certificateSettings?.pkcs12?.AllowAllAppsAccess || false,
    [authKeys.pkcs.preventExtraction]:
      certificateSettings?.pkcs12?.KeyIsExtractable != null
        ? !certificateSettings?.pkcs12?.KeyIsExtractable
        : false,
    [authKeys.pkcs.allowAccessToThePrivateKey]:
      certificateSettings?.pkcs12?.AllowAllAppsAccess || false,
    [authKeys.pkcs.password]: certificateSettings?.pkcs12?.Password || '',
    [authKeys.pkcs.file]:
      type === WifiService.identityCertificates.PKCS12
        ? {
            base64: certificateSettings?.pkcs12?.PayloadContent,
            name: certificate?.fileName || '',
            size: certificate?.fileSize || 0,
            uploadedAt: certificate?.fileUploadedAt || moment.now(),
          }
        : null,

    // SCEP
    [authKeys.scep.url]: certificateSettings?.scep?.URL || '',
    [authKeys.scep.name]: certificateSettings?.scep?.Name || '',
    [authKeys.scep.challenge]: certificateSettings?.scep?.Challenge || '',
    [authKeys.scep.fingerprint]: certificateSettings?.scep?.CAFingerprint || '',
    [authKeys.scep.subject]: certificateSettings?.scep?.Subject || '',
    [authKeys.scep.withSan]: withScepSubjectAltName,
    [authKeys.scep.san]: getSubjectAltName(
      withScepSubjectAltName,
      certificateSettings?.scep?.SubjectAltName,
    ),
    [authKeys.scep.keySize]:
      certificateSettings?.scep?.Keysize || WifiService.keySizes.s1024,
    [authKeys.scep.keyUsage]:
      certificateSettings?.scep?.['Key Usage'] || WifiService.keyUsages.NONE,
    [authKeys.scep.retries]:
      certificateSettings?.scep?.Retries != null
        ? String(certificateSettings.scep.Retries)
        : '3',
    [authKeys.scep.withRetries]: certificateSettings?.scep?.Retries != null,
    [authKeys.scep.retryDelay]:
      certificateSettings?.scep?.RetryDelay != null
        ? String(certificateSettings?.scep?.RetryDelay)
        : '10',
    [authKeys.scep.withRetryDelay]:
      certificateSettings?.scep?.RetryDelay != null,
    [authKeys.scep.disallowKeyExtraction]:
      certificateSettings?.scep?.KeyIsExtractable !== null
        ? !certificateSettings?.scep?.KeyIsExtractable
        : false,
    [authKeys.scep.allowAccessToApps]:
      certificateSettings?.scep?.AllowAllAppsAccess,
    [authKeys.scep.withCertificateExpirationNotification]:
      certificateSettings?.scep?.certificateExpirationNotification != null,
    [authKeys.scep.certificateExpirationNotification]:
      certificateSettings?.scep?.certificateExpirationNotification != null
        ? String(certificateSettings?.scep?.certificateExpirationNotification)
        : '14',
    [authKeys.scep.withAutoProfileRedistribution]:
      certificateSettings?.scep?.autoProfileRedistribution != null,
    [authKeys.scep.autoProfileRedistribution]:
      certificateSettings?.scep?.autoProfileRedistribution != null
        ? String(certificateSettings?.scep?.autoProfileRedistribution)
        : '30',
    [authKeys.scep.updatedAt]: scepUpdatedAt,

    // AD CS
    [authKeys.adcs.name]: certificateSettings?.adcs?.name || '',
    [authKeys.adcs.template]: certificateSettings?.adcs?.template || '',
    [authKeys.adcs.subject]: certificateSettings?.adcs?.subject || '',
    [authKeys.adcs.server]: certificateSettings?.adcs?.authority || '',
    [authKeys.adcs.keySize]:
      certificateSettings?.adcs?.key_size || WifiService.keySizes.s1024,
    [authKeys.adcs.preventExtraction]:
      certificateSettings?.adcs?.KeyIsExtractable !== null
        ? !certificateSettings?.adcs?.KeyIsExtractable
        : false,
    [authKeys.adcs.allowAccessToApps]:
      certificateSettings?.adcs?.AllowAllAppsAccess,
    [authKeys.adcs.withSan]: withAdcsSubjectAltName,
    [authKeys.adcs.san]: getSubjectAltNameForAdcs(
      withAdcsSubjectAltName,
      certificateSettings?.adcs?.san,
    ),
    [authKeys.adcs.updatedAt]: adcsUpdatedAt,
  };
};

export default authentication;
