/* istanbul ignore file */
import { Button } from '@kandji-inc/bumblebee';
import { i18n } from 'i18n';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import {
  getUsersCounts as callGetUsersCounts,
  restoreUsers as callRestoreUsers,
} from '../../../_actions/gSuite';
import { setSnackbar as callSetSnackbar } from '../../../_actions/ui';
import { LineLoader } from '../LineLoader';
import { ModalWrapper } from './ModalWrapper';
import { ButtonsWrapper, CancelButtonWrapper } from './elements';

const BodyText = styled.p`
  font-family: var(--font-family-primary);
  font-weight: 400;
`;

const BodyTextBold = styled(BodyText)`
  font-weight: 500;
`;

export class RestoreGSuiteUsers extends ModalWrapper {
  constructor(props) {
    super(props);
    this.modalName = 'RestoreGSuiteUsers';
    this.state = {
      isLoading: false,
    };
  }

  onRestore = () => {
    const { restoreUsers, setSnackbar, getUsersCounts } = this.props;
    const { selectedRows, onRestore } = this.props.info;
    const data = [...selectedRows].map((id) => ({ id, is_archived: false }));
    const fields = [
      'archived_g_suite_users',
      'device_g_suite_users',
      'without_devices_g_suite_users',
    ];
    const userVerb =
      selectedRows.size === 1 ? i18n.t('User was') : i18n.t('Users were');
    const snackbarText = i18n.t('{userVerb} restored successfully', {
      userVerb,
    });
    this.setState(
      {
        isLoading: true,
      },
      () => {
        restoreUsers(data)
          .then(() => {
            getUsersCounts(fields);
          })
          .then(() => {
            setSnackbar(snackbarText);
            onRestore();
            this.onHide();
          })
          .catch((err) => {
            setSnackbar(err.message);
          })
          .finally(() => this.setState({ isLoading: false }));
      },
    );
  };

  renderHeader = () => i18n.t('Confirm Action');

  renderBody() {
    const { isLoading } = this.state;
    if (isLoading) {
      return <LineLoader />;
    }
    return (
      <>
        <BodyTextBold>
          {i18n.t('Are you sure you want to restore chosen users?')}
        </BodyTextBold>
        <BodyText>
          {i18n.t(
            'Only visible (non-archived) Device Users can have assigned devices.',
          )}
        </BodyText>
      </>
    );
  }

  renderFooter() {
    const { isLoading } = this.state;
    return (
      <ButtonsWrapper>
        <CancelButtonWrapper>
          <Button type="button" onClick={this.onHide} kind="outline">
            {i18n.t('Cancel')}
          </Button>
        </CancelButtonWrapper>
        <Button type="button" disabled={isLoading} onClick={this.onRestore}>
          {i18n.t('Confirm')}
        </Button>
      </ButtonsWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      restoreUsers: callRestoreUsers,
      setSnackbar: callSetSnackbar,
      getUsersCounts: callGetUsersCounts,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(RestoreGSuiteUsers);
