import {
  Button,
  DialogPrimitives as Dialog,
  styled,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import { getDisplayName } from 'src/features/visibility/prism/implemented-category-uris';

export type PrismDeleteViewConfirmModalProps = {
  onConfirm: () => void;
  onClose: () => void;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  viewName: string;
  categoryName: string;
};

const Paragraph = styled('p', {
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
});

const PrismDeleteViewConfirmModal = ({
  onConfirm,
  onClose,
  isOpen,
  onOpenChange,
  viewName,
  categoryName,
}: PrismDeleteViewConfirmModalProps) => {
  const categoryDisplayName = getDisplayName(categoryName);
  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Content
        hideClose
        onOpenAutoFocus={(e: Event) => e.preventDefault()}
        css={{
          width: '480px',
          height: 'auto',
        }}
      >
        <Dialog.Header>
          <Dialog.Title>{i18n.t('Delete View')}</Dialog.Title>
        </Dialog.Header>
        <Dialog.Description as="div">
          <Paragraph>
            {categoryName
              ? i18n.t(
                  'Are you sure you want to delete {viewName} and remove it from {categoryDisplayName}?',
                  { viewName, categoryDisplayName },
                )
              : i18n.t('Are you sure you want to delete {viewName}?', {
                  viewName,
                })}
          </Paragraph>
          <p>{i18n.t('This action is permanent and can not be undone.')}</p>
        </Dialog.Description>
        <Dialog.Footer>
          <Button variant="subtle" compact onClick={onClose}>
            {i18n.t('Cancel')}
          </Button>
          <Button variant="danger" compact onClick={onConfirm}>
            {i18n.t('Delete')}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default PrismDeleteViewConfirmModal;
