/* istanbul ignore file */
import { agentLogsApi } from 'app/api/base';

class AgentLogsService {
  service: NonNullable<typeof agentLogsApi>;

  constructor() {
    if (!agentLogsApi) {
      throw new Error('agent logs API not found');
    }
    this.service = agentLogsApi;
  }

  async fetchData(endpoint: string, filter = {}, from = 0, size = 100) {
    let query = `_from=${from}&_size=${size}`;

    if (Object.keys(filter).length > 0) {
      const filterString = encodeURIComponent(JSON.stringify(filter));
      query = `filter=${filterString}&${query}`;
    }

    try {
      const results = await this.service(`${endpoint}${query}`).get();
      return results;
    } catch (error) {
      return [];
    }
  }

  async getLogs(filter = {}, from = 0, size = 100) {
    const results = await this.fetchData('/v1/search/?', filter, from, size);

    const logs = (results?.data?.logs || []).map((log) => ({
      ...log,
      type: 'log',
    }));

    return { ...results, data: { ...results.data, logs } };
  }

  async getEvents(filter = {}, from = 0, size = 100) {
    const results = await this.fetchData(
      '/v1/events/search/?',
      filter,
      from,
      size,
    );

    const logs = (results?.data?.logs || []).map((log) => {
      const { tenant_id, type, device_id, ...event } = log;
      return {
        ...log,
        date: log['@timestamp'],
        composedMessage: event,
      };
    });

    return { ...results, data: { ...results.data, logs } };
  }
}

const agentLogsService = new AgentLogsService();
export default agentLogsService;
export { agentLogsService };
