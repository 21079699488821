// istanbul ignore file
import { Flex, styled } from '@kandji-inc/nectar-ui';
import * as React from 'react';
import { usePrismAIContext } from '../contexts/PrismAIContext';
import {
  useAskChatMutation,
  useGetChatMessageQuery,
  useGetChatQuery,
  useNewChatMutation,
} from './ai-queries';
import type { ChatMessage } from './ai.types';
import {
  ChatDisclaimer,
  ChatHeader,
  ChatInput,
  ChatTemplates,
  EmptyState,
  Messages,
} from './components';

const template = [
  {
    id: 1,
    title: 'Latest version of Zoom',
    description: 'What versions of Zoom are installed across my devices?',
  },
  {
    id: 2,
    title: 'FileVault Status is Off',
    description:
      'Generate a list of devices that do not have FileVault enabled.',
  },
  {
    id: 3,
    title: 'Activation Lock disabled',
    description: 'How many of my devices do not have Activation Lock enabled?',
  },
  {
    id: 4,
    title: 'Application Firewall disabled',
    description: 'Which of my devices have Application Firewall disabled?',
  },
];

export const ChatWindow = () => {
  const [chatSessionId, setChatSessionId] = React.useState<string | null>(null);
  const [chatResponseId, setChatResponseId] = React.useState<string | null>(
    null,
  );
  const [messages, setMessages] = React.useState<ChatMessage[]>([]);

  const { onOpenChange } = usePrismAIContext();
  const { data: chatSession, refetch: refetchChatSession } =
    useGetChatQuery(chatSessionId);
  const { mutateAsync: createNewChat } = useNewChatMutation();
  const { mutateAsync: askChat } = useAskChatMutation();
  const { data: chatResponse } = useGetChatMessageQuery(chatResponseId);

  const messagesEndRef = React.useRef<HTMLDivElement>(null);

  const scrollToBottom = React.useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  React.useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  // istanbul ignore next
  const handleSubmit = async (message?: string) => {
    const sessionId = chatSessionId ?? (await createNewChat()).id;
    const askResponse = await askChat({
      chat_session_id: sessionId,
      user_chat: message || 'Hello, I need help',
    });
    if (message) {
      setMessages((currentMessages) => [
        ...currentMessages,
        {
          id: `message-${currentMessages.length}`,
          created_at: new Date().toISOString(),
          confidence: 'positive',
          sender: 'user',
          message_components: [
            {
              component_type: 'text',
              data: { text: message },
            },
          ],
        },
      ]);
    }
    if (askResponse) {
      setChatResponseId(askResponse.id);
    }
    setChatSessionId(sessionId);
    await refetchChatSession();
  };

  // istanbul ignore next
  const startNewChat = async () => {
    const newChat = await createNewChat();
    setMessages([]);
    setChatSessionId(newChat.id);
  };

  // istanbul ignore next
  React.useEffect(() => {
    if (chatResponse?.status === 'succeeded') {
      refetchChatSession();
    }
    if (chatResponse?.status === 'in_progress') {
      setMessages((currentMessages) => [
        ...currentMessages,
        {
          id: 'loading',
          created_at: new Date().toISOString(),
          confidence: 'positive',
          sender: 'bot',
          message_components: [
            {
              component_type: 'loading',
              data: true,
            },
          ],
        },
      ]);
    }
  }, [chatResponse, refetchChatSession]);

  // istanbul ignore next
  React.useEffect(() => {
    if (
      Array.isArray(chatSession?.messages) &&
      chatSession?.messages.length > 0
    ) {
      setMessages(chatSession?.messages);
    }
  }, [chatSession]);

  return (
    <Container flow="column" justifyContent="end" alignItems="start">
      <ChatHeader onOpenChange={onOpenChange} onCreateNewChat={startNewChat} />
      <ContentContainer>
        <ScrollableArea>
          {!chatSessionId || messages.length === 0 ? (
            <>
              <EmptyState />
              <ChatTemplates
                templates={template}
                onApplyTemplate={handleSubmit}
              />
            </>
          ) : (
            <MessagesContainer>
              <Messages messages={messages} chatSessionId={chatSessionId} />
              <div ref={messagesEndRef} />
            </MessagesContainer>
          )}
        </ScrollableArea>
        <ChatFooter>
          <ChatInput
            onSubmit={handleSubmit}
            disabled={messages.some((m) => m.id === 'loading')}
          />
          <ChatDisclaimer />
        </ChatFooter>
      </ContentContainer>
    </Container>
  );
};

const Container = styled(Flex, {
  width: '100%',
  height: '100%',
  padding: '0px 24px',
  flexShrink: 0,
  borderLeft: '1px solid $neutral20',
  boxShadow: '0px 0px 0px 0px rgba(15, 19, 23, 0.00)',
});

const ContentContainer = styled(Flex, {
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0 0',
  overflowY: 'auto',
  width: '100%',
});

const MessagesContainer = styled(Flex, {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginTop: 'auto',
});

const ChatFooter = styled(Flex, {
  display: 'flex',
  paddingTop: '16px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '$2',
  alignSelf: 'stretch',
  borderRadius: '8px',
});

// We may add this back in the future
// const Loading = () => {
//   return (
//     <Flex
//       flow="column"
//       alignItems="center"
//       css={{
//         gap: '32px',
//         flex: '1 0 0',
//         alignSelf: 'stretch',
//       }}
//     >
//       <Loader size="xl" isShown />
//       <Text
//         css={{
//           alignSelf: 'stretch',
//           color: 'var(--text-primary, #1F272F)',
//           textAlign: 'center',
//           fontSize: '20px',
//           fontStyle: 'normal',
//           fontWeight: 500,
//           lineHeight: '32px /* 160% */',
//           letterSpacing: '-0.8px',
//         }}
//       >
//         Generating your answer...
//       </Text>
//     </Flex>
//   );
// };

const ScrollableArea = styled(Flex, {
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0 0',
  overflowY: 'auto',
  maxHeight: 'calc(100vh - 200px)',
  paddingRight: '8px',
  width: '100%',
});
