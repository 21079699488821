import type { GetBehavioralThreatGroupResponse } from '../../../../threat.types';

const fromResponseFormat = (
  response: GetBehavioralThreatGroupResponse,
): GetBehavioralThreatGroupResponse => {
  const responseTransformedFromApi: GetBehavioralThreatGroupResponse = {
    ...response,
    data: response.data.map((threat) => ({
      ...threat,
      blocked_count: threat.blocked_count || null,
      detected_count: threat.detected_count || null,
      informational_count: threat.informational_count || null,
      description: threat.description || null,
      malware_family: threat.malware_families || null,
      informational_tags: threat.informational_tags || null,
    })),
  };

  return responseTransformedFromApi;
};

export default fromResponseFormat;
