import { Badge, styled } from '@kandji-inc/nectar-ui';
import { createColumnHelper } from '@tanstack/react-table';
import { i18n } from 'i18n';
import { formatTime } from 'src/app/components/common/helpers';
import type colors from 'src/features/blueprints/colors';
import { useADEListViewStore } from '../store/ADEListViewStore';
import { highlightedText } from '../utils/highlightText';
import { ADECellAssetTag } from './ADECellAssetTag';
import { ADECellBlueprintSelect } from './ADECellBlueprintSelect';
import { ADECellItalic, getDeviceCell } from './ADECellComponents';
import { ADECellSelected, ADEColumnSelected } from './ADECellSelected';
import { ADECellUserSelect } from './ADECellUserSelect';
import { ADETableProfileHeader } from './ADETableProfileHeader';

type Colors = keyof typeof colors;

export const UserSelections = {
  EMPTY: 'Select user',
  NOT_ASSIGNED: 'Not assigned',
};

export const LocalizedUserSelections = {
  [UserSelections.EMPTY]: () => i18n.t('Select user'),
  [UserSelections.NOT_ASSIGNED]: () => i18n.t('Not assigned'),
};

export function isUser(user: User | string): user is User {
  if (typeof user !== 'string') return true;
}

export type MDMDevice = {
  id: string;
  name: string;
  enrolled_at: string;
};

export interface Blueprint {
  id: string;
  name: string;
  icon: string;
  color: Colors;
}

export interface User {
  id: string;
  name: string;
  photo?: string;
}

export type Device = {
  blueprint: Blueprint;
  mdm_device: MDMDevice | null;
  user: User | null;
  dep_account: {
    id: string;
    server_name: string;
  };
  asset_tag: string | null;
  color: string;
  description: string;
  device_assigned_by: string;
  device_assigned_date: string;
  device_family: string;
  model: string;
  os: string;
  profile_assign_time: string;
  profile_push_time: string;
  profile_status: string;
  serial_number: string;
  id: string;
  last_assignment_status: string;
  failed_assignment_attempts: number;
  assignment_status_received_at: string;
};

export enum ADETableColumnIds {
  SELECTED = 'selected',
  DEVICE_NAME = 'mdm_device__computer__name',
  SERIAL_NUMBER = 'serial_number',
  MODEL = 'model',
  DESCRIPTION = 'description',
  BLUEPRINT = 'blueprint__name',
  USER = 'user__name',
  ASSET_TAG = 'asset_tag',
  PROFILE = 'profile_status',
  TOKEN = 'dep_account__server_name',
  ENROLLMENT_STATUS = 'mdm_device__computer__enrollment_status',
  DEVICE_ASSIGNED_DATE = 'device_assigned_date',
  DEVICE_ASSIGNED_BY = 'device_assigned_by',
  COLOR = 'color',
  DEVICE_FAMILY = 'device_family',
  LAST_ENROLLMENT = 'mdm_device__computer__enrolled_at',
  PROFILE_ASSIGNED = 'profile_assign_time',
}

const columnHelper = createColumnHelper<Device>();
const COLUMN_SIZES = {
  XS: 124,
  SM: 148,
  LG: 174,
  XXL: 240,
  INPUT: 215,
};

export function SearchableText({ children }) {
  const searchText = useADEListViewStore((state) => state.searchText);
  return highlightedText(children, searchText);
}

export function buildTextColumn(
  key: keyof Device,
  displayName: string,
  searchable = true,
) {
  return columnHelper.accessor(key, {
    id: key,
    header: displayName,
    cell: (info) => {
      if (!searchable) return info.getValue();

      return <SearchableText>{info.getValue()}</SearchableText>;
    },
    meta: {
      displayName,
    },
  });
}

export function buildDefaultHiddenColumn(
  key: keyof Device,
  displayName: string,
  defaultHidden = true,
) {
  return columnHelper.accessor(key, {
    id: key,
    header: displayName,
    cell: (info) => <SearchableText>{info.getValue()}</SearchableText>,
    meta: {
      displayName,
      defaultHidden,
    },
  });
}

export function selectedColumn(defaultHidden: boolean) {
  return columnHelper.display({
    id: ADETableColumnIds.SELECTED,
    header: () => <ADEColumnSelected />,
    size: 60,
    cell: (info) => {
      const device = info.row.original;
      return <ADECellSelected device={device} />;
    },
    meta: {
      displayName: i18n.t('Selected'),
      defaultHidden,
    },
  });
}

export function deviceNameColumn(isAllDevices = false) {
  return columnHelper.accessor('mdm_device', {
    id: ADETableColumnIds.DEVICE_NAME,
    header: i18n.t('Device name'),
    cell: getDeviceCell,
    size: COLUMN_SIZES.LG,
    meta: {
      displayName: i18n.t('Device name'),
      defaultHidden: !isAllDevices,
    },
  });
}

export function blueprintColumn(isAllDevices = false) {
  return columnHelper.accessor('blueprint', {
    id: ADETableColumnIds.BLUEPRINT,
    header: i18n.t('Blueprint'),
    size: COLUMN_SIZES.INPUT,
    minSize: COLUMN_SIZES.INPUT,
    cell: (info) => {
      const device = info.row.original;
      const blueprint = device.blueprint;

      if (blueprint == null) return null;

      return isAllDevices ? (
        <ADECellItalic device={device}>{blueprint.name}</ADECellItalic>
      ) : (
        <ADECellBlueprintSelect deviceId={device.id} blueprint={blueprint} />
      );
    },
    meta: {
      displayName: i18n.t('Blueprint'),
    },
  });
}

export function userColumn(isAllDevices = false) {
  return columnHelper.accessor('user', {
    id: ADETableColumnIds.USER,
    header: i18n.t('User'),
    size: COLUMN_SIZES.INPUT,
    minSize: COLUMN_SIZES.INPUT,
    cell: (info) => {
      const device = info.row.original;
      const userName = device.user != null ? device.user.name : '-';

      return isAllDevices ? (
        <ADECellItalic device={device}>{userName}</ADECellItalic>
      ) : (
        <ADECellUserSelect deviceId={device.id} user={device.user} />
      );
    },
    meta: {
      displayName: i18n.t('User'),
    },
  });
}

export function assetTagColumn(isAllDevices = false) {
  return columnHelper.accessor('asset_tag', {
    header: i18n.t('Asset tag'),
    id: ADETableColumnIds.ASSET_TAG,
    size: COLUMN_SIZES.INPUT,
    minSize: COLUMN_SIZES.INPUT,
    cell: (info) => {
      const device = info.row.original;
      const assetTagAllValue =
        device.asset_tag != null && device.asset_tag.trim().length > 0
          ? device.asset_tag
          : '-';

      return isAllDevices ? (
        <ADECellItalic device={device}>{assetTagAllValue}</ADECellItalic>
      ) : (
        <ADECellAssetTag
          deviceId={device.id}
          assetTagValue={device.asset_tag}
        />
      );
    },
    meta: {
      displayName: i18n.t('Asset tag'),
    },
  });
}

export function tokenColumn() {
  return columnHelper.accessor('dep_account', {
    header: i18n.t('Token'),
    id: ADETableColumnIds.TOKEN,
    size: COLUMN_SIZES.SM,
    cell: (info) => {
      const { server_name: token } = info.getValue();
      return <span title={token}>{token}</span>;
    },
    meta: {
      displayName: i18n.t('Token'),
    },
  });
}

export function enrollmentStatusColumn(isAllDevices = false) {
  return columnHelper.accessor('mdm_device', {
    header: i18n.t('Enrollment status'),
    id: ADETableColumnIds.ENROLLMENT_STATUS,
    cell: (info) => {
      const device = info.getValue();
      if (device == null) {
        return <Badge color="neutral">{i18n.t('Awaiting')}</Badge>;
      }

      return <Badge color="green">{i18n.t('Enrolled')}</Badge>;
    },
    meta: {
      displayName: i18n.t('Enrollment status'),
      defaultHidden: !isAllDevices,
    },
  });
}

export function lastEnrollmentColumn() {
  return columnHelper.accessor('mdm_device', {
    header: i18n.t('Last enrollment'),
    id: ADETableColumnIds.LAST_ENROLLMENT,
    size: COLUMN_SIZES.XXL,
    cell: (info) => {
      const device = info.getValue();
      if (device == null) {
        return (
          <span title={i18n.t('n/a')} style={{ fontStyle: 'italic' }}>
            {i18n.t('n/a')}
          </span>
        );
      }
      const formattedTime = formatTime(
        device.enrolled_at,
        false,
        null,
        null,
        true,
      );
      return <span title={formattedTime}>{formattedTime}</span>;
    },
    meta: {
      displayName: i18n.t('Last enrollment'),
      defaultHidden: true,
    },
  });
}

export function deviceAssignedDate() {
  return columnHelper.accessor('device_assigned_date', {
    header: i18n.t('Assigned to Kandji'),
    id: ADETableColumnIds.DEVICE_ASSIGNED_DATE,
    size: COLUMN_SIZES.XXL,
    cell: (info) => {
      const date = info.getValue();
      const formattedTime = formatTime(date, false, null, null, true);
      return <span title={formattedTime}>{formattedTime}</span>;
    },
    meta: {
      displayName: i18n.t('Assigned to Kandji'),
    },
  });
}

export function profileAssignedColumn() {
  return columnHelper.accessor('profile_assign_time', {
    id: ADETableColumnIds.PROFILE_ASSIGNED,
    header: i18n.t('Profile assigned'),
    size: COLUMN_SIZES.XXL,

    cell: (info) => {
      const assignedTime = info.getValue();
      return assignedTime != null
        ? formatTime(info.getValue(), false, null, null, true)
        : '-';
    },
    meta: {
      displayName: i18n.t('Profile assigned'),
      defaultHidden: true,
    },
  });
}

export const ADEProfileValue = styled('div', {
  textTransform: 'capitalize',
});

export function profileColumn() {
  return columnHelper.accessor('profile_status', {
    id: ADETableColumnIds.PROFILE,
    size: COLUMN_SIZES.XS,
    header: () => <ADETableProfileHeader />,
    cell: (info) => {
      const assignedTime = info.row.original.profile_assign_time;

      return (
        <ADEProfileValue
          title={
            assignedTime != null
              ? formatTime(assignedTime, false, null, null, true)
              : '-'
          }
        >
          <SearchableText>{info.getValue()}</SearchableText>
        </ADEProfileValue>
      );
    },
    meta: {
      displayName: i18n.t('Profile'),
    },
  });
}

export function getADETableColumns(isAllDevices: boolean) {
  return [
    selectedColumn(isAllDevices),
    deviceNameColumn(isAllDevices),

    buildTextColumn(ADETableColumnIds.SERIAL_NUMBER, i18n.t('Serial number')),
    buildTextColumn(ADETableColumnIds.MODEL, i18n.t('Model name')),
    buildDefaultHiddenColumn(
      ADETableColumnIds.DESCRIPTION,
      i18n.t('Description'),
      isAllDevices,
    ),

    blueprintColumn(isAllDevices),
    userColumn(isAllDevices),
    assetTagColumn(isAllDevices),

    profileColumn(),

    tokenColumn(),
    enrollmentStatusColumn(isAllDevices),
    deviceAssignedDate(),

    buildDefaultHiddenColumn(
      ADETableColumnIds.DEVICE_ASSIGNED_BY,
      i18n.t('Assigned by'),
    ),
    buildDefaultHiddenColumn(ADETableColumnIds.COLOR, i18n.t('Color')),
    buildDefaultHiddenColumn(
      ADETableColumnIds.DEVICE_FAMILY,
      i18n.t('Device family'),
    ),

    lastEnrollmentColumn(),
    profileAssignedColumn(),
  ];
}
