// import PropTypes from 'prop-types';
import { updateSetting as update } from '@kandji-inc/bumblebee';
/* istanbul ignore file */
import React, { useEffect, useCallback } from 'react';

import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import ActivityTab from 'features/library-items/common/activity-tab';
import { getNewStatusTabProps } from 'features/library-items/common/new-status-tab/util';
import featureFlags from 'src/config/feature-flags';
import { i18n } from 'src/i18n';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import NewStatusTab from '../../common/new-status-tab/new-status-tab';
import { populateSelectedDevices } from '../../data-service/library-item/devices';
import config from '../../library/library-item-configurations/items/profile';
import initial from './initial-state';
import Details from './sections/details';
import { extensionTypes } from './sections/extensions/constants';
import { KerberosPasswordOptions } from './sections/kerberos-sections';
import KerberosAdvanced from './sections/kerberos-sections/advanced';
import PlatformSSO from './sections/platformsso';
import { singleSignOnExtensionService } from './service/single-sign-on-extension.service';
import { transformFromApi, transformToApi } from './service/transformers';
import useSingleSignOnExtensionService from './service/use-single-sign-on-extension.service';

const SingleSignOnExtensionPage = (props) => {
  const { model, setModel, pageState } = props;
  const [validationDep, triggerValidation] = useUniqValue();

  const itemConfig = config['Single Sign-On Extension'];
  const detailsUpdate = useCallback(update('details', setModel), []);
  const platformUpdate = useCallback(update('platform', setModel), []);

  useEffect(() => populateSelectedDevices(model, setModel, itemConfig), []);

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.getName()}
      summaryInfoProps={{
        name: i18n.t(`{name} Profile`, { name: itemConfig.getName() }),
        description: itemConfig.getDescription(),
        publisher: itemConfig.getPublisher(),
        devices: itemConfig.devices,
        requirements: itemConfig.getOsRequirements(),
      }}
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={singleSignOnExtensionService}
      defaultIcon={itemConfig.icon}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      supportsRules
      supportsInstallOn
      supportsDuplication
    >
      <Details
        setting={model.details}
        update={detailsUpdate}
        validationDep={validationDep}
        isDisabled={pageState.isDisabled}
        isSubmitted={pageState.isSubmitted}
      />
      {model.details.type === extensionTypes.kerberos && (
        <>
          <KerberosPasswordOptions
            setting={model.details}
            update={detailsUpdate}
            validationDep={validationDep}
            isDisabled={pageState.isDisabled}
          />
          <KerberosAdvanced
            setting={model.details}
            update={detailsUpdate}
            validationDep={validationDep}
            isDisabled={pageState.isDisabled}
          />
        </>
      )}
      {(model.details.type === extensionTypes.credential ||
        model.details.type === extensionTypes.redirect) && (
        <PlatformSSO
          setting={model.platform}
          update={platformUpdate}
          validationDep={validationDep}
          isDisabled={pageState.isDisabled}
        />
      )}
    </LibraryItemPage>
  );
};

const SingleSignOnExtension = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState: initial,
    useService: useSingleSignOnExtensionService,
  });

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return <SingleSignOnExtensionPage {...pageProps} testId="sso-extension-v2" />;
};

export default SingleSignOnExtension;
