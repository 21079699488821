import React from 'react';
import Branding from '../blocks/branding';
import ContentWithDefaults from '../blocks/content-with-defaults';
import Head from '../blocks/head';
import Information from '../blocks/information';

const WithDefaultsPreset = (props) => (
  <>
    <Head />

    <Branding {...props} />

    <ContentWithDefaults {...props} />

    <Information {...props} />
  </>
);

export default WithDefaultsPreset;
