import { getAllBlueprintOptions } from 'src/features/library-items/data-service/blueprint/use-blueprint-service';
import config from '../../../library/library-item-configurations/items';

async function transformFromApi(data) {
  const selectedBlueprints = await getAllBlueprintOptions(data.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    data.excluded_blueprints,
  );

  return {
    data: {
      ...data,
      selectedBlueprints,
      excludedBlueprints,
      isAllBlueprints: data.is_all_blueprints,
      isActive: data.active,
      items: data.data.items,
    },
    rules: data.rules,
  };
}

function transformToApi(model) {
  return {
    id: model.id,
    type: config['Login & Background Items'].type,
    identifier: config['Login & Background Items'].identifier,
    active: model.isActive,
    name: model.name,
    blueprints: model.selectedBlueprints.map(({ value }) => value),
    is_all_blueprints: model.isAllBlueprints,
    data: {
      items: model.items,
    },
    rules: model.rules || null,
  };
}

export { transformFromApi, transformToApi };
