import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export const BasicModal = ({ header, body, footer, onCancel }) => (
  <Modal
    isOpen
    toggle={onCancel || (() => {})}
    className="modal-dialog-wide"
    zIndex={2050}
    data-name="modal"
  >
    {onCancel && (
      <i
        style={{
          position: 'absolute',
          right: '20px',
          top: '20px',
          cursor: 'pointer',
          fontSize: '1.2em',
          opacity: '0.5',
        }}
        className="far fa-times"
        onClick={onCancel}
      />
    )}
    <ModalHeader style={{ margin: 'auto' }}>{header}</ModalHeader>
    <ModalBody>{body}</ModalBody>
    <ModalFooter>{footer}</ModalFooter>
  </Modal>
);
