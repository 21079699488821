import React, { useEffect } from 'react';
import useAuth0 from './useAuth0';
import { defaultReturnTo } from './utils';

const defaultOnRedirecting = () => <></>;

/**
 *
 * const MyProtectedComponent = withAuthenticationRequired(MyComponent);
 *
 * When components are wrapped in this Higher Order Component
 * and an anonymous user visits the component
 * they will be redirected to the login page and
 * returned to the page they were redirected from after login.
 */
const withAuthenticationRequired =
  (Component, options = {}) =>
  (props) => {
    const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
    const {
      returnTo = defaultReturnTo,
      onRedirecting = defaultOnRedirecting,
      loginOptions = {},
    } = options;

    useEffect(() => {
      if (isLoading || isAuthenticated) {
        return;
      }
      const opts = {
        ...loginOptions,
        appState: {
          ...loginOptions.appState,
          returnTo: typeof returnTo === 'function' ? returnTo() : returnTo,
        },
      };
      (async () => {
        await loginWithRedirect(opts);
      })();
    }, [isLoading, isAuthenticated, loginWithRedirect, loginOptions, returnTo]);

    return isAuthenticated ? <Component {...props} /> : onRedirecting();
  };

export default withAuthenticationRequired;
