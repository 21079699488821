export const NoResultsFoundIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="104"
    height="51"
    viewBox="0 0 104 51"
    fill="none"
  >
    <g filter="url(#filter0_d_629_73926)">
      <g filter="url(#filter1_d_629_73926)">
        <path
          d="M10.1128 29.3984C8.19961 28.2939 8.19961 26.503 10.1128 25.3984L42.2778 6.82799C44.1909 5.72342 47.2928 5.72341 49.206 6.82798L55.9478 10.7204C57.8609 11.8249 57.8609 13.6158 55.9478 14.7204L23.7828 33.2908C21.8696 34.3954 18.7677 34.3954 16.8546 33.2908L10.1128 29.3984Z"
          fill="#F3F7FA"
        />
      </g>
      <g filter="url(#filter2_d_629_73926)">
        <path
          d="M32.9292 35.9668C31.016 34.8622 31.016 33.0714 32.9292 31.9668L65.0942 13.3963C67.0073 12.2918 70.1092 12.2918 72.0224 13.3963L78.7642 17.2887C80.6773 18.3933 80.6773 20.1842 78.7642 21.2887L46.5992 39.8592C44.686 40.9637 41.5842 40.9637 39.671 39.8592L32.9292 35.9668Z"
          fill="#F3F7FA"
        />
      </g>
    </g>
    <g filter="url(#filter3_b_629_73926)">
      <path
        d="M45.1689 13.551C45.1689 9.95037 46.2617 6.31104 50.9643 3.59626C58.9454 -1.01125 71.8854 -1.01125 79.8665 3.59626C84.7635 6.42326 85.5436 9.95037 85.5436 13.9879C85.5436 17.7164 84.4508 21.2278 79.7483 23.9426C71.7671 28.5501 58.8271 28.5501 50.846 23.9426C45.9491 21.1156 45.169 17.1516 45.1689 13.551Z"
        fill="#D7E1ED"
        fillOpacity="0.2"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.9645 7.40484C58.9456 2.45653 71.8856 2.45653 79.8668 7.40484C82.9419 9.31141 84.8322 11.6744 85.5376 14.1421C85.5417 13.9474 85.5436 13.7515 85.5436 13.5544C85.5436 9.82592 84.4508 6.3145 79.7483 3.59972C71.7671 -1.00779 58.8271 -1.00779 50.846 3.59972C45.9491 6.42672 45.169 10.3908 45.1689 13.9914C45.1689 14.1901 45.1723 14.389 45.1794 14.5879C45.7763 11.96 47.7047 9.42594 50.9645 7.40484Z"
      fill="#A1ADC4"
    />
    <path
      d="M81.4957 24.2379C80.1623 23.4682 80.1623 21.5436 81.4957 20.7738L81.5417 20.7472C82.1605 20.39 82.9228 20.39 83.5416 20.7472L101.851 31.3173C102.518 31.7022 102.518 32.6645 101.851 33.0494L100.305 33.942C99.6862 34.2992 98.9238 34.2992 98.305 33.942L81.4957 24.2379Z"
      fill="#BCC9DE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.9645 20.1381C58.9456 25.0864 71.8856 25.0864 79.8668 20.1381C82.9419 18.2316 84.8322 15.8686 85.5376 13.4009C85.5417 13.5955 85.5436 13.7914 85.5436 13.9885C85.5436 17.7171 84.4508 21.2285 79.7483 23.9432C71.7671 28.5508 58.8271 28.5508 50.846 23.9432C45.9491 21.1162 45.169 17.1522 45.1689 13.5516C45.1689 13.3528 45.1723 13.1539 45.1794 12.9551C45.7763 15.583 47.7047 18.117 50.9645 20.1381Z"
      fill="url(#paint0_linear_629_73926)"
    />
    <path
      d="M85.1791 12.1138C85.1791 15.1717 83.0791 18.0293 79.4921 20.1496C75.9162 22.2634 70.9435 23.5869 65.424 23.5869C59.9045 23.5869 54.9318 22.2634 51.3559 20.1496C47.7689 18.0293 45.6689 15.1717 45.6689 12.1138C45.6689 9.03382 47.5447 6.18091 50.9875 4.07395C54.4305 1.96689 59.3978 0.640625 65.424 0.640625C71.4502 0.640625 76.4175 1.96689 79.8605 4.07395C83.3033 6.18091 85.1791 9.03382 85.1791 12.1138Z"
      fill="white"
      fillOpacity="0.01"
      stroke="url(#paint1_linear_629_73926)"
    />
    <defs>
      <filter
        id="filter0_d_629_73926"
        x="0.677979"
        y="0"
        width="87.521"
        height="50.6875"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0588235 0 0 0 0 0.0745098 0 0 0 0 0.0901961 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_629_73926"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_629_73926"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_629_73926"
        x="8.67798"
        y="6"
        width="48.7046"
        height="30.1191"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.737255 0 0 0 0 0.788235 0 0 0 0 0.870588 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_629_73926"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_629_73926"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_629_73926"
        x="31.4944"
        y="12.5684"
        width="48.7046"
        height="30.1191"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.737255 0 0 0 0 0.788235 0 0 0 0 0.870588 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_629_73926"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_629_73926"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_b_629_73926"
        x="41.1689"
        y="-3.85938"
        width="48.3745"
        height="35.2578"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_629_73926"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_629_73926"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_629_73926"
        x1="45.1689"
        y1="15.0733"
        x2="83.9835"
        y2="19.3633"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.210168" stopColor="#7B8BA3" />
        <stop offset="1" stopColor="#BCC9DE" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_629_73926"
        x1="50.5837"
        y1="1.8129"
        x2="85.6787"
        y2="26.4029"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.38654" stopColor="#A1ADC4" />
        <stop offset="0.674648" stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
);
