import SystemPreferencesService from './service/system-preferences-service';

export default {
  id: null,
  name: '',
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  isActive: true,

  preVentura: {
    isActive: false,
    DisabledPreferencePanes: SystemPreferencesService.preVenturaOptions,
  },
  postVentura: {
    isActive: false,
    DisabledPreferencePanes: SystemPreferencesService.postVenturaOptions,
  },
  supportsRules: true,
};
