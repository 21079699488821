import { Flex, Heading, Text } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import { ReactSVG } from 'react-svg';

import MafnifyingGlassImage from '../assets/magnifying-glass.svg';
import RocketLaunchImage from '../assets/rocket-launch.svg';
import ShieldCheckImage from '../assets/shield-check.svg';

const WhyKandjiSection = () => {
  const whyKandjiOptions = [
    {
      image: ShieldCheckImage,
      title: 'Stop threats before they happen',
      description:
        "Informed by Apple's Endpoint Security framework events, Kandji can gather all metadata on a file or process, analyze it, detect the potential for malicious activity, and take action.",
    },
    {
      image: RocketLaunchImage,
      title: 'Fastest time to value',
      description:
        'Threat protection is purpose-built for Apple technologies and deployed by a single agent. This drives fast implementations and puts Mac endpoint protection within reach of every team.',
    },
    {
      image: MafnifyingGlassImage,
      title: 'Broadest coverage of threats',
      description:
        "With data from the world's leading threat feeds and a team of threat researchers feeding the detection engine, our intelligence is among the world's most comprehensive for Mac.",
    },
  ];

  return (
    <Flex
      flow="column"
      alignItems="center"
      css={{
        width: 1136,
        gap: 45,
      }}
    >
      <Heading
        as="h2"
        size="2"
        css={{ lineHeight: '36px', letterSpacing: '-0.8px' }}
      >
        {i18n.t('Why Kandji for Endpoint Detection & Response')}
      </Heading>
      <Flex
        justifyContent="space-between"
        alignItems="start"
        css={{
          alignSelf: 'stretch',
        }}
      >
        {whyKandjiOptions.map((item) => (
          <Flex
            gap="md"
            flow="column"
            alignItems="center"
            justifyContent="center"
            css={{ alignSelf: 'stretch' }}
          >
            <Flex
              gap="md"
              flow="column"
              alignItems="center"
              css={{
                width: 301,
              }}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                gap="xl"
                css={{
                  borderRadius: 6,
                  background: '$blue50',
                  width: 44,
                  height: 44,
                }}
              >
                <ReactSVG src={item.image} />
              </Flex>
              <Flex
                gap="md"
                flow="column"
                alignItems="center"
                css={{
                  alignSelf: 'stretch',
                  textAlign: 'center',
                }}
              >
                <Text
                  size="4"
                  css={{
                    fontWeight: '$medium',
                    lineHeight: '32px',
                    letterSpacing: '-0.8px',
                  }}
                >
                  {i18n.t(item.title)}
                </Text>
                <Text
                  size="2"
                  css={{
                    lineHeight: '$2',
                    color: '$neutral90',
                  }}
                >
                  {i18n.t(item.description)}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export { WhyKandjiSection };
