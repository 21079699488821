import { Banner, Paragraph } from '@kandji-inc/nectar-ui';
import React from 'react';

type Props = {
  isEditing: boolean;
  isODTEnabled: boolean;
  isConfigured: boolean;
};

const AppConfigBanners = (props: Props) => {
  const { isEditing, isODTEnabled, isConfigured } = props;
  if (isEditing && isODTEnabled && isConfigured) {
    return (
      <Banner
        css={{ width: '100%' }}
        theme="warning"
        icon="triangle-exclamation"
        text={
          <Paragraph>
            App configuration settings can not be managed when Okta Device Trust
            is turned on. Upon saving this Library item, the configuration
            dictionary specified below will be removed and will not be installed
            on future device deployments.
          </Paragraph>
        }
      />
    );
  }
  if (isODTEnabled || isConfigured) {
    return (
      <Banner
        css={{ width: '100%' }}
        text={
          <Paragraph>
            App configuration settings cannot be managed or deployed when the
            Okta Device Trust integration is turned on.
          </Paragraph>
        }
      />
    );
  }
  return null;
};

export default AppConfigBanners;
