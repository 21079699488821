import { colors } from 'app/common/constants';
import { truncate } from 'app/common/style-utils';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useOnClickOutside } from '../common/hooks';

const Wrapper = styled.div.attrs(({ leftIcon, underline }) => ({
  justifyContent: leftIcon ? 'space-between' : 'center',
  borderBottom: underline ? `2px solid ${colors['grey-250']}` : 'none',
}))`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justifyContent};
  position: relative;
  border-bottom: ${(props) => props.borderBottom};
`;

const ParameterWrapper = styled(Wrapper)`
  justify-content: space-between;
  width: 100%;
`;

const FilterWrapper = styled(Wrapper)`
  min-width: 153px;
`;

const Input = styled.section.attrs(({ disabled, opened }) => ({
  cursor: disabled ? 'default' : 'pointer',
  color: colors[opened ? 'grey-300' : 'grey-500'],
}))`
  ${truncate('auto')};
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 12px;
  cursor: ${(props) => props.cursor};
  color: ${(props) => props.color};
`;

const ModalInput = styled(Input)`
  text-transform: capitalize;
  color: ${colors['marengo-700']};
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0;
`;

const ParameterInput = styled(Input).attrs(({ opened, disabled }) => ({
  color: colors[opened || !disabled ? 'black' : 'grey-500'],
}))`
  font-family: var(--font-family-primary);
  font-weight: 400;
  width: 100%;
  font-size: 14px;
  text-transform: none;
  letter-spacing: 0;
  line-height: 24px;
  color: ${(props) => props.color};
`;

const FilterInput = styled(ParameterInput)`
  width: 150px;
  height: 35px;
  line-height: 35px;
  margin: 0 15px;
  font-size: 12px;
  color: ${colors['grey-999']};
`;

const FilterBigInput = styled(FilterInput)`
  height: 55px;
  line-height: 55px;
  font-size: 14px;
  color: ${colors['grey-999']};
`;

const InputArrow = styled.section.attrs(({ opened }) => ({
  content: opened ? 106 : 107,
  color: colors[opened ? 'grey-300' : 'grey-500'],
}))`
  &:after {
    margin-left: 0.5em;
    font-family: 'Font Awesome 5 Pro', sans-serif;
    content: '\f${(props) => props.content}';
    font-weight: 700;
    color: ${(props) => props.color};
  }
`;

const ParameterInputArrow = styled(InputArrow).attrs(({ hasLabel }) => ({
  marginTop: hasLabel ? '-6px' : 0,
}))`
  &:after {
    position: absolute;
    margin-top: ${(props) => props.marginTop};
    right: 0;
    top: 0;
  }
`;

const FilterInputArrow = styled(ParameterInputArrow)`
  &:after {
    position: absolute;
    margin-top: 5px;
    right: 15px;
    top: 0;
    height: 8px;
  }
`;

const FilterBigInputArrow = styled(FilterInputArrow)`
  &:after {
    margin-top: 15px;
  }
`;

const Body = styled.section.attrs(({ toBottom, width, lightBody, opened }) => ({
  BottomOrTop: toBottom ? 'bottom: 3rem' : 'top: 2rem',
  width,
  background: colors[lightBody ? 'grey-50' : 'marengo-700'],
  color: colors[lightBody ? 'marengo-700' : 'marengo-300'],
  thumbBackground: colors[opened ? 'grey-300' : 'marengo-500'],
}))`
  z-index: 2000;
  display: flex;
  flex-direction: column;
  position: absolute;
  ${(props) => props.BottomOrTop};
  width: ${(props) => props.width};
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 20px;
  box-shadow: rgba(33, 36, 45, 0.5) 0 5px 20px 5px;
  max-height: 250px;
  white-space: nowrap;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.thumbBackground};
    border-radius: 4px;
  }
`;

const ParameterBody = styled(Body).attrs(({ hasLabel }) => ({
  top: hasLabel ? '-26px' : '-11px',
}))`
  top: ${(props) => props.top};
  width: calc(100% + 32px);
  margin-left: -16px;
  border: 1px solid ${colors['grey-250']};
  background-color: white;
  padding-top: 0;
  padding-bottom: 10px;
  box-shadow: 0 10px 30px -5px #0003;
`;

const FilterBody = styled(ParameterBody)`
  top: 0;
  min-width: calc(100% + 32px);
  max-width: 300%;
  width: max-content;
`;

const LeftBody = styled(Body)`
  left: 0;
`;

const RightBody = styled(Body)`
  right: 0;
`;

const Option = styled.section.attrs(({ lightBody }) => ({
  hoverColor: lightBody ? colors['yellow-500'] : '#fff',
}))`
  font-weight: 700;
  cursor: pointer;
  padding: 10px 20px;
  &:hover {
    color: ${(props) => props.hoverColor};
  }
  &.active {
    color: ${(props) => props.hoverColor};
  }
`;

const ParameterOption = styled(Option)`
  font-family: var(--font-family-primary);
  font-weight: 400;
  padding: 10px 15px 0 15px;
  font-size: 14px;
  color: ${colors['grey-999']};
  &:hover {
    color: ${colors['yellow-500']};
  }
`;

const FilterOption = styled(ParameterOption)`
  &.active {
    position: relative;
    color: ${colors['yellow-500']};
    &:after {
      content: '\f00c';
      font-family: 'Font Awesome 5 Pro', sans-serif;
      font-weight: 900;
      color: ${colors['grey-999']};
      position: absolute;
      right: 15px;
    }
  }
`;

const SelectedParameterOptionWrapper = styled(ParameterOption)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border-bottom: 1px solid ${colors['grey-250']};
`;

const SelectedParameterOption = styled.section`
  display: flex;
  flex-direction: column;
`;

const SelectedParameterOptionName = styled.section`
  font-family: var(--font-family-primary);
  font-weight: 400;
  line-height: 24px;
`;

const SelectedParameterOptionIcon = styled.i`
  font-size: 15px;
  margin-top: 2px;
`;

const inputMapping = {
  regular: Input,
  modal: ModalInput,
  parameter: ParameterInput,
  filter: FilterInput,
  filterBig: FilterBigInput,
};

const WrapperMapping = {
  regular: Wrapper,
  modal: Wrapper,
  parameter: ParameterWrapper,
  filter: FilterWrapper,
  filterBig: FilterWrapper,
};

const InputArrowMapping = {
  regular: InputArrow,
  modal: InputArrow,
  parameter: ParameterInputArrow,
  filter: FilterInputArrow,
  filterBig: FilterBigInputArrow,
};

const BodyMapping = {
  regular: Body,
  modal: Body,
  parameter: ParameterBody,
  filter: FilterBody,
  filterBig: FilterBody,
};

const OptionMapping = {
  regular: Option,
  modal: Option,
  parameter: ParameterOption,
  filter: FilterOption,
  filterBig: FilterOption,
};

const SelectLabel = styled.label`
  font-family: var(--font-family-primary);
  margin-bottom: 0;
  color: ${colors['grey-400']};
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.2em;
`;

const AwesomeSelect = ({
  options,
  value,
  noSelectText,
  underline,
  leftIcon,
  toBottom,
  width,
  lightBody,
  disabled,
  variant,
  filterSelected,
  hasLabel,
  label,
  multi,
  className,
  horizontal,
  onChange,
}) => {
  const [opened, setOpened] = useState(false);
  const [option, setOption] = useState(
    options.find((el) => String(el.value) === String(value)) || {
      value: null,
      label: noSelectText,
    },
  );
  const [selected, setSelected] = useState(value || []);
  const AwesomeSelectRef = useRef(null);
  const toggle = () => {
    if (!disabled) {
      setOpened(!opened);
    }
  };
  const handleClickOutside = () => {
    if (opened) {
      toggle();
    }
  };
  useOnClickOutside(AwesomeSelectRef, handleClickOutside);
  useEffect(() => {
    if (value) {
      setOption(options.find((el) => String(el.value) === String(value)));
    }
  }, [value]);
  const change = (option) => {
    if (multi) {
      const newSelected = selected.includes(option.value)
        ? selected.filter((s) => s !== option.value)
        : [...selected, option.value];
      setSelected(newSelected);
      if (onChange) {
        onChange({ value: newSelected });
      }
    } else {
      setOption(option);
      setOpened(!opened);
      if (onChange) {
        onChange(option);
      }
    }
  };
  const getPositionBody = () => {
    if (horizontal === 'left') {
      return LeftBody;
    }
    if (horizontal === 'right') {
      return RightBody;
    }
    return BodyMapping[variant];
  };

  const PositionBody = getPositionBody();
  const ThemedInput = inputMapping[variant];
  const ThemedWrapper = WrapperMapping[variant];
  const ThemedInputArrow = InputArrowMapping[variant];
  const ThemedOption = OptionMapping[variant];
  return (
    <ThemedWrapper
      underline={underline}
      leftIcon={leftIcon}
      className={className}
      ref={AwesomeSelectRef}
    >
      {leftIcon && <i className={leftIcon} />}
      <ThemedInput disabled={disabled} onClick={toggle} opened={opened}>
        {multi
          ? options
              .filter((el) => selected.includes(el.value))
              .map((el) => el.label)
              .join(', ')
          : get(option, 'label')}
      </ThemedInput>
      <ThemedInputArrow opened={opened} onClick={toggle} hasLabel={hasLabel} />
      {opened && options.length && (
        <PositionBody
          toBottom={toBottom}
          width={width}
          lightBody={lightBody}
          hasLabel={hasLabel}
        >
          {variant === 'parameter' && (
            <SelectedParameterOptionWrapper
              key={camelCase(option.value)}
              onClick={() => change(option)}
              lightBody={lightBody}
            >
              <SelectedParameterOption>
                {hasLabel && <SelectLabel>{label}</SelectLabel>}
                <SelectedParameterOptionName>
                  {option.label}
                </SelectedParameterOptionName>
              </SelectedParameterOption>
              <SelectedParameterOptionIcon className="fas fa-angle-down" />
            </SelectedParameterOptionWrapper>
          )}
          {options
            .filter((item) =>
              filterSelected ? item.value !== option.value : true,
            )
            .map((item) => (
              <ThemedOption
                key={camelCase(item.value)}
                onClick={() => change(item)}
                className={classNames('cursor-pointer', {
                  active: multi
                    ? selected.includes(item.value)
                    : item.value === option.value,
                })}
                lightBody={lightBody}
                checkmark={['filter', 'filterBig'].includes(variant)}
                title={item.label}
              >
                {item.label}
              </ThemedOption>
            ))}
        </PositionBody>
      )}
    </ThemedWrapper>
  );
};

AwesomeSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onChange: PropTypes.func,
  underline: PropTypes.bool,
  noSelectText: PropTypes.string,
  leftIcon: PropTypes.string,
  toBottom: PropTypes.bool,
  width: PropTypes.string,
  lightBody: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf([
    'regular',
    'modal',
    'parameter',
    'filter',
    'filterBig',
  ]),
  filterSelected: PropTypes.bool,
  hasLabel: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  multi: PropTypes.bool,
  horizontal: PropTypes.oneOf(['left', 'right', null]),
};

AwesomeSelect.defaultProps = {
  value: '',
  onChange: null,
  underline: false,
  noSelectText: 'Select...',
  leftIcon: null,
  toBottom: false,
  width: '300px',
  lightBody: false,
  disabled: false,
  variant: 'regular',
  filterSelected: false,
  hasLabel: false,
  label: '',
  multi: false,
  className: null,
  horizontal: null,
};

export default AwesomeSelect;
