/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query';
import { pulseService } from '../data-service/pulse-service';

export const useAllPulseJobsQuery = ({
  enabled,
  filters = {},
  limit = 1000,
}: {
  enabled?: boolean;
  filters?: Record<string, any>;
  limit?: number;
}) =>
  useQuery({
    queryKey: ['allPulseJobs', JSON.stringify(filters), limit],
    queryFn: () => pulseService.getAllPulseJobs({ filters, limit }),
    enabled: enabled ?? true,
    refetchOnWindowFocus: true,
  });
