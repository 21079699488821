import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import {
  DateCell,
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  extractFilterableColumns,
  getFilteredDisabled as filterDisabled,
  getCodeSnippetCell,
  getEnumFilterOptions,
} from '../column-utils';
import { getGlobalColumns } from './globalColumns';

export const getLocalUsersColumns = (schemas) => {
  const globalSchema = schemas.find((schema) => schema.uri === 'globals');
  const localUsersSchema = schemas.find(
    (schema) => schema.uri === 'local_users',
  );

  if (!localUsersSchema || !globalSchema) {
    return { columnDefs: [], filterableColumns: [] };
  }

  const combinedProperties = {
    ...globalSchema.schema.properties,
    ...localUsersSchema.schema.properties,
  };

  const localUsersColumnHelper =
    createColumnHelper<z.infer<typeof combinedProperties>>();

  const globalColumns = getGlobalColumns(
    localUsersColumnHelper,
    combinedProperties,
  );

  const columnDefs = [
    ...globalColumns.startColumnDefs,

    localUsersColumnHelper.accessor((row) => row.username, {
      id: 'username',
      meta: {
        displayName: combinedProperties.username.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.uid, {
      id: 'uid',
      meta: {
        displayName: combinedProperties.uid.title,
        filterType: 'number',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.type, {
      id: 'type',
      meta: {
        displayName: combinedProperties.type.title,
        filterType: 'enum',
        filterOptions: getEnumFilterOptions(
          localUsersSchema.schema?.definitions.LocalUsersTypes.enum || [],
        ),
        filterIcon: 'list-check',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.filevault_user, {
      id: 'filevault_user',
      cell: YesNoCell,
      meta: {
        displayName: combinedProperties.filevault_user.title,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.home_directory, {
      id: 'home_directory',
      cell: getCodeSnippetCell,
      meta: {
        displayName: combinedProperties.home_directory.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.user_created_at, {
      id: 'user_created_at',
      cell: DateCell,
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.user_created_at.title,
        filterType: 'date-time',
        filterIcon: 'calendar',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.failed_logins, {
      id: 'failed_logins',
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.failed_logins.title,
        filterType: 'number',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.failed_login_time, {
      id: 'failed_login_time',
      cell: DateCell,
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.failed_login_time.title,
        filterType: 'date-time',
        filterIcon: 'calendar',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.password_last_set, {
      id: 'password_last_set',
      cell: DateCell,
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.password_last_set.title,
        filterType: 'date-time',
        filterIcon: 'calendar',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.generated_uid, {
      id: 'generated_uid',
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.generated_uid.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.directory, {
      id: 'directory',
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.directory.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.shell, {
      id: 'shell',
      cell: getCodeSnippetCell,
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.shell.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.hidden_user, {
      id: 'hidden_user',
      cell: YesNoCell,
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.hidden_user.title,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.secure_token, {
      id: 'secure_token',
      cell: YesNoCell,
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.secure_token.title,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.volume_owner, {
      id: 'volume_owner',
      cell: YesNoCell,
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.volume_owner.title,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.full_name, {
      id: 'full_name',
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.full_name.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.logged_in, {
      id: 'logged_in',
      cell: YesNoCell,
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.logged_in.title,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.logged_in_time, {
      id: 'logged_in_time',
      cell: DateCell,
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.logged_in_time.title,
        filterType: 'date-time',
        filterIcon: 'calendar',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.mobile_account, {
      id: 'mobile_account',
      cell: YesNoCell,
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.mobile_account.title,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    localUsersColumnHelper.accessor((row) => row.home_folder_secure, {
      id: 'home_folder_secure',
      cell: YesNoCell,
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.home_folder_secure.title,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    ...globalColumns.endColumnDefs,
  ];

  const filterableColumns = extractFilterableColumns(columnDefs);

  return { columnDefs, filterableColumns };
};
