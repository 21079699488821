import { createReducer } from 'redux-create-reducer';
import { computer } from '../_actions/action-types';

const initialState = {
  activeTab: localStorage.getItem('activeComputerTab') || 'status',
  selectedParameter: localStorage.getItem('activeComputerParameter') || ['ALL'],
  selectedConnectivity: 'ALL',
  selectedStatus: ['ALL'],
  selectedPeriod: '30',
  lost_mode: null,
};

export default createReducer(initialState, {
  [computer.SET_COMPUTER](state, action) {
    return {
      ...state,
      ...action.computer,
    };
  },
  [computer.SET_COMPUTER_MDM_AGENT_LAST_CHECK_IN](state, action) {
    return {
      ...state,
      last_checkins: {
        ...action.computerMDMAgentLastCheckIn,
      },
    };
  },
  [computer.CLEAR_COMPUTER]() {
    localStorage.removeItem('activeComputerParameter');
    localStorage.removeItem('activeComputerTab');
    return {
      activeTab: 'status',
      selectedParameter: null,
      selectedStatus: ['ALL'],
    };
  },
  [computer.UPDATE_COMPUTER](state, action) {
    if (action.updates.activeTab) {
      localStorage.setItem('activeComputerTab', action.updates.activeTab);
    }
    return {
      ...state,
      ...action.updates,
    };
  },
  [computer.SET_DEVICE_BYPASS_CODE](state, action) {
    return {
      ...state,
      bypassCode: {
        ...action.bypassCode,
      },
    };
  },
  [computer.SET_DEVICE_RECOVERY_PASSWORD](state, action) {
    return {
      ...state,
      recoveryPassword: action.recoveryPassword,
    };
  },
});
