import { colors } from 'app/common/constants';
import classNames from 'classnames';
import { InterfaceContext } from 'contexts/interface';
import get from 'lodash/get';
import React, { useState, useRef, useContext } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import styled from 'styled-components';
import { useOnClickOutside } from '../common/hooks';
import { Tooltip } from './tooltips/Tooltip';

const DropdownWrapper = styled.section`
  position: relative;
  user-select: none;
`;

const ButtonWrapper = styled.section`
  top: 0;
  position: absolute;
`;

const DropdownStyledBody = styled.section`
  user-select: none;
  position: absolute;
  min-width: 239px;
  max-height: 0;
  top: 0;
  left: 100%;
  transform: translateX(-100%);
  margin-top: calc(100% - 1px);
  opacity: 0;
  transition: all 0s;
  background-color: transparent;
  white-space: nowrap;
  overflow: auto;
  &.open {
    border: 1px solid ${colors['grey-200']};
    max-height: 520px;
    background-color: white;
    box-shadow: 0px 15px 30px rgba(0, 0, 1, 0.2);
    border-radius: 5px 0 5px 5px;
    z-index: 2;
    opacity: 1;
    transition:
      max-height 0.3s ease-out,
      opacity 0.3s ease-out,
      background-color 0.3s ease-out;
  }
`;

const OptionListScrollTrack = styled.div`
  width: 7px;
  right: 10px;
  bottom: 2px;
  top: 2px;
  border-radius: 4px;
`;

const OptionListScrollThumb = styled.div`
  cursor: pointer;
  border-radius: inherit;
  background-color: rgba(0, 0, 0, 0.2);
`;

const HollowDropdown = ({
  buttonOpen,
  options,
  buttonTooltipMessage,
  children,
}) => {
  const { bannerTopOffset } = useContext(InterfaceContext);

  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);
  useOnClickOutside(wrapperRef, () => open && setOpen(!open));

  const extendedChildren = (el) =>
    React.cloneElement(el, {
      ...el.props,
      onClick: (e) => {
        if (get(el, 'props.onClick')) {
          el.props.onClick(e);
        }
        setOpen(!open);
      },
    });

  return (
    <DropdownWrapper>
      {!open && (
        <Tooltip tooltipMessage={buttonTooltipMessage}>
          {extendedChildren(children)}
        </Tooltip>
      )}
      <ButtonWrapper onClick={() => setOpen(!open)}>
        {open && extendedChildren(buttonOpen)}
      </ButtonWrapper>
      <DropdownStyledBody className={classNames({ open })} ref={wrapperRef}>
        <Scrollbars
          autoHide
          autoHeight
          autoHeightMax={`${window.innerHeight - bannerTopOffset - 80}`}
          renderThumbVertical={({ style, ...props }) => (
            <OptionListScrollThumb {...props} style={{ ...style }} />
          )}
          renderTrackVertical={({ style, ...props }) => (
            <OptionListScrollTrack {...props} style={{ ...style }} />
          )}
        >
          {options.map((option) => extendedChildren(option))}
        </Scrollbars>
      </DropdownStyledBody>
    </DropdownWrapper>
  );
};

export default HollowDropdown;
