import { Chip } from '@kandji-inc/bumblebee';
import { i18n } from 'i18n';
import React from 'react';
import HoverTippy from '../hover-tippy';

const PreviewHoverTippy = () => (
  <HoverTippy
    maxWidth={450}
    text={i18n.t('Preview this feature and share your feedback with us.')}
  >
    <Chip
      className="li-add-library-item__preview"
      kind="preview"
      text={i18n.t('Preview')}
    />
  </HoverTippy>
);

export default PreviewHoverTippy;
