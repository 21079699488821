// istanbul ignore file
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { z } from 'zod';
import { ThreatService } from '../../data-service';
import type { ReleaseThreatResponse } from '../../threat.types';

const ReleaseThreatSchema = z.object({
  device_ids: z.array(z.string()),
  device_count: z.number(),
  library_item_name: z.string(),
  library_item_is_threat_allowed: z.boolean(),
});

type UseGetReleaseDetails = (
  deviceId: string,
  hash: string,
) => {
  data: ReleaseThreatResponse | undefined;
  isLoading: boolean;
  isError: boolean;
};

/**
 * Retrieves the release details for a threat.
 *
 * @returns An object containing the release details data.
 */
const useGetReleaseDetails: UseGetReleaseDetails = (deviceId, hash) => {
  const threatService = useMemo(() => new ThreatService(), []);
  const { data, isPlaceholderData, isLoading, isError } = useQuery({
    queryKey: ['release-threat-details', deviceId, hash],
    queryFn: async () => {
      const data = await threatService.getThreatRelease({
        device_id: deviceId,
        file_hash: hash,
      });

      if (!ReleaseThreatSchema.safeParse(data?.data).success) {
        throw new Error('Invalid schema');
      }

      return data;
    },
    placeholderData: keepPreviousData,
    retry: 2,
    retryDelay: 1000,
  });

  return {
    data: data?.data,
    isLoading: isLoading || isPlaceholderData,
    isError,
  };
};

export default useGetReleaseDetails;
