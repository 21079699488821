import { i18n } from 'src/i18n';

const extensionTypes = {
  credential: 'Credential',
  redirect: 'Redirect',
  kerberos: 'Kerberos',
};

const extensionTypeOptions = /* istanbul ignore next */ () => [
  { label: i18n.t('Credential'), value: extensionTypes.credential },
  { label: i18n.t('Redirect'), value: extensionTypes.redirect },
  { label: i18n.t('Kerberos'), value: extensionTypes.kerberos },
];

export { extensionTypes, extensionTypeOptions };
