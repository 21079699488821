/* istanbul ignore file */
import { useMutation, useQuery } from '@tanstack/react-query';
import { enterpriseExternalApi } from 'src/app/api/base';

const useTags = () => {
  const getTags = (name = '') => {
    const {
      data: apiRes,
      isLoading,
      isError,
      ...rest
    } = useQuery({
      queryKey: ['tags', name],
      queryFn: () =>
        enterpriseExternalApi('tags').get({ search: name, limit: 1000 }),
    });

    const apiData = apiRes?.data;

    return { data: apiData, isLoading, isError, ...rest };
  };

  interface TagRuleData {
    data: {
      id: string;
      name: string;
      used_in_rule_assignment_maps: Array<{
        id: string;
        name: string;
      }>;
      used_in_rule_library_items: Array<{
        id: string;
        name: string;
        instance_name: string;
        type: string;
        identifier: string;
      }>;
    };
  }

  const getTag = (id = '', enabled = true) => {
    const { data, ...rest } = useQuery<TagRuleData>({
      queryKey: ['tag-rules', id],
      enabled,
      queryFn: () => enterpriseExternalApi(`tags/${id}`).get(null),
      // queryFn: () => ({
      //   data: {
      //     id: '5c6fb3dd-d0ee-4ee1-aa19-70a8a35b1e33',
      //     name: 'hello55',
      //     used_in_rule_assignment_maps: [
      //       {
      //         id: '527d5325-9779-43ba-a1ef-f620918f01a4',
      //         name: 'assignment map nameassignment map nameassignment map nameassignment map nameassignment map nameassignment map name',
      //       },
      //       {
      //         id: '527d5325-9779-43ba-a1ef-f620918f01a5',
      //         name: 'am name',
      //       },
      //       {
      //         id: '527d5325-9779-43ba-a1ef-f620918f01a5',
      //         name: 'am name',
      //       },
      //       {
      //         id: '527d5325-9779-43ba-a1ef-f620918f01a5',
      //         name: 'am name',
      //       },
      //       {
      //         id: '527d5325-9779-43ba-a1ef-f620918f01a5',
      //         name: 'am name',
      //       },
      //       {
      //         id: '527d5325-9779-43ba-a1ef-f620918f01a5',
      //         name: 'am name',
      //       },

      //       {
      //         id: '527d5325-9779-43ba-a1ef-f620918f01a5',
      //         name: 'am name',
      //       },
      //       {
      //         id: '527d5325-9779-43ba-a1ef-f620918f01a5',
      //         name: 'am name',
      //       },
      //     ],
      //     used_in_rule_library_items: [
      //       {
      //         id: 'f2a2a2bc-ac86-454c-a04e-3fa00c858dd1',
      //         name: 'iTerm2',
      //         instance_name: null,
      //       },
      //       {
      //         id: 'f2a2a2bc-ac86-454c-a04e-3fa00c858dd1',
      //         name: 'iTerm2',
      //         instance_name: null,
      //       },
      //       {
      //         id: 'f2a2a2bc-ac86-454c-a04e-3fa00c858dd1',
      //         name: 'iTerm2',
      //         instance_name: null,
      //       },
      //       {
      //         id: 'f2a2a2bc-ac86-454c-a04e-3fa00c858dd1',
      //         name: 'iTerm2',
      //         instance_name: null,
      //       },
      //       {
      //         id: 'f2a2a2bc-ac86-454c-a04e-3fa00c858dd1',
      //         name: 'iTerm2',
      //         instance_name: null,
      //       },
      //       {
      //         id: 'f2a2a2bc-ac86-454c-a04e-3fa00c858dd1',
      //         name: 'iTerm2',
      //         instance_name: null,
      //       },
      //     ],
      //   },
      // }),
    });

    const apiRes = data?.data;

    return { ...rest, data: apiRes };
  };

  const { mutateAsync: createTag } = useMutation({
    mutationKey: ['tags', 'create'],
    mutationFn: (args: { name: string }) => {
      const { name } = args;
      return enterpriseExternalApi(`tags`).post({ name });
    },
  });

  const { mutateAsync: updateTag } = useMutation({
    mutationKey: ['tags', 'update'],
    mutationFn: (args: { id: string; name: string }) => {
      const { id, name } = args;
      return enterpriseExternalApi(`tags/${id}`).patch({ name });
    },
  });

  const { mutateAsync: deleteTag } = useMutation({
    mutationKey: ['tags', 'delete'],
    mutationFn: (id: string) => enterpriseExternalApi(`tags/${id}`).delete(),
  });

  return { getTags, getTag, updateTag, deleteTag, createTag };
};

export { useTags };
