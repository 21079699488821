import { Banner, Paragraph } from '@kandji-inc/nectar-ui';
import React from 'react';

export const buildModalContent = (hasAppConfiguration: boolean) => {
  const LEARN_MORE_LINK =
    'https://support.kandji.io/support/solutions/articles/72000580418-okta-device-trust-configuring-the-okta-verify-library-item';
  if (hasAppConfiguration) {
    return (
      <>
        <Paragraph>
          By turning Okta Device Trust on, there will be additional
          configuration settings related to the integration added to this
          Library item.{' '}
          <a
            href={LEARN_MORE_LINK}
            rel="noopener noreferrer"
            target="_blank"
            className="b-alink"
          >
            Learn more
          </a>
        </Paragraph>
        <Banner
          theme="warning"
          text={
            <Paragraph>
              App configuration will no longer be available to manage and will
              be removed when the Library item is saved with Okta Device Trust
              turned on. This will not impact existing deployments.{' '}
              <a
                href={LEARN_MORE_LINK}
                rel="noopener noreferrer"
                target="_blank"
                className="b-alink"
              >
                Learn more
              </a>
            </Paragraph>
          }
        />
        <Paragraph>
          Are you sure you want to turn Okta Device Trust on?
        </Paragraph>
      </>
    );
  }

  return (
    <>
      <Paragraph>
        By turning Okta Device Trust on, there will be additional settings
        related to the integration added to this Library item.{' '}
        <a
          href={LEARN_MORE_LINK}
          rel="noopener noreferrer"
          target="_blank"
          className="b-alink"
        >
          Learn more
        </a>
      </Paragraph>
      <Banner
        theme="default"
        text={
          <Paragraph>
            App configuration will become unavailable to manage.{' '}
          </Paragraph>
        }
      />
      <Paragraph>Are you sure you want to enable Okta Device Trust?</Paragraph>
    </>
  );
};
