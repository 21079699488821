import {
  Checkbox,
  MultiSelectSearch,
  Select,
  useInputsValidators,
} from '@kandji-inc/bumblebee';
/* istanbul ignore file */
import React, { useEffect } from 'react';

import { Setting } from 'features/library-items/template';

import { i18n } from 'src/i18n';
import EnergySaverService from '../service/energy-saver.service';

const Schedule = (props) => {
  const { setting, update, isDisabled, isSubmitted } = props;
  const { refs, onInvalidate, invalidations } = useInputsValidators(
    ['powerOn', 'powerOff'],
    update,
  );

  const wakeOptions = [
    {
      label: i18n.t('Start up or wake'),
      value: EnergySaverService.wakePower.WAKEPOWERON,
    },
    {
      label: i18n.t('Start up'),
      value: EnergySaverService.wakePower.POWERON,
    },
    {
      label: i18n.t('Wake'),
      value: EnergySaverService.wakePower.WAKE,
    },
  ];

  const sleepOptions = [
    {
      label: i18n.t('Sleep'),
      value: EnergySaverService.sleepPower.SLEEP,
    },
    {
      label: i18n.t('Restart'),
      value: EnergySaverService.sleepPower.RESTART,
    },
    {
      label: i18n.t('Shut down'),
      value: EnergySaverService.sleepPower.SHUTDOWN,
    },
  ];

  const weekDaysOptions = [
    {
      label: i18n.t('Monday'),
      value: EnergySaverService.weekDays.MONDAY,
    },
    {
      label: i18n.t('Tuesday'),
      value: EnergySaverService.weekDays.TUESDAY,
    },
    {
      label: i18n.t('Wednesday'),
      value: EnergySaverService.weekDays.WEDNESDAY,
    },
    {
      label: i18n.t('Thursday'),
      value: EnergySaverService.weekDays.THURSDAY,
    },
    {
      label: i18n.t('Friday'),
      value: EnergySaverService.weekDays.FRIDAY,
    },
    {
      label: i18n.t('Saturday'),
      value: EnergySaverService.weekDays.SATURDAY,
    },
    {
      label: i18n.t('Sunday'),
      value: EnergySaverService.weekDays.SUNDAY,
    },
  ];

  const timeOptions = [
    {
      label: i18n.t('12 midnight'),
      value: 0,
    },
    {
      label: i18n.t('12:30 a.m.'),
      value: 30,
    },
    {
      label: i18n.t('1:00 a.m.'),
      value: 60,
    },
    {
      label: i18n.t('1:30 a.m.'),
      value: 90,
    },
    {
      label: i18n.t('2:00 a.m.'),
      value: 120,
    },
    {
      label: i18n.t('2:30 a.m.'),
      value: 150,
    },
    {
      label: i18n.t('3:00 a.m.'),
      value: 180,
    },
    {
      label: i18n.t('3:30 a.m.'),
      value: 210,
    },
    {
      label: i18n.t('4:00 a.m.'),
      value: 240,
    },
    {
      label: i18n.t('4:30 a.m.'),
      value: 270,
    },
    {
      label: i18n.t('5:00 a.m.'),
      value: 300,
    },
    {
      label: i18n.t('5:30 a.m.'),
      value: 330,
    },
    {
      label: i18n.t('6:00 a.m.'),
      value: 360,
    },
    {
      label: i18n.t('6:30 a.m.'),
      value: 390,
    },
    {
      label: i18n.t('7:00 a.m.'),
      value: 420,
    },
    {
      label: i18n.t('7:30 a.m.'),
      value: 450,
    },
    {
      label: i18n.t('8:00 a.m.'),
      value: 480,
    },
    {
      label: i18n.t('8:30 a.m.'),
      value: 510,
    },
    {
      label: i18n.t('9:00 a.m.'),
      value: 540,
    },
    {
      label: i18n.t('9:30 a.m.'),
      value: 570,
    },
    {
      label: i18n.t('10:00 a.m.'),
      value: 600,
    },
    {
      label: i18n.t('10:30 a.m.'),
      value: 630,
    },
    {
      label: i18n.t('11:00 a.m.'),
      value: 660,
    },
    {
      label: i18n.t('11:30 a.m.'),
      value: 690,
    },
    {
      label: i18n.t('12 noon.'),
      value: 720,
    },
    {
      label: i18n.t('12:30 p.m.'),
      value: 750,
    },
    {
      label: i18n.t('1:00 p.m.'),
      value: 780,
    },
    {
      label: i18n.t('1:30 p.m.'),
      value: 810,
    },
    {
      label: i18n.t('2:00 p.m.'),
      value: 840,
    },
    {
      label: i18n.t('2:30 p.m.'),
      value: 870,
    },
    {
      label: i18n.t('3:00 p.m.'),
      value: 900,
    },
    {
      label: i18n.t('3:30 p.m.'),
      value: 930,
    },
    {
      label: i18n.t('4:00 p.m.'),
      value: 960,
    },
    {
      label: i18n.t('4:30 p.m.'),
      value: 990,
    },
    {
      label: i18n.t('5:00 p.m.'),
      value: 1020,
    },
    {
      label: i18n.t('5:30 p.m.'),
      value: 1050,
    },
    {
      label: i18n.t('6:00 p.m.'),
      value: 1080,
    },
    {
      label: i18n.t('6:30 p.m.'),
      value: 1110,
    },
    {
      label: i18n.t('7:00 p.m.'),
      value: 1140,
    },
    {
      label: i18n.t('7:30 p.m.'),
      value: 1170,
    },
    {
      label: i18n.t('8:00 p.m.'),
      value: 1200,
    },
    {
      label: i18n.t('8:30 p.m.'),
      value: 1230,
    },
    {
      label: i18n.t('9:00 p.m.'),
      value: 1260,
    },
    {
      label: i18n.t('9:30 p.m.'),
      value: 1290,
    },
    {
      label: i18n.t('10:00 p.m.'),
      value: 1320,
    },
    {
      label: i18n.t('10:30 p.m.'),
      value: 1350,
    },
    {
      label: i18n.t('11:00 p.m.'),
      value: 1380,
    },
    {
      label: i18n.t('11:30 p.m.'),
      value: 1410,
    },
  ];

  useEffect(() => {
    onInvalidate('powerOff')(
      setting.isRepeatingPowerOff && !setting.RepeatingPowerOff.weekdays.length,
    );
    onInvalidate('powerOn')(
      setting.isRepeatingPowerOn && !setting.RepeatingPowerOn.weekdays.length,
    );
  }, [setting]);

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('Schedule')}</h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              isDisabled={isDisabled}
              checked={setting.isRepeatingPowerOn}
              label={i18n.t('Power on schedule')}
              onChange={() => update('isRepeatingPowerOn', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            <p>
              {i18n.t(
                'Define a schedule for when the computer should be powered on.',
              )}
            </p>
          </Setting.Helpers>

          {setting.isRepeatingPowerOn && (
            <Setting.SecondaryControls>
              <div ref={refs.powerOn}>
                <div className="b-flex-vc b-flex-g1 b-mb1">
                  <Select
                    compact
                    className="b-flex1"
                    options={wakeOptions}
                    value={wakeOptions.find(
                      ({ value }) =>
                        value === setting.RepeatingPowerOn?.eventtype,
                    )}
                    disabled={isDisabled}
                    onChange={({ value }) =>
                      update('RepeatingPowerOn', (p) => ({
                        ...p,
                        eventtype: value,
                      }))
                    }
                  />
                  <p className="b-txt-light">{i18n.t('at')}</p>
                  <Select
                    compact
                    className="b-flex1"
                    options={timeOptions}
                    value={timeOptions.find(
                      ({ value }) => value === setting.RepeatingPowerOn?.time,
                    )}
                    disabled={isDisabled}
                    onChange={({ value }) =>
                      update('RepeatingPowerOn', (p) => ({
                        ...p,
                        time: value,
                      }))
                    }
                  />
                </div>
                <div className="b-flex1">
                  <MultiSelectSearch
                    compact
                    searchPlaceholder={i18n.t('Search day of the week')}
                    placeholder={i18n.t('Select Option')}
                    className="b-flex1"
                    options={weekDaysOptions}
                    values={weekDaysOptions
                      .map((lv) =>
                        setting.RepeatingPowerOn.weekdays.includes(lv.value)
                          ? lv
                          : null,
                      )
                      .filter(Boolean)}
                    disabled={isDisabled}
                    onChange={(_, { action, option }) => {
                      if (action === 'deselect-option') {
                        update('RepeatingPowerOn', (p) => ({
                          ...p,
                          weekdays: p.weekdays.filter(
                            (value) => value !== option.value,
                          ),
                        }));
                      } else if (action === 'select-option') {
                        update('RepeatingPowerOn', (p) => ({
                          ...p,
                          weekdays: [...p.weekdays, option.value],
                        }));
                      }
                    }}
                    errorText={
                      (isSubmitted &&
                        invalidations.powerOn &&
                        i18n.t('Required.')) ||
                      ''
                    }
                  />
                </div>
              </div>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              isDisabled={isDisabled}
              checked={setting.isRepeatingPowerOff}
              label={i18n.t('Power off schedule')}
              onChange={() => update('isRepeatingPowerOff', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            <p>
              {i18n.t(
                'Define a schedule for when the computer should be powered off.',
              )}
            </p>
          </Setting.Helpers>

          {setting.isRepeatingPowerOff && (
            <Setting.SecondaryControls>
              <div ref={refs.powerOff}>
                <div className="b-flex-vc b-flex-g1 b-mb1">
                  <Select
                    compact
                    className="b-flex1"
                    options={sleepOptions}
                    value={sleepOptions.find(
                      ({ value }) =>
                        value === setting.RepeatingPowerOff?.eventtype,
                    )}
                    disabled={isDisabled}
                    onChange={({ value }) =>
                      update('RepeatingPowerOff', (p) => ({
                        ...p,
                        eventtype: value,
                      }))
                    }
                  />
                  <p className="b-txt-light">{i18n.t('at')}</p>
                  <Select
                    compact
                    className="b-flex1"
                    options={timeOptions}
                    value={timeOptions.find(
                      ({ value }) => value === setting.RepeatingPowerOff?.time,
                    )}
                    disabled={isDisabled}
                    onChange={({ value }) =>
                      update('RepeatingPowerOff', (p) => ({
                        ...p,
                        time: value,
                      }))
                    }
                  />
                </div>
                <div className="b-flex1">
                  <MultiSelectSearch
                    compact
                    searchPlaceholder={i18n.t('Search day of the week')}
                    placeholder={i18n.t('Select Option')}
                    className="b-flex1"
                    options={weekDaysOptions}
                    values={weekDaysOptions
                      .map((lv) =>
                        setting.RepeatingPowerOff.weekdays.includes(lv.value)
                          ? lv
                          : null,
                      )
                      .filter(Boolean)}
                    disabled={isDisabled}
                    onChange={(_, { action, option }) => {
                      if (action === 'deselect-option') {
                        update('RepeatingPowerOff', (p) => ({
                          ...p,
                          weekdays: p.weekdays.filter(
                            (value) => value !== option.value,
                          ),
                        }));
                      } else if (action === 'select-option') {
                        update('RepeatingPowerOff', (p) => ({
                          ...p,
                          weekdays: [...p.weekdays, option.value],
                        }));
                      }
                    }}
                    errorText={
                      (isSubmitted &&
                        invalidations.powerOff &&
                        i18n.t('Required.')) ||
                      ''
                    }
                  />
                </div>
              </div>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default Schedule;
