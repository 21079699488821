import './styles.css';

import { Tabs } from '@kandji-inc/bumblebee';
import React, { useContext } from 'react';

import { AccountContext } from 'contexts/account';

import { Library } from 'features/library-items/template';
import MultiFactorAuthentication from 'features/multi-factor-authentication';
import UserProfile from 'features/user-profile';

export default () => {
  const { isEmailPasswordAuth0 } = useContext(AccountContext);
  const tabs = [{ label: 'General' }];

  if (isEmailPasswordAuth0) {
    tabs.push({
      label: 'Multi-Factor Authentication',
      route: 'multi-factor-authentication',
    });
  }

  return (
    <Library className="k-user-profile">
      <h1 className="b-h1 b-mb2 b-flex-btw">Profile</h1>
      <Tabs className="k-user-profile-tabs" tabs={tabs}>
        <UserProfile tabid="General" />
        {isEmailPasswordAuth0 && (
          <MultiFactorAuthentication tabid="Multi-Factor Authentication" />
        )}
      </Tabs>
    </Library>
  );
};
