import { Banner } from '@kandji-inc/bumblebee';
import React from 'react';
import { formatTime } from 'src/app/components/common/helpers';
import useLostMode from 'src/hooks/useLostMode';

function ErrorStatusBanner(props) {
  const {
    isLostModeSoundErrored,
    lostModeSoundStatusDatetime,
    isLostModeLocationErrored,
    lostModeLocationDatetime,
  } = useLostMode(props.computer);

  return (
    <>
      {isLostModeLocationErrored && lostModeLocationDatetime && (
        <Banner
          kind="block"
          theme="error"
          className="lost-mode__sound-status-banner"
        >
          <div>
            Lost Mode location could not be updated as of{' '}
            {formatTime(lostModeLocationDatetime, null, null, null, true)}.
          </div>
        </Banner>
      )}
      {isLostModeSoundErrored && lostModeSoundStatusDatetime && (
        <Banner
          kind="block"
          theme="error"
          className="lost-mode__sound-status-banner"
        >
          <div>
            Lost Mode sound could not be played as of{' '}
            {formatTime(lostModeSoundStatusDatetime, null, null, null, true)}.
          </div>
        </Banner>
      )}
    </>
  );
}

export default ErrorStatusBanner;
