import { api } from 'app/api/base';
import { URL_NOTES } from 'app/api/urls';

export const getNotes = (id) =>
  api(URL_NOTES)
    .get({ id })
    .then((res) => res.data);

export const addNote = (data) =>
  api(URL_NOTES)
    .post(data)
    .then((res) => res.data);

export const deleteNote = (noteId) =>
  api(`${URL_NOTES}${noteId}`)
    .delete()
    .then((res) => res.data);

export const updateNote = (noteId, content) =>
  api(`${URL_NOTES}${noteId}`)
    .patch({ content })
    .then((res) => res.data);
