import { i18n } from 'i18n';

import type { AnyFunc } from '@kandji-inc/nectar-ui';

import { privacyEnum } from './schema/privacy.schema';

import type * as PrivacyTypes from './privacy.types';
const {
  propertyName: {
    enum: {
      Accessibility,
      AddressBook,
      AppleEvents,
      BluetoothAlways,
      Calendar,
      Camera,
      FileProviderPresence,
      ListenEvent,
      MediaLibrary,
      Microphone,
      Reminders,
      Photos,
      PostEvent,
      ScreenCapture,
      SpeechRecognition,
      SystemPolicyAllFiles,
      SystemPolicyAppBundles,
      SystemPolicyDesktopFolder,
      SystemPolicyDocumentsFolder,
      SystemPolicyDownloadsFolder,
      SystemPolicyNetworkVolumes,
      SystemPolicyRemovableVolumes,
      SystemPolicySysAdminFiles,
    },
  },
  allowedLabel,
} = privacyEnum;

export const appAccessPropertyOptions = () =>
  [
    { value: Accessibility, label: i18n.t('Accessibility') },
    { value: AddressBook, label: i18n.t('AddressBook') },
    { value: AppleEvents, label: i18n.t('AppleEvents') },
    { value: BluetoothAlways, label: i18n.t('BluetoothAlways') },
    { value: Calendar, label: i18n.t('Calendar') },
    { value: Camera, label: i18n.t('Camera') },
    { value: FileProviderPresence, label: i18n.t('FileProviderPresence') },
    { value: ListenEvent, label: i18n.t('ListenEvent') },
    { value: MediaLibrary, label: i18n.t('MediaLibrary') },
    { value: Microphone, label: i18n.t('Microphone') },
    { value: Reminders, label: i18n.t('Reminders') },
    { value: Photos, label: i18n.t('Photos') },
    { value: PostEvent, label: i18n.t('PostEvent') },
    { value: ScreenCapture, label: i18n.t('ScreenCapture') },
    { value: SpeechRecognition, label: i18n.t('SpeechRecognition') },
    { value: SystemPolicyAllFiles, label: i18n.t('SystemPolicyAllFiles') },
    { value: SystemPolicyAppBundles, label: i18n.t('SystemPolicyAppBundles') },
    {
      value: SystemPolicyDesktopFolder,
      label: i18n.t('SystemPolicyDesktopFolder'),
    },
    {
      value: SystemPolicyDocumentsFolder,
      label: i18n.t('SystemPolicyDocumentsFolder'),
    },
    {
      value: SystemPolicyDownloadsFolder,
      label: i18n.t('SystemPolicyDownloadsFolder'),
    },
    {
      value: SystemPolicyNetworkVolumes,
      label: i18n.t('SystemPolicyNetworkVolumes'),
    },
    {
      value: SystemPolicyRemovableVolumes,
      label: i18n.t('SystemPolicyRemovableVolumes'),
    },
    {
      value: SystemPolicySysAdminFiles,
      label: i18n.t('SystemPolicySysAdminFiles'),
    },
    {
      value: 'SystemPolicyAppData',
      label: i18n.t('SystemPolicyAppData'),
    },
  ] as const;

export const allowDenyAccessOptions = () =>
  [
    { value: true, label: i18n.t(allowedLabel.enum.Allow) },
    { value: false, label: i18n.t(allowedLabel.enum.Deny) },
  ] as const;

export const denyAccessOptions = () => [
  { value: false, label: i18n.t(allowedLabel.enum.Deny) },
];

export const denyAndUserApproveAccessOptions = () =>
  [
    { value: false, label: i18n.t(allowedLabel.enum.Deny) },
    {
      value: null,
      label: i18n.t(allowedLabel.enum['Allow Standard User to Approve']),
    },
  ] as const;

export const getAllowOptions = (
  property: PrivacyTypes.AppAccessPropertySetting['Property'],
) => {
  switch (property) {
    // Deny or allow user approval access options
    case ListenEvent:
    case ScreenCapture:
      return denyAndUserApproveAccessOptions();

    // Only deny access options
    case Camera:
    case Microphone:
      return denyAccessOptions();

    // Allow or deny access options
    default:
      return allowDenyAccessOptions();
  }
};

export const createDisplayableError =
  (showError: boolean) =>
  <ConditionFn extends AnyFunc, Error, Ok>(
    condition: boolean | ConditionFn,
    error?: Error,
    ok?: Ok,
  ) => {
    if (typeof condition === 'function') {
      return showError && condition();
    }
    return showError && condition ? error : ok;
  };

/**
 * Given an identifier, validate it and return the appropriate message given the error.
 */
export const getInvalidIdentifierMessage = (
  Identifier:
    | PrivacyTypes.AppAccessSetting['Identifier']
    | PrivacyTypes.AppAccessPropertySetting['AEReceiverIdentifier'],
  IdentiferType:
    | PrivacyTypes.AppAccessSetting['IdentifierType']
    | PrivacyTypes.AppAccessPropertySetting['AEReceiverIdentifierType'],
) => {
  const { identifierType, validationMessage } = privacyEnum;
  const identifierTypeEnum = identifierType.enum;
  const validationMessageEnum = validationMessage.enum;

  if (!Identifier) {
    return { label: i18n.t(validationMessageEnum['Required.']) };
  }

  // Bundle IDs cannot contain spaces
  if (
    Identifier.includes(' ') &&
    IdentiferType === identifierTypeEnum.bundleID
  ) {
    return { label: i18n.t(validationMessageEnum['Invalid Bundle ID.']) };
  }

  return false;
};
