import type { AxiosResponse, CancelToken } from 'axios';

import {
  threatApi,
  threatApiV2,
  threatPublicApi,
  threatPublicApiV2,
} from 'app/api/base';
import type {
  CreateReleaseThreatAffectedDeviceDetailsRequestBody,
  CreateReleaseThreatAffectedDeviceDetailsResponse,
  FileStatusRequestBody,
  FileStatusResponse,
  GetAssignedBlueprintRequestParams,
  GetAssignedBlueprintResponse,
  GetBehavioralThreatDetailsRequestParams,
  GetBehavioralThreatDetailsResponse,
  GetBehavioralThreatGroupRequestParams,
  GetBehavioralThreatGroupResponse,
  GetByThreatsRequestParams,
  GetByThreatsResponse,
  GetDeviceBehavioralEventsRequestParams,
  GetDeviceBehavioralEventsResponse,
  GetDeviceEventsRequestParams,
  GetDeviceEventsResponse,
  GetDevicesBehavioralRequestParams,
  GetDevicesBehavioralResponse,
  GetDevicesRequestParams,
  GetDevicesResponse,
  GetHistoricalDatesRequestParams,
  GetHistoricalDatesResponse,
  GetStatusCountsRequestBody,
  GetStatusCountsResponse,
  GetThreatDetailsRequestParams,
  GetThreatDetailsResponse,
  GetThreatExportRequestParams,
  GetUniquePathsRequestParams,
  GetUniquePathsResponse,
  IncidentResponse,
  ReleaseThreatRequestBody,
  ReleaseThreatResponse,
} from '../threat.types';

/*
 * Usage:
 *
 * import { threatService } from "src/features/edr/threat/data-service/threat-service";
 * threatService.getThreats(params).then(console.log)
 * threatService.createThreatsRelease(body).then(console.log)
 */
class ThreatService {
  public async getThreats(
    params: GetThreatDetailsRequestParams,
  ): Promise<AxiosResponse<GetThreatDetailsResponse>> {
    const client = threatApiV2('/threats');
    return client.get(params);
  }

  public async getThreatRelease(
    params: ReleaseThreatRequestBody,
  ): Promise<AxiosResponse<ReleaseThreatResponse>> {
    const client = threatApi('/threats/release_details');
    return client.post(params);
  }

  public async createThreatRelease(
    body: CreateReleaseThreatAffectedDeviceDetailsRequestBody,
  ): Promise<AxiosResponse<CreateReleaseThreatAffectedDeviceDetailsResponse>> {
    const client = threatApi('/threats/release');
    return client.post(body);
  }

  public async getExportThreats(
    params: GetThreatExportRequestParams,
  ): Promise<AxiosResponse<string>> {
    const client = threatPublicApiV2('/threat-details/export');
    return client.get(params);
  }

  public async checkFileStatus(
    params: FileStatusRequestBody,
    cancelToken: CancelToken,
  ): Promise<AxiosResponse<FileStatusResponse>> {
    const client = threatPublicApi('/threat-status/file-status');
    return client.post(params, {
      cancelToken,
    });
  }

  public async getFileStatus(
    incidentResponseId: number,
    cancelToken: CancelToken,
  ): Promise<AxiosResponse<IncidentResponse>> {
    const client = threatPublicApi(
      `/threat-status/incident-response/${incidentResponseId}`,
    );
    return client.get({}, { cancelToken });
  }

  public async getAssignedBlueprint(
    params: GetAssignedBlueprintRequestParams,
  ): Promise<AxiosResponse<GetAssignedBlueprintResponse>> {
    const client = threatApiV2('/assigned_blueprint');
    return client.get(params);
  }

  public async getStatusCounts(
    params: GetStatusCountsRequestBody,
  ): Promise<AxiosResponse<GetStatusCountsResponse>> {
    const client = threatApiV2('/threats/status_counts');
    return client.get(params);
  }

  public async getByThreats(
    params: GetByThreatsRequestParams,
  ): Promise<AxiosResponse<GetByThreatsResponse>> {
    const client = threatPublicApi('/threat-groups/');
    return client.get(params);
  }

  public async getDevices(
    fileHash: string,
    params: GetDevicesRequestParams,
  ): Promise<AxiosResponse<GetDevicesResponse>> {
    const client = threatPublicApi(`/threat-groups/${fileHash}/devices`);
    return client.get(params);
  }

  public async getDeviceEvents(
    fileHash: string,
    deviceId: string,
    params: GetDeviceEventsRequestParams,
  ): Promise<AxiosResponse<GetDeviceEventsResponse>> {
    const client = threatPublicApi(
      `/threat-groups/${fileHash}/devices/${deviceId}/events`,
    );
    return client.get(params);
  }

  public async getUniquePaths(
    fileHash: string,
    params: GetUniquePathsRequestParams,
  ): Promise<AxiosResponse<GetUniquePathsResponse>> {
    const client = threatPublicApi(`/threat-groups/${fileHash}/unique-paths`);
    return client.get(params);
  }

  public async getHistoricalDates(
    params: GetHistoricalDatesRequestParams,
  ): Promise<AxiosResponse<GetHistoricalDatesResponse>> {
    const client = threatPublicApiV2(`/threat-history/`);
    return client.get(params);
  }

  public async getBehavioralThreats(
    params: GetBehavioralThreatDetailsRequestParams,
  ): Promise<AxiosResponse<GetBehavioralThreatDetailsResponse>> {
    const client = threatPublicApiV2('/behavioral-detections/events');
    return client.get(params);
  }

  public async getBehavioralThreatGroup(
    params: GetBehavioralThreatGroupRequestParams,
  ): Promise<AxiosResponse<GetBehavioralThreatGroupResponse>> {
    const client = threatPublicApiV2('/behavioral-detections/groups');
    return client.get(params);
  }

  public async getDevicesBehavioral(
    threatId: string,
    params: GetDevicesBehavioralRequestParams,
  ): Promise<AxiosResponse<GetDevicesBehavioralResponse>> {
    const client = threatPublicApiV2(
      `/behavioral-detections/groups/${threatId}`,
    );
    return client.get(params);
  }

  public async getDeviceBehavioralEvents(
    fileHash: string,
    deviceId: string,
    params: GetDeviceBehavioralEventsRequestParams,
  ): Promise<AxiosResponse<GetDeviceBehavioralEventsResponse>> {
    const client = threatPublicApiV2(
      `/behavioral-detections/groups/${fileHash}/devices/${deviceId}/events`,
    );
    return client.get(params);
  }
}

export default ThreatService;
