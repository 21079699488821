import { api } from 'app/api/base';
import { i18n } from 'i18n';
import {
  URL_DEP_COMPUTERS,
  URL_DEP_COMPUTERS_COUNTS,
  URL_DEP_COMPUTER_CHANGE_ASSET_TAG,
  URL_DEP_COMPUTER_CHANGE_BLUEPRINT,
  URL_DEP_COMPUTER_CHANGE_USER,
  URL_REPORTS_DEP_COMPUTERS,
} from '../api/urls';
import { noUserAssigned } from '../common/constants';
import {
  cleanStorageForPaginationAndFilters,
  formatTime,
  getDEPDeviceName,
  getDEPEnrollmentStatus,
  getDEPLastEnrollment,
  getFileName,
  isDEPDeviceEnrolled,
  saveAs,
} from '../components/common/helpers';
import { depComputer } from './action-types';
import { getVerboseError } from './computer';
import { setSnackbar } from './ui';

export const setDEPComputers = (DEPComputers) => ({
  type: depComputer.SET_DEP_COMPUTERS,
  DEPComputers,
});

export const annotateDEPComputer = (computer) => ({
  ...computer,
  agentVersion: computer.agent_version || 'No info',
  assetTag: computer.asset_tag || 'No info',
  assetUser: computer.user ? computer.user.name : null,
  blueprint: (computer.blueprint && computer.blueprint.name) || 'Not Found',
  blueprint_info: computer.blueprint || 'Not Found',
  blueprint_id: (computer.blueprint && computer.blueprint.id) || 'Not Found',
  color: computer.color || 'No info',
  computerId: computer.mdm_device && computer.mdm_device.id,
  computerName: getDEPDeviceName(computer.mdm_device),
  serial: computer.serial_number || 'No info',
  model: computer.model || 'No info',
  createdAt: computer.enrolled_at
    ? formatTime(computer.enrolled_at, true)
    : 'No info',
  createdAtNotFormatted: computer.enrolled_at
    ? formatTime(computer.enrolled_at, true, null, null, true)
    : 'No info',
  description: computer.description || 'No info',
  deviceAssignedBy: computer.device_assigned_by || 'No info',
  deviceAssignedDate: computer.device_assigned_date
    ? formatTime(computer.device_assigned_date, null, null, null, true)
    : 'No info',
  deviceFamily: computer.device_family || 'No info',
  enrollmentStatus: getDEPEnrollmentStatus(computer.mdm_device),
  MDMDevice: computer.mdm_device || 'No info',
  name: getDEPDeviceName(computer.mdm_device),
  OS: computer.os || 'No info',
  status: isDEPDeviceEnrolled(computer.mdm_device)
    ? computer.mdm_device.status
    : '',
  deferred_install: isDEPDeviceEnrolled(computer.mdm_device)
    ? computer.mdm_device.deferred_install
    : '',
  is_missing: isDEPDeviceEnrolled(computer.mdm_device)
    ? computer.mdm_device.is_missing
    : '',
  profileStatus: computer.profile_status || 'No info',
  user: computer.user ? computer.user : noUserAssigned,

  DEPProfileAssignmentStatus: computer.last_assignment_status,
  DEPProfileAssignmentStatusReceivedAt: computer.assignment_status_received_at,
  DEPProfileAssignmentFailedAttempts: computer.failed_assignment_attempts,

  firstReport: computer.first_report
    ? formatTime(computer.first_report, true)
    : 'No info',
  firstEnrollmentNotFormatted: computer.first_enrolled_at
    ? formatTime(computer.first_enrolled_at, false, null, null, true)
    : 'No info',
  firstEnrollment: computer.first_enrolled_at
    ? formatTime(computer.first_enrolled_at, true)
    : 'No info',
  firstReportNotFormatted: computer.first_report
    ? formatTime(computer.first_report, false, null, null, true)
    : 'No info',
  lastRun: computer.last_seen_dt || 'No info',
  lastRunVerboseNotFormatted: computer.last_seen_dt
    ? formatTime(computer.last_seen_dt, null, null, null, true)
    : 'Not yet checked-in',
  lastRunVerbose: computer.last_seen_dt
    ? formatTime(computer.last_seen_dt)
    : 'Not yet checked-in',
  lastRunNotFormatted: computer.last_seen_dt
    ? formatTime(computer.last_seen_dt, false, null, null, true)
    : 'Not yet checked-in',
  lastCheckIn: computer.last_seen_dt
    ? formatTime(computer.last_seen_dt, true)
    : 'Not yet checked-in',
  lastEnrollmentNotFormatted: computer.mdm_device
    ? formatTime(computer.mdm_device.enrolled_at, false, null, null, true)
    : 'No info',
  lastEnrollment: getDEPLastEnrollment(computer.mdm_device),
  agentInstalled: computer.agent_installed_at
    ? formatTime(computer.first_enrolled_at, true)
    : 'No info',
  agentInstalledNotFormatted: computer.agent_installed_at
    ? formatTime(computer.agent_installed_at, false, null, null, true)
    : 'No info',
  profileAssignTime: computer.profile_assign_time
    ? formatTime(computer.profile_assign_time, false, null, null, true)
    : 'No info',
  profilePushTime: computer.profile_push_time
    ? formatTime(computer.profile_push_time, false, null, null, true)
    : 'No info',
});

export const changeDEPComputersBlueprint = (devices, blueprintId) => () =>
  api(URL_DEP_COMPUTER_CHANGE_BLUEPRINT).post({
    devices,
    blueprint_id: blueprintId,
  });

export const clearDEPComputers = () => ({
  type: depComputer.CLEAR_DEP_COMPUTERS,
});

export const setDEPComputer = (DEPComputer, activeTab, selectedParameter) => ({
  type: depComputer.SET_DEP_COMPUTER,
  DEPComputer,
  activeTab,
  selectedParameter,
});

export const assignDEPComputersUser = (devices, user) => (dispatch) =>
  api(URL_DEP_COMPUTER_CHANGE_USER)
    .post({ devices, user_id: user })
    .then((res) => {
      const computer = res.data;
      const result = annotateDEPComputer(computer);
      dispatch(setDEPComputer(result));
    });

export const changeDEPComputersAssetTag = (devices, assetTag) => () =>
  api(URL_DEP_COMPUTER_CHANGE_ASSET_TAG).post({ devices, asset_tag: assetTag });

export const updateDEPComputer = (updates) => {
  if (updates.activeTab) {
    cleanStorageForPaginationAndFilters();
  }
  return {
    type: depComputer.UPDATE_DEP_COMPUTER,
    updates,
  };
};

export const editDEPComputerInfo = (computerId, data) => (dispatch, getState) =>
  api(URL_DEP_COMPUTERS + computerId)
    .get()
    .then((res) => {
      const computerInfo = res.data.info || {};
      const infoData = { ...computerInfo, ...data };
      return api(URL_DEP_COMPUTERS + computerId)
        .patch({ info: infoData })
        .then((patchResp) => {
          const computer = patchResp.data;
          const { blueprintNames } = getState().data;
          const result = annotateDEPComputer(computer, blueprintNames);
          dispatch(setDEPComputer(result));
        })
        .catch((err) => Promise.reject(getVerboseError(err)));
    });

export const queryDEPComputers = (params) => (dispatch) => {
  dispatch(clearDEPComputers());
  const { filters } = params;
  return api(URL_DEP_COMPUTERS)
    .get({
      ...params,
      filters: JSON.stringify(filters),
    })
    .then((res) => res.data)
    .catch(() => ({ items: [] }));
};

export const exportDEPComputers = (filters, ext) => (dispatch) =>
  api(URL_REPORTS_DEP_COMPUTERS)
    .get({ filters: JSON.stringify(filters), ext })
    .then((res) => {
      let name;
      let content;
      if (ext === 'csv') {
        name = getFileName('Auto-Enroll Devices', '.csv');
        content = `data:text/csv,${encodeURIComponent(res.data)}`;
      } else {
        name = getFileName('Auto-Enroll Devices', '.pdf');
        content = `data:application/octet-stream;base64,${res.data}`;
      }
      saveAs(content, name);
      dispatch(setSnackbar(i18n.t('File was exported')));
    })
    .catch((err) => {
      console.log(err);
      dispatch(setSnackbar(i18n.common.error()));
    });

export const getDEPComputersCountsForViews = () =>
  api(URL_DEP_COMPUTERS_COUNTS)
    .get()
    .then((response) => response.data)
    .catch(() => {});
