import type { Dispatch, SetStateAction } from 'react';
import React from 'react';

import { Button, modal as Modal } from '@kandji-inc/bumblebee';
import { i18n } from 'src/i18n';

type Props = {
  isOpen: boolean;
  onClose: () => Dispatch<SetStateAction<boolean>>;
  onConfirm: () => Dispatch<SetStateAction<boolean>>;
};

export function CancelEditingModal({ isOpen, onClose, onConfirm }: Props) {
  const parts = {
    header: { text: 'Cancel editing' },
    content: {
      children: (
        <>
          <p>{i18n.t('You will lose all unsaved work.')}</p>
          <p>{i18n.t('Are you sure you want to cancel editing?')}</p>
        </>
      ),
    },
    footer: {
      children: (
        <>
          <Button onClick={onClose} kind="outline">
            {i18n.t('Continue editing')}
          </Button>

          <Button onClick={onConfirm} className="b-ml">
            {i18n.t('Cancel editing')}
          </Button>
        </>
      ),
    },
  };

  return <Modal isOpen={isOpen} onClose={onClose} parts={parts} />;
}
