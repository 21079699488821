import { TextField } from '@kandji-inc/nectar-ui';
import { useEffect, useState } from 'react';
import type { OnApply, OnClear } from '../../common.types';

const textFieldSearchCss = {
  '& > div > div:first-child svg': {
    width: 20,
    height: 20,
  },
  '& input': {
    padding: '2.5px 8px',
    paddingLeft: 32,
  },
};

type SearchFilterProps = {
  name: string;
  value: string | null | undefined;
  onChange: OnApply;
  onClear: OnClear;
  disabled?: boolean;
  width?: string | number;
  placeholder?: string;
  isValueSync?: boolean;
};

export const EDRSearchFilter = (props: SearchFilterProps) => {
  const {
    name,
    value,
    onChange,
    onClear,
    disabled = false,
    width = 240,
    placeholder = 'Search',
    isValueSync = true,
  } = props;
  const [localValue, setLocalValue] = useState(value || '');

  useEffect(() => {
    setLocalValue(value || '');
  }, [value]);

  const handleInputChange = (newValue: string) => {
    setLocalValue(newValue);
    if (newValue === '') {
      onClear(name);
    } else {
      onChange(name, newValue);
    }
  };

  // istanbul ignore next -- clear button event handler seems not to be called?
  const handleClear = () => {
    setLocalValue('');
    onClear(name);
  };

  return (
    <TextField
      css={{ ...textFieldSearchCss, width }}
      placeholder={placeholder}
      icon="magnifying-glass"
      onChange={(e) => handleInputChange(e.target.value)}
      showClearButton={Boolean(localValue)}
      onClear={handleClear}
      value={localValue}
      aria-label="Search"
      disabled={disabled}
    />
  );
};

export default EDRSearchFilter;
