/* istanbul ignore file - legacy code, moved */
import { blueprintTypes } from 'app/common/constants';
import { DataContext } from 'contexts/data';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import MultiSelectDropdown from 'theme/components/atoms/MultiSelectDropdown';
import { IPADataContext } from './IPADataContext';
import { SHAREDAssignmentBlueprintsModal } from './SHAREDAssignmentBlueprintsModal';

export const IPAAssignmentBlueprint = ({ element: Element }) => {
  const {
    blueprintConflicts,
    closeConflictResolution,
    data,
    isReadOnly,
    onBlueprintAssignmentExit,
    onBlueprintAssignmentOpen,
    onBlueprintAssignmentToggle,
    selectedBlueprints,
    setSelectedBlueprints,
    initial,
    type,
  } = useContext(IPADataContext);
  const {
    appData: { blueprints, blueprintNames } = {},
  } = useContext(DataContext);

  const classicBlueprintOptions = blueprintNames;
  const selectedClassicBlueprintIds = [];
  const selectedAssignmentMapIds = [];

  blueprints?.forEach(({ id, type }) => {
    // Remove Assignment Maps from the Classic Blueprint options list
    if (type === blueprintTypes.flow) {
      delete classicBlueprintOptions[id];
    }

    // Create a list of selected Classic Blueprints and selected Assignment Maps
    if (selectedBlueprints.includes(id)) {
      if (type === blueprintTypes.form) {
        selectedClassicBlueprintIds.push(id);
      } else {
        selectedAssignmentMapIds.push(id);
      }
    }
  });

  const dropdownFooter = (
    <p>
      <Link to="/blueprints">+ Add new</Link>
    </p>
  );

  return (
    <>
      {data && (
        <Element className="assignment assignment-blueprint">
          <MultiSelectDropdown
            dropdownStyles={isEmpty(blueprintConflicts) ? null : ['error']}
            disabled={isReadOnly}
            name="blueprint-assignment"
            options={[classicBlueprintOptions]}
            selectedValues={selectedClassicBlueprintIds}
            searchPlaceholder="Search Blueprints..."
            onExit={(selectedValues) =>
              onBlueprintAssignmentExit(
                [...selectedValues, ...selectedAssignmentMapIds],
                initial.type,
              )
            }
            onOpen={onBlueprintAssignmentOpen}
            onToggle={(selectedValues) =>
              onBlueprintAssignmentToggle([
                ...selectedValues,
                ...selectedAssignmentMapIds,
              ])
            }
            dropdownFooter={dropdownFooter}
            scroll
            emptyPlaceholder="Select Blueprint"
          />
        </Element>
      )}
      {!isEmpty(blueprintConflicts) && (
        <SHAREDAssignmentBlueprintsModal
          conflicts={blueprintConflicts}
          itemData={data}
          getItemURL={(itemId) => `library/${type}/${itemId}/settings`}
          selectedBlueprints={selectedBlueprints}
          setSelectedBlueprints={setSelectedBlueprints}
          onCancel={() => closeConflictResolution(data.blueprints)}
          onResolve={(resolvedBlueprints) =>
            closeConflictResolution(resolvedBlueprints)
          }
        />
      )}
    </>
  );
};

IPAAssignmentBlueprint.propTypes = {
  element: PropTypes.string,
};

IPAAssignmentBlueprint.defaultProps = {
  element: 'div',
};
