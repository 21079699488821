import {
  Badge,
  Button,
  FilterButton,
  Flex,
  Heading,
  Icon,
  Loader,
  MultiSelect,
  Paragraph,
  Text,
  TextField,
  styled,
} from '@kandji-inc/nectar-ui';
import { createColumnHelper } from '@tanstack/react-table';
import { i18n } from 'i18n';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { links } from 'src/app/common/constants';
import { DataTable } from 'src/components';
import { SelectPulseDialog } from 'src/features/visibility/pulse/views/SelectPulseDialog';
import { useAllPulseJobsQuery } from '../hooks/use-pulse-devices-query';
import type {
  PulseCheckJobSchema,
  PulseCheckResponseSchema,
} from '../types/pulse.types';

const PulseHeading = styled(Heading, {
  color: '$neutral90',
  fontSize: '20px',
  fontWeight: '$regular',
  lineHeight: '32px',
  letterSpacing: '-0.8px',
});

const PulseParagraph = styled(Paragraph, {
  color: '$neutral90',
  fontSize: '14px',
  fontWeight: '$regular',
  lineHeight: '20px',
});

// TODO: move to schemas ?
const REPORT_TYPES = [
  {
    label: 'File Check',
    value: 'file_check',
  },
  {
    label: 'Processes',
    value: 'processes',
  },
  {
    label: 'Preferences',
    value: 'preferences',
  },
  {
    label: 'Logged in Users',
    value: 'logged_in_users',
  },
];

// TODO: move to schemas ?
const REPORT_STATUSES = [
  {
    label: 'In Progress',
    value: 'in progress',
  },
  {
    label: 'Complete',
    value: 'complete',
  },
  {
    label: 'Failed',
    value: 'failed',
  },
  {
    label: 'Canceled',
    value: 'cancelled',
  },
];

const PulseChecks = ({
  displaySelectDialog,
}: {
  displaySelectDialog: boolean;
}) => {
  const history = useHistory();
  const [searchText, setSearchText] = useState('');
  const [reportTypes, setReportTypes] = useState<string[]>([]);
  const [reportStatuses, setReportStatuses] = useState<string[]>([]);
  const filters = useMemo(() => {
    const filter: Record<string, any> = {};
    if (searchText) {
      filter.name = { like: [searchText] };
    }
    if (reportTypes.length > 0) {
      filter.type = { in: reportTypes };
    }
    if (reportStatuses.length > 0) {
      filter.status = { in: reportStatuses };
    }
    return filter;
  }, [searchText, reportTypes, reportStatuses]);
  const { data: jobs, isLoading } = useAllPulseJobsQuery({
    enabled: true,
    filters,
  });
  const isFiltered = Object.keys(filters).length > 0;
  const isInitialLoad = isLoading && !isFiltered;
  const isEmpty =
    !isLoading &&
    !isFiltered &&
    (!jobs || !jobs.data || jobs.data.length === 0);

  const selectDialog = (
    <SelectPulseDialog
      isOpen={displaySelectDialog}
      onClose={() => history.push(links.pulse)}
      onOpenChange={() => history.push(links.pulse)}
      onSubmit={(pulseType) => history.push(`${links.pulse}/new/${pulseType}`)}
    />
  );

  return (
    <Flex flow="column" css={{ paddingLeft: '16px', paddingRight: '24px' }}>
      {isEmpty && (
        <Flex
          justifyContent="start"
          alignItems="center"
          flow="column"
          css={{
            paddingTop: '180px',
            width: '480px',
            mx: 'auto',
            textAlign: 'center',
          }}
          gap="lg"
        >
          <EmptyIcon />
          <PulseHeading>{i18n.t('Create your first Pulse Check')}</PulseHeading>
          <Button
            variant="primary"
            onClick={() => history.push(`${links.pulse}/new`)}
            iconLeft
          >
            <Icon name="fa-plus-minus-small" size="xs" />{' '}
            {i18n.t('Create Report')}
          </Button>
        </Flex>
      )}
      {isInitialLoad && <Loader size="lg" data-testid="loader" />}
      {!isInitialLoad && !isEmpty && (
        <>
          <Flex pt3 pb3 gap="lg">
            <TextField
              compact
              icon="magnifying-glass"
              css={{ width: 320 }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              showClearButton={searchText !== ''}
              onClear={() => setSearchText('')}
            />
            <MultiSelect
              multi
              size="sm"
              onChange={(selected) => setReportTypes(selected)}
              value={reportTypes}
              options={REPORT_TYPES}
              componentCss={{ menu: { zIndex: 3 } }}
            >
              <FilterButton
                data-testid="pulse-type-filter"
                selectedFilters={REPORT_TYPES.filter(({ value }) =>
                  reportTypes.includes(value),
                )}
                showRemove={false}
              >
                Pulse type
              </FilterButton>
            </MultiSelect>
            <MultiSelect
              multi
              size="sm"
              onChange={(selected) => setReportStatuses(selected)}
              value={reportStatuses}
              options={REPORT_STATUSES}
              componentCss={{ menu: { zIndex: 3 } }}
            >
              <FilterButton
                data-testid="pulse-status-filter"
                selectedFilters={REPORT_STATUSES.filter(({ value }) =>
                  reportStatuses.includes(value),
                )}
                showRemove={false}
              >
                Report status
              </FilterButton>
            </MultiSelect>
            {(reportTypes.length > 0 || reportStatuses.length > 0) && (
              <Button
                compact
                data-testid="pulse-filter-clear"
                variant="subtle"
                onClick={() => {
                  setReportTypes([]);
                  setReportStatuses([]);
                }}
              >
                Clear
              </Button>
            )}
            <Flex flex="1" justifyContent="end">
              <Button
                compact
                data-testid="create-pulse-check"
                variant="primary"
                iconLeft
                onClick={() => history.push(`${links.pulse}/new`)}
              >
                <Icon name="fa-plus-minus-small" size="xs" /> Create new
              </Button>
            </Flex>
          </Flex>
          <PulseResults
            isLoading={isLoading}
            isFiltered={isFiltered}
            jobs={jobs}
            onRowClick={(jobId) => history.push(`${links.pulse}/${jobId}`)}
          />
        </>
      )}
      {selectDialog}
    </Flex>
  );
};

export default PulseChecks;

const getStatusProps = (
  status: string | null,
): {
  color: 'purple' | 'green' | 'red' | 'yellow' | 'neutral';
  label: string;
} => {
  switch (status) {
    case 'in progress':
      return {
        color: 'purple',
        label: 'In Progress',
      };
    case 'complete':
      return {
        color: 'green',
        label: 'Complete',
      };
    case 'cancelled':
      return {
        color: 'yellow',
        label: 'Canceled',
      };
    case 'failed':
      return {
        color: 'red',
        label: 'Failed',
      };
    default:
      return {
        color: 'neutral',
        label: 'Unknown',
      };
  }
};

const getPulseIcon = (pulseType: string | null | undefined) => {
  switch (pulseType) {
    case 'file_check':
      return 'list-search';
    case 'processes':
      return 'wave-pulse';
    case 'logged_in_users':
      return 'user-arrow-right';
    case 'preferences':
      return 'settings-gear';
    default:
      return 'file';
  }
};

const columnHelper = createColumnHelper<PulseCheckJobSchema>();

const columns = [
  columnHelper.accessor((row) => row.job_id, {
    id: 'name',
    cell: (info) => {
      const row = info.row.original;
      return (
        <Flex
          gap="xs"
          alignItems="center"
          css={{
            whiteSpace: 'nowrap',
            '& svg': {
              minWidth: 20,
            },
          }}
        >
          <Icon name={getPulseIcon(row.type)} />
          <Text
            css={{
              fontWeight: '$medium',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {row.name}
          </Text>
        </Flex>
      );
    },
    header: 'Pulse check',
  }),
  columnHelper.accessor((row) => row.status, {
    id: 'status',
    cell: (row) => {
      const { color, label } = getStatusProps(row.getValue());
      return <Badge color={color}>{label}</Badge>;
    },
    header: 'Status',
  }),
  columnHelper.accessor((row) => row.progress, {
    id: 'progress',
    cell: (info) => {
      const row = info.row.original;
      if (
        row.total_devices_finished == null ||
        row.total_devices_targeted == null
      ) {
        return <Text>-</Text>;
      }
      const percentage = Math.floor(
        (row.total_devices_finished / row.total_devices_targeted) * 100,
      );
      return (
        <Text>{`${percentage}% (${row.total_devices_finished} of ${row.total_devices_targeted})`}</Text>
      );
    },
    header: 'Devices checked in',
  }),
  columnHelper.accessor((row) => row.total_number_of_results, {
    id: 'total_number_of_results',
    cell: (info) => {
      const totalNumberOfResults = info.getValue();
      const jobType = info.row.original.type;
      return (
        <Text>
          {getResultsFoundTextByJobType(jobType, totalNumberOfResults)}
        </Text>
      );
    },
    header: 'Results found',
  }),
  columnHelper.accessor((row) => row.created_at, {
    id: 'created_at',
    cell: (info) => {
      const createdAt = info.getValue();
      if (createdAt == null) {
        return <Text>-</Text>;
      }
      return <Text>{new Date(createdAt).toLocaleString()}</Text>;
    },
    header: 'Created on',
  }),
];

export function getResultsFoundTextByJobType(
  jobType: string,
  numberOfResults: number,
) {
  if (jobType === 'logged_in_users') {
    return `${numberOfResults} user${numberOfResults !== 1 ? 's' : ''} found`;
  }
  if (jobType === 'file_check') {
    return `${numberOfResults} file${numberOfResults !== 1 ? 's' : ''} found`;
  }
  if (jobType === 'processes') {
    return `${numberOfResults} process${numberOfResults !== 1 ? 'es' : ''} found`;
  }
  if (jobType === 'preferences') {
    return `${numberOfResults} preference${numberOfResults !== 1 ? 's' : ''} found`;
  }
  return `${numberOfResults} result${numberOfResults !== 1 ? 's' : ''} found`;
}

const PulseResults = ({
  isFiltered,
  isLoading,
  jobs,
  onRowClick,
}: {
  isFiltered?: boolean;
  isLoading?: boolean;
  jobs: PulseCheckResponseSchema | undefined;
  onRowClick: (jobId: string) => void;
}) => {
  if (
    isFiltered &&
    !isLoading &&
    (!jobs || !jobs.data || jobs.data.length === 0)
  ) {
    return (
      <Flex
        justifyContent="start"
        alignItems="center"
        flow="column"
        css={{
          paddingTop: '180px',
          width: '480px',
          mx: 'auto',
          textAlign: 'center',
        }}
        gap="lg"
      >
        <EmptyIcon />
        <PulseParagraph>
          Could not find any records that match applied filters
        </PulseParagraph>
      </Flex>
    );
  }
  return isLoading ? (
    <Loader size="lg" data-testid="loader" />
  ) : (
    <DataTable
      columns={columns}
      pinnedColumns={['name']}
      data={jobs?.data || []}
      onRowClick={(row) => onRowClick(row.job_id)}
      offsets={{ container: 224, content: 304, table: 454 }}
    />
  );
};

const EmptyIcon = () => (
  <svg
    width="160"
    height="64"
    viewBox="0 0 160 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.25 48.9037C16.8193 48.9037 43.9578 48.0644 43.9578 33.3759C43.9578 28.7597 41.8594 25.4023 38.5021 25.4023C35.1447 25.4023 32.6267 28.7597 32.6267 33.3759C32.6267 42.6398 46.8441 47.6686 59.0991 40.501"
      stroke="#A1ADC4"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeDasharray="0.08 3.36"
    />
    <path
      d="M100.25 36.5C102.488 33.2825 108.75 24 123.75 24C131.25 24 137.652 28.9017 139.75 31C141.848 33.0983 146.356 38.0479 146.356 41.7409C146.356 46.3573 142.579 46.7769 141.74 46.7769C140.901 46.7769 137.124 46.3573 137.124 41.7409C137.124 37.1246 142.579 29.9902 154.75 29.1509"
      stroke="#A1ADC4"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeDasharray="0.08 3.36"
    />
    <g clipPath="url(#clip0_64_45364)">
      <path
        d="M78.2 53.05C80.8644 53.05 83.3928 52.4972 85.6856 51.5003C86.3563 52.8325 87.2081 54.0559 88.205 55.1344C85.0966 56.5844 81.7797 57.4 78.2 57.4C65.3856 57.4 55 47.0144 55 34.2C55 21.3856 65.3856 11 78.2 11C89.0206 11 98.1013 18.4013 100.593 28.4181C100.43 28.4091 100.195 28.4 99.95 28.4C98.6813 28.4 97.4578 28.545 96.2706 28.8259C93.9597 21.0322 86.7459 15.35 78.2 15.35C67.7872 15.35 59.35 23.7872 59.35 34.2C59.35 44.6128 67.7872 53.05 78.2 53.05ZM73.125 42.9C73.125 40.8791 74.3122 39.13 75.9434 38.3144V21.7934C75.9434 20.6697 76.9947 19.6184 78.1184 19.6184C79.4053 19.6184 80.2934 20.6697 80.2934 21.7934V38.3144C82.0878 39.13 83.275 40.8791 83.275 42.9C83.275 45.7003 81.0003 47.975 78.2 47.975C75.3997 47.975 73.125 45.7003 73.125 42.9ZM72.4 25.5C72.4 27.1041 71.1041 28.4 69.5 28.4C67.8959 28.4 66.6 27.1041 66.6 25.5C66.6 23.8959 67.8959 22.6 69.5 22.6C71.1041 22.6 72.4 23.8959 72.4 25.5ZM62.25 34.2C62.25 32.5959 63.5487 31.3 65.15 31.3C66.7541 31.3 68.05 32.5959 68.05 34.2C68.05 35.8041 66.7541 37.1 65.15 37.1C63.5487 37.1 62.25 35.8041 62.25 34.2ZM89.8 25.5C89.8 27.1041 88.5041 28.4 86.9 28.4C85.2959 28.4 84 27.1041 84 25.5C84 23.8959 85.2959 22.6 86.9 22.6C88.5041 22.6 89.8 23.8959 89.8 25.5ZM86.9 44.35C86.9 37.1453 92.7453 31.3 99.95 31.3C107.155 31.3 113 37.1453 113 44.35C113 51.5547 107.155 57.4 99.95 57.4C92.7453 57.4 86.9 51.5547 86.9 44.35ZM102.923 36.955L94.2225 43.48C93.8509 43.7609 93.6153 44.2503 93.8419 44.6944C93.9869 45.1384 94.4038 45.4375 94.875 45.4375H98.1013L95.3734 50.3494C95.1287 50.7934 95.2194 51.3553 95.6091 51.6906C95.9988 52.035 96.5697 51.9715 96.9775 51.745L105.678 45.22C106.049 44.939 106.203 44.4497 106.058 44.0056C105.913 43.5616 105.496 43.2625 105.025 43.2625H101.799L104.527 38.3506C104.771 37.9066 104.681 37.3447 104.291 36.9278C103.901 36.665 103.33 36.6469 102.923 36.955Z"
        fill="#A1ADC4"
      />
    </g>
    <defs>
      <clipPath id="clip0_64_45364">
        <rect
          width="58"
          height="46.4"
          fill="white"
          transform="translate(55 11)"
        />
      </clipPath>
    </defs>
  </svg>
);
