import {
  Button,
  Chip,
  Code,
  DialogPrimitives as Dialog,
  Flex,
  Text,
  styled,
} from '@kandji-inc/nectar-ui';
import * as React from 'react';
import type {
  PulseCheckAgentFilterSchema,
  PulseCheckTypes,
} from 'src/features/visibility/pulse/types/pulse.types';

interface PulseReportSettingsProps {
  jobType: PulseCheckTypes;
  agentFilters: PulseCheckAgentFilterSchema;
  targetedBlueprints: { name: string; id: string }[];
  targetedDevices: { name: string; id: string }[];
}

const Subtitle = styled(Text, {
  color: '$neutral70',
  fontSize: '$2',
  fontWeight: '$medium',
});

const BodyText = styled(Text, {
  color: '$neutral70',
  fontSize: '$1',
  fontWeight: '$normal',
  display: 'flex',
  gap: '$1',
});

const KeyText = styled(BodyText, {
  minWidth: '106px',
  maxWidth: '106px',
});

const ValueText = styled(Text, {
  color: '$neutral90',
  fontWeight: '$medium',
  fontSize: '$1',
});

export const PulseReportSettings = ({
  jobType,
  agentFilters,
  targetedBlueprints,
  targetedDevices,
}: PulseReportSettingsProps) => {
  const settingsEmpty = React.useMemo(
    () =>
      (jobType === 'file_check' &&
        (Object.keys(agentFilters).length === 0 ||
          (agentFilters.paths?.length === 0 &&
            agentFilters.hashes?.length === 0))) ||
      (jobType === 'processes' &&
        (Object.keys(agentFilters).length === 0 ||
          (agentFilters.name?.like.length === 0 &&
            agentFilters.state?.like.length === 0 &&
            agentFilters.user?.like.length === 0))) ||
      (jobType === 'preferences' &&
        (Object.keys(agentFilters).length === 0 ||
          (agentFilters.domain == null &&
            agentFilters.path == null &&
            agentFilters.username == null &&
            (agentFilters.keys == null || agentFilters.keys.length === 0)))),
    [agentFilters, jobType],
  );

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <Button variant="link" compact>
          View report settings
        </Button>
      </Dialog.Trigger>
      <Dialog.Content
        css={{
          minWidth: '600px',
          maxWidth: '960px',
          width: '768px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Dialog.Header
          css={{
            display: 'flex',
            padding: '$4 $5',
            alignItems: 'center',
            alignSelf: 'stretch',
            gap: '$2',
          }}
        >
          <Dialog.Title>Report settings</Dialog.Title>
        </Dialog.Header>
        <Dialog.Description as="div">
          <Flex flow="column" gap="lg">
            <Subtitle>Query on devices where</Subtitle>
            <QueryOnInfo
              devices={targetedDevices}
              blueprints={targetedBlueprints}
            />
            {!settingsEmpty && (
              <>
                <Subtitle>Search for</Subtitle>
                <>
                  {jobType === 'file_check' &&
                    FileCheckSearchParams({ agentFilters })}
                  {jobType === 'processes' &&
                    ProcessesCheckSearchParams({ agentFilters })}
                  {jobType === 'preferences' &&
                    PreferencesCheckSearchParams({ agentFilters })}
                  {jobType === 'logged_in_users' && LoggedInUsersSearchParams()}
                </>
              </>
            )}
          </Flex>
        </Dialog.Description>
        <Dialog.Footer>
          <Dialog.Trigger asChild>
            <Button variant="primary" compact>
              Close summary
            </Button>
          </Dialog.Trigger>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  );
};

const QueryOnInfo = ({
  devices,
  blueprints,
}: {
  devices: PulseReportSettingsProps['targetedDevices'];
  blueprints: PulseReportSettingsProps['targetedBlueprints'];
}) => {
  const hasDevices = devices.length > 0;
  const hasBlueprints = blueprints.length > 0;
  const hasBoth = hasDevices && hasBlueprints;
  return (
    <Flex gap="sm" flow="column">
      <Flex gap="sm" alignItems="center" wrap="wrap">
        <KeyText>Device family is</KeyText>
        <Chip
          iconLeft={{ icon: 'sf-desktop-computer' }}
          label="Mac" // TODO: make this dynamic
          size="compact"
        />
        {hasBlueprints && (
          <>
            <BodyText>and Blueprint is</BodyText>
            {blueprints.map((blueprint, index) => (
              <React.Fragment key={blueprint.id}>
                {index === 1 && <BodyText>or</BodyText>}
                {index > 1 && <BodyText>,</BodyText>}
                <Chip
                  iconLeft={{ icon: 'sf-desktop-computer' }}
                  label={blueprint.name}
                  size="compact"
                />
              </React.Fragment>
            ))}
          </>
        )}
      </Flex>
      {hasDevices && (
        <>
          <Text
            css={{
              color: '$neutral70',
              fontSize: '$1',
              fontWeight: '$medium',
            }}
          >
            {hasBoth ? 'or' : 'and'}
          </Text>
          <Flex gap="sm" alignItems="center" wrap="wrap">
            <KeyText>Device name is </KeyText>
            {devices.map((device, index) => (
              <React.Fragment key={device.id}>
                {index === 1 && <BodyText>or</BodyText>}
                {index > 1 && <BodyText>,</BodyText>}
                <Chip
                  iconLeft={{ icon: 'sf-desktop-computer' }}
                  label={device.name}
                  size="compact"
                />
              </React.Fragment>
            ))}
          </Flex>
        </>
      )}
    </Flex>
  );
};

const FileCheckSearchParams = ({
  agentFilters,
}: {
  agentFilters: PulseCheckAgentFilterSchema;
}) => (
  <Flex flow="column" gap="lg" alignItems="start">
    {agentFilters.paths[0] && (
      <Flex gap="md" alignItems="center" css={{ maxWidth: '100%' }}>
        <KeyText>File path</KeyText>
        <Flex gap="sm" alignItems="center" css={{ overflow: 'scroll' }}>
          <ValueText>equals</ValueText>
          <Code>{agentFilters.paths[0]}</Code>
        </Flex>
      </Flex>
    )}
    {agentFilters.hashes[0] && (
      <Flex gap="md" alignItems="center">
        <KeyText>File hash</KeyText>
        <Flex gap="sm" alignItems="center">
          <ValueText>equals</ValueText>
          <Code>{agentFilters.hashes[0]}</Code>
        </Flex>
      </Flex>
    )}
  </Flex>
);

const ProcessesCheckSearchParams = ({
  agentFilters,
}: {
  agentFilters: PulseCheckAgentFilterSchema;
}) => (
  <Flex flow="column" gap="lg" alignItems="start">
    <Flex gap="md" alignItems="center">
      <KeyText>Processes</KeyText>
      <ValueText>
        {agentFilters.name?.like.length > 0
          ? 'Specific processes'
          : 'All processes'}
      </ValueText>
    </Flex>
    {agentFilters.name?.like.length > 0 && (
      <Flex gap="md" alignItems="start">
        <KeyText css={{ paddingTop: 2 }}>Process name</KeyText>
        <Flex gap="sm" alignItems="center" wrap="wrap">
          <ValueText>equals</ValueText>
          {agentFilters.name.like.map((name, index) => (
            <Flex gap="xs" alignItems="center">
              <Chip size="compact" label={name} />
              {index === 0 && index !== agentFilters.name.like.length - 1 && (
                <BodyText>or</BodyText>
              )}
              {index >= 1 && index !== agentFilters.name.like.length - 1 && (
                <BodyText>,</BodyText>
              )}
            </Flex>
          ))}
        </Flex>
      </Flex>
    )}
    {agentFilters.state?.like.length > 0 && (
      <Flex gap="md" alignItems="start">
        <KeyText>Process state is</KeyText>
        <Flex gap="xs" alignItems="center">
          {agentFilters.state.like.map((name, index) => {
            let separator = '';
            if (index < agentFilters.state.like.length - 1) {
              separator =
                index === agentFilters.state.like.length - 2 ? ' or ' : ', ';
            }
            const text = name[0].toUpperCase() + name.slice(1) + separator;
            return <ValueText>{text}</ValueText>;
          })}
        </Flex>
      </Flex>
    )}
    <Flex gap="md" alignItems="center">
      <KeyText>Users</KeyText>
      <ValueText>
        {agentFilters.user?.like.length > 0
          ? 'Specific local users'
          : 'All local users'}
      </ValueText>
    </Flex>
    {agentFilters.user?.like.length > 0 && (
      <Flex gap="md" alignItems="start">
        <KeyText css={{ paddingTop: 2 }}>User name</KeyText>
        <Flex gap="sm" alignItems="center" wrap="wrap">
          <ValueText>equals</ValueText>
          {agentFilters.user.like.map((name, index) => (
            <Flex gap="xs" alignItems="center">
              <Chip size="compact" label={name} />
              {index === 0 && index !== agentFilters.user.like.length - 1 && (
                <BodyText>or</BodyText>
              )}
              {index >= 1 && index !== agentFilters.user.like.length - 1 && (
                <BodyText>,</BodyText>
              )}
            </Flex>
          ))}
        </Flex>
      </Flex>
    )}
  </Flex>
);

const PreferencesCheckSearchParams = ({
  agentFilters,
}: {
  agentFilters: PulseCheckAgentFilterSchema;
}) => (
  <Flex flow="column" gap="lg" alignItems="start">
    {agentFilters.domain && (
      <Flex gap="md" alignItems="center">
        <KeyText css={{ paddingTop: 2 }}>Preference domain</KeyText>
        <Flex gap="sm" alignItems="center" wrap="wrap">
          <ValueText>equals</ValueText>
          <Code>{agentFilters.domain}</Code>
        </Flex>
      </Flex>
    )}
    {agentFilters.path && (
      <Flex gap="md" alignItems="center" css={{ maxWidth: '100%' }}>
        <KeyText css={{ paddingTop: 2 }}>Plist path</KeyText>
        <Flex gap="sm" alignItems="center" css={{ overflow: 'scroll' }}>
          <ValueText>equals</ValueText>
          <Code>{agentFilters.path}</Code>
        </Flex>
      </Flex>
    )}
    {agentFilters.username && (
      <>
        {agentFilters.domain && (
          <Flex gap="md" alignItems="center">
            <KeyText css={{ paddingTop: 2 }}>Include ByHost</KeyText>
            <ValueText>
              {agentFilters.current_host ? 'True' : 'False'}
            </ValueText>
          </Flex>
        )}
        <Flex gap="md" alignItems="center">
          <KeyText css={{ paddingTop: 2 }}>Username</KeyText>
          <ValueText>{agentFilters.username}</ValueText>
        </Flex>
      </>
    )}
    <Flex gap="md" alignItems="center">
      <KeyText>Key collection</KeyText>
      <ValueText>
        {agentFilters.keys?.length > 0 ? 'Specific keys' : 'All keys'}
      </ValueText>
    </Flex>
    {agentFilters.keys?.length > 0 && (
      <Flex gap="md" alignItems="start">
        <KeyText css={{ paddingTop: 2 }}>Keys</KeyText>
        <Flex gap="sm" alignItems="center" wrap="wrap">
          <ValueText>equal</ValueText>
          {agentFilters.keys.map((key, index) => (
            <Flex gap="xs" alignItems="center">
              <Chip size="compact" label={key.key} />
              {index === 0 && index !== agentFilters.keys.length - 1 && (
                <BodyText>or</BodyText>
              )}
              {index >= 1 && index !== agentFilters.keys.length - 1 && (
                <BodyText>,</BodyText>
              )}
            </Flex>
          ))}
        </Flex>
      </Flex>
    )}
  </Flex>
);

const LoggedInUsersSearchParams = () => (
  <Flex gap="md" alignItems="center" css={{ maxWidth: '100%' }}>
    <KeyText>all</KeyText>
    <Flex gap="sm" alignItems="center">
      <Code css={{ flex: 0 }}>logged_in_users</Code>
      <BodyText>on the devices targeted above</BodyText>
    </Flex>
  </Flex>
);
