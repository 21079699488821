import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useState } from 'react';
import { dataTestId } from '../../../common/utils';
import './PrettyInput.styles.scss';

const PrettyInput = ({
  label,
  value,
  name,
  maxLength,
  onChange,
  placeholder,
  type,
  onFocus,
  onBlur,
  disabled,
  className,
}) => {
  const [active, setActive] = useState(false);
  const handleOnChange = useCallback((e) => onChange(e.target.value), []);
  const handleOnFocus = useCallback((event) => {
    setActive(true);
    onFocus(event);
  }, []);
  const handleOnBlur = useCallback((event) => {
    setActive(false);
    onBlur(event);
  }, []);
  return (
    <section
      className={classNames('PrettyInputWrapper', className, { active })}
    >
      {label && <label className="PrettyInputLabel">{label}</label>}
      {type === 'textarea' && (
        <textarea
          className="PrettyInputTextarea"
          name={name}
          value={value}
          maxLength={maxLength}
          placeholder={placeholder}
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          disabled={disabled}
          data-testid={dataTestId(label || 'inputText')}
        />
      )}
      {type !== 'textarea' && (
        <input
          className="PrettyInputInput"
          name={name}
          type={type}
          value={value}
          maxLength={maxLength}
          placeholder={placeholder}
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          disabled={disabled}
          data-testid={dataTestId(label || 'input')}
        />
      )}
    </section>
  );
};

PrettyInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  maxLength: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

PrettyInput.defaultProps = {
  label: '',
  value: '',
  name: '',
  maxLength: '',
  onFocus: () => {},
  onBlur: () => {},
  placeholder: 'Input value ...',
  type: 'text',
  disabled: false,
  className: null,
};

export default memo(PrettyInput, isEqual);
