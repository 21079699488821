import { createReducer } from 'redux-create-reducer';
import { computer } from '../_actions/action-types';

const initialState = {
  computers: [],
  fetched: false,
  depFetched: false,
};

export default createReducer(initialState, {
  [computer.SET_COMPUTERS_FOR_FILTERS_IS_FETCHING](state) {
    return { ...state, fetched: false };
  },
  [computer.SET_COMPUTERS_FOR_FILTERS](state, action) {
    return { ...state, computers: action.computers, fetched: true };
  },
});
