import { DATE_FORMAT } from 'app/components/common/helpers';

export const dateFormatOptions = DATE_FORMAT.options.map((df) => ({
  label: df,
  value: df,
}));

export const displayRelativeDatesOptions = [
  { label: 'No', value: 'disable' },
  { label: 'Yes', value: 'enable' },
];

export const displayWeeklyEmailOptions = [
  { label: 'No', value: false },
  { label: 'Yes', value: true },
];
