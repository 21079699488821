import React, { useContext } from 'react';

import Icon from '../../theme/components/atoms/Icon';
import Link from '../../theme/components/atoms/Link';
import Loader from '../../theme/components/atoms/Loader';
import Sticky from '../../theme/components/atoms/Sticky';
import Basic from '../../theme/components/molecules/Basic';

import './MultiFactorAuthentication.css';

import { Context, Provider } from './context';
import TableMultiFactorAuthentication from './table';

const Module = () => {
  const { list, auth0UserId, isLoading } = useContext(Context);

  return (
    <Basic section="multi-factor-authentication">
      <div className="paper">
        <Sticky
          label="paper-header"
          origin="paper"
          edge="border"
          sticky={false}
        >
          <div className="node">
            <h3 className="paper-title">
              <span>One-Time Password (OTP)</span>
            </h3>
          </div>
        </Sticky>
        {auth0UserId ? (
          <>
            {list && !isLoading ? (
              <>
                {list.length > 0 ? (
                  <>
                    <div className="paper-aside">
                      <div className="node">
                        <div className="paper-block">
                          <div className="paper-copy">
                            <p>
                              Manage your OTP authenticator for email and
                              password authentication.{' '}
                              <Link href="https://support.kandji.io/support/solutions/articles/72000559809" />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="paper-body">
                      <TableMultiFactorAuthentication />
                    </div>
                  </>
                ) : (
                  <div className="paper-body">
                    <div className="node">
                      <div className="paper-block">
                        <div className="paper-copy paper-copy-center">
                          <Icon icon="kandji-hive" />
                          <p>
                            There is no OTP Authenticator configured for this
                            account. You will be prompted to set up a new one
                            upon next log in.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="paper-body">
                <div className="node">
                  <div className="paper-block">
                    <Loader type="simple" />
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="paper-body">
            <div className="node">
              <div className="paper-block">
                <div className="paper-copy paper-copy-center">
                  <Icon icon="kandji-hive" />
                  <p>
                    {`You are viewing a customer's instance of the Company app.
                    You can manage the user's enrollments from within the Admin app.`}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Basic>
  );
};

export default () => (
  <Provider>
    <Module />
  </Provider>
);
