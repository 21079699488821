import { api } from 'app/api/base';
import { i18n } from 'i18n';
import get from 'lodash/get';
import { URL_COMPANY_USERS } from '../api/urls';
import { account, users } from './action-types';
import { setSnackbar } from './ui';

export const getCompanyUsers = (params, options) => (dispatch) =>
  api(URL_COMPANY_USERS)
    .get(params, options)
    .then((res) => {
      res.data.results.forEach((user) => {
        user.fullName = `${user.first_name} ${user.last_name}`;
      });
      const usersList = get(res, 'data.results', []);
      dispatch({
        type: users.COMPANY_USERS_FETCHED,
        payload: usersList,
      });
      return Promise.resolve(res.data);
    });

export const updateUser =
  (user, mySelfUpdate = false, showSnackbar = true) =>
  (dispatch) => {
    let request;
    if (mySelfUpdate) {
      request = api('users/my-settings').patch(user);
    } else {
      request = api(`company-users/${user.id}/`).patch(user);
    }
    return request
      .then((response) => {
        if (mySelfUpdate) {
          dispatch({
            type: account.ACCOUNT_USER_UPDATED,
            updates: response.data,
          });
        } else {
          dispatch({
            type: users.COMPANY_USER_UPDATED,
            payload: response.data,
          });
        }
        if (showSnackbar) {
          dispatch(setSnackbar(i18n.t('User was updated')));
        }
      })
      .catch(() => {
        dispatch(setSnackbar(i18n.common.error()));
      });
  };

export const deleteUser = (id) => (dispatch) =>
  api(`company-users/${id}/`)
    .delete()
    .then(() =>
      dispatch({
        type: users.COMPANY_USER_DELETED,
        payload: { id },
      }),
    );

export const loadUser = (userId) => (dispatch) =>
  api(`company-users/${userId}/`)
    .get()
    .then((response) => {
      dispatch({
        type: users.COMPANY_USER_FETCHED,
        payload: response.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch(setSnackbar(i18n.common.error()));
    });
