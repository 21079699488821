import { Flex, Heading, Text, css, styled } from '@kandji-inc/nectar-ui';

export const Wrapper = styled(Flex, {
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch',
});

export const ColumnLeft = styled(Flex, {
  flex: 1,
  flexDirection: 'column',
  alignItems: 'flexStart',
  gap: '$3',
  color: '$neutral70',
});

export const ColumnLeftHeader = styled(Flex, {
  alignItems: 'center',
  gap: 2,
});

export const ColumnLeftHeaderSubheading = styled(Text, {
  fontWeight: '$medium',
  letterSpacing: '0.4px',
  textTransform: 'uppercase',
});

export const ColumnLeftContent = styled(Flex, {
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 16,
  alignSelf: 'stretch',
});

export const ColumnLeftContentHeader = styled(Flex, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '$3',
  alignSelf: 'stretch',
});

export const ColumnLeftContentFooter = styled(Flex, {
  alignItems: 'center',
  gap: '$3',
  mb: 20,
});

export const ColumnLeftContentHeading = styled(Heading, {
  fontSize: 32,
  fontWeight: '$medium',
  lineHeight: '40px',
  letterSpacing: '-1.2px',
});

export const ColumnLeftContentDescription = styled(Text, {
  color: '$neutral90',
  fontSize: 14,
  lineHeight: '20px',
});

export const ColumnRight = styled(Flex, {
  width: 706,
  position: 'relative',
  '&:hover .side-panel': {
    transform: 'translateX(0%)',
  },
});

export const SidePanelWrapper = styled(Flex, {
  inset: 0,
  position: 'absolute',
  justifyContent: 'flex-end',
  overflow: 'hidden',
});

export const SidePanelAnimated = styled(Flex, {
  transform: 'translateX(100%)',
  transition: 'transform 400ms cubic-bezier(0.1, 0.96, 0.41, 0.96)',
});

export const heroImageCss = css({
  width: 738,
  marginLeft: -16,
  marginTop: -12,
  marginBottom: -20,
});
