import { Button, setClass } from '@kandji-inc/bumblebee';
import React, { useRef } from 'react';

const RemovableAddableContent = ({
  max,
  items,
  onChange,
  getEmptyItem,
  isDisabled,
  itemsClassName,
  rowClassName,
  renderItem,
  addButtonText = 'Add',
  keyField = 'keyField',
}) => {
  const itemsContainerRef = useRef();

  const removeItem = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    onChange(newItems);
  };
  const addItem = () => {
    const newItem = getEmptyItem();
    onChange([...items, newItem]);
    setTimeout(() => {
      itemsContainerRef.current.scrollTo?.({
        top: itemsContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    });
  };

  return (
    <>
      <div className={itemsClassName} ref={itemsContainerRef}>
        {items.map((item, index) => (
          <div
            key={item?.[keyField] || index}
            className={setClass(rowClassName, 'b-flex')}
          >
            {renderItem(index)}
            <Button
              theme="error"
              kind="link"
              icon="trash-can"
              onClick={() => removeItem(index)}
              disabled={isDisabled}
            />
          </div>
        ))}
      </div>

      <Button
        disabled={(max && items.length >= max) || isDisabled}
        className="b-mt"
        kind="link"
        icon="circle-plus"
        onClick={addItem}
      >
        {addButtonText}
      </Button>
    </>
  );
};

export default RemovableAddableContent;
