import {
  useCreatePrismExport,
  usePrismExportQuery,
} from 'src/features/visibility/prism/hooks';
import { useCreateViewsExportMutation } from 'src/features/visibility/prism/hooks/use-create-views-export-mutation';
import { useViewsExportStatusQuery } from 'src/features/visibility/prism/hooks/use-views-export-status-query';
import {
  useCreatePulseExportMutation,
  usePulseExportQuery,
} from 'src/features/visibility/pulse/hooks';
import type { ExportDataType } from './data-export-context';

// istanbul ignore next
export const useCreateExportMutation = (type: ExportDataType) => {
  switch (type) {
    case 'prism':
      return useCreatePrismExport();
    case 'pulse':
      return useCreatePulseExportMutation();
    case 'views':
      return useCreateViewsExportMutation();
    default:
      throw new Error(`Unsupported export type: ${type}`);
  }
};

// istanbul ignore next
export const useExportQuery = (type: ExportDataType, exportId: string) => {
  switch (type) {
    case 'prism':
      return usePrismExportQuery({ exportId });
    case 'pulse':
      return usePulseExportQuery({ exportId });
    case 'views':
      return useViewsExportStatusQuery({ exportId });
    default:
      throw new Error(`Unsupported export type: ${type}`);
  }
};
