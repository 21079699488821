import { Flex, Heading, Text, styled } from '@kandji-inc/nectar-ui';

export const Wrapper = styled(Flex, {
  width: 1136,
  flexDirection: 'column',
  alignItems: 'center',
  gap: 45,
});

export const SectionHeading = styled(Heading, {
  lineHeight: '36px',
  letterSpacing: '-0.8px',
  fontSize: '$5',
  fontWeight: '$medium',
});

export const SectionContent = styled(Flex, {
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
});

export const OptionWrapper = styled(Flex, {
  flexDirection: 'column',
  alignItems: 'center',
  gap: 12,
  alignSelf: 'stretch',
  justifyContent: 'center',
});

export const OptionContent = styled(Flex, {
  width: 301,
  flexDirection: 'column',
  alignItems: 'center',
  gap: 12,
});

export const OptionContentIcon = styled(Flex, {
  justifyContent: 'center',
  alignItems: 'center',
  gap: 24,
  borderRadius: 6,
  background: '$blue50',
  width: 44,
  height: 44,
});

export const OptionContentText = styled(Flex, {
  flexDirection: 'column',
  alignItems: 'center',
  gap: 12,
  alignSelf: 'stretch',
  textAlign: 'center',
});

export const OptionContentTitle = styled(Text, {
  fontSize: '$4',
  fontWeight: '$medium',
  lineHeight: '32px',
  letterSpacing: '-0.8px',
});

export const OptionContentDescription = styled(Text, {
  lineHeight: '20px',
  fontSize: 14,
  color: '$neutral90',
});
