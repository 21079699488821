import { Body, Button, DialogV2 as Dialog, Flex } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';

export function ManualEnrollmentCancelDialog({
  blueprint,
  isOpen,
  toggleDialog,
  handleCancel,
}) {
  return (
    <Dialog
      isOpen={isOpen}
      title={i18n.t(
        `Cancel editing enrollment portal settings for {blueprintName}?`,
        { blueprintName: blueprint.name },
      )}
      onOpenChange={toggleDialog}
      css={{ width: 500 }}
      content={
        <Flex flow="column">
          <Body css={{ marginBottom: 20 }}>
            {i18n.t('Changes you made will not be saved.')}
          </Body>
          <Flex gap="sm" justifyContent="end" css={{ paddingTop: '$4' }}>
            <Button
              compact
              onClick={/* istanbul ignore next */ () => toggleDialog(false)}
              variant="subtle"
            >
              {i18n.t('Continue editing')}
            </Button>
            <Button variant="primary" compact onClick={handleCancel}>
              {i18n.t('Cancel')}
            </Button>
          </Flex>
        </Flex>
      }
    />
  );
}
