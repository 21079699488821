import cn from 'classnames';
import React from 'react';
import {
  ModalBody as Body,
  ModalFooter as Footer,
  ModalHeader as Header,
  Modal,
} from 'reactstrap';

const OldModal = (props) => {
  const {
    onHide,
    isLoading,
    isWide,
    modalName = '',
    showCloseButton,
    centerHeader,
    header,
    body,
    footer,
  } = props;

  return (
    <Modal
      isOpen
      zIndex="2050"
      toggle={onHide}
      className={cn({
        'modal-loading': isLoading,
        'modal-dialog-wide': isWide,
      })}
      data-name={modalName}
    >
      {showCloseButton && (
        <button className="modal-close-button" type="button" onClick={onHide}>
          <i className="far fa-times" />
        </button>
      )}
      <Header className={cn({ centerHeader })}>{header}</Header>
      <Body>{body}</Body>
      <Footer>{footer}</Footer>
    </Modal>
  );
};

export default OldModal;
