import React, { useEffect, useState } from 'react';

import { api } from 'app/api/base';
import { URL_COMPANY_SELF_SERVICE_CONFIG } from 'app/api/urls';
import { withCache } from 'components/common/hof/caching';
import { Setting } from 'features/library-items/template';
import { i18n } from 'src/i18n';
import CategoryRow from './category-row';
import CustomizationRow from './customization-v2';
import './self-service-card.css';

export const getSsConfigData = withCache(
  () => api(URL_COMPANY_SELF_SERVICE_CONFIG).get(),
  { resetTimeout: 120000 }, // 2 mins
);

const SelfServiceCard = (props) => {
  const {
    setting,
    update,
    isDisabled,
    defaults,
    canBeDisabled = true,
    DrawerContent,
    isSubmitted,
  } = props;
  const [categoryOptions, setCategoryOptions] = useState([]);

  useEffect(() => {
    getSsConfigData.call().then((ssConfigData) =>
      setCategoryOptions(
        ssConfigData.data.categories.map((c) => ({
          value: c.id,
          label: c.name,
        })),
      ),
    );
  }, []);

  useEffect(() => {
    if (!setting.isEnabled || !setting.invalidationsMap) {
      update('invalidationsMap', {});
    }
  }, [setting.isEnabled, isSubmitted]);

  return (
    <Setting.Card>
      <Setting.Header className="k-ss2-header">
        <h3 className="b-h3">{i18n.t('Self Service')}</h3>
        <Setting.HeaderToggle
          isEnabled={setting.isEnabled}
          onChange={() => update('isEnabled', (p) => !p)}
          isDisabled={isDisabled || !canBeDisabled}
          chipText={{
            enabled: i18n.t('Available in Self Service'),
            disabled: i18n.t('Not available in Self Service'),
          }}
        />
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          {i18n.t(
            'Add this Library Item to Self Service by activating the toggle on the top right.',
          )}{' '}
          <a
            href="https://support.kandji.io/support/solutions/folders/72000558148"
            rel="noopener noreferrer"
            target="_blank"
            className="b-alink"
          >
            {i18n.t('Learn more...')}
          </a>
        </p>
      </Setting.SubHeader>

      {setting.isEnabled && (
        <Setting.Rows>
          <CategoryRow
            setting={setting}
            update={update}
            isDisabled={isDisabled}
            categoryOptions={categoryOptions}
            isSubmitted={isSubmitted}
          />

          <CustomizationRow
            setting={setting}
            update={update}
            defaults={defaults}
            categoryOptions={categoryOptions}
            isDisabled={isDisabled}
            DrawerContent={DrawerContent}
          />
        </Setting.Rows>
      )}
    </Setting.Card>
  );
};

export default SelfServiceCard;
