import { TextInput } from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';
import { i18n } from 'src/i18n';

import WifiService from '../../service/wifi-service';

const keys = WifiService.keys.authentication.acceptedEapTypes;

const OuterIdentity = ({ refs, isDisabled, update, setting }) => (
  <Setting.SecondaryControlsRow>
    <div>
      <p className="b-txt">{i18n.t(`Outer identity`)}</p>
    </div>
    <div>
      <p className="b-txt-light">
        {i18n.t(`Outer identity used to increase security by hiding the user's true
        name. The outer identity is visible in frame captures, but the
        user's actual name appears only inside the encrypted tunnel.`)}
      </p>
    </div>
    <div>
      <TextInput
        disabled={isDisabled}
        value={setting[keys.outerIdentity]}
        onChange={(e) => update(keys.outerIdentity, e.target.value)}
        compact
        isOptional
        placeholder="anonymous@accuhive.com"
        removeValidationOnUnmount
      />
    </div>
  </Setting.SecondaryControlsRow>
);

export default React.memo(OuterIdentity);
