/* istanbul ignore file */
import React from 'react';
import Lottie from 'react-lottie';
import { lottieLoaderAnimation } from './loader.lottie';

const Loader = ({
  animationData = lottieLoaderAnimation,
  height = 128,
  width = 128,
}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Lottie
      isClickToPauseDisabled
      options={defaultOptions}
      height={height}
      width={width}
      style={{
        cursor: 'auto',
      }}
    />
  );
};

export default Loader;
