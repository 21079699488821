// istanbul ignore file
import { useMutation } from '@tanstack/react-query';
import { pulseService } from 'src/features/visibility/pulse/data-service/pulse-service';

export const useDeletePulseJob = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: () => void;
}) => {
  const { mutateAsync: deletePulseJob, isPending: deletePending } = useMutation(
    {
      mutationFn: (id: string) => pulseService.deletePulseJob(id),
      onSuccess,
      onError,
    },
  );

  return { deletePulseJob, deletePending };
};
