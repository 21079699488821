import type { DropdownMenuOption } from '@kandji-inc/nectar-ui';
import { Box, Flex, Icon, Tbody, Td, Tr, styled } from '@kandji-inc/nectar-ui';
import { links } from 'app/common/constants';
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import featureFlags from 'src/config/feature-flags';
import { constants } from '../common';
import ThreatListHighlightedText from '../common/components/ThreatListHighlightedText';
import ThreatListStatusBadge from '../common/components/ThreatListStatusBadge';
import TableActionsDropdown from '../common/components/ThreatListTable/TableActionsDropdown';
import ThreatListTableArrowButton from '../common/components/ThreatListTable/ThreatListTableArrowButton';
import ThreatListTableExpandedRow from '../common/components/ThreatListTable/ThreatListTableExpandedRow';
import ThreatListTextCopyButton from '../common/components/ThreatListTextCopyButton';
import isoToDateString from '../common/utils/isoToDateString';
import type {
  BehavioralThreatClassification,
  BehavioralThreatDetail,
  BehavioralThreatStatus,
  ThreatClassification,
  ThreatDetail,
  ThreatStatus,
} from '../threat.types';

const TruncatedLink = styled(Link, {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const StatusColumnColorBadge = styled(Box, {
  width: '$2',
  height: '$2',
  borderRadius: '50%',
  flexShrink: 0,
});

const StatusColumnText = styled(Box, {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const getClassificationLabel = (
  classification: ThreatClassification | BehavioralThreatClassification,
  shortPUP: boolean = false,
) => {
  if (shortPUP && classification === 'PUP') {
    return classification;
  }
  return constants.THREAT_CLASSIFICATION_LABELS[classification];
};

const getStatusLabel = (status: ThreatStatus | BehavioralThreatStatus) =>
  constants.THREAT_STATUS_DISPLAY_NAME_MAP[status];

const getStatusColor = (status: ThreatStatus | BehavioralThreatStatus) =>
  constants.THREAT_STATUS_COLOR_MAP[status];

const getStatusIcon = (status: ThreatStatus | BehavioralThreatStatus) =>
  constants.THREAT_STATUS_ICON_MAP[status];

const getClassifactionIcon = (
  classification: ThreatClassification | BehavioralThreatClassification,
) => constants.THREAT_CLASSIFICATION_ICON_MAP[classification];

type RowActionOptions = Partial<{
  [key in ThreatStatus | BehavioralThreatStatus]: DropdownMenuOption;
}>;

type ThreatListTableBodyProps = {
  threats: ThreatDetail[] | BehavioralThreatDetail[];
  onGetReleaseDetails: (threat: ThreatDetail) => void;
  query: string | undefined;
  timezone: string;
  isOnDeviceRecord: boolean | undefined;
  onOpenSplitView: (
    threat: ThreatDetail | BehavioralThreatDetail,
    isFileDetectionType: boolean,
  ) => void;
  isMoreActionsShown?: boolean;
  onRecheckStatus: (threat: ThreatDetail) => void;
  isThreatBeingChecked: boolean;
  isSplitViewEnabled: boolean;
  selectedThreatForSplitView: ThreatDetail | BehavioralThreatDetail | undefined;
  isFileDetectionType: boolean;
};

const ThreatListTableBody = (props: ThreatListTableBodyProps) => {
  const {
    threats,
    onGetReleaseDetails,
    query,
    timezone,
    isOnDeviceRecord,
    onOpenSplitView,
    isMoreActionsShown,
    onRecheckStatus,
    isThreatBeingChecked,
    isSplitViewEnabled,
    selectedThreatForSplitView,
    isFileDetectionType,
  } = props;
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());
  const isRecheckStatusFFOn = featureFlags.getFlag(
    'edr_100323_recheck-threat-status',
  );

  const getRowActionOptions = (item: ThreatDetail): DropdownMenuOption[] => {
    const options: RowActionOptions = {
      [constants.THREAT_STATUS.QUARANTINED]: {
        label: 'Release threat',
        icon: 'square-arrow-up',
        onClick: () => onGetReleaseDetails(item),
      },
      [constants.THREAT_STATUS.NOT_QUARANTINED]: {
        label: 'Recheck status',
        icon: 'arrows-rotate',
        onClick: /* istanbul ignore next */ () =>
          isThreatBeingChecked ? null : onRecheckStatus(item),
        disabled: isThreatBeingChecked,
      },
    };

    // Exclusively for disabling the recheck status option for threats that are not quarantined while the feature flag is off
    // Condition should be removed as part of the feature flag clean up
    if (
      !isRecheckStatusFFOn &&
      item.status === constants.THREAT_STATUS.NOT_QUARANTINED
    ) {
      return [];
    }

    const virusTotalOption = {
      label: 'Search VirusTotal',
      icon: 'virus-total',
      onClick: /* istanbul ignore next -- Linking */ () =>
        window.open(`${constants.VIRUS_TOTAL_URL}${item.file_hash}`),
    };

    if (!(item.status in options)) {
      return [virusTotalOption];
    }

    return [options[item.status], virusTotalOption];
  };

  const handleExpandRowButtonClick = (key: string, isExpanded: boolean) => {
    if (isExpanded) {
      setExpandedRows((prev) => new Set(prev.add(key)));
    } else {
      setExpandedRows((prev) => {
        const newSet = new Set(prev);
        newSet.delete(key);
        return newSet;
      });
    }
  };

  const handleOpenSplitView = (item: ThreatDetail) => {
    if (!isSplitViewEnabled) return;
    onOpenSplitView(item, isFileDetectionType);
  };

  return (
    <Tbody>
      {threats.map((item) => {
        const id = `${item.file_hash}${item.file_path}${item.device_id}${item.detection_date}`;
        const selectedId = `${selectedThreatForSplitView?.file_hash}${selectedThreatForSplitView?.file_path}${selectedThreatForSplitView?.device_id}${selectedThreatForSplitView?.detection_date}`;
        const isExpanded = expandedRows.has(id);
        const isSplitViewSelected = selectedId === id;
        const actionOptions = getRowActionOptions(item);
        const statusEnumValue = getStatusLabel(item.status);

        const TrCss = {
          cursor: 'pointer',
          td: {
            boxShadow: isExpanded ? 'none' : undefined,
          },
        };

        return (
          <Fragment key={id}>
            <Tr
              css={TrCss}
              hoverAnchorUnderline={false}
              selected={isSplitViewEnabled && isSplitViewSelected}
              onClick={() => handleOpenSplitView(item)}
            >
              {!isSplitViewEnabled && (
                <Td title={isExpanded ? 'Collapse' : 'Expand'}>
                  <ThreatListTableArrowButton
                    expanded={isExpanded}
                    onToggle={(isExpanded) =>
                      handleExpandRowButtonClick(id, isExpanded)
                    }
                    testId="expand-row-button"
                  />
                </Td>
              )}
              {!isSplitViewEnabled ? (
                <Td title={item.threat_name}>
                  <ThreatListHighlightedText search={query}>
                    {item.threat_name}
                  </ThreatListHighlightedText>
                </Td>
              ) : (
                <Td title="">
                  <Flex
                    gap="xs"
                    alignItems="center"
                    css={{ '& svg': { flexShrink: 0 } }}
                  >
                    {isFileDetectionType ? (
                      <>
                        <Icon name="file" size="sm" />
                        <ThreatListTextCopyButton
                          text={item.file_hash}
                          highlighted={query}
                          css={{ pr: '$3' }}
                        />
                      </>
                    ) : (
                      <>
                        <Icon name="gear" size="sm" />
                        <ThreatListHighlightedText search={query}>
                          {item.file_hash}
                        </ThreatListHighlightedText>
                      </>
                    )}
                  </Flex>
                </Td>
              )}
              <Td title={item.associated_item}>
                <ThreatListHighlightedText search={query}>
                  {item.associated_item}
                </ThreatListHighlightedText>
              </Td>
              <Td title={getClassificationLabel(item.classification)}>
                {isSplitViewEnabled ? (
                  <Flex
                    gap="xs"
                    alignItems="center"
                    css={{ '& svg': { flexShrink: 0 } }}
                  >
                    <Icon
                      name={getClassifactionIcon(item.classification)}
                      size="sm"
                    />
                    {getClassificationLabel(item.classification, true)}
                  </Flex>
                ) : (
                  <>{getClassificationLabel(item.classification, true)}</>
                )}
              </Td>
              {!isOnDeviceRecord && (
                <Td title={item.device_name}>
                  <TruncatedLink
                    to={`${links.devices}/${item.device_id}/threats`}
                  >
                    <ThreatListHighlightedText search={query}>
                      {item.device_name}
                    </ThreatListHighlightedText>
                  </TruncatedLink>
                </Td>
              )}
              <Td title={`${item.detection_date}Z`}>
                {isoToDateString(item.detection_date, timezone)}
              </Td>
              <Td title={statusEnumValue}>
                {!isSplitViewEnabled ? (
                  <Flex alignItems="center" gap="sm">
                    <StatusColumnColorBadge
                      css={{
                        backgroundColor: `$${getStatusColor(item.status)}50`,
                      }}
                    />
                    <StatusColumnText>{statusEnumValue}</StatusColumnText>
                  </Flex>
                ) : (
                  <ThreatListStatusBadge
                    color={getStatusColor(item.status)}
                    icon={getStatusIcon(item.status)}
                  >
                    {getStatusLabel(item.status)}
                  </ThreatListStatusBadge>
                )}
              </Td>
              {isMoreActionsShown && (
                <Td title="Actions">
                  {Boolean(actionOptions.length) && (
                    <TableActionsDropdown
                      options={actionOptions}
                      testId="action-ellipsis"
                    />
                  )}
                </Td>
              )}
            </Tr>
            {!isSplitViewEnabled && isExpanded && (
              <ThreatListTableExpandedRow
                threat={item}
                isOnDeviceRecord={isOnDeviceRecord}
              />
            )}
          </Fragment>
        );
      })}
    </Tbody>
  );
};

export default ThreatListTableBody;
