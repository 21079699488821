import type { CSS } from '@kandji-inc/nectar-ui';
import { Box, Td, Th, Tooltip, styled } from '@kandji-inc/nectar-ui';
import * as React from 'react';

type ThProps = React.ComponentProps<typeof Th>;
type TdProps = React.ComponentProps<typeof Td>;

const ColumnResizerBox = styled(Box, {
  position: 'absolute',
  width: 2,
  background: '$blue50',
  opacity: 0,
  height: 36,
  top: -10,
  right: -12,
  '&:hover': {
    opacity: 1,
  },
  '&:active': {
    opacity: 1,
  },
  cursor: 'col-resize',

  // provides extra space grabbing the resizer handle
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    width: 6,
    height: '100%',
    background: 'transparent',
    cursor: 'col-resize',
    userSelect: 'none',
    touchAction: 'none',
  },
});

type ColumnResizerProps = {
  handleResize: HeaderCellProps['handleResize'];
};

// istanbul ignore next
const ColumnResizer = ({ handleResize }: ColumnResizerProps) => {
  return (
    <ColumnResizerBox
      aria-label="Column resizer"
      data-testid="column-resizer"
      onMouseDown={handleResize}
      onTouchStart={handleResize}
    />
  );
};

export const updateResizingColumnsOnly = (
  prev: RowCellProps | HeaderCellProps,
  next: RowCellProps | HeaderCellProps,
) => {
  const isResizing = prev.isResizingColumn || next.isResizingColumn;
  if (!isResizing) {
    return false;
  }

  const prevIsResizing = prev.isResizingColumn === prev.columnId;
  const nextIsResizing = next.isResizingColumn === next.columnId;

  if (prevIsResizing || nextIsResizing) {
    return (
      prev.size === next.size && prev.isResizingColumn === next.isResizingColumn
    );
  }

  return true;
};

type HeaderCellProps = {
  columnId: string;
  size: number;
  children: React.ReactNode;
  showMenu?: boolean;
  menuOptions?: React.ComponentProps<typeof Th>['menuOptions'];
  resizable?: boolean;
  isResizingColumn?: string | false;
  handleResize?: (
    e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>,
  ) => void;
  title: string;
  tooltip?: React.ReactNode;
  css?: CSS;
} & ThProps;

export const HeaderCell = React.memo(
  React.forwardRef<HTMLTableCellElement, HeaderCellProps>(
    (
      {
        columnId,
        size,
        children,
        showMenu = false,
        menuOptions = [],
        resizable,
        isResizingColumn,
        handleResize,
        css,
        tooltip,
        ...rest
      },
      ref,
    ) => {
      const headerCss = React.useMemo(() => {
        const restCss = css ?? {};
        return {
          '&:hover': {
            zIndex: resizable ? 1 : 'initial',
          },
          ...restCss,
        };
      }, [css, resizable]);

      const header = (
        <Th
          data-testid="column-header"
          ref={ref}
          scope="col"
          showMenu={showMenu}
          menuOptions={menuOptions}
          css={headerCss}
          style={{ width: size }}
          {...rest}
        >
          {children}
          {resizable && <ColumnResizer handleResize={handleResize} />}
        </Th>
      );
      return tooltip ? <Tooltip content={tooltip}>{header}</Tooltip> : header;
    },
  ),
  updateResizingColumnsOnly,
);

type RowCellProps = {
  columnId: string;
  size: number;
  children: React.ReactNode;
  isResizingColumn?: string | false;
  title: string;
  scope?: 'col' | 'row' | 'colgroup' | 'rowgroup' | undefined;
  css?: CSS;
  style?: React.CSSProperties;
} & (ThProps | TdProps);

export const RowCell = React.memo(
  React.forwardRef<HTMLTableCellElement, RowCellProps>(
    (
      { columnId, size, children, scope, isResizingColumn, css, ...rest },
      ref,
    ) => {
      const truncatedCss = React.useMemo(() => {
        const restCss = css ?? {};
        return {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          ...restCss,
        };
      }, [css]);

      return scope === 'row' ? (
        <Th ref={ref} scope="row" css={truncatedCss} {...rest}>
          {children}
        </Th>
      ) : (
        <Td ref={ref} css={truncatedCss} {...rest}>
          {children}
        </Td>
      );
    },
  ),
  updateResizingColumnsOnly,
);
