import { Flex } from '@kandji-inc/nectar-ui';
import { LibraryItemOSVersionRule } from 'features/rules-modal/components/LibraryItemOSVersionRule';
import { LI_DEVICE_SPECIFIC_OS_VERSION_FACETS } from 'features/rules/constants';
import React from 'react';

export const LibraryItemOSVersion = ({
  fieldPrefix,
  facetMap,
  installOnDeviceFamilies,
}) => (
  <Flex flow="column" gap="sm">
    {LI_DEVICE_SPECIFIC_OS_VERSION_FACETS.map(
      (childFacet) =>
        facetMap[childFacet] && (
          <LibraryItemOSVersionRule
            key={childFacet}
            operatorFieldName={`${fieldPrefix}.${childFacet}.operator`}
            valueFieldName={`${fieldPrefix}.${childFacet}`}
            facetData={facetMap[childFacet]}
            disabled={
              !installOnDeviceFamilies.includes(
                facetMap[childFacet].device_families[0],
              )
            }
          />
        ),
    )}
  </Flex>
);
