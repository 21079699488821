/* istanbul ignore file */
import { publicApi } from './api/base';
import { URL_AUTH0_CREDENTIALS } from './api/urls';

const TOKEN_NAME = 'kandji-token';
const INACTIVE_LOGOUT_NAME = 'inactive-logout';

export const getAuthToken = () => localStorage.getItem(TOKEN_NAME);
export const hasAuthToken = () => !!getAuthToken();
export const setAuthToken = (token) => localStorage.setItem(TOKEN_NAME, token);
export const clearAuthToken = () => localStorage.removeItem(TOKEN_NAME);

export const setInactiveLogout = () =>
  localStorage.setItem(INACTIVE_LOGOUT_NAME, 'true');

export const wasInactiveLogout = () =>
  localStorage.getItem(INACTIVE_LOGOUT_NAME) === 'true';

export const clearInactiveLogout = () =>
  localStorage.removeItem(INACTIVE_LOGOUT_NAME);

export const getTenantAuth0Credentials = (subdomain) =>
  publicApi(URL_AUTH0_CREDENTIALS)
    .get({ tenant: subdomain })
    .then(({ data }) => {
      if (data.err) {
        return Promise.reject({
          response: {
            data: data.err,
          },
        });
      }
      return data;
    })
    .catch((err) => Promise.reject(err.response.data));
