/* istanbul ignore file -- temp until API details determined */
import {
  getRunsOnFromModel,
  getSelectedDevicesFromRunsOn,
} from 'src/features/library-items/data-service/library-item/devices';
import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import configs from '../../../library/library-item-configurations/items/custom';
import type { BookmarkItem } from '../bookmarks.types';
import { bookmarksService } from './bookmarks-service';

type SendToApi = {
  name: string;
  active: boolean;
  blueprints: string[];
  is_all_blueprints: boolean;
  type?: string;
  identifier?: string;
  skip_bookmarks_conflict: boolean;
  data: {
    show_in_self_service: boolean;
    install_enforcement: string;
    self_service_data: {
      bookmarks: Array<
        Omit<BookmarkItem, 'iconDataUrl' | 'category'> & {
          category: string;
        }
      >;
    };
  };
  rules: object;
};

function transformSettings(settings) {
  return {
    manageBookmarks: {
      bookmarks: settings.self_service_data.bookmarks.map((apiBookmark) => ({
        category: apiBookmark.category,
        isRecommended: apiBookmark.is_recommended,
        title: apiBookmark.title,
        url: apiBookmark.url,
        id: apiBookmark.id,
        icon_s3_key: apiBookmark.icon_s3_key,
        icon_url: apiBookmark.icon_url,
      })),
      skip_bookmarks_conflict: false,
      bookmark_conflicts: [],
    },
  };
}

async function transformFromApi(apiData) {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const commonData = {
    id: apiData.id,
    name: apiData.name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
    rules: apiData.rules,
    devices: getSelectedDevicesFromRunsOn(apiData),
  };

  const result = {
    ...commonData,
    isActive: apiData.active,
    ...transformSettings(apiData.data),
  };

  return {
    ...apiData,
    data: result,
  };
}

async function transformToApi(model) {
  const bookmarks = [...model.manageBookmarks.bookmarks];

  await Promise.all(
    bookmarks.map((bookmark, idx) => {
      if (bookmark.iconDataUrl) {
        return bookmarksService.uploadBookmarksIcon(bookmark).then((res) => {
          bookmarks[idx].icon_s3_key = res.fields.key;
          return Promise.resolve();
        });
      }
      return Promise.resolve();
    }),
  );

  const toSend: SendToApi = {
    name: model.name,
    active: model.isActive,
    blueprints: model.selectedBlueprints.map((b) => b.value),
    is_all_blueprints: model.isAllBlueprints,
    skip_bookmarks_conflict: model.manageBookmarks.skip_bookmarks_conflict,
    data: {
      self_service_data: {
        bookmarks: model.manageBookmarks.bookmarks.map(
          (bookmark: BookmarkItem) => ({
            id: bookmark.id,
            url: bookmark.url,
            title: bookmark.title,
            category: bookmark.category || null,
            is_recommended: bookmark.isRecommended,
            icon_s3_key: bookmark.icon_s3_key,
          }),
        ),
      },
      // hard-coded for the BE:
      show_in_self_service: true,
      install_enforcement: 'no_enforcement',
    },
    rules: model.rules || null,
  };

  // Populate `runs_on_*` fields
  const runsOn = getRunsOnFromModel(model);
  Object.keys(runsOn).forEach((runsOnKey) => {
    toSend[runsOnKey] = runsOn[runsOnKey];
  });

  // Add necessary properties when creating new Profile:
  if (!model.id) {
    const itemConfig = configs.Bookmarks;

    toSend.type = itemConfig.type;
  }

  return toSend;
}

export { transformToApi, transformFromApi };
