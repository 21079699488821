/* istanbul ignore file */
import { Button } from '@kandji-inc/bumblebee';
import { colors } from 'app/common/constants';
import { i18n } from 'i18n';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { fetchAccount as callFetchAccount } from '../../../_actions/account';
import { setSnackbar as callSetSnackbar } from '../../../_actions/ui';
import { removeAppsAndBooks } from '../../integrations/Apple/api';
import IntegrationCheckbox from '../../integrations/elements/Fields/IntegrationCheckbox';
import { Text } from '../Typography';
import { ModalWrapper } from './ModalWrapper';
import { ButtonsWrapper, CancelButtonWrapper } from './elements';

const FormList = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const Line = styled.div`
  height: 1px;
  width: 100%;
  margin: 10px 0 -10px 0;
  border-bottom: 1px solid ${colors['grey-200']};
`;

export class DisconnectAppsAndBooks extends ModalWrapper {
  constructor(props) {
    super(props);
    this.modalName = 'DisconnectAppsAndBooks';
    this.state = {
      revokeLicenses: true,
      removeApps: true,
    };
    this.disconnectAppsAndBooks = this.disconnectAppsAndBooks.bind(this);
  }

  disconnectAppsAndBooks() {
    this.setState({ isLoading: true });
    const { revokeLicenses, removeApps } = this.state;
    const { setSnackbar, fetchAccount } = this.props;
    removeAppsAndBooks({
      revoke_licenses: revokeLicenses,
      remove_apps: removeApps,
    }).then(() => {
      this.setState({ isLoading: false });
      setSnackbar(i18n.t('Disconnect complete.'));
      fetchAccount();
      this.onHide();
    });
  }

  renderHeader = () => i18n.t('Disconnect Apps And Books');

  renderBody() {
    const { revokeLicenses, removeApps } = this.state;
    return (
      <>
        <Text>
          {i18n.t(
            `Kandji will no longer manage apps and books purchased through your
          Apple Business Manager account.`,
          )}
        </Text>
        <FormList>
          <Line />
          <IntegrationCheckbox
            value={revokeLicenses}
            label={i18n.t('Revoke all licenses')}
            description={i18n.t(
              `Kandji will revoke existing app licenses that are associated with your devices, freeing them
            up for future use.`,
            )}
            onChange={() =>
              this.setState({
                removeApps: !revokeLicenses && removeApps,
                revokeLicenses: !revokeLicenses,
              })
            }
          />
          <Line />
          <IntegrationCheckbox
            value={removeApps}
            label={i18n.t('Remove all apps')}
            description={i18n.t(
              'Kandji will attempt to uninstall all managed apps on iPhone and iPad devices.',
            )}
            onChange={() => this.setState({ removeApps: !removeApps })}
            disabled={!revokeLicenses}
          />
        </FormList>
      </>
    );
  }

  renderFooter() {
    const { isLoading } = this.state;
    return (
      <ButtonsWrapper>
        <CancelButtonWrapper>
          <Button kind="link" theme="dark" onClick={this.onHide}>
            {i18n.t('Cancel')}
          </Button>
        </CancelButtonWrapper>
        <Button
          theme="dark"
          onClick={this.disconnectAppsAndBooks}
          disabled={isLoading}
        >
          {isLoading ? i18n.t('Disconnecting...') : i18n.t('Disconnect')}
        </Button>
      </ButtonsWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSnackbar: callSetSnackbar,
      fetchAccount: callFetchAccount,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(DisconnectAppsAndBooks);
