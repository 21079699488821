import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import {
  YES_NO_CELL_FILTER_OPTIONS,
  extractFilterableColumns,
  getFilteredDisabled as filterDisabled,
  getEnumFilterOptions,
  getSpecificDeviceFamilyCell,
} from '../column-utils';
import { getGlobalColumns } from './globalColumns';

export const getAppsColumns = (schemas) => {
  const globalSchema = schemas.find((schema) => schema.uri === 'globals');
  const appsSchema = schemas.find((schema) => schema.uri === 'apps');

  if (!appsSchema || !globalSchema) {
    return { columnDefs: [], filterableColumns: [] };
  }

  const combinedProperties = {
    ...globalSchema.schema.properties,
    ...appsSchema.schema.properties,
  };

  const appsColumnHelper =
    createColumnHelper<z.infer<typeof combinedProperties>>();

  const globalColumns = getGlobalColumns(appsColumnHelper, combinedProperties);

  const columnDefs = [
    ...globalColumns.startColumnDefs,

    appsColumnHelper.accessor((row) => row.name, {
      id: 'name',
      meta: {
        displayName: combinedProperties.name.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.bundle_id, {
      id: 'bundle_id',
      meta: {
        displayName: combinedProperties.bundle_id.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.version, {
      id: 'version',
      meta: {
        displayName: combinedProperties.version.title,
        filterType: 'version',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.path, {
      id: 'path',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac'], 'code'),
      meta: {
        displayName: combinedProperties.path.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.obtained_from, {
      id: 'obtained_from',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: combinedProperties.obtained_from.title,
        defaultHidden: true,
        filterType: 'enum',
        filterOptions: getEnumFilterOptions(
          appsSchema.schema?.definitions.ObtainedFromType.enum || [],
        ),
        filterIcon: 'list-check',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.bundle_size, {
      id: 'bundle_size',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'bytes',
        ),
      meta: {
        displayName: combinedProperties.bundle_size.title,
        defaultHidden: true,
        filterType: 'number',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.short_version, {
      id: 'short_version',
      meta: {
        displayName: combinedProperties.short_version.title,
        defaultHidden: true,
        filterType: 'version',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.signature, {
      id: 'signature',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: combinedProperties.signature.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.ad_hoc_code_signed, {
      id: 'ad_hoc_code_signed',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: combinedProperties.ad_hoc_code_signed.title,
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.app_store_vendable, {
      id: 'app_store_vendable',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: combinedProperties.app_store_vendable.title,
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.beta_app, {
      id: 'beta_app',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: combinedProperties.beta_app.title,
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.device_based_vpp, {
      id: 'device_based_vpp',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: combinedProperties.device_based_vpp.title,
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.download_canceled, {
      id: 'download_canceled',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: combinedProperties.download_canceled.title,
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.download_failed, {
      id: 'download_failed',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: combinedProperties.download_failed.title,
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.download_paused, {
      id: 'download_paused',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: combinedProperties.download_paused.title,
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.download_waiting, {
      id: 'download_waiting',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: combinedProperties.download_waiting.title,
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.dynamic_size, {
      id: 'dynamic_size',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'bytes',
        ),
      meta: {
        displayName: combinedProperties.dynamic_size.title,
        defaultHidden: true,
        filterType: 'number',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.external_version_identifier, {
      id: 'external_version_identifier',
      cell: (info) =>
        getSpecificDeviceFamilyCell(info, ['iPad', 'AppleTV', 'iPhone']),
      meta: {
        displayName: combinedProperties.external_version_identifier.title,
        defaultHidden: true,
        filterType: 'version',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.has_update_available, {
      id: 'has_update_available',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: combinedProperties.has_update_available.title,
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.installing, {
      id: 'installing',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: combinedProperties.installing.title,
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.app_clip, {
      id: 'app_clip',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: combinedProperties.app_clip.title,
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.validated, {
      id: 'validated',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: combinedProperties.validated.title,
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.executable, {
      id: 'executable',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: combinedProperties.executable.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.bundle_name, {
      id: 'bundle_name',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: combinedProperties.bundle_name.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.bundle_type, {
      id: 'bundle_type',
      meta: {
        displayName: combinedProperties.bundle_type.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.environment_variables, {
      id: 'environment_variables',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: combinedProperties.environment_variables.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.compiler, {
      id: 'compiler',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: combinedProperties.compiler.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.bundle_region, {
      id: 'bundle_region',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: combinedProperties.bundle_region.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.bundle_display_name, {
      id: 'bundle_display_name',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: combinedProperties.bundle_display_name.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.info, {
      id: 'info',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: combinedProperties.info.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.minimum_system_version, {
      id: 'minimum_system_version',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: combinedProperties.minimum_system_version.title,
        defaultHidden: true,
        filterType: 'version',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.category, {
      id: 'category',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: combinedProperties.category.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.apple_scriptable, {
      id: 'apple_scriptable',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac'], 'boolean'),
      meta: {
        displayName: combinedProperties.apple_scriptable.title,
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.copyright, {
      id: 'copyright',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: combinedProperties.copyright.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.developer_name, {
      id: 'developer_name',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: combinedProperties.developer_name.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.team_id, {
      id: 'team_id',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: combinedProperties.team_id.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    ...globalColumns.endColumnDefs,
  ];

  const filterableColumns = extractFilterableColumns(columnDefs);

  return { columnDefs, filterableColumns };
};
