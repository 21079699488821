/* istanbul ignore file */
import { Body, Button, Dialog, Flex } from '@kandji-inc/nectar-ui';
import { useRulesContext } from 'features/rules-modal/RulesContext';
import React from 'react';

export const RulesDeleteModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}) => {
  const { deleteRules } = useRulesContext();
  return (
    <Dialog
      isOpen={isOpen}
      closeOnEscape
      closeOnOutsideClick
      onOpenChange={() => setIsOpen(false)}
      css={{
        width: '560px',
      }}
      title="Assignment Rules"
      content={
        <>
          <Body>
            By removing these Rules, this Library Item will be included on all
            devices associated with the Blueprints this Library Item is assigned
            to.
          </Body>
          <Body>
            Those changes will only go into effect once the Library Item itself
            is saved.
          </Body>
          <Body>Are you sure that you want to remove these Rules?</Body>
        </>
      }
      footer={
        <Flex justifyContent="end" gap="sm">
          <Button variant="subtle" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              deleteRules();
              setIsOpen(false);
            }}
          >
            Remove
          </Button>
        </Flex>
      }
    />
  );
};
