import { useState } from 'react';
import { oktaDeviceTrustService } from '../../../service/okta-device-trust-service';

type OktaDeviceTrustConfig = {
  domain: string;
  configured_platforms: string[];
  integration_id: string;
};

const useOktaDeviceTrustService = () => {
  const [data, setData] = useState<OktaDeviceTrustConfig>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const getConfig = async () => {
    setLoading(true);
    try {
      const configData = await oktaDeviceTrustService.getConfig();
      setData(configData);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    loading,
    error,
    getConfig,
  };
};

export default useOktaDeviceTrustService;
