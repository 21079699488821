import {
  Checkbox,
  Select,
  TextInput,
  setClass,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import React, { useEffect } from 'react';
import { i18n } from 'src/i18n';
import './shared-row.css';

import { Setting } from 'features/library-items/template';

import { requiredField, requiredFieldAndMinMax } from '../input-validations';

export const userValues = {
  USERS: 'maximum_resident_users',
  SIZE: 'user_quota_size',
  GUEST: 'only_allow_guest_user_session',
};

const SharedRow = ({
  ipad,
  update,
  isDisabled,
  isSaving,
  setModel,
  beforeEditModel,
  isSubmitted,
}) => {
  /* istanbul ignore next */
  const sharedIpadUserConfigurationOptions = [
    {
      value: userValues.USERS,
      label: i18n.t('Maximum resident users'),
    },
    {
      value: userValues.SIZE,
      label: i18n.t('User quota size'),
    },
    {
      value: userValues.GUEST,
      label: i18n.t('Only allow guest user session'),
    },
  ];

  /* istanbul ignore next */
  const sessionPeriodOptions = [
    {
      value: 0,
      label: i18n.t('Never'),
    },
    {
      value: 1,
      label: i18n.common.durationMinutes(1),
    },
    {
      value: 5,
      label: i18n.common.durationMinutes(5),
    },
    {
      value: 15,
      label: i18n.common.durationMinutes(15),
    },
    {
      value: 60,
      label: i18n.common.durationHours(1),
    },
    {
      value: 240,
      label: i18n.common.durationHours(4),
    },
    {
      value: 480,
      label: i18n.common.durationHours(8),
    },
  ];

  /* istanbul ignore next */
  const passcodePeriodOptions = [
    {
      value: 0,
      label: i18n.t('Immediately'),
    },
    {
      value: 1,
      label: i18n.common.durationMinutes(1),
    },
    {
      value: 5,
      label: i18n.common.durationMinutes(5),
    },
    {
      value: 15,
      label: i18n.common.durationMinutes(15),
    },
    {
      value: 60,
      label: i18n.common.durationHours(1),
    },
    {
      value: 240,
      label: i18n.common.durationHours(4),
    },
    {
      value: 480,
      label: i18n.common.durationHours(8),
    },
  ];

  const fieldsToValidate = [
    'maximumSharedResidents',
    'maximumSharedSize',
    'sharedUserConfiguration',
  ];

  const { refs, onInvalidate, invalidations } = useInputsValidators(
    fieldsToValidate,
    update,
  );
  useRemoveValidationOnUnmount(fieldsToValidate, update);
  useEffect(() => {
    if (ipad.sharedUserConfiguration !== userValues.USERS) {
      onInvalidate(0)(false);
    }
  }, [ipad.sharedUserConfiguration]);
  useEffect(() => {
    if (ipad.sharedUserConfiguration !== userValues.SIZE) {
      onInvalidate(1)(false);
    }
  }, [ipad.sharedUserConfiguration]);
  useEffect(() => {
    onInvalidate(2)(!ipad.sharedUserConfiguration);
  }, [ipad.sharedUserConfiguration, ipad.isShared]);
  useEffect(() => {
    if (!ipad.isShared) {
      onInvalidate(0)(false);
      onInvalidate(1)(false);
      onInvalidate(2)(false);
    }
  }, [ipad.isShared]);

  useEffect(() => {
    if (ipad.isShared) {
      setModel((p) => ({
        ...p,
        ipad: {
          ...p.ipad,
          sharedUserConfiguration: beforeEditModel.ipad.sharedUserConfiguration,
          maximumSharedResidents: beforeEditModel.ipad.maximumSharedResidents,
          maximumSharedSize: beforeEditModel.ipad.maximumSharedSize,
          isGuestSessionTimeout: beforeEditModel.ipad.isGuestSessionTimeout,
          guestSessionTimeout: beforeEditModel.ipad.guestSessionTimeout,
          isPasscodeLockPeriod: beforeEditModel.ipad.isPasscodeLockPeriod,
          passcodeLockPeriod: beforeEditModel.ipad.passcodeLockPeriod,
          isAutomaticUserLogout: beforeEditModel.ipad.isAutomaticUserLogout,
          automaticUserLogout: beforeEditModel.ipad.automaticUserLogout,
        },
      }));
    }
  }, [ipad.isShared]);

  return (
    <Setting.Row className="k-ade-shared-ipad-row">
      <Setting.Controls>
        <Checkbox
          defaultChecked
          checked={ipad.isShared}
          label={i18n.t('Shared iPad')}
          onChange={() => update('isShared', (p) => !p)}
          isDisabled={isDisabled}
        />
      </Setting.Controls>
      <Setting.Helpers>
        <p className="b-txt-light">
          {i18n.t(`Enable Shared iPad support for iPad devices. Note that this option
          will not enable shared iPad on iPad devices that are already set up.`)}{' '}
          <a
            href="https://support.kandji.io/support/solutions/articles/72000558686"
            rel="noopener noreferrer"
            target="_blank"
            className="b-alink"
          >
            {i18n.t('Learn More...')}
          </a>
        </p>
      </Setting.Helpers>
      {ipad.isShared && (
        <Setting.SecondaryControls className="k-ade-secondary-ctrl-grid-gap-align">
          <Setting.SecondaryControlsRow>
            <div>
              <p className="b-txt">{i18n.t('User configuration')}</p>
            </div>
            <div>
              <p className="b-txt-light">
                {i18n.t(
                  'Determine how the maximum user storage allocation should be defined, or only allow guest user sessions.',
                )}
              </p>
            </div>
            <div ref={refs[2]}>
              <Select
                isDisabled={isDisabled}
                options={sharedIpadUserConfigurationOptions}
                placeholder={i18n.t('Select user configuration')}
                compact
                onChange={(v) => update('sharedUserConfiguration', v.value)}
                value={sharedIpadUserConfigurationOptions.find(
                  (option) => option.value === ipad.sharedUserConfiguration,
                )}
                errorText={
                  /* istanbul ignore next */ isSubmitted && invalidations[2]
                    ? i18n.t('Required')
                    : ''
                }
                name="sharedUserConfiguration"
                id="sharedUserConfiguration"
              />
            </div>
            {ipad.sharedUserConfiguration === userValues.USERS && (
              <Setting.SecondaryControls
                className={setClass(
                  invalidations[0] &&
                    'k-ade-shared-ipad-row__secondary-controls-row--with-validation',
                )}
              >
                <div ref={refs[0]} />
                <TextInput
                  value={ipad.maximumSharedResidents}
                  onChange={(e) =>
                    update('maximumSharedResidents', e.target.value)
                  }
                  type="number"
                  subLabel={i18n.t('maximum resident users')}
                  // superCompact // unexpected behavior
                  className="k-ade-shared-input"
                  disabled={isDisabled}
                  validator={requiredFieldAndMinMax(1, 99)}
                  onInvalidate={onInvalidate(0)}
                  runValidationOn={[isSaving]}
                  name="maximumSharedResidents"
                />
              </Setting.SecondaryControls>
            )}
            {ipad.sharedUserConfiguration === userValues.SIZE && (
              <Setting.SecondaryControls
                className={setClass(
                  invalidations[1] &&
                    'k-ade-shared-ipad-row__secondary-controls-row--with-validation',
                )}
              >
                <div ref={refs[1]} />
                <TextInput
                  value={ipad.maximumSharedSize}
                  onChange={(e) => update('maximumSharedSize', e.target.value)}
                  type="number"
                  subLabel={i18n.t('maximum user size (MB)')}
                  // superCompact // unexpected behavior
                  className="k-ade-shared-input"
                  disabled={isDisabled}
                  validator={requiredField}
                  onInvalidate={onInvalidate(1)}
                  runValidationOn={[isSaving]}
                  name="maximumSharedSize"
                />
              </Setting.SecondaryControls>
            )}
          </Setting.SecondaryControlsRow>

          <Setting.SecondaryControlsRow>
            <div className="--no-bottom-gap">
              <Checkbox
                defaultChecked
                checked={ipad.isGuestSessionTimeout}
                label={i18n.t('Guest session timeout')}
                onChange={() => update('isGuestSessionTimeout', (p) => !p)}
                isDisabled={isDisabled}
              />
            </div>
            <Setting.Helpers>
              <p className="b-txt-light">
                {i18n.t(`The period of inactivity after which the guest user session will
                be automatically logged out. Setting this value to Never will
                disable automatic logout.`)}
              </p>
            </Setting.Helpers>
            {ipad.isGuestSessionTimeout && (
              <div>
                <Setting.SecondaryControls>
                  <Select
                    compact
                    isDisabled={isDisabled}
                    options={sessionPeriodOptions}
                    onChange={(v) => update('guestSessionTimeout', v.value)}
                    value={sessionPeriodOptions.find(
                      (option) => option.value === ipad.guestSessionTimeout,
                    )}
                  />
                </Setting.SecondaryControls>
              </div>
            )}
          </Setting.SecondaryControlsRow>

          {ipad.sharedUserConfiguration !== userValues.GUEST && (
            <Setting.SecondaryControlsRow>
              <div className="--no-bottom-gap">
                <Checkbox
                  defaultChecked
                  checked={ipad.isPasscodeLockPeriod}
                  label={i18n.t('Passcode lock grace period')}
                  onChange={() => update('isPasscodeLockPeriod', (p) => !p)}
                  isDisabled={isDisabled}
                />
              </div>

              <Setting.Helpers>
                <p className="b-txt-light">
                  {i18n.t(`The length of time after locking the screen until a user is
                  required to re-enter their Shared iPad passcode. Setting this
                  value to Immediately is the default Shared iPad behavior.`)}
                </p>
              </Setting.Helpers>

              {ipad.isPasscodeLockPeriod && (
                <div>
                  <Setting.SecondaryControls>
                    <Select
                      compact
                      isDisabled={isDisabled}
                      options={passcodePeriodOptions}
                      onChange={(v) => update('passcodeLockPeriod', v.value)}
                      value={passcodePeriodOptions.find(
                        (option) => option.value === ipad.passcodeLockPeriod,
                      )}
                    />
                  </Setting.SecondaryControls>
                </div>
              )}
            </Setting.SecondaryControlsRow>
          )}

          {ipad.sharedUserConfiguration !== userValues.GUEST && (
            <Setting.SecondaryControlsRow>
              <div className="--no-bottom-gap">
                <Checkbox
                  defaultChecked
                  checked={ipad.isAutomaticUserLogout}
                  label={i18n.t('Automatic user logout')}
                  onChange={() => update('isAutomaticUserLogout', (p) => !p)}
                  isDisabled={isDisabled}
                />
              </div>
              <Setting.Helpers>
                <p className="b-txt-light">
                  {i18n.t(`The period of inactivity after which the Shared iPad user will
                  be automatically logged out. Setting this value to Never will
                  disable automatic logout.`)}
                </p>
              </Setting.Helpers>

              {ipad.isAutomaticUserLogout && (
                <div>
                  <Setting.SecondaryControls>
                    <Select
                      compact
                      isDisabled={isDisabled}
                      options={sessionPeriodOptions}
                      onChange={(v) => update('automaticUserLogout', v.value)}
                      value={sessionPeriodOptions.find(
                        (option) => option.value === ipad.automaticUserLogout,
                      )}
                    />
                  </Setting.SecondaryControls>
                </div>
              )}
            </Setting.SecondaryControlsRow>
          )}
        </Setting.SecondaryControls>
      )}
    </Setting.Row>
  );
};

export default SharedRow;
