import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

function generateKeys(obj, prefix = '') {
  const newObj = { ...obj };
  for (const key of Object.keys(obj)) {
    if (typeof obj[key] === 'string') {
      newObj[key] = prefix + key;
    } else {
      newObj[key] = generateKeys(newObj[key], `${prefix}${key}.`);
    }
  }

  return newObj;
}

class EthernetService extends NewLibraryItemService {
  static keys = {
    installation: {
      type: 'type',
    },
    authentication: generateKeys({
      interface: '',
      useLoginWindow: '',
      identityCertificate: '',
      acceptedEapTypes: {
        acceptedEapTypes: '',
        username: '',
        promptForPassword: '',
        password: '',
        authentication: '',
        innerAuthentication: '',
        outerIdentity: '',
        tlsMinimumVersion: '',
        tlsMaximumVersion: '',
        usePac: '',
        provisionPac: '',
        provisionPacAnonymously: '',
        randValue: '',
        twoFactorAuth: '',
      },
      scep: {
        url: '',
        name: '',
        challenge: '',
        fingerprint: '',
        subject: '',
        subjectAlternativeNameType: '',
        withSan: '',
        san: '',
        sanUpn: '',
        keySize: '',
        keyUsage: '',
        withRetries: '',
        retries: '',
        withRetryDelay: '',
        retryDelay: '',
        disallowKeyExtraction: '',
        allowAccessToApps: '',
        withCertificateExpirationNotification: '',
        certificateExpirationNotification: '',
        withAutoProfileRedistribution: '',
        autoProfileRedistribution: '',
        drawer: '',
        updatedAt: '',
      },
      pkcs: {
        file: '',
        password: '',
        allowAccessToThePrivateKey: '',
        preventExtraction: '',
        drawer: '',
      },
      adcs: {
        allowAccessToApps: '',
        preventExtraction: '',
        subject: '',
        template: '',
        server: '',
        keySize: '',
        withSan: '',
        san: '',
        name: '',
        drawer: '',
        updatedAt: '',
      },
    }),
    trust: generateKeys({
      specifyCertificates: '',
      certificates: '',
      specifyServerCertificateNames: '',
      serverCertificateNames: '',
    }),
    proxy: generateKeys({
      isEnabled: '',
      proxyType: '',
      manual: {
        proxyServer: '',
        port: '',
        proxyUsername: '',
        proxyPassword: '',
      },
      automatic: {
        proxyPacUrl: '',
        allowProxyPacFallback: '',
      },
    }),
  };

  static interfaces = {
    GLOBAL: 'GlobalEthernet',
  };

  static protocols = {
    TLS: 13,
    TTLS: 21,
    LEAP: 17,
    PEAP: 25,
    EAP_FAST: 43,
  };

  static authentications = {
    USERNAME_AND_PASSWORD: 'username_and_password',
    COMPUTER_AD: 'computer_ad_system_auth',
    COMPUTER_OD: 'computer_od_system_auth',
  };

  static promptPasswordValues = {
    ONCE: 'once',
    EVERY_TIME: 'every-time',
  };

  static innerAuthentications = {
    CHAP: 'CHAP',
    EAP: 'EAP',
    MSCHAP: 'MSCHAP',
    MSCHAPv2: 'MSCHAPv2',
    PAP: 'PAP',
    DONT_SPECIFY: null,
  };

  static tlsVersions = {
    v1_0: '1.0',
    v1_1: '1.1',
    v1_2: '1.2',
    DONT_SPECIFY: null,
  };

  static randValues = {
    2: 2,
    3: 3,
    DONT_SPECIFY: null,
  };

  static identityCertificates = {
    NONE: null,
    ADCS: 'adcs',
    SCEP: 'scep',
    PKCS12: 'pkcs12',
  };

  static subjectAlternativeNameTypes = {
    NONE: null,
    RFC: 'rfc822Name',
    DNS: 'dNSName',
    URI: 'uniformResourceIdentifier',
    UPN: 'ntPrincipalName',
  };

  static subjectAlternativeNameTypesWindows = {
    NONE: null,
    [this.subjectAlternativeNameTypes.RFC]: 'email',
    [this.subjectAlternativeNameTypes.DNS]: 'dns',
    [this.subjectAlternativeNameTypes.URI]: 'uri',
    [this.subjectAlternativeNameTypes.UPN]: 'upn',
  };

  static keySizes = {
    s1024: 1024,
    s2048: 2048,
    s4096: 4096,
  };

  static keyUsages = {
    NONE: null,
    SIGNING: 1,
    ENCRYPTION: 2,
    BOTH: 5,
  };

  // proxy

  static proxyTypes = {
    NONE: 'None',
    MANUAL: 'Manual',
    AUTOMATIC: 'Auto',
  };

  // fast lane marking

  static qosValues = {
    ALLOW: 'allow-specific-apps',
    DISABLE: 'disable-fast-lane-for-all-apps',
  };
}

export const ethernetService = new EthernetService();
export default EthernetService;
