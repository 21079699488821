import { DateRangeSelect } from '@kandji-inc/bumblebee';

const severitySelectOptions = [
  {
    label: 'Critical',
    value: 'critical',
  },
  {
    label: 'High',
    value: 'high',
  },
  {
    label: 'Medium',
    value: 'medium',
  },
  {
    label: 'Low',
    value: 'low',
  },
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'Unassigned',
    value: 'unassigned',
  },
] as const;

const statusChipSelectOptions = [
  {
    value: '',
    label: 'All',
  },
  {
    value: 'open',
    label: 'Open',
  },
  {
    value: 'closed',
    label: 'Closed',
  },
  {
    value: 'ignored',
    label: 'Ignored',
  },
] as const;

const datesPreset = DateRangeSelect.getDefaultDatesPreset();

const selectOptions = {
  severity: severitySelectOptions,
  status: statusChipSelectOptions,
};

export default {
  selectOptions,
  datesPreset,
};
