/* istanbul ignore file */
import { Checkbox, LazyScriptEditor } from '@kandji-inc/bumblebee';
import React, { memo } from 'react';
import { i18n } from 'src/i18n';

import { Box, Flex, Radio, RadioGroup, Text } from '@kandji-inc/nectar-ui';
import { Setting } from 'features/library-items/template';
import { useFlags } from 'src/config/feature-flags';
import AutomaticAppService from '../service/automatic-app-service';

const OptionsCard = ({ setting, update, isDisabled }) => {
  const { 'dc-730-auto-app-install-scripts': LDFF_autoAppInstallScripts } =
    useFlags(['dc-730-auto-app-install-scripts']);

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t(`Options`)}</h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          {' '}
          <Setting.Controls>
            <Checkbox
              checked={setting.addToDock}
              label={i18n.t('Add to Dock during install')}
              onChange={() => update('addToDock', (p) => !p)}
              isDisabled={isDisabled}
            />
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t(
                'Kandji will add the app icon to the dock during initial app install, or during re-install if the app is found to be missing. Kandji will not automatically re-add the icon to the dock if the app is updated.',
              )}
            </p>
          </Setting.Helpers>
        </Setting.Row>
        {LDFF_autoAppInstallScripts && (
          <>
            <Setting.Row>
              <Setting.Controls>
                <Checkbox
                  checked={setting.isPreInstallScript}
                  label={i18n.t('Run preinstall script')}
                  onChange={() => update('isPreInstallScript', (p) => !p)}
                  isDisabled={isDisabled}
                />
              </Setting.Controls>
              <Setting.Helpers>
                <p className="b-txt-light">
                  {i18n.t(
                    'This script will run before the app is installed. It must have an exit code of 0 to be considered successful.',
                  )}
                </p>
              </Setting.Helpers>
              {setting.isPreInstallScript && (
                <Setting.SecondaryControls>
                  <Setting.SecondaryControlsRow>
                    <Flex flow="column" gap="lg" css={{ gridColumn: '1 / -1' }}>
                      <Text>{i18n.t('Preinstall script')}</Text>
                      <Box wFull>
                        <LazyScriptEditor
                          placeholder={i18n.t('Enter your script here')}
                          value={setting.preInstallScript}
                          onChange={(value) =>
                            update('preInstallScript', value)
                          }
                          language="shell"
                          options={{ readOnly: isDisabled }}
                          className={
                            isDisabled && 'k-mobile-config-info__preview'
                          }
                        />
                      </Box>
                      <Text>{i18n.t('Execution Frequency')}</Text>
                      <RadioGroup
                        value={setting.preInstallScriptRunOption}
                        orientation="vertical"
                      >
                        {Object.values(
                          AutomaticAppService.getScriptInstallOptions(),
                        ).map((option) => (
                          <Radio
                            key={option.value}
                            disabled={isDisabled}
                            checked={
                              setting.preInstallScriptRunOption === option.value
                            }
                            onClick={() =>
                              update('preInstallScriptRunOption', option.value)
                            }
                            value={option.value}
                            label={option.label}
                          />
                        ))}
                      </RadioGroup>
                    </Flex>
                  </Setting.SecondaryControlsRow>
                </Setting.SecondaryControls>
              )}
            </Setting.Row>
            <Setting.Row>
              <Setting.Controls>
                <Checkbox
                  checked={setting.isPostInstallScript}
                  label={i18n.t('Run postinstall script')}
                  onChange={() => update('isPostInstallScript', (p) => !p)}
                  isDisabled={isDisabled}
                />
              </Setting.Controls>
              <Setting.Helpers>
                <p className="b-txt-light">
                  {i18n.t(
                    'This script will run after the app is installed. It must have an exit code of 0 to be considered successful.',
                  )}
                </p>
              </Setting.Helpers>
              {setting.isPostInstallScript && (
                <Setting.SecondaryControls>
                  <Setting.SecondaryControlsRow>
                    <Flex flow="column" gap="lg" css={{ gridColumn: '1 / -1' }}>
                      <Text>{i18n.t('Postinstall script')}</Text>
                      <Box wFull>
                        <LazyScriptEditor
                          placeholder={i18n.t('Enter your script here')}
                          value={setting.postInstallScript}
                          onChange={(value) =>
                            update('postInstallScript', value)
                          }
                          language="shell"
                          options={{ readOnly: isDisabled }}
                          className={
                            isDisabled && 'k-mobile-config-info__preview'
                          }
                        />
                      </Box>
                      <Text>{i18n.t('Execution Frequency')}</Text>
                      <RadioGroup
                        value={setting.postInstallScriptRunOption}
                        orientation="vertical"
                      >
                        {Object.values(
                          AutomaticAppService.getScriptInstallOptions(),
                        ).map((option) => (
                          <Radio
                            key={option.value}
                            disabled={isDisabled}
                            checked={
                              setting.postInstallScriptRunOption ===
                              option.value
                            }
                            onClick={() =>
                              update('postInstallScriptRunOption', option.value)
                            }
                            value={option.value}
                            label={option.label}
                          />
                        ))}
                      </RadioGroup>
                    </Flex>
                  </Setting.SecondaryControlsRow>
                </Setting.SecondaryControls>
              )}
            </Setting.Row>
          </>
        )}
      </Setting.Rows>
    </Setting.Card>
  );
};

export default memo(OptionsCard);
