import { timezones } from 'app/common/constants';
import { i18n } from 'src/i18n';
import {
  DEFAULT_DELAY_OPTION,
  DEFAULT_TIME_IDX,
  TIME_OPTIONS,
  VERSION_ENFORCEMENT_OPTIONS,
} from '../../common/updates-card/updates-card-constants';

const INITIAL_MODEL = /* istanbul ignore next */ () => ({
  id: '',
  name: i18n.t('iOS Release'),
  instanceName: '',
  icon: '',
  isActive: true,
  osRequirements: '',
  blueprints: [],
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  publisher: i18n.t('Apple, Inc.'),
  updates: {
    versionEnforcement: VERSION_ENFORCEMENT_OPTIONS[0],
    minimumVersion: null,
    enforcementDelay: DEFAULT_DELAY_OPTION,
    enforcementDeadline: null,
    enforcementTime: TIME_OPTIONS[DEFAULT_TIME_IDX],
    enforcementTimezone: timezones[0],
  },
  supportsRules: true,
});

export { INITIAL_MODEL };
