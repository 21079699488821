/* istanbul ignore file */
import {
  Button,
  Callout,
  Checkbox,
  DialogPrimitives as Dialog,
  type FIXME_Any,
  Flex,
  MultiSelect,
  Select,
  Text,
  TextField,
} from '@kandji-inc/nectar-ui';
import useWhiteBg from 'features/util/hooks/use-white-bg';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { links } from 'src/app/common/constants';
import { CreateButton } from './components/CreateButton';
import { DeviceTargets } from './components/DeviceTargets';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { LabelText } from './components/LabelText';
import { TargetsPanel } from './components/TargetsPanel';

export const CreatePreferencesCheck = ({
  onCreate,
  isCreating,
  createJobError,
  reportName,
  setReportName,
  reportNameError,
  setReportNameError,
  reportDescription,
  setReportDescription,
  selectedDevices,
  setSelectedDevices,
  selectedBlueprints,
  setSelectedBlueprints,
}) => {
  useWhiteBg();
  const history = useHistory();
  const [preferenceType, setPreferenceType] = useState('domain');
  const [preferenceDomain, setPreferenceDomain] = useState('');
  const [preferenceDomainError, setPreferenceDomainError] = useState('');
  const [plistPath, setPlistPath] = useState('');
  const [plistPathError, setPlistPathError] = useState('');
  const [includeHost, setIncludeHost] = useState(false);
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState(false);
  const [keysSpecifier, setKeysSpecifier] = useState('all');
  const [keyPair, setKeyPair] = useState('');
  const [keyPairs, setKeyPairs] = useState([]);

  return (
    <Flex flow="column" pt4>
      <Header
        reportType="preferences"
        reportName={reportName}
        setReportName={setReportName}
        reportNameError={reportNameError}
        setReportNameError={setReportNameError}
        reportDescription={reportDescription}
        setReportDescription={setReportDescription}
      />
      <TargetsPanel>
        <DeviceTargets
          selectedDevices={selectedDevices}
          setSelectedDevices={setSelectedDevices}
          selectedBlueprints={selectedBlueprints}
          setSelectedBlueprints={setSelectedBlueprints}
        />
        <Flex flow="column" p4 gap="md">
          <LabelText>I want to search and return results for</LabelText>
          <Flex gap="md">
            <Text css={{ paddingTop: 10, flexShrink: 0, width: 85 }}>
              the following
            </Text>
            <Select
              onChange={(value) => {
                setPreferenceType(value);
                if (value === 'domain') {
                  setPreferenceDomainError('');
                } else {
                  setPlistPathError('');
                }
              }}
              options={[
                { value: 'domain', label: 'Preference domain' },
                { value: 'plist', label: 'Plist' },
              ]}
              size="xs"
              value={preferenceType}
              componentCss={{
                searchInput: { textWrap: 'nowrap' },
              }}
            />
            <Select
              options={[{ value: 'equals', label: 'equals' }]}
              onChange={() => {}}
              value="equals"
              componentCss={{
                trigger: { width: '85px' },
                menu: { width: '85px' },
              }}
            />
            {preferenceType === 'domain' && (
              <>
                <TextField
                  css={{ flexBasis: '100%', height: '36px' }}
                  placeholder="io.kandji.Kandji"
                  state={preferenceDomainError ? 'error' : 'default'}
                  hint={{
                    label:
                      preferenceDomainError ||
                      'Enter the reverse-DNS style domain',
                    variant: preferenceDomainError ? 'error' : 'default',
                  }}
                  value={preferenceDomain}
                  onChange={(e) => {
                    setPreferenceDomain(e.target.value);
                    setPreferenceDomainError('');
                  }}
                />
                <Flex css={{ minWidth: 140 }}>
                  <Checkbox
                    checked={includeHost}
                    onCheckedChange={() => {
                      if (includeHost) {
                        setUsernameError(false);
                      }
                      setIncludeHost(!includeHost);
                    }}
                    label="Include ByHost"
                  />
                </Flex>
              </>
            )}
            {preferenceType === 'plist' && (
              <TextField
                css={{ flexBasis: '100%', height: '36px' }}
                placeholder="Enter the full path of the preference file"
                state={plistPathError ? 'error' : 'default'}
                hint={{
                  label: plistPathError || 'Ends in .plist',
                  variant: plistPathError ? 'error' : 'default',
                }}
                value={plistPath}
                onChange={(e) => {
                  setPlistPath(e.target.value);
                  setPlistPathError('');
                }}
              />
            )}
          </Flex>
          {preferenceType === 'domain' && (
            <Flex gap="md" css={{ paddingTop: '$4' }}>
              <Text css={{ paddingTop: 10, flexShrink: 0, width: 85 }}>
                and username
              </Text>
              <Select
                options={[{ value: 'equals', label: 'equals' }]}
                onChange={() => {}}
                size="xs"
                value="equals"
              />
              <TextField
                css={{ flexBasis: '100%', height: '36px' }}
                placeholder="Enter the local username"
                hint={{
                  label: 'Required if Include ByHost is selected',
                  variant: usernameError ? 'error' : 'default',
                }}
                state={usernameError ? 'error' : 'default'}
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                  setUsernameError(false);
                }}
              />
            </Flex>
          )}
          <Flex gap="md" css={{ paddingBottom: '$4', paddingTop: '$4' }}>
            <Text css={{ paddingTop: 10, flexShrink: 0, width: 85 }}>
              And collect
            </Text>
            <Select
              onChange={(value) => {
                setKeysSpecifier(value);
              }}
              options={[
                { value: 'all', label: 'All keys' },
                { value: 'specific', label: 'Specific keys' },
              ]}
              size="xs"
              value={keysSpecifier}
            />
            {keysSpecifier === 'specific' && (
              <MultiSelect
                searchable
                showHiddenCountValue={false}
                size="full"
                value={keyPairs}
                valueText={keyPair as FIXME_Any}
                onChange={(val) => {
                  setKeyPairs(val);
                }}
                onEventAction={(eventMeta, { internalOnEventAction }) => {
                  internalOnEventAction(eventMeta);
                  if (eventMeta.action === 'search-input-change') {
                    setKeyPair(eventMeta.data);
                  }
                }}
                creatable={{
                  active: true,
                  onCreate: () => {
                    if (keyPairs.includes(keyPair)) {
                      return false;
                    }
                    setKeyPair('');
                    return true;
                  },
                }}
                hint={
                  keyPair ? { label: 'Hit enter to add more keys' } : undefined
                }
                placeholder="Enter key and hit enter to add more"
                components={{
                  OpenIcon: null,
                }}
                componentCss={{
                  searchInput: {
                    '&[data-search-value=""]': {
                      width: '100%',
                    },
                  },
                }}
                open={false}
              />
            )}
          </Flex>
          {selectedDevices.length === 0 && selectedBlueprints.length === 0 && (
            <Callout
              showCloseButton={false}
              text="You do not have any devices targeted. For faster results, target by device, blueprint or device family."
              theme="warning"
            />
          )}
          {createJobError && (
            <Callout
              showCloseButton={false}
              text={createJobError}
              theme="warning"
            />
          )}
        </Flex>
        <Dialog.Footer>
          <Button
            variant="subtle"
            onClick={() => {
              history.push(links.pulse);
            }}
          >
            Cancel
          </Button>
          <CreateButton
            isCreating={isCreating}
            onClick={() => {
              let error = false;
              if (reportName === '') {
                setReportNameError('Required');
                error = true;
              }
              if (preferenceType === 'domain' && preferenceDomain === '') {
                setPreferenceDomainError('Required');
                error = true;
              } else if (preferenceType === 'plist' && plistPath === '') {
                setPlistPathError('Required');
                error = true;
              }
              if (includeHost && username === '') {
                setUsernameError(true);
                error = true;
              }
              if (error) {
                return;
              }
              onCreate({
                checkType: 'preferences',
                agentFilters: {
                  domain:
                    preferenceType === 'domain' ? preferenceDomain : undefined,
                  path: preferenceType === 'plist' ? plistPath : undefined,
                  keys:
                    keysSpecifier === 'specific'
                      ? [keyPair, ...keyPairs]
                          .filter((keyName) => keyName !== '')
                          .map((keyName) => ({ key: keyName }))
                      : undefined,
                  username: username ? username : undefined,
                  current_host: includeHost,
                },
              });
            }}
          />
        </Dialog.Footer>
      </TargetsPanel>
      <Footer />
    </Flex>
  );
};
