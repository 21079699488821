import { api } from 'app/api/base';

class BlueprintService {
  constructor() {
    this.resource = 'blueprints/';
    this.templatesResource = 'blueprint-template-categories/';
    this.libraryItemsResource = (id) =>
      `${this.resource}${id}/set-library-items`;
    this.parametersResource = 'public/meta/legacy';
    this.tagsResource = 'public/parameters-tags';
  }

  async get(id, params) {
    const client = api(`${this.resource}${id}`);
    return client.get(params).then((r) => r.data);
  }

  async search(data, params) {
    const client = api(`${this.resource}search`);
    return client.post(data, params).then((r) => r.data);
  }

  async find(params) {
    const client = api(this.resource);
    return client.get(params).then((r) => r.data);
  }

  async delete(id, params) {
    const client = api(`${this.resource}${id}`);
    return client.delete(params);
  }

  async create(data, params) {
    const client = api(this.resource);
    return client.post(data, params);
  }

  async patch(id, data, params) {
    const client = api(`${this.resource}${id}`);
    return client.patch(data, params);
  }

  async getTemplates(params) {
    const client = api(this.templatesResource);
    return client.get(params);
  }

  async setLibraryItems(id, data, params) {
    const client = api(this.libraryItemsResource(id));
    return client.post(data, params);
  }

  async getParameters(params) {
    const client = api(this.parametersResource);
    return client.get(params);
  }

  async getTags(params) {
    const client = api(this.tagsResource);
    return client.get(params);
  }
}

const blueprintService = new BlueprintService();

export default BlueprintService;
export { blueprintService };
