import { Toaster as toaster } from '@kandji-inc/bumblebee';
import { USER_INT_TYPES } from 'features/integrations/constants';
import { newIntegrationsService } from 'features/integrations/data-service/new-integrations-service';
import { useEffect, useState } from 'react';

interface IntegrationCounts {
  [key: string]: number;
}

interface IntegrationHookResult {
  getIntegrationCounts: () => Promise<void>;
  countOfUserDirectoryIntegrations: number;
  hasUserDirectoryIntegration: boolean;
}

const useIntegrations = (): IntegrationHookResult => {
  const [integrationCounts, setIntegrationCounts] = useState<IntegrationCounts>(
    {},
  );

  // ensure initial values are populated
  useEffect(() => {
    getIntegrationCounts();
  }, []);

  const getIntegrationCounts = async (): Promise<void> => {
    try {
      const { data } = await newIntegrationsService.getIntegrationCounts();

      setIntegrationCounts(data);
    } catch (error) {
      console.error(error);

      toaster('Unable to retrieve user integration counts.');
    }
  };

  // get count of user directory integrations
  const countOfUserDirectoryIntegrations: number = USER_INT_TYPES.reduce(
    (count: number, type: string) => {
      const countForType: number | undefined = integrationCounts[type];
      if (countForType !== undefined) {
        return count + countForType;
      }
      return count;
    },
    0,
  );

  // boolean for ease of use
  const hasUserDirectoryIntegration: boolean =
    countOfUserDirectoryIntegrations > 0;

  return {
    getIntegrationCounts,
    countOfUserDirectoryIntegrations,
    hasUserDirectoryIntegration,
  };
};

export default useIntegrations;
