import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import configs from '../../../library/library-item-configurations/items/profile';
import initialState from '../initial-state';

function transformSettings(settings) {
  let transformedManageMediaAccessValues = {};
  if (settings.ManageMediaAccess) {
    // transform Array to comma separated string - ['one', 'two'] => 'one,two'
    transformedManageMediaAccessValues = Object.fromEntries(
      Object.entries(settings.ManageMediaAccess).map(([key, value]) => [
        key,
        value.join(','),
      ]),
    );
  }

  return Object.freeze({
    general: {
      DisconnectAllMediaAtLogout: settings.DisconnectAllMediaAtLogout,
      DisableMediaAutoActions: settings.DisableMediaAutoActions,
      isManagingDiscBurning: Boolean(settings?.ManageDiscBurning), // ui-only; not sent to API
      ...(settings?.ManageDiscBurning
        ? {
            ManageDiscBurning: settings?.ManageDiscBurning,
          }
        : {
            ManageDiscBurning: initialState.general.ManageDiscBurning,
          }),
    },
    manageMediaAccess: settings?.ManageMediaAccess
      ? {
          ...initialState.manageMediaAccess,
          ...transformedManageMediaAccessValues,
        }
      : initialState.manageMediaAccess,
  });
}

async function transformFromNewApi(apiData, preset) {
  const result = {
    ...preset,
    isActive: apiData.active,
    ...transformSettings(apiData.data),
  };

  return result;
}

async function transformFromApi(apiData) {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const commonData = {
    id: apiData.id,
    name: apiData.name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
    rules: apiData.rules,
  };

  const transformer = transformFromNewApi;

  const result = await transformer(apiData, commonData);

  return {
    ...apiData,
    data: result,
  };
}

function transformToApi(model) {
  const toSend = {
    name: model.name,
    active: model.isActive,
    blueprints: model.selectedBlueprints.map((b) => b.value),
    is_all_blueprints: model.isAllBlueprints,
    rules: model.rules || null,
    reassign_lib_item_to_bp: true,
    data: {
      DisconnectAllMediaAtLogout: model.general.DisconnectAllMediaAtLogout,
      DisableMediaAutoActions: model.general.DisableMediaAutoActions,
      ...(model.general.isManagingDiscBurning
        ? {
            ManageDiscBurning: model.general.ManageDiscBurning,
          }
        : {}),
    },
  };

  // Check if any Manage Media Access fields need to be added:
  const manageMediaAccessFields = Object.entries(model.manageMediaAccess).map(
    ([key, value]) => [key, value.split(',')],
  );

  if (manageMediaAccessFields.length > 0) {
    toSend.data.ManageMediaAccess = Object.fromEntries(manageMediaAccessFields);
  }

  if (model.skip_blueprint_conflict) {
    toSend.skip_blueprint_conflict = model.skip_blueprint_conflict;
  }

  // Add necessary properties when creating new Profile:
  if (!model.id) {
    const itemConfig = configs['Media Access'];

    toSend.type = itemConfig.type;
    toSend.identifier = itemConfig.identifier;
  }

  return toSend;
}

export { transformToApi, transformFromApi };
