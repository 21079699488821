// istanbul ignore file
import { Flex, ScrollArea, SortableList, styled } from '@kandji-inc/nectar-ui';
import type { Column } from '@tanstack/react-table';
import { ColumnItem } from './ColumnItem';

export const ColumnList = ({
  columns,
  columnOrder,
  columnVisibility,
  onColumnVisibilityChange,
  onColumnOrderChange,
  enableReorder = true,
}: {
  columns: {
    pinnedColumns?: string[];
    columnDefs?: Column<{}, unknown>[];
    alwaysHiddenColumns?: string[];
  };
  columnOrder: string[];
  columnVisibility: Record<string, boolean>;
  onColumnVisibilityChange: (visibility: Record<string, boolean>) => void;
  onColumnOrderChange: (newOrder: string[]) => void;
  enableReorder?: boolean;
}) => {
  const sortableItems = columnOrder.reduce((items, column) => {
    const columnDef = columns.columnDefs?.find((col) => col.id === column);
    if (!columnDef) {
      return items;
    }
    items.push({
      id: column,
      content: columnDef,
      renderItem: ({ content: columnDef, ...dndProps }) => (
        <ColumnItem
          enableReorder={enableReorder}
          column={columnDef}
          isPinned={columns.pinnedColumns?.includes(column) ?? false}
          dndProps={dndProps}
          columnVisibility={columnVisibility}
          onColumnVisibilityChange={onColumnVisibilityChange}
        />
      ),
      disabled: columns.pinnedColumns?.includes(column),
      gripPosition: 'right',
    });
    return items;
  }, [] as any[]);

  return (
    <Container>
      <ScrollArea
        css={{ height: '100%', width: '100%' }}
        scrollbarPosition="right"
      >
        <InnerContainer>
          <SortableList items={sortableItems} onChange={onColumnOrderChange} />
        </InnerContainer>
      </ScrollArea>
    </Container>
  );
};

const Container = styled(Flex, {
  alignItems: 'flex-start',
  gap: '4px',
  alignSelf: 'stretch',
  height: '100%',
  overflow: 'hidden',
});

const InnerContainer = styled(Flex, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '4px',
  flex: '1 0 auto',
  background: '$neutral0',
  minHeight: '100%',
  maxHeight: '100%',
});
