import { apiTypes } from 'features/library-items/library/common';
import moment from 'moment';

import { i18n } from 'src/i18n';
import { compareVersions } from './compare-versions';
import type { VersionDate, VersionDateAutoApps } from './updates-card.types';

const SECONDS_IN_DAY = 60 * 60 * 24;

interface BannerData {
  version: string;
  enforcedInstallDate: number;
}

function buildBannerData({
  version,
  date,
  enforcementDelay,
}: {
  version: string;
  date: number;
  enforcementDelay: number;
}) {
  return {
    version,
    enforcedInstallDate: date + enforcementDelay * SECONDS_IN_DAY,
  };
}

function formatVersionsAutoApps(
  versions: VersionDateAutoApps[],
  enforcementDelay: number,
): BannerData[] {
  return versions.map(({ version_display: version, date }) =>
    buildBannerData({ version, date, enforcementDelay }),
  );
}

function formatVersions(
  versions: VersionDate,
  enforcementDelay: number,
): BannerData[] {
  const versionValues = Object.entries(versions);
  const bannerData = versionValues.map(([version, date]) =>
    buildBannerData({ version, date, enforcementDelay }),
  );
  return bannerData.sort((a, b) => compareVersions(a.version, b.version));
}

function installationHasBeenApplied(enforcedInstallDate: number) {
  const now = Math.round(Date.now() / 1000);
  return enforcedInstallDate <= now;
}

export const getBanners = ({
  versions = {},
  versionsAutoApps = [],
  appName,
  enforcementDelay,
  type,
}: {
  versionsAutoApps: VersionDateAutoApps[];
  versions: VersionDate;
  appName: string;
  enforcementDelay: number;
  type: string;
}) => {
  const banners = [];
  const isAutoApp = versionsAutoApps.length > 0 && type === apiTypes.AUTO_APP;
  const formattedVersions = isAutoApp
    ? formatVersionsAutoApps(versionsAutoApps, enforcementDelay)
    : formatVersions(versions, enforcementDelay);

  for (let i = 0; i < formattedVersions.length; i++) {
    const { version, enforcedInstallDate } = formattedVersions[i];
    if (installationHasBeenApplied(enforcedInstallDate)) {
      banners[0] = {
        version,
        text: `${appName} ${version} ${i18n.t(`is currently the minimum allowed version`)}.`,
      };
    } else {
      const enforcementDate = moment.unix(enforcedInstallDate);
      const formattedEnforcementDate = enforcementDate.format('MMMM D, YYYY');

      banners.push({
        version,
        text: `${appName} ${version} ${i18n.t(`will be the minimum allowed version on`)} ${i18n.format.dateMedium(new Date(formattedEnforcementDate))}.`,
      });
    }
  }

  return banners;
};

export default getBanners;
