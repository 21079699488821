import type { IconNames } from '@kandji-inc/nectar-ui';
import { Box, Flex, Icon, Text, Tooltip, styled } from '@kandji-inc/nectar-ui';
import { useEffect, useState } from 'react';
import { Arrow } from 'src/features/edr/common/assets/Arrow';
import { getTimeBetween } from 'src/features/edr/common/util/getTimeBetween';
import { i18n } from 'src/i18n';
import { formatDate } from '../../helpers';

type TimelineItem = {
  label: string;
  type: string;
  date: string;
  icon: IconNames;
  theme?: 'default' | 'danger';
};

type TimelineProps = {
  items: TimelineItem[];
  showAge?: boolean;
  ageStartType?: string;
  ageEndType?: string;
};

const Label = styled(Text, {
  color: '$neutral70',
  fontSize: '$1',
});

const Time = styled(Text, {
  fontWeight: '$medium',
  fontSize: '$1',
});

const Timeline = (props: TimelineProps) => {
  const {
    items,
    showAge = false,
    ageStartType = undefined,
    ageEndType = undefined,
  } = props;

  const [isHoveringAge, setIsHoveringAge] = useState(false);
  const [agePosition, setAgePosition] = useState({ width: 0, left: 0 });
  const [gradientPosition, setGradientPosition] = useState({
    start: 0,
    end: 0,
  });

  const themes = {
    default: {
      color: 'var(--colors-neutral60)',
      backgroundColor: 'var(--colors-neutral10)',
    },
    danger: {
      color: 'var(--colors-red60)',
      backgroundColor: 'var(--colors-red10)',
    },
  };

  const getAlignment = (index: number) => {
    if (index === 0) return 'start';
    if (index === items.length - 1) return 'end';
    return 'center';
  };

  const ageStartIndex = items.findIndex((item) => item.type === ageStartType);
  const ageEndIndex = items.findIndex((item) => item.type === ageEndType);

  useEffect(
    /* istanbul ignore next */ () => {
      // Items for Age placement
      const container = document.querySelector('.timeline-container');
      const firstDetectedElement = document.querySelector(`.${ageStartType}`);
      const firstDetectedIcon = document.querySelector(`.${ageStartType}-icon`);

      // Items for gradient placement
      const lastDetectedElement = document.querySelector(`.${ageEndType}`);
      const lastDetectedIcon = document.querySelector(`.${ageEndType}-icon`);

      const updatePositionAndWidth = () => {
        // Update Age placement

        if (
          container &&
          firstDetectedElement &&
          firstDetectedIcon &&
          lastDetectedElement &&
          lastDetectedIcon
        ) {
          const containerLeft = container.getBoundingClientRect().left;
          const firstDetectedRight =
            firstDetectedElement.getBoundingClientRect().right;
          const lastDetectedLeft =
            lastDetectedElement.getBoundingClientRect().left;

          const widthBetween = lastDetectedLeft - firstDetectedRight;
          const leftOffset = firstDetectedRight - containerLeft;

          setAgePosition({ width: widthBetween, left: leftOffset });

          // Update gradient placement
          const firstDetectedIconMiddle =
            (firstDetectedIcon.getBoundingClientRect().left +
              firstDetectedIcon.getBoundingClientRect().right) /
            2;
          const lastDetectedIconMiddle =
            (lastDetectedIcon.getBoundingClientRect().left +
              lastDetectedIcon.getBoundingClientRect().right) /
            2;

          const firstDetectedIconOffset =
            firstDetectedIconMiddle - containerLeft;
          const lastDetectedIconOffset = lastDetectedIconMiddle - containerLeft;

          setGradientPosition({
            start: firstDetectedIconOffset,
            end: lastDetectedIconOffset,
          });
        }
      };

      // Create ResizeObserver to track screen size changes
      const resizeObserver = new ResizeObserver(updatePositionAndWidth);
      resizeObserver.observe(container);

      // Call once when component mounts
      updatePositionAndWidth();

      return () => {
        resizeObserver.disconnect();
      };
    },
    [],
  );

  return (
    <Flex
      flow="column"
      wFull
      className="timeline-container"
      css={{ position: 'relative' }}
    >
      <Flex justifyContent="space-between" wFull css={{ zIndex: 1 }}>
        {items.map((item, idx) => {
          const { label, date, icon, theme = 'default', type } = item;

          const { color, backgroundColor } = themes[theme];

          const isItemBetweenAgeStartAndEnd =
            idx > ageStartIndex && idx < ageEndIndex;
          const shouldHide =
            showAge && isItemBetweenAgeStartAndEnd && isHoveringAge;

          return (
            <Flex
              className={type}
              flow="column"
              gap="xs"
              alignItems={getAlignment(idx)}
              key={`${idx}-${label}`}
              css={{
                minWidth: '80px',
                visibility: shouldHide ? 'hidden' : 'visible',
              }}
            >
              <Label>{label}</Label>

              <Flex
                className={`${type}-icon`}
                alignItems="center"
                justifyContent="center"
                css={{
                  backgroundColor,
                  borderRadius: '$round',
                  height: '20px',
                  width: '20px',
                }}
              >
                <Icon name={icon} color={color} size="xs" />
              </Flex>

              <Tooltip
                side="bottom"
                theme="dark"
                content={formatDate({ date, showUserFriendlyTimestamp: true })}
                css={{ zIndex: 3, maxWidth: 'auto' }}
              >
                <Time>{formatDate({ date })}</Time>
              </Tooltip>
            </Flex>
          );
        })}
      </Flex>

      {showAge && (
        <Box
          css={{
            position: 'absolute',
            width: `${agePosition.width}px`,
            left: `${agePosition.left}px`,
            bottom: '59px',
            height: '0px',
            zIndex: 2,
          }}
        >
          <Flex
            flow="column"
            alignItems="center"
            css={{
              height: '50px',
            }}
            onMouseEnter={
              /* istanbul ignore next */ () => setIsHoveringAge(true)
            }
            onMouseLeave={
              /* istanbul ignore next */ () => setIsHoveringAge(false)
            }
          >
            <Flex
              flow="column"
              alignItems="center"
              gap="sm"
              css={{
                visibility: isHoveringAge ? 'visible' : 'hidden',
              }}
            >
              <Label>{i18n.$t('Duration')}</Label>
              <Arrow color="var(--colors-neutral80)" />
              <Label css={{ fontStyle: 'italic' }}>
                {getTimeBetween(
                  new Date(items[ageStartIndex].date),
                  new Date(items[ageEndIndex].date),
                )}
              </Label>
            </Flex>
          </Flex>
        </Box>
      )}

      <Flex css={{ position: 'relative', bottom: '30.5px', height: 0 }}>
        <Box
          css={{
            width: '100%',
            height: '1px',
            background: showAge
              ? `linear-gradient(to right, 
                  $neutral30 ${gradientPosition.start}px, 
                  ${isHoveringAge ? '$neutral80' : '$neutral60'} ${gradientPosition.start}px, 
                  ${isHoveringAge ? '$neutral80' : '$neutral60'} ${gradientPosition.end}px, 
                  $neutral30 ${gradientPosition.end}px)`
              : '$neutral30',
          }}
        />
      </Flex>
    </Flex>
  );
};

export type { TimelineItem };
export { Timeline };
