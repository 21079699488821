/* istanbul ignore file */

import { useContext } from 'react';
import { getDaysBetween } from 'src/app/components/common/helpers';
import { AccountContext } from 'src/contexts/account';
import { i18n } from 'src/i18n';

export const convertToCurrencyFormat = (
  number,
  withDecimal = 2,
  locale = 'en',
  currency = 'usd',
) => {
  const formattedLocale = locale.replace('_', '-').toLowerCase();
  return new Intl.NumberFormat(formattedLocale, {
    style: 'currency',
    currency,
    minimumFractionDigits: withDecimal,
    maximumFractionDigits: withDecimal,
  }).format(number);
};

export const getResources = () => {
  const { currentCompany } = useContext(AccountContext);
  const onBoardingDeviceTypes = currentCompany.onboarding?.deviceTypes;
  const hasMac =
    !onBoardingDeviceTypes || onBoardingDeviceTypes?.includes('mac');
  const hasIos =
    !onBoardingDeviceTypes ||
    onBoardingDeviceTypes?.includes('ipad') ||
    onBoardingDeviceTypes?.includes('iphone') ||
    onBoardingDeviceTypes?.includes('apple-tv');

  return [
    {
      title: i18n.t('Resources'),
      description: i18n.t(
        'Need security and/or legal review of Kandji terms? Visit our Trust Center and view our MSA.',
      ),
      sectionResources: [
        {
          color: 'blue',
          icon: 'shield-halved',
          title: i18n.t('For Security - Kandji Trust Center'),
          description: i18n.t(
            'Start a security review of Kandji and view compliance information.',
          ),
          link: 'https://trust.kandji.io/',
        },
        {
          color: 'blue',
          icon: 'gavel',
          title: i18n.t('For Legal - Kandji MSA'),
          description: i18n.t(
            'View and download our Master Subscription Agreement.',
          ),
          link: 'https://www.kandji.io/legal/terms/',
        },
      ],
    },
    {
      title: i18n.t('Help documentation'),
      sectionResources: [
        {
          color: 'yellow',
          icon: 'kandji-logo',
          title: i18n.t('Kandji knowledge base'),
          description: i18n.t(
            'Search our knowledge base for answers to common questions.',
          ),
          link: 'https://support.kandji.io/support/home',
        },
        {
          color: 'yellow',
          icon: 'flag',
          title: i18n.t('Getting started with Kandji'),
          description: i18n.t(
            'Read our getting started guide to help jumpstart your trial.',
          ),
          link: 'https://support.kandji.io/support/solutions/articles/72000560510-getting-started',
        },
        {
          color: 'yellow',
          icon: 'sf-desktop-computer',
          title: i18n.t('Managed OS for macOS'),
          description: i18n.t(
            'Learn how to enforce and manage OS updates for macOS.',
          ),
          link: 'https://support.kandji.io/support/solutions/articles/72000560360-configuring-managed-os-for-macos',
          isHidden: !hasMac,
        },
        {
          color: 'yellow',
          icon: 'sf-iphone',
          title: i18n.t('Managed OS for iOS, iPadOS and tvOS'),
          description: i18n.t(
            'Learn how to enforce and manage OS updates for iPhone, iPad, and Apple TV.',
          ),
          link: 'https://support.kandji.io/support/solutions/articles/72000572246-configuring-managed-os-for-ios-ipados-and-tvos',
          isHidden: !hasIos,
        },
      ],
    },
  ];
};

export const daysSinceUnixTime = (unixTime: number) => {
  // Get the current timestamp in milliseconds
  const currentTimestamp = Date.now();

  // Calculate the difference in milliseconds
  const differenceInMilliseconds =
    currentTimestamp - +new Date(unixTime * 1000);

  // Convert the difference to days (milliseconds to seconds -> minutes -> hours -> days)
  const differenceInDays = Math.floor(
    differenceInMilliseconds / (1000 * 60 * 60 * 24),
  );

  return differenceInDays;
};

export const daysLeftInTrial = (trial_end_date) => {
  if (!trial_end_date) {
    return 0;
  }

  const trialEndDate = Date.parse(trial_end_date);
  const now = Date.now();
  const dateIsNotExpired = trialEndDate && now < trialEndDate + 1;
  const daysLeft = dateIsNotExpired
    ? getDaysBetween(Date.parse(trial_end_date), Date.now()) + 1
    : null;

  return daysLeft;
};

export const getTotalTrialDays = (
  tenantCreationDate: number,
  trialEndDate: number,
) =>
  tenantCreationDate && trialEndDate
    ? getDaysBetween(tenantCreationDate, +new Date(trialEndDate)) + 1
    : 0;
