/* istanbul ignore file */
import type { Edge, Node } from 'reactflow';
import { v4 as uuid } from 'uuid';

import type {
  AssignmentData,
  Blueprint,
  ValidationErrors,
} from './blueprint-flow.types';

import { EDGE_TYPES, NODE_TYPES } from './constants';

const initialBlueprint: Blueprint = {
  id: '',
  name: 'Untitled Assignment Map',
  description: '',
  params: {},
  type: 'flow',
  libraryItems: [],
};

const initialAssignmentData: {
  rules: AssignmentData['rules'];
  items: AssignmentData['items'];
} = {
  rules: null,
  items: [],
};

const initialNodes: Node[] = [
  {
    id: null,
    type: NODE_TYPES.preStart,
    position: {
      x: 0,
      y: 0,
    },
    data: {},
  },
  {
    id: null,
    type: NODE_TYPES.start,
    position: {
      x: 0,
      y: 0,
    },
    data: {
      items: [],
    },
  },
  {
    id: null,
    type: NODE_TYPES.end,
    position: {
      x: 0,
      y: 0,
    },
    data: {},
  },
];

const initialEdges: Edge[] = [
  {
    id: null,
    type: EDGE_TYPES.placeholder,
    source: null,
    target: null,
  },
  {
    id: null,
    type: EDGE_TYPES.root,
    source: null,
    target: null,
  },
];

const getInitialBlueprint = (): Blueprint => {
  const forcedInitialNodes: Node[] = initialNodes.map((node) => ({
    ...node,

    // To prevent React Flow from remembering exact data objects and
    // causing unwanted behaviors (such as the flow graph width persistence
    // bug), the `id` must be unique and dynamically set during every
    // initial hydration of a graph.
    id: uuid(),
  }));

  const forcedInitialEdges: Edge[] = [
    {
      ...initialEdges[0],
      id: `${forcedInitialNodes[0].id}->${forcedInitialNodes[1].id}`,
      source: forcedInitialNodes[0].id,
      target: forcedInitialNodes[1].id,
    },
    {
      ...initialEdges[1],
      id: `${forcedInitialNodes[1].id}->${forcedInitialNodes[2].id}`,
      source: forcedInitialNodes[1].id,
      target: forcedInitialNodes[2].id,
    },
  ];

  return {
    ...initialBlueprint,
    id: null,
    nodes: forcedInitialNodes,
    edges: forcedInitialEdges,
  };
};

const initialValidationErrors: ValidationErrors = {
  assignmentNodesWithoutRules: [],
};

export {
  initialBlueprint,
  initialAssignmentData,
  initialNodes,
  initialEdges,
  initialValidationErrors,
  getInitialBlueprint,
};
