import { wasInactiveLogout } from 'app/auth';
import PropTypes from 'prop-types';
/* istanbul ignore file */
import React, { Component, useContext, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';

import { Button, Flex } from '@kandji-inc/bumblebee';
import { Flex as NectarFlex } from '@kandji-inc/nectar-ui';
import { useHistory, useLocation } from 'react-router-dom';
import { AccountContext } from 'src/contexts/account';
import Loader from 'src/theme/components/atoms/Loader';

import Auth0Login from '../app/components/common/Auth0Login';
import history from '../app/router/history';
import stackedLogo from '../assets/img/new_icons/kandji_logo_stacked_dark.svg';
import { useAuth0 } from '../auth0';

export class SignIn extends Component {
  componentDidMount() {
    const { loggedIn } = this.props;
    if (loggedIn) {
      history.push('/');
    }
  }

  render() {
    const { hasAuth0Config, errorMessage } = this.props;

    return (
      <Flex
        align="center"
        direction="col"
        // override global margin bottom on body
        style={{ minHeight: '100vh', marginBottom: '-48px' }}
      >
        <Flex align="center" direction="col" className="mt-5">
          <img
            style={{ marginBottom: '48px' }}
            src={stackedLogo}
            className="img-responsive center-block"
            width="175"
            height="120"
            alt="Kandji App"
          />
          <Auth0Login disabled={!hasAuth0Config} />

          {/* Error during current auth0 authentication */}
          {errorMessage && (
            <p className="b-txt b-txt--error" style={{ textAlign: 'center' }}>
              {errorMessage}
            </p>
          )}

          <Button kind="link" onClick={() => history.push('/forgot-password')}>
            Forgot password?
          </Button>
        </Flex>
        <Flex
          align="end"
          direction="row"
          justify="center"
          style={{
            flex: '1',
            width: '100%',
          }}
        >
          <Flex
            justify="center"
            direction="row"
            gapType="gap2"
            style={{
              padding: '20px 0',
              borderTop: '1px solid var(--b-primary-marengo-ultralight)',
              width: '100%',
            }}
          >
            <FooterLink
              text="Privacy Policy"
              url="https://www.kandji.io/legal/privacy/"
            />
            <FooterLink
              text="Cookie Policy"
              url="https://www.kandji.io/legal/cookie-policy/"
            />
            <FooterLink
              text="California Privacy Notice"
              url="https://www.kandji.io/legal/ca-privacy-notice/"
            />
            <FooterLink
              text="Accessibility"
              url="https://www.kandji.io/legal/accessibility-statement/"
            />
            <FooterLink text="Legal" url="https://www.kandji.io/legal/" />
            <FooterLink
              text="Patent"
              url="https://www.kandji.io/legal/patents/"
            />
          </Flex>
        </Flex>
      </Flex>
    );
  }
}

const FooterLink = ({ text, url }) => (
  <a target="_blank" rel="noreferrer" className="b-txt-ctrl9" href={`${url}`}>
    {text}
  </a>
);

FooterLink.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

const mapStateToProps = ({ account }) => ({
  loggedIn: !!account.user.id,
});

SignIn.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  hasAuth0Config: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
};

const withAuth0Data = (BaseComponent) => (props) => {
  let errorMessage;

  const { error: auth0Error } = useAuth0();
  const hasAuth0Config = useSelector((state) => !!state.auth0.clientId);
  const hasAuth0ClientError = useSelector((state) => state.auth0.error);
  const wasInactive = wasInactiveLogout();

  if (hasAuth0ClientError) {
    errorMessage =
      'Unable to find your account. Please contact Kandji support.';
  } else if (auth0Error?.message) {
    errorMessage = (
      <span>
        It looks like we&apos;ve encountered an error during authentication.{' '}
        <br />
        Please reach out to your IT administrator or Kandji Support at
        support@kandji.io if the issue persists.
      </span>
    );
  } else if (wasInactive && document.referrer) {
    errorMessage = 'You were logged out due to inactivity.';
  }

  return (
    <BaseComponent
      {...props}
      hasAuth0Config={hasAuth0Config}
      errorMessage={errorMessage}
    />
  );
};

export const RedirectToAuth0SigninFlow = () => {
  const { userId, auth0 } = useContext(AccountContext);
  const history = useHistory();
  const { state } = useLocation<{ from: string }>();
  const { error: auth0Error, loginWithRedirect, isLoading } = useAuth0();
  const wasInactive = wasInactiveLogout();

  const hasAuth0Config = auth0?.clientId;
  const hasAuth0ClientError = auth0?.error;
  const isLoggedIn = Boolean(userId);

  useEffect(() => {
    const hasBasicError =
      hasAuth0ClientError &&
      'Unable to find your account. Please contact Kandji support.';
    const hasAuth0ErrorMessage =
      auth0Error?.message &&
      `It looks like we've encountered an error during authentication. Please reach out to your IT administrator or Kandji Support at support@kandji.io if the issue persists.`;

    const wasInactiveLogout =
      wasInactive &&
      document.referrer &&
      'You were logged out due to inactivity.';
    const hasAnError =
      hasBasicError || hasAuth0ErrorMessage || wasInactiveLogout;

    if (!isLoggedIn && hasAuth0Config && !isLoading) {
      setTimeout(() =>
        loginWithRedirect({
          appState: {
            returnTo: state?.from,
          },
          ...(hasAnError ? { 'ext-error': hasAnError } : {}),
        }),
      );
    }

    if (isLoggedIn) {
      history.push('/');
    }
  }, [isLoggedIn, hasAuth0Config, isLoading]);

  return (
    <NectarFlex wFull hFull alignItems="center" justifyContent="center">
      <Loader type="splash" />
    </NectarFlex>
  );
};

export default compose(connect(mapStateToProps), withAuth0Data)(SignIn);
