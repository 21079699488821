import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { Setting } from 'src/features/library-items/template';

import { Banner, TextInput, useInputsValidators } from '@kandji-inc/bumblebee';
import { i18n } from 'src/i18n';

const AutoLogin = (props) => {
  const { update, isDisabled, settings, validationDep } = props;
  const fieldsToValidate = ['AutologinUsername', 'AutologinPassword'];
  const { refs, onInvalidate } = useInputsValidators(fieldsToValidate, update);

  const handleToggleManage = () => {
    if (settings.AutologinIsManaged) {
      update('AutologinUsername', '');
      update('AutologinPassword', '');

      // clear the validation:
      onInvalidate('AutologinUsername')(false);
      onInvalidate('AutologinPassword')(false);

      update('AutologinIsManaged', !settings.AutologinIsManaged);
    } else {
      update('AutologinIsManaged', !settings.AutologinIsManaged);
    }
  };

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('Auto Login (macOS 14 and later)')}</h3>
        <Setting.HeaderToggle
          isEnabled={
            /* istanbul ignore next */ settings.AutologinIsManaged ?? false
          }
          onChange={handleToggleManage}
          isDisabled={isDisabled}
          chipText={{
            enabled: i18n.t('Managed'),
            disabled: i18n.t('Unmanaged'),
          }}
        />
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">{i18n.t('Configure auto login settings.')}</p>
      </Setting.SubHeader>
      {settings.AutologinIsManaged && (
        <Setting.Rows>
          <Setting.Row>
            <Setting.Title>
              <p className="b-txt">{i18n.t('Username')}</p>
            </Setting.Title>
            <Setting.Helpers className="b-txt-light">
              <p className="b-txt-light">
                {i18n.t('Specify the user name to use for auto login.')}
              </p>
              <Banner theme="info">
                <p>
                  {i18n.t(
                    'This user must already exist on the device for the configuration to install.',
                  )}
                </p>
              </Banner>
            </Setting.Helpers>
            <Setting.Controls>
              <div ref={refs[0]}>
                <TextInput
                  data-testid="AutologinUsername"
                  compact
                  disabled={isDisabled || !settings.AutologinIsManaged}
                  value={settings.AutologinUsername}
                  onChange={(e) => update('AutologinUsername', e.target.value)}
                  placeholder={i18n.t('AutoLoginUser')}
                  validator={(v) => [
                    {
                      message: i18n.t('Required'),
                      invalid: () => !v,
                      trigger: ['onBlur', validationDep],
                    },
                  ]}
                  onInvalidate={onInvalidate('AutologinUsername')}
                />
              </div>
            </Setting.Controls>
          </Setting.Row>
          <Setting.Row>
            <Setting.Title>
              <p className="b-txt">{i18n.t('Password')}</p>
            </Setting.Title>
            <Setting.Helpers className="b-txt-light">
              {i18n.t(
                'Specify the password for the user to use for auto login.',
              )}
            </Setting.Helpers>
            <Setting.Controls>
              <div ref={refs[1]}>
                <TextInput
                  data-testid="AutologinPassword"
                  compact
                  disabled={isDisabled || !settings.AutologinIsManaged}
                  value={settings.AutologinPassword}
                  onChange={(e) => update('AutologinPassword', e.target.value)}
                  placeholder={i18n.t('Password')}
                  type="password"
                  validator={(v) => [
                    {
                      message: i18n.t('Required'),
                      invalid: () => !v,
                      trigger: ['onBlur', validationDep],
                    },
                  ]}
                  onInvalidate={onInvalidate('AutologinPassword')}
                />
              </div>
            </Setting.Controls>
          </Setting.Row>
        </Setting.Rows>
      )}
    </Setting.Card>
  );
};

AutoLogin.propTypes = {
  update: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  settings: PropTypes.object.isRequired, // @todo define the shape of `settings`
};

export default memo(AutoLogin);
