import { Checkbox, TextInput } from '@kandji-inc/bumblebee';
import PropTypes from 'prop-types';
import React from 'react';

import { Setting } from 'features/library-items/template';
import { i18n } from 'src/i18n';

const Message = ({ message, update, isDisabled }) => {
  const handleChangeInput = (key, value) => {
    update(key, value);
  };

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('Message')}</h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label={i18n.t('Display custom message')}
              id="display_custom_message"
              testId="display_custom_message_checkbox"
              checked={message.checked}
              disabled={isDisabled}
              onChange={() => update('checked', (prev) => !prev)}
            />
          </Setting.Controls>
          {message.checked && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <TextInput
                    textArea
                    compact
                    disabled={isDisabled}
                    id="message_value"
                    value={message.value}
                    maxLength={8000}
                    placeholder={i18n.t('Honeycomb Conference Room')}
                    onChange={(e) => handleChangeInput('value', e.target.value)}
                    data-testid="message-value"
                  />
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
          <Setting.Helpers>
            <div className="b-flex-vg1">
              <p className="b-txt-light">
                {i18n.t(
                  'Choose to display a custom message on screen in Conference Room Display mode.',
                )}
              </p>
            </div>
          </Setting.Helpers>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

Message.propTypes = {
  message: PropTypes.shape({
    checked: PropTypes.bool,
    value: PropTypes.string,
  }).isRequired,
  update: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default Message;
