/* istanbul ignore file - legacy code, moved */
import PropTypes from 'prop-types';
import React from 'react';
import { useOldField, useValidation } from 'src/old-honey-form';

const useCheckbox = (field) => {
  const { getValue, setValue, setTouched } = useOldField(field);
  return {
    name: field,
    checked: getValue(),
    onBlur: () => setTouched(true), // i.e. tabbed to/from
    onChange: () => {
      setTouched(true);
      setValue(!getValue());
    },
  };
};

const OldCheckboxElement = React.forwardRef((props, ref) => {
  const { name, validation, ...otherProps } = props;
  useValidation(name, validation);

  return (
    <input ref={ref} type="checkbox" {...useCheckbox(name)} {...otherProps} />
  );
});

export const IPAToggle = ({ name, on, off, status, disabled }) => {
  const { getValue } = useOldField(name);
  const field = getValue();
  const toggle = field ? 'on' : 'off';
  const label = field ? on : off;
  return (
    <>
      {status && (
        <div className={`status status-${toggle} chip`}>
          <span>{label}</span>
        </div>
      )}
      <div
        className={`toggle toggle-${toggle} toggle-${
          disabled ? 'disabled' : 'enabled'
        }`}
      >
        <OldCheckboxElement name={name} disabled={disabled} />
      </div>
    </>
  );
};

IPAToggle.propTypes = {
  name: PropTypes.string.isRequired,
  on: PropTypes.string,
  off: PropTypes.string,
  status: PropTypes.bool,
  disabled: PropTypes.bool,
};

IPAToggle.defaultProps = {
  on: 'active',
  off: 'inactive',
  status: false,
  disabled: false,
};
