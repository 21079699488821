import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React from 'react';

const SortableItem = (props) => {
  const { id, children } = props;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const shadow = '1px 1px 4px 4px rgb(0 0 0 / 8%)';

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    borderRadius: '8px',
    boxShadow: isDragging ? shadow : 'none',
  };

  const isChildrenFn = typeof children === 'function';
  return (
    <div
      ref={setNodeRef}
      style={style}
      {...(isChildrenFn ? {} : { ...attributes, ...listeners })}
    >
      {isChildrenFn ? children(attributes, listeners) : children}
    </div>
  );
};

export default SortableItem;
