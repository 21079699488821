import { useQuery } from '@tanstack/react-query';
import { prismService } from '../data-service/prism-service';
import type { ExportPrismCategoryResponseSchema } from '../types/prism.types';

type UsePrismExportQueryParams = {
  exportId: string | undefined;
};

export const usePrismExportQuery = ({ exportId }: UsePrismExportQueryParams) =>
  useQuery<ExportPrismCategoryResponseSchema>({
    queryKey: ['exportPrismData', exportId],

    queryFn: () => prismService.getExportStatus(exportId),
    enabled: !!exportId,
    refetchInterval: ({ state }) => {
      const status = state?.data?.status;
      const signedUrl = state?.data?.signed_url;
      // istanbul ignore next
      if (status === 'pending' || (!signedUrl && status !== 'failed')) {
        return 1000;
      }
      return false;
    },
  });
