import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import { getIconName } from '../../components/PrismNav/prismNavUtils';
import {
  DateCell,
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  getFilteredDisabled as filterDisabled,
  getCodeSnippetCell,
  getEnumFilterOptions,
} from '../column-utils';
import { getScharedColumns } from './getSharedColumns';

export const getLocalUsersColumns = ({
  localUsersSchema,
}): {
  columnDefs: any[];
  pinnedColumns: string[];
  alwaysHiddenColumns: string[];
} => {
  const { properties } = localUsersSchema.schema;
  const localUsersColumnHelper =
    createColumnHelper<z.infer<typeof properties>>();

  const columnDefs = [
    localUsersColumnHelper.accessor(
      (row) => row[`${localUsersSchema.uri}.username`],
      {
        id: `${localUsersSchema.uri}.username`,
        meta: {
          displayName: properties.username.title,
          displayIcon: getIconName(localUsersSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    localUsersColumnHelper.accessor(
      (row) => row[`${localUsersSchema.uri}.uid`],
      {
        id: `${localUsersSchema.uri}.uid`,
        meta: {
          displayName: properties.uid.title,
          displayIcon: getIconName(localUsersSchema.uri),
          filterType: 'number',
          filterIcon: 'hashtag',
          filterDisabled,
        },
      },
    ),

    localUsersColumnHelper.accessor(
      (row) => row[`${localUsersSchema.uri}.type`],
      {
        id: `${localUsersSchema.uri}.type`,
        meta: {
          displayName: properties.type.title,
          displayIcon: getIconName(localUsersSchema.uri),
          filterType: 'enum',
          filterOptions: getEnumFilterOptions(
            localUsersSchema.schema?.definitions.LocalUsersTypes.enum || [],
          ),
          filterIcon: 'list-check',
          filterDisabled,
        },
      },
    ),

    localUsersColumnHelper.accessor(
      (row) => row[`${localUsersSchema.uri}.filevault_user`],
      {
        id: `${localUsersSchema.uri}.filevault_user`,
        cell: YesNoCell,
        meta: {
          displayName: properties.filevault_user.title,
          displayIcon: getIconName(localUsersSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    localUsersColumnHelper.accessor(
      (row) => row[`${localUsersSchema.uri}.home_directory`],
      {
        id: `${localUsersSchema.uri}.home_directory`,
        cell: getCodeSnippetCell,
        meta: {
          displayName: properties.home_directory.title,
          displayIcon: getIconName(localUsersSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    localUsersColumnHelper.accessor(
      (row) => row[`${localUsersSchema.uri}.user_created_at`],
      {
        id: `${localUsersSchema.uri}.user_created_at`,
        cell: DateCell,
        meta: {
          defaultHidden: true,
          displayName: properties.user_created_at.title,
          displayIcon: getIconName(localUsersSchema.uri),
          filterType: 'date-time',
          filterIcon: 'calendar',
          filterDisabled,
        },
      },
    ),

    localUsersColumnHelper.accessor(
      (row) => row[`${localUsersSchema.uri}.failed_logins`],
      {
        id: `${localUsersSchema.uri}.failed_logins`,
        meta: {
          defaultHidden: true,
          displayName: properties.failed_logins.title,
          displayIcon: getIconName(localUsersSchema.uri),
          filterType: 'number',
          filterIcon: 'hashtag',
          filterDisabled,
        },
      },
    ),

    localUsersColumnHelper.accessor(
      (row) => row[`${localUsersSchema.uri}.failed_login_time`],
      {
        id: `${localUsersSchema.uri}.failed_login_time`,
        cell: DateCell,
        meta: {
          defaultHidden: true,
          displayName: properties.failed_login_time.title,
          displayIcon: getIconName(localUsersSchema.uri),
          filterType: 'date-time',
          filterIcon: 'calendar',
          filterDisabled,
        },
      },
    ),

    localUsersColumnHelper.accessor(
      (row) => row[`${localUsersSchema.uri}.password_last_set`],
      {
        id: `${localUsersSchema.uri}.password_last_set`,
        cell: DateCell,
        meta: {
          defaultHidden: true,
          displayName: properties.password_last_set.title,
          displayIcon: getIconName(localUsersSchema.uri),
          filterType: 'date-time',
          filterIcon: 'calendar',
          filterDisabled,
        },
      },
    ),

    localUsersColumnHelper.accessor(
      (row) => row[`${localUsersSchema.uri}.generated_uid`],
      {
        id: `${localUsersSchema.uri}.generated_uid`,
        meta: {
          defaultHidden: true,
          displayName: properties.generated_uid.title,
          displayIcon: getIconName(localUsersSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    localUsersColumnHelper.accessor(
      (row) => row[`${localUsersSchema.uri}.directory`],
      {
        id: `${localUsersSchema.uri}.directory`,
        meta: {
          defaultHidden: true,
          displayName: properties.directory.title,
          displayIcon: getIconName(localUsersSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    localUsersColumnHelper.accessor(
      (row) => row[`${localUsersSchema.uri}.shell`],
      {
        id: `${localUsersSchema.uri}.shell`,
        cell: getCodeSnippetCell,
        meta: {
          defaultHidden: true,
          displayName: properties.shell.title,
          displayIcon: getIconName(localUsersSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    localUsersColumnHelper.accessor(
      (row) => row[`${localUsersSchema.uri}.hidden_user`],
      {
        id: `${localUsersSchema.uri}.hidden_user`,
        cell: YesNoCell,
        meta: {
          defaultHidden: true,
          displayName: properties.hidden_user.title,
          displayIcon: getIconName(localUsersSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    localUsersColumnHelper.accessor(
      (row) => row[`${localUsersSchema.uri}.secure_token`],
      {
        id: `${localUsersSchema.uri}.secure_token`,
        cell: YesNoCell,
        meta: {
          defaultHidden: true,
          displayName: properties.secure_token.title,
          displayIcon: getIconName(localUsersSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    localUsersColumnHelper.accessor(
      (row) => row[`${localUsersSchema.uri}.volume_owner`],
      {
        id: `${localUsersSchema.uri}.volume_owner`,
        cell: YesNoCell,
        meta: {
          defaultHidden: true,
          displayName: properties.volume_owner.title,
          displayIcon: getIconName(localUsersSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    localUsersColumnHelper.accessor(
      (row) => row[`${localUsersSchema.uri}.full_name`],
      {
        id: `${localUsersSchema.uri}.full_name`,
        meta: {
          defaultHidden: true,
          displayName: properties.full_name.title,
          displayIcon: getIconName(localUsersSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    localUsersColumnHelper.accessor(
      (row) => row[`${localUsersSchema.uri}.logged_in`],
      {
        id: `${localUsersSchema.uri}.logged_in`,
        cell: YesNoCell,
        meta: {
          defaultHidden: true,
          displayName: properties.logged_in.title,
          displayIcon: getIconName(localUsersSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    localUsersColumnHelper.accessor(
      (row) => row[`${localUsersSchema.uri}.logged_in_time`],
      {
        id: `${localUsersSchema.uri}.logged_in_time`,
        cell: DateCell,
        meta: {
          defaultHidden: true,
          displayName: properties.logged_in_time.title,
          displayIcon: getIconName(localUsersSchema.uri),
          filterType: 'date-time',
          filterIcon: 'calendar',
          filterDisabled,
        },
      },
    ),

    localUsersColumnHelper.accessor(
      (row) => row[`${localUsersSchema.uri}.mobile_account`],
      {
        id: `${localUsersSchema.uri}.mobile_account`,
        cell: YesNoCell,
        meta: {
          defaultHidden: true,
          displayName: properties.mobile_account.title,
          displayIcon: getIconName(localUsersSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    localUsersColumnHelper.accessor(
      (row) => row[`${localUsersSchema.uri}.home_folder_secure`],
      {
        id: `${localUsersSchema.uri}.home_folder_secure`,
        cell: YesNoCell,
        meta: {
          defaultHidden: true,
          displayName: properties.home_folder_secure.title,
          displayIcon: getIconName(localUsersSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    ...getScharedColumns(localUsersSchema),
  ];

  return { columnDefs, pinnedColumns: [], alwaysHiddenColumns: [] };
};
