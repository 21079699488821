/* istanbul ignore file */
import { i18n } from 'i18n';
import React from 'react';
import history from '../../../router/history';
import UniversalAlert from './UniversalAlert';

const MDMConfigureBanner = () => (
  <UniversalAlert
    type="danger"
    text={i18n.t(
      'Apple Push Notification service not configured. You will not be able to enroll devices until this is complete.',
    )}
    button={{
      text: i18n.t('Configure APNs'),
      onClick: () => history.push('/my-company/integrations'),
    }}
  />
);

export default MDMConfigureBanner;
