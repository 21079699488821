function createBlob(csvData: string) {
  return new Blob([csvData], { type: 'text/csv' });
}

function generateFileName(): string {
  const url = new URL(window.location.href);
  const pathSegments = url.pathname.substring(1).split('/');
  const pathAsFilename = pathSegments.join('_');
  const timestamp = new Date().toISOString();
  return `${pathAsFilename}_${timestamp}.csv`;
}

function downloadFile(blob: any, filename: string) {
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = filename;
  link.href = url;
  link.click();
  URL.revokeObjectURL(url);
}

function downloadCSVFile(csvData: string) {
  downloadFile(createBlob(csvData), generateFileName());
}

export default downloadCSVFile;
