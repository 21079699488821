import React, { useEffect, useState } from 'react';

import { array, func, number, oneOfType, shape, string } from 'prop-types';

import { TextInput as BumblebeeText } from '@kandji-inc/bumblebee';

import { characterLimitValidator, regexValidator } from './validators';

const Range = ({
  fieldKey,
  placeholder,
  value,
  updateField,
  updateIsInvalid,
  validation,
  ariaLabel,
}) => {
  const { character_limit, regex } = validation;

  const validator = (v) => [
    characterLimitValidator(
      v,
      character_limit?.value,
      character_limit?.error_message,
    ),
    regexValidator(v, regex?.value, regex?.error_message),
  ];

  // If a non-Array Value is received, reset the field
  // to empty. Otherwise, use what we are given.
  const valueIsArray = Array.isArray(value);
  const [left, setLeft] = useState(valueIsArray ? value[0] : '');
  const [right, setRight] = useState(valueIsArray ? value[1] : '');

  // Update the form if we did not receive an Array as
  // a Value and had to reset the field
  useEffect(() => {
    if (!valueIsArray) {
      updateField(fieldKey, [left, right]);
    }
  }, []);

  const onChange = ({ target, place }) => {
    if (place === 'left') {
      setLeft(target.value);
      updateField(fieldKey, [target.value, right]);
    } else {
      setRight(target.value);
      updateField(fieldKey, [left, target.value]);
    }
  };

  return (
    <div className="k-rule-range">
      <div className="k-rule-range-input">
        <BumblebeeText
          placeholder={placeholder}
          value={left}
          onChange={(e) => onChange({ target: e.target, place: 'left' })}
          validator={validator}
          onInvalidate={updateIsInvalid}
          aria-label={`${ariaLabel}-left`}
        />
      </div>

      <p className="k-rule-field-separator b-txt b-ml-tiny b-mr-tiny">and</p>

      <div className="k-rule-range-input">
        <BumblebeeText
          placeholder={placeholder}
          value={right}
          onChange={(e) => onChange({ target: e.target, place: 'right' })}
          validator={validator}
          onInvalidate={updateIsInvalid}
          aria-label={`${ariaLabel}-right`}
        />
      </div>
    </div>
  );
};

Range.propTypes = {
  fieldKey: string.isRequired,
  placeholder: string.isRequired,
  value: oneOfType([array, string]),
  updateField: func.isRequired,
  updateIsInvalid: func.isRequired,
  validation: shape({
    character_limit: shape({
      value: number,
      error_message: string,
    }),
    regex: shape({
      value: string,
      error_message: string,
    }),
  }),
  ariaLabel: string.isRequired,
};

Range.defaultProps = {
  value: ['', ''],
  validation: {
    character_limit: null,
    regex: null,
  },
};

export default Range;
