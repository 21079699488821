import { i18n } from 'src/i18n';
import validator from 'validator';

const requiredField = (v) => {
  const r1 = {
    message: i18n.t('Required'),
    invalid: () => !String(v).length,
    trigger: ['onBlur', 'onInput'],
  };
  return r1;
};

const getRequiredField = (v, { trigger = ['onBlur', 'onInput'] } = {}) => {
  const r1 = {
    message: i18n.t('Required'),
    invalid: () => !String(v).length,
    trigger,
  };
  return r1;
};

const validateInteger = (v) => {
  const r1 = {
    message: i18n.t('Must be a whole number'),
    invalid: () => !validator.isInt(String(v)),
    trigger: ['onBlur', 'onInput'],
  };
  return r1;
};

const requiredFieldAndMatchPassword = (otherValue) => (thisValue) => {
  const r1 = requiredField(thisValue);
  const r2 = {
    message: i18n.t('Passwords do not match.'),
    invalid: () => thisValue !== otherValue,
    trigger: ['onBlur', 'onInput'],
  };
  return [r1, r2];
};

const requiredFieldAndMinMax =
  (min, max, { trigger = ['onBlur', 'onInput'] } = {}) =>
  (v) => {
    const isGreaterThanMax = Number(v) > max;
    const isLessThanMin = Number(v) < min;
    const isLessThanMax = Number(v) < max;
    const r1 = getRequiredField(v, trigger);
    const r2 = validateInteger(v);
    const r3 = isLessThanMax
      ? {
          message: i18n.t(`Must be {min} or greater`, { min }),
          invalid: () => isLessThanMin,
          trigger: ['onBlur', 'onInput'],
        }
      : {
          message: i18n.t(`Must be {max} or less`, { max }),
          invalid: () => isGreaterThanMax,
          trigger: ['onBlur', 'onInput'],
        };
    return [r1, r2, r3];
  };

export { requiredField, requiredFieldAndMatchPassword, requiredFieldAndMinMax };
