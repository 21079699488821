import { Checkbox, Chip, Select, Slider } from '@kandji-inc/bumblebee';
/* istanbul ignore file */
import React, { useEffect } from 'react';

import { Setting } from 'features/library-items/template';

import { i18n } from 'src/i18n';
import EnergySaverService from '../service/energy-saver.service';

const PortablesBattery = (props) => {
  const { setting, generalSetting, update, isDisabled } = props;

  const diskSleepOptions = [
    {
      label: i18n.t('Enable'),
      value: EnergySaverService.diskSleep.ENABLE,
    },
    {
      label: i18n.t('Disable'),
      value: EnergySaverService.diskSleep.DISABLE,
    },
  ];

  const reduceBrightness = [
    {
      label: i18n.t('Enable'),
      value: EnergySaverService.reduceBrightness.ENABLE,
    },
    {
      label: i18n.t('Disable'),
      value: EnergySaverService.reduceBrightness.DISABLE,
    },
  ];

  const powerNapOptions = [
    {
      label: i18n.t('Enable'),
      value: EnergySaverService.powerNap.ENABLE,
    },
    {
      label: i18n.t('Disable'),
      value: EnergySaverService.powerNap.DISABLE,
    },
  ];

  useEffect(() => {
    if (generalSetting.DestroyFVKeyOnStandby) {
      update('isPowerNap', true);
      update('DarkWakeBackgroundTasks', EnergySaverService.powerNap.DISABLE);
    }
  }, [generalSetting]);

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('Portables - Battery')}</h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row className="k-es-config-row">
          <Setting.Controls className="k-es-config-row__controls">
            <Checkbox
              isDisabled={isDisabled}
              checked={setting.isTurnDisplayOff}
              label={i18n.t('Turn display off after a period of inactivity')}
              onChange={() => update('isTurnDisplayOff', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light k-es-config-row__helper">
            <p>
              {i18n.t(
                'In order to require a password immediately after the display is turned off, create a Passcode profile with the “Require Passcode After Sleep or Screen Saver Begins” option enabled.',
              )}
            </p>
          </Setting.Helpers>
          {setting.isTurnDisplayOff && (
            <Setting.SecondaryControls className="k-es-config-row__secondary">
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <div className="b-txt b-mb2">
                    {i18n.t('Turn display off after')}{' '}
                    <Chip
                      style={{ display: 'inline' }}
                      text={
                        !setting['Display Sleep Timer']
                          ? i18n.t('Never')
                          : i18n.common.durationMins(
                              setting['Display Sleep Timer'],
                            )
                      }
                    />
                  </div>
                  <Slider
                    className="mb-4"
                    value={
                      !setting['Display Sleep Timer']
                        ? 181
                        : setting['Display Sleep Timer']
                    }
                    onChange={(value) =>
                      update('Display Sleep Timer', value > 180 ? 0 : value)
                    }
                    disabled={isDisabled}
                    min={1}
                    max={181}
                    getMarkerLabel={(val) => {
                      if (val === 1) {
                        return i18n.t('{val} min', { val });
                      }
                      if (val === 180) {
                        return i18n.t('Never');
                      }
                      if (!(val % 60)) {
                        return i18n.common.durationHrs(val / 60);
                      }
                      return '';
                    }}
                    markedValues={[1, 60, 120, 180]}
                  />
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        <Setting.Row className="k-es-config-row">
          <Setting.Controls className="k-es-config-row__controls">
            <Checkbox
              isDisabled={isDisabled}
              checked={setting.isDiskSleepTimer}
              label={i18n.t('Put hard disks to sleep when possible')}
              onChange={() => update('isDiskSleepTimer', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light k-es-config-row__helper">
            <p>
              {i18n.t(
                'Automatically put the hard disks to sleep whenever possible, enabling this option sets the disk sleep value to 10 minutes.',
              )}
            </p>
          </Setting.Helpers>
          {setting.isDiskSleepTimer && (
            <Setting.SecondaryControls className="k-es-config-row__secondary">
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <Select
                    compact
                    options={diskSleepOptions}
                    value={diskSleepOptions.find(
                      ({ value }) => value === setting['Disk Sleep Timer'],
                    )}
                    disabled={isDisabled}
                    onChange={({ value }) => update('Disk Sleep Timer', value)}
                  />
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        <Setting.Row className="k-es-config-row">
          <Setting.Controls className="k-es-config-row__controls">
            <Checkbox
              isDisabled={isDisabled}
              checked={setting.isReduceBrightness}
              label={i18n.t('Dim the display while on battery power')}
              onChange={() => update('isReduceBrightness', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light k-es-config-row__helper">
            <p>{i18n.t('Slightly dim the display while on battery power.')}</p>
          </Setting.Helpers>
          {setting.isReduceBrightness && (
            <Setting.SecondaryControls className="k-es-config-row__secondary">
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <Select
                    compact
                    options={reduceBrightness}
                    value={reduceBrightness.find(
                      ({ value }) => value === setting.ReduceBrightness,
                    )}
                    disabled={isDisabled}
                    onChange={({ value }) => update('ReduceBrightness', value)}
                  />
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        <Setting.Row className="k-es-config-row">
          <Setting.Controls className="k-es-config-row__controls">
            <Checkbox
              isDisabled={isDisabled || generalSetting.DestroyFVKeyOnStandby}
              checked={setting.isPowerNap}
              label={i18n.t('Power Nap')}
              onChange={() => update('isPowerNap', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light k-es-config-row__helper">
            <p>
              {i18n.t(
                'While sleeping, the Mac can periodically check for new email, calendar, and other iCloud updates.',
              )}{' '}
              <a
                href="https://support.apple.com/en-us/HT204032"
                rel="noopener noreferrer"
                target="_blank"
                className="b-alink"
              >
                {i18n.t('Learn more')}
              </a>
            </p>
          </Setting.Helpers>
          {setting.isPowerNap && (
            <Setting.SecondaryControls className="k-es-config-row__secondary">
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <Select
                    compact
                    options={powerNapOptions}
                    value={powerNapOptions.find(
                      ({ value }) => value === setting.DarkWakeBackgroundTasks,
                    )}
                    disabled={
                      isDisabled || generalSetting.DestroyFVKeyOnStandby
                    }
                    onChange={({ value }) =>
                      update('DarkWakeBackgroundTasks', value)
                    }
                  />
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default PortablesBattery;
