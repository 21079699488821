/* istanbul ignore file */
import React, { memo, useEffect } from 'react';

import { useInputsValidators } from '@kandji-inc/bumblebee';
import { Checkbox, Flex, Text, TextField } from '@kandji-inc/nectar-ui';
import { Setting } from 'src/features/library-items/template';
import { i18n } from 'src/i18n';
import type { OptionsProps } from '../../scep.types';

const Options = (props: OptionsProps) => {
  const { update, isDisabled, isSubmitted, settings } = props;

  const fieldsToValidate = [
    'Retries',
    'RetryDelay',
    'CertificateRenewalTimeInterval',
    'Redistribution',
  ];
  const { refs, invalidations, onInvalidate } = useInputsValidators(
    fieldsToValidate,
    update,
  );

  useEffect(() => {
    fieldsToValidate.forEach((field) => {
      if (!settings[field].checked) {
        onInvalidate(field)(false);
      }
    });
  }, [settings]);

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('Options')}</h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Title>
            <Checkbox
              label={<Text>{i18n.t('Retries')}</Text>}
              checked={settings.Retries.checked}
              onCheckedChange={(checked) =>
                update('Retries', {
                  ...settings.Retries,
                  checked: Boolean(checked),
                })
              }
              size="2"
              disabled={isDisabled}
            />
          </Setting.Title>

          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t(
                'The number of times the device should retry if the server sends a PENDING response.',
              )}
            </p>
          </Setting.Helpers>

          {settings.Retries.checked && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <Flex gap="sm" alignItems="center" ref={refs.Retries}>
                    <TextField
                      type="number"
                      css={{ maxWidth: '100px', flex: 1 }}
                      value={settings.Retries.value}
                      onChange={(e) => {
                        const value = parseInt(e.target.value, 10);
                        update('Retries', {
                          ...settings.Retries,
                          value,
                        });
                        onInvalidate('Retries')(
                          !value || value < 0 || value > 99,
                        );
                      }}
                      disabled={isDisabled}
                      placeholder={i18n.t('0')}
                      state={
                        invalidations[0] && isSubmitted ? 'error' : undefined
                      }
                    />
                    <Text>{i18n.t('retries')}</Text>
                  </Flex>
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        <Setting.Row>
          <Setting.Title>
            <Checkbox
              label={<Text>{i18n.t('Retry delay')}</Text>}
              checked={settings.RetryDelay.checked}
              onCheckedChange={(checked) =>
                update('RetryDelay', {
                  ...settings.RetryDelay,
                  checked: Boolean(checked),
                })
              }
              size="2"
              disabled={isDisabled}
            />
          </Setting.Title>

          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t(
                'The number of seconds to wait between subsequent retries. The first retry is attempted without this delay.',
              )}
            </p>
          </Setting.Helpers>

          {settings.RetryDelay.checked && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <Flex gap="sm" alignItems="center" ref={refs.RetryDelay}>
                    <TextField
                      type="number"
                      css={{ maxWidth: '100px', flex: 1 }}
                      value={settings.RetryDelay.value}
                      onChange={(e) => {
                        const value = parseInt(e.target.value, 10);
                        update('RetryDelay', {
                          ...settings.RetryDelay,
                          value: parseInt(e.target.value, 10),
                        });
                        onInvalidate('RetryDelay')(
                          !value || value < 1 || value > 99,
                        );
                      }}
                      disabled={isDisabled}
                      placeholder="0"
                      state={
                        invalidations[1] && isSubmitted ? 'error' : undefined
                      }
                    />
                    <Text>{i18n.t('seconds between retries')}</Text>
                  </Flex>
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        <Setting.Row>
          <Setting.Title>
            <Checkbox
              label={
                <Text>{i18n.t('Allow apps to access the private key')}</Text>
              }
              checked={settings.AllowAllAppsAccess}
              onCheckedChange={(checked) =>
                update('AllowAllAppsAccess', Boolean(checked))
              }
              size="2"
              disabled={isDisabled}
            />
          </Setting.Title>

          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t(
                'Allow all apps to access the certificate in the keychain. Available in macOS.',
              )}
            </p>
          </Setting.Helpers>
        </Setting.Row>

        <Setting.Row>
          <Setting.Title>
            <Checkbox
              label={
                <Text>
                  {i18n.t(
                    'Prevent the private key data from being extracted in the keychain',
                  )}
                </Text>
              }
              checked={settings.KeyIsExtractable}
              onCheckedChange={(checked) =>
                update('KeyIsExtractable', Boolean(checked))
              }
              size="2"
              disabled={isDisabled}
            />
          </Setting.Title>

          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t(
                'Disables exporting the private key from the keychain. Available in macOS 10.15 and later.',
              )}
            </p>
          </Setting.Helpers>
        </Setting.Row>

        <Setting.Row>
          <Setting.Title>
            <Checkbox
              label={
                <Text>{i18n.t('Certificate expiration notification')}</Text>
              }
              checked={settings.CertificateRenewalTimeInterval.checked}
              onCheckedChange={(checked) =>
                update('CertificateRenewalTimeInterval', {
                  ...settings.CertificateRenewalTimeInterval,
                  checked: Boolean(checked),
                })
              }
              size="2"
              disabled={isDisabled}
            />
          </Setting.Title>

          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t(
                'The number of days before the certificate expires at which to start showing the expiration notification. Available in macOS.',
              )}
            </p>
          </Setting.Helpers>

          {settings.CertificateRenewalTimeInterval.checked && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <Flex
                    gap="sm"
                    alignItems="center"
                    ref={refs.CertificateRenewalTimeInterval}
                  >
                    <TextField
                      type="number"
                      css={{ maxWidth: '100px', flex: 1 }}
                      value={settings.CertificateRenewalTimeInterval.value}
                      onChange={(e) => {
                        const value = parseInt(e.target.value, 10);
                        update('CertificateRenewalTimeInterval', {
                          ...settings.CertificateRenewalTimeInterval,
                          value: parseInt(e.target.value, 10),
                        });
                        onInvalidate('CertificateRenewalTimeInterval')(
                          !value || value < 0 || value > 99,
                        );
                      }}
                      disabled={isDisabled}
                      placeholder={i18n.t('0')}
                      state={
                        invalidations[2] && isSubmitted ? 'error' : undefined
                      }
                    />
                    <Text>{i18n.t('days before certificate expiration')}</Text>
                  </Flex>
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        <Setting.Row>
          <Setting.Title>
            <Checkbox
              label={<Text>{i18n.t('Automatic profile redistribution')}</Text>}
              checked={settings.Redistribution.checked}
              onCheckedChange={(checked) =>
                update('Redistribution', {
                  ...settings.Redistribution,
                  checked: Boolean(checked),
                })
              }
              size="2"
              disabled={isDisabled}
            />
          </Setting.Title>

          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t(
                'The number of days prior to the certificate expiration that Kandji should attempt to re-issue the SCEP profile to renew the certificate.',
              )}
            </p>
          </Setting.Helpers>

          {settings.Redistribution.checked && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <Flex gap="sm" alignItems="center" ref={refs.Redistribution}>
                    <TextField
                      type="number"
                      css={{ maxWidth: '100px', flex: 1 }}
                      value={settings.Redistribution.value}
                      onChange={(e) => {
                        const value = parseInt(e.target.value, 10);
                        update('Redistribution', {
                          ...settings.CertificateRenewalTimeInterval,
                          value: parseInt(e.target.value, 10),
                        });
                        onInvalidate('Redistribution')(
                          !value || value < 0 || value > 365,
                        );
                      }}
                      disabled={isDisabled}
                      placeholder={i18n.t('0')}
                      state={
                        invalidations[3] && isSubmitted ? 'error' : undefined
                      }
                    />
                    <Text>{i18n.t('days before certificate expiration')}</Text>
                  </Flex>
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default memo(Options);
