/* istanbul ignore file - legacy code, moved */
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';

const classify = (text) =>
  text
    .toString()
    .toLowerCase()
    // Remove all non-word chars
    .replace(/[^\w]+/g, ' ')
    // Trim spaces from start and end of text
    .trim();

export const IPABreadcrumb = ({ root, path, current, customCrumbs, style }) => {
  const { url } = useRouteMatch();
  const base = path ? url.replace(path, '') : root;
  const crumbs = customCrumbs || [];
  return (
    <nav aria-label="breadcrumb" style={style}>
      <ol className="breadcrumb breadcrumb-hook">
        <li className="breadcrumb-item">
          <NavLink activeClassName="active" to={`/${base}`} exact>
            {crumbs[0] ? crumbs[0] : classify(base)}
          </NavLink>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {crumbs[1] ? crumbs[1] : classify(current)}
        </li>
      </ol>
    </nav>
  );
};

IPABreadcrumb.propTypes = {
  root: PropTypes.string,
  path: PropTypes.string,
  current: PropTypes.string.isRequired,
  customCrumbs: PropTypes.arrayOf(PropTypes.string),
  style: PropTypes.object,
};

IPABreadcrumb.defaultProps = {
  root: 'home',
  path: undefined,
  customCrumbs: [],
  style: {},
};
