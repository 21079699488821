import { createColumnHelper } from '@tanstack/react-table';
import { i18n } from 'src/i18n';
/* istanbul ignore file */
import type { z } from 'zod';

import {
  BytesCell,
  DateCell,
  LostModeStatusCell,
  NaCell,
  TextCell,
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  extractFilterableColumns,
  getFilteredDisabled as filterDisabled,
  getBlueprintCell,
  getDeviceCell,
  getDeviceUserCell,
  getSpecificDeviceFamilyCell,
} from '../column-utils';

const GB = 1000000000;

export const getDeviceInfoColumns = (schemas) => {
  const globalSchema = schemas.find((schema) => schema.uri === 'globals');
  const deviceInfoSchema = schemas.find(
    (schema) => schema.uri === 'device_information',
  );

  if (!globalSchema || !deviceInfoSchema) {
    return { columnDefs: [], filterableColumns: [] };
  }

  const combinedProperties = {
    ...globalSchema.schema.properties,
    ...deviceInfoSchema.schema.properties,
  };
  const deviceInfoColumnHelper =
    createColumnHelper<z.infer<typeof combinedProperties>>();

  const columnDefs = [
    deviceInfoColumnHelper.accessor((row) => row.device__name, {
      id: 'device__name',
      enableHiding: false,
      cell: (info) => getDeviceCell(info),
      meta: {
        displayName: combinedProperties.device__name.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.model_name, {
      id: 'model_name',
      meta: {
        displayName: combinedProperties.model_name.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.display_os_version, {
      id: 'display_os_version',
      meta: {
        displayName: combinedProperties.display_os_version.title,
        filterType: 'version',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.serial_number, {
      id: 'serial_number',
      meta: {
        displayName: combinedProperties.serial_number.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.device__user_name, {
      id: 'device__user_name',
      cell: getDeviceUserCell,
      meta: {
        displayName: combinedProperties.device__user_name.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.blueprint_name, {
      id: 'blueprint_name',
      cell: getBlueprintCell,
      meta: {
        displayName: combinedProperties.blueprint_name.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.tags, {
      id: 'tags',
      cell: TextCell,
      meta: {
        displayName: combinedProperties.tags.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.asset_tag, {
      id: 'asset_tag',
      cell: TextCell,
      meta: {
        displayName: combinedProperties.asset_tag.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.last_enrollment_date, {
      id: 'last_enrollment_date',
      cell: DateCell,
      meta: {
        displayName: combinedProperties.last_enrollment_date.title,
        filterType: 'date-time',
        filterIcon: 'calendar',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.last_collected_at, {
      id: 'last_collected_at',
      cell: DateCell,
      meta: {
        displayName: combinedProperties.last_collected_at.title,
        filterType: 'date-time',
        filterIcon: 'calendar',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.last_checkin, {
      id: 'last_checkin',
      cell: DateCell,
      meta: {
        displayName: combinedProperties.last_checkin.title,
        filterType: 'date-time',
        filterIcon: 'calendar',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.last_checkin_agent, {
      id: 'last_checkin_agent',
      cell: DateCell,
      meta: {
        displayName: combinedProperties.last_checkin_agent.title,
        defaultHidden: true,
        filterType: 'date-time',
        filterIcon: 'calendar',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.last_checkin_mdm, {
      id: 'last_checkin_mdm',
      cell: DateCell,
      meta: {
        displayName: combinedProperties.last_checkin_mdm.title,
        defaultHidden: true,
        filterType: 'date-time',
        filterIcon: 'calendar',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.device_id, {
      id: 'device_id',
      meta: {
        displayName: combinedProperties.device_id.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.device__user_email, {
      id: 'device__user_email',
      meta: {
        displayName: combinedProperties.device__user_email.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor(
      (row) =>
        Number.isNaN(row.device_capacity) ? null : row.device_capacity * GB,
      {
        id: 'device_capacity',
        cell: BytesCell,
        meta: {
          displayName: combinedProperties.device_capacity.title,
          defaultHidden: true,
          filterType: 'number',
          filterIcon: 'hashtag',
          filterDisabled,
        },
      },
    ),

    deviceInfoColumnHelper.accessor((row) => row.battery_health, {
      id: 'battery_health',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPhone', 'Mac', 'iPad', 'AppleTV'],
          'string',
        ),
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.battery_health.title,
        filterType: 'enum',
        filterIcon: 'list-check',
        filterOptions: [
          { value: 'non-genuine', label: i18n.t('Non-genuine') },
          { value: 'normal', label: i18n.t('Normal') },
          {
            value: 'service-recommended',
            label: i18n.t('Service recommended'),
          },
          { value: 'unknown', label: i18n.t('Unknown') },
          { value: 'unsupported', label: i18n.t('Unsupported') },
        ],
        deviceFamily: ['iPhone', 'Mac'],
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.host_name, {
      id: 'host_name',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: combinedProperties.host_name.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.apple_silicon, {
      id: 'apple_silicon',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac'], 'boolean'),
      meta: {
        displayName: combinedProperties.apple_silicon.title,
        defaultHidden: true,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.local_hostname, {
      id: 'local_hostname',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: combinedProperties.local_hostname.title,
        defaultHidden: true,
        filterIcon: 'text-size',
        filterType: 'string',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.model, {
      id: 'model',
      meta: {
        displayName: combinedProperties.model.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.model_id, {
      id: 'model_id',
      meta: {
        displayName: combinedProperties.model_id.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.os_build, {
      id: 'os_build',
      meta: {
        displayName: combinedProperties.os_build.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.marketing_name, {
      id: 'marketing_name',
      meta: {
        displayName: combinedProperties.marketing_name.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.supplemental_build_version, {
      id: 'supplemental_build_version',
      meta: {
        displayName: combinedProperties.supplemental_build_version.title,
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor(
      (row) => row.supplemental_os_version_extra,
      {
        id: 'supplemental_os_version_extra',
        meta: {
          displayName: combinedProperties.supplemental_os_version_extra.title,
          defaultHidden: true,
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    deviceInfoColumnHelper.accessor((row) => row.os_version, {
      id: 'os_version',
      meta: {
        displayName: combinedProperties.os_version.title,
        defaultHidden: true,
        filterType: 'version',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.first_enrollment_date, {
      id: 'first_enrollment_date',
      cell: DateCell,
      meta: {
        displayName: combinedProperties.first_enrollment_date.title,
        defaultHidden: true,
        filterType: 'date-time',
        filterIcon: 'calendar',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.agent_version, {
      id: 'agent_version',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: combinedProperties.agent_version.title,
        defaultHidden: true,
        filterType: 'version',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.mdm_enabled, {
      id: 'mdm_enabled',
      cell: YesNoCell,
      meta: {
        displayName: combinedProperties.mdm_enabled.title,
        defaultHidden: true,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.agent_installed, {
      id: 'agent_installed',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac'], 'boolean'),
      meta: {
        displayName: combinedProperties.agent_installed.title,
        defaultHidden: true,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.shared_ipad, {
      id: 'shared_ipad',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['iPad'], 'boolean'),
      meta: {
        displayName: combinedProperties.shared_ipad.title,
        defaultHidden: true,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.cellular_technology, {
      id: 'cellular_technology',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['iPad', 'iPhone']),
      meta: {
        displayName: combinedProperties.cellular_technology.title,
        defaultHidden: true,
        filterType: 'enum',
        filterIcon: 'list-check',
        filterOptions: [
          { value: 'None', label: i18n.t('None') },
          { value: 'GSM', label: i18n.t('GSM') },
          { value: 'CDMA', label: i18n.t('CDMA') },
          { value: 'GSM and CDMA', label: i18n.t('Both GSM and CDMA') },
        ],
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.data_roaming, {
      id: 'data_roaming',
      cell: (info) => {
        if (
          info.row.original.device__family === 'iPad' &&
          info.row.original.cellular_technology === 'None'
        ) {
          return NaCell();
        }
        return getSpecificDeviceFamilyCell(info, ['iPad', 'iPhone'], 'boolean');
      },

      meta: {
        displayName: combinedProperties.data_roaming.title,
        defaultHidden: true,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.hotspot, {
      id: 'hotspot',
      cell: (info) => {
        if (
          info.row.original.device__family === 'iPad' &&
          info.row.original.cellular_technology === 'None'
        ) {
          return NaCell();
        }
        return getSpecificDeviceFamilyCell(info, ['iPad', 'iPhone'], 'boolean');
      },
      meta: {
        displayName: combinedProperties.hotspot.title,
        defaultHidden: true,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.lost_mode_status, {
      id: 'lost_mode_status',
      cell: LostModeStatusCell,
      meta: {
        displayName: combinedProperties.lost_mode_status.title,
        defaultHidden: true,
        filterType: 'enum',
        filterIcon: 'list-check',
        filterOptions: [
          { value: 'PENDING', label: i18n.t('Pending') },
          { value: 'ENABLED', label: i18n.t('Enabled') },
          { value: 'ERRORED', label: i18n.t('Errored') },
        ],
        deviceFamily: ['iPhone', 'iPad'],
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.last_changed_at, {
      id: 'last_changed_at',
      cell: DateCell,
      meta: {
        displayName: combinedProperties.last_changed_at.title,
        defaultHidden: true,
        filterType: 'date-time',
        filterIcon: 'calendar',
        filterDisabled,
      },
    }),

    /* this column is always hidden but needs to be in column defs to enable */
    /* filtering for export purposes */
    deviceInfoColumnHelper.accessor((row) => row.device__user_id, {
      id: 'device__user_id',
      meta: {
        displayName: combinedProperties.device__user_id.title,
        alwaysHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),
  ];

  const filterableColumns = extractFilterableColumns(columnDefs);

  return { columnDefs, filterableColumns };
};
