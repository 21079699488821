export const emptyItem = {
  identifierType: null,
  identifier: '',
  comment: '',
};

export const closeModalDefaults = {
  editItem: emptyItem,
  itemIdx: null,
  isModalOpen: false,
  isExisting: false,
};

const initialState = {
  id: null,
  name: '',
  isActive: true,
  blueprints: [],
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,

  items: [],
  itemIdx: null,
  editItem: emptyItem,

  backgroundItems: [],
  selectedBackgroundItemIdx: null,
  originalItem: null,

  iconFile: null,
  iconSrc: '',

  general: {},
  managedDomains: {},

  isModalOpen: false,
  supportsRules: true,
};

export default initialState;
