import React from 'react';

import { string } from 'prop-types';

import { DATA_TYPES, TEXT_TYPES } from '../../constants';

import MultiSelect from './multi-select';
import MultiSelectAPI from './multi-select-api';
import MultiText from './multi-text';
import Range from './range';
import Select from './select';
import Text from './text';

const Fields = ({ type, operator, ...rest }) => {
  const { TEXT, MULTISELECT, MULTISELECTAPI, MULTITEXT, SELECT } = DATA_TYPES;
  const {
    BETWEEN,
    CONTAINS,
    DOES_NOT_CONTAIN,
    IS,
    IS_NOT,
    IS_BLANK,
    IS_NOT_BLANK,
    IS_ANY,
  } = TEXT_TYPES;
  const operatorValue = operator?.value;

  switch (type) {
    case TEXT:
      switch (operatorValue) {
        case BETWEEN:
          return <Range {...rest} />;
        case IS_ANY:
          return null;
        default:
          return <Text {...rest} />;
      }
    case MULTISELECT:
      return <MultiSelect {...rest} />;
    case MULTISELECTAPI:
      return <MultiSelectAPI {...rest} />;
    case MULTITEXT:
      switch (operatorValue) {
        case CONTAINS:
        case DOES_NOT_CONTAIN:
        case IS:
        case IS_NOT:
          return <Text {...rest} />;
        case IS_BLANK:
        case IS_NOT_BLANK:
          return null;
        default:
          return <MultiText {...rest} />;
      }

    case SELECT:
      return <Select {...rest} />;
    default:
      return null;
  }
};

Fields.propTypes = {
  type: string.isRequired,
};

export default Fields;
