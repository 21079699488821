import { parse as parseQueryString } from 'query-string';
import React, { useEffect, useReducer } from 'react';

import { initialAuthState } from './auth-state';
import Auth0Context from './context';
import { reducer } from './reducer';
import {
  hasAuthParams,
  hasIDPInitiatedSAMLParams,
  loginError,
  wrappedGetToken,
} from './utils';

const toAuth0LoginRedirectOptions = (opts) => {
  if (!opts) {
    return null;
  }
  const { redirectUri, ...validOpts } = opts;
  return {
    ...validOpts,
    redirect_uri: redirectUri,
  };
};

const defaultOnRedirectCallback = (appState) => {
  window.history.replaceState(
    {},
    document.title,
    appState?.returnTo || window.location.pathname,
  );
};

/**
 * Auth0 Provider
 */
const Auth0Provider = (providerOpts) => {
  const {
    children,
    onRedirectCallback = defaultOnRedirectCallback,
    auth0Client,
  } = providerOpts;
  const client = auth0Client;
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  useEffect(() => {
    if (!client) {
      return;
    }

    (async () => {
      try {
        if (hasIDPInitiatedSAMLParams()) {
          const { idpConnection: connection } = parseQueryString(
            window.location.search,
          );
          dispatch({ type: 'REDIRECT_IN_PROGRESS' });
          await client.loginWithRedirect({
            connection,
          });
        } else if (hasAuthParams()) {
          const { appState } = await client.handleRedirectCallback();
          onRedirectCallback(appState);
        } else {
          await client.checkSession();
        }
        const user = await client.getUser();
        dispatch({ type: 'INITIALISED', isAuthenticated: !!user, user });
      } catch (error) {
        dispatch({ type: 'ERROR', error: loginError(error) });
      }
    })();
  }, [client, onRedirectCallback]);

  const logout = (opts = {}) => {
    if (!client) {
      return;
    }

    client.logout(opts);
    if (opts.localOnly) {
      dispatch({ type: 'LOGOUT' });
    }
  };

  const getIdTokenClaims = (opts = {}) => client?.getIdTokenClaims(opts);

  return (
    <Auth0Context.Provider
      value={{
        ...state,
        getAccessTokenSilently: wrappedGetToken((opts) =>
          client?.getTokenSilently(opts),
        ),
        getIdTokenClaims,
        loginWithRedirect: (opts) =>
          client?.loginWithRedirect(toAuth0LoginRedirectOptions(opts)),
        logout,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

export default Auth0Provider;
