/* istanbul ignore file - legacy code, moved */
import { IconUpload } from 'components/common/uploader';
import { bool, func, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useOldField } from 'src/old-honey-form';

const isString = (v) => typeof v === 'string';

export const IPAIconUpload = (props) => {
  const {
    name,
    name2,
    onFileSelect,
    currentIcon,
    defaultIcon,
    isDisabled,
    onRemove,
    ...rest
  } = props;
  const field = useOldField(name);
  const field2 = useOldField(name2);
  const fieldValue = field.getValue();
  const [selectedIcon, setSelectedIcon] = useState(fieldValue);

  const readFile = (f) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => setSelectedIcon(reader.result));
    reader.readAsDataURL(f);
  };

  useEffect(() => {
    if (!fieldValue) {
      setSelectedIcon(null);
      return;
    }

    if (isString(fieldValue)) {
      setSelectedIcon(fieldValue);
    } else {
      // fieldValue is a file.
      readFile(fieldValue);
    }
  }, [fieldValue]);

  return (
    <IconUpload
      {...rest}
      onDelete={() => {
        field.setValue(null);
        field2.setValue(null);
      }}
      // todo: may be it will be better to delegate defaultIcon logic to IconUpload?
      currentIcon={selectedIcon || (isDisabled && defaultIcon)}
      onFileSelect={(file) => {
        if (props.onFileSelect) {
          props.onFileSelect(file);
        }
        field.setValue(file);
      }}
      isDisabled={isDisabled}
    />
  );
};

IPAIconUpload.propTypes = {
  name: string,
  name2: string,
  onFileSelect: func,
  currentIcon: object,
  defaultIcon: string, // base64
  isDisabled: bool,
  onRemove: func,
};

IPAIconUpload.defaultProps = {
  name: 'settings_app_icon',
  name2: 'settings_self_service_data_iconUrl',
  onFileSelect: null,
  currentIcon: null,
  defaultIcon: null,
  isDisabled: false,
  onRemove: () => {},
};
