import {
  Checkbox,
  Select,
  mapInvalidIndexToField,
  useInvalidations,
  useRefs,
} from '@kandji-inc/bumblebee';
import {
  Banner,
  Chip,
  Flex,
  Icon,
  Text,
  TextField,
  styled,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
/* istanbul ignore file */
import React, { memo, useEffect, useMemo } from 'react';

import { Setting } from 'features/library-items/template';

import { deviceTypes } from 'src/features/library-items/library/common';
import SoftwareUpdateService from '../../service/software-update-service';

const SectionHeading = styled(Text, {
  fontWeight: 500,
  lineHeight: '20px',
});

const SectionDescription = styled(Text, {
  lineHeight: '20px',
});

const TextNoWrap = styled(Text, {
  lineHeight: '20px',
  whiteSpace: 'nowrap',
});

const ModernConfigurations = ({ update, isDisabled, settings }) => {
  const fieldsToValidate = [
    'CombinedPeriodInDays',
    'enforcedSoftwareUpdateMajorOSDeferredInstallDelay',
    'enforcedSoftwareUpdateMinorOSDeferredInstallDelay',
    'enforcedSoftwareUpdateNonOSDeferredInstallDelay',
    'enforcedSoftwareUpdateDelay',
  ];
  const refs = useRefs(fieldsToValidate.length);
  const { invalidations, onInvalidate } = useInvalidations({
    inputs: fieldsToValidate.length,
  });

  const invalidationsMap = mapInvalidIndexToField(
    invalidations,
    fieldsToValidate,
    refs,
  );

  const deferValidators = (v) => {
    const validators = [
      {
        message: i18n.t('Enter a deferral length.'),
        invalid: () => !v,
      },
      {
        message: i18n.t('Enter a deferral length from 1-90 days.'),
        invalid: () => Number.isNaN(parseInt(v, 10)) || v < 1 || v > 90,
      },
    ];
    const invalid = validators.find((validator) => validator.invalid());

    return [Boolean(invalid), invalid?.message];
  };

  useEffect(() => {
    update('invalidationsMap', (p) => ({ ...p, ...invalidationsMap }));
  }, [...invalidations]);

  useEffect(() => {
    if (
      settings.deferMacOSUpdates ===
      SoftwareUpdateService.DEFER_MACOS_UPDATES_OPTIONS().doNotDefer
    ) {
      onInvalidate('enforcedSoftwareUpdateMajorOSDeferredInstallDelay')(false);
      onInvalidate('enforcedSoftwareUpdateMinorOSDeferredInstallDelay')(false);
      onInvalidate('enforcedSoftwareUpdateNonOSDeferredInstallDelay')(false);
      onInvalidate('enforcedSoftwareUpdateDelay')(false);
    } else if (
      settings.deferMacOSUpdates ===
      SoftwareUpdateService.DEFER_MACOS_UPDATES_OPTIONS().deferAll
    ) {
      onInvalidate('enforcedSoftwareUpdateMajorOSDeferredInstallDelay')(false);
      onInvalidate('enforcedSoftwareUpdateMinorOSDeferredInstallDelay')(false);
      onInvalidate('enforcedSoftwareUpdateNonOSDeferredInstallDelay')(false);
    } else if (
      settings.deferMacOSUpdates ===
      SoftwareUpdateService.DEFER_MACOS_UPDATES_OPTIONS().deferByType
    ) {
      onInvalidate('enforcedSoftwareUpdateDelay')(false);
    }

    if (settings.deferiOsUpdates) {
      onInvalidate('CombinedPeriodInDays')(false);
    }
  }, [settings]);

  return (
    <Setting.Card>
      <Setting.Header>
        <Flex flow="column" gap="md" flex="1">
          <Flex alignItems="center" gap="xs" flex="1">
            <h3 className="b-h3">{i18n.t('Configurations')}</h3>
          </Flex>
        </Flex>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row style={{ paddingBottom: 0, boxShadow: 'unset' }}>
          <Flex flow="column" gap="xs">
            <SectionHeading>
              {i18n.t('Automatic Software Updates')}
            </SectionHeading>
            <SectionDescription>
              {i18n.t(
                'Define the automatic software update behavior on supervised iPhone, iPad, and Mac devices.',
              )}
            </SectionDescription>
          </Flex>
        </Setting.Row>
        <Setting.Row>
          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t(
                'Specifies whether automatic downloads and preparation of available updates only (not upgrades and Rapid Security Responses) can be controlled by the user.',
              )}
            </p>
          </Setting.Helpers>
          <Setting.Controls>
            <Select
              compact
              inputId="download"
              label={i18n.t('Download')}
              options={Object.values(SoftwareUpdateService.ALLOWED_OPTIONS())}
              onChange={(selected) => update('Download', selected.value)}
              value={Object.values(
                SoftwareUpdateService.ALLOWED_OPTIONS(),
              ).find((opt) => opt.value === settings.Download)}
              disabled={isDisabled}
            />
          </Setting.Controls>
        </Setting.Row>

        <Setting.Row>
          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t(
                'Specifies whether automatic installation of available operating system updates only (not upgrades and Rapid Security Responses) can be controlled by the user.',
              )}
            </p>
          </Setting.Helpers>
          <Setting.Controls>
            <Select
              compact
              inputId="installOsUpdates"
              label={i18n.t('Install OS updates')}
              options={Object.values(SoftwareUpdateService.ALLOWED_OPTIONS())}
              onChange={(selected) =>
                update('InstallOSUpdates', selected.value)
              }
              value={Object.values(
                SoftwareUpdateService.ALLOWED_OPTIONS(),
              ).find((opt) => opt.value === settings.InstallOSUpdates)}
              disabled={isDisabled}
            />
          </Setting.Controls>
        </Setting.Row>

        <Setting.Row>
          <Setting.Helpers>
            <Flex flow="column" gap="sm">
              <p className="b-txt-light">
                {i18n.t(
                  'Specifies whether automatic installation of available security updates can be controlled by the user.',
                )}{' '}
              </p>
              <Chip color="unfilled" label={i18n.t('Mac')} />
            </Flex>
          </Setting.Helpers>
          <Setting.Controls>
            <Select
              compact
              inputId="InstallSecurityUpdate"
              label={i18n.t('Install Security updates')}
              options={Object.values(SoftwareUpdateService.ALLOWED_OPTIONS())}
              onChange={(selected) =>
                update('InstallSecurityUpdate', selected.value)
              }
              value={Object.values(
                SoftwareUpdateService.ALLOWED_OPTIONS(),
              ).find((opt) => opt.value === settings.InstallSecurityUpdate)}
              disabled={isDisabled}
            />
          </Setting.Controls>
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label={i18n.t('Check for updates')}
              id="check_for_updates"
              testId="check_for_updates_checkbox"
              checked={settings.AutomaticCheckEnabled}
              // onChange={onCheckForUpdatesChange} TODO:
              onChange={() =>
                update('AutomaticCheckEnabled', (checked) => !checked)
              }
              disabled={isDisabled}
            />
          </Setting.Controls>
          <Setting.Helpers>
            <Flex flow="column" gap="sm">
              <p className="b-txt-light">
                {i18n.t('Automatically check for available updates.')}
              </p>
              <Chip color="unfilled" label={i18n.t('macOS 14 and earlier')} />
            </Flex>
          </Setting.Helpers>
        </Setting.Row>
        <Setting.Row
          style={{
            /** TODO: HACK HERE */ margin: '0 -24px',
            padding: '24px',
          }}
        >
          <Setting.Controls>
            <Checkbox
              label={i18n.t('Install app updates from the App Store')}
              id="download_new_updates"
              testId="download_new_updates_checkbox"
              checked={settings.AutomaticallyInstallAppUpdates}
              onChange={() => {
                update('AutomaticallyInstallAppUpdates', (checked) => !checked);
              }}
              disabled={isDisabled || !settings.AutomaticCheckEnabled}
            />
          </Setting.Controls>
          <Setting.Helpers>
            <Flex flow="column" gap="sm">
              <p className="b-txt-light">
                {i18n.t(
                  'Automatically download and install app updates from the App Store.',
                )}
              </p>
              <Chip color="unfilled" label={i18n.t('macOS 14 and earlier')} />
              {!settings.AutomaticCheckEnabled && (
                <Banner
                  text={i18n.t(
                    'Turn on "Check for updates" to modify this option.',
                  )}
                />
              )}
            </Flex>
          </Setting.Helpers>
        </Setting.Row>

        <Setting.Row
          style={{
            paddingBottom: 0,
            boxShadow: 'unset',
          }}
        >
          <Flex flow="column" gap="xs">
            <SectionHeading>
              {i18n.t('Rapid Security Responses')}
            </SectionHeading>
            <SectionDescription>
              {i18n.t(
                'Used to configure the Rapid Security Response behavior on supervised iPhone, iPad, and Mac devices.',
              )}
            </SectionDescription>
          </Flex>
        </Setting.Row>

        <Setting.Row>
          <Setting.Helpers>
            <Flex flow="column" gap="sm">
              <p className="b-txt-light">
                {i18n.t(
                  'Determine whether Rapid Security Responses are offered for user installation.',
                )}
              </p>
              <Flex alignItems="center" gap="xs">
                <Chip color="unfilled" label={i18n.t('macOS 15+')} />
                <Chip color="unfilled" label={i18n.t('iOS 18+')} />
                <Chip color="unfilled" label={i18n.t('iPadOS 18+')} />
              </Flex>
            </Flex>
          </Setting.Helpers>
          <Setting.Controls>
            <Checkbox
              label={i18n.t('Offer to user')}
              id="offer_to_user"
              testId="offer_to_user"
              checked={settings.Enable}
              onChange={() => update('Enable', (checked) => !checked)}
              disabled={isDisabled}
            />
          </Setting.Controls>
        </Setting.Row>

        <Setting.Row
          style={{
            /** TODO: HACK HERE */ margin: '0 -24px',
            padding: '24px',
          }}
        >
          <Setting.Helpers>
            <Flex flow="column" gap="sm">
              <p className="b-txt-light">
                {i18n.t(
                  'Controls whether users have the option to remove a Rapid Security Response.',
                )}
              </p>
              <Flex alignItems="center" gap="xs">
                <Chip color="unfilled" label={i18n.t('macOS 15+')} />
                <Chip color="unfilled" label={i18n.t('iOS 18+')} />
                <Chip color="unfilled" label={i18n.t('iPadOS 18+')} />
              </Flex>
            </Flex>
          </Setting.Helpers>
          <Setting.Controls>
            <Checkbox
              label={i18n.t('Allow rollback')}
              id="allow_rollback"
              testId="allow_rollback"
              checked={settings.EnableRollback}
              onChange={() => update('EnableRollback', (checked) => !checked)}
              disabled={isDisabled}
            />
          </Setting.Controls>
        </Setting.Row>

        <Setting.Row
          style={{
            paddingBottom: 0,
            boxShadow: 'unset',
          }}
        >
          <Flex flow="column" gap="xs">
            <SectionHeading>
              {i18n.t('Software Update Deferrals')}
            </SectionHeading>
            <SectionDescription>
              {i18n.t(
                'Defines how many days a software release isn’t offered to users after it became publicly available.',
              )}
            </SectionDescription>
          </Flex>
        </Setting.Row>

        <Setting.Row>
          <Flex
            alignItems="center"
            gap="xs"
            css={{ marginBottom: '0 !important' }}
          >
            <Text size="1" css={{ fontWeight: 500 }}>
              {i18n.t('iOS and iPadOS')}{' '}
            </Text>
            <Flex
              gap="xs"
              css={{
                '& svg': {
                  width: '12px',
                  height: '12px',
                },
              }}
            >
              <Icon name="sf-iphone" />
              <Icon name="sf-ipad-landscape" />
            </Flex>
          </Flex>
          <Setting.SecondaryControls>
            <Setting.SecondaryControlsRow>
              <Setting.Helpers>
                <p className="b-txt-light">
                  {i18n.t(
                    'Specifies the number of days to defer a software update. When set, software updates and upgrades appear only after the specified delay, following the release of the software update or upgrade.',
                  )}
                </p>
              </Setting.Helpers>
              <Setting.Title>
                <Flex flow="column" gap="md" alignItems="start" ref={refs[0]}>
                  <Checkbox
                    label={i18n.t('Defer iOS and iPadOS updates')}
                    id="defer_ios_update"
                    testId="defer_ios_updates"
                    checked={settings.deferiOsUpdates}
                    onChange={() => {
                      if (settings.deferiOsUpdates) {
                        onInvalidate(0)(false);
                      }
                      update('deferiOsUpdates', (checked) => !checked);
                    }}
                    disabled={isDisabled}
                  />
                  {settings.deferiOsUpdates && (
                    <Flex alignItems="center" gap="xs">
                      <TextNoWrap>{i18n.t('Defer for')}</TextNoWrap>
                      <TextField
                        placeholder="0"
                        disabled={isDisabled}
                        value={settings.CombinedPeriodInDays}
                        onChange={(e) => {
                          update('CombinedPeriodInDays', e.target.value);
                          onInvalidate(0)(deferValidators(e.target.value)[0]);
                        }}
                        css={{ width: '36px', '& input': { padding: '8px' } }}
                        onBlur={() =>
                          onInvalidate(0)(
                            deferValidators(settings.CombinedPeriodInDays)[0],
                          )
                        }
                      />

                      <Text css={{ lineHeight: '20px' }}>{i18n.t('days')}</Text>
                    </Flex>
                  )}
                  {invalidationsMap.CombinedPeriodInDays?.isInvalid && (
                    <Flex
                      gap="xs"
                      alignItems="center"
                      css={{
                        color: '$red60',
                        '& svg': {
                          width: '16px',
                          height: '16px',
                        },
                      }}
                    >
                      <Icon name="octagon-exclamation" />
                      <Text css={{ fontSize: '12px' }}>
                        {deferValidators(settings.CombinedPeriodInDays)[1]}
                      </Text>
                    </Flex>
                  )}
                </Flex>
              </Setting.Title>
            </Setting.SecondaryControlsRow>
            <Setting.SecondaryControlsRow>
              <Setting.Helpers>
                <Flex flow="column" gap="sm">
                  <p className="b-txt-light">
                    {i18n.t(
                      'Specifies how the device shows software upgrades to the user. When a software update and upgrade is available, oldest shows only updates for the oldest (lower numbered) software version, and newest: Shows only a software upgrade to the newest (highest numbered) software version.',
                    )}
                  </p>
                  <Flex alignItems="center" gap="xs">
                    <Chip color="unfilled" label={i18n.t('iOS 18+')} />
                    <Chip color="unfilled" label={i18n.t('iPadOS 18+')} />
                  </Flex>
                </Flex>
              </Setting.Helpers>
              <Setting.Title>
                <Select
                  compact
                  inputId="recommended_cadence"
                  label={i18n.t('Recommended cadence')}
                  options={Object.values(
                    SoftwareUpdateService.RECOMMENDED_CADENCE_OPTIONS(),
                  )}
                  onChange={(selected) =>
                    update('RecommendedCadence', selected.value)
                  }
                  value={Object.values(
                    SoftwareUpdateService.RECOMMENDED_CADENCE_OPTIONS(),
                  ).find((opt) => opt.value === settings.RecommendedCadence)}
                  disabled={isDisabled}
                />
              </Setting.Title>
            </Setting.SecondaryControlsRow>
          </Setting.SecondaryControls>
        </Setting.Row>

        <Setting.Row
          style={{
            /** TODO: HACK HERE */ margin: '0 -24px',
            padding: '24px',
          }}
        >
          <Setting.Helpers>
            <Flex flow="column" gap="sm">
              <p className="b-txt-light">
                {i18n.t(
                  'Delay the availability of new macOS updates up to 90 days. Beta seed builds are exempt from deferral on any device enrolled in an Apple beta software program.',
                )}{' '}
                <a
                  href="https://support.apple.com/guide/deployment/depc4c80847a"
                  className="b-alink"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more...
                </a>
              </p>

              <Banner
                text={i18n.t(
                  'This deferral period does not apply to updates that are deployed by MDM.',
                )}
              />
            </Flex>
          </Setting.Helpers>
          <Flex flow="column" gap="xs">
            <Flex
              alignItems="center"
              gap="xs"
              css={{ marginBottom: '0 !important' }}
            >
              <Text size="1" css={{ fontWeight: 500 }}>
                macOS{' '}
              </Text>
              <Flex
                gap="xs"
                css={{
                  '& svg': {
                    width: '12px',
                    height: '12px',
                  },
                }}
              >
                <Icon name="sf-desktop-computer" />
              </Flex>
            </Flex>
            <Select
              compact
              inputId="defer_macos_updates_select"
              options={Object.values(
                SoftwareUpdateService.DEFER_MACOS_UPDATES_OPTIONS(),
              )}
              onChange={(selected) =>
                update('deferMacOSUpdates', selected.value)
              }
              value={Object.values(
                SoftwareUpdateService.DEFER_MACOS_UPDATES_OPTIONS(),
              ).find((opt) => opt.value === settings.deferMacOSUpdates)}
              disabled={isDisabled}
            />
          </Flex>
          {settings.deferMacOSUpdates ===
            SoftwareUpdateService.DEFER_MACOS_UPDATES_OPTIONS().deferAll
              .value && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow>
                <Setting.Helpers>
                  <p className="b-txt-light">
                    {i18n.t(
                      'Specifies the number of days to defer a software upgrade on the device. When set, software upgrades appear only after the specified delay, following the release of the software upgrade.',
                    )}
                  </p>
                </Setting.Helpers>
                <Setting.Title>
                  <Flex flow="column" gap="md" alignItems="start" ref={refs[1]}>
                    <Flex alignItems="center" gap="xs">
                      <TextNoWrap>{i18n.t('Defer for')}</TextNoWrap>
                      <TextField
                        placeholder="0"
                        disabled={isDisabled}
                        value={settings.enforcedSoftwareUpdateDelay}
                        onChange={(e) => {
                          update('enforcedSoftwareUpdateDelay', e.target.value);
                          onInvalidate(4)(deferValidators(e.target.value)[0]);
                        }}
                        css={{ width: '36px', '& input': { padding: '8px' } }}
                        onBlur={() =>
                          onInvalidate(1)(
                            deferValidators(
                              settings.enforcedSoftwareUpdateDelay,
                            )[0],
                          )
                        }
                      />

                      <Text css={{ lineHeight: '20px' }}>{i18n.t('days')}</Text>
                    </Flex>
                    {invalidationsMap.enforcedSoftwareUpdateDelay
                      ?.isInvalid && (
                      <Flex
                        gap="xs"
                        alignItems="center"
                        css={{
                          color: '$red60',
                          '& svg': {
                            width: '16px',
                            height: '16px',
                          },
                        }}
                      >
                        <Icon name="octagon-exclamation" />
                        <Text css={{ fontSize: '12px' }}>
                          {
                            deferValidators(
                              settings.enforcedSoftwareUpdateDelay,
                            )[1]
                          }
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                </Setting.Title>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}

          {settings.deferMacOSUpdates ===
            SoftwareUpdateService.DEFER_MACOS_UPDATES_OPTIONS().deferByType
              .value && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow>
                <Setting.Helpers>
                  <p className="b-txt-light">
                    {i18n.t(
                      'Specifies the number of days to defer a software upgrade on the device. When set, software upgrades appear only after the specified delay, following the release of the software upgrade.',
                    )}
                  </p>
                </Setting.Helpers>
                <Setting.Title>
                  <Flex flow="column" gap="md" alignItems="start" ref={refs[1]}>
                    <Checkbox
                      label={i18n.t('Defer major macOS upgrades')}
                      id="defer_major_macos"
                      testId="defer_major_macoss"
                      checked={settings.forceDelayedMajorSoftwareUpdates}
                      onChange={() => {
                        if (settings.forceDelayedMajorSoftwareUpdates) {
                          onInvalidate(1)(false);
                        }
                        update(
                          'forceDelayedMajorSoftwareUpdates',
                          (checked) => !checked,
                        );
                      }}
                      disabled={isDisabled}
                    />
                    {settings.forceDelayedMajorSoftwareUpdates && (
                      <Flex alignItems="center" gap="xs">
                        <TextNoWrap>{i18n.t('Defer for')}</TextNoWrap>
                        <TextField
                          placeholder="0"
                          disabled={isDisabled}
                          value={
                            settings.enforcedSoftwareUpdateMajorOSDeferredInstallDelay
                          }
                          onChange={(e) => {
                            update(
                              'enforcedSoftwareUpdateMajorOSDeferredInstallDelay',
                              e.target.value,
                            );
                            onInvalidate(1)(deferValidators(e.target.value)[0]);
                          }}
                          css={{ width: '36px', '& input': { padding: '8px' } }}
                          onBlur={() =>
                            onInvalidate(1)(
                              deferValidators(
                                settings.enforcedSoftwareUpdateMajorOSDeferredInstallDelay,
                              )[0],
                            )
                          }
                        />

                        <Text css={{ lineHeight: '20px' }}>
                          {i18n.t('days')}
                        </Text>
                      </Flex>
                    )}
                    {invalidationsMap
                      .enforcedSoftwareUpdateMajorOSDeferredInstallDelay
                      ?.isInvalid && (
                      <Flex
                        gap="xs"
                        alignItems="center"
                        css={{
                          color: '$red60',
                          '& svg': {
                            width: '16px',
                            height: '16px',
                          },
                        }}
                      >
                        <Icon name="octagon-exclamation" />
                        <Text css={{ fontSize: '12px' }}>
                          {
                            deferValidators(
                              settings.enforcedSoftwareUpdateMajorOSDeferredInstallDelay,
                            )[1]
                          }
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                </Setting.Title>
              </Setting.SecondaryControlsRow>
              <Setting.SecondaryControlsRow>
                <Setting.Helpers>
                  <p className="b-txt-light">
                    {i18n.t(
                      'Specifies the number of days to defer a software update only (not a software upgrade or Rapid Security Response) on the device. When set, software updates appear only after the specified delay, following the release of the software update.',
                    )}
                  </p>
                </Setting.Helpers>
                <Setting.Title>
                  <Flex flow="column" gap="md" alignItems="start" ref={refs[2]}>
                    <Checkbox
                      label={i18n.t('Defer minor macOS updates')}
                      id="defer_minor_macos"
                      testId="defer_minor_macoss"
                      checked={settings.forceDelayedMinorSoftwareUpdates}
                      onChange={() => {
                        if (settings.forceDelayedMinorSoftwareUpdates) {
                          onInvalidate(2)(false);
                        }
                        update(
                          'forceDelayedMinorSoftwareUpdates',
                          (checked) => !checked,
                        );
                      }}
                      disabled={isDisabled}
                    />
                    {settings.forceDelayedMinorSoftwareUpdates && (
                      <Flex alignItems="center" gap="xs">
                        <TextNoWrap>{i18n.t('Defer for')}</TextNoWrap>
                        <TextField
                          placeholder="0"
                          disabled={isDisabled}
                          value={
                            settings.enforcedSoftwareUpdateMinorOSDeferredInstallDelay
                          }
                          onChange={(e) => {
                            update(
                              'enforcedSoftwareUpdateMinorOSDeferredInstallDelay',
                              e.target.value,
                            );
                            onInvalidate(2)(deferValidators(e.target.value)[0]);
                          }}
                          css={{ width: '36px', '& input': { padding: '8px' } }}
                          onBlur={() =>
                            onInvalidate(2)(
                              deferValidators(
                                settings.enforcedSoftwareUpdateMinorOSDeferredInstallDelay,
                              )[0],
                            )
                          }
                        />

                        <Text css={{ lineHeight: '20px' }}>
                          {i18n.t('days')}
                        </Text>
                      </Flex>
                    )}
                    {invalidationsMap
                      .enforcedSoftwareUpdateMinorOSDeferredInstallDelay
                      ?.isInvalid && (
                      <Flex
                        gap="xs"
                        alignItems="center"
                        css={{
                          color: '$red60',
                          '& svg': {
                            width: '16px',
                            height: '16px',
                          },
                        }}
                      >
                        <Icon name="octagon-exclamation" />
                        <Text css={{ fontSize: '12px' }}>
                          {
                            deferValidators(
                              settings.enforcedSoftwareUpdateMinorOSDeferredInstallDelay,
                            )[1]
                          }
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                </Setting.Title>
              </Setting.SecondaryControlsRow>
              <Setting.SecondaryControlsRow>
                <Setting.Helpers>
                  <p className="b-txt-light">
                    {i18n.t(
                      'Specifies the number of days to defer non-operating system updates. When set, updates appear only after the specified delay, following the release of the update.',
                    )}
                  </p>
                </Setting.Helpers>
                <Setting.Title>
                  <Flex flow="column" gap="md" alignItems="start" ref={refs[3]}>
                    <Checkbox
                      label={i18n.t('Defer other system updates')}
                      id="defer_system_updates"
                      testId="defer_system_updatess"
                      checked={settings.forceDelayedAppSoftwareUpdates}
                      onChange={() => {
                        if (settings.forceDelayedAppSoftwareUpdates) {
                          onInvalidate(3)(false);
                        }
                        update(
                          'forceDelayedAppSoftwareUpdates',
                          (checked) => !checked,
                        );
                      }}
                      disabled={isDisabled}
                    />
                    {settings.forceDelayedAppSoftwareUpdates && (
                      <Flex alignItems="center" gap="xs">
                        <TextNoWrap>{i18n.t('Defer for')}</TextNoWrap>
                        <TextField
                          placeholder="0"
                          disabled={isDisabled}
                          value={
                            settings.enforcedSoftwareUpdateNonOSDeferredInstallDelay
                          }
                          onChange={(e) => {
                            update(
                              'enforcedSoftwareUpdateNonOSDeferredInstallDelay',
                              e.target.value,
                            );
                            onInvalidate(3)(deferValidators(e.target.value)[0]);
                          }}
                          css={{ width: '36px', '& input': { padding: '8px' } }}
                          onBlur={() =>
                            onInvalidate(3)(
                              deferValidators(
                                settings.enforcedSoftwareUpdateNonOSDeferredInstallDelay,
                              )[0],
                            )
                          }
                        />

                        <Text css={{ lineHeight: '20px' }}>
                          {i18n.t('days')}
                        </Text>
                      </Flex>
                    )}
                    {invalidationsMap
                      .enforcedSoftwareUpdateNonOSDeferredInstallDelay
                      ?.isInvalid && (
                      <Flex
                        gap="xs"
                        alignItems="center"
                        css={{
                          color: '$red60',
                          '& svg': {
                            width: '16px',
                            height: '16px',
                          },
                        }}
                      >
                        <Icon name="octagon-exclamation" />
                        <Text css={{ fontSize: '12px' }}>
                          {
                            deferValidators(
                              settings.enforcedSoftwareUpdateNonOSDeferredInstallDelay,
                            )[1]
                          }
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                </Setting.Title>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        <Setting.Row
          style={{
            paddingBottom: 0,
            boxShadow: 'unset',
          }}
        >
          <Flex flow="column" gap="xs">
            <SectionHeading>{i18n.t('Other settings')}</SectionHeading>
          </Flex>
        </Setting.Row>
        <Setting.Row>
          <Setting.Helpers>
            <Flex flow="column" gap="sm">
              <p className="b-txt-light">
                {i18n.t(
                  'Configure whether the device shows shows all software update enforcement notifications, or just notifications triggered one hour before the enforcement deadline, and the restart countdown notification.',
                )}
              </p>
              <Chip color="unfilled" label={i18n.t('macOS 15+')} />
            </Flex>
          </Setting.Helpers>
          <Setting.Controls>
            <Select
              compact
              inputId="notification"
              label={i18n.t('Show software update enforcement notifications')}
              options={Object.values(
                SoftwareUpdateService.NOTIFICATIONS_OPTIONS(),
              )}
              onChange={(selected) => update('Notifications', selected.value)}
              value={Object.values(
                SoftwareUpdateService.NOTIFICATIONS_OPTIONS(),
              ).find((opt) => opt.value === settings.Notifications)}
              disabled={isDisabled}
            />
          </Setting.Controls>
        </Setting.Row>
        <Setting.Row>
          <Setting.Helpers>
            <Flex flow="column" gap="sm">
              <p className="b-txt-light">
                {i18n.t(
                  'Configure whether a standard user can perform updates and upgrades.',
                )}
              </p>
              <Chip color="unfilled" label={i18n.t('Mac')} />
              <Banner
                text={i18n.t(
                  'If this option is enabled, turning off all "Automatic updates" settings is recommended. Otherwise, Standard user accounts will be prompted to enter administrator credentials every time macOS attempts to install updates.',
                )}
              />
            </Flex>
          </Setting.Helpers>
          <Setting.Controls>
            <Checkbox
              label={i18n.t(
                'Require administrative credentials to update or upgrade macOS',
              )}
              id="credentials"
              testId="credetnaisl"
              checked={
                settings['restrict-software-update-require-admin-to-install']
              }
              onChange={() =>
                update(
                  'restrict-software-update-require-admin-to-install',
                  (checked) => !checked,
                )
              }
              disabled={isDisabled}
            />
          </Setting.Controls>
        </Setting.Row>

        <Setting.Row>
          <Setting.Helpers>
            <Flex flow="column" gap="sm">
              <p className="b-txt-light">
                {i18n.t(
                  'Specifies whether beta program enrollment can be controlled by the user in the software update settings user interface.',
                )}
              </p>
              <Chip color="unfilled" label={i18n.t('Mac')} />
            </Flex>
          </Setting.Helpers>
          <Setting.Controls>
            <Checkbox
              label={i18n.t('Disallow macOS beta release installation')}
              id="credentials"
              checked={!settings.AllowPreReleaseInstallation}
              onChange={() => update('AllowPreReleaseInstallation', (p) => !p)}
              disabled={isDisabled}
            />
          </Setting.Controls>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default memo(ModernConfigurations);
