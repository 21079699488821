import { z } from 'zod';

export const appAccessSchema = z
  .object({
    IdentifierType: z.enum(['bundleID', 'path']),
    Identifier: z.string(),
    CodeRequirements: z.string(),
    StaticCode: z.boolean().default(false),
    Properties: z
      .array(
        z
          .object({
            Property: z.enum([
              'Accessibility',
              'AddressBook',
              'AppleEvents',
              'BluetoothAlways',
              'Calendar',
              'Camera',
              'FileProviderPresence',
              'ListenEvent',
              'MediaLibrary',
              'Microphone',
              'Reminders',
              'Photos',
              'PostEvent',
              'ScreenCapture',
              'SpeechRecognition',
              'SystemPolicyAllFiles',
              'SystemPolicyAppBundles',
              'SystemPolicyDesktopFolder',
              'SystemPolicyDocumentsFolder',
              'SystemPolicyDownloadsFolder',
              'SystemPolicyNetworkVolumes',
              'SystemPolicyRemovableVolumes',
              'SystemPolicySysAdminFiles',
            ]),
            Allowed: z.boolean().or(z.literal('')).nullable().default(''),
            AEReceiverCodeRequirement: z.string(),
            AEReceiverIdentifier: z.string(),
            AEReceiverIdentifierType: z.enum(['bundleID', 'path']),
          })
          .catchall(z.never()),
      )
      .min(1),
  })
  .catchall(z.never())
  .describe('UI: Based off v2 Privacy item.');

export const privacyEnum = {
  appAccessKey: appAccessSchema.keyof(),
  identifierType: appAccessSchema.shape.IdentifierType,
  propertyName: appAccessSchema.shape.Properties.element.shape.Property,
  propertyKey: appAccessSchema.shape.Properties.element.keyof(),
  allowedLabel: z.enum(['Allow', 'Deny', 'Allow Standard User to Approve']),
  validationMessage: z.enum([
    'Required.',
    'At least one App or Service row is required to save.',
    'Invalid Bundle ID.',
  ]),
  updateActionType: z.enum(['setting', 'appAccess', 'appAccessProperty']),
};
