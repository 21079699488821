import { func, string } from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';

import { Toaster as toaster } from '@kandji-inc/bumblebee';
import { Box, Code, Dialog, Flex, Icon, Text } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';

const RecoveryLockPasswordModal = (props) => {
  const { onHide, deviceId, recoveryPassword, getComputerRecoveryPassword } =
    props;

  const {
    location: { pathname },
  } = useHistory();
  const currDevicePathname = useRef(pathname).current;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (pathname !== currDevicePathname) {
      onHide();
    }
  }, [pathname]);

  useEffect(() => {
    setIsLoading(true);
    getComputerRecoveryPassword(deviceId).finally(() => setIsLoading(false));
  }, []);

  const handleOnCopy = (value) => {
    navigator.clipboard.writeText(value);
    toaster(i18n.t('Copied to clipboard.'));
  };

  const content = !isLoading && (
    <Flex gap="sm" css={{ minWidth: '$15' }}>
      {recoveryPassword ? (
        <Flex flow="row" gap="sm">
          <Code>{recoveryPassword}</Code>

          <Box
            css={{ '&:hover': { cursor: 'pointer' } }}
            onClick={() => handleOnCopy(recoveryPassword)}
            data-testid="copy-recovery-password"
          >
            <Icon name="copy" color="var(--colors-neutral50)" size="sm" />
          </Box>
        </Flex>
      ) : (
        <Text>
          {i18n.t('There is no Recovery Lock Password for this device.')}
        </Text>
      )}
    </Flex>
  );

  return (
    <Dialog
      isOpen
      closeOnEscape
      closeOnOutsideClick
      onOpenChange={onHide}
      title="Recovery Lock Password"
      content={content}
      css={{ zIndex: 2000 }}
    />
  );
};

export default RecoveryLockPasswordModal;

/* istanbul ignore next */
RecoveryLockPasswordModal.propTypes = {
  onHide: func,
  recoveryPassword: string,
};

/* istanbul ignore next */
RecoveryLockPasswordModal.defaultProps = {
  onHide: () => {},
  recoveryPassword: '',
};
