import React, {
  createContext,
  useReducer,
  type ReactNode,
  type Dispatch,
} from 'react';
import type { GeneralWebClip } from 'src/types/common.types';
import uuid from 'uuid/v4';

export enum ActionType {
  SetWebclip = 'set_webclip',
  UpdateWebclip = 'update_webclip',
}

type Action =
  | {
      type: ActionType.SetWebclip;
      payload: { selectedWebclip: GeneralWebClip | null };
    }
  | {
      type: ActionType.UpdateWebclip;
      payload: { key: keyof GeneralWebClip; value: any };
    };

const NEW_WEBCLIP_ITEM: GeneralWebClip = {
  Label: '',
  URL: '',
  webClipImgUrl: '',
  webClipImgS3Key: '',
  webClipImg: '',
  IsRemovable: false,
  FullScreen: false,
  IgnoreManifestScope: false,
  TargetApplicationBundleIdentifier: '',
  Precomposed: false,
} as const;

/* istanbul ignore next */
function reducer(state: GeneralWebClip, action: Action): GeneralWebClip {
  switch (action.type) {
    case ActionType.SetWebclip: {
      const { selectedWebclip } = action.payload;

      return selectedWebclip
        ? { ...selectedWebclip }
        : { ...NEW_WEBCLIP_ITEM, id: uuid() };
    }
    case ActionType.UpdateWebclip: {
      const { key, value } = action.payload;
      return {
        ...state,
        [key]: value,
      };
    }

    default:
      return state;
  }
}

interface WebClipContextProps {
  state: GeneralWebClip;
  dispatch: Dispatch<Action>;
}

const WebClipContext = createContext<WebClipContextProps | undefined>(
  undefined,
);

const WebClipProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, NEW_WEBCLIP_ITEM);

  return (
    <WebClipContext.Provider value={{ state, dispatch }}>
      {children}
    </WebClipContext.Provider>
  );
};

export { WebClipContext, WebClipProvider };
