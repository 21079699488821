import { updateSetting as update } from '@kandji-inc/bumblebee';
import React, { useCallback } from 'react';

import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import ActivityTab from 'features/library-items/common/activity-tab';
import NewStatusTab from 'features/library-items/common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from 'features/library-items/common/new-status-tab/util';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import config from '../../library/library-item-configurations/items/profile';
import InfoBanners from '../automatic-app/info-banners';
import initial from './initial-state';
import General from './sections/general';
import ManageMediaAccess from './sections/manage-media-access';
import { mediaAccessService } from './service/media-access-service';
import { transformFromApi, transformToApi } from './service/transformers';
import useMediaAccessService from './service/use-media-access-service';

const MediaAccessPage = (props) => {
  const { model, setModel, pageState } = props;
  const [, triggerValidation] = useUniqValue();

  const updateGeneral = useCallback(update('general', setModel));
  const updateManageMediaAccess = useCallback(
    update('manageMediaAccess', setModel),
  );

  const itemConfig = config['Media Access'];

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.name}
      summaryInfoProps={{
        name: `${itemConfig.name} Profile`,
        description: itemConfig.description,
        publisher: itemConfig.publisher,
        devices: itemConfig.devices,
        requirements: itemConfig.osRequirements,
        extra: (
          <InfoBanners
            requiresRosetta={false}
            warning={
              <>
                These restrictions were deprecated by Apple in macOS 11 and are
                no longer supported. For more information see{' '}
                <a
                  href="https://support.apple.com/guide/deployment/restrictions-for-mac-depba790e53"
                  target="_blank"
                  rel="noreferrer"
                >
                  MDM restrictions for Mac computers
                </a>
              </>
            }
          />
        ),
      }}
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={mediaAccessService}
      defaultIcon={itemConfig.icon}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      supportsRules
      supportsDuplication
    >
      <General
        settings={model.general}
        update={updateGeneral}
        isDisabled={pageState.isDisabled}
      />
      <ManageMediaAccess
        settings={model.manageMediaAccess}
        update={updateManageMediaAccess}
        isDisabled={pageState.isDisabled}
      />
    </LibraryItemPage>
  );
};

// MediaAccessPage.propTypes = {};

const MediaAccess = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState: initial,
    useService: useMediaAccessService,
  });

  const blueprintConflicts = useBlueprintConflicts();

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <MediaAccessPage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
      testId="media-access-v2"
    />
  );
};

export default MediaAccess;
