import { ButtonSelect } from '@kandji-inc/nectar-ui';
import type { OnApply, OnClear } from 'src/features/edr/common/common.types';
import constants from '../../../common/constants';
import type { FilterFields, ThreatClassification } from '../../../threat.types';

const {
  THREAT_CLASSIFICATION: { MALWARE, PUP },
} = constants;

const ALL_VALUE = 'ALL' as const;
type ThreatClassificationFilter = ThreatClassification | typeof ALL_VALUE;

type ClassificationFilterOption = {
  key?: ThreatClassificationFilter;
  value: ThreatClassificationFilter;
  label: string;
  checked?: boolean;
};

type ClassificationFilterProps = {
  value: ThreatClassificationFilter | undefined;
  onChange: OnApply;
  onClear: OnClear;
  malwareCount: number | undefined;
  pupCount: number | undefined;
};

const getLabelWithCount = (label: string, count: number): string =>
  `${label} (${count.toLocaleString()})`;

const ClassificationFilter = (props: ClassificationFilterProps) => {
  const { value, onChange, onClear, malwareCount = 0, pupCount = 0 } = props;

  const labelAll = getLabelWithCount('All', malwareCount + pupCount);
  const labelMalware = getLabelWithCount('Malware', malwareCount);
  const labelPup = getLabelWithCount('PUP', pupCount);

  const filterName: FilterFields = 'classification';
  const filterValue: string = value || ALL_VALUE;
  const filterOptions: ClassificationFilterOption[] = [
    { label: labelAll, value: ALL_VALUE },
    { label: labelMalware, value: MALWARE },
    { label: labelPup, value: PUP },
  ];

  return (
    <ButtonSelect.Root
      type="single"
      value={filterValue}
      data-testid="classification-filter"
      compact
    >
      {filterOptions.map((item) => (
        <ButtonSelect.Item
          key={item.value}
          value={item.value}
          onClick={() => {
            if (item.value === ALL_VALUE) {
              onClear(filterName);
            } else {
              onChange(filterName, item.value);
            }
          }}
        >
          {item.label}
        </ButtonSelect.Item>
      ))}
    </ButtonSelect.Root>
  );
};

export default ClassificationFilter;
