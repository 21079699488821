import { useEffect, useRef } from 'react';
import WifiService from './service/wifi-service';

export const showTrustCardKey = WifiService.keys.authentication.securityType;
export const showTrustCardValues = [
  WifiService.securityTypes.DYNAMIC_WEP,
  WifiService.securityTypes.WPA_ENTERPRISE,
  WifiService.securityTypes.WPA2_ENTERPRISE,
  WifiService.securityTypes.WPA3_ENTERPRISE,
  WifiService.securityTypes.ANY_ENTERPRISE,
];

export const useCallbackByKey = (cb, deps) => {
  const cache = useRef(new Map());

  useEffect(() => cache.current.clear(), deps);

  return (key) => {
    if (!cache.current.has(key)) {
      cache.current.set(key, (...args) => {
        cb({ key, args });
      });
    }

    return cache.current.get(key);
  };
};
