/* istanbul ignore file */
import { Button } from '@kandji-inc/nectar-ui';
import { logout } from 'app/_actions/account';
import { store } from 'app/_store/rootStore';
import { apiFetchUser } from 'app/api/account';
import UniversalAlert from 'app/components/interface/banners/UniversalAlert';
import { InterfaceContext } from 'contexts/interface';
import { i18n } from 'i18n';
import React, { useContext } from 'react';

const LAST_ACTIVITY_KEY = 'lastActivity';

const getStoredNumber = (key: string, defaultValue: number): number => {
  const stored = localStorage.getItem(key);
  if (stored) {
    return parseInt(stored, 10);
  }
  return defaultValue;
};

const inactivityTimeoutMs = getStoredNumber(
  'logoutInactivity',
  60 * 60 * 1000, // 60 minutes
);

const inactivityWarningMs = getStoredNumber(
  'logoutInactivityWarning',
  5 * 60 * 1000, // 5 minutes
);

const getLastActivityMs = () => getStoredNumber(LAST_ACTIVITY_KEY, Date.now());

export const resetActivity = () => {
  localStorage.setItem(LAST_ACTIVITY_KEY, Date.now().toString());
};

export const getInactiveMs = () => Date.now() - getLastActivityMs();

export const useActivityMonitor = () => {
  let tickTimeout;
  const { setBanner } = useContext(InterfaceContext);

  const tick = () => {
    const msTillLogout = inactivityTimeoutMs - getInactiveMs();
    const msTillBanner = msTillLogout - inactivityWarningMs;

    // time to log out
    if (msTillLogout <= 0) {
      store.getState().account.user.id && store.dispatch(logout(true));
      return;
    }

    // can wait until banner should be displayed to check activity again
    if (msTillBanner > 0) {
      tickTimeout = setTimeout(tick, msTillBanner);
      setBanner(null);
      return;
    }

    // get time to log out string
    let timeToLogout;
    if (msTillLogout > 60 * 1000) {
      const minutes = Math.ceil(msTillLogout / (60 * 1000));
      timeToLogout = i18n.common.durationMinutes(minutes);
    } else {
      const seconds = Math.round(msTillLogout / 1000);
      timeToLogout = i18n.common.durationSeconds(seconds);
    }

    // display banner
    setBanner(
      <UniversalAlert
        type="info"
        text={
          <>
            <span style={{ marginRight: '1em' }}>
              {i18n.t(
                'You will be automatically logged out in {timeToLogout} due to inactivity.',
                { timeToLogout },
              )}
            </span>
            <Button
              onClick={() => {
                apiFetchUser();
                setBanner(null);
              }}
            >
              {i18n.t('Stay Logged In')}
            </Button>
          </>
        }
      />,
    );

    // update every 1 second
    tickTimeout = setTimeout(tick, 1000);
  };

  return {
    start: tick,
    stop: () => clearTimeout(tickTimeout),
  };
};
