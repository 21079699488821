import slug from './slug';

export default {
  [slug.GENERAL]: {
    product: 'Kandji',
    label: 'Feature request',
    description:
      'Share a feature request or suggest general web app improvements.',
    icon: 'wrench',
    slug: slug.GENERAL,
  },
  [slug.AUTO_APP]: {
    product: 'Auto Apps',
    label: 'Auto App request',
    description:
      'Request a new Auto App that would be valuable to your end users.',
    icon: 'grid-2',
    slug: slug.AUTO_APP,
  },
};
