export const AirPrintSetting = {
  PrinterName: null,
  IPAddress: null,
  Port: null,
  ResourcePath: null,
  ForceTLS: true,
};

export default {
  AirPrintSetting,
};
