/* istanbul ignore file */
import React from 'react';

import { i18n } from 'src/i18n';
import KandjiLoginService from '../../service/kandji-login-service';

const providerOptions = () => [
  {
    label: 'Google Workspace',
    value: KandjiLoginService.identityProviders.GOOGLE_WORKSPACE,
  },
  {
    label: 'Microsoft Entra ID',
    value: KandjiLoginService.identityProviders.MICROSOFT_AZURE,
  },
  {
    label: 'Okta',
    value: KandjiLoginService.identityProviders.OKTA,
  },
  {
    label: 'OneLogin',
    value: KandjiLoginService.identityProviders.ONE_LOGIN,
  },
  {
    label: i18n.t('Other'),
    value: KandjiLoginService.identityProviders.OTHER,
  },
];

const providerCopy = () => ({
  [KandjiLoginService.identityProviders.OKTA]: {
    idpLearnMoreHelper: i18n.t(
      'Additional steps required to configure Passport with Okta.',
    ),
    idpLearnMoreUrl:
      'https://support.kandji.io/support/solutions/articles/72000560471',
    urlPlaceholder: i18n.t('Okta domain URL'),
    urlHelper: (
      <>
        {i18n.ut(
          `Enter the URL of your Okta domain in the following format: <br />
        https://{yourOktaDomain}/.well-known/openid-configuration`,
          { yourOktaDomain: 'yourOktaDomain' },
        )}
      </>
    ),
    clientHelper: i18n.t(
      'Create an Okta app with ROPG/PKCE authentication to sync user passwords. Copy and paste the client ID for this app here.',
    ),
    modeHelper: i18n.t(
      'Select the authentication mode displayed at user login. If you have MFA policies configured with Okta, choose Web Login to enforce them.',
    ),
    webLoginBanner: (
      <span>
        {i18n.ut(`To ensure users can log in locally, confirm the
        <span class="b-txt-bold li-passport-color__inherit">
          Local user access
        </span> settings below.`)}
      </span>
    ),
    webClientIdHelper: i18n.t(
      'Create a second Okta application with POST authentication to use with web login. Copy and paste the client ID for this app here.',
    ),
    redirectUriPlaceholder: i18n.t('Okta redirect URI'),
    redirectUriHelper: i18n.t(
      'Copy and paste the redirect URI from your Okta app.',
    ),
    clientSecretHelper: i18n.t(
      'If one exists, copy and paste the client secret from your Okta app.',
    ),
  },
  [KandjiLoginService.identityProviders.MICROSOFT_AZURE]: {
    idpLearnMoreHelper: i18n.t(
      'Additional steps required to configure Passport with Microsoft Entra ID.',
    ),
    idpLearnMoreUrl:
      'https://support.kandji.io/support/solutions/articles/72000560497',
    urlPlaceholder: i18n.t('Microsoft Entra ID domain URL'),
    urlHelper: (
      <>
        {i18n.ut(
          `Enter the URL of your Microsoft Entra ID domain in the following format:
        <br /> https://login.microsoftonline.com/{tenant}/v2.0/.well-known/openid-configuration`,
          { tenant: 'tenant' },
        )}
      </>
    ),
    clientHelper: i18n.t(
      'Create a Microsoft Entra ID app with ROPG/PKCE authentication to sync user passwords. Copy and paste the client ID for this app here.',
    ),
    modeHelper: i18n.t(
      'Select the authentication mode displayed at user login. If you have MFA policies configured with Microsoft Entra ID, select Web Login to enforce them.',
    ),
    webLoginBanner: (
      <span>
        {i18n.ut(`To ensure users can log in locally, confirm the
        <span class="b-txt-bold li-passport-color__inherit">
          Local user access
        </span> settings below.`)}
      </span>
    ),
    webClientIdHelper: i18n.t(
      'Create a second Microsoft Entra ID application with POST authentication to use with web login. Copy and paste the client ID for this app here.',
    ),
    macLoginBanner: (
      <span>
        {i18n.t(`Azure's free-tier MFA policies are not supported by Mac Login and may
        prevent users from being able to log in.`)}
      </span>
    ),
    redirectUriPlaceholder: i18n.t('Microsoft Entra ID redirect URI'),
    redirectUriHelper: i18n.t(
      'Copy and paste the redirect URI from your Microsoft Entra ID app.',
    ),
    clientSecretHelper: i18n.t(
      'If one exists, copy and paste the client secret from your Microsoft Entra ID app.',
    ),
  },
  [KandjiLoginService.identityProviders.GOOGLE_WORKSPACE]: {
    idpLearnMoreHelper:
      'Additional steps required to configure Passport with Google Workspace.',
    idpLearnMoreUrl:
      'https://support.kandji.io/en/support/solutions/articles/72000595458',

    modeHelper:
      'Select the authentication mode displayed at user login. If you have MFA policies configured with Google Workspace, select Web Login to enforce them.',
  },
  [KandjiLoginService.identityProviders.ONE_LOGIN]: {
    idpLearnMoreHelper: i18n.t(
      'Additional steps required to configure Passport with OneLogin.',
    ),
    idpLearnMoreUrl:
      'https://support.kandji.io/support/solutions/articles/72000560516',
    urlPlaceholder: 'Issuer URL',
    urlHelper: (
      <>
        {i18n.t(
          `Enter the URL of your OneLogin domain in the following format: <br />
        https://{subdomain}.onelogin.com/oidc/2/.well-known/openid-configuration`,
          { subdomain: 'subdomain' },
        )}
      </>
    ),
    clientHelper: i18n.t(
      'Create a OneLogin app with ROPG/PKCE authentication to sync user passwords. Copy and paste the client ID for this app here.',
    ),
    modeHelper: i18n.t(
      'Select the authentication mode displayed at user login. If you have MFA policies configured with OneLogin, select Web Login to enforce them.',
    ),
    webLoginBanner: (
      <span>
        {i18n.ut(`To ensure users can log in locally, confirm the
        <span class="b-txt-bold li-passport-color__inherit">
          Local user access
        </span> settings below.`)}
      </span>
    ),
    webClientIdHelper: i18n.t(
      'Create a second OneLogin application with POST authentication to enable web login. Copy and paste the client ID for this app here.',
    ),
    webClientBanner: (
      <span>
        {i18n.t(`To enable Web Login, you must configure a second OneLogin application
        that uses the POST authentication method. Each of the following fields
        will use information from this POST application.`)}
      </span>
    ),
    macLoginBanner: (
      <span>
        {i18n.t(`OneLogin's MFA policies are not supported by Mac Login and may prevent
        users from being able to log in.`)}
      </span>
    ),
    redirectUriPlaceholder: i18n.t('OneLogin redirect URI'),
    redirectUriHelper: i18n.t(
      'Copy and paste the redirect URI from your OneLogin POST app.',
    ),
    clientSecretHelper: i18n.t(
      'Copy and paste the client secret from your OneLogin app.',
    ),
    clientSecretWebHelper: i18n.t(
      'Copy and paste the client secret from your OneLogin POST app.',
    ),
  },
  [KandjiLoginService.identityProviders.OTHER]: {
    urlPlaceholder: i18n.t('OIDC domain'),
    urlHelper: (
      <>
        {i18n.ut(
          `Enter the URL of your OIDC domain in the following format: <br />
        https://{OIDCDomainURL}/.well-known/openid-configuration`,
          { OIDCDomainURL: 'OIDCDomainURL' },
        )}
      </>
    ),
    clientHelper: i18n.t(
      'Create an app in your OIDC with ROPG/PKCE authentication to sync user passwords. Copy and paste the client ID for this app here.',
    ),
    modeHelper: i18n.t(
      'Select the authentication mode displayed at user login. If you have MFA policies configured with your IdP, select Web Login to enforce them.',
    ),
    webLoginBanner: (
      <span>
        {i18n.ut(`To ensure users can log in locally, confirm the
        <span class="b-txt-bold li-passport-color__inherit">
          Local user access
        </span> settings below.`)}
      </span>
    ),
    webClientIdHelper: i18n.t(
      'Create a second OIDC application with POST authentication to use with web login. Copy and paste the client ID for this app here.',
    ),
    webClientBanner: (
      <span>
        {i18n.t(`To enable Web Login, you must configure a second OIDC application that
        uses the authorization code grant. Each of the following fields will use
        information from this OIDC application.`)}
      </span>
    ),
    redirectUriPlaceholder: i18n.t('OIDC redirect URI'),
    redirectUriHelper: i18n.t(
      'Copy and paste the redirect URI from your second OIDC app.',
    ),
    clientSecretHelper: i18n.t(
      'If one exists, copy and paste the client secret from your second OIDC app.',
    ),
  },
});

export { providerOptions, providerCopy };
