import { updateSetting as update } from '@kandji-inc/bumblebee';
import React, { useCallback } from 'react';

import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import featureFlags from 'src/config/feature-flags';
import { i18n } from 'src/i18n';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import NewStatusTab from '../../common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from '../../common/new-status-tab/util';
import config from '../../library/library-item-configurations/items/profile';
import ActivityTab from '../custom-profile/activity-tab';
import initialState from './initial-state';
import AutoLogin from './sections/AutoLogin';
import LockMessageSection from './sections/lock-message';
import LoggedInUsersSection from './sections/logged-in-users';
import MenuBarSection from './sections/menu-bar';
import OptionsSection from './sections/options';
import UserVisibilitySection from './sections/user-visibility';
import { loginWindowService } from './service/login-window.service';
import { transformFromApi, transformToApi } from './service/transformers';
import useLoginWindowService from './service/use-login-window-service';

const LoginWindowPage = (props) => {
  const itemConfig = config['Login Window'];
  const { model, setModel, pageState } = props;
  const [validationDep, triggerValidation] = useUniqValue();
  const updateMenuBar = useCallback(update('menuBar', setModel), []);
  const updateOptions = useCallback(update('options', setModel), []);
  const autoLoginUpdate = useCallback(update('autoLogin', setModel), []);
  const updateLoggedInUsers = useCallback(
    update('loggedInUsers', setModel),
    [],
  );
  const updateLockMessage = useCallback(update('lockMessage', setModel), []);
  const updateUserVisibility = useCallback(
    update('userVisibility', setModel),
    [],
  );

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      defaultIcon={itemConfig.icon}
      crumb={model.name || itemConfig.getName()}
      service={loginWindowService}
      triggerValidation={triggerValidation}
      summaryInfoProps={{
        name: `${itemConfig.name} ${i18n.t('Profile')}`,
        description: itemConfig.getDescription(),
        publisher: itemConfig.getPublisher(),
        devices: itemConfig.devices,
        requirements: itemConfig.getOsRequirements(),
      }}
      transformFromApi={transformFromApi}
      transformToApi={transformToApi}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      supportsRules
      supportsDuplication
    >
      <MenuBarSection
        isDisabled={pageState.isDisabled}
        update={updateMenuBar}
        setting={model.menuBar}
      />
      <UserVisibilitySection
        isDisabled={pageState.isDisabled}
        update={updateUserVisibility}
        setting={model.userVisibility}
        validationDep={validationDep}
      />
      <LockMessageSection
        isDisabled={pageState.isDisabled}
        update={updateLockMessage}
        setting={model.lockMessage}
        validationDep={validationDep}
      />
      <OptionsSection
        isDisabled={pageState.isDisabled}
        update={updateOptions}
        setting={model.options}
        validationDep={validationDep}
      />
      <LoggedInUsersSection
        isDisabled={pageState.isDisabled}
        update={updateLoggedInUsers}
        setting={model.loggedInUsers}
        validationDep={validationDep}
      />
      <AutoLogin
        settings={model.autoLogin}
        isDisabled={pageState.isDisabled}
        update={autoLoginUpdate}
        validationDep={validationDep}
      />
    </LibraryItemPage>
  );
};

const LoginWindow = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState,
    useService: useLoginWindowService,
  });
  const blueprintConflicts = useBlueprintConflicts();

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <LoginWindowPage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
      testId="login-window-v2"
    />
  );
};

export default LoginWindow;
