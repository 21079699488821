/* istanbul ignore file - legacy code, moved */
import { Button } from '@kandji-inc/bumblebee';
import React, { useCallback, useContext } from 'react';
import { useOldAllFields, useOldForm } from 'src/old-honey-form';
import { IPADataContext } from './IPADataContext';

export const IPAButtonSave = () => {
  const { canEdit, isWorking, isEditing, isPendingValidation, afterSubmit } =
    useContext(IPADataContext);

  const { handleSubmit } = useOldForm();
  const allFields = useOldAllFields();

  const onClick = useCallback(() => {
    if (!canEdit || !isEditing || isPendingValidation) {
      return;
    }

    handleSubmit();
    if (afterSubmit) {
      afterSubmit(allFields);
    }
  }, [canEdit, isEditing, isPendingValidation, handleSubmit]);

  const isProgress = isWorking || isPendingValidation;

  return (
    <Button
      isProgress={isProgress}
      icon={isWorking ? 'arrows-rotate' : undefined}
      onClick={onClick}
    >
      {isWorking ? '' : 'Save'}
    </Button>
  );
};
