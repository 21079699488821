import type { Column } from '@tanstack/react-table';
import { z } from 'zod';

import { i18n } from 'i18n';
import AllColumnsVisibleIcon from '../../../assets/all-columns-visible-state.svg';
import EmptyIcon from '../../../assets/bp-filter-empty-state.svg';
import {
  GLOBAL_SIDEBAR_CLOSED_WIDTH_PX,
  GLOBAL_SIDEBAR_OPEN_WIDTH_PX,
} from '../../../utils/constants';

export const COLUMN_EDITOR_DIMENSIONS = {
  SIDEBAR_MODAL_OPEN_OFFSET_PX: GLOBAL_SIDEBAR_OPEN_WIDTH_PX / 2,
  SIDEBAR_MODAL_CLOSED_OFFSET_PX: GLOBAL_SIDEBAR_CLOSED_WIDTH_PX / 2,
  COLUMNS_CONTAINER_WIDTH_PX: 752,
  COLUMNS_CONTAINER_HEIGHT_PX: 440,
  COLUMN_LIST_CONTAINER_WIDTH_PX: 348,
  COLUMN_LIST_WIDTH_PX: 320,
  COLUMN_LIST_HEIGHT_PX: 370,
  COLUMN_LIST_LAYER: 1000,
  COLUMN_SEPARATOR_ICON_SIZE_PX: 36,
} as const;

const parseDefaultColumnVisibility = (
  columns: Array<Column<unknown, unknown>>,
) =>
  columns.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.id]:
        curr.columnDef.meta?.defaultHidden !== true &&
        curr.columnDef.meta?.alwaysHidden !== true,
    }),
    {},
  );

const parseAlwaysHiddenColumnsVisibility = (
  columns: Array<Column<unknown, unknown>>,
) =>
  columns.reduce(
    (acc, curr) =>
      curr.columnDef.meta?.alwaysHidden === true
        ? {
            ...acc,
            [curr.id]: false,
          }
        : acc,
    {},
  );

const excludeAlwaysHiddenColumns = z
  .function()
  .args(z.any())
  .implement(
    (col: Column<unknown, unknown>) =>
      col.columnDef.meta?.alwaysHidden !== true,
  );

const diffColumns = z
  .function()
  .args(z.set(z.any()), z.set(z.any()))
  .implement(
    (
      setA: Set<Column<unknown, unknown>>,
      setB: Set<Column<unknown, unknown>>,
    ) => Array.from(setA).filter((column) => !setB.has(column)),
  );

const searchColumns = z
  .function()
  .args(z.string().trim().toLowerCase())
  .implement(
    (search) => (column: Column<unknown, unknown>) =>
      column.columnDef.meta?.displayName.toLowerCase().trim().includes(search),
  );

const compareAscColumns = z
  .function()
  .args(z.any(), z.any())
  .implement((a: Column<unknown, unknown>, b: Column<unknown, unknown>) => {
    const aDisplayName = a.columnDef.meta?.displayName;
    const bDisplayName = b.columnDef.meta?.displayName;

    if (typeof aDisplayName !== 'string' || typeof bDisplayName !== 'string') {
      return 0;
    }

    return aDisplayName.localeCompare(bDisplayName);
  });

const createReorderColumns = z
  .function()
  .args(z.record(z.string(), z.number()))
  .implement((colOrderMap) =>
    z
      .function()
      .args(z.any(), z.any())
      .implement((a: Column<unknown, unknown>, b: Column<unknown, unknown>) => {
        const aColOrder = colOrderMap[a.id];
        const bColOrder = colOrderMap[b.id];

        if (aColOrder === undefined || bColOrder === undefined) {
          return 0;
        }

        return aColOrder - bColOrder;
      }),
  );

const parseOrderedColumns = z
  .preprocess(
    (orderedItems) =>
      z
        .object({ id: z.string() })
        .array()
        .parse(orderedItems)
        .map(({ id }) => id),
    z.string().array(),
  )
  .transform((orderedIds) => ({
    orderedIds,
    compareAscOrderId: createReorderColumns(
      Object.fromEntries(orderedIds.map((id, index) => [id, index])),
    ),
  }));

const allColumnsVisibleKind = z.literal('all-columns-visible');
const allColumnsEmptyKind = z.literal('all-columns-empty');
const singleEmptyColumnsKind = z.literal('single-empty-columns');
const emptyColumnsKind = z.union([
  allColumnsVisibleKind,
  allColumnsEmptyKind,
  singleEmptyColumnsKind,
]);

const allColumnsVisibleDetailsSchema = z.preprocess(
  (kind) => ({
    kind,
    heading: 'All columns are visible',
    message:
      'There are no additional columns to add for this Prism category table.',
    icon: AllColumnsVisibleIcon,
    altText: 'No results found',
    width: COLUMN_EDITOR_DIMENSIONS.COLUMN_LIST_CONTAINER_WIDTH_PX,
  }),
  z.object({
    kind: allColumnsVisibleKind,
    heading: z.string(),
    message: z.string(),
    icon: z.any(),
    altText: z.string(),
    width: z.number(),
  }),
);

const columnsEmptyDetailsSchema = z.preprocess(
  (kind) => ({
    kind,
    heading: i18n.t('No results found'),
    message: i18n.t(
      "We couldn't find a match. Try changing the search query with different keywords.",
    ),
    icon: EmptyIcon,
    altText: i18n.t('No results found'),
  }),
  z.discriminatedUnion('kind', [
    allColumnsVisibleDetailsSchema
      .innerType()
      .merge(
        z.object({
          kind: allColumnsEmptyKind,
          width: z.string().optional().default('100%'),
        }),
      )
      .passthrough(),
    allColumnsVisibleDetailsSchema
      .innerType()
      .merge(
        z.object({
          kind: singleEmptyColumnsKind,
          width: z
            .number()
            .optional()
            .default(COLUMN_EDITOR_DIMENSIONS.COLUMN_LIST_CONTAINER_WIDTH_PX),
        }),
      )
      .passthrough(),
  ]),
);

const emptyStateDetailsSchema = allColumnsVisibleDetailsSchema.or(
  columnsEmptyDetailsSchema,
);

const hiddenColumnsEmptyKind = z
  .object({
    search: z.string().trim().length(0),
    hiddenColumnsCount: z.number().lte(0),
    visibleColumnsCount: z.number().gte(1),
  })
  .transform(() => allColumnsVisibleKind.value)
  .or(
    z
      .object({
        search: z.string().trim().min(1),
        hiddenColumnsCount: z.number().lte(0),
        visibleColumnsCount: z.number(),
      })
      .transform(() => singleEmptyColumnsKind.value),
  );

export const zUtils = {
  emptyStateDetailsSchema,
  hiddenColumnsEmptyKind,
  parseOrderedColumns,
  parseDefaultColumnVisibility,
  parseAlwaysHiddenColumnsVisibility,
  excludeAlwaysHiddenColumns,
  diffColumns,
  compareAscColumns,
  searchColumns,
};

export type EmptyColumnsKind = z.infer<typeof emptyColumnsKind>;
