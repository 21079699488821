import uuidv4 from 'uuid/v4';

import { i18n } from 'src/i18n';
import { kandjiIcon, learnMoreIcon, selfServiceIcon } from './default-icons';

const defaultLinks = () => [
  {
    uuid: uuidv4(),
    icon: selfServiceIcon,
    iconUrl: '',
    iconS3Key: '',
    title: i18n.t('Self Service'),
    helperText: i18n.t(
      'Easily install apps and tools provided by your IT department.',
    ),
    buttonText: i18n.t('Open'),
    buttonUrl: 'kandji://open-app/self-service',
    type: 'self-service',
  },
  {
    uuid: uuidv4(),
    icon: learnMoreIcon,
    iconUrl: '',
    iconS3Key: '',
    title: i18n.t('Mac Basics'),
    helperText: i18n.t(
      'Everything you need to know to start using your new Mac.',
    ),
    buttonText: i18n.t('Open'),
    buttonUrl: 'https://help.apple.com/macos/big-sur/mac-basics/#intro',
    type: 'learn-more',
  },
];

const initialState = () => ({
  id: '',
  icon: '',
  name: '',
  isActive: true,
  blueprints: [],
  type: 'kandji-setup',
  runs_on_mac: true,

  general: {
    enrollmentTrigger: 'all_enrollments',
    logo: kandjiIcon,
    logoUrl: '',
    logoS3Key: '',
    logoDark: {},
    logoDarkUrl: '',
    logoDarkS3Key: '',
    isCustomLogo: false,
    displayMode: 'windowed',
    exitPassword: '',
    logoWidth: '',
    logoHeight: '',
    logoDarkWidth: '',
    logoDarkHeight: '',
    enforceManagedOS: false,
  },

  installScreen: {
    header: i18n.t('Setting up your Mac...'),
    subHeader: i18n.t(
      'Your Mac is being automatically configured by your organization. This process may take some time to complete. Please don’t attempt to restart or shut down the computer unless you are prompted to do so.',
    ),
  },

  helpScreen: {
    header: i18n.t('Welcome to your Mac!'),
    subHeader: i18n.t(
      'Please wait while your organization’s security policies, apps, and settings are configured. This process may take some time to complete. Please don’t attempt to restart or shut down your Mac unless you are prompted to do so.\n\nIf you have any issues or questions, please contact your IT Department.',
    ),
  },

  successScreen: {
    isActive: true,
    header: i18n.t('Setup complete'),
    subHeader: i18n.t(
      'Your Mac has been successfully configured. Below are a few resources to help you get started.',
    ),
    links: defaultLinks(),
  },
});

export default initialState;
