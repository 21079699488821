/* istanbul ignore file */
export const escapeSpecialCharacters = (text: string) =>
  text?.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');

export const doesTextHaveMatch = (text: string, term: string) => {
  if (!text) {
    return false;
  }

  const parts = text.split(
    new RegExp(`(${escapeSpecialCharacters(term)})`, 'gi'),
  );

  return parts.some((part) => part.toLowerCase() === term?.toLowerCase());
};

export const highlightedText = (
  text: string,
  term: string,
  color = 'var(--colors-yellow30)',
  boldOptions?: {
    color: string;
    matchIndex: number;
  },
) => {
  if (term) {
    const parts = text?.split(
      new RegExp(`(${escapeSpecialCharacters(term)})`, 'gi'),
    );

    let matchCount = 0;
    return (
      <span>
        {parts?.map((part, i) => {
          const isMatchingSearchText =
            part.toLowerCase() === term?.toLowerCase();

          const isBold =
            isMatchingSearchText && boldOptions?.matchIndex === matchCount;
          const highlightColor = isBold ? boldOptions.color : color;

          if (isMatchingSearchText) {
            matchCount += 1;
          }

          return (
            <span
              key={i}
              style={{
                background: isMatchingSearchText ? highlightColor : '',
              }}
            >
              {part}
            </span>
          );
        })}
      </span>
    );
  }

  return (
    <span>
      <span>{text}</span>
    </span>
  );
};
