import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class RecoveryPasswordService extends NewLibraryItemService {
  static DEFAULT_ROTATE_DAYS = '90';

  static LEGACY_PASS_LIMIT = 20;
}

export const recoveryPasswordService = new RecoveryPasswordService();
export default RecoveryPasswordService;
