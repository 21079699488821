import smoothscroll from 'smoothscroll-polyfill';

// kick off the polyfill!
smoothscroll.polyfill();

Promise.prototype.finally =
  Promise.prototype.finally ||
  {
    finally(fn) {
      const onFinally = (value) => Promise.resolve(fn()).then(() => value);
      return this.then(
        (result) => onFinally(result),
        (reason) => onFinally(Promise.reject(reason)),
      );
    },
  }.finally;
