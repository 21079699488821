import { useEffect, useState } from 'react';

export function getLostModeStatus(device) {
  /* istanbul ignore next */
  if (!device) {
    return {};
  }

  const lostMode = device.lost_mode;

  return {
    lostModeEnabledBy: lostMode?.enabled_by,
    lostModeEnabledDatetime: lostMode?.enable_status_at,
    lostModeLocationDatetime: lostMode?.last_location_at,
    lostModeSoundStatusDatetime: lostMode?.sound_status_at,
    isLostModeSoundPending: lostMode?.sound_status === 'PENDING',
    isLostModeSoundSuccess: lostMode?.sound_status === 'SUCCESS',
    isLostModeSoundErrored: lostMode?.sound_status === 'ERRORED',
    lostModeDisableDatetime: lostMode?.disable_status_at,
    lostModeCommandId: lostMode?.enable_command_id,
    isLostModeDisablePending: lostMode?.disable_status === 'PENDING',
    isLostModeDisableErrored: lostMode?.disable_status === 'ERRORED',
    isLostModeLocationErrored: lostMode?.last_location_status === 'ERRORED',
    isLostModeUnset: handleUnset(lostMode),
    isLostModePending: lostMode?.lost_mode_status === 'PENDING',
    isLostModeDisabled:
      lostMode?.disable_status === 'SUCCESS' ||
      lostMode?.lost_mode_status === 'DISABLED',
    isLostModeEnabled: lostMode?.lost_mode_status === 'ENABLED',
    isLostModeErrored: lostMode?.lost_mode_status === 'ERRORED',
    isLostModeLocationPending: lostMode?.last_location_status === 'PENDING',
    isNoCoordinates:
      !lostMode?.last_location?.latitude && !lostMode?.last_location?.longitude,
    footnote: lostMode?.lock_screen_footnote,
    message: lostMode?.lock_screen_message,
    phone: lostMode?.lock_screen_phone_number,
    address: lostMode?.last_location?.address,
    horizontalAccuracy: lostMode?.last_location?.horizontal_accuracy,
    longitude: Number(lostMode?.last_location?.longitude),
    latitude: Number(lostMode?.last_location?.latitude),
  };
}

export function handleUnset(lostMode) {
  if (!lostMode) {
    return true;
  }
  if (typeof lostMode === 'object' && Object.keys(lostMode).length === 0) {
    return true;
  }
  return false;
}

export const useLostMode = (computer) => {
  const [lostMode, setLostMode] = useState({});

  useEffect(() => {
    const lostModeStatus = getLostModeStatus(computer);

    setLostMode(lostModeStatus);
  }, [computer]);

  return lostMode;
};

export default useLostMode;

export function mockLostMode(state) {
  switch (state) {
    case 'ENABLED':
      return {
        lost_mode_status: 'ENABLED',
        enabled_by: 'Steve Fischer',
        enable_status_at: '2022-10-24T15:59:47.260747Z',
        lock_screen_message:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque in accusamus illo sequi cumque perferendis porro, vero non ducimus odio dolore voluptas ullam, eum, dicta et quis culpa eius. Omnis. Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto, iure minus! Fugit sit, molestiae consequatur adipisci tempore nostrum deleniti totam eius atque aut cum rerum impedit! Sed doloribus sint repellat.',
        lock_screen_phone_number: '123123123',
        lock_screen_footnote: 'footnote text',
        disable_status: '',
        disabled_by: '',
        disable_status_at: new Date().toISOString(),
        last_location_status: 'SUCCESS',
        last_location_status_at: '2022-10-24T15:59:57.829303Z',
        enable_command_id: '123123123123',
        last_location: {
          latitude: '32.715235',
          longitude: '-117.164208',
          horizontal_accuracy: 35,
        },
        last_location_at: '2022-10-26T15:59:57.826210Z',
        sound_status: 'SUCCESS',
        sound_status_at: '2022-10-24T15:59:57.829303Z',
      };
    case 'ENABLED_NO_LOCATION':
      return {
        lost_mode_status: 'ENABLED',
        enabled_by: 'Steve Fischer',
        enable_status_at: '2022-10-24T15:59:47.260747Z',
        lock_screen_message: 'message text',
        lock_screen_phone_number: '',
        lock_screen_footnote: 'footnote text',
        disable_status: '',
        disabled_by: '',
        disable_status_at: '',
        last_location_status: 'SUCCESS',
        last_location_status_at: '2022-10-24T15:59:57.829303Z',
        last_location: {
          latitude: '',
          longitude: '',
          approx: null,
        },
        last_location_at: '2022-10-26T15:59:57.826210Z',
        sound_status: '',
        sound_status_at: '',
      };

    case 'PENDING':
      return {
        lost_mode_status: 'PENDING',
        enabled_by: 'Steve Fischer',
        enable_status_at: '2022-10-24T15:59:47.260747Z',
        lock_screen_message: 'message text',
        lock_screen_phone_number: '',
        lock_screen_footnote: 'footnote text',
        disable_status: '',
        disabled_by: '',
        enable_command_id: '123123123123',
        disable_status_at: '',
        last_location_status: '',
        last_location_status_at: '',
        last_location: {},
        last_location_at: '',
        sound_status: '',
        sound_status_at: '',
      };
    case 'ERRORED':
      return {
        lost_mode_status: 'ERRORED',
        enabled_by: 'Steve Fischer',
        enable_status_at: '2022-10-24T15:59:47.260747Z',
        lock_screen_message: 'message text',
        lock_screen_phone_number: '',
        lock_screen_footnote: 'footnote text',
        disabled_by: '',
        disable_status: '',
        disable_status_at: '',
        last_location_status: '',
        last_location_status_at: '',
        last_location: {},
        last_location_at: '',
        sound_status: '',
        sound_status_at: '',
      };
    default:
      return null;
  }
}
