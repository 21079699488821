import { i18n } from 'i18n';
import { ReactSVG } from 'react-svg';
import {
  OptionContent,
  OptionContentDescription,
  OptionContentIcon,
  OptionContentText,
  OptionContentTitle,
  OptionWrapper,
  SectionContent,
  SectionHeading,
  Wrapper,
} from './why-kandji-styles';

import MafnifyingGlassImage from './assets/magnifying-glass.svg';
import RocketLaunchImage from './assets/rocket-launch.svg';
import ShieldCheckImage from './assets/shield-check.svg';

const whyKandjiOptions = [
  {
    image: ShieldCheckImage,
    title: 'Stop threats before they happen',
    description:
      'Leveraging Apple’s Endpoint Security framework, Kandji collects comprehensive metadata on files, enabling in-depth analysis to identify potential malicious activity and take appropriate action.',
  },
  {
    image: RocketLaunchImage,
    title: 'Fastest time to value',
    description:
      'Threat protection is purpose-built for Apple technologies and deployed by a single agent. This drives fast implementations and puts Mac endpoint protection within reach of every team.',
  },
  {
    image: MafnifyingGlassImage,
    title: 'Broadest coverage of threats',
    description:
      'With data from the world’s leading threat feeds and a team of threat researchers feeding the detection engine, our intelligence is among the world’s most comprehensive for Mac.',
  },
];

const WhyKandjiSection = () => {
  return (
    <Wrapper>
      <SectionHeading as="h2" size="2">
        {i18n.t('Why Kandji for Endpoint Detection & Response')}
      </SectionHeading>
      <SectionContent>
        {whyKandjiOptions.map((item) => (
          <OptionWrapper key={item.title}>
            <OptionContent>
              <OptionContentIcon>
                <ReactSVG src={item.image} />
              </OptionContentIcon>
              <OptionContentText>
                <OptionContentTitle>{i18n.t(item.title)}</OptionContentTitle>
                <OptionContentDescription>
                  {i18n.t(item.description)}
                </OptionContentDescription>
              </OptionContentText>
            </OptionContent>
          </OptionWrapper>
        ))}
      </SectionContent>
    </Wrapper>
  );
};

export default WhyKandjiSection;
