import CustomAppsService from './service/custom-apps-service';

const initialState = {
  id: '',
  iconFile: null,
  iconSrc: '',
  name: '',
  isActive: true,
  blueprints: [],
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  type: 'custom-app',
  supportsRules: true,

  installation: {
    type: CustomAppsService.installationTypes.INSTALL_ONCE,
    auditScript: '',
  },
  selfService: {
    isEnabled: false,
    category: '',
    isRecommended: false,
    displayName: null,
    shortDescription: null,
    longDescription: null,
    isRequireRead: false,
    iconUrl: null,
    iconFile: null,
    installBtnText: null,
    displayInfoBtn: false,
    infoBtnText: '',
    infoBtnUrl: '',
  },
  installDetails: {
    type: CustomAppsService.installDetailsType.PACKAGE,
    file: null, // {name, size, sha256, downloadLink, filePath, id}
    unzipLocation: '',
    preInstallScript: '',
    postInstallScript: '',
    isRestart: false,
  },
};

export default initialState;
