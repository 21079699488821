import React, { useState, useEffect } from 'react';

import { number, string } from 'prop-types';

import { Select, setClass } from '@kandji-inc/bumblebee';

import HoverTippy from 'features/util/components/hover-tippy';

import {
  LI_DEVICE_SPECIFIC_OS_VERSION_FACETS,
  LI_OS_VERSION_DEFAULT_OPERATOR,
} from '../../../constants';

import { findOption } from '../../../utilities';

import Text from '../../fields/text';

import { getDeviceFamilyIcon } from '../../icons/helpers';

import { Fields, useBuilder } from '../..';

const LibraryItemOSVersionRule = (props) => {
  const { facet, index, aggregator } = props;

  const {
    facetMap,
    form,
    updateForm,
    supportedDeviceFamilies,
    installOnDeviceFamilies,
  } = useBuilder();

  const [family, setFamily] = useState('');
  const [label, setLabel] = useState('');

  const [isSupported, setIsSupported] = useState(true);
  const [isApplicable, setIsApplicable] = useState(true);

  const [dataType, setDataType] = useState('');

  const [operatorOptions, setOperatorOptions] = useState([]);

  const [valueValidation, setValueValidation] = useState({});

  const {
    name,
    subject,
    data_type,
    device_families,
    supported_operators,
    validation,
  } = facetMap[facet] || {};

  const ruleList = form[aggregator];

  const rule = ruleList[index]?.children?.[facet];

  const updateRuleData = () => {
    const updatedFamily = device_families?.[0];
    const updatedName = name?.split(' ')?.[0];

    setFamily(updatedFamily);
    setLabel(updatedName);

    setIsSupported(supportedDeviceFamilies?.includes(updatedFamily));
    setIsApplicable(installOnDeviceFamilies?.includes(updatedFamily));

    setDataType(data_type);

    // Push the dummy 'is any' operator option to visually represent the selection of no operation
    if (supported_operators) {
      const isAny = {
        label: LI_OS_VERSION_DEFAULT_OPERATOR,
        value: LI_OS_VERSION_DEFAULT_OPERATOR,
      };

      const supportedOperatorOptions = [isAny, ...supported_operators];

      setOperatorOptions(supportedOperatorOptions);
    }

    setValueValidation(validation);
  };

  const updateField = (key, value) => {
    rule[key] = value;

    // Reset 'value' and 'isInvalid' if operator is set to LI_OS_VERSION_DEFAULT_OPERATOR
    if (key === 'operator' && value === LI_OS_VERSION_DEFAULT_OPERATOR) {
      rule.value = '';
      rule.isInvalid = false;
    }

    updateForm(aggregator, ruleList);
  };

  const updateIsInvalid = (isInvalid) => {
    updateField('isInvalid', isInvalid);
  };

  const getValue = (key, options) => {
    const value = rule[key];

    if (options) {
      return findOption(value, options);
    }
    return value;
  };

  const getOperatorValue = () => getValue('operator', operatorOptions);
  const getValueValue = () => getValue('value');

  useEffect(() => {
    updateRuleData();
  }, [facetMap, supportedDeviceFamilies, installOnDeviceFamilies]);

  useEffect(() => {
    if (subject) {
      updateField('subject', subject);
    }
  }, [subject]);

  return isSupported ? (
    <div
      className={setClass(
        'k-custom-os-version-rule',
        `facet-${facet}`,
        isApplicable ? '' : 'not-applicable',
      )}
    >
      <HoverTippy
        className="k-rule-tippy"
        text="This rule will no longer be applied based on the device platforms chosen."
        maxWidth="none"
        disabled={isApplicable}
      >
        <div className="k-rule-field device">
          <div className="k-text-input-with-icon">
            <Text
              fieldKey={label}
              placeholder={label}
              value={label}
              ariaLabel={label}
              disabled={!isApplicable}
              readOnly
            />

            <span
              className={setClass(
                'k-device-icon',
                isApplicable ? 'device-icon' : 'device-icon-disabled',
              )}
            >
              {getDeviceFamilyIcon(family)}
            </span>
          </div>
        </div>

        <div className="k-rule-field operator">
          <Select
            placeholder="Operator"
            options={operatorOptions}
            value={getOperatorValue()}
            onChange={({ value }) => updateField('operator', value)}
            disabled={!isApplicable}
            aria-label="Operator"
          />
        </div>

        {rule?.operator !== LI_OS_VERSION_DEFAULT_OPERATOR && (
          <div className="k-rule-field value">
            <Fields
              fieldKey="value"
              placeholder="Value"
              searchPlaceholder="Search value..."
              type={dataType}
              operator={getOperatorValue()}
              value={getValueValue()}
              updateField={updateField}
              updateIsInvalid={updateIsInvalid}
              validation={valueValidation}
              disabled={!isApplicable}
              ariaLabel="Value"
            />
          </div>
        )}
      </HoverTippy>
    </div>
  ) : null;
};

LibraryItemOSVersionRule.propTypes = {
  facet: string.isRequired,
  index: number.isRequired,
  aggregator: string.isRequired,
};

const LibraryItemCustomOSVersion = ({ ...rest }) => (
  <div className="k-custom-os-version">
    {LI_DEVICE_SPECIFIC_OS_VERSION_FACETS?.map((key) => (
      <LibraryItemOSVersionRule key={key} facet={key} {...rest} />
    ))}
  </div>
);

export default LibraryItemCustomOSVersion;
