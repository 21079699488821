import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import {
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  extractFilterableColumns,
  getFilteredDisabled as filterDisabled,
} from '../column-utils';
import { getGlobalColumns } from './globalColumns';

export const getCertificatesColumns = (schemas) => {
  const globalSchema = schemas.find((schema) => schema.uri === 'globals');
  const certificatesSchema = schemas.find(
    (schema) => schema.uri === 'certificates',
  );

  if (!certificatesSchema || !globalSchema) {
    return { columnDefs: [], filterableColumns: [] };
  }

  const combinedProperties = {
    ...globalSchema.schema.properties,
    ...certificatesSchema.schema.properties,
  };

  const certificatesColumnHelper =
    createColumnHelper<z.infer<typeof combinedProperties>>();

  const globalColumns = getGlobalColumns(
    certificatesColumnHelper,
    combinedProperties,
  );

  const columnDefs = [
    ...globalColumns.startColumnDefs,

    certificatesColumnHelper.accessor((row) => row.common_name, {
      id: 'common_name',
      meta: {
        displayName: combinedProperties.common_name.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    certificatesColumnHelper.accessor((row) => row.identity_certificate, {
      id: 'identity_certificate',
      cell: YesNoCell,
      meta: {
        displayName: combinedProperties.identity_certificate.title,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    // TODO: re-enable once we get certificates data
    // certificatesColumnHelper.accessor((row) => row.cert_data, {
    //   id: 'cert_data',
    //   meta: {
    //     displayName: combinedProperties.cert_data.title,
    //     filterType: 'string',
    //     filterIcon: 'text-size',
    //     filterDisabled,
    //   },
    // }),

    ...globalColumns.endColumnDefs,
  ];

  const filterableColumns = extractFilterableColumns(columnDefs);

  return { columnDefs, filterableColumns };
};
