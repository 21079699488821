import { decodeEnum, encodeString, withDefault } from 'use-query-params';
import { constants } from '../../common';
import type { DetectionTypeSelectorState } from '../../threat.types';

// istanbul ignore next
const DetectionTypeParamConfig = {
  encode: (str: DetectionTypeSelectorState | null | undefined) =>
    encodeString(str),
  decode: (arrayStr: string | (string | null)[] | null | undefined) =>
    decodeEnum(arrayStr, constants.THREAT_DETECTION_TYPE_LIST),
};

function createDetectionTypeParam<T>(defaultValue: T | undefined) {
  return withDefault(DetectionTypeParamConfig, defaultValue);
}

export default createDetectionTypeParam;
