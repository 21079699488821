// istanbul ignore file
import { ThreatService } from '../../../../data-service';
import type {
  FiltersState,
  PaginationState,
  SortColumnState,
} from '../../../../threat.types';
import BehavioralThreatsGroupSchema from '../../../schemas/BehavioralThreatsGroupSchema';
import fromResponseFormat from './fromResponseFormat';
import toRequestFormat from './toRequestFormat';

const threatService = new ThreatService();

const behavioralThreatsGroupQuery = async (
  filters: FiltersState,
  sort: SortColumnState,
  pagination: PaginationState,
) => {
  const result = await threatService.getBehavioralThreatGroup(
    toRequestFormat(filters, sort, pagination),
  );

  if (!BehavioralThreatsGroupSchema.safeParse(result?.data).success) {
    throw new Error('Invalid schema');
  }

  return { ...result, data: fromResponseFormat(result?.data) };
};

export default behavioralThreatsGroupQuery;
