import { Icon } from '@kandji-inc/bumblebee';
import React, { useState } from 'react';

import Modal from './modal';

const SetAssetTag = ({ ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = () => {
    setIsOpen(true);
  };

  return (
    <>
      <button
        type="submit"
        aria-label="set-asset-tag-modal-trigger"
        className="edit-icon"
        onClick={onClick}
      >
        <Icon name="pencil" />
      </button>

      <Modal isOpen={isOpen} setIsOpen={setIsOpen} {...props} />
    </>
  );
};

export default SetAssetTag;
