/* istanbul ignore file */
/*
This is a localized version of react-honey-form 0.0.9, which has been deprecated
It has been copied to this directory so that we can distinguish between OldHoneyForm (this) and new instances

All files have been combined to minimize the amount of exports available from the library, so people don't accidentally
include anything from this package

All exports have been renamed to include "Old" in the name to signify their deprecation
 */

import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

// -----------------------------------------------------------------------------
// constants
// -----------------------------------------------------------------------------

const DEFAULT_VALUE = '';
const DEFAULT_ERROR = null;
const DEFAULT_TOUCHED = false;

const allFieldsUpdateEvent = 'field-update';
const fieldUpdateEvent = (field) => `field-update-${field}`;

// -----------------------------------------------------------------------------
// utils
// -----------------------------------------------------------------------------

const usePersistentData = (initial = {}, defaultValue = undefined) => {
  const ref = useRef({ ...initial }); // shallow copy only
  // access
  const get = (key) => (key in ref.current ? ref.current[key] : defaultValue);
  const dump = () => ref.current;
  const keys = () => Object.keys(ref.current);
  // modify
  const set = (key, value) => (ref.current[key] = value);
  const remove = (key) => delete ref.current[key];
  const apply = (key, fn) => set(key, fn(get(key)));
  const filter = (key, filter) => apply(key, (vals) => vals.filter(filter));
  const map = (key, map) => apply(key, (vals) => vals.map(map));
  return { get, dump, keys, set, remove, apply, filter, map };
};

const makeArray = (obj) => {
  return Array.isArray(obj) ? obj : [obj];
};

const unique = (...arrays) => {
  return Array.from(new Set([].concat(...arrays)));
};

// -----------------------------------------------------------------------------
// events
// -----------------------------------------------------------------------------

const useFieldUpdateAnnouncer = () => {
  const { announce } = useEventsContext();
  return (field) => {
    announce(fieldUpdateEvent(field));
    announce(allFieldsUpdateEvent); // generic field update event
  };
};

const useFieldUpdateListener = (fieldOrFields) => {
  const { listen, ignore } = useEventsContext();
  const [, render] = useState({});

  useEffect(() => {
    const events = makeArray(fieldOrFields).map((f) => fieldUpdateEvent(f));
    const eventHandler = () => render({});
    events.forEach((e) => listen(e, eventHandler));
    return () => events.forEach((e) => ignore(e, eventHandler));
  }, [fieldOrFields, listen, ignore]);
};

const useAllFieldsUpdateListener = () => {
  const { listen, ignore } = useEventsContext();
  const [, render] = useState({});

  useEffect(() => {
    const eventHandler = () => render({});
    listen(allFieldsUpdateEvent, eventHandler);
    return () => ignore(allFieldsUpdateEvent, eventHandler);
  }, [listen, ignore]);
};

// -----------------------------------------------------------------------------
// event context
// -----------------------------------------------------------------------------

const EventContext = createContext({
  announce: (event, value) => {},
  listen: (event, cb) => {},
  ignore: (event, cb) => {},
});

const EventProvider = ({ children }) => {
  const subs = usePersistentData({}, []);

  const context = {
    announce: (event, value) => subs.get(event).forEach((cb) => cb(value)),
    listen: (event, cb) => subs.set(event, [...subs.get(event), cb]),
    ignore: (event, cb) => subs.filter(event, (cb_) => cb_ !== cb),
  };

  return (
    <EventContext.Provider value={context}>{children}</EventContext.Provider>
  );
};

const useEventsContext = () => useContext(EventContext);

// -----------------------------------------------------------------------------
// form context
// -----------------------------------------------------------------------------

const FormContext = createContext({
  handleReset: () => {},
  handleSubmit: (values) => {},
});

const FormProvider = ({
  formValidation = () => {},
  onSubmit = console.log,
  onFormErrors = console.log,
  children,
}) => {
  const {
    getValues,
    getFieldValidationErrors,
    resetAllFields,
    resetAllErrors,
  } = useAllFieldsContext();

  const context = {
    handleReset: resetAllFields,
    handleSubmit: () => {
      const values = getValues();
      const fieldErrors = getFieldValidationErrors();
      const formErrors = formValidation(values);
      // favor field (input) error messages
      const errors = { ...formErrors, ...fieldErrors };
      resetAllErrors(errors);
      const hasErrors = Object.keys(errors).length > 0;
      hasErrors ? onFormErrors(errors) : onSubmit(values);
    },
  };

  return (
    <FormContext.Provider value={context}>{children}</FormContext.Provider>
  );
};

const useFormContext = () => useContext(FormContext);

// -----------------------------------------------------------------------------
// field context
// -----------------------------------------------------------------------------

const FieldContext = createContext({
  // single field methods
  getFieldValue: (field) => {},
  setFieldValue: (field, value) => {},
  getFieldError: (field) => {},
  setFieldError: (field, error) => {},
  getFieldTouched: (field) => {},
  setFieldTouched: (field, isTouched) => {},
  resetField: (field) => {},
  validateField: (field) => {},
  // all field methods
  getValues: () => {},
  getErrors: () => {},
  getTouched: () => {},
  resetAllFields: (newValues) => {},
  resetAllErrors: (newErrors) => {},
  validateAllFields: () => {},
  getFieldValidationErrors: () => {},
});

const FieldProvider = ({
  initialValues = {},
  fieldValidation = {},
  children,
}) => {
  const values = usePersistentData(initialValues, DEFAULT_VALUE);
  const errors = usePersistentData({}, DEFAULT_ERROR);
  const touched = usePersistentData({}, DEFAULT_TOUCHED);
  const additionalFieldValidation = useRef({});

  const announceFieldUpdate = useFieldUpdateAnnouncer();

  // ---------------------------------------------------------------------------
  // single field methods
  // ---------------------------------------------------------------------------

  const setFieldValue = (field, value) => {
    if (value !== values.get(field)) {
      // remove if undefined (unset)
      if (value === undefined) {
        values.remove(field);
      } else {
        values.set(field, value);
        touched.get(field) && validateField(field);
      }
      announceFieldUpdate(field);
    }
  };

  const setFieldError = (field, error) => {
    if (error !== errors.get(field)) {
      // remove if undefined or falsy
      const removed = error === undefined || !error;
      removed ? errors.remove(field) : errors.set(field, error);
      announceFieldUpdate(field);
    }
  };

  const setFieldTouched = (field, isTouched) => {
    if (isTouched !== touched.get(field)) {
      // remove if undefined or falsy
      if (isTouched === undefined || !isTouched) {
        touched.remove(field);
      } else {
        touched.set(field, isTouched);
        validateField(field);
      }
      announceFieldUpdate(field);
    }
  };

  const resetField = (field) => {
    // remove touched first so we don't validate value changes
    setFieldTouched(field, undefined);
    setFieldError(field, undefined);
    const value = field in initialValues ? initialValues[field] : undefined;
    setFieldValue(field, value);
  };

  const validateField = (field) => {
    const error = _getFieldValidationError(field);
    setFieldError(field, error);
  };

  const singleFieldMethods = {
    getFieldValue: values.get,
    setFieldValue,
    getFieldError: errors.get,
    setFieldError,
    getFieldTouched: touched.get,
    setFieldTouched,
    resetField,
    validateField,
    addFieldValidation: (field, fn) => {
      const previousRules = additionalFieldValidation.current[field] || [];
      additionalFieldValidation.current[field] = [...previousRules, fn];
    },
    removeFieldValidation: (field, fn) => {
      const previousRules = additionalFieldValidation.current[field] || [];
      const index = previousRules.indexOf(fn);
      if (index !== -1) {
        previousRules.splice(index, 1);
        additionalFieldValidation.current[field] = previousRules;
      }
    },
  };

  // ---------------------------------------------------------------------------
  // all field methods
  // ---------------------------------------------------------------------------

  const resetAllFields = () => {
    const allKnownKeys = unique(
      values.keys(),
      errors.keys(),
      touched.keys(),
      Object.keys(initialValues),
    );
    allKnownKeys.forEach(resetField);
  };

  const resetAllErrors = (newErrors) => {
    unique(errors.keys(), Object.keys(newErrors)).forEach((field) => {
      const error = field in newErrors ? newErrors[field] : undefined;
      setFieldError(field, error);
    });
  };

  const validateAllFields = () => {
    Object.keys(fieldValidation).forEach(validateField);
  };

  const getFieldValidationErrors = () => {
    const reducer = (errors, field) => {
      const error = _getFieldValidationError(field);
      if (error !== DEFAULT_ERROR) {
        errors[field] = error;
      }
      return errors;
    };
    return Object.keys(fieldValidation).reduce(reducer, {});
  };

  const allFieldMethods = {
    getValues: values.dump,
    getErrors: errors.dump,
    getTouched: touched.dump,
    resetAllFields,
    resetAllErrors,
    validateAllFields,
    getFieldValidationErrors,
  };

  // ---------------------------------------------------------------------------
  // validation helpers
  // ---------------------------------------------------------------------------

  const _getFieldValidationError = (field) => {
    const validation = [
      ...makeArray(fieldValidation[field] || []),
      ...(additionalFieldValidation.current[field] || []),
    ];
    const value = values.get(field);
    const allFields = values.dump();

    // find any truthy error
    for (const validationFn of makeArray(validation)) {
      const error = validationFn(value, { allFields });
      if (error) {
        return error;
      }
    }

    return DEFAULT_ERROR;
  };

  // ---------------------------------------------------------------------------
  // context
  // ---------------------------------------------------------------------------

  const context = {
    ...singleFieldMethods,
    ...allFieldMethods,
  };

  return (
    <FieldContext.Provider value={context}>{children}</FieldContext.Provider>
  );
};

const useAllFieldsContext = () => useContext(FieldContext);

const useFieldContext = (field) => {
  const allFields = useAllFieldsContext();
  return {
    getValue: () => allFields.getFieldValue(field),
    setValue: (value) => allFields.setFieldValue(field, value),
    getError: () => allFields.getFieldError(field),
    setError: (error) => allFields.setFieldError(field, error),
    getTouched: () => allFields.getFieldTouched(field),
    setTouched: (touched) => allFields.setFieldTouched(field, touched),
    reset: () => allFields.resetField(field),
    validate: () => allFields.validateField(field),
    addValidation: (fn) => allFields.addFieldValidation(field, fn),
    removeValidation: (fn) => allFields.removeFieldValidation(field, fn),
  };
};

// -----------------------------------------------------------------------------
// form
// -----------------------------------------------------------------------------

const OldHoneyForm = ({
  initialValues,
  fieldValidation,
  formValidation,
  onSubmit,
  onFormErrors,
  children,
}) => (
  <EventProvider>
    <FieldProvider
      initialValues={initialValues}
      fieldValidation={fieldValidation}
    >
      <FormProvider
        formValidation={formValidation}
        onSubmit={onSubmit}
        onFormErrors={onFormErrors}
      >
        {children}
      </FormProvider>
    </FieldProvider>
  </EventProvider>
);

export default OldHoneyForm;

// -----------------------------------------------------------------------------
// context helpers
// -----------------------------------------------------------------------------

export const useOldForm = useFormContext;

export const useOldAllFields = () => {
  useAllFieldsUpdateListener();
  return useAllFieldsContext();
};

export const useOldField = (field) => {
  useFieldUpdateListener(field);
  return useFieldContext(field);
};

// -----------------------------------------------------------------------------
// elements
// -----------------------------------------------------------------------------

export const useValidation = (name, validation) => {
  const field = useOldField(name);
  useEffect(() => {
    if (!validation) return;

    field.addValidation(validation);

    return () => field.removeValidation(validation);
  }, [validation]);
};

const useInput = (field) => {
  const { getValue, setValue, setTouched } = useOldField(field);
  return {
    name: field,
    onBlur: () => setTouched(true),
    onChange: (e) => setValue(e.target.value),
    value: getValue(),
  };
};

export const OldInputElement = React.forwardRef((props, ref) => {
  const { name, validation, ...otherProps } = props;
  useValidation(name, validation);

  return <input ref={ref} type="text" {...useInput(name)} {...otherProps} />;
});

export const OldTextareaElement = React.forwardRef((props, ref) => {
  const { name, validation, ...otherProps } = props;
  useValidation(name, validation);

  return <textarea ref={ref} {...useInput(name)} {...otherProps} />;
});

const useRadio = (field, value) => {
  const { getValue, setValue, setTouched } = useOldField(field);
  return {
    name: field,
    checked: value === getValue(),
    onBlur: () => setTouched(true),
    onChange: () => {
      setTouched(true);
      setValue(value);
    },
    value,
  };
};

export const OldRadioElement = React.forwardRef((props, ref) => {
  const { name, value, validation, ...otherProps } = props;
  useValidation(name, validation);

  return (
    <input ref={ref} type="radio" {...useRadio(name, value)} {...otherProps} />
  );
});
