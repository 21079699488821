import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class AirplaySecurityService extends NewLibraryItemService {
  static accessTypes = {
    ANY: 'ANY',
    WIFI_ONLY: 'WIFI_ONLY',
  };

  static securityTypes = {
    PASSCODE_ONCE: 'PASSCODE_ONCE',
    PASSCODE_ALWAYS: 'PASSCODE_ALWAYS',
    PASSWORD: 'PASSWORD',
  };
}

export const airplaySecurityService = new AirplaySecurityService();
export default AirplaySecurityService;
