import { Button, Flex, styled } from '@kandji-inc/nectar-ui';
import { libraryRoute } from 'features/library-items/library/common';
import useAddBodyClass from 'features/util/hooks/use-add-body-class';
import { useHistory } from 'react-router-dom';
import ThreatEmptyIcon from '../../assets/threat-empty-unnassigned.png';
import useGetAssignedBlueprint from '../hooks/use-get-assigned-blueprint';
import ThreatListEmptyTable from './ThreatListEmptyTable';

const Container = styled(Flex, {
  position: 'fixed',
  height: 'calc(100% - 70px)',
  width: 'calc(100% - 256px)',
  minHeight: 570,
  backgroundColor: 'white',
  zIndex: 10,
  paddingBottom: 70,

  '.full-width-sidebar-closed &': {
    width: 'calc(100% - 56px)',
  },
});

const ThreatListUnassignedEmptyState = () => {
  const history = useHistory();
  const { isAssigned: isAvertAssignedToBlueprint, isLoading } =
    useGetAssignedBlueprint();
  useAddBodyClass('no-scroll', !isAvertAssignedToBlueprint);

  if (isLoading || isAvertAssignedToBlueprint) {
    return null;
  }

  return (
    <Container>
      <ThreatListEmptyTable
        icon={ThreatEmptyIcon}
        iconWidth={88}
        header="Get started with Kandji EDR"
        body={
          <>
            As threat actors pay increasing attention to Mac, Kandji EDR—built
            exclusively for Mac—can help minimize the risk exposure across your
            fleet.
            <br />
            <br />
            Assign a new or existing Avert Library Item to one or more
            Blueprints to start detecting and protecting against threats.
            <br />
            <br />
            <a
              href="https://support.kandji.io/support/solutions/articles/72000600371-getting-started-with-endpoint-detection-response-edr-"
              rel="noopener noreferrer"
              target="_blank"
              className="b-alink"
            >
              Learn more
            </a>
          </>
        }
        slot={
          <Flex gap="md">
            <Button
              variant="primary"
              onClick={() => history.push(libraryRoute.library)}
            >
              Go to Library
            </Button>
          </Flex>
        }
      />
    </Container>
  );
};

export default ThreatListUnassignedEmptyState;
