/* istanbul ignore file */
import { i18n } from 'src/i18n';
import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class ScepService extends NewLibraryItemService {
  static SubjectAltNames = () => [
    { value: 'None', label: i18n.t('None') },
    { value: 'RFC 822 Name', label: i18n.t('RFC 822 Name') },
    { value: 'DNS Name', label: i18n.t('DNS Name') },
    {
      value: 'Uniform Resource Identifier',
      label: i18n.t('Uniform Resource Identifier'),
    },
  ];

  static KeySizes = () => [
    { value: '1024', label: i18n.t('1024') },
    { value: '2048', label: i18n.t('2048') },
    { value: '4096', label: i18n.t('4096') },
  ];

  static KeyUsages = () => [
    { value: '0', label: i18n.t('None') },
    { value: '1', label: i18n.t('Signing') },
    { value: '4', label: i18n.t('Encryption') },
    { value: '5', label: i18n.t('Both signing and encryption') },
  ];
}

export const scepService = new ScepService();

export default ScepService;
