import { Button, Checkbox, Flex, styled } from '@kandji-inc/nectar-ui';

const CheckButton = styled(Button, {
  width: 36,
  height: 36,
  '&:not(:disabled)': {
    backgroundColor: '$transparent',
    '&:focus, &:hover, &:active': {
      backgroundColor: '$transparent',
      boxShadow: 'none',
    },
  },
});

type ThreatListTableCheckboxProps = Readonly<{
  selected: boolean;
  onToggle: (isSelected: boolean) => void;
  onHoverToggle?: (isOn: boolean) => void;
  disabled?: boolean;
  compact?: boolean;
  testId?: string;
}>;

const ThreatListTableCheckbox = (props: ThreatListTableCheckboxProps) => {
  const {
    selected,
    onToggle,
    onHoverToggle = () => null,
    testId,
    disabled = false,
  } = props;

  const handleToggle = (e) => {
    e.stopPropagation();
    onToggle(!selected);
  };

  return (
    <Flex alignItems="center" px3>
      <CheckButton
        variant="subtle"
        onClick={handleToggle}
        onMouseEnter={
          // istanbul ignore next
          () => onHoverToggle(true)
        }
        onMouseLeave={
          // istanbul ignore next
          () => onHoverToggle(false)
        }
      >
        <Checkbox
          checked={selected}
          onClick={handleToggle}
          data-testid={testId}
          disabled={disabled}
        />
      </CheckButton>
    </Flex>
  );
};

export default ThreatListTableCheckbox;
