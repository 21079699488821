/**
 * Handles state changes in the `useAuth0` hook.
 */
export const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISED':
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        user: action.user,
        isLoading: false,
        error: undefined,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: undefined,
      };
    case 'ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case 'REDIRECT_IN_PROGRESS':
      return {
        ...state,
        redirectInProgress: true,
      };
    default:
      return state;
  }
};
