import { colors } from 'app/common/constants';
import { truncate } from 'app/common/style-utils';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { dataTestId } from '../common/utils';

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  border-bottom: 2px solid ${colors['grey-250']};
  ${(props) =>
    props.disabled
      ? ''
      : `&:before {
    position: absolute;
    right: 0;
    bottom: 0;
    font-family: 'SSJunior', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    text-decoration: none;
    text-rendering: optimizeLegibility;
    white-space: nowrap;
    font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    content: '✎';
    color: ${colors['grey-400']};
  }`}
`;

const Input = styled.input`
  position: relative;
  ${truncate('calc(100% - 2rem)')};
  z-index: 2;
  border: none;
  width: calc(100% - 2rem);
  text-align: ${(props) => props.textAlign};
  font-size: 16px;
  font-weight: 500;
  padding-left: 2rem;
  transition: 0.3s ease;
  background-color: ${(props) =>
    props.transparentBackground ? 'transparent' : 'white'};
  &:focus {
    outline: none;
  }
`;

const ModalInput = styled(Input)`
  &::placeholder {
    color: ${colors['marengo-700']};
  }
`;

const Label = styled.label`
  color: ${colors.grey400};
  letter-spacing: 0.2em;
  transition: all 0.15s ease;
  transform-origin: 0 0 0;
  font-weight: 500;
  line-height: 30px;
  text-transform: uppercase;
  width: 100%;
  -webkit-font-smoothing: none;

  color: ${colors.grey900};
  font-size: 13px;
  text-align: left;
`;

const inputMapping = {
  regular: Input,
  modal: ModalInput,
};

const AwesomeInput = ({
  type,
  onChange,
  name,
  placeholder,
  maxLength,
  autoComplete,
  value,
  innerRef,
  label,
  textAlign,
  variant,
  disabled,
}) => {
  const ThemedInput = inputMapping[variant];
  return (
    <Wrapper disabled={disabled}>
      {label && <Label>{label}</Label>}
      <ThemedInput
        type={type}
        onChange={onChange}
        name={name}
        placeholder={!label ? placeholder : null}
        maxLength={maxLength}
        autoComplete={autoComplete}
        value={value}
        ref={innerRef}
        transparentBackground={!!label}
        textAlign={textAlign}
        disabled={disabled}
        data-testid={dataTestId(placeholder || 'input')}
      />
    </Wrapper>
  );
};

AwesomeInput.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.string,
  autoComplete: PropTypes.string,
  innerRef: PropTypes.func,
  label: PropTypes.string,
  textAlign: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
};

AwesomeInput.defaultProps = {
  onChange: null,
  type: 'text',
  name: 'name',
  placeholder: 'Input text ...',
  maxLength: '100',
  autoComplete: 'off',
  innerRef: null,
  label: null,
  textAlign: 'center',
  variant: 'regular',
  disabled: false,
};

export default AwesomeInput;
