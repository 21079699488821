import type { ReactNode } from 'react';
import React, { createContext } from 'react';
import useOktaController from './use-okta-controller';

type AppStoreAppsProviderProps = {
  children: ReactNode;
};

type AppStoreAppsContextType = {
  isAppConfigDisabled: boolean;
  setIsAppConfigDisabled: (value: boolean) => void;
};

const AppStoreAppsContext = createContext<AppStoreAppsContextType | null>(null);

const AppStoreAppsProvider = ({ children }: AppStoreAppsProviderProps) => {
  const { isAppConfigDisabled, setIsAppConfigDisabled } = useOktaController();
  const value = {
    isAppConfigDisabled,
    setIsAppConfigDisabled,
  };

  return (
    <AppStoreAppsContext.Provider value={value}>
      {children}
    </AppStoreAppsContext.Provider>
  );
};

const useAppStoreAppsContext = () => {
  const context = React.useContext(AppStoreAppsContext);
  if (!context) {
    throw new Error(
      'useAppStoreAppsContext must be used within a AppStoreAppsProvider',
    );
  }
  return context;
};

export { useAppStoreAppsContext };
export default AppStoreAppsProvider;
