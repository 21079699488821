import configs from 'features/library-items/library/library-item-configurations/items/profile';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import {
  getRunsOnFromModel,
  getSelectedDevicesFromRunsOn,
} from 'src/features/library-items/data-service/library-item/devices';
import uuidv4 from 'uuid/v4';
import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import CertificateService from './certificate-service';
import { getDownloadLink } from './utils';

const base64ToFile = (base64, filename, type = 'application/octet-stream') =>
  fetch(`data:${type};base64,${base64}`)
    .then((res) => res.arrayBuffer())
    .then(
      (buf) => new File([buf], filename, { type: 'application/octet-stream' }),
    );

const sanWindowsToNormal = Object.keys(
  CertificateService.subjectAlternativeNameTypesWindows,
).reduce((ret, key) => {
  ret[CertificateService.subjectAlternativeNameTypesWindows[key]] = key;
  return ret;
}, {});

const transformApiPayload = async (payload) => ({
  certificate: {
    type: payload.type,
    name: payload.display_name,
    subject: payload.subject,
    template: payload.template,
    server: payload.authority,
    isSanSpecified: !!payload.san,
    san: payload.san?.map((sanElem) => ({
      ...sanElem,
      // Only ADCS as of now, but if we add SCEP or something else that
      // has SAN, this type transformation must be conditionally applied
      // for ADCS only.
      type: sanWindowsToNormal[sanElem.type] || sanElem.type,
      _id: uuidv4(),
    })) || [CertificateService.createSan()],
    keySize: payload.key_size || CertificateService.keySizes.S_1024,
    isAccessByAllApps: !!payload.AllowAllAppsAccess,
    isKeychainExport: !!payload.KeyIsExtractable,
    password: payload.Password,
    file: payload.data
      ? {
          base64: payload.data,
          name: payload.file_name,
          size: payload.file_size,
          file: await base64ToFile(payload.data, payload.file_name),
          downloadLink: await getDownloadLink({
            type: 'application/octet-stream',
            base64: payload.data,
          }),
          updated_at: payload.updated_at,
        }
      : null,
  },
});

const transformFromNewApi = async (apiData, base) => {
  const result = {
    ...base,
    isActive: apiData.active,
    rules: apiData.rules,
    ...(await transformApiPayload(apiData.data)),
  };

  return result;
};

export const transformFromApi = async (apiData) => {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const common = {
    id: apiData.id,
    name: apiData.name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
    devices: getSelectedDevicesFromRunsOn(apiData),
    rules: apiData.rules,
  };

  const transformer = transformFromNewApi;

  const result = await transformer(apiData, common);

  return {
    ...apiData,
    data: result,
  };
};

const transformToNewApi = (model) => {
  const allFields = {
    AllowAllAppsAccess: model.certificate.isAccessByAllApps,
    KeyIsExtractable: model.certificate.isKeychainExport,
    Password: model.certificate.password,
    data: model.certificate.file?.base64 || undefined,
    file_name: model.certificate.file?.name,
    file_size: model.certificate.file?.size,
    updated_at: model.certificate.file?.updated_at,
    subject: model.certificate.subject,
    template: model.certificate.template,
    authority: model.certificate.server,
    key_size: model.certificate.keySize,
    san: model.certificate.isSanSpecified
      ? model.certificate.san.map((sanItem) => {
          const u = omit(sanItem, ['_id']);
          return {
            ...u,
            // Only ADCS as of now, but if we add SCEP or something else that
            // has SAN, this type transformation must be conditionally applied
            // for ADCS only.
            type: CertificateService.subjectAlternativeNameTypesWindows[
              sanItem.type
            ],
          };
        })
      : null,
  };

  const fieldsByType = {
    [CertificateService.certificateTypes.AD_CS]: [
      'AllowAllAppsAccess',
      'KeyIsExtractable',
      'subject',
      'template',
      'authority',
      'key_size',
      'san',
    ],
    [CertificateService.certificateTypes.PKCS_1]: [
      'data',
      'file_name',
      'file_size',
      'updated_at',
    ],
    [CertificateService.certificateTypes.PKCS_12]: [
      'data',
      'file_name',
      'file_size',
      'updated_at',
      'Password',
      'AllowAllAppsAccess',
      'KeyIsExtractable',
    ],
  };

  const toSend = {
    name: model.name,
    active: model.isActive,
    blueprints: model.selectedBlueprints.map(({ value }) => value),
    is_all_blueprints: model.isAllBlueprints,
    data: {
      type: model.certificate.type,
      display_name: model.certificate.name || '',
      ...pick(allFields, fieldsByType[model.certificate.type]),
    },
    rules: model.rules || null,
  };

  // Populate `runs_on_*` fields
  const runsOn = getRunsOnFromModel(model);
  Object.keys(runsOn).forEach((runsOnKey) => {
    toSend[runsOnKey] = runsOn[runsOnKey];
  });

  if (!model.id) {
    const itemConfig = configs.Certificate;

    toSend.type = itemConfig.type;
    toSend.identifier = itemConfig.identifier;
  }

  return toSend;
};

export const transformToApi = (model) => transformToNewApi(model);
