import PropTypes from 'prop-types';
import React from 'react';
import './ToggleButton.styles.scss';

const ToggleButton = ({ onToggle, disabled, checked, extraClass, style }) => (
  <div className="ToggleButtonWrapper" style={style}>
    <input
      className={`ToggleButtonInput ${extraClass}`}
      type="checkbox"
      onChange={onToggle}
      disabled={disabled}
      checked={checked}
    />
  </div>
);

ToggleButton.propTypes = {
  onToggle: PropTypes.func,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  extraClass: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
};

ToggleButton.defaultProps = {
  onToggle: () => {},
  disabled: false,
  checked: false,
  extraClass: '',
  style: {},
};

export default ToggleButton;
