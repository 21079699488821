export function deviceFamilyIdToName(id: string) {
  switch (id) {
    case 'iphone':
      return 'iPhone';
    case 'ipad':
      return 'iPad';
    case 'mac':
      return 'Mac';
    case 'AppleTV':
      return 'Apple TV';
    default:
      return '';
  }
}
