import { Chip, Toggle, setClass } from '@kandji-inc/bumblebee';
import { bool, func, object, shape, string } from 'prop-types';
import React from 'react';

const HeaderToggle = (props: any) => {
  const {
    style,
    className,
    isDisabled,
    isEnabled,
    onChange,
    chipText,
    isHideChip,
    // component: Component,
  } = props;

  // if (Component) {
  //   return <Component {...props} />;
  // }

  return (
    <div style={style} className={setClass(className, 'b-flex')}>
      {!isHideChip ? (
        <Chip
          className={setClass([
            'b-txt-ctrl8',
            isDisabled ? 'b-chip-active-md--disabled' : '',
          ])}
          disabled={isDisabled}
          kind={isEnabled ? 'active' : 'inactive'}
          text={
            isEnabled
              ? chipText?.enabled || 'Enabled'
              : chipText?.disabled || 'Disabled'
          }
        />
      ) : null}{' '}
      <Toggle
        disabled={isDisabled}
        style={{ marginLeft: 'var(--b-gap-tiny)' }}
        checked={isEnabled}
        onToggle={() => onChange({ isDisabled, isEnabled })}
      />
    </div>
  );
};

HeaderToggle.propTypes = {
  style: object,
  className: string,
  isDisabled: bool,
  isEnabled: bool,
  onChange: func,
  chipText: shape({
    enabled: string,
    disabled: string,
  }),
  isHideChip: bool,
};

HeaderToggle.defaultProps = {
  style: {},
  className: null,
  isDisabled: null,
  isEnabled: null,
  onChange: () => {},
  chipText: {
    enabled: 'Enabled',
    disabled: 'Disabled',
  },
  isHideChip: false,
};

export default HeaderToggle;
