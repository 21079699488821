/* istanbul ignore file */
import React from 'react';

export const AppleTV = () => (
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.77951 9.72774H12.2218C13.1635 9.72774 13.6673 9.22821 13.6673 8.28221V2.24005C13.6673 1.29835 13.1635 0.798828 12.2218 0.798828H1.77951C0.83781 0.798828 0.333984 1.29835 0.333984 2.24005V8.28221C0.333984 9.22821 0.83781 9.72774 1.77951 9.72774ZM1.83864 8.72512C1.50395 8.72512 1.3366 8.56154 1.3366 8.22737V2.29919C1.3366 1.96072 1.50395 1.80144 1.83864 1.80144H12.1617C12.4964 1.80144 12.6638 1.96072 12.6638 2.29919V8.22737C12.6638 8.56154 12.4964 8.72512 12.1617 8.72512H1.83864ZM4.31826 11.5616H9.6821C9.94298 11.5616 10.1587 11.3465 10.1587 11.087C10.1587 10.8275 9.94298 10.6113 9.6821 10.6113H4.31826C4.05832 10.6113 3.84264 10.8275 3.84264 11.087C3.84264 11.3465 4.05832 11.5616 4.31826 11.5616Z" />
  </svg>
);

export const IPad = () => (
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.16907 11.2154H11.8342C13.0449 11.2154 13.6673 10.592 13.6673 9.39867V2.5999C13.6673 1.40654 13.0449 0.785156 11.8342 0.785156H2.16706C0.960952 0.785156 0.333984 1.40195 0.333984 2.5999V9.39867C0.333984 10.5966 0.961958 11.2154 2.16907 11.2154ZM2.23255 10.1206C1.71197 10.1206 1.42978 9.85163 1.42978 9.3087V2.69087C1.42978 2.14794 1.71096 1.87994 2.23054 1.87994H11.7708C12.2857 1.87994 12.5725 2.14794 12.5725 2.69087V9.3087C12.5725 9.85163 12.2857 10.1206 11.7708 10.1206H2.23255ZM4.83867 9.67527H9.16822C9.32714 9.67527 9.44136 9.56866 9.44136 9.40213C9.44136 9.23561 9.32714 9.12341 9.16822 9.12341H4.83867C4.67975 9.12341 4.56195 9.23561 4.56195 9.40213C4.56195 9.56866 4.67975 9.67527 4.83867 9.67527Z" />
  </svg>
);

export const IPhone = () => (
  <svg
    width="10"
    height="14"
    viewBox="0 0 10 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.62056 13.6586H7.40501C8.43909 13.6586 9.13013 12.9859 9.13013 11.9791V2.02125C9.13013 1.01336 8.43909 0.341797 7.40501 0.341797H2.62056C1.57114 0.341797 0.869141 1.01336 0.869141 2.02015V11.9802C0.869141 12.9859 1.57114 13.6586 2.62056 13.6586ZM2.82513 12.4318C2.33829 12.4318 2.06199 12.1698 2.06199 11.7058V2.29462C2.06199 1.83055 2.33829 1.56862 2.82513 1.56862H7.18025C7.661 1.56862 7.9373 1.83274 7.9373 2.29571V11.7047C7.9373 12.1676 7.661 12.4318 7.18025 12.4318H2.82513ZM3.69056 11.9577H6.31873C6.49627 11.9577 6.62181 11.8344 6.62181 11.6496C6.62181 11.4638 6.49627 11.3443 6.31873 11.3443H3.69056C3.51302 11.3443 3.38138 11.4638 3.38138 11.6496C3.38138 11.8344 3.51302 11.9577 3.69056 11.9577ZM4.23694 2.99466H5.76735C6.02891 2.99466 6.2319 2.79167 6.2319 2.52513C6.2319 2.26356 6.02782 2.05948 5.76735 2.05948H4.23694C3.97148 2.05948 3.76739 2.26356 3.76739 2.52513C3.76739 2.79167 3.97039 2.99466 4.23694 2.99466Z" />
  </svg>
);

export const Mac = () => (
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.70324 9.68631H12.2936C13.1518 9.68631 13.6673 9.17082 13.6673 8.31255V1.69444C13.6673 0.837156 13.1518 0.326172 12.2936 0.326172H1.70324C0.849473 0.326172 0.333984 0.837156 0.333984 1.69444V8.31255C0.333984 9.17082 0.849473 9.68631 1.70324 9.68631ZM1.61776 7.14313C1.45791 7.14313 1.38748 7.0837 1.38748 6.91285V1.75937C1.38748 1.52107 1.53437 1.37868 1.76718 1.37868H12.2341C12.4669 1.37868 12.6138 1.52107 12.6138 1.75937V6.91285C12.6138 7.0837 12.5379 7.14313 12.379 7.14313H1.61776ZM5.2094 10.9423H8.79191V9.57436H5.2094V10.9423ZM5.18237 11.672H8.81893C9.09228 11.672 9.31377 11.4456 9.31377 11.1737C9.31377 10.9018 9.09228 10.6763 8.81893 10.6763H5.18237C4.90902 10.6763 4.68303 10.9018 4.68303 11.1737C4.68303 11.4456 4.90902 11.672 5.18237 11.672Z" />
  </svg>
);
