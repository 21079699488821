import { Button } from '@kandji-inc/bumblebee';
import { i18n } from 'i18n';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import {
  archiveUsers as callArchiveUsers,
  getUsersCounts as callGetUsersCount,
} from '../../../_actions/gSuite';
import { setSnackbar as callSetSnackbar } from '../../../_actions/ui';
import { LineLoader } from '../LineLoader';
import { ModalWrapper } from './ModalWrapper';
import { ButtonsWrapper, CancelButtonWrapper } from './elements';

const BodyText = styled.p`
  font-family: var(--font-family-primary);
  font-weight: 400;
`;

const BodyTextBold = styled(BodyText)`
  font-weight: 500;
`;

export class ArchiveGSuiteUsers extends ModalWrapper {
  constructor(props) {
    super(props);
    this.modalName = 'ArchiveGSuiteUsers';
    this.state = {
      isLoading: false,
    };
  }

  onArchive = () => {
    const { archiveUsers, setSnackbar, getUsersCounts } = this.props;
    const { selectedRows, onArchive } = this.props.info;
    const data = [...selectedRows].map((id) => ({ id, is_archived: true }));
    const fields = [
      'archived_g_suite_users',
      'device_g_suite_users',
      'without_devices_g_suite_users',
    ];
    // istanbul ignore next
    const archiveUserMessageTense =
      selectedRows.size === 1 ? i18n.t('User was') : i18n.t('Users were');
    this.setState(
      {
        isLoading: true,
      },
      () => {
        archiveUsers(data)
          .then(() => {
            getUsersCounts(fields);
          })
          .then(() => {
            setSnackbar(
              i18n.t('{archiveUserMessageTense} archived successfully', {
                archiveUserMessageTense,
              }),
            );
            onArchive();
            this.onHide();
          })
          .catch((err) => {
            setSnackbar(err.message);
          })
          .finally(() => this.setState({ isLoading: false }));
      },
    );
  };

  // istanbul ignore next
  renderHeader = () => i18n.t('Confirm Action');

  renderBody() {
    const { isLoading } = this.state;
    if (isLoading) {
      return <LineLoader />;
    }
    return (
      <>
        <BodyTextBold>
          {i18n.t('Are you sure you want to archive chosen users?')}
        </BodyTextBold>
        <BodyText>
          {i18n.t(
            'Only visible (non-archived) Device Users can have assigned devices.',
          )}
        </BodyText>
      </>
    );
  }

  renderFooter() {
    const { isLoading } = this.state;
    return (
      <ButtonsWrapper>
        <CancelButtonWrapper>
          <Button type="button" onClick={this.onHide} kind="outline">
            {i18n.t('Cancel')}
          </Button>
        </CancelButtonWrapper>
        <Button disabled={isLoading} type="button" onClick={this.onArchive}>
          {i18n.t('Confirm')}
        </Button>
      </ButtonsWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      archiveUsers: callArchiveUsers,
      setSnackbar: callSetSnackbar,
      getUsersCounts: callGetUsersCount,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(ArchiveGSuiteUsers);
