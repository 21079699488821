/* istanbul ignore file */
import configs from 'features/library-items/library/library-item-configurations/items';
import { getRunsOnFromModel } from 'src/features/library-items/data-service/library-item/devices';
import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import { getSelectedDevicesFromRunsOn } from '../../../data-service/library-item/devices';
import {
  transformMacSkipItemsFromApi,
  transformSkipItemsFromApi,
  transformSkipItemsToApi,
} from '../../auto-enroll/service/transformers';
import {
  IPAD_SKIP_SCREENS,
  IPHONE_SKIP_SCREENS,
  MAC_SKIP_SCREENS,
  screensById,
} from '../../auto-enroll/skip-screens/skip-screen-constants';
import type {
  SetupAssistantModel,
  SetupAssistantModelSettings,
} from '../setup-assistant.types';
import type {
  SetupAssistantApiPayloadSettings,
  SetupAssistantApiRequestPayload,
  SetupAssistantApiResponsePayload,
} from './transformers.types';

function transformSettings(
  settings: SetupAssistantApiPayloadSettings,
): SetupAssistantModelSettings {
  return {
    ...settings,
    mac: {
      isEnabled: settings.macIsEnabled,
      screens: transformMacSkipItemsFromApi(
        settings.skip_setup_items.MacOS,
        screensById.mac(),
      ),
    },
    iphone: {
      isEnabled: settings.iphoneIsEnabled,
      screens: transformSkipItemsFromApi(
        settings.skip_setup_items.iOS,
        screensById.iPhone(),
      ),
    },
    ipad: {
      isEnabled: settings.ipadIsEnabled,
      screens: transformSkipItemsFromApi(
        settings.skip_setup_items.iPadOS,
        screensById.iPad(),
      ),
    },
  };
}

async function transformFromApi(apiData: SetupAssistantApiResponsePayload) {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const result: SetupAssistantModel = {
    id: apiData.id,
    name: apiData.name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
    isActive: apiData.active,
    devices: getSelectedDevicesFromRunsOn(apiData),
    rules: apiData.rules,
    ...transformSettings(apiData.data),
  };

  return {
    ...apiData,
    data: result,
  };
}

function transformToApi(
  model: SetupAssistantModel,
): SetupAssistantApiRequestPayload {
  const runsOn: any = getRunsOnFromModel(model);

  const toSend: SetupAssistantApiRequestPayload = {
    name: model.name,
    active: model.isActive,
    blueprints: model.selectedBlueprints.map((b) => b.value),
    is_all_blueprints: model.isAllBlueprints,
    data: {
      macIsEnabled: model.mac.isEnabled,
      iphoneIsEnabled: model.iphone.isEnabled,
      ipadIsEnabled: model.ipad.isEnabled,
      skip_setup_items: {
        MacOS: {
          ...MAC_SKIP_SCREENS().reduce((a, c) => ({ ...a, [c.id]: false }), {}),
          ...(model.mac.isEnabled
            ? transformSkipItemsToApi(
                model.mac.screens,
                false,
                MAC_SKIP_SCREENS(),
              )
            : {}),
        },
        iOS: {
          ...IPHONE_SKIP_SCREENS().reduce(
            (a, c) => ({ ...a, [c.id]: false }),
            {},
          ),
          ...(model.iphone.isEnabled
            ? transformSkipItemsToApi(
                model.iphone.screens,
                false,
                IPHONE_SKIP_SCREENS(),
              )
            : {}),
        },
        iPadOS: {
          ...IPAD_SKIP_SCREENS().reduce(
            (a, c) => ({ ...a, [c.id]: false }),
            {},
          ),
          ...(model.ipad.isEnabled
            ? transformSkipItemsToApi(
                model.ipad.screens,
                false,
                IPAD_SKIP_SCREENS(),
              )
            : {}),
        },
      },
    },
    rules: model.rules || null,
  };

  Object.keys(runsOn).forEach((runsOnKey) => {
    toSend[runsOnKey] = runsOn[runsOnKey];
  });

  if (model.skip_blueprint_conflict) {
    toSend.skip_blueprint_conflict = model.skip_blueprint_conflict;
  }

  if (!model.id) {
    const itemConfig = configs['Setup Assistant'];

    toSend.type = itemConfig.type;
    toSend.identifier = itemConfig.identifier;
  }

  return toSend;
}

export { transformToApi, transformFromApi };
