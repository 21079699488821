import type { CancelTokenSource } from 'axios';
import axios from 'axios';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ThreatService } from '../../data-service';
import type { FileStatus } from '../../threat.types';
import { IncidentResponseStatuses } from '../../threat.types';

type StartFileStatusCheck = (
  deviceId: string,
  fileHash: string,
  filePath: string,
) => Promise<void>;

type UseFileStatusPolling = (
  onEvent: (event: FileStatus) => void,
  intervalTime?: number,
  timeoutTime?: number,
) => {
  startFileStatusCheck: StartFileStatusCheck;
  isPolling: boolean;
};

/**
 * Hook that polls the file status. Implements a polling mechanism with a timeout
 * which schedules the next polling request, and it calculates the delay based on
 * a constant interval time and the time elapsed during the last request. This
 * ensures that the polling requests are sent at approximately constant intervals,
 * regardless of how long each request takes. It cancels ongoing HTTP requests
 * when the component unmounts.
 *
 * @property {StartFileStatusCheck} startFileStatusCheck - Starts the file status
 * check for the given file.
 * @property {boolean} isPolling - Whether the file status is being polled.
 * @returns The current file status, a function to start
 * the file status check and whether the file status is being polled.
 */
const useFileStatusPolling: UseFileStatusPolling = (
  onEvent,
  intervalTime = 3000,
  timeoutTime = 15_000,
) => {
  const cancelTokenSource = useRef<CancelTokenSource>(
    axios.CancelToken.source(),
  );
  const threatService = useMemo<ThreatService>(() => new ThreatService(), []);
  const [isPolling, setIsPolling] = useState(false);
  const [incidentResponseId, setIncidentResponseId] = useState<number | null>();

  const timeoutId = useRef<NodeJS.Timeout>();

  /**
   * Calculates the number of intervals until the file status check times out.
   * @returns {number} The number of intervals until the file status check times out.
   */
  const getNumberOfIntervalsUntilTimeout = useCallback(
    (): number => Math.floor(timeoutTime / intervalTime),
    [timeoutTime, intervalTime],
  );

  const timeoutCounter = useRef<number | null>(null);

  /**
   * Calculates the time remaining until the next file status check.
   * @param {number} start - The time when the last file status check started.
   * @returns {number} The time remaining until the next file status check.
   */
  const getTimeRemainingUntilNextPoll = useCallback(
    (start: number): number => {
      const elapsed = Date.now() - start;
      const remainingTime = Math.max(0, intervalTime - elapsed);

      return remainingTime;
    },
    [intervalTime],
  );

  /**
   * Cancels any ongoing file status checks and clears the timeout.
   */
  const cleanup: () => void = useCallback(() => {
    setIsPolling(false);
    setIncidentResponseId(null);
    clearTimeout(timeoutId.current);
  }, []);

  /**
   * Starts the file status check for the given file. Cancels any ongoing file
   * status checks.
   * @param {string} deviceId - The ID of the device.
   * @param {string} fileHash - The hash of the file.
   * @param {string} filePath - The path of the file.
   * @returns {Promise<void>}
   */
  const startFileStatusCheck: StartFileStatusCheck = useCallback(
    async (deviceId, fileHash, filePath) => {
      cancelTokenSource.current = axios.CancelToken.source();
      timeoutCounter.current = getNumberOfIntervalsUntilTimeout();

      setIsPolling(true);
      onEvent('checking');

      try {
        const { data } = await threatService.checkFileStatus(
          { device_id: deviceId, file_hash: fileHash, file_path: filePath },
          cancelTokenSource.current.token,
        );

        const responseId = data?.threat_incident_response?.id;

        if (!responseId) {
          throw new Error('Incident response ID is missing');
        }

        setIncidentResponseId(responseId);
      } catch (error) {
        onEvent('error');
        cleanup();
      }
    },
    [cleanup, getNumberOfIntervalsUntilTimeout, onEvent, threatService],
  );

  useEffect(() => {
    return () => {
      cancelTokenSource.current.cancel();
      clearTimeout(timeoutId.current);
    };
  }, []);

  useEffect(() => {
    if (!isPolling || !incidentResponseId) {
      return;
    }

    /**
     * Polls the file status. Schedules the next polling request based on the
     * time elapsed during the last request.
     * @returns {Promise<void>}
     */
    const pollIncidentResponse = async (): Promise<void> => {
      cancelTokenSource.current = axios.CancelToken.source();
      timeoutCounter.current -= 1;

      try {
        const start = Date.now();
        const { data } = await threatService.getFileStatus(
          incidentResponseId,
          cancelTokenSource.current.token,
        );

        const { status, action_successful: actionSuccessful } =
          data?.threat_incident_response ?? {};

        if (!status) {
          throw new Error('Bad response parameters');
        }

        const isTimeout = timeoutCounter.current < 0;
        const isResolved = status === IncidentResponseStatuses.RESOLVED;
        const isUndeleted =
          status === IncidentResponseStatuses.MANUAL_DELETE_FAILED ||
          actionSuccessful === false;
        const isCleanable = isTimeout || isResolved || isUndeleted;

        if (isTimeout) {
          onEvent('timeout');
        }

        if (isResolved) {
          onEvent('resolved');
        }

        if (isUndeleted) {
          onEvent('undeleted');
        }

        if (isCleanable) {
          cleanup();
          return;
        }

        timeoutId.current = setTimeout(
          pollIncidentResponse,
          getTimeRemainingUntilNextPoll(start),
        );
      } catch (error) {
        onEvent('error');
        cleanup();
      }
    };

    pollIncidentResponse();
  }, [
    isPolling,
    incidentResponseId,
    threatService,
    intervalTime,
    getNumberOfIntervalsUntilTimeout,
    getTimeRemainingUntilNextPoll,
    onEvent,
    cleanup,
  ]);

  return { startFileStatusCheck, isPolling };
};

export default useFileStatusPolling;
