import { i18n } from 'src/i18n';

const getTimeBetween = (firstDate: Date, secondDate: Date) => {
  const diffTime = Math.abs(secondDate.getTime() - firstDate.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const diffMonths = Math.ceil(diffDays / 31);
  const diffYears = Math.floor(diffMonths / 12);

  let displayText = '';

  if (diffDays < 31) {
    displayText = i18n.common.durationDays(diffDays);
  } else if (diffMonths < 12) {
    displayText = i18n.common.durationMonths(diffMonths);
  } else {
    displayText = i18n.common.durationYears(diffYears);
  }

  return displayText;
};

export { getTimeBetween };
