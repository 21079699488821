import { Flex, TextInput } from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';

import SimpleRow from './simple-row';

const ValueRow = (props) => {
  const {
    setting,
    update,
    valueField,
    valueLabel,
    checkboxFiled,
    validator,
    onInvalidate,
    inputRef,
    placeholder,
    isDisabled,
    valueFieldId,
  } = props;

  return (
    <SimpleRow {...props} className="k-ssh-value-row">
      {setting[checkboxFiled] && (
        <Setting.SecondaryControls>
          <Setting.SecondaryControlsRow className="k-ssh-gc-secondary-block-row b-mt1">
            <Flex align="center">
              <TextInput
                id={valueFieldId}
                value={setting[valueField]}
                onChange={(e) =>
                  update(valueField, e.target.value.replace(/\D/g, ''))
                }
                superCompact
                className="k-ssh-gc-compact-input"
                validator={validator}
                onInvalidate={onInvalidate}
                placeholder={placeholder}
                disabled={isDisabled}
              />
              <p className="b-txt" ref={inputRef}>
                {valueLabel}
              </p>
            </Flex>
          </Setting.SecondaryControlsRow>
        </Setting.SecondaryControls>
      )}
    </SimpleRow>
  );
};

export default ValueRow;
