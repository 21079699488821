import CustomProfileService from './service/custom-profile-service';

const initialState = {
  id: '',
  name: '',
  iconFile: null,
  iconSrc: '',
  isActive: true, // item.is_active
  blueprints: [],
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  scope: 'system',
  supportsRules: true,

  installation: {
    type: CustomProfileService.installationTypes.CONTINUOUSLY_ENFORCE,
    allowRemove: false,
  },

  selfService: {
    isEnabled: false, // false => item.data.self_service_data === undefined
    category: '', // item.data.self_service_data.category
    isRecommended: false, // item.data.self_service_data.isRecommended
    displayName: null, // item.data.self_service_data.displayName
    shortDescription: null, // item.data.self_service_data.description
    longDescription: null, // item.data.self_service_data.bodyText
    isRequireRead: false, // item.data.self_service_data.mustViewAgreement
    iconUrl: null, // item.data.self_service_data.iconUrl
    iconFile: null,
    installBtnText: null,
    size: '',
    displayInfoBtn: false,
    infoBtnText: '',
    infoBtnUrl: '',
  },

  details: {
    mobileconfig: null,
    // mobileconfig: {
    //   file: null,
    //   name: '',
    //   uuid: '',
    //   downloadLink: null,
    //   updatedAt: null,
    //   preview: null,
    // },
  },
};

export default initialState;
