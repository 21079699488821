import { useQuery } from '@tanstack/react-query';
import { prismService } from '../data-service/prism-service';
import type { PrismCategoryParamsSchema } from '../types/prism.types';

export const useViewDataQuery = ({
  params = {
    limit: 50,
    offset: 0,
  },
  columns,
  filterBody,
  search,
}: {
  params?: PrismCategoryParamsSchema;
  columns?: Array<{ category: string; name: string }>;
  filterBody?: Record<string, unknown>;
  search?: string;
}) => {
  return useQuery({
    queryKey: ['prism-category', params, filterBody, columns, search],
    queryFn: async () =>
      prismService.getViewData(params, columns, filterBody, search),
    enabled: !!columns?.length,
  });
};
