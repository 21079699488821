import { useDroppable } from '@dnd-kit/core';
import {
  SortableContext,
  rectSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS as DndCSS } from '@dnd-kit/utilities';
import {
  Button,
  Flex,
  Grid,
  Icon,
  TextField,
  styled,
} from '@kandji-inc/nectar-ui';
import React, { useState, useRef } from 'react';
import type { MutableRefObject } from 'react';
import { createPortal } from 'react-dom';

import type {
  HomeScreenLayoutDeviceKind,
  HomeScreenLayoutItem,
  HomeScreenLayoutPreviewContextKind,
  NonFolderHomeScreenLayoutItem,
  UpdateFn,
} from '../../../home-screen-layout.types';
import folderIconIpad from '../../../service/apple-system-apps/assets/folder-ipad.svg';
import folderIconIphone from '../../../service/apple-system-apps/assets/folder-iphone.svg';
import { PREVIEW_CONFIG, createDragIndicatorCss } from '../../common';
import SingleApp from '../../common/SingleApp';
import {
  getDndContextPreviewContext,
  getSortableItemTypeByPreviewContext,
} from '../../common/hooks/dnd-helpers';

const FolderBackdrop = styled('div', {
  position: 'absolute',
  justifyContent: 'center',
  alignContent: 'center',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(80, 94, 113, 0.24)',
  backdropFilter: 'blur(10px)',
  zIndex: '2',
});

const FolderContainer = styled('div', {
  alignSelf: 'center',
  borderRadius: '72px',
  backgroundColor: '$neutral5',
  padding: '$6 $5',
});

const NameFieldContainer = styled('div', {
  alignSelf: 'center',
  marginBottom: '$6',
});

const IconContainer = styled(Flex, {
  width: '68px',
  height: '68px',
  position: 'relative',
  textAlign: 'center',
  userSelect: 'none',
  outline: 'none',
  margin: 0,
});

const RemoveButton = styled(Button);

const FolderIcon = styled('figure', {
  position: 'relative',
  width: '52px',
  height: '52px',
  textAlign: 'center',
  cursor: 'pointer',

  figcaption: {
    fontSize: '10px',
    lineHeight: '$1',
    color: '$neutral70',
    mt: 2,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  '&:hover': {
    [`& ${RemoveButton}`]: {
      visibility: 'visible',
    },
  },

  '&:focus-within': {
    '[data-dnd-focusable=true]': {
      boxShadow: '$$focusedAppShadow',
    },
  },

  variants: {
    disabled: {
      true: {
        '&:hover': {
          [`& ${RemoveButton}`]: {
            visibility: 'hidden !important',
          },
        },
      },
    },
  },
});

export type FolderProps = {
  readonly id: string;
  readonly kind: HomeScreenLayoutDeviceKind;
  readonly apps: ReadonlyArray<NonFolderHomeScreenLayoutItem>;
  readonly allPageItems: readonly HomeScreenLayoutItem[];
  readonly onRemove: (
    item: HomeScreenLayoutItem,
    ctx: {
      kind: HomeScreenLayoutDeviceKind;
      context: HomeScreenLayoutPreviewContextKind;
      disabled: boolean;
      isRemovable: boolean;
    },
  ) => void;
  disabled: boolean;
  readonly name?: string;
  readonly update: UpdateFn;
  readonly combinedRefs?: MutableRefObject<{
    droppableContainerRef?: HTMLDivElement | null;
    pageContainerRef?: HTMLDivElement | null;
  }>;
  readonly className: string;
  readonly pageIndex?: number;
};

const Folder = (props: FolderProps) => {
  const {
    id: folderId,
    apps,
    allPageItems,
    kind,
    onRemove,
    disabled,
    name: folderName,
    update,
    combinedRefs,
    className,
    pageIndex,
  } = props;

  const previewConfig = PREVIEW_CONFIG[kind];
  const isAtMax = apps?.length >= previewConfig.maxPerFolder;

  const nameFieldRef = useRef(null);

  // const [selectedApps, setSelectedApps] = useState([]);
  const [isFolderOpen, setIsFolderOpen] = useState<boolean>(false);

  const {
    folderDroppableContainerId,
    prefixSortableFolderItemId: prefixIdSortableFolderItem,
    dndContext: { droppableFolder },
  } = PREVIEW_CONFIG.common;

  const thisFolder = {
    Type: 'Folder',
    id: folderId,
    DisplayName: folderName,
    Pages: [apps],
  };

  const {
    isDragging,
    attributes,
    listeners,
    transition,
    transform,
    setNodeRef,
  } = useSortable({
    id: getSortableItemTypeByPreviewContext(folderId, 'pageFolder'),
    data: {
      dndContext: getDndContextPreviewContext('pageFolder'),
      item: thisFolder,
      items: allPageItems,
      maxItems: previewConfig.maxPerPage,
      isAtMax,
      pageIndex,
    },
    transition: {
      duration: 150,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    },
    disabled,
    attributes: {
      roleDescription: `sortable ${folderName} folder`,
    },
  });

  const {
    setNodeRef: setDroppableNodeRef,
    active,
    over,
  } = useDroppable({
    id: `${folderDroppableContainerId}_${folderId}`,
    data: {
      dndContext: droppableFolder,
      items: apps,
      maxItems: previewConfig.maxPerFolder,
      isAtMax,
    },
    disabled: disabled || isAtMax,
  });

  const isDraggingOverThis = over?.data?.current?.item?.id === folderId;

  const handleClose = () => {
    update('currentFolderId', null);

    if (folderName.length >= 1) {
      setIsFolderOpen(false);
    } else {
      nameFieldRef?.current?.focus();
    }
  };

  const handleFolderNameChange = ({ target: { value } }) => {
    update('Pages', (prevPages) => {
      const updatedPages = prevPages.map((page) => {
        if (page.some(({ id }) => id === folderId)) {
          return page.map((pageItem) => {
            if (pageItem.id === folderId) {
              return {
                ...pageItem,
                DisplayName: value,
              };
            }

            return pageItem;
          });
        }

        return page;
      });

      return updatedPages;
    });
  };

  const handleRemoveFolder = () => {
    onRemove(
      { id: folderId, DisplayName: folderName, Type: 'Folder', Pages: [apps] },
      {
        kind,
        context: 'page',
        disabled,
        isRemovable: true,
      },
    );
  };

  return (
    <IconContainer flow="column" alignItems="center">
      <FolderIcon
        className={className}
        aria-label={folderName}
        ref={(r) => {
          setNodeRef(r);
        }}
        onClick={() => {
          update('currentFolderId', folderId);
          setIsFolderOpen(true);
        }}
        title={folderName}
        disabled={disabled}
        css={{
          transition: transition || 'none',

          transform: /* istanbul ignore next */ isDraggingOverThis
            ? 'none'
            : DndCSS.Translate.toString(transform) || 'none',
          opacity: isDragging ? 0.6 : 1,

          '&:focus-within': {
            '[data-dnd-focusable=true]': {
              boxShadow: '$$focusedAppShadow',
            },
          },
        }}
        {...listeners}
        {...attributes}
        data-testid="folderIcon"
      >
        <img
          src={kind === 'ipad' ? folderIconIpad : folderIconIphone}
          alt="folder"
          style={{
            transition: '100ms ease',
            .../* istanbul ignore next */ (isDraggingOverThis
              ? {
                  transform: 'scale(1.25)',
                  cursor: isAtMax ? 'not-allowed' : 'pointer',
                  pointerEvents: isAtMax ? 'none' : 'auto',
                }
              : {}),
          }}
        />
        <figcaption>{folderName}</figcaption>
        <RemoveButton
          role="button"
          aria-label="Remove folder from the Home Screen Layout preview area"
          onClick={(e) => {
            e.stopPropagation();
            handleRemoveFolder();
          }}
          css={{
            visibility: 'hidden',
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            padding: '$1',
            right: '0px',
            top: '-3px',
            borderRadius: '14px',
            outline: '1px solid $neutral20',
            background: '$neutral5',
            boxShadow: '$elevation2',
            zIndex: 1,
          }}
        >
          <Icon size="xs" name="minus" />
        </RemoveButton>
      </FolderIcon>
      {isFolderOpen &&
        createPortal(
          <FolderBackdrop>
            <Flex flow="column" gap="xl">
              <Flex justifyContent="end" p2>
                <Button
                  icon={{ name: 'fa-xmark-small' }}
                  onClick={handleClose}
                  compact
                  css={{
                    backgroundColor: 'transparent',
                  }}
                  data-testid="folderCloseBtn"
                />
              </Flex>
              <NameFieldContainer>
                <TextField
                  testId="folderName"
                  ref={nameFieldRef}
                  value={folderName}
                  name="DisplayName"
                  onChange={handleFolderNameChange}
                  hint={
                    folderName.length < 1 && {
                      label: 'Required',
                    }
                  }
                  state={folderName.length < 1 ? 'error' : 'default'}
                  disabled={disabled}
                  showClearButton
                  onClear={
                    /* istanbul ignore next */ () =>
                      !disabled &&
                      handleFolderNameChange({ target: { value: 'Folder' } })
                  }
                  validations
                />
              </NameFieldContainer>
              <FolderContainer
                css={{
                  width: kind === 'ipad' ? '360px' : '288px',
                  height: kind === 'ipad' ? '360px' : '288px',
                }}
                ref={(r) => {
                  setDroppableNodeRef(r);
                  /* istanbul ignore if */
                  if (combinedRefs?.current) {
                    combinedRefs.current.droppableContainerRef = r;
                  }
                }}
                data-testid="folderContainer"
              >
                <SortableContext
                  key={folderId}
                  id={`${folderDroppableContainerId}_${folderId}`}
                  items={apps.map(
                    (item) => `${prefixIdSortableFolderItem}${item.id}`,
                  )}
                  strategy={rectSortingStrategy}
                  disabled={disabled}
                >
                  <Grid
                    columns={kind === 'iphone' ? 3 : 4}
                    rows={kind === 'iphone' ? 3 : 4}
                    gapY={2}
                  >
                    {apps.map((app: any, itemIndex) => {
                      const {
                        name,
                        BundleID,
                        icon,
                        id,
                        Type,
                        appType,
                        deviceFamilies,
                        alwaysInPreview,
                      } = app;
                      const isRemovable =
                        typeof alwaysInPreview === 'boolean'
                          ? !alwaysInPreview
                          : true;

                      const dragIndicatorCss = createDragIndicatorCss({
                        pageOrFolderItems: apps,
                        currentPageOrFolderPageNumber: 0, // TODO: implement with actual folder pages
                        pageOrFolderIndex: 0, // TODO: implement with actual folder pages
                        itemIndex,
                        active,
                        over,
                        droppableContainerId: `${folderDroppableContainerId}_${folderId}`,
                        sortableItemId: `${prefixIdSortableFolderItem}${id}`,
                      });

                      return (
                        <SingleApp
                          key={id}
                          kind={kind}
                          context="folder"
                          id={id}
                          name={name}
                          BundleID={BundleID}
                          alwaysInPreview={alwaysInPreview}
                          icon={icon}
                          Type={Type}
                          appType={appType}
                          deviceFamilies={deviceFamilies}
                          onRemove={
                            /* istanbul ignore next - hover interaction not getting triggered in tests :( */ (
                              ...args
                            ) => {
                              onRemove(...args);

                              // Remove folder if it is going to be empty after removing this item:
                              // @TODO handle case with multiple pages in the folder
                              if (apps.length === 1) {
                                handleRemoveFolder();
                              }
                            }
                          }
                          isRemovable={isRemovable}
                          disabled={disabled}
                          items={apps}
                          maxItems={previewConfig.maxPerFolder}
                          isAtMax={isAtMax}
                          isAtMaxPerFolder={isAtMax}
                          className={dragIndicatorCss()}
                          // onSelect={setSelectedApps}
                        />
                      );
                    })}
                  </Grid>
                </SortableContext>
              </FolderContainer>
            </Flex>
          </FolderBackdrop>,
          document.querySelector(`#previewContainer_${kind}`),
          folderId,
        )}
    </IconContainer>
  );
};

export default Folder;
