import { number, object, string } from 'prop-types';
import React from 'react';
import './progress.css';

const Progress = ({ progress, className, style }) => (
  <div className={`b-upload__progress-bar ${className}`} style={style}>
    <div
      className={`b-upload__progress-bar-loader ${
        progress < 100 ? 'b-upload__progress-bar-incomplete' : ''
      }`}
      style={{ width: `${progress}%` }}
    />
    <p className="b-txt-bold b-upload__progress-percent">{`${progress}%`}</p>
  </div>
);

Progress.propTypes = {
  progress: number,
  className: string,
  style: object,
};

Progress.defaultProps = {
  progress: 0,
  className: '',
  style: {},
};

export default Progress;
