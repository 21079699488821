/* istanbul ignore file */
import { useInfiniteQuery } from '@tanstack/react-query';
import { enterpriseExternalApi } from 'src/app/api/base';

const useGetTagsInfinite = (name = '', limit = 50) => {
  const { data: apiRes, ...rest } = useInfiniteQuery({
    queryKey: ['infinite-tags', name],
    queryFn: ({ pageParam }) =>
      enterpriseExternalApi('tags').get({
        search: name,
        limit,
        offset: pageParam * limit,
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.data.next) {
        return pages.length;
      }
    },
  });

  return { data: apiRes, ...rest };
};

export { useGetTagsInfinite };
