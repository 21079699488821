import { Checkbox, Select } from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';
import {
  i18nOsRequirements,
  osRequirements,
} from 'src/features/library-items/library/common';
import { i18n } from 'src/i18n';

const MinimumOSVersionRow = ({
  setting,
  update,
  isDisabled,
  minimumOSVersionOptions,
  typeOS,
  os,
}) => {
  const LABEL_HELPER_TEXT_MAP = {
    macOS: i18nOsRequirements(osRequirements.MAC_14_0),
    iOS: i18nOsRequirements(osRequirements.IOS_17_0),
    iPadOS: i18nOsRequirements(osRequirements.IPAD_17_0),
  };

  return (
    <Setting.Row>
      <Setting.Controls>
        <Checkbox
          checked={setting.isMinimumOSVersionSet}
          label={i18n.t(`Require minimum OS version ({osVersion})`, {
            osVersion: LABEL_HELPER_TEXT_MAP[os],
          })}
          onChange={() => update('isMinimumOSVersionSet', (p) => !p)}
          disabled={isDisabled}
          testId="min-os-version-checkbox"
        />
      </Setting.Controls>
      <Setting.Helpers>
        <p className="b-txt-light">
          {i18n.t(
            'Specify a minimum version of {typeOS} that the device must be running before the enrollment process continues.',
            { typeOS },
          )}
        </p>
      </Setting.Helpers>
      {setting.isMinimumOSVersionSet && (
        <Setting.SecondaryControls>
          <div className="b-library-form">
            <Setting.Title>
              <p className="b-txt b-mb1">
                {i18n.t('Version must be greater than or equal to')}
              </p>
            </Setting.Title>
            <Select
              isDisabled={isDisabled}
              options={minimumOSVersionOptions}
              placeholder={i18n.t('Select public release')}
              compact
              onChange={(v) => update('minimumOSVersion', v.value)}
              value={minimumOSVersionOptions.find(
                (option) => option.value === setting.minimumOSVersion,
              )}
              inputId="min-os-version-select"
            />
          </div>
        </Setting.SecondaryControls>
      )}
    </Setting.Row>
  );
};

export default MinimumOSVersionRow;
