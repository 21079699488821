import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import configs from '../../../library/library-item-configurations/items/profile';

const commonTransformationFromApi = (data) => {
  const result = {
    general: {
      isBlockMacAppStore: !!data['restrict-store-softwareupdate-only'],
      disableSoftwareUpdateNotifications:
        !!data.DisableSoftwareUpdateNotifications,
      disableAppAdoption: !!data['restrict-store-disable-app-adoption'],
    },
  };

  return result;
};

async function transformFromNewApi(apiData, preset) {
  const result = {
    ...preset,
    isActive: apiData.active,
    ...commonTransformationFromApi(apiData.data),
    rules: apiData.rules,
  };

  return result;
}

async function transformFromApi(apiData) {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const commonData = {
    id: apiData.id,
    name: apiData.name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
  };

  const result = await transformFromNewApi(apiData, commonData);

  return {
    ...apiData,
    data: result,
  };
}

function transformToOldApi(model) {
  const toSend = {
    blueprints: model.selectedBlueprints.map(({ value }) => value),
    is_all_blueprints: model.isAllBlueprints,
    name: model.name,
    is_active: model.isActive,
    settings: {
      'restrict-store-softwareupdate-only':
        model.general.isBlockMacAppStore || undefined,
      DisableSoftwareUpdateNotifications:
        model.general.disableSoftwareUpdateNotifications || undefined,
      'restrict-store-disable-app-adoption':
        model.general.disableAppAdoption || undefined,
    },
    rules: model.rules || null,
  };

  if (!model.id) {
    const itemConfig = configs['App Store'];

    toSend.type = itemConfig.type;
    toSend.identifier = itemConfig.identifier;
  }

  return toSend;
}

function transformToNewApi(model) {
  const toSend = transformToOldApi(model);

  toSend.active = toSend.is_active;
  delete toSend.is_active;
  toSend.data = toSend.settings;
  delete toSend.settings;

  if (model.skip_blueprint_conflict) {
    toSend.skip_blueprint_conflict = model.skip_blueprint_conflict;
  }

  return toSend;
}

export { transformFromApi, transformToNewApi };
