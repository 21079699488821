import {
  Box,
  Button,
  ButtonSelect,
  DropdownMenu,
  Flex,
  Grid,
  Icon,
  Text,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'src/i18n';

import HomeScreenLayoutService from '../../service/home-screen-layout-service';

import type {
  HomeScreenLayoutDeviceKind,
  PreviewStyleKind,
} from '../../home-screen-layout.types';

interface ActionBarProps {
  readonly kind: HomeScreenLayoutDeviceKind;
  readonly style: PreviewStyleKind;
  readonly onUpdateStyle: (kind: PreviewStyleKind) => void;
  readonly onAddPage: () => void;
  readonly canAddPage: boolean;
  readonly disabled: boolean;
  readonly canMovePageLeft?: boolean;
  readonly canMovePageRight?: boolean;
  readonly canRemovePage: boolean;
  readonly onRemovePage?: () => void;
  readonly onMovePage?: (number) => void;
  readonly onCreateFolder?: () => void;
  readonly onCancel: () => void;
  readonly selectedApps: Array<string>;
}

const ActionBar = (props: ActionBarProps) => {
  const {
    kind,
    style,
    onUpdateStyle,
    onAddPage,
    disabled,
    onRemovePage,
    canRemovePage,
    onMovePage,
    canAddPage,
    canMovePageLeft,
    canMovePageRight,
    selectedApps,
    onCancel,
    onCreateFolder,
  } = props;

  const hasMultipleAppsSelected = selectedApps.length > 0;

  return (
    <Grid
      columns="3"
      css={{
        padding: '$2',
        height: '44px',
        '@media (max-width: 1420px)': {
          gridTemplateColumns: '1fr 1fr auto',
        },
        backgroundColor: hasMultipleAppsSelected ? '$neutral0' : 'unset',
        borderBottom: hasMultipleAppsSelected
          ? '1px solid $neutral20'
          : 'unset',
      }}
      align="center"
    >
      {hasMultipleAppsSelected && (
        <>
          <Flex justifyContent="start" alignItems="center">
            <Button compact onClick={onCancel} variant="subtle">
              {i18n.t('Cancel')}
            </Button>
          </Flex>
          <Flex justifyContent="center" alignItems="center" gap="xs">
            <Text variant="description">
              {i18n.t('{selectedAppsLength} item(s) selected', {
                selectedAppsLength: selectedApps.length,
              })}
            </Text>
          </Flex>
          <Flex justifyContent="end">
            <DropdownMenu
              options={[
                {
                  label: i18n.t('Create Folder'),
                  icon: 'folder-closed',
                  disabled: !hasMultipleAppsSelected,
                  onClick: (e) => {
                    e.stopPropagation();
                    onCreateFolder();
                  },
                },
                // {
                //   label: 'Remove item(s)',
                //   icon: 'circle-minus',
                //   disabled: !hasMultipleAppsSelected,
                //   onClick: (e) => {
                //     e.stopPropagation();
                //   },
                // },
                // {
                //   label: 'Send to...',
                //   icon: 'arrow-right-to-line',
                //   disabled: !hasMultipleAppsSelected,
                //   onClick: (e) => {
                //     e.stopPropagation();
                //   },
                // },
              ]}
            >
              <Button
                id="action"
                variant="primary"
                onClick={
                  /* istanbul ignore next */ (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }
                compact
                icon={{ name: 'fa-angle-down-small', position: 'right' }}
                data-testid="actionMenuBtn"
              >
                {i18n.t('Actions')}
              </Button>
            </DropdownMenu>
          </Flex>
        </>
      )}
      {!hasMultipleAppsSelected && (
        <>
          <Box />
          <Flex justifyContent="center" alignItems="center" gap="xs">
            <Text variant="description">
              {HomeScreenLayoutService.deviceToName[kind]()}
            </Text>
          </Flex>
          <Flex
            gap="sm"
            css={{ justifySelf: 'flex-end', alignContent: 'center' }}
          >
            <DropdownMenu
              testId="pageMenu"
              options={[
                {
                  label: i18n.t('Move page to left'),
                  icon: 'arrow-left-from-line',
                  disabled: !canMovePageLeft || disabled,
                  onClick: (e) => {
                    // @todo need to update nectar component to respect the disabled property
                    /* istanbul ignore next */
                    if (!canMovePageLeft || disabled) {
                      return;
                    }
                    e.stopPropagation();
                    onMovePage(-1);
                  },
                },
                {
                  label: i18n.t('Move page to right'),
                  icon: 'arrow-right-from-line',
                  disabled: !canMovePageRight || disabled,
                  onClick: (e) => {
                    // @todo need to update nectar component to respect the disabled property
                    /* istanbul ignore next */
                    if (!canMovePageRight || disabled) {
                      return;
                    }
                    e.stopPropagation();
                    onMovePage(1);
                  },
                },
                {
                  label: i18n.t('Delete page'),
                  icon: 'trash-can',
                  theme: 'danger',
                  disabled: !canRemovePage || disabled,
                  onClick: (e) => {
                    // @todo need to update nectar component to respect the disabled property
                    /* istanbul ignore next */
                    if (!canRemovePage || disabled) {
                      return;
                    }
                    e.stopPropagation();
                    onRemovePage();
                  },
                },
              ]}
            >
              <Button
                id="action"
                onClick={
                  /* istanbul ignore next - clicks inside the test aren't triggering this fn :shrug: */ (
                    e,
                  ) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }
                compact
                icon={{ name: 'ellipsis' }}
                data-testid="pageMenuBtn"
              />
            </DropdownMenu>

            <Button
              compact
              onClick={onAddPage}
              disabled={disabled || !canAddPage}
            >
              {i18n.t('New Page')}
            </Button>

            {kind === 'ipad' && (
              <ButtonSelect.Root
                defaultValue="portrait"
                type="single"
                value={style}
                css={{
                  [`& ${ButtonSelect.Item}`]: {
                    color: '$neutral80',
                    backgroundColor: '$neutral20',
                    padding: 3,
                    '&[data-state=on]': {
                      color: '$neutral0',
                      backgroundColor: '$blue50',
                    },
                  },
                }}
              >
                <ButtonSelect.Item
                  value="portrait"
                  onClick={() => onUpdateStyle('portrait')}
                  data-testid="portraitBtn"
                >
                  <Icon name="rectangle-vertical" size={20} />
                </ButtonSelect.Item>
                <ButtonSelect.Item
                  value="landscape"
                  onClick={() => onUpdateStyle('landscape')}
                  data-testid="landscapeBtn"
                >
                  <Icon name="rectangle" size={20} />
                </ButtonSelect.Item>
              </ButtonSelect.Root>
            )}
          </Flex>
        </>
      )}
    </Grid>
  );
};

export default ActionBar;
