/* istanbul ignore file */
import { useMutation } from '@tanstack/react-query';
import { pulseService } from '../data-service/pulse-service';

export const useCreatePulseCheck = () => {
  const mutation = useMutation({
    mutationFn: pulseService.createPulseTemplate,
  });

  return mutation;
};

export const useCreatePulseJob = () => {
  const mutation = useMutation({
    mutationFn: pulseService.createPulseJob,
  });

  return mutation;
};
