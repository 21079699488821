import { AccountContext } from 'contexts/account';
import { useContext, useEffect } from 'react';

const Override = () => {
  const {
    userSettings: { hide_support_chat_bubble },
  } = useContext(AccountContext);

  useEffect(() => {
    const className = 'hide-support-chat-bubble';

    if (hide_support_chat_bubble) {
      document.body.classList.add(className);
    } else {
      document.body.classList.remove(className);
    }
  }, [hide_support_chat_bubble]);

  return null;
};

export default Override;
