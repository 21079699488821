import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import { getIconName } from '../../components/PrismNav/prismNavUtils';
import {
  NULL_VALUE_N_DASH,
  ON_OFF_CELL_FILTER_OPTIONS,
  OnOffCell,
  getFilteredDisabled as filterDisabled,
} from '../column-utils';
import { getScharedColumns } from './getSharedColumns';

export const getGatekeeperAndXprotectColumns = ({
  gatekeeperAndXprotectSchema,
}): {
  columnDefs: any[];
  pinnedColumns: string[];
  alwaysHiddenColumns: string[];
} => {
  const { properties } = gatekeeperAndXprotectSchema.schema;
  const gatekeeperAndXprotectColumnHelper =
    createColumnHelper<z.infer<typeof properties>>();

  const columnDefs = [
    gatekeeperAndXprotectColumnHelper.accessor(
      (row) => row[`${gatekeeperAndXprotectSchema.uri}.gatekeeper_status`],
      {
        id: `${gatekeeperAndXprotectSchema.uri}.gatekeeper_status`,
        cell: OnOffCell,
        meta: {
          displayName: properties.gatekeeper_status.title,
          displayIcon: getIconName(gatekeeperAndXprotectSchema.uri),
          filterType: 'boolean',
          filterOptions: [...ON_OFF_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    gatekeeperAndXprotectColumnHelper.accessor(
      (row) => row[`${gatekeeperAndXprotectSchema.uri}.trusted_developers`],
      {
        id: `${gatekeeperAndXprotectSchema.uri}.trusted_developers`,
        cell: (info) => {
          const cellValue = info.getValue();
          if (cellValue === null) {
            return NULL_VALUE_N_DASH;
          }
          return cellValue
            ? 'App Store and identified developers'
            : 'App Store';
        },
        meta: {
          displayName: properties.trusted_developers.title,
          displayIcon: getIconName(gatekeeperAndXprotectSchema.uri),
          filterType: 'boolean',
          filterOptions: [
            { value: 'true', label: 'App Store and identified developers' },
            { value: 'false', label: 'App Store' },
            { type: 'divider' },
            { value: 'is_null', label: 'is blank' },
            { value: 'is_not_null', label: 'is not blank' },
          ],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    gatekeeperAndXprotectColumnHelper.accessor(
      (row) => row[`${gatekeeperAndXprotectSchema.uri}.gatekeeper_version`],
      {
        id: `${gatekeeperAndXprotectSchema.uri}.gatekeeper_version`,
        meta: {
          displayName: properties.gatekeeper_version.title,
          displayIcon: getIconName(gatekeeperAndXprotectSchema.uri),
          filterType: 'version',
          filterIcon: 'hashtag',
          filterDisabled,
        },
      },
    ),

    gatekeeperAndXprotectColumnHelper.accessor(
      (row) =>
        row[`${gatekeeperAndXprotectSchema.uri}.gatekeeper_opaque_version`],
      {
        id: `${gatekeeperAndXprotectSchema.uri}.gatekeeper_opaque_version`,
        meta: {
          displayName: properties.gatekeeper_opaque_version.title,
          displayIcon: getIconName(gatekeeperAndXprotectSchema.uri),
          filterType: 'version',
          filterIcon: 'hashtag',
          filterDisabled,
        },
      },
    ),

    gatekeeperAndXprotectColumnHelper.accessor(
      (row) => row[`${gatekeeperAndXprotectSchema.uri}.xprotect_version`],
      {
        id: `${gatekeeperAndXprotectSchema.uri}.xprotect_version`,
        meta: {
          displayName: properties.xprotect_version.title,
          displayIcon: getIconName(gatekeeperAndXprotectSchema.uri),
          filterType: 'version',
          filterIcon: 'hashtag',
          filterDisabled,
        },
      },
    ),

    gatekeeperAndXprotectColumnHelper.accessor(
      (row) =>
        row[`$${gatekeeperAndXprotectSchema.uri}.malware_removal_tool_version`],
      {
        id: `${gatekeeperAndXprotectSchema.uri}.malware_removal_tool_version`,
        meta: {
          displayName: properties.malware_removal_tool_version.title,
          displayIcon: getIconName(gatekeeperAndXprotectSchema.uri),
          filterType: 'version',
          filterIcon: 'hashtag',
          filterDisabled,
        },
      },
    ),

    ...getScharedColumns(gatekeeperAndXprotectSchema),
  ];

  return { columnDefs, pinnedColumns: [], alwaysHiddenColumns: [] };
};
