import { Button } from '@kandji-inc/bumblebee';
import { updateCompanyReq } from 'app/_actions/company';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ModalWrapper } from './ModalWrapper';
import { ButtonsWrapper, CancelButtonWrapper } from './elements';

export class TransferOwnershipModal extends ModalWrapper {
  constructor(props) {
    super(props);
    this.user = props.info.user;
    this.companyId = props.info.companyId;
    this.modalName = 'TransferOwnershipModal';
  }

  transferCompanyOwnership = () => {
    this.setState({ isLoading: true }, () =>
      this.props
        .updateCompanyReq(this.companyId, { owner: this.user.id })
        .then(this.onHide)
        .catch((err) => console.log(err))
        .finally(() => this.setState({ isLoading: false })),
    );
  };

  renderHeader = () => 'Transfer Ownership';

  renderBody() {
    return (
      <>
        <p style={{ color: '#f05b7e' }}>
          Ownership of this company will be permanently transferred to
          <br />
          <b>{this.user.email}</b>. You will become an administrator of this
          account.
        </p>
      </>
    );
  }

  renderFooter() {
    const { isLoading } = this.state;
    return (
      <ButtonsWrapper>
        <CancelButtonWrapper>
          <Button type="button" onClick={this.onHide} kind="outline">
            Cancel
          </Button>
        </CancelButtonWrapper>
        <Button
          type="button"
          disabled={isLoading}
          onClick={this.transferCompanyOwnership}
        >
          {!isLoading ? 'Transfer' : 'Transfering...'}
        </Button>
      </ButtonsWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateCompanyReq,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(TransferOwnershipModal);
