import { Toaster as toaster } from '@kandji-inc/bumblebee';
import { Button, Dialog, Flex, Text } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import React, { useState } from 'react';
import { queryClient } from 'src/app/router';
import { disableLostMode } from '../../computer/api';

const DisableLostMode = (props) => {
  const { computer, info, name, onHide, updateComputer } = props;
  const { computerId } = info;

  const [isLoading, setIsLoading] = useState(false);

  const onConfirm = () => {
    setIsLoading(true);
    disableLostMode(computerId)
      .then(() => {
        /* istanbul ignore next */
        queryClient.setQueryData(
          ['single-computer', computer.id],
          (oldData) => {
            return {
              ...oldData,
              data: {
                ...oldData.data,
                lost_mode: {
                  ...(oldData.data?.lost_mode || {}),
                  disable_status: 'PENDING',
                  disable_status_at: new Date().toISOString(),
                },
              },
            };
          },
        );
        updateComputer({
          ...computer,
          lost_mode: {
            ...computer.lost_mode,
            disable_status: 'PENDING',
            disable_status_at: new Date().toISOString(),
          },
        });
        onHide();
        setIsLoading(false);
        toaster(i18n.t('Command sent to disable Lost Mode'));
      })
      .catch((err) => {
        onHide();
        setIsLoading(false);
        toaster(i18n.common.error());
      });
  };

  const content = (
    <Text>
      Are you sure you want to disable Lost Mode on {name}? Once disabled, the
      device will be unlocked and no longer tracked. A message will be shown to
      the user that their device was in Lost Mode.
    </Text>
  );

  const footer = (
    <Flex gap="md" justifyContent="end" wrap="wrap">
      <Button variant="subtle" onClick={onHide}>
        Cancel
      </Button>
      <Button variant="primary" loading={isLoading} onClick={onConfirm}>
        Confirm
      </Button>
    </Flex>
  );

  return (
    <Dialog
      isOpen
      closeOnEscape
      closeOnOutsideClick
      onOpenChange={onHide}
      title="Disable Lost Mode?"
      content={content}
      footer={footer}
      css={{ zIndex: 2000 }}
    />
  );
};

export default DisableLostMode;
