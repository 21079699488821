import { Icon } from '@kandji-inc/bumblebee';
import { i18n } from 'i18n';
import { get, isEmpty, merge, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ComputersDEPContext } from 'src/app/components/common/hooks/use-computersdep';
import styled from 'styled-components';
import { updateUser as updateUserRedux } from '../_actions/account';
import { setSnackbar as callSetSnackbar } from '../_actions/ui';
import { updateUser as callUpdateUser } from '../_actions/users';
import {
  DEPViews,
  KandjiViews,
  userTypes as UserTypes,
  colors,
} from '../common/constants';
import { prettyScroll, truncate } from '../common/style-utils';
import Toast from './common/Toast';
import { H8 } from './interface/Typography';

const Wrapper = styled.section`
  z-index: 20;
  width: 272px;
  height: calc(100vh - ${(props) => props.height}px);
  display: flex;
  flex-direction: column;
  background-color: var(--color-neutral-20);
  margin-right: 50px;
  position: fixed;
  border-right: 1px solid ${colors['grey-250']};

  @media (max-width: 768px) {
    .blueprint-page-tab-container {
      margin-left: calc(15rem - 5px - 3rem);
    }
  }
`;

const Header = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 35px 18px 10px 30px;
  color: var(--color-neutral-100);
`;

const EditModeIconWrapper = styled.section`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) =>
    props.isEditMode ? colors['marengo-680'] : colors['marengo-350']};
`;

const DroppableWrapper = styled.div`
  //overflow-x: hidden;
`;

const Views = styled.section`
  padding-left: 35px;
  max-height: 100%;
  //height: 100%;
  padding-bottom: 50px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-shrink: 1;
  ${prettyScroll({ scrollWidth: 3, scrollColor: 'marengo-200' })}
`;

const AddViewButtonWrapper = styled.div`
  z-index: 2;
  margin-top: -40px;
  flex-shrink: 0;
  background-color: var(--color-neutral-20);
`;

const EditViewIconWrapper = styled.section`
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`;

const EditViewIcon = styled(Icon)`
  cursor: pointer;
`;

const DeleteViewIconWrapper = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3px;
`;

const DeleteViewIcon = styled(Icon)`
  cursor: pointer;
  transition: color 0.3s ease;
  &:hover {
    color: ${colors['orange-500']};
  }
  &:active {
    color: ${colors['orange-500']};
  }
`;

const ReorderViewIconWrapper = styled.section`
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
`;

const ReorderViewIcon = styled(Icon)`
  color: ${colors['grey-405']};
`;

const ViewTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 2px solid ${colors['marengo-120']};
`;

const ViewTitle = styled.div`
  ${(props) => (props.truncate ? truncate('162px') : truncate('165px'))};
  color: ${colors['marengo-350']};
  padding: 8px 10px 8px 10px;
  ${(props) => (!props.isEditMode ? 'cursor: pointer' : null)};
`;

const ViewItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0 18px 0 0;
  font-family: var(--font-body-xs-family);
  font-weight: var(--font-body-xs-weight);
  font-size: var(--font-body-xs-size);
  line-height: var(--font-body-xs-line-height);
  letter-spacing: var(--font-body-xs-letter-spacing);

  &:hover {
    ${ViewTitle} {
      color: var(--color-neutral-100);

      font-weight: 500;
    }
    ${ViewTitleWrapper} {
      border-left: 2px solid var(--color-neutral-100);
    }
  }

  ${ViewTitleWrapper} {
    border-left: 2px solid
      ${(props) =>
        colors[props.active ? 'var(--color-neutral-100)' : 'marengo-120']};
  }

  ${ViewTitle} {
    color: ${(props) =>
      props.active ? 'var(--color-neutral-100)' : 'var(--color-neutral-60)'};

    font-weight: ${(props) => (props.active ? 500 : 400)};
  }
`;

const AddViewButton = styled.div`
  display: flex;
  align-items: center;
  font-family: var(--font-body-xs-family);
  font-weight: var(--font-body-xs-weight);
  font-size: var(--font-body-xs-size);
  line-height: var(--font-body-xs-line-height);
  letter-spacing: var(--font-body-xs-letter-spacing);
  text-align: center;
  padding-left: 30px;
  margin-top: 10px;
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'default')};
`;

const AddViewIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  font-size: 10px;
`;

const AddViewText = styled.div`
  color: ${(props) =>
    props.disabled ? colors['marengo-200'] : 'var(--color-neutral-100)'};
  padding-left: 8px;
`;

const ConfirmIconWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  cursor: pointer;
  color: ${colors['marengo-350']};
  &:hover {
    color: var(--color-neutral-100);
  }
`;

const ConfirmIcon = styled(Icon)`
  font-size: 20px;
`;

const ComputersCountWrapper = styled.div`
  font-family: var(--font-body-xxs-family);
  font-weight: var(--font-body-xxs-weight);
  font-size: var(--font-body-xxs-size);
  line-height: var(--font-body-xxs-line-height);
  letter-spacing: var(--font-body-xxs-letter-spacing);
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 18px;
  margin-left: auto;
  background-color: ${(props) =>
    props.active ? 'var(--color-neutral-100)' : 'var(--color-neutral-40)'};
  color: ${(props) => (props.active ? colors.white : 'var(--color-neutral-60)')};
  border-radius: 9px;
  text-align: center;
  padding: 0 6px;
`;

export class SavedViews extends React.Component {
  static contextType = ComputersDEPContext;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isEditMode: false,
      isDEPEditMode: false,
      idDeletePopupToShow: null,
    };
  }

  deleteView = (viewId, ifDep) => {
    const { userSettings, updateUser, setSnackbar, userId, toDefaultView } =
      this.props;
    const savedViews = get(userSettings, 'saved_views.computers_list', []);
    const savedDepViews = get(
      userSettings,
      'saved_views.computers_dep_list',
      [],
    );
    const activeSavedView = get(userSettings, 'active_views.computers_list');
    const activeDepSavedView = get(
      userSettings,
      'active_views.computers_dep_list',
    );
    const viewsOrder = get(userSettings, 'views_order.computers_list');
    const viewsDepOrder = get(userSettings, 'views_order.computers_dep_list');

    const newSavedViews = {
      saved_views: {
        computers_list: savedViews.filter(
          (view) => view.id !== viewId || ifDep,
        ),
        computers_dep_list: savedDepViews.filter(
          (view) => view.id !== viewId || !ifDep,
        ),
      },
      views_order: {
        computers_list: viewsOrder
          ? viewsOrder.filter((id) => id !== viewId || ifDep)
          : null,
        computers_dep_list: viewsDepOrder
          ? viewsDepOrder.filter((id) => id !== viewId || !ifDep)
          : null,
      },
    };
    const newActiveView = {
      active_views: {
        computers_list:
          ifDep || activeSavedView !== viewId ? activeSavedView : null,
        computers_dep_list:
          !ifDep || activeDepSavedView !== viewId ? activeDepSavedView : null,
      },
    };
    const payload = merge({ ...userSettings, ...newSavedViews }, newActiveView);
    this.setState({ isLoading: true });
    updateUser({ id: userId, settings: payload }, true, false)
      .then(() => {
        this.setState({ isLoading: false });
        setSnackbar(i18n.t('Saved View Was Removed'));
        if (!get(payload, 'active_views.computers_list')) {
          toDefaultView();
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        setSnackbar(err.message);
      });
  };

  reorderViews = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  /* istanbul ignore next */
  onDragEnd = (result) => {
    const { userSettings, updateUser, setSnackbar, userId, updateUserRedux } =
      this.props;
    const { isEditMode, isDEPEditMode } = this.state;
    if (!result.destination) {
      return;
    }
    const savedViews = get(userSettings, 'saved_views.computers_list', []);
    const savedDepViews = get(
      userSettings,
      'saved_views.computers_dep_list',
      [],
    );
    const oldViewsOrder = get(userSettings, 'views_order.computers_list');
    const oldDepViewsOrder = get(
      userSettings,
      'views_order.computers_dep_list',
    );
    let newViewsOrder = [];
    let newDepViewsOrder = [];
    if (oldViewsOrder) {
      newViewsOrder = this.reorderViews(
        oldViewsOrder,
        result.source.index,
        result.destination.index,
      );
    } else {
      /* istanbul ignore next */
      const defaultViewsOrder = [
        ...KandjiViews.filter(({ isHidden }) => !isHidden),
        ...savedViews,
      ].map((view) => view.id);
      newViewsOrder = this.reorderViews(
        defaultViewsOrder,
        result.source.index,
        result.destination.index,
      );
    }
    if (oldDepViewsOrder) {
      newDepViewsOrder = this.reorderViews(
        oldDepViewsOrder,
        result.source.index,
        result.destination.index,
      );
    } else {
      const defaultViewsOrder = [...DEPViews, ...savedDepViews].map(
        (view) => view.id,
      );
      newDepViewsOrder = this.reorderViews(
        defaultViewsOrder,
        result.source.index,
        result.destination.index,
      );
    }
    const newViewsOrderToSave = {
      views_order: {
        computers_list: (isEditMode ? newViewsOrder : oldViewsOrder) || [],
        computers_dep_list:
          (isDEPEditMode ? newDepViewsOrder : oldDepViewsOrder) || [],
      },
    };
    const payload = merge(userSettings, newViewsOrderToSave);
    updateUserRedux({ settings: payload });
    updateUser({ id: userId, settings: payload }, true, false)
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        setSnackbar(err.message);
      });
  };

  isSavedView = (viewId, savedViews) =>
    savedViews.some((view) => view.id === viewId);

  renderViews(DEP, defaultViews, savedViews = [], savedDepViews = []) {
    const {
      edit,
      acceptView,
      isViewChanged,
      userSettings,
      computersCounts,
      DEPComputersCounts,
      activeViewId,
    } = this.props;
    const { idDeletePopupToShow, isEditMode, isDEPEditMode } = this.state;
    const { counts: depcounts } = this.context;

    let counts;
    let isEdit;

    if (DEP) {
      counts = depcounts || DEPComputersCounts;
      isEdit = isDEPEditMode;
    } else {
      counts = computersCounts;
      isEdit = isEditMode;
    }

    let allViews = !DEP
      ? [...defaultViews, ...savedViews]
      : [...defaultViews, ...savedDepViews];
    const allSavedViews = [...savedDepViews, ...savedViews];
    const viewsOrder = get(userSettings, 'views_order.computers_list');
    const viewsDepOrder = get(userSettings, 'views_order.computers_dep_list');
    if (!DEP && viewsOrder) {
      allViews = sortBy(allViews, (item) => viewsOrder.indexOf(item.id));
    }
    if (DEP && viewsDepOrder) {
      allViews = sortBy(allViews, (item) => viewsDepOrder.indexOf(item.id));
    }

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, _snapshot) => (
            <DroppableWrapper ref={provided.innerRef}>
              {allViews.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={index}
                  isDragDisabled={!isEdit}
                >
                  {(provided, _snapshot) => (
                    <ViewItem
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      key={item.id}
                      active={item.id === activeViewId}
                      userView
                      onClick={!isEdit ? () => acceptView(item.id) : null}
                      {...(item.dataId ? { 'data-id': item.dataId } : {})}
                    >
                      <ViewTitleWrapper>
                        {isEdit && this.isSavedView(item.id, allSavedViews) && (
                          <DeleteViewIconWrapper>
                            <DeleteViewIcon
                              name="trash-can"
                              onClick={(e) => {
                                e.stopPropagation();
                                this.setState({ idDeletePopupToShow: item.id });
                              }}
                            />
                            {idDeletePopupToShow === item.id && (
                              <Toast
                                id={item.id}
                                visible={idDeletePopupToShow === item.id}
                                toggle={() =>
                                  this.setState({ idDeletePopupToShow: null })
                                }
                              >
                                <ConfirmIconWrapper
                                  onClick={() => this.deleteView(item.id, DEP)}
                                >
                                  <ConfirmIcon name="check" />
                                </ConfirmIconWrapper>
                                <ConfirmIconWrapper
                                  onClick={() =>
                                    this.setState({ idDeletePopupToShow: null })
                                  }
                                >
                                  <ConfirmIcon name="xmark" />
                                </ConfirmIconWrapper>
                              </Toast>
                            )}
                          </DeleteViewIconWrapper>
                        )}
                        <ViewTitle
                          title={
                            item.sidebarTitle ? item.sidebarTitle : item.name
                          }
                          isEditMode={isEdit}
                          savedView={
                            isEdit && this.isSavedView(item.id, allSavedViews)
                          }
                          truncate={
                            !isEdit &&
                            this.isSavedView(item.id, allSavedViews) &&
                            isViewChanged &&
                            item.id === activeViewId
                          }
                        >
                          {item.sidebarTitle ? item.sidebarTitle : item.name}
                        </ViewTitle>
                      </ViewTitleWrapper>
                      {!isEdit &&
                        this.isSavedView(item.id, allSavedViews) &&
                        isViewChanged &&
                        item.id === activeViewId && (
                          <EditViewIconWrapper>
                            <EditViewIcon
                              name="floppy-disk"
                              title="Save changes to view"
                              onClick={(event) => edit(event, item.id, DEP)}
                            />
                          </EditViewIconWrapper>
                        )}
                      {!isEdit && !isEmpty(counts) && (
                        <ComputersCountWrapper
                          active={item.id === activeViewId}
                        >
                          {counts[item.id]?.toLocaleString()}
                        </ComputersCountWrapper>
                      )}
                      {isEdit && (
                        <ReorderViewIconWrapper {...provided.dragHandleProps}>
                          <ReorderViewIcon name="grip-dots-vertical" />
                        </ReorderViewIconWrapper>
                      )}
                    </ViewItem>
                  )}
                </Draggable>
              ))}
            </DroppableWrapper>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  render() {
    const {
      userType,
      save,
      userSettings,
      toDefaultView,
      computersCounts,
      activeViewId,
      match,
    } = this.props;
    const { isEditMode, isDEPEditMode } = this.state;
    const { bannerTopOffset, counts } = this.context;

    const isDep = match.url === '/devices/auto-enroll';
    const savedViews = get(userSettings, 'saved_views.computers_list', []);
    const savedDEPViews = get(
      userSettings,
      'saved_views.computers_dep_list',
      [],
    );
    // const activeViewId = get(userSettings, 'active_views.computers_list');

    return (
      <Wrapper height={bannerTopOffset}>
        <Header>
          <H8>{i18n.t('Device Views')}</H8>
          <EditModeIconWrapper isEditMode={isEditMode}>
            <EditViewIcon
              name="gear"
              onClick={() => this.setState({ isEditMode: !isEditMode })}
            />
          </EditModeIconWrapper>
        </Header>
        <Views>
          <ViewItem
            active={
              !activeViewId ||
              ![
                ...KandjiViews.filter(({ isHidden }) => !isHidden),
                ...savedViews,
                ...DEPViews,
                ...savedDEPViews,
              ].find((v) => v.id === activeViewId)
            }
            onClick={!isEditMode ? toDefaultView : null}
          >
            <ViewTitleWrapper>
              <ViewTitle isEditMode={isEditMode}>All Devices</ViewTitle>
            </ViewTitleWrapper>
            {!isEditMode && !isEmpty(computersCounts) && (
              <ComputersCountWrapper active={!activeViewId}>
                {computersCounts.allComputersViewId?.toLocaleString()}
              </ComputersCountWrapper>
            )}
          </ViewItem>
          {this.renderViews(
            false,
            KandjiViews.filter(({ isHidden }) => !isHidden),
            savedViews,
            savedDEPViews,
          )}
        </Views>
        {!isEditMode && (
          <AddViewButtonWrapper>
            <AddViewButton
              onClick={(_e) => !isDep && save(false)}
              disabled={userType === UserTypes.super}
            >
              <AddViewIcon disabled={isDep}>
                <Icon name="circle-plus" />
              </AddViewIcon>
              <AddViewText disabled={isDep}>Save Current View</AddViewText>
            </AddViewButton>
          </AddViewButtonWrapper>
        )}
        <Header>
          <H8>{i18n.t('Automated Device Enrollment')}</H8>
          <EditModeIconWrapper isEditMode={isDEPEditMode} />
        </Header>
        <Views>
          <ViewItem
            active={
              !activeViewId ||
              ![...savedDEPViews, ...DEPViews].find(
                (v) => v.id === activeViewId,
              )
            }
            onClick={!isEditMode ? toDefaultView : null}
          />
          {this.renderViews(true, DEPViews)}
        </Views>
      </Wrapper>
    );
  }
}

SavedViews.defaultProps = {
  activeViewId: '',
  isViewChanged: false,
};

SavedViews.propTypes = {
  acceptView: PropTypes.func.isRequired,
  activeViewId: PropTypes.string,
  computersCounts: PropTypes.objectOf(PropTypes.number).isRequired,
  DEPComputersCounts: PropTypes.object.isRequired,
  edit: PropTypes.func.isRequired,
  isViewChanged: PropTypes.bool,
  save: PropTypes.func.isRequired,
  setSnackbar: PropTypes.func.isRequired,
  toDefaultView: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  updateUserRedux: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  userSettings: PropTypes.shape({
    active_views: PropTypes.object,
    saved_views: PropTypes.object,
    views_order: PropTypes.object,
  }).isRequired,
  userType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  userId: state.account.user.id,
  userType: state.account.user.type,
  userSettings: state.account.user.settings,
  feature_configuration: state.account?.company?.feature_configuration,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUser: callUpdateUser,
      updateUserRedux,
      setSnackbar: callSetSnackbar,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SavedViews);
