import { api, authApi } from 'app/api/base';
import { i18n } from 'i18n';
import {
  URL_BLUEPRINTS,
  URL_COMPUTERS,
  URL_COMPUTERS_FOR_FILTERS,
  URL_COMPUTER_CHANGE_BLUEPRINT,
  URL_DEVICES,
  URL_DEVICE_FAMILY_COUNTS,
  URL_REPORTS_COMPUTERS,
} from '../api/urls';
import { links } from '../common/constants';
import {
  cleanStorageForPaginationAndFilters,
  formatTime,
  getFileName,
  saveAs,
} from '../components/common/helpers';
import { getDeviceModel } from '../components/computer/computerHelpers';
import history from '../router/history';
import { computer as computerActions } from './action-types';
import { setSnackbar } from './ui';

export const getVerboseError = (err) => {
  let msg = 'Unexpected error occurred';
  try {
    const { status } = err.response;
    if (status === 401) {
      msg = 'You are not authorized to perform this action';
    } else {
      msg = err.response.data.error;
    }
  } catch (err) {
    // empty.
  }
  console.log(err);
  return new Error(msg);
};

export const getComputer =
  (computerId, activeTab, selectedParameter) => (dispatch, getState) =>
    api(URL_COMPUTERS + computerId)
      .get()
      .then((res) => {
        const computer = res.data;
        const { blueprintNames } = getState().data;
        const result = annotateComputer(computer, blueprintNames);
        dispatch(setComputer(result, activeTab, selectedParameter));
      });

export const getComputerMDMAgentLastCheckIn = (computerId) => (dispatch) =>
  api(`${URL_COMPUTERS + computerId}/lastcheckin`)
    .get()
    .then((res) => dispatch(setComputerMDMAgentLastCheckIn(res.data)));

export const getComputerFileVaultPRK = (computerId, showPreviousKeys = false) =>
  api(`${URL_COMPUTERS + computerId}/filevaultprk`)
    .get({ showPreviousKeys })
    .then((res) => res.data);

export const editComputerInfo = (computerId, data) => (dispatch, getState) =>
  api(URL_COMPUTERS + computerId)
    .get()
    .then((res) => {
      const computerInfo = res.data.info || {};
      const infoData = { ...computerInfo, ...data };
      return api(URL_COMPUTERS + computerId)
        .patch({ info: infoData })
        .then((res) => {
          const computer = res.data;
          const { blueprintNames } = getState().data;
          const result = annotateComputer(computer, blueprintNames);
          dispatch(setComputer(result));
        })
        .catch((err) => Promise.reject(getVerboseError(err)));
    });

export const startGetComputers = (blueprintId) => (dispatch, getState) => {
  const params = {};
  if (blueprintId) {
    params.blueprintId = blueprintId;
  }
  return api(URL_COMPUTERS)
    .get(params)
    .then((res) => {
      const computers = res.data.results;
      const { blueprintNames } = getState().data;
      const result = computers.map((computer) =>
        annotateComputer(computer, blueprintNames),
      );
      dispatch(setComputers(result));
    })
    .catch(() => {});
};

const annotateComputerBase = (computer, blueprintNames) => ({
  ...computer,
  computerId: computer.computerId,
  computerName: computer.name || 'No info',
  serial: computer.serial_number || 'No info',
  model: getDeviceModel(computer) || 'No info',
  blueprint: blueprintNames[computer.blueprint_id] || 'Not Found',
  assetUser: computer.user ? computer.user.name : null,
  assetTag: computer.asset_tag || 'No info',
  OS: computer.os_version
    ? [computer.os_version, computer.supplemental_os_version_extra]
        .filter(Boolean)
        .join(' ')
    : 'No info',
  agentVersion: computer.agent_version || 'No info',
});

export const getComputersForFilters = () => (dispatch, getState) => {
  dispatch({ type: computerActions.SET_COMPUTERS_FOR_FILTERS_IS_FETCHING });
  return api(URL_COMPUTERS_FOR_FILTERS)
    .get()
    .then((res) => {
      const computers = res.data.results;
      const { blueprintNames } = getState().data;
      const results = computers.map((computer) =>
        annotateComputerBase(computer, blueprintNames),
      );

      dispatch(setComputersForFilters(results));
    });
};

export const getComputerCount = () =>
  api(URL_DEVICE_FAMILY_COUNTS)
    .get()
    .then(
      ({
        data: {
          counts = {
            macos_count: 0,
            ios_count: 0,
            ipados_count: 0,
            tvos_count: 0,
          },
        },
      }) => {
        const macosCount = counts.macos_count;
        const otherCount =
          counts.ios_count + counts.ipados_count + counts.tvos_count;
        return {
          macos: macosCount,
          other: otherCount,
        };
      },
    )
    .catch(() => ({
      macos: 0,
      other: 0,
    }));

export const annotateComputer = (computer, blueprintNames) => ({
  ...annotateComputerBase(computer, blueprintNames),
  createdAt: computer.enrolled_at
    ? formatTime(computer.enrolled_at, true)
    : 'No info',
  createdAtNotFormatted: computer.enrolled_at
    ? formatTime(computer.enrolled_at, true, null, null, true)
    : 'No info',
  lastRunVerboseNotFormatted: computer.last_seen_dt
    ? formatTime(computer.last_seen_dt, null, null, null, true)
    : 'Not yet checked-in',
  lastRunVerbose: computer.last_seen_dt
    ? formatTime(computer.last_seen_dt)
    : 'Not yet checked-in',
  lastRunNotFormatted: computer.last_seen_dt
    ? formatTime(computer.last_seen_dt, false, null, null, true)
    : 'Not yet checked-in',
  lastRun: computer.last_seen_dt
    ? formatTime(computer.last_seen_dt, true)
    : 'Not yet checked-in',
  lastEnrollmentNotFormatted: computer.enrolled_at
    ? formatTime(computer.enrolled_at, false, null, null, true)
    : 'No info',
  lastEnrollment: computer.enrolled_at
    ? formatTime(computer.enrolled_at, true)
    : 'No info',
  firstEnrollmentNotFormatted: computer.first_enrolled_at
    ? formatTime(computer.first_enrolled_at, false, null, null, true)
    : 'No info',
  firstEnrollment: computer.first_enrolled_at
    ? formatTime(computer.first_enrolled_at, true)
    : 'No info',
  firstReportNotFormatted: computer.first_report
    ? formatTime(computer.first_report, false, null, null, true)
    : 'No info',
  agentInstalled: computer.agent_installed_at
    ? formatTime(computer.first_enrolled_at, true)
    : 'No info',
  agentInstalledNotFormatted: computer.agent_installed_at
    ? formatTime(computer.agent_installed_at, false, null, null, true)
    : 'No info',
  firstReport: computer.first_report
    ? formatTime(computer.first_report, true)
    : 'No info',
  lastCheckIn: computer.last_seen_dt || 'No info',
});

export const updateComputerInfo = (id, field) =>
  api(`/mdm/${id}/update-info/`).post({ to_update: field });

/* istanbul ignore next - implementation detail */
export const deleteComputers = (computers) => (dispatch, getState) =>
  api(URL_COMPUTERS).delete(computers);

export const changeComputerBlueprint = (computers, blueprintId) => () =>
  api(URL_COMPUTER_CHANGE_BLUEPRINT).put({ computers, blueprintId });

export const setComputers = (computers) => ({
  type: computerActions.SET_COMPUTERS,
  computers,
});

/* istanbul ignore next */
export const setPageScroll = (scrollY) => ({
  type: computerActions.SET_PAGE_SCROLL,
  scrollY,
});

export const setComputersForFilters = (computers) => ({
  type: computerActions.SET_COMPUTERS_FOR_FILTERS,
  computers,
});

export const clearComputers = () => ({
  type: computerActions.CLEAR_COMPUTERS,
});

export const setComputer = (computer, activeTab, selectedParameter) => ({
  type: computerActions.SET_COMPUTER,
  computer,
  activeTab,
  selectedParameter,
});

export const setDeviceBypassCode = (bypassCode) => ({
  type: computerActions.SET_DEVICE_BYPASS_CODE,
  bypassCode,
});

export const setDeviceRecoveryPassword = (recoveryPassword) => ({
  type: computerActions.SET_DEVICE_RECOVERY_PASSWORD,
  recoveryPassword,
});

export const setComputerMDMAgentLastCheckIn = (
  computerMDMAgentLastCheckIn,
) => ({
  type: computerActions.SET_COMPUTER_MDM_AGENT_LAST_CHECK_IN,
  computerMDMAgentLastCheckIn,
});

export const clearComputer = () => ({
  type: computerActions.CLEAR_COMPUTER,
});

export const updateComputer = (updates) => {
  if (updates.activeTab) {
    cleanStorageForPaginationAndFilters();
  }
  return {
    type: computerActions.UPDATE_COMPUTER,
    updates,
  };
};

export const mergeComputers =
  (serialNumber, hardwareUUID) => (dispatch, getState) =>
    api(`${URL_COMPUTERS}merge`)
      .post({ serial_number: serialNumber, hardware_uuid: hardwareUUID })
      .then((res) => {
        const computer = res.data;
        history.push(`${links.devices}/${computer.id}`);
        const { blueprintNames } = getState().data;
        const result = annotateComputer(computer, blueprintNames);
        dispatch(setComputer(result));
      });

export const queryComputers = (params) => (dispatch) => {
  dispatch(clearComputers());
  const { filters } = params;
  return api(URL_COMPUTERS)
    .get({
      ...params,
      filters: JSON.stringify(filters),
    })
    .then((res) => res.data)
    .catch(() => ({ items: [] }));
};

export const exportComputers = (filters, fields, ordering) => (dispatch) =>
  api(URL_REPORTS_COMPUTERS)
    .get({
      filters: JSON.stringify(filters),
      fields: JSON.stringify(fields),
      ordering,
    })
    .then((res) => {
      const name = getFileName('devices', '.csv');
      const content = `data:text/csv,${encodeURIComponent(res.data)}`;
      saveAs(content, name);
      dispatch(setSnackbar(i18n.t('File was exported')));
    })
    .catch(() => {
      dispatch(setSnackbar(i18n.common.error()));
    });

export const assignComputerUser = (computerId, user) => (dispatch, getState) =>
  api(URL_COMPUTERS + computerId)
    .patch({ user })
    .then((res) => {
      const computer = res.data;
      const { blueprintNames } = getState().data;
      const result = annotateComputer(computer, blueprintNames);
      dispatch(setComputer(result));
      /* istanbul ignore next */
      return result;
    });

export const updateComputerFields =
  (computerId, payload) => (dispatch, getState) =>
    api(URL_COMPUTERS + computerId)
      .patch(payload)
      .then((res) => {
        const computer = res.data;
        const { blueprintNames } = getState().data;
        const result = annotateComputer(computer, blueprintNames);
        dispatch(setComputer(result));
      })
      .catch((err) => Promise.reject(getVerboseError(err)));

export const createAppBlacklistRules = (blueprint, data) => (dispatch) =>
  api(`${URL_BLUEPRINTS}${blueprint}/blacklist`)
    .post(data)
    .then(() => {
      dispatch(setSnackbar('App restriction rules created'));
      return Promise.resolve();
    })
    .catch((err) => {
      if (err.response && err.response.status === 400) {
        dispatch(setSnackbar('Duplicate error'));
      }
      return Promise.reject(err);
    });

export const getComputersCountsForViews = (views) =>
  api(`${URL_COMPUTERS}counts`)
    .post(views)
    .then((response) => response.data)
    .catch(() => {});

export const getComputerBypassCode = (deviceId) => (dispatch) =>
  authApi(`${URL_DEVICES}${deviceId}/secrets/bypasscode`)
    .get()
    .then((res) => {
      const bypassCodeData = res.data;
      dispatch(setDeviceBypassCode(bypassCodeData));
    })
    .catch(() => {
      dispatch(setSnackbar(i18n.common.error()));
    });

export const getComputerRecoveryPassword = (deviceId) => (dispatch) =>
  authApi(`${URL_DEVICES}${deviceId}/secrets/recoverypassword`)
    .get()
    .then((res) => {
      const recoveryPasswordData = res.data?.recovery_password;
      dispatch(setDeviceRecoveryPassword(recoveryPasswordData));
    })
    .catch(() => {
      dispatch(setSnackbar(i18n.common.error()));
    });
