import { Banner, Select } from '@kandji-inc/bumblebee';
import PropTypes from 'prop-types';
import React from 'react';

import { Setting } from 'features/library-items/template';

import { MANAGE_MEDIA_ACCESS_FIELDS } from './constants';
import './manage-media-access.css';

const ManageMediaAccess = ({ update, isDisabled, settings }) => (
  <Setting.Card>
    <Setting.Header>
      <h3 className="b-h3">Manage Media Access</h3>
    </Setting.Header>
    <Setting.SubHeader>
      <p className="b-txt">
        Manage mount control settings for each media type. Note that &quot;Allow
        with authentication&quot; and &quot;Read-only with authentication&quot;
        options only apply to Standard users.
      </p>
      <Banner theme="info" className="mt-4" kind="block">
        <p className="b-txt-light">
          See the Apple deprecation note at the top of this library item.
        </p>
      </Banner>
      <Banner theme="info" className="mt-4" kind="block">
        <p>
          A restart is required for macOS to recognize and enforce changes made
          to media access. Managing Disk Image access in any capacity may
          prohibit the Kandji agent from using the DMG option in Custom Apps.
        </p>
      </Banner>
    </Setting.SubHeader>

    <Setting.Rows>
      <Setting.Row>
        <Setting.Controls className="k-manage-media-access-field-grid">
          {MANAGE_MEDIA_ACCESS_FIELDS.map((field) => (
            <Select
              key={`manage_media_access_${field.value}`}
              label={field.label}
              inputId={`${field.value}_select`}
              value={field.options.find(
                (opt) => opt.value === settings[field.value],
              )}
              options={field.options}
              onChange={(selected) => update(field.value, selected.value)}
              disabled={isDisabled}
            />
          ))}
        </Setting.Controls>
      </Setting.Row>
    </Setting.Rows>
  </Setting.Card>
);

ManageMediaAccess.propTypes = {
  update: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  settings: PropTypes.object.isRequired, // @todo define the shape of `settings`
};

export default ManageMediaAccess;
