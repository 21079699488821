import React, { useMemo } from 'react';

import {
  Box,
  Flex,
  Icon,
  Tooltip,
  TooltipProvider,
} from '@kandji-inc/nectar-ui';
import { Summary } from 'features/library-items/template';
import AssignRules from 'features/rules/library';
import useAccount from 'src/contexts/account';
import { useIntegrations } from 'src/features/integrations/hooks';
import { i18n } from 'src/i18n';

const Assignments = ({
  isEditing,
  isDisabled,
  blueprintOptions,
  devices,
  setModel,
  selectedBlueprints,
  excludedBlueprints,
  isAllBlueprints,
  setIsConfirmNavigationModalOpen,
  facetMap,
  rules,
  supportsRules,
  supportedDeviceFamilies,
  /* istanbul ignore next */ compatibleDeviceFamilies = [],
  supportsInstallOn,
}) => {
  const { currentCompany } = useAccount();
  const sortedBpOptions = useMemo(
    () => blueprintOptions?.sort((a, b) => a.label.localeCompare(b.label)),
    [blueprintOptions],
  );

  const { countOfUserDirectoryIntegrations, hasUserDirectoryIntegration } =
    useIntegrations();

  const deviceOptions = supportedDeviceFamilies.map((device) => ({
    label: device,
    value: device,
  }));

  if (compatibleDeviceFamilies.length > 0) {
    compatibleDeviceFamilies.forEach((deviceFamily) => {
      deviceOptions.push({
        label: (
          <Flex className="compatible-app-option" gap="xs" alignItems="center">
            {deviceFamily}
            <TooltipProvider>
              <Tooltip
                css={{ zIndex: 1000 }}
                side="top"
                trigger="hover"
                content={i18n.t(
                  'This app is not optimized for {deviceFamily}, but can be optionally installed.',
                  { deviceFamily },
                )}
              >
                <Flex>
                  <Icon name="circle-info" size={16} />
                </Flex>
              </Tooltip>
            </TooltipProvider>
          </Flex>
        ),
        value: deviceFamily,
      });
    });
  }

  // When a library item supports the "Install on" dropdown, the device families to install on is a
  // potentially narrowed down list. However, if a library item does not support the "Install on"
  // dropdown, the device families to install on match the supported device families.
  const installOnDeviceFamilies = supportsInstallOn
    ? devices?.map((device) => device.value)
    : supportedDeviceFamilies;

  return (
    <Box mt5>
      {supportsInstallOn && (
        <Summary.AssignDevices
          devices={deviceOptions}
          selectedDevices={devices}
          compatibleDeviceFamilies={compatibleDeviceFamilies}
          isDisabled={isDisabled}
          onChange={({ selectedDevices }) =>
            setModel((p) => ({ ...p, devices: selectedDevices }))
          }
        />
      )}

      <Summary.Assign>
        <Summary.AssignBlueprints
          isDisabled={isDisabled || !blueprintOptions}
          onChange={({
            selectedBlueprints,
            excludedBlueprints,
            isAllBlueprints,
          }) =>
            setModel((p) => ({
              ...p,
              selectedBlueprints,
              excludedBlueprints,
              isAllBlueprints,
            }))
          }
          blueprints={sortedBpOptions || []}
          selectedBlueprints={selectedBlueprints}
          excludedBlueprints={excludedBlueprints}
          isAllBlueprints={isAllBlueprints}
          isEditing={isEditing}
          onAddNew={() => setIsConfirmNavigationModalOpen(true)}
        />

        {supportsRules &&
          currentCompany?.feature_configuration?.library_item_rules
            ?.enabled && (
            <AssignRules
              facetMap={facetMap}
              rules={rules}
              setModel={setModel}
              isDisabled={isDisabled}
              supportedDeviceFamilies={supportedDeviceFamilies}
              installOnDeviceFamilies={installOnDeviceFamilies}
              countOfUserDirectoryIntegrations={
                countOfUserDirectoryIntegrations
              }
              hasUserDirectoryIntegration={hasUserDirectoryIntegration}
            />
          )}
      </Summary.Assign>
    </Box>
  );
};

export default React.memo(Assignments);
