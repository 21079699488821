import { canAddLibraryTypes } from '../common';
import allLibraryItemsConfiguration from './items';

const libraryItemsToAdd = Object.values(allLibraryItemsConfiguration).filter(
  (item) => item.canAdd !== canAddLibraryTypes.CANNOT_ADD,
);

export { libraryItemsToAdd };
export * from './items';
export default allLibraryItemsConfiguration;
