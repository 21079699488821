const bytesToSize = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (!bytes) {
    return '0 Bytes';
  }
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  return `${Math.round(bytes / 1024 ** i, 2)} ${sizes[i]}`;
};

const validateExtension = (allowedFileTypes, file) =>
  allowedFileTypes.some((ending) => file.name.endsWith(`.${ending}`));

export { bytesToSize, validateExtension };
