import { useQuery } from '@tanstack/react-query';
// istanbul ignore file
import * as React from 'react';
import type {
  PrismViewSchema,
  SavedViewsResponseSchema,
} from 'src/features/visibility/prism/types/prism.types';
import { prismService } from '../../../data-service/prism-service';

export interface SavedView extends PrismViewSchema {
  columnFilters: {
    attributeKey: string | undefined;
    columnKey: string;
    filters: any;
    id: string;
    value: any;
  }[];
  columnVisibility: Record<string, boolean> | undefined;
  columnOrder: string[] | undefined;
  columnSizing: Record<string, number | undefined> | undefined;
}

export const useSavedViewsQuery = (category?: string) =>
  useQuery({
    queryKey: ['prism', 'savedViews'],
    queryFn: () => prismService.getSavedViews(),
    select: React.useCallback(
      (data: SavedViewsResponseSchema): SavedView[] =>
        data
          .filter((view) => view.category === category)
          .map((view) => {
            const columnFilters = Object.entries(view.filters).map(
              ([columnKey, filters]) => ({
                id: columnKey,
                value: filters,
                key: columnKey,
                attributeKey: view.category,
                columnKey,
                filters,
              }),
            );

            const columnVisibility = {};
            const columnOrder: string[] = [];
            const columnSizing = {};
            view.columns?.forEach((col) => {
              if (col.name) {
                columnVisibility[col.name] = !!col.visible;
                columnOrder.push(col.name);
                columnSizing[col.name] = col.size;
              }
            });

            return {
              ...view,
              columnFilters,
              columnOrder,
              columnVisibility,
              columnSizing,
            };
          }),
      [category],
    ),
  });
