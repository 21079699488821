import { Code } from '@kandji-inc/nectar-ui';
import { createColumnHelper } from '@tanstack/react-table';
import {
  formatDate,
  getDeviceCell,
} from 'src/features/visibility/prism/utils/column-utils';
import type { PulseFileCheckDeviceSchema } from 'src/features/visibility/pulse/types/pulse.types';
import type { PulseCheckDetailColumns } from '../utils/pulse-column-types';
import { JsonDownloadButton } from './JsonDownloadButton';
import { PulseResultsTable } from './PulseResultsTable';

interface Results {
  path: string;
  hash: string;
  metadata: string;
}

interface PulseFileCheckResultsColumns extends PulseCheckDetailColumns {
  results: Results;
}

const columnHelper = createColumnHelper<PulseFileCheckResultsColumns>();

/*
  TODO:
  - Missing:
    - Content type: content_type
    - Display name: display_name
    - Date added: creation_date
    - Date modified: modification_date
    - Owning user: owning_user
    - Owning group: owning_group
    - Invisible: invisible
    - Spotlight metadata: spotlight_metadata
    - POSIX permissions: posix_permissions
*/

const fileCheckColumns = [
  columnHelper.accessor((row) => row.device__name, {
    id: 'device__name',
    cell: (info) => getDeviceCell(info),
    header: () => 'Device',
  }),
  columnHelper.accessor((row) => row.device__user_name, {
    id: 'device_user',
    cell: (row) => row.getValue(),
    header: () => 'User',
  }),
  columnHelper.accessor((row) => row.results?.path, {
    id: 'path',
    cell: (row) => <Code>{row.getValue()}</Code>,
    header: () => 'Path',
  }),

  columnHelper.accessor((row) => row.results?.hash, {
    id: 'hash',
    cell: (row) => <Code>{row.getValue()}</Code>,
    header: () => 'Hash',
  }),
  columnHelper.accessor((row) => row.results?.metadata, {
    id: 'metadata',
    cell: (row) => <JsonDownloadButton data={row.getValue()} />,
    header: () => 'File Metadata',
  }),
  columnHelper.accessor((row) => row.updated_at, {
    id: 'updated_at',
    cell: (row) => formatDate(row.getValue()),
    header: () => 'Last Collected',
  }),
];

export const PulseFileCheckResults = ({
  jobDevicesData,
  isLoading,
  onRerunJob,
}: {
  jobDevicesData: PulseFileCheckDeviceSchema[];
  isLoading: boolean;
  onRerunJob: () => void;
}) => {
  return (
    <PulseResultsTable
      jobDevicesData={jobDevicesData}
      isLoading={isLoading}
      columns={fileCheckColumns}
      onRerunJob={onRerunJob}
    />
  );
};
