import { Icon } from '@kandji-inc/bumblebee';
import PropTypes from 'prop-types';
import React from 'react';
import Const from '../Const';

const insertBtnDefaultClass = 'react-bs-table-add-btn';

const InsertButton = (props) => {
  const {
    btnContextual,
    className,
    onClick,
    icon,
    btnText,
    children,
    ...rest
  } = props;
  const content = children || (
    <span>
      <Icon name={icon} size="sm" /> {btnText}
    </span>
  );
  return (
    <button
      type="button"
      className={`btn ${btnContextual} ${insertBtnDefaultClass} ${className}`}
      onClick={onClick}
      {...rest}
    >
      {content}
    </button>
  );
};

InsertButton.propTypes = {
  btnText: PropTypes.string,
  btnContextual: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
};

InsertButton.defaultProps = {
  btnText: Const.INSERT_BTN_TEXT,
  btnContextual: 'btn-info',
  className: '',
  onClick: undefined,
  icon: 'circle-plus',
};

export default InsertButton;
