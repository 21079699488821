import { colors } from 'app/common/constants';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useOnClickOutside } from './hooks';

const Wrapper = styled.section`
  z-index: 1000;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  background: #fff;
  min-width: 0;
  min-height: 50px;
  left: 30px;
  border-radius: 5px;
  box-shadow:
    0 18px 35px rgba(50, 50, 93, 0.1),
    0 8px 15px rgba(0, 0, 0, 0.07);
  opacity: 0;
  //transition: width 1s ease;
  transition: opacity 1s;
  &:before {
    content: '';
    width: 0;
    height: 0;
    left: -20px;
    position: absolute;
    z-index: 9999999999;
    border: 10px solid transparent;
    border-right-color: ${colors.white};
  }
  &.show {
    min-width: 150px;
    opacity: 1;
  }
`;

const Toast = ({ id, children, toggle, visible }) => {
  const [ready, setReady] = useState(false);
  const ToastRef = useRef(null);
  useEffect(() => {
    setReady(true);
  }, []);
  const handleClickOutside = () => {
    if (visible && typeof toggle === 'function') {
      toggle();
    }
  };
  useOnClickOutside(ToastRef, handleClickOutside);

  return (
    <Wrapper
      ref={ToastRef}
      id={`confirm-toast-${id}`}
      className={ready ? 'show' : ''}
    >
      {children}
    </Wrapper>
  );
};

export default Toast;
