import { colors } from 'app/common/constants';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex: 0 0 ${(props) => props.size};
  height: ${(props) => props.size};
  width: ${(props) => props.size};
  font-size: calc(${(props) => props.size} * 0.8);
  border-radius: 50%;
  border: ${(props) =>
    props.border ? `${props.border} solid ${colors['grey-20']}` : 'none'};
  position: relative;
  transition: 0.3s ease;
  background: linear-gradient(
    90deg,
    ${(props) => colors[props.color]} 50%,
    ${(props) => colors[props.color]}dd 50%
  );
  overflow: hidden;
  // Safari border-radius + overflow: hidden + CSS transform fix
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  &.editable {
    &:after {
      cursor: pointer;
      content: 'Edit';
      color: ${colors['marengo-700']};
      background-color: white;
      height: 0;
      font-size: 8px;
      font-weight: 600;
      position: absolute;
      display: flex;
      justify-content: center;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      bottom: -15px;
      left: 0;
      right: 0;
      opacity: 0;
      transition:
        opacity 0.3s,
        bottom 0.3s,
        height 0.3s;
    }
    &:hover {
      &:after {
        height: 15px;
        bottom: 0;
        opacity: 1;
      }
    }
  }
`;

const BlackIconMixin = `
  color: ${colors.black};
  text-shadow: none;
`;

const Icon = styled.i`
  color: ${colors.white};
  margin: auto;
  text-shadow: 0 0.05rem #00000050;
  font-size: 40%;
  ${(props) =>
    props.backgroundColor === 'yellow-500' ||
    props.backgroundColor === 'green-300'
      ? BlackIconMixin
      : null};
`;

const AwesomeIconCircle = ({
  color,
  icon,
  onClick,
  border,
  size,
  editable,
  margin,
}) => (
  <Wrapper
    onClick={onClick}
    color={color}
    size={size}
    className={editable ? 'editable' : null}
    border={border}
  >
    <Icon
      className={`${icon}`}
      style={{ marginTop: margin }}
      size={size}
      backgroundColor={color}
    />
  </Wrapper>
);

AwesomeIconCircle.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.string,
  editable: PropTypes.bool,
  border: PropTypes.string,
  margin: PropTypes.string,
};

AwesomeIconCircle.defaultProps = {
  onClick: null,
  size: '10rem',
  editable: false,
  border: '.5rem',
  margin: null,
};

export default AwesomeIconCircle;
