import { Icon } from '@kandji-inc/bumblebee';
import classSet from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class PageButton extends Component {
  pageBtnClick = (e) => {
    e.preventDefault();
    console.log(window.location);
    if (window.location.pathname === '/blueprints') {
      localStorage.setItem('blueprintsTablePageNumber', this.props.pageNumber);
    }
    this.props.changePage(this.props.pageNumber);
  };

  render() {
    const {
      isNextButton,
      isPreButton,
      isFirstButton,
      isLastButton,
      active,
      hidden,
      disable,
    } = this.props;
    const classes = classSet({
      'page-link-icon':
        isNextButton || isPreButton || isFirstButton || isLastButton,
      active,
      hidden,
      'page-link': true,
    });
    return (
      <li className="page-item">
        <a
          href="#"
          onClick={(e) => {
            this._pageButton.blur();
            this.pageBtnClick(e);
          }}
          ref={(el) => (this._pageButton = el)}
          className={classes}
          disabled={disable}
        >
          {isNextButton && <Icon name="angle-right" />}
          {isPreButton && <Icon name="angle-left" />}
          {isFirstButton && (
            <>
              <Icon name="angle-left" />
              <Icon name="angle-left" />
            </>
          )}
          {isLastButton && (
            <>
              <Icon name="angle-right" />
              <Icon name="angle-right" />
            </>
          )}
          {!isNextButton &&
            !isPreButton &&
            !isFirstButton &&
            !isLastButton &&
            this.props.children}
        </a>
      </li>
    );
  }
}
PageButton.propTypes = {
  title: PropTypes.string,
  changePage: PropTypes.func,
  active: PropTypes.bool,
  disable: PropTypes.bool,
  hidden: PropTypes.bool,
  children: PropTypes.node,
  pageNumber: PropTypes.number,
};

export default PageButton;
