import uuid from 'uuid';

import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class KernelExtensionService extends NewLibraryItemService {
  static generateBundleId() {
    return {
      BundleId: null,
      DisplayName: null,
      id: uuid(),
    };
  }

  static generateTeamId() {
    return {
      AllowedKernelExtensions: [], // AKA Bundle IDs
      AllowedTeamIdentifiers: null,
      DisplayName: null,
      id: uuid(), // Only used for client-side UI, not sent to API
    };
  }
}

export const kernelExtensionService = new KernelExtensionService();

export default KernelExtensionService;
