import { useBuilder } from '../..';

/**
 * A hook containing utilities that modify select menu options
 * @returns various utilities
 */
const useOption = () => {
  const { installOnDeviceFamilies, hasUserDirectoryIntegration } = useBuilder();

  /**
   * Determines an option's isDisabled value
   * @param {Object} option - { value, label, subject, deviceFamilies }
   * @returns a boolean
   */
  const getIsDisabled = (option) => {
    const { subject, deviceFamilies } = option;

    const hasDeviceFamilyToBeInstalledOn = deviceFamilies?.some((family) =>
      installOnDeviceFamilies?.includes(family),
    );

    // Checks if the option is for "User Directory"
    if (subject === 'user_directory_integration') {
      // Disable option if no User Directory integrations are available
      if (!hasUserDirectoryIntegration) {
        return true;
      }
    }

    // Disable option if it has no supported device families to be installed on
    if (!hasDeviceFamilyToBeInstalledOn) {
      return true;
    }

    // Option is enabled by default
    return false;
  };

  return {
    getIsDisabled,
  };
};

export default useOption;
