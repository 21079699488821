type Pagination = {
  startCount: number;
  endCount: number;
  totalPages: number;
};

const usePagination = (
  page: number,
  pageSize: number,
  totalDataCount: number,
): Pagination => {
  const startCount = pageSize * (page - 1) + 1;
  const endCount = Math.min(pageSize * page, totalDataCount);
  const totalPages = Math.ceil(totalDataCount / pageSize);

  return {
    startCount,
    endCount,
    totalPages,
  };
};

export default usePagination;
