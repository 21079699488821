/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query';
import { api } from 'src/app/api/base';

export type ComputerTag = {
  computer_count: number;
  id: string;
  is_used_in_rule: boolean;
  name: string;
};

const useGetComputerTags = (computerIds = []) => {
  const { data: apiRes, ...rest } = useQuery({
    queryKey: ['computer-tags', ...computerIds],
    queryFn: () => api('computer-tags').post({ computer_ids: computerIds }),
  });

  const data = apiRes?.data;

  return {
    data,
    ...rest,
  };
};

export { useGetComputerTags };
