import type { PrismCategoryUri } from 'src/features/visibility/prism/types/prism.types';

export const getIconByDeviceFamily = (deviceFamily: string) => {
  switch (deviceFamily) {
    case 'mac':
      return 'sf-desktop-computer';
    case 'iphone':
      return 'sf-iphone';
    case 'ipad':
      return 'sf-ipad-landscape';
    case 'apple_tv':
      return 'sf-tv';
    default:
      return 'sf-desktop-computer';
  }
};

export const getSupportedDeviceFamiliesByPrismCategory = (
  prismCategory: PrismCategoryUri | undefined,
) => {
  switch (prismCategory) {
    case 'device_information':
      return ['mac', 'iphone', 'ipad', 'apple_tv'];
    case 'activation_lock':
      return ['mac', 'iphone', 'ipad'];
    case 'filevault':
      return ['mac'];
    case 'startup_settings':
      return ['mac'];
    case 'launch_agents_and_daemons':
      return ['mac'];
    case 'system_extensions':
      return ['mac'];
    case 'gatekeeper_and_xprotect':
      return ['mac'];
    case 'transparency_database':
      return ['mac'];
    case 'kernel_extensions':
      return ['mac'];
    case 'local_users':
      return ['mac'];
    case 'installed_profiles':
      return ['mac', 'iphone', 'ipad', 'apple_tv'];
    case 'application_firewall':
      return ['mac'];
    case 'apps':
      return ['mac', 'iphone', 'ipad', 'apple_tv'];
    case 'desktop_and_screensaver':
      return ['mac'];
    case 'certificates':
      return ['mac', 'iphone', 'ipad', 'apple_tv'];
    default:
      return [];
  }
};

export const getCopyByPrismCategory = (prismCategory: PrismCategoryUri) => {
  switch (prismCategory) {
    case 'device_information':
      return 'General information about enrolled devices.';
    case 'filevault':
      return 'The FileVault and Recovery Key state for macOS devices.';
    case 'startup_settings':
      return 'Startup security settings for macOS devices.';
    case 'launch_agents_and_daemons':
      return 'The installed Launch Agents and Daemons on macOS devices.';
    case 'activation_lock':
      return 'Activation Lock details for macOS, iOS, and iPadOS devices.';
    case 'system_extensions':
      return 'System Extensions details for macOS devices.';
    case 'gatekeeper_and_xprotect':
      return 'Gatekeeper and XProtect details for macOS devices.';
    case 'transparency_database':
      return 'Transparency, Consent, and Control (TCC) details for macOS devices running macOS 11 or later.';
    case 'kernel_extensions':
      return 'Kernel Extensions details for macOS devices.';
    case 'local_users':
      return 'Local Users details for macOS devices.';
    case 'installed_profiles':
      return 'Installed Profiles details for macOS, iOS, iPadOS, and tvOS devices.';
    case 'application_firewall':
      return 'The application layer firewall configuration for a macOS device.';
    case 'apps':
      return 'The applications installed on macOS, iOS, iPadOS, and tvOS devices.';
    case 'desktop_and_screensaver':
      return 'Desktop and Screensaver details for macOS devices.';
    case 'certificates':
      return 'Certificates installed on macOS, iOS, iPadOS, and tvOS devices.';
    default:
      return 'Unknown';
  }
};

export const SUPPORT_URL =
  'https://support.kandji.io/support/solutions/articles/72000611921';
