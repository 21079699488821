import { Button, Flex, Grid, styled } from '@kandji-inc/nectar-ui';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import type { MutableRefObject, RefObject } from 'react';

import { LAYOUT_CONFIG, PREVIEW_CONFIG } from '../common';
import ActionBar from './ActionBar';
import Dock from './Dock';
import Page from './Page';

import type {
  HomeScreenLayoutDeviceKind,
  HomeScreenLayoutDeviceModelSettings,
  HomeScreenLayoutItem,
  HomeScreenLayoutPreviewContextKind,
  PreviewStyleKind,
  UpdateFn,
} from '../../home-screen-layout.types';

const INITIAL_DEVICE_WIDTH = 525;
const { height: heightCss } = LAYOUT_CONFIG;

const PageIndicator = styled('div', {
  height: '$2',
  width: '$2',
  borderRadius: '50%',
  backgroundColor: '$neutral60',
  willChange: 'opacity',
  transition: 'opacity 250ms ease',

  variants: {
    active: {
      true: {
        opacity: '1',
      },
      false: {
        opacity: '0.5',
      },
    },
  },
});

interface PreviewProps {
  readonly kind: HomeScreenLayoutDeviceKind;
  readonly pages: HomeScreenLayoutDeviceModelSettings['Pages'];
  readonly dock: HomeScreenLayoutDeviceModelSettings['Dock'];
  readonly onRemove: (
    item: HomeScreenLayoutItem,
    ctx: {
      kind: HomeScreenLayoutDeviceKind;
      context: HomeScreenLayoutPreviewContextKind;
      disabled: boolean;
      isRemovable: boolean;
    },
  ) => void;
  readonly onAddPage: () => void;
  readonly onChangePage: (change: number) => void;
  readonly disabled: boolean;
  readonly layoutRef?: RefObject<HTMLDivElement>;
  readonly combinedRefs?: MutableRefObject<{
    droppableContainerRef?: HTMLDivElement | null;
    pageContainerRef?: HTMLDivElement | null;
  }>;
  readonly currentPage: number;
  readonly onRemovePage: () => void;
  readonly onMovePage: (number) => void;
  readonly onCreateFolder: (apps) => void;
  readonly currentFolderPage: number;
  readonly update: UpdateFn;
}

const Preview = (props: PreviewProps) => {
  const {
    kind,
    pages,
    dock,
    onAddPage,
    onRemove,
    disabled = false,
    layoutRef = null,
    combinedRefs = null,
    currentPage,
    onChangePage,
    onRemovePage,
    onMovePage,
    onCreateFolder,
    currentFolderPage,
    update,
  } = props;
  const [style, setStyle] = useState<PreviewStyleKind>('portrait');
  const pageContainerRef = useRef(null);
  const [pageContainerWidth, setPageContainerWidth] =
    useState<number>(INITIAL_DEVICE_WIDTH);
  const [selectedApps, setSelectedApps] = useState([]);

  const canAddNewPage = getCanAddNewPage(
    pages,
    currentPage,
    PREVIEW_CONFIG[kind],
  );

  const canMovePageLeft =
    !!pages[currentPage - 1] && pages[currentPage].length > 0;
  const canMovePageRight =
    !!pages[currentPage + 1] && pages[currentPage].length > 0;

  useLayoutEffect(() => {
    if (pageContainerRef.current) {
      // Get the width of a page container so we can slide the pages accurately:
      setPageContainerWidth(
        pageContainerRef.current.getBoundingClientRect().width,
      );
    }
  }, [style]);

  useEffect(() => {
    if (disabled) {
      setSelectedApps([]);
    }
  }, [disabled]);

  const previewConfig = PREVIEW_CONFIG[kind];
  const isAtMaxPerFolder = selectedApps.length >= previewConfig.maxPerFolder;

  useEffect(() => {
    if (kind === 'ipad' && layoutRef?.current) {
      const portraitHeight = getComputedStyle(
        layoutRef.current,
      ).getPropertyValue(heightCss.portrait.cssVar);
      const landscapeHeight = getComputedStyle(
        layoutRef.current,
      ).getPropertyValue(heightCss.landscape.cssVar);

      layoutRef.current.style.setProperty(
        heightCss.cssVar,
        style === 'landscape' ? landscapeHeight : portraitHeight,
      );
    }
  }, [layoutRef, kind, style]);

  const handleChangePage = (change: number) => {
    // Reset the selected apps:
    setSelectedApps([]);

    onChangePage(change);
  };

  const handleCancelMultiSelect = /* istanbul ignore next */ () => {
    setSelectedApps([]);
  };

  const handleSelectApp = (app) => {
    setSelectedApps((prevSelections) => {
      const filteredOutExisting = prevSelections.filter(
        (selectedItem) => selectedItem.id !== app.id,
      );
      const isDeselect = filteredOutExisting.length < prevSelections.length;

      // Only allow deselect when maxPerFolder items are already selected:
      if (isAtMaxPerFolder) {
        return isDeselect ? filteredOutExisting : prevSelections;
      }

      return isDeselect ? filteredOutExisting : [...prevSelections, app];
    });
  };

  return (
    <Grid
      css={{
        position: 'relative',
        gridTemplateRows: 'auto 1fr',
        backgroundColor: '$neutral5',
        borderRadius: '$1',
        border: '1px solid $neutral20',
      }}
      // @ts-expect-error -- need to allow id on this nectar component
      id={`previewContainer_${kind}`}
    >
      <ActionBar
        kind={kind}
        style={style}
        onUpdateStyle={setStyle}
        onAddPage={onAddPage}
        canAddPage={canAddNewPage}
        disabled={disabled || pages.length >= PREVIEW_CONFIG[kind].maxPages}
        onRemovePage={onRemovePage}
        onMovePage={onMovePage}
        canRemovePage={currentPage !== 0}
        canMovePageLeft={canMovePageLeft}
        canMovePageRight={canMovePageRight}
        selectedApps={selectedApps}
        onCancel={handleCancelMultiSelect}
        onCreateFolder={() => {
          onCreateFolder(selectedApps);
          setSelectedApps([]);
        }}
      />
      <Flex
        flow="column"
        gap="sm"
        justifyContent="end"
        alignItems="center"
        css={{
          overflow: 'hidden',
          position: 'relative',
          minHeight: '625px',
          paddingBottom: '$4',
        }}
      >
        <Flex
          my4
          gap="md"
          css={{
            position: 'relative',
            top: '0px',
            width: pageContainerWidth,
            transform: `translateX(${
              currentPage > 0
                ? `calc(-${currentPage * (pageContainerWidth + 12)}px)` // 12 refers to the gap="md" prop above
                : '0px'
            })`,
            transition: 'transform 250ms ease',
            willChange: 'transform',
          }}
        >
          {pages.map((page, pageIndex) => (
            <Page
              key={pageIndex}
              kind={kind}
              pageIndex={pageIndex}
              currentPage={currentPage}
              page={page}
              dock={dock}
              disabled={disabled}
              combinedRefs={combinedRefs}
              orientationStyle={style}
              pageContainerRef={pageContainerRef}
              currentFolderPage={currentFolderPage}
              isAtMaxPerFolder={isAtMaxPerFolder}
              onSelectApp={handleSelectApp}
              selectedApps={selectedApps}
              onRemove={onRemove}
              update={update}
            />
          ))}
        </Flex>

        <Flex
          justifyContent="space-between"
          css={{
            pointerEvents: 'none',
            position: 'absolute',
            top: 'calc(50% - 40px)',
            left: '0px',
            width: '100%',
            padding: '0 $4',
          }}
        >
          <Button
            role="button"
            aria-label="Previous Page"
            variant="subtle"
            disabled={currentPage < 1}
            onClick={
              /* istanbul ignore next - covered 'Next Page' with same function call */ () =>
                handleChangePage(-1)
            }
            icon={{ position: 'left', name: 'arrow-left' }}
            css={{
              pointerEvents: 'all',
            }}
          />
          <Button
            role="button"
            aria-label="Next Page"
            variant="subtle"
            disabled={currentPage >= pages.length - 1}
            onClick={
              /* istanbul ignore next - test covers this :shrug: */ () =>
                handleChangePage(1)
            }
            icon={{ position: 'left', name: 'arrow-right' }}
            css={{
              pointerEvents: 'all',
            }}
          />
        </Flex>

        <Flex gap="xs">
          {pages.map((_, idx) => (
            <PageIndicator
              key={`page-indicator-${idx}`}
              active={currentPage === idx}
            />
          ))}
        </Flex>

        <Flex
          css={{
            position: 'absolute',
            bottom: '80px',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Dock
            dock={dock}
            kind={kind}
            onRemove={onRemove}
            style={style}
            disabled={disabled}
          />
        </Flex>
      </Flex>
    </Grid>
  );
};

export function getCanAddNewPage(
  pages: HomeScreenLayoutDeviceModelSettings['Pages'],
  currentPage: number,
  config,
): boolean {
  const isNotAtMaxPages = pages.length < config.maxPages;
  const isCurrentPageNotEmpty = pages[currentPage].length > 0;
  const isNextPageEmpty = !pages[currentPage + 1];
  const isNextPageNotEmpty =
    !!pages[currentPage + 1] && pages[currentPage + 1]?.length > 0;

  return (
    (isNotAtMaxPages && isCurrentPageNotEmpty && isNextPageEmpty) ||
    (isCurrentPageNotEmpty && isNextPageNotEmpty)
  );
}

export default Preview;
