import {
  Button,
  Flex,
  Text,
  TextArea,
  TextField,
  styled,
} from '@kandji-inc/nectar-ui';
import { LabelText } from '../views/CreatePulseCheck/components/LabelText';

const DescriptionArea = styled(TextArea, {
  width: '600px',
  resize: 'none',
});

interface NameDescriptionProps {
  reportName: string;
  setReportName: (value: string) => void;
  reportNameError: string;
  setReportNameError: (value: string) => void;
  reportDescription: string;
  setReportDescription: (value: string) => void;
  isAddingDescription: boolean;
  setIsAddingDescription: (value: boolean) => void;
}

export const NameDescription = ({
  reportName,
  setReportName,
  reportNameError,
  setReportNameError,
  reportDescription,
  setReportDescription,
  isAddingDescription,
  setIsAddingDescription,
}: NameDescriptionProps) => (
  <Flex flow="column" gap="md">
    <TextField
      name="reportName"
      value={reportName}
      css={{ width: 600 }}
      onChange={(e) => {
        setReportName(e.target.value);
        setReportNameError('');
      }}
      placeholder="Enter report name"
      showClearButton={reportName.length > 0}
      onClear={() => {
        setReportName('');
        setReportNameError('');
      }}
      state={reportNameError ? 'error' : 'default'}
      hint={{
        label: reportNameError,
        variant: reportNameError ? 'error' : 'default',
      }}
    />
    {isAddingDescription ? (
      <Flex flow="column" gap="sm">
        <Flex gap="sm">
          <LabelText>Description</LabelText>
          <Text variant="disabled">(optional)</Text>
        </Flex>
        <DescriptionArea
          value={reportDescription}
          onChange={(e) => setReportDescription(e.currentTarget.value)}
        />
        <Flex alignItems="center" gap="xs">
          <Button
            compact
            icon={{ name: 'fa-xmark-small', position: 'left' }}
            variant="subtle"
            onClick={() => {
              setIsAddingDescription(false);
              setReportDescription('');
            }}
          >
            Remove description
          </Button>
        </Flex>
      </Flex>
    ) : (
      <Flex alignItems="center" gap="xs">
        <Button
          compact
          icon={{ name: 'fa-plus-minus-small', position: 'left' }}
          variant="subtle"
          onClick={() => {
            setIsAddingDescription(true);
          }}
        >
          Add description
        </Button>
        <Text variant="disabled">(optional)</Text>
      </Flex>
    )}
  </Flex>
);
