import { styled } from '@kandji-inc/nectar-ui';
import React from 'react';
import colors from 'src/features/blueprints/colors';

type Colors = keyof typeof colors;

interface BlueprintIconProps {
  iconName: string;
  color: Colors;
  size?: number;
  iconSize?: number;
}

const IconContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  width: '14px',
  height: '14px',

  '& i': {
    color: '$neutral0',
    fontSize: '8px',
    transform: 'translateY(1px)',
  },
});

const BlueprintIcon = ({
  iconName,
  color,
  size = 14,
  iconSize = 8,
}: BlueprintIconProps) => {
  return (
    <IconContainer
      css={{ backgroundColor: colors[color], width: size, height: size }}
    >
      <i className={iconName} style={{ fontSize: iconSize }} />
    </IconContainer>
  );
};

export default BlueprintIcon;
