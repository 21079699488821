import { isEmpty, isEqual, xorWith } from 'lodash';

import { extractRuleData } from 'src/features/rules/transformers';
import { i18n } from 'src/i18n';

/**
 * Retrieves the values from a list of selected devices and sorts them
 * @param {Array} devices - an list of selected devices in { label, value } format
 * @returns a list of sorted strings, ex. ['Apple TV', 'iPhone', 'Mac']
 */
const getSortedListOfDevices = (devices) =>
  devices?.map((device) => device.value).sort();

/**
 * Determines if a warning modal should appear if the library item was previously installed on devices
 * via a blueprint and assignment rules and/or the devices to install on have been edited
 * @param {Object} model - the potentially edited library item object
 * @param {Object} savedModel - the previously saved library item object
 * @returns whether or not the warning modal should appear
 */
const shouldShowWarningModal = (model, savedModel) => {
  const wasPreviouslyDeployedOnDevices = savedModel.selectedBlueprints?.length;

  const newInstallOn = getSortedListOfDevices(model.devices);
  const savedInstallOn = getSortedListOfDevices(savedModel.devices);
  const installOnChanged = !isEqual(newInstallOn, savedInstallOn);

  const newRules = model.rules?.and?.map((rule) => {
    const newRule = extractRuleData(rule);

    // The 'key' key is necessary for rendering list items in React (there are console errors without),
    // but must be removed prior to the equality check
    delete newRule?.key;

    return newRule;
  });
  const savedRules = savedModel.rules?.and?.map((rule) => {
    const savedRule = extractRuleData(rule);

    // The 'key' key is necessary for rendering list items in React (there are console errors without),
    // but must be removed prior to the equality check
    delete savedRule?.key;

    return savedRule;
  });

  const rulesChanged = !isEmpty(xorWith(newRules, savedRules, isEqual));

  return wasPreviouslyDeployedOnDevices && (installOnChanged || rulesChanged);
};

/**
 * Constructs the delete quick action for the quick actions menu
 * @param {func} onDelete - the delete action association with the delete quick action option
 * @param {obj} permissions - the permissions of the current user
 * @returns a single option array that uses the provided onDelete function as the onClick action
 */
const getDeleteQuickAction = (onDelete, permissions) => [
  {
    name: i18n.t('Delete'),
    icon: 'trash-can',
    theme: 'error',
    onClick: onDelete,
    isDisabled: !permissions.canManageBlueprints,
  },
];

/**
 * Constructs the duplicate quick action for the quick actions menu
 * @param {func} onDuplicate - the duplicate action association with the duplicate quick action option
 * @param {obj} permissions - the permissions of the current user
 * @returns a single option array that uses the provided onDuplicate function as the onClick action
 */
const getDuplicateQuickAction = (onDuplicate, permissions) => [
  {
    name: i18n.t('Duplicate'),
    icon: 'copy',
    onClick: onDuplicate,
    isDisabled: !permissions.canManageBlueprints,
  },
];

/**
 * Filters the contents of an array from another array
 * @param {Array} a - an array of objects containing at least a `value` key
 * @param {Array} b - an array of objects containing at least a `value` key
 * @returns first array with the objects contained within the second array removed
 */
const filterArrayFromArray = (a, b) =>
  a.filter(
    (objFromA) => !b.find((objFromB) => objFromA.value === objFromB.value),
  );

export {
  getDeleteQuickAction,
  getDuplicateQuickAction,
  shouldShowWarningModal,
  filterArrayFromArray,
};
