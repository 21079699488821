import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class GatekeeperService extends NewLibraryItemService {
  static appsFrom = {
    MAC_ONLY: 'MAC_ONLY',
    MAC_AND_DEVELOPERS: 'MAC_AND_DEVELOPERS',
    ANYWHERE: 'ANYWHERE',
  };
}

export const gatekeeperService = new GatekeeperService();

export default GatekeeperService;
