import {
  Button,
  Icon,
  SelectV2 as Select,
  type SelectV2Option,
  styled,
  theme,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import * as React from 'react';
import { api } from 'src/app/api/base';
import { URL_DEP_COMPUTER_CHANGE_USER } from 'src/app/api/urls';
import { useIsVisible } from 'src/hooks/useIsVisible';
import { useGetADEUsers } from '../hooks/useGetADEUsers';
import {
  ADEListViewStore,
  useADEListViewStore,
} from '../store/ADEListViewStore';
import { type User, UserSelections } from './ADEListViewTableColumns';

const NOT_ASSIGNED = () => i18n.t('Not assigned');

interface ADECellUserSelectProps {
  deviceId: string;
  user: User | null;
}

export const NotAssignedTrigger = {
  color: theme.colors.neutral60,
  fontStyle: 'italic',
  fontWeight: 'normal',
  width: '100%',
};

const UserIconWrapper = styled('div', {
  display: 'flex',
});

const UserImage = styled('img', {
  width: 20,
  height: 20,
  borderRadius: '50%',
});

const NoUserImage = styled(Icon, {
  width: 20,
  height: 20,
});

const NotAssignedIcon = styled(NoUserImage, {
  color: theme.colors.neutral60,
  display: 'flex',
});

export async function handleUpdateUser(
  deviceId: string,
  newUser: User | null | undefined,
  oldUser: User | null | undefined,
) {
  const updateUser = ADEListViewStore.getState().updateUser;

  const newUserId = newUser?.id ?? null;
  const oldUserId = oldUser?.id ?? null;

  if (oldUserId === newUserId) return;

  try {
    updateUser([deviceId], newUser);
    await api(URL_DEP_COMPUTER_CHANGE_USER).post({
      devices: [deviceId],
      user_id: newUserId,
    });
  } catch {
    updateUser([deviceId], oldUser);
  }
}

const UserIconSizes = {
  sm: 14,
  default: 20,
};

export function UserIcon({
  user,
  size = 'default',
}: {
  user: User | null;
  size?: 'sm' | 'default';
}) {
  if (user == null) {
    return (
      <NotAssignedIcon
        name="user-large"
        style={{ height: UserIconSizes[size], width: UserIconSizes[size] }}
      />
    );
  }

  return (
    <UserIconWrapper>
      {user.photo == null && (
        <NoUserImage
          name="user-large"
          style={{ height: UserIconSizes[size], width: UserIconSizes[size] }}
        />
      )}
      {user.photo != null && (
        <UserImage
          src={user.photo}
          alt={user.name}
          style={{
            height: UserIconSizes['default'],
            width: UserIconSizes['default'],
          }}
        />
      )}
    </UserIconWrapper>
  );
}
const NOT_ASSIGNED_OPTION = (size?: 'sm' | 'default') => ({
  value: UserSelections.NOT_ASSIGNED,
  label: NOT_ASSIGNED(),
  iconElement: () => <UserIcon user={null} size={size} />,
  metadata: { user: null },
  css: {
    fontStyle: 'italic',
    color: '$neutral60',
  },
});

function optionFromUser(
  user: User | null | undefined,
  size?: 'sm' | 'default',
) {
  if (user == null) {
    return NOT_ASSIGNED_OPTION(size);
  }
  return {
    label: user.name,
    value: user.id,
    iconElement: () => <UserIcon user={user} size={size} />,
    metadata: {
      user,
    },
  };
}

function flattenPaginatedData(data: { pages: any[] } | null | undefined) {
  return (
    data?.pages?.reduce((acc: any[], curr: any[]) => [...acc, ...curr], []) ??
    []
  );
}

export const ADECellUserSelect = React.memo(function ADECellUserSelect({
  deviceId,
  user,
}: ADECellUserSelectProps) {
  const ref = React.useRef(null);
  const isVisible = useIsVisible(ref);
  const searchText = useADEListViewStore((state) => state.searchText);
  const triggerOption = optionFromUser(user, 'sm');
  const triggerProps: any = {
    compact: true,
    css: user == null ? NotAssignedTrigger : { width: '100%' },
    placeholder: i18n.t('Search user'),
    variant: 'input',
    searchText,
  };

  function handleValueChange({
    metadata,
  }: SelectV2Option & { metadata: User | null | undefined }) {
    handleUpdateUser(deviceId, metadata.user as User | null | undefined, user);
  }

  return (
    <div ref={ref}>
      {/** Rendering button when off screen for performance */}
      {/** If we render nothing here the user will see empty space */}
      {/** when scrolling too fast */}
      {!isVisible && (
        <Button {...triggerProps}>
          {triggerOption.iconElement()}
          {triggerOption.label}
          <Icon
            name="fa-angle-down-small"
            className="selectIcon"
            style={{
              marginLeft: 'auto',
              color: '$neutral90',
              width: 20,
              height: 20,
            }}
          />
        </Button>
      )}
      {isVisible && (
        <Select.Async
          selectedOption={triggerOption}
          onValueChange={handleValueChange}
          transformData={
            /* istanbul ignore next */
            (data: any) => flattenPaginatedData(data).map(optionFromUser)
          }
          /** Providing deviceId as a queryKey to prevent the entire list of */
          /** selects from re-rendering */
          useGetData={
            /* istanbul ignore next */
            (search) => useGetADEUsers(search, deviceId)
          }
          defaultOptions={[NOT_ASSIGNED_OPTION()]}
          triggerProps={triggerProps}
        />
      )}
    </div>
  );
});
