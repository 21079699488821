import {
  TextInput,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
/* istanbul ignore file */
import React from 'react';

import { Setting } from 'features/library-items/template';
import { i18n } from 'src/i18n';

import { AddableTextField } from '../common';

const Credential = (props) => {
  const { setting, update, validationDep, isDisabled } = props;

  const fieldsToValidate = ['extension_identifier'];
  const { refs, onInvalidate } = useInputsValidators(fieldsToValidate, update);
  useRemoveValidationOnUnmount(fieldsToValidate, update);

  return (
    <>
      <Setting.Row>
        <Setting.Title className="b-txt">
          {i18n.t(`Extension identifier`)}
        </Setting.Title>
        <Setting.Helpers className="b-txt-light">
          <p ref={refs[0]}>
            {i18n.t(`The bundle identifier of the app extension that performs single
            sign-on.`)}
          </p>
        </Setting.Helpers>
        <Setting.Controls>
          <TextInput
            compact
            removeValidationOnUnmount
            disabled={isDisabled}
            value={setting.extension_identifier}
            onChange={(e) => update('extension_identifier', e.target.value)}
            placeholder="com.accuhive.enterpriseSSO.extension"
            onInvalidate={onInvalidate('extension_identifier')}
            validator={(v) => [
              {
                message: i18n.t('Required.'),
                invalid: () => !v,
                trigger: ['onBlur', validationDep],
              },
            ]}
          />
        </Setting.Controls>
      </Setting.Row>

      <Setting.Row>
        <Setting.Title className="b-txt">
          {i18n.ut(
            `Team identifier <span class="b-txt-light">(optional)</span>`,
          )}
        </Setting.Title>
        <Setting.Helpers className="b-txt-light">
          <p>
            {i18n.t(`The team identifier of the app extension that performs single
            sign-on. Required if extension will be assigned to macOS devices.
            Team identifier must be a 10-digit alphanumeric string.`)}
          </p>
        </Setting.Helpers>
        <Setting.Controls>
          <TextInput
            compact
            disabled={isDisabled}
            value={setting.team_identifier}
            onChange={(e) => update('team_identifier', e.target.value)}
            placeholder="P3FGV63VK7"
          />
        </Setting.Controls>
      </Setting.Row>

      <Setting.Row>
        <Setting.Title className="b-txt">
          {i18n.ut(`Realm <span class="b-txt-light">(optional)</span>`)}
        </Setting.Title>
        <Setting.Helpers className="b-txt-light">
          <p>{i18n.t(`Properly capitalized realm name for the credential.`)}</p>
        </Setting.Helpers>
        <Setting.Controls>
          <TextInput
            compact
            disabled={isDisabled}
            value={setting.realm}
            onChange={(e) => update('realm', e.target.value)}
            placeholder="accuhive.io"
          />
        </Setting.Controls>
      </Setting.Row>

      <Setting.Row>
        <Setting.Title className="b-txt">{i18n.t(`Hosts`)}</Setting.Title>
        <Setting.Helpers className="b-txt-light">
          <p>
            {i18n.t(`The domains or host names which can be authenticated through the app
            extension. Names must be unique across all Single Sign-On Extension
            profiles installed on a device.`)}
          </p>
        </Setting.Helpers>
        <Setting.Controls>
          <AddableTextField
            validationDep={validationDep}
            isDisabled={isDisabled}
            update={update}
            items={setting.hosts}
            settingKey="hosts"
            placeholder="identity.accuhive.io"
          />
        </Setting.Controls>
      </Setting.Row>
    </>
  );
};

export default Credential;
