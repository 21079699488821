import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { memo, useMemo, useCallback } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import { i18n } from 'src/i18n';
import EmptyContent from '../organisms/EmptyContent';
import Icon from './Icon';
import Select from './Select';

const selectRow = {
  mode: 'radio', // single row selection
  style: { background: 'red' },
};

// Accepts two args: `row` and `rowIndex`
const getRowStyle = () => ({});

// Accepts two args: `row` and `rowIndex`
const getRowClasses = () => '';

const getRowEvents = {
  // onClick: (e, row, rowIndex) => {
  //   alert(`This is a rowEvent! Row: ${rowIndex}`)
  // }
};

const getSortObj = {
  dataField: 'name',
  order: 'asc',
  // sortCaret: '',
  // sortFunc: '',
};

// Callback function will be called when remote enabled only
// will be passed `type` and `newState`, in that order.
const handleTableChange = () => {
  // handle any data change here
};

// Callback function will be called only when data size change by search/filter
// will be passed `opt:object` as the argument, can be read `({ dataSize })`.
const handleDataChange = () => {
  // this.setState({ rowCount: dataSize });
};

const Table = ({
  keyField,
  data,
  columns,
  striped,
  bordered,
  hover,
  bootstrap4,
  remote,
  noDataIndication,
  loading,
  overlay,
  caption,
  condensed,
  id,
  tabIndexCell,
  classes,
  wrapperClasses,
  headerClasses,
  headerWrapperClasses,
  bodyClasses,
  cellEdit,
  selectRowProp,
  rowStyle,
  rowClasses,
  rowEvents,
  hiddenRows,
  sort,
  defaultSorted,
  defaultSortDirection,
  pagination,
  filter,
  filterPosition,
  onTableChange,
  onDataSizeChange,
  setPaginationRemoteEmitter,
}) => (
  <BootstrapTable
    keyField={keyField}
    data={data}
    columns={columns}
    striped={striped}
    bordered={bordered}
    hover={hover}
    bootstrap4={bootstrap4}
    remote={remote}
    noDataIndication={noDataIndication}
    loading={loading}
    overlay={overlay}
    caption={caption}
    condensed={condensed}
    id={id}
    tabIndexCell={tabIndexCell}
    classes={classes}
    wrapperClasses={wrapperClasses}
    headerClasses={headerClasses}
    headerWrapperClasses={headerWrapperClasses}
    bodyClasses={bodyClasses}
    cellEdit={cellEdit}
    selectRow={selectRowProp}
    rowStyle={rowStyle}
    rowClasses={rowClasses}
    rowEvents={rowEvents}
    hiddenRows={hiddenRows}
    sort={sort}
    defaultSorted={defaultSorted}
    defaultSortDirection={defaultSortDirection}
    pagination={pagination}
    filter={filter}
    filterPosition={filterPosition}
    onTableChange={onTableChange}
    onDataSizeChange={onDataSizeChange}
    setPaginationRemoteEmitter={setPaginationRemoteEmitter}
  />
);

// For more detailed information about props, please visit:
// react-bootstrap-table.github.io/react-bootstrap-table2/docs/table-props.html
Table.propTypes = {
  keyField: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  striped: PropTypes.bool,
  bordered: PropTypes.bool,
  hover: PropTypes.bool,
  bootstrap4: PropTypes.bool,
  remote: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  noDataIndication: PropTypes.func,
  loading: PropTypes.bool,
  // overlay: PropTypes.func,
  // caption: PropTypes.string,
  condensed: PropTypes.bool,
  id: PropTypes.string,
  tabIndexCell: PropTypes.bool,
  classes: PropTypes.string,
  wrapperClasses: PropTypes.string,
  headerClasses: PropTypes.string,
  headerWrapperClasses: PropTypes.string,
  bodyClasses: PropTypes.string,
  // cellEdit: PropTypes.object,
  selectRow: PropTypes.object,
  rowStyle: PropTypes.func,
  rowClasses: PropTypes.func,
  rowEvents: PropTypes.object,
  hiddenRows: PropTypes.arrayOf(PropTypes.number),
  sort: PropTypes.object,
  defaultSorted: PropTypes.array,
  defaultSortDirection: PropTypes.string,
  // pagination: PropTypes.object,
  filter: PropTypes.object,
  filterPosition: PropTypes.string,
  onTableChange: PropTypes.func,
  onDataSizeChange: PropTypes.func,
};

Table.defaultProps = {
  keyField: 'id',
  data: [],
  columns: [{}],
  striped: false,
  bordered: false,
  hover: false,
  bootstrap4: true,
  remote: {
    filter: false,
    pagination: false,
    sort: false,
    cellEdit: false,
  },
  noDataIndication: EmptyContent,
  loading: false,

  // caption: 'This is a caption!',
  condensed: false,
  id: undefined,
  tabIndexCell: false,
  classes: 'theme-table', // Class on Table element
  wrapperClasses: '', // Class for outer element which wraps the table element
  headerClasses: '', // Class on the header row(tr)
  headerWrapperClasses: '', // Class on thead
  bodyClasses: '', // Class on tbody

  selectRow,
  rowStyle: getRowStyle,
  rowClasses: getRowClasses,
  rowEvents: getRowEvents,
  hiddenRows: [],
  sort: getSortObj,
  defaultSorted: [],
  defaultSortDirection: 'desc',

  filter: filterFactory(),
  filterPosition: 'top',
  onTableChange: handleTableChange,
  onDataSizeChange: handleDataChange,
};

export default Table;

export const TablePagination = memo(
  ({
    onPageChange,
    onSizePerPageChange,
    page,
    sizePerPage,
    totalSize,
    sizePerPageList,
    fixPageSize,
  }) => {
    const firstItemIndex = useMemo(
      () => (page - 1) * sizePerPage + 1,
      [page, sizePerPage],
    );
    const lastItemIndex = useMemo(
      () => Math.min(page * sizePerPage, totalSize),
      [page, sizePerPage, totalSize],
    );
    const isFirstPage = useMemo(() => page === 1, [page]);
    const isLastPage = useMemo(
      () => page >= Math.ceil(totalSize / sizePerPage),
      [page, sizePerPage, totalSize],
    );
    const handlePreciousPage = useCallback(
      () => !isFirstPage && onPageChange(page - 1),
      [isFirstPage, page],
    );
    const handleNextPage = useCallback(
      () => !isLastPage && onPageChange(page + 1),
      [isLastPage, page],
    );

    const perPageSelectValue = useMemo(
      () => ({
        label: sizePerPage.toString(),
        value: sizePerPage,
      }),
      [sizePerPage],
    );
    const perPageOptions = useMemo(
      () =>
        sizePerPageList.map((value) => ({
          value,
          label: value.toString(),
        })),
      [sizePerPageList],
    );
    const handlePerPageChange = useCallback(
      ({ value }) => onSizePerPageChange(value),
      [],
    );

    return (
      <div className="kandji-pagination">
        <div className="per-page-label"> {i18n.t('Row per page')} </div>
        {fixPageSize && <div className="per-page-value"> {sizePerPage} </div>}
        {!fixPageSize && (
          <Select
            value={perPageSelectValue}
            options={perPageOptions}
            onChange={handlePerPageChange}
            className="per-page-select"
          />
        )}
        <div className="current-page-indexes">
          {i18n.t('{range} of {totalSize}', {
            range: `${firstItemIndex}-${lastItemIndex}`,
            totalSize,
          })}
        </div>
        <Icon
          icon="calendar-arrow"
          onClick={handlePreciousPage}
          className={cn('pagination-previous-btn', {
            'is-active-pagination': !isFirstPage,
          })}
        />
        <Icon
          icon="calendar-arrow"
          onClick={handleNextPage}
          className={cn('pagination-next-btn', {
            'is-active-pagination': !isLastPage,
          })}
        />
      </div>
    );
  },
);

// there are a lot of another unused props
TablePagination.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  onSizePerPageChange: PropTypes.func,
  page: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  totalSize: PropTypes.number,
  sizePerPageList: PropTypes.arrayOf(PropTypes.number),
  fixPageSize: PropTypes.bool,
};
TablePagination.defaultProps = {
  totalSize: 0,
  sizePerPageList: [10, 25, 50, 100],
  fixPageSize: true,
  onSizePerPageChange: undefined,
};
