import { setClass } from '@kandji-inc/bumblebee';
import React from 'react';
import './toggle-button.css';

const ToggleButton = (props) => {
  const { isActive, onClick, className, style, children } = props;
  return (
    <button
      style={style}
      type="button"
      onClick={onClick}
      className={setClass(
        className,
        'k-klogin-toggle-button',
        isActive && '--active',
      )}
    >
      {children}
    </button>
  );
};

export default ToggleButton;
