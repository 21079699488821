import { Button, modal as Modal } from '@kandji-inc/bumblebee';
import type { Dispatch, SetStateAction } from 'react';
import React, { useMemo } from 'react';
import { i18n } from 'src/i18n';

type Props = {
  onClose: Dispatch<SetStateAction<boolean>>;
  onLeave: () => void;
};

export function ConfirmNavigationModal({ onClose, onLeave }: Props) {
  const contentCopy = [
    i18n.t('You will lose all unsaved work.'),
    i18n.t('Are you sure you want to leave this page?'),
  ];

  const parts = useMemo(
    () => ({
      header: { text: i18n.t('Confirm navigation') },
      content: {
        children: (
          <>
            {contentCopy.map((copy, idx) => (
              <p key={`confirm-navigation-modal-content-${idx}`}>{copy}</p>
            ))}
          </>
        ),
      },
      footer: {
        children: (
          <>
            <Button onClick={onClose} kind="outline">
              {i18n.t('Stay on this Page')}
            </Button>
            <Button onClick={onLeave} className="b-ml">
              {i18n.t('Leave this Page')}
            </Button>
          </>
        ),
      },
    }),
    [],
  );

  return <Modal isOpen parts={parts} onClose={onClose} />;
}
