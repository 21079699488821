/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { api, rootApi } from 'src/app/api/base';

export type WalkthroughResult = {
  ade: boolean;
  apns: boolean;
  devices: boolean;
  explore: boolean;
  is_explored_blueprints: boolean;
  is_explored_library: boolean;
  vpp: boolean;
};

export const useGetWalkthrough = () => {
  return useQuery<WalkthroughResult>({
    queryKey: ['get-walkthrough'],
    queryFn: async () => {
      const apiData = await rootApi('trial/v1/tour').get(null);
      const results = apiData.data.results;
      return {
        is_explored_library: results.is_explored_library,
        is_explored_blueprints: results.is_explored_blueprints,
        apns: results.apns !== 'INCOMPLETE',
        ade: results.ade !== 'INCOMPLETE',
        vpp: results.vpp !== 'INCOMPLETE',
        devices: results.devices !== 'INCOMPLETE',
        explore: results.is_explored_library && results.is_explored_blueprints,
      };
    },
  });
};
