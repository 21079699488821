import { Button } from '@kandji-inc/bumblebee';
import {
  blueprintColors,
  blueprintIconClasses,
  colors,
} from 'app/common/constants';
import { prettyScroll, truncate } from 'app/common/style-utils';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import trim from 'lodash/trim';
import trimStart from 'lodash/trimStart';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import HappyBlueprint from '../../../../assets/img/blueprint_happy.png';
import {
  createBlueprintFromTemplate as callCreateBlueprintFromTemplate,
  duplicateBlueprint as callDuplicateBlueprint,
  getBlueprints as callGetBlueprints,
  startAddBlueprint as callStartAddBlueprint,
} from '../../../_actions/blueprint';
import { getCategories as callGetCategories } from '../../../_actions/templates';
import { setSnackbar as callSetSnackbar } from '../../../_actions/ui';
import history from '../../../router/history';
import { dataTestId } from '../../common/utils';
import AwesomeIconCircle from '../AwesomeIconCircle';
import { AwesomeIconEditor } from '../AwesomeIconEditor';
import EditableH2 from '../EditableH2';
import { LineLoader } from '../LineLoader';
import { H2, H8 } from '../Typography';
import SimpleIndicator from '../indicators/SimpleIndicator';
import PrettyInput from '../inputs/PrettyInput';
import { StyledModalWrapper } from './StyledModalWrapper';

const ModalBody = styled.section`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 900px;
  min-height: 516px;
  max-height: 566px;
`;

const CloseModalIcon = styled.i`
  position: absolute;
  right: 30px;
  top: 25px;
  cursor: pointer;
  font-size: 27px;
  line-height: 20px;
  color: ${colors.marengo680};
`;

const BackButtonWrapper = styled.section`
  position: absolute;
  left: 20px;
  top: 20px;
  cursor: pointer;
`;

const Text = styled.section`
  display: ${(props) => (props.truncate ? 'block' : 'flex')};
  align-items: center;
  font-family: var(--font-family-primary);
  font-size: ${(props) => (props.size ? `${props.size}px` : '14px')};
  color: ${colors['grey-500']};
  ${(props) => (props.truncate ? truncate('404px') : '')};
`;

const SubtitleWrapper = styled.section`
  display: flex;
  align-items: center;
  ${Text} {
    flex: 0 0 150px;
  }
  &:first-of-type {
    margin-top: 10px;
  }
`;

const Line = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${colors['grey-200']};
`;

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

const StyledLink = styled.section`
  color: ${colors['marengo-700']};
  box-shadow: 0 3px 0 0 ${colors['grey-200']};
  font-weight: 500;
  cursor: pointer;
  margin-left: 5px;
`;

const ModalMenuWrapper = styled.section`
  width: 272px;
`;

const TopButton = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 70px;
  background-color: ${colors['marengo-700']};
  cursor: pointer;
  color: ${colors.white};
  &::after {
    display: ${(props) => (props.rightPointer ? 'block' : 'none')};
    content: '';
    top: 50%;
    transform: translateY(-50%);
    border: 5px solid transparent;
    border-left-color: ${colors['marengo-700']};
    height: 0;
    width: 0;
    right: -10px;
    position: absolute;
  }
`;

const TopButtonPlus = styled.section`
  font-size: 40px;
  font-family: var(--font-family-primary);
  color: ${colors.grey20};
  letter-spacing: 8px;
  line-height: 40px;
  text-align: center;
  font-weight: 400;
`;

const TopButtonText = styled.section`
  letter-spacing: 2px;
  font-size: 11px;
  font-family: var(--font-family-primary);
  text-transform: uppercase;
  font-weight: 700;
  color: ${colors.grey20};
`;

const TemplateListWrapper = styled.section`
  height: calc(100% - 70px);
  background: ${colors['marengo-50']};
`;

const TemplateListScrollThumb = styled.div`
  cursor: pointer;
  border-radius: inherit;
  background-color: rgba(0, 0, 0, 0.2);
`;

const TemplateListScrollTrack = styled.div`
  width: 7px;
  right: 10px;
  bottom: 2px;
  top: 2px;
  border-radius: 4px;
`;

const TemplateList = styled(Scrollbars)``;

const TemplateCategoryWrapper = styled.section`
  padding: 20px 30px;
  color: ${colors['marengo-350']};
  border-bottom: 1px solid ${colors['marengo-100']};
  &:last-of-type {
    border-bottom: none;
  }
`;

const TemplateItem = styled.section`
  cursor: pointer;
  padding: 8px 16px;
  margin-left: 5px;
  font-family: var(--font-family-primary);
  font-size: 14px;

  &:hover {
    color: ${colors['marengo-700']};
    border-left: 2px solid ${colors['marengo-700']};
    font-weight: 500;
  }

  color: ${(props) =>
    props.active ? colors['marengo-700'] : colors['marengo-350']};
  border-left: 2px solid
    ${(props) => colors[props.active ? 'marengo-700' : 'marengo-120']};
  font-weight: ${(props) => (props.active ? 500 : 400)};
`;

const ModalContent = styled.section`
  width: calc(100% - 272px);
  height: 100%;
`;

const InitialView = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px 0;
  & > :not(:last-child) {
    margin-bottom: 14px;
  }
`;

const CreateView = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 50px 52px;
  height: calc(100% - 100px);
  > button {
    margin-top: auto;
    margin-left: auto;
  }
`;

const CreateHeader = styled.section`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const CreateHeaderTitle = styled.section`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  flex: 1;
`;

const RatingLine = styled.section`
  height: 3px;
  border-radius: 2px;
  width: 110px;
  background: ${(props) =>
    `linear-gradient(to right, ${colors['marengo-700']} ${props.percent}%, ${colors['grey-400']} ${props.percent}%)`};
`;

const DescriptionWrapper = styled.section`
  margin: 20px 0;
  color: ${colors['marengo-350']};
  ${Text} {
    white-space: pre-line;
    max-height: 170px;
    margin-top: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    align-items: flex-start;
    ${prettyScroll({ scrollColor: 'grey-200' })}
  }
`;

export class BlueprintCreateModal extends React.Component {
  constructor(props) {
    super(props);
    const initIconClass =
      blueprintIconClasses[
        Math.floor(Math.random() * blueprintIconClasses.length)
      ];
    const initIconColor =
      blueprintColors[Math.floor(Math.random() * blueprintColors.length)];
    this.blueprintNameInput = null;
    this.state = {
      view: 'initial',
      icon: initIconClass,
      color: initIconColor,
      initIcon: initIconClass,
      initColor: initIconColor,
      name: '',
      description: '',
      isLoading: false,
      chosenTemplateOrigin: null,
      chosenTemplate: null,
    };
    this.onAdd = this.onAdd.bind(this);
    this.isDuplicatedName = this.isDuplicatedName.bind(this);
  }

  componentDidMount() {
    const { getCategories, blueprints } = this.props;
    if (blueprints.length) {
      const initIconClass =
        blueprintIconClasses[
          Math.floor(Math.random() * blueprintIconClasses.length)
        ];
      const initIconColor =
        blueprintColors[Math.floor(Math.random() * blueprintColors.length)];
      this.setState({
        icon: initIconClass,
        color: initIconColor,
        initIcon: initIconClass,
        initColor: initIconColor,
      });
    } else {
      this.setState({
        icon: 'ss-files',
        color: 'aqua-800',
        initIcon: 'ss-files',
        initColor: 'aqua-800',
      });
    }
    this.setState({ isLoading: true }, () =>
      getCategories().finally(() => this.setState({ isLoading: false })),
    );
  }

  onAdd() {
    const { isLoading, name, description, icon, color, chosenTemplate, view } =
      this.state;
    const {
      setSnackbar,
      startAddBlueprint,
      onHide,
      getBlueprints,
      createBlueprintFromTemplate,
    } = this.props;
    if (isLoading) {
      return null;
    }
    const trimmingName = trim(name);
    if (
      this.isDuplicatedName(trimmingName) ||
      this.isDuplicatedName(trim(get(chosenTemplate, 'name')))
    ) {
      if (view === 'template' && this.blueprintNameInput) {
        this.blueprintNameInput.setEditMode(true);
      }
      setSnackbar('Blueprint name already exists');
      return null;
    }
    let result = () =>
      startAddBlueprint({
        name: trimmingName,
        description,
        icon,
        color,
      });
    if (get(chosenTemplate, 'id') && view === 'template') {
      result = () =>
        createBlueprintFromTemplate({
          source: { type: 'template', id: `${get(chosenTemplate, 'id')}` },
          name: get(chosenTemplate, 'name'),
          description: get(chosenTemplate, 'description'),
          icon: get(chosenTemplate, 'icon'),
          color: get(chosenTemplate, 'color'),
        });
    }
    this.setState({ isLoading: true }, () =>
      result()
        .then((res) => {
          const blueprintId = res.id;
          onHide();
          getBlueprints();
          history.push(`/blueprints/${blueprintId}`);
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          setSnackbar(err.message);
        }),
    );
    return null;
  }

  isDuplicatedName(name) {
    const { blueprints } = this.props;
    return !!blueprints.find(
      (el) => el.name.toLowerCase() === (name || '').toLowerCase(),
    );
  }

  render() {
    const {
      isLoading,
      view,
      icon,
      initIcon,
      color,
      initColor,
      description,
      name,
      chosenTemplate,
      chosenTemplateOrigin,
    } = this.state;
    const { onHide, templates, blueprints } = this.props;

    return (
      <StyledModalWrapper {...this.props} onHide={() => {}}>
        <ModalBody>
          <CloseModalIcon className="fal fa-times" onClick={onHide} />
          {view !== 'edit' && view !== 'editTemplate' && (
            <>
              <ModalMenuWrapper>
                <TopButton
                  rightPointer={view === 'create'}
                  onClick={() =>
                    this.setState({
                      view: 'create',
                      chosenTemplate: null,
                      chosenTemplateOrigin: null,
                    })
                  }
                  data-testid="blueprint-create-modal-new"
                >
                  <TopButtonPlus>+</TopButtonPlus>
                  <TopButtonText>New Blueprint</TopButtonText>
                </TopButton>
                <TemplateListWrapper>
                  {isLoading && <LineLoader />}
                  <TemplateList
                    autoHide
                    autoHeight
                    autoHeightMax={446}
                    renderThumbVertical={({ style, ...props }) => (
                      <TemplateListScrollThumb
                        {...props}
                        style={{ ...style }}
                      />
                    )}
                    renderTrackVertical={({ style, ...props }) => (
                      <TemplateListScrollTrack
                        {...props}
                        style={{ ...style }}
                      />
                    )}
                  >
                    {templates.map((category) => (
                      <TemplateCategoryWrapper key={category.id}>
                        <H8>{category.name}</H8>
                        {sortBy(category.templates, 'name').map((template) => (
                          <TemplateItem
                            key={template.id}
                            active={
                              view === 'template' &&
                              template.id === get(chosenTemplate, 'id')
                            }
                            onClick={() =>
                              this.setState({
                                view: 'template',
                                chosenTemplate: template,
                                chosenTemplateOrigin: template,
                              })
                            }
                            data-testid={dataTestId(
                              `template ${template.name}`,
                            )}
                          >
                            {template.name}
                          </TemplateItem>
                        ))}
                      </TemplateCategoryWrapper>
                    ))}
                  </TemplateList>
                </TemplateListWrapper>
              </ModalMenuWrapper>
              <ModalContent>
                {view === 'initial' && (
                  <InitialView>
                    <img
                      src={HappyBlueprint}
                      style={{ width: 200 }}
                      alt="Happy Blueprint"
                    />
                    <H2>
                      {blueprints.length
                        ? 'Create a New Blueprint'
                        : 'Create your first Blueprint'}
                    </H2>
                    <Text>
                      Select a template on the left, or
                      <StyledLink
                        onClick={() =>
                          this.setState({
                            view: 'create',
                            chosenTemplate: null,
                            chosenTemplateOrigin: null,
                          })
                        }
                      >
                        start from scratch
                      </StyledLink>
                    </Text>
                  </InitialView>
                )}

                {view === 'create' && (
                  <CreateView>
                    <CreateHeader>
                      <AwesomeIconCircle
                        editable
                        color={color}
                        icon={`ss-icon ${icon}`}
                        size="100px"
                        onClick={() =>
                          this.setState({
                            view: 'edit',
                            chosenTemplate: null,
                            chosenTemplateOrigin: null,
                          })
                        }
                      />
                      <CreateHeaderTitle>
                        <H2>
                          {name ||
                            (blueprints.length
                              ? 'Create A New Blueprint'
                              : 'Create my first Blueprint')}
                        </H2>
                        <Text truncate>
                          {description || 'Blueprint Description'}
                        </Text>
                      </CreateHeaderTitle>
                    </CreateHeader>
                    <PrettyInput
                      value={name}
                      label="Blueprint Name"
                      placeholder="Blueprint Name"
                      onChange={(nameVal) =>
                        this.setState({ name: trimStart(nameVal) })
                      }
                      maxLength="100"
                    />
                    <PrettyInput
                      value={description}
                      label="Description"
                      placeholder="Description"
                      onChange={(desc) =>
                        this.setState({ description: trimStart(desc) })
                      }
                      type="textarea"
                      maxLength="500"
                    />
                    <Button disabled={!name || isLoading} onClick={this.onAdd}>
                      Create Blueprint
                    </Button>
                  </CreateView>
                )}

                {view === 'template' && (
                  <CreateView>
                    <CreateHeader>
                      <AwesomeIconCircle
                        color={get(chosenTemplate, 'color')}
                        icon={`ss-icon ${get(chosenTemplate, 'icon')}`}
                        size="100px"
                        editable
                        onClick={() => this.setState({ view: 'editTemplate' })}
                      />
                      <CreateHeaderTitle>
                        <EditableH2
                          ref={(input) => (this.blueprintNameInput = input)}
                          key={get(chosenTemplate, 'id')}
                          value={get(
                            chosenTemplate,
                            'name',
                            'Create A New Blueprint',
                          )}
                          onChange={(nameVal) =>
                            this.setState({
                              chosenTemplate: {
                                ...chosenTemplate,
                                name: nameVal,
                              },
                            })
                          }
                        />
                        <SubtitleWrapper>
                          <Text size={12}>Items Enabled</Text>
                          <SimpleIndicator
                            text={
                              Object.keys(get(chosenTemplate, 'params', {}))
                                .length
                            }
                            color="grey-500"
                          />
                        </SubtitleWrapper>
                        <SubtitleWrapper>
                          <Text size={12}>Restriction Rating</Text>
                          <RatingLine percent={get(chosenTemplate, 'rating')} />
                        </SubtitleWrapper>
                      </CreateHeaderTitle>
                    </CreateHeader>
                    <Line />
                    <DescriptionWrapper>
                      <H8>Description</H8>
                      <Text>{get(chosenTemplate, 'description')}</Text>
                    </DescriptionWrapper>
                    <ButtonWrapper>
                      <Button disabled={isLoading} onClick={this.onAdd}>
                        Create Blueprint
                      </Button>
                    </ButtonWrapper>
                  </CreateView>
                )}
              </ModalContent>
            </>
          )}

          {view === 'edit' && (
            <>
              <BackButtonWrapper>
                <Button
                  icon="arrow-left"
                  kind="link"
                  onClick={() => {
                    this.setState({
                      view: 'create',
                      chosenTemplate: null,
                      chosenTemplateOrigin: null,
                    });
                  }}
                >
                  Back
                </Button>
              </BackButtonWrapper>
              <AwesomeIconEditor
                color={color}
                onColorChange={(c) => this.setState({ color: c })}
                icon={icon}
                onIconChange={(i) => this.setState({ icon: i })}
                onRevertClick={() =>
                  this.setState({ icon: initIcon, color: initColor })
                }
              />
            </>
          )}

          {view === 'editTemplate' && (
            <>
              <BackButtonWrapper>
                <Button
                  icon="arrow-left"
                  kind="link"
                  onClick={() => this.setState({ view: 'template' })}
                >
                  Back
                </Button>
              </BackButtonWrapper>
              <AwesomeIconEditor
                color={get(chosenTemplate, 'color')}
                onColorChange={(colorVal) =>
                  this.setState({
                    chosenTemplate: {
                      ...chosenTemplate,
                      color: colorVal,
                    },
                  })
                }
                icon={get(chosenTemplate, 'icon')}
                onIconChange={(iconVal) =>
                  this.setState({
                    chosenTemplate: {
                      ...chosenTemplate,
                      icon: iconVal,
                    },
                  })
                }
                onRevertClick={() =>
                  this.setState({
                    chosenTemplate: {
                      ...chosenTemplate,
                      icon: chosenTemplateOrigin.icon,
                      color: chosenTemplateOrigin.color,
                    },
                  })
                }
              />
            </>
          )}
        </ModalBody>
      </StyledModalWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  blueprints: state.data.blueprints,
  templates: state.templates,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      startAddBlueprint: callStartAddBlueprint,
      duplicateBlueprint: callDuplicateBlueprint,
      createBlueprintFromTemplate: callCreateBlueprintFromTemplate,
      getBlueprints: callGetBlueprints,
      setSnackbar: callSetSnackbar,
      getCategories: callGetCategories,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BlueprintCreateModal);
