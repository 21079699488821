import React from 'react';

function HiveSvg() {
  return (
    <svg
      width="160"
      height="64"
      viewBox="0 0 160 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.25 52.9029C17.8193 52.9029 44.9578 52.0637 44.9578 37.3752C44.9578 32.759 42.8594 29.4016 39.5021 29.4016C36.1447 29.4016 33.6267 32.759 33.6267 37.3752C33.6267 46.639 47.8441 51.6678 60.0991 44.5002"
        stroke="#C9CDD9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeDasharray="0.08 3.36"
      />
      <path
        d="M101.25 25C103.488 21.7825 109.75 12.5 124.75 12.5C132.25 12.5 138.652 17.4017 140.75 19.5C142.848 21.5983 147.356 26.5479 147.356 30.2409C147.356 34.8573 143.579 35.2769 142.74 35.2769C141.901 35.2769 138.124 34.8573 138.124 30.2409C138.124 25.6246 143.579 18.4902 155.75 17.6509"
        stroke="#C9CDD9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeDasharray="0.08 3.36"
      />
      <path
        d="M100.85 29.5H62.1502C61.6502 31.2 61.2502 33.1 60.9502 35.1H102.05C101.75 33 101.35 31.2 100.85 29.5Z"
        fill="#C9CDD9"
      />
      <path
        d="M63.25 25.9999H99.55C98.75 23.8999 97.75 22.0999 96.75 20.3999H66.05C65.05 22.0999 64.15 23.8999 63.25 25.9999Z"
        fill="#C9CDD9"
      />
      <path
        d="M94.2504 17C88.3504 10.2 81.4504 9 81.4504 9C81.4504 9 74.5504 10.2 68.6504 17H94.2504Z"
        fill="#C9CDD9"
      />
      <path
        d="M76.5502 47.5H60.4502C61.9502 54.4 68.2502 54.9 76.5502 54.9V47.5Z"
        fill="#C9CDD9"
      />
      <path
        d="M86.3506 47.5V54.9C94.5506 54.9 100.951 54.3 102.451 47.5H86.3506Z"
        fill="#C9CDD9"
      />
      <path
        d="M81.45 39.7999C83.95 39.7999 85.95 41.5999 86.25 43.9999H102.65C102.65 41.9999 102.55 40.1999 102.45 38.3999H60.45C60.25 40.0999 60.25 41.9999 60.25 43.9999H76.65C76.95 41.6999 79.05 39.7999 81.45 39.7999Z"
        fill="#C9CDD9"
      />
    </svg>
  );
}

export default HiveSvg;
