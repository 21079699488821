import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import {
  getRunsOnFromModel,
  getSelectedDevicesFromRunsOn,
} from '../../../data-service/library-item/devices';
// import CustomProfileService from './custom-profile-service';
import { setBlueprintsToFormData } from '../../../data-service/library-item/transformers';

export const transformFromApi = async (apiData) => {
  try {
    const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
    const excludedBlueprints = await getAllBlueprintOptions(
      apiData.excluded_blueprints,
    );

    const customData = apiData.data || {};

    const result = {
      _data: apiData,
      id: apiData.id,
      name: apiData.name,
      // iconSrc: apiData.app_icon,
      isActive: apiData.active,
      devices: getSelectedDevicesFromRunsOn(apiData),
      template: apiData.template,
      selectedBlueprints,
      excludedBlueprints,
      isAllBlueprints: apiData.is_all_blueprints,
      rules: apiData.rules,
    };
    // result.installation = {
    //   type:
    //     customData.install_enforcement ||
    //     CustomProfileService.installationTypes.CONTINUOUSLY_ENFORCE,
    //   allowRemove: customData.allowRemove,
    // };
    // result.selfService = {
    //   isEnabled: customData.show_in_self_service,
    //   category: customData.self_service_data?.category,
    //   isRecommended: customData.self_service_data?.isRecommended,
    //   displayName: customData.self_service_data?.displayName,
    //   shortDescription: customData.self_service_data?.description,
    //   longDescription: customData.self_service_data?.bodyText,
    //   isRequireRead: customData.self_service_data?.mustViewAgreement,
    //   iconUrl: customData.self_service_data?.iconUrl,
    //   iconS3Key: customData.self_service_data?.iconS3Key,
    //   iconFile: null,
    //   installBtnText: customData.self_service_data?.installBtnText,
    //   size: '', // ??
    //   displayInfoBtn: customData.self_service_data?.displayInfoBtn,
    //   infoBtnText: customData.self_service_data?.infoBtnText,
    //   infoBtnUrl: customData.self_service_data?.infoBtnUrl,
    // };
    result.details = {
      mobileconfig: customData && {
        file: null,
        // downloadLink: customData.data, // ??
        updatedAt: customData.updated_at,
        preview: customData.plist,
        scope: customData.scope,
        uuid: customData.payload_uuid,
        name: customData.file_name,
        data: customData.data,
        isSigned: customData.is_signed,
      },
    };

    return {
      ...apiData,
      data: result,
    };
  } catch (e) {
    console.error(e);
    throw e.message;
  }
};

export const transformToApi = (model) => {
  const toSend = new FormData();

  toSend.set('name', model.name);
  toSend.set('active', model.isActive);
  toSend.set('type', 'profile');
  toSend.set('template', 'custom-profile');
  toSend.set('rules', JSON.stringify(model.rules) || null);
  toSend.delete('blueprints');
  setBlueprintsToFormData(toSend, model.selectedBlueprints);
  toSend.set('is_all_blueprints', model.isAllBlueprints);

  // if (model.iconFile) {
  //   toSend.set('app_icon', model.iconFile);
  // }
  // if (!model.iconSrc) {
  //   toSend.set('app_icon', '');
  // }

  if (!model.selectedBlueprints.length) {
    toSend.set('blueprints', '');
  }

  if (model.details.mobileconfig?.file) {
    toSend.set('file', model.details.mobileconfig?.file);
  }

  const data = {
    ...model._data?.data,
    scope: model.details.mobileconfig?.scope,
    updated_at: model.details.mobileconfig?.updatedAt,
    is_signed: model.details.mobileconfig?.isSigned,
    plist: model.details.mobileconfig?.preview,
    payload_uuid: model.details.mobileconfig?.uuid,
    // install_enforcement: model.installation.type,
    // allowRemove: model.installation.allowRemove,
  };

  // if (model.selfService.isEnabled) {
  //   data.show_in_self_service = true;
  //   data.self_service_data = {
  //     isRecommended: model.selfService.isRecommended,
  //     iconS3Key: model.selfService.iconS3Key,
  //     displayName: model.selfService.displayName,
  //     description: model.selfService.shortDescription,
  //     bodyText: model.selfService.longDescription,
  //     mustViewAgreement: model.selfService.isRequireRead,
  //     category: model.selfService.category,
  //     installBtnText: model.selfService.installBtnText,
  //     displayInfoBtn: model.selfService.displayInfoBtn,
  //     infoBtnText: model.selfService.infoBtnText,
  //     infoBtnUrl: model.selfService.infoBtnUrl,
  //   };
  // } else {
  //   data.show_in_self_service = false;
  //   delete data.self_service_data;
  // }
  toSend.set('data', JSON.stringify(data));

  // Populate `runs_on_*` fields
  const runsOn = getRunsOnFromModel(model);
  Object.keys(runsOn).forEach((runsOnKey) => {
    toSend.set(runsOnKey, runsOn[runsOnKey]);
  });

  toSend.set('identifier', 'com.kandji.profile.custom');
  toSend.set('scope', model.details.mobileconfig?.scope);

  return toSend;
};
