import { ButtonSelect, Flex, Heading, styled } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';

type ExportType = {
  type: string;
  label: string;
};

export interface ExportDropdownContentProps {
  title: string;
  exportTypeOptions: [ExportType, ExportType];
  helpText: string | React.ReactNode;
  exportType: string;
  setExportType: (exportType: string) => void;
}

const StyledP = styled('p', {
  color: '$neutral70',
  fontWeight: '$regular',
  fontSize: '$1',
  marginBottom: '0',
});

// TODO: move to nectar-ui
const SecondaryLabel = styled('label', {
  color: '$neutral70',
  fontSize: '$2',
  fontWeight: '$medium',
  marginBottom: '0',
});

const ExportDropdownContent = ({
  title,
  exportTypeOptions,
  helpText,
  exportType,
  setExportType,
}: ExportDropdownContentProps) => {
  return (
    <Flex flow="column" gap="lg" css={{ p: '16px 24px' }}>
      <Heading size="4" css={{ fontWeight: '$medium' }}>
        {title}
      </Heading>
      <Flex gap="sm" flow="column">
        <SecondaryLabel>{i18n.t('Includes')}</SecondaryLabel>
        <ButtonSelect.Root
          type="single"
          value={exportType}
          onValueChange={(option) => {
            // istanbul ignore next
            if (option) {
              setExportType(option);
            }
          }}
        >
          <ButtonSelect.Item
            css={{ width: '50%' }}
            value={exportTypeOptions[0].type}
          >
            {exportTypeOptions[0].label}
          </ButtonSelect.Item>
          <ButtonSelect.Item
            css={{ width: '50%' }}
            value={exportTypeOptions[1].type}
          >
            {exportTypeOptions[1].label}
          </ButtonSelect.Item>
        </ButtonSelect.Root>
        <StyledP>{helpText}</StyledP>
      </Flex>
    </Flex>
  );
};

export default ExportDropdownContent;
