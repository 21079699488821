import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Flex,
  Heading,
  css,
  styled,
} from '@kandji-inc/nectar-ui';

export const Wrapper = styled(Flex, {
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 45,
});

export const SectionHeading = styled(Heading, {
  lineHeight: '36px',
  letterSpacing: '-0.8px',
  fontSize: '$5',
  fontWeight: '$medium',
});

export const SectionContent = styled(Flex, {
  alignItems: 'center',
  gap: 59,
  alignSelf: 'stretch',
  minHeight: 320,
});

export const ColumnLeft = styled(Flex, {
  width: 430,
});

export const AccordionItemStyled = styled(AccordionItem, {
  borderBottom: '1px solid $neutral20',
  '&:last-child': {
    borderBottom: 'none',
  },
});

export const AccordionContentStyled = styled(AccordionContent, {
  pr: 24,
  pb: 8,
  color: '$neutral90',
});

export const AccordionTriggerStyled = styled(AccordionTrigger, {
  fontSize: '$4',
  lineHeight: '39px',
  letterSpacing: '-0.8px',
  justifyContent: 'space-between',
  '& div.stateIcon': {
    p: 0,
    width: 20,
    height: 20,
    '& svg': {
      verticalAlign: 6,
    },
  },
});

export const ColumnRight = styled(Flex, {
  width: 645,
  justifyContent: 'center',
});

export const oneAgentImageCss = css({
  width: 525,
});
