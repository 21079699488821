import { ADETableColumnIds } from '../ADEListViewTable/ADEListViewTableColumns';

export const VALID_SEARCH_COLUMNS = [
  ADETableColumnIds.COLOR,
  ADETableColumnIds.DEVICE_NAME,
  ADETableColumnIds.SERIAL_NUMBER,
  ADETableColumnIds.MODEL,
  ADETableColumnIds.DESCRIPTION,
  ADETableColumnIds.BLUEPRINT,
  ADETableColumnIds.USER,
  ADETableColumnIds.ASSET_TAG,
  ADETableColumnIds.PROFILE,
  ADETableColumnIds.DEVICE_FAMILY,
  ADETableColumnIds.DEVICE_ASSIGNED_BY,
];

export function getValidSearchColumns(columnIds: ADETableColumnIds[]): string {
  return columnIds
    .filter((columnId) => VALID_SEARCH_COLUMNS.includes(columnId))
    .sort()
    .join(',');
}
