import { Checkbox } from '@kandji-inc/bumblebee';
import { Flex, Select } from '@kandji-inc/nectar-ui';
import { Setting } from 'features/library-items/template';
import React, { useState, useEffect } from 'react';
import featureFlags from 'src/config/feature-flags';
import PreviewFeedback from 'src/features/util/components/preview-feedback';
import { newLibraryItemService } from '../../../data-service/library-item/new-library-item-service';

const ReturnToServiceCard = (props) => {
  const { setting, update, isDisabled, isMac } = props;
  const [wifiProfiles, setWifiProfiles] = useState([]);
  const [selectedWifiProfile, setSelectedWifiProfile] = useState(null);
  const LDFF_VPP_RTS_PREVIEW = featureFlags.getFlag('vpp-rts-preview');

  useEffect(() => {
    newLibraryItemService
      .list({
        type: 'managed-library',
        identifier: 'com.kandji.profile.wifi',
        include_data: true,
      })
      .then((r) => {
        const profiles = r.data.results
          .filter((d) => d.data.some((d) => d.certificate?.type !== 'scep'))
          .filter((d) => d.active === true)
          .map((d) => ({
            label: d.name,
            value: d.id,
          }));

        // add 'None' option to the top of the list
        profiles.unshift({
          label: 'None',
          value: null,
        });

        setWifiProfiles(profiles);

        const currentProfile = profiles.find(
          (d) => d.value === setting.corporateWifiProfileId,
        );

        if (currentProfile) {
          setSelectedWifiProfile(currentProfile.value);
        }
      })
      .catch((e) => console.log(e));
  }, []);

  if (isMac) {
    return null;
  }

  return (
    <Setting.Card>
      <Setting.Header>
        <Flex justifyContent="space-between" flex="1" alignItems="center">
          <h3 className="b-h3">Return To Service</h3>
          {LDFF_VPP_RTS_PREVIEW && (
            <PreviewFeedback
              modalProps={{
                description: 'Provide your feedback on Return to Service',
                defaultCategory: 'End-user Self Service',
              }}
            />
          )}
        </Flex>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              testId="return-to-service-checkbox"
              checked={setting.isReturnToServiceEnabled}
              onChange={() => update('isReturnToServiceEnabled', (p) => !p)}
              disabled={isDisabled}
              label="Add to Self Service"
            />
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              Add a Return to Service option to Self Service&apos;s
              &quot;More&quot; section. This will allow users to easily and
              securely reset devices, and remove any user-created data. The
              device will erase itself, re-enroll to Kandji and get back to the
              Home Screen without any intervention.
            </p>
          </Setting.Helpers>
        </Setting.Row>

        <Setting.Row>
          <Setting.Title>
            <p className="b-txt">Wi-Fi Profile (optional)</p>
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light">
              Select an existing Wi-Fi Profile from your Library. You can omit
              this if the devices are on Ethernet or Cellular. This Wi-Fi
              profile should be from the same Blueprint as the device that will
              use Return to Service.
            </p>
          </Setting.Helpers>
          <Setting.Controls>
            <Select
              data-testid="rts-wifi-select-test-id"
              className="b-mb1"
              options={wifiProfiles}
              value={selectedWifiProfile}
              defaultValue={selectedWifiProfile}
              disabled={isDisabled}
              onChange={(profileId) => {
                update('corporateWifiProfileId', profileId);
                setSelectedWifiProfile(profileId);
              }}
              inputid="rts-wifi-select"
            />
          </Setting.Controls>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default ReturnToServiceCard;
