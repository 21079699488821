/* istanbul ignore file */
import { useMutation } from '@tanstack/react-query';
import { rootApi } from 'src/app/api/base';

export const useMutateWalkthrough = () => {
  return useMutation({
    mutationKey: ['mutate-walkthrough'],
    mutationFn: async (data: {
      is_explored_library?: boolean;
      is_explored_blueprints?: boolean;
      skip_ade?: boolean;
      skip_vpp?: boolean;
    }) => {
      const apiData = await rootApi('trial/v1/tour').patch(data);
      return apiData.data.results;
    },
  });
};
