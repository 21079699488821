/* istanbul ignore file */
import type { FIXME_Any } from '@kandji-inc/nectar-ui';
import {
  Button,
  Callout,
  DialogPrimitives as Dialog,
  Flex,
  MultiSelect,
  Select,
  Text,
} from '@kandji-inc/nectar-ui';
import useWhiteBg from 'features/util/hooks/use-white-bg';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { links } from 'src/app/common/constants';
import { CreateButton } from './components/CreateButton';
import { DeviceTargets } from './components/DeviceTargets';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { LabelText } from './components/LabelText';
import { TargetsPanel } from './components/TargetsPanel';

export const CreateProcessesCheck = ({
  onCreate,
  isCreating,
  createJobError,
  reportName,
  setReportName,
  reportNameError,
  setReportNameError,
  reportDescription,
  setReportDescription,
  selectedDevices,
  setSelectedDevices,
  selectedBlueprints,
  setSelectedBlueprints,
}) => {
  useWhiteBg();
  const history = useHistory();
  const [processSpecifier, setProcessSpecifier] = useState('specific');
  const [processName, setProcessName] = useState('');
  const [processNames, setProcessNames] = useState([]);
  const [processStates, setProcessStates] = useState([
    'idle',
    'running',
    'sleeping',
  ]);
  const [usersSpecifier, setUsersSpecifier] = useState('all');
  const [userName, setUserName] = useState('');
  const [userNames, setUserNames] = useState([]);

  return (
    <Flex flow="column" pt4>
      <Header
        reportType="processes"
        reportName={reportName}
        reportNameError={reportNameError}
        setReportName={setReportName}
        setReportNameError={setReportNameError}
        reportDescription={reportDescription}
        setReportDescription={setReportDescription}
      />
      <TargetsPanel>
        <DeviceTargets
          selectedDevices={selectedDevices}
          setSelectedDevices={setSelectedDevices}
          selectedBlueprints={selectedBlueprints}
          setSelectedBlueprints={setSelectedBlueprints}
        />
        <Flex flow="column" p4 gap="md">
          <LabelText>I want to search and return results for a</LabelText>
          <Flex gap="md">
            <Text css={{ paddingTop: 10, flexShrink: 0, width: 115 }}>
              Query on
            </Text>
            <Flex css={{ flex: 0, minWidth: 170 }}>
              <Select
                onChange={(value) => {
                  setProcessSpecifier(value);
                }}
                options={[
                  { value: 'all', label: 'All processes' },
                  { value: 'specific', label: 'Specific processes' },
                ]}
                size="full"
                value={processSpecifier}
              />
            </Flex>
            {processSpecifier === 'specific' && (
              <>
                <Select
                  options={[{ value: 'equals', label: 'equal' }]}
                  onChange={() => {}}
                  size="xxs"
                  value="equals"
                />
                <MultiSelect
                  searchable
                  showHiddenCountValue={false}
                  size="full"
                  value={processNames}
                  valueText={processName as FIXME_Any}
                  onChange={(val) => {
                    setProcessNames(val);
                  }}
                  onEventAction={(eventMeta, { internalOnEventAction }) => {
                    internalOnEventAction(eventMeta);
                    if (eventMeta.action === 'search-input-change') {
                      setProcessName(eventMeta.data);
                    }
                  }}
                  creatable={{
                    active: true,
                    onCreate: () => {
                      if (processNames.includes(processName)) {
                        return false;
                      }
                      setProcessName('');
                      return true;
                    },
                  }}
                  hint={
                    processName ? { label: 'Hit enter to add more' } : undefined
                  }
                  placeholder="Enter a process name"
                  components={{
                    OpenIcon: null,
                  }}
                  componentCss={{
                    searchInput: {
                      '&[data-search-value=""]': {
                        width: '100%',
                      },
                    },
                  }}
                  open={false}
                />
              </>
            )}
            <Text css={{ paddingTop: 10, flexShrink: 0 }}>in</Text>
            <Flex css={{ flex: 0, minWidth: 270 }}>
              <MultiSelect
                placeholder="Process state"
                size="full"
                value={processStates}
                options={[
                  {
                    value: 'idle',
                    label: 'Idle',
                  },
                  {
                    value: 'running',
                    label: 'Running',
                  },
                  {
                    value: 'sleeping',
                    label: 'Sleeping',
                  },
                ]}
                onChange={(val) => {
                  setProcessStates(val);
                }}
              />
            </Flex>
          </Flex>
          <Flex gap="md">
            <Text css={{ paddingTop: 10, flexShrink: 0, width: 115 }}>
              Return results for
            </Text>
            <Flex css={{ flex: 0, minWidth: 170 }}>
              <Select
                onChange={(value) => {
                  setUsersSpecifier(value);
                }}
                options={[
                  { value: 'all', label: 'All local users' },
                  { value: 'specific', label: 'Specific local users' },
                ]}
                size="full"
                value={usersSpecifier}
              />
            </Flex>
            {usersSpecifier === 'specific' && (
              <MultiSelect
                searchable
                showHiddenCountValue={false}
                size="full"
                value={userNames}
                valueText={userName as FIXME_Any}
                onChange={(val) => {
                  setUserNames(val);
                }}
                onEventAction={(eventMeta, { internalOnEventAction }) => {
                  internalOnEventAction(eventMeta);
                  if (eventMeta.action === 'search-input-change') {
                    setUserName(eventMeta.data);
                  }
                }}
                creatable={{
                  active: true,
                  onCreate: () => {
                    if (userNames.includes(userName)) {
                      return false;
                    }
                    setUserName('');
                    return true;
                  },
                }}
                hint={userName ? { label: 'Hit enter to add more' } : undefined}
                placeholder="Enter a username"
                components={{
                  OpenIcon: null,
                }}
                componentCss={{
                  searchInput: {
                    '&[data-search-value=""]': {
                      width: '100%',
                    },
                  },
                }}
                open={false}
              />
            )}
          </Flex>
          {processSpecifier === 'all' && (
            <Callout
              showCloseButton={false}
              text="Please be aware that running a query to return all processes on a device may take some time for the report to complete."
              theme="warning"
            />
          )}
          {selectedDevices.length === 0 && selectedBlueprints.length === 0 && (
            <Callout
              showCloseButton={false}
              text="You do not have any devices targeted. For faster results, target by device, blueprint or device family."
              theme="warning"
            />
          )}
          {createJobError && (
            <Callout
              showCloseButton={false}
              text={createJobError}
              theme="warning"
            />
          )}
        </Flex>
        <Dialog.Footer>
          <Button
            variant="subtle"
            onClick={() => {
              history.push(links.pulse);
            }}
          >
            Cancel
          </Button>
          <CreateButton
            isCreating={isCreating}
            onClick={() => {
              if (reportName === '') {
                setReportNameError('Required');
                return;
              }
              onCreate({
                checkType: 'processes',
                agentFilters: {
                  name: { like: [...processNames] },
                  state: { like: [...processStates] },
                  user: { like: [...userNames] },
                },
              });
            }}
          />
        </Dialog.Footer>
      </TargetsPanel>
      <Footer />
    </Flex>
  );
};
