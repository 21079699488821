export function withCache(callback, { resetTimeout } = {}) {
  const cache = {};

  const reset = (keys) => {
    const toDelete = keys || Object.keys(cache);
    toDelete.forEach((key) => {
      delete cache[key];
    });
  };

  return {
    call: (...args) => {
      const key = JSON.stringify(args);

      if (!Object.keys(cache).includes(key)) {
        if (resetTimeout != null) {
          setTimeout(() => reset([key]), resetTimeout);
        }

        cache[key] = callback.apply(this, args);
      }

      return cache[key];
    },
    reset,
  };
}
