import { useQuery } from '@tanstack/react-query';
import { prismService } from '../data-service/prism-service';
import type { PrismCountParamsSchema } from '../types/prism.types';

export const useCategoryDataCountQuery = ({
  params,
  filterBody,
}: {
  params: PrismCountParamsSchema;
  filterBody?: Record<string, unknown>;
}) =>
  useQuery({
    enabled: Boolean(params.category),
    queryKey: ['prism-category-count', params, filterBody],
    queryFn: async () => prismService.getPrismCategoryCount(params, filterBody),
  });
