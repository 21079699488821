import { Button, modal as Modal } from '@kandji-inc/bumblebee';
import { i18n } from 'src/i18n';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export function RequiredModal({ isOpen, onClose }: Props) {
  const parts = {
    header: { text: i18n.t('Selection needed to save this Library Item') },
    content: {
      children: (
        <>
          <p>
            {i18n.t(
              'To save this Library Item, you must select at least one of the following to manage:',
            )}
          </p>
          <ul>
            <li>{i18n.t('External volumes')}</li>
            <li>{i18n.t('Disk images')}</li>
            <li>{i18n.t('Server volumes')}</li>
          </ul>
        </>
      ),
    },
    footer: {
      children: (
        <Button onClick={onClose} className="b-ml">
          {i18n.t('Okay')}
        </Button>
      ),
    },
  };

  return <Modal isOpen={isOpen} onClose={onClose} parts={parts} />;
}
