// TODO: ideally, phase this file out
import * as constants from './constants';

export const getDevelopmentEnvironmentName = () => {
  const env = constants.REACT_APP_ENV_NAME;
  return env === 'PRD' ? '' : env;
};

export const getEnv = () => {
  const env = constants.REACT_APP_ENV_NAME;
  return env ? env.toLowerCase() : env;
};
