// istanbul ignore file
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { SavedView } from '../components/PrismTable/hooks/use-saved-views-query';
import { prismService } from '../data-service/prism-service';

type UseDeletePrismViewParams = {
  appliedSavedView: SavedView;
  onSuccess: () => void;
};

export const useDeletePrismView = ({
  onSuccess,
  appliedSavedView,
}: UseDeletePrismViewParams) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: () => prismService.deleteSavedView(appliedSavedView.id),
    onSuccess: () => {
      queryClient.setQueryData(['prism', 'savedViews'], (prev: any) =>
        prev.filter((view) => view.id !== appliedSavedView?.id),
      );
      onSuccess();
    },
  });

  return mutation;
};
