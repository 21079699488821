import { api } from 'app/api/base';
import { LIBRARY_API_BASE } from 'app/api/domains';
import Axios from 'axios';
import configs from 'features/library-items/library/library-item-configurations/items/profile';
import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class CustomProfileService extends NewLibraryItemService {
  static installationTypes = {
    CONTINUOUSLY_ENFORCE: 'continuously_enforce',
    NO_ENFORCEMENT: 'no_enforcement',
  };

  getConfigUploader = (
    file,
    onUploadProgress,
    { name = '', isActive = true } = {},
  ) => {
    const CancelSource = new Axios.CancelToken.source();

    return {
      cancel: CancelSource.cancel,
      upload: () => {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('is_active', isActive);
        formData.append('file', file || '');
        formData.append('identifier', configs['Custom Profile'].identifier);
        formData.append('scope', 'system');

        return api(`${LIBRARY_API_BASE}validate-custom-profile/`)
          .post(formData, {
            onUploadProgress: (p) =>
              onUploadProgress(Math.round((p.loaded / p.total) * 100)),
            cancelToken: CancelSource.token,
          })
          .then((res) => res.data);
      },
    };
  };
}

export const customProfileService = new CustomProfileService();
export default CustomProfileService;
