import { useContext } from 'react';
import Auth0Context from './context';

/**
 * const {
 *   // Auth state:
 *   error,
 *   isAuthenticated,
 *   isLoading,
 *   user,
 *   redirectInProgress,
 *   // Auth methods:
 *   getAccessTokenSilently,
 *   getIdTokenClaims,
 *   loginWithRedirect,
 *   logout,
 * } = useAuth0();
 *
 * useAuth0 hook
 */
const useAuth0 = () => useContext(Auth0Context);

export default useAuth0;
