import { privacyEnum } from './schema/privacy.schema';

import type {
  AppAccessPropertySetting,
  AppAccessSetting,
} from './privacy.types';

const { identifierType } = privacyEnum;

export const INITIAL_APP_ACCESS_PROPERTY: AppAccessPropertySetting = {
  Allowed: '',
  Property: '',
  AEReceiverIdentifier: '',
  AEReceiverIdentifierType: identifierType.enum.bundleID,
  AEReceiverCodeRequirement: '',
} as const;

export const INITIAL_APP_ACCESS_SETTING: AppAccessSetting = {
  CodeRequirements: '',
  Identifier: '',
  IdentifierType: identifierType.enum.bundleID,
  StaticCode: false,
  Properties: [INITIAL_APP_ACCESS_PROPERTY],
};

export default {
  id: null,
  name: '',
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  isActive: true,
  rules: null,
  appAccess: [INITIAL_APP_ACCESS_SETTING],
  supportsRules: true,
  invalidations: {
    invalidationsMap: {},
  },
};
