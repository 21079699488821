import { CONCATENATION } from '../../constants';

/**
 * Gets the human-readable display of a given Rule value.
 * @param {string or array} value - the value that needs to be transformed into a human-readable form
 * @param {string} jsonLogicOperator - the operator in a rule, helps define concatenation (if any)
 * @param {object} facetData - the facet information related to the particular value
 * @returns a string of the human-readable display value
 */
const formatDisplayValue = (value, jsonLogicOperator, facetData) => {
  const { options } = facetData;
  let displayValue = value;

  // If the data has options to choose from and is not a text field,
  // get the human readable portion the selected option(s)
  if (options) {
    // The value is an array of items (ex. ["1", "2"])
    if (Array.isArray(value)) {
      displayValue.map(
        (v) => options.find((option) => option.value === v).label,
      );
    } else {
      // The value is a single string (ex. "4")
      displayValue = options.find((option) => option.value === value).label;
    }
  }

  // If the data is an array of items, we concatenate
  // them according to the given operator
  if (Array.isArray(displayValue)) {
    displayValue = displayValue.join(CONCATENATION[jsonLogicOperator]);
  }

  return displayValue;
};

/**
 * A helper to transform a JsonLogic rule into human-readable data to
 * be ingested by Tags.
 * @param {Object} rule - A rule object looking something like:
 *                   {"==": [{"var": "computer.enrollment_type"}, "4"]}
 * @param {Object} facetMap - facet data indexed by key generated by `transformFacetDataFromApi()`
 * @returns an object with input, operator, and value Strings.
 */
const transformJsonLogicToTag = (rule, facetMap) => {
  // "=="
  const jsonLogicOperator = Object.keys(rule)[0];

  // [{"var": "computer.enrollment_type"}, 4]
  const ruleData = rule[jsonLogicOperator];

  // {"var": "computer.enrollment_type"}
  const subjectAndKey = ruleData[0];

  // "enrollment_type"
  const ruleKey = subjectAndKey.var?.split('.')[1];

  // Get facet data associated with our rule key
  const facetData = facetMap[ruleKey];

  const operator = facetData?.supported_operators.find(
    ({ value }) => value === jsonLogicOperator,
  ).label;

  // "4" or ["1", "2"]
  const value = ruleData[1];

  return {
    input: facetData?.name,
    operator,
    value,
    jsonLogicOperator,
    ruleKey,
    facetData,
  };
};

export { transformJsonLogicToTag, formatDisplayValue };
