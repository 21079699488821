import React, { useEffect, useState } from 'react';

import {
  array,
  bool,
  func,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types';

import { TextInput as BumblebeeText } from '@kandji-inc/bumblebee';

import { characterLimitValidator, regexValidator } from './validators';

const Text = ({
  fieldKey,
  placeholder,
  value: val,
  updateField,
  updateIsInvalid,
  validation,
  disabled,
  ariaLabel,
  readOnly,
}) => {
  const { character_limit, regex } = validation;

  const validator = (v) => [
    characterLimitValidator(
      v,
      character_limit?.value,
      character_limit?.error_message,
    ),
    regexValidator(v, regex?.value, regex?.error_message),
  ];

  // If a non-String Value is received, reset the field
  // to empty. Otherwise, use what we are given.
  const valueIsString = typeof val === 'string';
  const [value, setValue] = useState(valueIsString ? val : '');

  useEffect(() => {
    setValue(val);
  }, [val]);

  // Update the form if we did not receive a String
  // as a Value and had to reset the field
  useEffect(() => {
    if (!valueIsString) {
      updateField(fieldKey, value);
    }
  }, []);

  const onChange = ({ target }) => {
    setValue(target.value);
    updateField(fieldKey, target.value);
  };

  return (
    <BumblebeeText
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      validator={validator}
      onInvalidate={updateIsInvalid}
      disabled={disabled}
      aria-label={ariaLabel}
      readOnly={readOnly}
    />
  );
};

Text.propTypes = {
  fieldKey: string.isRequired,
  placeholder: string.isRequired,
  value: oneOfType([string, array]),
  updateField: func,
  updateIsInvalid: func,
  validation: shape({
    character_limit: shape({
      value: number,
      error_message: string,
    }),
    regex: shape({
      value: string,
      error_message: string,
    }),
  }),
  disabled: bool,
  ariaLabel: string.isRequired,
  readOnly: bool,
};

Text.defaultProps = {
  value: '',
  updateField: () => {},
  updateIsInvalid: () => {},
  validation: {
    character_limit: null,
    regex: null,
  },
  disabled: false,
  readOnly: false,
};

export default Text;
