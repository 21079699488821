import { logError as logDatadogError } from 'src/config/datadog-rum';

/**
 * Logs an error to a logging provider.
 * @param error The error to log.
 * @param context Additional context to include with the error.
 * @returns void
 * @example
 * logError(new Error('An error occurred'), { context: 'some context' });
 */

export function logError(error: Error, context?: object) {
  logDatadogError(error, context);
}
