import { api } from 'app/api/base';
import { URL_MDM } from 'app/api/urls';

// ==== MDM Commands ==== //

export const getPendingCommands = (id) => (params) =>
  api(`${URL_MDM}${id}/commands/`)
    .get(params)
    .then((res) => res.data);

export const cancelPendingCommand = (computerId) => (commandId) =>
  api(`${URL_MDM}${computerId}/commands/${commandId}/`)
    .delete()
    .then((res) => res.data);

export const cancelAllPendingCommand = (computerId) =>
  api(`${URL_MDM}${computerId}/cancel-pending/`)
    .post({})
    .then((res) => res.data);

export const retryFailedCommand = (computerId) => (commandId) =>
  api(`${URL_MDM}${computerId}/commands/${commandId}/`)
    .put({})
    .then((res) => res.data);
