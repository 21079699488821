/* istanbul ignore file */
export const toMb = (v) => v / 1e6;

export const toImage = (file) =>
  new Promise((resolve, reject) => {
    if (!file) {
      return reject(new Error('file is required'));
    }

    if (/\.heic$/.test(file.name)) {
      return Promise.resolve('');
    }
    const reader = new window.FileReader();
    reader.onabort = () => reject(new Error('file reading was aborted'));
    reader.onerror = () => reject(new Error('file reading has failed'));
    reader.onload = () => {
      const img = new window.Image();
      img.src = reader.result;
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width,
          name: file.name,
          size: file.size,
          dataUrl: reader.result,
          file,
        });
      };
    };
    reader.readAsDataURL(file);
  });
