/* istanbul ignore file - legacy code, moved */
import { libraryAxios } from 'app/api/base';
import { LineLoader } from 'app/components/interface/LineLoader';
import { vppAppLog } from 'app/components/library/log';
import ExpandedActivityRow from 'components/common/activity-table/expanded-activity-row';
import { OldAppStoreStatusFilter } from 'features/library-items/items/app-store/OldAppStoreStatusFilter';
import { OldAppStoreStatusSearch } from 'features/library-items/items/app-store/OldAppStoreStatusSearch';
import { OldAppStoreStatusTable } from 'features/library-items/items/app-store/OldAppStoreStatusTable';
import get from 'lodash/get';
import moment from 'moment';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import Basic from 'theme/components/molecules/Basic';

const verboseFormat = 'MMMM D, YYYY [at] HH:MM A';

const getLibraryItemStatuses = (id, params) =>
  // old backend return array of all statuses in res.data
  // new backend has pagination and statuses are in res.data.results
  // new FE works fine with pagination, but this is method for legacy code and should work without pagination
  // so I add get param `sizePerPage: 1000` and return `res.data.results`
  libraryAxios(`library-items/statuses/${id}`)
    .get({
      sizePerPage: 1000,
      ...params,
    })
    .then((res) => res.data.results);

const defaultConfig = {
  getStatuses: getLibraryItemStatuses, // for old status table
  flushItemStatus: () => Promise.resolve({}),
  statusCanBeFlushed: (data, status) =>
    ['PASS', 'success', 'REMEDIATED', 'ERROR'].includes(status.status),
  statusIsExpandable: (status) => status && status.log,
  getStatusExpandedInfo: (status) => (
    <div className="parameter-details">{vppAppLog(status)}</div>
  ),
  getStatusSuccessLabel: () => 'Installed',
  getStatusDateHeader: () => 'Install Date',
  getStatusDateField: () => 'date',
};

const flushLibraryItemStatus = (type) => (id) =>
  libraryAxios(`library-items/statuses/flush/${id}`)
    .delete(null, { params: { type } })
    .then((res) => res.data);

const useDataRetriever = (dataFn, dataArgs = [], onError = console.log) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  const reloadData = () => {
    setIsLoading(true);
    dataFn(...dataArgs)
      .then(setData)
      .catch(onError)
      .finally(() => setIsLoading(false));
  };

  useEffect(reloadData, dataArgs);

  return { data, reloadData, isLoading };
};

const OldAppStoreStatusList = ({
  data,
  config,
  statuses,
  reloadStatuses,
  filteredStatus,
  setFilteredStatus,
}) => {
  const history = useHistory();
  const [table, setTable] = useState(null);

  const successLabel = config.getStatusSuccessLabel(data);
  const dateHeader = config.getStatusDateHeader(data);
  const dateField = config.getStatusDateField(data);

  return (
    <div className="view-status">
      <div className="filter-block">
        <div className="paper">
          <div className="node">
            <div className="filters">
              <OldAppStoreStatusFilter
                statuses={statuses}
                filteredStatus={filteredStatus}
                setFilteredStatus={setFilteredStatus}
                successLabel={successLabel}
              />
              {table && <OldAppStoreStatusSearch table={table} />}
            </div>
          </div>
        </div>
      </div>
      <OldAppStoreStatusTable
        data={data}
        statuses={statuses}
        filteredStatus={filteredStatus}
        reloadStatuses={reloadStatuses}
        setTable={setTable}
        config={config}
        successLabel={successLabel}
        dateHeader={dateHeader}
        dateField={dateField}
        history={history}
      />
    </div>
  );
};

const OldAppStoreViewStatus = ({
  config: configOverrides,
  context: Context,
}) => {
  const { data, item } = useContext(Context);
  const [filteredStatus, setFilteredStatus] = useState('all');
  const history = useHistory();

  const config = {
    ...defaultConfig,
    ...configOverrides,
  };

  const fetchTabData = (fetchFn, args) => {
    const { data, reloadData, isLoading } = useDataRetriever(
      fetchFn,
      [...args],
      () => {
        /* review-later: do we need to setup context to use snackbar or
         * can we use it from app/_actions/ui ?
         */
        // dispatch(setSnackbar('Something went wrong'));
        history.push(`/library`);
      },
    );
    return { data, reloadData, isLoading };
  };

  const {
    data: statuses,
    reloadData: reloadStatuses,
    isLoading,
  } = fetchTabData(config.getStatuses, [item]);

  return (
    <Basic section="view">
      {isLoading ? (
        <LineLoader />
      ) : (
        <OldAppStoreStatusList
          data={data}
          config={config}
          statuses={statuses}
          reloadStatuses={reloadStatuses}
          filteredStatus={filteredStatus}
          setFilteredStatus={setFilteredStatus}
        />
      )}
    </Basic>
  );
};

const OldAppStoreExpandedRow = ({ item: status }) => {
  const data = useMemo(() => {
    const profileData = [];

    if (status.control_reported_at) {
      profileData.push({
        label: 'Status',
        values: [moment(status.control_reported_at).format(verboseFormat)],
      });
    }

    if (status.control_log) {
      profileData.push({
        label: 'Status details',
        values: status.control_log.split('\n'),
      });
    }

    if (status.last_audit_run) {
      profileData.push({
        label: 'Last audit',
        values: [moment(status.last_audit_run).format(verboseFormat)],
      });
    }

    if (status.last_audit_log) {
      profileData.push({
        label: 'Last audit details',
        values: status.last_audit_log.split('\n'),
      });
    }

    if (status.date) {
      profileData.push({
        label: 'Last install',
        values: [moment(status.date).format(verboseFormat)],
      });
    }

    if (status.log) {
      profileData.push({
        label: 'Last install details',
        values: status.log.split('\n'),
      });
    }

    return profileData;
  }, [status]);

  return <ExpandedActivityRow data={data} />;
};

export const OldAppStoreStatusTab = ({ itemId }) => {
  const Context = useMemo(
    () =>
      createContext({
        item: itemId,
        data: {},
      }),
    [itemId],
  );

  return (
    <OldAppStoreViewStatus
      config={{
        flushItemStatus: flushLibraryItemStatus('app-store'),
        statusIsExpandable: (status) =>
          get(status, 'log') || get(status, 'last_audit_log'),
        ExpandedComponent: OldAppStoreExpandedRow,
        getStatusSuccessLabel: () => 'Installed',
        getStatusDateHeader: () => 'Install Date',
        getDefaultIcon: () => <i className="fas fa-fighter-jet" />,
      }}
      context={Context}
    />
  );
};
