import WifiService from '../../../wifi-service';

const { general: generalKeys } = WifiService.keys;

const general = (data) => {
  const {
    SSID_STR = '',
    AutoJoin = false,
    HIDDEN_NETWORK = false,
    Priority = null,
    EnableIPv6 = null,
    CaptiveBypass = null,
    DisableAssociationMACRandomization = false,
  } = data;

  const isPriorityObject =
    Priority != null &&
    typeof Priority === 'object' &&
    'checked' in Priority &&
    'value' in Priority;

  return {
    [generalKeys.ssid]: SSID_STR || '',
    [generalKeys.ipv6]: EnableIPv6 === null ? true : EnableIPv6,
    [generalKeys.withIpV6]: EnableIPv6 != null,
    [generalKeys.autoJoin]: AutoJoin || false,
    [generalKeys.hiddenNetwork]: HIDDEN_NETWORK || false,
    [generalKeys.disableMacAddressRandomization]:
      DisableAssociationMACRandomization || false,
    [generalKeys.disableCaptiveNetworkPriority]: CaptiveBypass,
    [generalKeys.networkPriority]: Priority,
    [generalKeys.withNetworkPriority]: isPriorityObject
      ? !!Priority?.checked
      : Priority != null,
  };
};

export default general;
