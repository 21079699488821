import { i18n } from 'src/i18n';

export const ACTIVATION_LOCK_OPTION_ITEM = {
  ALLOW: () => ({
    value: 'allow',
    label: i18n.t('Allow user-based Activation Lock'),
  }),
  DISALLOW: () => ({
    value: 'disallow',
    label: i18n.t('Disallow user-based Activation Lock'),
  }),
};

export const ACTIVATION_LOCK_OPTIONS = () => [
  ACTIVATION_LOCK_OPTION_ITEM.ALLOW(),
  ACTIVATION_LOCK_OPTION_ITEM.DISALLOW(),
];
