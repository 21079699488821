/* istanbul ignore file - tested in LibraryItemService */
import NewLibraryItemService from 'features/library-items/data-service/library-item/new-library-item-service';

class MacosReleasesService extends NewLibraryItemService {
  constructor(data = { isAdd: false }) {
    super();
    this.isAdd = data.isAdd;
  }

  /**
   * Get either the auto app template or instance depending on current URL.
   * @override
   */
  async get(id, params) {
    return this.isAdd ? super.getTemplate(id, params) : super.get(id, params);
  }

  static installationTypes = {
    CONTINUOUSLY_ENFORCE: 'continuously_enforce',
    NO_ENFORCEMENT: 'no_enforcement',
  };
}

export const macosReleasesService = new MacosReleasesService();
export default MacosReleasesService;
