import { Button } from '@kandji-inc/bumblebee';
import toaster from '@kandji-inc/bumblebee/lib/atoms/toaster';
import type { AxiosResponse } from 'axios';
import React, { useContext } from 'react';
import { TableContext } from '../table.context';

type TitleProps = Readonly<{
  onExport?: () => Promise<AxiosResponse<string>>;
  exportText?: string;
  enableExport?: boolean;
}>;

export default function Title(props: TitleProps) {
  const { onExport, exportText = 'Export', enableExport = false } = props;
  const [{ totalCount, title }] = useContext(TableContext);

  const handleOnClick = () => {
    onExport()
      .then((response) => {
        const CSVFile = new Blob([response.data], {
          type: 'text/csv',
        });
        // Create to temporary link to initiate
        // download process
        const tempLink = document.createElement('a');

        // Download csv file
        const fileName = `${new URL(window.location.href).pathname
          .substring(1)
          .split('/')
          .join('_')}_${new Date().toISOString()}.csv`;

        tempLink.download = fileName;
        const url = window.URL.createObjectURL(CSVFile);
        tempLink.href = url;

        // This link should not be displayed
        tempLink.style.display = 'none';
        document.body.appendChild(tempLink);

        // Automatically click the link to
        // trigger download
        tempLink.click();
        document.body.removeChild(tempLink);
      })
      .catch(() => {
        toaster('Unable to export threat release details, please try again.');
      });
  };

  return (
    <div className="b-flex-btw b-flex-align-center">
      <div className="b-flex-gtiny b-flex-align-center">
        <h3 className="b-h3">{title}</h3>
        {totalCount !== undefined && totalCount !== null && (
          <span className="v-table__select-counter __v-table-ns">
            {totalCount}
          </span>
        )}
      </div>
      {enableExport ? (
        <div className="b-flex-gtiny b-flex-align-center">
          <Button
            onClick={handleOnClick}
            icon="file-export"
            kind="link"
            size="small"
          >
            {exportText}
          </Button>
        </div>
      ) : null}
    </div>
  );
}
