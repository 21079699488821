import PropTypes from 'prop-types';
import React, { useMemo, useCallback, useState, memo } from 'react';
import Icon from '../atoms/Icon';

const Button = memo(
  ({
    kind,
    tier,
    label,
    icon,
    iconRight,
    active,
    disabled,
    loader,
    onClick,
  }) => {
    const labelElement = useMemo(() => {
      if (loader && disabled) {
        return <Icon icon="sync" className="btn-sync-icon" />;
      }
      if (label) {
        return <span className="label">{label}</span>;
      }

      return null;
    }, [loader, disabled, label]);

    return (
      <button
        type="button"
        className={`btn btn-${kind} btn-${tier} ${
          active ? 'active' : 'passive'
        }`}
        disabled={disabled}
        onClick={onClick}
      >
        {icon && !iconRight && <Icon icon={icon} />}
        {labelElement}
        {icon && iconRight && <Icon icon={icon} />}
      </button>
    );
  },
);

Button.propTypes = {
  kind: PropTypes.string,
  tier: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  iconRight: PropTypes.bool,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  loader: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

Button.defaultProps = {
  kind: 'default',
  tier: 'zero',
  label: undefined,
  icon: undefined,
  iconRight: false,
  active: false,
  disabled: false,
  loader: false,
};

const Group = ({
  kind,
  label,
  id,
  className,
  options,
  selection,
  setSelection,
  children,
}) => {
  const loopOption = useMemo(
    () =>
      options &&
      options.map(({ label: optionLabel, icon, value }) => {
        const onClick = () => setSelection(value);
        return (
          <Button
            key={`option-${value}`}
            kind={kind}
            label={optionLabel}
            icon={icon}
            active={selection === value}
            onClick={onClick}
          />
        );
      }),
    [options, kind, selection, setSelection],
  );

  return (
    <div
      id={id}
      className={`group group-${kind} btn-group ${className}`}
      role="group"
      aria-label={label}
    >
      {children}
      {loopOption}
    </div>
  );
};

Group.propTypes = {
  kind: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array,
  selection: PropTypes.string,
  setSelection: PropTypes.func,
  children: PropTypes.node,
};

Group.defaultProps = {
  kind: 'default',
  label: 'Group',
  id: undefined,
  className: 'no-class',
  options: undefined,
  selection: undefined,
  setSelection: undefined,
  children: undefined,
};

export { Button, Group };
