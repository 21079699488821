/* istanbul ignore file */
import {
  authApi,
  newLibraryAxios,
  libraryAxios as oldLibraryAxios,
} from 'app/api/base';

class NewLibraryItemService {
  async get(id, params) {
    return newLibraryAxios(`${id}/`).get(params);
  }

  async list(params) {
    return newLibraryAxios('').get(params);
  }

  async v2List() {
    return authApi('web/list-library-items-v2').get({});
  }

  async v2Counts() {
    return authApi('web/list-library-items-counts').get({});
  }

  async create(data) {
    return newLibraryAxios('').post(data);
  }

  async patch(id, data) {
    return newLibraryAxios(`${id}/`).patch(data);
  }

  async update(id, data) {
    return newLibraryAxios(`${id}/`).put(data);
  }

  async remove(id) {
    return newLibraryAxios(`${id}/`).delete();
  }

  async duplicate(id) {
    return newLibraryAxios(`${id}/duplicate/`).post();
  }

  // Blueprint Conflicts - not actively being used
  async checkConflicts({ type, itemId, blueprintIds, identifier }) {
    const params = {
      type__in: type,
      depprofileconfig__is_default__exclude: true,
      blueprint__in: blueprintIds.join(', '),
    };

    if (identifier) {
      params.identifier = identifier;
    }
    if (itemId) {
      params.id__exclude = itemId;
    }

    return newLibraryAxios('')
      .get(params)
      .then(({ data }) =>
        data.results
          .map((conflictedLibItem) =>
            conflictedLibItem.blueprints
              .filter((bp) => blueprintIds.includes(bp.id))
              .map((bp) => ({
                blueprint_id: bp.id,
                blueprint_name: bp.name,
                conflicted_item_id: conflictedLibItem.id,
                conflicted_item_name: conflictedLibItem.name,
              })),
          )
          .flat(),
      );
  }

  /**
   *
   * @param {Array} statuses ids of statuses to flush
   * @returns {Promise<*>} an axios promise
   */
  async flushStatusesById(statuses) {
    return newLibraryAxios(`statuses/flush/`).post({ delete_ids: statuses });
  }

  async getTemplate(id, params) {
    const client = oldLibraryAxios(`managed-library-items/${id}`);
    return client.get(params);
  }
}

const newLibraryItemService = new NewLibraryItemService();
export { newLibraryItemService };
export default NewLibraryItemService;
