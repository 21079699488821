/* istanbul ignore file */

import { i18n } from 'i18n';
import React from 'react';

import { formatTime } from '../../common/helpers';

const entry = ({ details }, { connectorList }) => {
  const { connector } = details;

  const { name, bound_domain } =
    connectorList?.find(({ id }) => id === connector) || {};

  const pendingMessage = `${i18n.t('Connector details are pending')}...`;

  return {
    icon: 'trash-can',
    iconText: i18n.t('Connector deleted'),
    text: i18n.t('Connector deleted'),
    description: name || bound_domain || pendingMessage,
  };
};

const detail = ({ action_type, details }, { connectorList }) => {
  const { connector, deleted_by, deleted_on } = details;

  const { name, bound_domain } =
    connectorList?.find(({ id }) => id === connector) || {};

  const pendingMessage = `${i18n.t('Connector details are pending')}...`;

  return (
    <div className={`activity-entry-details ${action_type}`}>
      <p>
        {i18n.t('Deleted on:')} {formatTime(deleted_on)}
      </p>
      <p>
        {i18n.t('Deleted by:')} {deleted_by}
      </p>
      <p>
        {i18n.t('Connector name:')} {name || bound_domain || pendingMessage}
      </p>
    </div>
  );
};

export default { entry, detail };
