import { api } from 'app/api/base';
import sortBy from 'lodash/sortBy';
import { URL_TEMPLATE_CATEGORIES } from '../api/urls';

export const T_CATEGORIES_FETCHED = 'T_CATEGORIES_FETCHED';

const sortCategoryTemplates = (categories) =>
  sortBy(categories, 'weight').map((c) => ({
    ...c,
    templates: sortBy(c.templates, 'blueprint.name'),
  }));

export const getCategories = () => (dispatch) =>
  api(URL_TEMPLATE_CATEGORIES)
    .get()
    .then((res) => {
      dispatch({
        type: T_CATEGORIES_FETCHED,
        payload: sortCategoryTemplates(res.data.results),
      });
    })
    .catch((err) => console.log('Error', err));
