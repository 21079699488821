/* istanbul ignore file */
import { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { links } from 'src/app/common/constants';
import { DEVICE_FAMILY } from 'src/features/visibility/prism/views/PrismCategories';
import {
  useCreatePulseCheck,
  useCreatePulseJob,
} from '../../hooks/use-create-pulse-check-query';
import { CreateFileCheck } from './CreateFileCheck';
import { CreatePreferencesCheck } from './CreatePreferencesCheck';
import { CreateProcessesCheck } from './CreateProcessesCheck';
import { CreateUsersCheck } from './CreateUsersCheck';
import type { Device } from './components/DeviceTargets';

const CreatePulseCheck = () => {
  const [reportName, setReportName] = useState('');
  const [reportNameError, setReportNameError] = useState('');
  const [reportDescription, setReportDescription] = useState('');
  const [selectedDevices, setSelectedDevices] = useState<Device[]>([]);
  const [selectedBlueprints, setSelectedBlueprints] = useState<string[]>([]);
  const [createJobError, setCreateJobError] = useState<string>('');

  const { params } = useRouteMatch();
  const { pulseType } = params as { pulseType: string };

  const history = useHistory();
  const { mutateAsync: createPulseCheck, isPending: isCreatingPulseCheck } =
    useCreatePulseCheck();
  const { mutateAsync: createPulseJob, isPending: isCreatingPulseJob } =
    useCreatePulseJob();

  const handleCreatePulseJobClick = async ({ checkType, agentFilters }) => {
    setCreateJobError('');

    const pulseResponse = await createPulseCheck({
      devices: [...selectedDevices.map((device) => device.id)],
      device_families: [DEVICE_FAMILY.mac.id],
      blueprints: [...selectedBlueprints],
      type: checkType,
      name: reportName,
      description: reportDescription,
      agent_filters: agentFilters,
    });

    try {
      await createPulseJob(
        {
          pulseCheckId: pulseResponse.id,
          ...pulseResponse,
          agent_filters: pulseResponse.agent_filters
            ? JSON.parse(pulseResponse.agent_filters)
            : {},
        },
        {
          onSuccess: (response) => {
            history.push(`${links.pulse}/${response.job_id}`);
          },
        },
      );
    } catch (error) {
      const response = JSON.parse(error.request.responseText);
      if (response.detail === 'ERROR__NO_DEVICES_FOUND') {
        setCreateJobError(
          'No macOS devices found for the selected Blueprints. Select specific macOS devices or Blueprints with macOS devices and re-run the check.',
        );
      } else {
        setCreateJobError(
          'An error occurred while creating the job. Please try again.',
        );
      }
    }
  };

  const createJobProps = {
    onCreate: handleCreatePulseJobClick,
    isCreating: isCreatingPulseJob || isCreatingPulseCheck,
    reportName,
    createJobError,
    setReportName,
    reportNameError,
    setReportNameError,
    reportDescription,
    setReportDescription,
    selectedDevices,
    setSelectedDevices,
    selectedBlueprints,
    setSelectedBlueprints,
  };

  if (pulseType === 'file') {
    return <CreateFileCheck {...createJobProps} />;
  }
  if (pulseType === 'processes') {
    return <CreateProcessesCheck {...createJobProps} />;
  }
  if (pulseType === 'users') {
    return <CreateUsersCheck {...createJobProps} />;
  }
  if (pulseType === 'preferences') {
    return <CreatePreferencesCheck {...createJobProps} />;
  }
  return null;
};

export default CreatePulseCheck;
