// src/toSlug.ts
function toSlug(str) {
  let s = str;
  if (!s) {
    return "";
  }
  s = s.toLowerCase().trim();
  s = s.replace(/ & /g, " and ");
  s = s.replace(/[ ]+/g, "-");
  s = s.replace(/[-]+/g, "-");
  s = s.replace(/[^a-z0-9-]+/g, "");
  return s;
}

// src/useIsomorphicLayoutEffect.tsx
import * as React from "react";
var useIsomorphicLayoutEffect = typeof window !== "undefined" ? React.useLayoutEffect : () => {
};

// src/usePrevious.tsx
import * as React2 from "react";
function usePrevious(value) {
  const ref = React2.useRef(value);
  React2.useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

// src/useOnOutsideClick.tsx
import { useEffect as useEffect2 } from "react";
function useOnOutsideClick(ref, handler, ignoreRefs, mouseEvent = "mousedown") {
  useEffect2(() => {
    const f = (event) => {
      const el = ref == null ? void 0 : ref.current;
      if (!el || el.contains(event.target) || (ignoreRefs == null ? void 0 : ignoreRefs.some(
        (iRef) => {
          var _a;
          return (_a = iRef == null ? void 0 : iRef.current) == null ? void 0 : _a.contains(event.target);
        }
      ))) {
        return;
      }
      handler(event);
    };
    window.addEventListener(mouseEvent, f);
    return () => window.removeEventListener(mouseEvent, f);
  }, []);
}

// src/assertElseThrow.ts
var isProduction = process.env.NODE_ENV === "production";
var DEFAULT_PREFIX = "Invariant failed";
var hasSomeAllOptionsProps = (typeTargetOrOptions) => typeof typeTargetOrOptions === "object" && typeTargetOrOptions !== null && ("prefix" in typeTargetOrOptions || "throwInProd" in typeTargetOrOptions || "throwInDev" in typeTargetOrOptions);
function invariant(condition, message, typeTargetOrOptions, options) {
  const opt = hasSomeAllOptionsProps(typeTargetOrOptions) ? typeTargetOrOptions : options;
  const {
    prefix = DEFAULT_PREFIX,
    throwInProd = false,
    throwInDev = true
  } = opt || {};
  if (condition) {
    return;
  }
  if (isProduction && throwInProd) {
    throw new Error(prefix);
  }
  const provided = typeof message === "function" ? message() : message;
  const value = provided ? `${prefix}: ${provided}` : `${prefix}`;
  if (throwInDev) {
    throw new Error(value);
  } else {
    console.error(value);
  }
}
var assertElseThrow = invariant;

// src/booleanUtils.ts
var isNullish = (value) => value === null || value === void 0;
var isNonNullish = (value) => value !== null && value !== void 0;
var hasOwn = (obj, key) => Object.prototype.hasOwnProperty.call(obj, key);

// src/useFocusInput.tsx
import { useEffect as useEffect3, useRef as useRef2 } from "react";
function useFocusInput(dependencies) {
  const ref = useRef2(null);
  useEffect3(() => {
    const focusTimeout = setTimeout(() => {
      var _a;
      (_a = ref == null ? void 0 : ref.current) == null ? void 0 : _a.focus();
    }, 10);
    return () => {
      clearTimeout(focusTimeout);
    };
  }, [dependencies]);
  return ref;
}

// src/useInfiniteScroll.tsx
import { useCallback, useRef as useRef3 } from "react";
function handleIntesection(entries, hasNextPage, isFetching, fetchNextPage) {
  if (entries[0].isIntersecting && hasNextPage && !isFetching) {
    fetchNextPage();
  }
}
function useInfiniteScroll({
  isLoading,
  hasNextPage,
  isFetching,
  fetchNextPage
}) {
  const observer = useRef3();
  const lastElementRef = useCallback(
    (node) => {
      if (isLoading)
        return;
      if (observer.current)
        observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        handleIntesection(entries, hasNextPage, isFetching, fetchNextPage);
      });
      if (node)
        observer.current.observe(node);
    },
    [fetchNextPage, hasNextPage, isFetching, isLoading]
  );
  return lastElementRef;
}

// src/useDebounce.tsx
import { useEffect as useEffect4, useState } from "react";
var useDebounce = (value, delay = 100) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect4(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [value, delay]);
  return debouncedValue;
};
export {
  assertElseThrow,
  handleIntesection,
  hasOwn,
  isNonNullish,
  isNullish,
  toSlug,
  useDebounce,
  useFocusInput,
  useInfiniteScroll,
  useIsomorphicLayoutEffect,
  useOnOutsideClick,
  usePrevious
};
