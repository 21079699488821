import { api } from 'app/api/base';
import { URL_COUNTS } from 'app/api/urls';

const ALERT_MONITOR_INTERVAL = 30000;

export const startAlertMonitor = (setAlertCount) => {
  const alertMonitorFn = () =>
    api(URL_COUNTS)
      .get({ fields: ['active_alerts'] })
      .then(
        (response) =>
          response !== null && setAlertCount(response.data.active_alerts),
      )
      .catch(() => {
        console.log('ERROR: Could not fetch active alerts');
      });
  alertMonitorFn();
  return setInterval(alertMonitorFn, ALERT_MONITOR_INTERVAL);
};

export const stopAlertMonitor = (alertMonitor) => clearInterval(alertMonitor);
