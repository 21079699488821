// Unknown
import appleTV3 from './img/com.apple.apple-tv-3.webp';
import appleTV4 from './img/com.apple.apple-tv-4.webp';
import appleTV4k3rd from './img/com.apple.apple-tv-4k-3rd.webp';

// Apple TV
import appleTV from './img/com.apple.apple-tv.webp';

// iMac
import emac from './img/com.apple.emac.webp';
import imac2021Silver from './img/com.apple.imac-2021-silver.webp';
import imacAluminum20 from './img/com.apple.imac-aluminum-20.webp';
import imacAluminum24 from './img/com.apple.imac-aluminum-24.webp';
import imacG415 from './img/com.apple.imac-g4-15.webp';
import imacG417 from './img/com.apple.imac-g4-17.webp';
import imacG420 from './img/com.apple.imac-g4-20.webp';
import imacG517 from './img/com.apple.imac-g5-17.webp';

// Power Mac
import imaciSight17 from './img/com.apple.imac-iSight-17.webp';
import imaciSight24 from './img/com.apple.imac-iSight-24.webp';
import imacUnibody21NoOptical from './img/com.apple.imac-unibody-21-no-optical.webp';
import imacUnibody21 from './img/com.apple.imac-unibody-21.webp';
import imacUnibody27NoOptical from './img/com.apple.imac-unibody-27-no-optical.webp';
import imacUnibody27 from './img/com.apple.imac-unibody-27.webp';

// iMac Pro
import imacPro2017 from './img/com.apple.imacpro-2017.webp';
import ipad2Black from './img/com.apple.ipad-2-black.webp';
import ipad6WiFin1 from './img/com.apple.ipad-6-wifi-1.webp';
import ipad6WWANn1 from './img/com.apple.ipad-6-wwan-1.webp';
import ipad7WiFin1 from './img/com.apple.ipad-7-wifi-1.webp';
import ipad7WWANn1 from './img/com.apple.ipad-7-wwan-1.webp';
import ipad8WiFin1 from './img/com.apple.ipad-8-wifi-1.webp';
import ipad8WWANn1 from './img/com.apple.ipad-8-wwan-1.webp';
import ipad9WiFin1 from './img/com.apple.ipad-9-wifi-1.webp';
import ipad9WWANn1 from './img/com.apple.ipad-9-wwan-1.webp';
import iPad10n1 from './img/com.apple.ipad-10-1.webp';
import ipadAirx99989b from './img/com.apple.ipad-air-99989b.webp';
import ipadAirm21 from './img/com.apple.ipad-air-m2-1.webp';
import ipadAir2WiFixb4b5b9 from './img/com.apple.ipad-air2-wifi-b4b5b9.webp';
import ipadAir2WWANxb4b5b9 from './img/com.apple.ipad-air2-wwan-b4b5b9.webp';
import ipadAir3WiFin1 from './img/com.apple.ipad-air3-wifi-1.webp';
import ipadAir3WWANn1 from './img/com.apple.ipad-air3-wwan-1.webp';
import ipadAir4n1 from './img/com.apple.ipad-air4-1.webp';
import ipadAir5n1 from './img/com.apple.ipad-air5-1.webp';
import ipadMiniA17n1 from './img/com.apple.ipad-mini-a17-1.webp';
import ipadMiniBlack3G from './img/com.apple.ipad-mini-black-3g.webp';
import ipadMiniBlackWiFi from './img/com.apple.ipad-mini-black-wifi.webp';
import ipadMini2x99989b from './img/com.apple.ipad-mini2-99989b.webp';
import ipadMini3WiFixb4b5b9 from './img/com.apple.ipad-mini3-wifi-b4b5b9.webp';
import ipadMini3WWANxb4b5b9 from './img/com.apple.ipad-mini3-wwan-b4b5b9.webp';
import ipadMini4WiFixb4b5b9 from './img/com.apple.ipad-mini4-wifi-b4b5b9.webp';
import ipadMini4WWANxb4b5b9 from './img/com.apple.ipad-mini4-wwan-b4b5b9.webp';
import ipadMini5WiFin1 from './img/com.apple.ipad-mini5-wifi-1.webp';
import ipadMini5WWANn1 from './img/com.apple.ipad-mini5-wwan-1.webp';
import ipadMini6n1 from './img/com.apple.ipad-mini6-1.webp';
import ipadPro9point7WiFixb9b7ba from './img/com.apple.ipad-pro-9point7-wifi-b9b7ba.webp';
import ipadPro9point7WWANxb9b7ba from './img/com.apple.ipad-pro-9point7-wwan-b9b7ba.webp';
import ipadPro10point5WiFin1 from './img/com.apple.ipad-pro-10point5-wifi-1.webp';
import ipadPro10point5WWANn1 from './img/com.apple.ipad-pro-10point5-wwan-1.webp';
import ipadPro11n1 from './img/com.apple.ipad-pro-11-1.webp';
import iPadPro114thn1 from './img/com.apple.ipad-pro-11-4th-1.webp';
import ipadPro12point9n1 from './img/com.apple.ipad-pro-12point9-1.webp';
import iPadPro12point96thn1 from './img/com.apple.ipad-pro-12point9-6th-1.webp';
import ipadProm41 from './img/com.apple.ipad-pro-m4-2.webp';
import ipadProWiFixb4b5b9 from './img/com.apple.ipad-pro-wifi-b4b5b9.webp';
import ipadProWWANxb4b5b9 from './img/com.apple.ipad-pro-wwan-b4b5b9.webp';

// iPad
import ipad from './img/com.apple.ipad.webp';
import iphone3G from './img/com.apple.iphone-3g.webp';
import iphone4Black from './img/com.apple.iphone-4-black.webp';
import iphone4CDMABlack from './img/com.apple.iphone-4-cdma-black.webp';
import iphone5Black from './img/com.apple.iphone-5-black.webp';
import iphone5Cxf5f4f7 from './img/com.apple.iphone-5c-f5f4f7.webp';
import iphone5Sx99989b from './img/com.apple.iphone-5s-99989b.webp';
import iphone6xb4b5b9 from './img/com.apple.iphone-6-b4b5b9.webp';
import iphone6Plusxb4b5b9 from './img/com.apple.iphone-6-plus-b4b5b9.webp';
import iphone6Sxb9b7ba from './img/com.apple.iphone-6s-b9b7ba.webp';
import iphone6SPlusxb9b7ba from './img/com.apple.iphone-6s-plus-b9b7ba.webp';
import iphone7n1 from './img/com.apple.iphone-7-1.webp';
import iphone7Plusn1 from './img/com.apple.iphone-7-plus-1.webp';
import iphone11n1 from './img/com.apple.iphone-11-1.webp';
import iphone11Pron1 from './img/com.apple.iphone-11-pro-1.webp';
import iphone11ProMaxn1 from './img/com.apple.iphone-11-pro-max-1.webp';
import iphone12n1 from './img/com.apple.iphone-12-1.webp';
import iphone12Minin1 from './img/com.apple.iphone-12-mini-1.webp';
import iphone12Pron1 from './img/com.apple.iphone-12-pro-1.webp';
import iphone12ProMaxn1 from './img/com.apple.iphone-12-pro-max-1.webp';
import iphone13n1 from './img/com.apple.iphone-13-1.webp';
import iphone13Minin1 from './img/com.apple.iphone-13-mini-1.webp';
import iphone13Pron1 from './img/com.apple.iphone-13-pro-1.webp';
import iphone13ProMaxn1 from './img/com.apple.iphone-13-pro-max-1.webp';
import iphone14n1 from './img/com.apple.iphone-14-1.webp';
import iphone14Pron1 from './img/com.apple.iphone-14-pro-1.webp';
import iphone15n1 from './img/com.apple.iphone-15-1.webp';
import iphone15Pron1 from './img/com.apple.iphone-15-pro-1.webp';
import iphone16n1 from './img/com.apple.iphone-16-1.webp';
import iphone16Pron1 from './img/com.apple.iphone-16-pro-1.webp';
import iphoneSExaeb1b8 from './img/com.apple.iphone-se-aeb1b8.webp';
import iphoneSE3n1 from './img/com.apple.iphone-se3-1.webp';
import iphoneXn1 from './img/com.apple.iphone-x-1.webp';
import iphoneXRn1 from './img/com.apple.iphone-xr-1.webp';
import iphoneXSn1 from './img/com.apple.iphone-xs-1.webp';
import iphoneXSMaxn1 from './img/com.apple.iphone-xs-max-1.webp';

// iPhone
import iphone from './img/com.apple.iphone.webp';
import ipodTouch2 from './img/com.apple.ipod-touch-2.webp';
import ipodTouch4Black from './img/com.apple.ipod-touch-4-black.webp';
import ipodTouch5Slate from './img/com.apple.ipod-touch-5-slate.webp';
import ipodTouch6x6b6a6d from './img/com.apple.ipod-touch-6-6b6a6d.webp';
import ipodTouch7n5 from './img/com.apple.ipod-touch-7-5.webp';

// iPod
import ipodTouch from './img/com.apple.ipod-touch.webp';
import macbookRetinaSilver from './img/com.apple.macbook-retina-silver.webp';
import macbookUnibodyPlastic from './img/com.apple.macbook-unibody-plastic.webp';
import macbookUnibody from './img/com.apple.macbook-unibody.webp';

// MacBook
import macbookWhite from './img/com.apple.macbook-white.webp';
import macbookAir11Unibody from './img/com.apple.macbookair-11-unibody.webp';
import macbookAir132022Silver from './img/com.apple.macbookair-13-2022-silver.webp';
import macbookAir13Unibody from './img/com.apple.macbookair-13-unibody.webp';
import macbookAir152023Silver from './img/com.apple.macbookair-15-2023-silver.webp';
import macbookAir2018Silver from './img/com.apple.macbookair-2018-silver.webp';

// MacBook Air
import macbookAir from './img/com.apple.macbookair.webp';
import macbookPro13RetinaDisplay from './img/com.apple.macbookpro-13-retina-display.webp';
import macbookPro13RetinaTouchIDSilver from './img/com.apple.macbookpro-13-retina-touchid-silver.webp';
import macbookPro13RetinaUSBCSilver from './img/com.apple.macbookpro-13-retina-usbc-silver.webp';
import macbookPro13Unibody from './img/com.apple.macbookpro-13-unibody.webp';
import macbookPro14x2021Silver from './img/com.apple.macbookpro-14-2021-silver.webp';
import macbookPro15RetinaDisplay from './img/com.apple.macbookpro-15-retina-display.webp';
import macbookPro15RetinaTouchIDSilver from './img/com.apple.macbookpro-15-retina-touchid-silver.webp';
import macbookPro15Unibody from './img/com.apple.macbookpro-15-unibody.webp';

// MacBook Pro
import macbookPro15 from './img/com.apple.macbookpro-15.webp';
import macbookPro16x2021Silver from './img/com.apple.macbookpro-16-2021-silver.webp';
import macbookPro16Silver from './img/com.apple.macbookpro-16-silver.webp';
import macbookPro17Unibody from './img/com.apple.macbookpro-17-unibody.webp';
import macbookPro17 from './img/com.apple.macbookpro-17.webp';
import macMini2018 from './img/com.apple.macmini-2018.webp';
import macMini2020 from './img/com.apple.macmini-2020.webp';
import macMini2024 from './img/com.apple.macmini-2024.webp';
import macMiniUnibodyNoOptical from './img/com.apple.macmini-unibody-no-optical.webp';
import macMiniUnibody from './img/com.apple.macmini-unibody.webp';

// Mac Mini
import macMini from './img/com.apple.macmini.webp';
import macPro2019 from './img/com.apple.macpro-2019.webp';
import macProCylinder from './img/com.apple.macpro-cylinder.webp';

// Mac Pro
import macPro from './img/com.apple.macpro.webp';

// Mac studio
import macStudio from './img/com.apple.macstudio.webp';
import powermacG4Quicksilver from './img/com.apple.powermac-g4-quicksilver.webp';

// Apple Vision Pro
import appleVisionPro from './img/com.apple.vision-pro.webp';
import xserveNew from './img/com.apple.xserve.webp';

import unknownAppleTV from './img/UnknownAppleTV.webp';
import unknowniPad from './img/UnknowniPad.webp';
import unknowniPhone from './img/UnknowniPhone.webp';
// ADP
import miniADP from './img/macmini-2018.webp';
import unknown from './img/unknown.webp';

// Virtual Machine
import vmParallels from './img/vm-parallels.webp';
import vmVirtualBox from './img/vm-virtual-box.webp';
import vmVMwareFusion from './img/vm-vmware-fusion.webp';

// Server
import serverXserve from './img/xserve.webp';

export default {
  // Unknown
  unknown,
  unknownAppleTV,
  unknowniPad,
  unknowniPhone,

  // Virtual Machine
  vmParallels,
  vmVirtualBox,
  vmVMwareFusion,

  // Server
  xserveNew,

  // ADP
  miniADP,

  // Apple TV
  'AppleTV1,1': appleTV,
  'AppleTV11,1': appleTV4,
  'AppleTV2,1': appleTV3,
  'AppleTV3,1': appleTV3,
  'AppleTV3,2': appleTV3,
  'AppleTV5,3': appleTV4,
  'AppleTV6,2': appleTV4,
  'AppleTV14,1': appleTV4k3rd,

  // MacBook
  'MacBook1,1': macbookWhite,
  'MacBook10,1': macbookRetinaSilver,
  'MacBook2,1': macbookWhite,
  'MacBook3,1': macbookWhite,
  'MacBook4,1': macbookWhite,
  'MacBook5,1': macbookUnibody,
  'MacBook5,2': macbookWhite,
  'MacBook6,1': macbookUnibodyPlastic,
  'MacBook7,1': macbookUnibodyPlastic,
  'MacBook8,1': macbookRetinaSilver,
  'MacBook9,1': macbookRetinaSilver,

  // MacBook Air
  'MacBookAir1,1': macbookAir,
  'MacBookAir10,1': macbookAir2018Silver,
  'MacBookAir2,1': macbookAir,
  'MacBookAir3,1': macbookAir11Unibody,
  'MacBookAir3,2': macbookAir13Unibody,
  'MacBookAir4,1': macbookAir11Unibody,
  'MacBookAir4,2': macbookAir13Unibody,
  'MacBookAir5,1': macbookAir11Unibody,
  'MacBookAir5,2': macbookAir13Unibody,
  'MacBookAir6,1': macbookAir11Unibody,
  'MacBookAir6,2': macbookAir13Unibody,
  'MacBookAir7,1': macbookAir11Unibody,
  'MacBookAir7,2': macbookAir13Unibody,
  'MacBookAir8,1': macbookAir2018Silver,
  'MacBookAir8,2': macbookAir2018Silver,
  'MacBookAir9,1': macbookAir2018Silver,
  'Mac14,2': macbookAir132022Silver,
  'Mac14,15': macbookAir152023Silver,
  'Mac15,12': macbookAir132022Silver,
  'Mac15,13': macbookAir152023Silver,

  // MacBook Pro
  'MacBookPro1,1': macbookPro15,
  'MacBookPro1,2': macbookPro17,
  'MacBookPro10,1': macbookPro15RetinaDisplay,
  'MacBookPro10,2': macbookPro13RetinaDisplay,
  'MacBookPro11,1': macbookPro13RetinaDisplay,
  'MacBookPro11,2': macbookPro15RetinaDisplay,
  'MacBookPro11,3': macbookPro15RetinaDisplay,
  'MacBookPro11,4': macbookPro15RetinaDisplay,
  'MacBookPro11,5': macbookPro15RetinaDisplay,
  'MacBookPro12,1': macbookPro13RetinaDisplay,
  'MacBookPro13,1': macbookPro13RetinaUSBCSilver,
  'MacBookPro13,2': macbookPro13RetinaTouchIDSilver,
  'MacBookPro13,3': macbookPro15RetinaTouchIDSilver,
  'MacBookPro14,1': macbookPro13RetinaUSBCSilver,
  'MacBookPro14,2': macbookPro13RetinaTouchIDSilver,
  'MacBookPro14,3': macbookPro15RetinaTouchIDSilver,
  'Mac14,3': macMini2020,
  'Mac14,5': macbookPro14x2021Silver,
  'Mac14,6': macbookPro16x2021Silver,
  'Mac14,7': macbookPro13RetinaTouchIDSilver,
  'Mac14,9': macbookPro14x2021Silver,
  'Mac14,10': macbookPro16x2021Silver,
  'Mac15,3': macbookPro14x2021Silver,
  'Mac15,6': macbookPro14x2021Silver,
  'Mac15,7': macbookPro16x2021Silver,
  'Mac15,8': macbookPro14x2021Silver,
  'Mac15,9': macbookPro16x2021Silver,
  'Mac15,10': macbookPro14x2021Silver,
  'Mac15,11': macbookPro16x2021Silver,
  'MacBookPro15,1': macbookPro15RetinaTouchIDSilver,
  'MacBookPro15,2': macbookPro13RetinaTouchIDSilver,
  'MacBookPro15,3': macbookPro15RetinaTouchIDSilver,
  'MacBookPro15,4': macbookPro13RetinaUSBCSilver,
  'MacBookPro16,1': macbookPro16Silver,
  'MacBookPro16,2': macbookPro13RetinaTouchIDSilver,
  'MacBookPro16,3': macbookPro13RetinaTouchIDSilver,
  'MacBookPro16,4': macbookPro16Silver,
  'MacBookPro17,1': macbookPro13RetinaTouchIDSilver,
  'MacBookPro18,1': macbookPro16x2021Silver,
  'MacBookPro18,2': macbookPro16x2021Silver,
  'MacBookPro18,3': macbookPro14x2021Silver,
  'MacBookPro18,4': macbookPro14x2021Silver,
  'MacBookPro2,1': macbookPro17,
  'MacBookPro2,2': macbookPro15,
  'MacBookPro3,1': macbookPro15,
  'MacBookPro4,1': macbookPro15,
  'MacBookPro5,1': macbookPro15Unibody,
  'MacBookPro5,2': macbookPro17Unibody,
  'MacBookPro5,3': macbookPro15Unibody,
  'MacBookPro5,4': macbookPro15Unibody,
  'MacBookPro5,5': macbookPro13Unibody,
  'MacBookPro6,1': macbookPro17Unibody,
  'MacBookPro6,2': macbookPro15Unibody,
  'MacBookPro7,1': macbookPro13Unibody,
  'MacBookPro8,1': macbookPro13Unibody,
  'MacBookPro8,2': macbookPro15Unibody,
  'MacBookPro8,3': macbookPro17Unibody,
  'MacBookPro9,1': macbookPro15Unibody,
  'MacBookPro9,2': macbookPro13Unibody,

  // Mac Pro
  'MacPro1,1': macPro,
  'MacPro2,1': macPro,
  'MacPro3,1': macPro,
  'MacPro4,1': macPro,
  'MacPro5,1': macPro,
  'MacPro6,1': macProCylinder,
  'MacPro7,1': macPro2019,
  'MacPro14,8': macPro2019,

  // Mac Studio
  'Mac13,2': macStudio,
  'Mac13,1': macStudio,
  'Mac14,13': macStudio,
  'Mac14,14': macStudio,

  // Mac Mini
  Macmini: macMini2018,
  'Macmini1,1': macMini,
  'Macmini2,1': macMini,
  'Macmini3,1': macMini,
  'Macmini4,1': macMiniUnibody,
  Macmini5: macMiniUnibodyNoOptical,
  'Macmini5,1': macMiniUnibodyNoOptical,
  'Macmini5,2': macMiniUnibodyNoOptical,
  'Macmini5,3': macMiniUnibodyNoOptical,
  Macmini6: macMiniUnibodyNoOptical,
  'Macmini6,1': macMiniUnibodyNoOptical,
  'Macmini6,2': macMiniUnibodyNoOptical,
  Macmini7: macMiniUnibodyNoOptical,
  'Macmini7,1': macMiniUnibodyNoOptical,
  'Macmini8,1': macMini2018,
  'Macmini9,1': macMini2020,
  'Mac14,12': macMini2020,

  // Power Mac
  'ADP2,1': macPro,
  'ADP3,2': macMini2018,
  'PowerMac10,1': macMini,
  'PowerMac10,2': macMini,
  'PowerMac11,2': macPro,
  'PowerMac11,2,Quad': macPro,
  'PowerMac12,1': imaciSight17,
  'PowerMac3,5': powermacG4Quicksilver,
  'PowerMac3,6': powermacG4Quicksilver,
  'PowerMac4,2': imacG415,
  'PowerMac4,4': emac,
  'PowerMac4,5': imacG417,
  'PowerMac6,1': imacG417,
  'PowerMac6,3': imacG420,
  'PowerMac6,4': emac,
  'PowerMac7,2': macPro,
  'PowerMac7,3': macPro,
  'PowerMac8,1': imacG517,
  'PowerMac8,2': imacG517,
  'PowerMac9,1': macPro,

  // iMac
  'iMac10,1': imacUnibody27,
  'iMac11,1': imacUnibody27,
  'iMac11,2': imacUnibody21,
  'iMac11,3': imacUnibody27,
  'iMac12,1': imacUnibody21,
  'iMac12,2': imacUnibody27,
  'iMac13,1': imacUnibody21NoOptical,
  'iMac13,2': imacUnibody27NoOptical,
  'iMac13,3': imacUnibody21NoOptical,
  'iMac14,1': imacUnibody27NoOptical,
  'iMac14,2': imacUnibody27NoOptical,
  'iMac14,3': imacUnibody21NoOptical,
  'iMac14,4': imacUnibody21NoOptical,
  'iMac15,1': imacUnibody27NoOptical,
  'Mac15,4': imac2021Silver,
  'Mac15,5': imac2021Silver,
  'iMac16,1': imacUnibody21NoOptical,
  'iMac16,2': imacUnibody21NoOptical,
  'iMac17,1': imacUnibody27NoOptical,
  'iMac18,1': imacUnibody21NoOptical,
  'iMac18,2': imacUnibody21NoOptical,
  'iMac18,3': imacUnibody27NoOptical,
  'iMac19,1': imacUnibody27NoOptical,
  'iMac19,2': imacUnibody21NoOptical,
  'iMac20,1': imacUnibody27NoOptical,
  'iMac20,2': imacUnibody27NoOptical,
  'iMac21,1': imac2021Silver,
  'iMac21,2': imac2021Silver,
  'iMac4,1': imaciSight24,
  'iMac4,2': imaciSight24,
  'iMac5,1': imaciSight24,
  'iMac5,2': imaciSight24,
  'iMac6,1': imaciSight24,
  'iMac7,1': imacAluminum20,
  'iMac8,1': imacAluminum20,
  'iMac9,1': imacAluminum24,

  // iMac Pro
  'iMacPro1,1': imacPro2017,

  // iPad
  'iPad1,1': ipad,
  'iPad11,1': ipadMini5WiFin1,
  'iPad11,2': ipadMini5WWANn1,
  'iPad11,3': ipadAir3WiFin1,
  'iPad11,4': ipadAir3WWANn1,
  'iPad11,6': ipad8WiFin1,
  'iPad11,7': ipad8WWANn1,
  'iPad12,1': ipad9WiFin1,
  'iPad12,2': ipad9WWANn1,
  'iPad13,1': ipadAir4n1,
  'iPad13,10': ipadPro12point9n1,
  'iPad13,11': ipadPro12point9n1,
  'iPad13,2': ipadAir4n1,
  'iPad13,4': ipadPro11n1,
  'iPad13,5': ipadPro11n1,
  'iPad13,6': ipadPro11n1,
  'iPad13,7': ipadPro11n1,
  'iPad13,8': ipadPro12point9n1,
  'iPad13,9': ipadPro12point9n1,
  'iPad13,16': ipadAir5n1,
  'iPad13,17': ipadAir5n1,
  'iPad14,1': ipadMini6n1,
  'iPad14,2': ipadMini6n1,
  'iPad2,1': ipad2Black,
  'iPad2,2': ipad2Black,
  'iPad2,3': ipad2Black,
  'iPad2,4': ipad2Black,
  'iPad2,5': ipadMiniBlackWiFi,
  'iPad2,6': ipadMiniBlack3G,
  'iPad2,7': ipadMiniBlack3G,
  'iPad3,1': ipad2Black,
  'iPad3,2': ipad2Black,
  'iPad3,3': ipad2Black,
  'iPad3,4': ipad2Black,
  'iPad3,5': ipad2Black,
  'iPad3,6': ipad2Black,
  'iPad4,1': ipadAirx99989b,
  'iPad4,2': ipadAirx99989b,
  'iPad4,3': ipadAirx99989b,
  'iPad4,4': ipadMini2x99989b,
  'iPad4,5': ipadMini2x99989b,
  'iPad4,6': ipadMini2x99989b,
  'iPad4,7': ipadMini3WiFixb4b5b9,
  'iPad4,8': ipadMini3WWANxb4b5b9,
  'iPad4,9': ipadMini3WWANxb4b5b9,
  'iPad5,1': ipadMini4WiFixb4b5b9,
  'iPad5,2': ipadMini4WWANxb4b5b9,
  'iPad5,3': ipadAir2WiFixb4b5b9,
  'iPad5,4': ipadAir2WWANxb4b5b9,
  'iPad6,11': ipadAir2WiFixb4b5b9,
  'iPad6,12': ipadAir2WWANxb4b5b9,
  'iPad6,3': ipadPro9point7WiFixb9b7ba,
  'iPad6,4': ipadPro9point7WWANxb9b7ba,
  'iPad6,7': ipadProWiFixb4b5b9,
  'iPad6,8': ipadProWWANxb4b5b9,
  'iPad7,1': ipadProWiFixb4b5b9,
  'iPad7,11': ipad7WiFin1,
  'iPad7,12': ipad7WWANn1,
  'iPad7,2': ipadProWWANxb4b5b9,
  'iPad7,3': ipadPro10point5WiFin1,
  'iPad7,4': ipadPro10point5WWANn1,
  'iPad7,5': ipad6WiFin1,
  'iPad7,6': ipad6WWANn1,
  'iPad8,1': ipadPro11n1,
  'iPad8,10': ipadPro11n1,
  'iPad8,11': ipadPro12point9n1,
  'iPad8,12': ipadPro12point9n1,
  'iPad8,2': ipadPro11n1,
  'iPad8,3': ipadPro11n1,
  'iPad8,4': ipadPro11n1,
  'iPad8,5': ipadPro12point9n1,
  'iPad8,6': ipadPro12point9n1,
  'iPad8,7': ipadPro12point9n1,
  'iPad8,8': ipadPro12point9n1,
  'iPad8,9': ipadPro11n1,
  'iPad13,18': iPad10n1,
  'iPad13,19': iPad10n1,
  'iPad14,3': iPadPro114thn1,
  'iPad14,4': iPadPro114thn1,
  'iPad14,5': iPadPro12point96thn1,
  'iPad14,6': iPadPro12point96thn1,
  'iPad14,8': ipadAirm21,
  'iPad14,9': ipadAirm21,
  'iPad14,10': ipadAirm21,
  'iPad14,11': ipadAirm21,
  'iPad16,3': ipadProm41,
  'iPad16,4': ipadProm41,
  'iPad16,5': ipadProm41,
  'iPad16,6': ipadProm41,

  // iPhone
  'iPhone1,1': iphone,
  'iPhone1,2': iphone3G,
  'iPhone10,1': iphone7n1,
  'iPhone10,2': iphone7Plusn1,
  'iPhone10,3': iphoneXn1,
  'iPhone10,4': iphone7n1,
  'iPhone10,5': iphone7Plusn1,
  'iPhone10,6': iphoneXn1,
  'iPhone11,2': iphoneXSn1,
  'iPhone11,4': iphoneXSMaxn1,
  'iPhone11,6': iphoneXSMaxn1,
  'iPhone11,8': iphoneXRn1,
  'iPhone12,1': iphone11n1,
  'iPhone12,3': iphone11Pron1,
  'iPhone12,5': iphone11ProMaxn1,
  'iPhone12,8': iphone7n1,
  'iPhone13,1': iphone12Minin1,
  'iPhone13,2': iphone12n1,
  'iPhone13,3': iphone12Pron1,
  'iPhone13,4': iphone12ProMaxn1,
  'iPhone14,2': iphone13Pron1,
  'iPhone14,3': iphone13ProMaxn1,
  'iPhone14,4': iphone13Minin1,
  'iPhone14,5': iphone13n1,
  'iPhone14,6': iphoneSE3n1,
  'iPhone14,7': iphone14n1,
  'iPhone14,8': iphone14n1,
  'iPhone15,2': iphone14Pron1,
  'iPhone15,3': iphone14Pron1,
  'iPhone15,4': iphone15n1,
  'iPhone15,5': iphone15n1,
  'iPhone16,1': iphone15Pron1,
  'iPhone16,2': iphone15Pron1,
  'iPhone2,1': iphone3G,
  'iPhone3,1': iphone4Black,
  'iPhone3,2': iphone4Black,
  'iPhone3,3': iphone4CDMABlack,
  'iPhone4,1': iphone4CDMABlack,
  'iPhone5,1': iphone5Black,
  'iPhone5,2': iphone5Black,
  'iPhone5,3': iphone5Cxf5f4f7,
  'iPhone5,4': iphone5Cxf5f4f7,
  'iPhone6,1': iphone5Sx99989b,
  'iPhone6,2': iphone5Sx99989b,
  'iPhone7,1': iphone6Plusxb4b5b9,
  'iPhone7,2': iphone6xb4b5b9,
  'iPhone8,1': iphone6Sxb9b7ba,
  'iPhone8,2': iphone6SPlusxb9b7ba,
  'iPhone8,4': iphoneSExaeb1b8,
  'iPhone9,1': iphone7n1,
  'iPhone9,2': iphone7Plusn1,
  'iPhone9,3': iphone7n1,
  'iPhone9,4': iphone7Plusn1,

  // iPod
  'iPod1,1': ipodTouch,
  'iPod2,1': ipodTouch2,
  'iPod3,1': ipodTouch2,
  'iPod4,1': ipodTouch4Black,
  'iPod5,1': ipodTouch5Slate,
  'iPod7,1': ipodTouch6x6b6a6d,
  'iPod9,1': ipodTouch7n5,

  // RackMac
  RackMac: xserveNew,
  'RackMac1,1': xserveNew,
  'RackMac1,2': xserveNew,
  'RackMac3,1': xserveNew,
  Xserve: xserveNew,
  'Xserve1,1': serverXserve,
  'Xserve2,1': serverXserve,
  'Xserve3,1': serverXserve,

  // Apple Vision Pro
  'RealityDevice14,1': appleVisionPro,

  // Oct 2024
  'iPhone17,1': iphone16Pron1,
  'iPhone17,2': iphone16Pron1,
  'iPhone17,3': iphone16n1,
  'iPhone17,4': iphone16n1,
  'iPad16,1': ipadMiniA17n1,
  'iPad16,2': ipadMiniA17n1,

  // Nov 2024
  'Mac16,2': imac2021Silver,
  'Mac16,3': imac2021Silver,
  'Mac16,10': macMini2024,
  'Mac16,15': macMini2024,
  'Mac16,1': macbookPro14x2021Silver,
  'Mac16,6': macbookPro14x2021Silver,
  'Mac16,8': macbookPro14x2021Silver,
  'Mac16,5': macbookPro16x2021Silver,
  'Mac16,7': macbookPro16x2021Silver,
};
