import { func, shape, string } from 'prop-types';
import React from 'react';
import { components } from 'react-select';

import { Icon } from '@kandji-inc/bumblebee';

const DropdownIndicator = (props: any) => {
  const {
    selectProps: { inputValue, clearSearchValue },
  } = props;
  const isSearching = typeof inputValue !== 'string' || inputValue !== '';
  return (
    <components.DropdownIndicator {...props}>
      {isSearching ? (
        <button type="button" onClick={clearSearchValue} className="b-ml2">
          <Icon
            name="circle-xmark"
            className="b-select__icon b-select__icon--search"
          />
        </button>
      ) : (
        <Icon
          name="magnifying-glass"
          className="b-select-search b-select__icon b-select__icon--search"
        />
      )}
    </components.DropdownIndicator>
  );
};

DropdownIndicator.propTypes = {
  selectProps: shape({
    inputValue: string,
    clearSearchValue: func.isRequired,
  }),
};

DropdownIndicator.defaultProps = {
  selectProps: {
    inputValue: '',
  },
};

export default DropdownIndicator;
