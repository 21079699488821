/* istanbul ignore file */
import { Banner } from '@kandji-inc/nectar-ui';
import { setModal } from 'app/_actions/ui';
import { i18n } from 'i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
const NotificationLink = styled.span`
  border-bottom: 1px solid #d1c594;
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid #958964;
  }
`;

const DuplicatesNotification = ({ computer, setModal: callSetModal }) => {
  const mergeDuplicates = (e) => {
    e.preventDefault();
    const duplicates = computer.duplicates.sort(
      (a, b) => b.enrolled_at > a.enrolled_at,
    );

    if (duplicates.length) {
      callSetModal('COMPUTER_MERGE_DUPLICATES', {
        newComputer: computer.computerName,
        oldComputers: duplicates.map((el) => el.name),
        id: duplicates[0].id,
      });
    }
  };

  return (
    <Banner
      theme="warning"
      text={
        <>
          <strong>{i18n.t('Duplicate Device Records found.')}</strong>
          {i18n.ut(
            ' Multiple Device Records have been found with the same <i>Serial Number</i> or <i>Hardware UUID</i>, and may be duplicates. ',
          )}
          <NotificationLink onClick={mergeDuplicates}>
            {i18n.t('Review and reconcile duplicate Device Records')}
          </NotificationLink>
          .
        </>
      }
    />
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setModal,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(DuplicatesNotification);
