import styled from 'styled-components';

export const Status = styled('div')`
  font-size: 10px;
  line-height: 7px;
  display: flex;
  letter-spacing: 0.15em;
  text-transform: uppercase;

  color: #1a1d25;
  i {
    font-size: 6px;
    color: ${(props) => props.iColor};
    margin-right: 5px;
  }
`;

export const StatusLabel = styled('span')`
  line-height: 26px;
  width: 120px;
  display: flex;
  align-items: center;
`;
