import { Flex, Icon, Paragraph } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import type { PrismCategoryUri } from 'src/features/visibility/prism/types/prism.types';
import { StyledLink } from '../../shared';
import {
  SUPPORT_URL,
  getCopyByPrismCategory,
  getIconByDeviceFamily,
  getSupportedDeviceFamiliesByPrismCategory,
} from '../utils/tooltipUtils';
import TableHeaderIconButton from './TableHeaderIconButton';

type FleetTitleTooltipContentProps = {
  prismCategoryUri: PrismCategoryUri;
  onClose: () => void;
};

const FleetTitleTooltipContent = (props: FleetTitleTooltipContentProps) => {
  const { prismCategoryUri, onClose } = props;
  const supportedDeviceFamilies =
    getSupportedDeviceFamiliesByPrismCategory(prismCategoryUri);
  return (
    <Flex flow="column" gap="sm" css={{ fontSize: '$2' }}>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex css={{ color: '$neutral60' }} gap="xs">
          {supportedDeviceFamilies.map((deviceFamily) => (
            <Icon
              key={deviceFamily}
              name={getIconByDeviceFamily(deviceFamily)}
              size="md"
            />
          ))}
        </Flex>
        <TableHeaderIconButton
          icon="fa-xmark-small"
          compact
          onClick={onClose}
        />
      </Flex>
      <Flex flow="column" gap="xs">
        <Paragraph>{getCopyByPrismCategory(prismCategoryUri)}</Paragraph>
        <StyledLink
          href={SUPPORT_URL}
          target="_blank"
          rel="noreferrer noopener"
        >
          {i18n.t('Learn more...')}
        </StyledLink>
      </Flex>
    </Flex>
  );
};

export default FleetTitleTooltipContent;
