import { Setting } from 'features/library-items/template';
import React from 'react';
import { i18n } from 'src/i18n';
import WifiService from '../service/wifi-service';
import AuthenticationTypeRow from './authentication-type-row';
import EapTypesRow from './eap-types-row';
import IdentityCertificateRow from './identity-certificate-row';
import { securityWithLoginWindowConfigurationValues } from './protocols-card.model';

const keys = WifiService.keys.authentication;

const ProtocolsCard = (props) => {
  const { setting } = props;

  const withAllRows = securityWithLoginWindowConfigurationValues.includes(
    setting[keys.securityType],
  );

  const hasAuthentication =
    withAllRows &&
    [
      WifiService.protocols.TTLS,
      WifiService.protocols.LEAP,
      WifiService.protocols.PEAP,
      WifiService.protocols.EAP_FAST,
    ].some((protocol) =>
      setting[keys.acceptedEapTypes.acceptedEapTypes].find(
        ({ value }) => protocol === value,
      ),
    );

  const isAd =
    hasAuthentication &&
    setting[keys.acceptedEapTypes.authentication] ===
      WifiService.authentications.COMPUTER_AD;

  const isOd =
    hasAuthentication &&
    setting[keys.acceptedEapTypes.authentication] ===
      WifiService.authentications.COMPUTER_OD;

  const showIdentityCertificate =
    [
      WifiService.protocols.TLS,
      WifiService.protocols.TTLS,
      WifiService.protocols.PEAP,
      WifiService.protocols.EAP_FAST,
    ].some((eap) =>
      setting[keys.acceptedEapTypes.acceptedEapTypes].find(
        ({ value }) => value === eap,
      ),
    ) &&
    !isAd &&
    !isOd;

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('Authentication')}</h3>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          {i18n.t(
            'Choose the authentication type(s) used to access this network.',
          )}{' '}
          <a
            href="https://support.kandji.io/support/solutions/articles/72000558737"
            rel="noopener noreferrer"
            target="_blank"
            className="b-alink"
          >
            {i18n.t('Learn more...')}
          </a>
        </p>
      </Setting.SubHeader>
      <Setting.Rows>
        <AuthenticationTypeRow {...props} />

        {withAllRows && <EapTypesRow {...props} />}

        {showIdentityCertificate && <IdentityCertificateRow {...props} />}
      </Setting.Rows>
    </Setting.Card>
  );
};

export default React.memo(ProtocolsCard);
