/* istanbul ignore file */
import { newIntegrationsAxios } from 'app/api/base';

class OktaDeviceTrustService {
  constructor() {
    this.resource = 'okta-device-trust/config/';
  }

  async getConfig() {
    return newIntegrationsAxios(this.resource)
      .get()
      .then((r) => r.data);
  }
}

const oktaDeviceTrustService = new OktaDeviceTrustService();

export default OktaDeviceTrustService;
export { oktaDeviceTrustService };
