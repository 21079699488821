import { Icon } from '@kandji-inc/bumblebee';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AwesomeIconCircle from '../../interface/AwesomeIconCircle';
import './AddDevicesSelect.styles.scss';

const List = styled('ul')`
  z-index: 10;
  position: absolute;
  ${(props) => (props.isRightAlign ? 'right: -2px' : 'left: -2px')};
  min-width: ${(props) => (props.minWidth ? props.minWidth : null)};
  width: 100%;
  border: 2px solid #e5e5e5;
  border-top: none;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: white;
  box-shadow: 0 2px 5px -1px rgb(232, 232, 232);
  padding: 15px 0;
  max-height: 270px;
  overflow-x: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #1a1d25;
    border-radius: 4px;
  }
`;

const AddDevicesSelect = (props) => {
  const setDefaults = () => {
    const { selected, title } = props;
    return {
      listOpen: false,
      headerTitle: selected.name || title,
      headerIcon: selected.icon || null,
      headerColor: selected.color || null,
    };
  };
  const [state, setState] = useState(setDefaults());

  const close = () => {
    const { onDropClick } = props;
    onDropClick(true);
    setState((prevState) => ({
      ...prevState,
      listOpen: false,
    }));
  };

  useEffect(() => {
    window.addEventListener('click', close);
    return window.removeEventListener('click', close);
  });

  const selectItem = (item, title, icon, color, id) => {
    const { resetThenSet } = props;
    setState((prevState) => ({
      ...prevState,
      headerTitle: title,
      headerIcon: icon,
      headerColor: color,
      listOpen: false,
    }));
    resetThenSet(item);
  };

  const toggleList = () => {
    const { onDropClick } = props;
    const { listOpen } = state;
    onDropClick(listOpen);
    setState((prevState) => ({
      ...prevState,
      listOpen: !prevState.listOpen,
    }));
  };

  const {
    list,
    selected,
    width,
    verticalCentered,
    verticalCenteredIcon,
    isRightAlign,
    minWidth,
  } = props;
  const { listOpen, headerTitle, headerIcon, headerColor } = state;
  return (
    <div className="dd-wrapper" style={{ width }}>
      <div className="dd-header" onClick={() => toggleList()}>
        <div
          className={classNames('dd-header-title', {
            'text-gray': !headerIcon,
          })}
        >
          {headerIcon && (
            <AwesomeIconCircle
              icon={`ss-icon ${headerIcon}`}
              color={headerColor}
              margin={verticalCenteredIcon ? '3px' : null}
              size="25px"
              border=""
            />
          )}
          <div
            style={verticalCentered ? { marginTop: '4px' } : null}
            className={classNames('dd-list-item-text', {
              'text-margin': headerIcon,
              'centered-text': headerIcon,
            })}
          >
            {headerTitle}
          </div>
        </div>
        {listOpen ? (
          <i className="fas fa-sort-up dd-icon" style={{ marginTop: '4px' }} />
        ) : (
          <i
            className="fas fa-sort-down dd-icon"
            style={{ marginBottom: '4px' }}
          />
        )}
      </div>
      {state.listOpen && (
        <List
          minWidth={minWidth}
          isRightAlign={isRightAlign}
          onClick={(e) => e.stopPropagation()}
        >
          {list.map((item) => (
            <li
              style={{ display: 'flex' }}
              className="dd-list-item"
              key={item.id}
              onClick={() =>
                selectItem(item, item.name, item.icon, item.color, item.id)
              }
            >
              <AwesomeIconCircle
                icon={`ss-icon ${item.icon}`}
                color={item.color}
                margin={verticalCenteredIcon ? '3px' : null}
                size="25px"
                border=""
              />
              <div
                className={classNames('dd-list-item-text', {
                  'text-margin': item.icon,
                  'centered-text': item.icon,
                })}
              >
                {item.name}
                {item.id === selected.id && (
                  <Icon name="check" className="dd-list-item-check-icon" />
                )}
              </div>
            </li>
          ))}
        </List>
      )}
    </div>
  );
};

AddDevicesSelect.propTypes = {
  list: PropTypes.arrayOf(PropTypes.any).isRequired,
  selected: PropTypes.objectOf(PropTypes.any),
  onDropClick: PropTypes.func,
  width: PropTypes.string,
  minWidth: PropTypes.string,
  title: PropTypes.string,
  verticalCentered: PropTypes.bool,
  verticalCenteredIcon: PropTypes.bool,
  isRightAlign: PropTypes.bool,
};

AddDevicesSelect.defaultProps = {
  onDropClick: () => {},
  selected: {},
  title: '',
  width: '',
  minWidth: '',
  verticalCentered: false,
  verticalCenteredIcon: false,
  isRightAlign: false,
};

export default AddDevicesSelect;
