/* istanbul ignore file */
import { Banner, Flex, Icon, Text, styled } from '@kandji-inc/nectar-ui';
import { devices } from 'features/library-items/data-service/library-item/devices';
import { i18n } from 'src/i18n';
import { getPatternDefaults } from './common';

const PreviewBox = styled(Flex, {
  padding: '24px',
  borderRadius: '4px',
  backgroundColor: 'rgba(26, 29, 37, 0.02)',
});

const deviceToIcon: Record<string, any> = {
  [devices.MAC]: 'sf-desktop-computer',
  [devices.IPHONE]: 'sf-iphone',
  [devices.IPAD]: 'sf-ipad-landscape',
  [devices.TV]: 'sf-tv',
};

const VariablePreview = (props: any) => {
  const { kind, selected, title, onDevices, previewLabel } = props;

  const hasMacOnlyPattern =
    kind === 'device' &&
    selected.some((v) => !v.isCustom && v.deviceVariable?.isMacOnly);
  const isInstallOnMacOnly = onDevices.every(
    ({ device, disabled }) =>
      (device === devices.MAC && !disabled) ||
      (device !== devices.MAC && disabled),
  );
  const patternDefaultToUse = getPatternDefaults(kind);

  return (
    <PreviewBox flow="column" gap="lg">
      <Flex alignItems="center" justifyContent="space-between">
        <Text css={{ fontWeight: 500 }}>
          {i18n.t('{title} Preview', { title })}
        </Text>
        <Flex gap="sm" css={{ '& > svg': { width: '20px', height: '20px' } }}>
          {onDevices.map(({ device, disabled }) => {
            if (!hasMacOnlyPattern || device === devices.MAC) {
              return (
                <Icon
                  key={device}
                  name={deviceToIcon[device]}
                  color={disabled && '#A1ADC4'}
                />
              );
            }

            return null;
          })}
        </Flex>
      </Flex>
      <Flex
        justifyContent="space-between"
        gap="sm"
        css={{
          backgroundColor: 'rgba(26, 29, 37, 0.02)',
          border: '1px solid rgba(26, 29, 37, 0.05)',
          borderRadius: '4px',
          padding: '8px',
        }}
      >
        <Text>{previewLabel || i18n.t('Name')}</Text>
        {selected?.length > 0 && (
          <Flex css={{ gridColumn: '2 / -1' }} wrap="wrap" justifyContent="end">
            {selected.map((v) => {
              const defaultValue =
                patternDefaultToUse.find((pd) => pd.key === v.value)?.value ||
                v.value;
              return (
                <Text key={v.id} css={{ whiteSpace: 'pre' }}>
                  {defaultValue}
                </Text>
              );
            })}
          </Flex>
        )}
      </Flex>
      {hasMacOnlyPattern && !isInstallOnMacOnly && (
        <Banner
          css={{ alignItems: 'center' }}
          text={
            <Flex alignItems="center" gap="xs" css={{ color: '$neutral80' }}>
              <Text css={{ fontWeight: 500 }}>Important:</Text>
              <Text>
                This pattern contains a macOS only variable. Be sure to set this
                Library Item's Install On setting to be only for Mac computers.
              </Text>
            </Flex>
          }
        />
      )}
    </PreviewBox>
  );
};

export default VariablePreview;
