import React from 'react';

import { Badge } from '@kandji-inc/nectar-ui';

import { mapStatusToAttributes } from './helpers';

type StatusBadgeProps = {
  status: string;
};

const StatusBadge = (props: StatusBadgeProps) => {
  const { status } = props;

  const { label, color } = mapStatusToAttributes(status);

  return <Badge color={color}>{label}</Badge>;
};

export default StatusBadge;
