import { Checkbox, Radio } from '@kandji-inc/bumblebee';
/* istanbul ignore file */
import React from 'react';

import { Setting } from 'features/library-items/template';
import featureFlags from 'src/config/feature-flags';
import { i18n } from 'src/i18n';
import SkipScreens, { useSkipScreensValidation } from '../skip-screens';

const SkipScreensRow = ({ ipad, update, isDisabled, setting, isSaving }) => {
  const panelConfig = {
    title: i18n.t('Specify Setup Assistant screens for iPad'),
    helper: i18n.t(
      'Select the checkbox for a Setup Assistant screen to skip it.',
    ),
    selectAllLabel: i18n.t('Skip all screens for iPad'),
  };

  const skipScreensValidationSettings = {
    isEnabled: setting.isEnabled,
    canSkip: setting.canSkip,
    isSkipAll: setting.isSkipAll,
    screens: setting.screens,
    isSaving,
  };

  const { skipScreensRef, isSkipScreenInvalid } = useSkipScreensValidation(
    skipScreensValidationSettings,
    update,
  );

  return (
    <Setting.Row>
      <Setting.Controls>
        <Checkbox
          defaultChecked
          checked={ipad.canSkip}
          label={i18n.t('Skip screens during Setup Assistant for iPad devices')}
          onChange={() => update('canSkip', (p) => !p)}
          isDisabled={isDisabled}
        />
      </Setting.Controls>
      <Setting.Helpers>
        <p className="b-txt-light">
          {i18n.t(
            'Determine which screens are displayed during Setup Assistant.',
          )}
        </p>
      </Setting.Helpers>
      {ipad.canSkip && (
        <Setting.SecondaryControls>
          <div className="b-library-form">
            <div ref={skipScreensRef}>
              <SkipScreens
                panelConfig={panelConfig}
                screens={ipad.screens}
                setScreens={(screens) => update('screens', screens)}
                disabled={isDisabled}
                hasError={isSkipScreenInvalid}
              />
            </div>
          </div>
        </Setting.SecondaryControls>
      )}
    </Setting.Row>
  );
};

export default SkipScreensRow;
