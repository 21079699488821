import { getDepIntegration } from 'app/components/integrations/Apple/api';
import { useLibraryService } from '../../../data-service/library-item/use-library-service';
import { depProfileService } from './dep-profile-service';
import { transformFromApi } from './transformers';

const useDepProfileService = (id) =>
  useLibraryService(id, transformFromApi, [], depProfileService);

const getDepIntegrationInfo = async () => getDepIntegration();

export { useDepProfileService, getDepIntegrationInfo };
