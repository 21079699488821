/* istanbul ignore file - legacy code, moved */
import React from 'react';
import { IPADataProvider } from './IPADataContext';
import { IPALibraryProvider } from './IPALibraryContext';
import { IPAPage } from './IPAPage';

export const IPALibraryItem = () => (
  <IPALibraryProvider>
    <IPADataProvider>
      <IPAPage />
    </IPADataProvider>
  </IPALibraryProvider>
);
