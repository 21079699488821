import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled('div')`
  display: grid;
  grid-template-areas:
    'checkbox text'
    'checkbox text';
  grid-template-columns: 35px auto;
  grid-template-rows: 35px auto;
  margin-top: 28px;
`;

const Label = styled('div')`
  margin-top: 0.2em;
  grid-area: text;
  font-weight: 400;
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 17px;
`;

const SubLabel = styled('div')`
  grid-area: text;
  grid-row-start: 2;
  white-space: normal;
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 17px;
  color: #76777c;
`;

const CheckBox = styled('input')`
  grid-area: checkbox;
  cursor: pointer;
  position: relative;
  width: 21px;
  height: 21px;
  appearance: none;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  border-radius: 1px;
  outline: none;
  transition:
    background 0.3s,
    border 0.3s;
  opacity: ${(props) => (props.isDependenceDisable ? '.5' : '1')};
  &:disabled {
    cursor: default;
  }
  &:checked {
    background: #1a1d25;
    border: 2px solid #1a1d25;
    &:after {
      content: '\f00c';
      font-family: 'Font Awesome\ 5 Pro', sans-serif;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 11px;
    }
  }
`;

const IntegrationCheckbox = ({
  value,
  label,
  description,
  onChange,
  disabled,
}) => (
  <Wrapper>
    <CheckBox
      type="checkbox"
      checked={value}
      disabled={disabled}
      onChange={onChange}
      value={value}
    />
    <Label>{label}</Label>
    <SubLabel>{description}</SubLabel>
  </Wrapper>
);

IntegrationCheckbox.propTypes = {
  value: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

IntegrationCheckbox.defaultProps = {
  description: '',
  disabled: false,
};

export default IntegrationCheckbox;
