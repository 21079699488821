import { Flex, Heading, Text } from '@kandji-inc/nectar-ui';
import * as React from 'react';
import { zUtils } from '../../utils/columnEditorUtils';
import type { EmptyColumnEditorStateProps } from '../PrismColumnEditor';

export const EmptyColumnEditorState = ({
  emptyKind,
}: EmptyColumnEditorStateProps) => {
  const emptyStateDetails = React.useMemo(() => {
    try {
      return zUtils.emptyStateDetailsSchema.parse(emptyKind);
    } catch (e) {
      return null;
    }
  }, [emptyKind]);

  if (!emptyStateDetails) {
    return null;
  }

  const { heading, message, icon, altText, width } = emptyStateDetails;

  return (
    <Flex
      flow="column"
      alignItems="center"
      wrap="wrap"
      css={{
        width,
        textAlign: 'center',
      }}
      wFull
      mt6
    >
      <img src={icon} alt={altText} />

      <Flex flow="column" alignItems="center" wFull>
        <Heading
          size="5"
          css={{
            color: '$neutral60',
            mt: '$4',
            mb: '$1',
          }}
        >
          {heading}
        </Heading>

        <Text variant="description">{message}</Text>
      </Flex>
    </Flex>
  );
};
