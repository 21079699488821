import type { DetectionDateFilterFields } from 'src/features/edr/common/common.types';
import { decodeObject, encodeObject, withDefault } from 'use-query-params';

// istanbul ignore next
const DetectionDateParamConfig = {
  encode: (obj: DetectionDateFilterFields | null | undefined) =>
    encodeObject(obj, '-', '~'),
  decode: (str: string | (string | null)[] | null | undefined) =>
    decodeObject(str, '-', '~'),
};

function createDetectionDateParam<T>(defaultDetectionDate: T | undefined) {
  return withDefault(DetectionDateParamConfig, defaultDetectionDate);
}

export default createDetectionDateParam;
