// istanbul ignore file
import { dataPlatformApi } from 'src/app/api/base';

import {
  chatBotResponse,
  chatBotSubmittedResponse,
  chatHistoryResponse,
  chatListResponse,
  newChatresponse,
  rateChatRequest,
} from './ai.schema';

const getApiClient = (url: string) => {
  if (!dataPlatformApi) {
    throw new Error('dataPlatformApi is not defined');
  }
  return dataPlatformApi(url);
};

export const listChatSessions = async () => {
  const response = await getApiClient('/list-chat-sessions/').get({});
  try {
    return chatListResponse.parse(response.data);
  } catch (e) {
    console.error(e);
    throw new Error(e as any);
  }
};

export const newChat = async () => {
  const response = await getApiClient('/new-chat/').post({});
  try {
    return newChatresponse.parse(response.data);
  } catch (e) {
    console.error(e);
    throw new Error(e as any);
  }
};

export const getChat = async (chatId: string | null) => {
  if (!chatId) {
    return;
  }
  const response = await getApiClient(`/chat/${chatId}`).get({});
  try {
    return chatHistoryResponse.parse(response.data);
  } catch (e) {
    console.error(e);
    throw new Error(e as any);
  }
};

export const askChat = async (chatId: string, user_chat: string) => {
  const response = await getApiClient(`/chat/${chatId}`).post({
    user_chat,
  });

  try {
    return chatBotResponse.parse(response.data);
  } catch (e) {
    console.error(e);
    throw new Error(e as any);
  }
};

export const getChatMessage = async (chat_response_id: string | null) => {
  const response = await getApiClient(`/chat-response/${chat_response_id}`).get(
    {},
  );
  try {
    return chatBotSubmittedResponse.parse(response.data);
  } catch (e) {
    console.error(e);
    throw new Error(e as any);
  }
};

export const rateChatMessage = async (
  chat_session_id: string,
  message_id: string,
  liked: boolean,
  optional_feedback?: string,
) => {
  const request = rateChatRequest.parse({
    liked,
    optional_feedback,
  });
  const response = await getApiClient(
    `/rate-chat-message/${chat_session_id}/${message_id}`,
  ).post(request);

  return response.data;
};
