import { z } from 'zod';

export const result = z.object({
  id: z.string().uuid(),
  integration: z.number(),
  domain: z.string(),
  client_id: z.string(),
  mac_scep_url: z.string(),
  mac_scep_challenge_url: z.string(),
  mac_scep_challenge_username: z.string(),
  ios_configured: z.boolean(),
  mac_configured: z.boolean(),
});

export const oktaDeviceTrustSchema = z.object({
  count: z.number().nullable().optional(),
  next: z.number().nullable().optional(),
  previous: z.number().nullable().optional(),
  results: z.array(result),
});

export const oktaDeviceTrustUpdateSchema = z.object({
  domain: z.string().optional(),
  mac_scep_url: z.string().optional(),
  mac_scep_challenge_url: z.string().optional(),
  mac_scep_challenge_username: z.string().optional(),
  mac_scep_challenge_password: z.string().optional(),
  ios_provider_secret_key: z.string().optional(),
  client_id: z.string().optional(),
  client_secret: z.string().optional(),
});
