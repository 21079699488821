/* istanbul ignore file -- function is created by Pendo */
// Pendo function copied and pasted from https://support.pendo.io/hc/en-us/articles/360046272771-Developer-s-Guide-To-Installing-the-Pendo-Snippet#TheSnippet
export function injectPendo(loadAsync = true) {
  const pendoKey =
    process.env.REACT_APP_PENDO_API_KEY || window.REACT_APP_PENDO_API_KEY;
  ((p, e, n, d, o) => {
    var v, w, x, y, z;
    o = p[d] = p[d] || {};
    o._q = o._q || [];
    v = [
      'initialize',
      'identify',
      'updateOptions',
      'pageLoad',
      'track',
      'flushNow',
      'validateInstall',
    ];
    for (w = 0, x = v.length; w < x; ++w) {
      ((m) => {
        o[m] =
          o[m] ||
          (() => {
            o._q[m === v[0] ? 'unshift' : 'push'](
              // biome-ignore lint/correctness/noUndeclaredVariables: created by pendo
              [m].concat([].slice.call(arguments, 0)),
            );
          });
      })(v[w]);
    }
    y = e.createElement(n);
    y.async = loadAsync ? !0 : !1;
    y.src = `https://cdn.pendo.io/agent/static/${pendoKey}/pendo.js`;
    z = e.getElementsByTagName(n)[0];
    z.parentNode.insertBefore(y, z);
  })(window, document, 'script', 'pendo');
}
