import { api } from 'app/api/base';

class SsoService {
  constructor() {
    this.ssoPrefix = 'auth/connector';
    this.withId = (prefix, id) => `${prefix}/${id}`;
  }

  async get() {
    const client = api(this.ssoPrefix);
    return client.get();
  }

  async find(params) {
    const client = api(this.ssoPrefix);
    return client.get(params);
  }

  async create(data, params) {
    const client = api(this.addDepProfilePrefix);
    return client.post(data, params);
  }

  async patch(id, data, params) {
    const client = api(this.withId(this.ssoPrefix, id));
    return client.patch(data, params);
  }

  async update(id, data, params) {
    const client = api(this.withId(this.ssoPrefix, id));
    return client.put(data, params);
  }

  async remove(id, data, params) {
    const client = api(this.withId(this.ssoPrefix, id));
    return client.delete(data, params);
  }
}

const ssoService = new SsoService();

export default SsoService;
export { ssoService };
