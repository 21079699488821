import { Box, Tooltip } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import TableHeaderIconButton from '../../../prism/components/PrismTable/components/TableHeaderIconButton';

export const ColumnEditorButton = ({
  onToggleColumnEditor,
}: {
  onToggleColumnEditor: () => void;
}) => {
  return (
    <Box onClick={onToggleColumnEditor}>
      <Tooltip content={i18n.t('Edit columns')} side="top">
        <TableHeaderIconButton
          icon="table-columns"
          onClick={onToggleColumnEditor}
          aria-label={i18n.t('Edit columns')}
          role="button"
        />
      </Tooltip>
    </Box>
  );
};
