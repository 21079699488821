import {
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import { Box, Button, Grid, Icon, Select } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import * as React from 'react';

import featureFlags from 'src/config/feature-flags';

import {
  appAccessPropertyOptions,
  createDisplayableError,
  getAllowOptions,
} from '../common';
import { privacyEnum } from '../schema/privacy.schema';

import type { AppAccessPropertySetting, UpdatePrivacy } from '../privacy.types';
import { AppleEventsProperty } from './AppleEventsProperty';

interface AppAccessPropertyProps {
  appAccessPropertySetting: AppAccessPropertySetting;
  appAccessIndex: number;
  appAccessPropertyIndex: number;
  appAccessProperties: ReadonlyArray<AppAccessPropertySetting>;
  update: UpdatePrivacy;
  isDisabled: boolean;
  showError: boolean;
  canDelete: boolean;
}

const { propertyKey, propertyName, validationMessage, updateActionType } =
  privacyEnum;

const propertyKeyEnum = propertyKey.enum;
const propertyNameEnum = propertyName.enum;
const validationMessageEnum = validationMessage.enum;
const updateActionTypeEnum = updateActionType.enum;

export function AppAccessProperty(props: Readonly<AppAccessPropertyProps>) {
  const {
    appAccessPropertySetting,
    appAccessIndex,
    appAccessPropertyIndex,
    appAccessProperties,
    update,
    isDisabled,
    showError,
    canDelete,
  } = props;
  const {
    AEReceiverCodeRequirement,
    AEReceiverIdentifier,
    AEReceiverIdentifierType,
    Allowed,
    Property,
  } = appAccessPropertySetting;

  const validations = React.useMemo(() => {
    const fieldNames = [propertyKeyEnum.Property, propertyKeyEnum.Allowed];
    const fields = fieldNames.map(
      (name, position) =>
        `appAccess-${appAccessIndex}_property-${appAccessPropertyIndex}_pos-${position}.${name}`,
    );
    const fieldKeys = Object.fromEntries(
      fieldNames.map((name, index) => [name, fields[index]]),
    );

    const getKey = (fieldName: keyof typeof propertyKeyEnum) => {
      const fieldKey = fieldKeys[fieldName];
      return fieldKey || '';
    };

    return {
      fields,
      getKey,
    };
  }, [appAccessIndex, appAccessPropertyIndex]);

  const updateInvalidations = React.useCallback(
    (_key, invalidUpdater) =>
      update({
        type: updateActionTypeEnum.setting,
        updater: (setting) => {
          const invalidationsMap = invalidUpdater(
            setting.invalidations?.invalidationsMap,
          );
          return {
            ...setting,
            invalidations: {
              invalidationsMap,
            },
          };
        },
      }),
    [update],
  );

  const { refs, onInvalidate, invalidations } = useInputsValidators(
    validations.fields,
    updateInvalidations,
  );

  useRemoveValidationOnUnmount(validations.fields, updateInvalidations);

  const validateProperty = React.useCallback(
    (property) => {
      const skipValidation =
        isDisabled || Property === propertyNameEnum.AppleEvents;
      if (skipValidation) {
        return false;
      }

      if (!property) {
        return i18n.t(validationMessageEnum['Required.']);
      }

      const matchingProperties = appAccessProperties.filter(
        (prop) => prop.Property === Property,
      );

      if (matchingProperties.length > 1) {
        return `${Property} has already been added.`;
      }

      return false;
    },
    [Property, appAccessProperties, isDisabled],
  );

  React.useEffect(() => {
    onInvalidate(validations.getKey(propertyKeyEnum.Property))(
      validateProperty(Property),
    );
    onInvalidate(validations.getKey(propertyKeyEnum.Allowed))(
      typeof Allowed !== 'boolean' && Allowed !== null
        ? i18n.t(validationMessageEnum['Required.'])
        : false,
    );
  }, [
    AEReceiverCodeRequirement,
    AEReceiverIdentifier,
    AEReceiverIdentifierType,
    Allowed,
    Property,
    appAccessIndex,
    appAccessPropertyIndex,
    validateProperty,
    validations,
  ]);

  const displayableError = React.useMemo(
    () => createDisplayableError(showError),
    [showError],
  );

  return (
    <Box
      css={{
        gridColumn: '1 / -1',
        '&:not(:last-of-type)': {
          mb: '$2',
        },
      }}
    >
      <Grid
        css={{
          gridTemplateColumns: '320px 320px auto',
          gridColumnGap: '$2',
          alignItems: 'start',
        }}
      >
        <Select
          ref={refs[validations.getKey(propertyKeyEnum.Property)]}
          value={Property}
          onChange={(selectedProperty) =>
            update({
              type: updateActionTypeEnum.appAccessProperty,
              appAccessIndex,
              appAccessPropertyIndex,
              updater: (appAccessProperty) => ({
                ...appAccessProperty,
                Property: selectedProperty,
                Allowed: '',
              }),
            })
          }
          options={appAccessPropertyOptions()}
          readOnly={isDisabled}
          error={displayableError(
            !!invalidations[validations.getKey(propertyKeyEnum.Property)],
            invalidations[validations.getKey(propertyKeyEnum.Property)],
            '',
          )}
        />

        <Select
          key={`${Property}-${Allowed}`}
          ref={refs[validations.getKey(propertyKeyEnum.Allowed)]}
          value={Allowed}
          onChange={(selectedValue) =>
            update({
              type: updateActionTypeEnum.appAccessProperty,
              appAccessIndex,
              appAccessPropertyIndex,
              updater: (appAccessProperty) => ({
                ...appAccessProperty,
                Allowed: selectedValue,
              }),
            })
          }
          options={getAllowOptions(Property)}
          readOnly={isDisabled}
          error={displayableError(
            !!invalidations[validations.getKey(propertyKeyEnum.Allowed)],
            invalidations[validations.getKey(propertyKeyEnum.Allowed)],
            '',
          )}
        />

        {!isDisabled && canDelete && (
          <Button
            variant="subtle"
            disabled={isDisabled}
            aria-label="Remove property"
            onClick={() =>
              update({
                type: updateActionTypeEnum.appAccess,
                appAccessIndex,
                updater: (appAccess) => ({
                  ...appAccess,
                  Properties: appAccess.Properties?.filter(
                    (_, index) => index !== appAccessPropertyIndex,
                  ),
                }),
              })
            }
            css={{
              justifySelf: 'end',
            }}
          >
            <Icon name="trash-can" />
          </Button>
        )}
      </Grid>

      {Property === propertyNameEnum.AppleEvents && (
        <AppleEventsProperty
          appAccessPropertySetting={appAccessPropertySetting}
          update={update}
          appAccessIndex={appAccessIndex}
          appAccessPropertyIndex={appAccessPropertyIndex}
          isDisabled={isDisabled}
          showError={showError}
        />
      )}
    </Box>
  );
}
