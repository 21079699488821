import PropTypes from 'prop-types';
import React from 'react';
import Const from '../Const';

const exportCsvBtnDefaultClass = 'react-bs-table-csv-btn';

const ExportCSVButton = (props) => {
  const {
    btnContextual,
    className,
    onClick,
    btnGlyphicon,
    btnText,
    children,
    ...rest
  } = props;
  const content = children || (
    <span>
      <i className={`fa glyphicon ${btnGlyphicon}`} /> {btnText}
    </span>
  );
  return (
    <button
      type="button"
      className={`btn ${btnContextual} ${exportCsvBtnDefaultClass} ${className} hidden-print`}
      onClick={onClick}
      {...rest}
    >
      {content}
    </button>
  );
};

ExportCSVButton.propTypes = {
  btnText: PropTypes.string,
  btnContextual: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  btnGlyphicon: PropTypes.string,
};

ExportCSVButton.defaultProps = {
  btnText: Const.EXPORT_CSV_TEXT,
  btnContextual: 'btn-success',
  className: '',
  onClick: undefined,
  btnGlyphicon: 'glyphicon-export fa-download',
};

export default ExportCSVButton;
