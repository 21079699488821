import {
  getRunsOnFromModel,
  getSelectedDevicesFromRunsOn,
} from 'features/library-items/data-service/library-item/devices';
import featureFlags from 'src/config/feature-flags';
import { INT_TYPES } from 'src/features/integrations/constants';
import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import { setBlueprintsToFormData } from '../../../data-service/library-item/transformers';

export const getOSRequirement = (apiData) => {
  const osNameMap = {
    macos: 'macOS',
    ios: 'iOS',
    ipados: 'iPadOS',
    tvos: 'tvOS',
  };

  const osRequirements = apiData.data.os_requirements;

  if (
    osRequirements.macos &&
    apiData.data.required_capabilities?.includes('arm64')
  ) {
    // 11.0 (Big Sur) is the lowest version that Apple Silicon will run on:
    osRequirements.macos = '11.0';
  } else if (apiData.data.is_universal_app && !osRequirements.macos) {
    // Set a default version for the macos if this is a universal app:
    osRequirements.macos = '10.15';
  }

  return Object.entries(osRequirements)
    .map(([key, value]) => `${osNameMap[key]} ${value}+`)
    .join(', ');
};

export const transformFromApi = async (apiData) => {
  try {
    const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
    const excludedBlueprints = await getAllBlueprintOptions(
      apiData.excluded_blueprints,
    );

    const data = apiData.data_with_file_urls || apiData.data;

    const deviceFamiliesFromData = apiData?.device_families || [];
    // dc-318
    const LD_mdmCrossPlatformVppSupport = featureFlags.getFlag(
      'mdm-cross-platform-vpp-support',
    );
    /* istanbul ignore next */
    const compatibleDeviceFamiliesFromData = LD_mdmCrossPlatformVppSupport
      ? parseCompatibleDeviceFamilies(
          apiData?.compatible_device_families,
          'compatible',
        ) || []
      : [];
    /* istanbul ignore next */
    const supportedDeviceFamiliesFromData = LD_mdmCrossPlatformVppSupport
      ? parseCompatibleDeviceFamilies(
          apiData?.compatible_device_families,
          'supported',
        ) ||
        apiData?.device_families ||
        []
      : deviceFamiliesFromData;

    const result = {
      _data: data,
      id: apiData.id,
      name: apiData.name,
      icon: data.icon,
      subtitle: data.subtitle,
      description: data.description,
      publisher: data.author,
      bundleId: data.bundle_id,
      supportedDeviceFamilies: {
        ipad: supportedDeviceFamiliesFromData.includes('ipad'),
        iphone: supportedDeviceFamiliesFromData.includes('iphone'),
        ipod: supportedDeviceFamiliesFromData.includes('ipod'),
        mac: supportedDeviceFamiliesFromData.includes('mac'),
        tv: supportedDeviceFamiliesFromData.includes('tvos'),
        watch: supportedDeviceFamiliesFromData.includes('watch'),
        realityDevice:
          supportedDeviceFamiliesFromData.includes('realityDevice'),
      },
      compatibleDeviceFamilies: {
        ipad: compatibleDeviceFamiliesFromData.includes('ipad'),
        iphone: compatibleDeviceFamiliesFromData.includes('iphone'),
        ipod: compatibleDeviceFamiliesFromData.includes('ipod'),
        mac: compatibleDeviceFamiliesFromData.includes('mac'),
        tv: compatibleDeviceFamiliesFromData.includes('tvos'),
        watch: compatibleDeviceFamiliesFromData.includes('watch'),
        realityDevice:
          compatibleDeviceFamiliesFromData.includes('realityDevice'),
      },
      devices: getSelectedDevicesFromRunsOn(apiData),
      osRequirements: getOSRequirement(apiData),
      isActive: apiData.active,
      isSalable: apiData.is_salable,
      selectedBlueprints,
      excludedBlueprints,
      isAllBlueprints: apiData.is_all_blueprints,
      rules: apiData.rules,
    };
    if (
      supportedDeviceFamiliesFromData.includes('mac') ||
      supportedDeviceFamiliesFromData.includes('ipad') ||
      supportedDeviceFamiliesFromData.includes('iphone')
    ) {
      result.installation = {
        type: data.install_enforcement,
      };
      result.selfService = {
        isEnabled: data.show_in_self_service,
        category: data.self_service_data?.category,
        isRecommended: data.self_service_data?.isRecommended,
        displayName: data.self_service_data?.displayName,
        shortDescription: data.self_service_data?.description ?? data.subtitle,
        longDescription: data.self_service_data?.bodyText ?? data.description,
        isRequireRead: data.self_service_data?.mustViewAgreement,
        // iconUrl: data.self_service_data?.iconUrl,
        // iconS3Key: data.self_service_data?.iconS3Key,
        iconUrl: null,
        iconS3Key: null,
        iconFile: null,
        // size: data.file_size,
        size: 0,
        version: data.version?.display || '-',
        displayInfoBtn: data.self_service_data?.displayInfoBtn, // ??
        infoBtnText: data.self_service_data?.infoBtnText, // ??
        infoBtnUrl: data.self_service_data?.infoBtnUrl, // ??
      };
    }
    result.licenses = {
      used: apiData.used_licenses,
      total: data.num_licenses,
    };
    result.options = {
      addToDockDuringInstall: data.add_to_dock,
      isSkipAutoUpdate: apiData.is_skip_auto_update,
    };
    result.returnToService = {
      isReturnToServiceEnabled: data.is_return_to_service_enabled,
      corporateWifiProfileId: data.corporate_wifi_profile_id,
    };

    result.configuration = {
      value: data.configuration,
    };

    result.oktaDeviceTrust = {
      isEnabled: data.okta_device_trust,
    };

    const msdcMsAuthenticatorFF = featureFlags.getFlag(
      'splint-msdc-microsoft-authenticator-li',
    );
    /* istanbul ignore next -- temp for feature flag */
    if (msdcMsAuthenticatorFF) {
      result.msDeviceCompliance = {
        isEnabled: data[INT_TYPES.msDeviceCompliance] ?? false,
      };
    }

    return {
      ...apiData,
      data: result,
    };
  } catch (e) {
    console.error(e);
    throw e.message;
  }
};

export const transformToApi = (model) => {
  const toSend = new FormData();
  toSend.set('active', model.isActive);
  toSend.set('is_skip_auto_update', model.options.isSkipAutoUpdate);
  toSend.set('rules', JSON.stringify(model.rules) || null);
  setBlueprintsToFormData(toSend, model.selectedBlueprints);
  toSend.set('is_all_blueprints', model.isAllBlueprints);

  const data = {
    ...model._data,
    configuration: model.configuration.value,
    install_enforcement: model.installation.type,
    add_to_dock: model.options.addToDockDuringInstall,
  };
  if (model.selfService.isEnabled) {
    data.show_in_self_service = true;
    data.self_service_data = {
      isRecommended: model.selfService.isRecommended,
      // iconS3Key: model.selfService.iconS3Key,
      iconS3Key: null,
      displayName: model.selfService.displayName,
      description: model.selfService.shortDescription,
      bodyText: model.selfService.longDescription,
      mustViewAgreement: model.selfService.isRequireRead,
      category: model.selfService.category,
      displayInfoBtn: model.selfService.displayInfoBtn,
      infoBtnText: model.selfService.infoBtnText,
      infoBtnUrl: model.selfService.infoBtnUrl,
    };
  } else {
    data.show_in_self_service = false;
    delete data.self_service_data;
  }
  data.okta_device_trust = model.oktaDeviceTrust.isEnabled;

  const msdcMsAuthenticatorFF = featureFlags.getFlag(
    'splint-msdc-microsoft-authenticator-li',
  );
  /* istanbul ignore next -- temp for feature flag */
  if (msdcMsAuthenticatorFF && 'msDeviceCompliance' in model) {
    data[INT_TYPES.msDeviceCompliance] = model.msDeviceCompliance.isEnabled;
  }

  // Return to Service
  data.is_return_to_service_enabled =
    model.returnToService.isReturnToServiceEnabled;
  data.corporate_wifi_profile_id = model.returnToService.corporateWifiProfileId;

  // Populate `runs_on_*` fields for FormData
  const runsOn = getRunsOnFromModel(model);
  Object.keys(runsOn).forEach((runsOnKey) => {
    toSend.set(runsOnKey, runsOn[runsOnKey]);
  });

  toSend.set('data', JSON.stringify(data));

  return toSend;
};

/* istanbul ignore next */
function parseCompatibleDeviceFamilies(
  deviceFamiliesMap,
  supportLevel: 'compatible' | 'supported' = 'compatible',
) {
  if (deviceFamiliesMap === undefined) {
    return deviceFamiliesMap;
  }

  return Object.keys(deviceFamiliesMap).reduce((acc, deviceFam) => {
    if (deviceFamiliesMap[deviceFam] === supportLevel) {
      acc.push(deviceFam);
    }

    return acc;
  }, []);
}
