/* istanbul ignore file */
import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class EnergySaverService extends NewLibraryItemService {
  static computerSleep = {
    PREVENT: 0,
    ALLOW: 10,
  };

  static diskSleep = {
    DISABLE: 0,
    ENABLE: 10,
  };

  static wakeLan = {
    DISABLE: 0,
    ENABLE: 1,
  };

  static restartOptions = {
    DISABLE: 0,
    ENABLE: 1,
  };

  static powerNap = {
    DISABLE: 0,
    ENABLE: 1,
  };

  static reduceBrightness = {
    DISABLE: 0,
    ENABLE: 1,
  };

  static wakePower = {
    WAKEPOWERON: 'wakepoweron',
    POWERON: 'poweron',
    WAKE: 'wake',
  };

  static sleepPower = {
    SLEEP: 'sleep',
    RESTART: 'restart',
    SHUTDOWN: 'shutdown',
  };

  static weekDays = {
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 4,
    THURSDAY: 8,
    FRIDAY: 16,
    SATURDAY: 32,
    SUNDAY: 64,
  };

  static weekDayCountToDay = Object.entries(this.weekDays).reduce(
    (a, [ck, cv]) => ({ ...a, [cv]: ck }),
    {},
  );

  static weekDayToDays = (count) => {
    if (this.weekDayCountToDay[count]) {
      return [count];
    }

    let i = 0;
    const arr = [];
    while (1 << i < count) {
      if (count & (1 << i)) {
        arr.push(1 << i);
      }

      i++;
    }
    return arr;
  };

  static daysToWeekdays = (days) => days.reduce((a, c) => c + a, 0);
}

export const energySaverService = new EnergySaverService();

export default EnergySaverService;
