/* istanbul ignore file */
import { formatDeviceOptions } from '../../data-service/library-item/devices';
import { type IpaAppModel, IpaSupportedDevices } from './in-house-apps.types';

const initialState: IpaAppModel = {
  id: '',
  name: '',
  isActive: true,
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  devices: formatDeviceOptions(IpaSupportedDevices),
  rules: [],
  icon: undefined,
  skip_blueprint_conflict: undefined,
  installDetails: {
    ipaFile: {
      file: undefined,
      fileName: undefined,
      fileSize: undefined,
      fileKey: undefined,
      fileUpdated: undefined,
      fileUrl: undefined,
      appIcon: undefined,
      ipadIcon: undefined,
      appId: undefined,
      appName: undefined,
      appVersion: undefined,
      sha256: undefined,
    },
  },
};

export default initialState;
