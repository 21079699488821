import React from 'react';

import { Aggregator } from '.';

const Builder = () => (
  <div className="k-rules-builder">
    <Aggregator aggregator="and" />
  </div>
);

export default Builder;
