import { Checkbox, Toaster as toaster } from '@kandji-inc/bumblebee';
import { Button, Dialog, Flex, Text } from '@kandji-inc/nectar-ui';
import React, { useState } from 'react';

const ComputerMergeDuplicates = (props) => {
  const { computerRecord, info, mergeComputers, onHide, startGetComputers } =
    props;
  const { newComputer, oldComputers } = info;
  const formattedOldComputers = oldComputers.join(', ');
  const { serial_number: serialNumber, hardware_uuid: hardwareUUID } =
    computerRecord;

  const [isLoading, setIsLoading] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const onMerge = () => {
    setIsLoading(true);
    mergeComputers(serialNumber, hardwareUUID)
      .then(() => {
        startGetComputers();
        onHide();
        setIsLoading(false);
        toaster('Computers were merged');
      })
      .catch((err) => {
        onHide();
        toaster(err.message);
      });
  };

  const content = (
    <Flex flow="column" gap="lg">
      <Text>
        {formattedOldComputers} will be merged with{' '}
        <Text css={{ fontWeight: '$medium', display: 'inline-block' }}>
          {newComputer}
        </Text>
        . The complete history will be transferred, and the old record will be
        deleted.
      </Text>

      <Text variant="danger">
        Merging Device Records will transfer all the data (Device Info,
        Parameter History, Notes) from merged Computers under the newest Device
        Record. Merged Device Records will be removed from your Dashboard, all
        Parameter History will be merged. This action cannot be undone.
      </Text>

      <Text>Please confirm you want to merge Device Records.</Text>

      <Checkbox
        checked={isCheckboxChecked}
        onChange={() => setIsCheckboxChecked((curr) => !curr)}
        label="I understand all the data (Parameter Results, Notes, History) from
            merged Computers will be transferred to the newest Device Record.
            This cannot be undone."
      />
    </Flex>
  );

  const footer = (
    <Flex gap="md" justifyContent="end" wrap="wrap">
      <Button variant="subtle" onClick={onHide}>
        Cancel
      </Button>
      <Button
        variant="primary"
        loading={isLoading}
        disabled={!isCheckboxChecked}
        onClick={onMerge}
      >
        Merge
      </Button>
    </Flex>
  );

  return (
    <Dialog
      isOpen
      closeOnEscape
      closeOnOutsideClick
      onOpenChange={onHide}
      title="Merge computer records?"
      content={content}
      footer={footer}
      css={{ zIndex: 2000 }}
    />
  );
};

export default ComputerMergeDuplicates;
