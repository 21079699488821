/* istanbul ignore file -- menu positioning changes not testable in jest */
import type { CSSProperties, MouseEventHandler, RefObject } from 'react';
import React, { useMemo, useState, useRef } from 'react';

import { Button, setClass } from '@kandji-inc/bumblebee';
import { detectOverflow } from '@popperjs/core';
import type { SomeReadonlyRecord } from '@shared/types/util.types';
import type { TippyProps } from '@tippyjs/react';
import Tippy from '@tippyjs/react';

import type { ActionsMenuOption, TableMetaData } from '../../table.types';

const MENU_OPTION_BUTTON_THEME_CLASS = {
  error: 'error',
  action: 'action',
  default: 'action',
} as const;
const DEFAULT_OFFSET_PX = 8;

type ActionsMenuProps<DataRecord extends SomeReadonlyRecord> = Readonly<{
  data: DataRecord;
  menuOptions: ActionsMenuOption<DataRecord>[];
  menuPlacement?: TippyProps['placement'];
  onClick?: (
    data: DataRecord,
    context: {
      event: MouseEventHandler<HTMLButtonElement>;
      option?: ActionsMenuOption<DataRecord>;
    },
  ) => void;
  /**
   * Some commonly used Tippy.js props for tooltip/popover configuration like
   * `zIndex` and `appendTo` are exposed as top-level `ActionsMenu` props, but
   * even further customization can be done by providing an object of Tippy
   * props to `menuPopoverProps`.
   * * @see {@link https://atomiks.github.io/tippyjs/v6/all-props/|the full list of Tippy props.}
   */
  menuPopoverProps?: Readonly<TippyProps>;
  zIndex?: number;
  offset?: TippyProps['offset'];
  appendTo?: TippyProps['appendTo'];
  reference?: TippyProps['reference'] | 'table' | 'toggle';
  tableMetaRef: RefObject<TableMetaData>;
  toggleRef: RefObject<HTMLButtonElement>;
  className?: string;
  style?: CSSProperties;
  children: React.ReactElement<{
    onClick?: (
      data: DataRecord,
      context: { event: MouseEventHandler<HTMLButtonElement> },
    ) => void;
  }>;
  isDisabled?: boolean;
}>;

const ActionsMenu = <DataRecord extends SomeReadonlyRecord>(
  props: ActionsMenuProps<DataRecord>,
) => {
  const {
    data,
    menuOptions,
    menuPlacement,
    menuPopoverProps,
    zIndex = 1,
    offset = [0, DEFAULT_OFFSET_PX],
    appendTo,
    reference,
    tableMetaRef,
    toggleRef,
    children,
    className,
    style,
    onClick,
    isDisabled,
  } = props;
  const [isVisible, setIsVisible] = useState(false);
  const renderedContentRef = useRef<HTMLDivElement>(null);
  const { tableRef } = tableMetaRef.current.refs;
  const tableHeaderHeight = tableMetaRef.current?.tableHeaderHeight || 0;

  const chosenReference = useMemo(() => {
    if (!reference) {
      return null;
    }

    if (typeof reference === 'string') {
      switch (reference) {
        case 'table':
          return tableRef.current || null;
        case 'toggle':
          return toggleRef.current || null;
        default:
          return toggleRef.current || null;
      }
    }

    if (
      (reference &&
        Object.prototype.hasOwnProperty.call(reference, 'current')) ||
      reference instanceof HTMLElement
    ) {
      return reference;
    }

    return null;
  }, [reference, tableRef, toggleRef]);

  const appendToProp = appendTo
    ? { appendTo }
    : {
        appendTo: 'parent',
      };

  const tippyProps = {
    zIndex,
    reference: chosenReference,
    ...menuPopoverProps,
    ...appendToProp,
  };

  const handleOnMenuItemClick = (
    option: ActionsMenuOption<DataRecord>,
    event: MouseEventHandler<HTMLButtonElement>,
  ) => {
    const onClickAction = option.onClick || onClick;
    Promise.resolve(onClickAction(data, { option, event }))
      .then(() => setIsVisible(false))
      .catch(() => setIsVisible(false));
  };

  const handleOnMenuToggleClick = (event) => {
    if (isDisabled) {
      return;
    }
    if (children.props.onClick) {
      children.props.onClick(data, { event });
    }
    setIsVisible((prev) => !prev);
  };
  const menuRef = useRef<HTMLMenuElement>(null);

  const defaultPopoverOptions = {
    modifiers: [
      {
        name: 'eventListeners',
        options: {
          // makes menu stay in place when scrolling or resizing
          scroll: false,
          resize: false,
        },
      },
      {
        name: 'computeStyles',
        options: {
          adaptive: false, // prevents menu moving when scrolling
        },
      },
      {
        name: 'flip',
        options: {
          // primarily flips to top-end if overflows table bottom boundary,
          // alternatively left-end (pushed to the left) if overflows both bottom
          // and top table boundaries
          fallbackPlacements: ['top-end', 'left-end', 'top-start'],
          boundary: tableRef.current || 'clippingParents',
          padding: {
            top: tableHeaderHeight, // adjust to flip if menu goes over col headers row
          },
        },
      },
      {
        name: 'detectOverflowModifier',
        enabled: true,
        phase: 'beforeWrite',
        fn({ state, instance }) {
          const popperInstance = instance;
          const overflow = detectOverflow(state, {
            boundary: tableRef.current || 'clippingParents',
            padding: {
              top: tableHeaderHeight,
            },
          });

          // re-append menu to table element if overflows overall table height
          if (overflow.top + DEFAULT_OFFSET_PX + overflow.bottom > 0) {
            tableRef.current.appendChild(state.elements.popper);
          }

          if (overflow.top > 0) {
            popperInstance.state.styles.popper.top = `${
              // add additional px to prevent menu shadow cutoff by col headers
              overflow.top + DEFAULT_OFFSET_PX + 6
            }px`;
          }
        },
      },
    ],
  };

  return (
    <Tippy
      ref={renderedContentRef}
      disabled={isDisabled}
      content={
        <menu
          ref={menuRef}
          className={setClass('v-table-actions-menu', 'b-menu', className)}
          style={{ margin: 0, ...style }}
        >
          {menuOptions.map((opt) => {
            const {
              name,
              type,
              disabled,
              theme = 'none',
              icon,
              iconClassName,
            } = opt;

            const hasIcon = !!icon || !!iconClassName;
            if (type === 'line') {
              return (
                <div
                  key={`line_${name}`}
                  role="separator"
                  style={{
                    height: '1px',
                    padding: 0,
                    background: 'var(--color-neutral-20)',
                  }}
                />
              );
            }

            return (
              <Button
                key={name}
                kind="link"
                disabled={disabled}
                icon={icon}
                theme={MENU_OPTION_BUTTON_THEME_CLASS[theme]}
                className={setClass(
                  'b-txt',
                  'b-menu__link',
                  hasIcon && 'btn-icon btn-icon-left',
                )}
                onClick={(event) => handleOnMenuItemClick(opt, event)}
                isDisabled={isDisabled}
              >
                {!!iconClassName && (
                  <i
                    className={setClass(
                      iconClassName,
                      opt.loading && 'b-menu__icon-spin',
                      'b-mr-micro',
                    )}
                  />
                )}
                {opt.name}
              </Button>
            );
          })}
        </menu>
      }
      theme="vuln"
      interactive
      visible={isVisible}
      onClickOutside={() => setIsVisible(false)}
      placement={menuPlacement}
      zIndex={zIndex}
      animation={false}
      arrow={false}
      offset={offset}
      // @ts-expect-error -- difficult to type with Tippy's provided types
      popperOptions={menuPopoverProps || defaultPopoverOptions}
      {...tippyProps}
    >
      {React.cloneElement(children, {
        ...children.props,
        onClick: handleOnMenuToggleClick,
      })}
    </Tippy>
  );
};

export default ActionsMenu;
