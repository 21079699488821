/* istanbul ignore file */
import EthernetService from '../../ethernet-service';

const proxyKeys = EthernetService.keys.proxy;

const getProxy = (model) => {
  const { proxy } = model;
  const isEnabled = proxy[proxyKeys.isEnabled];
  const proxyType = proxy[proxyKeys.proxyType];
  const isAutomatic =
    isEnabled && proxyType === EthernetService.proxyTypes.AUTOMATIC;
  const isManual = isEnabled && proxyType === EthernetService.proxyTypes.MANUAL;

  const server = proxy[proxyKeys.manual.proxyServer] || null;
  const port = parseInt(proxy[proxyKeys.manual.port], 10);
  const proxyUsername = proxy[proxyKeys.manual.proxyUsername] || null;
  const proxyPassword = proxy[proxyKeys.manual.proxyPassword] || null;
  const proxyPacUrl = proxy[proxyKeys.automatic.proxyPacUrl] || null;
  const proxyPacFallbackAllowed =
    proxy[proxyKeys.automatic.allowProxyPacFallback];

  return {
    isManaged: isEnabled,
    settings: {
      ProxyType: isEnabled ? proxyType : null,
      ProxyServer: isManual ? server : null,
      ProxyServerPort: isManual ? port : null,
      ProxyUsername: isManual && proxyUsername ? proxyUsername : null,
      ProxyPassword: isManual && proxyPassword ? proxyPassword : null,
      ProxyPACURL: isAutomatic && proxyPacUrl ? proxyPacUrl?.trim() : null,
      ProxyPACFallbackAllowed: isAutomatic ? proxyPacFallbackAllowed : null,
    },
  };
};

export default getProxy;
