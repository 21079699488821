import { useRouteMatch } from 'react-router-dom';
import { useWhiteBackground } from 'src/hooks/useWhiteBackground';
import PulseChecks from './PulseChecks';

const PulseRoot = () => {
  const { path } = useRouteMatch();
  useWhiteBackground();

  return <PulseChecks displaySelectDialog={path.includes('/new')} />;
};

export default PulseRoot;
