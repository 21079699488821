import { Icon } from '@kandji-inc/bumblebee';
import { colors } from 'app/common/constants';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import uuidv4 from 'uuid/v4';
import { useOnClickOutside } from '../common/hooks';

const Wrapper = styled.section`
  font-family: var(--font-family-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  min-height: 2em;
  font-size: 0.9rem;
  font-weight: 400;
`;

const Button = styled.button.attrs(({ opened }) => ({
  zIndex: opened ? 9999 : 1,
  color: opened ? colors['grey-300'] : colors['grey-500'],
  background: opened ? '#fff' : 'transparent',
  border: opened ? `1px solid ${colors['grey-200']}` : 'none',
  transition: opened
    ? 'border-radius .3s cubic-bezier(0, 1, 0.5, 1)'
    : '.3s .05s cubic-bezier(0, 1, 0.5, 1), border-radius 0s ease',
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  width: 100%;
  height: 100%;
  min-height: 2em;
  text-align: center;
  cursor: pointer;
  border-radius: 5px 5px 0 0;
  z-index: ${(props) => props.zIndex};
  color: ${(props) => props.color};
  background: ${(props) => props.background};
  border: ${(props) => props.border};
  border-bottom: none;
  transition: ${(props) => props.transition};
  &:not(.opened) {
    transition: border-radius 0s;
  }
  &:focus {
    outline: 0;
  }
`;

const Body = styled.section`
  font-family: var(--font-family-primary);
  font-weight: 500;
  z-index: 9998;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 260px;
  top: calc(100% - 1px);
  background: #fff;
  color: ${colors['grey-400']};
  border-radius: 5px 0 5px 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  align-self: flex-start;
  border: 1px solid ${colors['grey-200']};
  box-shadow: 0 10px 40px 0 ${colors['grey-300']};
  transition:
    width 0.3s ease,
    max-height 0.4s ease;
  overflow: hidden;
  white-space: nowrap;
  max-height: 500px;
  &:not(.opened) {
    max-height: 0;
    width: 100%;
    box-shadow: none;
    padding: 0;
    border: none;
    opacity: 0;
  }
`;

const LeftBody = styled(Body)`
  left: 0;
`;

const RightBody = styled(Body)`
  right: 0;
`;

const Option = styled.section`
  color: ${colors.grey500};
  cursor: pointer;
  text-align: left;
  padding: 5px 20px;
  transition: 0.5s;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;

  &:hover {
    color: #000;
  }
  &:not(.opened) {
    color: transparent;
  }
`;

const OptionIcon = styled(Icon)`
  margin-right: 1em;
`;

const Delimiter = styled.section`
  width: 100%;
  height: 0;
  border-bottom: 1px solid ${colors['grey-200']};
`;

const ButtonText = styled.span`
  font-size: 1rem;
  font-weight: 500;
`;

const AwesomeDropdown = ({ options, buttonText, disabled, horizontal }) => {
  const [opened, setOpened] = useState(false);
  const AwesomeDropdownRef = useRef(null);
  const toggle = () => setOpened(!opened);
  const handleClickOutside = () => {
    if (opened) {
      toggle();
    }
  };
  useOnClickOutside(AwesomeDropdownRef, handleClickOutside);
  const getPositionBody = () => {
    if (horizontal === 'left') {
      return LeftBody;
    }
    if (horizontal === 'right') {
      return RightBody;
    }
    return Body;
  };
  const PositionBody = getPositionBody();
  return (
    <Wrapper ref={AwesomeDropdownRef}>
      <Button
        onClick={(e) => {
          toggle();
          e.stopPropagation();
        }}
        opened={opened}
        className={classNames({ opened })}
        disabled={disabled}
      >
        {buttonText ? (
          <ButtonText>{buttonText}</ButtonText>
        ) : (
          <Icon name="ellipsis" />
        )}
      </Button>
      <PositionBody className={classNames({ opened })}>
        {options.map((item) => (
          <React.Fragment key={camelCase(item.label) || uuidv4()}>
            {isEmpty(item) && <Delimiter />}
            {!isEmpty(item) && (
              <Option
                className={classNames(item.colorClass, { opened })}
                onClick={(e) => {
                  item.action();
                  toggle();
                  e.stopPropagation();
                }}
              >
                <OptionIcon name={item.iconClass} />
                {item.label}
              </Option>
            )}
          </React.Fragment>
        ))}
      </PositionBody>
    </Wrapper>
  );
};

AwesomeDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      action: PropTypes.func,
      iconClass: PropTypes.string,
      colorClass: PropTypes.string,
    }),
  ).isRequired,
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
  horizontal: PropTypes.oneOf(['left', 'right', null]),
};

AwesomeDropdown.defaultProps = {
  buttonText: null,
  disabled: false,
  horizontal: null,
};

export default AwesomeDropdown;
