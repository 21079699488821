import { Tooltip, TooltipProvider } from '@kandji-inc/nectar-ui';
import type { CSS } from '@kandji-inc/nectar-ui';

type EDRTooltipProps = {
  children: React.ReactNode;
  label?: string | undefined;
  side?: 'top' | 'bottom' | 'left' | 'right';
  interactive?: boolean;
  css?: CSS;
};

const EDRTooltip = ({
  children,
  label,
  side = 'top',
  css = {},
  interactive = false,
}: EDRTooltipProps) => (
  <TooltipProvider>
    <Tooltip
      hidden={!label}
      content={label}
      side={side}
      css={{ zIndex: 1, overflowWrap: 'break-word', ...css }}
      interactive={interactive}
    >
      {children}
    </Tooltip>
  </TooltipProvider>
);

export default EDRTooltip;
