/* istanbul ignore file */
import { useDroppable } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { Box, Icon, animations, theme } from '@kandji-inc/nectar-ui';
import { useMemo, useRef, useState } from 'react';

import { homeScreenLayoutItemSchema } from '../../home-screen-layout.schemas';
import appLibraryIcon from '../../service/apple-system-apps/assets/app-library.png';
import {
  APP_ICON_CONFIG,
  PREVIEW_CONFIG,
  createDragIndicatorCss,
} from '../common';
import SingleApp from '../common/SingleApp';

import type {
  HomeScreenLayoutDeviceKind,
  HomeScreenLayoutDeviceModelSettings,
  HomeScreenLayoutItem,
  HomeScreenLayoutPreviewContextKind,
  PreviewStyleKind,
} from '../../home-screen-layout.types';
import AppIcon from '../common/AppIcon';

interface DockProps {
  readonly dock: HomeScreenLayoutDeviceModelSettings['Dock'];
  readonly kind: HomeScreenLayoutDeviceKind;
  readonly style: PreviewStyleKind;
  readonly disabled: boolean;
  readonly onRemove: (
    item: HomeScreenLayoutItem,
    ctx: {
      kind: HomeScreenLayoutDeviceKind;
      context: HomeScreenLayoutPreviewContextKind;
      disabled: boolean;
      isRemovable: boolean;
    },
  ) => void;
  readonly pageIndex?: number;
}

const Dock = ({
  dock,
  kind,
  onRemove,
  style,
  disabled = false,
  pageIndex,
}: DockProps) => {
  const previewConfig = PREVIEW_CONFIG[kind];
  const {
    dockDroppableContainerId,
    prefixSortableDockItemId: prefixIdSortableDockItem,
    dndContext: { droppableDock },
  } = PREVIEW_CONFIG.common;
  const droppableDockId = dockDroppableContainerId;
  const isAtMax = dock.length >= previewConfig.maxDock;

  const { setNodeRef, active, over } = useDroppable({
    id: `${droppableDockId}_${pageIndex}`,
    data: {
      dndContext: droppableDock,
      items: dock,
      maxItems: previewConfig.maxDock,
      isAtMax,
    },
    disabled: disabled || isAtMax,
  });

  const [dockScrollLeft, setDockScrollLeft] = useState(0);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const { showDockArrowLeft, showDockArrowRight } = useMemo(() => {
    /* istanbul ignore next */
    const hasOverflow = dock.length >= (style === 'portrait' ? 6 : 8);
    const scrollingNode = scrollContainerRef.current;

    if (hasOverflow && scrollingNode) {
      const { clientWidth, scrollWidth } = scrollingNode;
      const scrollOverflowWidth = scrollWidth - clientWidth;

      const SCROLL_OFFSET = 5;
      const isAtScrollStart = dockScrollLeft <= SCROLL_OFFSET;
      const isAtScrollEnd =
        dockScrollLeft === 0
          ? false
          : dockScrollLeft >= scrollOverflowWidth - SCROLL_OFFSET;

      return {
        showDockArrowLeft: !isAtScrollStart,
        showDockArrowRight: !isAtScrollEnd,
      };
    }

    return {
      showDockArrowLeft: false,
      showDockArrowRight: false,
    };
  }, [
    dock.length,
    previewConfig.maxDockVisible,
    dockScrollLeft,
    scrollContainerRef,
    style,
  ]);

  return (
    <SortableContext
      id={`${droppableDockId}_${pageIndex}`}
      items={dock.map((item) => `${prefixIdSortableDockItem}${item.id}`)}
      strategy={rectSortingStrategy}
      disabled={disabled}
    >
      <Box
        ref={setNodeRef}
        css={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          px: '$2',
          backgroundColor: '$neutral10',
          borderRadius: 24,
          overflow: 'hidden',
        }}
      >
        {showDockArrowLeft && (
          <Box
            as="button"
            css={{
              position: 'absolute',
              left: '0',
              display: 'flex',
              alignItems: 'center',
              justifySelf: 'center',
              gridColumn: 'arrow-left',
              gridRow: '1 / -1',
              width: '$5',
              height: '100%',
              background:
                'linear-gradient(to right, $neutral10, $neutral10 50%, transparent)',
              zIndex: 1,
              animation: `${animations.fadeIn.raw} 100ms ease-in`,
            }}
          >
            <Icon
              name="angle-left"
              size="sm"
              color={theme.colors.neutral70 as unknown as string}
              onClick={() =>
                scrollContainerRef.current?.scrollBy({
                  left: APP_ICON_CONFIG.containerSize * -1,
                  behavior: 'smooth',
                })
              }
            />
          </Box>
        )}

        <Box
          ref={scrollContainerRef}
          onScroll={(e) => {
            const { scrollLeft } = e.currentTarget;
            const SCROLL_STEP = 2;
            const isAtScrollStep = scrollLeft % SCROLL_STEP === 0;
            if (isAtScrollStep) {
              setDockScrollLeft(e.currentTarget.scrollLeft);
            }
          }}
          css={{
            display: 'grid',
            gridTemplateColumns: `repeat(${Math.min(
              // for ipad always add one slot for rightmost app library icon
              kind === 'ipad' ? dock.length + 1 : dock.length,
              kind === 'ipad'
                ? previewConfig.maxDock + 1
                : previewConfig.maxDock,
            )}, ${APP_ICON_CONFIG.containerSize}px)`,
            gridColumnGap: '$1',
            gridColumn: 'arrow-left-end / arrow-right-start',
            gridRow: '1 / -1',
            alignItems: 'center',
            minWidth: `${APP_ICON_CONFIG.containerSize * 4}px`,
            /* istanbul ignore next */
            maxWidth: `${
              APP_ICON_CONFIG.containerSize * (style === 'portrait' ? 7 : 9) -
              40
            }px`,
            height: previewConfig.dockHeight[style],
            overflowX: 'auto',
            overflowY: 'visible',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {dock.map((item, itemIndex) => {
            const parsed = homeScreenLayoutItemSchema.safeParse(item);
            if (parsed.success) {
              switch (parsed.data.Type) {
                case 'Application': {
                  const {
                    name,
                    BundleID,
                    icon,
                    id,
                    Type,
                    appType,
                    deviceFamilies,
                    alwaysInPreview,
                  } = parsed.data;

                  const dragIndicatorCss = createDragIndicatorCss({
                    dock,
                    itemIndex,
                    active,
                    over,
                    droppableContainerId: `${droppableDockId}_${pageIndex}`,
                    sortableItemId: `${prefixIdSortableDockItem}${id}`,
                  });

                  const isRemovable =
                    typeof alwaysInPreview === 'boolean'
                      ? !alwaysInPreview
                      : true;

                  return (
                    <SingleApp
                      key={id}
                      kind={kind}
                      context="dock"
                      id={id}
                      name={name}
                      hideName
                      BundleID={BundleID}
                      alwaysInPreview={alwaysInPreview}
                      icon={icon}
                      Type={Type}
                      appType={appType}
                      deviceFamilies={deviceFamilies}
                      onRemove={onRemove}
                      isRemovable={isRemovable}
                      disabled={disabled}
                      items={dock}
                      maxItems={previewConfig.maxDock}
                      isAtMax={isAtMax}
                      className={dragIndicatorCss()}
                    />
                  );
                }
                case 'WebClip': {
                  const {
                    name,
                    URL,
                    icon,
                    id,
                    Type,
                    appType,
                    deviceFamilies,
                    alwaysInPreview,
                  } = parsed.data;

                  const dragIndicatorCss = createDragIndicatorCss({
                    dock,
                    itemIndex,
                    active,
                    over,
                    droppableContainerId: `${droppableDockId}_${pageIndex}`,
                    sortableItemId: `${prefixIdSortableDockItem}${id}`,
                  });

                  const isRemovable =
                    typeof alwaysInPreview === 'boolean'
                      ? !alwaysInPreview
                      : true;

                  return (
                    <SingleApp
                      key={id}
                      kind={kind}
                      context="dock"
                      id={id}
                      name={name}
                      hideName
                      alwaysInPreview={alwaysInPreview}
                      icon={icon}
                      Type={Type}
                      URL={URL}
                      appType={appType}
                      deviceFamilies={deviceFamilies}
                      onRemove={onRemove}
                      isRemovable={isRemovable}
                      disabled={disabled}
                      items={dock}
                      maxItems={previewConfig.maxDock}
                      isAtMax={isAtMax}
                      className={dragIndicatorCss()}
                    />
                  );
                }

                default:
                  return null;
              }
            }

            return null;
          })}

          {kind === 'ipad' && (
            <AppIcon
              app={{
                id: 'app-library',
                name: 'App Library',
                icon: appLibraryIcon,
                Type: 'Application',
                appType: 'system',
              }}
              hideName
              disabled={disabled}
              draggable={false}
            />
          )}
        </Box>

        {showDockArrowRight && (
          <Box
            as="button"
            css={{
              position: 'absolute',
              right: '0',
              display: 'flex',
              alignItems: 'center',
              justifySelf: 'center',
              gridColumn: 'arrow-right',
              gridRow: '1 / -1',
              width: '$5',
              height: '100%',
              background:
                'linear-gradient(to left, $neutral10, $neutral10 50%, transparent)',
              zIndex: 1,
              animation: `${animations.fadeIn.raw} 100ms ease-in`,
            }}
          >
            <Icon
              name="angle-right"
              size="sm"
              color={theme.colors.neutral70 as unknown as string}
              onClick={() =>
                scrollContainerRef.current?.scrollBy({
                  left: APP_ICON_CONFIG.containerSize,
                  behavior: 'smooth',
                })
              }
            />
          </Box>
        )}
      </Box>
    </SortableContext>
  );
};

export default Dock;
