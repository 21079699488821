import React, { useState, useEffect } from 'react';

import { number, string } from 'prop-types';

import { Button, Select, setClass } from '@kandji-inc/bumblebee';

import HoverTippy from 'features/util/components/hover-tippy';

import {
  AM_OS_VERSION_PARENT_KEY,
  AM_OS_VERSION_RULE_TEMPLATE,
  LI_OS_VERSION_RULE_TEMPLATE,
  OS_VERSION_PARENT_KEY,
  TEXT_TYPES,
} from '../constants';

import { filterOptions, findOption } from '../utilities';

import AMCustomOSVersion from './components/am-custom-os-version';

import CustomSelect from './components/custom-select';

import { Fields, useBuilder } from '.';
import LibraryItemCustomOSVersion from './components/li-custom-os-version';
import { getAllInputOptions, getAvailableInputOptions } from './helpers';

const Rule = (props) => {
  const { index, aggregator } = props;

  const {
    facetMap,
    form,
    updateForm,
    supportedDeviceFamilies,
    installOnDeviceFamilies,
  } = useBuilder();

  const [dataType, setDataType] = useState('');
  const [operatorOptions, setOperatorOptions] = useState([]);
  const [onlyOneOperatorOption, setOnlyOneOperatorOption] = useState(false);
  const [valueOptions, setValueOptions] = useState([]);
  const [valueValidation, setValueValidation] = useState({});
  const [apiData, setApiData] = useState({});
  const [isApplicable, setIsApplicable] = useState(true);

  const order = index + 1;

  const ruleList = form[aggregator];

  // Update data related to the chosen Input, including
  // operator options, value options, and character limit
  const updateRuleData = (inputKey) => {
    const {
      data_type,
      options,
      supported_operators: supportedOperatorOptions,
      validation,
      api,
      device_families,
    } = facetMap[inputKey] || {};

    setDataType(data_type);
    setOperatorOptions(supportedOperatorOptions);
    setValueOptions(filterOptions(options, supportedDeviceFamilies));
    setValueValidation(validation);
    setApiData(api);

    // Check against the rule's inputKey and if it is not an OS version key
    // run setIsApplicable with the installOnDeviceFamilies logic otherwise
    // if it is an OS version key keep isApplicable set as TRUE (default)
    if (
      inputKey !== OS_VERSION_PARENT_KEY &&
      inputKey !== AM_OS_VERSION_PARENT_KEY
    ) {
      setIsApplicable(
        device_families?.some((family) =>
          installOnDeviceFamilies?.includes(family),
        ),
      );
    }

    // Every time our operator options change, we redetermine if there is only one
    // operator value that should be preselected or if we should give users a choice
    if (supportedOperatorOptions?.length === 1) {
      setOnlyOneOperatorOption(true);
      ruleList[index].operator = supportedOperatorOptions[0]?.value;
    } else {
      setOnlyOneOperatorOption(false);
    }
  };

  // Update a field's value given a key and the new value
  const updateField = (key, value) => {
    ruleList[index][key] = value;

    if (key === 'input') {
      if (value === OS_VERSION_PARENT_KEY) {
        // Add 'children' object containing the default values of all the OS Versions while
        // the 'subject' values are set for each within the LibraryItemOSVersionRule component via the API
        ruleList[index].children = JSON.parse(
          JSON.stringify(LI_OS_VERSION_RULE_TEMPLATE.children),
        );
      } else if (value === AM_OS_VERSION_PARENT_KEY) {
        // Provide an empty "children" object
        ruleList[index].children = JSON.parse(
          JSON.stringify(AM_OS_VERSION_RULE_TEMPLATE.children),
        );
      } else {
        // If we change the 'Input' type, we have to reset what we are storing for 'Operator'
        // and 'Value' so these field do not pre-populate with previous 'Input' type values
        ruleList[index].operator = '';
        ruleList[index].value = '';

        // When the Input value is chosen, populate the "subject"
        // field according to what is in the Facet Map
        ruleList[index].subject = facetMap[value]?.subject;

        // When changing the 'Input' type away from OS_VERSION_PARENT_KEY, we have to delete the 'children' key and its data (object)
        delete ruleList[index].children;
      }

      // If we change the 'Input' type, we have to reset 'isInvalid' back to FALSE especially when changing
      // away from "Asset tag" and other facets that use either the Text or Range field components for 'Value'
      ruleList[index].isInvalid = false;

      // Update data related to the chosen Input
      updateRuleData(value);
    }

    if (key === 'operator') {
      // Reset 'Value' if 'Operator' is either "is blank" or "is not blank".
      if (value === TEXT_TYPES.IS_BLANK || value === TEXT_TYPES.IS_NOT_BLANK) {
        ruleList[index].value = '';
      }
    }

    updateForm(aggregator, ruleList);
  };

  const updateIsInvalid = (isInvalid) => {
    updateField('isInvalid', isInvalid);
  };

  const deleteRule = () => {
    updateForm(aggregator, [
      ...ruleList.slice(0, index),
      ...ruleList.slice(index + 1),
    ]);
  };

  // Gets the value (object) set within the form state by updateField per the given options
  // for a specific Bumblebee Select input component.
  const getValue = (key, options, isMultiSelect) => {
    if (options) {
      return findOption(ruleList[index][key], options, isMultiSelect);
    }
    return ruleList[index][key];
  };

  const ruleInput = ruleList[index]?.input;
  const getInputValue = () => getValue('input', getAllInputOptions(facetMap));
  const getOperatorValue = () => getValue('operator', operatorOptions);
  const getValueValue = () => getValue('value', valueOptions);

  // Hydrates Rule component (options, values, etc.) per persisted and/or confirmed form state
  useEffect(() => {
    if (ruleInput) {
      updateRuleData(ruleInput);

      getInputValue();
      getOperatorValue();
      getValueValue();
    }
  }, [ruleInput]);

  const getOperatorAndValueComponents = () => {
    switch (ruleInput) {
      case AM_OS_VERSION_PARENT_KEY:
        return null;
      case OS_VERSION_PARENT_KEY:
        return (
          <LibraryItemCustomOSVersion index={index} aggregator={aggregator} />
        );
      default:
        return (
          <>
            <div className="k-rule-field operator">
              <Select
                placeholder="Operator"
                options={operatorOptions}
                value={getOperatorValue()}
                onChange={({ value }) => updateField('operator', value)}
                disabled={
                  !isApplicable || !getInputValue() || onlyOneOperatorOption
                }
                aria-label="Operator"
              />
            </div>

            <div className="k-rule-field value">
              {getInputValue() && (
                <Fields
                  fieldKey="value"
                  placeholder="Value"
                  searchPlaceholder="Search value..."
                  type={dataType}
                  operator={getOperatorValue()}
                  options={valueOptions}
                  value={getValueValue()}
                  updateField={updateField}
                  updateIsInvalid={updateIsInvalid}
                  getValue={getValue}
                  validation={valueValidation}
                  disabled={!isApplicable}
                  apiData={apiData}
                  ariaLabel="Value"
                />
              )}
            </div>
          </>
        );
    }
  };

  return (
    <div
      className={setClass(
        'k-rule',
        `facet-${ruleInput}`,
        isApplicable ? '' : 'not-applicable',
      )}
    >
      <div className="k-rule-order">{order}.</div>

      <div>
        <div style={{ display: 'flex', alignItems: 'start' }}>
          <HoverTippy
            className="k-rule-tippy"
            text="This rule will no longer be applied based on the device platforms chosen."
            maxWidth="none"
            disabled={isApplicable}
          >
            <div className="k-rule-field-group">
              <div className="k-rule-field input">
                <CustomSelect
                  placeholder="Select input"
                  options={getAvailableInputOptions(
                    facetMap,
                    ruleList,
                    supportedDeviceFamilies,
                  )}
                  value={getInputValue()}
                  onChange={({ value }) => updateField('input', value)}
                  disabled={!isApplicable}
                  aria-label="Input"
                />
              </div>

              {getOperatorAndValueComponents()}
            </div>
          </HoverTippy>

          <div className="k-rule-action">
            {ruleList?.length > 1 && (
              <div className="k-rule-action-delete">
                <Button
                  theme="error"
                  kind="link"
                  icon="trash-can"
                  onClick={deleteRule}
                />
              </div>
            )}
          </div>
        </div>

        {ruleInput === AM_OS_VERSION_PARENT_KEY && (
          <AMCustomOSVersion index={index} aggregator={aggregator} />
        )}
      </div>
    </div>
  );
};

Rule.propTypes = {
  index: number.isRequired,
  aggregator: string.isRequired,
};

export default Rule;
