import { Button } from '@kandji-inc/bumblebee';
import EmptyContent from 'features/edr/vulnerability/old/components/table/assets/EmptyContent.svg';
import type { MouseEventHandler, ReactElement } from 'react';
import React from 'react';

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement> | null;
  button?: ReactElement | null;
  textHeader?: string;
  textDescription?: string | undefined;
  icon?: string | null;
};

const TableNoContent = (props: Props) => {
  const {
    onClick,
    button: ButtonComponent,
    textHeader,
    textDescription,
    icon,
  } = props;

  return (
    <div className="b-flex-col b-flex-vc">
      <img
        src={icon || EmptyContent}
        alt="empty"
        className="v-table__no-content-img b-mb"
      />
      <h4 className="b-h4 b-mb1">{textHeader || `No results found`}</h4>
      <p className="b-txt b-mb">
        {textDescription !== undefined
          ? textDescription
          : `We couldn't find a match. Try changing the filter, or search with keywords
      and request methods.`}
      </p>
      {ButtonComponent}
      {onClick && (
        <Button icon="circle-xmark" kind="link" onClick={onClick}>
          Clear filters
        </Button>
      )}
    </div>
  );
};

TableNoContent.defaultProps = {
  onClick: null,
  button: null,
  textHeader: '',
  textDescription: undefined,
  icon: null,
};

export default TableNoContent;
