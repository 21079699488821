/* istanbul ignore file */
import { useMutation } from '@tanstack/react-query';
import { api } from 'src/app/api/base';

export type ComputerTag = {
  computer_count: number;
  id: string;
  is_used_in_rule: boolean;
  name: string;
};

export type ComputerTagsRequest = {
  computer_ids: string[];
  add_tags: string[];
  remove_tags: string[];
};

const useUpdateComputerTags = () => {
  const { data, ...rest } = useMutation({
    mutationKey: ['computer-update-tags'],
    mutationFn: (req: ComputerTagsRequest) =>
      api('computers/update-tags').post(req),
  });

  return {
    data,
    ...rest,
  };
};

export { useUpdateComputerTags };
