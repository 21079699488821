import { Flex, Icon, styled } from '@kandji-inc/nectar-ui';

const IconContainer = styled(Flex, {
  cursor: 'pointer',
  '& svg': {
    height: 20,
    width: 20,
    flexShrink: 0,
  },
});

type ThreatListTableArrowButtonProps = Readonly<{
  expanded: boolean;
  onToggle: (isExpanded: boolean) => void;
  compact?: boolean;
  testId?: string;
}>;

const ThreatListTableArrowButton = (props: ThreatListTableArrowButtonProps) => {
  const { expanded, onToggle, compact, testId } = props;

  const handleToggle = () => {
    onToggle(!expanded);
  };

  const iconContainerWidth = compact ? 16 : 44;

  return (
    <Flex alignItems="center" justifyContent="center">
      <IconContainer
        alignItems="center"
        justifyContent="center"
        onClick={handleToggle}
        css={{
          width: iconContainerWidth,
          height: iconContainerWidth,
        }}
        data-testid={testId}
      >
        <Icon
          color="var(--color-neutral-70)"
          name={`fa-angle-${expanded ? 'up' : 'down'}-small`}
        />
      </IconContainer>
    </Flex>
  );
};

export default ThreatListTableArrowButton;
