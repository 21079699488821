/* istanbul ignore file */
import React from 'react';

export default () => (
  <svg
    width="17"
    height="14"
    viewBox="0 0 17 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.88965 13.3901C2.17415 13.3901 1.63639 13.2124 1.27637 12.8569C0.920898 12.506 0.743164 11.9774 0.743164 11.271V2.92432C0.743164 2.21794 0.920898 1.68929 1.27637 1.33838C1.63639 0.98291 2.17415 0.805176 2.88965 0.805176H14.7158C15.4359 0.805176 15.9736 0.98291 16.3291 1.33838C16.6846 1.68929 16.8623 2.21794 16.8623 2.92432V11.271C16.8623 11.9774 16.6846 12.506 16.3291 12.8569C15.9736 13.2124 15.4359 13.3901 14.7158 13.3901H2.88965ZM2.90332 12.2896H14.7021C15.0394 12.2896 15.2992 12.2007 15.4814 12.0229C15.6683 11.8407 15.7617 11.5718 15.7617 11.2163V2.979C15.7617 2.62354 15.6683 2.35693 15.4814 2.1792C15.2992 1.99691 15.0394 1.90576 14.7021 1.90576H2.90332C2.56152 1.90576 2.29948 1.99691 2.11719 2.1792C1.9349 2.35693 1.84375 2.62354 1.84375 2.979V11.2163C1.84375 11.5718 1.9349 11.8407 2.11719 12.0229C2.29948 12.2007 2.56152 12.2896 2.90332 12.2896ZM6.16406 11.7632C6.06836 11.7632 5.99089 11.7358 5.93164 11.6812C5.8724 11.6219 5.84277 11.5444 5.84277 11.4487C5.84277 11.353 5.8724 11.2756 5.93164 11.2163C5.99089 11.1571 6.06836 11.1274 6.16406 11.1274H11.4482C11.5394 11.1274 11.6146 11.1571 11.6738 11.2163C11.7331 11.2756 11.7627 11.353 11.7627 11.4487C11.7627 11.5444 11.7331 11.6219 11.6738 11.6812C11.6146 11.7358 11.5394 11.7632 11.4482 11.7632H6.16406Z"
      fill="#35414E"
    />
  </svg>
);
