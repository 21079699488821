import KernelExtensionService from './service/kernel-extension-service';

export default {
  id: null,
  name: '',
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  isActive: true,

  supportsRules: true,

  general: {
    AllowUserOverrides: true,
    AllowNonAdminUserApprovals: true,
  },

  teamIds: {
    invalidationsMap: {},
    teams: [KernelExtensionService.generateTeamId()],
  },
};
