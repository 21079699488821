/* istanbul ignore file - legacy code, moved */
import PropTypes from 'prop-types';
import React from 'react';
import { useOldField } from 'src/old-honey-form';

import Banner from 'theme/components/atoms/Banner';

export const IPABannerStatus = ({ text, type }) => {
  const active = useOldField('active').getValue();

  return !active && <Banner type={type} text={text} />;
};

IPABannerStatus.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
};

IPABannerStatus.defaultProps = {
  type: Banner.types.STATUS,
};
