/* istanbul ignore file */
import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export interface UseSyncUrlWithTableStateReturnType {
  filter: any;
  limit: number;
  offset: number;
  setFilter: (filter: any) => void;
  removeFilter: (filterKey: string) => void;
  setLimit: (limit: number) => void;
  setOffset: (offset: number) => void;
  resetFilter: () => void;
  replaceFilter: (filter: any) => void;
}

export function useSyncUrlWithTableState(): UseSyncUrlWithTableStateReturnType {
  const { search } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const filterString = searchParams.get('filter');

  const filter: Record<string, any> = useMemo(
    () => (filterString ? JSON.parse(filterString.replace(/'/g, '')) : {}),
    [filterString],
  );
  const limit = parseInt(searchParams.get('limit') ?? '25', 10);
  const offset = parseInt(searchParams.get('offset') ?? '0', 10);

  const updateUrl = useCallback(
    (key: string, value: string | number | string[] | any) => {
      if (Array.isArray(value)) {
        searchParams.set(key, value.join(','));
      } else if (typeof value === 'object') {
        searchParams.set(key, JSON.stringify(value));
      } else {
        searchParams.set(key, value.toString());
      }
      history.push(`/logs?${searchParams.toString()}`, {
        replace: true,
      });
    },
    [history, searchParams],
  );

  const setFilter = useCallback(
    (newFilter: Record<string, any>) => {
      const updatedFilter = { ...filter, ...newFilter };
      updateUrl('filter', updatedFilter);
    },
    [filter, updateUrl],
  );

  const replaceFilter = useCallback(
    (newFilter: Record<string, any>) => updateUrl('filter', newFilter),
    [updateUrl],
  );

  const removeFilter = useCallback(
    (filterKey: string) => {
      const newFilter = { ...filter };
      delete newFilter[filterKey];
      updateUrl('filter', newFilter);
    },
    [filter, updateUrl],
  );

  const resetFilter = useCallback(() => {
    searchParams.delete('filter');
    history.push(`/logs?${searchParams.toString()}`, {
      replace: true,
    });
  }, [history, searchParams]);

  const setLimit = useCallback(
    (limit: number) => updateUrl('limit', limit),
    [updateUrl],
  );

  const setOffset = useCallback(
    (offset: number) => updateUrl('offset', offset),
    [updateUrl],
  );

  return {
    filter,
    limit,
    offset,
    setFilter,
    removeFilter,
    setLimit,
    setOffset,
    resetFilter,
    replaceFilter,
  };
}
