import dc from 'deepcopy';

const isNullish = (v) => v == null || v === '';
const isPlainObject = (v) =>
  v != null && Object.prototype.toString.call(v) === '[object Object]';

const isAnyEmptyRec = (o, ignore) =>
  Object.keys(o).some((k) => {
    if (Array.isArray(o[k])) {
      return o[k].some((vn) => isAnyEmptyRec(vn, ignore));
    }
    if (isPlainObject(o[k])) {
      return isAnyEmptyRec(o[k], ignore);
    }
    if (!ignore.includes(k)) {
      return isNullish(o[k]);
    }
    return false;
  });

/* at any level, if matches the name of the ignore key, ignore it */
const isAnyEmpty = (o, { ignore = [] } = {}) => {
  if (isNullish(o)) {
    return true;
  }

  if (o && !isPlainObject(o)) {
    return false;
  }

  const c = dc(o);

  return isAnyEmptyRec(c, ignore);
};

export default isAnyEmpty;
