import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { useDebounce } from './useDebounce';

export const useDebouncedState = <T>(
  defaultValue,
  debounceFor = 100,
): [T, Dispatch<SetStateAction<T>>, T] => {
  const [value, setValue] = useState<T>(defaultValue);
  const debouncedValue = useDebounce(value, debounceFor);

  return [debouncedValue, setValue, value];
};
