import React from 'react';

function LocationPoint(props) {
  return (
    <svg
      {...props}
      width="24"
      height="32"
      viewBox="0 0 24 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0C5.375 0 0 5.4375 0 12C0 16.8125 1.5625 18.25 10.75 31.4375C11.0625 31.8125 11.5 32 12 32C12.4375 32 12.875 31.8125 13.1875 31.4375C22.375 18.25 24 16.8125 24 12C24 5.4375 18.5625 0 12 0ZM12 27.9375C11.0625 26.625 10.25 25.4375 9.5 24.4375C3.625 16.0625 3 15.0625 3 12C3 7.0625 7 3 12 3C16.9375 3 21 7.0625 21 12C21 15.0625 20.3125 16.0625 14.4375 24.4375C13.6875 25.4375 12.875 26.625 12 27.9375ZM16.5625 8.125L15.875 7.4375C15.4375 7.0625 14.8125 7.0625 14.4375 7.4375L12 9.9375L9.5 7.4375C9.125 7.0625 8.5 7.0625 8.0625 7.4375L7.375 8.125C7 8.5625 7 9.1875 7.375 9.5625L9.875 12L7.375 14.5C7 14.875 7 15.5 7.375 15.9375L8.0625 16.625C8.5 17 9.125 17 9.5 16.625L12 14.125L14.4375 16.625C14.8125 17 15.4375 17 15.875 16.625L16.5625 15.9375C16.9375 15.5 16.9375 14.875 16.5625 14.5L14.0625 12L16.5625 9.5625C16.9375 9.1875 16.9375 8.5625 16.5625 8.125Z"
        fill="#C9CDD9"
      />
    </svg>
  );
}

export default LocationPoint;
