import type { ChangeEvent, Dispatch, SetStateAction } from 'react';
import React from 'react';

import {
  Badge,
  Box,
  FilterButton,
  Flex,
  MultiSelect,
  Text,
  TextField,
} from '@kandji-inc/nectar-ui';

type FilterOptions = {
  label: string;
  value: string;

  color?: string;
};

type MultiSelectFilterProps = {
  name: string;
  options: Array<FilterOptions>;
  value: Array<string>;
  setValue: Dispatch<SetStateAction<Array<string>>>;
  defaultOptions?: Array<FilterOptions>;
};

type StatusSearchFiltersProps = {
  typeOptions: Array<FilterOptions>;
  typeFilter: Array<string>;
  setTypeFilter: Dispatch<SetStateAction<Array<string>>>;
  statusOptions: Array<FilterOptions>;
  defaultStatusFilterOptions: Array<FilterOptions>;
  statusFilter: Array<string>;
  setStatusFilter: Dispatch<SetStateAction<Array<string>>>;
  handleSearchInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const MultiSelectFilter = (props: MultiSelectFilterProps) => {
  const { name, options, value, setValue, defaultOptions } = props;

  const selectedFilters = options.filter(({ value: v }) => value.includes(v));

  const isAll = selectedFilters?.length === options?.length;
  const isNone = selectedFilters?.length === 0;
  const isDefaultFilterActive =
    selectedFilters.length === defaultOptions?.length &&
    defaultOptions?.every(({ value: optValue }) =>
      selectedFilters.find(({ value: v }) => optValue === v),
    );

  const renderLabel = () => {
    let label;
    if (isAll) {
      label = <Text css={{ fontWeight: '$medium' }}>All</Text>;
    } else if (isNone) {
      label = <Text css={{ fontWeight: '$medium' }}>None</Text>;
    } else {
      label = (
        <Flex flow="row" gap="xs" alignItems="center">
          <Text css={{ fontWeight: '$medium' }}>
            {selectedFilters[0].label}
          </Text>
          {selectedFilters.length > 1 && (
            <Badge
              compact
              css={{
                color: '$neutral0',
                backgroundColor: '$blue50',
              }}
            >
              {`+${selectedFilters.length - 1}`}
            </Badge>
          )}
        </Flex>
      );
    }

    return (
      <Flex flow="row" gap="xs" alignItems="center">
        <Text>{`${name}: `}</Text>
        {label}
      </Flex>
    );
  };

  const hasClearButton = defaultOptions && {
    showClear: Boolean(defaultOptions),
    clearLabel: 'Reset',
    handleClear: () => setValue(defaultOptions.map(({ value }) => value)),
    clearDisabled: isDefaultFilterActive,
  };

  return (
    <Box>
      <MultiSelect
        multi
        selectAll={{ selectAllLabel: 'Select all' }}
        options={options}
        value={value}
        onChange={(selected) => setValue(selected)}
        footer={hasClearButton}
      >
        <FilterButton
          filtersSelected={defaultOptions ? !isDefaultFilterActive : !isAll}
          showRemove={false}
          title={name}
        >
          {renderLabel}
        </FilterButton>
      </MultiSelect>
    </Box>
  );
};

const StatusSearchFilters = (props: StatusSearchFiltersProps) => {
  const {
    typeOptions,
    typeFilter,
    setTypeFilter,
    statusOptions,
    defaultStatusFilterOptions,
    statusFilter,
    setStatusFilter,
    handleSearchInputChange,
  } = props;

  return (
    <Flex pt3 alignItems="center" gap="sm">
      <TextField
        compact
        placeholder="Search name or label"
        icon="magnifying-glass"
        onChange={handleSearchInputChange}
        css={{ '& svg': { height: '20px', width: '20px' } }}
      />

      <MultiSelectFilter
        name="Type"
        options={typeOptions}
        value={typeFilter}
        setValue={setTypeFilter}
      />

      <MultiSelectFilter
        name="Status"
        options={statusOptions}
        value={statusFilter}
        setValue={setStatusFilter}
        defaultOptions={defaultStatusFilterOptions}
      />
    </Flex>
  );
};

export default StatusSearchFilters;
