import React from 'react';

import { bool, func } from 'prop-types';

import { Button, Flex, modal as Modal } from '@kandji-inc/bumblebee';

import { useBuilder } from '../builder';

const DeleteModal = ({ isOpen, setIsOpen }) => {
  const { deleteRuleset, resetForm } = useBuilder();

  const onClose = (closed) => {
    setIsOpen(false);
    if (closed) {
      setTimeout(() => closed(), 500);
    }
  };

  const onCancel = () => {
    onClose();
  };

  const onConfirm = () => {
    deleteRuleset();
    onClose(resetForm);
  };

  return (
    <Modal
      classes={{ root: 'k-rules-library-delete-modal' }}
      isOpen={isOpen}
      onClose={onCancel}
      animationDuration={250}
      parts={{
        header: {
          text: 'Remove Rules',
        },
        content: {
          children: (
            <>
              <p className="b-txt">
                By removing these Rules, this Library Item will be included on
                all devices associated with the Blueprints this Library Item is
                assigned to.
              </p>

              <p className="b-txt b-mt">
                Those changes will only go into effect once the Library Item
                itself is saved.
              </p>

              <p className="b-txt b-mt">
                Are you sure that you want to remove these Rules?
              </p>
            </>
          ),
        },
        footer: {
          children: (
            <Flex>
              <Button kind="outline" onClick={onCancel}>
                Cancel
              </Button>

              <Button
                className="l-ml"
                theme="error"
                isProgress={false}
                onClick={onConfirm}
              >
                Remove
              </Button>
            </Flex>
          ),
        },
      }}
    />
  );
};

DeleteModal.propTypes = {
  isOpen: bool.isRequired,
  setIsOpen: func.isRequired,
};

export default DeleteModal;
