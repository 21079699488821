import type { QueryParamConfig } from 'use-query-params';
import { decodeEnum, encodeString, withDefault } from 'use-query-params';

// istanbul ignore next
function createSortByParamConfig<SortColumnName>(
  enumValues: SortColumnName[],
): QueryParamConfig<SortColumnName[] | undefined> {
  return {
    encode: (str: SortColumnName | null | undefined) => encodeString(str),
    decode: (arrayStr: string | (string | null)[] | null | undefined) =>
      decodeEnum(arrayStr, Array.from(enumValues)),
  };
}

// istanbul ignore next
function createSortOrderParamConfig<SortOrderParamConfig>(
  enumValues: SortOrderParamConfig[],
): QueryParamConfig<SortOrderParamConfig[] | undefined> {
  return {
    encode: (str: SortOrderParamConfig | null | undefined) => encodeString(str),
    decode: (arrayStr: string | (string | null)[] | null | undefined) =>
      decodeEnum(arrayStr, Array.from(enumValues)),
  };
}

function createSortByParam<SortColumnName>(
  enumValues: SortColumnName[],
  defaultValue: SortColumnName | undefined,
) {
  const SortByParamConfig = createSortByParamConfig(enumValues);
  const SortByParam = withDefault(SortByParamConfig, defaultValue);
  return SortByParam;
}

function createSortParam<SortColumnDirection>(
  enumValues: SortColumnDirection[],
  defaultValue: SortColumnDirection | undefined,
) {
  const SortOrderParamConfig = createSortOrderParamConfig(enumValues);
  const SortOrderParam = withDefault(SortOrderParamConfig, defaultValue);

  return SortOrderParam;
}

export { createSortByParam, createSortParam };
