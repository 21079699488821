import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import { setBlueprintsToFormData } from '../../../data-service/library-item/transformers';
import SshProfileService from './ssh-profile-service';

export const transformFromApi = async (apiData) => {
  try {
    const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
    const excludedBlueprints = await getAllBlueprintOptions(
      apiData.excluded_blueprints,
    );

    const result = {
      _originalData: apiData,
      id: apiData.id,
      name: apiData.name,
      isActive: apiData.active,
      selectedBlueprints,
      excludedBlueprints,
      isAllBlueprints: apiData.is_all_blueprints,
      rules: apiData.rules,
    };

    const theData = apiData.data;

    result.general = {
      invalidationsMap: {},

      isLoginAvailability: theData.LoginAvailability != null,
      isLoginAvailabilityEnabled: !!theData.LoginAvailability,

      isChallengeResponseAuthentication:
        theData.ChallengeResponseAuthentication != null,
      isChallengeResponseAuthenticationEnabled:
        !!theData.ChallengeResponseAuthentication,

      isPublicKeyAuthentication: theData.PublicKeyAuthentication != null,
      isPublicKeyAuthenticationEnabled: !!theData.PublicKeyAuthentication,

      isRootLogin: theData.RootLogin != null,
      isRootLoginEnabled: !!theData.RootLogin,

      isSshLoginBanner: theData.LoginBanner != null,
      isSshLoginBannerEnabled: !!theData.LoginBanner,
      sshLoginBannerText:
        theData.LoginBanner || SshProfileService.DEFAULT_BANNER,

      isGrace: theData.LoginAttemptGracePeriod != null,
      graceValue:
        theData.LoginAttemptGracePeriod?.toString() ||
        SshProfileService.DEFAULT_GRACE,

      isMaximumAliveCount: theData.MaximumAliveCount != null,
      maximumAliveCountValue:
        theData.MaximumAliveCount?.toString() ||
        SshProfileService.DEFAULT_MAX_ACCOUNTS,

      isSessionTimeout: theData.SessionTimeout != null,
      sessionTimeoutValue:
        theData.SessionTimeout?.toString() || SshProfileService.DEFAULT_TIMEOUT,

      isMaximumAuthenticationAttempts:
        theData.MaximumAuthenticationAttempts != null,
      maximumAuthenticationAttemptsValue:
        theData.MaximumAuthenticationAttempts?.toString() ||
        SshProfileService.DEFAULT_MAX_ATTEMPTS,

      isPort: theData.SSHServerPort != null,
      portValue:
        theData.SSHServerPort?.toString() || SshProfileService.DEFAULT_PORT,

      isLimitAccess: !!theData.LimitServerAccess?.length,
      limitAccessValue: theData.LimitServerAccess?.length
        ? theData.LimitServerAccess
        : [{ type: null, name: '' }],

      isRemoveNonFipsCiphers: theData.RemoveNonFipsCiphers,
      isRemoveNonFipsMacs: theData.RemoveNonFipsMacs,
      isSecureKeyExchangeAlgorithms: theData.SecureKeyExchangeAlgorithm,
    };

    return {
      ...apiData,
      data: result,
    };
  } catch (e) {
    console.error(e);
    throw e.message;
  }
};

const getLoginBanner = (model) => {
  const {
    isSshLoginBanner: isBanner,
    isSshLoginBannerEnabled: isEnabled,
    sshLoginBannerText: text,
  } = model.general;

  if (!isBanner) {
    return null;
  }
  if (!isEnabled) {
    return '';
  }

  return text;
};

export const transformToApi = (model) => {
  const toSend = new FormData();

  toSend.set('type', 'ssh');
  toSend.set('active', model.isActive);
  toSend.set('name', model.name);
  toSend.set('rules', JSON.stringify(model.rules) || null);
  setBlueprintsToFormData(toSend, model.selectedBlueprints);
  toSend.set('is_all_blueprints', model.isAllBlueprints);

  if (model.skip_blueprint_conflict) {
    toSend.set('skip_blueprint_conflict', model.skip_blueprint_conflict);
  }

  const data = {
    LoginAvailability: model.general.isLoginAvailability
      ? model.general.isLoginAvailabilityEnabled
      : null,
    ChallengeResponseAuthentication: model.general
      .isChallengeResponseAuthentication
      ? model.general.isChallengeResponseAuthenticationEnabled
      : null,
    PublicKeyAuthentication: model.general.isPublicKeyAuthentication
      ? model.general.isPublicKeyAuthenticationEnabled
      : null,
    RootLogin: model.general.isRootLogin
      ? model.general.isRootLoginEnabled
      : null,
    LoginAttemptGracePeriod: model.general.isGrace
      ? model.general.graceValue
      : null,
    MaximumAliveCount: model.general.isMaximumAliveCount
      ? model.general.maximumAliveCountValue || 0
      : null,
    SessionTimeout: model.general.isSessionTimeout
      ? model.general.sessionTimeoutValue
      : null,
    MaximumAuthenticationAttempts: model.general.isMaximumAuthenticationAttempts
      ? model.general.maximumAuthenticationAttemptsValue
      : null,
    LoginBanner: getLoginBanner(model),
    SSHServerPort: model.general.isPort ? model.general.portValue : null,

    RemoveNonFipsCiphers: model.general.isRemoveNonFipsCiphers,
    RemoveNonFipsMacs: model.general.isRemoveNonFipsMacs,
    SecureKeyExchangeAlgorithm: model.general.isSecureKeyExchangeAlgorithms,

    LimitServerAccess: model.general.isLimitAccess
      ? model.general.limitAccessValue
      : [],
  };
  toSend.set('data', JSON.stringify(data));

  return toSend;
};
