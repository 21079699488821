import React from 'react';
import { components } from 'react-select';

import { setClass } from '@kandji-inc/bumblebee';
import { Flex, Icon, Text, theme } from '@kandji-inc/nectar-ui';

const deviceIconMapping = {
  macos: 'sf-desktop-computer',
  ios: 'sf-iphone',
  ipados: 'sf-ipad-landscape',
  tvos: 'sf-apple-tv',
};

export const SingleValueWithIcon = (props) => {
  const { label, value: input } = props.data;
  return (
    <components.SingleValue {...props}>
      <Flex alignItems="center" gap="xs">
        <Icon name={deviceIconMapping[input]} size={20} color="#505E71" />
        <Text>{label}</Text>
      </Flex>
    </components.SingleValue>
  );
};

export const OptionwithIcon = (props) => {
  const { data, isSelected } = props;
  const { label: optionLabel, value: optionValue } = data;
  return (
    <components.Option {...props}>
      <Flex alignItems="center" gap="xs">
        <Icon
          name={deviceIconMapping[optionValue]}
          size={20}
          color={isSelected ? '#F2F5FD' : theme.colors.neutral70.value}
        />
        <Text>{optionLabel}</Text>
      </Flex>
    </components.Option>
  );
};

export const DropdownIndicator = (props) => {
  const {
    selectProps: { menuIsOpen },
  } = props;

  return (
    <components.DropdownIndicator {...props}>
      <Icon
        name="angle-down"
        size="sm"
        className={setClass([
          'b-select__icon',
          menuIsOpen ? '' : 'b-select__icon--closed',
        ])}
      />
    </components.DropdownIndicator>
  );
};
