/* istanbul ignore file */
import { ProgressChips } from '@kandji-inc/nectar-ui';
import { severityColorMap } from '../../constants';
import type { Severity } from '../../vulnerability.types';

type SeverityProgressChipsProps = {
  severity: Severity;
};

const SeverityProgressChips = (props: SeverityProgressChipsProps) => {
  const severity = props?.severity?.toLowerCase();

  const numChipsFilled = {
    none: 0,
    low: 1,
    medium: 2,
    high: 3,
    critical: 4,
  };

  return severity in numChipsFilled ? (
    <ProgressChips
      length={4}
      filledLength={numChipsFilled[severity.toLowerCase()]}
      filledColor={severityColorMap[severity.toLowerCase()]}
    />
  ) : null;
};

export { SeverityProgressChips };
