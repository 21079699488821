import { ssoService } from './sso-service';

const authStrategyMap = {
  EMAIL_PASSWORD: 'email_password',
  GOOGLE_APPS: 'google-apps',
  AZURE_ACTIVE_DIRECTORY: 'waad',
  CUSTOM_SAML: 'samlp',
};

const getSsoConnections = async () => {
  try {
    const r = await ssoService.get();
    const connections = r?.data?.connections;

    if (connections.length > 0) {
      return connections;
    }

    return [];
  } catch {
    throw new Error('Unable to fetch authentication connections');
  }
};

export { getSsoConnections, authStrategyMap };
