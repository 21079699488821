import { Toaster as toaster } from '@kandji-inc/bumblebee';
import {
  Button,
  Dialog,
  Flex,
  Select,
  Text,
  TextField,
} from '@kandji-inc/nectar-ui';
import React, { useState } from 'react';

import { deleteUser } from 'src/app/components/computer/api';

const ComputerDeleteUserAccount = (props) => {
  const { info, onHide } = props;
  const { computerId, computerOSVersion, computerDeviceFamily } = info;
  const canDeleteAllUsers =
    ['iPad', 'iPhone', 'iPod'].includes(computerDeviceFamily) &&
    RegExp(/^(?:1[4-9]|[2-9]\d)\.\d(?:\.\d+)?$/).test(computerOSVersion);

  const [isLoading, setIsLoading] = useState(false);
  const [userName, setUserName] = useState('');
  const [deleteAllUsers, setDeleteAllUsers] = useState(false);

  const deleteOptions = [
    { label: 'Delete specified user', value: false },
    { label: 'Delete all users', value: true },
  ];

  const handleOnUserNameChange = (e) => setUserName(e.target.value);

  const onDelete = () => {
    setIsLoading(true);
    deleteUser(computerId, userName, deleteAllUsers)
      .then(() => {
        onHide();
        setIsLoading(false);
        toaster(i18n.t('Command initiated'));
      })
      .catch(({ response }) => {
        onHide();
        setIsLoading(false);
        toaster(response?.data);
      });
  };

  const content = (
    <Flex flow="column" gap="lg" css={{ minWidth: '$15' }}>
      <Text variant="danger">Deleting a User Account cannot be undone.</Text>
      {deleteAllUsers && (
        <Text>Send a command to delete all user accounts.</Text>
      )}
      {!deleteAllUsers && <Text>Send a command to delete a user account.</Text>}
      {canDeleteAllUsers && (
        <Select
          options={deleteOptions}
          onChange={setDeleteAllUsers}
          value={deleteAllUsers}
        />
      )}
      {!deleteAllUsers && (
        <TextField
          aria-label="username"
          label="Enter the short name (account name) of the user you would like to
            delete:"
          onChange={handleOnUserNameChange}
        />
      )}
    </Flex>
  );

  const footer = (
    <Flex gap="md" justifyContent="end" wrap="wrap">
      <Button variant="subtle" onClick={onHide}>
        Cancel
      </Button>
      <Button
        variant="danger"
        loading={isLoading}
        onClick={onDelete}
        disabled={!deleteAllUsers && !userName}
      >
        {deleteAllUsers ? 'Delete All Users' : 'Delete User'}
      </Button>
    </Flex>
  );

  return (
    <Dialog
      isOpen
      closeOnEscape
      closeOnOutsideClick
      onOpenChange={onHide}
      title="Delete user account"
      content={content}
      footer={footer}
      css={{ zIndex: 2000 }}
    />
  );
};

export default ComputerDeleteUserAccount;
