/* prettier-ignore */
import EthernetService from '../../ethernet-service';

const { keys } = EthernetService;
const authKeys = keys.authentication;
const certTrustKeys = keys.trust;

const getSetupModes = (model) => {
  const { authentication } = model;
  const setupModes = new Set();

  if (authentication[authKeys.useLoginWindow]) {
    setupModes.add('Loginwindow');
  } else {
    setupModes.add('System');
  }

  return setupModes.size ? Array.from(setupModes) : null;
};

const getAuthentication = (model) => {
  const { authentication, trust } = model;

  const hasAuthentication = [
    EthernetService.protocols.TLS,
    EthernetService.protocols.TTLS,
    EthernetService.protocols.LEAP,
    EthernetService.protocols.PEAP,
    EthernetService.protocols.EAP_FAST,
  ].some((protocol) =>
    authentication[authKeys.acceptedEapTypes.acceptedEapTypes].find(
      ({ value }) => protocol === value,
    ),
  );

  const isAd =
    hasAuthentication &&
    authentication[authKeys.acceptedEapTypes.authentication] ===
      EthernetService.authentications.COMPUTER_AD;

  const isOd =
    hasAuthentication &&
    authentication[authKeys.acceptedEapTypes.authentication] ===
      EthernetService.authentications.COMPUTER_OD;

  const isUsernamePassword =
    hasAuthentication &&
    authentication?.[authKeys.acceptedEapTypes.authentication] ===
      EthernetService.authentications.USERNAME_AND_PASSWORD;

  const isTTLS = authentication[
    authKeys.acceptedEapTypes.acceptedEapTypes
  ].find(({ value }) => EthernetService.protocols.TTLS === value);
  const hasOuterIdentity = [
    EthernetService.protocols.TTLS,
    EthernetService.protocols.PEAP,
    EthernetService.protocols.EAP_FAST,
  ].some((protocol) =>
    authentication[authKeys.acceptedEapTypes.acceptedEapTypes].find(
      ({ value }) => protocol === value,
    ),
  );

  const hasTLSVersions = [
    EthernetService.protocols.TLS,
    EthernetService.protocols.TTLS,
    EthernetService.protocols.PEAP,
    EthernetService.protocols.EAP_FAST,
  ].some((protocol) =>
    authentication[authKeys.acceptedEapTypes.acceptedEapTypes].find(
      ({ value }) => protocol === value,
    ),
  );

  const hasPac = authentication[
    authKeys.acceptedEapTypes.acceptedEapTypes
  ].find(({ value }) => EthernetService.protocols.EAP_FAST === value);

  const hasTwoFactorAuth =
    [
      EthernetService.protocols.TTLS,
      EthernetService.protocols.PEAP,
      EthernetService.protocols.EAP_FAST,
    ].some((protocol) =>
      authentication[authKeys.acceptedEapTypes.acceptedEapTypes].find(
        ({ value }) => protocol === value,
      ),
    ) &&
    !authentication[authKeys.useLoginWindow] &&
    authentication[authKeys.acceptedEapTypes.twoFactorAuth];

  const transformTrustedCertsToApi = (certs = []) =>
    certs.map(({ name, size, type, data }) => ({
      fileName: name,
      fileSize: size,
      type,
      data,
    }));

  return {
    trustedCertificates: trust[certTrustKeys.specifyCertificates]
      ? transformTrustedCertsToApi(trust[certTrustKeys.certificates])
      : [],
    settings: {
      SetupModes: getSetupModes(model),
      EAPClientConfiguration: {
        AcceptEapTypes: authentication[
          authKeys.acceptedEapTypes.acceptedEapTypes
        ]?.map(({ value }) => value),
        SystemModeCredentialsSource: isAd ? 'ActiveDirectory' : null,
        SystemModeUseOpenDirectoryCredentials: isOd || null,
        UserName:
          isUsernamePassword &&
          authentication[authKeys.acceptedEapTypes.username]
            ? authentication[authKeys.acceptedEapTypes.username]?.trim()
            : null,
        UserPassword:
          isUsernamePassword &&
          authentication[authKeys.acceptedEapTypes.password]
            ? authentication[authKeys.acceptedEapTypes.password]
            : null,
        OneTimeUserPassword: isUsernamePassword
          ? authentication[authKeys.acceptedEapTypes.promptForPassword] ===
            EthernetService.promptPasswordValues.EVERY_TIME
          : null,
        TTLSInnerAuthentication: isTTLS
          ? authentication[authKeys.acceptedEapTypes.innerAuthentication]
          : null,
        OuterIdentity:
          hasOuterIdentity &&
          authentication[authKeys.acceptedEapTypes.outerIdentity]
            ? authentication[authKeys.acceptedEapTypes.outerIdentity]?.trim()
            : null,
        TLSMinimumVersion: hasTLSVersions
          ? authentication[authKeys.acceptedEapTypes.tlsMinimumVersion]
          : null,
        TLSMaximumVersion: hasTLSVersions
          ? authentication[authKeys.acceptedEapTypes.tlsMaximumVersion]
          : null,
        EAPFASTUsePAC: hasPac
          ? authentication[authKeys.acceptedEapTypes.usePac]
          : null,
        EAPFASTProvisionPAC: hasPac
          ? authentication[authKeys.acceptedEapTypes.provisionPac]
          : null,
        EAPFASTProvisionPACAnonymously: hasPac
          ? authentication[authKeys.acceptedEapTypes.provisionPacAnonymously]
          : null,
        TLSCertificateIsRequired: hasTwoFactorAuth || null,
        // Certificate Trust fields
        TLSTrustedServerNames: trust[
          certTrustKeys.specifyServerCertificateNames
        ]
          ? trust[certTrustKeys.serverCertificateNames]?.map(({ value }) =>
              value?.trim(),
            )
          : null,
      },
    },
  };
};

export default getAuthentication;
