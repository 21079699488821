import _cond from 'lodash/cond';
import _stubTrue from 'lodash/stubTrue';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './enrollmentAuthentication.css';

import { Button } from '@kandji-inc/bumblebee';
import { adcsLinks } from 'app/common/constants';
import HubSpotHandler from 'src/components/common/hubspot-handler';
import { auth0RedirectUri } from 'src/config';
import Loader from 'theme/components/atoms/Loader';
import KandjiLogo from '../../assets/img/new_icons/kandji_logo_stacked_dark.svg';
import { useAuth0 } from '../../auth0';

const AUTH_SUCCESS = 'auth-success';
const AUTH_FAILURE = 'auth-failure';

export const postMessage = ({ messageType, messageParam }) => {
  window.chrome &&
    window.chrome.webview.postMessage({ messageType, messageParam });
  window.webkit &&
    window.webkit.messageHandlers.kandjiAuthHandler.postMessage({
      messageType,
      messageParam,
    });
};

const isSuccessful = ({ isAuthenticated, isLoading }) =>
  isAuthenticated && !isLoading;
const isFail = ({ isAuthenticated, isLoading, redirect }) =>
  !isAuthenticated && !isLoading && !redirect;
const isError = ({ error }) => error;
const isCredentialsError = ({ auth0ClientError }) => auth0ClientError;

const errInfo =
  'There was a problem authenticating your user account. If the problem persists, you will need to reach out to Kandji support.';

const successMessages = () => ({
  message: 'Authentication was successful',
  info: 'Redirect in progress...',
});
const failMessages = () => ({
  message: 'Failed to authenticate',
  info: errInfo,
});
const errorMessages = () => ({
  message: 'Unable to verify your account',
  info: errInfo,
});

const getMessaging = _cond([
  [isSuccessful, successMessages],
  [isError, errorMessages],
  [isCredentialsError, errorMessages],
  [isFail, failMessages],
  [_stubTrue, () => ({})],
]);

const onSuccessfullAuth = async ({ getAccessTokenSilently }) => {
  try {
    const token = await getAccessTokenSilently();
    postMessage({ messageType: AUTH_SUCCESS, messageParam: token });
  } catch (_) {
    postMessage({
      messageType: AUTH_FAILURE,
      messageParam: 'Unable to get access token',
    });
  }
};

const onError = () =>
  postMessage({ messageType: AUTH_FAILURE, messageParam: 'Auth0 error' });

const onCredentialsError = () =>
  postMessage({
    messageType: AUTH_FAILURE,
    messageParam: 'Unable to fetch Auth0 credentials',
  });

const onFail = () =>
  postMessage({
    messageType: AUTH_FAILURE,
    messageParam: 'Authentication failed',
  });

const doAction = _cond([
  [isSuccessful, onSuccessfullAuth],
  [isError, onError],
  [isCredentialsError, onCredentialsError],
  [isFail, onFail],
  [_stubTrue, () => ({})],
]);

const ADCSEnrollmentAuthentication = () => {
  const {
    clientId,
    metadata,
    error: auth0ClientError,
  } = useSelector((state) => state.auth0);
  const gotAuth0Creds = !!clientId;
  const {
    error,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  // auth0 rule threw error
  const authFailed = Object.keys(metadata).includes('authFailed');

  const [redirect, setRedirect] = useState(false);
  const redirectUri = `${auth0RedirectUri}${adcsLinks.adcsEnrollment}`;

  // send user to auth0, user will be redirected here
  useEffect(() => {
    if (clientId && !isLoading && !isAuthenticated && !error && !authFailed) {
      setRedirect(true);
      loginWithRedirect({
        redirectUri,
      });
    }
  }, [
    authFailed,
    clientId,
    error,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    redirectUri,
  ]);

  // actions to take
  useEffect(() => {
    doAction({
      isAuthenticated,
      isLoading,
      error,
      auth0ClientError,
      redirect,
      getAccessTokenSilently,
    });
  }, [
    isAuthenticated,
    getAccessTokenSilently,
    isLoading,
    error,
    auth0ClientError,
    redirect,
  ]);

  // ui messaging
  const { message = '', info = '' } = getMessaging({
    isAuthenticated,
    isLoading,
    error,
    auth0ClientError,
    redirect,
  });

  const className = 'adcs-authentication';

  useEffect(() => {
    document.body.classList.add(className);
    return () => document.body.classList.remove(className);
  }, []);

  return (
    <div className="k-adcs-container">
      <HubSpotHandler modifiers={['hide']} />
      <img className="k-adcs-logo" src={KandjiLogo} alt="Kandji" />
      <div className={`k-adcs-content k-adcs-${redirect ? 'loader' : 'text'}`}>
        {/* Initial mount - redirecting to auth0 */}
        {redirect ? (
          <Loader type="simple" />
        ) : (
          <>
            <h2 className="k-adcs-message b-h4">{message}</h2>
            <div className="k-adcs-info b-txt">{info}</div>
          </>
        )}
        {/* Unsuccessful authentication - allow user to retry */}
        {authFailed && (
          <div className="k-adcs-button">
            <Button
              onClick={() =>
                loginWithRedirect({
                  redirectUri,
                })
              }
              disabled={!gotAuth0Creds}
            >
              Try Again
            </Button>
          </div>
        )}
        <div className="k-adcs-footer b-txt-ctrl9">
          If you have any questions, please contact Kandji support.
        </div>
      </div>
    </div>
  );
};

export default ADCSEnrollmentAuthentication;
