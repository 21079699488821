import sortBy from 'lodash/sortBy';
import { createReducer } from 'redux-create-reducer';
import { T_CATEGORIES_FETCHED } from '../_actions/templates';

const initialState = [];

export default createReducer(initialState, {
  [T_CATEGORIES_FETCHED](state, action) {
    return sortBy([...action.payload], 'weight');
  },
});
