import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import { setBlueprintsToFormData } from '../../../data-service/library-item/transformers';
import config from '../../../library/library-item-configurations/items/profile';
import RecoveryPasswordService from './recovery-password-service';

export const transformFromApi = async (apiData) => {
  try {
    const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
    const excludedBlueprints = await getAllBlueprintOptions(
      apiData.excluded_blueprints,
    );

    const data = apiData.data_with_file_urls || apiData.data;

    const result = {
      _data: data,
      id: apiData.id,
      name: apiData.name,
      isActive: apiData.active,
      selectedBlueprints,
      excludedBlueprints,
      isAllBlueprints: apiData.is_all_blueprints,
      rules: apiData.rules,
    };

    result.manage = {
      isAutoPassword: apiData.data.isAutoGeneratable,
      isRotatePassword: apiData.data.rotateDays != null,
      rotateDays:
        apiData.data.rotateDays?.toString() ??
        RecoveryPasswordService.DEFAULT_ROTATE_DAYS,
      password: apiData.data.recoveryPassword ?? '',
    };

    result.legacy = {
      isManaged: !!apiData.data.legacyPasswords,
      passwords: apiData.data.legacyPasswords || [''],
    };

    return {
      ...apiData,
      data: result,
    };
  } catch (e) {
    console.error(e);
    throw e.message;
  }
};

export const transformToApi = (model) => {
  const itemConfig = config['Recovery Password'];
  const toSend = new FormData();
  toSend.set('active', model.isActive);
  toSend.set('name', model.name);
  toSend.set('type', itemConfig.type);
  toSend.set('identifier', itemConfig.identifier);
  toSend.set('reassign_lib_item_to_bp', 'true');
  toSend.set('rules', JSON.stringify(model.rules) || null);
  setBlueprintsToFormData(toSend, model.selectedBlueprints);
  toSend.set('is_all_blueprints', model.isAllBlueprints);

  const data = {
    legacyPasswords: model.legacy.isManaged ? model.legacy.passwords : null,
    recoveryPassword: model.manage.isAutoPassword
      ? null
      : model.manage.password,
    isAutoGeneratable: model.manage.isAutoPassword,
    rotateDays:
      model.manage.isAutoPassword && model.manage.isRotatePassword
        ? model.manage.rotateDays
        : null,
  };

  /* istanbul ignore next */
  if (model.skip_blueprint_conflict) {
    toSend.set('skip_blueprint_conflict', model.skip_blueprint_conflict);
  }

  toSend.set('data', JSON.stringify(data));

  return toSend;
};
