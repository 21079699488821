import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import {
  ON_OFF_CELL_FILTER_OPTIONS,
  OnOffCell,
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  extractFilterableColumns,
  getFilteredDisabled as filterDisabled,
  getEnumFilterOptions,
} from '../column-utils';
import { getGlobalColumns } from './globalColumns';

export const getApplicationFirewallColumns = (schemas) => {
  const globalSchema = schemas.find((schema) => schema.uri === 'globals');
  const applicationFirewallSchema = schemas.find(
    (schema) => schema.uri === 'application_firewall',
  );

  if (!applicationFirewallSchema || !globalSchema) {
    return { columnDefs: [], filterableColumns: [] };
  }

  const combinedProperties = {
    ...globalSchema.schema.properties,
    ...applicationFirewallSchema.schema.properties,
  };

  const applicationFirewallColumnHelper =
    createColumnHelper<z.infer<typeof combinedProperties>>();

  const globalColumns = getGlobalColumns(
    applicationFirewallColumnHelper,
    combinedProperties,
  );

  const columnDefs = [
    ...globalColumns.startColumnDefs,

    applicationFirewallColumnHelper.accessor((row) => row.status, {
      id: 'status',
      cell: OnOffCell,
      meta: {
        displayName: combinedProperties.status.title,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...ON_OFF_CELL_FILTER_OPTIONS()],
        filterDisabled,
      },
    }),

    applicationFirewallColumnHelper.accessor((row) => row.block_all_incoming, {
      id: 'block_all_incoming',
      cell: YesNoCell,
      meta: {
        displayName: combinedProperties.block_all_incoming.title,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterDisabled,
      },
    }),

    applicationFirewallColumnHelper.accessor(
      (row) => row.allow_signed_applications,
      {
        id: 'allow_signed_applications',
        cell: YesNoCell,
        meta: {
          displayName: combinedProperties.allow_signed_applications.title,
          filterType: 'boolean',
          filterIcon: 'circle-dot',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterDisabled,
        },
      },
    ),

    applicationFirewallColumnHelper.accessor((row) => row.stealth_mode, {
      id: 'stealth_mode',
      cell: OnOffCell,
      meta: {
        displayName: combinedProperties.stealth_mode.title,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...ON_OFF_CELL_FILTER_OPTIONS()],
        filterDisabled,
      },
    }),

    applicationFirewallColumnHelper.accessor((row) => row.logging, {
      id: 'logging',
      cell: OnOffCell,
      meta: {
        displayName: combinedProperties.logging.title,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...ON_OFF_CELL_FILTER_OPTIONS()],
        filterDisabled,
      },
    }),

    applicationFirewallColumnHelper.accessor((row) => row.logging_option, {
      id: 'logging_option',
      meta: {
        displayName: combinedProperties.logging_option.title,
        filterType: 'enum',
        filterOptions: getEnumFilterOptions(
          applicationFirewallSchema.schema?.definitions.FirewallLoggingOption
            .enum || [],
        ),
        filterIcon: 'list-check',
        filterDisabled,
      },
    }),

    applicationFirewallColumnHelper.accessor((row) => row.version, {
      id: 'version',
      meta: {
        displayName: combinedProperties.version.title,
        defaultHidden: true,
        filterType: 'version',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    applicationFirewallColumnHelper.accessor((row) => row.unloading, {
      id: 'unloading',
      cell: YesNoCell,
      meta: {
        displayName: combinedProperties.unloading.title,
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterDisabled,
      },
    }),

    ...globalColumns.endColumnDefs,
  ];

  const filterableColumns = extractFilterableColumns(columnDefs);

  return { columnDefs, filterableColumns };
};
