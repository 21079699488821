import type { SortColumnState } from 'src/features/edr/threat/threat.types';

const getSortBy = (sort: SortColumnState): string => {
  if (!sort.sortOrder || !sort.sortBy || sort.sortOrder === 'none') {
    return '';
  }
  return `${sort.sortOrder === 'desc' ? '-' : ''}${sort.sortBy}`;
};

export default getSortBy;
