import type { History } from 'history';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SearchParamsUiHelper from './SearchParamsUiHelper.class';

const isTestEnv = process.env.NODE_ENV === 'test';
const SEV_FILTER_DEBOUNCE_MS = 2000;
const DEFAULT_ROWS_PER_PAGE = [25, 50, 100, 300] as const;
const DEFAULT_SORT_BY_COL_NAMES = [
  'software_name',
  'severity',
  'cvss_score',
] as const;
const INITIAL_SEARCH_PARAMS_REPLACE =
  'useSearchParamsUiHelper.initialSearchParamsReplace';

export default function useSearchParamsUiHelper({
  initialParams = {},
  uiConfig,
  onParamsChange,
  sortByColNames = DEFAULT_SORT_BY_COL_NAMES,
  rowsPerPageOptions = DEFAULT_ROWS_PER_PAGE,
}: {
  initialParams?: Record<string, string>;
  uiConfig?: InstanceType<typeof SearchParamsUiHelper>['uiConfig'];
  onParamsChange?: InstanceType<typeof SearchParamsUiHelper>['onParamsChange'];
  sortByColNames?: readonly string[];
  rowsPerPageOptions?: readonly number[];
} = {}) {
  const [uiControlledState, setUiControlledState] = useState({});
  const history = useHistory();
  const { search, state = { from: null } } = useLocation<{
    from?: typeof INITIAL_SEARCH_PARAMS_REPLACE;
  }>();

  const [initialParamsObj, initialParamsString, hasSameSearchAsInitial] =
    useMemo(() => {
      if (!Object.keys(initialParams).length) {
        return [null, null, () => {}];
      }

      const paramsObj = Object.fromEntries(
        Object.entries(initialParams).map(([paramKey, defaultValue]) => {
          const paramValue: string =
            new URLSearchParams(search.toLowerCase()).get(paramKey)?.trim() ||
            defaultValue.toLowerCase();

          // Detect if the query string 'limit' is not a valid option and
          // override it with the closest valid value
          if (
            paramKey === 'limit' &&
            !rowsPerPageOptions.includes(Number(paramValue))
          ) {
            const paramRowsPerPage = Number(paramValue);
            const closestRowsPerPage = rowsPerPageOptions.reduce(
              (closest, rpp) => {
                if (
                  Math.abs(rpp - paramRowsPerPage) <
                  Math.abs(closest - paramRowsPerPage)
                ) {
                  return rpp;
                }
                return closest;
              },
            );

            return [paramKey, closestRowsPerPage];
          }

          if (paramKey === 'offset') {
            const paramOffset = Number.isNaN(Number(paramValue))
              ? 0
              : paramValue;
            return [paramKey, Math.max(0, Number(paramOffset))];
          }

          const validSortByColNames = [
            ...sortByColNames,
            ...sortByColNames.map((colName) => `-${colName}`),
          ];
          if (
            paramKey === 'sort_by' &&
            !validSortByColNames.includes(paramValue)
          ) {
            return [paramKey, defaultValue];
          }

          if (paramKey === 'severity') {
            const severityParam = paramValue
              .split(',')
              .map((sev) => sev.trim());

            return severityParam.every((param) =>
              [
                'low',
                'medium',
                'high',
                'critical',
                'none',
                'unassigned',
              ].includes(param),
            )
              ? [paramKey, severityParam.join(',')]
              : [paramKey, defaultValue];
          }

          if (paramKey === 'status') {
            return ['open', 'closed'].includes(paramValue)
              ? [paramKey, paramValue]
              : [paramKey, defaultValue];
          }

          return [paramKey, paramValue];
        }),
      );

      // for determining if normalized `useLocation.search` and initial params
      // strings are same for skipping duplicate API requests on mount
      const paramsSearch = new URLSearchParams(paramsObj);
      paramsSearch.sort();

      const paramsString = `?${paramsSearch.toString()}`;
      const currHistoryLength = history.length;

      const hasSameSearch = (hist: History) => {
        const isAtInitialHistoryIdx = currHistoryLength === hist.length;

        // sort search params only if first loading page with specified params,
        // all subsequent search params are expected to be sorted via
        // `searchParamsUi.updateSearchUrl`
        if (isAtInitialHistoryIdx) {
          const normalizedSearch = new URLSearchParams(hist.location.search);
          normalizedSearch.sort();
          return `?${normalizedSearch.toString()}` === paramsString;
        }

        return hist.location.search === paramsString;
      };

      return [paramsObj, paramsString, hasSameSearch];
    }, []);

  const searchParamsUi = useMemo(
    () =>
      new SearchParamsUiHelper({
        // Pass the initial params if the search URL query strings haven't been updated yet (AKA search = {}):
        search,
        history,
        uiConfig,
        onParamsChange,
        setUiControlledState,
        uiControlledState,
      }),
    [search, onParamsChange, history, uiConfig, uiControlledState],
  );

  const handleServerSort = useCallback(
    ({ colName, nextSortDirection }) => {
      // Override CVSS Score to Severity:
      const sortBy =
        colName.toLowerCase() === 'cvss_score' ? 'severity' : colName;

      searchParamsUi.setAll({
        sort_by: `${nextSortDirection === 'desc' ? '-' : ''}${sortBy}`,
      });

      searchParamsUi.updateSearchUrl({
        key: ['sort_by'],
        value: [sortBy],
      });
    },
    [searchParamsUi],
  );

  /* 
    dep to determine creation and return of new `paramsObj` on new
    `useLocation.search` but maintain stable obj ref to prevent duplicate API
    requests on mount when:
    
    1. no search -> use stable `initialParamsObj` as params obj with default values
    2. immediate render after history replace to set search URL with `initialParamsObj` key values -> keep using stable `initialParamsObj` ref
    3. initial page load has search but is same as `initialParamsObj` search string -> use stable `initialParamsObj` ref instead
  */
  const paramsObjDep =
    !search ||
    state.from === INITIAL_SEARCH_PARAMS_REPLACE ||
    hasSameSearchAsInitial(history)
      ? INITIAL_SEARCH_PARAMS_REPLACE
      : search;
  const paramsObj = useMemo(
    () =>
      // Pass the initial params if the search URL query strings haven't been updated yet (AKA search params = {}):
      initialParamsObj && paramsObjDep === INITIAL_SEARCH_PARAMS_REPLACE
        ? initialParamsObj
        : searchParamsUi.getParamsObj(),
    [paramsObjDep],
  );

  useEffect(() => {
    if (initialParamsString) {
      history.replace(initialParamsString, {
        from: INITIAL_SEARCH_PARAMS_REPLACE,
      });
    }

    return () => {
      searchParamsUi?.revoke?.();
    };
  }, []);

  useEffect(() => {
    setUiControlledState(paramsObj);
  }, [paramsObj]);

  const isFilteringOnOpenVulns = paramsObj.status === 'open';
  const isFilteringOnIgnoredVulns = paramsObj.status === 'ignored';
  const getIsAnyFilterActive = (initial, changed) =>
    !!Object.keys(changed).length &&
    Object.keys(initial).some((k) => initial[k] != changed[k]);

  /* istanbul ignore next */
  const clearFilters =
    (exclude = []) =>
    () => {
      Object.keys(initialParams).forEach((initialParamKey) => {
        if (!exclude.includes(initialParamKey)) {
          searchParamsUi.set(initialParamKey, initialParams[initialParamKey]);
        }
      });
      searchParamsUi.updateSearchUrl();
    };

  return {
    search,
    searchParamsUi,
    history,
    paramsObj,
    handleServerSort,
    isFilteringOnOpenVulns,
    isFilteringOnIgnoredVulns,
    getIsAnyFilterActive,
    clearFilters,
  };
}

export function resetVulnTableOffset(searchParamsUi, { updatedKey }) {
  const resetOffsetParams = [
    'sort_by',
    'term',
    'severity',
    'status',
    'first_identified_date',
    'date_closed',
    'agent_last_check_in',
  ];

  if (resetOffsetParams.includes(updatedKey)) {
    searchParamsUi.set('offset', '0');
  }
}

export const handleOnSevChangeDebounced = (() => {
  let timeoutId: NodeJS.Timeout | undefined;

  return (values, setValue, search) => {
    clearTimeout(timeoutId);
    setValue(values);
    timeoutId = setTimeout(
      () => {
        search.updateSearchUrl();
      },
      // istanbul ignore next
      isTestEnv ? 0 : SEV_FILTER_DEBOUNCE_MS,
    );
  };
})();
