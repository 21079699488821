import { EnrollmentStatusFilters } from '../ADEListViewTable/ADEStatusFilter';

export function getEnrollmentStatusFilter(
  isAllDevices: boolean,
  enrollmentStatusFilter: EnrollmentStatusFilters,
  isCountQuery: boolean = false,
) {
  if (isCountQuery && !isAllDevices) return [];

  if (!isAllDevices) {
    return [
      {
        name: 'enrollmentStatus',
        operator: 'equals',
        value: '0',
      },
    ];
  }

  if (!enrollmentStatusFilter) {
    return [];
  }

  switch (enrollmentStatusFilter) {
    case EnrollmentStatusFilters.AWAITING:
      return [
        {
          name: 'enrollmentStatus',
          operator: 'equals',
          value: '0',
        },
      ];

    case EnrollmentStatusFilters.ENROLLED:
      return [
        {
          name: 'enrollmentStatus',
          operator: 'notEqual',
          value: '0',
        },
      ];
  }
}
