import { camelCase } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

export const Tooltip = ({ target, tooltipMessage, placement, children }) => {
  const childrenId = target || `${camelCase(tooltipMessage)}Tooltip`;
  return (
    <>
      <div id={childrenId}>{children}</div>
      <UncontrolledTooltip
        placement={placement || 'top'}
        delay={{ show: 400, hide: 0 }}
        className="cover-all-helper"
        innerClassName="custom-helper"
        target={childrenId}
      >
        {tooltipMessage}
      </UncontrolledTooltip>
    </>
  );
};

Tooltip.propTypes = {
  target: PropTypes.string,
  placement: PropTypes.string,
  tooltipMessage: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
