import SafariExtensionsService from './service/safari-extensions-service';

export default {
  id: null,
  name: '',
  selectedBlueprints: [],
  isAllBlueprints: false,
  isActive: true,
  supportsRules: true,
  rules: null,

  allExtensions: {
    isManaged: true,
    BundleId: '*',
    TeamId: '',
    State: SafariExtensionsService.stateTypes.ALLOWED,
    PrivateBrowsing: SafariExtensionsService.stateTypes.ALLOWED,
    AllowedDomains: [],
    DeniedDomains: [],
  },
  specificExtensions: {
    extensions: [],
  },
};
