import * as React from 'react';
import { ExportButton } from 'src/features/visibility/shared/components/export';
import type { ExportPrismViewRequestSchema } from '../../prism/types/prism.types';

interface DevicesExportButtonProps {
  viewName?: string | undefined;
  columns:
    | {
        category: string | undefined;
        name: string | undefined;
        visible: boolean | undefined;
        size: number | undefined;
      }[]
    | undefined;
  filters?: any;
  blueprintIds?: string[] | undefined;
  deviceFamilies?: string[] | undefined;
}

export const DevicesExportButton = ({
  viewName,
  columns,
  filters,
  blueprintIds,
  deviceFamilies,
}: DevicesExportButtonProps) => {
  const [exportType, setExportType] = React.useState<
    'current_view' | 'all_attributes'
  >('current_view');
  const filterEntry = filters;
  // istanbul ignore next
  const filter = React.useMemo(() => filterEntry || {}, [filterEntry]);
  return (
    <ExportButton
      title={getTitle(viewName)}
      exportTypeOptions={[
        { type: 'current_view', label: 'Current View' },
        { type: 'all_attributes', label: 'All Attributes' },
      ]}
      helpText={getHelpText(exportType)}
      onExport={() =>
        handleExportPayload({
          exportType,
          viewName,
          columns,
          filter,
          blueprintIds,
          deviceFamilies,
        })
      }
      exportType={exportType}
      setExportType={setExportType}
      exportDataType="views"
    />
  );
};

export const getTitle = (viewName: string | undefined) => {
  return `Export ${viewName ?? 'Devices'} to CSV`;
};

export const handleExportPayload = ({
  exportType,
  // sorting,
  columns,
  blueprintIds,
  deviceFamilies,
  filter,
  viewName,
}: {
  exportType: 'current_view' | 'all_attributes';
  // sorting: SortingState;
  columns:
    | {
        category: string | undefined;
        name: string | undefined;
        visible: boolean | undefined;
        size: number | undefined;
      }[]
    | undefined;
  blueprintIds: string[] | undefined;
  deviceFamilies: string[] | undefined;
  viewName?: string | undefined;
  filter: Record<string, unknown> | undefined;
}): ExportPrismViewRequestSchema => {
  const prefixedFilter = prefixFilterWithCategory(filter, 'device_information');

  const payload: ExportPrismViewRequestSchema = {
    blueprint_ids: blueprintIds ?? [],
    device_families: deviceFamilies ?? [],
    filter: prefixedFilter,
    columns:
      exportType === 'all_attributes'
        ? columns
        : columns?.filter((col) => col.visible),
    view_name: viewName ?? 'Devices',
    // TODO: revisit once we have support for sorting in views export
    // sort_by: getSortString(sorting),
  };

  return payload;
};

export const prefixFilterWithCategory = (
  filter: Record<string, unknown> | undefined,
  category: string,
) => {
  if (!filter || Object.keys(filter).length === 0) {
    return filter;
  }

  return Object.entries(filter).reduce(
    (acc, [key, value]) => {
      if (key.startsWith(`${category}.`)) {
        acc[key] = value;
      } else {
        acc[`${category}.${key}`] = value;
      }
      return acc;
    },
    {} as Record<string, unknown>,
  );
};

export const getHelpText = (exportType: string) => {
  if (exportType === 'current_view') {
    return (
      <>
        CSV will <strong>only</strong> include data that is currently visible in
        the table, and contain any applied filters.
      </>
    );
  }
  return (
    <>
      CSV will include <strong>all</strong> data for this category, including
      data that is not currently visible in the table.
    </>
  );
};
