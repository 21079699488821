import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow';
import type {
  Blueprint,
  Device,
  User,
} from '../ADEListViewTable/ADEListViewTableColumns';
import type { EnrollmentStatusFilters } from '../ADEListViewTable/ADEStatusFilter';

export interface ADEListViewStoreType {
  devices: Device[];
  selectAllDevices: boolean;
  selectedDevices: string[];
  tokenFilters: string[];
  enrollmentStatusFilter: string | undefined | null;
  isSyncing: boolean;
  visibleColumns: string[];
  searchText: string;
  setSearchText: (searchText: string) => void;
  setDevices: (devices: Device[]) => void;
  updateAssetTag: (devices: string[], assetTag: string) => void;
  updateBlueprint: (devices: string[], blueprint: Blueprint) => void;
  updateUser: (devices: string[], user: User | null) => void;
  setSelectAllDevices: (selectAllDevices: boolean) => void;
  setSelectedDevices: (devices: string[]) => void;
  updateTokenFilters: (tokenFilters: string[]) => void;
  updateEnrollmentStatusFilter: (
    enrollmentStatus: EnrollmentStatusFilters | null | undefined,
  ) => void;
  setIsSyncing: (isSyncing: boolean) => void;
  setVisibleColumns: (columns: string[]) => void;
}

export const ADEListViewStore = create<ADEListViewStoreType>()((set) => {
  const bulkUpdate = (devices: string[], update: Record<string, any>) =>
    set((state) => ({
      devices: state.devices.map((device: Device) => {
        let newDevice = { ...device };
        if (devices.includes(newDevice.id)) {
          newDevice = { ...newDevice, ...update };
        }
        return newDevice;
      }),
    }));

  function handleSetDevices(devices: Device[]) {
    set(() => ({ devices }));
  }

  const handleUpdateAssetTag = (devices: string[], assetTag: string) =>
    bulkUpdate(devices, { asset_tag: assetTag });

  const handleUpdateBlueprint = (devices: string[], blueprint: Blueprint) =>
    bulkUpdate(devices, { blueprint });

  const handleUpdateUser = (devices: string[], user: User | null) =>
    bulkUpdate(devices, { user });

  const handleSetSelectAllDevices = (selectAllDevices: boolean) =>
    set((state) => ({ selectedDevices: [], selectAllDevices }));

  const handleSetSelectedDevices = (selectedDevices: string[]) =>
    set((state) => ({ selectedDevices }));

  const handleUpdateTokenFilters = (tokenFilters: string[]) =>
    set((state) => ({
      tokenFilters,
    }));

  const handleUpdateEnrollmentStatusFilter = (
    enrollmentStatusFilter: EnrollmentStatusFilters | null | undefined,
  ) =>
    set((state) => ({
      enrollmentStatusFilter,
    }));

  const handleSetIsSyncing = (isSyncing: boolean) => set(() => ({ isSyncing }));

  const handleSetVisibleColumns = (visibleColumns: string[]) =>
    set(() => ({ visibleColumns }));

  const handleSetSearchText = (searchText: string) =>
    set(() => ({
      searchText,
    }));

  return {
    devices: [],
    tokenFilters: [],
    enrollmentStatusFilter: null,
    selectAllDevices: false,
    selectedDevices: [],
    isSyncing: false,
    visibleColumns: [],
    searchText: '',
    setSearchText: handleSetSearchText,
    setDevices: handleSetDevices,
    updateAssetTag: handleUpdateAssetTag,
    updateBlueprint: handleUpdateBlueprint,
    updateUser: handleUpdateUser,
    setSelectAllDevices: handleSetSelectAllDevices,
    setSelectedDevices: handleSetSelectedDevices,
    updateTokenFilters: handleUpdateTokenFilters,
    updateEnrollmentStatusFilter: handleUpdateEnrollmentStatusFilter,
    setIsSyncing: handleSetIsSyncing,
    setVisibleColumns: handleSetVisibleColumns,
  };
});

export function useADEListViewStore(
  handler: (state: ADEListViewStoreType) => any,
) {
  return ADEListViewStore(useShallow(handler));
}
