import * as React from 'react';

export function useBodyNoMargin() {
  React.useEffect(() => {
    document.body.classList.add('no-margin');
    return () => {
      document.body.classList.remove('no-margin');
    };
  }, []);
}
