import { Box, Flex, styled } from '@kandji-inc/nectar-ui';
import { Setting } from 'features/library-items/template';
import { i18n } from 'i18n';
import { useEffect, useState } from 'react';
import KandjiSupportLink from 'src/app/components/common/KandjiSupportLink';
import { LockScreenLexical } from './LockScreenLexical';
import OrientationSwitcher from './OrientationSwitcher';
import './MessageConfig.css';

const UpperText = styled(Flex, {
  marginTop: '20px',
  justifyContent: 'space-between',
});

const DeviceTypes = {
  IPAD_PORTRAIT: 'IPAD_PORTRAIT',
  IPAD_LANDSCAPE: 'IPAD_LANDSCAPE',
  IPHONE: 'IPHONE',
};

const deviceClassMap = {
  [DeviceTypes.IPHONE]: 'iphone-frame',
  [DeviceTypes.IPAD_PORTRAIT]: 'ipad-portrait-frame',
  [DeviceTypes.IPAD_LANDSCAPE]: 'ipad-landscape-frame',
};

const MessageConfig = ({ isDisabled = false }) => {
  const [editorContent, setEditorContent] = useState<string | null>(null);

  const handleEditorChange = (newEditorState: string) => {
    setEditorContent(newEditorState);
  };

  const [formattedText, setFormattedText] = useState('Your message here');

  const supportLink = (
    <KandjiSupportLink
      href="https://support.kandji.io/support/solutions/articles/72000636327"
      text={i18n.t('Learn more')}
      className="b-alink"
    />
  );

  const [orientation, setOrientation] = useState(DeviceTypes.IPHONE);

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('General')}</h3>
      </Setting.Header>
      <Setting.Rows>
        <p className="b-txt the-recommended">
          {i18n.t(
            'Enter a custom message that will appear on the Lock Screen of iPhone and iPad (and on the login screen of Shared iPad). You can use Kandji Global Variables by typing a dollar sign ($) to make the message dynamic and adapted to each device.',
          )}
          {supportLink}
        </p>
      </Setting.Rows>

      <Box
        id="Settings"
        style={{ padding: 'var(--b-gap2)', marginTop: '-20px' }}
      >
        <UpperText>
          <Box style={{ width: '50%' }}>
            <Box className="input-label">{i18n.t('Lock Screen message')}</Box>
            <LockScreenLexical handleEditorChange={handleEditorChange} />
          </Box>
        </UpperText>

        <Box id="Settings" className="settings-box">
          <Box>
            <br />
            <Box className="outer-preview-box">
              <Box className="inner-preview-box">
                <Box className="preview-text">{i18n.t('Preview')}</Box>
                <Box className="switcher-container">
                  <OrientationSwitcher
                    disabled={isDisabled}
                    orientation={orientation}
                    setOrientation={setOrientation}
                  />
                </Box>
                <Box className="device-container-lsm">
                  <Box className="device-wrapper">
                    <Box className="device-frame-container">
                      <Box className={deviceClassMap[orientation]}>
                        <Box className="lockscreen-text">
                          {i18n.t('Swipe up to open')}
                        </Box>
                        <Box className="lockscreen-message">
                          {formattedText}
                        </Box>
                        <Box className="swipe-bar" />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Setting.Card>
  );
};

export default MessageConfig;
