import {
  Button,
  Flex,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';

import { i18n } from 'src/i18n';
import { configurationsModelProperty } from '../../initial-state';
import ConfigurationCard from './configuration-card';
import { AirPrintSetting } from './configuration.constants';
import ConfigsContext from './configurations.context';

const Configurations = (props) => {
  const { configurations, isDisabled, update, validationDep } = props;
  const MAX_CONFIGS = 20;
  const configsIdentifiers = new Array(MAX_CONFIGS)
    .fill(0)
    .map((_, idx) => idx);

  const { refs, onInvalidate } = useInputsValidators(
    configsIdentifiers,
    update,
  );
  useRemoveValidationOnUnmount(configsIdentifiers, update);

  useEffect(() => {
    if (configurations) {
      configsIdentifiers.forEach((n) => {
        const curr = configurations[n];
        onInvalidate(n)(curr?.isInvalid);
      });
    }
  }, [configurations]);

  const onChangeInput = useCallback(
    (key, index, value, isInvalid) =>
      update(
        configurationsModelProperty,
        configurations.map((c, i) =>
          index === i
            ? {
                ...c,
                [key]: value,
                ...(isInvalid === undefined ? {} : { isInvalid }),
              }
            : c,
        ),
      ),
    [configurations, update],
  );

  const onChangeNumber = useCallback(
    (key, index, value) =>
      update(
        configurationsModelProperty,
        configurations.map((c, i) =>
          index === i ? { ...c, [key]: parseInt(value, 10) } : c,
        ),
      ),
    [configurations, update],
  );

  const onChangeCheckbox = useCallback(
    (key, index) =>
      update(
        configurationsModelProperty,
        configurations.map((c, i) =>
          index === i ? { ...c, [key]: !c[key] } : c,
        ),
      ),
    [configurations, update],
  );

  const onRemoveConfig = useCallback(
    (index) => {
      update(
        configurationsModelProperty,
        configurations.filter((_, i) => i !== index),
      );
    },
    [configurations, update],
  );

  return (
    <ConfigsContext.Provider
      value={{
        isDisabled,
        refs,
      }}
    >
      {configurations.map((configObj, index) => (
        <ConfigurationCard
          idx={index}
          config={configObj}
          onChangeInput={onChangeInput}
          onChangeNumber={onChangeNumber}
          onChangeCheckbox={onChangeCheckbox}
          onRemove={onRemoveConfig}
          canRemove={configurations.length > 1}
          validationDep={validationDep}
        />
      ))}
      {!isDisabled && (
        <Flex justify="flex-end" align="center">
          <Button
            disabled={isDisabled || configurations.length >= MAX_CONFIGS}
            icon="plus"
            iconPlacement="right"
            onClick={() =>
              update(configurationsModelProperty, [
                ...configurations,
                AirPrintSetting,
              ])
            }
          >
            {i18n.t('Add Printer')}
          </Button>
        </Flex>
      )}
    </ConfigsContext.Provider>
  );
};

Configurations.propTypes = {
  /**
   * List of Configuration objects
   */
  configurations: PropTypes.arrayOf(
    PropTypes.shape({
      ForceTLS: PropTypes.bool,
      IPAddress: PropTypes.string,
      Port: PropTypes.number,
      PrinterName: PropTypes.string,
      ResourcePath: PropTypes.string,
    }),
  ).isRequired,
  update: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  validationDep: PropTypes.number.isRequired,
};

export default Configurations;
