/* istanbul ignore file */
import EnergySaverService from './service/energy-saver.service';

export default {
  id: null,
  name: '',
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  isActive: true,

  supportsRules: true,

  general: {
    SleepDisabled: false,
    DestroyFVKeyOnStandby: false,
  },

  desktops: {
    'Display Sleep Timer': 18,
    isTurnDisplayOff: false,

    'System Sleep Timer': EnergySaverService.computerSleep.ALLOW,
    isSystemSleepTimer: false,

    'Disk Sleep Timer': EnergySaverService.diskSleep.ENABLE,
    isDiskSleepTimer: false,

    'Wake on LAN': EnergySaverService.wakeLan.ENABLE,
    isWakeOnLan: false,

    'Automatic Restart On Power Loss': EnergySaverService.restartOptions.ENABLE,
    isRestartOnPowerLoss: false,

    DarkWakeBackgroundTasks: EnergySaverService.powerNap.ENABLE,
    isPowerNap: false,
  },

  battery: {
    'Display Sleep Timer': 2,
    isTurnDisplayOff: false,

    'Disk Sleep Timer': EnergySaverService.diskSleep.ENABLE,
    isDiskSleepTimer: false,

    ReduceBrightness: EnergySaverService.reduceBrightness.ENABLE,
    isReduceBrightness: false,

    DarkWakeBackgroundTasks: EnergySaverService.powerNap.ENABLE,
    isPowerNap: false,
  },

  powerAdapter: {
    'Display Sleep Timer': 18,
    isTurnDisplayOff: false,

    'System Sleep Timer': EnergySaverService.computerSleep.ALLOW,
    isSystemSleepTimer: false,

    'Disk Sleep Timer': EnergySaverService.diskSleep.ENABLE,
    isDiskSleepTimer: false,

    'Wake on LAN': EnergySaverService.wakeLan.ENABLE,
    isWakeOnLan: false,

    'Automatic Restart On Power Loss': EnergySaverService.restartOptions.ENABLE,
    isRestartOnPowerLoss: false,

    DarkWakeBackgroundTasks: EnergySaverService.powerNap.ENABLE,
    isPowerNap: false,
  },

  schedule: {
    RepeatingPowerOn: {
      eventtype: EnergySaverService.wakePower.WAKEPOWERON,
      time: 0,
      weekdays: Object.values(EnergySaverService.weekDays),
    },
    isRepeatingPowerOn: false,

    RepeatingPowerOff: {
      eventtype: EnergySaverService.sleepPower.SLEEP,
      time: 0,
      weekdays: Object.values(EnergySaverService.weekDays),
    },
    isRepeatingPowerOff: false,
  },
};
