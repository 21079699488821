import {
  Banner,
  Breadcrumb as Crumb,
  newc,
  setClass,
} from '@kandji-inc/bumblebee';
import {
  array,
  bool,
  element,
  node,
  object,
  oneOfType,
  string,
} from 'prop-types';
import React from 'react';
import './scss/library.scss';

import Actions from '../actions/actions';

const Breadcrumb = (props: any) => {
  const { style, className, crumbs, baseUrl, component: Component } = props;

  if (Component) {
    return <Component {...props} />;
  }

  return (
    <div style={style} className={setClass(className, 'b-library__bread')}>
      <Crumb crumbs={crumbs} baseUrl={baseUrl} />
    </div>
  );
};

Breadcrumb.propTypes = {
  style: object,
  className: string,
  crumbs: array,
  baseUrl: string,
  component: oneOfType([node, element]),
};

Breadcrumb.defaultProps = {
  style: {},
  className: null,
  crumbs: [],
  baseUrl: '',
  component: null,
};

const Tabs = (props: any) => newc(props, 'b-library__tabs');
const Settings = (props: any) => newc(props, 'b-library__settings');
const Library = (props: any) => newc(props, 'b-library');
const Summary = (props: any) => newc(props, 'b-library-summary');

const InactiveBanner = (props: any) => {
  const { style, className, children, isVisible } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <div style={style} className={className}>
      <Banner theme="info-strong" kind="block" className="b-mb">
        <span>{children}</span>
      </Banner>
    </div>
  );
};

InactiveBanner.propTypes = {
  style: object,
  className: string,
  children: oneOfType([node, element]),
  isVisible: bool.isRequired,
};

InactiveBanner.defaultProps = {
  style: {},
  className: null,
  children: null,
};

Library.Breadcrumb = Breadcrumb;
Library.InactiveBanner = InactiveBanner;
Library.Summary = Summary;
Library.Tabs = Tabs;
Library.Settings = Settings;
Library.Actions = Actions;

export default Library;
export { Breadcrumb, InactiveBanner, Summary, Tabs, Settings, Actions };
