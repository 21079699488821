const findOption = (value, options, isMultiSelect = false) => {
  if (isMultiSelect) {
    return options.filter((v) => value.includes(v.value));
  }
  return options.find((v) => v.value === value);
};

// Creates a list of objects from an object map that is best suited
// for Select input components after some additional transformations.
const getOptionsFromObject = (object = {}) =>
  Object.entries(object).map(([value, data]) => ({ value, data }));

/**
 * Filters the options provided by the facet data to match what actually pertains to a library item.
 * For example, we should remove device families from the options list that the library item does not support.
 * @param {array} options - an array of objects representing options available in the multi select
 *                          dropdown each object has at minimum a label and a value.
 * @param {*} supportedDeviceFamilies - a string list of device families the library item supports.
 * @returns a filtered down list of options.
 */
const filterOptions = (options, supportedDeviceFamilies) => {
  let filteredOptions = options;

  // If an option has a device_family attribute, we filter out the options
  // that do not pertain to the current supported device families
  if (options?.some((option) => option.device_family)) {
    filteredOptions = options.filter((option) =>
      supportedDeviceFamilies.includes(option.device_family),
    );
  }

  return filteredOptions;
};

export { findOption, getOptionsFromObject, filterOptions };
