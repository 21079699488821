/* istanbul ignore file */
import { i18n } from 'i18n';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import FeatureFlags from '../config/feature-flags';

const mapStateToProps = ({ auth0, account }) => ({
  configLocale: auth0?.locale,
  tenantLocale: account?.company?.locale,
  userLocale: account?.user?.locale,
});

function withLocale(BaseComponent) {
  function LocaleWrapper({ configLocale, tenantLocale, userLocale }) {
    // set provider
    useEffect(() => {
      const i18nProvider =
        localStorage.getItem('i18n-provider') ||
        FeatureFlags.getFlag('i18n-provider', i18n.ProviderNames.noop);
      i18n.setProvider(i18nProvider);
    }, []);

    // set locale
    useEffect(() => {
      const initializeI18nLocale = async () => {
        // default locale for tenant is en
        const i18nLocale =
          localStorage.getItem('i18n-locale') ||
          userLocale ||
          tenantLocale ||
          configLocale;
        await i18n.setLocale(i18nLocale);
        document.documentElement.lang = i18nLocale;
      };

      // we only update locale if we find an inconsistency or change
      if (configLocale === tenantLocale && configLocale === userLocale) {
        return;
      }

      initializeI18nLocale();
    }, [configLocale, tenantLocale, userLocale]);

    return <BaseComponent />;
  }
  return connect(mapStateToProps)(LocaleWrapper);
}

export default withLocale;
