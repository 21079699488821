import { createColumnHelper } from '@tanstack/react-table';
import { i18n } from 'src/i18n';
/* istanbul ignore file */
import type { z } from 'zod';

import {
  NULL_VALUE_N_DASH,
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  extractFilterableColumns,
  getFilteredDisabled as filterDisabled,
  getCodeSnippetCell,
} from '../column-utils';
import { getGlobalColumns } from './globalColumns';

export const getDesktopAndScreensaverColumns = (schemas) => {
  const globalSchema = schemas.find((schema) => schema.uri === 'globals');
  const desktopAndScreensaverSchema = schemas.find(
    (schema) => schema.uri === 'desktop_and_screensaver',
  );

  if (!desktopAndScreensaverSchema || !globalSchema) {
    return { columnDefs: [], filterableColumns: [] };
  }

  const combinedProperties = {
    ...globalSchema.schema.properties,
    ...desktopAndScreensaverSchema.schema.properties,
  };

  const desktopAndScreensaverColumnHelper =
    createColumnHelper<z.infer<typeof combinedProperties>>();

  const globalColumns = getGlobalColumns(
    desktopAndScreensaverColumnHelper,
    combinedProperties,
  );

  const hotCornerOptionFilterOptions = [
    { value: 'Mission Control', label: i18n.t('Mission Control') },
    { value: 'Application Windows', label: i18n.t('Application Windows') },
    { value: 'Desktop', label: i18n.t('Desktop') },
    { value: 'Notification Center', label: i18n.t('Notification Center') },
    { value: 'Launchpad', label: i18n.t('Launchpad') },
    { value: 'Quick Note', label: i18n.t('Quick Note') },
    { value: 'Start Screen Saver', label: i18n.t('Start Screen Saver') },
    { value: 'Put Display to Sleep', label: i18n.t('Put Display to Sleep') },
    { value: 'Lock Screen', label: i18n.t('Lock Screen') },
    { value: 'Off', label: i18n.t('Off') },
  ];

  const columnDefs = [
    ...globalColumns.startColumnDefs,

    desktopAndScreensaverColumnHelper.accessor((row) => row.user_account, {
      id: 'user_account',
      meta: {
        displayName: combinedProperties.user_account.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    desktopAndScreensaverColumnHelper.accessor(
      (row) => row.screensaver_interval,
      {
        id: 'screensaver_interval',
        cell: (info) => {
          const value = info.getValue();
          if (value === null) {
            return NULL_VALUE_N_DASH;
          }
          const num = Number(value);
          if (!Number.isNaN(num)) {
            const minutes = Math.floor(num / 60);
            if (minutes === 0) {
              return i18n.t(`{num} seconds`, { num });
            }
            return i18n.t(`{minutes} minutes`, { minutes });
          }
          return '';
        },
        meta: {
          displayName: combinedProperties.screensaver_interval.title,
          filterType: 'time',
          filterIcon: 'clock',
          filterDisabled,
        },
      },
    ),

    desktopAndScreensaverColumnHelper.accessor((row) => row.screensaver_path, {
      id: 'screensaver_path',
      cell: getCodeSnippetCell,
      meta: {
        displayName: combinedProperties.screensaver_path.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    desktopAndScreensaverColumnHelper.accessor((row) => row.show_with_clock, {
      id: 'show_with_clock',
      cell: YesNoCell,
      meta: {
        displayName: combinedProperties.show_with_clock.title,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    desktopAndScreensaverColumnHelper.accessor(
      (row) => row.uses_random_screensaver,
      {
        id: 'uses_random_screensaver',
        cell: YesNoCell,
        meta: {
          displayName: combinedProperties.uses_random_screensaver.title,
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    desktopAndScreensaverColumnHelper.accessor(
      (row) => row.top_left_hot_corner_option,
      {
        id: 'top_left_hot_corner_option',
        meta: {
          displayName: combinedProperties.top_left_hot_corner_option.title,
          filterType: 'enum',
          filterIcon: 'list-check',
          filterDisabled,
          defaultHidden: true,
          filterOptions: hotCornerOptionFilterOptions,
        },
      },
    ),

    desktopAndScreensaverColumnHelper.accessor(
      (row) => row.top_left_hot_corner_modifier,
      {
        id: 'top_left_hot_corner_modifier',
        meta: {
          displayName: combinedProperties.top_left_hot_corner_modifier.title,
          defaultHidden: true,
        },
      },
    ),

    desktopAndScreensaverColumnHelper.accessor(
      (row) => row.top_right_hot_corner_option,
      {
        id: 'top_right_hot_corner_option',
        meta: {
          displayName: combinedProperties.top_right_hot_corner_option.title,
          filterType: 'enum',
          filterIcon: 'list-check',
          filterDisabled,
          defaultHidden: true,
          filterOptions: hotCornerOptionFilterOptions,
        },
      },
    ),

    desktopAndScreensaverColumnHelper.accessor(
      (row) => row.top_right_hot_corner_modifier,
      {
        id: 'top_right_hot_corner_modifier',
        meta: {
          displayName: combinedProperties.top_right_hot_corner_modifier.title,
          defaultHidden: true,
        },
      },
    ),

    desktopAndScreensaverColumnHelper.accessor(
      (row) => row.bottom_left_hot_corner_option,
      {
        id: 'bottom_left_hot_corner_option',
        meta: {
          displayName: combinedProperties.bottom_left_hot_corner_option.title,
          filterType: 'enum',
          filterIcon: 'list-check',
          filterDisabled,
          defaultHidden: true,
          filterOptions: hotCornerOptionFilterOptions,
        },
      },
    ),

    desktopAndScreensaverColumnHelper.accessor(
      (row) => row.bottom_left_hot_corner_modifier,
      {
        id: 'bottom_left_hot_corner_modifier',
        meta: {
          displayName: combinedProperties.bottom_left_hot_corner_modifier.title,
          defaultHidden: true,
        },
      },
    ),

    desktopAndScreensaverColumnHelper.accessor(
      (row) => row.bottom_right_hot_corner_option,
      {
        id: 'bottom_right_hot_corner_option',
        meta: {
          displayName: combinedProperties.bottom_right_hot_corner_option.title,
          filterType: 'enum',
          filterIcon: 'list-check',
          filterDisabled,
          defaultHidden: true,
          filterOptions: hotCornerOptionFilterOptions,
        },
      },
    ),

    desktopAndScreensaverColumnHelper.accessor(
      (row) => row.bottom_right_hot_corner_modifier,
      {
        id: 'bottom_right_hot_corner_modifier',
        meta: {
          displayName:
            combinedProperties.bottom_right_hot_corner_modifier.title,
          defaultHidden: true,
        },
      },
    ),

    ...globalColumns.endColumnDefs,
  ];

  const filterableColumns = extractFilterableColumns(columnDefs);

  return { columnDefs, filterableColumns };
};
