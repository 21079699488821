import { Checkbox, Toaster as toaster } from '@kandji-inc/bumblebee';

import {
  Banner,
  Button,
  Dialog,
  Flex,
  Select,
  Text,
  TextField,
} from '@kandji-inc/nectar-ui';
import React, { useEffect, useState } from 'react';
import { isMacOSDevice, regExpMapper } from 'src/app/components/common/helpers';
import {
  eraseDevice,
  getReturnToServiceProfiles,
} from 'src/app/components/computer/api';

import { i18n } from 'i18n';
import featureFlags from 'src/config/feature-flags';

const ComputerErase = (props) => {
  const { info, name, onHide } = props;
  const { computerId, deviceFamily, enrollmentType, getComputer, osVersion } =
    info;
  const isMacOS = isMacOSDevice(deviceFamily);
  const confirmationTextRequirement = 'ERASE';
  const noReturnToServiceProfileOption = {
    id: null,
    label: 'None',
  };

  const [isLoading, setIsLoading] = useState(true);
  const [confirmationText, setConfirmationText] = useState();
  const [isReturnToServiceEnabled, setIsReturnToServiceEnabled] =
    useState(false);
  const [deviceSupportsReturnToService, setDeviceSupportsReturnToService] =
    useState(false);
  const [returnToServiceProfileOptions, setReturnToServiceProfileOptions] =
    useState([]);
  const [returnToServiceProfile, setReturnToServiceProfile] = useState(null);

  useEffect(() => {
    getHasReturnToService();
  }, []);

  const handleOnUseReturnToServiceChange = () => {
    setIsReturnToServiceEnabled((prev) => !prev);
    setReturnToServiceProfile(null);
  };

  const handleOnConfirmationTextChange = (e) =>
    setConfirmationText(e.target.value);

  const getHasReturnToService = () => {
    const osVersionTestForReturnToService = {
      iPhone: '17.0+',
      iPad: '17.0+',
      AppleTV: '18.0+',
    };

    const isIphoneOrIpadOrTV = ['iPhone', 'iPad', 'iPod', 'AppleTV'].includes(
      deviceFamily,
    );
    const isADE = enrollmentType === 4;
    const osVersionHasReturnToService =
      regExpMapper[osVersionTestForReturnToService[deviceFamily]]?.test(
        osVersion,
      );

    const supportsRTS =
      isIphoneOrIpadOrTV && isADE && osVersionHasReturnToService;

    // If the device supports Return to Service, get the Return to Service profiles to select from
    if (supportsRTS) {
      getReturnToServiceProfiles(computerId)
        .then((data) => {
          setDeviceSupportsReturnToService(supportsRTS);
          setReturnToServiceProfileOptions(
            data.map((profile) => ({
              label: profile.name,
              value: profile.id,
            })),
          );
          setIsLoading(false);
        })
        .catch(() => {
          setDeviceSupportsReturnToService(supportsRTS);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  const onEraseDevice = () => {
    setIsLoading(true);
    eraseDevice(computerId, isReturnToServiceEnabled, returnToServiceProfile)
      .then(() => {
        onHide();
        getComputer();
        setIsLoading(false);
        toaster(`The device ${name} has been erased.`);
      })
      .catch(() => {
        onHide();
        setIsLoading(false);
        toaster(i18n.common.error());
      });
  };

  const content = (
    <Flex flow="column" gap="lg">
      <Banner
        theme="warning"
        text={`The device ${name} will be erased the next time it is connected to the internet.`}
      />

      {isMacOS ? (
        <ul>
          <li>
            <Text>
              Intel-based Mac computers running macOS 10.15 or earlier, or those
              without the Apple T2 Security Chip running any version of macOS,
              will have their data fully erased and a PIN will be set which will
              be required to use the Mac again. The PIN will be available on the
              device record.
            </Text>
          </li>
          <li>
            <Text>
              {`Mac computers with the Apple T2 Security Chip or Apple silicon
              running macOS 11 or later will not have a PIN set and will be
              fully erased. If they're running macOS 12 or later, an Erase All
              Content and Settings will be attempted before falling back to a
              full erase.`}
            </Text>
          </li>
        </ul>
      ) : (
        <Text>
          The erase device command will “Erase all Content and Settings” on the
          device. This will erase all user data and applications from the device
          while leaving the operating system intact.
        </Text>
      )}

      {deviceSupportsReturnToService && (
        <Flex
          flow="column"
          alignItems="start"
          gap="md"
          css={{ paddingTop: '$4', borderTop: '1px solid $neutral20' }}
        >
          <Checkbox
            label="Use Return to Service"
            checked={isReturnToServiceEnabled}
            onChange={handleOnUseReturnToServiceChange}
          />
          <Select
            disabled={isLoading || !isReturnToServiceEnabled}
            placeholder="Select a Wi-Fi Profile for re-enrollment (optional)"
            options={[
              // Only provide the 'deselect' option if an option is selected and there are other options to choose from
              ...(returnToServiceProfileOptions.length && returnToServiceProfile
                ? [noReturnToServiceProfileOption]
                : []),
              ...returnToServiceProfileOptions,
            ]}
            // `key` is necessary to reset select when selecting 'None' or toggling Use Return to Service
            key={returnToServiceProfile}
            value={returnToServiceProfile}
            onChange={setReturnToServiceProfile}
          />
          <Text variant="subtle">
            Using Return to Service will cause the device to automatically
            advance through Setup Assistant and re-enroll without user
            intervention after it has been erased. A Wi-Fi Profile should be
            selected from your Library unless the device is connected to
            Ethernet.
          </Text>
        </Flex>
      )}

      <Flex
        flow="column"
        gap="md"
        css={{ paddingTop: '$4', borderTop: '1px solid $neutral20' }}
      >
        <Text>
          Type{' '}
          <Text css={{ fontWeight: '$medium', display: 'inline-block' }}>
            ERASE
          </Text>{' '}
          to erase the device.
        </Text>
        <TextField
          placeholder={confirmationTextRequirement}
          aria-label="erase-confirmation"
          onChange={handleOnConfirmationTextChange}
        />
        <Text variant="subtle" size="1">
          I understand this action will erase the device.
        </Text>
      </Flex>
    </Flex>
  );

  const footer = (
    <Flex gap="md" justifyContent="end" wrap="wrap">
      <Button variant="subtle" onClick={onHide}>
        Cancel
      </Button>
      <Button
        variant="danger"
        loading={isLoading}
        disabled={confirmationText !== confirmationTextRequirement}
        onClick={onEraseDevice}
      >
        Erase Device
      </Button>
    </Flex>
  );

  return (
    <Dialog
      isOpen
      closeOnEscape
      closeOnOutsideClick
      onOpenChange={onHide}
      title="Erase device"
      content={content}
      footer={footer}
      css={{ zIndex: 2000 }}
    />
  );
};

export default ComputerErase;
