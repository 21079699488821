// istanbul ignore file
import { datadogLogs } from '@datadog/browser-logs';
import { ZodError } from 'zod';

const IS_PROD = process.env['NODE_ENV'] === 'production';
export const logError = (error: unknown, service: string) => {
  if (IS_PROD && error instanceof ZodError) {
    datadogLogs.logger.error(
      `Zod error in ${service}`,
      {
        error: error.format(),
      },
      error,
    );
  } else if (error instanceof ZodError) {
    console.error(`Zod error in ${service}`, error.format());
  } else {
    console.error(error);
  }
};
