import * as React from 'react';
import type { IpaAppModel } from '../in-house-apps.types';

export function validateFileInput(
  model: IpaAppModel,
  setIsSaveEnabled: (enabled: boolean) => void,
  isSaveEnabled: boolean,
  isEditing: boolean,
) {
  const { file, appId } = model.installDetails.ipaFile;
  const isNotValidFile = file == null || appId == null;

  if (isNotValidFile && isSaveEnabled) {
    setIsSaveEnabled(false);
  } else if (!isEditing && !isSaveEnabled) {
    setIsSaveEnabled(true);
  }
}

/* istanbul ignore next */
export function useValidateFileInput(
  model: IpaAppModel,
  setIsSaveEnabled: (enabled: boolean) => void,
  isSaveEnabled: boolean,
  isEditing: boolean,
) {
  React.useEffect(
    () => validateFileInput(model, setIsSaveEnabled, isSaveEnabled, isEditing),
    [model, setIsSaveEnabled, isSaveEnabled, isEditing],
  );
}
