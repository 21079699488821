import CustomScriptService from './service/custom-script-service';

const initialState = {
  id: '',
  name: '',
  iconFile: null,
  iconSrc: '',
  isActive: true,
  blueprints: [],
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  type: 'custom-script',
  supportsRules: true,

  executionFrequency: {
    type: CustomScriptService.executionFrequencyTypes.ONCE, // item.execution_frequency
    invalidationsMap: {},
  },

  selfService: {
    isEnabled: false, // false => item.data.self_service_data === undefined
    category: '', // item.data.self_service_data.category
    isRecommended: false, // item.data.self_service_data.isRecommended
    displayName: null, // item.data.self_service_data.displayName
    shortDescription: null, // item.data.self_service_data.description
    longDescription: null, // item.data.self_service_data.bodyText
    isRequireRead: false, // item.data.self_service_data.mustViewAgreement
    iconUrl: null, // item.data.self_service_data.iconUrl
    iconFile: null,
    installBtnText: null,
    removeBtnText: '', // ??
    size: '', // ??
    version: '', // ??
  },

  scriptDetails: {
    auditScript: '', // item.script
    remediationScript: '', // item.remediation_script
    restart: false, // item.restart
  },
};

export default initialState;
