import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../_reducers/index';
import history from '../router/history';

export const configure = (initialState = {}) => {
  const middlewares = [thunkMiddleware, routerMiddleware(history)];
  const middlewareEnhancer = applyMiddleware(...middlewares);
  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(rootReducer, initialState, composedEnhancers);

  if (module.hot) {
    /* istanbul ignore next -- only for developer experience */
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../_reducers', () => {
      const nextRootReducer = require('../_reducers/index').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};
