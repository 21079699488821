import {
  useAfterMount,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import { useEffect, useLayoutEffect, useState } from 'react';

export const useIsChanged = (value, reset) => {
  const [changed, setChanged] = useState(false);
  const afterMount = useAfterMount();
  useEffect(() => {
    if (afterMount) {
      setChanged(true);
    }
  }, [value]);

  useLayoutEffect(() => {
    setChanged(false);
  }, [reset]);

  return changed;
};

export const useValidate = ({ error, onInvalidate, validationDep, reset }) => {
  useEffect(() => {
    onInvalidate(error);
  }, [error]);
  useEffect(
    () =>
      // when unmounted remove validations
      () => {
        onInvalidate(false);
      },
    [],
  );
  const canShowError = useIsChanged(validationDep, reset);
  return canShowError;
};

export const useDrawerValidate = ({
  fieldsToValidate = [],
  isVisible,
  update,
  drawerKey,
  validationDep,
}) => {
  const { refs, onInvalidate, invalidations } = useInputsValidators(
    [drawerKey, ...fieldsToValidate],
    update,
  );
  const drawerRef = refs[drawerKey];

  const failedValidation = fieldsToValidate.find(
    (field) => !!invalidations[field],
  );

  const canShowDrawerError = useValidate({
    error: !isVisible && failedValidation && invalidations[failedValidation],
    onInvalidate: onInvalidate(drawerKey),
    validationDep,
    reset: isVisible,
  });

  // remove all invalidations on unmount
  useRemoveValidationOnUnmount([drawerKey, ...fieldsToValidate], update);

  return {
    onInvalidate,
    refs,
    invalidations,
    drawerRef,
    drawerValidationError: invalidations[drawerKey],
    isValidationFailed: !!invalidations[drawerKey],
    canShowDrawerError,
  };
};
