import { TextInput, useInputsValidators } from '@kandji-inc/bumblebee';
import React, { useEffect } from 'react';

import { Setting } from 'features/library-items/template';
import { i18n } from 'src/i18n';

const PrinterNameRow = ({ setting, update, isDisabled, isSubmitted }) => {
  const fieldsToValidate = ['printerName'];
  const { refs, onInvalidate } = useInputsValidators(fieldsToValidate, update);
  const validator = (v) => [
    {
      message: i18n.t('Required'),
      invalid: () => !v,
      trigger: ['onBlur', isSubmitted && 'onSubmit'],
    },
    {
      message: i18n.t('Invalid Format'),
      invalid: () => !v.match(/^[\w-]+$/),
      trigger: ['onBlur', isSubmitted && 'onSubmit'],
    },
  ];
  useEffect(() => {
    const isInvalid = validator(setting.printerName)
      .map(({ invalid }) => invalid())
      .some((v) => v);

    if (isInvalid) {
      onInvalidate(0)(true);
    }
  }, []);

  return (
    <Setting.Row>
      <div>
        <p className="b-txt">{i18n.t('Printer Name')}</p>
      </div>
      <Setting.Helpers className="b-txt-light">
        {i18n.t(
          'Enter the CUPS printer name, special characters and spaces cannot be used.',
        )}
      </Setting.Helpers>
      <div ref={refs[0]}>
        <TextInput
          value={setting.printerName}
          onChange={(e) => update('printerName', e.target.value)}
          disabled={isDisabled}
          placeholder={i18n.t('Marketing_Printer')}
          compact
          onInvalidate={onInvalidate(0)}
          validator={validator}
        />
      </div>
    </Setting.Row>
  );
};

export default PrinterNameRow;
