import { colors } from 'app/common/constants';
import { truncate } from 'app/common/style-utils';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  border-bottom: 2px solid ${colors['grey-250']};
  &:before {
    position: absolute;
    right: 0;
    bottom: 0;
    font-family: 'SSJunior', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    text-decoration: none;
    text-rendering: optimizeLegibility;
    white-space: nowrap;
    font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    content: '✎';
    color: ${colors['grey-400']};
  }
`;

const Input = styled.input`
  position: relative;
  ${truncate('calc(100% - 2rem)')};
  z-index: 2;
  border: none;
  width: calc(100% - 2rem);
  text-align: ${(props) => props.textAlign};
  font-size: 16px;
  padding-left: 2rem;
  transition: 0.3s ease;
  background-color: transparent;
  &:focus {
    outline: none;
  }
`;

function AssetTagInput({ initialTag, onBlur }) {
  const [assetTag, changeAssetTag] = useState(initialTag);

  function onChange(e) {
    changeAssetTag(e.target.value);
  }

  return (
    <Wrapper>
      <Input
        name="assetTag"
        placeholder="Asset Tag"
        value={assetTag}
        onChange={onChange}
        onBlur={() => onBlur(assetTag)}
      />
    </Wrapper>
  );
}

AssetTagInput.propTypes = {
  initialTag: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
};

export default AssetTagInput;
