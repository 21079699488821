import { api } from 'app/api/base';
import { i18n } from 'src/i18n';

const LATEST_PUBLIC_OS_VERSION_OPTION = () => ({
  value: 'latest_public',
  label: i18n.t('Latest public release'),
});

const initialMinimumOsVersions = () => ({
  mac: [{ value: '14.0', label: i18n.t('macOS 14.0') }],
  iphone: [LATEST_PUBLIC_OS_VERSION_OPTION()],
  ipad: [LATEST_PUBLIC_OS_VERSION_OPTION()],
});

const transformToOptions = (data, osType, osLabel) =>
  // OS versions are already in descending order (by BE)
  (data[osType] || []).map((i) => ({ value: i, label: `${osLabel} ${i}` }));

const getAdeMinimumOsVersions = async () =>
  api('apple-software/min-ade-versions')
    .get()
    .then(({ data }) => ({
      mac: transformToOptions(data, 'macOS', i18n.t('macOS')),
      iphone: [LATEST_PUBLIC_OS_VERSION_OPTION()].concat(
        transformToOptions(data, 'iOS', i18n.t('iOS')),
      ),
      ipad: [LATEST_PUBLIC_OS_VERSION_OPTION()].concat(
        transformToOptions(data, 'iPadOS', i18n.t('iPadOS')),
      ),
    }));

export {
  LATEST_PUBLIC_OS_VERSION_OPTION,
  initialMinimumOsVersions,
  getAdeMinimumOsVersions,
};
