import lowerCase from 'lodash/lowerCase';
import replace from 'lodash/replace';
import React from 'react';

export const renderTableActions = (actionButtons) => (
  // todo: refactor
  <div className="actions">
    {actionButtons.map((button, idx) => (
      <div style={{ width: '41px' }} key={idx}>
        {button}
      </div>
    ))}
  </div>
);

export function dataTestId(identifier) {
  return replace(lowerCase(identifier), /\s+/g, '-');
}
