import {
  Button,
  sidePanel as SidePanel,
  TextInput,
  Toggle,
  setClass,
  useInvalidations,
  useShadowOnScroll,
} from '@kandji-inc/bumblebee';
import useRefs from 'features/library-items/common/self-service-card/use-refs';
import React, { useState, useEffect } from 'react';
import { i18n } from 'src/i18n';
import {
  mapInvalidIndexToFieldWithRefs,
  requiredTextValidator,
} from '../../input-validations';

const Drawer = (props) => {
  const { isVisible, setIsVisible, setting, update, onClose } = props;
  const { isShowHeaderShadow, setBody } = useShadowOnScroll();
  const refs = useRefs(1);
  const { invalidations, onInvalidate } = useInvalidations({ inputs: 1 });
  const invalidationsMap = mapInvalidIndexToFieldWithRefs(
    invalidations,
    ['helpHeader'],
    refs,
  );
  const [toggleValidation, setToggleValidation] = useState();

  const onCancel = () => {
    setIsVisible(false);
    onClose();
  };

  const onDone = () => {
    const invalids = Object.values(setting.invalidationsMap).filter(
      (d) => d.isInvalid,
    );
    if (!invalids.length) {
      setIsVisible(false);
    } else {
      setToggleValidation((prev) => !prev);
      const withRef = invalids.find((d) => d.ref);
      withRef?.ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  };

  useEffect(() => {
    update('invalidationsMap', (p) => ({ ...p, ...invalidationsMap }));
  }, [...invalidations]);

  return (
    <SidePanel isVisible={isVisible}>
      <div className="b-side-panel-layout">
        <div
          className={setClass(
            'b-side-panel-layout__header',
            isShowHeaderShadow && '--with-shadow',
          )}
        >
          <h2 className="b-h2">{i18n.t(`Customize Help window`)}</h2>
        </div>
        <div
          className="b-side-panel-layout__body --k-ss2-style-body hubspot-buffer-bottom"
          ref={setBody}
        >
          <div className="k-ss2-style-drawer-section">
            <h3 className="b-h3">{i18n.t(`Support tab`)}</h3>
            <div className="k-ss2-style-drawer-setting">
              <TextInput
                label={i18n.t('Header')}
                value={setting.header}
                onChange={(e) => update('header', e.target.value)}
                validator={requiredTextValidator(
                  [toggleValidation],
                  i18n.t('Required'),
                )}
                onInvalidate={onInvalidate(0)}
                maxLength={30}
                fieldsGrid
              />
            </div>
            <div className="k-ss2-style-drawer-setting">
              <TextInput
                style={{ height: '160px' }}
                label={
                  <span>
                    {i18n.ut(
                      `Body text <span class="b-txt-light">(optional)</span>`,
                    )}
                  </span>
                }
                value={setting.subheader}
                onChange={(e) => update('subheader', e.target.value)}
                maxLength={250}
                fieldsGrid
                textArea
              />
            </div>
          </div>

          <div className="k-ss2-style-drawer-section">
            <h3 className="b-h3">{i18n.t(`Device info tab`)}</h3>
            <p className="b-txt-light b-mb2">
              {i18n.t(`The following information will be displayed to your users when
              they click on the Help (?) button in the bottom left corner of the
              login window.`)}
            </p>
            <div className="k-ss2-style-drawer-setting">
              <div className="k-ss2-style-drawer-setting-with-toggle b-mb2">
                <p className="b-txt">{i18n.t(`Serial number`)}</p>
                <Toggle
                  checked={setting.isSerialNumber}
                  onToggle={() =>
                    update('isSerialNumber', !setting.isSerialNumber)
                  }
                />
              </div>
              <div className="k-ss2-style-drawer-setting-with-toggle b-mb2">
                <p className="b-txt">{i18n.t(`IP address`)}</p>
                <Toggle
                  checked={setting.isIpAddress}
                  onToggle={() => update('isIpAddress', !setting.isIpAddress)}
                />
              </div>
              <div className="k-ss2-style-drawer-setting-with-toggle b-mb2">
                <div>
                  <p className="b-txt">{i18n.t(`Hostname`)}</p>
                </div>
                <div>
                  <Toggle
                    checked={setting.isHostName}
                    onToggle={() => update('isHostName', !setting.isHostName)}
                  />
                </div>
              </div>
              <div className="k-ss2-style-drawer-setting-with-toggle b-mb2">
                <p className="b-txt">{i18n.t(`macOS version`)}</p>
                <Toggle
                  checked={setting.isMacVersion}
                  onToggle={() => update('isMacVersion', !setting.isMacVersion)}
                />
              </div>
              <div className="k-ss2-style-drawer-setting-with-toggle b-mb2">
                <p className="b-txt">{i18n.t(`Model information`)}</p>
                <Toggle
                  checked={setting.isModelInfo}
                  onToggle={() => update('isModelInfo', !setting.isModelInfo)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="b-side-panel-layout__footer">
          <div className="b-flex-justify-end">
            <div className="b-grid-ctas">
              <Button kind="outline" onClick={onCancel}>
                {i18n.t(`Cancel`)}
              </Button>
              <Button onClick={onDone}>{i18n.t(`Done`)}</Button>
            </div>
          </div>
        </div>
      </div>
    </SidePanel>
  );
};

export default Drawer;
