/* istanbul ignore file - legacy code, moved */
import React from 'react';
import styled from 'styled-components';

const SearchInputWrapper = styled('div')`
    position: relative;

    &:after {
        content: ' 002 ';
        font-family: 'Font Awesome\ 5 Pro', sans-serif;
        font-size: 17px;
        color: #bbbcbf;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
    }
`;

const SearchInput = styled('input')`
  min-width: 304px;
  position: relative;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
  height: 40px;
  outline: none;
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 17px;
  padding: 12px 40px 10px 15px;
`;

export const OldAppStoreStatusSearch = ({ table }) => (
  <SearchInputWrapper>
    <SearchInput
      type="text"
      placeholder="Search"
      onChange={(e) => table.handleSearch(e.target.value)}
    />
  </SearchInputWrapper>
);
