import classSet from 'classnames';
import React, { Component } from 'react';

class ExpandComponent extends Component {
  onMouseEnter = (e) => {
    if (!this.props.className) {
      return;
    }
    let { target } = e;

    while (target && target.className !== this.props.className) {
      target = target.parentElement;
    }
    target.previousSibling.classList.add('highlighted-by-expand');
  };

  onMouseLeave = (e) => {
    if (!this.props.className) {
      return;
    }
    let { target } = e;

    while (target && target.className !== this.props.className) {
      target = target.parentElement;
    }
    target.previousSibling.classList.remove('highlighted-by-expand');
  };

  render() {
    const { className } = this.props;
    const trCss = {
      style: {
        backgroundColor: this.props.bgColor,
      },
      className: classSet(className),
    };
    return (
      <tr
        hidden={this.props.hidden}
        width={this.props.width}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        {...trCss}
      >
        <td colSpan={this.props.colSpan}>{this.props.children}</td>
      </tr>
    );
  }
}

export default ExpandComponent;
