/* istanbul ignore file - legacy code */
import { useEffect, useState } from 'react';
import { blueprintService } from './blueprint-service';
import { transformToSelectOptions } from './transformers';

const noop = (v) => v;

const useBlueprintService = (id, transformer = noop /* params = {} */) => {
  const [err, setErr] = useState(null);
  const [resp, setResp] = useState(null);

  useEffect(() => {
    const request = id ? blueprintService.get(id) : blueprintService.find();

    request
      .then((r) => {
        const apiResp = {
          data: transformer(r),
        };
        setResp(apiResp);
      })
      .catch((error) => setErr(error));
  }, []);

  return [err, resp];
};

const useFindBlueprints = (transformer) => useBlueprintService('', transformer);

const useGetBlueprint = (id, transformer) =>
  useBlueprintService(id, transformer);

/* istanbul ignore next */
const getAllBlueprintOptions = async (
  blueprints,
  transformer = transformToSelectOptions,
) => {
  if (!blueprints?.length) {
    return transformer([]);
  }

  const data = {
    ids: blueprints.map((bp) => bp.id || bp),
  };

  try {
    const r = await blueprintService.search(data);
    return transformer(
      r.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1)),
    );
  } catch {
    throw new Error('Unable to fetch Blueprint options');
  }
};

export { useFindBlueprints, useGetBlueprint, getAllBlueprintOptions };
