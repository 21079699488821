import { Checkbox, Select } from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';

import { i18n } from 'src/i18n';
import ScreenSaverService from '../../service/screen-saver-service';
import SSPanel from '../side-panel';

const LoginWindow = ({
  update,
  isDisabled,
  setting,
  validationDep,
  triggerValidation,
  beforeEditModel,
}) => (
  <Setting.Card>
    <Setting.Header>
      <h3 className="b-h3">{i18n.t('Login window')}</h3>
    </Setting.Header>
    <Setting.Rows>
      <Setting.Row>
        <Setting.Controls>
          <Checkbox
            label={i18n.t('Configure Screen Saver for Login Window')}
            checked={setting.isScreenSaverLoginWindowConfigured}
            isDisabled={isDisabled}
            onChange={() =>
              update('isScreenSaverLoginWindowConfigured', (p) => !p)
            }
            id="configure-screen-saver-login-window"
            testId="configure-screen-saver-login-window"
          />
        </Setting.Controls>
        <Setting.Helpers className="b-txt-light">
          {i18n.t(
            'Set the amount of time of inactivity when a user is not logged in, after which the screen saver will start.',
          )}
        </Setting.Helpers>

        {setting.isScreenSaverLoginWindowConfigured && (
          <Setting.SecondaryControls>
            <Setting.SecondaryControlsRow>
              <Setting.Controls>
                <Select
                  compact
                  disabled={isDisabled}
                  options={ScreenSaverService.getScreenSaverStartAfter()}
                  value={ScreenSaverService.getScreenSaverStartAfter().find(
                    ({ value }) => value === setting.login_window_idle_time,
                  )}
                  onChange={({ value }) =>
                    update('login_window_idle_time', value)
                  }
                />
              </Setting.Controls>
            </Setting.SecondaryControlsRow>
          </Setting.SecondaryControls>
        )}
      </Setting.Row>

      {setting.isScreenSaverLoginWindowConfigured && (
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label={i18n.t('Configure Screen Saver module for Login Window')}
              checked={setting.isScreenSaverModuleLoginWindowConfigured}
              isDisabled={isDisabled}
              onChange={() =>
                update('isScreenSaverModuleLoginWindowConfigured', (p) => !p)
              }
              id="configure-screen-saver-module"
              testId="configure-screen-saver-module-login-window"
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            {i18n.t(
              'Set the Screen Saver module that will be displayed when a user is not logged in.',
            )}
          </Setting.Helpers>

          {setting.isScreenSaverModuleLoginWindowConfigured && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <SSPanel
                    setting={setting}
                    update={update}
                    isDisabled={isDisabled}
                    validationDep={validationDep}
                    beforeEditModel={beforeEditModel}
                    triggerValidation={triggerValidation}
                    field="login_window_module_path"
                    testId="login-window"
                  />
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>
      )}
    </Setting.Rows>
  </Setting.Card>
);

export default LoginWindow;
