import PropTypes from 'prop-types';
import React from 'react';

import Icon from './Icon';

const Banner = ({ type, icon, text }) => (
  <div className={`banner banner-${type}`} data-testid="banner">
    <div className="node">
      <Icon icon={icon || type} />
    </div>
    <div className="node">
      {typeof text === 'string' ? <p>{text}</p> : text}
    </div>
  </div>
);

Banner.types = {
  MINI_INFO: 'mini-info',
  WARNING: 'warning',
  STATUS: 'status',
  NARROW_STATUS: 'narrow-status',
  INFORMATION: 'information',
};

Banner.propTypes = {
  type: PropTypes.oneOf(Object.values(Banner.types)),
  icon: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

Banner.defaultProps = {
  type: Banner.types.INFORMATION,
  icon: 'information',
};

export default Banner;
