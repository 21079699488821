/* istanbul ignore file - legacy code, moved */
import { Button, Flex } from '@kandji-inc/bumblebee';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Modal from 'theme/components/atoms/Modal';
import Basic from 'theme/components/molecules/Basic';
import { IPAAnchor } from './IPAAnchor';
import { IPAButtonCancel } from './IPAButtonCancel';
import { IPAButtonSave } from './IPAButtonSave';
import { IPADataContext } from './IPADataContext';

export const IPASettings = ({ children }) => {
  const {
    data,
    canEdit,
    canDelete,
    isWorking,
    isEditing,
    isExisting,
    isPendingValidation,
    onDelete,
    onClose,
    onEdit,
    onModalOpen,
    onModalClose,
  } = useContext(IPADataContext);
  const title = get(data, 'name');
  const isProgress = isWorking || isPendingValidation;

  return (
    <>
      <Basic section="view">{children}</Basic>
      <IPAAnchor>
        <div className="node">
          {canDelete && canEdit && isExisting && (
            <Button
              theme="error"
              kind="link"
              disabled={isProgress}
              onClick={
                canEdit && isExisting && !isPendingValidation
                  ? () => onModalOpen('delete')
                  : () => {}
              }
            >
              Delete
            </Button>
          )}
        </div>
        <div className="node">
          {canEdit && isEditing ? (
            <Flex>
              <IPAButtonCancel />
              <IPAButtonSave />
            </Flex>
          ) : (
            <Flex>
              <Button kind="outline" disabled={isWorking} onClick={onClose}>
                Close
              </Button>
              {canEdit && (
                <Button
                  disabled={isWorking}
                  onClick={canEdit ? onEdit : () => {}}
                >
                  Edit
                </Button>
              )}
            </Flex>
          )}
        </div>
      </IPAAnchor>
      <Modal name="delete">
        <div className="paper">
          <header className="modal-division division">
            <div className="node">
              <h3 className="modal-title">Delete {title}?</h3>
            </div>
          </header>
          <section className="modal-division division">
            <div className="node">
              <p>
                <strong className="modal-strong">
                  Are you sure you want to delete {title}?
                </strong>{' '}
                This action is permanent, and cannot be undone.
              </p>
            </div>
          </section>
          <footer className="modal-division division divide">
            <Flex className="node" justify="flex-end">
              <Button
                kind="outline"
                disabled={isProgress}
                onClick={onModalClose}
              >
                Cancel
              </Button>
              <Button
                theme="error"
                isProgress={isProgress}
                icon={isProgress ? 'arrows-rotate' : undefined}
                onClick={
                  canEdit && isExisting && !isPendingValidation
                    ? onDelete
                    : () => {}
                }
              >
                {isProgress ? '' : 'Delete'}
              </Button>
            </Flex>
          </footer>
        </div>
      </Modal>
    </>
  );
};

IPASettings.propTypes = {
  children: PropTypes.node,
};

IPASettings.defaultProps = {
  children: undefined,
};
