import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import AwesomeTableBody from './AwesomeTableBody';
import './AwesomeTableBody.styles.scss';

const AwesomeTableBodyContainer = ({
  data,
  headers,
  tableWidth,
  expandComponent,
  loadingDataView,
  clickableCellsToExpand,
  isExpandableRow,
}) => {
  useEffect(() => {}, [headers]);
  return (
    <AwesomeTableBody
      data={data}
      headers={headers}
      tableWidth={tableWidth}
      expandComponent={expandComponent}
      loadingDataView={loadingDataView}
      clickableCellsToExpand={clickableCellsToExpand}
      isExpandableRow={isExpandableRow}
    />
  );
};

AwesomeTableBodyContainer.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  tableWidth: PropTypes.number.isRequired,
  expandComponent: PropTypes.func.isRequired,
  clickableCellsToExpand: PropTypes.arrayOf(PropTypes.string),
  isExpandableRow: PropTypes.func,
};

AwesomeTableBodyContainer.defaultProps = {
  data: [],
  clickableCellsToExpand: null,
  isExpandableRow: null,
};

export default AwesomeTableBodyContainer;
