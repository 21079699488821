import React, { useContext, useEffect, useState } from 'react';
import './auto-enroll.css';

import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import { useBlueprintConflicts } from 'features/library-items/common/blueprint-conflicts';
import { useLibraryItem } from 'features/library-items/common/hooks/use-library-item';
import { depProfileService } from 'features/library-items/items/auto-enroll/service/dep-profile-service';
import {
  transformFromApi,
  transformToApi,
} from 'features/library-items/items/auto-enroll/service/transformers';
import { useDepProfileService } from 'features/library-items/items/auto-enroll/service/use-dep-profile-service';
import { i18n } from 'src/i18n';

import LibraryItemPage from '../../common/library-item-page';
import LibraryContext from '../../routes/library.context';
import AppleTV from './apple-tv';
import {
  getAdeMinimumOsVersions,
  initialMinimumOsVersions,
} from './gdmf-service';
import General from './general';
import initial from './initial-state';
import IPad from './ipad';
import IPhone from './iphone';
import Mac from './mac';
import update from './update';

const AutoEnrollmentPage = (props) => {
  const { model, setModel, pageState, savedModel } = props;
  const { itemConfig } = useContext(LibraryContext);

  const [_, triggerValidation] = useUniqValue();

  const [minimumOsVersions, setMinimumOsVersions] = useState(
    initialMinimumOsVersions(),
  );
  useEffect(() => {
    const fetchMinimumOsVersions = async () => {
      setMinimumOsVersions(await getAdeMinimumOsVersions());
    };

    fetchMinimumOsVersions();
  }, [setMinimumOsVersions]);

  return (
    <LibraryItemPage
      {...props}
      defaultIcon={itemConfig.icon}
      type={itemConfig.type}
      crumb={model.name || `${itemConfig.getName()} ${i18n.t('Configuration')}`}
      summaryInfoProps={{
        name: `${itemConfig.getName()} ${i18n.t('Configuration')}`,
        description: (
          <>
            {itemConfig.getDescription()}{' '}
            <a
              href="https://support.kandji.io/support/solutions/articles/72000558681"
              rel="noopener noreferrer"
              target="_blank"
              className="b-alink"
            >
              {i18n.t('Learn More...')}
            </a>
          </>
        ),
        publisher: itemConfig.getPublisher(),
        devices: itemConfig.devices,
        requirements: itemConfig.getOsRequirements(),
      }}
      service={depProfileService}
      transformFromApi={(apiData) =>
        transformFromApi(apiData, model.general.defaultOrgDetails)
      }
      transformToApi={transformToApi}
      triggerValidation={triggerValidation}
      withoutTabs
      supportsDuplication
    >
      <General
        isActive={false}
        isDisabled={pageState.isDisabled}
        isAdding={pageState.isAdding}
        setting={model.general}
        update={update('general', setModel)}
        beforeEditModel={savedModel}
        isSubmitted={pageState.isSubmitted}
      />

      <Mac
        isDisabled={pageState.isDisabled}
        setting={model.mac}
        update={update('mac', setModel)}
        beforeEditModel={savedModel}
        setModel={setModel}
        isSaving={pageState.isSaving}
        isSubmitted={pageState.isSubmitted}
        minimumOSVersionOptions={minimumOsVersions.mac}
      />

      <IPhone
        isDisabled={pageState.isDisabled}
        setting={model.iphone}
        update={update('iphone', setModel)}
        isSaving={pageState.isSaving}
        minimumOSVersionOptions={minimumOsVersions.iphone}
      />

      <IPad
        isDisabled={pageState.isDisabled}
        setting={model.ipad}
        update={update('ipad', setModel)}
        beforeEditModel={savedModel}
        setModel={setModel}
        isSaving={pageState.isSaving}
        isSubmitted={pageState.isSubmitted}
        minimumOSVersionOptions={minimumOsVersions.ipad}
      />

      <AppleTV
        isDisabled={pageState.isDisabled}
        setting={model.appleTv}
        update={update('appleTv', setModel)}
        isSaving={pageState.isSaving}
      />
    </LibraryItemPage>
  );
};

const AutoEnrollment = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState: initial(),
    useService: useDepProfileService,
  });
  const blueprintConflicts = useBlueprintConflicts();

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <AutoEnrollmentPage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
      testId="ade"
    />
  );
};

export default AutoEnrollment;
