import configs from 'src/features/library-items/library/library-item-configurations/items';
import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import type {
  DiskManagementModel,
  DiskManagementModelSettings,
} from '../disk-management.types';
import type {
  DiskManagementApiPayloadSettings,
  DiskManagementApiRequestPayload,
  DiskManagementApiResponsePayload,
} from './transformers.types';

function transformSettings(
  settings: DiskManagementApiPayloadSettings,
): DiskManagementModelSettings {
  return {
    management: {
      externalVolumes: settings.ExternalStorage,
      internalVolumes: settings.NetworkStorage,
    },
  };
}

async function transformFromApi(apiData: DiskManagementApiResponsePayload) {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const commonData = {
    id: apiData.id,
    name: apiData.name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
    rules: apiData.rules,
  };

  const result: DiskManagementModel = {
    ...commonData,
    isActive: apiData.active,
    ...transformSettings(apiData.data),
  };

  return {
    ...apiData,
    data: result,
  };
}

function transformToApi(
  model: DiskManagementModel,
): DiskManagementApiRequestPayload {
  const toSend: DiskManagementApiRequestPayload = {
    name: model.name,
    active: model.isActive,
    blueprints: model.selectedBlueprints.map((b) => b.value),
    is_all_blueprints: model.isAllBlueprints,
    data: {
      ExternalStorage: model.management.externalVolumes,
      NetworkStorage: model.management.internalVolumes,
    },
    rules: model.rules || null,
  };

  if (model.skip_blueprint_conflict) {
    toSend.skip_blueprint_conflict = model.skip_blueprint_conflict;
  }

  if (!model.id) {
    const itemConfig = configs['Disk Management'];

    toSend.type = itemConfig.type;
    toSend.identifier = itemConfig.identifier;
  }

  return toSend;
}

export { transformToApi, transformFromApi };
