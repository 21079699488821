const mapInvalidIndexToFieldWithRefs = (invalidations, names, refs) => {
  const m = {};
  names.forEach(
    (n, i) => (m[n] = { isInvalid: invalidations[i], ref: refs && refs[i] }),
  );
  return m;
};

const requiredFileValidator = (msg) => (file) => {
  if (!file) {
    return (
      msg ||
      'Upload a custom icon or turn the toggle off to use the default icon.'
    );
  }
  return null;
};

const requiredTextValidator = (watches, msg) => (v) => [
  {
    message: msg || 'Field is required.',
    invalid: () => !v,
    trigger: ['onBlur', ...watches],
  },
];

export {
  mapInvalidIndexToFieldWithRefs,
  requiredTextValidator,
  requiredFileValidator,
};
