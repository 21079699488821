/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query';
import { vulnerabilityApi } from 'src/app/api/base';

const useGetDeviceVulnerabilities = (
  deviceId: string,
  keys: Array<any> = [],
  params: any = {},
) => {
  const {
    data: apiRes,
    isLoading,
    ...rest
  } = useQuery({
    queryKey: ['device-vulnerabilities', deviceId, ...(keys || [])],
    queryFn: () =>
      vulnerabilityApi(`v1/devices/${deviceId}/vulnerabilities`).get(params),
  });

  const data = apiRes?.data;
  const vulnerabilities = data?.results || [];
  const count = data?.total;

  return { vulnerabilities, count, isLoading, ...rest };
};

export { useGetDeviceVulnerabilities };
