/* istanbul ignore file */
import { useContext, useEffect } from 'react';

import { hasAuthToken } from 'app/auth';
import { links } from 'app/common/constants';
import { AccountContext } from 'contexts/account';
import { useLocation } from 'react-router-dom';
import history from '../app/router/history';
import { useAuth0 } from '../auth0';

const useFetchAccount = (accountError) => {
  const { fetchAccount, accountReady } = useContext(AccountContext);
  const { pathname } = useLocation();

  const { isAuthenticated, isLoading, redirectInProgress } = useAuth0();

  useEffect(() => {
    // determine if we are logged in to auth0 or an admin has a kandji token
    if (hasAuthToken() || (!isLoading && isAuthenticated)) {
      fetchAccount();
    }
    // not logged in or unable to fetch auth0 client
    else if (
      (!isLoading && !isAuthenticated && !redirectInProgress) ||
      accountError
    ) {
      history.push(links.signin, { from: pathname });
    }
  }, [
    isAuthenticated,
    redirectInProgress,
    isLoading,
    fetchAccount,
    accountError,
  ]);

  return accountReady;
};

export default useFetchAccount;
