import { InterfaceContext } from 'contexts/interface';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { animated, useTransition } from 'react-spring';
import { noop, slugify } from '../../function';
import * as spring from '../../spring';
import Shroud from './Shroud';

const Modal = ({ kind, name, children, onClickShroud, shouldShow }) => {
  const { modal } = useContext(InterfaceContext);
  const isOpen = shouldShow || (modal ? modal[name] : false);
  const springModal = useTransition(isOpen, null, spring.appear);
  const id = slugify(name);
  const animateModal = springModal.map(
    ({ item, key, props }) =>
      item && (
        <animated.aside
          key={key}
          style={props}
          id={`modal-${id}`}
          className={`spring-modal-${kind} spring-modal spring-appear theme-base`}
        >
          {children}
        </animated.aside>
      ),
  );

  return (
    <>
      <Shroud isOpen={isOpen} isLock={isOpen} onClick={onClickShroud} />
      {animateModal}
    </>
  );
};

Modal.propTypes = {
  kind: PropTypes.string,
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClickShroud: PropTypes.func,
  shouldShow: PropTypes.bool,
};

Modal.defaultProps = {
  kind: 'default',
  onClickShroud: noop,
  shouldShow: false,
};

export default Modal;
