import {
  Button,
  modal as Modal,
  Toaster as toaster,
} from '@kandji-inc/bumblebee';
import { i18n } from 'i18n';
import PropTypes from 'prop-types';
import React from 'react';
import { copyToClipboard } from 'theme/function';
import PinInput from '../../../../components/common/pin-input';

const PINModal = (props) => {
  const { pin, showPin, onClose } = props;
  const parts = {
    header: {
      text: i18n.t('Device PIN'),
    },
    content: {
      children: <PinInput value={pin} />,
    },
    footer: {
      children: (
        <div className="b-grid-ctas">
          <Button kind="outline" onClick={onClose}>
            {i18n.t('Close')}
          </Button>
          <Button
            onClick={() => {
              copyToClipboard(pin);
              toaster(i18n.t('Copied to clipboard.'));
            }}
          >
            {i18n.t('Copy PIN')}
          </Button>
        </div>
      ),
    },
  };

  return (
    <Modal
      isOpen={showPin}
      parts={parts}
      onClose={onClose}
      withoutCloseButton
    />
  );
};

PINModal.propTypes = {
  pin: PropTypes.string.isRequired,
  showPin: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PINModal;
