const discOptions = [
  { label: 'Allow', value: 'allow' },
  { label: 'Allow with authentication', value: 'authenticate' },
  { label: 'Deny and eject', value: 'deny,eject' },
];

const diskWithEjectOptions = [
  { label: 'Allow', value: 'allow' },
  { label: 'Allow with authentication', value: 'authenticate' },
  { label: 'Read-only with authentication', value: 'read-only,authenticate' },
  { label: 'Read-only', value: 'read-only' },
  { label: 'Deny and eject', value: 'deny,eject' },
];

const diskOptions = [
  { label: 'Allow', value: 'allow' },
  { label: 'Allow with authentication', value: 'authenticate' },
  { label: 'Read-only with authentication', value: 'read-only,authenticate' },
  { label: 'Read-only', value: 'read-only' },
  { label: 'Deny', value: 'deny' },
];

const MANAGE_MEDIA_ACCESS_FIELDS = [
  { label: 'CDs', value: 'cd', options: discOptions },
  { label: 'DVDs', value: 'dvd', options: discOptions },
  { label: 'Blu-ray discs', value: 'bd', options: discOptions },
  { label: 'Blank CDs', value: 'blankcd', options: discOptions },
  { label: 'Blank DVDs', value: 'blankdvd', options: discOptions },
  { label: 'Blank Blu-ray discs', value: 'blankbd', options: discOptions },
  { label: 'DVD-RAMs', value: 'dvdram', options: diskWithEjectOptions },
  { label: 'Disk images', value: 'disk-image', options: diskOptions },
  {
    label: 'Internal hard disks',
    value: 'harddisk-internal',
    options: diskOptions,
  },
  {
    label: 'External hard disks',
    value: 'harddisk-external',
    options: diskOptions,
  },
];

export { MANAGE_MEDIA_ACCESS_FIELDS };
