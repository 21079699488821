import { Flex, styled } from '@kandji-inc/nectar-ui';

export const TargetsPanel = styled(Flex, {
  flexFlow: 'column',
  borderRadius: '$1',
  background: '$neutral0',
  boxShadow: '$elevation1',
  marginTop: '$4',
  marginBottom: '$4',
});
