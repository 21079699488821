import type React from 'react';

import {
  type CSS,
  Flex,
  Heading,
  Paragraph,
  styled,
} from '@kandji-inc/nectar-ui';

type EmptyProps = {
  image?: any;
  title?: string;
  message?: string;
  link?: Partial<{
    label: string;
    route: string;
    show: boolean;
    render: React.ReactElement;
  }>;
  fullHeight?: boolean;
  css?: CSS;
};

const StyledLink = styled('a', {
  color: '$blue50',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
});

const Empty = (props: EmptyProps) => {
  const { image, title, message, link, fullHeight, css } = props;

  return (
    <Flex
      flow="column"
      alignItems="center"
      justifyContent="center"
      pt8={!fullHeight}
      pb8={!fullHeight}
      hFull={fullHeight}
      wFull
      css={css}
    >
      <Flex flow="column" alignItems="center" wrap="wrap" wFull>
        {image}

        <Flex flow="column" alignItems="center" pt2 pb2 wFull>
          <Heading
            as="h4"
            size="4"
            css={{
              color: '$neutral90',
              fontWeight: '$medium',
              textAlign: 'center',
              lineHeight: 'var(--lineHeights-3)',
            }}
          >
            {title}
          </Heading>

          <Paragraph
            css={{
              color: '$neutral90',
              whiteSpace: 'normal',
              textAlign: 'center',
            }}
          >
            {message}
          </Paragraph>

          {link?.show && (
            <Flex css={{ padding: '14px 0' }}>
              {link?.render || (
                <StyledLink href={link?.route}>{link?.label}</StyledLink>
              )}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Empty;
