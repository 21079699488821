import { Code } from '@kandji-inc/nectar-ui';
import { createColumnHelper } from '@tanstack/react-table';
import {
  formatDate,
  getDeviceCell,
} from 'src/features/visibility/prism/utils/column-utils';
import type { PulsePreferencesDeviceSchema } from 'src/features/visibility/pulse/types/pulse.types';
import type { PulseCheckDetailColumns } from '../utils/pulse-column-types';
import { JsonDownloadButton } from './JsonDownloadButton';
import { PulseResultsTable } from './PulseResultsTable';

interface Results {
  username?: string;
  domain?: string;
  plist_path?: string;
  preferences?: unknown;
}

interface PulsePreferencesResultsColumns extends PulseCheckDetailColumns {
  results: Results;
}

const columnHelper = createColumnHelper<PulsePreferencesResultsColumns>();

const preferencesColumns = [
  columnHelper.accessor((row) => row.device__name, {
    id: 'device__name',
    cell: (info) => getDeviceCell(info),
    header: () => 'Device',
  }),
  columnHelper.accessor((row) => row.device__user_name, {
    id: 'device__user_name',
    cell: (row) => row.getValue(),
    header: () => 'Device user',
  }),
  columnHelper.accessor((row) => row.results?.domain, {
    id: 'domain',
    cell: (row) => {
      const value = row.getValue();
      return value ? <Code>{row.getValue()}</Code> : '';
    },
    header: () => 'Domain',
  }),
  columnHelper.accessor((row) => row.results?.plist_path, {
    id: 'plist_path',
    cell: (row) => {
      const value = row.getValue();
      return value ? <Code>{row.getValue()}</Code> : '';
    },
    header: () => 'Path',
  }),
  columnHelper.accessor((row) => row.results?.preferences, {
    id: 'preferences',
    cell: (row) => <JsonDownloadButton data={row.getValue()} />,
    header: () => 'Preferences',
  }),
  columnHelper.accessor((row) => row.updated_at, {
    id: 'updated_at',
    cell: (row) => formatDate(row.getValue()),
    header: () => 'Last Collected',
  }),
];

export const PulsePreferencesResults = ({
  jobDevicesData,
  isLoading,
  onRerunJob,
}: {
  jobDevicesData: PulsePreferencesDeviceSchema[];
  isLoading: boolean;
  onRerunJob: () => void;
}) => {
  return (
    <PulseResultsTable
      jobDevicesData={jobDevicesData}
      isLoading={isLoading}
      columns={preferencesColumns}
      onRerunJob={onRerunJob}
    />
  );
};
