import { Button, TextInput } from '@kandji-inc/bumblebee';
import { addAppModes } from 'features/library-items/items/wifi/fast-lane-card/fast-lane-card.model';
import React, { useState } from 'react';
import { i18n } from 'src/i18n';
import { useCustomAppsActions } from '../../../fast-lane-card.hooks';

const AddBundle = ({ setting, update, setAddAppMode, apps }) => {
  const { addApp, isBundleIdValid } = useCustomAppsActions({
    update,
    setting,
    apps,
  });
  const [appName, setAppName] = useState('');
  const [bundleId, setBundleId] = useState('');
  const isInvalid = isBundleIdValid(bundleId);
  const isDisabled = !appName || !bundleId || isInvalid;
  const add = () => {
    addApp(appName, bundleId);
    setAppName('');
    setBundleId('');
  };
  return (
    <>
      <div className="k-ss2-drawer-setting-extra k-wifiv2-drawer__row">
        <div>
          <p className="b-txt-bold k-wifiv2-drawer__row-header k-wifiv2-drawer__row-header--big">
            {i18n.t('Manually add a bundle identifier')}
          </p>
        </div>
        <div className="k-wifiv2-fastlane-drawer__search-form">
          <div className="k-wifiv2-fastlane-drawer__search-input">
            <TextInput
              placeholder={i18n.t('Google Chrome')}
              value={appName}
              label={i18n.t('App Name')}
              onChange={(e) => setAppName(e.target.value)}
            />
          </div>
          <div className="k-wifiv2-fastlane-drawer__search-input">
            <TextInput
              placeholder="com.culturecode.ThingsMac"
              value={bundleId}
              onChange={(e) => setBundleId(e.target.value)}
              label={i18n.t('Bundle ID')}
              validator={(v) => ({
                invalid: () => isBundleIdValid(v),
                message: i18n.t('It must be unique'),
                trigger: ['onInput'],
              })}
            />
          </div>
          <div className="k-wifiv2-fastlane-drawer__search-input-btn">
            <Button disabled={isDisabled} onClick={add}>
              {i18n.t('Add')}
            </Button>
          </div>
        </div>
      </div>
      <div className="k-ss2-drawer-setting-extra k-wifiv2-drawer__row">
        <Button
          onClick={() => setAddAppMode(addAppModes.SEARCH)}
          kind="link"
          icon="magnifying-glass"
        >
          {i18n.t('Search the App Store')}
        </Button>
      </div>
    </>
  );
};

export default AddBundle;
