import { colors } from 'app/common/constants';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Logo from '../../../assets/img/new_icons/kandji_logo_horizontal_light.svg';

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${(props) => (props.large ? '160px' : '103px')};
  background: #1a1d25;
  border-radius: 4px 4px 0 0;
`;

const LogoWrapper = styled.div`
  width: ${(props) => (props.large ? '175px' : '140px')};
  height: ${(props) => (props.large ? '44px' : '35px')};
  margin-right: 10px;
  display: flex;
  justify-content: flex-end;
`;

const Separator = styled.div`
  width: 1px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.1);
  margin-right: 16px;
`;

const PageName = styled.div`
  padding-top: 2px;
  width: 76px;
  height: 35px;
  font-family: var(--font-family-primary);
  font-size: 13px;
  font-weight: 600;
  line-height: 14px;
  word-wrap: break-word;
  color: ${colors.white};
`;

const HeaderWithLogo = ({ pageName, large }) => (
  <HeaderWrapper large={large}>
    <LogoWrapper large={large}>
      <img role="presentation" alt="logo" src={Logo} />
    </LogoWrapper>
    <Separator />
    <PageName>
      <div>{pageName}</div>
    </PageName>
  </HeaderWrapper>
);

HeaderWithLogo.propTypes = {
  pageName: PropTypes.string.isRequired,
  large: PropTypes.bool,
};

HeaderWithLogo.defaultProps = {
  large: false,
};

export default HeaderWithLogo;
