import React from 'react';

function HiveIcon() {
  return (
    <svg
      width="160"
      height="64"
      viewBox="0 0 160 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.25 52.9029C17.8193 52.9029 44.9578 52.0637 44.9578 37.3752C44.9578 32.759 42.8594 29.4016 39.5021 29.4016C36.1447 29.4016 33.6267 32.759 33.6267 37.3752C33.6267 46.639 47.8441 51.6678 60.0991 44.5002"
        stroke="#C9CDD9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeDasharray="0.08 3.36"
      />
      <path
        d="M101.25 25C103.488 21.7825 109.75 12.5 124.75 12.5C132.25 12.5 138.652 17.4017 140.75 19.5C142.848 21.5983 147.356 26.5479 147.356 30.2409C147.356 34.8573 143.579 35.2769 142.74 35.2769C141.901 35.2769 138.124 34.8573 138.124 30.2409C138.124 25.6246 143.579 18.4902 155.75 17.6509"
        stroke="#C9CDD9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeDasharray="0.08 3.36"
      />
      <path
        d="M100.85 29.5H62.15C61.65 31.2 61.25 33.1 60.95 35.1H102.05C101.75 33 101.35 31.2 100.85 29.5Z"
        fill="#C9CDD9"
      />
      <path
        d="M63.25 26H99.55C98.75 23.9 97.75 22.1 96.75 20.4H66.05C65.05 22.1 64.15 23.9 63.25 26Z"
        fill="#C9CDD9"
      />
      <path
        d="M94.25 17C88.35 10.2 81.45 9 81.45 9C81.45 9 74.55 10.2 68.65 17H94.25Z"
        fill="#C9CDD9"
      />
      <path
        d="M76.55 47.5H60.45C61.95 54.4 68.25 54.9 76.55 54.9V47.5Z"
        fill="#C9CDD9"
      />
      <path
        d="M86.35 47.5V54.9C94.55 54.9 100.95 54.3 102.45 47.5H86.35Z"
        fill="#C9CDD9"
      />
      <path
        d="M81.45 39.8C83.95 39.8 85.95 41.6 86.25 44H102.65C102.65 42 102.55 40.2 102.45 38.4H60.45C60.25 40.1 60.25 42 60.25 44H76.65C76.95 41.7 79.05 39.8 81.45 39.8Z"
        fill="#C9CDD9"
      />
    </svg>
  );
}

export default HiveIcon;
