export const requiredField = () => (v) => {
  const r1 = {
    message: 'Required',
    invalid: () => !String(v).length,
    trigger: ['onBlur', 'onInput'],
  };
  return r1;
};

export const withinCharLimit =
  (charLimit = 50) =>
  (v) => {
    const r1 = {
      message: 'Max character limit',
      invalid: () => String(v).length > Number(charLimit),
      trigger: ['onBlur', 'onInput', 'onMount'],
    };
    return r1;
  };

export const exceedCharMin =
  (charMin = 8, fieldName) =>
  (v) => {
    const r1 = {
      message: `${fieldName} must be at least ${charMin} characters long`,
      invalid: () => String(v).length < Number(charMin),
      trigger: ['onBlur', 'onInput', 'onMount'],
    };
    return r1;
  };

export const requiredAndWithinCharLimit = (charLimit) => (v) => {
  const r1 = requiredField()(v);
  const r2 = withinCharLimit(charLimit)(v);
  return [r1, r2];
};

export const passwordsMustMatch = (v2) => (v) => {
  const r1 = {
    message: 'Passwords must match',
    invalid: () => v !== v2,
    trigger: ['onFocus', 'onBlur', 'onInput'],
  };
  return r1;
};

export const passwordsCannotMatch = (v2) => (v) => {
  const r1 = {
    message: 'New password cannot match old password',
    invalid: () => v === v2,
    trigger: ['onFocus', 'onBlur', 'onInput'],
  };
  return r1;
};

export const newPassword = (charMin, fieldName, oldPassword) => (v) => {
  // Only enforce specific field validation if an oldPassword exists
  if (oldPassword.length) {
    const r1 = exceedCharMin(charMin, fieldName)(v);
    const r2 = passwordsCannotMatch(oldPassword)(v);
    return [r1, r2];
  }
  return [];
};
