/* istanbul ignore file - tested in LibraryItemService */
import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class IpadosReleasesService extends NewLibraryItemService {
  constructor(data = { isAdd: false }) {
    super();
    this.isAdd = data.isAdd;
  }

  /**
   * Get either the auto app template or instance depending on current URL.
   * @override
   */
  async get(id, params) {
    return this.isAdd ? super.getTemplate(id, params) : super.get(id, params);
  }
}

export const ipadosReleasesService = new IpadosReleasesService();
export default IpadosReleasesService;
