/* istanbul ignore file */
import {
  Badge,
  Button,
  DropdownMenu,
  type DropdownMenuOption,
  FilterButton,
  Flex,
  Icon,
  MultiSelect,
  Text,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import isEqual from 'lodash/isEqual';
import type React from 'react';
import type { DetectionDateFilterFields } from 'src/features/edr/common/common.types';
import EDRDetectionDateFilter from 'src/features/edr/common/components/Filters/EDRDetectionDateFilter';
import EDRSearchFilter from 'src/features/edr/common/components/Filters/EDRSearchFilter';
import {
  DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS,
  defaultDateFilter,
} from 'src/features/edr/vulnerability/constants';
import { useGetVulnerabilityBlueprints } from 'src/features/edr/vulnerability/hooks/use-get-vulnerability-blueprints';
import type {
  SidePanelDeviceTabSortTypes,
  Vulnerability,
} from 'src/features/edr/vulnerability/vulnerability.types';

type FilterState = {
  term: string;
  detectionDate: DetectionDateFilterFields;
  blueprints: string[];
};

const FilterBar = (props: {
  cveId: Vulnerability['cve_id'];
  appName: Vulnerability['app_name'];
  filter: FilterState;
  setFilter: React.Dispatch<React.SetStateAction<FilterState>>;
  sort: SidePanelDeviceTabSortTypes;
  setSort: React.Dispatch<React.SetStateAction<SidePanelDeviceTabSortTypes>>;
  defaultFilter: FilterState;
}) => {
  const { cveId, filter, setFilter, sort, setSort, defaultFilter } = props;
  const { term, detectionDate, blueprints: blueprintsFilter } = filter;

  const SORT_LABELS: Record<
    SidePanelDeviceTabSortTypes,
    string | React.ReactNode
  > = {
    '-last_detection_date': i18n.t('Last detected'),
    last_detection_date: i18n.t('First detected'),
    name: (
      <Flex
        alignItems="center"
        gap="xs"
        css={{ svg: { height: '14px', width: '14px' } }}
      >
        {i18n.t('Device name')}
        <Icon name="arrow-up" />
      </Flex>
    ),
    '-name': (
      <Flex
        alignItems="center"
        gap="xs"
        css={{ svg: { height: '14px', width: '14px' } }}
      >
        {i18n.t('Device name')}
        <Icon name="arrow-down" />
      </Flex>
    ),
  };

  const { blueprints, isLoading: isLoadingBlueprints } =
    useGetVulnerabilityBlueprints(cveId);
  const blueprintOptions =
    blueprints?.map((bp) => ({
      label: bp.blueprint_name,
      value: bp.blueprint_id,
    })) || [];

  const visibleBlueprintItems = blueprintsFilter.slice(0, 3);
  const hiddenBlueprintItemCount =
    blueprintsFilter.length - visibleBlueprintItems.length;

  return (
    <Flex flow="column" css={{ gap: '6px' }}>
      <Flex wFull flow="row" justifyContent="space-between">
        <EDRSearchFilter
          name="device"
          value={term}
          onChange={(_, value) =>
            setFilter((prev) => ({ ...prev, term: value as string }))
          }
          onClear={() => setFilter((prev) => ({ ...prev, term: '' }))}
          placeholder={i18n.t('Search')}
        />
        <DropdownMenu
          contentProps={{ align: 'end' }}
          withArrow={false}
          css={{ zIndex: 2, minWidth: 260 }}
          options={Object.entries(SORT_LABELS).map(
            ([value, label]) =>
              ({
                label,
                value,
                onClick: () => setSort(value as SidePanelDeviceTabSortTypes),
                selected: value === sort,
              }) as DropdownMenuOption,
          )}
        >
          <Button
            compact
            variant="subtle"
            icon={{ name: 'fa-angle-down-small', position: 'right' }}
          >
            {SORT_LABELS[sort]}
          </Button>
        </DropdownMenu>
      </Flex>
      <Flex css={{ gap: '6px' }}>
        <EDRDetectionDateFilter
          filter={detectionDate}
          label={i18n.t('Detection date')}
          options={DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS}
          onChange={(_, fields: DetectionDateFilterFields) =>
            setFilter((prev) => ({ ...prev, detectionDate: fields }))
          }
          onClear={() =>
            setFilter((prev) => ({
              ...prev,
              detectionDate: defaultDateFilter,
            }))
          }
        />

        <MultiSelect
          multi
          options={blueprintOptions}
          value={blueprintsFilter}
          onChange={(value) =>
            setFilter((prev) => ({ ...prev, blueprints: value }))
          }
          footer={{
            showClear: true,
            clearLabel: i18n.t('Clear'),
            handleClear: () =>
              setFilter((prev) => ({ ...prev, blueprints: [] })),
          }}
        >
          <FilterButton
            filtersSelected={Boolean(blueprintsFilter.length)}
            showRemove={false}
            css={{ height: 28 }}
          >
            <Flex flow="row" alignItems="center">
              <Text>{i18n.t('Blueprint')}</Text>
              {!isLoadingBlueprints &&
                blueprintsFilter.length === blueprintOptions.length && (
                  <Flex flow="row" gap="xs">
                    <Text>: </Text>
                    <Text css={{ fontWeight: '$medium' }}>
                      {' '}
                      {i18n.t('All')}
                    </Text>
                  </Flex>
                )}
              {blueprintsFilter.length !== blueprintOptions.length &&
                blueprintsFilter.length > 0 && (
                  <Flex flow="row" gap="xs">
                    <Text>:</Text>
                    <Flex
                      alignItems="center"
                      css={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                      }}
                    >
                      <Text
                        css={{
                          fontWeight: '$medium',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {visibleBlueprintItems
                          .map(
                            (item) =>
                              blueprintOptions
                                .find((option) => option.value === item)
                                ?.label.split(' ')[0],
                          )
                          .join(', ')}
                      </Text>
                    </Flex>
                    {hiddenBlueprintItemCount > 0 && (
                      <Badge
                        compact
                        css={{
                          color: '$neutral0',
                          backgroundColor: '$blue50',
                        }}
                      >
                        +{hiddenBlueprintItemCount}
                      </Badge>
                    )}
                  </Flex>
                )}
            </Flex>
          </FilterButton>
        </MultiSelect>

        {!isEqual(filter, defaultFilter) && (
          <Button
            variant="subtle"
            compact
            onClick={() => setFilter(defaultFilter)}
          >
            {i18n.t('Clear all')}
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export { FilterBar };
