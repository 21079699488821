import type { CSSProperties } from 'react';
import React, { useContext } from 'react';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { Chip, setClass } from '@kandji-inc/bumblebee';

import { AccountContext } from 'contexts/account';
import { iso8601ToFormat } from 'features/edr/vulnerability/old/common';

const textVariantMap = {
  bold: 'b-txt-bold',
  normal: 'b-txt',
} as const;

type BasicTextCellProps = Readonly<{
  children: string;
  className?: string;
  style?: CSSProperties;
}>;

type TextCellProps = BasicTextCellProps &
  Readonly<{
    color?: string;
    variant?: keyof typeof textVariantMap;
  }>;

const TextCell = (props: TextCellProps) => {
  const {
    children: text = '',
    color = 'primary-marengo-ultradark',
    variant = 'normal',
    className = '',
    style = {},
  } = props;

  return (
    <div
      className={setClass(className, textVariantMap[variant])}
      style={{
        color: `var(--b-${color})`,
        ...style,
      }}
    >
      {text}
    </div>
  );
};

type InternalLinkCellProps = BasicTextCellProps &
  Readonly<{
    to: LinkProps['to'];
    replace?: LinkProps['replace'];
  }>;

const InternalLinkCell = (props: InternalLinkCellProps) => {
  const {
    children: text = '',
    to = '',
    replace,
    className = '',
    style = {},
  } = props;

  return (
    <Link
      to={to}
      replace={replace}
      className={setClass('b-alink', className)}
      style={style}
      // prevent click event from bubbling up to the row columns to prevent
      // possible expanded row toggle
      onClick={(e) => e.stopPropagation()}
    >
      {text}
    </Link>
  );
};

const DateCell = (props: BasicTextCellProps) => {
  const { userSettings } = useContext(AccountContext);
  const { children: isoDateString = '', className = '', style = {} } = props;

  return (
    <div className={className} style={style}>
      {iso8601ToFormat(isoDateString, {
        format: 'DDD',
        fallback: '',
        timezone: userSettings?.timezone,
        isUtc: true,
      })}
    </div>
  );
};

const DateTimeCell = (props: BasicTextCellProps) => {
  const { userSettings } = useContext(AccountContext);

  const { children: isoDateString = '', className = '', style = {} } = props;

  return (
    <div
      className={className}
      style={{
        color: 'var(--b-primary-marengo-ultradark)',
        ...style,
      }}
    >
      {iso8601ToFormat(isoDateString, {
        format: "DDD 'at' t",
        fallback: '',
        timezone: userSettings?.timezone,
        isUtc: true,
      })}
    </div>
  );
};

const PathCell = (props: BasicTextCellProps) => {
  const { children: path = '', className = '', style = {} } = props;

  return (
    <code
      className={className}
      style={{
        display: 'inline-block',
        background: 'var(--b-primary-marengo-super-ultralight)',
        color: 'var(--b-system-slate-dark)',
        border: '1px solid var(--b-primary-marengo-ultralight)',
        borderRadius: '4px',
        padding: '2px 8px',
        maxWidth: 'max-content',
        ...style,
      }}
    >
      {path}
    </code>
  );
};

type ChipKind =
  | 'primary'
  | 'inactive'
  | 'secondary'
  | 'tertiary'
  | 'tertiary-light'
  | 'tertiary-lighter'
  | 'primary-bright'
  | 'alert'
  | 'active'
  | 'info'
  | 'dull';

type ChipCellProps = Readonly<{ children: string; kind: ChipKind }>;

const ChipCell = (props: ChipCellProps) => {
  const { children: text = '', kind = 'primary' } = props;

  return <Chip text={text} kind={kind} />;
};

const Cell = {
  Text: TextCell,
  InternalLink: InternalLinkCell,
  Date: DateCell,
  DateTime: DateTimeCell,
  Path: PathCell,
  Chip: ChipCell,
};

export default Cell;
