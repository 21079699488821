import { i18n } from 'i18n';

import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class SoftwareUpdateService extends NewLibraryItemService {
  static ALLOWED_OPTIONS = () => ({
    allowed: {
      label: i18n.t('Allowed'),
      value: 'Allowed',
    },
    alwaysOn: {
      label: i18n.t('Always On'),
      value: 'AlwaysOn',
    },
    alwaysOff: {
      label: i18n.t('Always Off'),
      value: 'AlwaysOff',
    },
  });

  static NOTIFICATIONS_OPTIONS = () => ({
    always: {
      label: i18n.t('Always'),
      value: true,
    },
    hourBefore: {
      label: i18n.t('Only starting one hour before the enforcement deadline'),
      value: false,
    },
  });

  static RECOMMENDED_CADENCE_OPTIONS = () => ({
    all: {
      label: i18n.t('All'),
      value: 'All',
    },
    oldest: {
      label: i18n.t('Oldest'),
      value: 'Oldest',
    },
    newest: {
      label: i18n.t('Newest'),
      value: 'Newest',
    },
  });

  static DEFER_MACOS_UPDATES_OPTIONS = () => ({
    doNotDefer: {
      label: i18n.t('Do not defer macOS updates'),
      value: 'doNotDefer',
    },
    deferAll: {
      label: i18n.t('Defer all macOS updates'),
      value: 'deferAll',
    },
    deferByType: {
      label: i18n.t('Defer macOS updates by type'),
      value: 'deferByType',
    },
  });
}

export const softwareUpdateService = new SoftwareUpdateService();

export default SoftwareUpdateService;
