import React from 'react';
import { SelectInput } from '../SelectInput';

export const ParameterConnectivityFilter = (props) => {
  const { style, onChange, selected } = props;

  let options = (options = [
    'Connectivity',
    ['ALL', 'All Connectivity'],
    '',
    ['ONLINE', 'Internet Connected'],
    ['OFFLINE', 'Offline Mode'],
  ]);

  return (
    <SelectInput
      options={options}
      selected={selected}
      style={style}
      size="sm"
      title="Select Connectivity"
      titlePlural="Connectivity"
      onChange={onChange}
    />
  );
};
