import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import ActivityTabNew from '../../../app/components/ActivityTabNew';
import Basic from '../../../theme/components/molecules/Basic';

const ViewActvity = ({ context: Context, itemId }) => {
  const { data } = useContext(Context) || {};
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const routerProps = {
    location,
    match,
    history,
  };
  return (
    <Basic section="view">
      <ActivityTabNew
        libraryItemId={itemId || data.id}
        activityTabType="app"
        filters={['type', 'period']}
        {...routerProps}
      />
    </Basic>
  );
};

ViewActvity.propTypes = {
  context: PropTypes.shape({
    data: PropTypes.object.isRequired,
  }),
  itemId: PropTypes.string,
};

ViewActvity.defaultProps = {
  context: undefined,
  itemId: undefined,
};

export default ViewActvity;
