import type { UniqueIdentifier } from '@dnd-kit/core';

import app from 'src/app/_reducers/app';
import type {
  App,
  AppTypeKind,
  GetLibraryItemsApiResponseApp,
  HomeScreenLayoutDeviceKind,
  LibraryTypeAppKind,
  WebClip,
} from '../home-screen-layout.types';

type FetchedHomeScreenLayoutApp = App | GetLibraryItemsApiResponseApp;

class HomeScreenLayoutApps {
  private readonly appEntries = new Map();

  private readonly blueprintLookup = new Map();

  private readonly appTypeLookup = new Map([
    ['appStore', new Set()],
    ['inHouse', new Set()],
    ['system', new Set()],
    ['webclip', new Set()],
  ]);

  private readonly deviceTypeLookup = new Map([
    ['ipad', new Set()],
    ['iphone', new Set()],
  ]);

  constructor(data: ReadonlyArray<FetchedHomeScreenLayoutApp>) {
    data.forEach((appItem) => {
      let appEntry: App | WebClip;

      if (this.isAppleSystemApp(appItem)) {
        appEntry = appItem;
        this.appTypeLookup.get('system')?.add(appItem.id);
      } else if (this.isLibraryItemsApiApp(appItem)) {
        const deviceFamilies = this.getDeviceFamilies(appItem);
        let appType: string;
        let bundleId: string = appItem?.data?.bundle_id;
        let appName: string = appItem.name;
        let icon: string = appItem.icon
          ? appItem.icon
          : appItem.data.webClipImgUrl;

        switch (appItem.type) {
          case 'vpp-app':
            appType = 'appStore';
            break;
          case 'profile':
            appType = 'profile';
            break;
          default:
            appType = 'inHouse';
            bundleId = appItem.identifier;
            appName = appItem.data?.app_name ?? appName;
            // default to empty string to render ipa apps w/ no icon
            icon = icon ?? '';
            break;
        }

        const appIdentifier = appItem.identifier;

        appEntry = {
          id: appItem.id,
          name: appName,
          icon,
          Type: 'Application',
          BundleID: bundleId,
          appIdentifier: appIdentifier,
          appType,
          deviceFamilies,
        };

        if (
          appType === 'profile' &&
          appIdentifier === 'com.kandji.profile.webclip'
        ) {
          this.appTypeLookup.get('webclip')?.add(appItem.id);
          appEntry = {
            ...appEntry,
            name: appItem.data.Label,
            URL: appItem.data.URL,
            Type: 'WebClip',
            label: appItem.data.Label,
          };
        } else {
          this.appTypeLookup.get(appType)?.add(appItem.id);
        }

        appItem.blueprints.forEach((bp) => {
          if (!this.blueprintLookup.has(bp.id)) {
            this.blueprintLookup.set(bp.id, new Set([appItem.id]));
          } else {
            this.blueprintLookup.get(bp.id)?.add(appItem.id);
          }
        });
      }
      if (appEntry.deviceFamilies.includes('ipad')) {
        this.deviceTypeLookup.get('ipad')?.add(appItem.id);
      }

      if (appEntry.deviceFamilies.includes('iphone')) {
        this.deviceTypeLookup.get('iphone')?.add(appItem.id);
      }

      return this.appEntries.set(appItem.id, appEntry);
    });
  }

  public list({
    deviceType,
    blueprints = [],
    appName,
    appType,
  }: {
    deviceType: HomeScreenLayoutDeviceKind;
    blueprints?: ReadonlyArray<string>;
    appName?: string;
    appType?: ReadonlyArray<AppTypeKind>;
  }) {
    const isAllBp = !blueprints || !blueprints.length;

    const selectedBlueprints = isAllBp
      ? []
      : blueprints.flatMap((id) =>
          Array.from(this.blueprintLookup.get(id) || 0),
        );

    const bpFilter = new Set(selectedBlueprints);

    const appsByType = [...this.appTypeLookup.entries()].map(([k, v]) => [
      k,
      Array.from(v)
        .map((id: string) => {
          const appEntry = this.appEntries.get(id);
          const hasBlueprint =
            isAllBp || bpFilter.has(id) || appEntry?.appType === 'system';
          const hasDeviceType = this.deviceTypeLookup.get(deviceType)?.has(id);

          if (hasBlueprint && hasDeviceType) {
            // Filter on `appName` search term:
            if (appName) {
              const regex = new RegExp(appName, 'gi');
              if (regex.test(appEntry.name)) {
                return appEntry;
              }
              return undefined;
            }

            // @TODO implement blueprint filter here
            // return app entry if no other filters apply:
            return appEntry;
          }

          return undefined;
        })
        .filter((appEntry) => {
          if (appType?.length > 0 && appEntry) {
            if (appType.includes(appEntry.appType)) {
              return appEntry;
            }

            return undefined;
          }

          return appEntry;
        })
        .filter(Boolean)
        .sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
        ),
    ]);

    return Object.fromEntries(appsByType);
  }

  public getAppEntry(id: UniqueIdentifier) {
    return this.appEntries.get(id);
  }

  private getDeviceFamilies(item: GetLibraryItemsApiResponseApp) {
    const deviceMapping = {
      iphone: item.runs_on_iphone === true ? 'iphone' : undefined,
      ipad: item.runs_on_ipad === true ? 'ipad' : undefined,
    };

    return Object.values(deviceMapping).filter<
      HomeScreenLayoutDeviceKind | undefined
    >((device): device is HomeScreenLayoutDeviceKind => !!device);
  }

  private isAppleSystemApp(
    app: FetchedHomeScreenLayoutApp & {
      appType?: AppTypeKind | undefined;
    },
  ): app is Extract<FetchedHomeScreenLayoutApp, App> & {
    appType: 'system';
  } {
    return Object.hasOwnProperty.call(app, 'appType')
      ? app.appType === 'system'
      : false;
  }

  private isLibraryItemsApiApp(
    app: FetchedHomeScreenLayoutApp & {
      type?: LibraryTypeAppKind | undefined;
    },
  ): app is Exclude<FetchedHomeScreenLayoutApp, App> {
    return Object.hasOwnProperty.call(app, 'type')
      ? app.type === 'vpp-app' ||
          app.type === 'ipa-app' ||
          app.type === 'ipa-app-v2' ||
          app.type === 'profile'
      : false;
  }

  public getAppTypeContents() {
    const appTypeContents: { [key: string]: string[] } = {};
    this.appTypeLookup.forEach((value, key) => {
      appTypeContents[key] = Array.from(value);
    });
    return appTypeContents;
  }
}

export default HomeScreenLayoutApps;
