export default (email, firstName, lastName) => {
  function initFreshChat() {
    window.fcWidget.init({
      token: 'c8ab0cbd-083a-4581-9349-6c01f716e0d0',
      host: 'https://wchat.freshchat.com',
      email,
      firstName,
      lastName,
      config: {
        hideFAQ: true,
      },
    });
  }

  function initialize(i, t) {
    var e;
    i.getElementById(t)
      ? initFreshChat()
      : (((e = i.createElement('script')).id = t),
        (e.async = !0),
        (e.src = 'https://wchat.freshchat.com/js/widget.js'),
        (e.onload = initFreshChat),
        i.head.appendChild(e));
  }

  function initiateCall() {
    initialize(document, 'Freshchat-js-sdk');
  }

  initiateCall();
};
