import { Button } from '@kandji-inc/bumblebee';
import toaster from '@kandji-inc/bumblebee/lib/atoms/toaster';
import cn from 'classnames';
import { i18n } from 'i18n';
import React from 'react';
import { useDispatch } from 'react-redux';
import { updateComputer } from 'src/app/_actions/computer';
import { setSnackbar } from 'src/app/_actions/ui';
import { colors } from 'src/app/common/constants';
import { formatTime } from 'src/app/components/common/helpers';
import HoverTippy from 'src/features/util/components/hover-tippy/hover-tippy';
import useLostMode from 'src/hooks/useLostMode';
import styled from 'styled-components';
import { playLostModeSound, updateLostModeLocation } from '../../../api';
import TempButton from '../TempButton';
import OpenOut from '../assets/OpenOut';
import Volume from '../assets/Volume';
import useAddress from './useAddress';

const grayText = colors['marengo-300'];
const borderColor = '#dedede';

const StyledLostModeDetails = styled.div`
  &.enabled-lost-mode__map-details {
    width: 52%;
    padding: 0 0 0 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      font-family:
        Atlas Grotesk Web,
        sans-serif;
      font-weight: 400;
    }

    .shouldMoveUp {
      transform: translateY(-10px);
    }

    .enabled-lost-mode__address-info {
      color: ${grayText};
    }

    .enabled-lost-mode__address {
      p {
        font-family: 'Sharp Sans', sans-serif;
        font-weight: 600;
        font-size: 16px;
        margin-right: 8px;
      }
    }
    .enabled-lost-mode__coords {
      font-family:
        Atlas Grotesk Web,
        sans-serif;
      font-weight: 400;

      &.noAddress {
        display: flex;
        align-items: center;
        font-size: 16px;
        margin-right: 8px;
      }
    }

    .enabled-lost-mode__bottom-bar {
      display: flex;
      margin-top: 45px;
      padding-top: 12px;
      border-top: 1px solid #dedede;
      height: 100px;

      .enabled-lost-mode__location {
        padding-top: 12px;
        color: ${grayText};
        border-right: 1px solid ${borderColor};
        width: 50%;
      }
      .enabled-lost-mode__sound {
        padding-left: 24px;
        padding-top: 12px;
        color: ${grayText};

        .enabled-lost-mode__sound-hover-tippy {
          width: 20px;
          height: 20px;
          background: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          color: #0057da;
          box-shadow:
            0px 0px 1.45455px rgba(0, 0, 0, 0.25),
            inset 0px 0px 0px 0.145455px rgba(0, 0, 0, 0.25);
          font-size: 14px;
          cursor: default;
        }
      }
    }
  }
`;

function LostModeDetails(props) {
  const { computer } = props;
  const {
    longitude,
    latitude,
    lostModeLocationDatetime,
    lostModeSoundStatusDatetime,
    isLostModeSoundPending,
    isLostModeLocationPending,
    isNoCoordinates,
    horizontalAccuracy,
  } = useLostMode(computer);

  const address = useAddress({ latitude, longitude });

  const coords = formatCoordinates(latitude, longitude);
  const dispatch = useDispatch();

  const handleUpdateLocation = (e) => {
    e.preventDefault();
    updateLostModeLocation(computer.id)
      .then(() => {
        dispatch(
          setSnackbar(i18n.t('Command sent to update Lost Mode location.')),
        );
        dispatch(
          updateComputer({
            ...computer,
            lost_mode: {
              ...computer.lost_mode,
              last_location_status: 'PENDING',
            },
          }),
        );
      })
      .catch((err) => {
        dispatch(setSnackbar(err.message));
      });
  };

  const handlePlaySound = (e) => {
    e.preventDefault();
    playLostModeSound(computer.id)
      .then(() => {
        dispatch(setSnackbar(i18n.t('Command sent to play Lost Mode sound.')));
        dispatch(
          updateComputer({
            ...computer,
            lost_mode: {
              ...computer.lost_mode,
              sound_status: 'PENDING',
            },
          }),
        );
      })
      .catch((err) => {
        dispatch(setSnackbar(err.message));
      });
  };

  return (
    <StyledLostModeDetails className="enabled-lost-mode__map-details">
      {isNoCoordinates ? (
        <div>
          <p className="enabled-lost-mode__address-info">Location pending...</p>
          <TempButton icon={<OpenOut />} kind="link" disabled={isNoCoordinates}>
            Open In Maps
          </TempButton>
        </div>
      ) : (
        <div className={cn({ shouldMoveUp: !horizontalAccuracy })}>
          {horizontalAccuracy && (
            <p className="enabled-lost-mode__address-info">
              Within {Math.round(horizontalAccuracy)} meters of
            </p>
          )}
          {address && (
            <div className="enabled-lost-mode__address">
              <p>{address}</p>
              <Button
                kind="link"
                icon="copy"
                onClick={() => {
                  navigator.clipboard.writeText(address);
                  toaster(i18n.t('Copied to clipboard.'));
                }}
              />
            </div>
          )}
          <p
            className={cn('enabled-lost-mode__coords', {
              noAddress: !address,
            })}
          >
            {coords}
            {!address && (
              <Button
                kind="link"
                icon="copy"
                onClick={() => {
                  navigator.clipboard.writeText(coords);
                  toaster(i18n.t('Copied to clipboard.'));
                }}
              />
            )}
          </p>
          <TempButton
            className={cn({ 'b-mt-tiny': !address })}
            icon={<OpenOut />}
            target="_blank"
            href={`https://maps.apple.com/?${`ll=${latitude},${longitude}&q=Last location for ${
              computer.name || 'the device'
            }`}&sspn=0.016`}
          >
            Open In Maps
          </TempButton>
        </div>
      )}
      <div className="enabled-lost-mode__bottom-bar">
        <div className="enabled-lost-mode__location">
          <p>Updated {formatTime(lostModeLocationDatetime, true)}</p>
          <Button
            disabled={isLostModeLocationPending}
            icon="arrow-rotate-right"
            kind="link"
            onClick={handleUpdateLocation}
          >
            {isLostModeLocationPending
              ? 'Update pending...'
              : 'Update Location'}
          </Button>
        </div>
        <div className="enabled-lost-mode__sound">
          <div className="b-flex">
            <p className="b-mr1">
              {lostModeSoundStatusDatetime
                ? `Sound played ${formatTime(
                    lostModeSoundStatusDatetime,
                    true,
                  )}`
                : 'No sound playing'}
            </p>

            <HoverTippy
              maxWidth={330}
              text="The sound plays for two minutes, or until the device’s user presses any side button."
            >
              <span className="enabled-lost-mode__sound-hover-tippy">?</span>
            </HoverTippy>
          </div>

          <TempButton
            disabled={isLostModeSoundPending || isLostModeLocationPending}
            icon={<Volume />}
            onClick={handlePlaySound}
          >
            {isLostModeSoundPending ? 'Sound pending...' : 'Play Sound'}
          </TempButton>
        </div>
      </div>
    </StyledLostModeDetails>
  );
}

export default LostModeDetails;

function formatCoordinates(lat, long) {
  if (!lat && !long) {
    return '';
  }

  let latCardinalDirection = 'S';
  let longCardinalDirection = 'W';

  if (lat > 0) {
    latCardinalDirection = 'N';
  }
  if (long > 0) {
    longCardinalDirection = 'E';
  }

  return `${lat.toFixed(4)}° ${latCardinalDirection}, ${long.toFixed(
    4,
  )}° ${longCardinalDirection}`;
}
