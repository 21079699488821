import EthernetService from '../../ethernet-service';

const { keys } = EthernetService;
const authKeys = keys.authentication;

const getCertificate = (model) => {
  const { authentication } = model;

  const isPkcs =
    authentication[authKeys.identityCertificate] ===
    EthernetService.identityCertificates.PKCS12;

  const isScep =
    authentication[authKeys.identityCertificate] ===
    EthernetService.identityCertificates.SCEP;

  const isAdcs =
    authentication[authKeys.identityCertificate] ===
    EthernetService.identityCertificates.ADCS;

  const hasPkcsFile = isPkcs && authentication[authKeys.pkcs.file];

  const getSubjectAltName = (is, withSan, san) =>
    is && withSan && san?.length
      ? san.reduce(
          (a, c) => {
            if (!c.type || !c.value) {
              return a;
            }

            return {
              ...a,
              [c.type]: [...(a?.[c.type] || []), c.value],
            };
          },
          {
            [EthernetService.subjectAlternativeNameTypes.DNS]: null,
            [EthernetService.subjectAlternativeNameTypes.RFC]: null,
            [EthernetService.subjectAlternativeNameTypes.UPN]: null,
            [EthernetService.subjectAlternativeNameTypes.URI]: null,
          },
        )
      : null;

  const getSubjectAltNameForAdcs = (is, withSan, san) =>
    is && withSan && san?.length
      ? san.map(({ type, value }) => ({
          type: EthernetService.subjectAlternativeNameTypesWindows[type],
          value,
        }))
      : null;

  return {
    type: authentication[authKeys.identityCertificate],
    fileName: hasPkcsFile ? authentication[authKeys.pkcs.file]?.name : null,
    fileSize: hasPkcsFile ? authentication[authKeys.pkcs.file]?.size : null,
    fileUploadedAt: hasPkcsFile
      ? authentication[authKeys.pkcs.file]?.uploadedAt
      : null,
    adcsUpdatedAt: isAdcs
      ? authentication[authKeys.adcs.updatedAt] || Date.now()
      : null,
    scepUpdatedAt: isScep
      ? authentication[authKeys.scep.updatedAt] || Date.now()
      : null,
    settings: {
      pkcs12: {
        AllowAllAppsAccess: isPkcs
          ? authentication[authKeys.pkcs.allowAccessToThePrivateKey]
          : null,
        KeyIsExtractable: isPkcs
          ? !authentication[authKeys.pkcs.preventExtraction]
          : null,
        Password:
          isPkcs && authentication[authKeys.pkcs.password]
            ? authentication[authKeys.pkcs.password]
            : null,
        PayloadContent: hasPkcsFile
          ? authentication[authKeys.pkcs.file]?.base64
          : null,
      },
      scep: {
        URL:
          isScep && authentication[authKeys.scep.url]
            ? authentication[authKeys.scep.url]
            : null,
        Name:
          isScep && authentication[authKeys.scep.name]
            ? authentication[authKeys.scep.name]
            : null,
        Challenge:
          isScep && authentication[authKeys.scep.challenge]
            ? authentication[authKeys.scep.challenge]
            : null,
        CAFingerprint:
          isScep && authentication[authKeys.scep.fingerprint]
            ? authentication[authKeys.scep.fingerprint]
            : null,
        Subject:
          isScep && authentication[authKeys.scep.subject]
            ? authentication[authKeys.scep.subject]
            : null,
        SubjectAltName: getSubjectAltName(
          isScep,
          authentication[authKeys.scep.withSan],
          authentication[authKeys.scep.san],
        ),
        Keysize: isScep ? authentication[authKeys.scep.keySize] : null,
        'Key Usage': isScep ? authentication[authKeys.scep.keyUsage] : null,
        Retries:
          isScep && authentication[authKeys.scep.withRetries]
            ? authentication[authKeys.scep.retries]
            : null,
        RetryDelay:
          isScep && authentication[authKeys.scep.withRetryDelay]
            ? authentication[authKeys.scep.retryDelay]
            : null,
        KeyIsExtractable: isScep
          ? !authentication[authKeys.scep.disallowKeyExtraction]
          : null,
        AllowAllAppsAccess: isScep
          ? authentication[authKeys.scep.allowAccessToApps]
          : null,
        certificateExpirationNotification:
          isScep &&
          authentication[authKeys.scep.withCertificateExpirationNotification]
            ? authentication[authKeys.scep.certificateExpirationNotification]
            : null,
        autoProfileRedistribution:
          isScep && authentication[authKeys.scep.withAutoProfileRedistribution]
            ? authentication[authKeys.scep.autoProfileRedistribution]
            : null,
      },
      adcs: {
        AllowAllAppsAccess: isAdcs
          ? authentication[authKeys.adcs.allowAccessToApps]
          : null,
        KeyIsExtractable: isAdcs
          ? !authentication[authKeys.adcs.preventExtraction]
          : null,
        key_size: isAdcs ? authentication[authKeys.adcs.keySize] : null,
        san: getSubjectAltNameForAdcs(
          isAdcs,
          authentication[authKeys.adcs.withSan],
          authentication[authKeys.adcs.san],
        ),
        authority: isAdcs ? authentication[authKeys.adcs.server] : null,
        subject: isAdcs ? authentication[authKeys.adcs.subject] : null, // Do we dilineate by '/' ?
        template: isAdcs ? authentication[authKeys.adcs.template] : null,
        name: isAdcs ? authentication[authKeys.adcs.name] : null,
      },
    },
  };
};

export default getCertificate;
