import { updateSetting as update } from '@kandji-inc/bumblebee';
import { useCallback, useContext, useEffect, useMemo } from 'react';

import ActivityTab from 'features/library-items/common/activity-tab';
import NewStatusTab from 'features/library-items/common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from 'features/library-items/common/new-status-tab/util';
import useUniqValue from 'src/components/common/hooks/use-uniq-value/use-uniq-value';
import { i18n } from 'src/i18n';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import { populateSelectedDevices } from '../../data-service/library-item/devices';
import LibraryContext from '../../routes/library.context';
import initial from './initial-state';
import Device from './sections/Device';
import HomeScreenLayoutApps from './service/home-screen-layout-apps';
import { homeScreenLayoutService } from './service/home-screen-layout-service';
import { transformFromApi, transformToApi } from './service/transformers';
import useHomeScreenLayoutApps from './service/use-home-screen-layout-apps';
import useHomeScreenLayoutService from './service/use-home-screen-layout-service';

import type { HomeScreenLayoutProps } from './home-screen-layout.types';

const HomeScreenLayoutPage = (props: HomeScreenLayoutProps) => {
  const { model, setModel, pageState, blueprintOptions } = props;
  const [, triggerValidation] = useUniqValue();

  const appsQuery = useHomeScreenLayoutApps();

  const homeScreenLayoutApps = useMemo(
    () =>
      appsQuery.data?.length ? new HomeScreenLayoutApps(appsQuery.data) : null,
    [appsQuery.data],
  );

  const iPadUpdate = useCallback(update('iPad', setModel), []);
  const iPhoneUpdate = useCallback(update('iPhone', setModel), []);
  const { itemConfig } = useContext(LibraryContext);

  useEffect(() => populateSelectedDevices(model, setModel, itemConfig), []);

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.getName()}
      summaryInfoProps={{
        name: itemConfig.getName(),
        description: itemConfig.getDescription(),
        publisher: itemConfig.getPublisher(),
        devices: itemConfig.devices,
        requirements: itemConfig
          .getOsRequirements()
          .map((os) => `${os} (${i18n.t('Requires Supervision')})`),
      }}
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={homeScreenLayoutService}
      defaultIcon={itemConfig.icon}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      supportsRules
      supportsDuplication
      supportsInstallOn
    >
      <Device
        kind="ipad"
        update={iPadUpdate}
        isDisabled={pageState.isDisabled}
        settings={model.iPad}
        apps={homeScreenLayoutApps}
        blueprintOptions={blueprintOptions}
      />
      <Device
        kind="iphone"
        update={iPhoneUpdate}
        isDisabled={pageState.isDisabled}
        settings={model.iPhone}
        apps={homeScreenLayoutApps}
        blueprintOptions={blueprintOptions}
      />
    </LibraryItemPage>
  );
};

const HomeScreenLayout = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState: initial,
    useService: useHomeScreenLayoutService,
  });

  const blueprintConflicts = useBlueprintConflicts();

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <HomeScreenLayoutPage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
      testId="home-screen-layout"
    />
  );
};

export default HomeScreenLayout;
