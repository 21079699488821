import uuid from 'uuid';
import WifiService from './service/wifi-service';

const { keys } = WifiService;

const initialState = {
  id: '',
  isActive: true,
  name: '',
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,

  supportsRules: true,

  installation: {
    [keys.installation.type]:
      WifiService.installationTypes.CONTINUOUSLY_ENFORCE,
  },
  selfService: {
    isEnabled: false,
    category: '',
    isRecommended: false,
    displayName: null,
    shortDescription: '',
    longDescription: '',
    isRequireRead: false,
    iconUrl: null,
    iconFile: null,
    installBtnText: null,
    size: '',
    displayInfoBtn: false,
    infoBtnText: '',
    infoBtnUrl: '',
  },
  general: {
    [keys.general.ssid]: '',
    [keys.general.autoJoin]: true,
    [keys.general.hiddenNetwork]: false,
    [keys.general.withNetworkPriority]: false,
    [keys.general.networkPriority]: '0',
    [keys.general.withIpV6]: false,
    [keys.general.ipv6]: true,
    [keys.general.disableCaptiveNetworkPriority]: false,
    [keys.general.disableMacAddressRandomization]: false,
  },
  authentication: {
    [keys.authentication.securityType]: null,
    [keys.authentication.securityPassword]: '',
    [keys.authentication.useLoginWindow]: false,
    // EAP fields
    [keys.authentication.acceptedEapTypes.acceptedEapTypes]: [],
    [keys.authentication.acceptedEapTypes.authentication]: null,
    [keys.authentication.acceptedEapTypes.username]: '',
    [keys.authentication.acceptedEapTypes.promptForPassword]:
      WifiService.promptPasswordValues.ONCE,
    [keys.authentication.acceptedEapTypes.password]: '',
    [keys.authentication.acceptedEapTypes.innerAuthentication]:
      WifiService.innerAuthentications.MSCHAPv2,
    [keys.authentication.acceptedEapTypes.outerIdentity]: '',
    [keys.authentication.acceptedEapTypes.tlsMinimumVersion]:
      WifiService.tlsVersions.v1_0,
    [keys.authentication.acceptedEapTypes.tlsMaximumVersion]:
      WifiService.tlsVersions.v1_2,
    [keys.authentication.acceptedEapTypes.randValue]: WifiService.randValues[3],
    [keys.authentication.acceptedEapTypes.usePac]: false,
    [keys.authentication.acceptedEapTypes.provisionPac]: false,
    [keys.authentication.acceptedEapTypes.provisionPacAnonymously]: false,
    [keys.authentication.acceptedEapTypes.twoFactorAuth]: false,

    [keys.authentication.identityCertificate]:
      WifiService.identityCertificates.NONE,
    // SCEP fields
    [keys.authentication.scep.url]: '',
    [keys.authentication.scep.name]: '',
    [keys.authentication.scep.challenge]: '',
    [keys.authentication.scep.fingerprint]: '',
    [keys.authentication.scep.subject]: '',
    [keys.authentication.scep.subjectAlternativeNameType]:
      WifiService.subjectAlternativeNameTypes.NONE,
    [keys.authentication.scep.withSan]: false,
    [keys.authentication.scep.san]: [{ _id: uuid(), type: null, value: '' }],
    [keys.authentication.scep.keySize]: WifiService.keySizes.s1024,
    [keys.authentication.scep.keyUsage]: WifiService.keyUsages.NONE,
    [keys.authentication.scep.withRetries]: true,
    [keys.authentication.scep.retries]: '3',
    [keys.authentication.scep.withRetryDelay]: true,
    [keys.authentication.scep.retryDelay]: '10',
    [keys.authentication.scep.disallowKeyExtraction]: true,
    [keys.authentication.scep.allowAccessToApps]: true,
    [keys.authentication.scep.withCertificateExpirationNotification]: true,
    [keys.authentication.scep.certificateExpirationNotification]: '14',
    [keys.authentication.scep.withAutoProfileRedistribution]: true,
    [keys.authentication.scep.autoProfileRedistribution]: '30',
    [keys.authentication.scep.updatedAt]: null,
    // PKCS12 fields
    [keys.authentication.pkcs.file]: '',
    [keys.authentication.pkcs.password]: '',
    [keys.authentication.pkcs.allowAccessToThePrivateKey]: false,
    [keys.authentication.pkcs.preventExtraction]: false,
    // AD CS fields
    [keys.authentication.adcs.name]: '',
    [keys.authentication.adcs.san]: [{ _id: uuid(), type: null, value: '' }],
    [keys.authentication.adcs.withSan]: false,
    [keys.authentication.adcs.keySize]: WifiService.keySizes.s1024,
    [keys.authentication.adcs.server]: '',
    [keys.authentication.adcs.template]: '',
    [keys.authentication.adcs.subject]: '',
    [keys.authentication.adcs.allowAccessToApps]: false,
    [keys.authentication.adcs.preventExtraction]: false,
    [keys.authentication.adcs.updatedAt]: null,
  },
  trust: {
    [keys.trust.serverCertificateNames]: [{ keyField: '1', value: '' }],
    [keys.trust.specifyCertificates]: false,
    [keys.trust.certificates]: [],
    [keys.trust.specifyServerCertificateNames]: false,
  },
  fastLaneMarking: {
    [keys.fastlane.isEnabled]: false,
    [keys.fastlane.qos]: WifiService.qosValues.DISABLE,
    [keys.fastlane.addedApps]: [],
  },
  proxy: {
    [keys.proxy.isEnabled]: false,
    [keys.proxy.proxyType]: WifiService.proxyTypes.AUTOMATIC,
    [keys.proxy.proxyPacUrl]: '',
    [keys.proxy.allowProxyPacFallback]: false,
    [keys.proxy.proxyServer]: '',
    [keys.proxy.port]: '',
    [keys.proxy.proxyUsername]: '',
    [keys.proxy.proxyPassword]: '',
    [keys.proxy.automatic.allowProxyPacFallback]: false,
  },
};

export default initialState;
