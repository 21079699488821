import { IconButton, styled, theme } from '@kandji-inc/nectar-ui';

// TODO: move to nectar-ui

const TableHeaderIconButton = styled(IconButton, {
  width: '36px',
  height: '36px',
  color: theme.colors.button_subtle_icon,
  '&:hover': {
    background: theme.colors.button_subtle_background_hover,
  },
  '&[data-state="open"], &[data-state="delayed-open"]': {
    background: theme.colors.button_subtle_background_hover,
  },
  variants: {
    compact: {
      true: {
        p: '$1',
        width: '28px',
        height: '28px',
      },
    },
  },
});

export default TableHeaderIconButton;
