import { useQuery } from '@tanstack/react-query';
import { pulseService } from 'src/features/visibility/pulse/data-service/pulse-service';
import type { PulseCheckTypes } from 'src/features/visibility/pulse/types/pulse.types';

export const useJobDevicesQuery = (
  jobId: string,
  jobType: PulseCheckTypes,
  totalFinished: number,
  pagination: { limit: number; offset: number },
) =>
  // const queryClient = useQueryClient();
  // const jobMetaData = queryClient.getQueryData<PulseCheckResponseSchema>([
  //   'pulse',
  //   jobId,
  // ]);
  useQuery({
    queryKey: ['pulse', jobId, 'devices', totalFinished, pagination],
    queryFn: () => pulseService.getPulseDevicesById(jobId, jobType, pagination),
    enabled: !!jobId,
    // TODO: saving this for future
    // refetchInterval: () => {
    //   console.log('refetch interval check running');
    //   const jobData = jobMetaData?.data?.[0];
    //   const jobStatus = jobData?.status;
    //   const finished =
    //     jobStatus === 'complete' ||
    //     jobStatus === 'failed' ||
    //     jobData?.total_devices_finished === jobData?.total_devices_targeted;

    //   if (finished) {
    //     console.log('job complete, no need to poll');
    //     return false;
    //   }
    //   console.log('job not complete, polling');
    //   return 5000;
    // },
    refetchOnWindowFocus: true,
  });
