import { Button, DialogPrimitives as Dialog } from '@kandji-inc/nectar-ui';

export const DeleteConfirmation = ({
  reportName,
  onDelete,
  deletePending,
  open,
  setOpen,
}: {
  reportName: string;
  onDelete: () => Promise<void>;
  deletePending: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Content onOpenAutoFocus={(e: Event) => e.preventDefault()}>
        <Dialog.Header>
          <Dialog.Title>Delete {reportName}?</Dialog.Title>
        </Dialog.Header>
        <Dialog.Description as="div">
          <p>
            Deleting {reportName} will permanently remove the Pulse check
            report.
          </p>
          <p>This action cannot be undone.</p>
        </Dialog.Description>
        <Dialog.Footer>
          <Button variant="subtle" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={onDelete} disabled={deletePending}>
            {deletePending ? 'Deleting...' : 'Delete'}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  );
};
