import { Icon, setClass } from '@kandji-inc/bumblebee';
import React, { useCallback, useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import './logo-uploader.css';
// import toFile from '../settings/to-file';

const toMb = (v) => v / 1e6;

const toImage = (file) =>
  new Promise((resolve, reject) => {
    if (file) {
      const reader = new window.FileReader();
      reader.onabort = () => reject(new Error('file reading was aborted'));
      reader.onerror = () => reject(new Error('file reading has failed'));
      reader.onload = () => {
        const img = new window.Image();
        img.src = reader.result;
        img.onload = () => {
          resolve({
            height: img.height,
            width: img.width,
            name: file.name,
            size: file.size,
            dataUrl: reader.result,
            file,
          });
        };
      };
      reader.readAsDataURL(file);
    }
  });

const LogoUploader = (props) => {
  const {
    icon,
    style,
    className,
    onImage,
    maxSizeAllowed = 4e6,
    disabled = false,
    defaultIcon,
    onSelect = () => {},
    canRemove,
    onRemove = () => {},
    smallPreview = false,
    accept = 'image/*',
    isDarkMode = false,
    previewClass = '',
  } = props;

  const [image, setImage] = useState({ dataUrl: '' });
  const [imgSrc, setImgSrc] = useState();
  const [error, setError] = useState();

  const [isHoverPreview, setIsHoverPreview] = useState(false);

  const onDrop = useCallback((files) => {
    /* gets called when dropped, arg passed in is files */
    const file = files[0];
    toImage(file)
      .then((img) => onSelect(img))
      .catch(console.log);
  }, []);

  const onDropAccepted = useCallback((acceptedFiles) => {
    setError(null);
    const file = acceptedFiles[0];
    toImage(file)
      .then((im) => setImage(im))
      .catch(console.log);
  });

  const onDropRejected = useCallback(() => {
    /* gets call when rejected, arg is 'fileRejections' */
  }, []);

  useEffect(() => {
    // Icon can either be an icon object or a url/base64 of the icon.
    if (icon?.dataUrl || typeof icon === 'string') {
      setImgSrc(icon.dataUrl || icon);
    } else {
      onDropAccepted([defaultIcon]);
    }
  }, [icon, defaultIcon]);

  /* set the image when it's set */
  useEffect(() => {
    if (image.dataUrl) {
      setImgSrc(image.dataUrl);
      if (onImage) {
        onImage(image);
      }
    }
  }, [image]);

  const prevent = useCallback((e) => {
    e.preventDefault();
  }, []);

  const validator = useCallback((file) => {
    if (file.size > maxSizeAllowed) {
      setError({
        type: 'max-size',
        message: `Upload a file that has a max file size of ${toMb(
          maxSizeAllowed,
        )} MB.`,
      });
    }
  }, []);

  return (
    <div
      style={style}
      className={setClass([
        'k-library-org-logo',
        className,
        disabled && 'k-library-org-logo--disabled',
      ])}
    >
      <div
        className={setClass([
          previewClass,
          'k-library-org-logo__preview',
          smallPreview && '--small-preview',
          error && '--has-error',
          canRemove && isHoverPreview && 'k-library-org-logo__preview--delete',
          isDarkMode && '--dark-mode',
        ])}
        onClick={() => {
          if (canRemove) {
            onRemove();
            setError(null);
          }
        }}
        onMouseEnter={() => setIsHoverPreview(true)}
        onMouseLeave={() => setIsHoverPreview(false)}
      >
        {canRemove && isHoverPreview ? (
          <div className="k-library-org-logo__preview__delete-overlay">
            <Icon name="trash-can" />
          </div>
        ) : null}
        <img src={imgSrc} alt="Company Logo" />
      </div>
      <div className="k-library-org-logo__uploader">
        <Dropzone
          disabled={disabled}
          validator={validator}
          onDropAccepted={onDropAccepted}
          onDropRejected={onDropRejected}
          accept={accept}
          maxSize={maxSizeAllowed}
          onDrop={onDrop}
        >
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div
              className={setClass([
                'k-library-org-logo__droparea',
                isDragActive && 'k-library-org-logo__droparea--active',
              ])}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <Icon name="file-arrow-up" />
              <p className="b-txt">
                Drop image here or{' '}
                <a href="" onClick={prevent} className="b-alink">
                  upload
                </a>
              </p>
            </div>
          )}
        </Dropzone>
      </div>
      {error && (
        <div className="k-library-org-logo__error">
          <Icon name="octagon-exclamation" />
          <p className="b-txt">{error.message}</p>
        </div>
      )}
    </div>
  );
};

export default LogoUploader;
