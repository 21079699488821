/* istanbul ignore file - legacy code, moved */
import { Button } from '@kandji-inc/bumblebee';
import React, { useState } from 'react';
import styled from 'styled-components';

import Radio from 'app/components/good/form_styles/Radio';
import { CancelButtonWrapper } from 'app/components/interface/modals/elements';
import { BasicModal } from 'app/components/library/common/BasicModal';

const tagThemes = {
  basic: `
    background-color: #ECEEF3;
    border: none;
    font-weight: 500;
    color: #8F97AE;
  `,
  secondary: `
    background-color: #FFFFFF;
    border: 1px solid rgb(236, 238, 243);
    font-weight: 400;
    color: #8F97AE;
    `,
  device: `
    background: rgba(236, 238, 243, 0.5);
    color: #8F97AE;
  `,
};

const Tag = styled('div')`
  ${(props) => tagThemes[props.theme]};
  border-radius: 3px;
  height: 25px;
  width: fit-content;
  max-width: 275px;
  padding: 5px 10px;
  white-space: nowrap;
  font-family: var(--font-family-primary);
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-right: 8px;
  margin-top: 8px;
`;

const Wrapper = styled('div')`
  padding-top: 0;
  margin-top: -3rem;
`;

const ConflictWrapper = styled('div')`
  max-height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Title = styled('p')`
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #1a1d25;
`;

const ButtonsWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 25px;
  justify-content: flex-end;
  align-items: center;
`;

const Card = styled('div')`
  padding: 25px 25px 12px 0;
  &:not(:last-of-type) {
    border-bottom: 1px solid rgba(26, 29, 37, 0.05);
  }
`;

const RadioButtonsList = styled('div')`
  display: flex;
  width: 800px;
  flex-direction: column;
  padding-top: 8px;
`;

const RadioButtonLine = styled('div')`
  display: flex;
  flex-direction: row;
  &:not(:last-of-type) {
    margin-bottom: -10px;
  }
`;

const TextInputLabel = styled('label')`
  font-family: var(--font-family-primary);
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  color: #1a1d25;
  cursor: pointer;
`;

const Bold = styled('b')`
  font-weight: 500;
`;

export const SHAREDAssignmentBlueprintsModal = ({
  conflicts,
  itemData,
  getItemURL,
  selectedBlueprints,
  onCancel,
  onResolve,
}) => {
  const conflictedBlueprintsIds = conflicts.map(
    (conflict) => conflict.blueprint_id,
  );
  const safeBlueprints = selectedBlueprints.filter(
    (blueprint) => !conflictedBlueprintsIds.includes(blueprint),
  );
  const [resolvedBlueprints, setResolvedBlueprints] = useState(safeBlueprints);

  const addResolvedBlueprint = (blueprint) =>
    setResolvedBlueprints((resolvedBlueprints) => [
      ...resolvedBlueprints,
      blueprint,
    ]);

  const removeResolvedBlueprint = (blueprint) =>
    setResolvedBlueprints((resolvedBlueprints) =>
      resolvedBlueprints.filter((bp) => bp !== blueprint),
    );

  const getItemLink = (itemId, itemName) => (
    <a
      href={getItemURL(itemId)}
      style={{
        color: '#000',
        textDecoration: 'underline',
      }}
      rel="noopener noreferrer"
      target="_blank"
    >
      {itemName}
    </a>
  );

  const header = (
    <p style={{ textTransform: 'none', letterSpacing: '0' }}>
      Assignment Conflict
    </p>
  );

  // Formats the modal body title based on the conflict type
  const createBodyTitle = (data) => {
    if (data.type === 'macos-release') {
      return 'managed OS version';
    }
    if (data.template) {
      return data.template.replace(/(^\w|-\w)/g, (text) =>
        text.replace(/-/, ' ').toUpperCase(),
      );
    }
    return 'of this item type';
  };

  const body = (
    <Wrapper>
      <Title>{`Only one ${createBodyTitle(
        itemData,
      )} can be assigned to a Blueprint at a time.`}</Title>
      <ConflictWrapper>
        {conflicts.map((conflict) => (
          <Card>
            <Tag theme="basic">{conflict.blueprint_name}</Tag>
            <RadioButtonsList>
              <RadioButtonLine>
                <Radio.Input
                  id={`${conflict.blueprint_id}-leave`}
                  type="radio"
                  value="leave"
                  checked={!resolvedBlueprints.includes(conflict.blueprint_id)}
                  onChange={() =>
                    removeResolvedBlueprint(conflict.blueprint_id)
                  }
                />
                <TextInputLabel htmlFor={`${conflict.blueprint_id}-leave`}>
                  <p>
                    {'Leave '}
                    {getItemLink(
                      conflict.conflicted_item_id,
                      conflict.conflicted_item_name,
                    )}
                    {' enabled for the '}
                    <Bold>{conflict.blueprint_name}</Bold>
                    {' Blueprint'}
                  </p>
                </TextInputLabel>
              </RadioButtonLine>
              <RadioButtonLine>
                <Radio.Input
                  id={`${conflict.blueprint_id}-disable`}
                  type="radio"
                  value="disable"
                  checked={resolvedBlueprints.includes(conflict.blueprint_id)}
                  onChange={() => addResolvedBlueprint(conflict.blueprint_id)}
                />
                <TextInputLabel htmlFor={`${conflict.blueprint_id}-disable`}>
                  <p>{`Disable ${conflict.conflicted_item_name} and enable this item`}</p>
                </TextInputLabel>
              </RadioButtonLine>
            </RadioButtonsList>
          </Card>
        ))}
      </ConflictWrapper>
    </Wrapper>
  );

  const footer = (
    <ButtonsWrapper>
      <CancelButtonWrapper>
        <Button onClick={onCancel} kind="outline">
          Cancel
        </Button>
      </CancelButtonWrapper>
      <Button onClick={() => onResolve(resolvedBlueprints)}>Continue</Button>
    </ButtonsWrapper>
  );

  return (
    <BasicModal
      header={header}
      body={body}
      footer={footer}
      onCancel={onCancel}
    />
  );
};
