import React from 'react';
import styled from 'styled-components';
import HeaderWithLogo from './HeaderWithLogo';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.div`
  margin-top: 50px;
  width: 80%;
  font-family: var(--font-family-primary);
  font-size: 30px;
  font-weight: 500;
  line-height: 39px;
  text-align: center;
  color: #1a1d25;
`;

const EnrollWarningPage = () => (
  <Container id="enroll">
    <HeaderWithLogo pageName="Enrollment Portal" large />
    <Header>
      Sorry, this browser is unable to be used for device enrollment. Please
      open the enrollment link in Safari.
    </Header>
  </Container>
);

export default EnrollWarningPage;
