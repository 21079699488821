import {
  Box,
  Chip,
  Flex,
  Grid,
  Icon,
  Text,
  styled,
} from '@kandji-inc/nectar-ui';
import { links } from 'app/common/constants';
import { i18n } from 'i18n';
import { Link } from 'react-router-dom';
import { constants } from '../../common';
import ThreatListHashPathCode from '../../common/components/ThreatListHashPathCode';
import type { ThreatDetail } from '../../threat.types';

const SummaryDetails = styled(Flex, {
  gap: '$3',
  pt: '$1',
  alignItems: 'start',
});

const PathCodeWrapper = styled(Box, {
  '& > div': { width: 'auto' },
  '& code': { flexGrow: 0 },
  overflow: 'hidden',
});

const TextLink = styled(Text, {
  fontWeight: '$medium',
  width: 'fit-content',
  '&:hover': {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  color: '$neutral90',
});

const TextTruncated = styled(Text, {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '100%',
  maxWidth: '100%',
  fontWeight: '$medium',
  a: {
    color: '$neutral90',
  },
});

const PostureFlex = styled(Flex, {
  gap: '$1',
  marginTop: '$1',
});

const CapitalizedChip = styled(Chip, {
  '&:not(:disabled)': {
    textTransform: 'capitalize',
    backgroundColor: '$neutral0',
    border: '1px solid $neutral30',
    color: '$neutral60',
    padding: '1px 5px',
  },
});

const StyledGrid = styled(Grid, {
  fontSize: '$1',
  color: '$neutral70',
  gap: 6,
  gridTemplateColumns: '126px 1fr',
  flex: 1,
  alignItems: 'stretch',
  gridAutoRows: '22px',
});

const GridCell = styled(Flex, {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  flexDirection: 'column',
});

const ThreatListSplitViewBody = (props: {
  threat: ThreatDetail;
  query: string | undefined;
}) => {
  const { threat, query } = props;

  return (
    <Flex flow="column" gap="lg" flex={1} css={{ overflow: 'hidden' }}>
      <Box wFull css={{ height: '1px', backgroundColor: '$neutral20' }} />
      <SummaryDetails>
        <StyledGrid>
          <GridCell>
            <Text variant="description">VirusTotal</Text>
          </GridCell>
          <GridCell>
            <TextLink>
              <Flex
                gap="xs"
                onClick={
                  /* istanbul ignore next */ () =>
                    window.open(
                      `${constants.VIRUS_TOTAL_URL}${threat.file_hash}`,
                    )
                }
              >
                <Icon name="virus-total" size="sm" />
                {i18n.t('Search VirusTotal')}
              </Flex>
            </TextLink>
          </GridCell>

          <GridCell>
            <Text variant="description">{i18n.t('User')}</Text>
          </GridCell>
          <GridCell>{threat.process_owner}</GridCell>

          <GridCell>
            <Text variant="description">{i18n.t('Path')}</Text>
          </GridCell>
          <GridCell>
            <PathCodeWrapper>
              <ThreatListHashPathCode
                value={threat.file_path}
                highlight={query}
              />
            </PathCodeWrapper>
          </GridCell>

          <GridCell>
            <Text variant="description">{i18n.t('Bundle path')}</Text>
          </GridCell>
          <GridCell>
            {threat.bundle_path ? (
              <PathCodeWrapper>
                <ThreatListHashPathCode
                  value={threat.bundle_path}
                  highlight={query}
                />
              </PathCodeWrapper>
            ) : (
              '-'
            )}
          </GridCell>

          <GridCell>
            <Text variant="description">{i18n.t('Process')}</Text>
          </GridCell>
          <GridCell>{threat.process_name}</GridCell>

          <GridCell>
            <Text variant="description">{i18n.t('Blueprint')}</Text>
          </GridCell>
          <GridCell>
            <TextTruncated>
              {threat.blueprint_id ? (
                <Link
                  to={`${links.blueprints}/${threat.blueprint_id}`}
                  target="_blank"
                >
                  {threat.blueprint_name || threat.blueprint_id}
                </Link>
              ) : (
                '-'
              )}
            </TextTruncated>
          </GridCell>

          <GridCell>
            <Text variant="description">{i18n.t('Avert Library Item')}</Text>
          </GridCell>
          <GridCell css={{ height: 'fit-content' }}>
            <TextTruncated>
              {threat.library_item_id ? (
                <Link
                  to={`/library/avert/${threat.library_item_id}`}
                  target="_blank"
                >
                  {threat.library_item_name || threat.library_item_id}
                </Link>
              ) : (
                '-'
              )}
            </TextTruncated>
            <PostureFlex>
              {threat.library_item_id && threat.malware_posture && (
                <CapitalizedChip
                  size="compact"
                  color="neutral"
                  label={`Malware: ${threat.malware_posture}`}
                />
              )}
              {threat.library_item_id && threat.pup_posture && (
                <CapitalizedChip
                  size="compact"
                  color="neutral"
                  label={`PUP: ${threat.pup_posture}`}
                />
              )}
            </PostureFlex>
          </GridCell>
        </StyledGrid>
      </SummaryDetails>
    </Flex>
  );
};

export default ThreatListSplitViewBody;
