import { useQuery } from '@tanstack/react-query';

import { blueprintService } from 'src/features/library-items/data-service/blueprint/blueprint-service';

export const useBlueprints = (enabled: boolean = true) =>
  useQuery({
    queryKey: ['blueprints'],
    queryFn: () => blueprintService.find(),
    enabled,
  });
