import { Button } from '@kandji-inc/bumblebee';
import { colors } from 'app/common/constants';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import NoComputersEnrolled from '../../../assets/img/no_computers_enrolled.png';

const NoDataTableWrapper = styled.section.attrs(
  ({ borderless, isCropped }) => ({
    boxShadow: !borderless ? `0 3px 3px ${colors['grey-200']}` : 'none',
    border: !borderless ? `1px solid ${colors['grey-230']}` : 'none',
    isCropped: isCropped ? '950px' : 'none',
  }),
)`
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 70px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: ${(props) => props.boxShadow};
  border-radius: 8px;
  border: ${(props) => props.border};
  background-color: white;
  max-width: ${(props) => props.isCropped};
`;

const Image = styled.img`
  margin: ${(props) => props.imageMargin};
  width: ${(props) => props.imageWidth}px;
`;

const H2 = styled.section`
  font-family: var(--font-family-primary);
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  padding: 0;
  color: ${colors['marengo-700']};
  margin: 30px 0 20px 0;
`;

const Text = styled.section`
  font-family: var(--font-family-primary);
  font-size: 14px;
  color: ${colors['grey-500']};
  margin-bottom: 25px;
  white-space: pre-line;
`;

const TableNoDataHelper = ({
  image,
  message,
  submessage,
  btnText,
  onBtnClick,
  borderless,
  imageWidth,
  imageMargin,
  variant,
  isCropped,
}) => (
  <NoDataTableWrapper isCropped={isCropped} borderless={borderless}>
    <Image
      alt=""
      src={image}
      imageWidth={imageWidth}
      imageMargin={imageMargin}
    />
    <H2>{message}</H2>
    {submessage && <Text>{submessage}</Text>}
    {btnText && (
      <Button onClick={onBtnClick} name={btnText} kind={variant}>
        {btnText}
      </Button>
    )}
  </NoDataTableWrapper>
);

TableNoDataHelper.propTypes = {
  image: PropTypes.string,
  message: PropTypes.string.isRequired,
  submessage: PropTypes.string,
  btnText: PropTypes.string,
  onBtnClick: PropTypes.func,
  borderless: PropTypes.bool,
  imageWidth: PropTypes.number,
  imageMargin: PropTypes.string,
  variant: PropTypes.string,
};

TableNoDataHelper.defaultProps = {
  submessage: '',
  btnText: '',
  onBtnClick: null,
  image: NoComputersEnrolled,
  borderless: false,
  imageWidth: 344,
  imageMargin: '0 0 0 35px',
  variant: 'link',

  isCropped: false,
};

export default TableNoDataHelper;
