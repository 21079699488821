import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import {
  DateTimeCell,
  extractFilterableColumns,
  getFilteredDisabled as filterDisabled,
  getEnumFilterOptions,
} from '../column-utils';
import { getGlobalColumns } from './globalColumns';

export const getTransparencyDatabaseColumns = (schemas) => {
  const globalSchema = schemas.find((schema) => schema.uri === 'globals');
  const transparencyDatabaseSchema = schemas.find(
    (schema) => schema.uri === 'transparency_database',
  );

  if (!transparencyDatabaseSchema || !globalSchema) {
    return { columnDefs: [], filterableColumns: [] };
  }

  const combinedProperties = {
    ...globalSchema.schema.properties,
    ...transparencyDatabaseSchema.schema.properties,
  };

  const transparencyDatabaseColumnHelper =
    createColumnHelper<z.infer<typeof combinedProperties>>();

  const globalColumns = getGlobalColumns(
    transparencyDatabaseColumnHelper,
    combinedProperties,
  );
  const columnDefs = [
    ...globalColumns.startColumnDefs,

    transparencyDatabaseColumnHelper.accessor((row) => row.application, {
      id: 'application',
      meta: {
        displayName: combinedProperties.application.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    transparencyDatabaseColumnHelper.accessor((row) => row.service, {
      id: 'service',
      meta: {
        displayName: combinedProperties.service.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    transparencyDatabaseColumnHelper.accessor((row) => row.status, {
      id: 'status',
      meta: {
        displayName: combinedProperties.status.title,
        filterType: 'enum',
        filterOptions: getEnumFilterOptions(
          transparencyDatabaseSchema.schema?.definitions.Status.enum || [],
        ),
        filterIcon: 'list-check',
        filterDisabled,
      },
    }),

    transparencyDatabaseColumnHelper.accessor((row) => row.status_reason, {
      id: 'status_reason',
      meta: {
        displayName: combinedProperties.status_reason.title,
        filterType: 'enum',
        filterOptions: getEnumFilterOptions(
          transparencyDatabaseSchema.schema?.definitions.StatusReason.enum ||
            [],
        ),
        filterIcon: 'list-check',
        filterDisabled,
      },
    }),

    transparencyDatabaseColumnHelper.accessor((row) => row.local_user, {
      id: 'local_user',
      meta: {
        displayName: combinedProperties.local_user.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    transparencyDatabaseColumnHelper.accessor((row) => row.client_type, {
      id: 'client_type',
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.client_type.title,
        filterType: 'enum',
        filterOptions: getEnumFilterOptions(
          transparencyDatabaseSchema.schema?.definitions.ClientType.enum || [],
        ),
        filterIcon: 'list-check',
        filterDisabled,
      },
    }),

    transparencyDatabaseColumnHelper.accessor((row) => row.last_modified, {
      id: 'last_modified',
      cell: DateTimeCell,
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.last_modified.title,
        filterType: 'date-time',
        filterIcon: 'calendar',
        filterDisabled,
      },
    }),

    ...globalColumns.endColumnDefs,
  ];

  const filterableColumns = extractFilterableColumns(columnDefs);

  return { columnDefs, filterableColumns };
};
