// istanbul ignore file
import { useQuery } from '@tanstack/react-query';
import { prismService } from '../data-service/prism-service';

export const useViewsExportStatusQuery = ({
  exportId,
}: {
  exportId: string | undefined;
}) => {
  return useQuery({
    queryKey: ['views-export-status', exportId],
    queryFn: () => prismService.getViewExportStatus(exportId),
    enabled: !!exportId,
    refetchInterval: ({ state }) => {
      const status = state?.data?.status;
      const signedUrl = state?.data?.signed_url;
      if (status === 'pending' || (!signedUrl && status !== 'failed')) {
        return 1000;
      }
      return false;
    },
  });
};
