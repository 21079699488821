import { newLibraryAxios } from 'app/api/base';
import { i18n } from 'src/i18n';
import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';
import appleSystemApps from './apple-system-apps';

import imagePlayground from 'features/library-items/items/home-screen-layout/service/apple-system-apps/image-playground';
import FeatureFlags from 'src/config/feature-flags';
import type {
  App,
  GetLibraryItemsApiResponseApp,
} from '../home-screen-layout.types';

class HomeScreenLayoutService extends NewLibraryItemService {
  public static readonly deviceToName = {
    iphone: () => i18n.t('iPhone'),
    ipad: () => i18n.t('iPad'),
  } as const;

  public static readonly systemApps = appleSystemApps;

  public async listApps() {
    const appListRes: Awaited<{
      data: { results: ReadonlyArray<GetLibraryItemsApiResponseApp> };
    }> = await newLibraryAxios('').get({
      type__in: ['vpp-app', 'ipa-app', 'ipa-app-v2', 'profile'].join(', '),
      limit: 50000,
      include_data: true,
    });

    const appStoreAndInHouseApps: ReadonlyArray<
      App | GetLibraryItemsApiResponseApp
    > = appListRes?.data?.results;

    if (FeatureFlags.getFlag('ios-image-playground')) {
      HomeScreenLayoutService.systemApps['Image Playground'] = imagePlayground;
    }

    if (appStoreAndInHouseApps) {
      const systemAppList = Object.values(HomeScreenLayoutService.systemApps);
      return appStoreAndInHouseApps.concat(systemAppList);
    }

    return [];
  }
}

export const homeScreenLayoutService = new HomeScreenLayoutService();

export default HomeScreenLayoutService;
