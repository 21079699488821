/* istanbul ignore file */
export const versionTestForBypassCode = {
  Mac: '10.15+',
  iPhone: '7.0+',
  iPod: '7.0+',
  iPad: '7.0+',
  AppleTV: '7.0+',
};

export const versionTestForShutdownDevice = {
  Mac: '10.13+',
  iPhone: '11.0+',
  iPod: '11.0+',
  iPad: '11.0+',
  AppleTV: '11.0+',
};
