import { Button } from '@kandji-inc/bumblebee';
import { colors } from 'app/common/constants';
import trim from 'lodash/trim';
import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import { H2 } from './Typography';

const Input = styled.input`
  background: transparent;
  border: none;
  -webkit-appearance: none;
  outline: none;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.2em;
  text-align: left;
  width: 100%;
  height: 24px;
  text-transform: uppercase;
  box-shadow: 0 2px 0 0
    ${(props) => colors[props.warning ? 'red-500' : 'grey-300']};
  color: ${(props) => (props.warning ? '#EF0D33' : '#000')};
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  margin-left: 20px;
  height: 24px;
`;

const StyledH2 = styled(H2)`
  cursor: pointer;
  color: ${colors.marengo680};
`;

class EditableH2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
    };
    this.inputRef = createRef();
    this.setEditMode = this.setEditMode.bind(this);
  }

  setEditMode = (value) => {
    this.setState({ editMode: value }, () => {
      if (this.inputRef.current) {
        this.inputRef.current.focus();
      }
    });
  };

  render() {
    const { editMode } = this.state;
    const { value, onChange } = this.props;
    return (
      <>
        {editMode && (
          <InputWrapper>
            <Input
              ref={this.inputRef}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              warning={!trim(value)}
              data-testid="edit-title"
            />
            <ButtonWrapper>
              <Button
                onClick={() => this.setEditMode(false)}
                disabled={!trim(value)}
              >
                Apply
              </Button>
            </ButtonWrapper>
          </InputWrapper>
        )}
        {!editMode && (
          <StyledH2
            onClick={() => this.setEditMode(true)}
            data-testid="title-input"
          >
            {value}
          </StyledH2>
        )}
      </>
    );
  }
}

EditableH2.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default EditableH2;
