import moment from 'moment';
import { v4 as uuid } from 'uuid';
import initialState from '../../../../initial-state';
import { protocolOptions } from '../../../../protocols-card/protocols-card.model';
import EthernetService from '../../../ethernet-service';

const { authentication: authKeys } = EthernetService.keys;

const authentication = (data) => {
  if (!data) {
    return initialState.authentication;
  }

  const {
    authentication: authenticationData = {},
    certificate = {},
    Interface,
  } = data;

  const { settings = {} } = authenticationData || {};
  const { SetupModes = [], EAPClientConfiguration = {} } = settings || {};

  const {
    type = EthernetService.identityCertificates.NONE,
    adcsUpdatedAt,
    scepUpdatedAt,
    settings: certificateSettings = {},
  } = certificate || {};

  const acceptEapTypes =
    EAPClientConfiguration?.AcceptEapTypes?.map((v) =>
      protocolOptions.find(({ value }) => v === value),
    )?.filter(Boolean) || [];

  const hasTLSVersions = [
    EthernetService.protocols.TLS,
    EthernetService.protocols.TTLS,
    EthernetService.protocols.PEAP,
    EthernetService.protocols.EAP_FAST,
  ].some((protocol) => acceptEapTypes.find(({ value }) => protocol === value));

  const isTTLS = acceptEapTypes.find(
    ({ value }) => EthernetService.protocols.TTLS === value,
  );

  const withScepSubjectAltName = certificateSettings?.scep
    ? certificateSettings.scep.SubjectAltName !== null
    : false;

  const withAdcsSubjectAltName = certificateSettings?.adcs
    ? certificateSettings.adcs.san !== null
    : false;

  const getSubjectAltName = (withSan, san) =>
    withSan
      ? Object.keys(san).reduce(
          (a, c) => [
            ...a,
            ...(san[c]
              ? san[c].map((alt) => ({
                  _id: uuid(),
                  type: c,
                  value: alt,
                }))
              : []),
          ],
          [],
        )
      : [{ _id: uuid(), type: null, value: '' }];

  const getSubjectAltNameForAdcs = (withSan, san) => {
    const sanWindowsToNormal = Object.keys(
      EthernetService.subjectAlternativeNameTypesWindows,
    ).reduce((ret, key) => {
      ret[EthernetService.subjectAlternativeNameTypesWindows[key]] = key;
      return ret;
    }, {});

    return withSan
      ? san.map((s) => ({
          ...s,
          _id: uuid(),
          type: sanWindowsToNormal[s.type],
        }))
      : [{ _id: uuid(), type: null, value: '' }];
  };

  return {
    [authKeys.interface]: Interface || EthernetService.interfaces.GLOBAL,
    [authKeys.useLoginWindow]: SetupModes?.includes('Loginwindow'),
    [authKeys.identityCertificate]:
      type || EthernetService.identityCertificates.NONE,

    [authKeys.acceptedEapTypes.acceptedEapTypes]: acceptEapTypes,
    [authKeys.acceptedEapTypes.username]:
      EAPClientConfiguration?.UserName || '',
    [authKeys.acceptedEapTypes.promptForPassword]:
      EAPClientConfiguration?.OneTimeUserPassword
        ? EthernetService.promptPasswordValues.EVERY_TIME
        : EthernetService.promptPasswordValues.ONCE,
    [authKeys.acceptedEapTypes.password]:
      EAPClientConfiguration?.UserPassword || '',
    [authKeys.acceptedEapTypes.authentication]:
      EAPClientConfiguration?.SystemModeCredentialsSource === 'ActiveDirectory'
        ? EthernetService.authentications.COMPUTER_AD
        : EAPClientConfiguration?.SystemModeUseOpenDirectoryCredentials
          ? EthernetService.authentications.COMPUTER_OD
          : EthernetService.authentications.USERNAME_AND_PASSWORD,
    [authKeys.acceptedEapTypes.innerAuthentication]: isTTLS
      ? EAPClientConfiguration?.TTLSInnerAuthentication
      : EthernetService.innerAuthentications.MSCHAPv2,
    [authKeys.acceptedEapTypes.outerIdentity]:
      EAPClientConfiguration?.OuterIdentity || '',
    [authKeys.acceptedEapTypes.tlsMinimumVersion]: hasTLSVersions
      ? EAPClientConfiguration?.TLSMinimumVersion
      : EthernetService.tlsVersions.v1_0,
    [authKeys.acceptedEapTypes.tlsMaximumVersion]: hasTLSVersions
      ? EAPClientConfiguration?.TLSMaximumVersion
      : EthernetService.tlsVersions.v1_2,
    [authKeys.acceptedEapTypes.usePac]:
      EAPClientConfiguration?.EAPFASTUsePAC || false,
    [authKeys.acceptedEapTypes.provisionPac]:
      EAPClientConfiguration?.EAPFASTProvisionPAC || false,
    [authKeys.acceptedEapTypes.provisionPacAnonymously]:
      EAPClientConfiguration?.EAPFASTProvisionPACAnonymously || false,
    [authKeys.acceptedEapTypes.twoFactorAuth]:
      EAPClientConfiguration?.TLSCertificateIsRequired || false,

    // PKCS12
    [authKeys.pkcs.allowAccessToThePrivateKey]:
      certificateSettings?.pkcs12?.AllowAllAppsAccess || false,
    [authKeys.pkcs.preventExtraction]:
      certificateSettings?.pkcs12?.KeyIsExtractable != null
        ? !certificateSettings?.pkcs12?.KeyIsExtractable
        : false,
    [authKeys.pkcs.allowAccessToThePrivateKey]:
      certificateSettings?.pkcs12?.AllowAllAppsAccess || false,
    [authKeys.pkcs.password]: certificateSettings?.pkcs12?.Password || '',
    [authKeys.pkcs.file]:
      type === EthernetService.identityCertificates.PKCS12
        ? {
            base64: certificateSettings?.pkcs12?.PayloadContent,
            name: certificate?.fileName || '',
            size: certificate?.fileSize || 0,
            uploadedAt: certificate?.fileUploadedAt || moment.now(),
          }
        : null,

    // SCEP
    [authKeys.scep.url]: certificateSettings?.scep?.URL || '',
    [authKeys.scep.name]: certificateSettings?.scep?.Name || '',
    [authKeys.scep.challenge]: certificateSettings?.scep?.Challenge || '',
    [authKeys.scep.fingerprint]: certificateSettings?.scep?.CAFingerprint || '',
    [authKeys.scep.subject]: certificateSettings?.scep?.Subject || '',
    [authKeys.scep.withSan]: withScepSubjectAltName,
    [authKeys.scep.san]: getSubjectAltName(
      withScepSubjectAltName,
      certificateSettings?.scep?.SubjectAltName,
    ),
    [authKeys.scep.keySize]:
      certificateSettings?.scep?.Keysize || EthernetService.keySizes.s1024,
    [authKeys.scep.keyUsage]:
      certificateSettings?.scep?.['Key Usage'] ||
      EthernetService.keyUsages.NONE,
    [authKeys.scep.retries]:
      certificateSettings?.scep?.Retries != null
        ? String(certificateSettings.scep.Retries)
        : '3',
    [authKeys.scep.withRetries]: certificateSettings?.scep?.Retries != null,
    [authKeys.scep.retryDelay]:
      certificateSettings?.scep?.RetryDelay != null
        ? String(certificateSettings?.scep?.RetryDelay)
        : '10',
    [authKeys.scep.withRetryDelay]:
      certificateSettings?.scep?.RetryDelay != null,
    [authKeys.scep.disallowKeyExtraction]:
      certificateSettings?.scep?.KeyIsExtractable !== null
        ? !certificateSettings?.scep?.KeyIsExtractable
        : false,
    [authKeys.scep.allowAccessToApps]:
      certificateSettings?.scep?.AllowAllAppsAccess,
    [authKeys.scep.withCertificateExpirationNotification]:
      certificateSettings?.scep?.certificateExpirationNotification != null,
    [authKeys.scep.certificateExpirationNotification]:
      certificateSettings?.scep?.certificateExpirationNotification != null
        ? String(certificateSettings?.scep?.certificateExpirationNotification)
        : '14',
    [authKeys.scep.withAutoProfileRedistribution]:
      certificateSettings?.scep?.autoProfileRedistribution != null,
    [authKeys.scep.autoProfileRedistribution]:
      certificateSettings?.scep?.autoProfileRedistribution != null
        ? String(certificateSettings?.scep?.autoProfileRedistribution)
        : '30',
    [authKeys.scep.updatedAt]: scepUpdatedAt,

    // AD CS
    [authKeys.adcs.name]: certificateSettings?.adcs?.name || '',
    [authKeys.adcs.template]: certificateSettings?.adcs?.template || '',
    [authKeys.adcs.subject]: certificateSettings?.adcs?.subject || '',
    [authKeys.adcs.server]: certificateSettings?.adcs?.authority || '',
    [authKeys.adcs.keySize]:
      certificateSettings?.adcs?.key_size || EthernetService.keySizes.s1024,
    [authKeys.adcs.preventExtraction]:
      certificateSettings?.adcs?.KeyIsExtractable !== null
        ? !certificateSettings?.adcs?.KeyIsExtractable
        : false,
    [authKeys.adcs.allowAccessToApps]:
      certificateSettings?.adcs?.AllowAllAppsAccess,
    [authKeys.adcs.withSan]: withAdcsSubjectAltName,
    [authKeys.adcs.san]: getSubjectAltNameForAdcs(
      withAdcsSubjectAltName,
      certificateSettings?.adcs?.san,
    ),
    [authKeys.adcs.updatedAt]: adcsUpdatedAt,
  };
};

export default authentication;
