import { Checkbox } from '@kandji-inc/bumblebee';
import React from 'react';
import { i18n } from 'src/i18n';
import DescriptionWithDefault from './description-with-default';

const ContentWithDefaults = (props) => {
  const { update, setting } = props;
  const { isRequireRead } = setting;

  return (
    <div className="k-ss2-drawer-section --content">
      <h3 className="b-h3">Content</h3>

      <DescriptionWithDefault
        {...props}
        fieldName="shortDescription"
        label={i18n.t('Display short description')}
        helper={i18n.t(
          'A short description is displayed under the Library Item name. The provided description is pulled directly from the App Store.',
        )}
        placeholder={i18n.t(
          'Add a custom short description of the Library Item.',
        )}
        maxLength={170}
      />

      <DescriptionWithDefault
        {...props}
        fieldName="longDescription"
        label={i18n.t('Display long description')}
        helper={i18n.t(
          'A long description is displayed in the item detail page. The provided description is pulled directly from the App Store.',
        )}
        placeholder={i18n.t(
          'Add a custom long description of the Library Item.',
        )}
        maxLength={4000}
        bottomSlot={
          <div className="k-ss2-drawer-setting-extra">
            <p className="b-txt b-mb1">{i18n.t('Additional option')}</p>
            <Checkbox
              checked={isRequireRead}
              onChange={() => update('isRequireRead', !isRequireRead)}
              label={i18n.t(
                'Require users to read the description before installing',
              )}
            />
          </div>
        }
      />
    </div>
  );
};

export default ContentWithDefaults;
