import configs from 'features/library-items/library/library-item-configurations/items';
import omit from 'lodash/omit';
import {
  getRunsOnFromModel,
  getSelectedDevicesFromRunsOn,
} from 'src/features/library-items/data-service/library-item/devices';
/* istanbul ignore file */
import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import type {
  KeyModelSetting,
  ScepModel,
  ScepModelSettings,
} from '../scep.types';
import type {
  ScepApiPayloadSettings,
  ScepApiRequestPayload,
  ScepApiResponsePayload,
} from './transformers.types';

function transformSettings(
  settings: ScepApiPayloadSettings,
): ScepModelSettings {
  return {
    general: {
      URL: settings.URL,
      Name: settings.Name,
      Challenge: settings.Challenge,
      CAFingerprint: settings.CAFingerprint,
    },

    subject: {
      Subject: settings.Subject,
      SubjectAlternativeNameType: settings.SubjectAlternativeNameType,
      SubjectAltName: settings.SubjectAltName,
      ntPrincipalName: settings.ntPrincipalName,
    },

    key: {
      Keysize: settings.Keysize as KeyModelSetting['Keysize'],
      'Key Usage': settings['Key Usage'] as KeyModelSetting['Key Usage'],
    },

    options: {
      Retries: settings.Retries,
      RetryDelay: settings.RetryDelay,
      KeyIsExtractable: settings.KeyIsExtractable,
      AllowAllAppsAccess: settings.AllowAllAppsAccess,
      CertificateRenewalTimeInterval: settings.CertificateRenewalTimeInterval,
      Redistribution: settings.Redistribution,
    },
  };
}

async function transformFromApi(apiData: ScepApiResponsePayload) {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const commonData = {
    id: apiData.id,
    name: apiData.name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
    devices: getSelectedDevicesFromRunsOn(apiData),
  };

  const result: ScepModel = {
    ...commonData,
    isActive: apiData.active,
    ...transformSettings(apiData.data),
  };

  return {
    ...apiData,
    data: result,
  };
}

function transformToApi(model: ScepModel): ScepApiRequestPayload {
  const toSend: ScepApiRequestPayload = {
    name: model.name,
    active: model.isActive,
    blueprints: model.selectedBlueprints.map((b) => b.value),
    is_all_blueprints: model.isAllBlueprints,
    data: {
      ...omit(model.general, ['invalidationsMap']),
      ...omit(model.subject, ['invalidationsMap']),
      ...omit(model.key, ['invalidationsMap']),
      ...omit(model.options, ['invalidationsMap']),
    },
  };

  const runsOn = getRunsOnFromModel(model);
  Object.keys(runsOn).forEach((runsOnKey) => {
    toSend[runsOnKey] = runsOn[runsOnKey];
  });

  if (!model.id) {
    const itemConfig = configs.SCEP;

    toSend.type = itemConfig.type;
    toSend.identifier = itemConfig.identifier;
  }

  return toSend;
}

export { transformToApi, transformFromApi };
