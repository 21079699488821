import { Button, Flex } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';

type ExportDropdownFooterProps = {
  exportType: string;
  onCancel: () => void;
  onExport: () => void;
};

const ExportDropdownFooter = (props: ExportDropdownFooterProps) => {
  const { exportType, onCancel, onExport } = props;

  const handleExport = () => {
    onExport();
    onCancel();
  };

  return (
    <Flex
      gap="sm"
      justifyContent="end"
      alignItems="center"
      css={{ p: '16px 12px' }}
    >
      <Button compact onClick={onCancel}>
        {i18n.t('Cancel')}
      </Button>
      <Button
        compact
        onClick={handleExport}
        variant="primary"
        className="pendo-prism-start-export"
        value={exportType}
      >
        {i18n.t('Start export')}
      </Button>
    </Flex>
  );
};

export default ExportDropdownFooter;
