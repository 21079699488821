/* istanbul ignore file */
import { useCallback, useMemo, useState } from 'react';
import type { SortState } from 'src/components';

const useVulnerabilitySort = () => {
  // By default, we sort by severity in descending order
  const [sortBy, setSortBy] = useState('-cvss_score');

  const handleSort = useCallback(
    ({ col, direction }: SortState) => {
      if (direction === 'none') {
        setSortBy('');
        return;
      }

      // Add a prefix to indicate to the API in which direction to sort
      const prefix = direction === 'asc' ? '+' : '-';
      setSortBy(`${prefix}${col}`);
    },
    [setSortBy],
  );

  const sort = useMemo(() => {
    if (sortBy === null || sortBy === '') {
      return { col: '', direction: 'none' };
    }

    const [direction, col] = sortBy.startsWith('-')
      ? // Get the column name by removing the prefix
        ['desc', sortBy.slice(1)]
      : ['asc', sortBy.slice(1)];

    return { col, direction };
  }, [sortBy]) as SortState;

  return { sort, sortBy, handleSort };
};

export { useVulnerabilitySort };
