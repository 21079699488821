import { generateRowConfig } from './sections/configurations/rows.config';

const initialState = () => ({
  id: '',
  name: '',
  isActive: true,
  blueprints: [],
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,

  configurations: {
    invalidationsMap: {},
    vpnConfigurations: [generateRowConfig()],
  },

  supportsRules: true,
});

export default initialState;
