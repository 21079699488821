import { updateSetting as update } from '@kandji-inc/bumblebee';
import React, { useCallback } from 'react';

import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import NewStatusTab from 'features/library-items/common/new-status-tab/new-status-tab';
import ActivityTab from 'features/library-items/items/air-print/activity-tab';
import { i18n } from 'src/i18n';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import { getNewStatusTabProps } from '../../common/new-status-tab/util';
import config from '../../library/library-item-configurations/items/profile';
import GeneralCard from './general-card';
import initial from './initial-state';
import { gatekeeperService } from './service/gatekeeper-service';
import { transformFromApi, transformToNewApi } from './service/transformers';
import useGatekeeperService from './service/use-gatekeeper-service';

const GateKeeperPage = (props) => {
  const { model, setModel, pageState, blueprintOptions } = props;
  const [validationDep, triggerValidation] = useUniqValue();

  const generalUpdate = useCallback(update('general', setModel), []);

  const itemConfig = config.Gatekeeper;

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.getName()}
      summaryInfoProps={{
        name: `${itemConfig.getName()} ${i18n.t('Profile')}`,
        description: itemConfig.getDescription(),
        publisher: itemConfig.getPublisher(),
        devices: itemConfig.devices,
        requirements: itemConfig.getOsRequirements(),
      }}
      triggerValidation={triggerValidation}
      transformToApi={transformToNewApi}
      transformFromApi={transformFromApi}
      service={gatekeeperService}
      defaultIcon={itemConfig.icon}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      supportsRules
      supportsDuplication
    >
      <GeneralCard
        setting={model.general}
        isDisabled={pageState.isDisabled}
        update={generalUpdate}
        validationDep={validationDep}
      />
    </LibraryItemPage>
  );
};

const GateKeeper = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState: initial,
    useService: useGatekeeperService,
  });
  const blueprintConflicts = useBlueprintConflicts();

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <GateKeeperPage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
      testId="gatekeeper-v2"
    />
  );
};

export default GateKeeper;
