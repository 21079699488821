import { useInfiniteQuery } from '@tanstack/react-query';
import { api } from 'src/app/api/base';
import { URL_BLUEPRINTS_V2 } from 'src/app/api/urls';

export async function getADEBlueprints(
  search: string,
  page: number,
): Promise<any> {
  try {
    const res = await api(URL_BLUEPRINTS_V2).get({
      search,
      page,
      sizePerPage: 25,
    });
    const { data } = res;

    return data.results ?? [];
  } catch (error) {
    return [];
  }
}

export function useGetADEBlueprints(search: string) {
  return useInfiniteQuery({
    queryKey: ['get-ade-blueprints', search],
    queryFn: ({ pageParam }) => getADEBlueprints(search, pageParam),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) =>
      /* istanbul ignore next */
      lastPage.length ? pages.length + 1 : undefined,
  });
}
