import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import { setBlueprintsToFormData } from '../../../data-service/library-item/transformers';
import CustomScriptService from './custom-script-service';

export const transformFromApi = async (apiData) => {
  try {
    const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
    const excludedBlueprints = await getAllBlueprintOptions(
      apiData.excluded_blueprints,
    );

    const result = {
      _original: apiData,
      id: apiData.id,
      name: apiData.name,
      iconSrc: apiData.app_icon,
      isActive: apiData.active,
      selectedBlueprints,
      excludedBlueprints,
      isAllBlueprints: apiData.is_all_blueprints,
    };
    result.executionFrequency = {
      type: apiData.execution_frequency,
    };
    result.selfService = {
      isEnabled: apiData.data.show_in_self_service,
      category: apiData.data.self_service_data?.category,
      isRecommended: !!apiData.data.self_service_data?.isRecommended,
      displayName: apiData.data.self_service_data?.displayName,
      shortDescription: apiData.data.self_service_data?.description,
      longDescription: apiData.data.self_service_data?.bodyText,
      isRequireRead: apiData.data.self_service_data?.mustViewAgreement,
      iconUrl: apiData.data.self_service_data?.iconUrl,
      iconS3Key: apiData.data.self_service_data?.iconS3Key,
      iconFile: null,
      version: '-',
      size: apiData.file_size,
      installBtnText: apiData.data.self_service_data?.installBtnText, // ??
      displayInfoBtn: apiData.data.self_service_data?.displayInfoBtn, // ??
      infoBtnText: apiData.data.self_service_data?.infoBtnText, // ??
      infoBtnUrl: apiData.data.self_service_data?.infoBtnUrl, // ??
    };
    result.scriptDetails = {
      auditScript: apiData.script,
      remediationScript: apiData.remediation_script,
      restart: apiData.restart,
    };

    result.rules = apiData.rules;

    return {
      ...apiData,
      data: result,
    };
  } catch (e) {
    console.error(e);
    throw e.message;
  }
};

export const transformToApi = (model) => {
  const toSend = new FormData();
  toSend.set('type', 'custom-script'); // to create
  toSend.set('name', model.name);
  if (model.iconFile) {
    toSend.set('app_icon', model.iconFile);
  }
  if (!model.iconSrc) {
    toSend.set('app_icon', '');
  }
  toSend.set('active', model.isActive);
  setBlueprintsToFormData(toSend, model.selectedBlueprints);
  toSend.set('is_all_blueprints', model.isAllBlueprints);
  toSend.set('script', model.isActive);
  toSend.set('rules', JSON.stringify(model.rules) || null);

  const data = {
    install_enforcement:
      CustomScriptService.enforcementAndFrequencyMap[
        model.executionFrequency.type
      ],
    show_in_self_service: model.selfService.isEnabled,
  };
  if (model.selfService.isEnabled) {
    data.self_service_data = {
      isRecommended: model.selfService.isRecommended,
      iconS3Key: model.selfService.iconS3Key,
      displayName: model.selfService.displayName,
      description: model.selfService.shortDescription,
      bodyText: model.selfService.longDescription,
      mustViewAgreement: model.selfService.isRequireRead,
      category: model.selfService.category,
      installBtnText: model.selfService.installBtnText,
      displayInfoBtn: model.selfService.displayInfoBtn,
      infoBtnText: model.selfService.infoBtnText,
      infoBtnUrl: model.selfService.infoBtnUrl,
    };
  }
  toSend.set('data', JSON.stringify(data));
  toSend.set('script', model.scriptDetails.auditScript);
  toSend.set('remediation_script', model.scriptDetails.remediationScript);
  toSend.set('restart', model.scriptDetails.restart);
  toSend.set('execution_frequency', model.executionFrequency.type);

  return toSend;
};
