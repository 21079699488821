import classSet from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Const from './Const';
import DateFilter from './filters/Date';
import NumberFilter from './filters/Number';
import RegexFilter from './filters/Regex';
import SelectFilter from './filters/Select';
import TextFilter from './filters/Text';
import Util from './util';

class TableHeaderColumn extends Component {
  constructor(props) {
    super(props);
    this.handleFilter = this.handleFilter.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.reset) {
      this.cleanFiltered();
    }

    // If column not displaying the same dataField, reset the filter accordingly
    if (nextProps.filter && nextProps.dataField !== this.props.dataField) {
      const emitter = nextProps.filter.emitter || {};
      const currentFilter = emitter.currentFilter || {};
      const filter = currentFilter[nextProps.dataField];
      const value = filter ? filter.value : '';

      const { ref } = this.getFilters(nextProps) || {};

      if (this.refs[ref]) {
        this.refs[ref].setState({ value });
      }
    }
    if (nextProps.dataField !== this.props.dataField) {
      this.headerCol.setAttribute('data-field', nextProps.dataField);
    }
  }

  handleColumnClick = (e) => {
    e.stopPropagation();
    const {
      isOnlyHead,
      dataSort,
      defaultASC,
      sort,
      onSort,
      sortName,
      dataField,
    } = this.props;
    if (e.target.classList.contains('resizer')) {
      return;
    }
    if (isOnlyHead || !dataSort) {
      return;
    }
    let { sort: order } = this.props;
    if (!order && defaultASC) {
      order = Const.SORT_ASC;
    } else {
      order = sort === Const.SORT_DESC ? Const.SORT_ASC : Const.SORT_DESC;
    }
    onSort(order, sortName || dataField, dataField);
  };

  handleFilter(value, type) {
    const { filter } = this.props;
    filter.emitter.handleFilter(this.props.dataField, value, type, filter);
  }

  getFilters(props = this.props) {
    const { headerText, children } = props;
    switch (props.filter.type) {
      case Const.FILTER_TYPE.TEXT: {
        return (
          <TextFilter
            ref={(n) => (this.textFilter = n)}
            {...props.filter}
            columnName={headerText || children}
            filterHandler={this.handleFilter}
          />
        );
      }
      case Const.FILTER_TYPE.REGEX: {
        return (
          <RegexFilter
            ref={(n) => (this.regexFilter = n)}
            {...props.filter}
            columnName={headerText || children}
            filterHandler={this.handleFilter}
          />
        );
      }
      case Const.FILTER_TYPE.SELECT: {
        return (
          <SelectFilter
            ref={(n) => (this.selectFilter = n)}
            {...props.filter}
            columnName={headerText || children}
            filterHandler={this.handleFilter}
          />
        );
      }
      case Const.FILTER_TYPE.NUMBER: {
        return (
          <NumberFilter
            ref={(n) => (this.numberFilter = n)}
            {...props.filter}
            columnName={headerText || children}
            filterHandler={this.handleFilter}
          />
        );
      }
      case Const.FILTER_TYPE.DATE: {
        return (
          <DateFilter
            ref={(n) => (this.dateFilter = n)}
            {...props.filter}
            columnName={headerText || children}
            filterHandler={this.handleFilter}
          />
        );
      }
      case Const.FILTER_TYPE.CUSTOM: {
        const elm = props.filter.getElement(
          this.handleFilter,
          props.filter.customFilterParameters,
        );

        return React.cloneElement(elm, { ref: (n) => (this.customFilter = n) });
      }
    }
  }

  componentDidMount() {
    this.headerCol.setAttribute('data-field', this.props.dataField);
  }

  renderDefaultCaret(dataSort, isBootstrap4) {
    if (!dataSort) {
      return null;
    }
    if (isBootstrap4) {
      // TODO: Move style to css
      return null;
    }
    return (
      <span className="order">
        <span className="dropdown">
          <span
            className="caret"
            style={{ margin: '10px 0 10px 5px', color: '#8ea5bc' }}
          />
        </span>
        <span className="dropup">
          <span
            className="caret"
            style={{ margin: '10px 0', color: '#8ea5bc' }}
          />
        </span>
      </span>
    );
  }

  render() {
    let defaultCaret;
    let sortCaret;
    let sortClass;
    const {
      headerText,
      dataAlign,
      dataField,
      headerAlign,
      headerTitle,
      hidden,
      sort,
      dataSort,
      sortIndicator,
      children,
      caretRender,
      className,
      isOnlyHead,
      version,
      sortHeaderColumnClassName: customSortClass,
      thStyle: style,
    } = this.props;
    const thStyle = {
      textAlign: headerAlign || dataAlign,
      display: hidden ? 'none' : null,
      ...style,
    };
    const isBootstrap4 = Util.isBootstrap4(version);
    if (!isOnlyHead) {
      if (sortIndicator) {
        defaultCaret = this.renderDefaultCaret(dataSort, isBootstrap4);
      }
      sortCaret = sort
        ? Util.renderReactSortCaret(sort, isBootstrap4)
        : defaultCaret;
      if (caretRender) {
        sortCaret = caretRender(sort, dataField);
      }
    }

    if (sort) {
      sortClass = Util.isFunction(customSortClass)
        ? customSortClass(sort, dataField)
        : customSortClass;
    }
    const classes = classSet(
      Util.isFunction(className) ? className() : className,
      !isOnlyHead && dataSort ? 'sort-column' : '',
      sortClass,
    );

    const attr = {};
    if (headerTitle) {
      if (typeof children === 'string' && !headerText) {
        attr.title = children;
      } else {
        attr.title = headerText;
      }
    }
    return (
      <th
        ref={(node) => (this.headerCol = node)}
        className={classes}
        style={thStyle}
        onClick={(e) => !this.props.resizing && this.handleColumnClick(e)}
        rowSpan={this.props.rowSpan}
        colSpan={this.props.colSpan}
        data-is-only-head={this.props.isOnlyHead}
        {...attr}
      >
        {children}
        {sortCaret}
        <div onClick={(e) => e.stopPropagation()}>
          {this.props.filter && !isOnlyHead ? this.getFilters() : null}
        </div>
      </th>
    );
  }

  cleanFiltered() {
    if (!this.props.filter) {
      return;
    }

    switch (this.props.filter.type) {
      case Const.FILTER_TYPE.TEXT: {
        this.textFilter.cleanFiltered();
        break;
      }
      case Const.FILTER_TYPE.REGEX: {
        this.regexFilter.cleanFiltered();
        break;
      }
      case Const.FILTER_TYPE.SELECT: {
        this.selectFilter.cleanFiltered();
        break;
      }
      case Const.FILTER_TYPE.NUMBER: {
        this.numberFilter.cleanFiltered();
        break;
      }
      case Const.FILTER_TYPE.DATE: {
        this.dateFilter.cleanFiltered();
        break;
      }
      case Const.FILTER_TYPE.CUSTOM: {
        this.customFilter.cleanFiltered();
        break;
      }
    }
  }

  applyFilter(val) {
    if (!this.props.filter) {
      return;
    }
    switch (this.props.filter.type) {
      case Const.FILTER_TYPE.TEXT: {
        this.textFilter.applyFilter(val);
        break;
      }
      case Const.FILTER_TYPE.REGEX: {
        this.regexFilter.applyFilter(val);
        break;
      }
      case Const.FILTER_TYPE.SELECT: {
        this.selectFilter.applyFilter(val);
        break;
      }
      case Const.FILTER_TYPE.NUMBER: {
        this.numberFilter.applyFilter(val);
        break;
      }
      case Const.FILTER_TYPE.DATE: {
        this.dateFilter.applyFilter(val);
        break;
      }
    }
  }
}

const filterTypeArray = [];
Object.keys(Const.FILTER_TYPE).forEach((key) => {
  filterTypeArray.push(Const.FILTER_TYPE[key]);
});

TableHeaderColumn.propTypes = {
  dataField: PropTypes.string,
  dataAlign: PropTypes.string,
  headerAlign: PropTypes.string,
  headerTitle: PropTypes.bool,
  headerText: PropTypes.string,
  dataSort: PropTypes.bool,
  onSort: PropTypes.func,
  dataFormat: PropTypes.func,
  csvFormat: PropTypes.func,
  csvHeader: PropTypes.string,
  csvFieldType: PropTypes.oneOf([Const.CSV_STRING_TYPE, Const.CSV_NUMBER_TYPE]),
  isKey: PropTypes.bool,
  editable: PropTypes.any,
  hidden: PropTypes.bool,
  hiddenOnInsert: PropTypes.bool,
  searchable: PropTypes.bool,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  width: PropTypes.string,
  sortName: PropTypes.string,
  sortFunc: PropTypes.func,
  sortFuncExtraData: PropTypes.any,
  sortHeaderColumnClassName: PropTypes.any,
  columnClassName: PropTypes.any,
  editColumnClassName: PropTypes.any,
  invalidEditColumnClassName: PropTypes.any,
  columnTitle: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
    PropTypes.string,
  ]),
  filterFormatted: PropTypes.bool,
  filterValue: PropTypes.func,
  sort: PropTypes.string,
  caretRender: PropTypes.func,
  formatExtraData: PropTypes.any,
  csvFormatExtraData: PropTypes.any,
  filter: PropTypes.shape({
    type: PropTypes.oneOf(filterTypeArray),
    delay: PropTypes.number,
    options: PropTypes.oneOfType([
      PropTypes.object, // for SelectFilter
      PropTypes.arrayOf(PropTypes.number), // for NumberFilter
    ]),
    numberComparators: PropTypes.arrayOf(PropTypes.string),
    emitter: PropTypes.object,
    placeholder: PropTypes.string,
    getElement: PropTypes.func,
    customFilterParameters: PropTypes.object,
    condition: PropTypes.oneOf([Const.FILTER_COND_EQ, Const.FILTER_COND_LIKE]),
  }),
  sortIndicator: PropTypes.bool,
  export: PropTypes.bool,
  expandable: PropTypes.bool,
  tdAttr: PropTypes.object,
  editTdAttr: PropTypes.object,
  tdStyle: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  thStyle: PropTypes.object,
  keyValidator: PropTypes.bool,
  defaultASC: PropTypes.bool,
};

TableHeaderColumn.defaultProps = {
  dataAlign: 'left',
  headerAlign: undefined,
  headerTitle: true,
  dataSort: false,
  dataFormat: undefined,
  csvFormat: undefined,
  csvHeader: undefined,
  csvFieldType: Const.CSV_STRING_TYPE,
  isKey: false,
  editable: true,
  onSort: undefined,
  hidden: false,
  hiddenOnInsert: false,
  searchable: true,
  className: '',
  columnTitle: false,
  width: null,
  sortName: '',
  sortFunc: undefined,
  columnClassName: '',
  editColumnClassName: '',
  invalidEditColumnClassName: '',
  filterFormatted: false,
  filterValue: undefined,
  sort: undefined,
  formatExtraData: undefined,
  sortFuncExtraData: undefined,
  filter: undefined,
  sortIndicator: true,
  expandable: true,
  tdAttr: undefined,
  editTdAttr: undefined,
  tdStyle: undefined,
  thStyle: undefined,
  keyValidator: false,
  defaultASC: false,
};

export default TableHeaderColumn;
