import type { PrismCategoryUri } from '../../types/prism.types';
import { getActivationLockColumns } from './activationLockColumns';
import { getApplicationFirewallColumns } from './applicationFirewallColumns';
import { getAppsColumns } from './appsColumns';
import { getCertificatesColumns } from './certificatesColumns';
import { getDesktopAndScreensaverColumns } from './desktopAndScreensaverColumns';
/* istanbul ignore file */
import { getDeviceInfoColumns } from './deviceInfoColumns';
import { getFileVaultColumns } from './fileVaultColumns';
import { getGatekeeperAndXprotectColumns } from './gatekeeperAndXprotectColumns';
import { getInstalledProfilesColumns } from './installedProfilesColumns';
import { getKernelExtensionsColumns } from './kernelExtensionsColumns';
import { getLaunchAgentsDaemonsColumns } from './launchAgentsDaemonsColumns';
import { getLocalUsersColumns } from './localUsersColumns';
import { getStartupSettingsColumns } from './startupSettingsColumns';
import { getSystemExtensionsColumns } from './systemExtensionsColumns';
import { getTransparencyDatabaseColumns } from './transparencyDatabaseColumns';

export const getColumnsForPrismCategory = (
  attribute: PrismCategoryUri,
  schema,
) => {
  switch (attribute) {
    case 'device_information':
      return getDeviceInfoColumns(schema);
    case 'filevault':
      return getFileVaultColumns(schema);
    case 'startup_settings':
      return getStartupSettingsColumns(schema);
    case 'local_users':
      return getLocalUsersColumns(schema);
    case 'launch_agents_and_daemons':
      return getLaunchAgentsDaemonsColumns(schema);
    case 'activation_lock':
      return getActivationLockColumns(schema);
    case 'system_extensions':
      return getSystemExtensionsColumns(schema);
    case 'gatekeeper_and_xprotect':
      return getGatekeeperAndXprotectColumns(schema);
    case 'transparency_database':
      return getTransparencyDatabaseColumns(schema);
    case 'kernel_extensions':
      return getKernelExtensionsColumns(schema);
    case 'installed_profiles':
      return getInstalledProfilesColumns(schema);
    case 'application_firewall':
      return getApplicationFirewallColumns(schema);
    case 'apps':
      return getAppsColumns(schema);
    case 'desktop_and_screensaver':
      return getDesktopAndScreensaverColumns(schema);
    case 'certificates':
      return getCertificatesColumns(schema);
    default:
      return {
        columnDefs: [],
        filterableColumns: [],
      };
  }
};
