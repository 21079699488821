import './styles.scss';

import { Chip, Icon, setClass, useOutsideClick } from '@kandji-inc/bumblebee';
import { arrayOf, bool, func, node, oneOfType, string } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

const MultiText = (props) => {
  const { disabled, isOptional, label, onChange, placeholder, values, hint } =
    props;
  const [tags, setTags] = useState(values);
  const [showInput, setShowInput] = useState(!(tags.length > 0));

  const wrapperRef = useRef(null);
  const inputRef = useRef(null);
  const removeAllButtonRef = useRef(null);

  useOutsideClick(wrapperRef, () => setShowInput(false));

  // Force focus the input element when anything except "remove tag" Xs is clicked
  useEffect(() => {
    if (showInput && tags.length > 0) {
      inputRef.current.focus();
    }
  }, [showInput]);

  useEffect(() => {
    setTags(values);
  }, [values]);

  const handleOnClick = () => {
    inputRef.current.focus();
    setShowInput(true);
  };

  const handleOnMouseDown = (e) => e.preventDefault();

  const resetInput = (e) => {
    e.target.value = '';
  };

  const addTag = (e) => {
    if (e.target.value !== '') {
      const sanitizedTag = e.target.value.trim();
      const newTags = [...tags, sanitizedTag];

      setTags(newTags);
      onChange({ values: newTags });
      resetInput(e);
    }
  };

  const handleOnPaste = (e) => {
    // Pasting should not automatically update the value in the textbox
    //  since newline-separated pasted values turn into chips
    e.preventDefault();

    const pastedText = e.clipboardData.getData('Text');

    const sanitizedTags = pastedText
      // Split pasted text on newline characters
      .split(/\r?\n|\r|\n/g)
      // Remove empty lines
      .filter((line) => line !== '');

    // If at least one newline was present in the pasted text, add the newline-separated values as tags.
    // Otherwise, simply paste the text into the input.
    if (sanitizedTags.length > 1) {
      const newTags = [...tags, ...sanitizedTags];

      setTags(newTags);
      onChange({ values: newTags });
      resetInput(e);
    } else {
      e.target.value = pastedText;
    }
  };

  const removeTag = (indexToRemove) => {
    const newTags = [...tags.filter((_, index) => index !== indexToRemove)];
    setTags(newTags);
    onChange({ values: newTags });
  };

  const removeAllTags = () => {
    const newTags = [];
    setTags(newTags);
    onChange({ values: newTags });
  };

  const handleOnKeyUp = (e) => {
    if (e.key === 'Enter') {
      addTag(e);
    }
  };

  const handleIconClick = (e) => {
    // Unfocus the input
    e.stopPropagation();
    document.activeElement.blur();

    removeAllTags();
  };

  const renderLabel = () => {
    const disClass = 'b-txt-light2 b-multi-text__label--disabled';
    if (!label) {
      return null;
    }
    if (typeof label === 'string') {
      return (
        <label className={setClass(['b-txt', disabled ? disClass : ''])}>
          {label}
          {isOptional && <span className="b-txt-light2"> (optional)</span>}
        </label>
      );
    }
    return React.cloneElement(label, {
      className: setClass([
        'b-txt',
        disabled ? disClass : '',
        label.props.className || '',
      ]),
    });
  };

  return (
    <>
      {renderLabel()}
      <div
        className={setClass(['b-multi-text', disabled ? 'disabled' : ''])}
        onClick={handleOnClick}
        onMouseDown={handleOnMouseDown}
        ref={wrapperRef}
      >
        <div className="b-multi-text__tag-container">
          {/* Since duplicate tags are allowed, we break the eslint best
          practice of avoiding using the Array index as a key */}
          {tags.map((tag, index) => (
            <Chip
              className="b-multi-text__tag"
              key={index}
              text={tag}
              kind="secondary"
              iconRight={disabled ? '' : 'xmark'}
              onRightIconClick={() => removeTag(index)}
            />
          ))}
          <input
            className={setClass([
              'b-multi-text__input',
              // The input should not be hidden when there are no tags
              !showInput && tags.length > 0 ? 'hidden' : '',
            ])}
            ref={inputRef}
            type="text"
            onKeyUp={handleOnKeyUp}
            onPaste={handleOnPaste}
            disabled={disabled}
            onMouseDown={handleOnMouseDown}
            // Only display a placeholder if there are no tags
            placeholder={!tags.length ? placeholder : ''}
          />
        </div>
        {!disabled && tags.length > 0 && (
          <span
            className="b-multi-text__icon"
            ref={removeAllButtonRef}
            onClick={handleIconClick}
          >
            <Icon name="circle-xmark" />
          </span>
        )}
      </div>
      {hint && <p className="b-mt-micro b-txt-light">{hint}</p>}
    </>
  );
};

MultiText.propTypes = {
  disabled: bool,
  isOptional: bool,
  label: oneOfType([node, string]),
  onChange: func,
  placeholder: string,
  values: arrayOf(string),
};

MultiText.defaultProps = {
  disabled: false,
  isOptional: false,
  label: '',
  onChange: () => {},
  placeholder: 'Type value and press enter or paste a list',
  values: [],
};

export default MultiText;
