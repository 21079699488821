import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { LineLoader } from '../../interface/LineLoader';
import './AwesomeTable.styles.scss';
import AwesomeTableBody from './components/AwesomeTableBody';
import AwesomeTableHeader from './components/AwesomeTableHeader';

const AwesomeTable = ({
  headers,
  data,
  onDragEnd,
  onResize,
  tableWidth,
  isReady,
  expandComponent,
  loadingDataView,
  sortFunc,
  ordering,
  clickableCellsToExpand,
  isExpandableRow,
}) => (
  <>
    {!isReady && <LineLoader isDelayed />}
    <div className={classNames('awesome-table', { isReady })}>
      <AwesomeTableHeader
        headers={headers}
        onDragEnd={onDragEnd}
        onResize={onResize}
        tableWidth={tableWidth}
        sortFunc={sortFunc}
        ordering={ordering}
      />
      <AwesomeTableBody
        data={data}
        headers={headers}
        tableWidth={tableWidth}
        expandComponent={expandComponent}
        loadingDataView={loadingDataView}
        clickableCellsToExpand={clickableCellsToExpand}
        isExpandableRow={isExpandableRow}
      />
    </div>
  </>
);

AwesomeTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  tableWidth: PropTypes.number.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  onResize: PropTypes.func.isRequired,
  isReady: PropTypes.bool.isRequired,
  expandComponent: PropTypes.func.isRequired,
  sortFunc: PropTypes.func,
  ordering: PropTypes.string.isRequired,
  clickableCellsToExpand: PropTypes.arrayOf(PropTypes.string),
  isExpandableRow: PropTypes.func,
};

AwesomeTable.defaultProps = {
  data: [],
  sortFunc: null,
  clickableCellsToExpand: null,
  isExpandableRow: null,
};

export default AwesomeTable;
