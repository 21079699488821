/* istanbul ignore file */
import React from 'react';
import styled from 'styled-components';

const StyledText = styled.span`
  font-family: var(--font-body-xs-family);
  font-weight: var(--font-body-xs-weight);
  font-size: var(--font-body-xs-size);
  line-height: var(--font-body-xs-line-height);
  letter-spacing: var(--font-body-xs-letter-spacing);
  color: var(--color-neutral-100);
  font-style: normal;
`;
export const H2 = ({ children, ...rest }) => (
  <h2 className="b-h2" {...rest}>
    {children}
  </h2>
);
export const H3 = ({ children, ...rest }) => (
  <h3 className="b-h3" {...rest}>
    {children}
  </h3>
);
export const H6 = ({ children, ...rest }) => (
  <h6 className="b-h6" {...rest}>
    {children}
  </h6>
);
export const H8 = ({ children, ...rest }) => (
  <h8 className="b-h6" {...rest}>
    {children}
  </h8>
);
export const Text = ({ children, ...rest }) => (
  <StyledText {...rest}>{children}</StyledText>
);
