/* istanbul ignore file */
import appStoreApps from './app-store-apps';
import autoApps from './auto-apps';
import customApps from './custom';
import enrollmentApps from './enrollment';
import ipaApps from './in-house-apps';
import operatingSystemApps from './operating-systems';
import profileApps from './profile';
import securityApps from './security';

/**
 * Library Item configurations are split up into logical groupings.
 */
const allLibraryItemsConfiguration = /** @type {const} */ {
  ...appStoreApps,
  ...autoApps,
  ...customApps,
  ...enrollmentApps,
  ...ipaApps,
  ...operatingSystemApps,
  ...profileApps,
  ...securityApps,
};

const getConfig = (key) => allLibraryItemsConfiguration[key];

export default allLibraryItemsConfiguration;
export {
  appStoreApps,
  autoApps,
  customApps,
  enrollmentApps,
  ipaApps,
  operatingSystemApps,
  profileApps,
  securityApps,
  getConfig,
};
