import React from 'react';

const useOktaController = () => {
  const [isAppConfigDisabled, setIsAppConfigDisabled] = React.useState(false);

  return {
    isAppConfigDisabled,
    setIsAppConfigDisabled,
  };
};

export default useOktaController;
