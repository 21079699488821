import { Box, Flex, Icon, type IconNames } from '@kandji-inc/nectar-ui';
import EDRTooltip from '../../common/components/EDRTooltip';
import constants from '../common/constants';
import getFormatCount from '../common/utils/getFormatCount';
import type { BehavioralThreatStatus, ThreatStatus } from '../threat.types';

const ThreatStatusIconText = (props: {
  label: number;
  icon: IconNames;
  color: string;
  tooltip: string;
}) => {
  const { label, icon, color, tooltip } = props;

  return (
    <EDRTooltip side="bottom" label={tooltip}>
      <Flex gap="xs" alignItems="center" pr1>
        <Icon name={icon} color={`var(--color-${color})`} />
        <Box css={{ width: 20 }}>{getFormatCount(label || 0)}</Box>
      </Flex>
    </EDRTooltip>
  );
};

const getStatusLabel = (status: ThreatStatus | BehavioralThreatStatus) =>
  constants.THREAT_STATUS_DISPLAY_NAME_MAP[status];

const getStatusIcon = (status: ThreatStatus | BehavioralThreatStatus) =>
  constants.THREAT_STATUS_ICON_MAP[status];

type ThreatListStatusSummaryBadgesProps = {
  compact?: boolean;
  notQuarantined?: number | undefined;
  quarantined?: number | undefined;
  resolved?: number | undefined;
  released?: number | undefined;
  detected?: number | undefined;
  blocked?: number | undefined;
  informational?: number | undefined;
  isFileDetectionType?: boolean;
  isMalicious?: boolean;
};

const ThreatListStatusSummaryBadges = (
  props: ThreatListStatusSummaryBadgesProps,
) => {
  const {
    compact = false,
    notQuarantined,
    quarantined,
    resolved,
    released,
    detected,
    blocked,
    informational,
    isFileDetectionType = true,
    isMalicious = true,
  } = props;

  const iconSize = compact ? 15 : 20;

  return (
    <Flex
      alignItems="center"
      gap={compact ? 'xs' : 'sm'}
      css={{
        svg: {
          width: iconSize,
          height: iconSize,
          flexShrink: 0,
        },
      }}
    >
      {isFileDetectionType && (
        <>
          <ThreatStatusIconText
            label={notQuarantined}
            icon={getStatusIcon(constants.THREAT_STATUS.NOT_QUARANTINED)}
            color="red-50"
            tooltip={getStatusLabel(constants.THREAT_STATUS.NOT_QUARANTINED)}
          />
          <ThreatStatusIconText
            label={quarantined}
            icon={getStatusIcon(constants.THREAT_STATUS.QUARANTINED)}
            color="blue-50"
            tooltip={getStatusLabel(constants.THREAT_STATUS.QUARANTINED)}
          />
          <ThreatStatusIconText
            label={resolved}
            icon={getStatusIcon(constants.THREAT_STATUS.RESOLVED)}
            color="green-50"
            tooltip={getStatusLabel(constants.THREAT_STATUS.RESOLVED)}
          />
          <ThreatStatusIconText
            label={released}
            icon={getStatusIcon(constants.THREAT_STATUS.RELEASED)}
            color="neutral-70"
            tooltip={getStatusLabel(constants.THREAT_STATUS.RELEASED)}
          />
        </>
      )}
      {!isFileDetectionType && isMalicious && (
        <>
          <ThreatStatusIconText
            label={detected}
            icon={getStatusIcon(constants.THREAT_BEHAVIORAL_STATUS.DETECTED)}
            color="yellow-50"
            tooltip={getStatusLabel(
              constants.THREAT_BEHAVIORAL_STATUS.DETECTED,
            )}
          />
          <ThreatStatusIconText
            label={blocked}
            icon={getStatusIcon(constants.THREAT_BEHAVIORAL_STATUS.BLOCKED)}
            color="blue-50"
            tooltip={getStatusLabel(constants.THREAT_BEHAVIORAL_STATUS.BLOCKED)}
          />
        </>
      )}
      {!isFileDetectionType && !isMalicious && (
        <ThreatStatusIconText
          label={informational}
          icon={getStatusIcon(constants.THREAT_BEHAVIORAL_STATUS.INFORMATIONAL)}
          color="neutral-70"
          tooltip={getStatusLabel(
            constants.THREAT_BEHAVIORAL_STATUS.INFORMATIONAL,
          )}
        />
      )}
    </Flex>
  );
};

export default ThreatListStatusSummaryBadges;
