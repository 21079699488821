import { parse, stringify } from 'query-string';
import { StrictMode, useContext } from 'react';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';

import featureFlags from 'src/config/feature-flags';
import { AccountContext } from 'src/contexts/account';

import useAddBodyClass from 'features/util/hooks/use-add-body-class';
import useAdjustSidebarChatBubble from 'features/util/hooks/use-adjust-sidebar-chat-bubble';
import ThreatListErrorBoundary from './common/components/ThreatListErrorBoundary';
import ThreatListSuspense from './common/components/ThreatListSuspense';
import EdrTrial from './edr-trial/EdrTrial';
import ThreatListV2 from './threat-events/ThreatList';
import ThreatListV3 from './threat-groups/ThreatList';

import './styles.css';

const ThreatListFFSwitch = (props: any) => {
  const { currentCompany } = useContext(AccountContext);
  const { isOnDeviceRecord } = props;
  const isFeatureFlagV3On = featureFlags.getFlag('edr_041724_threats-v3');
  const isEdrTrialOn = featureFlags.getFlag('edr_110824_edr-trial-enabled-ui');
  const isSkuEnabled = Boolean(
    currentCompany?.feature_configuration?.edr?.enabled,
  );

  const isEdrTrialPageShown = isEdrTrialOn && !isSkuEnabled;

  useAddBodyClass('threat', !isOnDeviceRecord);
  useAddBodyClass('threat-v2', Boolean(isOnDeviceRecord || !isFeatureFlagV3On));
  useAdjustSidebarChatBubble();

  const ThreatList =
    isFeatureFlagV3On && !props.forceV2 ? ThreatListV3 : ThreatListV2;

  return (
    <StrictMode>
      <ThreatListErrorBoundary css={{ position: 'absolute', inset: 0 }}>
        <ThreatListSuspense>
          {isEdrTrialPageShown ? (
            <EdrTrial />
          ) : (
            <QueryParamProvider
              adapter={ReactRouter5Adapter}
              options={{
                searchStringToObject: parse,
                objectToSearchString: stringify,
                removeDefaultsFromUrl: true,
              }}
            >
              <ThreatList {...props} />
            </QueryParamProvider>
          )}
        </ThreatListSuspense>
      </ThreatListErrorBoundary>
    </StrictMode>
  );
};

export default ThreatListFFSwitch;
