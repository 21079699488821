import {
  Chip,
  Flex,
  Icon,
  Separator,
  Text,
  css,
  styled,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import EDRTooltip from '../../../common/components/EDRTooltip';
import ThreatListTextCopyButton from '../../common/components/ThreatListTextCopyButton';
import ThreatListButtonPopover from './SidePanels/ThreatListButtonPopover';

const TextBold = styled(Text, { fontWeight: '$medium' });

const infoIconStyle = css({
  pl: 2,
  width: 14,
  height: 14,
  '& > svg': { height: '$3', width: '$3', verticalAlign: 'text-top' },
});

const MitrePopoverInfoTooltip = ({ text }: { text: string }) => (
  <EDRTooltip label={text} css={{ zIndex: 2 }}>
    <span className={infoIconStyle().className}>
      <Icon name="circle-info" />
    </span>
  </EDRTooltip>
);

const MitrePopoverCopyButton = ({ text }: { text: string | undefined }) => {
  return (
    <ThreatListTextCopyButton
      text={text}
      css={{
        gap: 2,
        fontSize: '$1',
        lineHeight: '$1',
        '& > div': {
          width: '$4',
          height: '$4',
        },
        '& svg': {
          mt: 1,
          height: 14,
          width: 14,
          verticalAlign: 'text-top',
        },
      }}
    />
  );
};

const MitrePopoverContent = ({ techniques }: { techniques: string[] }) => {
  return (
    <Flex
      flow="column"
      gap="xs"
      css={{
        fontSize: '$1',
        maxHeight: 224,
        width: 326,
        overflow: 'auto',
        position: 'relative',
        marginInline: '-$4',
        marginBlock: '-$3',
        paddingInline: '$4',
        paddingBlock: '$3',
      }}
    >
      {techniques.map((item, index) => {
        const [tactic, technique, subTechnique] = item.split('.');
        const isLast = index === techniques.length - 1;

        return (
          <Flex flow="column" gap="xs" alignItems="start" key={item}>
            <Flex>
              <TextBold>{i18n.t('Tactic')}</TextBold>
              <MitrePopoverInfoTooltip
                text={i18n.t(
                  "The adversary's objective or goal during an attack phase",
                )}
              />
            </Flex>
            <MitrePopoverCopyButton text={tactic} />
            {technique && (
              <>
                <Flex>
                  <TextBold>{i18n.t('Technique')}</TextBold>
                  <MitrePopoverInfoTooltip
                    text={i18n.t(
                      'The method an adversary uses to achieve a specific tactic',
                    )}
                  />
                </Flex>
                <MitrePopoverCopyButton text={technique} />
              </>
            )}
            {subTechnique && (
              <>
                <Flex>
                  <TextBold>{i18n.t('Sub-technique')}</TextBold>
                  <MitrePopoverInfoTooltip
                    text={i18n.t(
                      'A more detailed method that describes a specific variation of a technique used by adversaries during an attack',
                    )}
                  />
                </Flex>
                <MitrePopoverCopyButton text={subTechnique} />
              </>
            )}
            {!isLast && <Separator css={{ my: '$1' }} />}
          </Flex>
        );
      })}
    </Flex>
  );
};

const MitrePopover = ({
  techniques,
}: {
  techniques: string[];
}) => {
  if (!techniques?.length) return null;

  return (
    <ThreatListButtonPopover
      icon="clock-rotate-left"
      isHoverable
      trigger={<Chip css={{ px: 6 }} label={techniques.length} />}
      testId="mitre-popup-chip"
    >
      <MitrePopoverContent techniques={techniques} />
    </ThreatListButtonPopover>
  );
};

export default MitrePopover;
