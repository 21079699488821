import { getRunsOnFromModel } from 'src/features/library-items/data-service/library-item/devices';

import getAuthentication from './get-authentication';
import getCertificate from './get-certificate';
import getFastlaneMarking from './get-fastlane-marking';
import getGeneral from './get-general';
import getProxy from './get-proxy';

const transformToApi = (model) => {
  let toSendData = {};
  const hasLegacyConfigs = model.legacyConfigurations?.configurations?.length;
  if (hasLegacyConfigs) {
    const { configurations } = model.legacyConfigurations;
    const stillHasMultipleConfigs = configurations.length > 1;

    toSendData.configurations = configurations.map((config) => {
      const transformedConfig = {
        ...getGeneral(config),
        ...(!stillHasMultipleConfigs
          ? {
              fastLaneMarking: getFastlaneMarking(config),
              certificate: getCertificate(config),
              proxy: getProxy(config),
              authentication: getAuthentication(config),
            }
          : {}),
      };
      return transformedConfig;
    });
  } else {
    toSendData = {
      ...getGeneral(model),
      fastLaneMarking: getFastlaneMarking(model),
      certificate: getCertificate(model),
      proxy: getProxy(model),
      authentication: getAuthentication(model),
    };
  }

  const { name, isActive, selectedBlueprints, isAllBlueprints } = model;
  const result = {
    data: hasLegacyConfigs ? toSendData.configurations : [toSendData],
    type: 'profile',
    identifier: 'com.kandji.profile.wifi',
    name,
    active: isActive,
    blueprints: selectedBlueprints.map(({ value }) => value),
    is_all_blueprints: isAllBlueprints,
    rules: model.rules || null,
  };

  // Populate `runs_on_*` fields
  const runsOn = getRunsOnFromModel(model);
  Object.keys(runsOn).forEach((runsOnKey) => {
    result[runsOnKey] = runsOn[runsOnKey];
  });

  return result;
};

export default transformToApi;
