import cn from 'classnames';
import { AccountContext } from 'contexts/account';
import { InterfaceContext } from 'contexts/interface';
import React, { useContext, useMemo } from 'react';
import Sidebar from 'react-sidebar';
import ProfileDropdown from './ProfileDropdown';
import SidebarHeader from './SidebarHeader';
import SidebarItems from './SidebarItems';

import './Sidebar.css';

const MAGIC_WIDTH = 1199;

const SidebarWrap = () => {
  const { sidebarDocked, sidebarOpened, setSidebarOpened } =
    useContext(InterfaceContext);
  const sidebar = useMemo(
    () => (
      <div
        id="sidebar"
        data-testid="sidebar"
        className={cn('sidebar', { opened: sidebarOpened })}
        onMouseEnter={() => {
          if (
            !sidebarOpened &&
            !sidebarDocked &&
            window.innerWidth > MAGIC_WIDTH
          ) {
            setSidebarOpened(true);
          }
        }}
        onMouseLeave={() => {
          if (!sidebarDocked && window.innerWidth > MAGIC_WIDTH) {
            setSidebarOpened(false);
          }
        }}
      >
        <SidebarHeader />
        <SidebarItems />
        <ProfileDropdown />
      </div>
    ),
    [sidebarOpened, sidebarDocked],
  );

  return (
    <Sidebar
      sidebar={sidebar}
      open
      docked={sidebarDocked}
      onSetOpen={setSidebarOpened}
      shadow={false}
      rootClassName={cn('kandji-sidebar', {
        'kandji-sidebar--opened': sidebarOpened,
        'kandji-sidebar--over': !sidebarDocked && sidebarOpened,
      })}
      sidebarClassName={cn('kandji-sidebar__sidebar', {
        'kandji-sidebar__sidebar--over': !sidebarDocked && sidebarOpened,
      })}
      contentClassName="kandji-sidebar__content"
      overlayClassName="kandji-sidebar__overlay"
    >
      <div className="d-none" />
    </Sidebar>
  );
};

export default SidebarWrap;
