import { Badge, type CSS, type IconNames, styled } from '@kandji-inc/nectar-ui';
import EDRTooltip from '../../../common/components/EDRTooltip';

type ThreatListStatusBadgeProps = {
  color: string;
  icon: IconNames;
  tooltip?: string;
  tooltipPosition?: 'top' | 'right' | 'bottom' | 'left';
  children: React.ReactNode;
  css?: CSS;
};

const StyledBadge = styled(Badge, {
  padding: '0 7px 0 6px',
  fontSize: '$1',
  alignSelf: 'center',
  cursor: 'default',
});

const ThreatListStatusBadge = (props: ThreatListStatusBadgeProps) => {
  const {
    color,
    icon,
    tooltip,
    tooltipPosition = 'bottom',
    children,
    css,
  } = props;

  return (
    <EDRTooltip side={tooltipPosition} label={tooltip}>
      <StyledBadge css={css} color={color} icon={icon}>
        {children}
      </StyledBadge>
    </EDRTooltip>
  );
};

export default ThreatListStatusBadge;
