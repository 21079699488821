import React, { useEffect, useRef } from 'react';
import {
  bgPaths,
  logo,
  previewPaths,
  success,
} from './screens/set-preview-image';

const newCssNode = (css, id) => {
  const head = document.head || document.getElementsByTagName('head')[0];
  const style = document.createElement('style');
  head.appendChild(style);
  style.type = 'text/css';
  style.appendChild(document.createTextNode(css));
  style.id = id;
  return style;
};

const PreLoadImages = () => {
  const allPaths = Object.values(bgPaths)
    .concat(Object.values(previewPaths))
    .concat(Object.values(logo))
    .concat(Object.values(success));

  const styleId = useRef(`preload_${String(+new Date())}`);
  const styleRef = useRef();

  const urlPaths = allPaths.map((p) => `url(${p})`);

  const preLoadCss = `
  body::after {
     position:absolute; width:0; height:0; overflow:hidden; z-index:-1;
     content: ${urlPaths.join(' ')}
  }
  `;

  useEffect(() => {
    styleRef.current = newCssNode(preLoadCss, styleId.current);
    return () => {
      if (styleRef.current) {
        styleRef.current.remove();
      }
    };
  }, []);

  return <div hidden />;
};

export default PreLoadImages;
