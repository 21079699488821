/* istanbul ignore file - legacy code, moved */
import { logout } from 'app/_actions/account';
import { store } from 'app/_store/rootStore';
import { authenticatedRequest, publicRequest } from 'app/api/base';
import { getBackend } from 'app/api/domains';
import { nextUniqueID } from 'app/api/utils';
import axiosBase from 'axios';

const createAPI = (
  axiosBase,
  listURL,
  createURL,
  formatItemURL,
  withAuthentication = true,
) => {
  const client = withAuthentication
    ? authenticatedRequest(axiosBase)
    : publicRequest(axiosBase);
  const extractData = async (request) => {
    const { data } = await request;
    return data;
  };
  const fetchResponse = async (options) => extractData(client(options));
  return {
    id: nextUniqueID(),
    list: async (params) =>
      fetchResponse({ method: 'GET', url: listURL, params }),
    create: async (data) =>
      fetchResponse({ method: 'POST', url: createURL, data }),
    retrieve: async (id) =>
      fetchResponse({
        method: 'GET',
        url: formatItemURL(id),
      }),
    update: async (id, data) =>
      fetchResponse({
        method: 'PATCH',
        url: formatItemURL(id),
        data,
      }),
    delete: async (id) =>
      fetchResponse({
        method: 'DELETE',
        url: formatItemURL(id),
      }),
  };
};

const libraryClient = axiosBase.create({
  baseURL: `${getBackend()}library/v1/`,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
  },
});

libraryClient.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log('API ERROR', error);
    if (error.response && error.response.status === 401) {
      store.dispatch(logout());
    }
    return Promise.reject(error);
  },
);

export const LibraryItemsAPI = createAPI(
  libraryClient,
  'library-items/',
  'library-items/add',
  (id) => `library-items/${id}`,
);

export const ProfilesAPI = createAPI(
  libraryClient,
  'profiles/',
  'profiles/',
  (id) => `profiles/${id}/`,
);
