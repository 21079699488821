import {
  Checkbox,
  Select,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import React, { useEffect } from 'react';

import { Setting } from 'features/library-items/template';
import { i18n } from 'src/i18n';

const CategoryRow = (props) => {
  const { setting, update, isDisabled, categoryOptions, isSubmitted } = props;
  const fieldsToValidate = ['category'];
  const { refs, onInvalidate } = useInputsValidators(fieldsToValidate, update);

  useEffect(() => {
    onInvalidate(0)(!setting.category);
  }, [setting.category]);

  useRemoveValidationOnUnmount(fieldsToValidate, update);

  return (
    <Setting.Row>
      <Setting.Title>
        <p className="b-txt">{i18n.t('Category')}</p>
      </Setting.Title>
      <Setting.Helpers>
        <p className="b-txt-light">
          {i18n.t(`Specify the Self Service category that this Library Item should
          display under. Recommended items will also be displayed in a
          "Recommended" section at the top of the Self Service library
          page and categories list.`)}
        </p>
      </Setting.Helpers>
      <Setting.Controls>
        <Select
          isDisabled={isDisabled}
          options={categoryOptions}
          placeholder={i18n.t('Category')}
          compact
          onChange={(v) => update('category', v.value)}
          value={categoryOptions.find(
            (option) => option.value === setting.category,
          )}
          errorText={
            (isSubmitted && !setting.category && i18n.t('Required')) || ''
          }
        />
      </Setting.Controls>
      <div ref={refs[0]} className="b-mt2">
        <Checkbox
          checked={setting.isRecommended}
          onChange={() => update('isRecommended', (p) => !p)}
          disabled={isDisabled}
          label={i18n.t('Recommended')}
        />
      </div>
    </Setting.Row>
  );
};

export default CategoryRow;
