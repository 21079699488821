import type { CSS } from '@kandji-inc/nectar-ui';
import { Box, Td, Th } from '@kandji-inc/nectar-ui';
import type { ColumnSizingInfoState } from '@tanstack/react-table';
import { i18n } from 'i18n';
import * as React from 'react';

type ThProps = React.ComponentProps<typeof Th>;
type TdProps = React.ComponentProps<typeof Td>;

type ColumnResizerProps = {
  resizable: boolean | undefined;
  handleResize: HeaderCellProps['handleResize'];
};

type HeaderCellProps = {
  columnId: string;
  size: number;
  children: React.ReactNode;
  showMenu?: boolean;
  menuOptions?: React.ComponentProps<typeof Th>['menuOptions'];
  resizable?: boolean;
  isResizingColumn?: ColumnSizingInfoState['isResizingColumn'];
  handleResize?: (
    e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>,
  ) => void;
  title: string;
  css?: CSS;
} & ThProps;

type RowCellProps = {
  columnId: string;
  size: number;
  children: React.ReactNode;
  resizable?: boolean;
  isResizingColumn?: ColumnSizingInfoState['isResizingColumn'];
  title: string;
  scope?: 'col' | 'row' | 'colgroup' | 'rowgroup' | undefined;
  css?: CSS;
} & (ThProps | TdProps);
export const ColumnResizer = ({
  resizable,
  handleResize,
}: ColumnResizerProps) => {
  const resizerCss = React.useMemo(
    () => ({
      position: 'absolute',
      top: 0,
      right: 0,
      width: 2,
      height: '100%',
      background: '$blue50',
      opacity: 0,
      '&:hover': {
        opacity: 1,
      },
      '&:active': {
        opacity: 1,
      },
      cursor: 'col-resize',
      userSelect: 'none',

      // provides extra space grabbing the resizer handle
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        width: 6,
        height: '100%',
        background: 'transparent',
        cursor: 'col-resize',
        userSelect: 'none',
        touchAction: 'none',
      },
    }),
    [],
  );

  return resizable === true ? (
    <Box
      role="presentation"
      aria-label={i18n.t('Column resizer')}
      data-col-resizer=""
      onMouseDown={handleResize}
      onTouchStart={handleResize}
      css={resizerCss}
    />
  ) : null;
};

/**
 * A component that wraps around the `nectar-ui` `Th` component to render a
 * table column header cell with support for resizing columns and minimizing
 * rerendering of non-resizing columns during resizing.
 *
 * Use this component instead of the default `Th` component from
 * `@kandji-inc/nectar-ui` for resizable table columns.
 *
 * @remarks
 * Use the default `TableCell.HeaderCell` exported component for automatic
 * React memoization performance optimizations during column resizing,
 * otherwise use the named `HeaderCell` exported component for manually
 * supplying your own `React.memo` comparator function as needed.
 */
export const HeaderCell = React.forwardRef<
  HTMLTableCellElement,
  HeaderCellProps
>(
  (
    {
      columnId,
      size,
      children,
      showMenu = false,
      menuOptions = [],
      resizable,
      isResizingColumn,
      handleResize,
      css,
      ...rest
    },
    ref,
  ) => {
    const headerCss = React.useMemo(() => {
      const restCss = css ?? {};
      return {
        truncated: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
        size: {
          // on hover stack above next header to the right for resizer handle
          // to appear
          '&:hover': {
            zIndex: resizable ? 1 : 'initial',
          },
          '& [data-col-resizer]': {
            height: 36,
            top: -10,
            right: -12,
          },
          userSelect: 'none',
          ...restCss,
        },
      };
    }, [css, resizable]);

    const dataAttrs = React.useMemo(
      () => ({
        'data-col-id': columnId,
        'data-col-resizing': String(isResizingColumn === columnId),
      }),
      [columnId, isResizingColumn],
    );

    return (
      <Th
        ref={ref}
        scope="col"
        showMenu={showMenu}
        menuOptions={menuOptions}
        css={headerCss.size}
        style={{ width: size, maxWidth: size }}
        {...dataAttrs}
        {...rest}
      >
        {children}
        <ColumnResizer resizable={resizable} handleResize={handleResize} />
      </Th>
    );
  },
);

/**
 * A component that wraps around the `nectar-ui` `Td` or `Th` component to
 * render a table row cell with support for resizing columns and minimizing
 * rerendering of non-resizing columns during resizing. The row cell will be a
 * `Th` row header cell if the `scope` prop is set to `row`, otherwise it will
 * be a `Td` data cell. Pass `scope="row"` to this component for supporting
 * fixed/frozen row headers.
 *
 * Use this component instead of the default `Td` and `Th` components from
 * `@kandji-inc/nectar-ui` for resizable table columns.
 *
 * @remarks
 * Use the default `TableCell.RowCell` exported component for automatic
 * React memoization performance optimizations during column resizing,
 * otherwise use the named `RowCell` exported component for manually
 * supplying your own `React.memo` comparator function as needed.
 */
export const RowCell = React.forwardRef<HTMLTableCellElement, RowCellProps>(
  (
    { columnId, size, children, scope, isResizingColumn, css, ...rest },
    ref,
  ) => {
    const truncatedCss = React.useMemo(() => {
      const restCss = css ?? {};
      return {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        ...restCss,
      };
    }, [css]);

    const dataAttrs = React.useMemo(
      () => ({
        'data-col-id': columnId,
        'data-col-resizing': String(isResizingColumn === columnId),
      }),
      [columnId, isResizingColumn],
    );

    return scope === 'row' ? (
      <Th
        ref={ref}
        scope="row"
        css={truncatedCss}
        style={{ width: size }}
        {...dataAttrs}
        {...rest}
      >
        {children}
      </Th>
    ) : (
      <Td
        ref={ref}
        css={truncatedCss}
        style={{ width: size }}
        {...dataAttrs}
        {...rest}
      >
        {children}
      </Td>
    );
  },
);

const MemoHeaderCell = React.memo(HeaderCell, updateResizingColumnsOnly);
const MemoRowCell = React.memo(RowCell, updateResizingColumnsOnly);

const TableCell = {
  HeaderCell: MemoHeaderCell,
  RowCell: MemoRowCell,
};

export default TableCell;

/**
 * A custom `React.memo` comparator function for the `HeaderCell` and `RowCell`
 * components to address sluggish UI performance during "live" updated column
 * resizing on drag. It will only rerender the cells that belong to the
 * currently resizing column based on if the column name of the
 * `isResizingColumn` prop matches the cell's `columnId` prop.
 *
 * @param prev.isResizingColumn The previous string column name of any
 * currently resizing column, otherwise `false` if no column is being resizing.
 * @param prev.columnId The previous string column name of the cell. Should
 * have the same string value throughout the lifetime of the cell.
 * @param next.isResizingColumn The next string column name of any currently
 * resizing column, otherwise `false` if no column is being resizing.
 * @param next.columnId The next string column name of the cell. Should have
 * the same string value throughout the lifetime of the cell.
 * @returns `true` if the cell should not rerender during resize, otherwise
 * `false` to rerender the cell since it belongs to the currently resizing
 * column.
 */
export function updateResizingColumnsOnly(
  prev: HeaderCellProps | RowCellProps,
  next: HeaderCellProps | RowCellProps,
) {
  const isResizing = prev.isResizingColumn || next.isResizingColumn;
  if (!isResizing) {
    return false;
  }

  const prevIsResizing = prev.isResizingColumn === prev.columnId;
  const nextIsResizing = next.isResizingColumn === next.columnId;

  if (prevIsResizing || nextIsResizing) {
    return (
      prev.size === next.size && prev.isResizingColumn === next.isResizingColumn
    );
  }

  return true;
}
