import PropTypes from 'prop-types';
import React from 'react';

const clearBtnDefaultClass = 'react-bs-table-search-clear-btn';

const ClearSearchButton = (props) => {
  const { btnContextual, className, onClick, btnText, children, ...rest } =
    props;
  const content = children || <span>{btnText}</span>;
  return (
    <button
      className={`btn ${btnContextual} ${className} ${clearBtnDefaultClass}`}
      type="button"
      onClick={onClick}
      {...rest}
    >
      {content}
    </button>
  );
};

ClearSearchButton.propTypes = {
  btnContextual: PropTypes.string,
  className: PropTypes.string,
  btnText: PropTypes.string,
  onClick: PropTypes.func,
};

ClearSearchButton.defaultProps = {
  btnContextual: 'btn-default btn-secondary',
  className: '',
  btnText: 'Clear',
  onClick: undefined,
};

export default ClearSearchButton;
