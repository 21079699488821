import { createRef, useEffect, useState } from 'react';

const useRefs = (count) => {
  const [refs, setRefs] = useState(Array(count).fill(undefined));

  useEffect(() => {
    setRefs(refs.map(createRef));
  }, []);

  return refs;
};

export default useRefs;
