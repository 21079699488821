import type { MouseEvent } from 'react';
import React, {
  cloneElement,
  useContext,
  useState,
  useEffect,
  Fragment,
  useMemo,
  useRef,
} from 'react';

import { Button, setClass } from '@kandji-inc/bumblebee';
import type { SomeReadonlyRecord } from '@shared/types/util.types';

import { TableContext } from '../table.context';
import { COL_TYPE_MAP, isValidTableColType } from '../table.helpers';
import type {
  AllowedColName,
  ColType,
  GridCSSStyleProps,
  RowProps,
} from '../table.types';
import { Col } from './col';

export default function Row<DataRecord extends SomeReadonlyRecord>(
  props: RowProps<DataRecord>,
) {
  const {
    rowData,
    rowNum,
    colsChildren,
    rowExpandRef,
    setExpandedRowNumList,
    expandedRowNumList,
    expandableGrid,
    gridColumnDetails,
    frozenLeftPosValues,
    numFrozenRowActions,
    isLastRow,
    tableRef,
    tableMetaRef,
  } = props;

  const [{ expandable }] = useContext(TableContext);
  const [isRowExpanded, setIsRowExpanded] = useState(false);
  const expandToggleRef = useRef(null);
  const hasRowExpand = Boolean(expandable);
  const expandedColIdx = numFrozenRowActions - 1;

  const expandableGridStyle = useMemo(() => {
    const gridArea = `expanded-${rowNum}`;
    const defaultStyles = {
      gridArea,
      gridAutoColumns: gridColumnDetails.colTracks,
    };

    /* istanbul ignore next -- TODO branch */
    if (!expandableGrid) {
      return defaultStyles;
    }

    /* istanbul ignore next -- TODO branch */
    if (typeof expandableGrid === 'string') {
      return {
        gridArea,
        gridAutoColumns: expandableGrid,
      };
    }

    const isPlainObjectGuard = (
      value: unknown,
    ): value is { [key: string]: unknown } => {
      /* istanbul ignore next -- TODO branch */
      const isObjectLike = !!value && typeof value === 'object';

      /* istanbul ignore next -- TODO branch */
      if (isObjectLike) {
        const objProto = Object.getPrototypeOf(value);
        return objProto === null || objProto === Object.prototype;
      }

      return false;
    };

    const isGridStyleObjectGuard = (
      value: unknown,
    ): value is object & GridCSSStyleProps => {
      const allowedGridStyleKeys = {
        grid: true,
        gridArea: true,
        gridAutoColumns: true,
        gridAutoFlow: true,
        gridAutoRows: true,
        gridTemplate: true,
        gridTemplateAreas: true,
        gridTemplateColumns: true,
        gridTemplateRows: true,
      };

      /* istanbul ignore next -- TODO branch */
      return (
        isPlainObjectGuard(value) &&
        Object.keys(value).some((key) => allowedGridStyleKeys[key])
      );
    };

    /* istanbul ignore next -- TODO branch */
    if (isGridStyleObjectGuard(expandableGrid)) {
      return {
        gridArea,
        ...expandableGrid,
      };
    }

    /* istanbul ignore next -- TODO branch */
    if (typeof expandableGrid === 'function') {
      const gridResult = expandableGrid(gridColumnDetails);
      /* istanbul ignore next -- TODO branch */
      if (isGridStyleObjectGuard(gridResult)) {
        return {
          gridArea,
          ...gridResult,
        };
      }

      /* istanbul ignore next -- TODO branch */
      if (typeof gridResult === 'string') {
        return {
          gridArea,
          gridAutoColumns: gridResult,
        };
      }

      return defaultStyles;
    }

    return defaultStyles;
  }, [expandableGrid, gridColumnDetails, rowNum]);

  useEffect(() => {
    if (!expandedRowNumList[rowNum]) {
      setIsRowExpanded(false);
    }
  }, [expandedRowNumList]);

  const onToggleRowExpand = (e: MouseEvent<Element>) => {
    if (!hasRowExpand) {
      return; // noop
    }

    const isRowExpandButton =
      e.currentTarget.getAttribute('data-v-table-col-name') !== 'dropdown';

    /* istanbul ignore next -- hard to test text select accurately in jest */
    if (isRowExpandButton) {
      setIsRowExpanded((prev) => !prev);
      setExpandedRowNumList((prev) =>
        prev[rowNum]
          ? { ...prev, [rowNum]: null }
          : { ...prev, [rowNum]: true },
      );
    }
  };

  const renderExpandableRow = () =>
    Object.entries(expandable).map(([colName, value]) => {
      const {
        displayName = colName,
        rowCell: RowCell = Fragment,
        rowHeader: RowHeader = Col,
        rowComponent: RowComponent,
        isVisible = () => true,
      } = value;
      const datum = rowData[colName];

      if (!isVisible({ datum, rowData, colName })) {
        return null;
      }

      const hasRowComponent = typeof RowComponent === 'function';

      return hasRowComponent ? (
        <RowComponent
          key={`row-${colName}`}
          rowData={rowData}
          gridColumnDetails={gridColumnDetails}
        />
      ) : (
        <Fragment key={`expanded-${colName}`}>
          {/* @ts-expect-error -- TODO */}
          <RowHeader
            colName={`${colName}-expanded-header` as AllowedColName}
            rowData={rowData}
            datum={displayName}
            role="rowheader"
            style={{
              gridColumnStart: numFrozenRowActions + 1,
            }}
          >
            {datum}
          </RowHeader>
          <Col
            colName={colName as AllowedColName}
            rowData={rowData}
            datum={datum as string}
            className="--expanded"
            gridColumnDetails={gridColumnDetails}
            style={{
              gridColumnStart: numFrozenRowActions + 2,
            }}
          >
            {/* @ts-expect-error -- TODO */}
            {RowCell}
          </Col>
        </Fragment>
      );
    });

  return (
    <>
      {hasRowExpand && (
        <div
          ref={rowExpandRef}
          data-v-table-frozen
          data-v-table-row-action-type="expand"
          data-v-table-row-num={rowNum}
          data-v-table-col-num={expandedColIdx + 1}
          data-v-table-expanded={isRowExpanded}
          aria-expanded={isRowExpanded}
          className="expand-row-action__container __v-table-ns"
          style={{
            gridArea: `expand-${rowNum}`,
            left: frozenLeftPosValues[expandedColIdx],
            ...{ ...(isLastRow && { '--shadow-border-bottom': 'none' }) },
          }}
        >
          <Button
            innerRef={expandToggleRef}
            kind="link"
            icon="angle-down"
            onClick={onToggleRowExpand}
            className={setClass(
              'expand-row-action__btn',
              '__v-table-ns',
              isRowExpanded && '--expanded',
            )}
          />
        </div>
      )}

      {colsChildren.map((colChild, colIdx) => {
        const colType = isValidTableColType(colChild.type)
          ? (COL_TYPE_MAP.get(colChild.type) as ColType)
          : 'col';

        return cloneElement(colChild, {
          ...colChild.props,
          datum: rowData[
            colChild.props.colName
          ] as unknown as DataRecord[Extract<keyof DataRecord, string>],
          rowData,
          rowNum,
          colNum: numFrozenRowActions + colIdx + 1,
          colType,
          isRowExpanded,
          tableRef,
          tableMetaRef,
          isLastCol: colIdx === colsChildren.length - 1,
          onClick: onToggleRowExpand,

          style: colChild.props.isFrozen
            ? {
                ...colChild.props.style,
                gridArea: `frozen-${rowNum}`,
                left: frozenLeftPosValues[numFrozenRowActions + colIdx],
                ...{ ...(isLastRow && { '--shadow-border-bottom': 'none' }) },
              }
            : {
                ...colChild.props.style,
                ...{ ...(isLastRow && { '--shadow-border-bottom': 'none' }) },
              },
        });
      })}

      {expandable && isRowExpanded && (
        <div
          role="rowgroup"
          data-v-table-row-expanded
          style={expandableGridStyle}
        >
          {renderExpandableRow()}
        </div>
      )}
    </>
  );
}
