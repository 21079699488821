// istanbul ignore file
import { useQuery } from '@tanstack/react-query';
import { api } from 'app/api/base';
import { URL_COMPUTERS } from 'src/app/api/urls';

const getComputers = (search: string) =>
  api(URL_COMPUTERS).get({
    search,
    filters: JSON.stringify([
      { name: 'model', operator: 'contains', value: 'mac' },
    ]),
  });

export const useSearchComputersQuery = (search: string) =>
  useQuery({
    queryKey: ['search-computers', search],
    queryFn: () => getComputers(search),
    enabled: search.length > 0,
  });
