import Axios, { type CancelToken } from 'axios';
import { enterpriseExternalApi } from 'src/app/api/base';
import { CANCEL_ERROR } from './sections/install-uploader';

const IPA_UPLOAD_URL = 'library/ipa-apps/upload';

export enum IpaUploadStatus {
  UPLOADED = 'UPLOADED',
  UPLOADING = 'UPLOADING',
  VALIDATED = 'VALIDATED',
  VALIDATE_FAILED = 'VALIDATE_FAILED',
}

export interface IpaApiStatusData {
  status: IpaUploadStatus;
  sha256: string;
  reason?: string;
  metadata: {
    app_identifier: string;
    icon: string | null | undefined;
    ipad_icon: string | null | undefined;
    app_version: string;
    app_name: string;
    app_icon: string | null | undefined;
    file_size: number;
    minimum_os_version: string | null | undefined;
    supported_device_families: string[] | null | undefined;
  };
}

export async function getIpaUploadStatus(
  pendingUploadId: string,
  cancelToken: CancelToken,
  libraryItemId: string | null | undefined,
  retries = 300,
  delay = 1000,
): Promise<IpaApiStatusData | undefined> {
  const uploadStatusApi = enterpriseExternalApi(
    `${IPA_UPLOAD_URL}/${pendingUploadId}/status`,
  );

  return new Promise((resolve, reject) => {
    uploadStatusApi
      .get({ library_item_id: libraryItemId }, { cancelToken })
      .then((response) => {
        const { data } = response;

        if (retries === 0) reject(new Error('Could not calculate SHA256'));

        switch (data.status) {
          case IpaUploadStatus.UPLOADED:
          case IpaUploadStatus.UPLOADING:
            setTimeout(() => {
              getIpaUploadStatus(
                pendingUploadId,
                cancelToken,
                libraryItemId,
                retries - 1,
                delay,
              ).then(resolve, reject);
            }, delay);
            break;

          case IpaUploadStatus.VALIDATE_FAILED:
          case IpaUploadStatus.VALIDATED:
          default:
            resolve(data);
        }
      })
      .catch((error) => {
        // silently fail when requests are cancelled
        if (error?.message === CANCEL_ERROR) {
          resolve(undefined);
          return;
        }

        reject(error);
      });
  });
}

export interface IpaS3Data {
  id: string;
  filename: string;
  sha256: null | string;
  expires: string;
  post_url: string;
  post_data: {
    key: string;
    'x-amz-algorithm': string;
    'x-amz-credential': string;
    'x-amz-date': string;
    'x-amz-security-token': string;
    policy: string;
    'x-amz-signature': string;
  };
  file_key: string;
}

export async function getIPAS3Data(
  filename: string,
  cancelToken,
): Promise<IpaS3Data> {
  const uploadApi = enterpriseExternalApi(IPA_UPLOAD_URL);
  const { data } = await uploadApi.post({ filename }, { cancelToken });

  return data;
}

export const uploadIpaAppToS3 = (ipaS3Data: IpaS3Data, file, options) => {
  try {
    const { post_url: url, post_data: postData } = ipaS3Data;
    const formData = new FormData();
    Object.entries(postData).forEach((values) => formData.append(...values));
    formData.append('file', file);
    return Axios.post(url, formData, options);
  } catch (err) {
    console.log(err);
  }
};

export const uploadIpaApp = (
  file,
  onUploadProgress,
  ipaS3Data: IpaS3Data,
  cancelToken: CancelToken,
) => {
  return {
    upload: uploadIpaAppToS3(ipaS3Data, file, {
      onUploadProgress: (p) =>
        onUploadProgress(Math.round((p.loaded / p.total) * 100)),
      cancelToken,
    }),
  };
};
