/* istanbul ignore file -- this file just container constants,
the consumers of this context should be tested instead */
import React, { createContext } from 'react';
import { DEMO_KEY_NAME } from '../config';
import * as constants from '../constants';

const baseDomain = constants.REACT_APP_BASE_DOMAIN;
const env = constants.REACT_APP_ENV_NAME;
const isEnableFastRefresh = constants.REACT_APP_FAST_REFRESH === 'true';

const demoModeValue = localStorage.getItem(DEMO_KEY_NAME);

export const branch = {
  DEV: 'dev',
  QA: 'qa',
  STAGE: 'stage',
  PRD: 'prd',
  'PRD-EU': 'prd-eu',
};

const domain = {
  DEV: 'mccaw',
  QA: 'apqa',
  STAGE: 'waystar',
  PRD: 'kandji',
  'PRD-EU': 'eu.kandji',
};

const defaultValues = {
  environment: undefined,
  environmentBranch: undefined,
  environmentDomain: undefined,
  showEnvironment: false,
  appEnv: env,
  isEnv: {
    local: false,
    dev: false,
    qa: false,
    stage: false,
    prod: false,
  },
  inclusionLvl: () => {},
  onlyInEnvs: () => {},
  isEnableFastRefresh,
};

const onlyInEnvs = (envs, currEnv) =>
  envs.some((e) => e.toLowerCase() === currEnv.toLowerCase());

const INCLUSION_LVL = {
  0: [],
  1: [branch.DEV, branch.QA],
  2: [branch.DEV, branch.QA, branch.STAGE],
  3: [branch.DEV, branch.QA, branch.STAGE, branch.PRD, branch['PRD-EU']],
};

function inclusionLvl(lvl) {
  if (lvl === 0) {
    return process.env.REACT_APP_IS_LOCAL === 'true';
  }

  return onlyInEnvs(INCLUSION_LVL[lvl], env || '');
}

const EnvironmentContext = createContext(defaultValues);

const EnvironmentProvider = ({ children }) => {
  const environment = inclusionLvl(2) && env;
  const environmentBranch = branch[env];
  const environmentDomain = domain[env];
  const showEnvironment = environment && !demoModeValue;

  let isEnv;

  if (!env || !baseDomain) {
    isEnv = defaultValues.isEnv;
  } else {
    isEnv = {
      local: baseDomain.includes('localhost'),
      dev: env.toLowerCase() === 'dev',
      /* ideally, dev would be different than local... */
      // dev: env.toLowerCase() === 'dev' && !baseDomain.includes('localhost'),
      qa: env.toLowerCase() === 'qa',
      stage: env.toLowerCase() === 'stage',
      prod: env.toLowerCase() === 'prd',
    };
  }

  return (
    <EnvironmentContext.Provider
      value={{
        ...defaultValues,
        environment,
        environmentBranch,
        environmentDomain,
        showEnvironment,
        inclusionLvl,
        onlyInEnvs: (envs) => onlyInEnvs(envs, env),
        isEnv,
      }}
    >
      {children}
    </EnvironmentContext.Provider>
  );
};

export { EnvironmentContext, EnvironmentProvider, inclusionLvl };
