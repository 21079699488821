import { setClass } from '@kandji-inc/bumblebee';
import React from 'react';
import { logo } from './set-preview-image';

const KandjiLogo = (props) => {
  const { isDarkMode, style, className } = props;
  return (
    <img
      draggable="false"
      style={style}
      className={setClass(className, 'k-library-preview__kandji-logo')}
      src={isDarkMode ? logo.kandjiLogoDark : logo.kandjiLogo}
      alt="Kandji Logo"
    />
  );
};

export default KandjiLogo;
