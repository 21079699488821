/* istanbul ignore file */
import { i18n } from 'src/i18n';

export const deviceVariables = () => [
  {
    name: i18n.t('Blueprint name'),
    value: 'blueprint_name',
  },
  {
    name: i18n.t('Asset tag'),
    value: 'asset_tag',
  },
  {
    name: i18n.t('Assigned user full name'),
    value: 'assigned_user',
  },
  {
    name: i18n.t('Assigned user first name'),
    value: 'assigned_user_firstname',
  },
  {
    name: i18n.t('Assigned user last name'),
    value: 'assigned_user_lastname',
  },
  {
    name: i18n.t('Company name'),
    value: 'company_name',
  },
  {
    name: i18n.t('Marketing name'),
    value: 'model_name',
  },
  {
    name: i18n.t('Model name'),
    value: 'mdm_model_name',
  },
  {
    name: i18n.t('Serial number'),
    value: 'serial_number',
  },
  {
    name: i18n.t('Primary local user name'),
    value: 'primary_local_user',
    isMacOnly: true,
  },
];

export const patternDefaults = () => [
  {
    value: i18n.t('MacBook Pro (14-inch, Nov 2023)'),
    key: 'model_name',
  },
  {
    value: i18n.t('MacBook Pro'),
    key: 'mdm_model_name',
  },
  {
    value: i18n.t('Heather Smith'),
    key: 'assigned_user',
  },
  {
    value: i18n.t('Heather'),
    key: 'assigned_user_firstname',
  },
  {
    value: i18n.t('Smith'),
    key: 'assigned_user_lastname',
  },
  {
    value: i18n.t('K023'),
    key: 'asset_tag',
  },
  {
    value: i18n.t('Blueprint'),
    key: 'blueprint_name',
  },
  {
    value: i18n.t('Accuhive'),
    key: 'company_name',
  },
  {
    value: i18n.t('S123456789'),
    key: 'serial_number',
  },
  {
    value: i18n.t('Morgan Freeman'),
    key: 'primary_local_user',
  },
];

export const hostnamePatternDefaults = () => [
  {
    value: i18n.t('MacBook-Pro-14-inch-Nov-2023'),
    key: 'model_name',
  },
  {
    value: i18n.t('MacBook Pro'),
    key: 'mdm_model_name',
  },
  {
    value: i18n.t('Heather-Smith'),
    key: 'assigned_user',
  },
  {
    value: i18n.t('Heather'),
    key: 'assigned_user_firstname',
  },
  {
    value: i18n.t('Smith'),
    key: 'assigned_user_lastname',
  },
  {
    value: i18n.t('K023'),
    key: 'asset_tag',
  },
  {
    value: i18n.t('Blueprint'),
    key: 'blueprint_name',
  },
  {
    value: i18n.t('Accuhive'),
    key: 'company_name',
  },
  {
    value: i18n.t('S123456789'),
    key: 'serial_number',
  },
  {
    value: i18n.t('Morgan-Freeman'),
    key: 'primary_local_user',
  },
];

export const getPatternDefaults = (kind: 'hostname' | 'device') => {
  if (kind === 'hostname') {
    return hostnamePatternDefaults();
  }

  return patternDefaults();
};
