import { Flex, Loader } from '@kandji-inc/nectar-ui';

type ThreatListLoaderProps = {
  size?: 'sm' | 'md' | 'lg';
  label?: string;
};

const ThreatListLoader = (props: ThreatListLoaderProps) => {
  const { label = '', size = 'lg' } = props;

  return (
    <Flex flex={1} justifyContent="center" data-testid="loader">
      <Loader size={size} label={label} />
    </Flex>
  );
};

export default ThreatListLoader;
