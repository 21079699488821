import { DATE_FORMAT } from 'app/components/common/helpers';
import { AccountContext } from 'contexts/account';
import { useContext } from 'react';

// TODO: use this once profile picture API is complete
// import PlaceholderAvatar from '../../assets/img/square-placeholder-avatar.svg';

export const useInitialForm = () => {
  const {
    userId,
    userFirstName,
    userLastName,
    userEmail,
    userLocale,
    userSettings,
  } = useContext(AccountContext);
  // const defaultProfilePicture = PlaceholderAvatar;
  return {
    first_name: userFirstName,
    last_name: userLastName,
    email: userEmail,
    id: userId,
    locale: userLocale,
    // profilePicture: defaultProfilePicture,
    settings: {
      ...userSettings,
      timezone: userSettings?.timezone || null,
      preferred_date_format:
        userSettings?.preferred_date_format || DATE_FORMAT.default,
      disable_relative_dates: userSettings?.disable_relative_dates || 'enable',
      send_weekly_emails:
        userSettings?.send_weekly_emails !== undefined
          ? userSettings.send_weekly_emails
          : true,
    },
  };
};

export const initialPasswords = {
  current: '',
  new: '',
  confirmNew: '',
};

export const useInitialPageState = () => ({
  isEditing: false,
  isAdding: false,
  isSaving: false,
  isValid: false,
});
