/* istanbul ignore file - legacy code, moved */
import { Button } from '@kandji-inc/bumblebee';
import React, { useCallback, useContext } from 'react';
import { useOldForm } from 'src/old-honey-form';
import { IPADataContext } from './IPADataContext';

export const IPAButtonCancel = () => {
  const { canEdit, isWorking, isEditing, isPendingValidation, onCancel } =
    useContext(IPADataContext);
  const { handleReset } = useOldForm();

  const onClick = useCallback(() => {
    if (!canEdit || !isEditing || isPendingValidation) {
      return;
    }

    handleReset();
    onCancel();
  }, [onCancel, handleReset, canEdit, isEditing, isPendingValidation]);

  return (
    <Button
      kind="outline"
      disabled={isWorking || isPendingValidation}
      onClick={onClick}
    >
      Cancel
    </Button>
  );
};
