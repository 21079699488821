import { useQuery } from '@tanstack/react-query';
import { newIntegrationsService } from 'src/features/integrations/data-service/new-integrations-service';
import type { IntegrationTypes } from '../types';

type ListIntegrations<ApiData, ApiError, SelectedData = ApiData> = {
  integrationType: IntegrationTypes;
  isAutoFetchEnabled?: boolean;
  initialData?: ApiData;
  select?: (data: ApiData) => SelectedData;
};

const useListIntegrations = <
  ApiData,
  ApiError = Error,
  SelectedData = ApiData,
>({
  integrationType,
  isAutoFetchEnabled = true,
  initialData,
  select,
}: ListIntegrations<ApiData, ApiError, SelectedData>) =>
  // we need to kick off a job for refreshing channels since they may not be available to us in the backend. We need this call to query slack again.
  useQuery<ApiData, ApiError, SelectedData>({
    queryKey: ['integrations', integrationType],
    queryFn: () => newIntegrationsService.listIntegrations(integrationType),
    enabled: isAutoFetchEnabled,
    initialData,
    select,
  });

export default useListIntegrations;
