import { colors } from 'app/common/constants';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Header = styled.section`
  display: flex;
  align-items: center;
`;

const HeaderContent = styled.div``;

const ArrowWrapper = styled.i`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
`;

const Arrow = styled.i`
  color: ${colors.grey999};
  line-height: 1.6em;
  transition: 0.3s;
`;

const Body = styled('section')`
  height: 0;
  transition: height 0.3s;
  overflow: hidden;
  padding-left: 30px;
  &.opened {
    height: ${(props) => props.bodyHeight}px;
  }
`;

const Collapsible = ({ header, body, collapsedInitial }) => {
  const [collapsed, setCollapsed] = useState(collapsedInitial);
  const [bodyHeight, setBodyHeight] = useState(0);
  const bodyRef = useRef(null);
  useEffect(() => {
    if (bodyRef.current) {
      setBodyHeight(bodyRef.current.offsetHeight);
    }
  }, []);
  const handleOnHeaderClick = () => setCollapsed(!collapsed);
  return (
    <Wrapper>
      <Header onClick={handleOnHeaderClick}>
        <ArrowWrapper>
          <Arrow
            className={classNames('fas fa-caret-right', {
              'fa-rotate-90': !collapsed,
            })}
          />
        </ArrowWrapper>
        <HeaderContent>{header}</HeaderContent>
      </Header>
      <Body
        className={classNames({ opened: !collapsed })}
        bodyHeight={bodyHeight}
      >
        {React.cloneElement(body, {
          ref: bodyRef,
        })}
      </Body>
    </Wrapper>
  );
};

Collapsible.propTypes = {
  header: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
  collapsedInitial: PropTypes.bool,
};

Collapsible.defaultProps = {
  collapsedInitial: true,
};

export default Collapsible;
