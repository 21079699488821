/* istanbul ignore file */
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { rootApi } from 'src/app/api/base';
import { queryClient } from 'src/app/router';
import type { Quote } from './useGetQuote';

type QuoteParams = {
  numberOfIosDevices: number;
  numberOfMacDevices: number;
  isEdrEnabled: boolean;
};

export const useGenerateQuote = () =>
  useMutation({
    mutationKey: ['generateQuote'],
    mutationFn: async (data: QuoteParams) => {
      const { numberOfIosDevices, numberOfMacDevices, isEdrEnabled } = data;

      const apiRes = await rootApi('trial/v1/quotes').post({
        mac_os_device_count: numberOfMacDevices,
        ios_apple_tv_device_count: numberOfIosDevices,
        edr_enabled: isEdrEnabled,
      });

      const quote = apiRes.data?.quote;

      return {
        macOsDeviceCount: quote.mac_os_device_count,
        macOsUnitPrice: quote.mac_os_unit_price,
        macOsPriceTotal: quote.mac_os_price_total,
        edrEnabled: quote.edr_enabled,
        edrPriceTotal: quote.edr_price_total,
        iosAppleTvDeviceCount: quote.ios_apple_tv_device_count,
        iosAppleTvDeviceUnitPrice: quote.ios_apple_tv_device_unit_price,
        iosAppleTvPriceTotal: quote.ios_apple_tv_price_total,
        quoteTotal: quote.quote_total,
        quoteTotalCurrency: quote.quote_total_currency,
        quoteContractTermMonths: quote.quote_contract_term_months,
        quoteUrl: quote.quote_url,
        quoteRef: quote.quote_ref,
      } as Quote;
    },
    onSuccess: (data) => queryClient.setQueryData(['get-quote'], data),
  });
