/* istanbul ignore file */
import { WebClipUploader } from 'components/common/s3-upload';
import imageToFile from 'features/self-service-new/api/tofile';
import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class WebclipService extends NewLibraryItemService {
  async uploadWebClipImage(dataUrl, filename) {
    const file = imageToFile(dataUrl, filename.name);

    if (!file) {
      return null;
    }

    const s3Data = await WebClipUploader.getS3UploadData([
      { name: filename.name },
    ]);

    await WebClipUploader.upload({
      file,
      s3Data: s3Data.data.files[0].s3_post_data,
    });

    return s3Data.data.files[0].s3_post_data;
  }
}

export const webclipService = new WebclipService();
