import type { IpaFile } from '../in-house-apps.types';

export function currentFileFromIpaFile(ipaFile: IpaFile) {
  const { sha256, fileName, appName, fileSize } = ipaFile;

  if (sha256 == null) return null;

  return {
    file: {
      name: appName != null ? `${appName}.ipa` : fileName,
      size: fileSize,
    },
    sha256,
  };
}
