/* istanbul ignore file */
import { createContext, useContext } from 'react';
import type { UseSyncUrlWithTableStateReturnType } from '../hooks/sync-url';
import { useSyncUrlWithTableState } from '../hooks/sync-url';

export interface UrlContextType extends UseSyncUrlWithTableStateReturnType {}

export const UrlContext = createContext<UrlContextType>(null);

export const UrlProvider = ({ children }) => {
  const {
    filter,
    limit,
    offset,
    setFilter,
    removeFilter,
    setLimit,
    setOffset,
    resetFilter,
    replaceFilter,
  } = useSyncUrlWithTableState();

  return (
    <UrlContext.Provider
      value={{
        filter,
        limit,
        offset,
        setFilter,
        removeFilter,
        setLimit,
        setOffset,
        resetFilter,
        replaceFilter,
      }}
    >
      {children}
    </UrlContext.Provider>
  );
};

// istanbul ignore next
export const useUrlContext = () => useContext(UrlContext);
