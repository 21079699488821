/* istanbul ignore file */
import featureFlags from 'src/config/feature-flags';
import {
  i18nOsRequirements,
  osRequirements,
} from 'src/features/library-items/library/common';
import { i18n } from 'src/i18n';

const orderScreensById = (screens) =>
  screens().reduce((screensById, screen) => {
    screensById[screen.id] = screen;
    return screensById;
  }, {});

export const MAC_SKIP_SCREENS = () => {
  return [
    {
      id: 'Accessibility',
      label: i18n.t('Accessibility'),
      description: i18n.t(
        'Skips the Accessibility pane, only if the Mac is connected to Ethernet and the cloud config is downloaded.',
      ),
      requirements: [i18nOsRequirements(osRequirements.MAC_10_11)],
      isSelected: false,
    },
    {
      id: 'Appearance',
      label: i18n.t('Appearance'),
      description: i18n.t(
        'Users will not be prompted to configure Light/Dark Mode.',
      ),
      requirements: [i18nOsRequirements(osRequirements.MAC_10_14)],
      isSelected: false,
    },
    {
      id: 'AppleID',
      label: i18n.t('Apple Account'),
      description: i18n.t(
        'Users will not be prompted to sign in with their Apple Account.',
      ),
      requirements: [
        i18nOsRequirements(osRequirements.MAC_10_9),
        'NIST',
        'STIG/SRG',
      ],
      isSelected: false,
    },
    {
      id: 'AppStore',
      label: i18n.t('App Store'),
      description: i18n.t(
        'Users will not be presented with the App Store pane',
      ),
      requirements: [i18nOsRequirements(osRequirements.MAC_11_1)],
      isSelected: false,
    },
    {
      id: 'Payment',
      label: i18n.t('Apple Pay'),
      description: i18n.t('Users will not be prompted to configure Apple Pay.'),
      requirements: [i18nOsRequirements(osRequirements.MAC_10_12_4)],
      isSelected: false,
    },
    {
      id: 'Biometric',
      label: i18n.t('Biometric'),
      description: i18n.t('Users will not be prompted configure Touch ID.'),
      requirements: [
        i18nOsRequirements(osRequirements.MAC_10_12_4),
        'NIST',
        'STIG/SRG',
      ],
      isSelected: false,
    },
    {
      id: 'Diagnostics',
      label: i18n.t('Diagnostics'),
      description: i18n.t(
        'Users will not be prompted to configure diagnostics submission. When skipped, automatic diagnostics submission will be turned off.',
      ),
      requirements: [
        i18nOsRequirements(osRequirements.MAC_10_9),
        'NIST',
        'STIG/SRG',
      ],
      isSelected: false,
    },
    {
      id: 'EnableLockdownMode',
      label: i18n.t('Enable Lockdown Mode'),
      description: i18n.t(
        'Users will not be prompted to configure the Lockdown Mode pane if an Apple Account/iCloud account is set up.',
      ),
      requirements: [i18nOsRequirements(osRequirements.MAC_14_0)],
      isSelected: false,
    },
    {
      id: 'FileVault',
      label: i18n.t('FileVault'),
      description: i18n.t(
        'Users will not be prompted to configure FileVault disk encryption.',
      ),
      requirements: [i18nOsRequirements(osRequirements.MAC_10_10)],
      isSelected: false,
    },
    {
      id: 'iCloudDiagnostics',
      label: i18n.t('iCloud Analytics'),
      description: i18n.t(
        'Users will not be prompted to share iCloud usage and data details with Apple. When skipped, analytics information is not shared with Apple by default.',
      ),
      requirements: [i18nOsRequirements(osRequirements.MAC_10_12_4)],
      isSelected: false,
    },
    {
      id: 'iCloudStorage',
      label: i18n.t('iCloud Storage'),
      description: i18n.t(
        'Users will not be prompted to configure the iCloud Desktop & Documents Folders sync feature. When skipped, this feature will be turned off.',
      ),
      requirements: [
        i18nOsRequirements(osRequirements.MAC_10_13_4),
        'NIST',
        'STIG/SRG',
      ],
      isSelected: false,
    },
    {
      id: 'Intelligence',
      label: i18n.t('Intelligence'),
      description: i18n.t(
        'Users will not be presented with the Apple Intelligence pane.',
      ),
      requirements: [i18nOsRequirements(osRequirements.MAC_15_0)],
      isSelected: false,
    },
    {
      id: 'Location',
      label: i18n.t('Location Services'),
      description: i18n.t(
        'Users will not be prompted to configure Location Services. When skipped, Location Services will be turned off.',
      ),
      requirements: [i18nOsRequirements(osRequirements.MAC_10_11)],
      isSelected: false,
    },
    {
      id: 'Privacy',
      label: i18n.t('Privacy'),
      description: i18n.t(
        'Users will not be prompted to review the Apple Privacy Policy. When skipped, the policy is automatically accepted.',
      ),
      requirements: [
        i18nOsRequirements(osRequirements.MAC_10_13_4),
        'STIG/SRG',
      ],
      isSelected: false,
    },
    {
      id: 'Restore',
      label: i18n.t('Restore'),
      description: i18n.t(
        'Users will not be prompted to restore from a backup.',
      ),
      requirements: [i18nOsRequirements(osRequirements.MAC_10_9)],
      isSelected: false,
    },
    {
      id: 'ScreenTime',
      label: i18n.t('Screen Time'),
      description: i18n.t(
        'Users will not be prompted to configure the Screen Time feature.',
      ),
      requirements: [i18nOsRequirements(osRequirements.MAC_10_15)],
      isSelected: false,
    },
    {
      id: 'Siri',
      label: i18n.t('Siri'),
      description: i18n.t(
        'Users will not be prompted to configure Siri. When skipped, Siri will be turned off.',
      ),
      requirements: [
        i18nOsRequirements(osRequirements.MAC_10_12),
        'NIST',
        'STIG/SRG',
      ],
      isSelected: false,
    },
    {
      id: 'TOS',
      label: i18n.t('Terms and Conditions'),
      description: i18n.t(
        'Users will not be prompted to review the Apple Terms and Conditions. When skipped, the terms and conditions are automatically agreed to.',
      ),
      requirements: [i18nOsRequirements(osRequirements.MAC_10_9)],
      isSelected: false,
    },
    {
      id: 'TermsOfAddress',
      label: i18n.t('Terms of Address'),
      description: i18n.t(
        'Users will not be prompted to set up Terms of Address. Will only be skipped if the Mac is on Ethernet.',
      ),
      requirements: [i18nOsRequirements(osRequirements.MAC_13_0)],
      isSelected: false,
    },
    {
      id: 'DisplayTone',
      label: i18n.t('True Tone Display'),
      description: i18n.t(
        'Users will not be prompted to configure the True Tone Display feature.',
      ),
      requirements: [i18nOsRequirements(osRequirements.MAC_10_13_6)],
      isSelected: false,
    },
    /* To be added -> */
    {
      id: 'UnlockWatch',
      label: i18n.t('Unlock with Watch'),
      description: i18n.t(
        'Users will not be prompted to configure unlocking the Mac with Apple Watch. When skipped, the Mac will not unlock with Apple Watch unless the user configures it in System Preferences.',
      ),
      requirements: [i18nOsRequirements(osRequirements.MAC_10_12)],
      isSelected: false,
    },
    {
      id: 'Wallpaper',
      label: i18n.t('Wallpaper'),
      description: i18n.t('Users will not be prompted to select a Wallpaper'),
      requirements: [i18nOsRequirements(osRequirements.MAC_14_1)],
      isSelected: false,
    },
    {
      id: 'Welcome',
      label: i18n.t('Welcome'),
      description: i18n.t(
        'Users will not be presented with the Get Started pane.',
      ),
      requirements: [i18nOsRequirements(osRequirements.MAC_15_0)],
      isSelected: false,
    },
    /* To be removed -> */
    // {
    //   id: 'Registration',
    //  i18n.t( label: 'Registration'),
    //   description:
    //     'Users will not be prompted to register the device with Apple. When skipped, the device will not be registered. This does not impact AppleCare warranty coverage.',
    //   requirements: [],
    //   isSelected: false,
    // },
  ];
};

export const APPLE_TV_SKIP_SCREENS = () => [
  {
    id: 'AppleID',
    label: i18n.t('Apple Account'),
    description: i18n.t(
      'Users will not be prompted to sign in with their Apple Account.',
    ),
    requirements: [
      i18nOsRequirements(osRequirements.TV_10_2),
      'NIST',
      'STIG/SRG',
    ],
    isSelected: false,
  },
  {
    id: 'Diagnostics',
    label: i18n.t('Diagnostics'),
    description: i18n.t(
      'Users will not be prompted to configure diagnostics submission. When skipped, automatic diagnostics submission will be turned off.',
    ),
    requirements: [
      i18nOsRequirements(osRequirements.TV_10_2),
      'NIST',
      'STIG/SRG',
    ],
    isSelected: false,
  },
  {
    id: 'Privacy',
    label: i18n.t('Privacy'),
    description: i18n.t(
      'Users will not be prompted to review the Apple Privacy Policy. When skipped, the policy is automatically accepted.',
    ),
    requirements: [i18nOsRequirements(osRequirements.TV_11_13), 'STIG/SRG'],
    isSelected: false,
  },
  {
    id: 'ScreenSaver',
    label: i18n.t('Screen Saver'),
    description: i18n.t(
      'Skips the tvOS screen about using aerial screensavers in ATV.',
    ),
    requirements: [i18nOsRequirements(osRequirements.TV_10_2)],
    isSelected: false,
  },
  {
    id: 'Siri',
    label: i18n.t('Siri'),
    description: i18n.t(
      'Users will not be prompted to configure Siri. When skipped, Siri will be turned off.',
    ),
    requirements: [
      i18nOsRequirements(osRequirements.TV_10_2),
      'NIST',
      'STIG/SRG',
    ],
    isSelected: false,
  },
  {
    id: 'TapToSetup',
    label: i18n.t('Tap To Set Up'),
    description: i18n.t(
      'Skips the Tap To Set Up option in ATV about using an iOS device to set up your ATV.',
    ),
    requirements: [i18nOsRequirements(osRequirements.TV_10_2)],
    isSelected: false,
  },
  {
    id: 'TOS',
    label: i18n.t('Terms and Conditions'),
    description: i18n.t(
      'Users will not be prompted to review the Apple Terms and Conditions. When skipped, the terms and conditions are automatically agreed to.',
    ),
    requirements: [i18nOsRequirements(osRequirements.TV_10_2)],
    isSelected: false,
  },
  /* To be added -> */
  {
    id: 'TVHomeScreenSync',
    label: i18n.t('TV Home Screen Sync'),
    description: i18n.t(
      'Users will not be prompted to sync the home screen with other Apple TV devices logged in with the same Apple Account.',
    ),
    requirements: [i18nOsRequirements(osRequirements.TV_11_0)],
    isSelected: false,
  },
  {
    id: 'TVProviderSignIn',
    label: i18n.t('TV Provider Sign In'),
    description: i18n.t('Skips the TV provider sign in screen in tvOS.'),
    requirements: [i18nOsRequirements(osRequirements.TV_11_0)],
    isSelected: false,
  },
  {
    id: 'TVRoom',
    label: i18n.t('TV Room'),
    description: i18n.t('Skips the "Where is this Apple TV?" screen in tvOS.'),
    requirements: [i18nOsRequirements(osRequirements.TV_11_4)],
    isSelected: false,
  },
];

export const IPHONE_SKIP_SCREENS = () => {
  return [
    {
      id: 'ActionButton',
      label: i18n.t('Action Button'),
      description: i18n.t(
        'Users will not be prompted to configure the Action Button on supported iPhones.',
      ),
      requirements: [i18nOsRequirements(osRequirements.IOS_17_0)],
      isSelected: false,
    },
    {
      id: 'Android',
      label: i18n.t('Android Migration'),
      description: i18n.t(
        'Users will not be prompted to move from Android. Note that this pane is only displayed if the Restore pane is not skipped.',
      ),
      requirements: [i18nOsRequirements(osRequirements.IOS_9_0)],
      isSelected: false,
    },
    {
      id: 'AppStore',
      label: i18n.t('App Store'),
      description: i18n.t(
        'Users will not be presented with the App Store pane',
      ),
      requirements: [i18nOsRequirements(osRequirements.IOS_14_3)],
      isSelected: false,
    },
    {
      id: 'Appearance',
      label: i18n.t('Appearance'),
      description: i18n.t(
        'Users will not be prompted to configure Light/Dark Mode.',
      ),
      requirements: [i18nOsRequirements(osRequirements.IOS_13_0)],
      isSelected: false,
    },
    {
      id: 'AppleID',
      label: i18n.t('Apple Account'),
      description: i18n.t(
        'Users will not be prompted to sign in with their Apple Account.',
      ),
      requirements: [
        i18nOsRequirements(osRequirements.IOS_7_0),
        'NIST',
        'STIG/SRG',
      ],
      isSelected: false,
    },
    {
      id: 'CameraButton',
      label: i18n.t('Camera Button'),
      description: i18n.t(
        'Users will not be presented with the Camera Button pane.',
      ),
      requirements: [i18nOsRequirements(osRequirements.IOS_18_0)],
      isSelected: false,
    },
    {
      id: 'Payment',
      label: i18n.t('Apple Pay'),
      description: i18n.t('Users will not be prompted to configure Apple Pay.'),
      requirements: [i18nOsRequirements(osRequirements.IOS_8_1)],
      isSelected: false,
    },
    {
      id: 'WatchMigration',
      label: i18n.t('Apple Watch Migration'),
      description: i18n.t(
        'Users will not be prompted to configure their Apple Watch when restoring from a previous backup that was paired with an Apple Watch.',
      ),
      requirements: [i18nOsRequirements(osRequirements.IOS_11_0)],
      isSelected: false,
    },
    {
      id: 'Biometric',
      label: i18n.t('Biometric'),
      description: i18n.t(
        'Users will not be prompted configure Touch ID or Face ID.',
      ),
      requirements: [
        i18nOsRequirements(osRequirements.IOS_8_1),
        'NIST',
        'STIG/SRG',
      ],
      isSelected: false,
    },
    {
      id: 'SIMSetup',
      label: i18n.t('Cellular Plan'),
      description: i18n.t('Users will not be prompted to add a cellular plan.'),
      requirements: [i18nOsRequirements(osRequirements.IOS_12_0)],
      isSelected: false,
    },
    {
      id: 'DeviceToDeviceMigration',
      label: i18n.t('Device to Device Migration'),
      description: i18n.t(
        'Users will not be prompted to initiate a device to device migration.',
      ),
      requirements: [i18nOsRequirements(osRequirements.IOS_13_0)],
      isSelected: false,
    },
    {
      id: 'Diagnostics',
      label: i18n.t('Diagnostics'),
      description: i18n.t(
        'Users will not be prompted to configure diagnostics submission. When skipped, automatic diagnostics submission will be turned off.',
      ),
      requirements: [
        i18nOsRequirements(osRequirements.IOS_7_0),
        'NIST',
        'STIG/SRG',
      ],
      isSelected: false,
    },
    {
      id: 'Zoom',
      label: i18n.t('Display Zoom'),
      description: i18n.t(
        'Users will not be prompted to configure Display Zoom settings. When skipped, standard text sizes and control sizes will be used.',
      ),
      requirements: [i18nOsRequirements(osRequirements.IOS_8_3)],
      isSelected: false,
    },
    {
      id: 'HomeButtonSensitivity',
      label: i18n.t('Home Button Sensitivity'),
      description: i18n.t(
        'Users will not be prompted to configure the Home button feedback sensitivity setting.',
      ),
      requirements: [i18nOsRequirements(osRequirements.IOS_10_0)],
      isSelected: false,
    },
    {
      id: 'iMessageAndFaceTime',
      label: i18n.t('iMessage and FaceTime'),
      description: i18n.t(
        'Users will not be prompted to configure iMessage and FaceTime features.',
      ),
      requirements: [i18nOsRequirements(osRequirements.IOS_12_0)],
      isSelected: false,
    },
    {
      id: 'Intelligence',
      label: i18n.t('Intelligence'),
      description: i18n.t(
        'Users will not be presented with the Apple Intelligence pane.',
      ),
      requirements: [i18nOsRequirements(osRequirements.IOS_18_0)],
      isSelected: false,
    },
    {
      id: 'Location',
      label: i18n.t('Location Services'),
      description: i18n.t(
        'Users will not be prompted to configure Location Services. When skipped, Location Services will be turned off.',
      ),
      requirements: [i18nOsRequirements(osRequirements.IOS_7_0)],
      isSelected: false,
    },
    /* To be added -> */
    {
      id: 'MessageActivatePhoneNumber',
      label: i18n.t('Messaging Activation Using Phone Number'),
      description: i18n.t('Skips the iMessage screen.'),
      requirements: [i18nOsRequirements(osRequirements.IOS_10_0)],
      isSelected: false,
    },
    {
      id: 'Passcode',
      label: i18n.t('Passcode'),
      description: i18n.t('Users will not be prompted to create a passcode.'),
      requirements: [i18nOsRequirements(osRequirements.IOS_7_0)],
      isSelected: false,
    },
    {
      id: 'Privacy',
      label: i18n.t('Privacy'),
      description: i18n.t(
        'Users will not be prompted to review the Apple Privacy Policy. When skipped, the policy is automatically accepted.',
      ),
      requirements: [i18nOsRequirements(osRequirements.IOS_11_13), 'STIG/SRG'],
      isSelected: false,
    },
    {
      id: 'Restore',
      label: i18n.t('Restore'),
      description: i18n.t(
        'Users will not be prompted to restore from a backup.',
      ),
      requirements: [i18nOsRequirements(osRequirements.IOS_7_0)],
      isSelected: false,
    },
    {
      id: 'RestoreCompleted',
      label: i18n.t('Restore Completed'),
      description: i18n.t('Skips the Restore Completed pane.'),
      requirements: [i18nOsRequirements(osRequirements.IOS_14_0)],
      isSelected: false,
    },
    {
      id: 'Safety',
      label: i18n.t('Safety'),
      description: i18n.t('Users will not be presented with the Safety pane'),
      requirements: [i18nOsRequirements(osRequirements.IOS_16_0)],
      isSelected: false,
    },
    {
      id: 'ScreenTime',
      label: i18n.t('Screen Time'),
      description: i18n.t(
        'Users will not be prompted to configure the Screen Time feature.',
      ),
      requirements: [i18nOsRequirements(osRequirements.IOS_12_0)],
      isSelected: false,
    },
    {
      id: 'Siri',
      label: i18n.t('Siri'),
      description: i18n.t(
        'Users will not be prompted to configure Siri. When skipped, Siri will be turned off.',
      ),
      requirements: [
        i18nOsRequirements(osRequirements.IOS_7_0),
        'NIST',
        'STIG/SRG',
      ],
      isSelected: false,
    },
    {
      id: 'SoftwareUpdate',
      label: i18n.t('Software Update'),
      description: i18n.t(
        'Users will not be prompted to configure automatic software updates. When skipped, automatic software updates will be turned off.',
      ),
      requirements: [i18nOsRequirements(osRequirements.IOS_12_0)],
      isSelected: false,
    },
    {
      id: 'UpdateCompleted',
      label: i18n.t('Software Update Complete'),
      description: i18n.t('Skips the Software Update Complete pane.'),
      requirements: [i18nOsRequirements(osRequirements.IOS_14_0)],
      isSelected: false,
    },
    {
      id: 'TOS',
      label: i18n.t('Terms and Conditions'),
      description: i18n.t(
        'Users will not be prompted to review the Apple Terms and Conditions. When skipped, the terms and conditions are automatically agreed to.',
      ),
      requirements: [i18nOsRequirements(osRequirements.IOS_7_0)],
      isSelected: false,
    },
    {
      id: 'DisplayTone',
      label: i18n.t('True Tone Display'),
      description: i18n.t(
        'Users will not be prompted to configure the True Tone Display feature.',
      ),
      requirements: [i18nOsRequirements(osRequirements.IOS_9_3_2)],
      isSelected: false,
    },
    {
      id: 'OnBoarding',
      label: i18n.t('User Education'),
      description: i18n.t(
        'Users will not be presented with informational screens for features such as Control Center, Cover Sheet, and Multitasking.',
      ),
      requirements: [i18n.t('iOS 11.0 - iOS 13.6')],
      isSelected: false,
    },
    {
      id: 'Welcome',
      label: i18n.t('Welcome'),
      description: i18n.t(
        'Users will not be presented with the Get Started pane.',
      ),
      requirements: [i18nOsRequirements(osRequirements.IOS_13_0)],
      isSelected: false,
    },
    /* To be removed -> */
    // {
    //   id: 'Keyboard',
    //  i18n.t( label: 'Keyboard'),
    //   description: i18n.t('Users will not be prompted to configure the keyboard.'),
    //   requirements: [],
    //   isSelected: false,
    // },
  ];
};

export const IPAD_SKIP_SCREENS = () => {
  return [
    {
      id: 'Android',
      label: i18n.t('Android Migration'),
      description: i18n.t(
        'Users will not be prompted to move from Android. Note that this pane is only displayed if the Restore pane is not skipped.',
      ),
      requirements: [i18nOsRequirements(osRequirements.IOS_9_0)],
      isSelected: false,
    },
    {
      id: 'AppStore',
      label: i18n.t('App Store'),
      description: i18n.t(
        'Users will not be presented with the App Store pane',
      ),
      requirements: [i18nOsRequirements(osRequirements.IPAD_14_3)],
      isSelected: false,
    },
    {
      id: 'Appearance',
      label: i18n.t('Appearance'),
      description: i18n.t(
        'Users will not be prompted to configure Light/Dark Mode.',
      ),
      requirements: [i18nOsRequirements(osRequirements.IPAD_13_0)],
      isSelected: false,
    },
    {
      id: 'AppleID',
      label: i18n.t('Apple Account'),
      description: i18n.t(
        'Users will not be prompted to sign in with their Apple Account.',
      ),
      requirements: [
        i18nOsRequirements(osRequirements.IOS_7_0),
        'NIST',
        'STIG/SRG',
      ],
      isSelected: false,
    },
    {
      id: 'Payment',
      label: i18n.t('Apple Pay'),
      description: i18n.t('Users will not be prompted to configure Apple Pay.'),
      requirements: [i18nOsRequirements(osRequirements.IOS_8_1)],
      isSelected: false,
    },
    /* To be removed -> */
    // {
    //   id: 'WatchMigration',
    //  i18n.t( label: 'Apple Watch Migration'),
    //   description:
    //     i18n.t('Users will not be prompted to configure their Apple Watch when restoring from a previous backup that was paired with an Apple Watch.'),
    //   requirements: [],
    //   isSelected: false,
    // },
    {
      id: 'Biometric',
      label: i18n.t('Biometric'),
      description: i18n.t(
        'Users will not be prompted configure Touch ID or Face ID.',
      ),
      requirements: [
        i18nOsRequirements(osRequirements.IOS_8_1),
        'NIST',
        'STIG/SRG',
      ],
      isSelected: false,
    },
    {
      id: 'SIMSetup',
      label: i18n.t('Cellular Plan'),
      description: i18n.t('Users will not be prompted to add a cellular plan.'),
      requirements: [i18nOsRequirements(osRequirements.IOS_12_0)],
      isSelected: false,
    },
    {
      id: 'DeviceToDeviceMigration',
      label: i18n.t('Device to Device Migration'),
      description: i18n.t(
        'Users will not be prompted to initiate a device to device migration.',
      ),
      requirements: [i18nOsRequirements(osRequirements.IPAD_13_0)],
      isSelected: false,
    },
    {
      id: 'Diagnostics',
      label: i18n.t('Diagnostics'),
      description: i18n.t(
        'Users will not be prompted to configure diagnostics submission. When skipped, automatic diagnostics submission will be turned off.',
      ),
      requirements: [
        i18nOsRequirements(osRequirements.IOS_7_0),
        'NIST',
        'STIG/SRG',
      ],
      isSelected: false,
    },
    {
      id: 'Zoom',
      label: i18n.t('Display Zoom'),
      description: i18n.t(
        'Users will not be prompted to configure Display Zoom settings. When skipped, standard text sizes and control sizes will be used.',
      ),
      requirements: [i18nOsRequirements(osRequirements.IOS_8_3)],
      isSelected: false,
    },
    /* To be removed -> */
    // {
    //   id: 'HomeButtonSensitivity',
    //  i18n.t( label: 'Home Button Sensitivity'),
    //   description:
    //     i18n.t('Users will not be prompted to configure the Home button feedback sensitivity setting.'),
    //   requirements: [i18nOsRequirements(osRequirements.IOS_10_0)],
    //   isSelected: false,
    //   hide: true,
    // },
    {
      id: 'iMessageAndFaceTime',
      label: i18n.t('iMessage and FaceTime'),
      description: i18n.t(
        'Users will not be prompted to configure iMessage and FaceTime features.',
      ),
      requirements: [i18nOsRequirements(osRequirements.IOS_12_0)],
      isSelected: false,
    },
    {
      id: 'Intelligence',
      label: i18n.t('Intelligence'),
      description: i18n.t(
        'Users will not be presented with the Apple Intelligence pane.',
      ),
      requirements: [i18nOsRequirements(osRequirements.IOS_18_0)],
      isSelected: false,
    },
    {
      id: 'Location',
      label: i18n.t('Location Services'),
      description: i18n.t(
        'Users will not be prompted to configure Location Services. When skipped, Location Services will be turned off.',
      ),
      requirements: [i18nOsRequirements(osRequirements.IOS_7_0)],
      isSelected: false,
    },
    /* To be added -> */
    {
      id: 'MessageActivatePhoneNumber',
      label: i18n.t('Messaging Activation Using Phone Number'),
      description: i18n.t('Skips the iMessage screen.'),
      requirements: [i18nOsRequirements(osRequirements.IOS_10_0)],
      isSelected: false,
    },
    {
      id: 'Passcode',
      label: i18n.t('Passcode'),
      description: i18n.t('Users will not be prompted to create a passcode.'),
      requirements: [i18nOsRequirements(osRequirements.IOS_7_0)],
      isSelected: false,
    },
    {
      id: 'Privacy',
      label: i18n.t('Privacy'),
      description: i18n.t(
        'Users will not be prompted to review the Apple Privacy Policy. When skipped, the policy is automatically accepted.',
      ),
      requirements: [i18nOsRequirements(osRequirements.IOS_11_13), 'STIG/SRG'],
      isSelected: false,
    },
    {
      id: 'Restore',
      label: i18n.t('Restore'),
      description: i18n.t(
        'Users will not be prompted to restore from a backup.',
      ),
      requirements: [i18nOsRequirements(osRequirements.IOS_7_0)],
      isSelected: false,
    },
    {
      id: 'RestoreCompleted',
      label: i18n.t('Restore Completed'),
      description: i18n.t('Skips the Restore Completed pane.'),
      requirements: [i18nOsRequirements(osRequirements.IPAD_14_0)],
      isSelected: false,
    },
    {
      id: 'Safety',
      label: i18n.t('Safety'),
      description: i18n.t('Users will not be presented with the Safety pane'),
      requirements: [i18nOsRequirements(osRequirements.IPAD_16_0)],
      isSelected: false,
    },
    {
      id: 'ScreenTime',
      label: i18n.t('Screen Time'),
      description: i18n.t(
        'Users will not be prompted to configure the Screen Time feature.',
      ),
      requirements: [i18nOsRequirements(osRequirements.IOS_12_0)],
      isSelected: false,
    },
    {
      id: 'Siri',
      label: i18n.t('Siri'),
      description: i18n.t(
        'Users will not be prompted to configure Siri. When skipped, Siri will be turned off.',
      ),
      requirements: [
        i18nOsRequirements(osRequirements.IOS_7_0),
        'NIST',
        'STIG/SRG',
      ],
      isSelected: false,
    },
    {
      id: 'SoftwareUpdate',
      label: i18n.t('Software Update'),
      description: i18n.t(
        'Users will not be prompted to configure automatic software updates. When skipped, automatic software updates will be turned off.',
      ),
      requirements: [i18nOsRequirements(osRequirements.IOS_12_0)],
      isSelected: false,
    },
    {
      id: 'UpdateCompleted',
      label: i18n.t('Software Update Complete'),
      description: i18n.t('Skips the Software Update Complete pane.'),
      requirements: [i18nOsRequirements(osRequirements.IPAD_14_0)],
      isSelected: false,
    },
    {
      id: 'TOS',
      label: i18n.t('Terms and Conditions'),
      description: i18n.t(
        'Users will not be prompted to review the Apple Terms and Conditions. When skipped, the terms and conditions are automatically agreed to.',
      ),
      requirements: [i18nOsRequirements(osRequirements.IOS_7_0)],
      isSelected: false,
    },
    {
      id: 'DisplayTone',
      label: i18n.t('True Tone Display'),
      description: i18n.t(
        'Users will not be prompted to configure the True Tone Display feature.',
      ),
      requirements: [i18nOsRequirements(osRequirements.IOS_9_3_2)],
      isSelected: false,
    },
    {
      id: 'OnBoarding',
      label: i18n.t('User Education'),
      description: i18n.t(
        'Users will not be presented with informational screens for features such as Control Center, Cover Sheet, and Multitasking.',
      ),
      requirements: [
        i18nOsRequirements(osRequirements.IPAD_13_6),
        i18nOsRequirements(osRequirements.IOS_11_0),
      ],
      isSelected: false,
    },
    {
      id: 'Welcome',
      label: i18n.t('Welcome'),
      description: i18n.t(
        'Users will not be presented with the Get Started pane.',
      ),
      requirements: [i18nOsRequirements(osRequirements.IPAD_13_0)],
      isSelected: false,
    },
    /* To be removed -> */
    // {
    //   id: 'Keyboard',
    //  i18n.t( label: 'Keyboard'),
    //   description: i18n.t('Users will not be prompted to configure the keyboard.'),
    //   requirements: [],
    //   isSelected: false,
    // },
  ];
};

export const screensById = {
  mac: () => orderScreensById(MAC_SKIP_SCREENS),
  iPhone: () => orderScreensById(IPHONE_SKIP_SCREENS),
  iPad: () => orderScreensById(IPAD_SKIP_SCREENS),
  appleTv: () => orderScreensById(APPLE_TV_SKIP_SCREENS),
};
