import { Flex, Text } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import EmptyIcon from '../../../../assets/bp-filter-empty-state.svg';

const SearchBlueprintsEmptyState = () => (
  <Flex
    flow="column"
    alignItems="center"
    justifyContent="center"
    gap="md"
    css={{
      p: '32px 24px',
      gap: '10px',
    }}
  >
    <img src={EmptyIcon} alt={i18n.t('Empty Icon')} />
    <Text
      css={{
        color: '$neutral60',
        fontSize: '$2',
        fontWeight: '$medium',
      }}
    >
      {i18n.t('No results found')}
    </Text>
    <Text css={{ color: '$neutral60', textAlign: 'center' }}>
      {i18n.t(
        "We couldn't find a match. Try changing the search query with different keywords.",
      )}
    </Text>
  </Flex>
);

export default SearchBlueprintsEmptyState;
