import { getEnforcementOptions, getRestartOptions } from './constants';

export default () => ({
  id: null,
  name: '',
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  isActive: true,
  enforcement: {
    enforcementOption: getEnforcementOptions().ENFORCE_IMMEDIATELY, // Not used in the API
    DeferForceAtUserLoginMaxBypassAttempts: 0, // value for "User deferral" dropdown
    DeferDontAskAtUserLogout: true, // value for "Prompt for restart if FileVault is not enabled" checkbox
    ForceEnableInSetupAssistant: false, // FF: LIT-WWDC23-filevault-ade
    restartOption: getRestartOptions().FORCE, // Not used in the API
    ForceRestartAfter: 30,
    RemindRestartEvery: 24,
  },
  recoveryKeys: {
    ShowRecoveryKey: true,
    EscrowRecoveryKey: true,
    autoRotateKeys: true,
    AutomaticallyRotateKeysAfter: 90,
  },
  supportsRules: true,
});
