import { api } from 'app/api/base';

class SelfServiceService {
  constructor() {
    this.configPrefix = 'company/self-service/config';
  }

  get(data, params) {
    const client = api(this.configPrefix);
    return client.get(data, params);
  }

  patch(data, params) {
    const client = api(this.configPrefix);
    return client.patch(data, params);
  }
}

const selfServiceService = new SelfServiceService();

export default SelfServiceService;
export { selfServiceService };
