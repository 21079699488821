/* istanbul ignore file */
import { i18n } from 'i18n';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import UniversalAlert from './UniversalAlert';

const RenewalBanner = ({ renewalBannerData }) => {
  const renewalEndDate = moment(renewalBannerData.renewal_end_date).format(
    'MM/DD/YYYY',
  );
  const bannerText = i18n.t(
    `Your renewal date is coming up on {renewalEndDate}.`,
    { renewalEndDate },
  );
  const button = renewalBannerData.renewal_quote_link
    ? {
        text: i18n.t('View Quote'),
        onClick: () => {
          window.open(renewalBannerData.renewal_quote_link);
        },
      }
    : null;

  return (
    <UniversalAlert type="warning" text={bannerText} button={button} closable />
  );
};

RenewalBanner.propTypes = {
  renewalBannerData: PropTypes.shape({
    is_enabled: PropTypes.bool,
    renewal_quote_link: PropTypes.string,
    renewal_end_date: PropTypes.string,
  }),
};

RenewalBanner.defaultProps = {
  renewalBannerData: {
    is_enabled: false,
    renewal_quote_link: null,
    renewal_end_date: null,
  },
};

export default RenewalBanner;
