import React, { createContext, useState, useEffect } from 'react';

const KandjiSetupCtx = createContext({});

const KandjiSetupProvider = (props) => {
  const { children, itemId, itemType, previousPage } = props;
  const isAdding = !itemId;
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isEditingPage, setIsEditingPage] = useState(isAdding);
  const [isSaving, setIsSaving] = useState(false);
  const [isDisabled, setIsDisabled] = useState(!isEditingPage || isSaving);
  const [isAnyInputsInvalid, setIsAnyInputsInvalid] = useState(false);
  const [globalValidations, setGlobalValidations] = useState();

  /* determines when the page should be in disabled state */
  useEffect(() => {
    setIsDisabled(isSaving || !isEditingPage);
  }, [isSaving, isEditingPage]);

  /* update global is any inputs invalid when we get validations */
  useEffect(() => {
    if (globalValidations) {
      const isAnyInvalid = Object.values(globalValidations).some(Boolean);
      setIsAnyInputsInvalid(isAnyInvalid);
    }
  }, [globalValidations]);

  return (
    <KandjiSetupCtx.Provider
      value={{
        isDarkMode,
        setIsDarkMode,
        itemId,
        itemType,
        isEditingPage,
        setIsEditingPage,
        isAdding,
        isSaving,
        setIsSaving,
        isDisabled,
        isAnyInputsInvalid,
        setGlobalValidations,
        previousPage,
      }}
    >
      {children}
    </KandjiSetupCtx.Provider>
  );
};

export default KandjiSetupCtx;

export { KandjiSetupCtx, KandjiSetupProvider };
