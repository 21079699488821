import { AccountContext } from 'contexts/account';
import { useContext, useEffect } from 'react';
import initFreshChat from '../config/freshchat';

const FreshChat = () => {
  // only run on first render
  const { userFirstName, userEmail, userLastName, accountReady } =
    useContext(AccountContext);
  useEffect(() => {
    if (accountReady) {
      initFreshChat(userEmail, userFirstName, userLastName);
    }
  }, [accountReady]);
  return null;
};

export default FreshChat;
