import classSet from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Const from '../Const';
import Util from '../util';
import PageButton from './PageButton';
import SizePerPageDropDown from './SizePerPageDropDown';

class PaginationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
    };
  }

  UNSAFE_componentWillReceiveProps() {
    const { keepSizePerPageState } = this.props;
    if (!keepSizePerPageState) {
      this.closeDropDown();
    }
  }

  changePage = (page) => {
    const {
      pageStartIndex,
      prePage,
      currPage,
      nextPage,
      lastPage,
      firstPage,
      sizePerPage,
      keepSizePerPageState,
    } = this.props;

    if (page === prePage) {
      page = currPage - 1 < pageStartIndex ? pageStartIndex : currPage - 1;
    } else if (page === nextPage) {
      page = currPage + 1 > this.lastPage ? this.lastPage : currPage + 1;
    } else if (page === lastPage) {
      page = this.lastPage;
    } else if (page === firstPage) {
      page = pageStartIndex;
    } else {
      page = parseInt(page, 10);
    }

    if (keepSizePerPageState) {
      this.closeDropDown();
    }

    if (page !== currPage) {
      this.props.changePage(page, sizePerPage);
    }
  };

  changeSizePerPage = (pageNum) => {
    console.log('>>> >>>> >>>>', pageNum);

    const selectSize =
      typeof pageNum === 'string' ? parseInt(pageNum, 10) : pageNum;
    let { currPage } = this.props;
    if (selectSize !== this.props.sizePerPage) {
      this.totalPages = Math.ceil(this.props.dataSize / selectSize);
      this.lastPage = this.props.pageStartIndex + this.totalPages - 1;
      if (currPage > this.lastPage) {
        currPage = this.lastPage;
      }

      this.props.changePage(currPage, selectSize);
      if (this.props.onSizePerPageList) {
        this.props.onSizePerPageList(selectSize);
      }
    }

    this.closeDropDown();
  };

  toggleDropDown = () => {
    this.setState(() => ({
      open: !this.state.open,
    }));
  };

  closeDropDown = () => {
    this.setState(() => ({
      open: false,
    }));
  };

  render() {
    const {
      currPage,
      dataSize,
      sizePerPage,
      sizePerPageList,
      paginationShowsTotal,
      pageStartIndex,
      paginationPanel,
      hidePageListOnlyOnePage,
      resultsString,
      showPaginationOnTop,
    } = this.props;
    this.totalPages = Math.ceil(dataSize / sizePerPage);
    // let isBlueprintsListPage = resultsString === 'Blueprints' && dataSize <= 25;
    this.lastPage = this.props.pageStartIndex + this.totalPages - 1;
    const pageBtns = !showPaginationOnTop
      ? this.makePage(Util.isFunction(paginationPanel))
      : undefined;
    const dropdown = this.makeDropDown(showPaginationOnTop);

    const offset = Math.abs(Const.PAGE_START_INDEX - pageStartIndex);
    let start = (currPage - pageStartIndex) * sizePerPage;
    start = dataSize === 0 ? 0 : start + 1;
    let to = Math.min(sizePerPage * (currPage + offset) - 1, dataSize);
    if (to >= dataSize) {
      to -= 1;
    }
    let total = paginationShowsTotal ? (
      <span>
        Showing rows {start} to&nbsp;{to + 1} of&nbsp;{dataSize}
      </span>
    ) : null;

    if (Util.isFunction(paginationShowsTotal)) {
      total = paginationShowsTotal(start, to + 1, dataSize);
    }

    const content =
      paginationPanel &&
      paginationPanel({
        currPage,
        sizePerPage,
        sizePerPageList,
        pageStartIndex,
        totalPages: this.totalPages,
        changePage: this.changePage,
        toggleDropDown: this.toggleDropDown,
        changeSizePerPage: this.changeSizePerPage,
        components: {
          totalText: total,
          sizePerPageDropdown: dropdown,
          pageList: pageBtns,
        },
        resultsString,
        dataSize,
      });

    const hidePageList =
      hidePageListOnlyOnePage && this.totalPages === 1 ? 'none' : 'block';
    return (
      <div className="row" style={{ marginTop: 15, userSelect: 'none' }}>
        {content || [
          <div
            key="paging-left"
            className="col-md-6 col-xs-6 col-sm-6 col-lg-6"
          >
            {total}
            {sizePerPageList.length > 1 ? dropdown : null}
          </div>,
          <div
            key="paging-right"
            style={{ display: hidePageList }}
            className="col-md-6 col-xs-6 col-sm-6 col-lg-6"
          >
            {pageBtns}
          </div>,
        ]}
      </div>
    );
  }

  makeDropDown() {
    let dropdown;
    let dropdownProps;
    let sizePerPageText = '';
    const {
      sizePerPageDropDown,
      hideSizePerPage,
      sizePerPage,
      sizePerPageList,
      resultsString,
      showPaginationOnTop,
    } = this.props;
    if (sizePerPageDropDown) {
      dropdown = sizePerPageDropDown({
        resultsString,
        dataSize: this.props.dataSize,
        currPage: this.props.currPage,
        open: this.state.open,
        hideSizePerPage,
        currSizePerPage: String(sizePerPage),
        sizePerPageList,
        showPaginationOnTop,
        toggleDropDown: this.toggleDropDown,
        changeSizePerPage: this.changeSizePerPage,
        onBlur: this.closeDropDown,
      });
      if (dropdown.type.name === SizePerPageDropDown.name) {
        dropdownProps = dropdown.props;
      } else {
        return dropdown;
      }
    }

    if (dropdownProps || !dropdown) {
      const isBootstrap4 = Util.isBootstrap4(this.props.version);
      const sizePerPageOptions = sizePerPageList.map((_sizePerPage) => {
        const pageText = _sizePerPage.text || _sizePerPage;
        const pageNum = _sizePerPage.value || _sizePerPage;
        if (sizePerPage === pageNum) {
          sizePerPageText = pageText;
        }
        if (isBootstrap4) {
          return (
            <a
              href="#"
              tabIndex="-1"
              key={pageText}
              className="dropdown-item"
              onMouseDown={(e) => {
                e.preventDefault();
                this.changeSizePerPage(pageNum);
              }}
            >
              {pageText}
            </a>
          );
        }
        return (
          <li key={pageText} role="presentation" className="dropdown-item">
            <a
              role="menuitem"
              tabIndex="-1"
              href="#"
              data-page={pageNum}
              onMouseDown={(e) => {
                e.preventDefault();
                this.changeSizePerPage(pageNum);
              }}
            >
              {pageText}
            </a>
          </li>
        );
      });
      dropdown = (
        <SizePerPageDropDown
          open={this.state.open}
          hidden={hideSizePerPage}
          currSizePerPage={String(sizePerPageText)}
          options={sizePerPageOptions}
          onClick={this.toggleDropDown}
          onBlur={this.closeDropDown}
          isBootstrap4={isBootstrap4}
          {...dropdownProps}
        />
      );
    }
    return dropdown;
  }

  makePage(isCustomPagingPanel = false) {
    const pages = this.getPages();
    let pageBtns;
    if (!pages.length) {
      pages.push('previous', 'current', 'next');
      pageBtns = pages.map(
        (page, index) => (
          <PageButton
            key={page}
            title={`${page} page`}
            isNextButton={page === 'next'}
            isPreButton={page === 'previous'}
            changePage={(f) => f}
            active={page === 'current'}
            disable
            pageNumber={index}
          >
            {index}
          </PageButton>
        ),
        this,
      );
      const classname = classSet(
        isCustomPagingPanel ? null : 'react-bootstrap-table-page-btns-ul',
        'pagination',
      );
      return (
        <div className="pagination-block extended">
          <span className="results-label-left">
            {`Page ${
              this.getLastPage() ? this.props.currPage : 0
            } of ${this.getLastPage()}`}
          </span>
          <ul className={classname}>{pageBtns}</ul>
        </div>
      );
    }

    const isStart = (page, { currPage, pageStartIndex, firstPage, prePage }) =>
      currPage === pageStartIndex && (page === firstPage || page === prePage);
    const isEnd = (page, { currPage, nextPage, lastPage }) =>
      currPage === this.lastPage && (page === nextPage || page === lastPage);
    pageBtns = pages
      .filter(function (page) {
        if (this.props.alwaysShowAllBtns) {
          return true;
        }
        return !(isStart(page, this.props) || isEnd(page, this.props));
      }, this)
      .map(function (page, index) {
        const isActive = page === this.props.currPage;
        const isDisabled = !!(
          isStart(page, this.props) || isEnd(page, this.props)
        );
        let title = `${page}`;
        let pageNumber = page;

        if (page === this.props.nextPage) {
          title = this.props.nextPageTitle;
          pageNumber = this.props.currPage + 1;
        } else if (page === this.props.prePage) {
          title = this.props.prePageTitle;
          pageNumber = this.props.currPage - 1;
        } else if (page === this.props.firstPage) {
          title = this.props.firstPageTitle;
          pageNumber = this.props.pageStartIndex;
        } else if (page === this.props.lastPage) {
          title = this.props.lastPageTitle;
          pageNumber = this.getLastPage();
        }
        return (
          <PageButton
            key={index}
            title={title}
            isNextButton={page === this.props.nextPage}
            isPreButton={page === this.props.prePage}
            isFirstButton={page === this.props.firstPage}
            isLastButton={page === this.props.lastPage}
            changePage={this.changePage}
            active={isActive}
            disable={isDisabled}
            pageNumber={pageNumber}
          >
            {page}
          </PageButton>
        );
      }, this);
    const classname = classSet(
      isCustomPagingPanel ? null : 'react-bootstrap-table-page-btns-ul',
      'pagination',
    );
    return (
      <div className="pagination-block extended">
        <span className="results-label-left">
          {`Page ${
            this.getLastPage() ? this.props.currPage : 0
          } of ${this.getLastPage()}`}
        </span>
        <ul className={classname}>{pageBtns}</ul>
      </div>
    );
  }

  getLastPage() {
    return this.lastPage;
  }

  getPages() {
    let pages;
    let endPage = this.totalPages;
    if (endPage <= 0) {
      return [];
    }
    let startPage = Math.max(
      this.props.currPage - Math.floor(this.props.paginationSize / 2),
      this.props.pageStartIndex,
    );
    endPage = startPage + this.props.paginationSize - 1;

    if (endPage > this.lastPage) {
      endPage = this.lastPage;
      startPage = endPage - this.props.paginationSize + 1;
    }

    if (
      startPage !== this.props.pageStartIndex &&
      this.totalPages > this.props.paginationSize &&
      this.props.withFirstAndLast
    ) {
      pages = [this.props.firstPage, this.props.prePage];
    } else if (this.totalPages > 5) {
      pages = [this.props.firstPage, this.props.prePage];
    } else if (this.totalPages > 0) {
      pages = [this.props.prePage];
    } else {
      pages = [];
    }

    for (let i = startPage; i <= endPage; i++) {
      if (i >= this.props.pageStartIndex) {
        pages.push(i);
      }
    }

    if (this.totalPages > 0) {
      pages.push(this.props.nextPage);
      if (this.totalPages > 5) {
        pages.push(this.props.lastPage);
      }
    }

    return pages;
  }
}

PaginationList.propTypes = {
  currPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sizePerPage: PropTypes.number,
  dataSize: PropTypes.number,
  changePage: PropTypes.func,
  sizePerPageList: PropTypes.array,
  paginationShowsTotal: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  paginationSize: PropTypes.number,
  onSizePerPageList: PropTypes.func,
  prePage: PropTypes.any,
  nextPage: PropTypes.any,
  firstPage: PropTypes.any,
  lastPage: PropTypes.any,
  pageStartIndex: PropTypes.number,
  hideSizePerPage: PropTypes.bool,
  alwaysShowAllBtns: PropTypes.bool,
  withFirstAndLast: PropTypes.bool,
  sizePerPageDropDown: PropTypes.func,
  paginationPanel: PropTypes.func,
  prePageTitle: PropTypes.string,
  nextPageTitle: PropTypes.string,
  firstPageTitle: PropTypes.string,
  lastPageTitle: PropTypes.string,
  hidePageListOnlyOnePage: PropTypes.bool,
  keepSizePerPageState: PropTypes.bool,
  showPaginationOnTop: PropTypes.bool,
};

PaginationList.defaultProps = {
  sizePerPage: Const.SIZE_PER_PAGE,
  pageStartIndex: Const.PAGE_START_INDEX,
};

export default PaginationList;
