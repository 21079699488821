import { InterfaceContext } from 'contexts/interface';
import React, { createContext, useContext, useState } from 'react';

const ComputersDEPContext = createContext({
  counts: 0,
  setCounts: () => {},
  bannerTopOffset: 0,
});

const ComputersDEPProvider = ({ children, value }) => {
  const { bannerTopOffset } = useContext(InterfaceContext);
  const [counts, setCounts] = useState(0);
  return (
    <ComputersDEPContext.Provider
      value={{
        counts,
        setCounts,
        bannerTopOffset,
        ...value,
      }}
    >
      {children}
    </ComputersDEPContext.Provider>
  );
};

export { ComputersDEPContext, ComputersDEPProvider };
