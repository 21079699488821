import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';

import { Button, Flex, TextInput } from '@kandji-inc/bumblebee';

import { Setting } from 'features/library-items/template';

import { i18n } from 'src/i18n';
import KernelExtensionService from '../../service/kernel-extension-service';
import BundleIdsTable from './bundle-ids-table';

const TeamIds = ({ update, isDisabled, settings, validationDep }) => {
  const onDeleteBundleId = useCallback(
    (teamId) =>
      ({ id }) => {
        update('teams', (teams) =>
          // Find and remove the `AllowedKernelExtensions` item in the correct Team ID:
          teams.map((team) => {
            if (team.id === teamId) {
              return {
                ...team,
                AllowedKernelExtensions: team.AllowedKernelExtensions.filter(
                  (bundle) => bundle.id !== id,
                ),
              };
            }

            return team;
          }),
        );
      },
    [update],
  );

  const onUpdateBundleId = useCallback(
    (teamId) => (property, bundleId, value, isInvalid) => {
      update('teams', (teams) =>
        teams.map((team) => {
          if (team.id === teamId) {
            return {
              ...team,
              AllowedKernelExtensions: team.AllowedKernelExtensions.map(
                (bundle) => {
                  if (bundle.id === bundleId) {
                    return {
                      ...bundle,
                      [property]: value,
                      isInvalid: Boolean(isInvalid),
                    };
                  }

                  return bundle;
                },
              ),
              isInvalid: Boolean(isInvalid),
            };
          }

          return team;
        }),
      );
    },
    [],
  );

  const onAddBundleId = useCallback(
    (teamId) => () => {
      update('teams', (teams) =>
        teams.map((team) => {
          if (team.id === teamId) {
            return {
              ...team,
              AllowedKernelExtensions: [
                ...team.AllowedKernelExtensions,
                KernelExtensionService.generateBundleId(),
              ],
            };
          }

          return team;
        }),
      );
    },
    [],
  );

  const onRemoveTeamId = useCallback((idx) => {
    update('teams', (teams) => {
      teams.splice(idx, 1);
      return teams;
    });
  }, []);

  const onUpdateTeamId = useCallback((property, teamId, value) => {
    update('teams', (teams) =>
      teams.map((team) => {
        if (team.id === teamId) {
          return {
            ...team,
            [property]: value,
          };
        }

        return team;
      }),
    );
  }, []);

  return (
    <>
      {settings.teams.map((team, idx) => (
        <Setting.Card key={team.id}>
          <Setting.Header>
            <h3 className="b-h3">{i18n.t('Team ID')}</h3>
            {!isDisabled && settings.teams.length > 1 && (
              <Button
                theme="error"
                kind="link"
                size="small"
                icon="xmark"
                onClick={() => onRemoveTeamId(idx)}
              >
                {i18n.t('Remove')}
              </Button>
            )}
          </Setting.Header>
          <Setting.Rows>
            <Setting.Row>
              <Setting.Title>
                <p className="b-txt">
                  {i18n.t('Name')}{' '}
                  <span className="b-txt-light">({i18n.t('optional')})</span>
                </p>
              </Setting.Title>
              <Setting.Controls>
                <TextInput
                  disabled={isDisabled}
                  id={`display_name_${team.id}`}
                  isOptional
                  value={team.DisplayName}
                  placeholder="VMware Fusion"
                  onChange={(e) =>
                    onUpdateTeamId('DisplayName', team.id, e.target.value)
                  }
                />
              </Setting.Controls>
            </Setting.Row>
            <Setting.Row>
              <Setting.Title>
                <p className="b-txt">
                  {i18n.t('Team ID')}{' '}
                  <span className="b-txt-light">({i18n.t('optional')})</span>
                </p>
              </Setting.Title>
              <Setting.Controls>
                <TextInput
                  disabled={isDisabled}
                  id={`team_id_${team.id}`}
                  isOptional
                  value={team.AllowedTeamIdentifiers}
                  placeholder="EG7KH642X6"
                  onChange={(e) =>
                    onUpdateTeamId(
                      'AllowedTeamIdentifiers',
                      team.id,
                      e.target.value,
                    )
                  }
                />
              </Setting.Controls>
            </Setting.Row>

            {(team.AllowedKernelExtensions.length > 0 || !isDisabled) && (
              <>
                <div className="mt-5 pb-3">
                  <p className="b-txt">
                    <strong>{i18n.t('Approved Kernel Extensions')} </strong>
                    <span className="b-txt-light">
                      (
                      {i18n.t(
                        'optional - approve only the following Bundle IDs',
                      )}
                      )
                    </span>
                  </p>
                </div>
                <BundleIdsTable
                  bundleIds={team.AllowedKernelExtensions}
                  isDisabled={isDisabled}
                  onDelete={onDeleteBundleId(team.id)}
                  onUpdate={onUpdateBundleId(team.id)}
                  updateTeams={update}
                  onAddRow={onAddBundleId(team.id)}
                  validationDep={validationDep}
                />
              </>
            )}
          </Setting.Rows>
        </Setting.Card>
      ))}

      <Flex justify="end">
        <Button
          disabled={isDisabled}
          icon="plus"
          iconPlacement="right"
          onClick={() =>
            update('teams', [
              ...settings.teams,
              KernelExtensionService.generateTeamId(),
            ])
          }
        >
          {i18n.t('Add Team ID')}
        </Button>
      </Flex>
    </>
  );
};

TeamIds.propTypes = {
  update: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  settings: PropTypes.object.isRequired, // @todo define the shape of `settings`
};

export default memo(TeamIds);
