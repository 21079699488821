import { PropTypes } from 'prop-types';
import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

export const CounterIndicator = (props) => {
  const { id, value, tooltip } = props;

  return (
    <div id={`counter${id}`} className="counter">
      {value}
      {tooltip && id && (
        <UncontrolledTooltip
          placement="top"
          delay={{ show: 400, hide: 0 }}
          innerClassName="custom-helper"
          target={`counter${id}`}
        >
          {tooltip}
        </UncontrolledTooltip>
      )}
    </div>
  );
};

CounterIndicator.propTypes = {
  value: PropTypes.number.isRequired,
  tooltip: PropTypes.string,
};

export default CounterIndicator;
