import {
  Banner,
  TextInput,
  onEmptyBlurValidator,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';

import { Setting } from 'features/library-items/template';
import { i18n } from 'src/i18n';

const fieldsToValidate = ['password', 'repeatPassword'];

const Passwords = (props) => {
  const { setting, isDisabled, update, validationDep } = props;

  const { refs, onInvalidate } = useInputsValidators(fieldsToValidate, update);
  useEffect(() => {
    onInvalidate('repeatPassword')(setting.password !== setting.repeatPassword);
  }, [setting.password]);
  useRemoveValidationOnUnmount(fieldsToValidate, update);
  const trigger = ['onBlur', validationDep];

  return (
    <Setting.SecondaryControls>
      <Setting.SecondaryControlsRow>
        <div ref={refs.password}>
          <p className="b-txt b-mb-tiny">{i18n.t('Password')}</p>
          <TextInput
            disabled={isDisabled}
            value={setting.password}
            onChange={(e) => update('password', e.target.value)}
            compact
            validator={(v) => [onEmptyBlurValidator(v, { trigger })]}
            onInvalidate={onInvalidate('password')}
            type="password"
          />
        </div>
        {setting.passwordUpdatedAt && (
          <div style={{ padding: 0 }}>
            <Banner theme="info">
              <p>
                {i18n.t('Set on')}{' '}
                {DateTime.fromSeconds(setting.passwordUpdatedAt).toFormat(
                  'LLLL dd, yyyy',
                )}
              </p>
            </Banner>
          </div>
        )}
      </Setting.SecondaryControlsRow>
      {!isDisabled && (
        <Setting.SecondaryControlsRow>
          <div ref={refs.repeatPassword}>
            <p className="b-txt b-mb-tiny">{i18n.t('Verify Password')}</p>
            <TextInput
              disabled={isDisabled}
              value={setting.repeatPassword}
              onChange={(e) => update('repeatPassword', e.target.value)}
              compact
              validator={(v) => [
                onEmptyBlurValidator(v, { trigger }),
                {
                  message: i18n.t('Passwords do not match.'),
                  invalid: () => setting.password !== v,
                  trigger,
                },
              ]}
              onInvalidate={onInvalidate('repeatPassword')}
              type="password"
            />
          </div>
        </Setting.SecondaryControlsRow>
      )}
    </Setting.SecondaryControls>
  );
};

export default Passwords;
