import React from 'react';
import { Map, Marker } from 'react-mapkit';

import useLostMode from 'src/hooks/useLostMode';
import styled from 'styled-components';
import LocationPoint from '../assets/LocationPoint';

const StyledLostModeMap = styled.div`
  &.enabled-lost-mode__map {
    border-radius: 8px;
    overflow: hidden;
    width: 48%;
    height: 18vw;
    min-height: 268px;
    border: 1px solid #e1e6f4;

    .enabled-lost-mode__pending-map {
      display: flex;
      height: 100%;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #eef0f6;

      .enabled-lost-mode__icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        background: #fff;
        margin-bottom: 24px;
      }
      .enabled-lost-mode__pending-map-text {
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
`;

function LostModeMap(props) {
  const { computer } = props;
  const { longitude, latitude, isNoCoordinates = true } = useLostMode(computer);

  return (
    <StyledLostModeMap className="enabled-lost-mode__map">
      {isNoCoordinates ? (
        <div className="enabled-lost-mode__pending-map">
          <div className="enabled-lost-mode__icon-container">
            <LocationPoint data-testid="location-point" />
          </div>
          <h3 className="enabled-lost-mode__pending-map-text">
            Location Pending...
          </h3>
        </div>
      ) : (
        <Map
          key={`${latitude}-${longitude}`}
          region={{
            latitude,
            longitude,
            latitudeSpan: 0.016,
            longitudeSpan: 0.016,
          }}
          center={[latitude, longitude]}
        >
          <Marker latitude={latitude} longitude={longitude} />
        </Map>
      )}
    </StyledLostModeMap>
  );
}

export default LostModeMap;
