import { string } from 'prop-types';
import React from 'react';
import './pin-input.css';

function PinInput({ value }) {
  return (
    <div className="pin-input">
      {value.split('').map((x) => (
        <h3 className="b-h3 pin-number" key={x}>
          {x}
        </h3>
      ))}
    </div>
  );
}

PinInput.propTypes = {
  value: string,
};

PinInput.defaultProps = {
  value: null,
};

export default PinInput;
