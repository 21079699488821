// istanbul ignore file -- need to figure out how to test Radix Popover
import {
  Button,
  Checkbox,
  Chip,
  Flex,
  Icon,
  Loader,
  DropdownMenuPrimitives as Menu,
  Text,
  TextField,
  styled,
} from '@kandji-inc/nectar-ui';
import * as Popover from '@radix-ui/react-popover'; // TODO: update this in Nectar
import debounce from 'lodash/debounce';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useBlueprints } from 'src/features/visibility/prism/hooks/use-blueprints-query';
import { useSearchComputersQuery } from 'src/features/visibility/pulse/hooks/use-search-computers-query';
import { NoResultsFoundIcon } from '../../../components/NoResultsFoundIcon';
import { type DeviceMetadata, DeviceSearchList } from './DeviceSearchList';
import { LabelText } from './LabelText';

const LabelDescription = styled(Text, {
  color: '$neutral70',
  fontSize: '$1',
  fontWeight: '$regular',
  lineHeight: '$1',
  flexShrink: 0,
});

const CategoryLabel = styled(Text, {
  fontSize: '$1',
  color: '$neutral60',
});

const MenuItem = styled(Flex, {
  padding: '6px 12px',
  gap: '$1',
  alignItems: 'center',
});

const VScrollArea = styled(Flex, {
  flexDirection: 'column',
  maxHeight: 400,
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '$1',
  },
  '&:hover': {
    '&::-webkit-scrollbar-track': {
      background: 'rgba(243, 247, 250)',
      borderRadius: '$rounded',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(80, 94, 113, 0.24)',
      borderRadius: '$rounded',
      height: '50px',
    },
  },
});

const PopoverContent = styled(Popover.Content, {
  marginTop: '$2',
  width: 'var(--radix-popover-trigger-width)',
  height: 'min-content',
  padding: '$1 0px',
  background: '$neutral0',
  filter: 'drop-shadow($elevation2)',
  borderRadius: '$rounded',
  zIndex: 3,
});

interface Device {
  id: string;
  name: string;
}

const DeviceTargets = ({
  selectedDevices,
  selectedBlueprints,
  setSelectedDevices,
  setSelectedBlueprints,
}: {
  selectedDevices: Array<{ id: string; name: string }>;
  selectedBlueprints: string[];
  setSelectedDevices: (value: Array<{ id: string; name: string }>) => void;
  setSelectedBlueprints: (value: string[]) => void;
}) => {
  const [deviceName, setDeviceName] = useState('');
  const [searchOpen, setSearchOpen] = useState(false);
  const deviceNameInputRef = useRef<HTMLInputElement>(null);
  const focusRef = useRef<HTMLElement>(null);
  const { data: blueprints } = useBlueprints();

  const { data: devices, isPending: devicesLoading } =
    useSearchComputersQuery(deviceName);

  const handleSearchChange = debounce(
    (searchTerm: string) => setDeviceName(searchTerm),
    500,
  );

  useEffect(() => {
    if (focusRef.current) {
      focusRef.current.focus();
    }
  }, [searchOpen, deviceName]);

  const devicesMeta: DeviceMetadata[] = useMemo<DeviceMetadata[]>(
    () =>
      (devices?.data?.results || []).map((computer: any) => ({
        deviceId: computer.id,
        serialNumber: computer.serial_number,
        assetTag: computer.asset_tag,
        deviceName: computer.name,
        deviceFamily: computer.device_family,
        model: computer.model,
        modelId: computer.info?.['Model Identifier'],
        userEmail: computer?.user?.email,
        userName: computer?.user?.name,
        selected: selectedDevices.some((d) => d.id === computer.id),
      })),
    [devices?.data?.results, selectedDevices],
  );

  return (
    <>
      <Flex flow="column" p4 gap="xs">
        <LabelText>Add device targets</LabelText>
        <LabelDescription>
          Choose to target and return results for specific devices or target a
          group of devices by specifying a Blueprint and device family segment.
        </LabelDescription>
        <Flex gap="sm" alignItems="start">
          <Flex flow="column" flex={1}>
            <Popover.Root open={searchOpen}>
              <Popover.Anchor>
                <TextField
                  iconLeft
                  ref={deviceNameInputRef}
                  showClearButton={deviceNameInputRef.current?.value.length > 0}
                  onClear={
                    // istanbul ignore next
                    () => {
                      setDeviceName('');
                      setSearchOpen(true);
                      if (deviceNameInputRef.current) {
                        deviceNameInputRef.current.value = '';
                      }
                    }
                  }
                  icon="magnifying-glass"
                  type="input"
                  placeholder="Search by device name, serial number, asset tag, user name, or e-mail"
                  onChange={(e) => handleSearchChange(e.target.value)}
                  onFocus={() => setSearchOpen(true)}
                />
              </Popover.Anchor>
              <PopoverContent
                onInteractOutside={(e) => {
                  e.preventDefault();
                  setSearchOpen(e.target === deviceNameInputRef.current);
                  focusRef.current = e.target as HTMLElement;
                  if (e.target !== deviceNameInputRef.current) {
                    setDeviceName('');
                  }
                }}
                onOpenAutoFocus={(e) => {
                  e.preventDefault();
                  deviceNameInputRef.current?.focus();
                }}
              >
                {deviceName.length === 0 && (
                  <Flex justifyContent="center" css={{ margin: '$6 $7' }}>
                    <Text css={{ color: '$neutral70', fontSize: '$1' }}>
                      Start typing to return results.
                    </Text>
                  </Flex>
                )}
                {deviceName.length > 0 && devicesLoading && (
                  <Flex
                    gap="sm"
                    flow="column"
                    justifyContent="center"
                    alignItems="center"
                    css={{ margin: '18px $7' }}
                  >
                    <Loader size="md" />
                    <Text css={{ color: '$neutral70', fontSize: '$1' }}>
                      Loading device matches now...
                    </Text>
                  </Flex>
                )}
                {deviceName.length > 0 &&
                  !devicesLoading &&
                  devices?.data?.results?.length === 0 && (
                    <Flex
                      gap="sm"
                      flow="column"
                      justifyContent="center"
                      alignItems="center"
                      css={{ margin: '$6' }}
                    >
                      <NoResultsFoundIcon />
                      <Flex flow="column" css={{ gap: '2px' }}>
                        <Text css={{ fontSize: '$1', fontWeight: '$medium' }}>
                          No devices match your search query
                        </Text>
                        <Text css={{ color: '$neutral70', fontSize: '$1' }}>
                          Try searching with different keywords.
                        </Text>
                      </Flex>
                    </Flex>
                  )}
                <VScrollArea>
                  <DeviceSearchList
                    devices={devicesMeta}
                    searchTerm={deviceName}
                    searchFields={[
                      'deviceName',
                      'model',
                      'serialNumber',
                      'assetTag',
                      'userName',
                      'userEmail',
                    ]}
                    onSelect={(device) => {
                      if (
                        selectedDevices.some((d) => d.id === device.deviceId)
                      ) {
                        setSelectedDevices(
                          selectedDevices.filter(
                            (d) => d.id !== device.deviceId,
                          ),
                        );
                      } else {
                        setSelectedDevices([
                          ...selectedDevices,
                          { id: device.deviceId, name: device.deviceName },
                        ]);
                      }
                    }}
                  />
                </VScrollArea>
              </PopoverContent>
            </Popover.Root>
          </Flex>
          <Flex gap="sm" alignItems="center">
            <Text>or</Text>
            <Menu.Root>
              <Menu.Trigger asChild>
                <Button css={{ width: 300 }}>
                  Add Device family + Blueprint target
                  <Icon name="fa-angle-down-small" />
                </Button>
              </Menu.Trigger>
              <Menu.Portal>
                <Menu.Content mt2 pt2 pb2 css={{ width: 300 }}>
                  <Flex flow="column">
                    <MenuItem>
                      <CategoryLabel>Device family</CategoryLabel>
                    </MenuItem>
                    <MenuItem>
                      <Checkbox checked disabled />
                      <Icon size={16} name="sf-desktop-computer" />
                      <Text>Mac</Text>
                    </MenuItem>
                    <Menu.Divider />
                    <MenuItem>
                      <CategoryLabel>Blueprint</CategoryLabel>
                    </MenuItem>
                    <VScrollArea>
                      {blueprints?.map((blueprint) => (
                        <Menu.Item
                          key={blueprint.id}
                          onSelect={(e) => {
                            e.preventDefault();
                            if (selectedBlueprints.includes(blueprint.id)) {
                              setSelectedBlueprints(
                                selectedBlueprints.filter(
                                  (id) => id !== blueprint.id,
                                ),
                              );
                            } else {
                              setSelectedBlueprints([
                                blueprint.id,
                                ...selectedBlueprints,
                              ]);
                            }
                          }}
                        >
                          <Checkbox
                            checked={selectedBlueprints.includes(blueprint.id)}
                          />
                          <Text>{blueprint.name}</Text>
                        </Menu.Item>
                      ))}
                    </VScrollArea>
                  </Flex>
                </Menu.Content>
              </Menu.Portal>
            </Menu.Root>
          </Flex>
        </Flex>
      </Flex>
      {(selectedDevices.length > 0 || selectedBlueprints.length > 0) && (
        <Flex flow="column" p4 gap="sm">
          <LabelText>Run query on</LabelText>
          {selectedDevices.length > 0 && (
            <Flex alignItems="center" gap="sm" css={{ flexWrap: 'wrap' }}>
              <LabelDescription>Specific devices</LabelDescription>
              {selectedDevices.map((device) => (
                <Chip
                  key={device.id}
                  dismissible={{
                    onIconClick: () => {
                      setSelectedDevices(
                        selectedDevices.filter((d) => d.name !== device.name),
                      );
                    },
                  }}
                  label={device.name}
                  size="compact"
                />
              ))}
              {selectedBlueprints.length > 0 && (
                <LabelDescription>and</LabelDescription>
              )}
            </Flex>
          )}
          {selectedBlueprints.length > 0 && (
            <Flex alignItems="center" gap="sm" css={{ flexWrap: 'wrap' }}>
              <LabelDescription>Devices that are</LabelDescription>
              <Chip
                iconLeft={{ icon: 'sf-desktop-computer' }}
                label="Mac"
                size="compact"
              />
              <LabelDescription>and assigned to</LabelDescription>
              {selectedBlueprints.map((id) => {
                const blueprint = blueprints?.find((b) => b.id === id);
                return (
                  <Chip
                    key={id}
                    dismissible={{
                      onIconClick: () => {
                        setSelectedBlueprints(
                          selectedBlueprints.filter((i) => i !== id),
                        );
                      },
                    }}
                    label={blueprint?.name}
                    size="compact"
                  />
                );
              })}
            </Flex>
          )}
          <Flex alignItems="center" gap="sm">
            {/* TODO: Re-enable when we figure out how correctly count blueprint devices */}
            {/* <LabelText>
              {deviceNames.length} device{deviceNames.length !== 1 ? 's' : ''}
            </LabelText>
            <div
              style={{
                borderRight: '1px solid #D7E1ED',
                height: 20,
              }}
            /> */}
            <Button
              variant="link"
              onClick={() => {
                setSelectedDevices([]);
                setSelectedBlueprints([]);
              }}
            >
              Clear
            </Button>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export { DeviceTargets };
export type { Device };
