/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query';
import { pulseService } from '../data-service/pulse-service';
import type { PulseExportResponseSchema } from '../types/pulse.types';

type UsePulseExportQueryParams = {
  exportId: string;
};

export const usePulseExportQuery = ({ exportId }: UsePulseExportQueryParams) =>
  useQuery<PulseExportResponseSchema>({
    queryKey: ['exportPulseData', exportId],

    queryFn: () => pulseService.getExportStatus(exportId),
    enabled: !!exportId,
    refetchInterval: ({ state }) => {
      const status = state?.data?.status;
      const signedUrl = state?.data?.signed_url;
      // istanbul ignore next
      if (status === 'pending' || (!signedUrl && status !== 'failed')) {
        return 1000;
      }
      return false;
    },
  });
