/* istanbul ignore file */

import { api } from 'app/api/base';
import { URL_TIMELINE } from '../api/urls';

export const queryTimeline = (
  activityTabType,
  computerId,
  blueprintId,
  libraryItemId,
  next,
  requestQuery,
) => {
  const basicUrl = `${URL_TIMELINE}`;
  let url;
  let preparedQuery = requestQuery;
  if (computerId) {
    preparedQuery = `obj_type=computer&obj_id=${computerId}&${requestQuery}`;
  } else if (blueprintId) {
    preparedQuery = `obj_type=blueprint&obj_id=${blueprintId}&${requestQuery}`;
  } else if (libraryItemId) {
    preparedQuery = `obj_type=library_item&obj_id=${libraryItemId}&${requestQuery}`;
  } else if (activityTabType === 'adcs') {
    preparedQuery = `obj_type=adcs&${requestQuery}`;
  }

  if (preparedQuery) {
    url = next || `${basicUrl}?${preparedQuery}`;
  } else {
    url = next || basicUrl;
  }
  return () =>
    api(url)
      .get()
      .then((res) => res.data)
      .catch(() => {});
};
