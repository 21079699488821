import { Banner } from '@kandji-inc/bumblebee';
import React from 'react';

type ConfiguredPlatforms = {
  configuredPlatforms: string[];
};

const DeviceBannerText = ({ configuredPlatforms }: ConfiguredPlatforms) => {
  const hasIOS = configuredPlatforms?.includes('IOS');
  const hasMac = configuredPlatforms?.includes('Mac');

  const shouldDisplayBanner = !hasMac || !hasIOS;

  if (shouldDisplayBanner) {
    return (
      <Banner theme="warning" icon="triangle-exclamation">
        <p className="b-txt">
          {`Okta Device Trust is configured for ${hasIOS ? 'iOS' : 'macOS'} devices only. Okta Verify will
        not be deployed to any ${hasIOS ? 'macOS' : 'iOS'} devices that may be included in the assigned
        Blueprints for this Library Item.`}
        </p>
      </Banner>
    );
  }
  return null;
};

export default DeviceBannerText;
