import { Summary } from 'features/library-items/template';
import React from 'react';

const Instance = ({
  canHaveInstanceName,
  instanceName,
  placeholder,
  isDisabled,
  isSubmitted,
  setModel,
}) => (
  <Summary.Instance
    canHaveInstanceName={canHaveInstanceName}
    instanceName={instanceName}
    placeholder={placeholder}
    isDisabled={isDisabled}
    onChange={async (v) => {
      setModel((p) => ({
        ...p,
        instanceName: v.instanceName,
        isInstanceNameValid: v.isValid,
        instanceNameValidationResult: v.validationResult,
      }));
    }}
  />
);

export default React.memo(Instance);
