import PropTypes from 'prop-types';
import React from 'react';
import './override.css';

const Basic = ({
  element: Element,
  section,
  container,
  id,
  className,
  children,
  style,
}) => (
  <Element
    id={id}
    style={style}
    className={`${section} block ${className || 'no-class'} theme-base`}
  >
    <div className="zone">
      <div className={container}>{children}</div>
    </div>
  </Element>
);

Basic.propTypes = {
  element: PropTypes.string,
  section: PropTypes.string,
  container: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
};

Basic.defaultProps = {
  element: 'section',
  section: 'basic',
  container: 'container-fluid',
  id: undefined,
  className: undefined,
  children: undefined,
  style: {},
};

export default Basic;
