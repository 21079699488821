import { isEqual } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

type ColumnVisibility = { [key: string]: boolean };
type ColumnOrder = string[];

interface ColumnState {
  columnVisibility: ColumnVisibility;
  columnOrder: ColumnOrder;
}

interface UseColumnEditorProps {
  currentState: ColumnState;
  defaultState: ColumnState;
}

export const useColumnEditorState = ({
  currentState,
  defaultState,
}: UseColumnEditorProps) => {
  const [columnVisibility, setColumnVisibility] = useState<ColumnVisibility>(
    currentState.columnVisibility,
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrder>(
    currentState.columnOrder,
  );

  const makeVisible = (column: string) => {
    setColumnVisibility((prevState) => ({ ...prevState, [column]: true }));
    setColumnOrder((prevState) => [
      ...prevState.filter((col) => col !== column),
      column,
    ]);
  };

  const makeHidden = (column: string) => {
    setColumnVisibility((prevState) => ({ ...prevState, [column]: false }));
  };

  const reorder = (newOrder: ColumnOrder) => {
    setColumnOrder(newOrder);
  };

  const reset = () => {
    setColumnVisibility(defaultState.columnVisibility);
    setColumnOrder(defaultState.columnOrder);
  };

  const discard = () => {
    setColumnVisibility(currentState.columnVisibility);
    setColumnOrder(currentState.columnOrder);
  };

  const getColumnState = useCallback(
    () => ({
      columnVisibility,
      columnOrder,
    }),
    [columnVisibility, columnOrder],
  );

  const isDirty = useMemo(
    () => !isEqual(getColumnState(), currentState),
    [getColumnState, currentState],
  );
  const isDefault = useMemo(
    () => isEqual(getColumnState(), defaultState),
    [defaultState, getColumnState],
  );

  return {
    makeVisible,
    makeHidden,
    reorder,
    reset,
    getColumnState,
    discard,
    isDirty,
    isDefault,
  };
};
