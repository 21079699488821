import { QueryClientProvider } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { queryClient } from 'src/app/router';
import ModalConductor from '../app/components/ModalConductor';
import FeatureRequest from '../features/feature-request';
import useFetchAccount from '../hooks/useFetchAccount';
import Loader from '../theme/components/atoms/Loader';

import Banner from './region/Banner';
import Content from './region/Content';
import Toolbar from './region/Toolbar';

const MainWithAuthCheck = ({ children, accountError }) => {
  const accountReady = useFetchAccount(accountError);

  return accountReady ? (
    <QueryClientProvider client={queryClient}>
      <Banner />
      <Toolbar />
      <Content>{children}</Content>
      <FeatureRequest />
      <ModalConductor />
    </QueryClientProvider>
  ) : (
    <Loader type="splash" />
  );
};

MainWithAuthCheck.propTypes = {
  children: PropTypes.node.isRequired,
  accountError: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ auth0 }) => ({
  accountError: auth0.error,
});

export default connect(mapStateToProps)(MainWithAuthCheck);
