import { Checkbox } from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';

const SimpleRow = (props) => {
  const {
    label,
    checkboxFiled,
    helper,
    setting,
    update,
    isDisabled,
    children,
    className,
  } = props;

  return (
    <Setting.Row className={className}>
      <Setting.Controls>
        <Checkbox
          defaultChecked
          checked={setting[checkboxFiled]}
          label={label}
          onChange={() => update(checkboxFiled, (p) => !p)}
          isDisabled={isDisabled}
        />
      </Setting.Controls>
      <Setting.Helpers>
        <div className="b-txt-light">{helper}</div>
      </Setting.Helpers>
      {children}
    </Setting.Row>
  );
};

export default SimpleRow;
