import { Select } from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';
import { i18n } from 'src/i18n';

export const PRIMARY_ACCOUNT_TYPES = {
  ADMIN: 'admin',
  STANDARD: 'standard',
  SKIP: 'skip',
};

const AccountTypeRow = ({ setting, update, isDisabled }) => {
  const PRIMARY_ACCOUNT_OPTIONS = [
    {
      value: PRIMARY_ACCOUNT_TYPES.ADMIN,
      label: i18n.t('Admin'),
    },
    {
      value: PRIMARY_ACCOUNT_TYPES.STANDARD,
      label: i18n.t('Standard account'),
    },
    {
      value: PRIMARY_ACCOUNT_TYPES.SKIP,
      label: i18n.t('Skip primary account creation'),
    },
  ];

  return (
    <Setting.Row>
      <div>
        <p className="b-txt">{i18n.t('Primary account type')}</p>
      </div>
      <Setting.Helpers className="b-txt-light">
        {i18n.t(`Specify if the primary user account created on Macs during Setup Assistant
        is an Administrator account (default) or a Standard account. You may also
        choose to skip primary account creation.`)}
      </Setting.Helpers>
      <div>
        <Select
          isDisabled={isDisabled}
          options={PRIMARY_ACCOUNT_OPTIONS}
          placeholder={i18n.t('Select primary account type')}
          compact
          onChange={(v) => update('accountType', v.value)}
          value={PRIMARY_ACCOUNT_OPTIONS.find(
            (option) => option.value === setting.accountType,
          )}
        />
      </div>
    </Setting.Row>
  );
};

export default AccountTypeRow;
