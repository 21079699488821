import { Flex, styled } from '@kandji-inc/nectar-ui';

const DescriptionLabel = styled('div', {
  width: 72,
});

interface InHouseAppDescriptionProps {
  description: string;
  appVersion: string;
  appName: string;
}

export function InHouseAppDescription({
  description,
  appVersion,
  appName,
}: InHouseAppDescriptionProps) {
  return (
    <Flex flow="column" gap="xs">
      <div style={{ marginBottom: 4 }}>{description}</div>
      {appVersion != null && (
        <Flex gap="sm">
          <DescriptionLabel className="b-txt-light">Version:</DescriptionLabel>
          {appVersion}
        </Flex>
      )}
      {appName != null && (
        <Flex gap="sm">
          <DescriptionLabel className="b-txt-light">App name:</DescriptionLabel>
          {appName}
        </Flex>
      )}
    </Flex>
  );
}
