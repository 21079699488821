import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import AwesomeTableRow from './AwesomeTableRow';
import './AwesomeTableRow.styles.scss';

const AwesomeTableRowContainer = ({
  headers,
  row,
  index,
  tableWidth,
  expandComponent,
  computers,
  clickableCellsToExpand,
  isExpandableRow,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <AwesomeTableRow
      headers={headers}
      row={row}
      index={index}
      tableWidth={tableWidth}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      expandComponent={expandComponent}
      computers={computers}
      clickableCellsToExpand={clickableCellsToExpand}
      isExpandableRow={isExpandableRow}
    />
  );
};

AwesomeTableRowContainer.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
  tableWidth: PropTypes.number.isRequired,
  row: PropTypes.shape({}).isRequired,
  index: PropTypes.number.isRequired,
  expandComponent: PropTypes.func.isRequired,
  clickableCellsToExpand: PropTypes.arrayOf(PropTypes.string),
  isExpandableRow: PropTypes.func,
};

AwesomeTableRowContainer.defaultProps = {
  clickableCellsToExpand: null,
  isExpandableRow: null,
};

const mapStateToProps = (state) => ({
  computers: state.data.computers,
});

export default connect(mapStateToProps, null)(AwesomeTableRowContainer);
