import {
  Button,
  DropdownMenuPrimitives as DropdownMenu,
  Flex,
  Heading,
  styled,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import { useState } from 'react';
import { ViewNameInput } from './ViewNameInput';

type PrismViewCreateDropdownProps = {
  isOpen?: boolean;
  viewName: string;
  viewNameError?: string;
  setViewNameError: (viewNameError: string) => void;
  handleViewCreate: (newName) => void;
};

const StyledP = styled('p', {
  color: '$neutral70',
  fontWeight: '$regular',
  fontSize: '$1',
  marginBottom: '0',
});

const PrismViewCreateDropdown = ({
  isOpen,
  viewName,
  viewNameError,
  handleViewCreate,
  setViewNameError,
}: PrismViewCreateDropdownProps) => {
  const [open, setOpen] = useState(!!isOpen);
  const [newName, setNewName] = useState(viewName);

  return (
    <DropdownMenu.Root open={open} modal={false}>
      <DropdownMenu.Trigger asChild>
        <Button
          data-testid="save-view-trigger"
          variant="default"
          compact
          onClick={() => setOpen(!open)}
        >
          {i18n.t('Save view')}
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          onCloseAutoFocus={(e) => {
            e.preventDefault();
          }}
          onInteractOutside={() => {
            setNewName('');
            setViewNameError('');
            setOpen(false);
          }}
          css={{
            width: '356px',
            marginRight: '48px',
            marginTop: '4px',
            zIndex: 991,
          }}
        >
          <Flex flow="column" gap="md" css={{ p: '16px 24px 0 24px' }}>
            <Heading size="4" css={{ fontWeight: '$medium' }}>
              {i18n.t('Save view')}
            </Heading>
            <StyledP>
              {i18n.t(
                'Provide a name and save this view. Saved views include your filters, column order and sort preference.',
              )}
            </StyledP>
            <ViewNameInput
              viewName={newName}
              viewNameError={viewNameError}
              setViewName={setNewName}
              setViewNameError={setViewNameError}
            />
          </Flex>
          <Flex
            gap="sm"
            justifyContent="end"
            alignItems="center"
            css={{ p: '16px 12px' }}
          >
            <Button
              compact
              onClick={() => {
                setNewName('');
                setViewNameError('');
                setOpen(false);
              }}
            >
              {i18n.t('Cancel')}
            </Button>
            <Button
              compact
              onClick={() => handleViewCreate(newName)}
              variant="primary"
            >
              {i18n.t('Save')}
            </Button>
          </Flex>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default PrismViewCreateDropdown;
