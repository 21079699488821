import { createReducer } from 'redux-create-reducer';

import { app } from '../_actions/action-types';

const initialState = {
  appDataReady: false,
  resetPasswordTokenValidated: false,
};

export default createReducer(initialState, {
  '@@router/LOCATION_CHANGE': (state) => state,
  [app.APP_DATA_READY](state, action) {
    return { ...state, appDataReady: action.ready };
  },
  [app.RESET_PASSWORD_TOKEN_VALIDATED](state, action) {
    return {
      ...state,
      resetPasswordTokenValidated: action.payload,
    };
  },
});
