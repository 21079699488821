import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import { getIconName } from '../../components/PrismNav/prismNavUtils';
import {
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  getFilteredDisabled as filterDisabled,
  getCodeSnippetCell,
} from '../column-utils';
import { getScharedColumns } from './getSharedColumns';

export const getSystemExtensionsColumns = ({
  systemExtensionsSchema,
}): {
  columnDefs: any[];
  pinnedColumns: string[];
  alwaysHiddenColumns: string[];
} => {
  const { properties } = systemExtensionsSchema.schema;
  const systemExtensionsColumnHelper =
    createColumnHelper<z.infer<typeof properties>>();

  const columnDefs = [
    systemExtensionsColumnHelper.accessor(
      (row) => row[`${systemExtensionsSchema.uri}.name`],
      {
        id: `${systemExtensionsSchema.uri}.name`,
        meta: {
          displayName: properties.name.title,
          displayIcon: getIconName(systemExtensionsSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    systemExtensionsColumnHelper.accessor(
      (row) => row[`${systemExtensionsSchema.uri}.identifier`],
      {
        id: `${systemExtensionsSchema.uri}.identifier`,
        meta: {
          displayName: properties.identifier.title,
          displayIcon: getIconName(systemExtensionsSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    systemExtensionsColumnHelper.accessor(
      (row) => row[`${systemExtensionsSchema.uri}.short_version`],
      {
        id: `${systemExtensionsSchema.uri}.short_version`,
        meta: {
          displayName: properties.short_version.title,
          displayIcon: getIconName(systemExtensionsSchema.uri),
          filterType: 'version',
          filterIcon: 'hashtag',
          filterDisabled,
        },
      },
    ),

    systemExtensionsColumnHelper.accessor(
      (row) => row[`${systemExtensionsSchema.uri}.is_mdm_managed`],
      {
        id: `${systemExtensionsSchema.uri}.is_mdm_managed`,
        cell: YesNoCell,
        meta: {
          displayName: properties.is_mdm_managed.title,
          displayIcon: getIconName(systemExtensionsSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    systemExtensionsColumnHelper.accessor(
      (row) => row[`${systemExtensionsSchema.uri}.state`],
      {
        id: `${systemExtensionsSchema.uri}.state`,
        meta: {
          displayName: properties.state.title,
          displayIcon: getIconName(systemExtensionsSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    systemExtensionsColumnHelper.accessor(
      (row) => row[`${systemExtensionsSchema.uri}.path`],
      {
        id: `${systemExtensionsSchema.uri}.path`,
        cell: getCodeSnippetCell,
        meta: {
          displayName: properties.path.title,
          displayIcon: getIconName(systemExtensionsSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    systemExtensionsColumnHelper.accessor(
      (row) => row[`${systemExtensionsSchema.uri}.bundle_version`],
      {
        id: `${systemExtensionsSchema.uri}.bundle_version`,
        meta: {
          defaultHidden: true,
          displayName: properties.bundle_version.title,
          displayIcon: getIconName(systemExtensionsSchema.uri),
          filterType: 'version',
          filterIcon: 'hashtag',
          filterDisabled,
        },
      },
    ),

    systemExtensionsColumnHelper.accessor(
      (row) => row[`${systemExtensionsSchema.uri}.categories`],
      {
        id: `${systemExtensionsSchema.uri}.categories`,
        meta: {
          defaultHidden: true,
          displayName: properties.categories.title,
          displayIcon: getIconName(systemExtensionsSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    systemExtensionsColumnHelper.accessor(
      (row) => row[`${systemExtensionsSchema.uri}.bundle_path`],
      {
        id: `${systemExtensionsSchema.uri}.bundle_path`,
        cell: getCodeSnippetCell,
        meta: {
          defaultHidden: true,
          displayName: properties.bundle_path.title,
          displayIcon: getIconName(systemExtensionsSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    systemExtensionsColumnHelper.accessor(
      (row) => row[`${systemExtensionsSchema.uri}.team_id`],
      {
        id: `${systemExtensionsSchema.uri}.team_id`,
        meta: {
          defaultHidden: true,
          displayName: properties.team_id.title,
          displayIcon: getIconName(systemExtensionsSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    ...getScharedColumns(systemExtensionsSchema),
  ];

  return { columnDefs, pinnedColumns: [], alwaysHiddenColumns: [] };
};
