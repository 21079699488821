import { getAllBlueprintOptions } from 'features/library-items/data-service/blueprint/use-blueprint-service';
import { getSelectedDevicesFromRunsOn } from 'src/features/library-items/data-service/library-item/devices';

import authentication from './authentication';
import certificateTrust from './certificate-trust';
import proxy from './proxy';

const transformFromApi = async (apiData) => {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const [data] = apiData.data;

  const result = {
    _data: apiData,
    id: apiData.id,
    name: apiData.name,
    iconSrc: apiData.app_icon,
    isActive: apiData.active,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
    devices: getSelectedDevicesFromRunsOn(apiData),
    rules: apiData.rules,
    authentication: authentication(data),
    proxy: proxy(data),
    trust: certificateTrust(data),
  };

  return {
    ...apiData,
    data: result,
  };
};

export default transformFromApi;
