const initialState = {
  id: null,
  name: '',
  blueprints: [],
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  isActive: true,

  iPhone: {
    active: true,
    lockScreen: null,
    lockScreenUrl: '',
    lockScreenS3Key: '',
    homeScreen: null,
    homeScreenUrl: '',
    homeScreenS3Key: '',
  },
  iPad: {
    active: true,
    lockScreen: null,
    lockScreenUrl: '',
    lockScreenS3Key: '',
    homeScreen: null,
    homeScreenUrl: '',
    homeScreenS3Key: '',
  },
  supportsRules: true,
};

export default initialState;
