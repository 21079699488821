// istanbul ignore file
import * as React from 'react';

export const PrismAIContext = React.createContext({
  onOpenChange: () => {},
  isOpen: false,
  featureEnabled: false,
  chatPanelSize: 0,
  isFullWidth: false,
  toggleWidth: () => {},
});

export const usePrismAIContext = () => React.useContext(PrismAIContext);

export const PrismAIProvider = PrismAIContext.Provider;
