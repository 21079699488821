import { Button } from '@kandji-inc/nectar-ui';

export const CreateButton = ({
  onClick,
  isCreating,
}: {
  onClick: () => void;
  isCreating: boolean;
}) => (
  <Button variant="primary" onClick={onClick} disabled={isCreating}>
    {!isCreating ? 'Create and run' : 'Creating...'}
  </Button>
);
