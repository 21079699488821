import { Icon, setClass } from '@kandji-inc/bumblebee';
import Drawer from 'features/library-items/items/ethernet/components/drawer';
import { useDrawerValidate } from 'features/library-items/items/ethernet/ethernet.hooks';
import DrawerContent from 'features/library-items/items/ethernet/protocols-card/identity-certificate-row/configure-adcs/drawer-content';
import EthernetService from 'features/library-items/items/ethernet/service/ethernet-service';
import profileConfigs from 'features/library-items/library/library-item-configurations/items/profile';
import { Setting } from 'features/library-items/template';
import moment from 'moment';
import React, { useState } from 'react';
import './configure-adcs.css';

const adcsKeys = EthernetService.keys.authentication.adcs;

const SpecifyAdcs = ({
  setting,
  hasError,
  openOptions,
  disabled,
  drawerRef,
}) => {
  const isAdcsConfigured = [
    adcsKeys.subject,
    adcsKeys.template,
    adcsKeys.server,
  ].every((s) => setting[s]);

  return (
    <div
      onClick={() => !disabled && openOptions()}
      style={{ display: 'flex', cursor: disabled ? 'not-allowed' : 'pointer' }}
      ref={drawerRef}
      data-testid="specify-adcs"
    >
      <div
        className={setClass(
          'k-skip-screen-tags',
          hasError ? 'k-skip-screen-tags--error' : '',
        )}
      >
        <div className="k-skip-screen-tags__main">
          <p className="b-txt">
            <button
              type="button"
              className={setClass([
                hasError ? 'b-txt b-txt--error' : 'b-alink b-decorate-off',
                disabled && 'b-alink--disabled',
              ])}
              disabled={disabled}
            >
              {isAdcsConfigured ? (
                <div className="b-flex-vc">
                  <img
                    className="b-mr1 adcs-d__cert-image"
                    src={profileConfigs.Certificate.icon}
                    alt=""
                  />
                  <div className="b-flex-col b-flex-align-start">
                    <p className="b-txt-bold">AD CS Certificate</p>
                    <p className="b-txt-light adcs-d__cert-name">
                      {setting[adcsKeys.name] || 'Certificate name (optional)'}
                    </p>
                    {setting[adcsKeys.updatedAt] && (
                      <p className="b-txt-light">
                        added{' '}
                        {moment(setting[adcsKeys.updatedAt]).format(
                          'MM/DD/YYYY',
                        )}
                      </p>
                    )}
                  </div>
                </div>
              ) : (
                'Configure AD CS Certificate'
              )}
            </button>
          </p>
        </div>
        <div className="k-skip-screen-tags__icon">
          <button
            type="button"
            className={setClass([
              'k-skip-screen-tags__icon-button',
              'b-alink',
              'b-decorate-off',
              disabled && 'b-alink--disabled',
            ])}
            disabled={disabled}
          >
            <Icon name="pencil" />
          </button>
        </div>
      </div>
    </div>
  );
};

const externalFields = [
  ...Object.values(EthernetService.keys.authentication.scep),
  ...Object.values(EthernetService.keys.authentication.pkcs),
  ...Object.values(EthernetService.keys.authentication.acceptedEapTypes),
  EthernetService.keys.authentication.identityCertificate,
];

const fieldsToValidate = [
  adcsKeys.subject,
  adcsKeys.template,
  adcsKeys.server,
  adcsKeys.san,
];

const ConfigureAdcs = ({
  update,
  isDisabled,
  setting,
  validationDep,
  adcsServers,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const {
    onInvalidate,
    refs,
    invalidations,
    drawerRef,
    isValidationFailed,
    canShowDrawerError,
  } = useDrawerValidate({
    fieldsToValidate,
    isVisible,
    update,
    validationDep,
    drawerKey: adcsKeys.drawer,
  });

  return (
    <Setting.SecondaryControls className="k-ade-secondary-ctrl-grid-gap-align">
      <Setting.SecondaryControlsRow>
        <SpecifyAdcs
          setting={setting}
          disabled={isDisabled}
          hasError={canShowDrawerError && isValidationFailed}
          openOptions={() => setIsVisible(true)}
          drawerRef={drawerRef}
        />
      </Setting.SecondaryControlsRow>
      <Drawer
        update={update}
        defaults={{}}
        setting={setting}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        validationDep={validationDep}
        onInvalidate={onInvalidate}
        DrawerContent={DrawerContent}
        title="Configure AD CS Certificate"
        externalFields={externalFields}
        refs={refs}
        invalidations={invalidations}
        size="super-wide"
        adcsServers={adcsServers}
      />
    </Setting.SecondaryControls>
  );
};

export default React.memo(ConfigureAdcs);
