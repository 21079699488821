import { Checkbox, Radio } from '@kandji-inc/bumblebee';
import { Setting } from 'features/library-items/template';
import React from 'react';
import featureFlags from 'src/config/feature-flags';
import { i18n } from 'src/i18n';
import { LanguageRegionSetting } from '../general/LanguageRegionSetting';
import SkipScreens, { useSkipScreensValidation } from '../skip-screens';

function AppleTV(props) {
  const { setting, update, isDisabled, isSaving } = props;

  const SKIP_SCREENS_PANEL_CONFIG = {
    title: i18n.t('Specify Setup Assistant screens for Apple TV'),
    helper: i18n.t(
      'Select the checkbox for a Setup Assistant screen to skip it.',
    ),
    selectAllLabel: i18n.t('Skip all screens for Apple TV'),
  };

  const skipScreensValidationSettings = {
    isEnabled: setting.isEnabled,
    canSkip: setting.canSkip,
    isSkipAll: setting.isAutoAdvance,
    screens: setting.screens,
    isSaving,
  };

  const { skipScreensRef, isSkipScreenInvalid } = useSkipScreensValidation(
    skipScreensValidationSettings,
    update,
  );

  const languageRegionEnabled = featureFlags.getFlag('dl-language-region');
  const showLanguageRegionSetting =
    !languageRegionEnabled || (setting.canSkip && setting.isAutoAdvance);

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('Apple TV')}</h3>
        <Setting.HeaderToggle
          isEnabled={setting.isEnabled}
          onChange={() => update('isEnabled', (p) => !p)}
          isDisabled={isDisabled}
          isHideChip
        />
      </Setting.Header>

      <Setting.SubHeader>
        <p className="b-txt">
          {i18n.t(
            'Customize and configure Setup Assistant screens, Language/Region, and Auto Advance.',
          )}
        </p>
      </Setting.SubHeader>

      {setting.isEnabled && (
        <Setting.Rows>
          <Setting.Row>
            <Setting.Controls>
              <Checkbox
                checked={setting.canSkip}
                onChange={() => update('canSkip', (p) => !p)}
                disabled={isDisabled}
                label={i18n.t(
                  'Skip screens during Setup Assistant for Apple TV devices.',
                )}
              />
            </Setting.Controls>

            <Setting.Helpers className="b-txt-light">
              {i18n.t(
                'Determine which screens are displayed during Setup Assistant.',
              )}
            </Setting.Helpers>

            {setting.canSkip && (
              <Setting.SecondaryControls>
                <div className="b-library-form">
                  <div className="k-ade-secondary-subrow-lite">
                    <div className="b-grid-controls">
                      <Radio
                        label={i18n.t(
                          'Automatically advance through all Setup Assistant screens (requires Ethernet)',
                        )}
                        checked={setting.isAutoAdvance}
                        disabled={isDisabled}
                        onChange={() => update('isAutoAdvance', true)}
                      />
                      <Radio
                        label={
                          <span>
                            {i18n.t(
                              'Specify which screens to skip during Setup Assistant',
                            )}
                          </span>
                        }
                        checked={!setting.isAutoAdvance}
                        disabled={isDisabled}
                        onChange={() => update('isAutoAdvance', false)}
                      />
                    </div>
                  </div>
                  {!setting.isAutoAdvance && (
                    <div ref={skipScreensRef}>
                      <Setting.SecondaryControls>
                        <SkipScreens
                          panelConfig={SKIP_SCREENS_PANEL_CONFIG}
                          screens={setting.screens}
                          setScreens={(screens) => update('screens', screens)}
                          disabled={isDisabled}
                          hasError={isSkipScreenInvalid}
                        />
                      </Setting.SecondaryControls>
                    </div>
                  )}
                </div>
              </Setting.SecondaryControls>
            )}
          </Setting.Row>
          {showLanguageRegionSetting && (
            <LanguageRegionSetting
              deviceType={i18n.t('Apple TV')}
              setting={setting}
              isDisabled={isDisabled}
              update={update}
            />
          )}
        </Setting.Rows>
      )}
    </Setting.Card>
  );
}

export default AppleTV;
