import {
  ButtonSelect,
  Card,
  Checkbox,
  Flex,
  Select,
  TextField,
} from '@kandji-inc/nectar-ui';
import React from 'react';
import { deviceOptions } from 'src/features/library-items/data-service/library-item/devices';
import { i18n } from 'src/i18n';

const restrictionTypeOptions = () => [
  { label: i18n.t('All Restrictions'), value: 'all' },
  { label: i18n.t('Supervision not required'), value: 'non-supervised' },
  { label: i18n.t('Supervision required'), value: 'supervised' },
];

export type FilterType =
  | 'restrictionType'
  | 'isShowEnabledOnly'
  | 'deviceType'
  | 'searchTerm';

export type RestrictionsFilterProps = {
  values: Record<FilterType, any>;
  onChange: (key: FilterType, value: any) => void;
};

const RestrictionsFilter = (props: RestrictionsFilterProps) => {
  const { onChange, values } = props;

  const getOnChange = (key: FilterType) => (v: any) => onChange(key, v);

  return (
    <Card>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex gap="md" alignItems="center">
          <Select
            size="sm"
            options={restrictionTypeOptions()}
            value={values.restrictionType}
            onChange={getOnChange('restrictionType')}
          />
          <Checkbox
            label={i18n.t('Show enabled only')}
            size="2"
            checked={values.isShowEnabledOnly}
            onCheckedChange={getOnChange('isShowEnabledOnly')}
          />
          <ButtonSelect.Root
            defaultValue="all"
            type="single"
            value={values.deviceType}
          >
            <ButtonSelect.Item
              value="all"
              onClick={() => getOnChange('deviceType')('all')}
            >
              All
            </ButtonSelect.Item>
            {deviceOptions.map(({ value, label }) => (
              <ButtonSelect.Item
                key={value}
                value={value}
                onClick={() => getOnChange('deviceType')(value)}
              >
                {label}
              </ButtonSelect.Item>
            ))}
          </ButtonSelect.Root>
        </Flex>
        <TextField
          placeholder={i18n.t('Search')}
          icon="magnifying-glass"
          value={values.searchTerm}
          onChange={(e) => getOnChange('searchTerm')(e.target.value)}
        />
      </Flex>
    </Card>
  );
};

export default RestrictionsFilter;
