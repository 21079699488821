import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useLostMode from 'src/hooks/useLostMode';
import styled from 'styled-components';
import EnabledLostModeTab from './EnabledLostModeTab';
import ErroredLostModeTab from './ErroredLostModeTab';
import LostModeDisableStatus from './LostModeDisableStatus';
import LostModeHeader from './LostModeHeader';
import PendingLostModeTab from './PendingLostModeTab';
import refreshLostModeData from './refreshLostModeData';

const LostModeContainer = styled.div`
  .lost-mode__status-banner {
    margin-top: 24px;

    & > div {
      display: flex;

      .lost-mode__warning-refresh,
      .lost-mode__activity-link {
        margin-left: 5px;
        margin-right: 5px;
        text-decoration: underline;
        color: inherit;
      }
    }
  }
  .lost-mode__container {
    margin-top: 24px;
    margin-bottom: 350px;
    background: #fff;
    border-radius: 4px;
  }
`;

const LostModeTab = (props) => {
  const dispatch = useDispatch();
  const {
    isLostModeLocationPending,
    isLostModePending,
    isLostModeDisablePending,
    isLostModeSoundPending,
  } = useLostMode(props.computer);

  useEffect(() => {
    refreshLostModeData(props, dispatch);
  }, []);

  /* istanbul ignore next */
  useEffect(() => {
    let id;
    let count = 0;
    if (
      isLostModeLocationPending ||
      isLostModeDisablePending ||
      isLostModePending ||
      isLostModeSoundPending
    ) {
      id = window.setInterval(() => {
        const MAX_REFRESHES_PER_2SEC_INTERVAL = 3;
        const REFRESH_LIMIT = 12;
        const COUNT_INCREMENT = 1;

        // * Refreshing every 2 seconds, 3 times
        refreshLostModeData(props, dispatch);
        count += COUNT_INCREMENT;
        if (count === MAX_REFRESHES_PER_2SEC_INTERVAL) {
          window.clearInterval(id);
          id = window.setInterval(() => {
            count += COUNT_INCREMENT;
            // * Refreshing every 6 seconds, 10 times
            refreshLostModeData(props, dispatch);
            if (count === REFRESH_LIMIT) {
              // * Clearing interval after 12 refreshes total -- 1 min 6 seconds
              window.clearInterval(id);
            }
          }, 6000);
        }
      }, 2000);
    }
    return () => window.clearInterval(id);
  }, [
    isLostModeLocationPending,
    isLostModeDisablePending,
    isLostModePending,
    isLostModeSoundPending,
    dispatch,
  ]);

  return (
    <LostModeContainer>
      <LostModeDisableStatus {...props} />
      <div className="lost-mode__container">
        <LostModeHeader {...props} />
        <RenderTab {...props} />
      </div>
    </LostModeContainer>
  );
};

function RenderTab(props) {
  const { isLostModeEnabled, isLostModeErrored } = useLostMode(props.computer);

  if (isLostModeEnabled) {
    return <EnabledLostModeTab {...props} />;
  }
  if (isLostModeErrored) {
    return <ErroredLostModeTab {...props} />;
  }
  return <PendingLostModeTab {...props} />;
}

export default LostModeTab;
