import { updateSetting as update } from '@kandji-inc/bumblebee';
import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';

import { timezones } from 'app/common/constants';
import ActivityTab from 'features/library-items/common/activity-tab';
import { useLibraryItem } from 'features/library-items/common/hooks/use-library-item';
import LibraryItemPage from 'features/library-items/common/library-item-page';
import NewStatusTab from 'features/library-items/common/new-status-tab/new-status-tab';
import { INITIAL_MODEL } from 'features/library-items/items/macos-releases/constants';
import MacosReleasesService, {
  macosReleasesService,
} from 'features/library-items/items/macos-releases/service/macos-releases-service';
import {
  createTransformToApi,
  transformFromApi,
} from 'features/library-items/items/macos-releases/service/transformers';
import useMacosReleasesService from 'features/library-items/items/macos-releases/service/use-macos-releases-service';
import { InstallationCard } from 'features/library-items/template';
import featureFlags from 'src/config/feature-flags';
import UpdatesCard from 'src/features/library-items/common/updates-card/UpdatesCard';
import { i18n } from 'src/i18n';
import useDefaultTimezone from '../../../../components/common/hooks/use-default-timezone';
import useUniqValue from '../../../../components/common/hooks/use-uniq-value/use-uniq-value';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { getNewStatusTabProps } from '../../common/new-status-tab/util';
import { ManagedOSNameShort } from '../../common/updates-card/updates-card.types';
import { publishers } from '../../library/common';
import configs from '../../library/library-item-configurations/items/operating-systems';
import type { MacosReleasesPageProps } from './macos-releases.types';
import DrawerContent from './self-service-card/drawer-content';
import SelfServiceCard from './self-service-card/self-service-card';

/* istanbul ignore next */
export default function MacosReleases() {
  const { pathname } = useLocation();
  const [validationDep, triggerValidation] = useUniqValue();
  const blueprintConflicts = useBlueprintConflicts();
  const defaultTimezone = useDefaultTimezone();

  const service = useMacosReleasesService({ isAdd: pathname.includes('/add') });

  const INSTALL_OPTIONS = [
    {
      value: MacosReleasesService.installationTypes.CONTINUOUSLY_ENFORCE,
      label: i18n.t('Continuously Enforce'),
    },
    {
      value: MacosReleasesService.installationTypes.NO_ENFORCEMENT,
      label: i18n.t('Install on-demand from Self Service'),
    },
  ];

  const INSTALLATION_HELPER = (
    <div>
      <p className="b-txt-light mb-4">
        {i18n.t(
          `Determine how this macOS version should be installed and enforced.`,
        )}
      </p>
      <p className="b-txt-light mb-4">
        {i18n.ut(`Setting this option to
        <span class="b-txt-light b-txt-bold">Continuously Enforce</span> will
        cause the <span class="b-txt-light b-txt-bold">Enforcement</span>
        configuration below to determine when this major macOS Upgrade and
        subsequent minor updates are enforced.`)}
      </p>
      <p className="b-txt-light">
        {i18n.ut(`Setting this option to
        <span class="b-txt-light b-txt-bold">
          Install on-demand from Self Service
        </span>
        will cause the <span class="b-txt-light b-txt-bold">Enforcement</span>
        configuration below to only handle enforcement for minor macOS updates;
        after the user has upgraded to this major macOS version through Self
        Service or Software Update.`)}
      </p>
    </div>
  );

  const {
    pageProps,
    PlaceHolder,
  }: {
    pageProps: MacosReleasesPageProps;
    PlaceHolder: (props: unknown) => JSX.Element;
  } = useLibraryItem({
    initialState: INITIAL_MODEL(),
    useService: service,
  });

  const {
    model,
    handlers,
    pageState,
    savedModel,
    isEditable,
    blueprintOptions,
    facetMap,
    history,
    setModel,
    setSavedModel,
    restoreModel,
  } = pageProps;

  const {
    _data: data,
    updates,
    name,
    instanceName,
    installation,
    selfService,
    description,
    subtitle,
    publisher,
    osRequirements,
    icon,
    currentVersion,
  } = model;

  const selectedTimezone =
    model.updates.enforcementTimezone ||
    timezones.find((tz) => tz.value === defaultTimezone);

  const breadcrumb = instanceName ? `${name} - ${instanceName}` : name;

  const updateInstallation = useMemo(
    () => update('installation', setModel),
    [setModel],
  );
  const updateSelfService = useMemo(
    () => update('selfService', setModel),
    [setModel],
  );
  const updateUpdates = useMemo(() => update('updates', setModel), [setModel]);

  const fall2023NewManagedOSLibraryItems = featureFlags.getFlag(
    'mdm_081123_fall-2023-managed-os-library-items',
  );

  const fall2023RSREnforcement = featureFlags.getFlag(
    'mdm-10312023-rsr-version-updates-fe-2',
  );

  useEffect(() => {
    update('selfService', setModel)(
      'isEnabled',
      installation.type ===
        MacosReleasesService.installationTypes.NO_ENFORCEMENT,
    );
  }, [installation, setModel]);

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  const config = configs['MacOS Release'];

  return (
    <LibraryItemPage
      history={history}
      model={model}
      setModel={setModel}
      pageState={pageState}
      handlers={handlers}
      restoreModel={restoreModel}
      setSavedModel={setSavedModel}
      savedModel={savedModel}
      isEditable={isEditable}
      blueprintOptions={blueprintOptions}
      facetMap={facetMap}
      blueprintConflicts={blueprintConflicts}
      type={config.type}
      identifier={config.identifier}
      crumb={breadcrumb}
      service={macosReleasesService}
      triggerValidation={triggerValidation}
      transformFromApi={transformFromApi}
      transformToApi={createTransformToApi({ selectedTimezone })}
      ActivityTab={ActivityTab}
      isNameEditable={false}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      canHaveInstanceName
      summaryInfoProps={{
        name: config.getName(),
        instanceName,
        description,
        publisher: publisher || publishers.APPLE,
        devices: config.devices,
        requirements: [osRequirements],
        currentVersion,
      }}
      supportsRules
      supportsDuplication
    >
      <InstallationCard
        title={i18n.t('Upgrades')}
        setting={installation}
        update={updateInstallation}
        isDisabled={pageState.isDisabled}
        options={INSTALL_OPTIONS}
        helper={INSTALLATION_HELPER}
      />
      <SelfServiceCard
        setting={selfService}
        update={updateSelfService}
        isDisabled={pageState.isDisabled}
        defaults={{
          icon,
          name,
          shortDescription: subtitle,
          longDescription: description,
        }}
        isSubmitted={pageState.isSubmitted}
        validationDep={validationDep}
        DrawerContent={DrawerContent}
      />
      <UpdatesCard
        installationType={installation.type}
        name={name}
        update={updateUpdates}
        setting={updates}
        isDisabled={pageState.isDisabled}
        selectedTimezone={selectedTimezone}
        validationDep={validationDep}
        appName={ManagedOSNameShort.MAC_OS}
        version={data?.version}
        fall2023NewManagedOSLibraryItems={fall2023NewManagedOSLibraryItems}
        fall2023RSREnforcement={fall2023RSREnforcement}
        timezoneMinOSVersion="13"
      />
    </LibraryItemPage>
  );
}
