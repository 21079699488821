import { Icon, setClass } from '@kandji-inc/bumblebee';
import { useDrawerValidate } from 'features/library-items/items/ethernet/ethernet.hooks';
import profileConfigs from 'features/library-items/library/library-item-configurations/items/profile';
import { Setting } from 'features/library-items/template';
import moment from 'moment';
import React, { useState } from 'react';
import { i18n } from 'src/i18n';
import Drawer from '../../../components/drawer';
import EthernetService from '../../../service/ethernet-service';
import DrawerContent from './drawer-content';

const scepKeys = EthernetService.keys.authentication.scep;

const SpecifyScep = ({
  setting,
  hasError,
  openOptions,
  disabled,
  drawerRef,
}) => {
  const isScepConfigured = [EthernetService.keys.authentication.scep.url].every(
    (s) => setting[s],
  );

  return (
    <div
      onClick={() => !disabled && openOptions()}
      style={{ display: 'flex', cursor: disabled ? 'not-allowed' : 'pointer' }}
      ref={drawerRef}
      data-testid="specify-scep"
    >
      <div
        className={setClass(
          'k-skip-screen-tags',
          hasError ? 'k-skip-screen-tags--error' : '',
        )}
      >
        <div className="k-skip-screen-tags__main">
          <p className="b-txt">
            <button
              type="button"
              className={setClass([
                hasError ? 'b-txt b-txt--error' : 'b-alink b-decorate-off',
                disabled && 'b-alink--disabled',
              ])}
              disabled={disabled}
            >
              {isScepConfigured ? (
                <div className="b-flex-vc">
                  <img
                    className="b-mr1 adcs-d__cert-image"
                    src={profileConfigs.SCEP.icon}
                    alt=""
                  />
                  <div className="b-flex-col b-flex-align-start">
                    <p className="b-txt-bold">{i18n.t(`SCEP Certificate`)}</p>
                    <p className="b-txt-light adcs-d__cert-name">
                      {setting[scepKeys.url]}
                    </p>
                    {setting[scepKeys.updatedAt] && (
                      <p className="b-txt-light">
                        {i18n.t(`added {date}`, {
                          date: i18n.format.date(
                            new Date(
                              moment(setting[scepKeys.updatedAt]).format(
                                'MM/DD/YYYY',
                              ),
                            ),
                          ),
                        })}
                      </p>
                    )}
                  </div>
                </div>
              ) : (
                i18n.t('Configure SCEP Certificate')
              )}
            </button>
          </p>
        </div>
        <div className="k-skip-screen-tags__icon">
          <button
            type="button"
            className={setClass([
              'k-skip-screen-tags__icon-button',
              'b-alink',
              'b-decorate-off',
              disabled && 'b-alink--disabled',
            ])}
            disabled={disabled}
          >
            <Icon name="pencil" />
          </button>
        </div>
      </div>
    </div>
  );
};

const externalKeys = [
  ...Object.values(EthernetService.keys.authentication.acceptedEapTypes),
  ...Object.values(EthernetService.keys.authentication.pkcs),
  ...Object.values(EthernetService.keys.authentication.adcs),
  EthernetService.keys.authentication.identityCertificate,
];

const fieldsToValidate = [
  EthernetService.keys.authentication.scep.url,
  EthernetService.keys.authentication.scep.san,
  EthernetService.keys.authentication.scep.sanUpn,
  EthernetService.keys.authentication.scep.retries,
  EthernetService.keys.authentication.scep.retryDelay,
  EthernetService.keys.authentication.scep.certificateExpirationNotification,
  EthernetService.keys.authentication.scep.autoProfileRedistribution,
];

const ConfigureScep = ({ update, isDisabled, setting, validationDep }) => {
  const [isVisible, setIsVisible] = useState(false);
  const {
    onInvalidate,
    refs,
    drawerRef,
    isValidationFailed,
    canShowDrawerError,
  } = useDrawerValidate({
    fieldsToValidate,
    isVisible,
    update,
    validationDep,
    drawerKey: EthernetService.keys.authentication.scep.drawer,
  });

  return (
    <Setting.SecondaryControls className="k-ade-secondary-ctrl-grid-gap-align">
      <Setting.SecondaryControlsRow>
        <SpecifyScep
          setting={setting}
          disabled={isDisabled}
          hasError={canShowDrawerError && isValidationFailed}
          openOptions={() => setIsVisible(true)}
          drawerRef={drawerRef}
        />
      </Setting.SecondaryControlsRow>
      <Drawer
        update={update}
        defaults={{}}
        setting={setting}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        DrawerContent={DrawerContent}
        onInvalidate={onInvalidate}
        size="super-wide"
        title={i18n.t('Configure SCEP')}
        externalFields={externalKeys}
        validationDep={validationDep}
        refs={refs}
      />
    </Setting.SecondaryControls>
  );
};

export default React.memo(ConfigureScep);
