import { Select } from '@kandji-inc/bumblebee';
import React from 'react';
import { i18n } from 'src/i18n';

import { Setting } from 'features/library-items/template';

import EthernetService from '../../service/ethernet-service';
import { innerAuthenticationOptions } from '../protocols-card.model';

const keys = EthernetService.keys.authentication.acceptedEapTypes;

const InnerAuthentication = ({
  refs,
  isDisabled,
  update,
  setting,
  canShowInnerAuthenticationError,
  innerAuthenticationError,
}) => (
  <Setting.SecondaryControlsRow>
    <div>
      <p className="b-txt">{i18n.t('Inner authentication')}</p>
    </div>
    <div>
      <p className="b-txt-light">
        {i18n.t('The inner authentication used by the TTLS module.')}
      </p>
    </div>
    <div ref={refs[keys.innerAuthentication]}>
      <Select
        isDisabled={isDisabled}
        options={innerAuthenticationOptions()}
        compact
        onChange={(v) => update(keys.innerAuthentication, v.value)}
        value={innerAuthenticationOptions().find(
          (option) => option.value === setting[keys.innerAuthentication],
        )}
        errorText={
          (canShowInnerAuthenticationError && innerAuthenticationError) || ''
        }
      />
    </div>
  </Setting.SecondaryControlsRow>
);

export default React.memo(InnerAuthentication);
