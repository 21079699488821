/* istanbul ignore file - legacy code */
import { libraryAxios } from 'app/api/base';
import Axios from 'axios';

export const waitForSHA256 = (pendingUploadId, retries = 120, delay = 5000) =>
  new Promise((resolve, reject) => {
    libraryAxios(`custom-apps/pending-upload/${pendingUploadId}`)
      .get()
      .then((response) => {
        const { sha256 } = response.data;
        if (sha256) {
          resolve(sha256);
        } else if (retries === 0) {
          reject(new Error('Could not calculate SHA256'));
        } else {
          setTimeout(() => {
            waitForSHA256(pendingUploadId, retries - 1, delay).then(
              resolve,
              reject,
            );
          }, delay);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

export const uploadCustomAppToS3 = (s3PostData, file, options) => {
  const formData = new FormData();
  Object.entries(s3PostData.fields).forEach((values) =>
    formData.append(...values),
  );
  formData.append('file', file);
  return Axios.post(s3PostData.url, formData, options);
};

export const getS3InfoForFile = (file, type) =>
  libraryAxios('custom-apps/pending-upload').post({
    filename: file.name,
    type,
  });

export const uploadCustomApp = (file, onUploadProgress, s3Data) => {
  const CancelSource = new Axios.CancelToken.source();
  return {
    cancel: CancelSource.cancel,
    upload: uploadCustomAppToS3(s3Data, file, {
      onUploadProgress: (p) =>
        onUploadProgress(Math.round((p.loaded / p.total) * 100)),
      cancelToken: CancelSource.token,
    }),
  };
};
