import { Checkbox, Radio } from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';
import { i18n } from 'src/i18n';

import SkipScreens, { useSkipScreensValidation } from '../skip-screens';

const SkipScreensRow = ({ setting, update, isDisabled, isSaving }) => {
  const SKIP_SCREENS_PANEL_CONFIG = {
    title: i18n.t('Specify Setup Assistant screens for Mac'),
    helper: i18n.t(
      'Select the checkbox for a Setup Assistant screen to skip it.',
    ),
    selectAllLabel: i18n.t('Skip all screens for Mac'),
  };

  const skipScreensValidationSettings = {
    isEnabled: setting.isEnabled,
    canSkip: setting.canSkip,
    isSkipAll: setting.isAutoAdvance,
    screens: setting.screens,
    isSaving,
  };

  const { skipScreensRef, isSkipScreenInvalid } = useSkipScreensValidation(
    skipScreensValidationSettings,
    update,
  );

  return (
    <Setting.Row>
      <Setting.Controls>
        <Checkbox
          defaultChecked
          checked={setting.canSkip}
          label={i18n.t('Skip screens during Setup Assistant for Mac devices')}
          onChange={() => update('canSkip', (p) => !p)}
          isDisabled={isDisabled}
        />
      </Setting.Controls>
      <Setting.Helpers>
        <p className="b-txt-light">
          {i18n.t(
            'Determine which screens are displayed during Setup Assistant.',
          )}
        </p>
      </Setting.Helpers>
      {setting.canSkip && (
        <Setting.SecondaryControls>
          <div className="b-library-form">
            <div className="b-grid-controls">
              <Radio
                label={i18n.t(
                  'Automatically advance through all Setup Assistant screens (requires Ethernet)',
                )}
                value="daily"
                checked={setting.isAutoAdvance}
                onChange={() => update('isAutoAdvance', true)}
                isDisabled={isDisabled}
              />
              <Radio
                label={
                  <span>
                    {i18n.t(
                      'Specify which screens to skip during Setup Assistant',
                    )}
                  </span>
                }
                checked={!setting.isAutoAdvance}
                onChange={() => update('isAutoAdvance', false)}
                isDisabled={isDisabled}
              />
            </div>
            {!setting.isAutoAdvance && (
              <div ref={skipScreensRef}>
                <Setting.SecondaryControls>
                  <SkipScreens
                    panelConfig={SKIP_SCREENS_PANEL_CONFIG}
                    screens={setting.screens}
                    setScreens={(screens) => update('screens', screens)}
                    disabled={isDisabled}
                    hasError={isSkipScreenInvalid}
                  />
                </Setting.SecondaryControls>
              </div>
            )}
          </div>
        </Setting.SecondaryControls>
      )}
    </Setting.Row>
  );
};

export default SkipScreensRow;
