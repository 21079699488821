import GatekeeperService from './service/gatekeeper-service';

export default {
  name: '',
  isActive: true,

  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,

  general: {
    appsFrom: GatekeeperService.appsFrom.MAC_AND_DEVELOPERS,
    disableOverride: false,
    EnableXProtectMalwareUpload: true,
  },

  supportsRules: true,
};
