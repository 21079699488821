import { useEffect, useState } from 'react';

const useShadowOnScroll = () => {
  const [isShowHeaderShadow, setIsShowHeaderShow] = useState(false);
  const [body, setBody] = useState();

  const handleScroll = () => {
    setIsShowHeaderShow(body.scrollTop > 0);
  };

  useEffect(() => {
    if (body) {
      body.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (body) {
        body.removeEventListener('scroll', handleScroll);
      }
    };
  }, [body]);

  return { isShowHeaderShadow, setBody };
};

export default useShadowOnScroll;
