import { api } from 'app/api/base';
import { URL_FEATURE_REQUEST_UPDATED } from 'app/api/urls';

export const postFeatureRequest = async (form) => {
  try {
    const response = await api(URL_FEATURE_REQUEST_UPDATED).post(form);
    // console.log(response); // delete
    const { data } = response;
    return data;
  } catch (error) {
    // console.log(error); // delete
    return error;
  }
};
