import { libraryAxios, newLibraryAxios } from 'app/api/base';

const libraryTypes = {
  library: 'library-items/',
  'custom-apps': 'custom-apps/',
  'custom-scripts': 'custom-scripts/',
  profiles: 'profiles/',
  'dep-profiles': 'dep-profiles/',
  'managed-library': 'managed-library-items/list',
};

/*
 * Usage:
 *
 * import { libraryItemService } from "./library-item-service";
 * libraryItemService.find({}).then(console.log)
 * libraryItemService.get(id).then(console.log)
 * libraryItemService.create({ ...data }).then(console.log)
 * libraryItemService.remove(id).then(console.log)
 * libraryItemService.patch(id, { ...data }).then(console.log)
 */
class LibraryItemService {
  constructor(
    prefix = libraryTypes.library,
    postfix = '',
    createWithPostfix = true,
  ) {
    this.prefix = prefix;
    this.addLibraryitemPrefix = `${prefix}${
      createWithPostfix ? `add${postfix}` : ''
    }`;
    this.conflictsPrefix = 'check-conflicts/';
    this.uploadPrefix = `${prefix}upload`;
    this.withId = (id) => `${prefix}${id}${postfix}`;
    this.withTemplateId = (id) => `managed-library-items/${id}`;
  }

  async get(id, params) {
    const client = libraryAxios(this.withId(id));
    return client.get(params);
  }

  async find(params) {
    const prefix = (params.type && libraryTypes[params.type]) || this.prefix;
    const client = libraryAxios(prefix);
    const urlParams = {};
    if (params.forLibrary) {
      urlParams.for_library = true;
    }
    return client.get(urlParams);
  }

  async create(data, params) {
    const client = libraryAxios(this.addLibraryitemPrefix);
    return client.post(data, params);
  }

  async duplicate(id) {
    // The /duplicate endpoint only exists on the new library item API
    return newLibraryAxios(`${id}/duplicate/`).post();
  }

  async patch(id, data, params) {
    const client = libraryAxios(this.withId(id));
    return client.patch(data, params);
  }

  async update(id, data, params) {
    const client = libraryAxios(this.withId(id));
    return client.put(data, params);
  }

  async remove(id, data, params) {
    const client = libraryAxios(this.withId(id));
    return client.delete(data, params);
  }

  /**
   *
   * @param {FormData} filesForm - A form containing files to upload.
   */
  async uploadFile(filesForm) {
    const client = libraryAxios(this.uploadPrefix);
    return client.post(filesForm);
  }

  async checkConflicts({
    type,
    itemId,
    blueprintIds,
    identifier,
    managedLibraryItemId,
    isAllBlueprints,
  }) {
    const client = libraryAxios(this.conflictsPrefix);
    const params = {
      type,
      blueprints: blueprintIds,
      identifier: identifier || '',
      managed_library_item_id: managedLibraryItemId,
      is_all_blueprints: isAllBlueprints,
      ...(itemId && { item_id: itemId }),
    };
    return client.post(params).then(({ data }) => data);
  }
}

const libraryItemService = new LibraryItemService();
export default LibraryItemService;
export { libraryItemService, libraryTypes };
