import { TextInput } from '@kandji-inc/bumblebee';
import React from 'react';
import { i18n } from 'src/i18n';

import { Setting } from 'features/library-items/template';

import EthernetService from '../../service/ethernet-service';

const keys = EthernetService.keys.authentication.acceptedEapTypes;

const Username = ({ isDisabled, update, setting }) => (
  <Setting.SecondaryControlsRow>
    <div>
      <p className="b-txt">
        {i18n.t('Username')}
        <span className="b-txt-light2"> {i18n.t('(optional)')}</span>
      </p>
    </div>
    <div>
      <p className="b-txt-light">{i18n.t('The user name for the account.')}</p>
    </div>
    <div>
      <TextInput
        disabled={isDisabled}
        value={setting[keys.username]}
        onChange={(e) => {
          update(keys.username, e.target.value);
          /* istanbul ignore next */
          update(
            keys.authentication,
            e.target.value
              ? EthernetService.authentications.USERNAME_AND_PASSWORD
              : null,
          );
        }}
        compact
        isOptional
        placeholder="$EMAIL"
        data-testid="auth-email"
      />
    </div>
  </Setting.SecondaryControlsRow>
);

export default React.memo(Username);
