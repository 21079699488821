import React from 'react';
import ODTAppConfigBanners from '../additional-settings/okta/components/app-config-banners/app-config-banners';

type ModelType = {
  isEnabled: boolean;
};

type Configuration = {
  value: string;
};
type Model = {
  bundleId: string;
  configuration: Configuration;
  oktaDeviceTrust: ModelType;
} & { [key: string]: unknown };

type PageState = {
  isEditing: boolean;
};

export function getConfigCardBanners(model: Model, pageState: PageState) {
  const { bundleId, configuration } = model;
  const { isEditing } = pageState;
  const isOktaDeviceTrustEnabled = model?.oktaDeviceTrust?.isEnabled;

  const banners = {
    'com.okta.mobile': isOktaDeviceTrustEnabled && (
      <ODTAppConfigBanners
        isEditing={isEditing}
        isODTEnabled={isOktaDeviceTrustEnabled}
        isConfigured={Boolean(configuration.value)}
      />
    ),
  };

  if (!banners[bundleId]) {
    return null;
  }

  return banners[bundleId];
}
