import { LineLoader } from 'app/components/interface/LineLoader';
import { DataContext } from 'contexts/data';
import { InterfaceContext } from 'contexts/interface';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ErrorBoundary from './ErrorBoundary';

// TODO: clean up className and styles phasing "pr-5" out
// TODO: possibly move this code into Main.js and phase this file out

const Content = ({ children }) => {
  const { appDataReady } = useContext(DataContext);
  const { sidebarDocked, sidebarOpened, setSidebarOpened } =
    useContext(InterfaceContext);
  const onClick = () =>
    !sidebarDocked && sidebarOpened && setSidebarOpened(false);
  return (
    <ErrorBoundary>
      <main
        className={`full-width-sidebar-${
          sidebarDocked && sidebarOpened ? 'opened' : 'closed'
        }`}
        onClick={onClick}
        role="main"
      >
        {appDataReady ? children : <LineLoader isDelayed />}
      </main>
    </ErrorBoundary>
  );
};

Content.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Content;
