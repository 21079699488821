import { createReducer } from 'redux-create-reducer';
import { editor } from '../_actions/action-types';

const initialState = '';

export default createReducer(initialState, {
  [editor.SET_EDITOR](state, action) {
    return action.html;
  },
  [editor.CLEAR_EDITOR]() {
    return initialState;
  },
});
