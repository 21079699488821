import { KeyboardSensor } from '@dnd-kit/core';
import type { DraggableNode, KeyboardSensorOptions } from '@dnd-kit/core';
import type { SyntheticEvent } from 'react';

/**
 * Custom dnd kit sensor extending the built-in {@link KeyboardSensor} to
 * support selecting multiple items.
 */
export class MultiSelectKeyboardSensor extends KeyboardSensor {
  private static readonly _originalActivators = KeyboardSensor.activators;

  /**
   * Keys pressed without any keyboard modifiers held that trigger app selection
   * of the currently focused app on user click or `{Tab}`/`{Shift} + {Tab}`
   * keydown to focus the next/previous app.
   */
  public static readonly _onSelectNormalKey = {
    s: 's',
  } as const;

  /**
   * Keys pressed with shift key modifier held that trigger app selection of the
   * currently focused app on user click on app or `{Tab}`/`{Shift} + {Tab}`
   * keydown to focus the next/previous app.
   *
   * @remarks
   * Note that dnd kit has default keys of `{" "/Space}` or `{Enter}` to start or
   * end the drag process so a modifier must be used in order to use those keys
   * for app selection.
   */
  public static readonly _onSelectShiftKey = {
    Enter: 'Enter',
    ' ': ' ',
  } as const;

  /**
   * The static {@link KeyboardSensor.activators} field determines which
   * keyboard events that the sensor will listen and respond to. By default, the
   * original {@link KeyboardSensor.activators} reserves both `{" "/Space}` and
   * `{Enter}` keys for drag start/end and arrow keys for keyboard-driven drag
   * movements. This override allows for shift modifier + `{" "/Space}` or `{Enter}`
   * to register the {@link KeyboardSensorOptions.onActivation} callback (e.g.
   * for updating React state of currently selected items) before passing the
   * event to the original activator to handle keyboard accessible drag-and-drop
   * functionality.
   * @override
   */
  public static readonly activators = this._originalActivators.map(
    (activator) => {
      if (activator.eventName === 'onKeyDown') {
        return {
          eventName: 'onKeyDown' as const,
          handler: (
            event: SyntheticEvent<HTMLElement, KeyboardEvent>,
            options: KeyboardSensorOptions,
            context: { active: DraggableNode },
          ) => {
            const { nativeEvent } = event;
            const { onActivation } = options;

            const hasValidNormalKey =
              MultiSelectKeyboardSensor._onSelectNormalKey[nativeEvent.key];
            const hasShiftKey = nativeEvent.shiftKey;
            const hasValidShiftModKey =
              hasShiftKey &&
              MultiSelectKeyboardSensor._onSelectShiftKey[nativeEvent.key];

            if (hasValidNormalKey || hasValidShiftModKey) {
              event.preventDefault();
              onActivation(
                { event: nativeEvent },
                // @ts-expect-error -- passing additional args via custom sensor extended from `KeyboardSensor` class
                {
                  src: 'onKeyDownSelectItemWithoutActivation',
                  syntheticEvent: event,
                  options,
                  context,
                },
              );

              // prevent activating the original sensor to begin drag start
              return false;
            }

            return activator.handler(event, options, context);
          },
        };
      }

      return activator;
    },
  );
}
