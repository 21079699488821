import type { InitialState } from 'src/types/common.types';

const initialState: InitialState = {
  id: '',
  iconFile: null,
  iconSrc: '',
  name: '',
  isActive: true,
  blueprints: [],
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  type: 'webclip',
  supportsRules: true,

  general: {
    Label: '', //mandatory
    URL: '', //mandatory
    webClipImg: '',
    webClipImgS3Key: '',
    webClipImgUrl: '',
    IsRemovable: false,
    FullScreen: false,
    IgnoreManifestScope: false,
    TargetApplicationBundleIdentifier: '', //future
    Precomposed: false, //prevents apple from adding shine to the icon
  },
};

export default initialState;
