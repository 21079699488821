import { decodeEnum, encodeString, withDefault } from 'use-query-params';
import { constants } from '../../common';
import type {
  BehavioralThreatClassification,
  ThreatClassification,
} from '../../threat.types';

// istanbul ignore next
const ClassificationParamConfig = {
  encode: (
    str:
      | ThreatClassification
      | BehavioralThreatClassification
      | null
      | undefined,
  ) => encodeString(str),
  decode: (arrayStr: string | (string | null)[] | null | undefined) =>
    decodeEnum(arrayStr, [
      ...constants.THREAT_CLASSIFICATION_LIST,
      ...constants.THREAT_BEHAVIORAL_CLASSIFICATION_LIST,
    ]),
};

function createClassificationParam<T>(defaultValue: T | undefined) {
  return withDefault(ClassificationParamConfig, defaultValue);
}

export default createClassificationParam;
