import featureFlags from 'src/config/feature-flags';
import { MsAuthenticatorBanner } from '../additional-settings/ms-authenticator/ms-authenticator-banner';

type PageState = {
  isEditing: boolean;
};

type AdditionalProps = {
  pageState: PageState;
};

export function getExtraSummary(
  bundleId: string,
  additionalProps: AdditionalProps,
): JSX.Element {
  const { pageState } = additionalProps;

  const msdcMsAuthenticatorFF = featureFlags.getFlag(
    'splint-msdc-microsoft-authenticator-li',
  );

  const banners = {
    'com.microsoft.azureauthenticator':
      msdcMsAuthenticatorFF /* istanbul ignore next -- temp for feature flag */ ? (
        /* istanbul ignore next - implementation detail */
        <MsAuthenticatorBanner isEditing={pageState.isEditing} />
      ) : null,
  };

  return banners[bundleId];
}
