import { Body, Button, DialogV2 as Dialog, Flex } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';

export function ManualEnrollmentLeaveDialog({
  isOpen,
  toggleDialog,
  handleLeave,
}) {
  return (
    <Dialog
      isOpen={isOpen}
      title={i18n.t('Leave this page?')}
      onOpenChange={toggleDialog}
      css={{ width: 500 }}
      content={
        <Flex flow="column">
          <Body css={{ marginBottom: 20 }}>
            {i18n.t('Changes you made will not be saved.')}
          </Body>
          <Flex gap="sm" justifyContent="end" css={{ paddingTop: '$4' }}>
            <Button
              compact
              variant="subtle"
              onClick={/* istanbul ignore next */ () => toggleDialog(false)}
            >
              {i18n.t('Stay on page')}
            </Button>
            <Button variant="primary" compact onClick={handleLeave}>
              {i18n.t('Leave')}
            </Button>
          </Flex>
        </Flex>
      }
    />
  );
}
