/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query';
import { queryParamHistory } from 'src/app/_actions/parameters';

const useParamHistory = (
  parameterId: string,
  computerId: string,
  options = {
    displayType: 'LAST_STATE',
    ordering: '-run',
  },
  isEnabled = true,
) =>
  useQuery({
    queryKey: ['parameter-history', parameterId, computerId, options],
    queryFn: () =>
      queryParamHistory({
        parameterid: parameterId,
        computerid: computerId,
        ...options,
      }),
    enabled: isEnabled,
  });

export default useParamHistory;
