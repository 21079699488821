/* istanbul ignore file - legacy code, moved */
import { get } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { OldInputElement, useOldField } from 'src/old-honey-form';
import Icon from 'theme/components/atoms/Icon';
import { IPADataContext } from './IPADataContext';

export const IPATitle = ({ name }) => {
  const {
    summary,
    refInputTitle,
    isAppStoreApp,
    isAutoApp,
    isOperatingSystem,
    isErrorName, // it is not bool. it is string
    isReadOnly,
    setIsErrorName,
    validateName,
  } = useContext(IPADataContext);
  const { getValue, getError } = useOldField(name);
  const field = getValue();
  const individualError = getError();
  const error = individualError || isErrorName;
  const typeName = get(summary, 'name');

  useEffect(() => {
    if (validateName) {
      const err = validateName(field);
      setIsErrorName((prev) => (prev === err ? prev : err));
    } else if (!field && isErrorName) {
      setIsErrorName('');
    }
  }, [field]);

  return (
    <div
      className={`title title-${isReadOnly ? 'display' : 'edit'} title-${
        error ? 'error' : 'good'
      } b-pos-rel`}
    >
      <OldInputElement
        name={name}
        placeholder={typeName}
        disabled={isAppStoreApp || isAutoApp || isOperatingSystem || isReadOnly}
        ref={refInputTitle}
      />
      {error && (
        <div
          className="theme-input-required b-pos-abs"
          style={{
            bottom: '-27px',
            left: 0,
            fontSize: '11px',
          }}
        >
          <Icon
            style={{ marginRight: '5px', width: '12px', height: '18px' }}
            icon="error-circle"
          />
          <span>{error}</span>
        </div>
      )}
    </div>
  );
};
