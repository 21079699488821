import CertificateService from './service/certificate-service';

const initialState = {
  id: '',
  name: '',
  isActive: true,
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,

  supportsRules: true,

  installation: {
    type: CertificateService.installationTypes.INSTALL_ONCE,
  },

  selfService: {
    isEnabled: false,
    category: null,
    isRecommended: false,
    displayName: null,
    shortDescription: null,
    longDescription: null,
    iconUrl: null,
    iconFile: null,
    installBtnText: null,
    removeBtnText: '',
  },

  certificate: {
    type: null,
    name: '',
    subject: '',
    template: '',
    server: null,
    isSanSpecified: false,
    san: [CertificateService.createSan()],
    keySize: CertificateService.keySizes.S_1024,
    isAccessByAllApps: false,
    isKeychainExport: true,
    password: '',
    file: null,
  },
};

export default initialState;
