import { TextInput, useInputsValidators } from '@kandji-inc/bumblebee';
import React, { useEffect } from 'react';

import { Setting } from 'features/library-items/template';
import { i18n } from 'src/i18n';

const DisplayNameRow = ({ setting, update, isDisabled, isSubmitted }) => {
  const fieldsToValidate = ['displayName'];
  const { refs, onInvalidate } = useInputsValidators(fieldsToValidate, update);
  useEffect(() => {
    if (!setting.displayName) {
      onInvalidate(0)(true);
    }
  }, []);

  return (
    <Setting.Row>
      <div>
        <p className="b-txt">{i18n.t('Display Name')}</p>
      </div>
      <Setting.Helpers className="b-txt-light">
        {i18n.t(
          'Provide a display name that will be visible for users in the printer preferences.',
        )}
      </Setting.Helpers>
      <div ref={refs[0]}>
        <TextInput
          value={setting.displayName}
          onChange={(e) => update('displayName', e.target.value)}
          disabled={isDisabled}
          placeholder={i18n.t('Marketing_Printer')}
          compact
          onInvalidate={onInvalidate(0)}
          validator={(v) => [
            {
              message: i18n.t('Required'),
              invalid: () => !v,
              trigger: ['onBlur', isSubmitted && 'onSubmit'],
            },
          ]}
        />
      </div>
    </Setting.Row>
  );
};

export default DisplayNameRow;
