import { Flex } from '@kandji-inc/nectar-ui';
import { Suspense } from 'react';
import ThreatListLoader from './ThreatListLoader';

type ThreatListSuspenseProps = Readonly<{
  children: React.ReactNode;
}>;

const ThreatListSuspense = (props: ThreatListSuspenseProps) => {
  const { children } = props;
  return (
    <Suspense
      fallback={
        <Flex flex={1} hScreen css={{ position: 'absolute', inset: 0 }}>
          <ThreatListLoader label="Loading Threats" />
        </Flex>
      }
    >
      {children}
    </Suspense>
  );
};

export default ThreatListSuspense;
