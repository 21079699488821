import { useQuery } from '@tanstack/react-query';
import { pulseService } from 'src/features/visibility/pulse/data-service/pulse-service';

export const useJobMetadataQuery = (jobId: string) => {
  return useQuery({
    queryKey: ['pulse', jobId],
    queryFn: () => pulseService.getPulseById(jobId),
    enabled: !!jobId,
    refetchInterval: ({ state }) => {
      const metadata = state.data?.data?.[0];
      const status = metadata?.status;
      const totalTargeted = metadata?.total_devices_targeted ?? 0;
      const totalFinished = metadata?.total_devices_finished ?? 0;
      const finished = totalFinished === totalTargeted;
      return status === 'complete' || status === 'failed' || finished
        ? false
        : 2000;
    },
    select: (data) => data?.data?.[0],
    refetchOnWindowFocus: true,
  });
};
