import ActivityTabNew from 'app/components/ActivityTabNew';
import React from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';

const ActivityTab = ({ itemId }) => {
  const location = useLocation();
  const match = useRouteMatch();
  const history = useHistory();

  return (
    <ActivityTabNew
      libraryItemId={itemId}
      activityTabType="app"
      filters={['type', 'period']}
      location={location}
      match={match}
      history={history}
    />
  );
};

export default ActivityTab;
