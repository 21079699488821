import { Box, Text, styled } from '@kandji-inc/nectar-ui';
import { Cell, Pie, PieChart } from 'recharts';

const PieLabel = styled(Text, {
  fontSize: '$4',
  color: '$neutral90',
  textAlign: 'center',
  position: 'absolute',
  width: '100%',
});

const ThreatListPieChart = (props: { data: any; label: string }) => {
  const { data, label } = props;
  const radius = 70;
  const donutWidth = 7;

  const pieLabelCss = { top: radius - 8, fontWeight: '$medium' };

  return (
    <Box css={{ position: 'relative' }}>
      <PieChart
        width={140}
        height={140}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      >
        <Pie
          dataKey="value"
          data={data}
          cx={radius}
          cy={radius}
          startAngle={540}
          endAngle={180}
          innerRadius={radius - donutWidth * 2}
          outerRadius={radius}
          paddingAngle={1}
        >
          {data.map((item: { status: string; color: string }) => (
            <Cell key={`cell-${item.status}`} fill={item.color} />
          ))}
        </Pie>
      </PieChart>
      <PieLabel css={pieLabelCss}>{label}</PieLabel>
    </Box>
  );
};

export default ThreatListPieChart;
