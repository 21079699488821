import { array, object } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Box } from '@kandji-inc/nectar-ui';

import HoverTippy from 'features/util/components/hover-tippy/hover-tippy';

import { useGetDisplayValue } from '../../use-rule-service';

import { i18n } from 'src/i18n';
import { transformJsonLogicToTag } from './tag-transformers';

const TagListContainer = ({ children }) => (
  <Box
    p1
    mr2
    mb2
    css={{
      backgroundColor: 'var(--colors-neutral05)',
      color: 'var(--colors-neutral60',
    }}
  >
    {children}
  </Box>
);

const EmptyRuleTagList = () => {
  const text = <span className="ruletag-text">{i18n.t('No Rules')}</span>;

  return <TagListContainer>{text}</TagListContainer>;
};

const RuleTag = (props) => {
  const { rule, facetMap, installOnDeviceFamilies } = props;

  const [isApplicable, setIsApplicable] = useState(true);

  const [displayInput, setDisplayInput] = useState('');
  const [displayOperator, setDisplayOperator] = useState('');

  const { displayValue, getDisplayValue } = useGetDisplayValue();

  useEffect(() => {
    const { input, operator, value, jsonLogicOperator, facetData } =
      transformJsonLogicToTag(rule, facetMap);

    setDisplayInput(input);
    setDisplayOperator(operator);

    getDisplayValue(value, jsonLogicOperator, facetData);
  }, []);

  useEffect(() => {
    const { facetData } = transformJsonLogicToTag(rule, facetMap);

    const device_families = facetData?.device_families;

    // Only update `isApplicable` if `installOnDeviceFamilies` exists. Otherwise we assume the tag is applicable.
    if (installOnDeviceFamilies) {
      setIsApplicable(
        device_families?.some((family) =>
          installOnDeviceFamilies.includes(family),
        ),
      );
    }
  }, [installOnDeviceFamilies]);

  const text = (
    <span className="ruletag-text" data-testid="ruletag-text">
      {displayInput} <span className="operator">{displayOperator}</span>{' '}
      {displayValue}
    </span>
  );

  return (
    <HoverTippy
      className="ruletag-column"
      text={i18n.t(
        'This rule will no longer be applied based on the device platforms chosen.',
      )}
      maxWidth="none"
      disabled={isApplicable}
    >
      <TagListContainer>{text}</TagListContainer>
    </HoverTippy>
  );
};

RuleTag.propTypes = {
  rule: object.isRequired,
  facetMap: object.isRequired,
  installOnDeviceFamilies: array,
};

RuleTag.defaultProps = {
  installOnDeviceFamilies: null,
};

const RuleTagList = (props) => {
  const { rules, facetMap, installOnDeviceFamilies } = props;

  if (rules) {
    const ruleList = rules?.and;

    return ruleList?.map((rule) => {
      const key = JSON.stringify(rule);

      return (
        <RuleTag
          key={key}
          facetMap={facetMap}
          rule={rule}
          installOnDeviceFamilies={installOnDeviceFamilies}
        />
      );
    });
  }

  return null;
};

RuleTagList.propTypes = {
  facetMap: object.isRequired,
  rules: object,
  installOnDeviceFamilies: array,
};

RuleTagList.defaultProps = {
  rules: null,
  installOnDeviceFamilies: null,
};

export default RuleTagList;
export { EmptyRuleTagList, RuleTag };
