import { colors } from 'app/common/constants';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import uuidv4 from 'uuid/v4';

const Wrapper = styled.section`
  display: flex;
  position: relative;
  margin: ${(props) => props.margin}rem;
  align-items: ${(props) => props.checkboxPosition};
`;

const Input = styled.input`
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  -webkit-appearance: none;
  outline: none;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background-color: #fff;
  border: 2px solid ${colors['marengo-700']};
  &:checked {
    background-color: ${colors['marengo-700']};
    font-family: 'Font Awesome 5 Pro', sans-serif;
    font-weight: 700;
  }
  &:checked::after {
    position: absolute;
    left: 6px;
    top: 4px;
    font-size: 8px;
    font-weight: 900;
    content: '\\F00C';
    color: #fff;
    text-shadow: 0 -1px 2px ${colors['grey-500']};
  }
`;

const Label = styled.label`
  cursor: pointer;
  margin-left: 0.5em;
  margin-bottom: 0;
  color: ${(props) => colors[props.columnChooser ? 'grey-500' : 'grey-400']};
  font-size: ${(props) => (props.columnChooser ? '14px' : '1rem')};
  font-family: ${(props) =>
    props.columnChooser ? 'Atlas Grotesk Web' : '"Sharp Sans", sans-serif'};
  font-weight: 400;
`;

export const AwesomeCheckbox = ({
  keyId,
  label,
  checked,
  onCheck,
  margin,
  checkboxPosition,
  disabled,
  columnChooser,
  className,
}) => {
  const id = keyId || uuidv4();
  return (
    <Wrapper
      margin={margin}
      checkboxPosition={checkboxPosition}
      className={className}
    >
      <Input
        type="checkbox"
        disabled={disabled}
        id={id}
        checked={checked}
        onChange={onCheck}
        data-testid="checkbox"
      />
      <Label columnChooser={columnChooser} htmlFor={id}>
        {label}
      </Label>
    </Wrapper>
  );
};

AwesomeCheckbox.propTypes = {
  keyId: PropTypes.string,
  label: PropTypes.string.isRequired,
  onCheck: PropTypes.func,
  checked: PropTypes.bool,
  margin: PropTypes.number,
  checkboxPosition: PropTypes.string,
  columnChooser: PropTypes.bool,
  className: PropTypes.string,
};

AwesomeCheckbox.defaultProps = {
  keyId: '',
  checked: false,
  onCheck: null,
  margin: 1,
  checkboxPosition: 'center',
  columnChooser: false,
  className: '',
};

export default AwesomeCheckbox;
