import { useQuery } from '@tanstack/react-query';

import { homeScreenLayoutService } from './home-screen-layout-service';

export default function useHomeScreenLayoutApps() {
  const query = useQuery({
    queryKey: ['home-screen-layout', 'apps'],
    queryFn: () => homeScreenLayoutService.listApps(),
  });

  return query;
}
