import { InterfaceContext } from 'contexts/interface';
import PropTypes from 'prop-types';
import React, { useState, useLayoutEffect, useContext, useRef } from 'react';

// TODO: dynamically calculate and know when sticky is stickied/affixed
// TODO: ideally, move scroll logic into interface context file
// TODO: phase out .sticky-background in favor of having the children wrapped in their own background-color
// TODO: possibly combine .sticky-shadow and .sticky-background and be part of the children

const Sticky = ({
  label,
  origin,
  edge,
  sticky,
  offsetActive,
  offsetExtra,
  children,
}) => {
  const { bannerTopOffset } = useContext(InterfaceContext);
  const offset = bannerTopOffset + offsetExtra;

  const rootRef = useRef();
  const [isStuck, setIsStuck] = useState(false);
  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      ([e]) => setIsStuck(e.intersectionRatio < 1),
      { threshold: [1] },
    );
    observer.observe(rootRef.current);

    return () => observer.unobserve(rootRef.current);
  }, []);

  return (
    <>
      <div
        ref={rootRef}
        style={{ position: 'relative', top: -offset - offsetActive }}
      />

      <div
        className={`${label} sticky sticky-${
          sticky ? 'on' : 'off'
        } sticky-${origin} ${sticky && isStuck ? 'stuck' : 'free'}`}
        style={{ top: offset }}
      >
        <div className={`sticky-${edge}`}>
          <div className="sticky-background">{children}</div>
        </div>
      </div>
    </>
  );
};

Sticky.propTypes = {
  label: PropTypes.string,
  origin: PropTypes.string,
  edge: PropTypes.string,
  sticky: PropTypes.bool,
  offsetActive: PropTypes.number,
  offsetExtra: PropTypes.number,
  children: PropTypes.node.isRequired,
};

Sticky.defaultProps = {
  label: 'no-label',
  origin: 'default',
  edge: 'shadow',
  sticky: true,
  offsetActive: 0,
  offsetExtra: 0,
};

export default Sticky;
