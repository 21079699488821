import { Banner, Flex, Icon, Text, styled } from '@kandji-inc/nectar-ui';
import { useState } from 'react';
import { useMsdcConfigurationStatus } from './use-msdc-configuration-status';

interface Props {
  isEditing: boolean;
}

const MS_AUTH_MSDC_BANNER_STORAGE_KEY = 'ms-authenticator-li-msdc-banner';

const StyledCloseButton = styled(Icon, {
  position: 'absolute',
  top: '$3',
  right: '$3',
  cursor: 'pointer',
});

const StyledBanner = styled(Banner, {
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  py: '$3',
  mt: '$5',

  variants: {
    theme: {
      default: {
        backgroundColor: '$neutral10',
      },
    },
  },

  defaultVariants: {
    theme: 'default',
  },
});

export function MsAuthenticatorBanner(props: Props) {
  const { isEditing } = props;
  const { msdcConfigStatus } = useMsdcConfigurationStatus();
  const [isBannerClosed, setIsBannerClosed] = useState(() => {
    const savedBannerStatus = localStorage.getItem(
      MS_AUTH_MSDC_BANNER_STORAGE_KEY,
    );
    try {
      return !!JSON.parse(savedBannerStatus);
    } catch {
      return false;
    }
  });

  const isMsdcConfigured = msdcConfigStatus === 'configured';
  const isBannerVisibleForUnconfigured = isMsdcConfigured
    ? false
    : isEditing && !isBannerClosed;

  function handleClose() {
    const isClosed = true;
    localStorage.setItem(
      MS_AUTH_MSDC_BANNER_STORAGE_KEY,
      JSON.stringify(isClosed),
    );
    setIsBannerClosed(isClosed);
  }

  return (
    isBannerVisibleForUnconfigured && (
      <StyledBanner
        text={
          <Flex>
            <Text>
              Microsoft Device Compliance is a configured integration. Turn on
              the integration below to deploy device compliance.
            </Text>

            <StyledCloseButton
              name="fa-xmark-small"
              size={20}
              onClick={handleClose}
            />
          </Flex>
        }
      />
    )
  );
}
