/* istanbul ignore file */
import { Setting } from 'features/library-items/template';
import React from 'react';
import { i18n } from 'src/i18n';
import EthernetService from '../service/ethernet-service';
import AuthenticationTypeRow from './authentication-type-row';
import EapTypesRow from './eap-types-row';
import IdentityCertificateRow from './identity-certificate-row';

const keys = EthernetService.keys.authentication;

const ProtocolsCard = (props) => {
  const { setting } = props;

  const hasAuthentication = [
    EthernetService.protocols.TTLS,
    EthernetService.protocols.LEAP,
    EthernetService.protocols.PEAP,
    EthernetService.protocols.EAP_FAST,
  ].some((protocol) =>
    setting[keys.acceptedEapTypes.acceptedEapTypes].find(
      ({ value }) => protocol === value,
    ),
  );

  const isAd =
    hasAuthentication &&
    setting[keys.acceptedEapTypes.authentication] ===
      EthernetService.authentications.COMPUTER_AD;

  const isOd =
    hasAuthentication &&
    setting[keys.acceptedEapTypes.authentication] ===
      EthernetService.authentications.COMPUTER_OD;

  const showIdentityCertificate =
    [
      EthernetService.protocols.TLS,
      EthernetService.protocols.TTLS,
      EthernetService.protocols.PEAP,
      EthernetService.protocols.EAP_FAST,
    ].some((eap) =>
      setting[keys.acceptedEapTypes.acceptedEapTypes].find(
        ({ value }) => value === eap,
      ),
    ) &&
    !isAd &&
    !isOd;

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('Authentication')}</h3>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          {i18n.t(
            'Choose the authentication type(s) used to access this network',
          )}{' '}
          <a
            href="https://support.kandji.io/support/solutions/articles/72000632703"
            rel="noopener noreferrer"
            target="_blank"
            className="b-alink"
          >
            {i18n.t('Learn more...')}
          </a>
        </p>
      </Setting.SubHeader>
      <Setting.Rows>
        <AuthenticationTypeRow {...props} />

        <EapTypesRow {...props} />

        {showIdentityCertificate && <IdentityCertificateRow {...props} />}
      </Setting.Rows>
    </Setting.Card>
  );
};

export default React.memo(ProtocolsCard);
