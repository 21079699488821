import React from 'react';
import SingleConfiguration from './single-configuration';

const WifiV1Updated = (props) => {
  const { setting, update, isDisabled, validationDep } = props;

  return (
    <>
      {setting.configurations.map((config, idx) => (
        <SingleConfiguration
          key={config.id}
          config={config}
          update={update}
          updateConfig={(key, v, scope = 'general') =>
            update('configurations', (p) => {
              const currConfig = p[idx];
              currConfig[scope][key] =
                typeof v === 'function' ? v(currConfig[scope][key]) : v;
              return [...p];
            })
          }
          onDelete={() =>
            update('configurations', (p) => p.filter((_, i) => i !== idx))
          }
          canDelete={setting.configurations.length > 1}
          isDisabled={isDisabled}
          validationDep={validationDep}
        />
      ))}
    </>
  );
};

export default WifiV1Updated;
