/* istanbul ignore file */
import { useMutation } from '@tanstack/react-query';
import { api } from 'app/api/base';
import { URL_COMPUTERS } from 'app/api/urls';

const usePatchComputer = (id: string) => {
  const { ...rest } = useMutation({
    mutationKey: ['single-computer', id],
    mutationFn: (payload: any) => api(`${URL_COMPUTERS}${id}`).patch(payload),
  });

  return {
    ...rest,
  };
};

export default usePatchComputer;
