import { Icon } from '@kandji-inc/bumblebee';
import { colors } from 'app/common/constants';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  border-radius: ${(props) => props.size};
  background: ${(props) => props.background || colors['grey-150']};
  font-size: ${(props) => props.fontSize || '1.5rem'};
  color: ${(props) => props.color};
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  &:active {
    transform: scale(0.95, 0.95);
  }
`;

export const CircleButton = ({
  icon,
  onClick,
  isProcessing,
  name,
  fontSize,
  background,
  color,
  size,
  disabled,
  isNewFilterExist,
  className,
  innerRef,
}) => (
  <Wrapper
    disabled={disabled}
    onClick={onClick}
    fontSize={fontSize}
    background={background}
    color={color}
    size={size}
    isNewFilterExist={isNewFilterExist}
    className={className}
    ref={innerRef}
  >
    {name || <Icon size="sm" name={isProcessing ? 'arrows-rotate' : icon} />}
  </Wrapper>
);

CircleButton.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isProcessing: PropTypes.bool,
  name: PropTypes.string,
  fontSize: PropTypes.string,
  background: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  isNewFilterExist: PropTypes.bool,
  className: PropTypes.string,
  innerRef: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

CircleButton.defaultProps = {
  onClick: null,
  isProcessing: false,
  size: '36px',
  disabled: false,
  isNewFilterExist: false,
  className: '',
  innerRef: null,
};

export default CircleButton;
