const initialState = {
  id: '',
  name: '',
  isActive: true,
  blueprints: [],
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,

  menuBar: {
    showInputMenu: false,
    AdminHostInfo: false,
  },

  options: {
    SleepDisabled: false,
    RestartDisabled: false,
    ShutDownDisabled: false,
    DisableFDEAutoLogin: false,
    'com.apple.login.mcx.DisableAutoLoginClient': false,
    RetriesUntilHint: { checked: false, value: 0 },
  },

  loggedInUsers: {
    MultipleSessionEnabled: false,
    RestartDisabledWhileLoggedIn: false,
    ShutDownDisabledWhileLoggedIn: false,
    DisableScreenLockImmediate: false,
    LogOutDisabledWhileLoggedIn: false,
    'com.apple.autologout.AutoLogOutDelay': { checked: false, value: 3600 },
  },

  lockMessage: {
    invalidationsMap: {},
    LoginwindowText: {
      checked: false,
      value: '',
    },
  },

  userVisibility: {
    SHOWFULLNAME: { checked: false, value: true },
    HideAdminUsers: false,
    HideLocalUsers: false,
    SHOWOTHERUSERS_MANAGED: false,
    HiddenUsersList: { checked: false, value: '' },
  },

  supportsRules: true,

  autoLogin: {
    AutologinIsManaged: false,
    AutologinUsername: '',
    AutologinPassword: '',
    invalidationsMap: {},
  },
};

export default initialState;

/**
{
    "showInputMenu": true,
    "AdminHostInfo": true,
    "SHOWFULLNAME": {
        "checked": true,
        "value": false
    },
    "HideAdminUsers": true,
    "HideLocalUsers": true,
    "SHOWOTHERUSERS_MANAGED": true,
    "HiddenUsersList": {
        "checked": true,
        "value": "allan,brian,yoohoo"
    },
    "LoginwindowText": {
        "checked": true,
        "value": "lock message"
    },
    "SleepDisabled": true,
    "RestartDisabled": true,
    "ShutDownDisabled": true,
    "RetriesUntilHint": {
        "checked": true,
        "value": 6
    },
    "DisableFDEAutoLogin": true,
    "com.apple.login.mcx.DisableAutoLoginClient": true,
    "MultipleSessionEnabled": true,
    "RestartDisabledWhileLoggedIn": true,
    "ShutDownDisabledWhileLoggedIn": true,
    "DisableScreenLockImmediate": true,
    "LogOutDisabledWhileLoggedIn": true,
    "com.apple.autologout.AutoLogOutDelay": {
        "checked": true,
        "value": 3600
    }
}
 */
