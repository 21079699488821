import './kandji-setup.css';

import deepcopy from 'deepcopy';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';

import { Banner, Breadcrumb, Tabs } from '@kandji-inc/bumblebee';

import { LineLoader } from 'app/components/interface/LineLoader';
import HubSpotHandler from 'components/common/hubspot-handler';
import { useBlueprintConflicts } from 'features/library-items/common/blueprint-conflicts';
import { transformToSelectOptions } from 'features/library-items/data-service/blueprint/transformers';
import { useFindBlueprints } from 'features/library-items/data-service/blueprint/use-blueprint-service';
import { transformFromApi } from 'features/library-items/data-service/library-item/transformers';
import { useLibraryService } from 'features/library-items/data-service/library-item/use-library-service';
import { Whoops404 } from 'pages/404';

import { usePermissions } from 'contexts/account';

import DuplicateModal from 'features/library-items/common/library-item-page/duplicate-modal';
import {
  getDeleteQuickAction,
  getDuplicateQuickAction,
} from 'features/library-items/common/library-item-page/helpers';
import { useHandlers } from 'features/library-items/common/library-item-page/library-item-page.hooks';
import { i18n } from 'src/i18n';

import ViewActivity from 'components/library/ViewActivity';

import initialState from './initial-state';
import Settings from './settings/settings';
import Summary from './summary/summary';

import { KandjiSetupCtx, KandjiSetupProvider } from '../kandji-setup.context';
import PreLoadImages from './pre-load-images';
import { kandjiSetupService } from './service/kandji-setup-service';

const KandjiSetupPage = (props) => {
  const {
    model,
    setModel,
    blueprintOptions,
    beforeEditModel,
    setBeforeEditModel,
    blueprintConflicts,
  } = props;
  const { isAdding } = useContext(KandjiSetupCtx);

  const history = useHistory();
  const params = useParams();

  const permissions = usePermissions();

  const { onDuplicate } = useHandlers({
    model,
    history,
    service: kandjiSetupService,
    handlers: {
      onEdit: () => {},
      onSave: () => {},
      onRemove: () => {},
      onDelete: () => {},
      onCancel: () => {},
      onClose: () => {},
      onCloseModal: () => {},
    },
  });

  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  /* istanbul ignore next */
  const onDuplicateModalOpen = () => setIsDuplicateModalOpen(true);
  /* istanbul ignore next */
  const onDuplicateModalClose = () => setIsDuplicateModalOpen(false);
  /* istanbul ignore next */
  const onDuplicateModalConfirm = () => onDuplicate();

  /* istanbul ignore next */
  const onDeleteModalOpen = () => setIsDeleteModalOpen(true);
  /* istanbul ignore next */
  const onDeleteModalClose = () => setIsDeleteModalOpen(false);

  /* istanbul ignore next - feature flag */
  const quickActions = [
    ...getDuplicateQuickAction(onDuplicateModalOpen, permissions),
    ...getDeleteQuickAction(onDeleteModalOpen, permissions),
  ];

  const initialStateCopy = deepcopy(initialState());

  return (
    <div className="k-library k-library--kandji-setup">
      <HubSpotHandler />
      <PreLoadImages />
      <div style={{ margin: '31px 0 23px' }}>
        <Breadcrumb
          crumbs={[
            { title: i18n.t('LIBRARY'), url: '/library' },
            { title: model.name || i18n.t('Liftoff') },
          ]}
        />
      </div>
      {!model.isActive && (
        <div>
          <Banner theme="info-strong" kind="block" className="b-mb">
            <span>
              {i18n.t(`This Library Item is inactive. Settings configured for this
              Library Item will not be deployed to devices within the assigned
              Blueprints while inactive.`)}
            </span>
          </Banner>
        </div>
      )}
      <Summary
        model={model}
        setModel={setModel}
        blueprintOptions={blueprintOptions?.data}
        blueprintConflicts={blueprintConflicts}
        quickActions={quickActions}
      />

      <Tabs
        className="fe-library-tabs"
        style={{ margin: 'var(--b-gap3) 0 var(--b-gap3)' }}
        tabs={[
          { label: i18n.t('Settings') },
          ...(!isAdding
            ? [{ label: i18n.t('Activity'), route: 'activity' }]
            : []),
        ]}
      >
        <Settings
          tabid={i18n.t('Settings')}
          model={model}
          setModel={setModel}
          initialStateCopy={initialStateCopy}
          beforeEditModel={beforeEditModel}
          setBeforeEditModel={setBeforeEditModel}
          isLoadingBpOptions={!blueprintOptions}
          blueprintConflicts={blueprintConflicts}
          isDeleteModalOpen={isDeleteModalOpen}
          onDeleteModalOpen={onDeleteModalOpen}
          onDeleteModalClose={onDeleteModalClose}
        />
        {!isAdding && (
          <ViewActivity
            noWrapper
            tabid={i18n.t('Activity')}
            itemId={params.item}
            context={{}}
          />
        )}
      </Tabs>

      <DuplicateModal
        isOpen={isDuplicateModalOpen}
        onClose={onDuplicateModalClose}
        onConfirm={onDuplicateModalConfirm}
      />
    </div>
  );
};

const KandjiSetup = () => {
  const params = useParams();
  const itemId = params.item;
  const [model, setModel] = useState(deepcopy(initialState()));
  const [beforeEditModel, setBeforeEditModel] = useState(deepcopy(model));
  const [err, resp] = useLibraryService(
    itemId,
    transformFromApi,
    [],
    kandjiSetupService,
  );
  const blueprintConflicts = useBlueprintConflicts();

  const [bpErr, blueprintOptions] = useFindBlueprints(transformToSelectOptions);
  const { state } = useLocation();
  const [previousPage, setPreviousPage] = useState();

  /* on mount, scroll to top */
  useEffect(() => {
    document.body.scrollIntoView();
    if (state?.previousPage) {
      setPreviousPage(state.previousPage);
    }
  }, []);

  /* set the ui modal from api */
  useEffect(() => {
    if (resp && resp.data) {
      setModel(resp.data.data);
    }
  }, [resp]);

  if (err && itemId) {
    return <Whoops404 />;
  }

  if (itemId && !resp) {
    return <LineLoader />;
  }

  return (
    <KandjiSetupProvider
      itemId={itemId}
      itemType={params.type}
      previousPage={previousPage}
    >
      <KandjiSetupPage
        model={model}
        setModel={setModel}
        blueprintOptions={blueprintOptions}
        beforeEditModel={beforeEditModel}
        setBeforeEditModel={setBeforeEditModel}
        blueprintConflicts={blueprintConflicts}
      />
    </KandjiSetupProvider>
  );
};

export default KandjiSetup;
