import { Banner, Flex, Icon, Text, styled } from '@kandji-inc/nectar-ui';
import { Setting } from 'features/library-items/template';
import { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { INT_TYPES } from 'src/features/integrations/constants';
import { LibraryItemContext } from 'src/features/library-items/common/library-item-page/library-item-page';
import {
  type DialogStatus,
  MsAuthenticatorDialog,
} from './ms-authenticator-dialog';
import { useMsdcConfigurationStatus } from './use-msdc-configuration-status';

interface Props {
  setting: {
    isEnabled: boolean;
  };
  update: (key: string, value: unknown) => void;
  isDisabled: boolean;
  isEditing: boolean;
}

const StyledSettingCardFooter = styled(Flex);

const StyledSettingCard = styled(Setting.Card, {
  // for overriding specificity of library item setting card styles
  '.b-library__setting-card__subheader.b-library__setting-card__subheader': {
    boxShadow: 'none',
  },
  [`&.b-library__setting-card > *:last-child${StyledSettingCardFooter}`]: {
    mt: '$2',
    px: '$5',
    py: 18,
  },
});

const StyledBanner = styled(Banner, {
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  py: '$3',
  mb: 10,

  variants: {
    theme: {
      default: {
        backgroundColor: '$neutral10',
      },
    },
  },

  defaultVariants: {
    theme: 'default',
  },
});

export function MsAuthenticatorSettingCard(props: Props) {
  const { setting, update, isDisabled, isEditing } = props;
  const { isEnabled = false } = setting;

  const history = useHistory();
  const { onSave } = useContext(LibraryItemContext);
  const { msdcConfigStatus } = useMsdcConfigurationStatus();
  const [dialogStatus, setDialogStatus] = useState<DialogStatus>('allClosed');

  const isMsdcConfigured = msdcConfigStatus === 'configured';
  const isHeaderToggleEnabled = isEnabled || false;
  const isHeaderToggleDisabled = isDisabled || !isMsdcConfigured;

  function onHeaderToggleChange({
    isEnabled: prevToggleState,
  }: {
    isEnabled: boolean;
  }) {
    const showTurnOffMsdcDialog = prevToggleState === true && isMsdcConfigured;
    if (showTurnOffMsdcDialog) {
      return setDialogStatus('turnOffMsdcOpen');
    }
    handleToggleChange(!prevToggleState);
  }

  function navToMsdcPage() {
    history.push(`/integrations/${INT_TYPES.msDeviceCompliance}`);
  }

  function onViewMsdcIntegrationPage() {
    if (isEditing) {
      return setDialogStatus('navToMsdcPageOpen');
    }
    navToMsdcPage();
  }

  function saveAndNavToMsdcPage(): Promise<() => void> {
    return new Promise((resolve, reject) =>
      onSave()
        .then(() => resolve(navToMsdcPage))
        .catch((err: Error | undefined) => reject(err)),
    );
  }

  function handleToggleChange(nextToggleState: boolean) {
    update('isEnabled', nextToggleState);
  }

  return (
    <>
      <StyledSettingCard>
        <Setting.Header>
          <h3 className="b-h3">Microsoft Device Compliance</h3>

          <Setting.HeaderToggle
            isEnabled={isHeaderToggleEnabled}
            onChange={onHeaderToggleChange}
            isDisabled={isHeaderToggleDisabled}
            chipText={{
              enabled: 'On',
              disabled: 'Off',
            }}
          />
        </Setting.Header>

        <Setting.SubHeader>
          {!isMsdcConfigured && (
            <StyledBanner
              text={
                <>
                  <Text
                    css={{
                      a: {
                        color: '$blue50',
                      },
                    }}
                  >
                    Microsoft Device Compliance cannot be turned on until the
                    integration has been configured. {' '}
                    <Link to="/integrations/marketplace">
                      Go to Integration marketplace.
                    </Link>
                  </Text>
                </>
              }
            />
          )}

          <Text
            className="b-txt"
            css={{
              a: {
                color: '$blue50',
              },
            }}
          >
            Turning on this integration allows you to manage device compliance
            and ensures a device is compliant before allowing access to company
            resources.{' '}
            <a
              // TODO: correct support link
              href=""
              rel="noopener noreferrer"
              target="_blank"
              className="b-alink"
            >
              Learn more
            </a>
          </Text>
        </Setting.SubHeader>

        {isMsdcConfigured && (
          <Flex justifyContent="end">
            <Text
              as="button"
              onClick={onViewMsdcIntegrationPage}
              css={{
                color: '$neutral70',
                fontWeight: '$medium',
                lineHeight: '$2',
              }}
            >
              View Microsoft Device Compliance integration{' '}
              <Icon name="arrow-right" size={20} />
            </Text>
          </Flex>
        )}
      </StyledSettingCard>

      <MsAuthenticatorDialog
        dialogStatus={dialogStatus}
        setDialogStatus={setDialogStatus}
        handleToggleChange={handleToggleChange}
        saveAndNavToMsdcPage={saveAndNavToMsdcPage}
      />
    </>
  );
}
