import type { IconNames } from '@kandji-inc/nectar-ui';
import {
  Breadcrumbs,
  BreadcrumbsItem,
  Flex,
  Icon,
} from '@kandji-inc/nectar-ui';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { links } from 'src/app/common/constants';
import { NameDescription } from 'src/features/visibility/pulse/components';

type ReportType =
  | 'file_check'
  | 'processes'
  | 'logged_in_users'
  | 'preferences';

export const getReportIconName = (reportType: ReportType): IconNames => {
  switch (reportType) {
    case 'file_check':
      return 'list-search';
    case 'processes':
      return 'wave-pulse';
    case 'logged_in_users':
      return 'user-arrow-right';
    case 'preferences':
      return 'settings-gear';
    default:
      return 'list-search';
  }
};

export const Header = ({
  reportType,
  reportName,
  setReportName,
  reportNameError,
  setReportNameError,
  reportDescription,
  setReportDescription,
}: {
  reportType: ReportType;
  reportName: string;
  setReportName: (value: string) => void;
  reportNameError: string;
  setReportNameError: (value: string) => void;
  reportDescription: string;
  setReportDescription: (value: string) => void;
}) => {
  const [isAddingDescription, setIsAddingDescription] = useState(false);

  return (
    <Flex flow="column" gap="md">
      <Breadcrumbs>
        <BreadcrumbsItem
          item={{ title: 'Pulse' }}
          itemRender={({ title }) => <Link to={links.pulse}>{title}</Link>}
        />
        <BreadcrumbsItem
          item={{ title: 'Select Pulse report' }}
          itemRender={({ title }) => (
            <Link to={`${links.pulse}/new`}>{title}</Link>
          )}
        />
        <BreadcrumbsItem item={{ title: 'Create Pulse Report' }} />
      </Breadcrumbs>
      <Flex gap="md">
        <Flex
          css={{
            backgroundColor: '$blue10',
            minWidth: 48,
            minHeight: 48,
            maxHeight: 48,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 24,
          }}
        >
          <Icon
            size={32}
            name={getReportIconName(reportType)}
            color="#106AF2"
          />
        </Flex>
        <NameDescription
          reportName={reportName}
          setReportName={setReportName}
          reportNameError={reportNameError}
          setReportNameError={setReportNameError}
          reportDescription={reportDescription}
          setReportDescription={setReportDescription}
          isAddingDescription={isAddingDescription}
          setIsAddingDescription={setIsAddingDescription}
        />
      </Flex>
    </Flex>
  );
};
