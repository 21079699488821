import ThreatListSquareButton from './ThreatListSquareButton';

type ThreatListExportButtonProps = {
  disabled?: boolean;
  onClick: () => void;
};

const ThreatListExportButton = ({
  onClick,
  disabled = false,
}: ThreatListExportButtonProps) => (
  <ThreatListSquareButton
    tooltip="Export CSV"
    icon="file-arrow-down"
    onClick={onClick}
    disabled={disabled}
    testId="threat-export-button"
  />
);

export default ThreatListExportButton;
