import React from 'react';

export const SyncIcon = (
  <svg
    width="13"
    height="17"
    viewBox="0 0 13 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.18182 2.31818V0L3.09091 3.09091L6.18182 6.18182V3.86364C8.73182 3.86364 10.8182 5.95 10.8182 8.5C10.8182 9.27273 10.5864 10.0455 10.2773 10.6636L11.4364 11.8227C11.9773 10.8182 12.3636 9.73636 12.3636 8.5C12.3636 5.1 9.58182 2.31818 6.18182 2.31818ZM6.18182 13.1364C3.63182 13.1364 1.54545 11.05 1.54545 8.5C1.54545 7.72727 1.77727 6.95455 2.08636 6.33636L0.927273 5.17727C0.386364 6.18182 0 7.26364 0 8.5C0 11.9 2.78182 14.6818 6.18182 14.6818V17L9.27273 13.9091L6.18182 10.8182V13.1364Z"
      fill="#1A1D25"
    />
  </svg>
);

export const CloseIcon = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.06127 6.00087L11.7801 1.28202C12.0733 0.989097 12.0733 0.513809 11.7801 0.220882C11.4869 -0.0722948 11.0122 -0.0722948 10.719 0.220882L6.00012 4.93973L1.28102 0.220882C0.987847 -0.0722948 0.51306 -0.0722948 0.219883 0.220882C-0.0732943 0.513809 -0.0732943 0.989097 0.219883 1.28202L4.93898 6.00087L0.219883 10.7197C-0.0732943 11.0127 -0.0732943 11.4879 0.219883 11.7809C0.366471 11.9272 0.558587 12.0005 0.750453 12.0005C0.942319 12.0005 1.13444 11.9272 1.28102 11.7806L6.00012 7.06177L10.719 11.7806C10.8656 11.9272 11.0577 12.0005 11.2495 12.0005C11.4414 12.0005 11.6335 11.9272 11.7801 11.7806C12.0733 11.4877 12.0733 11.0124 11.7801 10.7195L7.06127 6.00087Z"
      fill="#1A1D25"
    />
  </svg>
);
