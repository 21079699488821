// istanbul ignore file
import * as React from 'react';
import type { ImperativePanelHandle, PanelProps } from 'react-resizable-panels';

import {
  ResizablePanel,
  ResizablePanelGroup,
} from '../../move-to-nectar/resizable';

export const PanelGroup = ({
  canResize,
  groupRef,
  children,
}: {
  canResize: boolean;
  groupRef?: any;
  children: any;
}) => {
  return canResize ? (
    <ResizablePanelGroup direction="horizontal" ref={groupRef}>
      {children}
    </ResizablePanelGroup>
  ) : (
    <>{children}</>
  );
};

export const Panel = React.forwardRef<
  ImperativePanelHandle,
  PanelProps & {
    canResize: boolean;
  }
>(({ canResize, ...props }, ref) => {
  const {
    defaultSize,
    maxSize,
    minSize,
    collapsible,
    collapsedSize,
    onExpand,
    onCollapse,
    onResize,
    children,
  } = props;
  return canResize ? (
    <ResizablePanel
      defaultSize={defaultSize}
      maxSize={maxSize}
      minSize={minSize}
      collapsible={collapsible}
      collapsedSize={collapsedSize}
      onExpand={onExpand}
      onCollapse={onCollapse}
      onResize={onResize}
      ref={ref}
    >
      {children}
    </ResizablePanel>
  ) : (
    <>{children}</>
  );
});
