import { formatDeviceOptions } from '../../data-service/library-item/devices';
import { deviceTypes } from '../../library/common';
import type { LockScreenMessage } from './lock-screen-message.types';

const initialState: LockScreenMessage = {
  id: '',
  iconFile: null,
  iconSrc: '',
  name: '',
  isActive: true,
  blueprints: [],
  selectedBlueprints: [],
  excludedBlueprints: [],
  is_all_blueprints: false,
  type: 'lockscreenmessage',
  supportsRules: true,
  LockScreenFootnote: { LockScreenFootnote: '' },
  devices: formatDeviceOptions([deviceTypes.IPAD, deviceTypes.IPHONE]),
};

export default initialState;
