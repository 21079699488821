import { createColumnHelper } from '@tanstack/react-table';
import type { z } from 'zod';

import { getIconName } from '../../components/PrismNav/prismNavUtils';
import { DateCell } from '../column-utils';

export const getScharedColumns = (schema, ffCrossCategoryViews) => {
  const { properties } = schema.schema;
  const sharedColumnHelper = createColumnHelper<z.infer<typeof properties>>();

  return [
    sharedColumnHelper.accessor((row) => row[`${schema.uri}.created_at`], {
      id: `${schema.uri}.created_at`,
      cell: DateCell,
      meta: {
        displayName: properties.created_at.title,
        displayIcon:
          ffCrossCategoryViews === false ? undefined : getIconName(schema.uri),
        filterType: 'date-time',
        filterIcon: 'calendar',
        deviceFamily: ['all'],
      },
    }),

    sharedColumnHelper.accessor((row) => row[`${schema.uri}.updated_at`], {
      id: `${schema.uri}.updated_at`,
      cell: DateCell,
      meta: {
        displayName: properties.updated_at.title,
        displayIcon:
          ffCrossCategoryViews === false ? undefined : getIconName(schema.uri),
        filterType: 'date-time',
        filterIcon: 'calendar',
        deviceFamily: ['all'],
      },
    }),

    sharedColumnHelper.accessor((row) => row[`${schema.uri}.last_changed_at`], {
      id: `${schema.uri}.last_changed_at`,
      cell: DateCell,
      meta: {
        displayName: properties.last_changed_at.title,
        displayIcon:
          ffCrossCategoryViews === false ? undefined : getIconName(schema.uri),
        filterType: 'date-time',
        filterIcon: 'calendar',
        deviceFamily: ['all'],
      },
    }),

    sharedColumnHelper.accessor(
      (row) => row[`${schema.uri}.last_collected_at`],
      {
        id: `${schema.uri}.last_collected_at`,
        cell: DateCell,
        meta: {
          displayName: properties.last_collected_at.title,
          displayIcon:
            ffCrossCategoryViews === false
              ? undefined
              : getIconName(schema.uri),
          filterType: 'date-time',
          filterIcon: 'calendar',
          deviceFamily: ['all'],
        },
      },
    ),
  ];
};
