/* istanbul ignore file */
import { v4 } from 'uuid';
import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class DeviceNameService extends NewLibraryItemService {
  /** Hostname cannot have hyphens. */
  static convertDeviceNameToHostName(
    deviceName: Array<{ id: string; isCustom: boolean; value: string }>,
  ) {
    return deviceName.map(({ value, isCustom }) => {
      const valueToUse = isCustom
        ? value.replaceAll(' ', '-').replace(/[^a-zA-Z0-9-]/g, '')
        : value;
      return {
        id: v4(), // Give the hostname a new id to avoid collisions
        isCustom,
        value: valueToUse,
      };
    });
  }
}

export const deviceNameService = new DeviceNameService();

export default DeviceNameService;
