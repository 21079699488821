import type { CSS } from '@kandji-inc/nectar-ui';
import { useMemo } from 'react';
import type { OnApply, OnClear } from 'src/features/edr/common/common.types';
import constants from '../../../common/constants';
import type {
  BehavioralThreatStatus,
  ThreatStatus,
} from '../../../threat.types';
import MultiselectFilter from './MultiselectFilter';

type StatusFiltersProps = {
  values: ThreatStatus[] | BehavioralThreatStatus[] | undefined;
  onChange: OnApply;
  onClear: OnClear;
  list: ThreatStatus[] | BehavioralThreatStatus[];
  label?: string;
  optionCss?: CSS;
};

const THREAT_STATUS_LIST_ORDER = {
  [constants.THREAT_STATUS.NOT_QUARANTINED]: 0,
  [constants.THREAT_STATUS.QUARANTINED]: 1,
  [constants.THREAT_STATUS.RESOLVED]: 2,
  [constants.THREAT_STATUS.RELEASED]: 3,
  [constants.THREAT_BEHAVIORAL_STATUS.DETECTED]: 0,
  [constants.THREAT_BEHAVIORAL_STATUS.BLOCKED]: 1,
  [constants.THREAT_BEHAVIORAL_STATUS.BLOCKED_PARENT]: 2,
  [constants.THREAT_BEHAVIORAL_STATUS.INFORMATIONAL]: 3,
} as const;

const THREAT_STATUS_LIST_COLOR = {
  [constants.THREAT_STATUS.NOT_QUARANTINED]: '$red50',
  [constants.THREAT_STATUS.QUARANTINED]: '$blue50',
  [constants.THREAT_STATUS.RESOLVED]: '$green50',
  [constants.THREAT_STATUS.RELEASED]: '$neutral70',
  [constants.THREAT_BEHAVIORAL_STATUS.DETECTED]: '$yellow50',
  [constants.THREAT_BEHAVIORAL_STATUS.BLOCKED]: '$blue50',
  [constants.THREAT_BEHAVIORAL_STATUS.BLOCKED_PARENT]: '$blue50',
  [constants.THREAT_BEHAVIORAL_STATUS.INFORMATIONAL]: '$neutral70',
} as const;

const StatusFilter = (props: StatusFiltersProps) => {
  const {
    values: newValues,
    onChange,
    onClear,
    list,
    label = 'Threat status',
  } = props;

  const options = useMemo(
    () =>
      list
        .map((value) => ({
          key: value,
          value,
          label: constants.THREAT_STATUS_DISPLAY_NAME_MAP[value],
          icon: constants.THREAT_STATUS_ICON_MAP[value],
          order: THREAT_STATUS_LIST_ORDER[value],
        }))
        .sort((a, b) => a.order - b.order),
    [list],
  );

  const colorStyles = useMemo(() => {
    return list.reduce((styles, value) => {
      const index = THREAT_STATUS_LIST_ORDER[value];
      const colorKey = THREAT_STATUS_LIST_COLOR[value];
      styles[`&:nth-child(${index + 1}) [role=label] svg`] = {
        color: colorKey,
      };
      return styles;
    }, {});
  }, [list]);

  return (
    <MultiselectFilter
      name="status"
      label={label}
      values={newValues}
      onChange={onChange}
      onClear={onClear}
      options={options}
      maxWidth={280}
      optionCss={{
        '& > div': { gap: '$2' },
        '& > div div': { gap: 6 },
        '&[data-disabled] [role=label] svg': { opacity: 0.5 },
        ...colorStyles,
      }}
    />
  );
};

export default StatusFilter;
