import svgs from 'src/features/library-items/library/library-item-configurations/icons';
import IPAFileIcon from '../assets/ipa-file-icon.webp';

export function getIpaUploaderIcon(
  bundleId: string | null | undefined,
  icon: string,
) {
  const hasFile = bundleId != null;
  const hasIcon = icon != null && icon.length > 0;

  if (!hasFile) {
    return IPAFileIcon;
  }

  if (hasIcon) {
    return icon;
  }

  return svgs['IpaApp'];
}
