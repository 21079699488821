import { styled } from '@kandji-inc/nectar-ui';
import * as Popover from '@radix-ui/react-popover';

const PopoverContent = styled(Popover.Content, {
  marginTop: 1,
  height: 'min-content',
  background: '$neutral0',
  filter: 'drop-shadow($elevation2)',
  borderRadius: '$rounded',
  zIndex: 2,
  paddingInline: '$4',
  paddingBlock: '$3',
  cursor: 'unset',
});

const EDRPopover = (props: {
  children: React.ReactNode;
  trigger: React.ReactNode;
  isOpen: boolean;
  onInteractOutside?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
}) => {
  const {
    children,
    trigger,
    isOpen,
    onInteractOutside,
    onMouseEnter,
    onMouseLeave,
    onClick,
  } = props;

  return (
    <Popover.Root open={isOpen}>
      <Popover.Anchor
        style={{ width: 'fit-content' }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
      >
        {trigger}
      </Popover.Anchor>
      <PopoverContent
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={/* istanbul ignore next */ (e) => e.stopPropagation()}
        onInteractOutside={
          // istanbul ignore next
          typeof onInteractOutside === 'function'
            ? onInteractOutside
            : // istanbul ignore next
              () => null
        }
        css={{ '&:focus-visible': { outline: 'none' } }}
      >
        {children}
        <Popover.Arrow
          width={12}
          height={8}
          style={{ fill: 'var(--colors-neutral0)' }}
        />
      </PopoverContent>
    </Popover.Root>
  );
};

export default EDRPopover;
