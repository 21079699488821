import React from 'react';

import { startCase } from 'lodash';

import {
  Banner,
  Button,
  Chip,
  Flex,
  Icon,
  modal as Modal,
  Radio,
  setClass,
} from '@kandji-inc/bumblebee';
import { Box, Icon as NectarIcon } from '@kandji-inc/nectar-ui';
import { useFlags } from 'src/config/feature-flags';
import { i18n } from 'src/i18n';
import { singularizeName } from '../../library/common';

type Props = {
  blueprintConflicts: any;
  blueprints: any;
  isAllBlueprints: boolean;
  itemId: string;
  itemName: string;
  itemTemplate: string;
  itemInstanceName: string;
  itemTypeName: string;
};

function BlueprintConflictsModal(props: Props) {
  const {
    blueprintConflicts,
    blueprints,
    isAllBlueprints: isThisLibraryItemAllBlueprints,
    itemId,
    itemName,
    itemTemplate,
    itemInstanceName,
    itemTypeName,
  } = props;

  const { conflictsState, finishBpConflicts, selectBpConflict } =
    blueprintConflicts;

  const {
    showModal,
    conflicts,
    itemSelections,
    resolved,
    type,
    setBlueprints,
    allBlueprintConflict,
  } = conflictsState;

  const typeMap = {
    'kandji-setup': i18n.t('Liftoff Item'),
    'dep-profile': i18n.t('Automated Device Enrollment Configuration'),
    'macos-release': i18n.t('managed OS version'),
  };

  const itemDescription =
    typeMap[type] ||
    (itemTemplate && startCase(itemTemplate)) ||
    (itemTypeName && singularizeName(itemTypeName.toLowerCase())) ||
    i18n.t('item of this type');

  const currItemId = itemId || 'new-item-id';
  const currItemName = itemName || 'New Liftoff Item';

  const isOpen = showModal;

  // This assumes that there can only ever be one Library Item of a type that can
  // be set to "All Blueprints" at a time.
  const hasAllBlueprintsConflictedLibraryItem = conflicts.find(
    ({ is_all_blueprints }) => is_all_blueprints,
  );

  const allBlueprintsConflictedLibraryItemName =
    hasAllBlueprintsConflictedLibraryItem?.conflicted_item_name;

  const onClose = () => finishBpConflicts(false);

  /* istanbul ignore next */
  const handleOnSave = () => {
    const conflictsValues = conflicts.map(({ blueprint_id }) => blueprint_id);
    const nonConflictValues = blueprints.filter(
      ({ value }) => !conflictsValues.includes(value),
    );

    setBlueprints([...nonConflictValues, ...resolved]);
    finishBpConflicts(true, [...nonConflictValues, ...resolved]);
  };

  const allBlueprintsIcon = (
    <span className="b-library-summary__all-blueprints">
      <Chip kind="info" iconLeft="kandji-blueprint" />
    </span>
  );

  const getLabel = (
    name,
    isThisItem,
    instanceName,
    isCurrentlyAssignedLibraryItemAllBlueprints,
  ) => {
    // Determines whether to show the "All Blueprints" icon or not
    const showAllBlueprintsIcon = () => {
      // Show All Blueprints icon on "this Library Item"
      if (isThisItem && isThisLibraryItemAllBlueprints) {
        return allBlueprintsIcon;
      }
      if (
        // Show All Blueprints icon on "currently assigned Library Item" if the conflict is not between two All Blueprints Library Items
        !allBlueprintConflict &&
        !isThisItem &&
        isCurrentlyAssignedLibraryItemAllBlueprints
      ) {
        return allBlueprintsIcon;
      }

      // Render nothing if the option does not involve a Library Item that is already set or is being set
      // to "All Blueprints".
      return null;
    };

    return (
      <div className="b-flex-col k-library__blueprint-conflicts-item">
        <div className="b-flex">
          {showAllBlueprintsIcon()}

          <p className="b-txt">
            {name}{' '}
            {isThisItem
              ? '(this Library Item)'
              : '(currently assigned Library Item)'}
          </p>
        </div>

        {instanceName && (
          <div>
            <p
              className="b-txt-light k-library__blueprint-conflicts-instance-name"
              data-testid="conflict-instance-name"
            >
              {instanceName}
            </p>
          </div>
        )}
      </div>
    );
  };

  const parts = {
    header: {
      children: (
        <>
          <div className="k-modal-header">
            <Flex align="center" className="b-mb">
              <Icon
                name="circle-xmark"
                className="k-library__icon--warning b-h2"
              />

              <h2>{i18n.t('Classic Blueprint with conflicts')}</h2>
            </Flex>

            <div className="b-txt">
              {i18n.t(
                'Only one {itemDescription} can be assigned to a Classic Blueprint at a time.',
                { itemDescription },
              )}
            </div>

            <Banner
              className="b-mt1"
              icon="circle-info"
              kind="block"
              theme="info"
            >
              <p>
                {i18n.t('Consider using an')}{' '}
                <a
                  href="https://support.kandji.io/support/solutions/articles/72000627627"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="b-alink"
                  style={{ display: 'inline-flex', alignItems: 'center' }}
                >
                  {i18n.t('Assignment Map')}{' '}
                  <Box
                    as="span"
                    css={{
                      '& svg': { width: '14px', height: '14px' },
                      paddingBottom: '2px',
                    }}
                  >
                    <NectarIcon name="arrow-up-right-from-square" />
                  </Box>
                </a>{' '}
                {i18n.t('to be able to assign multiple.')}
              </p>
            </Banner>

            {/* Informational banner for when All Blueprints is being turned ON. */}
            {isThisLibraryItemAllBlueprints && conflicts.length > 0 && (
              <Banner
                className="k-library__blueprint-conflicts-modal-banner b-mt1"
                icon="circle-info"
                kind="slim"
                theme="info-strong"
              >
                <p>
                  {i18n.t(`Because you're turning on the All Classic Blueprints setting
                  for this Library Item, confirm you would like to replace the
                  below assignments with this Library Item.`)}
                </p>
              </Banner>
            )}

            {/* Informational banner for when All Blueprints is being turned ON and there is a 
          conflict with a self-conflicting LI that already has All Blueprints ON. */}
            {allBlueprintConflict && (
              <Banner
                className="k-library__blueprint-conflicts-modal-banner b-mt1"
                icon="circle-info"
                kind="slim"
                theme="info-strong"
              >
                <p>
                  {i18n.t(
                    'Turning on the All Classic Blueprints setting for this Library Item will turn off the setting from {conflictedItemName} Library Item.',
                    {
                      conflictedItemName:
                        allBlueprintConflict.conflicted_item_name,
                    },
                  )}
                </p>
              </Banner>
            )}

            {/* Informational banner for when All Blueprints is not being modified but 
          there is a conflict with a LI that has All Blueprints turned ON. */}
            {hasAllBlueprintsConflictedLibraryItem &&
              !isThisLibraryItemAllBlueprints && (
                <Banner
                  className="k-library__blueprint-conflicts-modal-banner b-mt"
                  icon="circle-info"
                  kind="slim"
                  theme="info-strong"
                >
                  <p>
                    {i18n.t(
                      '{allBlueprintsConflictedLibraryItemName} is set to All Classic Blueprints. If you choose to assign this Library Item to the below Blueprint(s), those Blueprints will be added to the exclusion list of {allBlueprintsConflictedLibraryItemName}.',
                      { allBlueprintsConflictedLibraryItemName },
                    )}
                  </p>
                </Banner>
              )}
          </div>
        </>
      ),
    },
    content: {
      children: (
        <div className="k-library__blueprint-conflicts-modal">
          {conflicts.map(
            (
              {
                blueprint_name,
                blueprint_id,
                conflicted_item_name,
                conflicted_item_id,
                conflicted_item_instance_name,
                is_all_blueprints: isCurrentlyAssignedLibraryItemAllBlueprints,
              },
              index,
            ) => {
              const selectedItem = itemSelections[index];
              const blueprintsWithConflict = [
                ...resolved,
                { label: blueprint_name, value: blueprint_id },
              ];
              const blueprintsWithoutConflict = resolved.filter(
                (bp) => bp.value !== blueprint_id,
              );

              return (
                <div
                  className="k-library__blueprint-conflict-assignment"
                  key={blueprint_id}
                >
                  <Flex direction="col" gapType="gap2">
                    <span className="b-txt k-library__blueprint-conflict-desc">
                      <Chip
                        kind="secondary"
                        text={blueprint_name}
                        className="k-library__blueprint-conflict-chip"
                      />{' '}
                      {i18n.t('- Choose a Library Item to assign.')}
                    </span>

                    <div className="b-flex">
                      <Radio
                        className={setClass([
                          itemSelections[index] === currItemId
                            ? 'k-library__blueprint-conflict-radio--selected'
                            : '',
                        ])}
                        label={getLabel(
                          currItemName,
                          true,
                          itemInstanceName,
                          isCurrentlyAssignedLibraryItemAllBlueprints,
                        )}
                        value={currItemId}
                        checked={selectedItem === currItemId}
                        onChange={() => {
                          const selections = [...itemSelections];
                          selections[index] = currItemId;
                          selectBpConflict(selections, blueprintsWithConflict);
                        }}
                      />
                    </div>

                    <div className="b-flex">
                      <Radio
                        className={setClass([
                          itemSelections[index] === conflicted_item_id
                            ? 'k-library__blueprint-conflict-radio--selected'
                            : '',
                        ])}
                        label={getLabel(
                          conflicted_item_name,
                          false,
                          conflicted_item_instance_name,
                          isCurrentlyAssignedLibraryItemAllBlueprints,
                        )}
                        value={conflicted_item_id}
                        checked={selectedItem === conflicted_item_id}
                        onChange={() => {
                          const selections = [...itemSelections];
                          selections[index] = conflicted_item_id;
                          selectBpConflict(
                            selections,
                            blueprintsWithoutConflict,
                          );
                        }}
                      />
                    </div>
                  </Flex>
                </div>
              );
            },
          )}
        </div>
      ),
    },
    footer: {
      children: (
        <>
          <Button kind="outline" onClick={onClose}>
            {i18n.t('Cancel')}
          </Button>

          <Button
            className="b-ml"
            theme="action"
            iconPlacement="right"
            onClick={handleOnSave}
            disabled={!itemSelections.every(Boolean)}
          >
            {i18n.t('Save')}
          </Button>
        </>
      ),
    },
  };

  return (
    <Modal
      classes={{ root: 'k-blueprint-conflict-modal' }}
      isOpen={isOpen}
      onClose={onClose}
      parts={parts}
    />
  );
}

export default BlueprintConflictsModal;
