import { Icon } from '@kandji-inc/bumblebee';
import styled from 'styled-components';
import { PrimaryText } from './text.styles';

const Banner = styled('div')`
  display: flex;
  align-items: flex-start;
  border-radius: 4px;
  padding: ${(props) => (props.padding ? props.padding : '15px 78px 13px 21px')};
  margin: ${(props) => (props.margin ? props.margin : '24px 0')};
`;

const BannerIcon = styled(Icon)`
  margin-right: 12px;
  font-size: 18px;
`;

const BannerText = styled('div')`
  ${PrimaryText};
  font-size: 14px;
  line-height: 20px;
  color: #4d5a79;
`;

export const InfoBannerElement = styled(Banner)`
  background: ${(props) => (props.background ? props.background : '#EEF0F6')};
  width: ${(props) => (props.width ? props.width : null)};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : null)};
  padding: ${(props) => (props.padding ? props.padding : null)};
  @media only screen and (min-width: 800px) {
    grid-row-start: 3;
  }
  ${(props) =>
    props.isAdaptive &&
    `@media only screen and (min-width: 1440px) {
    grid-column-start: 2;
    grid-row-start: 2;
  }`};
`;

InfoBannerElement.Icon = styled(BannerIcon)`
  color: #4d5a79;
`;

InfoBannerElement.Title = styled(BannerText)`
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  padding-bottom: 7px;
  color: #4d5a79;
`;

InfoBannerElement.Text = styled(BannerText)`
  color: #4d5a79;
`;

export const WarningBannerElement = styled(Banner)`
  background: rgba(255, 122, 0, 0.1);
`;

WarningBannerElement.Icon = styled(BannerIcon)`
  color: #ff7a00;
`;

WarningBannerElement.Text = styled(BannerText)`
  color: #ff7a00;
`;
