// Third Party Imports
import { useEffect, useState } from 'react';

// Internal Scoped Imports
// ...Internal Scoped Imports Go Here

// Local Imports
import { kandjiDarkIcon } from '../../default-icons';
import isSquare from './utils/isSquare';

// Types
// TODO: This type should be greatly simplified for what this component
//    actually needs.  The components that pass data into this should map the data to conform to the options object that this hook needs.
type CompanyLogoHookOptions = {
  isDarkMode: boolean;
  model: {
    general: {
      isCustomLogo: boolean;
      logo: {
        dataUrl: string;
      };
      logoDark: {
        dataUrl: string;
      };
      logoDarkWidth: number;
      logoDarkHeight: number;
      logoWidth: number;
      logoHeight: number;
      logoUrl: string;
      logoDarkUrl: string;
    };
  };
};

type CompanyLogoResult = {
  src: string;
  width: number;
  height: number;
  isSquare: boolean;
};

// Defaults
const defaultOptions = {
  isDarkMode: false,
  model: {
    general: {
      isCustomLogo: false,
      logo: {
        dataUrl: '',
      },
      logoDark: {
        dataUrl: '',
      },
      logoUrl: '',
      logoDarkUrl: '',
      logoDarkWidth: 128,
      logoDarkHeight: 128,
      logoWidth: 128,
      logoHeight: 128,
    },
  },
};

// main export
const useCompanyLogo = (opts: CompanyLogoHookOptions = defaultOptions) => {
  const { model, isDarkMode } = opts;
  const defaultState: CompanyLogoResult = getOptionsFromModel(opts);
  const [companyLogo, setCompanyLogo] = useState(defaultState);

  useEffect(() => {
    const options: CompanyLogoResult = getOptionsFromModel(opts);
    setCompanyLogo(options);
  }, [isDarkMode, model]);

  return companyLogo;
};

// -----------------
// Private Functions
// -----------------

export const getOptionsFromModel = (opts: CompanyLogoHookOptions) => {
  const { model, isDarkMode } = opts;
  const { general } = model;
  const { isCustomLogo } = general;

  const width = isDarkMode ? general.logoDarkWidth : general.logoWidth;
  const height = isDarkMode ? general.logoDarkHeight : general.logoHeight;

  const logoUrl = general.logoUrl || general.logo.dataUrl;
  const darkLogoUrl =
    general.logoDarkUrl || general.logoDark?.dataUrl || kandjiDarkIcon.dataUrl;
  const hasDarkLogoUrl = !!general.logoDarkUrl || !!general.logoDark?.dataUrl;

  let url = isDarkMode ? darkLogoUrl : logoUrl;

  // If dark mode is enabled and there is a custom logo uploaded but no custom dark mode logo
  //    is uploaded, then show the original / light mode custom logo
  if (isDarkMode && isCustomLogo && !hasDarkLogoUrl) {
    url = logoUrl;
  }

  return {
    src: url,
    width,
    height,
    isSquare: isSquare(width, height),
  };
};

export default useCompanyLogo;
