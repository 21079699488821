import { useContext } from 'react';
import { AccountContext } from 'src/contexts/account';
import { VulnerabilitiesTable } from './all-vulnerabilities/vulnerabilities-table';
import { TrialPage } from './trial-page';

const MainVulnerabilityPage = () => {
  const { currentCompany } = useContext(AccountContext);
  const { feature_configuration } = currentCompany;

  return feature_configuration?.vulnerability_management?.enabled ? (
    <VulnerabilitiesTable />
  ) : (
    <TrialPage />
  );
};

export { MainVulnerabilityPage };
