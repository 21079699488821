import {
  type FieldArray,
  type FieldValues,
  NectarForm,
  type SubmitValidCallback,
  Transform,
  useFieldArray,
} from '@kandji-inc/nectar-form';
import {
  transformJSONLogicToRules,
  transformRulesToJSONLogic,
} from 'features/rules-modal/transformers';
import type { FacetMap, RuleData } from 'features/rules-modal/types';
import { filterOptions } from 'features/rules/utilities';
import type React from 'react';
import { createContext, useContext, useEffect, useState } from 'react';

const RulesContext = createContext({
  facetMap: {} as FacetMap,
  andRulesArray: {} as FieldArray,
  supportedDeviceFamilies: [] as string[],
  installOnDeviceFamilies: [] as string[],
  countOfUserDirectoryIntegrations: 0,
  hasUserDirectoryIntegration: true,
  deleteRules: /* istanbul ignore next */ () => {},
});

export const RulesContextProvider = ({
  facetMap,
  rules,
  setModel,
  supportedDeviceFamilies,
  installOnDeviceFamilies,
  countOfUserDirectoryIntegrations,
  hasUserDirectoryIntegration,
  afterSubmit,
  children,
}: {
  facetMap: FacetMap;
  rules: RuleData | null;
  setModel: (prev) => void;
  supportedDeviceFamilies: string[];
  installOnDeviceFamilies: string[];
  countOfUserDirectoryIntegrations: number;
  hasUserDirectoryIntegration: boolean;
  afterSubmit: () => void;
  children: React.ReactNode;
}) => {
  const [initialValues, setInitialValues] = useState<FieldValues | null>(null);
  const [formVersion, setFormVersion] = useState(0);
  const andRulesArray = useFieldArray('and');

  /**
   * This context is typically used with a modal that is visible/hidden on a page, but always present
   *
   * Since the form is never actually _unmounted_ we want to be able to force an unmount (by setting key)
   * to reset the form after submission, reset/cancel, changes to incoming rules, etc
   */
  const rebuildForm = () => {
    setFormVersion((p) => p + 1);
  };

  // transform incoming rule data
  useEffect(() => {
    const data = transformJSONLogicToRules(
      /* istanbul ignore next */ rules || { and: [] },
      facetMap,
    );
    setInitialValues(Transform.toFieldValues(data));
    rebuildForm();
  }, [rules]);

  // waiting on transformed rule data
  if (initialValues === null) {
    return null;
  }

  const onInitialize = () => {
    // start with one empty rule on new forms
    if (andRulesArray.getLength() === 0) {
      andRulesArray.addFieldIndex();
    }
  };

  // rebuild the form when cancelled
  const onReset = () => {
    rebuildForm();
  };

  const onSubmitValid: SubmitValidCallback = async (data) => {
    let newRules = data;
    newRules = Transform.toNestedData(newRules);
    newRules = transformRulesToJSONLogic(newRules, facetMap);
    setModel((p) => ({ ...p, rules: newRules }));
    rebuildForm();
    afterSubmit();
  };

  const deleteRules = /* istanbul ignore next */ () => {
    setModel((p) => ({ ...p, rules: null }));
    rebuildForm();
  };

  // remove unsupported facets and options
  const refinedFacetMap = {};
  Object.keys(facetMap).forEach((facet) => {
    facetMap[facet].device_families.forEach((family) => {
      if (supportedDeviceFamilies.includes(family)) {
        refinedFacetMap[facet] = facetMap[facet];
      }
    });
    if (refinedFacetMap[facet] && refinedFacetMap[facet].options) {
      refinedFacetMap[facet].options = filterOptions(
        refinedFacetMap[facet].options,
        supportedDeviceFamilies,
      );
    }
  });

  return (
    <NectarForm
      key={formVersion}
      initialValues={initialValues}
      fieldArrays={[andRulesArray]}
      onInitialize={onInitialize}
      onSubmitValid={onSubmitValid}
      onReset={onReset}
    >
      <RulesContext.Provider
        value={{
          facetMap: refinedFacetMap,
          andRulesArray,
          supportedDeviceFamilies,
          installOnDeviceFamilies,
          countOfUserDirectoryIntegrations,
          hasUserDirectoryIntegration,
          deleteRules,
        }}
      >
        {children}
      </RulesContext.Provider>
    </NectarForm>
  );
};

export const useRulesContext = () => useContext(RulesContext);
