import deepcopy from 'deepcopy';
import { getAllBlueprintOptions } from 'features/library-items/data-service/blueprint/use-blueprint-service';
import { omit } from 'lodash';
import {
  type BaseLibraryItemModel,
  type LibraryItemApiData,
  LibraryItemBaseApiData,
  type LibraryItemRunsOn,
} from '../../library-items.types';
import dataReplace from './data-replace';
import { getRunsOnFromModel, getSelectedDevicesFromRunsOn } from './devices';

// this used in ../automatic-app/transformers.js and other transformers
const getOSRequirement = (apiData) => {
  if (apiData.runs_on_mac) {
    return [`macOS ${apiData.data.minimum_os_version || `10.11`}+`];
  }

  return [`iOS ${apiData.data.minimum_os_version}+`];
};

/**
 * Necessary transformations on the data from the api.
 * @param {Object} data - The library item data received from the api.
 */
const transformFromApi = async (apiData) => {
  // These are the keys this specific library item type has that contains runs
  // on info.
  const runsOnKeys = Object.keys(apiData.data).filter((k) =>
    k.includes('runs_on_'),
  );
  const transformed = {
    ...apiData,
    data: {
      // In case the backend, or somewhere else has changed the actual library
      // item data, let's update the necessary fields in data.
      ...apiData.data,
      blueprints: await getAllBlueprintOptions(apiData.blueprints),
      isActive: apiData.active,
      name: apiData.name,
      type: apiData.type,
      id: apiData.id,
      template: apiData.template,
    },
  };

  // This way, we don't add unnecessary runs_on fields to the transformed data.
  runsOnKeys.forEach((runsOn) => {
    transformed.data[runsOn] = apiData[runsOn];
  });

  /* set nullish values to '' for ui */
  dataReplace(transformed.data, '');

  return transformed;
};

/**
 * Necessary transformations on the data to send to the api.
 * @param {Object} data - The library item data model to send to the api.
 */
const transformToApi = (data) => {
  const d = deepcopy(data);
  const blueprints = d.blueprints.map((blueprint) => blueprint.value);
  delete d.blueprints;
  delete d.id;

  const toSend = {
    active: d.isActive,
    blueprints,
    data: omit(d, ['blueprints', 'id', 'template']),
    name: d.name,
    type: d.type,
    runs_on_mac: d.runs_on_mac || false,
    runs_on_ipad: d.runs_on_ipad || false,
    runs_on_iphone: d.runs_on_iphone || false,
    runs_on_ipod: d.runs_on_ipod || false,
    runs_on_tv: d.runs_on_tv || false,
    runs_on_watch: d.runs_on_watch || false,
    reassign_lib_item_to_bp: true,
    skip_blueprint_conflict: d.skip_blueprint_conflict || false,
  };

  dataReplace(toSend.data);
  return toSend;
};

const setBlueprintsToFormData = (formData, blueprints) => {
  if (!blueprints.length) {
    formData.set('blueprints', '');
    return;
  }
  for (let i = 0; i < blueprints.length; i++) {
    const bp = blueprints[i];
    formData.append('blueprints', bp.value);
  }
};

interface BlueprintOption {
  label: string;
  value: string;
  type: string;
}

function splitExcludedBlueprints(
  blueprintOptions: BlueprintOption[],
  excludedBlueprintData: string[],
) {
  const excludedBlueprints = [];
  const selectedBlueprints = blueprintOptions.filter((blueprintOption) => {
    if (excludedBlueprintData.includes(blueprintOption.value)) {
      excludedBlueprints.push(blueprintOption);
      return false;
    }

    return true;
  });

  return {
    selectedBlueprints,
    excludedBlueprints,
  };
}

async function baseLibraryItemFromApi(
  apiData: LibraryItemApiData,
): Promise<BaseLibraryItemModel> {
  const {
    id,
    name,
    rules,
    active: isActive,
    is_all_blueprints: isAllBlueprints,
    blueprints: blueprintData,
    excluded_blueprints: excludedBlueprintData = [],
  } = apiData;

  const blueprintOptions = await getAllBlueprintOptions(blueprintData);

  const { selectedBlueprints, excludedBlueprints } = splitExcludedBlueprints(
    blueprintOptions,
    excludedBlueprintData,
  );

  return {
    id,
    name,
    rules,
    isActive,
    isAllBlueprints,
    selectedBlueprints,
    excludedBlueprints,
    devices: getSelectedDevicesFromRunsOn(apiData),
  };
}

function buildRunsOnFromModel(model: BaseLibraryItemModel): LibraryItemRunsOn {
  const runsOn = getRunsOnFromModel(model);
  const runsOnData: LibraryItemRunsOn = {
    runs_on_mac: false,
    runs_on_iphone: false,
    runs_on_ipad: false,
    runs_on_ipod: false,
    runs_on_tv: false,
    runs_on_watch: false,
    runs_on_reality_device: false,
  };

  for (const deviceKey in runsOn) {
    runsOnData[deviceKey] = runsOn[deviceKey];
  }

  return runsOnData;
}

function baseLibraryItemToApi(
  model: BaseLibraryItemModel,
): Partial<LibraryItemApiData> {
  const {
    id,
    name,
    rules,
    selectedBlueprints,
    excludedBlueprints,
    isActive: active,
    isAllBlueprints: is_all_blueprints,
  } = model;

  const libraryItemBaseData: Partial<LibraryItemApiData> = {
    id,
    name,
    active,
    is_all_blueprints,
    rules: rules ?? null,
    blueprints: selectedBlueprints.map((blueprint) => blueprint.value),
    excluded_blueprints: excludedBlueprints.map((blueprint) => blueprint.value),
  };

  return {
    ...libraryItemBaseData,
    ...buildRunsOnFromModel(model),
  };
}

export {
  baseLibraryItemFromApi,
  baseLibraryItemToApi,
  getOSRequirement,
  setBlueprintsToFormData,
  transformFromApi,
  transformToApi,
};
