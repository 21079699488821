import uuidv4 from 'uuid/v4';

import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class SafariExtensionsService extends NewLibraryItemService {
  static stateTypes = {
    ALLOWED: 'Allowed',
    ALWAYS_ON: 'AlwaysOn',
    ALWAYS_OFF: 'AlwaysOff',
  } as const;

  static stateOptions = [
    { label: 'Allowed', value: this.stateTypes.ALLOWED },
    { label: 'Always On', value: this.stateTypes.ALWAYS_ON },
    { label: 'Always Off', value: this.stateTypes.ALWAYS_OFF },
  ];

  static generateExtension = () => ({
    id: uuidv4(),
    BundleId: '',
    TeamId: '',
    State: this.stateTypes.ALLOWED,
    PrivateBrowsing: this.stateTypes.ALLOWED,
    AllowedDomains: [],
    DeniedDomains: [],
  });

  static generateDomain = () => ({
    id: uuidv4(),
    value: '',
  });
}

export const safariExtensionsService = new SafariExtensionsService();

export default SafariExtensionsService;
