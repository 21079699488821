import { Chip } from '@kandji-inc/bumblebee';
import React, { useState, useMemo } from 'react';
import { i18n } from 'src/i18n';
import WifiService from '../../../../service/wifi-service';
import GenericAppIcon from '../../../assets/generic-app-icon.svg';
import {
  useCustomAppsActions,
  useSearchedAppsActions,
} from '../../../fast-lane-card.hooks';
import { addAppModes, appsTypes } from '../../../fast-lane-card.model';
import AddBundle from '../add-bundle';
import SearchApp from '../search-app';

const keys = WifiService.keys.fastlane;

export const renderTags = ({
  apps,
  selectedApps,
  removeSearchedApp,
  removeCustomApp,
}) => {
  const renderedApps = selectedApps.map((selectedApp) => {
    if (selectedApp.type === appsTypes.VPP) {
      const app = apps.find((i) => i?.data?.bundle_id === selectedApp.id);
      const icon = app?.data?.icon || app?.icon || GenericAppIcon;

      return (
        <Chip
          asImage={{ left: true, right: false }}
          text={app.name}
          iconRight="xmark"
          iconLeft={icon}
          key={app.id}
          className="k-wifiv2-fastlane-drawer__selected-apps-tag"
          onRightIconClick={() => removeSearchedApp(selectedApp.id)}
        />
      );
    }
    if (selectedApp.type === appsTypes.CUSTOM) {
      const app = apps.find((i) => i.data.bundle_id === selectedApp.bundleId);
      const icon = app?.data?.icon || app?.icon || GenericAppIcon;

      return (
        <Chip
          key={selectedApp.bundleId}
          asImage={{ left: true, right: false }}
          text={selectedApp.name}
          onRightIconClick={() => removeCustomApp(selectedApp.bundleId)}
          iconRight="xmark"
          iconLeft={icon}
          className="k-wifiv2-fastlane-drawer__selected-apps-tag"
        />
      );
    }
    // if something is wrong
    return null;
  });

  return renderedApps;
};

const DrawerContent = ({ setting, update, apps, appsLoaded }) => {
  const [addAppMode, setAddAppMode] = useState(addAppModes.SEARCH);
  const { removeApp: removeCustomApp } = useCustomAppsActions({
    update,
    setting,
    apps,
  });
  const { removeApp: removeSearchedApp } = useSearchedAppsActions({
    update,
    setting,
  });
  const selectedApps = setting[keys.addedApps] || [];
  const tags = useMemo(
    () =>
      renderTags({ apps, selectedApps, removeSearchedApp, removeCustomApp }),
    [selectedApps, apps, removeSearchedApp, removeCustomApp],
  );

  const showSearchApp = addAppMode === addAppModes.SEARCH;
  const showAddBundle = addAppMode === addAppModes.CUSTOM;

  return (
    <div className="k-wifiv2-drawer k-wifiv2-fastlane-drawer">
      <div className="k-wifiv2-drawer__header">
        <p className="b-txt">
          {i18n.t(
            'Search for App Store apps in your library, or add a Bundle ID manually.',
          )}
        </p>
        {selectedApps.length > 0 && (
          <div className="k-wifiv2-fastlane-drawer__selected-apps">{tags}</div>
        )}
      </div>
      {showSearchApp && (
        <SearchApp
          update={update}
          setting={setting}
          apps={apps}
          appsLoaded={appsLoaded}
          setAddAppMode={setAddAppMode}
        />
      )}
      {showAddBundle && (
        <AddBundle
          update={update}
          setting={setting}
          setAddAppMode={setAddAppMode}
          apps={apps}
        />
      )}
    </div>
  );
};

export default DrawerContent;
