import { i18n } from 'i18n';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Pagination } from './Pagination';

const Wrapper = styled.section`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const PaginationPanel = ({
  sizePerPage,
  sizePerPageNode,
  currPage,
  totalItems,
  lastPage,
  changeFilterInUrl,
  labelName,
}) => (
  <Wrapper>
    <Pagination
      currentPage={Number(currPage)}
      count={totalItems}
      perPage={sizePerPage}
      onChange={(page) => {
        changeFilterInUrl({ page });
      }}
      lastPage={lastPage}
      labelName={labelName}
    />
    {sizePerPageNode}
  </Wrapper>
);

PaginationPanel.propTypes = {
  sizePerPage: PropTypes.number.isRequired,
  sizePerPageNode: PropTypes.node.isRequired,
  totalItems: PropTypes.number,
  currPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lastPage: PropTypes.number,
  labelName: PropTypes.string,
};

PaginationPanel.defaultProps = {
  currPage: 1,
  lastPage: 1,
  /* istanbul ignore next */
  get labelName() {
    return i18n.t('Results');
  },
  totalItems: 0,
};
