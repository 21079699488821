import { Button } from '@kandji-inc/bumblebee';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteBlueprint as callDeleteBlueprint } from '../../../_actions/blueprint';
import history from '../../../router/history';
import AwesomeCheckbox from '../AwesomeCheckbox';
import { ModalWrapper } from './ModalWrapper';
import { ButtonsWrapper, CancelButtonWrapper } from './elements';

export class BlueprintDelete extends ModalWrapper {
  constructor(props) {
    super(props);
    this.modalName = 'BlueprintDelete';
    this.state = {
      isAccepted: false,
    };
    this.onDelete = this.onDelete.bind(this);
  }

  onDelete() {
    const { info, deleteBlueprint, setSnackbar, startGetComputers } =
      this.props;
    const { isAccepted } = this.state;
    const blueprintId = info.id;
    if (!isAccepted) {
      return null;
    }
    this.setState({ isLoading: true });
    deleteBlueprint(blueprintId).then(
      () => {
        this.onHide();
        history.push('/blueprints/');
        setSnackbar('Blueprint was deleted');
        startGetComputers();
      },
      ({ response }) => {
        this.setState({ isLoading: false });
        setSnackbar(response.data.error);
      },
    );
    return null;
  }

  renderHeader = () => 'Delete Blueprint';

  renderBody() {
    const { isAccepted } = this.state;
    return (
      <>
        <p style={{ color: '#f05b7e' }}>
          Deleting a Blueprint will permanently destroy its Device Records,
          History, and Notes. This action cannot be undone.
        </p>
        <p>This process may take some time to complete.</p>
        <AwesomeCheckbox
          label="I understand this Blueprint will be permanently deleted, along with its Device Records, History, and Notes."
          onCheck={() => this.setState({ isAccepted: !isAccepted })}
          checked={isAccepted}
          keyId="deleteCheckbox"
          margin={0}
          checkboxPosition="start"
        />
      </>
    );
  }

  renderFooter() {
    const { isLoading, isAccepted } = this.state;
    return (
      <ButtonsWrapper>
        <CancelButtonWrapper>
          <Button type="button" onClick={this.onHide} kind="outline">
            Cancel
          </Button>
        </CancelButtonWrapper>
        <Button
          type="button"
          disabled={isLoading || !isAccepted}
          onClick={this.onDelete}
          name={!isLoading ? 'Delete' : 'Deleting'}
          theme="error"
        >
          {!isLoading ? 'Delete' : 'Deleting'}
        </Button>
      </ButtonsWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteBlueprint: callDeleteBlueprint,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(BlueprintDelete);
