// istanbul ignore file
import { useState } from 'react';
import type { BehavioralThreatGroup, ThreatGroup } from '../../../threat.types';

type UseSelectRows = () => {
  selectRow: (key: string) => void;
  unselectRow: (key: string) => void;
  selectRows: (threats: ThreatGroup[] | BehavioralThreatGroup[]) => void;
  unselectRows: (threats: ThreatGroup[] | BehavioralThreatGroup[]) => void;
  unselectAllRows: () => void;
  isRowSelected: (key: string) => boolean;
  selectedRowsCount: number;
  isAnyRowSelected: boolean;
};

const useSelectRows: UseSelectRows = () => {
  const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set());

  const selectedRowsCount = selectedRows.size;
  const isAnyRowSelected = selectedRowsCount > 0;

  const selectRow = (key: string) => {
    setSelectedRows((prev) => new Set(prev.add(key)));
  };

  const unselectRow = (key: string) => {
    setSelectedRows((prev) => {
      const newSet = new Set(prev);
      newSet.delete(key);
      return newSet;
    });
  };

  const selectRows = (threats: ThreatGroup[] | BehavioralThreatGroup[]) => {
    setSelectedRows((prev) => {
      const newSet = new Set(prev);
      threats.forEach((threat) => {
        newSet.add(threat.threat_id);
      });
      return newSet;
    });
  };

  const unselectRows = (threats: ThreatGroup[] | BehavioralThreatGroup[]) => {
    setSelectedRows((prev) => {
      const newSet = new Set(prev);
      threats.forEach((threat) => {
        newSet.delete(threat.threat_id);
      });
      return newSet;
    });
  };

  const unselectAllRows = () => {
    if (!isAnyRowSelected) return;

    setSelectedRows(new Set());
  };

  const isRowSelected = (key: string) => selectedRows.has(key);

  return {
    selectRow,
    unselectRow,
    selectRows,
    unselectRows,
    unselectAllRows,
    isRowSelected,
    selectedRowsCount,
    isAnyRowSelected,
  };
};

export default useSelectRows;
