/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query';
import { api } from 'app/api/base';
import { URL_COMPUTERS } from 'app/api/urls';

const useComputerLastCheckIn = (id: string, isEnabled = true) => {
  const {
    data: apiRes,
    isLoading,
    isError,
    ...rest
  } = useQuery({
    queryKey: ['device-last-check-in', id],
    queryFn: () => api(`${URL_COMPUTERS}${id}/lastcheckin`).get(),
    enabled: isEnabled,
  });

  const apiData = apiRes?.data;

  return {
    data: apiData,
    isLoading,
    isError,
    ...rest,
  };
};

export default useComputerLastCheckIn;
