import { Checkbox, Chip, Radio, Select } from '@kandji-inc/bumblebee';
/* istanbul ignore file */
import React from 'react';

import { Setting } from 'features/library-items/template';
import featureFlags from 'src/config/feature-flags';
import {
  i18nOsRequirements,
  osRequirements,
} from 'src/features/library-items/library/common';
import { i18n } from 'src/i18n';

import {
  ACTIVATION_LOCK_OPTIONS,
  ACTIVATION_LOCK_OPTION_ITEM,
} from '../activation-lock';
import MinimumOSVersionRow from '../minimum-os-verstion-row';
import SkipScreens, { useSkipScreensValidation } from '../skip-screens';

const IPhone = (props) => {
  const { setting, update, isDisabled, isSaving } = props;
  const SKIP_SCREENS_PANEL_CONFIG = {
    title: i18n.t('Specify Setup Assistant screens for iPhone'),
    helper: i18n.t(
      'Select the checkbox for a Setup Assistant screen to skip it.',
    ),
    selectAllLabel: i18n.t('Skip all screens for iPhone'),
  };

  const skipScreensValidationSettings = {
    isEnabled: setting.isEnabled,
    canSkip: setting.canSkip,
    isSkipAll: setting.isSkipAll,
    screens: setting.screens,
    isSaving,
  };

  const { skipScreensRef, isSkipScreenInvalid } = useSkipScreensValidation(
    skipScreensValidationSettings,
    update,
  );

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('iPhone')}</h3>
        <Setting.HeaderToggle
          isEnabled={setting.isEnabled}
          onChange={() => update('isEnabled', (p) => !p)}
          isDisabled={isDisabled}
          isHideChip
        />
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          {i18n.t(
            'Customize and configure the iOS Setup Assistant screens and Activation Lock.',
          )}
        </p>
      </Setting.SubHeader>
      {setting.isEnabled && (
        <Setting.Rows>
          <Setting.Row>
            <Setting.Controls>
              <Checkbox
                checked={setting.canSkip}
                label={i18n.t(
                  'Skip screens during Setup Assistant for iPhone devices.',
                )}
                onChange={() => update('canSkip', (p) => !p)}
                disabled={isDisabled}
              />
            </Setting.Controls>
            <Setting.Helpers className="b-txt-light">
              {i18n.t(
                'Determine which screens are displayed during Setup Assistant.',
              )}
            </Setting.Helpers>
            {setting.canSkip && (
              <Setting.SecondaryControls>
                <div className="b-library-form">
                  <div ref={skipScreensRef}>
                    <SkipScreens
                      panelConfig={SKIP_SCREENS_PANEL_CONFIG}
                      screens={setting.screens}
                      setScreens={(screens) => update('screens', screens)}
                      disabled={isDisabled}
                      hasError={isSkipScreenInvalid}
                    />
                  </div>
                </div>
              </Setting.SecondaryControls>
            )}
          </Setting.Row>

          <Setting.Row>
            <div>
              <p className="b-txt">{i18n.t('Activation Lock')}</p>
            </div>
            <Setting.Helpers>
              <p className="b-txt-light">
                {i18n.t(
                  'Specify if users should be allowed to enable Activation Lock using Find My and a personal Apple Account.',
                )}
                &nbsp;
                <a
                  href="https://support.kandji.io/support/solutions/articles/72000558685"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="b-alink"
                >
                  {i18n.t('Learn More...')}
                </a>
              </p>
              <div className="b-flex-wrap-gtiny">
                <Chip
                  kind="tertiary-light"
                  text={i18nOsRequirements(osRequirements.IOS_7_0)}
                  className="k-ade-chip--m0"
                />
              </div>
            </Setting.Helpers>
            <div>
              <Select
                isDisabled={isDisabled}
                options={ACTIVATION_LOCK_OPTIONS()}
                compact
                onChange={(v) =>
                  update(
                    'userActivationLockAllowed',
                    v.value === ACTIVATION_LOCK_OPTION_ITEM.ALLOW().value,
                  )
                }
                value={
                  setting.userActivationLockAllowed
                    ? ACTIVATION_LOCK_OPTION_ITEM.ALLOW()
                    : ACTIVATION_LOCK_OPTION_ITEM.DISALLOW()
                }
              />
            </div>
            {!setting.userActivationLockAllowed && (
              <Setting.SecondaryControls className="k-ade-secondary-ctrl-grid-gap-align">
                <Setting.SecondaryControlsRow>
                  <div>
                    <Checkbox
                      checked={setting.isEnableOrgActivationLock}
                      label={i18n.t('Enable device-based Activation Lock')}
                      onChange={() =>
                        update('isEnableOrgActivationLock', (p) => !p)
                      }
                      isDisabled={isDisabled}
                    />
                  </div>
                  <div>
                    <p className="b-txt-light">
                      {i18n.t(
                        'Automatically enable device-based Activation Lock through Apple Business Manager.',
                      )}
                    </p>
                  </div>
                </Setting.SecondaryControlsRow>
              </Setting.SecondaryControls>
            )}
          </Setting.Row>

          <MinimumOSVersionRow {...props} typeOS={i18n.t('iOS')} os="iOS" />
        </Setting.Rows>
      )}
    </Setting.Card>
  );
};

export default IPhone;
