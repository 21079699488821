import React from 'react';

export const Eclipse = () => (
  <svg
    width="200px"
    height="200px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    className="lds-eclipse"
    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%' }}
  >
    <path
      stroke="none"
      d="M10 50A40 40 0 0 0 90 50A40 43 0 0 1 10 50"
      fill="#071e4c"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        calcMode="linear"
        values="0 50 51.5;360 50 51.5"
        keyTimes="0;1"
        dur="2s"
        begin="0s"
        repeatCount="indefinite"
      />
    </path>
  </svg>
);
