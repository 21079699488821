import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const AwesomeTableRow = ({
  headers,
  row,
  isExpanded,
  setIsExpanded,
  expandComponent,
  clickableCellsToExpand,
  isExpandableRow,
}) => (
  <div className="awesome-table-row-row-wrapper">
    <div className="awesome-table-row-row">
      {headers.map((h) => {
        const cellStyle = {
          flex: `0 0 ${h.width}px`,
        };
        return (
          <div
            className={classNames('awesome-table-row-cell', {
              'right-bordered': h.pinned,
              expanded: isExpanded,
              'cursor-pointer': clickableCellsToExpand.includes(h.name),
            })}
            key={`AwesomeTableCell-${row.id}-${h.name}`}
            onClick={() => {
              if (
                isExpandableRow &&
                isExpandableRow(row) &&
                clickableCellsToExpand.includes(h.name)
              ) {
                setIsExpanded(!isExpanded);
              }
            }}
            style={cellStyle}
          >
            {h.formatRow(null, row, isExpanded, setIsExpanded)}
          </div>
        );
      })}
    </div>
    <div
      className={classNames('awesome-table-row-row-expand', {
        'd-none': !isExpanded,
      })}
    >
      {expandComponent(row)}
    </div>
  </div>
);

AwesomeTableRow.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
  tableWidth: PropTypes.number.isRequired,
  row: PropTypes.shape({}).isRequired,
  index: PropTypes.number.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  setIsExpanded: PropTypes.func.isRequired,
  expandComponent: PropTypes.func.isRequired,
  clickableCellsToExpand: PropTypes.arrayOf(PropTypes.string),
  isExpandableRow: PropTypes.func,
};

AwesomeTableRow.defaultProps = {
  clickableCellsToExpand: null,
  isExpandableRow: null,
};

export default AwesomeTableRow;
