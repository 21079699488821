import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import { getIconName } from '../../components/PrismNav/prismNavUtils';
import {
  getFilteredDisabled as filterDisabled,
  getCodeSnippetCell,
} from '../column-utils';
import { getScharedColumns } from './getSharedColumns';

export const getKernelExtensionsColumns = ({
  kernelExtensionsSchema,
}): {
  columnDefs: any[];
  pinnedColumns: string[];
  alwaysHiddenColumns: string[];
} => {
  const { properties } = kernelExtensionsSchema.schema;
  const kernelExtensionsColumnHelper =
    createColumnHelper<z.infer<typeof properties>>();

  const columnDefs = [
    kernelExtensionsColumnHelper.accessor(
      (row) => row[`${kernelExtensionsSchema.uri}.memory_size`],
      {
        id: `${kernelExtensionsSchema.uri}.memory_size`,
        meta: {
          displayName: properties.memory_size.title,
          displayIcon: getIconName(kernelExtensionsSchema.uri),
          filterType: 'number',
          filterIcon: 'hashtag',
          filterDisabled,
          defaultHidden: true,
        },
      },
    ),

    kernelExtensionsColumnHelper.accessor(
      (row) => row[`${kernelExtensionsSchema.uri}.name`],
      {
        id: `${kernelExtensionsSchema.uri}.name`,
        meta: {
          displayName: properties.name.title,
          displayIcon: getIconName(kernelExtensionsSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    kernelExtensionsColumnHelper.accessor(
      (row) => row[`${kernelExtensionsSchema.uri}.version`],
      {
        id: `${kernelExtensionsSchema.uri}.version`,
        meta: {
          displayName: properties.version.title,
          displayIcon: getIconName(kernelExtensionsSchema.uri),
          filterType: 'version',
          filterIcon: 'hashtag',
          filterDisabled,
        },
      },
    ),

    kernelExtensionsColumnHelper.accessor(
      (row) => row[`${kernelExtensionsSchema.uri}.path`],
      {
        id: `${kernelExtensionsSchema.uri}.path`,
        cell: getCodeSnippetCell,
        meta: {
          displayName: properties.path.title,
          displayIcon: getIconName(kernelExtensionsSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    kernelExtensionsColumnHelper.accessor(
      (row) => row[`${kernelExtensionsSchema.uri}.identifier`],
      {
        id: `${kernelExtensionsSchema.uri}.identifier`,
        meta: {
          defaultHidden: true,
          displayName: properties.identifier.title,
          displayIcon: getIconName(kernelExtensionsSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    kernelExtensionsColumnHelper.accessor(
      (row) => row[`${kernelExtensionsSchema.uri}.references`],
      {
        id: `${kernelExtensionsSchema.uri}.references`,
        meta: {
          defaultHidden: true,
          displayName: properties.references.title,
          displayIcon: getIconName(kernelExtensionsSchema.uri),
          filterType: 'number',
          filterIcon: 'hashtag',
          filterDisabled,
        },
      },
    ),

    kernelExtensionsColumnHelper.accessor(
      (row) => row[`${kernelExtensionsSchema.uri}.linked_against`],
      {
        id: `${kernelExtensionsSchema.uri}.linked_against`,
        meta: {
          defaultHidden: true,
          displayName: properties.linked_against.title,
          displayIcon: getIconName(kernelExtensionsSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    ...getScharedColumns(kernelExtensionsSchema),
  ];

  return { columnDefs, pinnedColumns: [], alwaysHiddenColumns: [] };
};
