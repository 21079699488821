/* istanbul ignore file */
import { useState } from 'react';

export function usePagination(initialSize = 100) {
  const [pagination, setPagination] = useState({
    pageSize: initialSize,
    pageIndex: 0,
  });
  const { pageSize, pageIndex } = pagination;

  return {
    onPaginationChange: setPagination,
    pagination,
    size: pageSize,
    from: pageSize * pageIndex,
  };
}
