import { Checkbox, type CheckedState } from '@kandji-inc/nectar-ui';
import * as React from 'react';
import { useADEListViewStore } from '../store/ADEListViewStore';
import type { Device } from './ADEListViewTableColumns';

export function ADEColumnSelected() {
  const {
    devices,
    selectAllDevices,
    setSelectAllDevices,
    selectedDevices,
    setSelectedDevices,
  } = useADEListViewStore((state) => ({
    devices: state.devices,
    selectAllDevices: state.selectAllDevices,
    selectedDevices: state.selectedDevices,
    setSelectedDevices: state.setSelectedDevices,
    setSelectAllDevices: state.setSelectAllDevices,
  }));

  const isEntirePageSelected = React.useMemo(() => {
    return devices.every((device: Device) =>
      selectedDevices.includes(device.id),
    );
  }, [devices, selectedDevices]);

  const deviceIds = React.useMemo(() => {
    const deviceIdMap = {};
    for (const device of devices) {
      deviceIdMap[device.id] = device;
    }

    return deviceIdMap;
  }, [devices]);

  const isDeviceOnPageSelected = React.useMemo(() => {
    return selectedDevices.some((deviceId: string) => deviceIds[deviceId]);
  }, [selectedDevices, deviceIds]);

  function removePageFromSelectedDevices() {
    return selectedDevices.filter(
      (deviceId: string) => deviceIds[deviceId] == null,
    );
  }

  let checkValue: CheckedState = false;
  if (isEntirePageSelected || selectAllDevices) checkValue = true;
  else if (selectedDevices.length > 0 && isDeviceOnPageSelected) {
    checkValue = 'indeterminate';
  }

  return (
    <Checkbox
      checked={checkValue}
      onCheckedChange={(selected: boolean) => {
        setSelectAllDevices(false);
        if (selected) {
          setSelectedDevices([
            ...selectedDevices,
            ...devices.map((device: Device) => device.id),
          ]);
          return;
        }

        setSelectedDevices(removePageFromSelectedDevices());
      }}
    />
  );
}

export const ADECellSelected = React.memo(function ADECellSelected({
  device,
}: {
  device: Device;
}) {
  const {
    selectAllDevices,
    selectedDevices,
    setSelectedDevices,
    setSelectAllDevices,
  } = useADEListViewStore((state) => ({
    selectAllDevices: state.selectAllDevices,
    selectedDevices: state.selectedDevices,
    setSelectedDevices: state.setSelectedDevices,
    setSelectAllDevices: state.setSelectAllDevices,
  }));

  const selected = selectAllDevices || selectedDevices.includes(device.id);

  function handleCheckChanged(selected: boolean) {
    setSelectAllDevices(false);
    if (selected) {
      setSelectedDevices([...selectedDevices, device.id]);
    } else {
      setSelectedDevices(
        selectedDevices.filter((deviceId: string) => device.id !== deviceId),
      );
    }
  }

  return <Checkbox checked={selected} onCheckedChange={handleCheckChanged} />;
});
