import { Flex } from '@kandji-inc/nectar-ui';
import * as React from 'react';

import type { SortingState } from '@tanstack/react-table';
import { useParams } from 'react-router';
import { useWhiteBackground } from 'src/hooks/useWhiteBackground';
import { PrismNav } from '../components/PrismNav';
import PrismTable from '../components/PrismTable/PrismTable';
import { usePrismContext } from '../contexts/PrismContext';
import { usePrismUrlContext } from '../contexts/PrismUrlContext';
import { validateSortString } from '../contexts/utils/sortUtils';
import type { Blueprint } from '../hooks';
import { useCategoryDataCountQuery, usePagination } from '../hooks';
import { useCategoryQuery } from '../hooks/use-category-query';
import { GlobalFilterBar } from './components';
import { PrismAIBar } from './components/PrismAIBar';

export const DEVICE_FAMILY = {
  mac: {
    id: 'Mac',
    name: 'Mac',
  },
  iphone: {
    id: 'iPhone',
    name: 'iPhone',
  },
  ipad: {
    id: 'iPad',
    name: 'iPad',
  },
} as const;

const PrismCategories = () => {
  useWhiteBackground();

  const {
    columns,
    selectedPrismCategory,
    globalFilters,
    blueprintsQuery,
    computerCountQuery,
    prismNavOpen,
    setPrismNavOpen,
    globalFilterModalOpen,
    setGlobalFilterModalOpen,
  } = usePrismContext();

  const routeParams = useParams<{ prismCategory: string }>();

  const {
    paginationState: { pageIndex, pageSize },
    setPagination,
    resetPagination,
  } = usePagination({ storageKey: 'prism-page-limit' });

  const [sorting, setSorting] = React.useState<SortingState>([]);

  React.useEffect(() => {
    resetPagination();
    setSorting([]);
  }, [routeParams.prismCategory]);

  const {
    prismCategory,
    blueprints,
    deviceFamilies,
    filter,
    // limit, TODO: we don't currently store these in the URL, but when we're ready, they're here
    // offset,
    setBlueprints,
    resetBlueprints,
    setDeviceFamilies,
    // setLimit,
    // setOffset,
    resetBlueprintsAndDeviceFamilies,
  } = usePrismUrlContext();

  const validatedSortBy = React.useMemo(() => {
    if (prismCategory !== selectedPrismCategory?.uri) {
      return null;
    }
    const validSorting = validateSortString(
      sorting,
      selectedPrismCategory?.schema.properties,
    );
    return validSorting;
  }, [sorting, prismCategory, selectedPrismCategory]);

  const { data: newData, isPending } = useCategoryQuery({
    uri: prismCategory,
    params: {
      blueprint_ids: blueprints.join(','),
      device_families: deviceFamilies.join(','),
      sort_by: validatedSortBy,
      limit: pageSize,
      offset: pageIndex * pageSize,
    },
    filterBody: filter,
  });

  const prismCountQuery = useCategoryDataCountQuery({
    params: {
      blueprint_ids: blueprints.join(','),
      device_families: deviceFamilies.join(','),
      category: routeParams.prismCategory,
    },
    filterBody: filter,
  });

  return (
    <Flex
      flow="column"
      css={{
        pt: '$5',
        width: '100%',
        paddingLeft: '16px',
        paddingRight: '24px',
      }}
    >
      <Flex alignItems="center" gap="md" wFull css={{ pb: '$5' }}>
        <GlobalFilterBar
          globalFilters={{
            blueprints:
              /* istanbul ignore next */ blueprints.filter((b) => !!b) ?? [],
            deviceFamilies:
              /* istanbul ignore next */ deviceFamilies.filter((d) => !!d) ??
              [],
          }}
          onDeviceFamilyFilterChange={setDeviceFamilies}
          onBlueprintFilterChange={(bp: Blueprint[]) => {
            // istanbul ignore next
            if (bp.length === 0) {
              resetBlueprints();
              return;
            }
            const formattedBps = bp.map((b) => {
              // istanbul ignore next
              if (typeof b === 'string') {
                return {
                  id: b,
                  name:
                    // istanbul ignore next
                    blueprintsQuery.data?.find((bp) => bp.id === b)?.name ?? '',
                };
              }
              return b;
            });

            setBlueprints(formattedBps.map((b) => b.id));
          }}
          blueprintsQuery={blueprintsQuery}
          onResetFilters={resetBlueprintsAndDeviceFamilies}
          editViewModalOpen={globalFilterModalOpen}
          setEditViewModalOpen={setGlobalFilterModalOpen}
        />
        <PrismAIBar />
      </Flex>

      <Flex gap="xl">
        {prismNavOpen && <PrismNav />}

        <PrismTable
          data={newData}
          columns={columns}
          paginationState={{
            pageIndex,
            pageSize,
          }}
          onPaginationChange={setPagination}
          isLoading={
            /* istanbul ignore next */ isPending ||
            computerCountQuery?.isPending
          }
          title={selectedPrismCategory?.display_name}
          isPaidCategory={!!selectedPrismCategory?.paid}
          uri={selectedPrismCategory?.uri}
          globalFilters={globalFilters}
          prismNavOpen={prismNavOpen}
          computerCount={computerCountQuery?.data}
          togglePrismNavOpen={() => setPrismNavOpen(!prismNavOpen)}
          setEditViewModalOpen={() => setGlobalFilterModalOpen(true)}
          prismCategorySchema={selectedPrismCategory}
          sortingState={sorting}
          onSortingChange={setSorting}
          countQuery={prismCountQuery}
        />
      </Flex>
    </Flex>
  );
};

export default PrismCategories;
