export const KANDJI_ENROLL_CONFIG = 'kandji-enroll.mobileconfig';

export function downloadFromObjectUrl(url: string) {
  const anchor = document.createElement('a');
  anchor.href = url;

  anchor.download = KANDJI_ENROLL_CONFIG;
  document.body.appendChild(anchor);

  anchor.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );

  document.body.removeChild(anchor);
}
