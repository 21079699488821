import { Checkbox } from '@kandji-inc/bumblebee';
/* istanbul ignore file */
import React from 'react';

import { Setting } from 'features/library-items/template';

import CustomProfileService from '../service/custom-profile-service';

const InstallationCard = (props) => {
  const { setting, update, isDisabled } = props;

  if (setting.type !== CustomProfileService.installationTypes.NO_ENFORCEMENT) {
    return null;
  }

  return (
    <Setting.SecondaryControls>
      <Setting.SecondaryControlsRow>
        <Setting.Controls>
          <Checkbox
            defaultChecked
            checked={setting.allowRemove}
            label="Allow user to remove profile"
            onChange={() => update('allowRemove', (p) => !p)}
            isDisabled={isDisabled}
          />
        </Setting.Controls>
        <Setting.Helpers>
          <p className="b-txt-light">
            This option will allow a user to manually remove the Custom Profile
            on their device directly from Self Service.
          </p>
        </Setting.Helpers>
      </Setting.SecondaryControlsRow>
    </Setting.SecondaryControls>
  );
};

export default InstallationCard;
