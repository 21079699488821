const isFunction = (f) => typeof f === 'function';

const bytesToSize = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (!bytes) {
    return '0 Byte';
  }
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  return `${Math.round(bytes / 1024 ** i, 2)} ${sizes[i]}`;
};

const validateFileEnding = (file, allowedTypes) =>
  allowedTypes.some((ending) => file.name.endsWith(ending));

const getFileEnding = (file) => {
  if (file && file.name.indexOf('.') >= 0) {
    const fileSplit = file.name.split('.');
    return `.${fileSplit[fileSplit.length - 1]}`;
  }
  return '';
};
const withClass = (isTrue, className, defaultClass = '') =>
  isTrue ? className : defaultClass;

const loadGivenFile = (getFile, allowedTypes) =>
  Promise.resolve(isFunction(getFile) ? getFile() : getFile).then((r) => {
    const isCurrentFileAllowed = validateFileEnding(r.file, allowedTypes);
    if (isCurrentFileAllowed) {
      return r;
    }
    return Promise.reject(new Error('File ending is not allowed.'));
  });

export {
  isFunction,
  bytesToSize,
  validateFileEnding,
  withClass,
  getFileEnding,
  loadGivenFile,
};
