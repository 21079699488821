import { Icon } from '@kandji-inc/bumblebee';
import classNames from 'classnames';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Resizible from 'react-draggable';
import './AwesomeTableHeader.styles.scss';

const AwesomeTableHeader = ({
  onDragEnd,
  onResize,
  isDragDisabled,
  setIsDragDisabled,
  pinnedHeaders,
  draggableHeaders,
  sortFunc,
  ordering,
}) => {
  const isSortingHeader = (headerName, sortable) => {
    if (!sortable) {
      return false;
    }
    const sortField = ordering[0] === '-' ? ordering.slice(1) : ordering;
    return sortField === headerName;
  };
  const renderSortIcon = () => {
    const iconClass = ordering[0] === '-' ? 'arrow-down' : 'arrow-up';
    return <Icon name={iconClass} size="xs" />;
  };

  return (
    <div className="awesome-header-wrapper" id="header-wrapper">
      {pinnedHeaders.map((item) => {
        const headerStyle = { flex: `0 0 ${item.width}px` };
        const onSorting = () => sortFunc(item.name);
        const isSorting = isSortingHeader(item.name, item.sortable);
        return (
          <div
            className="awesome-table-header undraggable"
            key={item.name}
            role="presentation"
            data-field={item.name}
            style={headerStyle}
            onClick={item.sortable ? onSorting : null}
          >
            <Resizible
              axis="x"
              defaultClassName="DragHandle"
              defaultClassNameDragging="DragHandleActive"
              onStart={() => {
                setIsDragDisabled(true);
              }}
              onDrag={(event, { lastX, deltaX }) => {
                onResize(item, deltaX);
              }}
              onStop={() => {
                setIsDragDisabled(false);
              }}
              position={{ x: 0 }}
              zIndex={9999}
            >
              <div className="resizer-wrapper" />
            </Resizible>
            {item.label}
            {isSorting && (
              <div className="sort-icon-wrapper">{renderSortIcon()}</div>
            )}
          </div>
        );
      })}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable direction="horizontal" droppableId="droppable">
          {(provided, snapshot) => (
            <div
              className="awesome-draggable-header-wrapper"
              ref={provided.innerRef}
            >
              {draggableHeaders.map((item, index) => (
                <Draggable
                  key={item.name}
                  draggableId={item.name}
                  index={index}
                  isDragDisabled={isDragDisabled || item.pinned}
                >
                  {(provided, snapshot) => {
                    const headerStyle = {
                      flex: `0 0 ${item.width}px`,
                      ...provided.draggableProps.style,
                    };
                    const onSorting = () => sortFunc(item.name);
                    const isSorting = isSortingHeader(item.name, item.sortable);
                    return (
                      <div
                        className={classNames('awesome-table-header', {
                          'is-dragging': snapshot.isDragging,
                        })}
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        key={item.name}
                        data-field={item.name}
                        style={headerStyle}
                        onClick={item.sortable ? onSorting : null}
                      >
                        {draggableHeaders.length > index + 1 && (
                          <Resizible
                            axis="x"
                            defaultClassName="DragHandle"
                            defaultClassNameDragging="DragHandleActive"
                            onStart={() => {
                              setIsDragDisabled(true);
                            }}
                            onDrag={(event, { lastX, deltaX }) => {
                              onResize(item, deltaX);
                            }}
                            onStop={() => {
                              setIsDragDisabled(false);
                            }}
                            position={{ x: 0 }}
                            zIndex={9999}
                          >
                            <div className="resizer-wrapper">
                              <span className="resizer" />
                            </div>
                          </Resizible>
                        )}

                        <div className="dnd-icon" {...provided.dragHandleProps}>
                          <Icon name="grip-dots-vertical" />
                        </div>
                        {item.label}
                        {isSorting && (
                          <div className="sort-icon-wrapper">
                            {renderSortIcon()}
                          </div>
                        )}
                      </div>
                    );
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default AwesomeTableHeader;
