import { Button, modal as Modal } from '@kandji-inc/bumblebee';
import cn from 'classnames';
import React from 'react';

const { Header, Content, Footer } = Modal;

const NewModal = (props) => {
  const { isOpen, isOverflowYVisible, onHide, header, body, footer } = props;

  return (
    <Modal
      classes={{
        root: cn('new-modal', 'width-constraint', { isOverflowYVisible }),
      }}
      isOpen={isOpen}
      onClose={onHide}
    >
      <Button
        icon="xmark"
        kind="link"
        onClick={onHide}
        className="b-modal__close-button"
      />
      <Header text={header} />
      <Content>{body}</Content>
      <Footer className="">{footer}</Footer>
    </Modal>
  );
};

export default NewModal;
