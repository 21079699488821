/* istanbul ignore file */
import { i18n } from 'i18n';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import OldHoneyForm, {
  OldInputElement,
  OldRadioElement,
  OldTextareaElement,
  useOldField,
} from 'src/old-honey-form';

import { findByProperty } from '../../filter';
import { slugify } from '../../function';

import Dropdown from '../atoms/Dropdown';
import Icon from '../atoms/Icon';

const Form = ({ ...props }) => <OldHoneyForm {...props} />;

const FieldSelect = ({
  name,
  label,
  detail,
  disabled,
  required,
  options,
  scroll,
  ...props
}) => {
  const { getValue, getError } = useOldField(name);
  const field = getValue();
  const error = getError();
  const selected = findByProperty(options, 'value', field);
  const dropdownLabel = (
    <div className="theme-form-select-label">
      <span>{selected.label}</span>
      <i className="dropdown-toggle-caret" />
    </div>
  );
  const loopOption = (toggle, setToggle) =>
    options && (
      <div
        className={`dropdown-menu-body dropdown-menu-body-${
          scroll ? 'scroll' : 'default'
        }`}
      >
        <ul className="dropdown-menu-list">
          {options.map(({ label, value }) => {
            const id = slugify(`${name}-${label}`);
            return (
              <li
                key={`option-${id}`}
                className="theme-form-select-option theme-form-radio"
              >
                <OldRadioElement
                  name={name}
                  id={id}
                  className="theme-form-select-option-input theme-form-radio-input"
                  value={value}
                  disabled={disabled}
                  onClick={() => setToggle(!toggle)}
                />
                <label
                  htmlFor={id}
                  className="theme-form-select-option-label theme-form-radio-label"
                >
                  {label}
                </label>
              </li>
            );
          })}
        </ul>
      </div>
    );
  return (
    <div className="theme-form-select theme-form-select-full">
      {label && (
        <label className="theme-form-text-label">
          {label}
          {required && <i>*</i>}
          {!required && <span>({i18n.t('optional')})</span>}
        </label>
      )}
      {detail && typeof detail === 'string' ? (
        <p className="theme-form-text-detail">{detail}</p>
      ) : (
        detail
      )}
      <Dropdown
        name={name}
        label={dropdownLabel}
        disabled={disabled}
        render={loopOption}
        {...props}
      />
      {error && <p className="theme-form-text-error">{error}</p>}
    </div>
  );
};

FieldSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  detail: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  options: PropTypes.array.isRequired,
  scroll: PropTypes.bool,
};

FieldSelect.defaultProps = {
  label: undefined,
  detail: undefined,
  disabled: false,
  required: false,
  scroll: false,
};

const FieldText = ({
  name,
  label,
  detail,
  disabled,
  required,
  showAsteriskIfRequired,
  validateAfterSubmit,
  ...props
}) => {
  const id = slugify(name);
  const isSubmitted = useOldField('__isSubmitted').getValue();
  const errorText = useOldField(name).getError();
  let error;
  if (validateAfterSubmit) {
    error = isSubmitted && errorText;
  } else {
    error = errorText;
  }

  return (
    <div
      className={`theme-form-text theme-form-block-${
        error ? 'error' : 'valid'
      }`}
    >
      {label && (
        <label htmlFor={id} className="theme-form-text-label">
          {label}
          {required && showAsteriskIfRequired && <i>*</i>}
          {!required && <span>({i18n.t('optional')})</span>}
        </label>
      )}
      {detail && typeof detail === 'string' ? (
        <p className="theme-form-text-detail">{detail}</p>
      ) : (
        detail
      )}
      <OldInputElement
        name={name}
        id={id}
        className="theme-form-text-input"
        disabled={disabled}
        autoComplete="new-password"
        {...props}
      />
      {error && <p className="theme-form-text-error">{error}</p>}
    </div>
  );
};

FieldText.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  detail: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  showAsteriskIfRequired: PropTypes.bool,
  validateAfterSubmit: PropTypes.bool,
};

FieldText.defaultProps = {
  label: undefined,
  detail: undefined,
  disabled: false,
  required: false,
  showAsteriskIfRequired: true,
  validateAfterSubmit: false,
};

const FieldTextarea = ({
  name,
  label,
  detail,
  disabled,
  required,
  ...props
}) => {
  const id = slugify(name);
  const error = useOldField(name).getError();
  return (
    <div
      className={`theme-form-textarea theme-form-block-${
        error ? 'error' : 'valid'
      }`}
    >
      {label && (
        <label htmlFor={id} className="theme-form-textarea-label">
          {label}
          {required && <i>*</i>}
          {!required && <span>({i18n.t('optional')})</span>}
        </label>
      )}
      {detail && typeof detail === 'string' ? (
        <p className="theme-form-textarea-detail">{detail}</p>
      ) : (
        detail
      )}
      <OldTextareaElement
        name={name}
        id={id}
        className="theme-form-textarea-input"
        disabled={disabled}
        {...props}
      />
      {error && <p className="theme-form-textarea-error">{error}</p>}
    </div>
  );
};

FieldTextarea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  detail: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

FieldTextarea.defaultProps = {
  label: undefined,
  detail: undefined,
  disabled: false,
  required: false,
};

const FieldPassword = ({
  name,
  label,
  pureLabel,
  detail,
  disabled,
  required,
  validateAfterSubmit,
  wrapperClassName,
  ...props
}) => {
  const id = slugify(name);
  const isSubmitted = useOldField('__isSubmitted').getValue();
  const errorText = useOldField(name).getError();
  let error;
  if (validateAfterSubmit) {
    error = isSubmitted && errorText;
  } else {
    error = errorText;
  }

  const [isTextHidden, setIsTextHidden] = useState(true);
  const changeVisibility = () => setIsTextHidden(!isTextHidden);

  return (
    <div
      className={`theme-form-text theme-form-block-${
        error ? 'error' : 'valid'
      }${wrapperClassName ? ` ${wrapperClassName}` : ''}`}
    >
      {label && (
        <label htmlFor={id} className="theme-form-text-label">
          {label}
          {!pureLabel && (
            <>
              {required && <i>*</i>}
              {!required && <span>(optional)</span>}
            </>
          )}
        </label>
      )}
      {detail && typeof detail === 'string' ? (
        <p className="theme-form-text-detail">{detail}</p>
      ) : (
        detail
      )}
      <div className="theme-form-password-wrapper">
        <OldInputElement
          name={name}
          id={id}
          type={isTextHidden ? 'password' : 'text'}
          className="theme-form-text-input"
          disabled={disabled}
          autoComplete="new-password"
          {...props}
        />
        <Icon
          icon={isTextHidden ? 'eye-slash' : 'eye'}
          onClick={changeVisibility}
          className="field-password-eye-icon"
        />
      </div>
      {error && <p className="theme-form-text-error">{error}</p>}
    </div>
  );
};

FieldPassword.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  pureLabel: PropTypes.bool,
  detail: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  validateAfterSubmit: PropTypes.bool,
  wrapperClassName: PropTypes.string,
};

FieldPassword.defaultProps = {
  label: undefined,
  pureLabel: false,
  detail: undefined,
  disabled: false,
  required: false,
  validateAfterSubmit: false,
  wrapperClassName: '',
};

export { Form, FieldSelect, FieldText, FieldTextarea, FieldPassword };
