const sfSymbolsRegularFiles = require.context(
  './assets/sf-symbols/regular',
  true,
  /\.svg$|.png/,
);

// These are the icon names that were not matched to the sySymbolsRegularFiles
const sfSymbolRegularNotFound = {
  'externaldrive.badge.minus': '',
  'person.crop.square': '',
  'sterlingsign.circle.fill': '',
  'pencil.and.outline': '',
  message: '',
  'reecord.circle': '',
  'person.crop.square.fill.and.at.reectangle': '',
  latch_2_case: '',
};

const sfSymbolsRegularIcons = sfSymbolsRegularFiles.keys().reduce(
  (a, c) => [
    ...a,
    {
      icon: c.replace(/^.\/|[.](svg$|png$)/gi, ''),
      src: sfSymbolsRegularFiles(c),
    },
  ],
  [],
);

export { sfSymbolsRegularIcons };
