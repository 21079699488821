import map from './map';

export default (deviceFamily, modelID = null) => {
  const {
    unknown,
    unknownAppleTV,
    unknowniPad,
    unknowniPhone,
    vmParallels,
    vmVirtualBox,
    vmVMwareFusion,
    xserveNew,
    miniADP,
  } = map;

  const image = map[modelID];

  if (image) {
    return image;
  }
  if (deviceFamily === 'AppleTV') {
    return unknownAppleTV;
  }
  if (deviceFamily === 'iPad') {
    return unknowniPad;
  }
  if (deviceFamily === 'iPhone' || deviceFamily === 'iPod') {
    return unknowniPhone;
  }
  if (deviceFamily === 'Mac' && modelID) {
    if (modelID.includes('Parallels')) {
      return vmParallels;
    }
    if (modelID.includes('VirtualBox')) {
      return vmVirtualBox;
    }
    if (modelID.includes('VMware')) {
      return vmVMwareFusion;
    }
    if (modelID.includes('Xserve')) {
      return xserveNew;
    }
    if (modelID.includes('ADP')) {
      return miniADP;
    }
  }
  return unknown;
};
