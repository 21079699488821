import { theme } from 'app/common/constants';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const TextArea = styled.textarea`
  font-family: var(--font-family-primary);
  border: 1px solid ${theme.colors['grey-250']};
  background: ${theme.colors['grey-50']};
  border-radius: 5px;
  width: 100%;
  height: 100%;
  min-height: 10px;
  font-size: 14px;
  color: ${theme.colors['grey-999']};
  padding: 16px 16px;
  resize: none;
  transition: 0.3s ease;
  &:focus {
    outline: none;
    border-color: ${theme.colors['grey-400']};
  }
  :hover {
    border-color: ${theme.colors['grey-400']};
  }
  /* Width scrollbar */
  &::-webkit-scrollbar {
    width: 0.25rem;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 0.25rem;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${theme.colors['grey-400']};
    border-radius: 0.25rem;
  }
`;

const ModalTextArea = styled(TextArea)`
  &::-webkit-input-placeholder {
    color: ${theme.colors['marengo-700']};
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: ${theme.colors['marengo-700']};
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: ${theme.colors['marengo-700']};
  }
  &:-ms-input-placeholder {
    color: ${theme.colors['marengo-700']};
  }
  &::placeholder {
    color: ${theme.colors['marengo-700']};
  }
`;

const TextAreaMapping = {
  regular: TextArea,
  modal: ModalTextArea,
};

export class AwesomeTextArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      value,
      onChange,
      rows,
      name,
      placeholder,
      maxLength,
      innerRef,
      variant,
      disabled,
    } = this.props;
    const ThemedTextArea = TextAreaMapping[variant];
    return (
      <ThemedTextArea
        value={value}
        onChange={onChange}
        rows={rows}
        name={name}
        placeholder={placeholder}
        maxLength={maxLength}
        ref={innerRef}
        disabled={disabled}
      />
    );
  }
}

AwesomeTextArea.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  rows: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.string,
  innerRef: PropTypes.func,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
};

AwesomeTextArea.defaultProps = {
  onChange: null,
  rows: '3',
  name: 'description',
  placeholder: 'Enter text ...',
  maxLength: '300',
  innerRef: null,
  variant: 'regular',
  disabled: false,
};

export default AwesomeTextArea;
