import { func, string } from 'prop-types';
import React, { useEffect } from 'react';

import { MultiText as CustomMultiText } from 'src/features/util/components/multi-text';

const MultiText = ({ ariaLabel, fieldKey, updateField, getValue, ...rest }) => {
  const values = getValue(fieldKey);
  const valuesIsArray = Array.isArray(values);
  const onChange = (input) => updateField(fieldKey, input.values);

  useEffect(() => {
    if (!valuesIsArray) {
      updateField(fieldKey, []);
    }
  }, []);

  return (
    <CustomMultiText
      {...rest}
      aria-label={ariaLabel}
      onChange={onChange}
      placeholder="Type value and press enter or paste a list"
      /* If the prop value we receive is the default empty string,
      we override it with the proper 'default' value */
      values={valuesIsArray ? values : []}
    />
  );
};

MultiText.propTypes = {
  ariaLabel: string.isRequired,
  fieldKey: string.isRequired,
  updateField: func.isRequired,
  getValue: func.isRequired,
};

export default MultiText;
