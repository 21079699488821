/* istanbul ignore file -- tested in implementation */
import { api, enterpriseExternalApi, libraryAxios } from 'app/api/base';
import { URL_DEVICES, URL_MDM } from 'app/api/urls';
import { randomInteger } from '../common/helpers';

const mdmCommand = (id, commandType, requestData = null) =>
  api(`${URL_MDM}${id}/commands/`)
    .post(
      requestData
        ? { command_type: commandType, request_data: requestData }
        : { command_type: commandType },
    )
    .then((res) => res.data);

export const blankPush = (id) => mdmCommand(id, 'BlankPush');
export const logOutUser = (id) => mdmCommand(id, 'LogOutUser');
export const clearPasscode = (id) => mdmCommand(id, 'ClearPasscode');
export const restartDevice = (id) => mdmCommand(id, 'RestartDevice');
export const shutdownDevice = (id) => mdmCommand(id, 'ShutDownDevice');

export const lockDevice = (id, message = null, phoneNumber = null) => {
  const requestData = {
    PIN: `${randomInteger(111111, 999999)}`,
  };
  if (message) {
    requestData.Message = message;
  }
  if (phoneNumber) {
    requestData.PhoneNumber = phoneNumber;
  }
  return mdmCommand(id, 'DeviceLock', requestData).then((res) => res.data);
};

export const setDeviceName = (id, deviceName) =>
  mdmCommand(id, 'SetDeviceName', {
    DeviceName: deviceName,
  }).then((res) => res.data);

export const setAutoAdminPassword = (id, newPassword) =>
  mdmCommand(id, 'SetAutoAdminPassword', {
    new_password: newPassword,
  }).then((res) => res.data);

export const eraseDevice = (
  id,
  isReturnToServiceEnabled = false,
  returnToServiceProfile = null,
) =>
  mdmCommand(id, 'EraseDevice', {
    PIN: `${randomInteger(111111, 999999)}`,
    PreserveDataPlan: true,
    DisallowProximitySetup: false,
    ReturnToService: {
      Enabled: isReturnToServiceEnabled,
      // Only send the Return to Service Profile key if there is a profile selected
      ...(returnToServiceProfile === null
        ? {}
        : {
            ProfileId: returnToServiceProfile,
          }),
    },
  }).then((res) => res.data);

export const getReturnToServiceProfiles = (id) =>
  enterpriseExternalApi(`${URL_DEVICES}${id}/return-to-service-profiles`)
    .get()
    .then((res) => res.data);

export const getPin = (id) =>
  enterpriseExternalApi(`${URL_DEVICES}${id}/secrets/unlockpin`)
    .get()
    .then((res) => res.data);

export const getComputerRecoveryPasswordHistory = (id) =>
  enterpriseExternalApi(`${URL_DEVICES}${id}/secrets/recoverypasswordhistory`)
    .get()
    .then((res) => res.data);

export const reinstallAgent = (id) =>
  api(`${URL_MDM}${id}/reinstall-agent/`)
    .post({})
    .then((res) => res.data);

export const unlockUser = (id, userName) => {
  const data = {
    command_type: 'UnlockUserAccount',
    request_data: {
      UserName: userName,
    },
  };
  return api(`${URL_MDM}${id}/commands/`)
    .post(data)
    .then((res) => res.data);
};

export const deleteUser = (id, userName, deleteAllUsers) => {
  const data = {
    command_type: 'DeleteUser',
    request_data: {
      UserName: userName,
      ForceDeletion: true,
      DeleteAllUsers: deleteAllUsers,
    },
  };
  return api(`${URL_MDM}${id}/commands/`)
    .post(data)
    .then((res) => res.data);
};

export const toggleRemoteDesktop = (id, isEnabled) =>
  enterpriseExternalApi(`${URL_DEVICES}${id}/action/remotedesktop`)
    .post({
      EnableRemoteDesktop: isEnabled,
    })
    .then((res) => res.data);

export const enableLostMode = (id, body) =>
  enterpriseExternalApi(`${URL_DEVICES}${id}/action/enablelostmode`)
    .post(body)
    .then((res) => res.data);

export const disableLostMode = (id, body = {}) =>
  enterpriseExternalApi(`${URL_DEVICES}${id}/action/disablelostmode`)
    .post(body)
    .then((res) => res.data);

export const updateLostModeLocation = (id) =>
  enterpriseExternalApi(`${URL_DEVICES}${id}/action/updatelocation`)
    .post({})
    .then((res) => res.data);

export const playLostModeSound = (id) =>
  enterpriseExternalApi(`${URL_DEVICES}${id}/action/playlostmodesound`)
    .post({})
    .then((res) => res.data);

export const fetchLostModeData = (id) =>
  enterpriseExternalApi(`${URL_DEVICES}${id}/details/lostmode`)
    .get()
    .then((res) => res.data);

export const cancelLostMode = (id) =>
  enterpriseExternalApi(`${URL_DEVICES}${id}/details/lostmode`)
    .delete()
    .then((res) => res.data);

export const getCustomAppInstallStatusesForComputer = (computerId) =>
  libraryAxios('custom-apps/installs')
    .get({ computer_id: computerId })
    .then((res) => res.data);
