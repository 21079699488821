import { colors } from 'app/common/constants';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  font-weight: bold;
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 18px;
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease,
    transform 0s;
  margin: 0;
  text-transform: capitalize;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: #1a1d25;
  min-width: 50px;
  height: 28px;
  padding: 0;
  border-radius: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  &:focus {
    outline: 0;
  }
  &:active {
    transform: translateY(1px);
  }
  &:hover {
    &:not(:disabled) {
      background: none;
      box-shadow: none;
      color: #4e5361;
      svg {
        path {
          fill: #4e5361;
        }
      }
    }
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
  svg {
    path {
      fill: ${(props) => props.color || colors.black};
    }
  }
`;

const Icon = styled.span`
  margin-right: 10px;
  margin-bottom: 2px;
`;

const Name = styled.span`
  white-space: nowrap;
`;

const SimpleIconButton = ({
  onClick,
  name,
  icon,
  disabled,
  innerRef,
  type,
  className,
  color,
}) => (
  <Button
    type={type}
    color={color}
    onClick={onClick}
    disabled={disabled}
    ref={innerRef}
    className={className}
  >
    <Icon>{icon}</Icon>
    <Name>{name}</Name>
  </Button>
);

SimpleIconButton.propTypes = {
  onClick: PropTypes.func,
  name: PropTypes.string.isRequired,
  icon: PropTypes.object,
  disabled: PropTypes.bool,
  innerRef: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
};

SimpleIconButton.defaultProps = {
  onClick: null,
  icon: null,
  disabled: false,
  innerRef: null,
  type: 'button',
  className: null,
};

export default SimpleIconButton;
