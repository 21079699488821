import { ENFORCEMENT_KEYS } from 'features/library-items/common/updates-card/updates-card-constants';
import { omit } from 'lodash';
import { DateTime } from 'luxon';
import { applyRSREnforcementOptions } from 'src/features/library-items/common/updates-card/applyRSREnforcementOptions';
import { getUpdatesModel } from 'src/features/library-items/common/updates-card/getUpdatesModel';
import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import { setBlueprintsToFormData } from '../../../data-service/library-item/transformers';

async function transformFromApi(apiData) {
  const customData = apiData.data;

  const selectedBlueprints = await getAllBlueprintOptions(
    apiData.blueprints || [],
  );
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const result = {
    _data: customData,

    id: apiData.id,
    managedLibraryItemId: customData.managed_library_item_id,
    name: apiData.name,
    icon: apiData.app_icon || customData.icon_url,
    isActive: 'active' in apiData ? apiData.active : true,
    osRequirements: `iOS ${customData.minimum_os_version}+ ${
      customData.requires_supervision ? ' (Requires Supervision)' : ''
    }`,
    isPreviewChip: customData.is_preview_chip,
    instanceName: apiData.instance_name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints || false,
    rules: apiData.rules,
  };

  result.updates = getUpdatesModel(customData);

  return {
    ...apiData,
    data: result,
  };
}

const createTransformToApi =
  ({ selectedTimezone }) =>
  (model) => {
    const toSend = new FormData();

    toSend.set('active', model.isActive);
    toSend.set('instance_name', model.instanceName || '');
    toSend.set('type', 'ios-release');
    toSend.set('name', model.name);
    toSend.set('rules', JSON.stringify(model.rules) || null);
    setBlueprintsToFormData(toSend, model.selectedBlueprints);
    toSend.set('is_all_blueprints', model.isAllBlueprints);

    if (model.skip_blueprint_conflict) {
      toSend.set('skip_blueprint_conflict', model.skip_blueprint_conflict);
    }

    const enf = model.updates.versionEnforcement.value;
    const detailsUrl = model.updates.detailsUrl;

    let data = {
      ...omit(model._data, [
        'version_enforcement',
        'enforcement_time',
        'enforcement_timezone',
        'minimum_app_version',
        'enforce_after',
        'enforcement_delay',
      ]),
      version_enforcement: enf,
      details_url: detailsUrl,
    };

    data = applyRSREnforcementOptions(data, model.updates);

    if (enf !== ENFORCEMENT_KEYS.any) {
      data.enforcement_time = model.updates.enforcementTime.value;
      data.enforcement_timezone = selectedTimezone.value;
    }

    switch (enf) {
      case ENFORCEMENT_KEYS.custom:
        data.minimum_app_version = model.updates.minimumVersion.value;
        data.enforce_after = DateTime.fromJSDate(
          model.updates.enforcementDeadline,
        ).toFormat('MM/dd/yyyy');
        break;
      case ENFORCEMENT_KEYS.newest:
        data.enforcement_delay = model.updates.enforcementDelay.value;
    }

    toSend.set('data', JSON.stringify(data));

    return toSend;
  };

export { createTransformToApi, transformFromApi };
