/* istanbul ignore file */
import * as React from 'react';

export function useDynamicImport(importFunction: () => Promise<any>) {
  const [npmModule, setNpmModule] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    async function loadPackage() {
      try {
        const { default: moduleDefault } = await importFunction();
        setNpmModule(() => moduleDefault);
      } catch {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    }

    loadPackage();
  }, []);

  return [npmModule, isLoading, error];
}
