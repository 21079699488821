import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class AppStoreAppsService extends NewLibraryItemService {
  static installationTypes = {
    CONTINUOUSLY_ENFORCE: 'continuously_enforce',
    NO_ENFORCEMENT: 'no_enforcement',
  };

  static KANDJI_SS_BUNDLE_IDS = [
    'io.kandji.Self-Service-Mobile',
    'io.kandji.Self-Service-Mobile.internal',
  ];

  static getIsKandjiSelfService({ bundleId }) {
    return AppStoreAppsService.KANDJI_SS_BUNDLE_IDS.includes(bundleId);
  }
}

export const appStoreAppsService = new AppStoreAppsService();

export default AppStoreAppsService;
