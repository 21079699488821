import { bytesToSize } from '@kandji-inc/bumblebee';
import { Badge } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import type { IpaFile } from '../in-house-apps.types';

interface IPAUploadRenderInfoProps {
  isDisabled: boolean;
  ipaFile: IpaFile;
}

export function IPAUploadRenderInfo({
  isDisabled,
  ipaFile,
}: IPAUploadRenderInfoProps) {
  const { fileName, fileSize, appId, appVersion, sha256 } = ipaFile;

  return (
    <div>
      <div className="b-flex-col">
        <span className={`b-txt-bold ${isDisabled && 'b-txt-light'}`}>
          {fileName}{' '}
          <Badge>
            {i18n.t('Version')} {appVersion}
          </Badge>
        </span>
        <span className="b-txt-light">
          {bytesToSize(fileSize)} - {i18n.t('Bundle ID')}: {appId}
        </span>
        <span className="b-txt-light">SHA-256: {sha256}</span>
      </div>
    </div>
  );
}

export function IPAUploadInstructions() {
  return (
    <span>
      {i18n.ut(
        'Drag file here or <span class="b-alink">click to upload</span>',
      )}{' '}
    </span>
  );
}
