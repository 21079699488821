import { Icon } from '@kandji-inc/bumblebee';
import { api } from 'app/api/base';
import { withPermissions } from 'contexts/account';
import debounce from 'lodash/debounce';
/* istanbul ignore file */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import NoDataToDisplay from '../../assets/img/no_data_to_display.png';
import { updateComputerInfo } from '../_actions/computer';
import { setModal, setSnackbar } from '../_actions/ui';
import {
  URL_APPLICATION_INVENTORY,
  URL_APPLICATION_INVENTORY_INFO,
} from '../api/urls';
import { colors } from '../common/constants';
import { BootstrapTable, TableHeaderColumn } from './common/BootstrapTable';
import { Table } from './common/Table';
import TableNoDataHelper from './common/TableNoDataHelper';
import { formatBytes, formatTime } from './common/helpers';
import { checkField } from './computer/computerHelpers';
import { InfoBanner } from './good/banners';
import AwesomeDropdown from './interface/AwesomeDropdown';
import AwesomeTableToolBar from './interface/AwesomeTableToolBar';
import { HoveredSpan } from './interface/Base';
import { SyncIcon } from './interface/IconsSVG';
import { LineLoader } from './interface/LineLoader';
import { H6 } from './interface/Typography';
import SimpleIconButton from './interface/buttons/SimpleIconButton';

const queryString = require('query-string');

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 30px;
  font-family: var(--font-body-s-family);
  font-size: var(--font-body-s-size);
  line-height: var(--font-body-s-line-height);
  font-weight: var(--font-body-s-weight);
  letter-spacing: var(--font-body-s-letter-spacing);
  color: var(--color-neutral-100);
`;

const SyncWrapper = styled.div`
  display: flex;
  align-content: space-between;
`;

const Label = styled.span`
  font-weight: 400;
  margin-right: 10px;
`;

const Value = styled.span`
  color: ${colors['grey-600']};
`;

const SimpleIconButtonStyled = styled(SimpleIconButton)`
  margin-top: -2px;
`;

const SyncInfo = styled('div')`
  font-family: var(--font-body-s-family);
  font-size: var(--font-body-s-size);
  line-height: var(--font-body-s-line-height);
  font-weight: var(--font-body-s-weight);
  letter-spacing: var(--font-body-s-letter-spacing);
  align-items: center;
  text-transform: none;
  color: #181c24;
  width: 600px;
`;

const SyncTitle = styled('div')`
  font-family: var(--font-heading-xs-family);
  font-size: var(--font-heading-xs-size);
  line-height: var(--font-heading-xs-line-height);
  font-weight: var(--font-heading-xs-weight);
  letter-spacing: var(--font-heading-xs-letter-spacing);
  margin-right: 14px;
  padding-right: 16px;
  border-right: 1px solid #e2e2e2;
`;

const BoldText = styled('b')`
  font-weight: 500;
`;

const getAppInventoryInfo = (computerId) =>
  api(URL_APPLICATION_INVENTORY_INFO)
    .get({ computer_id: computerId })
    .then((response) => response.data);

class ApplicationInventoryTab extends Table {
  constructor(props) {
    super(props);
    this.serverSidePagination = true; // Don't remove! It's used on parent
    this.serverSideSorting = true; // Don't remove! It's used on parent
    this.state = {
      ...this.state,
      currPage: 1,
      isSyncing: false,
      widths: {},
      percentWidths: {},
      sortName: 'name',
      sortOrder: 'asc',
      showFilters: false,
      isLoadingTab: true,
    };
    this.fetchFunc = this.fetchApplication.bind(this);
    this.fetchApplication = this.fetchApplication.bind(this);
    this.formatApplicationActionsRow =
      this.formatApplicationActionsRow.bind(this);
  }

  componentDidMount() {
    const { computer } = this.props;
    this.setState({ isLoadingTab: true });
    const promises = [
      this.fetchData(true),
      getAppInventoryInfo(computer.id).then((appInventoryInfo) =>
        this.setState({ appInventoryInfo }),
      ),
    ];
    Promise.all(promises).then(() => this.setState({ isLoadingTab: false }));
  }

  makeQueryParams = () => {
    // Don't remove! It's used on parent
    const { location, computer } = this.props;
    const paginationParams = {
      page: this.state.currPage,
      sizePerPage: this.state.sizePerPage,
    };

    return {
      ...paginationParams,
      ...queryString.parse(location.search, { arrayFormat: 'bracket' }),
      computer_id: computer.id,
    };
  };

  queryApplication = (params) => {
    const { filters } = params;
    const { computer } = this.props;
    const url =
      computer.device_family === 'Mac'
        ? URL_APPLICATION_INVENTORY
        : `/mdm/${computer.id}/installed-apps/`;
    const parameters =
      computer.device_family === 'Mac'
        ? {
            ...params,
            filters: JSON.stringify(filters),
          }
        : {
            computer_id: computer.id,
            ...params,
            filters: JSON.stringify(filters),
          };
    return api(url)
      .get(parameters)
      .then((res) => res.data)
      .catch(() => ({ items: [] }));
  };

  fetchApplication(queryParams) {
    return this.queryApplication(queryParams)
      .then((res) => Promise.resolve(res))
      .catch((err) => console.log(err));
  }

  onSyncClick = () => {
    const { computer, setSnackbar: callSetSnackbar } = this.props;
    this.setState({ isSyncing: true });
    callSetSnackbar('App Inventory Request Initiated');
    updateComputerInfo(computer.id, [
      'InstalledApplicationList',
      'ManagedApplicationConfiguration',
    ]).finally(() => {
      this.fetchData(true);
      this.setState({ isSyncing: false });
    });
  };

  formatApplicationActionsRow = (cell, row) => {
    const applicationActions = [
      {
        action: () =>
          this.props.setModal('COMPUTER_APPLICATIONS_BLACKLIST', row),
        iconClass: 'ban',
        label: 'Block Application',
      },
    ];
    return <AwesomeDropdown horizontal="right" options={applicationActions} />;
  };

  onSortChange = (sortName, sortOrder) => {
    this.setState({ currPage: 1, sortName, sortOrder });
    this.changeFilterInUrl({
      ordering: this.getOrdering(sortName, sortOrder),
      page: 1,
    });
  };

  getOrdering = (sortName, sortOrder) =>
    sortOrder === 'desc' ? `-${sortName}` : sortName;

  macExpandComponent = (row) => (
    <div style={{ paddingTop: 10 }}>
      <H6>Signed With</H6>
      <ul style={{ paddingLeft: 15 }}>
        {row.signed_with.map((item) => (
          <li style={{ paddingTop: 5 }}>{item}</li>
        ))}
      </ul>
      <H6>Path</H6>
      <p>{row.path}</p>
    </div>
  );

  iOsExpandComponent = (row) => (
    <div style={{ paddingTop: 10 }}>
      <H6>Other info</H6>
      <p>
        {`Download Cancelled: ${checkField(
          'download_cancelled',
          row.download_cancelled,
        )}`}
      </p>
      <p>
        {`Download Failed: ${checkField(
          'download_failed',
          row.download_failed,
        )}`}
      </p>
      <p>
        {`Download Paused: ${checkField(
          'download_paused',
          row.download_paused,
        )}`}
      </p>
      <p>
        {`Download Waiting: ${checkField(
          'download_waiting',
          row.download_waiting,
        )}`}
      </p>
      <p>
        {`Has Update Available: ${checkField(
          'has_update_available',
          row.has_update_available,
        )}`}
      </p>
      <p>
        {`Ad Hoc Code Signed: ${checkField(
          'ad_hoc_code_signed',
          row.ad_hoc_code_signed,
        )}`}
      </p>
      <p>{`Beta App: ${checkField('beta_app', row.beta_app)}`}</p>
      <p>{`Dynamic Size: ${formatBytes(row.dynamic_size)}`}</p>
      <p>{`Validated: ${checkField('is_validated', row.is_validated)}`}</p>
      <p>
        {`External Version Identifier: ${checkField(
          'external_version_identifier',
          row.external_version_identifier,
        )}`}
      </p>
      <p>{`Version: ${checkField('version', row.version)}`}</p>
      <p>{`Installing: ${checkField('installing', row.installing)}`}</p>
    </div>
  );

  expandColumnComponent = ({ isExpandableRow, isExpanded }) => {
    let content = '';
    if (isExpandableRow) {
      content = isExpanded ? (
        <Icon name="angle-down" size="xs" className="c-grey-500" />
      ) : (
        <Icon name="angle-right" size="xs" className="c-grey-999" />
      );
    } else {
      content = ' ';
    }
    return content;
  };

  renderNoDataView() {
    const { isLoading } = this.state;
    if (isLoading) {
      return <LineLoader />;
    }
    return (
      <TableNoDataHelper
        borderless
        key="##table-empty##"
        message="No data to display"
        image={NoDataToDisplay}
      />
    );
  }

  render() {
    const { computer, permissions } = this.props;
    const {
      appInventoryInfo,
      isLoading,
      isLoadingTab,
      sortName,
      sortOrder,
      currPage,
      isSyncing,
    } = this.state;

    const options = {
      ...this.defaultOptions,
      sortName: sortName || 'name',
      sortOrder: sortOrder || 'asc',
      defaultSortName: sortName || 'name',
      defaultSortOrder: sortOrder || 'asc',
      onSortChange: this.onSortChange.bind(this),
      noDataView: this.renderNoDataView(),
      page: currPage,
    };

    delete options.toolBar;
    delete options.btnGroup;

    if (isLoadingTab) {
      return <LineLoader />;
    }

    const macDataField = [
      <TableHeaderColumn
        dataField="name"
        columnClassName="column-border-right"
        className="header-column-border-right"
        dataSort
      >
        Name
      </TableHeaderColumn>,
      <TableHeaderColumn dataField="version" dataSort>
        Version
      </TableHeaderColumn>,
      <TableHeaderColumn dataField="obtained_from" dataSort>
        Obtained From
      </TableHeaderColumn>,
      <TableHeaderColumn dataField="bundle_identifier" dataSort>
        Bundle Identifier
      </TableHeaderColumn>,
      <TableHeaderColumn dataField="process_name" dataSort>
        Process
      </TableHeaderColumn>,
      <TableHeaderColumn dataField="path" dataSort>
        Path
      </TableHeaderColumn>,
      <TableHeaderColumn
        dataField="last_modified"
        dataFormat={(cell) => (
          <HoveredSpan
            text={formatTime(cell)}
            hoveredText={formatTime(cell, null, null, null, true)}
          />
        )}
        dataSort
      >
        Last Modified
      </TableHeaderColumn>,
      permissions.canManageDevices && (
        <TableHeaderColumn
          dataFormat={this.formatApplicationActionsRow}
          expandable={false}
          thStyle={{ width: 50 }}
          tdStyle={{ overflow: 'visible', width: 50 }}
        />
      ),
      <TableHeaderColumn dataField="id" hidden isKey />,
    ];

    const iOsDataField = [
      <TableHeaderColumn
        dataField="name"
        columnClassName="column-border-right"
        className="header-column-border-right"
        dataSort
      >
        Name
      </TableHeaderColumn>,
      <TableHeaderColumn dataField="identifier" dataSort>
        Identifier
      </TableHeaderColumn>,
      <TableHeaderColumn dataField="short_version" dataSort>
        Short Version
      </TableHeaderColumn>,
      <TableHeaderColumn
        dataField="bundle_size"
        dataFormat={(cell, row) => formatBytes(row.bundle_size)}
        dataSort
      >
        Bundle Size
      </TableHeaderColumn>,
      <TableHeaderColumn dataField="app_store_vendable" dataSort>
        App Store Vendable
      </TableHeaderColumn>,
      <TableHeaderColumn dataField="device_based_vpp" dataSort>
        Device Based VPP
      </TableHeaderColumn>,
      <TableHeaderColumn dataField="id" hidden isKey />,
    ];

    const URLparams = queryString.parse(location.search, {
      arrayFormat: 'bracket',
    });

    return (
      <>
        {computer.device_family !== 'Mac' && (
          <InfoBanner
            text="This lists third-party apps installed on this device. Built-in iOS apps are not inventoried."
            styles={{
              width: '750px',
              background: '#e6e7ea',
            }}
          />
        )}
        {appInventoryInfo && appInventoryInfo.created_at__max && (
          <Wrapper>
            <Label>Last Update:</Label>
            <Value>
              {formatTime(
                appInventoryInfo.created_at__max,
                null,
                null,
                null,
                true,
              )}
            </Value>
          </Wrapper>
        )}
        <SyncWrapper>
          {computer.device_family !== 'Mac' && (
            <Wrapper>
              <SyncInfo>
                <SyncTitle>
                  {'Last updated '}
                  <BoldText>
                    {formatTime(
                      computer.mdm_applications_updated_at,
                      null,
                      null,
                      null,
                      true,
                    )}
                  </BoldText>
                </SyncTitle>
                {permissions.canManageDevices && (
                  <SimpleIconButtonStyled
                    disabled={isSyncing}
                    name={isSyncing ? 'Updating' : 'Update now'}
                    icon={SyncIcon}
                    onClick={this.onSyncClick}
                  />
                )}
              </SyncInfo>
            </Wrapper>
          )}
          <AwesomeTableToolBar
            btnGroup={this.renderActionButtons()}
            defaultValue={URLparams.search}
            searchLabel="Search"
            searchFunc={debounce(
              (searchString) =>
                this.changeFilterInUrl({ search: searchString, page: 1 }),
              400,
            )}
            searchIconPosition="right"
          />
        </SyncWrapper>
        {isLoading ? (
          <LineLoader />
        ) : (
          <BootstrapTable
            ref={(node) => (this.table = node)}
            data={this.state.data}
            expandableRow={() => true}
            expandComponent={
              computer.device_family === 'Mac'
                ? this.macExpandComponent
                : this.iOsExpandComponent
            }
            expandColumnOptions={{
              expandColumnVisible: true,
              expandColumnComponent: this.expandColumnComponent,
              columnWidth: 36,
            }}
            remote={() => ({
              pagination: true,
            })}
            version="4"
            pagination
            trClassName="users-table-tr"
            containerClass="bst-borderless old-table"
            containerStyle={{
              marginBottom: '82px',
            }}
            options={options}
          >
            {computer.device_family === 'Mac' &&
              macDataField.map((field) => field)}
            {computer.device_family !== 'Mac' &&
              iOsDataField.map((field) => field)}
          </BootstrapTable>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setModal,
      setSnackbar,
    },
    dispatch,
  );

export default connect(
  null,
  mapDispatchToProps,
)(withPermissions(ApplicationInventoryTab));
