import * as React from 'react';

export function useWhiteBackground() {
  React.useEffect(() => {
    document.body.classList.add('white-bg');
    return () => {
      document.body.classList.remove('white-bg');
    };
  }, []);
}
