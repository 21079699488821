import {
  TextInput,
  setClass,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import React from 'react';
import validator from 'validator';

import { Setting } from 'features/library-items/template';
import { i18n } from 'src/i18n';

const OrganizationDetails = (props) => {
  const {
    setting,
    update,
    className,
    isDisabled,
    defaultOrgDetails,
    isSubmitted,
  } = props;

  const fieldsToValidate = ['phone', 'email'];
  const { refs, onInvalidate, invalidations } = useInputsValidators(
    fieldsToValidate,
    update,
  );
  useRemoveValidationOnUnmount(fieldsToValidate, update);

  const updateOrgDetails = (k, v) => {
    update('orgDetails', (p) => ({
      ...p,
      [k]: typeof v === 'function' ? v(p[k]) : v,
    }));
  };

  return (
    <Setting.SecondaryControls className={setClass(className)}>
      <Setting.SecondaryControlsRow>
        <div ref={refs[0]} className={setClass(invalidations[0] && 'b-mb2')}>
          <TextInput
            disabled={isDisabled}
            name="phone"
            value={setting.phoneNumber}
            onChange={(e) => updateOrgDetails('phoneNumber', e.target.value)}
            fieldsGrid
            type="tel"
            compact
            placeholder={defaultOrgDetails.phoneNumber}
            label={i18n.t('Support phone number')}
            isOptional
            // CORE-758: There is intentionally no validation here, any arbitrary string is allowed
          />
        </div>
      </Setting.SecondaryControlsRow>
      <Setting.SecondaryControlsRow>
        <div ref={refs[1]} className={setClass(invalidations[1] && 'b-mb2')}>
          <TextInput
            disabled={isDisabled}
            name="email"
            value={setting.email}
            onChange={(e) => updateOrgDetails('email', e.target.value)}
            fieldsGrid
            type="tel"
            compact
            placeholder={defaultOrgDetails.email}
            label={i18n.t('Support email address')}
            isOptional
            validator={(v) => [
              {
                message: i18n.t('Invalid email address'),
                invalid: () => v !== '' && !validator.isEmail(v),
                trigger: [
                  'onBlur',
                  ...(isSubmitted ? ['onInput', 'onMount'] : []),
                ],
              },
            ]}
            onInvalidate={onInvalidate(1)}
          />
        </div>
      </Setting.SecondaryControlsRow>
      <Setting.SecondaryControlsRow>
        <div>
          <TextInput
            disabled={isDisabled}
            value={setting.location}
            onChange={(e) => updateOrgDetails('location', e.target.value)}
            fieldsGrid
            compact
            placeholder={defaultOrgDetails.location}
            label={i18n.t('Department or location')}
            isOptional
            maxLength={100}
            hideMaxLength
          />
        </div>
      </Setting.SecondaryControlsRow>
    </Setting.SecondaryControls>
  );
};

export default OrganizationDetails;
