import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-family: 'Sharp Sans', sans-serif;
  padding: 10px 8px;
  color: var(--b-link-blue-normal);
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-style: none;
  border: none;
  border-image: none;
  opacity: 1;

  &:hover {
    background-color: var(--b-primary-marengo-ultralight);
    color: var(--b-link-blue-normal);
    text-decoration: none;
  }

  &:disabled {
    color: #1a1d2566;
    &:hover {
      color: #1a1d2566;
    }
    svg {
      path {
        fill: #1a1d2566;
      }
    }
  }

  .temp-btn__icon {
    margin-right: 8px;
    transform: translateY(-1px);
  }
`;

// * For temporary use until the design update for component library arrives
function TempButton(props) {
  const { icon, children, onClick, href, className, ...rest } = props;

  const btnProps = href
    ? {
        as: 'a',
        href,
      }
    : {
        onClick,
      };

  return (
    <StyledButton
      className={cn('temp-btn', className)}
      type="button"
      {...rest}
      {...btnProps}
    >
      <span className="temp-btn__icon">{icon}</span>
      {children}
    </StyledButton>
  );
}

export default TempButton;
