import type {
  GetByThreatsResponse,
  ThreatClassification,
} from '../../../../threat.types';

const fromResponseFormat = (
  response: GetByThreatsResponse,
): GetByThreatsResponse => {
  const responseTransformedFromApi: GetByThreatsResponse = {
    ...response,
    data: response.data.map((threat) => ({
      ...threat,
      classification:
        threat.classification?.toUpperCase() as ThreatClassification,
    })),
  };

  return responseTransformedFromApi;
};

export default fromResponseFormat;
