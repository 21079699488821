import uuidv4 from 'uuid/v4';
import { extensionTypes } from './sections/extensions/constants';
import SingleSignOnExtensionService from './service/single-sign-on-extension.service';

export default {
  name: '',
  isActive: true,
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  details: {
    // Details
    type: extensionTypes.credential,
    extension_identifier: '',
    team_identifier: '',
    realm: '',
    hosts: [{ _id: uuidv4(), value: '' }],
    DeniedBundleIdentifiers: null,
    urls: [{ _id: uuidv4(), value: '' }],

    // Kerberos password options
    disallow_automatic_login: false,
    disallow_password_change: false,
    require_complex_passwords: false,
    password_history: '', // number
    isPasswordHistory: false, // local boolean
    password_length: '', // number
    isPasswordLength: false, // local boolean
    password_requirements_text: '',
    isPasswordRequirementsText: false, // local boolean
    sync_local_password: false,
    password_change_url: '',
    isChangePasswordURL: false, // local boolean
    max_password_age: '', // number
    isMaximumPasswordAge: false, // local boolean
    password_expiration_notification: '', // number
    isPasswordExpirationNotification: false, // local boolean
    min_password_age: '', // number
    isMinimumPasswordAge: false, // local boolean

    // Kerberos advanced
    cache_name: '',
    isCacheName: false, // local boolean
    bundle_id_access: [{ _id: uuidv4(), value: '' }],
    isBundleIdAccess: false, // local boolean
    autofill_username: '',
    isAutoFillUsername: false, // local boolean
    default_realm: false,
    require_user_presence: false,
    site_code: '',
    isSiteCode: false, // local boolean
    disable_site_auto_discovery: false,
    custom_username_label: '',
    isCustomUsernameLabel: false, // local boolean
    delay_user_setup: false,
    help_text: '',
    isHelpText: false, // local boolean
    include_managed_apps_in_bundle_id_ACL: false,
    monitor_credentials_cache: false,
    replication_time: '', // number
    isReplicationTime: false, // local boolean
    allowSmartCard: false,
    allowPassword: false,
    startInSmartCardMode: false,
    identityIssuerAutoSelectFilter: null,

    // Extension details
    isExtensionDetails: false, // local boolean
    extension_details: '',

    invalidationsMap: {},
  },

  platform: {
    PlatformSSOActive: false,
    AuthenticationMethod:
      SingleSignOnExtensionService.AuthorizationMethod.Password, // Password, UserSecureEnclaveKey, SmartCard
    UserAuthorizationMode: 'Standard', // Standard, Admin, Groups
    NewUserAuthorizationMode: 'Standard', // Standard, Admin, Groups
    UseSharedDeviceKeys: false,
    EnableAuthorization: false,
    EnableCreateUserAtLogin: false,
    AccountDisplayName: '',
    LoginFrequency: 64800, // min: 3601
    AccountName: '', // TokenToUserMapping
    FullName: '', // TokenToUserMapping
    AdministratorGroups: [], // Array<string>
    AdditionalGroups: [], // Array<string>
    AuthorizationGroups: [],
    FileVaultPolicy:
      SingleSignOnExtensionService.PolicyAuthenticationOptions.Attempt, // AttemptAuthentication, RequireAuthentication
    LoginPolicy:
      SingleSignOnExtensionService.PolicyAuthenticationOptions.Attempt, // AttemptAuthentication, RequireAuthentication
    UnlockPolicy:
      SingleSignOnExtensionService.PolicyAuthenticationOptions.Attempt, // AttemptAuthentication, RequireAuthentication
    OfflineGracePeriod: 120,
    AuthenticationGracePeriod: 120,
    NonPlatformSSOAccounts: null,
  },

  supportsRules: true,
};
