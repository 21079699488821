import moment from 'moment';
import type { VersionDateRsr } from './updates-card.types';

const SECONDS_IN_DAY = 60 * 60 * 24;

interface RsrBannerData {
  version: string;
  extraVersion: string;
  date: number;
  enforcedInstallDate: number;
}

function buildRsrBannerData({
  version,
  extraVersion,
  date,
  enforcementDelay,
}: {
  version: string;
  extraVersion: string;
  date: number;
  enforcementDelay: number;
}): RsrBannerData {
  return {
    version,
    extraVersion,
    date,
    enforcedInstallDate: date + enforcementDelay * SECONDS_IN_DAY,
  };
}

function fromRsrVersions(
  versions: VersionDateRsr[],
  enforcementDelay: number,
): RsrBannerData[] {
  return versions.map(({ osVersion, extraVersion, releaseDate }) =>
    buildRsrBannerData({
      version: osVersion,
      extraVersion,
      date: releaseDate,
      enforcementDelay,
    }),
  );
}

function installationHasBeenApplied(enforcedInstallDate: number) {
  const now = Math.round(Date.now() / 1000);
  return enforcedInstallDate <= now;
}

function hasOverlappingRsr(
  bannerIndex: number,
  bannerDataList: RsrBannerData[],
): RsrBannerData | null {
  let overlappingRsr: RsrBannerData | null = null;
  const bannerData = bannerDataList[bannerIndex];

  for (let i = 0; i < bannerDataList.length; i++) {
    const d = bannerDataList[i];
    const isSameVersion = bannerData.version === d.version;
    const isOverlapping =
      d.date < bannerData.enforcedInstallDate && d.date >= bannerData.date;

    if (isOverlapping && isSameVersion && i !== bannerIndex) {
      const isNewest = overlappingRsr == null || d.date > overlappingRsr.date;
      overlappingRsr = isNewest ? d : overlappingRsr;
    }
  }

  return overlappingRsr;
}

function buildAppOsName(
  appName: string,
  version: string,
  extraVersion: string,
) {
  return `${appName} ${version}${extraVersion}`;
}

export const getRsrBanners = ({
  rsrVersions = [],
  appName,
  enforcementDelay,
}: {
  rsrVersions: VersionDateRsr[];
  appName: string;
  enforcementDelay: number;
}) => {
  const bannerDataList = fromRsrVersions(rsrVersions, enforcementDelay);
  const banners: Array<{ version: string; text: string }> = [];

  for (let i = 0; i < bannerDataList.length; i++) {
    const bannerData = bannerDataList[i];
    const { version, extraVersion, enforcedInstallDate } = bannerData;
    const enforcementDate = moment.unix(enforcedInstallDate);
    const formattedEnforcementDate = enforcementDate.format('MMMM D, YYYY');
    const overlappingRsr = hasOverlappingRsr(i, bannerDataList);

    if (!installationHasBeenApplied(enforcedInstallDate)) {
      if (overlappingRsr != null) {
        banners.push({
          version,
          text: `${buildAppOsName(
            appName,
            version,
            overlappingRsr.extraVersion,
          )} has replaced ${buildAppOsName(
            appName,
            version,
            extraVersion,
          )} and will be the minimum allowed version on ${formattedEnforcementDate}.`,
        });
      } else {
        banners.push({
          version,
          text: `${buildAppOsName(
            appName,
            version,
            extraVersion,
          )} will be the minimum allowed version on ${formattedEnforcementDate}.`,
        });
      }
    }
  }

  return banners;
};
