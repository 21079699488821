import classSet from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Const from './Const';
import ExpandRowHeaderColumn from './ExpandRowHeaderColumn';
import SelectRowHeaderColumn from './SelectRowHeaderColumn';
import Utils from './util';

class Checkbox extends Component {
  componentDidMount() {
    this.update(this.props.checked);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.update(props.checked);
  }

  update(checked) {
    ReactDOM.findDOMNode(this).indeterminate = checked === 'indeterminate';
  }

  render() {
    return (
      <input
        className="react-bs-select-all"
        type="checkbox"
        checked={this.props.checked}
        onChange={this.props.onChange}
      />
    );
  }
}

const getSortOrder = (sortList, field, enableSort) => {
  if (!enableSort) {
    return undefined;
  }
  const result = sortList.filter((sortObj) => sortObj.sortField === field);
  if (result.length > 0) {
    return result[0].order;
  }
  return undefined;
};

class TableHeader extends Component {
  render() {
    const {
      sortIndicator,
      sortList,
      onSort,
      reset,
      version,
      condensed,
      bordered,
      expandedColumnHeaderComponent,
      noAnyExpand,
      toggleExpandAllChilds,
      expandAll,
    } = this.props;
    const containerClasses = classSet(
      'react-bs-container-header',
      'table-header-wrapper',
      this.props.headerContainerClass,
    );
    const customTableClasses = {
      'table-bordered': bordered,
    };
    if (condensed) {
      if (Utils.isBootstrap4(version)) {
        customTableClasses['table-sm'] = true;
      } else {
        customTableClasses['table-condensed'] = true;
      }
    }
    const tableClasses = classSet(
      'table',
      'table-hover',
      customTableClasses,
      this.props.tableHeaderClass,
    );

    const rowCount = Math.max(
      ...React.Children.map(this.props.children, (elm) =>
        elm && elm.props.row ? Number(elm.props.row) : 0,
      ),
    );

    const rows = [];
    let rowKey = 0;

    rows[0] = [];
    rows[0].push(
      [
        this.props.expandColumnVisible &&
          this.props.expandColumnBeforeSelectColumn && (
            <ExpandRowHeaderColumn
              key="expandCol"
              rowCount={rowCount + 1}
              expandedColumnHeaderComponent={expandedColumnHeaderComponent}
              noAnyExpand={noAnyExpand}
              expandAll={expandAll}
              toggleExpandAllChilds={toggleExpandAllChilds}
            />
          ),
      ],
      [this.renderSelectRowHeader(rowCount + 1, (rowKey += 1))],
      [
        this.props.expandColumnVisible &&
          !this.props.expandColumnBeforeSelectColumn && (
            <ExpandRowHeaderColumn
              key="expandCol"
              rowCount={rowCount + 1}
              expandedColumnHeaderComponent={expandedColumnHeaderComponent}
              noAnyExpand={noAnyExpand}
              expandAll={expandAll}
              toggleExpandAllChilds={toggleExpandAllChilds}
            />
          ),
      ],
    );

    React.Children.forEach(this.props.children, (elm) => {
      if (elm === null || elm === undefined) {
        // Skip null or undefined elements.
        return;
      }
      const { dataField, dataSort } = elm.props;
      const sort = getSortOrder(sortList, dataField, dataSort);
      const thStyle =
        sort !== undefined ? { ...elm.props.thStyle } : elm.props.thStyle;
      const rowIndex = elm.props.row ? Number(elm.props.row) : 0;
      const rowSpan = elm.props.rowSpan ? Number(elm.props.rowSpan) : 1;
      if (rows[rowIndex] === undefined) {
        rows[rowIndex] = [];
      }
      if (rowSpan + rowIndex === rowCount + 1) {
        rows[rowIndex].push(
          React.cloneElement(elm, {
            reset,
            key: (rowKey += 1),
            onSort,
            sort,
            sortIndicator,
            isOnlyHead: false,
            version,
            thStyle,
          }),
        );
      } else {
        rows[rowIndex].push(
          React.cloneElement(elm, {
            key: (rowKey += 1),
            isOnlyHead: true,
            version,
          }),
        );
      }
    });

    const trs = rows.map((row, indexRow) => <tr key={indexRow}>{row}</tr>);

    return (
      <div
        ref={(node) => (this.container = node)}
        className={containerClasses}
        style={this.props.style}
      >
        <table className={tableClasses}>
          {React.cloneElement(this.props.colGroups, {
            ref: (node) => (this.headerGrp = node),
          })}
          <thead ref={(node) => (this.header = node)}>{trs}</thead>
        </table>
      </div>
    );
  }

  getHeaderColGroup = () => this.headerGrp.childNodes;

  renderSelectRowHeader(rowCount, rowKey) {
    if (this.props.hideSelectColumn) {
      return null;
    }
    if (this.props.customComponent) {
      const CustomComponent = this.props.customComponent;
      return (
        <SelectRowHeaderColumn key={rowKey} rowCount={rowCount}>
          <CustomComponent
            type="checkbox"
            checked={this.props.isSelectAll}
            indeterminate={this.props.isSelectAll === 'indeterminate'}
            disabled={false}
            onChange={this.props.onSelectAllRow}
            rowIndex="Header"
          />
        </SelectRowHeaderColumn>
      );
    }
    if (this.props.rowSelectType === Const.ROW_SELECT_SINGLE) {
      return <SelectRowHeaderColumn key={rowKey} rowCount={rowCount} />;
    }
    if (this.props.rowSelectType === Const.ROW_SELECT_MULTI) {
      return (
        <SelectRowHeaderColumn key={rowKey} rowCount={rowCount}>
          <Checkbox
            onChange={this.props.onSelectAllRow}
            checked={this.props.isSelectAll}
          />
        </SelectRowHeaderColumn>
      );
    }
    return null;
  }
}

TableHeader.propTypes = {
  headerContainerClass: PropTypes.string,
  tableHeaderClass: PropTypes.string,
  style: PropTypes.object,
  rowSelectType: PropTypes.string,
  onSort: PropTypes.func,
  onSelectAllRow: PropTypes.func,
  sortList: PropTypes.array,
  hideSelectColumn: PropTypes.bool,
  bordered: PropTypes.bool,
  condensed: PropTypes.bool,
  isFiltered: PropTypes.bool,
  isSelectAll: PropTypes.oneOf([true, 'indeterminate', false]),
  sortIndicator: PropTypes.bool,
  customComponent: PropTypes.func,
  colGroups: PropTypes.element,
  reset: PropTypes.bool,
  expandColumnVisible: PropTypes.bool,
  expandColumnComponent: PropTypes.func,
  expandedColumnHeaderComponent: PropTypes.func,
  expandColumnBeforeSelectColumn: PropTypes.bool,
  version: PropTypes.string,
  noAnyExpand: PropTypes.bool,
  expandAll: PropTypes.bool,
  toggleExpandAllChilds: PropTypes.func,
};

export default TableHeader;
