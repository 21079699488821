import {
  Button,
  Checkbox,
  Chip,
  Flex,
  Icon,
  NoContent,
  sidePanel as SidePanel,
  TextInput,
  setClass,
} from '@kandji-inc/bumblebee';
import deepcopy from 'deepcopy';
import { array, arrayOf, bool, func, object, shape, string } from 'prop-types';
import React, { useState, useRef } from 'react';
import { i18n } from 'src/i18n';
/* istanbul ignore file */ // TODO: Add tests for this component
import './skip-screens.css';

function SkipRow({ screen, onChange }) {
  return (
    <div className="k-skip-row">
      <div className="k-skip-row__option">
        <Checkbox
          onChange={onChange}
          checked={screen.isSelected}
          label={
            <span>
              {i18n.t('Skip')}{' '}
              <span className="b-txt-bold">{screen.label}</span>
            </span>
          }
        />
      </div>
      <div className="k-skip-row__description">
        <p className="b-txt-light b-mb">{screen.description}</p>
        <Flex>
          {screen.requirements.map((req) => (
            <Chip key={req} kind="tertiary-light" text={req} />
          ))}
        </Flex>
      </div>
    </div>
  );
}

SkipRow.propTypes = {
  screen: shape({
    isSelected: bool.isRequired,
    label: string.isRequired,
    description: string.isRequired,
    requirements: array.isRequired,
  }).isRequired,
  onChange: func.isRequired,
};

const SkipScreens = ({
  panelConfig,
  screens,
  setScreens,
  disabled = false,
  hasError = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filterTerm, setFilterTerm] = useState('');
  const [tempScreens, setTempScreens] = useState(deepcopy(screens));
  const skipScreensRef = useRef(null);

  const selectedScreens = screens.filter((v) => v.isSelected);
  const isAnySelected = tempScreens.some((v) => v.isSelected);
  const isAllSelected = tempScreens.every((v) => v.isSelected);

  const openOptions = () => {
    setTempScreens(deepcopy(screens));
    setIsOpen(true);
  };

  const toggleOption = (toSkip) => {
    setTempScreens((p) => {
      const c = [...p];
      const idx = c.findIndex((s) => s.id === toSkip.id);
      c[idx].isSelected = !c[idx].isSelected;
      return c;
    });
  };

  const handleSelectAll = () => {
    if (isAllSelected) {
      setTempScreens((p) => p.map((v) => ({ ...v, isSelected: false })));
    } else if ((isAnySelected && !isAllSelected) || !isAllSelected) {
      setTempScreens((p) => p.map((v) => ({ ...v, isSelected: true })));
    }
  };

  const filterByTerm = (term) => (option) => {
    const termKey = term.toLowerCase();
    const optionLabelAndDesc =
      `${i18n.t('skip')} ${option.label} ${option.description}`.toLowerCase();

    return optionLabelAndDesc.includes(termKey);
  };

  const onSave = () => {
    setScreens(deepcopy(tempScreens));
    setIsOpen(false);
  };

  const filterResult = tempScreens.filter(filterByTerm(filterTerm));

  const isAnyButNotAllSelectedIcon =
    isAnySelected && !isAllSelected ? 'horizontal-rule' : 'check';

  return (
    <div
      ref={skipScreensRef}
      className={setClass('b-flex', hasError ? 'b-mb' : '')}
    >
      <div
        className={setClass(
          'k-skip-screen-tags',
          hasError ? 'k-skip-screen-tags--error' : '',
        )}
      >
        <div className="k-skip-screen-tags__main">
          {!selectedScreens.length ? (
            <p className="b-txt">
              <button
                onClick={openOptions}
                type="button"
                className={setClass([
                  hasError ? 'b-txt b-txt--error' : 'b-alink b-decorate-off',
                  disabled && 'b-alink--disabled',
                ])}
                disabled={disabled}
              >
                {i18n.t('Specify the screens to skip')}
              </button>
            </p>
          ) : (
            <div className="b-flex-wrap-gmicro">
              {selectedScreens.map((s) => (
                <Chip key={s.id} kind="tertiary" text={s.label} />
              ))}
            </div>
          )}
        </div>
        <div className="k-skip-screen-tags__icon">
          <button
            type="button"
            onClick={openOptions}
            className={setClass([
              'k-skip-screen-tags__icon-button',
              'b-alink',
              'b-decorate-off',
              disabled && 'b-alink--disabled',
            ])}
            disabled={disabled}
          >
            <Icon name="pencil" />
          </button>
        </div>
      </div>
      {hasError && (
        <div className="b-txt-input__err-msg">
          <Icon name="octagon-exclamation" className="b-txt-input__err-icon" />
          <p className="b-txt b-txt--error">{i18n.t('Required')}</p>
        </div>
      )}
      <SidePanel isVisible={isOpen} size="default">
        <div className="k-skip-screens-panel b-side-panel-layout">
          <div className="b-side-panel-layout__header --skip-screen-header">
            <h2 className="b-h2 k-skip-title">{panelConfig.title}</h2>
            <div className="b-flex-vg2 k-skip-screen-search">
              <p className="b-txt">{panelConfig.helper}</p>
              <TextInput
                icon={filterTerm ? 'circle-xmark' : 'magnifying-glass'}
                onIconClick={() => {
                  if (filterTerm.length) {
                    setFilterTerm('');
                  }
                }}
                value={filterTerm}
                onChange={(e) => setFilterTerm(e.target.value)}
              />
              <Checkbox
                checked={isAnySelected}
                icon={isAnyButNotAllSelectedIcon}
                onChange={handleSelectAll}
                label={
                  <p className="b-txt-bold">{panelConfig.selectAllLabel}</p>
                }
              />
            </div>
          </div>
          <div
            className="b-side-panel-layout__body b-mb3"
            style={{ paddingTop: 0 }}
          >
            {!filterResult.length && (
              <NoContent
                header={i18n.t('No results found')}
                text={i18n.t(
                  'We couldn’t find a match. Try using a different keyword or description.',
                )}
                className="b-mt3"
              />
            )}
            <div className="k-skip-rows">
              {filterResult.map((s) => (
                <SkipRow
                  key={s.id}
                  screen={s}
                  onChange={() => toggleOption(s)}
                />
              ))}
            </div>
          </div>
          <div className="b-side-panel-layout__footer b-mt3">
            <div className="b-flex-justify-end">
              <div className="b-grid-ctas">
                <Button kind="outline" onClick={() => setIsOpen(false)}>
                  {i18n.t('Cancel')}
                </Button>
                <Button onClick={onSave}>{i18n.t('Save')}</Button>
              </div>
            </div>
          </div>
        </div>
      </SidePanel>
    </div>
  );
};

SkipScreens.propTypes = {
  panelConfig: shape({
    title: string.isRequired,
    helper: string.isRequired,
    selectAllLabel: string.isRequired,
  }).isRequired,
  screens: arrayOf(object).isRequired,
  setScreens: func.isRequired,
  disabled: bool,
  hasError: bool,
};

export default SkipScreens;
