import { useEffect, useRef } from 'react';

const usePreviousValue = (value, watch = []) => {
  const ref = useRef();
  const deps = [value, ...watch];
  useEffect(() => {
    ref.current = value;
  }, deps);
  return ref.current;
};

export default usePreviousValue;
