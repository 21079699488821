import { Icon } from '@kandji-inc/bumblebee';
import { array, bool, func, object, string } from 'prop-types';
import React, { useRef } from 'react';
import Menu from '../../menu';
import './icon-upload.css';

/**
 * Doesn't currently handle uploading, essentially an icon file selecter.
 * However, when necesary, can add progress and loading.
 */
const IconUpload = ({
  className,
  style,
  allowedTypes,
  isDisabled,
  onFileSelect,
  onDelete,
  currentIcon,
  disabledIcon,
  disabledMenu,
}) => {
  const inputRef = useRef();

  return (
    <Menu isDisabled={disabledMenu || isDisabled}>
      <div>
        <a
          href=""
          onClick={(e) => {
            e.preventDefault();
            if (inputRef && inputRef.current) {
              inputRef.current.click();
            }
          }}
        >
          <Icon name="pencil" />
          Upload
        </a>
        {currentIcon && (
          <a
            href=""
            style={{ color: 'var(--color-red-50' }}
            onClick={(e) => {
              e.preventDefault();
              if (onDelete) {
                onDelete(currentIcon);
              }
            }}
          >
            <Icon name="trash-can" />
            Delete
          </a>
        )}
      </div>
      <div
        className={`b-icon-up-xs ${
          !currentIcon && !isDisabled ? 'b-icon-up-xs-container' : ''
        } ${
          isDisabled
            ? `b-icon-up-xs-container__disabled${
                currentIcon ? '__with-icon' : ''
              }`
            : ''
        } ${currentIcon ? 'b-icon-up-xs-container__loaded' : ''} ${
          className || ''
        } b-flex-vc b-flex-hc`}
        style={style}
      >
        <input
          className="b-icon-up-xs__input"
          type="file"
          accept={allowedTypes.join(',')}
          disabled={isDisabled}
          ref={inputRef}
          onChange={(e) => {
            if (onFileSelect) {
              onFileSelect(e.target.files[0]);
            }
          }}
        />
        {currentIcon ? (
          <img
            className="b-icon-up-xs-img b-icon-up-xs__loaded"
            src={currentIcon}
            alt=""
            style={{ cursor: isDisabled ? null : 'pointer' }}
          />
        ) : (
          <a
            href=""
            className={`decorate-off b-icon-up-xs__icon ${
              isDisabled ? 'b-icon-up-xs-icon__disabled' : ''
            }`}
            onClick={(e) => e.preventDefault()}
          >
            {isDisabled ? (
              <i className={`bi bi-${disabledIcon}`} />
            ) : (
              <Icon name="circle-plus" />
            )}
          </a>
        )}
      </div>
    </Menu>
  );
};

IconUpload.propTypes = {
  className: string,
  style: object,
  allowedTypes: array,
  isDisabled: bool,
  onFileSelect: func,
  onDelete: func,
  disabledIcon: string,
  currentIcon: string,
  disabledMenu: bool,
};

IconUpload.defaultProps = {
  className: '',
  style: {},
  allowedTypes: ['.png', '.jpg', '.jpeg'],
  isDisabled: false,
  onFileSelect: () => {},
  onDelete: () => {},
  disabledIcon: 'file',
  currentIcon: '',
  disabledMenu: false,
};

export default IconUpload;
