import { useQuery } from '@tanstack/react-query';
import { prismService } from '../data-service/prism-service';
import type { PrismCategoryParamsSchema } from '../types/prism.types';

export const useCategoryQuery = ({
  uri,
  params = {
    limit: 25,
    offset: 0,
  },
  filterBody,
}: {
  uri: string;
  params?: PrismCategoryParamsSchema;
  filterBody?: Record<string, unknown>;
}) =>
  useQuery({
    enabled: Boolean(uri),
    queryKey: ['prism-category', uri, params, filterBody],
    queryFn: async () =>
      prismService.UNSAFE_getCategoryData(uri, params, filterBody),
  });
