import { colors } from 'app/common/constants';
import { InterfaceContext } from 'contexts/interface';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { H2 } from './Typography';

const TableHeaderWrapper = styled.section`
  background-color: var(--color-neutral-10);
  height: 80px;
  z-index: 30;
  ${(props) => props.sticky && 'position: sticky;'}
  ${(props) => props.sticky && `top: ${props.bannerTopOffset}px`};
  display: flex;
  align-items: center;
  justify-content: space-between;
  //width: 100%;
  margin: 0 -10px 33px -10px;
  padding: 33px 10px 33px 10px;
  // padding-bottom: ${(props) => props.paddingBottom || '14px'};
  .actions {
    display: flex;
    flex-direction: row;
    margin-left: 1rem;
    > div {
      margin-left: 5px;
    }
    @media (max-width: 767px) {
      margin-top: 1rem;
      margin-left: 0;
      div:first-child {
        margin-left: 0;
      }
    }
  }
`;

const TableHeaderChildren = styled.section`
  display: flex;
  flex-flow: row wrap;
  &:first-child {
    margin-right: auto;
  }
`;

const TitleWrapper = styled.section`
  display: flex;
  align-items: center;
  color: ${colors['marengo-700']};
  height: 39px;
`;

const DescriptionWrapper = styled.section`
  ${(props) => props.sticky && 'position: sticky'};
  top: 115px;
  background-color: var(--color-neutral-10);
  z-index: 999;
`;

const Description = styled.section`
  margin-bottom: 10px;
`;

const DescriptionChildren = styled.section`
  display: flex;
  flex-flow: row wrap;
  padding-bottom: 20px;
`;

export const TableHeader = ({
  title,
  children,
  sticky,
  bColor,
  paddingBottom,
  style,
}) => {
  const { bannerTopOffset } = useContext(InterfaceContext);

  return (
    <TableHeaderWrapper
      style={style}
      sticky={sticky}
      bColor={bColor}
      paddingBottom={paddingBottom}
      bannerTopOffset={bannerTopOffset}
    >
      <TitleWrapper>
        <H2>{title}</H2>
      </TitleWrapper>
      <TableHeaderChildren>{children}</TableHeaderChildren>
    </TableHeaderWrapper>
  );
};

TableHeader.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.string,
  ]),
  sticky: PropTypes.bool,
  bColor: PropTypes.string,
  paddingBottom: PropTypes.string,
  style: PropTypes.object,
};

TableHeader.defaultProps = {
  children: null,
  sticky: false,
  bColor: 'button-secondary-new-bg',
  paddingBottom: null,
  style: {},
};

export const HoveredSpan = ({ text, hoveredText }) => {
  const [isHovered, setHovered] = useState(false);
  return (
    <span
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {isHovered ? hoveredText : text}
    </span>
  );
};

HoveredSpan.propTypes = {
  text: PropTypes.string.isRequired,
  hoveredText: PropTypes.string.isRequired,
};

export const TableHeaderDescription = ({
  description,
  sticky,
  bColor,
  children,
}) => (
  <DescriptionWrapper sticky={sticky} bColor={bColor}>
    <Description>{description}</Description>
    <DescriptionChildren>{children}</DescriptionChildren>
  </DescriptionWrapper>
);

TableHeaderDescription.propTypes = {
  description: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.string,
  ]),
  bColor: PropTypes.string,
  sticky: PropTypes.bool,
};

TableHeaderDescription.defaultProps = {
  description: null,
  children: null,
  bColor: 'var(--color-neutral-10)',
  sticky: false,
};
