/* istanbul ignore file - tested during implementation */

import { store } from 'app/_store/rootStore';
import { AwaitingEnrollment, EnrollmentTypes } from 'app/common/constants';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { i18n } from 'src/i18n';
import { getDevelopmentEnvironmentName } from 'src/util';
import { parse } from 'uri-js';
import history from '../../router/history';

const momentBase = require('moment-timezone');

export const getStatusIconClass = (status, muted, deferred, missing) => {
  if (status === 'STOPPED') {
    return 'fas fa-exclamation-triangle text-warning';
  }

  if (deferred) {
    return 'far fa-clock text-grey';
  }
  if (missing) {
    return 'fa fa-question-circle text-grey';
  }

  switch (status) {
    case 'PASS':
      return 'fas fa-check-circle text-success';
    case 'REMEDIATED':
      return 'fas fa-check-square text-light-blue';
    case 'ERROR':
    case 'WARNING':
      return `fas fa-exclamation-triangle ${
        muted ? 'text-grey' : 'text-warning'
      }`;
    case 'INCOMPATIBLE':
      return 'fas fa-times-circle text-grey';
    case 'MISSING':
      return 'fas fa-question-circle text-grey';
    case 'MUTE':
      return 'fas fa-exclamation-triangle text-grey';
    default:
      return 'far fa-circle text-grey';
  }
};

export const getStatusColorClass = (status, deferred, missing) => {
  if (status === 'STOPPED') {
    return 'c-orange-500';
  }
  if (deferred || missing) {
    return 'c-grey-300';
  }

  switch (status) {
    case 'PASS':
    case 'installed':
    case 'success':
      return 'c-green-500';
    case 'REMEDIATED':
      return 'c-blue-400';
    case 'ERROR':
    case 'WARNING':
    case 'MUTE':
    case 'failed':
      return 'c-orange-500';
    case 'INSTALLING':
    case 'INCOMPATIBLE':
    case 'MISSING':
    case 'EXCLUDED':
    case 'AVAILABLE':
    case 'CHANGES_PENDING':
    default:
      return 'c-grey-300';
  }
};

export const getStatusBackgroundColorClass = (
  status,
  muted,
  deferred,
  missing,
) => {
  if (status === 'STOPPED') {
    return 'bg-orange-500';
  }
  if (deferred || missing) {
    return 'bg-grey-300';
  }

  switch (status) {
    case 'PASS':
    case 'REMEDIATED':
      return 'bg-green-500';
    // return 'bg-marengo-640';
    case 'ERROR':
    case 'WARNING':
    case 'LOST':
      return `${muted ? 'bg-grey-300' : 'bg-orange-500'}`;
    case 'INCOMPATIBLE':
    case 'MISSING':
    case 'MUTE':
    default:
      return 'bg-grey-300';
  }
};

export const getStatusBadgeColor = (status, muted, deferred, missing) => {
  if (status === 'STOPPED') {
    return 'yellow';
  }
  if (deferred || missing) {
    return 'neutral';
  }

  switch (status) {
    case 'PASS':
    case 'REMEDIATED':
      return 'green';
    case 'ERROR':
    case 'WARNING':
    case 'LOST':
      return `${muted ? 'neutral' : 'yellow'}`;
    case 'INCOMPATIBLE':
    case 'MISSING':
    case 'MUTE':
    default:
      return 'neutral';
  }
};

export const getStatusComputersIconClass = (
  status,
  _muted,
  deferred,
  missing,
) => {
  if (deferred) {
    return 'far fa-clock';
  }
  if (missing) {
    return 'fa fa-question-circle';
  }

  switch (status) {
    case 'PASS':
    case 'REMEDIATED':
    case 'INCOMPATIBLE':
      return 'fas fa-check';
    case 'ERROR':
    case 'WARNING':
    case 'MUTE':
      return 'fas fa-exclamation-triangle';
    case 'MISSING':
      return 'fas fa-question-circle';
    default:
    case 'EMPTY':
      return 'far fa-circle';
  }
};

export const getStatusCircleIconClass = (status, isMissing, deferred) => {
  if (deferred || isMissing) {
    return 'fas fa-circle';
  }
  switch (status) {
    case 'MUTE':
    case 'EMPTY':
    case 'LOST':
      return 'far fa-circle';
    case 'INCOMPATIBLE':
      return 'fas fa-times-circle';
    case 'INSTALLING':
    default:
      return 'fas fa-circle';
  }
};

export const getStatusComputersCircleIconClass = (
  status,
  isMissing,
  deferred,
) => {
  if (deferred || isMissing) {
    return 'fas fa-circle';
  }
  switch (status) {
    case 'PASS':
    case 'REMEDIATED':
      return '';
    case 'MUTE':
    case 'EMPTY':
    case '':
      return 'far fa-circle';
    default:
      return 'fas fa-circle';
  }
};

export const getStatusComputersColorClass = (
  status,
  _muted,
  deferred,
  missing,
) => {
  if (deferred) {
    return 'c-grey-300';
  }

  if (missing) {
    return 'c-yellow-500';
  }

  const statusType = status.toLowerCase();
  const classes = {
    pass: 'c-green-500',
    remediated: 'c-marengo-700',
    incompatible: 'c-green-500',
    error: 'c-orange-500',
    warning: 'c-orange-500',
    missing: 'c-yellow-500',
    mute: 'c-orange-500',
    empty: 'c-grey-300',
    defaultClass: '',
  };

  const statusClass = classes[statusType];
  if (statusClass) {
    return statusClass;
  }

  return classes.defaultClass;
};

export const getTextStatusComputersColorClass = (
  status,
  _muted,
  deferred,
  missing,
) => {
  if (deferred) {
    return 'c-grey-300';
  }

  if (missing) {
    return 'c-yellow-500';
  }

  const statusType = status.toLowerCase();
  const classes = {
    pass: 'c-green-500',
    remediated: 'c-green-500',
    incompatible: 'c-green-500',
    error: 'c-orange-500',
    warning: 'c-orange-500',
    missing: 'c-yellow-500',
    mute: 'c-orange-500',
    empty: 'c-grey-300',
    lost: 'c-orange-500',
    defaultClass: '',
  };

  const statusClass = classes[statusType];
  if (statusClass) {
    return statusClass;
  }

  return classes.defaultClass;
};

export const getParameterHtml = (paramName, timestamp, item) => {
  const formatedTimestamp = `${moment(timestamp)
    .utcOffset(-480)
    .format('HH:mm a, MMM D YYYY')} PST`;
  let result = `<blockquote>Parameter: ${paramName}<br>Status: ${item.status}<br>`;
  result += `Last Run: ${formatedTimestamp}<br>Build: ${item.version}<br>`;
  const details = item.details || [];
  details.forEach((el, i, arr) => {
    result += i + 1 === arr.length ? `${el}` : `${el}<br>`;
  });
  result += '</blockquote><p></p>';
  return result;
};

export const getHelperText = (status, missing, deferred) => {
  if (deferred) {
    return i18n.t('Awaiting First Run');
  }

  if (missing) {
    return i18n.t('Offline');
  }

  const statusType = status.toLowerCase();

  const messages = i18n.createMap({
    pass: () => i18n.t('All Clear'),
    incompatible: () => i18n.t('All Clear'),
    remediated: () => i18n.t('Remediated'),
    error: () => i18n.t('Alert'),
    warning: () => i18n.t('Alert'),
    missing: () => i18n.t('Offline'),
    mute: () => i18n.t('Mute'),
    empty: () => i18n.t('No History'),
  });

  const msg = messages(statusType);
  if (msg) {
    return msg;
  }

  return messages('empty');
};

export const getHelperTextForResults = (status) => {
  const statusType = status.toLowerCase();
  const messages = i18n.createMap({
    pass: () => i18n.t('Passed'),
    remediated: () => i18n.t('Remediated'),
    error: () => i18n.t('Alert'),
    warning: () => i18n.t('Alert'),
    incompatible: () => i18n.t('Incompatible'),
    mute: () => i18n.t('Muted Alert'),
    defaultMessage: () => i18n.t('No History'),
  });

  const msg = messages(statusType);
  if (msg) {
    return msg;
  }

  return messages('defaultMessage');
};

export const getHelperTextForStatus = (status) => {
  const statusType = status.toLowerCase();
  const messages = i18n.createMap({
    success: () => i18n.t('Success'),
    failed: () => i18n.t('Failed'),
    installing: () => i18n.t('Pending'),
    pending: () => i18n.t('Pending'),
    installed: () => i18n.t('Installed'),
    incompatible: () => i18n.t('Incompatible'),
    mute: () => i18n.t('Muted Alert'),
    excluded: () => i18n.t('Excluded'),
    defaultMessage: status,
  });

  const msg = messages(statusType);
  if (msg) {
    return msg;
  }

  return messages('defaultMessage');
};

export const relativeFormat = (preferredFormat = null) => ({
  lastDay: '[Yesterday]',
  sameDay(now) {
    const diff = momentBase.duration(now.diff(this));
    if (parseInt(diff.asHours(), 10) > 1) {
      return i18n.t(`[{diffHours} hours ago]`, {
        diffHours: parseInt(diff.asHours(), 10),
      });
    }
    if (parseInt(diff.asHours(), 10) === 1) {
      return i18n.t(`[{diffHours} hour ago]`, {
        diffHours: parseInt(diff.asHours(), 10),
      });
    }
    if (parseInt(diff.asMinutes(), 10) > 1) {
      return i18n.t(`[{diffMinutes} minutes ago]`, {
        diffMinutes: parseInt(diff.asMinutes(), 10),
      });
    }
    if (parseInt(diff.asMinutes(), 10) === 1) {
      return i18n.t(`[{diffMinutes} minute ago]`, {
        diffMinutes: parseInt(diff.asMinutes(), 10),
      });
    }
    return i18n.t('[less than 1 minute ago]');
  },
  nextDay: i18n.t('[Tomorrow]'),
  lastWeek: preferredFormat || 'L',
  nextWeek: preferredFormat || 'L',
  sameElse: preferredFormat || 'L',
});

export const DATE_FORMAT = {
  options: ['MM/DD/YYYY', 'DD/MM/YYYY', 'YYYY/MM/DD'],
  default: 'MM/DD/YYYY',
};

export const formatTime = (
  timestamp,
  hideSeconds,
  errorMessage,
  dateOnly = false,
  pass_relativity = false,
  format = DATE_FORMAT.default,
) => {
  let momentObject;
  if (typeof timestamp === 'number') {
    momentObject = moment.unix(Number(timestamp.toString().substring(0, 10)));
  } else {
    momentObject = moment(timestamp);
  }

  const userSettings = store.getState().account.user.settings;
  const preferredFormat = userSettings?.preferred_date_format || format;

  const userTimezone =
    get(userSettings, 'timezone') ||
    Intl.DateTimeFormat().resolvedOptions().timeZone ||
    'UTC';
  const disable_relativity =
    get(userSettings, 'disable_relative_dates') === 'disable';

  if (disable_relativity || pass_relativity) {
    return momentObject
      .tz(userTimezone)
      .format(hideSeconds ? preferredFormat : `${preferredFormat} [at] LTS`);
  }

  if (!momentObject.isValid()) {
    return (
      i18n.t('{errorMessage}', { errorMessage }) ||
      i18n.t('Invalid timestamp format')
    );
  }
  if (dateOnly) {
    return momentObject.tz(userTimezone).format(preferredFormat);
  }

  // check to make sure we pass 'MM/DD/YYYY' momentObject to i18
  const returnObject = momentObject
    .tz(userTimezone)
    .calendar(null, relativeFormat(preferredFormat));

  if (
    returnObject === moment(returnObject, 'MM/DD/YYYY').format(preferredFormat)
  ) {
    return i18n.formatDateTime(new Date(timestamp));
  }

  return returnObject;
};

export function momentToLuxon(
  momentFormat,
  {
    splitFunc = (str) => str.split('/'),
    buildFunc = (arr) => arr.join('/'),
  } = {},
) {
  const splitFormat = splitFunc(momentFormat);
  return buildFunc(
    splitFormat.map((part) => {
      if (['D', 'Y'].includes(part[0])) {
        return part.toLowerCase();
      }
      return part;
    }),
  );
}

export function useDateFormat({
  defaultFormat = DATE_FORMAT.default,
  asLuxon = false,
} = {}) {
  const { preferred_date_format } = useSelector(
    (state) => state.account.user.settings,
  );

  const format = preferred_date_format || defaultFormat;
  return asLuxon ? momentToLuxon(format) : format;
}

export const sortSubCategories = (a, b) => {
  if (a.category.name === b.category.name) {
    if (a.subcategory.name === b.subcategory.name) {
      if (a.weight > b.weight) {
        return 1;
      }
      if (a.weight < b.weight) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
    } else if (a.subcategory.name === 'No Subcategory') {
      return -1;
    }
    if (b.subcategory.name === 'No Subcategory') {
      return 1;
    }
    if (a.subcategory.weight > b.subcategory.weight) {
      return 1;
    }
    if (a.subcategory.weight < b.subcategory.weight) {
      return -1;
    }
    if (a.subcategory.name > b.subcategory.name) {
      return 1;
    }
    if (a.subcategory.name < b.subcategory.name) {
      return -1;
    }
  } else {
    if (a.category.weight > b.category.weight) {
      return 1;
    }
    if (a.category.weight < b.category.weight) {
      return -1;
    }
    if (a.category.name > b.category.name) {
      return 1;
    }
    if (a.category.name < b.category.name) {
      return -1;
    }
  }
  return 0;
};

export const moment = momentBase;

export const scrollToTab = () => {
  const elem = document.querySelector('.computer-tabs');
  if (!elem) {
    return;
  }
  elem.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  });
};

export const cleanStorageForPaginationAndFilters = () => {
  localStorage.removeItem('pagination.currentPage');
  localStorage.removeItem('activeTableFilters');
  localStorage.removeItem('blueprintsTablePageNumber');
};

// in this kind of functions we need "this", so we can't use arrow functions!!!
Element.prototype.documentOffsetTop = function documentOffsetTop() {
  return (
    this.offsetTop +
    (this.offsetParent ? this.offsetParent.documentOffsetTop() : 0)
  );
};

// in this kind of functions we need "this", so we can't use arrow functions!!!
String.prototype.capitalize =
  String.prototype.capitalize ||
  function capitalize() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  };

export const getFileName = (name, ext) => {
  let filename = name;
  if (getDevelopmentEnvironmentName()) {
    filename += `-${getDevelopmentEnvironmentName()}`;
  }
  filename += moment().format('-YYYYMMDD');
  return filename + ext;
};

export const saveAs = (uri, filename) => {
  const link = document.createElement('a');
  link.href = uri;
  link.download = filename;
  link.dispatchEvent(
    new MouseEvent('click', { bubbles: true, cancelable: true, view: window }),
  );
};

export const downloadFile = (data, filename, type = 'text/plain') => {
  const blob = new Blob([data], { type });
  const anchor = document.createElement('a');
  anchor.download = filename;
  anchor.href = window.URL.createObjectURL(blob);
  anchor.dataset.downloadurl = [type, anchor.download, anchor.href].join(':');
  anchor.dispatchEvent(
    new MouseEvent('click', { bubbles: true, cancelable: true, view: window }),
  );
};

export const parseDateFromFilter = (date) => {
  const periods = [
    'day',
    'days',
    'week',
    'weeks',
    'month',
    'months',
    'year',
    'years',
  ];
  const regExp = /^(\d+)\s(\w+)\sago$/;
  const parsed = regExp.exec(date);
  let parsedDate;
  if (parsed && periods.includes(parsed[2])) {
    parsedDate = moment().subtract(parsed[1], parsed[2]);
  } else {
    parsedDate = moment(date, ['M/D/YY', 'M/D/YYYY'], true);
  }
  if (!parsedDate.isValid()) {
    return null;
  }
  return parsedDate;
};

export const getFiltersFromUrl = (location, pure = true) => {
  const urlParams = new URLSearchParams(location.search);
  const queryStringFilters = urlParams.get('filters');
  let activeFilters = [];
  if (queryStringFilters) {
    activeFilters = JSON.parse(queryStringFilters);
  }
  const availableFilters = [
    'blueprint',
    'status',
    'computerName',
    'model',
    'serial',
    'lastCheckIn',
    'firstEnrollment',
    'lastEnrollment',
    'os',
    'assetUser',
    'assetTag',
    'agentVersion',
    'description',
    'color',
    'deviceFamily',
    'deviceAssignedBy',
    'deviceAssignedDate',
    'enrollmentStatus',
    'lastEnrollment',
  ];
  const totalFilters = [];

  activeFilters.forEach((filter) => {
    if (availableFilters.includes(filter.name)) {
      if (pure) {
        totalFilters.push({
          name: filter.name,
          operator: filter.operator,
          value: filter.value,
        });
      } else {
        totalFilters.push({
          name: filter.name,
          operator: filter.operator,
          value: filter.value,
          state: 'exist',
        });
      }
    }
  });

  return totalFilters;
};

export const setFiltersToUrl = (formFilters, viewId) => {
  const options = [];
  if (formFilters) {
    formFilters.forEach((filter) => {
      if (!Object.keys(filter).length) {
        return;
      }
      const option = {};

      switch (filter.name) {
        case 'blueprint':
        case 'status':
        case 'computerName':
        case 'model':
        case 'serial':
        case 'os':
        case 'assetUser':
        case 'assetTag':
        case 'agentVersion':
        case 'description':
        case 'color':
        case 'deviceFamily':
        case 'deviceAssignedBy':
        case 'enrollmentStatus':
        case 'lastCheckIn':
        case 'firstEnrollment':
        case 'lastEnrollment':
        case 'deviceAssignedDate':
          option.name = filter.name;
          option.value = filter.value;
          option.operator = filter.operator;

          options.push(option);
          break;
        default:
          break;
      }
    });
  }

  if (!isEmpty(options)) {
    /* istanbul ignore next */
    history.push(
      `${window.location.pathname}?filters=${encodeURIComponent(
        JSON.stringify(options),
      )}${viewId ? `&viewId=${viewId}` : ''}`,
    );
  } else {
    history.push(`${window.location.pathname}`);
  }
  return options;
};

export const getTopOffset = (forBanner) => {
  const blocked = false;
  const blockedHeight = blocked ? 40 : 0;
  if (forBanner) {
    return blockedHeight;
  }
  const bannersHeight = get(
    document.getElementById('UniversalAlertsLayer'),
    'clientHeight',
    0,
  );
  return blockedHeight + bannersHeight;
};

export const sortFuncDate = (a, b, order, sortField) => {
  let name;
  switch (sortField) {
    case 'lastRun':
      name = 'lastRun';
      break;
    case 'firstEnrollment':
      name = 'firstEnrollment';
      break;
    case 'lastEnrollment':
      name = 'lastEnrollment';
      break;
    case 'lastCheckIn':
      name = 'lastCheckIn';
      break;
    default:
      name = sortField;
  }
  const aDate = a[name] ? new Date(a[name]) : new Date();
  const bDate = b[name] ? new Date(b[name]) : new Date();
  if (order === 'asc') {
    return aDate - bDate;
  }
  return bDate - aDate;
};

// return the caret position of the textare
HTMLTextAreaElement.prototype.getCaretPosition = () => this.selectionStart;
// change the caret position of the textarea
HTMLTextAreaElement.prototype.setCaretPosition = (position) => {
  this.selectionStart = position;
  this.selectionEnd = position; // Not delete
  this.focus();
};

export const randomInteger = (min, max) => {
  const rand = min - 0.5 + Math.random() * (max - min + 1);
  return Math.round(rand);
};

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) {
    return '0 Bytes';
  }

  // const k = 1024; // this is wrong
  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export const isDEPDeviceEnrolled = (mdmDevice) =>
  mdmDevice && !mdmDevice.is_removed;

export const getDEPEnrollmentStatus = (mdmDevice) => {
  if (isDEPDeviceEnrolled(mdmDevice)) {
    if (mdmDevice.enrollment_status === EnrollmentTypes.MDM.id) {
      return EnrollmentTypes.MDM.value;
    }
    return EnrollmentTypes.DEP.value;
  }
  return 'Not Enrolled';
};

export const getDEPDeviceName = (mdmDevice) => {
  if (isDEPDeviceEnrolled(mdmDevice) && mdmDevice.name) {
    return mdmDevice.name;
  }
  return AwaitingEnrollment;
};

export const getDEPLastEnrollment = (mdmDevice) => {
  if (isDEPDeviceEnrolled(mdmDevice)) {
    formatTime(mdmDevice.enrolled_at, true);
  }
  return 'No info';
};

export const getDEPAssignHelperTitle = (status, receivedAt, failedAttempts) => {
  const receivedAtText =
    receivedAt !== null
      ? ` - ${formatTime(receivedAt, null, null, null, true)}`
      : '';
  switch (status) {
    case 'SUCCESS':
      return `Profile Assigned${receivedAtText}`;
    case 'FAILED':
      return `Profile Assignment Failed (${failedAttempts} times)${receivedAtText}`;
    default:
      return 'Profile Assignment Pending';
  }
};

export const getDEPAssignHelperText = (status) => {
  switch (status) {
    case 'SUCCESS':
      return 'Assigned';
    case 'FAILED':
      return 'Failed';
    default:
      return 'Pending';
  }
};

export const isMacOSDevice = (deviceFamily) => deviceFamily === 'Mac';

export const isM1Device = (deviceModel) => deviceModel.includes('M1');

export const isIpad = () => {
  const ua = window.navigator.userAgent;
  if (/iPad/i.test(ua)) {
    return true;
  }

  if (/Macintosh/i.test(ua)) {
    try {
      document.createEvent('TouchEvent');
      return true;
    } catch (e) {
      // empty.
    }
  }

  return false;
};

export const isIphone = () => {
  const ua = window.navigator.userAgent;
  return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua);
};

export const isSafari =
  navigator.vendor &&
  navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf('CriOS') === -1 &&
  navigator.userAgent.indexOf('FxiOS') === -1;

export const regExpMapper = {
  // *** Mac ***
  '10.11+': new RegExp(/^(?:1[1-9](?:\.\d+)+|10\.(?:[1-9][1-9])(?:\.\d+)?)$/),
  '10.12+': new RegExp(
    /^(?:1[1-9](?:\.\d+)+|10\.(?:(?:1[2-9]|[2-9][\d])(?:\.\d+)?))$/,
  ),
  '10.12.4+': new RegExp(
    /^(?:1[1-9](?:\.\d+)+|10\.(?:12\.[4-9]\d*|(?:1[3-9]|[2-9][\d])(?:\.\d+)?))$/,
  ),
  '10.13+': new RegExp(
    /^(?:1[1-9](?:\.\d+)+|10\.(?:(?:1[3-9]|[2-9][\d])(?:\.\d+)?))$/,
  ),
  '10.15+': new RegExp(
    /^(?:1[1-9](?:\.\d+)+|10\.(?:1[5-9]|[2-9][\d])(?:\.\d+)?)$/,
  ),
  '11.0-11.4': new RegExp(/^(?:11\.(?:[0-4])(?:\.\d+)?)$/),
  '10.14.4-10.15': new RegExp(/^(10\.14\.[4-6]|10\.15)$/),
  '12.0+': new RegExp(/^(?:(?:1[2-9]|[2-9][\d])(?:\.\d+)(?:\.\d+)?)$/),
  // *** iPhone/iPad/iPod/AppleTV ***
  '7.0+': new RegExp(/^(?:[7-9]|[1-9]\d)\.\d(?:\.\d+)?$/),
  '11.0+': new RegExp(/^(?:1[1-9]|[2-9]\d)\.\d(?:\.\d+)?$/),
  '17.0+': new RegExp(/^(?:1[7-9]|[2-9]\d)\.\d(?:\.\d+)?$/),
  '18.0+': new RegExp(/^(?:1[8-9]|[2-9]\d)\.\d(?:\.\d+)?$/),
};

/**
 * Determines whether or not a pin should be enabled for a device. Pins should only be enabled
 * on devices that either have a T1 chip or have a T2 chip and an OS version less than 11.
 * We know a device has a T2 chip when it supports Activation Lock and has an Intel chip.
 * See: https://developer.apple.com/forums/thread/686664
 *
 * @param {string} deviceFamily - the device family, ex. 'Mac'
 * @param {string} osVersion - the OS version of the device, ex. '10.15.6'
 * @param {string} deviceModel - the model of the device, ex. 'MacBook Pro (13-inch, 2019, Two Thunderbolt 3 ports)'
 * @param {boolean} activationLockSupported - whether or not activation lock is supported on the device
 * @returns
 */
export const isPinEnabled = (
  deviceFamily,
  osVersion,
  deviceModel,
  activationLockSupported,
) => {
  const isMacOS = isMacOSDevice(deviceFamily);
  const isLessThanVersion11 = !regExpMapper['11.0+'].test(osVersion);
  const isIntelChip = isMacOS && !isM1Device(deviceModel);
  const isT2AndLessThanVersion11 =
    isIntelChip && activationLockSupported && isLessThanVersion11;
  const isT1Chip = isIntelChip && !activationLockSupported;
  return isT2AndLessThanVersion11 || isT1Chip;
};

export const downloadFromUrl = (url) => {
  /* istanbul ignore next */
  if (isIphone() || isIpad()) {
    window.open(url);
  } else {
    fetch(url)
      .then((resp) => resp.blob())
      .then((dataBlob) => {
        downloadFromBlob(dataBlob);
      });
  }
};

export const downloadFromBlob = (blob) => {
  const urlstr = window.URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  anchor.style.display = 'none';
  anchor.href = urlstr;
  anchor.download = 'kandji-enroll.mobileconfig';

  anchor.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );

  window.URL.revokeObjectURL(urlstr);
};

export const getDaysBetween = (date1, date2) => {
  if (!date1 || !date2) {
    return null;
  }
  return Math.round(Math.abs(date1 - date2) / (1000 * 60 * 60 * 24));
};

// https://mathiasbynens.be/demo/url-regex @diegoperini
export const isValidUrl = (value) =>
  /^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value,
  );

export const isValidPrinterUrl = (value) => {
  const { scheme, host } = parse(value);
  // force scheme and host to be booleans
  return !!scheme && !!host;
};

export function isRemoteEligible({ osVersion, isSupervised, isADE }) {
  return (
    (regExpMapper['10.15+'].test(osVersion) && isSupervised) ||
    (regExpMapper['10.14.4-10.15'].test(osVersion) && isADE)
  );
}
