import { Select } from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';
import { i18n } from 'src/i18n';

import WifiService from '../../service/wifi-service';
import { tlsOptions } from '../protocols-card.model';

const keys = WifiService.keys.authentication.acceptedEapTypes;

const TslMaximumVersion = ({
  refs,
  isDisabled,
  update,
  setting,
  canShowMaximumTlsVersionError,
  maximumTlsVersionError,
}) => (
  <Setting.SecondaryControlsRow>
    <div ref={refs[keys.tlsMaximumVersion]}>
      <p className="b-txt">{i18n.t(`TLS maximum version`)}</p>
    </div>
    <div>
      <p className="b-txt-light">
        {i18n.t(`The maximum TLS version to be used with EAP authentication.`)}
      </p>
    </div>
    <div>
      <Select
        isDisabled={isDisabled}
        options={tlsOptions()}
        compact
        onChange={(v) => update(keys.tlsMaximumVersion, v.value)}
        value={tlsOptions().find(
          (option) => option.value === setting[keys.tlsMaximumVersion],
        )}
        errorText={
          (canShowMaximumTlsVersionError && maximumTlsVersionError) || ''
        }
      />
    </div>
  </Setting.SecondaryControlsRow>
);

export default React.memo(TslMaximumVersion);
