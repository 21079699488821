import type { DetectionDateFilterFields } from 'src/features/edr/common/common.types';
import { constants } from '../../common';
import type {
  BehavioralThreatClassification,
  BehavioralThreatStatus,
  DetectionTypeSelectorState,
  PageSize,
  SortColumnDirection,
  SortColumnName,
  ThreatClassification,
  ThreatStatus,
} from '../../threat.types';

type DefaultParams = {
  STATUS: ThreatStatus[] | BehavioralThreatStatus[] | undefined;
  DETECTION_DATE: DetectionDateFilterFields;
  CLASSIFICATION:
    | ThreatClassification
    | BehavioralThreatClassification
    | undefined;
  DETECTION_TYPE: DetectionTypeSelectorState | undefined;
  PAGE: number;
  PAGE_SIZE: PageSize;
  SORT_BY: SortColumnName | undefined;
  SORT_ORDER: SortColumnDirection | undefined;
  SEARCH_QUERY: string | undefined;
};

const defaultParams: DefaultParams = {
  STATUS: undefined,
  DETECTION_DATE: {
    value: constants.THREAT_DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS[1].value,
  },
  CLASSIFICATION: undefined,
  DETECTION_TYPE: constants.THREAT_DETECTION_TYPE.FILE,
  PAGE_SIZE: 50,
  PAGE: 1,
  SORT_BY: undefined,
  SORT_ORDER: undefined,
  SEARCH_QUERY: undefined,
};

export default defaultParams;
