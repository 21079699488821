import _filter from 'lodash/fp/filter';
import _flow from 'lodash/fp/flow';
import _fromPairs from 'lodash/fp/fromPairs';
import _isEmpty from 'lodash/fp/isEmpty';
import _map from 'lodash/fp/map';
import _toPairs from 'lodash/fp/toPairs';
import PropTypes from 'prop-types';
import React, {
  useState,
  useCallback,
  memo,
  useRef,
  useEffect,
  useMemo,
} from 'react';

import Dropdown from './Dropdown';
import Icon from './Icon';

// TODO: ideally, find a way to not have a <div> or <hr> inside a <ul> or just convert all the "list" markup into <div> with classes

const makeFlatOptions = (options) => Object.assign({}, ...options);

const SelectedValues = memo(
  ({
    customStyles,
    chipFilter,
    disabled,
    emptyPlaceholder,
    options,
    selectedValues,
    toggle,
  }) => {
    const styles = Array.isArray(customStyles) ? customStyles.join(' ') : '';
    const chips = chipFilter(selectedValues);
    return (
      <div className={`field-list-wrap chip-list-wrap ${styles}`}>
        <ul className="field-list chip-list">
          {_isEmpty(chips) && (
            <li className="placeholder">
              <span>{emptyPlaceholder}</span>
            </li>
          )}
          {chips.map((id) => (
            <li key={`selected-${id}`} className="chip">
              <span>
                {options[id]}
                {!disabled && (
                  <Icon
                    icon="xmark"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggle(id);
                    }}
                  />
                )}
              </span>
            </li>
          ))}
        </ul>
        <i className="field-list-caret" />
      </div>
    );
  },
);

SelectedValues.propTypes = {
  customStyles: PropTypes.arrayOf(PropTypes.string),
  chipFilter: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  emptyPlaceholder: PropTypes.string.isRequired,
  options: PropTypes.objectOf(PropTypes.string).isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggle: PropTypes.func.isRequired,
};

SelectedValues.defaultProps = {
  customStyles: null,
};

const DropDownOptions = memo(({ id, name, selectedValues, toggle }) => (
  <li className="theme-form-check form-check">
    <input
      id={id}
      className="theme-form-check-input form-check-input"
      type="checkbox"
      checked={selectedValues.includes(id)}
      onChange={() => toggle(id)}
    />
    <label htmlFor={id} className="theme-form-check-label form-check-label">
      {name}
    </label>
  </li>
));

DropDownOptions.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.string),
  toggle: PropTypes.func.isRequired,
};

DropDownOptions.defaultProps = {
  selectedValues: [],
};

const getOptionMatches = (text) =>
  _flow(
    _toPairs,
    _filter(([, opt]) => opt.toLowerCase().includes(text)),
    _fromPairs,
  );

const sortSelectedValues = (selectedValues) =>
  _flow(
    _toPairs,
    _filter(([key]) => selectedValues.includes(key)),
    (arr) => arr.sort((a, b) => a[1].localeCompare(b[1])),
    _map(([key]) => key),
  );

const MultiSelectDropdown = ({
  disabled,
  dropdownFooter,
  dropdownStyles,
  element: Element,
  emptyPlaceholder,
  name,
  onExit,
  onOpen,
  onToggle,
  chipFilter,
  options,
  searchPlaceholder,
  selectedValues,
  withSearch,
  scroll,
  ...props
}) => {
  const flattenedOptions = useMemo(() => makeFlatOptions(options), [options]);
  const [visibleOptions, setVisibleOptions] = useState(options);
  const [searchText, setSearchText] = useState('');
  const currentSelections = useRef(selectedValues);

  useEffect(() => {
    currentSelections.current = selectedValues;
  }, [selectedValues]);

  const filterVisibleItems = useCallback(
    (e) => {
      const text = e.target.value;
      setSearchText(text);
      const optionMatches = getOptionMatches(text.toLowerCase())(
        flattenedOptions,
      );
      if (text === '') {
        setVisibleOptions(options);
        return;
      }
      setVisibleOptions([optionMatches]);
    },
    [flattenedOptions, options],
  );

  const toggle = useCallback(
    (id) => {
      currentSelections.current = selectedValues.includes(id)
        ? selectedValues.filter((val) => val !== id)
        : sortSelectedValues([...selectedValues, id])(flattenedOptions);
      onToggle(currentSelections.current, id);
    },
    [onToggle, selectedValues, flattenedOptions],
  );

  const onDropdownClose = () => {
    onExit(currentSelections.current);
    setVisibleOptions(options);
    setSearchText('');
  };

  const onDropdownOpen = () => {
    onOpen(currentSelections.current);
  };

  const resetSearch = () => {
    setSearchText('');
    setVisibleOptions(options);
  };

  return (
    <Element className="ms-dropdown">
      <Dropdown
        name={name}
        disabled={disabled}
        label={
          <SelectedValues
            emptyPlaceholder={emptyPlaceholder}
            disabled={disabled}
            chipFilter={chipFilter}
            selectedValues={selectedValues}
            options={flattenedOptions}
            toggle={toggle}
            customStyles={dropdownStyles}
          />
        }
        onDropdownOpen={onDropdownOpen}
        onDropdownClose={onDropdownClose}
        {...props}
      >
        <>
          {withSearch && (
            <div className="dropdown-menu-header">
              <input
                className="dropdown-menu-search form-control"
                placeholder={searchPlaceholder}
                value={searchText}
                onChange={filterVisibleItems}
              />
              {searchText === '' && <Icon icon="magnifying-glass" />}
              {/* Below markup has to be rendered in DOM when Dropdown is mounted */}
              <i
                onClick={(e) => {
                  e.stopPropagation();
                  resetSearch();
                }}
                className={`dropdown-menu-search-reset ${
                  searchText === '' ? 'hide' : 'show'
                }`}
              >
                &times;
              </i>
            </div>
          )}
          {!_isEmpty(visibleOptions[0]) && (
            <div
              className={`dropdown-menu-body dropdown-menu-body-${
                scroll ? 'scroll' : 'initial'
              }`}
            >
              <ul className="dropdown-menu-list">
                {visibleOptions.map((visibleOption, index) => (
                  <div key={Object.keys(visibleOption).toString()}>
                    {Object.entries(visibleOption).map(
                      ([id, blueprintName]) => (
                        <DropDownOptions
                          key={id}
                          name={blueprintName}
                          id={id}
                          selectedValues={selectedValues}
                          toggle={toggle}
                        />
                      ),
                    )}
                    {index !== visibleOptions.length - 1 && <hr />}
                  </div>
                ))}
              </ul>
            </div>
          )}
          {dropdownFooter && (
            <div
              className={`dropdown-menu-${
                !_isEmpty(visibleOptions[0]) ? 'footer' : 'fallback'
              }`}
            >
              {dropdownFooter}
            </div>
          )}
        </>
      </Dropdown>
    </Element>
  );
};

MultiSelectDropdown.propTypes = {
  disabled: PropTypes.bool.isRequired,
  dropdownFooter: PropTypes.node,
  dropdownStyles: PropTypes.arrayOf(PropTypes.string),
  element: PropTypes.string,
  emptyPlaceholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  onExit: PropTypes.func,
  onOpen: PropTypes.func,
  onToggle: PropTypes.func,
  chipFilter: PropTypes.func, // filter out selected values from the chips
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  searchPlaceholder: PropTypes.string,
  selectedValues: PropTypes.arrayOf(PropTypes.string),
  withSearch: PropTypes.bool,
  scroll: PropTypes.bool,
};

const noop = () => null;

MultiSelectDropdown.defaultProps = {
  dropdownFooter: null,
  dropdownStyles: null,
  element: 'div',
  emptyPlaceholder: 'Not Assigned',
  onExit: noop,
  onOpen: noop,
  onToggle: noop,
  chipFilter: (values) => values,
  options: [],
  searchPlaceholder: 'Search...',
  selectedValues: [],
  withSearch: true,
  scroll: false,
};

export default memo(MultiSelectDropdown);
