import RecoveryPasswordService from './service/recovery-password-service';

const initialState = {
  isActive: true,
  name: '',
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,

  manage: {
    isAutoPassword: true,
    isRotatePassword: true,
    rotateDays: RecoveryPasswordService.DEFAULT_ROTATE_DAYS,
    password: '',
  },
  legacy: {
    isManaged: false,
    passwords: [''],
  },

  supportsRules: true,
};

export default initialState;
