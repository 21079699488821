import { useEffect, useRef } from 'react';

export const useCallbackByKey = (cb, deps) => {
  const cache = useRef(new Map());

  useEffect(() => cache.current.clear(), deps);

  return (key) => {
    if (!cache.current.has(key)) {
      cache.current.set(key, (...args) => {
        cb({ key, args });
      });
    }

    return cache.current.get(key);
  };
};
