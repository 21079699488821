import { Banner, TextInput, useInputsValidators } from '@kandji-inc/bumblebee';
import React, { useEffect } from 'react';

import { isValidPrinterUrl } from 'app/components/common/helpers';
import { Setting } from 'features/library-items/template';
import { i18n } from 'src/i18n';

const UriRow = ({ setting, update, isDisabled, isSubmitted }) => {
  const fieldsToValidate = ['uri'];
  const { refs, onInvalidate } = useInputsValidators(fieldsToValidate, update);
  const validator = (v) => [
    {
      message: i18n.t('Required'),
      invalid: () => !v,
      trigger: ['onBlur', isSubmitted && 'onSubmit'],
    },
    {
      message: i18n.t('Must be a URI'),
      invalid: () => !isValidPrinterUrl(v), //! /^\w+:\/(\/([\w-.]+))+$/.test(v),
      trigger: ['onBlur', isSubmitted && 'onSubmit'],
    },
  ];
  useEffect(() => {
    const isInvalid = validator(setting.uri)
      .map(({ invalid }) => invalid())
      .some((v) => v);

    if (isInvalid) {
      onInvalidate(0)(true);
    }
  }, []);

  return (
    <Setting.Row>
      <div>
        <p className="b-txt">{i18n.t('Device URI')}</p>
      </div>
      <Setting.Helpers>
        <p className="b-txt-light">
          {i18n.t(`Provide the device URI, this is usually the print protocol (ipp:// or
          lpd:// or dnssd://) followed by the hostname or ip address of the
          printer.`)}
        </p>

        <Banner theme="info" kind="block" style={{ minWidth: 'unset' }}>
          <p>
            {i18n.t(`If configuring print server, typically the queue name is appended to
            the hostname — Ie. lpd://print-server.local/queue_name`)}
          </p>
        </Banner>
      </Setting.Helpers>
      <div ref={refs[0]}>
        <TextInput
          value={setting.uri}
          onChange={(e) => update('uri', e.target.value)}
          disabled={isDisabled}
          placeholder="lpd://192.168.128.101"
          compact
          onInvalidate={onInvalidate(0)}
          validator={validator}
        />
      </div>
    </Setting.Row>
  );
};

export default UriRow;
