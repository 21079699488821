import {
  NFSelectV2,
  ValidationTemplates,
  registerField,
} from '@kandji-inc/nectar-form';
import { Box, Button, Flex } from '@kandji-inc/nectar-ui';
import type { FacetMap } from 'features/rules-modal/types';
import type React from 'react';
import './LibraryItemOSVersionRule.scss';
import { RuleValue } from 'features/rules-modal/components/RuleValue';
import { getDeviceFamilyIconByOS } from 'features/rules-modal/utils';
import { AM_DEVICE_SPECIFIC_OS_VERSION_FACETS } from 'features/rules/constants';

const requiredOS = ValidationTemplates.required('Please select an OS');

export const AssignmentMapOSVersionRule = ({
  inputFieldName,
  operatorFieldName,
  valueFieldName,
  facetMap,
  canRemoveRule,
  removeRule,
}: {
  inputFieldName: string;
  operatorFieldName: string;
  valueFieldName: string;
  facetMap: FacetMap;
  canRemoveRule: boolean;
  removeRule: () => void;
}): React.ReactElement => {
  const inputOptions = [
    ...AM_DEVICE_SPECIFIC_OS_VERSION_FACETS.map((input) => ({
      label: facetMap[input].name,
      value: input,
      icon: getDeviceFamilyIconByOS(input),
    })),
  ];

  const inputField = registerField(inputFieldName);
  const currentInput = inputField.getValue();
  const facetData = facetMap[currentInput];

  return (
    <Flex flow="row" gap="md" alignItems="start" justifyContent="space-between">
      <Flex gap="sm">
        <Box css={{ width: '218px' }}>
          <NFSelectV2
            name={inputFieldName}
            options={inputOptions}
            validation={requiredOS}
            placeholder="Select OS"
            aria-label="Input"
          />
        </Box>
        {currentInput && (
          <>
            <Box css={{ width: '240px' }}>
              <NFSelectV2
                name={operatorFieldName}
                options={facetData.supported_operators}
                aria-label="Operator"
              />
            </Box>
            <Box css={{ width: '410px' }}>
              <RuleValue
                operatorFieldName={operatorFieldName}
                valueFieldPrefix={valueFieldName}
                facetData={facetData}
                aria-label="Value"
              />
            </Box>
          </>
        )}
      </Flex>
      {canRemoveRule && (
        <Button
          variant="subtleDanger"
          icon={{ name: 'trash-can' }}
          onClick={removeRule}
          disabled={!canRemoveRule}
        />
      )}
    </Flex>
  );
};
