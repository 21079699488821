import { Icon } from '@kandji-inc/bumblebee';
import { object } from 'prop-types';
import React, { useState } from 'react';

import Modal from './modal';

const AssignUser = ({ ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = () => {
    setIsOpen(true);
  };

  return (
    <>
      <button
        type="submit"
        aria-label="assign-user-modal-trigger"
        className="edit-icon"
        onClick={onClick}
      >
        <Icon name="pencil" />
      </button>

      <Modal isOpen={isOpen} setIsOpen={setIsOpen} {...props} />
    </>
  );
};

AssignUser.propTypes = {
  computerRecord: object.isRequired,
};

export default AssignUser;
