// istanbul ignore file
import { useQueries, useQuery } from '@tanstack/react-query';
import { prismService } from '../../prism/data-service/prism-service';
import type { PrismViewCountRequestSchema } from '../../prism/types/prism.types';

export const useDeviceViewsCountQueries = ({
  views,
}: {
  views: { id: string; name: string }[];
}) => {
  const queries = views.map((view) => ({
    queryKey: ['prism-view-count', view.id],
    queryFn: async () => {
      const body: PrismViewCountRequestSchema = {
        view_id: view.id,
      };
      const count = await prismService.getPrismViewCount(body);
      return { [view.id]: count.count };
    },
    enabled: !!view.id,
  }));

  const results = useQueries({ queries });

  const data = results.reduce((acc, result) => {
    return { ...acc, ...(result.data || {}) };
  }, {});

  const isPending = results.reduce((acc, result, index) => {
    const viewId = views[index]?.id;
    return viewId ? { ...acc, [viewId]: result.isPending } : acc;
  }, {});

  const isError = results.reduce((acc, result, index) => {
    const viewId = views[index]?.id;
    return viewId ? { ...acc, [viewId]: result.isError } : acc;
  }, {});

  const refetchCountForViewId = (viewId: string) => {
    const index = views.findIndex((view) => view.id === viewId);
    if (index !== -1) {
      results[index]?.refetch();
    }
  };

  return {
    data,
    isPending,
    isError,
    refetchCountForViewId,
  };
};

export const useDeviceViewCountQuery = ({
  params,
  viewId,
}: {
  params: Omit<PrismViewCountRequestSchema, 'view_id'>;
  viewId: string;
}) => {
  const queryKeyParams = {
    ...params,
    columns: params.columns
      ?.map((column) => ({ name: column.name, category: column.category }))
      .sort((a, b) => (a.name < b.name ? -1 : 1)),
  };
  return useQuery({
    enabled: !!(params.columns && params.columns.length > 0),
    queryKey: ['prism-view-count-single', viewId, queryKeyParams],
    queryFn: async () => prismService.getPrismViewCount(params),
  });
};
