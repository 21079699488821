import uuid from 'uuid';

import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import configs from '../../../library/library-item-configurations/items/profile';

function transformSettings(settings) {
  return {
    ...settings,
    general: {
      AllowUserOverrides: settings.AllowUserOverrides,
    },
    // Add client-side `id`s so we can track each unique team ID and extension ID for `key` props and deleting:
    teamIds: {
      teams: settings.TeamIds.map((teamId) => ({
        ...teamId,
        id: uuid(), // client-side UI purposes only
        Extensions: teamId.Extensions.map((ext) => ({
          ...ext,
          // Adding new field, making sure if its undefined we set it to false.
          canRemoveItself:
            'canRemoveItself' in ext ? ext.canRemoveItself : false,
          userCanTurnOff: 'userCanTurnOff' in ext ? ext.userCanTurnOff : false,
          id: uuid(),
        })),
      })),
    },
  };
}

async function transformFromApi(apiData) {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const commonData = {
    id: apiData.id,
    name: apiData.name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
    rules: apiData.rules,
  };

  const result = {
    ...commonData,
    isActive: apiData.active,
    ...transformSettings(apiData.data),
  };

  return {
    ...apiData,
    data: result,
  };
}

function transformToApi(model) {
  const teams = model.teamIds.teams.map((teamId) => ({
    TeamId: teamId.TeamId,
    AllowedExtensions: teamId.AllowedExtensions,
    DisplayName: teamId.DisplayName,
    ExtensionTypes: teamId.ExtensionTypes,
    Extensions: teamId.Extensions.map((extension) => ({
      // Pick specific properties since we don't need everything (ex: `id` is only used on client-side)
      Name: extension.Name,
      BundleId: extension.BundleId,
      canRemoveItself: extension.canRemoveItself,
      userCanTurnOff: extension.userCanTurnOff,
    })),
  }));

  const toSend = {
    name: model.name,
    active: model.isActive,
    blueprints: model.selectedBlueprints.map((b) => b.value),
    is_all_blueprints: model.isAllBlueprints,
    data: {
      AllowUserOverrides: model.general.AllowUserOverrides,
      TeamIds: teams,
    },
    rules: model.rules || null,
  };

  // Add necessary properties when creating new Profile:
  if (!model.id) {
    const itemConfig = configs['System Extension'];

    toSend.type = itemConfig.type;
    toSend.identifier = itemConfig.identifier;
  }

  return toSend;
}

export { transformToApi, transformFromApi };
