// istanbul ignore file
import { FilterButton, MultiSelect } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';

const DEVICE_FAMILY_OPTIONS = () => [
  { label: i18n.t('Mac'), value: 'Mac' },
  { label: i18n.t('iPhone'), value: 'iPhone' },
  { label: i18n.t('iPad'), value: 'iPad' },
  { label: i18n.t('Apple TV'), value: 'AppleTV' },
];

export const DeviceFamilyFilter = ({
  deviceFamilyFilter,
  setDeviceFamilyFilter,
}: {
  deviceFamilyFilter: string[];
  setDeviceFamilyFilter: (values: string[]) => void;
}) => {
  return (
    <MultiSelect
      options={DEVICE_FAMILY_OPTIONS()}
      onChange={(values) => setDeviceFamilyFilter(values)}
      value={deviceFamilyFilter}
      multi
      size="sm"
      componentCss={{ menu: { zIndex: 999, width: '100%' } }}
    >
      <FilterButton
        maxWidth={190}
        selectedFilters={DEVICE_FAMILY_OPTIONS().filter((option) =>
          deviceFamilyFilter.includes(option.value),
        )}
        showRemove={false}
      >
        {i18n.t('Device family')}
      </FilterButton>
    </MultiSelect>
  );
};

const DATA_TYPE_OPTIONS = () => [
  { label: i18n.t('Boolean'), value: 'boolean' },
  { label: i18n.t('Number'), value: 'number' },
  { label: i18n.t('Date'), value: 'date-time' },
  { label: i18n.t('Enum'), value: 'enum' },
  { label: i18n.t('Text'), value: 'string' },
  { label: i18n.t('Version'), value: 'version' },
];

export const DataTypeFilter = ({
  dataTypeFilter,
  setDataTypeFilter,
}: {
  dataTypeFilter: string[];
  setDataTypeFilter: (values: string[]) => void;
}) => {
  return (
    <MultiSelect
      options={DATA_TYPE_OPTIONS()}
      onChange={(values) => setDataTypeFilter(values)}
      value={dataTypeFilter}
      multi
      size="sm"
      componentCss={{ menu: { zIndex: 999, width: '100%' } }}
    >
      <FilterButton
        maxWidth={190}
        selectedFilters={DATA_TYPE_OPTIONS().filter((option) =>
          dataTypeFilter.includes(option.value),
        )}
        showRemove={false}
      >
        {i18n.t('Data type')}
      </FilterButton>
    </MultiSelect>
  );
};

export const CategoryFilter = ({
  categoryFilter,
  setCategoryFilter,
  categoryOptions,
}: {
  categoryFilter: string[];
  setCategoryFilter: (values: string[]) => void;
  categoryOptions: { label: string; value: string }[];
}) => {
  return (
    <MultiSelect
      options={categoryOptions}
      onChange={(values) => setCategoryFilter(values)}
      value={categoryFilter}
      multi
      size="sm"
      componentCss={{ menu: { zIndex: 999 } }}
    >
      <FilterButton
        maxWidth={190}
        selectedFilters={categoryOptions.filter((option) =>
          categoryFilter.includes(option.value),
        )}
        showRemove={false}
      >
        {i18n.t('Category')}
      </FilterButton>
    </MultiSelect>
  );
};
