import {
  Flex,
  Select,
  TextInput,
  useInputsValidators,
  useIsChanged,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import React, { useEffect } from 'react';

import { onEmptyBlurValidator } from 'src/app/common/validators';
import { i18n } from 'src/i18n';
import CertificateService from '../../service/certificate-service';

const SanRaw = (props) => {
  const { setting, updateValidation, validationDep, isDisabled, update } =
    props;
  const { type, value, _id } = setting;
  const fieldsToValidate = [`san-type_${_id}`, `san-value_${_id}`];
  const { refs, onInvalidate } = useInputsValidators(
    fieldsToValidate,
    updateValidation,
  );

  const sanTypeOptions = [
    // {
    //   value: CertificateService.sanTypes.NONE,
    //   label: 'None',
    // },
    {
      value: CertificateService.sanTypes.DNS,
      label: i18n.t('DNS name'),
    },
    {
      value: CertificateService.sanTypes.RFC_822,
      label: i18n.t('RFC 822 Name'),
    },
    {
      value: CertificateService.sanTypes.URI,
      label: i18n.t('Uniform Resource Identifier'),
    },
    {
      value: CertificateService.sanTypes.UPN,
      label: i18n.t('User Principal Name'),
    },
  ];

  useEffect(() => {
    onInvalidate(fieldsToValidate[0])(!type);
  }, [type]);
  useRemoveValidationOnUnmount(fieldsToValidate, updateValidation);
  const isSubmitted = useIsChanged(validationDep);

  const selectedTypeOption = sanTypeOptions.find(
    (option) => option.value === type,
  );

  return (
    <div ref={refs[0]} className="k-cert-li__san-inputs">
      <div className="b-txt b-mb1" ref={refs[1]}>
        {i18n.t('SAN type')}
      </div>
      <Flex className="react-select-test" gapType="gap2">
        <Select
          isDisabled={isDisabled}
          options={sanTypeOptions}
          compact
          placeholder={i18n.t('Select Option')}
          onChange={(v) => update('type', v.value)}
          value={selectedTypeOption}
          // menuPortalTarget={document.body}
          errorText={isSubmitted && !type ? i18n.t('Required') : ''}
          testId="san-raw"
        />
        <TextInput
          disabled={isDisabled}
          value={value}
          onChange={(e) => update('value', e.target.value)}
          compact
          placeholder={i18n.t('Enter the subject alternative name')}
          validator={(v) => [
            onEmptyBlurValidator(v, {
              trigger: ['onBlur', validationDep],
            }),
          ]}
          onInvalidate={onInvalidate(fieldsToValidate[1])}
          data-testid="san-value"
        />
      </Flex>
    </div>
  );
};

export default SanRaw;
