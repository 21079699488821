import { createReducer } from 'redux-create-reducer';
import { company } from '../_actions/action-types';

const initialState = {
  customer: null,
};

export default createReducer(initialState, {
  [company.UPDATE_COMPANY](state, action) {
    return {
      ...state,
      ...action.updates,
    };
  },
});
