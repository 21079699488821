import { Banner, Checkbox, Select } from '@kandji-inc/bumblebee';
import PropTypes from 'prop-types';
import React from 'react';

import { Setting } from 'features/library-items/template';

const MANAGE_DISC_BURNING_OPTIONS = [
  {
    label: 'Allow burning',
    value: 'on',
  },
  {
    label: 'Allow burning with authentication',
    value: 'authenticate',
  },
  {
    label: 'Disallow burning',
    value: 'off',
  },
];

const General = ({ update, isDisabled, settings }) => (
  <Setting.Card>
    <Setting.Header>
      <h3 className="b-h3">General</h3>
    </Setting.Header>
    <Setting.Rows>
      <Setting.Row>
        <Setting.Controls>
          <Checkbox
            label="Disconnect all media at logout"
            id="disconnect_all_media_at_logout"
            testId="disconnect_all_media_at_logout_checkbox"
            checked={settings.DisconnectAllMediaAtLogout}
            onChange={() =>
              update('DisconnectAllMediaAtLogout', (checked) => !checked)
            }
            disabled={isDisabled}
          />
        </Setting.Controls>
        <Setting.Helpers>
          <p className="b-txt-light">
            All removable media will be unmounted on logout.
          </p>
          <Banner theme="info">
            <p className="b-txt-light">
              See the Apple deprecation note at the top of this library item.
            </p>
          </Banner>
        </Setting.Helpers>
      </Setting.Row>
      <Setting.Row>
        <Setting.Controls>
          <Checkbox
            label="Manage disc burning"
            id="manage_disc_burning"
            testId="manage_disc_burning_checkbox"
            checked={settings.isManagingDiscBurning}
            onChange={() =>
              update('isManagingDiscBurning', (checked) => !checked)
            }
            disabled={isDisabled}
          />
        </Setting.Controls>
        {settings.isManagingDiscBurning && (
          <Setting.SecondaryControls>
            <Setting.SecondaryControlsRow>
              <Select
                compact
                inputId="manage_disc_burning_select"
                options={MANAGE_DISC_BURNING_OPTIONS}
                onChange={(selected) =>
                  update('ManageDiscBurning', selected.value)
                }
                value={MANAGE_DISC_BURNING_OPTIONS.find(
                  (opt) => opt.value === settings.ManageDiscBurning,
                )}
                disabled={isDisabled}
              />
            </Setting.SecondaryControlsRow>
          </Setting.SecondaryControls>
        )}
        <Setting.Helpers>
          <p className="b-txt-light">
            Configure macOS to allow, allow with authentication, or block the
            ability to burn physical disc.
          </p>
          <Banner theme="info">
            <p className="b-txt-light">
              See the Apple deprecation note at the top of this library item.
            </p>
          </Banner>
        </Setting.Helpers>
      </Setting.Row>
      <Setting.Row>
        <Setting.Controls>
          <Checkbox
            label="Disable media auto actions"
            id="disable_media_auto_actions"
            testId="disable_media_auto_actions_checkbox"
            checked={settings.DisableMediaAutoActions}
            onChange={() =>
              update('DisableMediaAutoActions', (checked) => !checked)
            }
            disabled={isDisabled}
          />
        </Setting.Controls>
        <Setting.Helpers>
          <p className="b-txt-light">
            Prevent macOS from automatically launching relevant applications
            when physical disc media is inserted.
          </p>
          <Banner theme="info">
            <p className="b-txt-light">
              See the Apple deprecation note at the top of this library item.
            </p>
          </Banner>
        </Setting.Helpers>
      </Setting.Row>
    </Setting.Rows>
  </Setting.Card>
);

General.propTypes = {
  update: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  settings: PropTypes.shape({
    DisconnectAllMediaAtLogout: PropTypes.bool,
    DisableMediaAutoActions: PropTypes.bool,
    ManageDiscBurning: PropTypes.string,
  }).isRequired,
};

export default General;
