import { Banner, Flex, Paragraph, Text } from '@kandji-inc/nectar-ui';
import React from 'react';

type Props = {
  isModalForBannerNavigation?: boolean;
};

const ModalContent = (props: Props) => {
  const { isModalForBannerNavigation } = props;
  return (
    <Flex flow="column" gap="md">
      <Banner
        text="You have unsaved changes on this Library item."
        theme="warning"
      />
      <Paragraph>
        You are about to navigate away from the{' '}
        <Text css={{ fontWeight: '$medium', display: 'inline-block' }}>
          Okta Verify Library item
        </Text>{' '}
        and have unsaved changes. If you are done modifying this Library item,
        we will save your work and navigate to the{' '}
        {isModalForBannerNavigation
          ? 'Integrations Marketplace.'
          : 'Okta Device Trust integration.'}
      </Paragraph>
      <Paragraph>
        If you need to continue modifying the Library item, click on Cancel to
        return to the current page.
      </Paragraph>
    </Flex>
  );
};

export default ModalContent;
