/* istanbul ignore file */
import React from 'react';
import { i18n } from 'src/i18n';

const Head = ({ disableCustomIcon }) => {
  const optionalIconCopy = disableCustomIcon ? '' : 'icon';

  return (
    <p className="b-txt">
      {i18n.t(
        `Customize the {optionalIconCopy} name and content that will display in Self Service.`,
        { optionalIconCopy },
      )}
    </p>
  );
};

export default Head;
