import {
  Box,
  Button,
  Flex,
  type IconNames,
  styled,
} from '@kandji-inc/nectar-ui';
import { useEffect, useRef, useState } from 'react';
import EDRPopover from 'src/features/edr/common/components/EDRPopover';
import EDRTooltip from 'src/features/edr/common/components/EDRTooltip';

const TinyButton = styled(Button, {
  '&:not(empty)': {
    svg: { width: '$3', height: '$3' },
    padding: 1,
  },
});

const ThreatListButtonPopover = (props: {
  children: React.ReactNode;
  icon?: IconNames;
  tooltip?: string;
  tooltipSide?: 'top' | 'bottom' | 'left' | 'right';
  onOpen?: () => void;
  trigger?: React.ReactNode;
  isHoverable?: boolean;
  testId?: string;
}) => {
  const {
    children,
    icon = 'clock-rotate-left',
    onOpen,
    tooltip,
    tooltipSide = 'bottom',
    trigger,
    isHoverable = false,
    testId,
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (typeof onOpen === 'function' && isOpen) {
      onOpen();
    }
  }, [onOpen, isOpen]);

  const handleClick = () => {
    // istanbul ignore next
    if (isHoverable) return;
    setIsOpen(!isOpen);
  };

  const handleMouseEnter =
    // istanbul ignore next
    () => {
      if (!isHoverable) return;

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setIsOpen(true);
    };

  const handleMouseLeave =
    // istanbul ignore next
    () => {
      if (!isHoverable) return;

      timeoutRef.current = setTimeout(() => {
        setIsOpen(false);
      }, 200);
    };

  const handleInteractOutside = () => {
    setIsOpen(false);
  };

  return (
    <EDRPopover
      isOpen={isOpen}
      onInteractOutside={handleInteractOutside}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      trigger={
        <EDRTooltip side={tooltipSide} label={tooltip}>
          <Box css={{ cursor: 'pointer' }} data-testid={testId}>
            {trigger || <TinyButton variant="subtle" icon={{ name: icon }} />}
          </Box>
        </EDRTooltip>
      }
    >
      <Flex flow="column" gap="xs">
        {children}
      </Flex>
    </EDRPopover>
  );
};

export default ThreatListButtonPopover;
