/* istanbul ignore file */

import * as React from 'react';
import type { SVGProps } from 'react';

const CircleBook = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.7148 25.4395C19.6777 25.4395 25.4395 19.6777 25.4395 12.7246C25.4395 5.76172 19.668 0 12.7051 0C5.75195 0 0 5.76172 0 12.7246C0 19.6777 5.76172 25.4395 12.7148 25.4395ZM12.7148 23.623C6.67969 23.623 1.82617 18.7598 1.82617 12.7246C1.82617 6.67969 6.66992 1.82617 12.7051 1.82617C18.75 1.82617 23.6133 6.67969 23.6133 12.7246C23.6133 18.7598 18.7598 23.623 12.7148 23.623Z"
      fill="currentColor"
    />
    <path
      d="M6.39648 18.9258L7.44141 18.9258C7.92969 18.9258 8.18359 18.6621 8.18359 18.2031L8.18359 8.28125C8.18359 7.8125 7.92969 7.55859 7.44141 7.55859L6.39648 7.55859C5.89844 7.55859 5.64453 7.8125 5.64453 8.28125L5.64453 18.2031C5.64453 18.6621 5.89844 18.9258 6.39648 18.9258ZM9.7168 18.9258L12.0312 18.9258C12.5293 18.9258 12.7637 18.6621 12.7637 18.2031L12.7637 10.293C12.7637 9.82422 12.5293 9.54102 12.0312 9.54102L9.7168 9.54102C9.24805 9.54102 8.98438 9.82422 8.98438 10.293L8.98438 18.2031C8.98438 18.6621 9.24805 18.9258 9.7168 18.9258ZM10.2148 11.5625C10 11.5625 9.86328 11.3965 9.86328 11.2109C9.86328 11.0156 10.0098 10.8594 10.2148 10.8594L11.5625 10.8594C11.7578 10.8594 11.9141 11.0156 11.9141 11.2109C11.9141 11.3867 11.7578 11.5625 11.5625 11.5625ZM10.2148 17.627C10.0098 17.627 9.86328 17.4805 9.86328 17.2754C9.86328 17.0703 10 16.9238 10.2148 16.9238L11.5625 16.9238C11.7578 16.9238 11.9141 17.0801 11.9141 17.2754C11.9141 17.4805 11.7578 17.627 11.5625 17.627ZM14.3262 18.9258L15.6738 18.9258C16.1621 18.9258 16.416 18.6621 16.416 18.2031L16.416 6.72852C16.416 6.25977 16.1621 5.99609 15.6738 5.99609L14.3262 5.99609C13.8281 5.99609 13.584 6.25977 13.584 6.72852L13.584 18.2031C13.584 18.6621 13.8281 18.9258 14.3262 18.9258ZM18.6621 18.916L19.5117 18.7305C19.9902 18.6426 20.1855 18.3301 20.0977 17.8613L18.2031 8.38867C18.1152 7.91992 17.8223 7.67578 17.3438 7.79297L16.4941 7.99805C16.0156 8.10547 15.8203 8.39844 15.8984 8.86719L17.8027 18.3496C17.8906 18.8086 18.1836 19.0234 18.6621 18.916Z"
      fill="currentColor"
    />
  </svg>
);

export default CircleBook;
