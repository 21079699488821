import { Select, TextInput, onEmptyBlurValidator } from '@kandji-inc/bumblebee';
import { Button, Flex } from '@kandji-inc/nectar-ui';
import React from 'react';

import { Setting } from 'src/features/library-items/template';
import SafariExtensionsService from '../../service/safari-extensions-service';

const ExtensionSettings = (props) => {
  const {
    isDisabled,
    update,
    settings,
    validationDep,
    validationRefs,
    allowedDomainsIdentifiers,
    deniedDomainsIdentifiers,
    onInvalidate,
  } = props;

  const hideDomainFields =
    settings.State === SafariExtensionsService.stateTypes.ALWAYS_OFF &&
    settings.PrivateBrowsing === SafariExtensionsService.stateTypes.ALWAYS_OFF;

  const handleAddAllowedDomain = () => {
    update('AllowedDomains', (curr) => [
      ...curr,
      SafariExtensionsService.generateDomain(),
    ]);
  };

  const handleAddDeniedDomain = () => {
    update('DeniedDomains', (curr) => [
      ...curr,
      SafariExtensionsService.generateDomain(),
    ]);
  };

  const updateDeniedDomain = (
    id: string,
    value: string,
    isInvalid?: boolean,
  ) => {
    update('DeniedDomains', (curr) =>
      curr.map(
        /*istanbul ignore next */ (domain) => {
          if (domain.id === id) {
            return {
              ...domain,
              value,
              ...(isInvalid === undefined ? {} : { isInvalid }),
            };
          }
          return domain;
        },
      ),
    );
  };

  const deleteAllowedDomain = (id: string, idx: string) => {
    onInvalidate(allowedDomainsIdentifiers[idx], false);

    update('AllowedDomains', (curr) =>
      curr.filter((domain) => domain.id !== id),
    );
  };

  const deleteDeniedDomain = (id: string, idx: string) => {
    onInvalidate(deniedDomainsIdentifiers[idx], false);

    update('DeniedDomains', (curr) =>
      curr.filter((domain) => domain.id !== id),
    );
  };

  const updateAllowedDomain = (
    id: string,
    value: string,
    isInvalid?: boolean,
  ) => {
    update('AllowedDomains', (curr) =>
      curr.map((domain) => {
        if (domain.id === id) {
          return {
            ...domain,
            value,
            .../* istanbul ignore next */ (isInvalid === undefined
              ? {}
              : { isInvalid }),
          };
        }
        return domain;
      }),
    );
  };

  const resetDomainValidations = /* istanbul ignore next */ () => {
    allowedDomainsIdentifiers.forEach((id) => onInvalidate(id, false));
    deniedDomainsIdentifiers.forEach((id) => onInvalidate(id, false));
  };

  return (
    <>
      <Setting.Row>
        <Setting.Title>
          <p className="b-txt">State</p>
        </Setting.Title>
        <Setting.Helpers>
          <p className="b-txt-light">Enforce the state of the extension.</p>
        </Setting.Helpers>
        <Setting.Controls>
          <Select
            options={SafariExtensionsService.stateOptions}
            compact
            disabled={isDisabled}
            onChange={(selected) => {
              update('State', selected.value);

              // Check if both states are set to always off, if so reset validations on domain lists:
              /* istanbul ignore next */
              if (
                selected.value ===
                  SafariExtensionsService.stateTypes.ALWAYS_OFF &&
                settings.PrivateBrowsing ===
                  SafariExtensionsService.stateTypes.ALWAYS_OFF
              ) {
                resetDomainValidations();
              }
            }}
            value={SafariExtensionsService.stateOptions.find(
              (opt) => opt.value === settings.State,
            )}
          />
        </Setting.Controls>
      </Setting.Row>
      <Setting.Row>
        <Setting.Title>
          <p className="b-txt">State in Private Browsing</p>
        </Setting.Title>
        <Setting.Helpers>
          <p className="b-txt-light">
            Enforce the state of the extension in Private Browsing.
          </p>
        </Setting.Helpers>
        <Setting.Controls>
          <Select
            options={SafariExtensionsService.stateOptions}
            compact
            disabled={isDisabled}
            onChange={(selected) => {
              update('PrivateBrowsing', selected.value);

              // Check if both states are set to always off, if so reset validations on domain lists:
              /* istanbul ignore next */
              if (
                selected.value ===
                  SafariExtensionsService.stateTypes.ALWAYS_OFF &&
                settings.State === SafariExtensionsService.stateTypes.ALWAYS_OFF
              ) {
                resetDomainValidations();
              }
            }}
            value={SafariExtensionsService.stateOptions.find(
              (opt) => opt.value === settings.PrivateBrowsing,
            )}
          />
        </Setting.Controls>
      </Setting.Row>
      {!hideDomainFields && (
        <Setting.Row>
          <Setting.Title>
            <p className="b-txt">Allowed domains</p>
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light">
              Optional. Specify the allowed domains for the extension. Wildcards
              such as *.kandji.io are supported.
            </p>
          </Setting.Helpers>
          <Setting.Controls>
            {settings.AllowedDomains.map((domain, idx) => (
              <Flex
                key={domain.id}
                gap="sm"
                mb2
                alignItems="start"
                ref={validationRefs[allowedDomainsIdentifiers[idx]]}
                data-testid="allowed-domain"
              >
                <TextInput
                  key={domain.id}
                  placeholder="Enter allowed domain"
                  value={domain.value}
                  onChange={(e) =>
                    updateAllowedDomain(domain.id, e.target.value)
                  }
                  disabled={isDisabled}
                  validator={(v) => {
                    return [
                      onEmptyBlurValidator(v, {
                        trigger: ['onBlur', validationDep],
                      }),
                    ];
                  }}
                  onInvalidate={(isInvalid) =>
                    onInvalidate(allowedDomainsIdentifiers[idx], isInvalid)
                  }
                  data-testid="allowed-domain-input"
                />
                {!isDisabled && (
                  <Button
                    variant="subtleDanger"
                    icon={{ name: 'trash-can' }}
                    onClick={() => deleteAllowedDomain(domain.id, idx)}
                    css={{ minHeight: '44px' }}
                    data-testid="delete-allowed-domain"
                  />
                )}
              </Flex>
            ))}

            <Button
              variant="subtle"
              icon={{ name: 'circle-plus' }}
              onClick={handleAddAllowedDomain}
              disabled={isDisabled}
              data-testid="add-allowed-domain"
            >
              Add
            </Button>
          </Setting.Controls>
        </Setting.Row>
      )}
      {!hideDomainFields && (
        <Setting.Row>
          <Setting.Title>
            <p className="b-txt">Denied domains</p>
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light">
              Optional. Specify the denied domains for the extension. Wildcards
              such as *.kandji.io are supported.
            </p>
          </Setting.Helpers>
          <Setting.Controls>
            {settings.DeniedDomains.map((domain, idx) => (
              <Flex
                key={domain.id}
                gap="sm"
                mb2
                alignItems="start"
                ref={validationRefs[deniedDomainsIdentifiers[idx]]}
                data-testid="denied-domain"
              >
                <TextInput
                  key={domain.id}
                  placeholder="Enter denied domain"
                  value={domain.value}
                  onChange={(e) =>
                    updateDeniedDomain(domain.id, e.target.value)
                  }
                  disabled={isDisabled}
                  validator={(v) => {
                    return [
                      onEmptyBlurValidator(v, {
                        trigger: ['onBlur', validationDep],
                      }),
                    ];
                  }}
                  onInvalidate={(isInvalid) =>
                    onInvalidate(deniedDomainsIdentifiers[idx], isInvalid)
                  }
                  data-testid="denied-domain-input"
                />
                {!isDisabled && (
                  <Button
                    variant="subtleDanger"
                    icon={{ name: 'trash-can' }}
                    onClick={() => deleteDeniedDomain(domain.id, idx)}
                    css={{ minHeight: '44px' }}
                    data-testid="delete-denied-domain"
                  />
                )}
              </Flex>
            ))}

            <Button
              variant="subtle"
              icon={{ name: 'circle-plus' }}
              onClick={handleAddDeniedDomain}
              disabled={isDisabled}
              data-testid="add-denied-domain"
            >
              Add
            </Button>
          </Setting.Controls>
        </Setting.Row>
      )}
    </>
  );
};

export default ExtensionSettings;
