import React, { useContext } from 'react';

import {
  Box,
  Button,
  DialogPrimitives as Dialog,
  Text,
} from '@kandji-inc/nectar-ui';

import { Context } from './context';

const Header = () => (
  <Dialog.Header>
    <Dialog.Title>Thanks for your feedback!</Dialog.Title>
  </Dialog.Header>
);

const Content = () => (
  <Box p5>
    <Text>
      Your feature request has been submitted and will be reviewed by the team.
    </Text>
  </Box>
);

const Footer = () => {
  const { onHome, onClose } = useContext(Context);
  return (
    <Dialog.Footer css={{ gap: 8 }}>
      <Button onClick={onHome} variant="link">
        Add another feature request
      </Button>
      <Button onClick={onClose} variant="primary">
        Done
      </Button>
    </Dialog.Footer>
  );
};

export default {
  header: <Header />,
  content: <Content />,
  footer: <Footer />,
};
