/* istanbul ignore file */
// These are API-connection functions, jest unit tests are irrelevant
import { integrationsApi, newIntegrationsAxios } from 'app/api/base';

class NewIntegrationsService {
  _makeParams(type) {
    return type ? { type__in: type } : undefined;
  }

  // //////////////////////////////
  // GENERAL
  // //////////////////////////////

  async getChannels(integrationId, teamId) {
    const params = teamId ? { team_id: teamId } : undefined;
    return newIntegrationsAxios(`${integrationId}/channels/`).get(params);
  }

  async refreshChannels(integrationId) {
    return newIntegrationsAxios(`${integrationId}/refresh-channels/`).post();
  }

  async getRefreshChannelsStatus(integrationId) {
    return newIntegrationsAxios(`${integrationId}/refresh-channels`).get();
  }

  async getTeams(integrationId) {
    return newIntegrationsAxios(`${integrationId}/teams/`).get();
  }

  // //////////////////////////////
  // INTEGRATIONS
  // //////////////////////////////

  async createIntegration(data) {
    return newIntegrationsAxios('').post(data);
  }

  async updateIntegration(id, data) {
    return newIntegrationsAxios(`${id}/`).patch(data);
  }

  async listIntegrations(type) {
    return newIntegrationsAxios('').get(this._makeParams(type));
  }

  async getIntegrationCounts(type) {
    return newIntegrationsAxios('counts').get(this._makeParams(type));
  }

  async integrationsRotateToken(id, type) {
    return newIntegrationsAxios(`${id}/rotate-token/`).post({ type });
  }

  async createScimIntegration(name) {
    return newIntegrationsAxios('scim/').post({ name });
  }

  async deleteIntegration(id) {
    return newIntegrationsAxios(`${id}/`).delete();
  }

  async rotateScimToken(id) {
    return newIntegrationsAxios(`${id}/scim/rotate/`).post();
  }

  async syncUsers(id) {
    return newIntegrationsAxios(`${id}/update-users/`).post();
  }

  // //////////////////////////////
  // NOTIFICATIONS
  // //////////////////////////////

  async createNotification(data) {
    return newIntegrationsAxios('subscriptions/').post(data);
  }

  async listNotifications(type) {
    return newIntegrationsAxios('subscriptions/').get(this._makeParams(type));
  }

  async updateNotification(id, data) {
    return newIntegrationsAxios(`subscriptions/${id}/`).patch(data);
  }

  async deleteNotification(id) {
    return newIntegrationsAxios(`subscriptions/${id}/`).delete();
  }

  async sendTestNotification(data) {
    return newIntegrationsAxios('subscriptions/send-test-message/').post(data);
  }

  // //////////////////////////////
  // NOTIFICATION DETAILS
  // //////////////////////////////
  async createNotificationDetail(data) {
    return integrationsApi(`notifications/`).post(data);
  }

  async updateNotificationDetails(notificationId, data) {
    return integrationsApi(`notifications/${notificationId}/`).patch(data);
  }
}

const newIntegrationsService = new NewIntegrationsService();
export default NewIntegrationsService;
export { newIntegrationsService };
