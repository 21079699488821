import {
  baseLibraryItemFromApi,
  baseLibraryItemToApi,
} from 'src/features/library-items/data-service/library-item/transformers';
import { apiTypes } from 'src/features/library-items/library/common';
import type {
  IpaAppApiData,
  IpaAppFromApi,
  IpaAppModel,
} from '../in-house-apps.types';

export async function transformFromApi(
  apiData: IpaAppApiData,
): Promise<IpaAppFromApi> {
  const {
    file,
    sha256,
    icon,
    file_name: fileName,
    file_size: fileSize,
    file_updated: fileUpdated,
    file_url: fileUrl,
    file_key: fileKey,
    app_identifier: appId,
    app_version: appVersion,
    ipad_icon: ipadIcon,
    app_name: appName,
  } = apiData;

  const { app_icon: appIcon } = apiData.data;

  const baseFromApi = await baseLibraryItemFromApi(apiData);

  return {
    ...apiData,
    data: {
      ...baseFromApi,
      icon,
      installDetails: {
        ipaFile: {
          file,
          fileName,
          fileSize,
          fileKey,
          fileUrl,
          fileUpdated,
          appId,
          appVersion,
          sha256,
          appIcon,
          ipadIcon,
          appName,
        },
      },
    },
  };
}

export const createTransformToApi =
  ({ shouldUpdateFile }) =>
  (model: IpaAppModel) => {
    const { installDetails } = model;
    const { file } = installDetails.ipaFile;

    const baseToApi = baseLibraryItemToApi(model);

    const ipaAppToApi: Partial<IpaAppApiData> = {
      ...baseToApi,
      type: apiTypes.IPA_APP_V2,
      data: { file_url: '', app_icon: '' },
    };

    if (shouldUpdateFile) {
      ipaAppToApi.file_key = file;
      ipaAppToApi.file = file;
    }

    if (model.skip_blueprint_conflict) {
      ipaAppToApi.skip_blueprint_conflict = model.skip_blueprint_conflict;
    }

    return ipaAppToApi;
  };
