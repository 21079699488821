import SystemExtensionService from './service/system-extension-service';

export default {
  id: null,
  name: '',
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  isActive: true,

  supportsRules: true,

  general: {
    AllowUserOverrides: true,
  },

  teamIds: {
    invalidationsMap: {},
    teams: [SystemExtensionService.generateTeamId()],
  },
};
