import { useDndContext } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS as DndCSS } from '@dnd-kit/utilities';
import {
  Button,
  Flex,
  Icon,
  Tooltip,
  TooltipProvider,
  styled,
} from '@kandji-inc/nectar-ui';
import { useRef } from 'react';

import AppIcon from './AppIcon';
import { APP_ICON_CONFIG, getTruncatedAppLabelStatus } from './common';

import type {
  App,
  HomeScreenLayoutDeviceKind,
  HomeScreenLayoutItem,
  HomeScreenLayoutPreviewContextKind,
} from '../../home-screen-layout.types';
import {
  getDndContextPreviewContext,
  getSortableItemTypeByPreviewContext,
} from './hooks/dnd-helpers';

const RemoveButton = styled(Button);

const AppContainer = styled(Flex, {
  '&:hover': {
    [`${RemoveButton}`]: {
      visibility: 'visible',
    },
  },

  variants: {
    disabled: {
      true: {
        '&:hover': {
          [`& ${RemoveButton}`]: {
            visibility: 'hidden !important',
          },
        },
      },
    },
  },
});

interface SingleAppProps extends App {
  readonly kind: HomeScreenLayoutDeviceKind;
  readonly context: HomeScreenLayoutPreviewContextKind;
  readonly disabled: boolean;
  readonly items: readonly HomeScreenLayoutItem[];
  readonly maxItems: number;
  readonly isAtMaxPerFolder?: boolean;
  readonly isAtMax: boolean;
  readonly isRemovable?: boolean;
  readonly onRemove: (
    app: App,
    ctx: {
      kind: HomeScreenLayoutDeviceKind;
      context: HomeScreenLayoutPreviewContextKind;
      disabled: boolean;
      isRemovable: boolean;
    },
  ) => void;
  readonly className?: string;
  readonly onSelect?: (app: any) => void;
  readonly isSelected?: boolean;
  readonly pageIndex?: number;
  readonly name: string;
  readonly hideName?: boolean;
}

const SingleApp = (props: SingleAppProps) => {
  const {
    id: appId,
    kind,
    context,
    name,
    hideName = false,
    icon,
    Type,
    appType,
    deviceFamilies,
    BundleID,
    URL,
    alwaysInPreview,
    onRemove,
    isRemovable = false,
    disabled = false,
    items,
    maxItems,
    isAtMaxPerFolder,
    isAtMax,
    className,
    onSelect = /* istanbul ignore next */ () => {},
    isSelected,
    pageIndex,
  } = props;

  const app = {
    id: appId,
    name,
    icon,
    Type,
    appType,
    deviceFamilies,
    BundleID,
    alwaysInPreview,
    URL,
  };

  const internalNodeRef = useRef<HTMLDivElement | null>(null);

  const { over } = useDndContext();

  const {
    isDragging,
    attributes,
    listeners,
    transition,
    transform,
    setNodeRef,
  } = useSortable({
    id: getSortableItemTypeByPreviewContext(appId, context),
    data: {
      dndContext: getDndContextPreviewContext(context),
      item: app,
      items,
      maxItems,
      isAtMax,
      pageIndex,
    },
    transition: {
      duration: 150,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    },
    disabled,
    attributes: {
      roleDescription: `sortable ${app.name} app`,
    },
  });

  const handleRemove =
    /* istanbul ignore next - tested in other components */ () => {
      // Remove the current app from the selections, if it's there:
      if (isSelected) {
        onSelect(app);
      }

      onRemove(app, {
        kind,
        context,
        disabled,
        isRemovable,
      });
    };

  const handleTooltipOpen = (open: boolean) => {
    if (!open || disabled) {
      return Promise.resolve();
    }

    const [tooltipNode, { isTruncated }] = getTruncatedAppLabelStatus({
      parentNode: internalNodeRef.current,
      selector: '[data-dnd-tooltip]',
    });

    if (!tooltipNode || !isTruncated) {
      return Promise.resolve();
    }

    return Promise.reject();
  };

  return (
    <TooltipProvider>
      <Tooltip
        onOpen={handleTooltipOpen}
        side="bottom"
        openDelay={500}
        content={app.name}
      >
        <AppContainer
          aria-label={app.name}
          ref={(r) => {
            setNodeRef(r);
            internalNodeRef.current = r;
          }}
          flow="column"
          alignItems="center"
          className={className}
          disabled={disabled}
          css={{
            width: APP_ICON_CONFIG.containerSize,

            position: 'relative',
            outline: 'none',
            transition: transition || 'none',
            transform:
              over?.data?.current?.item?.Type === 'Folder'
                ? 'none'
                : DndCSS.Translate.toString(transform) || 'none',
            opacity: isDragging || disabled ? 0.6 : 1,

            '&:focus-within': {
              '[data-dnd-focusable=true]': {
                boxShadow: '$$focusedAppShadow',
              },
            },
          }}
          {...listeners}
          {...attributes}
        >
          {isRemovable && (
            <RemoveButton
              aria-label={`Remove ${app.name} app from the Home Screen Layout preview area`}
              onClick={handleRemove}
              css={{
                visibility: 'hidden',
                display: 'flex',
                alignItems: 'center',
                position: 'absolute',
                padding: '$1',
                right: '0px',
                top: '-3px',
                borderRadius: '14px',
                outline: '1px solid $neutral20',
                background: '$neutral5',
                boxShadow: '$elevation2',
                zIndex: 1,
              }}
            >
              <Icon size="xs" name="minus" />
            </RemoveButton>
          )}

          <AppIcon
            app={app}
            disabled={disabled || (isAtMaxPerFolder && !isSelected)}
            hideName={hideName}
            onMouseUp={() => {
              /* istanbul ignore next - silly workout until nectar-ui gets fixed */
              if (disabled) {
                return;
              }
              /* istanbul ignore next */
              if (!isDragging) {
                onSelect(app);
              }
            }}
            isSelected={isSelected}
            hideCount
            css={{
              width: '52px',
            }}
          />
        </AppContainer>
      </Tooltip>
    </TooltipProvider>
  );
};

export default SingleApp;
