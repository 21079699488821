// istanbul ignore file
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import type {
  FiltersState,
  GetBehavioralThreatGroupResponse,
  PaginationState,
  SortColumnState,
} from '../../../../threat.types';
import behavioralThreatsGroupQuery from './behavioralThreatsGroupQuery';

type UseGetBehavioralThreatsGroup = (
  filters: FiltersState,
  sort: SortColumnState,
  pagination: PaginationState,
  enabled?: boolean,
) => {
  data: GetBehavioralThreatGroupResponse | undefined;
  isLoading: boolean;
  isError: boolean;
  error: Error | null;
};

/**
 * Retrieves threat details based on the provided filters, sort, pagination, and computer information.
 *
 * @param filters - The filters to apply to the threat details.
 * @param sort - The sorting options for the threat details.
 * @param pagination - The pagination options for the threat details.
 * @param enabled - Boolean to enable or disable the query.
 * @returns An object containing the threat details data and a boolean indicating if the data is currently being loaded.
 */
const useGetBehavioralThreatsGroup: UseGetBehavioralThreatsGroup = (
  filters,
  sort,
  pagination,
  enabled = true,
) => {
  const { data, isPlaceholderData, isLoading, isError, error } = useQuery({
    queryKey: ['behavioral-threats-group', window?.location?.search],
    queryFn: () => behavioralThreatsGroupQuery(filters, sort, pagination),
    placeholderData: keepPreviousData,
    retry: 2,
    retryDelay: 1000,
    enabled,
  });

  return {
    data: data?.data,
    isLoading: isLoading || isPlaceholderData,
    isError,
    error,
  };
};

export default useGetBehavioralThreatsGroup;
