/* istanbul ignore file */
import { Flex, Heading, Tabs } from '@kandji-inc/nectar-ui';
import React, { useMemo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import type { ImperativePanelGroupHandle } from 'react-resizable-panels';
import { getComputersForFilters } from 'src/app/_actions/computer';
import {
  Panel,
  PanelGroup,
} from '../visibility/prism/components/panel-helpers';
import { LogsTable } from './components/logs';
import { UrlProvider } from './contexts/url-context';

const AgentLogs = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const panelGroupRef = useRef<ImperativePanelGroupHandle>(null);

  const agentLogsTabs = useMemo(
    () => [
      {
        label: 'Logs',
        tabId: 'logs',
        onClick: () => history.push(`/logs`),
      },
    ],
    [history],
  );

  useEffect(() => {
    dispatch(getComputersForFilters());
  }, [dispatch]);

  const computers = useSelector((state) => state.filters.computers);

  return (
    <UrlProvider>
      <PanelGroup groupRef={panelGroupRef} canResize={false}>
        <Panel canResize={false} defaultSize={100}>
          <Flex flow="column">
            <Heading
              as="h2"
              size="1"
              css={{
                pb: '16px',
                pt: '32px',
              }}
            >
              Device Logs and Events
            </Heading>
          </Flex>
          <Tabs.Container tabId="logs" tabs={agentLogsTabs}>
            <Tabs.Content tabId="logs">
              <LogsTable computers={computers} />
            </Tabs.Content>
          </Tabs.Container>
        </Panel>
      </PanelGroup>
    </UrlProvider>
  );
};

export default React.memo(AgentLogs);
