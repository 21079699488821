import {
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';

import WifiService from '../../service/wifi-service';
import { useShowCategories } from '../protocols-card.hooks';
import AcceptedEapTypes from './accepted-eap-types';
import Authentication from './authentication';
import InnerAuthentication from './inner-authentication';
import MinimumRandValues from './minimum-rand-values';
import OuterIdentity from './outer-identity';
import Pac from './pac';
import RequireTwoFactor from './require-two-factor';
import TslMaximumVersion from './tsl-maximum-version';
import TslMinimumVersion from './tsl-minimum-version';
import Username from './username';

const acceptedEapTypesKeys = WifiService.keys.authentication.acceptedEapTypes;

const fieldsToValidate = [acceptedEapTypesKeys.authentication];

const EapTypesRow = (props) => {
  const { setting, update, isDisabled, validationDep } = props;

  const { refs, onInvalidate } = useInputsValidators(fieldsToValidate, update);
  useRemoveValidationOnUnmount(fieldsToValidate, update);

  const {
    showAuthentication,
    showInnerAuthentication,
    showOuterIdentity,
    showTlsMinimumVersion,
    showTlsMaximumVersion,
    showAuthenticationCredentials,
    showTlsUsername,
    showProvisionPac,
    showProvisionPacAnonym,
    showMinimumRandValues,
    showTwoFactorAuth,
    canShowInnerAuthenticationError,
    canShowMinimumTlsVersionError,
    canShowMaximumTlsVersionError,
    canShowRandValuesError,
    showPac,
  } = useShowCategories({ validationDep, onInvalidate, setting });

  return (
    <Setting.Row>
      <AcceptedEapTypes {...props} />

      {!!setting[acceptedEapTypesKeys.acceptedEapTypes].length && (
        <Setting.SecondaryControls className="k-ade-secondary-ctrl-grid-gap-align">
          {showTlsUsername && <Username {...props} />}
          {showAuthentication && (
            <Authentication
              {...props}
              showAuthenticationCredentials={showAuthenticationCredentials}
            />
          )}
          {showTwoFactorAuth && (
            <RequireTwoFactor
              setting={setting}
              update={update}
              isDisabled={isDisabled}
            />
          )}
          {showInnerAuthentication && (
            <InnerAuthentication
              setting={setting}
              update={update}
              isDisabled={isDisabled}
              refs={refs}
              canShowInnerAuthenticationError={canShowInnerAuthenticationError}
            />
          )}
          {showOuterIdentity && (
            <OuterIdentity
              update={update}
              isDisabled={isDisabled}
              setting={setting}
            />
          )}
          {showTlsMinimumVersion && (
            <TslMinimumVersion
              setting={setting}
              update={update}
              isDisabled={isDisabled}
              refs={refs}
              canShowMinimumTlsVersionError={canShowMinimumTlsVersionError}
            />
          )}
          {showTlsMaximumVersion && (
            <TslMaximumVersion
              refs={refs}
              isDisabled={isDisabled}
              setting={setting}
              update={update}
              canShowMaximumTlsVersionError={canShowMaximumTlsVersionError}
            />
          )}
          {showPac && (
            <Pac
              update={update}
              isDisabled={isDisabled}
              setting={setting}
              showProvisionPac={showProvisionPac}
              showProvisionPacAnonym={showProvisionPacAnonym}
            />
          )}
          {showMinimumRandValues && (
            <MinimumRandValues
              setting={setting}
              update={update}
              isDisabled={isDisabled}
              refs={refs}
              canShowRandValuesError={canShowRandValuesError}
            />
          )}
        </Setting.SecondaryControls>
      )}
    </Setting.Row>
  );
};

export default EapTypesRow;
