import { Checkbox, Chip, Select, Slider } from '@kandji-inc/bumblebee';
/* istanbul ignore file */
import React, { useEffect } from 'react';

import { Setting } from 'features/library-items/template';

import { i18n } from 'src/i18n';
import EnergySaverService from '../service/energy-saver.service';

const Desktops = (props) => {
  const { setting, generalSetting, update, isDisabled } = props;

  const computerSleepOptions = [
    {
      label: i18n.t('Prevent computer from sleeping'),
      value: EnergySaverService.computerSleep.PREVENT,
    },
    {
      label: i18n.t('Allow computer to sleep'),
      value: EnergySaverService.computerSleep.ALLOW,
    },
  ];

  const diskSleepOptions = [
    {
      label: i18n.t('Enable'),
      value: EnergySaverService.diskSleep.ENABLE,
    },
    {
      label: i18n.t('Disable'),
      value: EnergySaverService.diskSleep.DISABLE,
    },
  ];

  const wakeLanOptions = [
    {
      label: i18n.t('Enable'),
      value: EnergySaverService.wakeLan.ENABLE,
    },
    {
      label: i18n.t('Disable'),
      value: EnergySaverService.wakeLan.DISABLE,
    },
  ];

  const restartOptions = [
    {
      label: i18n.t('Enable'),
      value: EnergySaverService.restartOptions.ENABLE,
    },
    {
      label: i18n.t('Disable'),
      value: EnergySaverService.restartOptions.DISABLE,
    },
  ];

  const powerNapOptions = [
    {
      label: i18n.t('Enable'),
      value: EnergySaverService.powerNap.ENABLE,
    },
    {
      label: i18n.t('Disable'),
      value: EnergySaverService.powerNap.DISABLE,
    },
  ];

  useEffect(() => {
    if (generalSetting.DestroyFVKeyOnStandby) {
      update('isPowerNap', true);
      update('DarkWakeBackgroundTasks', EnergySaverService.powerNap.DISABLE);
    }

    if (generalSetting.SleepDisabled) {
      update('isSystemSleepTimer', true);
      update('System Sleep Timer', EnergySaverService.computerSleep.PREVENT);
    }
  }, [generalSetting]);

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('Desktops')}</h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row className="k-es-config-row">
          <Setting.Controls className="k-es-config-row__controls">
            <Checkbox
              isDisabled={isDisabled}
              checked={setting.isTurnDisplayOff}
              label={i18n.t('Turn display off after a period of inactivity')}
              onChange={() => update('isTurnDisplayOff', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light k-es-config-row__helper">
            <p>
              {i18n.t(
                'In order to require a password immediately after the display is turned off, create a Passcode profile with the “Require Passcode After Sleep or Screen Saver Begins” option enabled.',
              )}
            </p>
          </Setting.Helpers>
          {setting.isTurnDisplayOff && (
            <Setting.SecondaryControls className="k-es-config-row__secondary">
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <div className="b-txt b-mb2">
                    {i18n.t('Turn display off after')}{' '}
                    <Chip
                      style={{ display: 'inline' }}
                      text={
                        !setting['Display Sleep Timer']
                          ? i18n.t('Never')
                          : i18n.common.durationMins(
                              setting['Display Sleep Timer'],
                            )
                      }
                    />
                  </div>
                  <Slider
                    className="mb-4"
                    value={
                      !setting['Display Sleep Timer']
                        ? 181
                        : setting['Display Sleep Timer']
                    }
                    onChange={(value) =>
                      update('Display Sleep Timer', value > 180 ? 0 : value)
                    }
                    disabled={isDisabled}
                    min={1}
                    max={181}
                    getMarkerLabel={(val) => {
                      if (val === 1) {
                        return i18n.t('{val} min', { val });
                      }
                      if (val === 180) {
                        return i18n.t('Never');
                      }
                      if (!(val % 60)) {
                        return i18n.common.durationHrs(val / 60);
                      }
                      return '';
                    }}
                    markedValues={[1, 60, 120, 180]}
                  />
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        <Setting.Row className="k-es-config-row">
          <Setting.Controls className="k-es-config-row__controls">
            <Checkbox
              isDisabled={
                isDisabled ||
                (!setting['Display Sleep Timer'] && setting.isTurnDisplayOff) ||
                generalSetting.SleepDisabled
              }
              checked={setting.isSystemSleepTimer}
              label={i18n.t('Configure computer sleep')}
              onChange={() => update('isSystemSleepTimer', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light k-es-config-row__helper">
            <p>
              {i18n.ut(`Configure the computer's sleep settings.
              <br />
              <br />
              Setting this option to “Prevent computer from sleeping” will
              configure the computer to never sleep. Alternatively, setting this
              option to “Allow computer to sleep” will set the system sleep
              value, in terms of minutes, to the value of the display sleep
              option plus 10 (if display sleep is not configured the value will
              be set to 10).`)}
            </p>
          </Setting.Helpers>
          {setting.isSystemSleepTimer && (
            <Setting.SecondaryControls className="k-es-config-row__secondary">
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <Select
                    compact
                    options={computerSleepOptions}
                    value={computerSleepOptions.find(
                      ({ value }) => value === setting['System Sleep Timer'],
                    )}
                    disabled={
                      isDisabled ||
                      (!setting['Display Sleep Timer'] &&
                        setting.isTurnDisplayOff) ||
                      generalSetting.SleepDisabled
                    }
                    onChange={({ value }) =>
                      update('System Sleep Timer', value)
                    }
                  />
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        <Setting.Row className="k-es-config-row">
          <Setting.Controls className="k-es-config-row__controls">
            <Checkbox
              isDisabled={isDisabled}
              checked={setting.isDiskSleepTimer}
              label={i18n.t('Put hard disks to sleep when possible')}
              onChange={() => update('isDiskSleepTimer', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light k-es-config-row__helper">
            <p>
              {i18n.t(
                'Automatically put the hard disks to sleep whenever possible, enabling this option sets the disk sleep value to 10 minutes.',
              )}
            </p>
          </Setting.Helpers>
          {setting.isDiskSleepTimer && (
            <Setting.SecondaryControls className="k-es-config-row__secondary">
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <Select
                    compact
                    options={diskSleepOptions}
                    value={diskSleepOptions.find(
                      ({ value }) => value === setting['Disk Sleep Timer'],
                    )}
                    disabled={isDisabled}
                    onChange={({ value }) => update('Disk Sleep Timer', value)}
                  />
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        <Setting.Row className="k-es-config-row">
          <Setting.Controls className="k-es-config-row__controls">
            <Checkbox
              isDisabled={isDisabled}
              checked={setting.isWakeOnLan}
              label={i18n.t('Wake for network access')}
              onChange={() => update('isWakeOnLan', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light k-es-config-row__helper">
            <p>
              {i18n.t(
                'Users can access shared printers, file sharing, or use remote management even when the computer is in sleep mode.',
              )}
            </p>
          </Setting.Helpers>
          {setting.isWakeOnLan && (
            <Setting.SecondaryControls className="k-es-config-row__secondary">
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <Select
                    compact
                    options={wakeLanOptions}
                    value={wakeLanOptions.find(
                      ({ value }) => value === setting['Wake on LAN'],
                    )}
                    disabled={isDisabled}
                    onChange={({ value }) => update('Wake on LAN', value)}
                  />
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        <Setting.Row className="k-es-config-row">
          <Setting.Controls className="k-es-config-row__controls">
            <Checkbox
              isDisabled={isDisabled}
              checked={setting.isRestartOnPowerLoss}
              label={i18n.t('Start up automatically after a power failure')}
              onChange={() => update('isRestartOnPowerLoss', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light k-es-config-row__helper">
            <p>
              {i18n.t(
                'The computer will automatically start up after an unexpected loss of power.',
              )}
            </p>
          </Setting.Helpers>
          {setting.isRestartOnPowerLoss && (
            <Setting.SecondaryControls className="k-es-config-row__secondary">
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <Select
                    compact
                    options={restartOptions}
                    value={restartOptions.find(
                      ({ value }) =>
                        value === setting['Automatic Restart On Power Loss'],
                    )}
                    disabled={isDisabled}
                    onChange={({ value }) =>
                      update('Automatic Restart On Power Loss', value)
                    }
                  />
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        <Setting.Row className="k-es-config-row">
          <Setting.Controls className="k-es-config-row__controls">
            <Checkbox
              isDisabled={isDisabled || generalSetting.DestroyFVKeyOnStandby}
              checked={setting.isPowerNap}
              label={i18n.t('Power Nap')}
              onChange={() => update('isPowerNap', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light k-es-config-row__helper">
            <p>
              {i18n.t(
                'While sleeping, the Mac can periodically check for new email, calendar, and other iCloud updates.',
              )}{' '}
              <a
                href="https://support.apple.com/en-us/HT204032"
                rel="noopener noreferrer"
                target="_blank"
                className="b-alink"
              >
                {i18n.t('Learn more')}
              </a>
            </p>
          </Setting.Helpers>
          {setting.isPowerNap && (
            <Setting.SecondaryControls className="k-es-config-row__secondary">
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <Select
                    compact
                    options={powerNapOptions}
                    value={powerNapOptions.find(
                      ({ value }) => value === setting.DarkWakeBackgroundTasks,
                    )}
                    disabled={
                      isDisabled || generalSetting.DestroyFVKeyOnStandby
                    }
                    onChange={({ value }) =>
                      update('DarkWakeBackgroundTasks', value)
                    }
                  />
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default Desktops;
