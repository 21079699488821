import React, {
  useCallback,
  useEffect,
  useState,
  createContext,
  useContext,
} from 'react';
import { oktaDeviceTrustService } from '../services';
import type { DevicePlatformResponse } from '../types';

type Props = {
  children: JSX.Element;
};

type OktaDeviceTrustContextType = {
  data: DevicePlatformResponse | null;
  loading: boolean;
  hasFetched: boolean;
  clearData: () => void;
  error: Error | undefined;
  getOktaDeviceTrustDetails: () => Promise<void>;
};

const OktaDeviceTrustContext = createContext<OktaDeviceTrustContextType | null>(
  null,
);

// exposing this if we ever need to fetch ODT details outside of the provider.
const useGetOktaDeviceTrustDetails = ({
  disableAutoFetch,
}: {
  disableAutoFetch: boolean;
}) => {
  const [data, setData] = useState<DevicePlatformResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const [error, setError] = useState<Error>();

  const getOktaDeviceTrustDetails = useCallback(async () => {
    setLoading(true);

    try {
      const response = await oktaDeviceTrustService.getOktaDeviceTrustDetails();
      if (response.success && response.data.results.length > 0) {
        setData(response.data);
        setHasFetched(true);
      }
    } catch (e) {
      // istanbul ignore if
      if (e instanceof Error) {
        setError(e);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  const clearData = () => {
    setData(null);
    setHasFetched(false);
  };

  useEffect(() => {
    if (!data && !disableAutoFetch) {
      getOktaDeviceTrustDetails();
    }
  }, [data, getOktaDeviceTrustDetails, disableAutoFetch]);

  return {
    data,
    loading,
    error,
    hasFetched,
    clearData,
    getOktaDeviceTrustDetails,
  };
};

const OktaDeviceTrustProvider = ({ children }: Props) => {
  const {
    data,
    loading,
    error,
    hasFetched,
    clearData,
    getOktaDeviceTrustDetails,
  } = useGetOktaDeviceTrustDetails({ disableAutoFetch: true });

  const value = {
    data,
    loading,
    error,
    hasFetched,
    clearData,
    getOktaDeviceTrustDetails,
  };
  return (
    <OktaDeviceTrustContext.Provider value={value}>
      {children}
    </OktaDeviceTrustContext.Provider>
  );
};

type DeviceTrustIntegrationsParams = {
  isAutoFetchEnabled?: boolean;
};

const useGetOktaDeviceTrust = (params?: DeviceTrustIntegrationsParams) => {
  const context = useContext(OktaDeviceTrustContext);

  if (!context) {
    throw new Error(
      'useGetOktaDeviceTrust has to be used within <OktaDeviceTrustContext.Provider>',
    );
  }

  const { data, loading, hasFetched, getOktaDeviceTrustDetails } = context;
  const hasData = data && data.results.length > 0;

  useEffect(() => {
    let isSubscribed = true;
    // to support conditional fetching
    const shouldFetch = params?.isAutoFetchEnabled ?? true;

    if (!hasData && !loading && !hasFetched && isSubscribed && shouldFetch) {
      getOktaDeviceTrustDetails();
    }
    return () => {
      isSubscribed = false;
    };
  }, []);
  return context;
};

export { OktaDeviceTrustProvider, useGetOktaDeviceTrustDetails };
export default useGetOktaDeviceTrust;
