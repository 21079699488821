/* istanbul ignore file */
import React from 'react';
import agentLogsService from '../data-service';

function removeTypeKey(inputObj: Record<string, any>): Record<string, any> {
  const obj = { ...inputObj };
  if (obj.hasOwnProperty('type')) {
    const typeKeys = Object.keys(obj.type);
    for (const key of typeKeys) {
      if (Array.isArray(obj.type[key]) && obj.type[key].includes('log')) {
        delete obj['type'];
        break;
      }
    }
  }
  return obj;
}

export function useLogs(query, from, size) {
  const [logs, setLogs] = React.useState([]);
  const [events, setEvents] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [isLoading, setLoading] = React.useState(false);

  React.useEffect(() => {
    async function fetchLogs() {
      setLoading(true);
      const logsQuery = removeTypeKey(query);
      const getLogs = agentLogsService
        .getLogs(logsQuery, from, size)
        .catch(() => null);
      const getEvents = agentLogsService
        .getEvents(query, from, size)
        .catch(() => null);
      const [logsResult, eventsResult] = await Promise.all([
        getLogs,
        getEvents,
      ]);

      setLogs(logsResult?.data?.logs || []);
      setEvents(eventsResult?.data?.logs || []);
      setCount((logsResult?.data?.hits || 0) + (eventsResult?.data?.hits || 0));
      setLoading(false);
    }

    fetchLogs();
  }, [query, from, size]);

  const pageCount = Math.ceil(count / size);

  return { logs, events, count, isLoading, pageCount };
}
