import { NFSelectV2 } from '@kandji-inc/nectar-form';
import {
  Flex,
  Icon,
  type OptionGroup,
  SelectContents,
  SelectListItems,
  SelectV2Primitives,
  Text,
  getGroupedOptions,
  styled,
} from '@kandji-inc/nectar-ui';
import React from 'react';

import { useRulesContext } from 'features/rules-modal/RulesContext';
import { getIcons } from '../../rules/builder/icons/helpers';

const IconContainer = styled(Flex, {
  visibility: 'hidden',
});

const ItemContainer = styled(SelectV2Primitives.Item, {
  [`&:hover ${IconContainer}`]: {
    visibility: 'visible',
  },
});

export const FacetCustomSelect = (props) => {
  const { fieldName, options, disabled, validation, placeholder, ariaLabel } =
    props;

  const { supportedDeviceFamilies, installOnDeviceFamilies } =
    useRulesContext();

  const { groupedOptions, ungroupedOptions } = getGroupedOptions(options);

  return (
    <NFSelectV2
      name={fieldName}
      options={options}
      trigger={(ref, selectedOption) => (
        <SelectV2Primitives.Trigger ref={ref} variant="input" {...props}>
          <SelectV2Primitives.Value
            placeholder={
              <SelectV2Primitives.Placeholder>
                {placeholder}
              </SelectV2Primitives.Placeholder>
            }
            asChild
          >
            <Flex
              gap="xs"
              justifyContent="space-between"
              alignItems="center"
              css={{ width: '100%' }}
            >
              <div>{selectedOption.label}</div>
              <Flex>
                {getIcons(
                  selectedOption?.deviceFamilies,
                  supportedDeviceFamilies,
                  installOnDeviceFamilies,
                  disabled,
                )}
              </Flex>
            </Flex>
          </SelectV2Primitives.Value>
          <SelectV2Primitives.Icon className="selectIcon">
            <Icon name="fa-angle-down-small" />
          </SelectV2Primitives.Icon>
        </SelectV2Primitives.Trigger>
      )}
      content={() => {
        return (
          <SelectContents.Default>
            {groupedOptions.map(([group, groupOptions]: OptionGroup) => (
              <SelectV2Primitives.Group key={group}>
                <SelectV2Primitives.GroupLabel>
                  {group}
                </SelectV2Primitives.GroupLabel>
                {groupOptions.map((option) => {
                  return (
                    <ItemContainer
                      key={option.value}
                      value={option.value}
                      disabled={option.disabled}
                    >
                      <Flex
                        gap="sm"
                        justifyContent="space-between"
                        alignItems="center"
                        css={{ width: '100%' }}
                      >
                        <div>
                          {option.label}
                          {option.hint && (
                            <Text size="1" variant="description">
                              {option.hint}
                            </Text>
                          )}
                        </div>
                        <IconContainer gap="xs">
                          {getIcons(
                            option?.deviceFamilies,
                            supportedDeviceFamilies,
                            installOnDeviceFamilies,
                            disabled,
                          )}
                        </IconContainer>
                      </Flex>
                    </ItemContainer>
                  );
                })}

                {
                  /* istanbul ignore next */ groupedOptions.length > 0 &&
                    ungroupedOptions.length > 0 && (
                      <SelectV2Primitives.Separator />
                    )
                }
                {ungroupedOptions.map(
                  /* istanbul ignore next */ (opt) => (
                    <SelectListItems.Default {...opt} />
                  ),
                )}
              </SelectV2Primitives.Group>
            ))}
          </SelectContents.Default>
        );
      }}
      disabled={disabled}
      validation={validation}
      placeholder={placeholder}
      aria-label={ariaLabel}
    />
  );
};
