// istanbul ignore file
import { useSuspenseQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { ThreatService } from '../../data-service';

type UseGetAssignedBlueprint = () => {
  isAssigned: boolean;
  isLoading: boolean;
};

/**
 * Retrieves a boolean indicating if there is an assigned blueprint.
 *
 * @returns An object containing the assigned blueprint data and a boolean indicating if the data is currently being loaded.
 */
const useGetAssignedBlueprint: UseGetAssignedBlueprint = () => {
  const threatService = useMemo(() => new ThreatService(), []);
  const { data, isLoading } = useSuspenseQuery({
    queryKey: ['threats-assigned-blueprint'],
    queryFn: () => threatService.getAssignedBlueprint({}),
    retry: 2,
    retryDelay: 1000,
  });

  const isAssigned = data?.data?.avert_enabled || false;
  return { isAssigned, isLoading };
};

export default useGetAssignedBlueprint;
