/* istanbul ignore file - legacy code, moved */
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Sticky from 'theme/components/atoms/Sticky';
import { IPADataContext } from './IPADataContext';
import { IPAFormCopy } from './IPAFormCopy';
import { IPAToggle } from './IPAToggle';

export const IPAFormSection = ({
  title,
  sticky,
  manage,
  name,
  on,
  off,
  copy,
  disabled,
  children,
  className,
}) => {
  const { isReadOnly } = useContext(IPADataContext);
  return (
    <div
      className={`theme-form-section paper${className ? ` ${className}` : ''}`}
    >
      {title && (
        <Sticky
          label="theme-form-section-header"
          origin="paper"
          edge="border"
          sticky={sticky}
        >
          <div className="node">
            <h3
              className="theme-form-section-title paper-title"
              style={{ fontWeight: 500 }}
            >
              {title}
            </h3>
          </div>
          {name && (
            <div className="node">
              <IPAToggle
                name={name}
                on={on}
                off={off}
                disabled={disabled || isReadOnly}
                status
              />
            </div>
          )}
        </Sticky>
      )}
      {copy && (
        <div className="theme-form-section-aside">
          <div className="node">
            <IPAFormCopy copy={copy} />
          </div>
        </div>
      )}
      {manage && children && (
        <div className="theme-form-section-body">
          <div className="node">{children}</div>
        </div>
      )}
    </div>
  );
};

IPAFormSection.propTypes = {
  title: PropTypes.string,
  sticky: PropTypes.bool,
  manage: PropTypes.bool,
  name: PropTypes.string,
  on: PropTypes.string,
  off: PropTypes.string,
  copy: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disabled: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

IPAFormSection.defaultProps = {
  title: '',
  sticky: true,
  manage: true,
  name: undefined,
  on: undefined,
  off: undefined,
  copy: undefined,
  disabled: false,
  children: undefined,
  className: '',
};
