import {
  Flex,
  Text,
  Tooltip,
  TooltipProvider,
  styled,
} from '@kandji-inc/nectar-ui';
/* istanbul ignore file */
import React, { useRef, useState, useEffect } from 'react';

const MAX_WIDTH = 185;

const TagText = styled(Text, {
  color: '$neutral90',
  fontWeight: '$medium',
  fontSize: '14px',
});

const TagDisplay = ({ tags }) => {
  const [visibleCount, setVisibleCount] = useState(0);
  const measureRef = useRef();

  useEffect(() => {
    let totalWidth = 0;
    let count = 0;

    tags.some((tag, index) => {
      // @ts-ignore
      measureRef.current.textContent = tag;
      // @ts-ignore
      totalWidth += measureRef.current.offsetWidth;
      if (totalWidth > MAX_WIDTH) {
        return true;
      }
      count = index + 1;
      return false;
    });

    setVisibleCount(count);
  }, [tags]);

  return (
    <TooltipProvider>
      <Flex flow="row" gap="xs" alignItems="center">
        <TagText>{tags.slice(0, visibleCount).join(', ')}</TagText>
        <Tooltip
          content={tags.slice(visibleCount).join(', ')}
          css={{ zIndex: 9999999 }}
        >
          <TagText>
            {visibleCount < tags.length && ` +${tags.length - visibleCount}`}
          </TagText>
        </Tooltip>
        <span
          ref={measureRef}
          style={{
            visibility: 'hidden',
            whiteSpace: 'nowrap',
            position: 'absolute',
          }}
        />
      </Flex>
    </TooltipProvider>
  );
};

export default TagDisplay;
