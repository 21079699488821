/* istanbul ignore file - legacy code, moved */
import _cloneDeep from 'lodash/fp/cloneDeep';
import _flow from 'lodash/fp/flow';
import _omit from 'lodash/fp/omit';
import React, { createContext, useContext, useEffect } from 'react';
import { IPALibraryContext } from './IPALibraryContext';

const summary = {
  icon: '',
  name: 'In-House App',
  description: '',
  publisher: 'Kandji',
  device_family: ['iPhone', 'iPad'],
  os_requirement: [''],
};

const initial = {
  type: 'ipa-app',
  identifier: '',
  name: '',
  active: true,
  blueprints: [],
  data: {},
};

export const deserialize = (dataRaw) => {
  const buildData = _flow(_cloneDeep, _omit(['settings']));
  const data = buildData(dataRaw);
  const optional = {};
  return { data, optional };
};

const mapData = _flow(_cloneDeep, (data) => ({
  name: data.name,
  active: data.active,
  data: {
    ...data.data, // all properties within "data.data" are required for a PATCH request
    // mapped settings
  },
}));

const processData = (dataFlat, dataMapped, manage) => {
  return _cloneDeep(dataMapped);
};

export const serialize = (dataFlat, manage) => {
  const dataMapped = mapData(dataFlat);
  return processData(dataFlat, dataMapped, manage);
};

const defaultValues = {
  onSave: () => {},
};

const IPADataContext = createContext(defaultValues);

export const IPADataProvider = ({ children }) => {
  const libraryContext = useContext(IPALibraryContext);
  const {
    data: dataRaw,
    initializeData,
    initializeManage,
    makeSaveHandler,
  } = libraryContext;
  const { data, optional } = dataRaw ? deserialize(dataRaw) : {};
  // console.log('Deserialized:', data); // delete
  const { type, identifier } = initial;
  const onSave = (form) =>
    makeSaveHandler(serialize, form, type, identifier, summary.name);
  useEffect(() => {
    initializeData(initial);
  }, [initial]);
  useEffect(() => {
    initializeManage(optional);
  }, [optional]);
  return (
    <IPADataContext.Provider
      value={{
        ...libraryContext,
        ...defaultValues,
        summary,
        initial,
        data,
        onSave,
      }}
    >
      {children}
    </IPADataContext.Provider>
  );
};

export { IPADataContext, summary };
