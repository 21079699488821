/* istanbul ignore file */
import { createColumnHelper } from '@tanstack/react-table';
import type { z } from 'zod';

import { getIconName } from '../../components/PrismNav/prismNavUtils';
import {
  ON_OFF_CELL_FILTER_OPTIONS,
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  getFilteredDisabled as filterDisabled,
  getSpecificDeviceFamilyCell,
} from '../column-utils';
import { getScharedColumns } from './getSharedColumns';

export const getActivationLockColumns = ({
  activationLockSchema,
}): {
  columnDefs: any[];
  pinnedColumns: string[];
  alwaysHiddenColumns: string[];
} => {
  const { properties } = activationLockSchema.schema;
  const activationLockColumnHelper =
    createColumnHelper<z.infer<typeof properties>>();

  const columnDefs = [
    activationLockColumnHelper.accessor(
      (row) => row['activation_lock.device_activation_lock_status'],
      {
        id: 'activation_lock.device_activation_lock_status',
        cell: (info) =>
          getSpecificDeviceFamilyCell(info, ['iPad', 'iPhone'], 'on-off-badge'),
        meta: {
          displayName: properties.device_activation_lock_status.title,
          displayIcon: getIconName(activationLockSchema.uri),
          filterType: 'boolean',
          filterOptions: [...ON_OFF_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    activationLockColumnHelper.accessor(
      (row) => row['activation_lock.device_albc'],
      {
        id: 'activation_lock.device_albc',
        cell: (info) =>
          getSpecificDeviceFamilyCell(info, ['iPad', 'iPhone'], 'boolean'),
        meta: {
          displayName: properties.device_albc.title,
          displayIcon: getIconName(activationLockSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    activationLockColumnHelper.accessor(
      (row) => row['activation_lock.user_activation_lock_status'],
      {
        id: 'activation_lock.user_activation_lock_status',
        cell: (info) =>
          getSpecificDeviceFamilyCell(
            info,
            ['Mac', 'AppleTV', 'iPad', 'iPhone'],
            'on-off',
          ),
        meta: {
          displayName: properties.user_activation_lock_status.title,
          displayIcon: getIconName(activationLockSchema.uri),
          filterType: 'boolean',
          filterOptions: [...ON_OFF_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    activationLockColumnHelper.accessor(
      (row) => row['activation_lock.user_albc_escrowed'],
      {
        id: 'activation_lock.user_albc_escrowed',
        cell: (info) =>
          getSpecificDeviceFamilyCell(
            info,
            ['iPad', 'iPhone', 'Mac'],
            'boolean',
          ),
        meta: {
          displayName: properties.user_albc_escrowed.title,
          displayIcon: getIconName(activationLockSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    activationLockColumnHelper.accessor(
      (row) => row['activation_lock.bypass_code_expired'],
      {
        id: 'activation_lock.bypass_code_expired',
        cell: (info) =>
          getSpecificDeviceFamilyCell(
            info,
            ['iPad', 'iPhone', 'Mac'],
            'boolean',
          ),
        meta: {
          defaultHidden: true,
          displayName: properties.bypass_code_expired.title,
          displayIcon: getIconName(activationLockSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    activationLockColumnHelper.accessor(
      (row) => row['activation_lock.user_activation_lock_allowed'],
      {
        id: 'activation_lock.user_activation_lock_allowed',
        cell: YesNoCell,
        meta: {
          displayName: properties.user_activation_lock_allowed.title,
          displayIcon: getIconName(activationLockSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    activationLockColumnHelper.accessor(
      (row) => row['activation_lock.is_activation_lock_supported'],
      {
        id: 'activation_lock.is_activation_lock_supported',
        cell: YesNoCell,
        meta: {
          defaultHidden: true,
          displayName: properties.is_activation_lock_supported.title,
          displayIcon: getIconName(activationLockSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    ...getScharedColumns(activationLockSchema),
  ];

  return { columnDefs, pinnedColumns: [], alwaysHiddenColumns: [] };
};
