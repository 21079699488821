import uuidv4 from 'uuid/v4';
import validator from 'validator';
import { libraryTypes } from '../../../data-service/library-item/library-item-service';
import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class CertificateService extends NewLibraryItemService {
  static installationTypes = {
    INSTALL_ONCE: 'install_once',
    // CONTINUOUSLY_ENFORCE: 'continuously_enforce',
    NO_ENFORCEMENT: 'no_enforcement',
  };

  static createSan = () => ({
    _id: uuidv4(),
    type: null,
    value: '',
  });

  static certificateTypes = {
    AD_CS: 'adcs',
    PKCS_1: 'pkcs1',
    PKCS_12: 'pkcs12',
  };

  static sanTypes = {
    NONE: null,
    DNS: 'dNSName',
    RFC_822: 'rfc822Name',
    URI: 'uniformResourceIdentifier',
    UPN: 'userPrincipalName',
  };

  static subjectAlternativeNameTypesWindows = {
    NONE: null,
    [this.sanTypes.RFC_822]: 'email',
    [this.sanTypes.DNS]: 'dns',
    [this.sanTypes.URI]: 'uri',
    [this.sanTypes.UPN]: 'upn',
  };

  static keySizes = {
    S_1024: 1024,
    S_2048: 2048,
    S_4096: 4096,
  };
}

export const certificateService = new CertificateService(
  libraryTypes.profiles,
  '/',
  false,
);

export default CertificateService;
