import {
  blueprintColors,
  blueprintIconClasses,
  colors,
} from 'app/common/constants';
import { prettyScroll } from 'app/common/style-utils';
import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import AwesomeIconCircle from './AwesomeIconCircle';

const IconChooserWrapper = styled.section`
  display: flex;
  flex-direction: row;
  margin: 90px 30px 80px 30px;
`;

const IconWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 50px;
`;

const RevertButton = styled.section`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  cursor: pointer;
  color: ${colors['grey-500']};
  font-size: 11px;
  font-weight: 700;
  font-family: var(--font-family-primary);
  text-transform: uppercase;
  letter-spacing: 0.2em;
  > i {
    margin-right: 5px;
  }
`;

const IconGallery = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: auto;
  margin-right: 20px;
  ${prettyScroll({ scrollWidth: 6, scrollColor: 'grey-200' })}
`;

const IconItemWrapper = styled.div`
  width: 23px;
  height: 23px;
  margin: 0 15px 20px 15px;
`;

const ActiveIconItemWrapper = styled.div`
  margin: -12px 0 0 -15px;
`;

const IconItem = styled.i`
  font-size: 20px;
  color: ${colors['grey-430']};
  transition: 0.3s ease;
  &:hover {
    color: #000;
  }
  &.active {
    color: #000;
  }
`;

const ColorGallery = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
`;

const ColorItem = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: ${(props) => colors[props.color]};
  transition: 0.3s ease;
  &:hover {
    transform: scale(1.4, 1.4);
  }
  &.active {
    transform: scale(1.5, 1.5);
    box-shadow:
      0 0 0 2px #fff,
      0 0 0 3px ${colors['grey-300']};
  }
`;

export const AwesomeIconEditor = ({
  color,
  onColorChange,
  icon,
  onIconChange,
  onRevertClick,
}) => (
  <IconChooserWrapper>
    <ColorGallery>
      {blueprintColors.map((colorItem) => (
        <ColorItem
          key={camelCase(colorItem)}
          className={color === colorItem ? 'active' : null}
          color={colorItem}
          onClick={() => onColorChange(colorItem)}
        />
      ))}
    </ColorGallery>

    <IconWrapper>
      <AwesomeIconCircle color={color} icon={`ss-icon ${icon}`} size="241px" />
      <RevertButton onClick={onRevertClick}>
        <i className="ss-icon ss-refresh" />
        <span>Revert To Original</span>
      </RevertButton>
    </IconWrapper>

    <IconGallery>
      {blueprintIconClasses.map((iconClassItem) => (
        <IconItemWrapper
          key={camelCase(iconClassItem)}
          active={icon === iconClassItem}
        >
          {icon === iconClassItem && (
            <ActiveIconItemWrapper>
              <AwesomeIconCircle
                color={color}
                icon={`ss-icon ${iconClassItem}`}
                size="48px"
                border="5px"
              />
            </ActiveIconItemWrapper>
          )}
          {icon !== iconClassItem && (
            <IconItem
              className={`ss-icon ${iconClassItem}`}
              onClick={() => onIconChange(iconClassItem)}
            />
          )}
        </IconItemWrapper>
      ))}
    </IconGallery>
  </IconChooserWrapper>
);

AwesomeIconEditor.propTypes = {
  color: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onColorChange: PropTypes.func.isRequired,
  onIconChange: PropTypes.func.isRequired,
  onRevertClick: PropTypes.func.isRequired,
};
