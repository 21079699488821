/* istanbul ignore file - used and tested in use-library-item.js */
import { useCallback, useEffect, useState } from 'react';

const noop = (v: any) => v;

const usePageState = (opt = {}) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'isAdding' does not exist on type '{}'.
  const { isAdding } = opt;
  const [pageState, setPageState] = useState({
    isEditing: false,
    isDeleteModal: false,
    isDeleting: false,
    isSaving: false,
    isDisabled: false,
    isValid: true,
    isSubmitted: false,
    isAdding,
  });

  useEffect(() => {
    if (isAdding) {
      setPageState((p) => ({
        ...p,
        isDisabled: false,
        isEditing: true,
      }));
    }
  }, [isAdding]);

  useEffect(() => {
    if (pageState.isDeleting) {
      setPageState((p) => ({ ...p, isDisabled: true }));
    }
  }, [pageState.isDeleting]);

  useEffect(() => {
    setPageState((p) => ({ ...p, isDisabled: !pageState.isEditing }));
  }, [pageState.isEditing]);

  const onEdit = useCallback(
    (cb) => () => {
      setPageState((p) => ({ ...p, isEditing: true }));
      return cb();
    },
    [],
  );

  const onSave = (cb: any) => () => {
    setPageState((p) => ({
      ...p,
      isSaving: true,
      isDisabled: true,
      isSubmitted: true,
    }));
    return cb()
      .then(noop)
      .catch((e: any) => {
        console.log(e);
      })
      .then((res: any) => {
        if (res === onSave.stop) {
          setPageState((p) => ({ ...p, isSaving: false, isDisabled: false }));
          return;
        }
        setPageState((p) => ({
          ...p,
          isSaving: false,
          isEditing: false,
          isAdding: false,
          isSubmitted: false,
        }));
      });
  };

  onSave.stop = Symbol('stop onSave');

  const onDelete = useCallback(
    (cb) => () => {
      setPageState((p) => ({ ...p, isDeleteModal: true }));
      return cb();
    },
    [],
  );

  const onCancel = useCallback(
    (cb) => () => {
      setPageState((p) => ({ ...p, isEditing: false, isSubmitted: false }));
      return cb();
    },
    [],
  );

  const onClose = useCallback((cb) => () => cb(), []);

  const onRemove = useCallback(
    (cb) => () => {
      setPageState((p) => ({ ...p, isDeleting: true }));
      return cb()
        .then(noop)
        .catch(noop)
        .then(() => {
          setPageState((p) => ({ ...p, isDeleting: false, isEditing: false }));
          setTimeout(() => {
            setPageState((p) => ({ ...p, isDeleteModal: false }));
          }, 0);
        });
    },
    [],
  );

  const onCloseModal = useCallback(
    (cb) => () => {
      setPageState((p) => ({ ...p, isDeleteModal: false }));
      return cb();
    },
    [],
  );

  const onValidate = useCallback(
    (cb) => (isValid: any) => {
      setPageState((p) => ({ ...p, isValid }));
      return cb(isValid);
    },
    [],
  );

  const handlers = {
    onEdit,
    onSave,
    onDelete,
    onCancel,
    onClose,
    onRemove,
    onCloseModal,
    onValidate,
  };

  return {
    pageState,
    setPageState,
    handlers,
  };
};

export default usePageState;
