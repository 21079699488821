/* istanbul ignore file */
import useAccount from 'contexts/account';
import featureFlags from 'src/config/feature-flags';
import { INT_TYPES, INT_TYPE_CATEGORY_MAPPING } from '../constants';

const useFeatures = () => {
  const { currentCompany } = useAccount();

  const isScimEnabled = currentCompany?.feature_configuration?.scim?.enabled;
  const isServicenowEnabled = featureFlags.getFlag('paint-servicenow');
  const isMsDeviceComplianceEnabled = featureFlags.getFlag(
    'paint-microsoft-device-compliance',
  );

  // Control feature visibility
  const isFeatureEnabled = {
    [INT_TYPES.scim]: isScimEnabled,
    [INT_TYPES.servicenow]: isServicenowEnabled,
    [INT_TYPES.msDeviceCompliance]: isMsDeviceComplianceEnabled,
  };

  const getEnabledFeatures = () =>
    Object.keys(INT_TYPE_CATEGORY_MAPPING)
      .sort()
      .reduce((a, category) => {
        const obj = {};
        obj[category] = INT_TYPE_CATEGORY_MAPPING[category].filter((type) =>
          isFeatureEnabled[type] === undefined
            ? true
            : !!isFeatureEnabled[type],
        );
        return { ...a, ...obj };
      }, {});

  const mapCategoryType = getEnabledFeatures();

  return {
    mapCategoryType,
    isServicenowEnabled,
  };
};

export default useFeatures;
