import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { i18n } from 'src/i18n';

const SupportLink = styled('a')`
  color: #0085ff;
`;

const KandjiSupportLink = ({ href, text, className }) => (
  <SupportLink
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className={className}
  >
    {text || i18n.t(`Learn More`)}
  </SupportLink>
);

KandjiSupportLink.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string,
  className: PropTypes.string,
};

export default KandjiSupportLink;
