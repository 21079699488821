class HubspotService {
  static modifiers = {
    moveUp: 'hubspot-handler',
    overlay: 'hubspot-handler--overlaid',
    hide: 'hubspot-hide',
  };

  static applyModifiers(...modifiers) {
    document.body.classList.add(...modifiers);
  }

  static discardModifiers(...modifiers) {
    document.body.classList.remove(...modifiers);
  }
}

export default HubspotService;
