/* istanbul ignore file */
import { i18n } from 'i18n';
import React from 'react';
import styled from 'styled-components';
import UniversalAlert from './UniversalAlert';

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = () => (
  <TextWrapper>
    <div>
      {i18n.t('Apple Business Manager terms and conditions have been updated.')}
    </div>
    <div>
      {i18n.t(
        `Automated Device Enrollment functionality will be limited until new terms
      are accepted.`,
      )}
    </div>
  </TextWrapper>
);

const ABMExpiredBanner = () => (
  <UniversalAlert
    type="warning"
    text={<Text />}
    button={{
      text: i18n.t('Review'),
      onClick: () => window.open('https://business.apple.com/'),
    }}
    secondButton={{
      text: i18n.t('Dismiss'),
      closeOnClick: true,
    }}
  />
);

export default ABMExpiredBanner;
