import { Flex, Heading, Paragraph } from '@kandji-inc/nectar-ui';
import type React from 'react';
import { ReactSVG } from 'react-svg';
import EmptyStateSVG from './assets/events_empty_content.svg';

type AllowBlockListEmptyStateProps = {
  isVisible: boolean;
  header?: string;
  body?: string;
  button?: React.ReactNode;
};

const AllowBlockListEmptyState = (props: AllowBlockListEmptyStateProps) => {
  const { isVisible, header, body, button } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flow="column"
      gap="xl"
      css={{ paddingBlock: 60 }}
    >
      <Flex
        css={{ maxWidth: '480px', textAlign: 'center' }}
        justifyContent="center"
        alignItems="center"
        flow="column"
      >
        <ReactSVG src={EmptyStateSVG} />
        <Heading size="4" as="h4" css={{ fontWeight: '$medium' }}>
          {header}
        </Heading>
        <Paragraph>{body}</Paragraph>
      </Flex>
      {button}
    </Flex>
  );
};

export default AllowBlockListEmptyState;
