import { Setting } from 'features/library-items/template';
/* istanbul ignore file */
import React, { memo } from 'react';

import { i18n } from 'src/i18n';
import DisplayNameRow from './display-name-row';
import LocationRow from './location-row';
import PpdPathRow from './ppd-path-row';
import PrinterNameRow from './printer-name-row';
import UriRow from './uri-row';

const GeneralCard = (props) => {
  const { setting, update, isDisabled, isSubmitted, onValidate } = props;

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('General')}</h3>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          {i18n.t(
            'Provide the details for the Custom Printer and specify the PPD path below.',
          )}
        </p>
      </Setting.SubHeader>
      <Setting.Rows>
        <DisplayNameRow
          update={update}
          setting={setting}
          isDisabled={isDisabled}
          isSubmitted={isSubmitted}
        />
        <PrinterNameRow
          update={update}
          setting={setting}
          isDisabled={isDisabled}
          isSubmitted={isSubmitted}
        />
        <LocationRow
          update={update}
          setting={setting}
          isDisabled={isDisabled}
          isSubmitted={isSubmitted}
        />
        <UriRow
          update={update}
          setting={setting}
          isDisabled={isDisabled}
          isSubmitted={isSubmitted}
        />
        <PpdPathRow
          update={update}
          setting={setting}
          isDisabled={isDisabled}
          isSubmitted={isSubmitted}
          onValidate={onValidate}
        />
      </Setting.Rows>
    </Setting.Card>
  );
};

export default memo(GeneralCard);
