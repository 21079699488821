import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';
import {
  extractFilterableColumns,
  getFilteredDisabled as filterDisabled,
  getCodeSnippetCell,
} from '../column-utils';
import { getGlobalColumns } from './globalColumns';

export const getKernelExtensionsColumns = (schemas) => {
  const globalSchema = schemas.find((schema) => schema.uri === 'globals');
  const kernelExtensionsSchema = schemas.find(
    (schema) => schema.uri === 'kernel_extensions',
  );

  if (!kernelExtensionsSchema || !globalSchema) {
    return { columnDefs: [], filterableColumns: [] };
  }

  const combinedProperties = {
    ...globalSchema.schema.properties,
    ...kernelExtensionsSchema.schema.properties,
  };

  const kernelExtensionsColumnHelper =
    createColumnHelper<z.infer<typeof combinedProperties>>();

  const globalColumns = getGlobalColumns(
    kernelExtensionsColumnHelper,
    combinedProperties,
  );

  const columnDefs = [
    ...globalColumns.startColumnDefs,

    kernelExtensionsColumnHelper.accessor((row) => row.memory_size, {
      id: 'memory_size',
      meta: {
        displayName: combinedProperties.memory_size.title,
        filterType: 'number',
        filterIcon: 'hashtag',
        filterDisabled,
        defaultHidden: true,
      },
    }),

    kernelExtensionsColumnHelper.accessor((row) => row.name, {
      id: 'name',
      meta: {
        displayName: combinedProperties.name.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    kernelExtensionsColumnHelper.accessor((row) => row.version, {
      id: 'version',
      meta: {
        displayName: combinedProperties.version.title,
        filterType: 'version',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    kernelExtensionsColumnHelper.accessor((row) => row.path, {
      id: 'path',
      cell: getCodeSnippetCell,
      meta: {
        displayName: combinedProperties.path.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    kernelExtensionsColumnHelper.accessor((row) => row.identifier, {
      id: 'identifier',
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.identifier.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    kernelExtensionsColumnHelper.accessor((row) => row.references, {
      id: 'references',
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.references.title,
        filterType: 'number',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    kernelExtensionsColumnHelper.accessor((row) => row.linked_against, {
      id: 'linked_against',
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.linked_against.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    ...globalColumns.endColumnDefs,
  ];

  const filterableColumns = extractFilterableColumns(columnDefs);

  return { columnDefs, filterableColumns };
};
