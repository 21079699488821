import { updateSetting as update } from '@kandji-inc/bumblebee';
import React, { useCallback, useEffect } from 'react';

import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import NewStatusTab from '../../common/new-status-tab';
import { getNewStatusTabProps } from '../../common/new-status-tab/util';
import { populateSelectedDevices } from '../../data-service/library-item/devices';
import config from '../../library/library-item-configurations/items/profile';
import ActivityTab from '../custom-profile/activity-tab';
import initialState from './initial-state';
import GeneralSection from './sections/general/general';
import ManagedDomains from './sections/managed-domains/managed-domains';
import { managedDataFlowService } from './service/managed-data-flow-service';
import { transformFromApi, transformToApi } from './service/transformers';
import useManagedDataFlowService from './service/use-managed-data-flow-service';

const ManagedDataFlowPage = (props) => {
  const itemConfig = config['Managed Data Flow'];

  const { model, setModel, pageState } = props;
  const [validationDep, triggerValidation] = useUniqValue();
  const updateGeneral = useCallback(update('general', setModel), []);
  const updateDomains = useCallback(update('managedDomains', setModel), []);

  useEffect(() => populateSelectedDevices(model, setModel, itemConfig), []);

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      defaultIcon={itemConfig.icon}
      crumb={model.name || itemConfig.getName()}
      service={managedDataFlowService}
      triggerValidation={triggerValidation}
      summaryInfoProps={{
        name: itemConfig.getName(),
        description: itemConfig.getDescription(),
        publisher: itemConfig.getPublisher(),
        devices: itemConfig.devices,
        requirements: itemConfig.getOsRequirements(),
      }}
      transformFromApi={transformFromApi}
      transformToApi={transformToApi}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      isIconEditable
      supportsRules
      supportsInstallOn
      supportsDuplication
    >
      <GeneralSection
        setting={model.general}
        update={updateGeneral}
        isDisabled={pageState.isDisabled}
        validationDep={validationDep}
      />
      <ManagedDomains
        setting={model.managedDomains}
        update={updateDomains}
        isDisabled={pageState.isDisabled}
        isSaving={pageState.isSaving}
        validationDep={validationDep}
      />
    </LibraryItemPage>
  );
};

const ManagedDataFlow = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState,
    useService: useManagedDataFlowService,
  });
  const blueprintConflicts = useBlueprintConflicts();

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <ManagedDataFlowPage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
    />
  );
};

export default ManagedDataFlow;
