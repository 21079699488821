/* istanbul ignore file - legacy code, moved */
import { get } from 'lodash';
import React, { useContext } from 'react';
import { IPADataContext } from './IPADataContext';

import Banner from 'theme/components/atoms/Banner';
import Link from 'theme/components/atoms/Link';

export const IPABannerWarning = () => {
  const { data } = useContext(IPADataContext);

  const appWarning = get(data, 'data.warning', null);
  const requiresRosetta = get(data, 'data.requires_rosetta', false);

  const rosettaText = (
    <p>
      Mac Computers with Apple Silicon require Rosetta to run this app, Rosetta
      will be installed if it is missing.{' '}
      <Link href="https://support.kandji.io/support/solutions/articles/72000560504" />
    </p>
  );

  return (
    <>
      {appWarning && <Banner text={appWarning} />}
      {requiresRosetta && <Banner text={rosettaText} />}
    </>
  );
};
