import type { QueryParamConfig } from 'use-query-params';
import {
  decodeDelimitedArrayEnum,
  encodeDelimitedArray,
  withDefault,
} from 'use-query-params';

// istanbul ignore next
function createMultiEnumParamConfig<T>(
  enumValues: T[],
): QueryParamConfig<T[] | undefined> {
  return {
    encode: (array: T[] | null | undefined) => encodeDelimitedArray(array, ','),
    decode: (arrayStr: string | (string | null)[] | null | undefined) =>
      decodeDelimitedArrayEnum(arrayStr, enumValues, ',') || undefined,
  };
}

function createMultiEnumParam<T>(
  enumValues: T[],
  defaultStatus: T[] | undefined,
) {
  const StatusParamConfig = createMultiEnumParamConfig(enumValues);
  return withDefault(StatusParamConfig, defaultStatus);
}

export default createMultiEnumParam;
