/* istanbul ignore file */
import { Button, ButtonGroup, setClass } from '@kandji-inc/bumblebee';
import { Setting } from 'features/library-items/template';
import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';

import featureFlags from 'src/config/feature-flags';
import { i18n } from 'src/i18n';
import KandjiLoginService from '../../service/kandji-login-service';

import bgLight from '../common/assets/bg-default-monterey.jpg';
import bgLightSonoma from '../common/assets/bg-macos-sonoma-light.jpg';
import help from '../common/assets/help2.svg';
import kandjiLogo from '../common/assets/kandji-bee-logo-transparent.png';
import footerLogo from '../common/assets/kandji-footer-logo.svg';
import laptop from '../common/assets/laptop.png';
import power from '../common/assets/power.svg';
import restart from '../common/assets/restart.svg';
import sleep from '../common/assets/sleep.svg';
// import bgSonomaHorizon from '../../assets/sonoma-horizon-time.png';
// import sonomaTimeClock from '../../assets/sonoma-time-clock.png';
import bgSonomaHorizon from '../common/assets/sonoma-no-time.png';
import sonomaTimeClock from '../common/assets/sonoma-time-clock.png';
import support from '../common/assets/support.png';
import Header from '../common/header';
import ToggleButton from '../common/toggle-button';
import Drawer from './drawer';

const helpTabs = {
  SUPPORT: 'support',
  INFO: 'info',
  ABOUT: 'about',
};

const toggleButtons = [helpTabs.SUPPORT, helpTabs.INFO, helpTabs.ABOUT];

const thisYear = new Date().getFullYear();

const HelpScreen = (props) => {
  const {
    setting,
    update,
    authSetting,
    beforeEditModel,
    isDisabled,
    loginInfo,
    isNewOsTab,
  } = props;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [helpTab, setHelpTab] = useState(helpTabs.SUPPORT);

  const LDFFpassportRefresh = featureFlags.getFlag(
    'DC-998-passport-ui-refresh',
  );
  const isWebLoginModeSelected =
    authSetting.idp &&
    authSetting.mode === KandjiLoginService.authenticationMode.WEB_LOGIN;

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t(`Help window`)}</h3>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          {i18n.t(`Customize the header and body text on the Help window and specify the
          device information to be displayed. Users can access this screen by
          clicking the Help button (?) in the bottom-left corner of the login
          window.`)}
        </p>
      </Setting.SubHeader>
      <div className="k-klogin-preview">
        <div className="b-flex-vc b-flex-btw">
          <div className="b-flex-g b-flex-vc">
            <Button disabled={isDisabled} onClick={() => setIsDrawerOpen(true)}>
              {i18n.t(`Customize`)}
            </Button>
            <Button
              onClick={() => setIsExpanded((p) => !p)}
              icon={isExpanded ? 'angle-up' : 'angle-down'}
              kind="link"
              size="small"
            >
              {isExpanded
                ? i18n.t('Collapse preview')
                : i18n.t('Expand preview')}
            </Button>
          </div>
          <div className="k-ks-toggle">
            <ButtonGroup
              initialSelected={helpTab === helpTabs.SUPPORT ? 0 : 1}
              activeWatch={toggleButtons.indexOf(helpTab)}
              onToggle={(index) => setHelpTab(toggleButtons[index])}
            >
              <ToggleButton
                className="b-txt k-ks-dark-blue"
                onClick={() => setHelpTab(helpTabs.SUPPORT)}
              >
                {i18n.t(`Support`)}
              </ToggleButton>
              <ToggleButton
                className="b-txt k-ks-dark-blue"
                onClick={() => setHelpTab(helpTabs.INFO)}
              >
                {i18n.t(`Device Info`)}
              </ToggleButton>
              <ToggleButton
                className="b-txt k-ks-dark-blue"
                onClick={() => setHelpTab(helpTabs.ABOUT)}
              >
                {i18n.t(`About`)}
              </ToggleButton>
            </ButtonGroup>
          </div>
        </div>
        {isExpanded && (
          <div className="k-klogin-preview-row k-klogin-help-preview">
            <div
              className="k-klogin-preview-image"
              style={{
                backgroundImage: `url(${
                  (loginInfo.isDesktopBg &&
                    (loginInfo.desktopBg?.dataUrl || loginInfo.desktopBgUrl)) ||
                  (LDFFpassportRefresh && isNewOsTab && bgSonomaHorizon) ||
                  bgLightSonoma ||
                  bgLight
                })`,
              }}
              alt="Preview"
            />
            {loginInfo.isDesktopBg &&
              (loginInfo.desktopBg?.dataUrl || loginInfo.desktopBgUrl) && (
                <div
                  style={{
                    position: 'absolute',
                    left: '0',
                    right: '0',
                    bottom: '0',
                    top: '0',
                    background: 'rgba(0,0,0,0.2)',
                  }}
                />
              )}
            <div className="k-klogin-datetime">
              <div className="k-klogin-date">
                {i18n.t('Monday, September 23')}
              </div>
              <div className="k-klogin-time">8:39</div>
            </div>
            <div className="k-klogin-login-preview-inner">
              <Header
                setting={loginInfo}
                isNewOs={isNewOsTab}
                // isWebLoginModeSelected={isWebLoginModeSelected}
              />
              <div className="k-klog-device-info">
                <div className="k-klog-device-header">
                  <div className="k-klog-device-btn --close" />
                  <div className="k-klog-device-btn" />
                  <div className="k-klog-device-btn" />

                  <div className="k-klog-device-tabs">
                    <div
                      className={setClass(
                        'k-klog-device-tab',
                        helpTab === helpTabs.SUPPORT && '--active',
                        helpTab === helpTabs.ABOUT && '--with-border',
                      )}
                      onClick={() => setHelpTab(helpTabs.SUPPORT)}
                    >
                      {i18n.t(`Support`)}
                    </div>
                    <div
                      className={setClass(
                        'k-klog-device-tab',
                        helpTab === helpTabs.INFO && '--active',
                        helpTab === helpTabs.SUPPORT && '--with-border',
                      )}
                      onClick={() => setHelpTab(helpTabs.INFO)}
                    >
                      {i18n.t(`Device info`)}
                    </div>
                    <div
                      className={setClass(
                        'k-klog-device-tab',
                        helpTab === helpTabs.ABOUT && '--active',
                      )}
                      onClick={() => setHelpTab(helpTabs.ABOUT)}
                    >
                      {i18n.t(`About`)}
                    </div>
                  </div>
                </div>
                <div className="k-klog-device-separator" />
                <div className="k-klog-device-inner">
                  <div
                    className={setClass(
                      'k-klog-device-inner-support',
                      helpTab !== helpTabs.SUPPORT && '--hidden',
                    )}
                  >
                    <img
                      className="k-klog-device-icon"
                      src={
                        (loginInfo.isLogo &&
                          (loginInfo.logoUrl || loginInfo.logo?.dataUrl)) ||
                        support
                      }
                      alt={i18n.t('Support')}
                    />
                    <div className="k-klog-device-inner-support__main">
                      <div className="k-klog-device-title">
                        <h2>{setting.header}</h2>
                      </div>
                      <div className="k-klog-device-helper">
                        <p className="k-library--kandji-login__help-window-subheader">
                          {setting.subheader}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={setClass(
                      'k-klog-device-inner-info',
                      helpTab !== helpTabs.INFO && '--hidden',
                    )}
                  >
                    <img
                      className="k-klog-device-icon"
                      src={laptop}
                      alt={i18n.t('Laptop')}
                    />
                    <div>
                      <div className="k-klog-device-title">
                        <h2> {i18n.t(`MacBook Pro user`)} </h2>
                      </div>
                      <div className="k-klog-device-info-grid-wrapper">
                        <div
                          hidden={!setting.isSerialNumber}
                          className="k-klog-device-info-grid"
                        >
                          <p>{i18n.t(`Serial Number:`)}</p>
                          <p>OX BA34567WQ239H1</p>
                        </div>
                        <div
                          hidden={!setting.isIpAddress}
                          className="k-klog-device-info-grid"
                        >
                          <p>{i18n.t(`IP Address:`)}</p>
                          <p>24.156.99.202</p>
                        </div>
                        <div
                          hidden={!setting.isHostName}
                          className="k-klog-device-info-grid"
                        >
                          <p>{i18n.t(`Hostname:`)}</p>
                          <p>{i18n.t(`0000-Name-Inc.local`)}</p>
                        </div>
                        <div
                          hidden={!setting.isMacVersion}
                          className="k-klog-device-info-grid"
                        >
                          <p>{i18n.t(`Version:`)}</p>
                          <p>{i18n.t(`Version`)} 11.0.1 (20B29)</p>
                        </div>
                        <div
                          hidden={!setting.isModelInfo}
                          className="k-klog-device-info-grid"
                        >
                          <p>{i18n.t(`Model:`)}</p>
                          <p>{i18n.t(`MacBook Pro (16-inch, 2019)`)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={setClass(
                      'k-klog-device-inner-about',
                      helpTab !== helpTabs.ABOUT && '--hidden',
                    )}
                  >
                    <img
                      className="k-klog-device-icon"
                      src={kandjiLogo}
                      alt={i18n.t('Logo')}
                    />
                    <div className="k-klog-device-about__info">
                      <div className="k-klog-device-about__title">
                        {i18n.t(`Passport`)}
                      </div>
                      <div className="k-klog-device-about__description">
                        <p>{i18n.t(`Version 1.0 (1000)`)}</p>
                        <p>
                          {i18n.t(
                            `© 2018-{thisYear} Kandji, Inc. All rights reserved.`,
                            { thisYear },
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="k-klog-pmsg"
                style={isNewOsTab ? { bottom: '175px' } : {}}
              >
                {loginInfo.lockMessageOption === 'custom' && (
                  <p>{loginInfo.customLockMessage}</p>
                )}
              </div>
              <div className="k-klog-pfooter">
                <div className="k-klog-footer-button --help">
                  <ReactSVG className="k-klog-footer-svg" src={help} />
                  <p>{i18n.t(`Help`)}</p>
                </div>
                {!isNewOsTab && (
                  <div className="k-klog-power-buttons">
                    <div
                      hidden={!loginInfo.isShutdownButton}
                      className="k-klog-footer-button --power"
                    >
                      <ReactSVG className="k-klog-footer-svg" src={power} />
                      <p>{i18n.t(`Shut Down`)}</p>
                    </div>
                    <div
                      hidden={!loginInfo.isRestartButton}
                      className="k-klog-footer-button --restart"
                    >
                      <ReactSVG className="k-klog-footer-svg" src={restart} />
                      <p>{i18n.t(`Restart`)}</p>
                    </div>
                    <div
                      hidden={!loginInfo.isSleepButton}
                      className="k-klog-footer-button --sleep"
                    >
                      <ReactSVG className="k-klog-footer-svg" src={sleep} />
                      <p>{i18n.t(`Sleep`)}</p>
                    </div>
                  </div>
                )}
                <div className="k-klog-footer-logo">
                  <img
                    draggable="false"
                    src={footerLogo}
                    alt={i18n.t('logo')}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Drawer
        setting={setting}
        update={update}
        isVisible={isDrawerOpen}
        setIsVisible={setIsDrawerOpen}
        onClose={() =>
          Object.keys(beforeEditModel).forEach((key) =>
            update(key, beforeEditModel[key]),
          )
        }
      />
    </Setting.Card>
  );
};
export default HelpScreen;
