import { ui } from './action-types';

export const setModal = (name, info) => ({
  type: ui.SET_MODAL,
  name,
  info,
  isNewModal: info?.isNewModal,
});

export const clearModal = () => ({
  type: ui.CLEAR_MODAL,
});

export const closeModal = () => ({
  type: ui.CLOSE_MODAL,
});

export const setSnackbar = (message) => ({
  type: ui.SET_SNACKBAR,
  message,
});

export const clearSnackbar = () => ({
  type: ui.CLEAR_SNACKBAR,
});

export const setSidebarOpened = (payload) => (dispatch) =>
  dispatch({
    type: ui.SET_SIDEBAR_OPENED,
    payload,
  });

export const setSidebarDocked = (payload) => (dispatch) => {
  localStorage.setItem('sidebarPinned', payload);

  dispatch({
    type: ui.SET_SIDEBAR_DOCKED,
    payload,
  });
};
