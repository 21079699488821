import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../atoms/Icon';

const isString = (s) => typeof s === 'string';
const isFile = (f) => f && f.constructor === File;

const handleSetImageSrc = (imageInput, setSrc) => {
  if (isString(imageInput)) {
    setSrc(imageInput);
  } else if (isFile(imageInput)) {
    const reader = new FileReader();
    reader.addEventListener('load', () => setSrc(reader.result));
    reader.readAsDataURL(imageInput);
  }
};

const Avatar = ({
  type,
  title,
  image,
  fallbackImage,
  fallbackToIcon,
  icon,
}) => {
  const [src, setSrc] = React.useState(image);
  React.useEffect(() => {
    if (typeof image === 'function') {
      handleSetImageSrc(image(), setSrc);
    } else {
      handleSetImageSrc(image, setSrc);
    }
  }, [image]);

  const onImageLoadingFailure = React.useCallback(() => {
    if (fallbackImage) {
      setSrc(fallbackImage);
    } else if (fallbackToIcon && icon) {
      setSrc('');
    }
  }, [fallbackImage, fallbackToIcon, icon]);

  return (
    <div className={`avatar avatar-${image ? 'image' : 'icon'} avatar-${type}`}>
      {src ? (
        <img
          className="img-fluid"
          src={src}
          alt={title}
          onError={onImageLoadingFailure}
        />
      ) : (
        <Icon icon={icon || 'settings'} />
      )}
    </div>
  );
};

Avatar.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  icon: PropTypes.string,
  fallbackImage: PropTypes.string,
  fallbackToIcon: PropTypes.bool,
};

Avatar.defaultProps = {
  type: 'default',
  title: 'Avatar',
  image: undefined,
  icon: undefined,
  fallbackImage: undefined,
  fallbackToIcon: false,
};

export default React.memo(Avatar);
