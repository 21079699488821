import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import configs from '../../../library/library-item-configurations/items/profile';

const transformFromApiCommonPart = (payload) => ({
  access: {
    type: payload.AccessType,
  },
  security: {
    type: payload.SecurityType,
    password: payload.Password,
    repeatPassword: payload.Password,
    passwordUpdatedAt: payload.PASSWORD_UPDATED_AT,
  },
});

const transformFromNewApi = async (apiData, preset) => {
  const result = {
    ...preset,
    isActive: apiData.active,
    ...transformFromApiCommonPart(apiData.data),
  };

  return result;
};

async function transformFromApi(apiData) {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const commonData = {
    id: apiData.id,
    name: apiData.name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
    rules: apiData.rules,
  };

  const transformer = transformFromNewApi;

  const result = await transformer(apiData, commonData);

  return {
    ...apiData,
    data: result,
  };
}

function transformToApi(model) {
  const toSend = {
    blueprints: model.selectedBlueprints.map(({ value }) => value),
    is_all_blueprints: model.isAllBlueprints,
    name: model.name,
    active: model.isActive,
    rules: model.rules || null,
    data: {
      AccessType: model.access.type,
      SecurityType: model.security.type,
      Password: model.security.password,
      PASSWORD_UPDATED_AT: null,
    },
  };

  if (model.skip_blueprint_conflict) {
    toSend.skip_blueprint_conflict = model.skip_blueprint_conflict;
  }

  if (!model.id) {
    const itemConfig = configs['Airplay Security'];

    toSend.type = itemConfig.type;
    toSend.identifier = itemConfig.identifier;
  }

  return toSend;
}

export { transformToApi, transformFromApi };
