/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query';
import { vulnerabilityApi } from 'src/app/api/base';
import type { Application } from '../vulnerability.types';

const useGetVulnerabilityApplications = (
  vulnerabilityId: string,
  keys: Array<any> = [],
  params: any = {},
) => {
  const { data: apiRes, isLoading } = useQuery({
    queryKey: ['vulnerability-applications', vulnerabilityId, ...keys],
    queryFn: () =>
      vulnerabilityApi(
        `v1/vulnerabilities/${vulnerabilityId}/applications`,
      ).get(params),
  });

  const data = apiRes?.data;
  const applications: Application[] = data?.results || [];
  const count = data?.total;

  return { applications, count, isLoading };
};

export { useGetVulnerabilityApplications };
