import WifiService from 'features/library-items/items/wifi/service/wifi-service';
import { appsTypes } from '../../../../fast-lane-card/fast-lane-card.model';
import initialState from '../../../../initial-state';

const fastlaneKeys = WifiService.keys.fastlane;

const getApps = (appsFromData) => {
  const apps = appsFromData.map((app) => {
    if (!app.isCustom) {
      return { type: appsTypes.VPP, id: app.bundleID };
    }

    return { type: appsTypes.CUSTOM, name: app.name, bundleId: app.bundleID };
  });

  return apps;
};

const fastlane = (data) => {
  if (!data) {
    return initialState.fastLaneMarking;
  }

  const { fastLaneMarking } = data;
  const { isManaged = false, apps = [], settings = {} } = fastLaneMarking || {};
  const { QoSMarkingPolicy = {} } = settings || {};
  const { QoSMarkingEnabled = false, QoSMarkingAppleAudioVideoCalls = false } =
    QoSMarkingPolicy || {};

  return {
    [fastlaneKeys.isEnabled]: isManaged,
    [fastlaneKeys.markMediaApps]: QoSMarkingAppleAudioVideoCalls,
    [fastlaneKeys.qos]: QoSMarkingEnabled
      ? WifiService.qosValues.ALLOW
      : WifiService.qosValues.DISABLE,
    [fastlaneKeys.addedApps]: getApps(apps || []),
  };
};

export default fastlane;
