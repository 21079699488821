/* Note: this is a modified version of what exists in
src/features/library-items/common/self-service-card */

import '../../../common/self-service-card/self-service-card.css';

import { Chip, Toggle } from '@kandji-inc/bumblebee';
import React, { useEffect, useState } from 'react';

import { api } from 'app/api/base';
import { URL_COMPANY_SELF_SERVICE_CONFIG } from 'app/api/urls';
import { withCache } from 'components/common/hof/caching';
import { Setting } from 'features/library-items/template';
import { i18n } from 'src/i18n';

import CategoryRow from './category-row';
import CustomizationRow from './customization-row';

const getSsConfigData = withCache(
  () => api(URL_COMPANY_SELF_SERVICE_CONFIG).get(),
  { resetTimeout: 120000 }, // 2 mins
);

const SelfServiceCard = (props) => {
  const { setting, update, isDisabled, defaults, DrawerContent, isSubmitted } =
    props;
  const [categoryOptions, setCategoryOptions] = useState([]);

  useEffect(() => {
    getSsConfigData.call().then((ssConfigData) =>
      setCategoryOptions(
        ssConfigData.data.categories.map((c) => ({
          value: c.id,
          label: c.name,
        })),
      ),
    );
  }, []);

  useEffect(() => {
    if (!setting.isEnabled || !setting.invalidationsMap) {
      update('invalidationsMap', {});
    }
  }, [setting.isEnabled, isSubmitted]);

  return setting.isEnabled ? (
    <Setting.Card>
      <Setting.Header className="k-ss2-header">
        <h3 className="b-h3">{i18n.t(`Self Service Availability`)}</h3>
        <div className="b-flex">
          <Chip className="b-txt-ctrl8" kind="active" text={i18n.t('Active')} />{' '}
          <Toggle
            disabled
            style={{ marginLeft: 'var(--b-gap-tiny)' }}
            checked
          />
        </div>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          {i18n.t(`Add this Library Item to Self Service by activating the toggle on the
          top right.`)}{' '}
          <a
            href="https://support.kandji.io/support/solutions/folders/72000558148"
            rel="noopener noreferrer"
            target="_blank"
            className="b-alink"
          >
            {i18n.t(`Learn more...`)}
          </a>
        </p>
      </Setting.SubHeader>

      <Setting.Rows>
        <CategoryRow
          setting={setting}
          update={update}
          isDisabled={isDisabled}
          categoryOptions={categoryOptions}
          isSubmitted={isSubmitted}
        />

        <CustomizationRow
          setting={setting}
          update={update}
          defaults={defaults}
          isDisabled={isDisabled}
          DrawerContent={DrawerContent}
        />
      </Setting.Rows>
    </Setting.Card>
  ) : (
    <></>
  );
};

export default SelfServiceCard;
