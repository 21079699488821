import styled from 'styled-components';
import { PrimaryText } from '../text.styles';

const Input = styled('textarea')`
  ${PrimaryText};
  outline: none;
  background: #ffffff;
  border: 2px solid ${(props) => (props.error ? '#EE3434' : '#E5E5E5')};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 12px 15px 10px 15px;

  font-size: 14px;
  line-height: 17px;

  color: #1a1d25;

  margin: 5px 0;
  width: 100%;
  height: 198px;

  &::placeholder {
    opacity: 0.5;
  }
`;

const Textarea = {
  Input,
};

export default Textarea;
