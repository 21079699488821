import { i18n } from 'src/i18n';
import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class AppLockService extends NewLibraryItemService {
  static getAppOptionsMap = () => ({
    assistiveTouch: {
      label: i18n.t('Assistive touch'),
      serverField: 'EnableAssistiveTouch',
      defaultValue: false,
      needsInvert: false,
    },
    autoLock: {
      label: i18n.t('Auto lock'),
      serverField: 'DisableAutoLock',
      defaultValue: true,
      needsInvert: true,
    },
    deviceRotation: {
      label: i18n.t('Device rotation'),
      serverField: 'DisableDeviceRotation',
      defaultValue: true,
      needsInvert: true,
    },
    invertColors: {
      label: i18n.t('Invert colors'),
      serverField: 'EnableInvertColors',
      defaultValue: false,
      needsInvert: false,
    },
    monoAudio: {
      label: i18n.t('Mono audio'),
      serverField: 'EnableMonoAudio',
      defaultValue: false,
      needsInvert: false,
    },
    ringerSwitch: {
      label: i18n.t('Ringer switch'),
      serverField: 'DisableRingerSwitch',
      defaultValue: true,
      needsInvert: true,
    },
    sleepWakeButton: {
      label: i18n.t('Sleep/wake button'),
      serverField: 'DisableSleepWakeButton',
      defaultValue: true,
      needsInvert: true,
    },
    speakSelection: {
      label: i18n.t('Speak selection'),
      serverField: 'EnableSpeakSelection',
      defaultValue: false,
      needsInvert: false,
    },
    touch: {
      label: i18n.t('Touch'),
      serverField: 'DisableTouch',
      defaultValue: true,
      needsInvert: true,
    },
    voiceControl: {
      label: i18n.t('Voice control'),
      serverField: 'EnableVoiceControl',
      defaultValue: false,
      needsInvert: false,
    },
    voiceOver: {
      label: i18n.t('Voice over'),
      serverField: 'EnableVoiceOver',
      defaultValue: false,
      needsInvert: false,
    },
    volumeButtons: {
      label: i18n.t('Volume buttons'),
      serverField: 'DisableVolumeButtons',
      defaultValue: true,
      needsInvert: true,
    },
    zoom: {
      label: i18n.t('Zoom'),
      serverField: 'EnableZoom',
      defaultValue: false,
      needsInvert: false,
    },
  });

  static getUserOptionsMap = () => ({
    assistiveTouch: {
      label: i18n.t('Assistive touch'),
      serverField: 'AssistiveTouch',
    },
    invertColors: {
      label: i18n.t('Invert colors'),
      serverField: 'InvertColors',
    },
    voiceControl: {
      label: i18n.t('Voice control'),
      serverField: 'VoiceControl',
    },
    voiceOver: {
      label: i18n.t('Voice over'),
      serverField: 'VoiceOver',
    },
    zoom: {
      label: i18n.t('Zoom'),
      serverField: 'Zoom',
    },
  });
}

export const appLockService = new AppLockService();

export default AppLockService;
