import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import ReactSelect from 'react-select';

import Icon from './Icon';

const SelectComponentPropTypes = {
  innerRef: PropTypes.object,
  selectProps: PropTypes.shape({
    classNamePrefix: PropTypes.string,
  }).isRequired,
  innerProps: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isFocused: PropTypes.bool.isRequired,
};

const IndicatorSeparator = () => null;
const DropdownIndicator = ({
  innerRef,
  innerProps,
  selectProps,
  isDisabled,
  isFocused,
}) => (
  <svg
    ref={innerRef}
    {...innerProps}
    className={cn(`${selectProps.classNamePrefix}__dropdown-indicator`, {
      [`${selectProps.classNamePrefix}__dropdown-indicator--is-disabled`]:
        isDisabled,
      [`${selectProps.classNamePrefix}__dropdown-indicator--is-focused`]:
        isFocused,
    })}
    width="10"
    height="5"
    viewBox="0 0 10 5"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.47266 0C0.980469 0 0.734375 0.601562 1.08984 0.957031L4.58984 4.45703C4.80859 4.67578 5.16406 4.67578 5.38281 4.45703L8.88281 0.957031C9.23828 0.601562 8.99219 0 8.5 0H1.47266Z" />
  </svg>
);

DropdownIndicator.propTypes = SelectComponentPropTypes;

export const customSelectComponents = { DropdownIndicator, IndicatorSeparator };

// This traditional controlled input with 'value' and 'onChange' props
// Agnostic about react-honey-form or something else
const Select = ({ className, error, ...props }) => (
  <>
    <ReactSelect
      className={cn('kandji-select-container', {
        [className]: className,
        'kandji-select__error': error,
      })}
      classNamePrefix="kandji-select"
      components={customSelectComponents}
      menuPlacement="auto"
      {...props}
    />

    {error && (
      <div className="kandji-select__error-label">
        <Icon icon="exclamation-circle" />
        {error}
      </div>
    )}
  </>
);

const valueType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
});

Select.propTypes = {
  error: PropTypes.string,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(valueType),
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([valueType, PropTypes.arrayOf(valueType)]),
};

Select.defaultProps = {
  error: undefined,
  autoFocus: false,
  className: undefined,
  isDisabled: false,
  isMulti: false,
  isSearchable: false,
  name: undefined,
  options: undefined,
  placeholder: 'Select Option',
  value: undefined,
};

export default memo(Select);
