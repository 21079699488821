import {
  Radio,
  Select,
  TextInput,
  useInputsValidators,
  useIsChanged,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import React, { useEffect } from 'react';

import { Setting } from 'features/library-items/template';
import { i18n } from 'src/i18n';

import WifiService from '../../service/wifi-service';
import { authenticationOptions } from '../protocols-card.model';

const keys = WifiService.keys.authentication.acceptedEapTypes;

const AuthenticationCredentials = ({
  isDisabled,
  setting,
  update,
  validationDep,
}) => {
  const isUseAsLoginWindowConfiguration = setting.useLoginWindow;

  const fieldsToValidate = [keys.username, keys.password];
  const { refs, onInvalidate } = useInputsValidators(fieldsToValidate, update);
  useRemoveValidationOnUnmount(fieldsToValidate, update);

  return (
    <Setting.SecondaryControls className="k-ade-secondary-ctrl-grid-gap-align">
      <Setting.SecondaryControlsRow>
        <div>
          <p className="b-txt">
            {i18n.t(`Username`)}
            {isUseAsLoginWindowConfiguration && (
              <span className="b-txt-light2">{i18n.t(` (optional)`)}</span>
            )}
          </p>
        </div>
        <div>
          <p className="b-txt-light">
            {i18n.t(`The user name for the account. If not provided, the user is prompted
            during login. If not using a Login Window configuration, this field
            is required.`)}
          </p>
        </div>
        <div ref={refs[keys.username]}>
          <TextInput
            disabled={isDisabled}
            value={setting[keys.username]}
            onChange={(e) => update(keys.username, e.target.value)}
            compact
            isOptional={isUseAsLoginWindowConfiguration}
            validator={(v) => [
              {
                message: i18n.t('Required'),
                invalid: () => !isUseAsLoginWindowConfiguration && !v,
                trigger: [
                  'onBlur',
                  'onInput',
                  validationDep,
                  isUseAsLoginWindowConfiguration,
                ],
              },
            ]}
            onInvalidate={onInvalidate(keys.username)}
            placeholder="$EMAIL"
            data-testid="auth-email"
          />
        </div>
      </Setting.SecondaryControlsRow>
      <Setting.SecondaryControlsRow>
        <div>
          <p className="b-txt">
            {i18n.t(`Password`)}
            {isUseAsLoginWindowConfiguration && (
              <span className="b-txt-light2">{i18n.t(` (optional)`)}</span>
            )}
          </p>
        </div>
        <div>
          <p className="b-txt-light">
            {i18n.t(`The user's password. If not provided, the user will be
              prompted when joining the network. If not using a Login Window
              configuration, this field is required.`)}
          </p>
        </div>
        <div ref={refs[keys.password]}>
          <TextInput
            disabled={isDisabled}
            value={setting[keys.password]}
            onChange={(e) => update(keys.password, e.target.value)}
            compact
            isOptional={isUseAsLoginWindowConfiguration}
            validator={(v) => [
              {
                message: i18n.t('Required'),
                invalid: () => !isUseAsLoginWindowConfiguration && !v,
                trigger: [
                  'onBlur',
                  'onInput',
                  validationDep,
                  isUseAsLoginWindowConfiguration,
                ],
              },
            ]}
            onInvalidate={onInvalidate(keys.password)}
            placeholder={i18n.t('Password')}
            type="password"
            data-testid="auth-password"
          />
        </div>
      </Setting.SecondaryControlsRow>
    </Setting.SecondaryControls>
  );
};

const fieldsToValidate = [keys.authentication];

const Authentication = ({
  isDisabled,
  update,
  setting,
  validationDep,
  showAuthenticationCredentials,
}) => {
  const { refs, onInvalidate, invalidations } = useInputsValidators(
    fieldsToValidate,
    update,
  );
  useRemoveValidationOnUnmount(fieldsToValidate, update);
  useEffect(() => {
    // i didnt find where it is being overwritten
    // thats why i have to do this way
    setTimeout(() => {
      onInvalidate(keys.authentication)(
        !setting[keys.authentication] && 'Required',
      );
    }, 0);
  }, [setting[keys.authentication]]);
  const showError = useIsChanged(validationDep);

  return (
    <Setting.SecondaryControlsRow>
      <div className="b-library-second-ctrl-row-title --first">
        <p className="b-txt" ref={refs[keys.authentication]}>
          {i18n.t(`Authentication`)}
        </p>
      </div>
      <div className="b-library-second-ctrl-row-helper">
        <p className="b-txt-light">
          {i18n.t(`The account type used to connect to the network.`)}
        </p>
      </div>
      <div className="b-library-second-ctrl-row-option --last">
        <Select
          placeholder={i18n.t('Select Option')}
          isDisabled={isDisabled}
          options={authenticationOptions()}
          compact
          onChange={(v) => update(keys.authentication, v.value)}
          value={authenticationOptions().find(
            (option) => option.value === setting[keys.authentication],
          )}
          errorText={(showError && invalidations[keys.authentication]) || ''}
          testId="auth-options"
        />
      </div>
      {showAuthenticationCredentials && (
        <AuthenticationCredentials
          isDisabled={isDisabled}
          setting={setting}
          update={update}
          validationDep={validationDep}
        />
      )}
    </Setting.SecondaryControlsRow>
  );
};

export default React.memo(Authentication);
