import {
  Button,
  DialogPrimitives as Dialog,
  Flex,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';

import * as React from 'react';
import { ViewNameInput } from './ViewNameInput';

export type PrismViewRenameModalProps = {
  isOpen: boolean;
  viewName: string;
  viewNameError?: string;
  onClose: () => void;
  onOpenChange: (isOpen: boolean) => void;
  handleViewRename: (newName: string) => void;
  setViewNameError: (viewNameError: string) => void;
};

const PrismViewRenameModal = ({
  isOpen,
  viewName,
  viewNameError,
  onClose,
  onOpenChange,
  handleViewRename,
  setViewNameError,
}: PrismViewRenameModalProps) => {
  const [newName, setNewName] = React.useState('');

  React.useEffect(() => {
    setNewName(viewName);
  }, [viewName]);

  return (
    <Dialog.Root
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          setNewName(viewName);
          setViewNameError('');
        }
        onOpenChange(open);
      }}
    >
      <Dialog.Content
        onClose={onClose}
        onOpenAutoFocus={(e: Event) => e.preventDefault()}
        css={{
          width: '480px',
          height: 'auto',
        }}
      >
        <Dialog.Header>
          <Dialog.Title>{i18n.t('Edit name')}</Dialog.Title>
        </Dialog.Header>
        <Flex pl5 pr5 pt3 pb3 flow="column">
          <ViewNameInput
            viewName={newName}
            viewNameError={viewNameError}
            placeholder={i18n.t('Enter a new name')}
            setViewName={setNewName}
            setViewNameError={setViewNameError}
          />
        </Flex>
        <Dialog.Footer>
          <Button
            compact
            onClick={() => {
              setNewName(viewName);
              setViewNameError('');
              onClose();
            }}
          >
            {i18n.t('Cancel')}
          </Button>
          <Button
            compact
            onClick={() => handleViewRename(newName)}
            variant="primary"
          >
            {i18n.t('Save')}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default PrismViewRenameModal;
