import { useMutation, useQueryClient } from '@tanstack/react-query';
import { pulseService } from 'src/features/visibility/pulse/data-service/pulse-service';

export const usePatchPulseNameAndDescMutation = ({
  pulseJobId,
}: {
  pulseJobId: string;
}) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: pulseService.patchPulseNameAndDescription,
    onSettled: async () =>
      await queryClient.invalidateQueries({
        queryKey: ['pulse', pulseJobId],
      }),
  });
  return mutation;
};
