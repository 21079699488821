/* istanbul ignore file */
import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';
import OldDmg from '../icons/old_dmg.png';
import OldPkg from '../icons/old_pkg.png';
import OldZip from '../icons/old_zip.png';

class CustomAppsService extends NewLibraryItemService {
  static installationTypes = {
    INSTALL_ONCE: 'install_once',
    CONTINUOUSLY_ENFORCE: 'continuously_enforce',
    NO_ENFORCEMENT: 'no_enforcement',
  };

  static installDetailsType = {
    PACKAGE: 'package',
    IMAGE: 'image',
    ZIP: 'zip',
  };

  static defaultIconsMap = {
    [CustomAppsService.installDetailsType.PACKAGE]: OldPkg,
    [CustomAppsService.installDetailsType.ZIP]: OldZip,
    [CustomAppsService.installDetailsType.IMAGE]: OldDmg,
  };
}

export const customAppsService = new CustomAppsService();
export default CustomAppsService;
