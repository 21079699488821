// istanbul ignore file
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { prismService } from '../../prism/data-service/prism-service';
import type { PrismViewSchema } from '../../prism/types/prism.types';

export const useDeleteDeviceView = ({
  onSuccess,
  view,
}: {
  onSuccess: () => void;
  view: PrismViewSchema | undefined;
}) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: () => prismService.deleteDeviceView(view?.id),
    onSuccess: () => {
      queryClient.setQueryData(['prism', 'deviceViews'], (prev: any) => {
        return prev.filter((v) => v.id !== view?.id);
      });
      onSuccess();
    },
  });

  return mutation;
};
