import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import configs from '../../../library/library-item-configurations/items/profile';
import initialState from '../initial-state';

function transformSettings(settings) {
  return {
    ...settings,
    users: {
      idle_time: settings.idle_time ?? initialState().users.idle_time,
      isScreenSaverConfigured: 'idle_time' in settings,

      module_path: settings.module_path || initialState().users.module_path,
      isScreenSaverModuleConfigured: 'module_path' in settings,
    },
    loginWindow: {
      login_window_idle_time:
        settings.login_window_idle_time ??
        initialState().loginWindow.login_window_idle_time,
      isScreenSaverLoginWindowConfigured: 'login_window_idle_time' in settings,

      login_window_module_path:
        settings.login_window_module_path ||
        initialState().loginWindow.login_window_module_path,
      isScreenSaverModuleLoginWindowConfigured:
        'login_window_module_path' in settings,
    },
  };
}

async function transformFromNewApi(apiData, preset) {
  const result = {
    ...preset,
    isActive: apiData.active,
    ...transformSettings(apiData.data),
  };

  return result;
}

async function transformFromApi(apiData) {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const commonData = {
    id: apiData.id,
    name: apiData.name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
    rules: apiData.rules,
  };

  const transformer = transformFromNewApi;

  const result = await transformer(apiData, commonData);

  return {
    ...apiData,
    data: result,
  };
}

function transformToOldApi(model) {
  const result = {
    name: model.name,
    is_active: model.isActive,
    blueprints: model.selectedBlueprints.map((b) => b.value),
    is_all_blueprints: model.isAllBlueprints,
    rules: model.rules || null,
    settings: {
      idle_time: model.users.idle_time,
      module_path: model.users.module_path,

      login_window_idle_time: model.loginWindow.login_window_idle_time,
      login_window_module_path: model.loginWindow.login_window_module_path,
    },
  };

  if (!model.users.isScreenSaverConfigured) {
    delete result.settings.idle_time;
  }
  if (!model.users.isScreenSaverModuleConfigured) {
    delete result.settings.module_path;
  }

  if (!model.loginWindow.isScreenSaverLoginWindowConfigured) {
    delete result.settings.login_window_idle_time;
  }
  if (!model.loginWindow.isScreenSaverModuleLoginWindowConfigured) {
    delete result.settings.login_window_module_path;
  }

  // Add necessary properties when creating new Profile:
  if (!model.id) {
    const itemConfig = configs['Screen Saver'];

    result.type = itemConfig.type;
    result.identifier = itemConfig.identifier;
  }

  return result;
}

function transformToNewApi(model) {
  const toSend = transformToOldApi(model);
  toSend.active = toSend.is_active;
  delete toSend.is_active;
  toSend.data = toSend.settings;
  delete toSend.settings;

  if (model.skip_blueprint_conflict) {
    toSend.skip_blueprint_conflict = model.skip_blueprint_conflict;
  }

  return toSend;
}

function transformToApi(model) {
  const transformer = transformToNewApi;

  return transformer(model);
}

export { transformToApi, transformFromApi };
