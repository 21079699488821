const colors = {
  danger: '#f05b7e',

  'red-500': '#E52D2E',

  'orange-500': '#f16116',
  'orange-400': '#F27029',
  'orange-300': '#F19C16',

  'yellow-500': '#ffcf01',
  'yellow-400': '#FFD229',
  'yellow-300': '#fccd01',

  'green-500': '#71a75b',
  'green-300': '#bfc45b',

  'aqua-800': '#4575B1',
  'aqua-500': '#5ba6a7',

  'purple-500': '#504EA5',
  'purple-400': '#826AB7',

  'grey-999': '#0f0f0f',
  'grey-900': '#212529',
  'grey-700': '#444',
  'grey-600': '#555',
  'grey-500': '#666',
  'grey-450': '#6c757d',
  'grey-430': '#808080',
  'grey-410': '#909196',
  'grey-405': '#92969e',
  'grey-400': '#999',
  'grey-300': '#b2b2b2',
  'grey-280': '#afb2b8',
  'grey-270': '#bfbfbf',
  'grey-250': '#ccc',
  'grey-230': '#d6d6d6',
  'grey-200': '#dedede',
  'grey-150': '#dee0e4',
  'grey-130': '#e0e0e0',
  'grey-110': '#e6e8ed',
  'grey-80': '#f0f2f7',
  'grey-50': '#f6f6f6',
  'grey-20': '#f0f0f0',

  'marengo-700': '#1B1F29',
  'marengo-699': '#1d242f',
  'marengo-680': '#1f242f',
  'marengo-650': '#292f3e',
  'marengo-630': '#3D4557',
  'marengo-600': '#3B4459',
  'marengo-550': '#424f6c',
  'marengo-500': '#42506B',
  'marengo-400': '#4E5C77',
  'marengo-380': '#595e6b',
  'marengo-350': '#606673',
  'marengo-340': '#646b7a',
  'marengo-330': '#5E677E',
  'marengo-320': '#93969c',
  'marengo-310': '#9498a1',
  'marengo-300': '#888E9D',
  'marengo-200': '#A5AAB5',
  'marengo-120': '#c8ccd5',
  'marengo-110': '#E5E7EF',
  'marengo-100': '#caceda',
  'marengo-90': '#C9CDD9',
  'marengo-70': '#d8deeb',
  'marengo-50': '#dcdfe6',
  'marengo-30': '#e6e9f0',

  red500: '#E52D2E',
  red50: '#fff3f6',

  orange500: '#f16116',
  orange400: '#F27029',
  orange300: '#F19C16',

  yellow500: '#ffcf01',
  yellow400: '#FFD229',
  yellow300: '#fccd01',

  green500: '#71a75b',
  green300: '#bfc45b',

  aqua800: '#4575B1',
  aqua500: '#5ba6a7',
  aqua50: '#e3effe',

  purple500: '#504EA5',
  purple400: '#826AB7',

  grey999: '#0f0f0f',
  grey900: '#212529',
  grey700: '#444',
  grey600: '#555',
  grey500: '#666',
  grey450: '#6c757d',
  grey430: '#808080',
  grey410: '#909196',
  grey405: '#92969e',
  grey400: '#999',
  grey300: '#b2b2b2',
  grey280: '#afb2b8',
  grey270: '#bfbfbf',
  grey250: '#ccc',
  grey230: '#d6d6d6',
  grey200: '#dedede',
  grey150: '#dee0e4',
  grey130: '#e0e0e0',
  grey110: '#e6e8ed',
  grey80: '#f0f2f7',
  grey50: '#f6f6f6',
  grey20: '#f0f0f0',

  marengo700: '#1B1F29',
  marengo699: '#1d242f',
  marengo680: '#1f242f',
  marengo650: '#292f3e',
  marengo630: '#3D4557',
  marengo600: '#3B4459',
  marengo550: '#424f6c',
  marengo500: '#42506B',
  marengo400: '#4E5C77',
  marengo380: '#595e6b',
  marengo350: '#606673',
  marengo340: '#646b7a',
  marengo320: '#93969c',
  marengo310: '#9498a1',
  marengo300: '#888E9D',
  marengo200: '#A5AAB5',
  marengo120: '#c8ccd5',
  marengo100: '#caceda',
  marengo70: '#d8deeb',
  marengo50: '#dcdfe6',
  marengo30: '#e6e9f0',

  white: '#fff',
  black: '#000',

  shark500: '#1a1d25',
  wildSand500: '#f4f4f4',

  'grey-300-transparent-300': '#B7B7B750',

  'button-primary-bg': '#1f242f',
  'button-primary-hover-bg': '#585e6c',

  'button-secondary-bg': '#fff',
  'button-secondary-hover-bg': '#ced1d8',
  'button-secondary-border': '#1f242f',

  'button-secondary-new-bg': '#EEF0F6',
  'button-secondary-new-border': '#1f242f',

  'button-tertiary-border': '#ced1d8',
};

export default colors;
