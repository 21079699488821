import { useQuery } from '@tanstack/react-query';
import useAccount from 'src/contexts/account';
import { blueprintService } from 'src/features/library-items/data-service/blueprint/blueprint-service';

export const transformParametersByCategory = (paramRes, { account }) =>
  paramRes.data.categories
    .sort((a, b) => a.weight - b.weight)
    .map((category) => {
      const subcategories = category.subcategories
        .filter(({ name }) => name !== 'No Subcategory')
        .map((subcat) => ({
          ...subcat,
          parameters: paramRes.data.parameters
            .filter(
              (param) =>
                param.category?.id === category.id &&
                param.subcategory?.id === subcat.id,
            )
            .map((param) => {
              if (param.dependency) {
                const dependency = paramRes.data.parameters.find(
                  ({ id }) => param.dependency === id,
                );
                if (dependency) {
                  return { ...param, dependency };
                }
              }
              return { ...param };
            })
            .sort((a, b) => a.weight - b.weight),
        }))
        .sort((a, b) => a.weight - b.weight);

      return {
        ...category,
        subcategories,
      };
    });

export const transformParametersById = (paramRes) =>
  paramRes.data.parameters.reduce((a, c) => ({ ...a, [c.id]: c }), {});

const defaultTransformers = [
  transformParametersByCategory,
  transformParametersById,
];

const useParameters = (transformers = defaultTransformers) => {
  const account = useAccount();

  return useQuery({
    queryKey: ['am-parameters'],
    queryFn: async () => {
      const paramRes = await blueprintService.getParameters();
      const tagsRes = await blueprintService.getTags();

      const parametersData = {
        ...paramRes.data,
      };

      if (transformers.includes(transformParametersByCategory)) {
        parametersData.paramsByCategory = transformParametersByCategory(
          paramRes,
          {
            account,
          },
        );
      }

      if (transformers.includes(transformParametersById)) {
        parametersData.parametersById = transformParametersById(paramRes);
      }

      return {
        parameters: parametersData,
        tags:
          tagsRes.data.results
            .map((tag) => {
              const compatibilityTag = ['10.13', '10.14', '10.15'];
              const isCompatibilityTag = compatibilityTag.includes(tag.name);
              const isNum = !Number.isNaN(parseInt(tag.name, 10));

              return {
                ...tag,
                hidden: isCompatibilityTag ? false : tag.hidden,
                kind: isNum ? 'compatibility' : 'framework',
              };
            })
            .filter(({ hidden }) => !hidden)
            .sort((a, b) => a.name.localeCompare(b.name)) || [],
      };
    },
  });
};

export default useParameters;
