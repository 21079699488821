import { Button, modal as Modal } from '@kandji-inc/bumblebee';
import { Flex, Paragraph } from '@kandji-inc/nectar-ui';
import React from 'react';
import { buildModalContent } from './components/build-modal-content';

type Props = {
  isModalOpen: boolean;
  setIsModalOpen?: (isModalOpen: boolean) => void;
  withoutCloseButton?: boolean;
  disableCloseOnOutsideClick?: boolean;
  hasAppConfiguration: boolean;
  turnOnOktaToggle: () => void;
};

const OktaDeviceTrustModal = (props: Props) => {
  const {
    isModalOpen,
    setIsModalOpen,
    withoutCloseButton = true,
    disableCloseOnOutsideClick = false,
    hasAppConfiguration,
    turnOnOktaToggle,
  } = props;

  const handleClose = () => {
    setIsModalOpen(false);
  };
  const handleSubmit = () => {
    turnOnOktaToggle();
  };

  return (
    <Modal
      isOpen={isModalOpen}
      withoutCloseButton={withoutCloseButton}
      disableCloseOnOutsideClick={disableCloseOnOutsideClick}
      onClose={handleClose}
      parts={{
        header: {
          text: 'Turn on Okta Device Trust?',
        },
        content: {
          children: (
            <Flex gap="lg" flow="column" css={{ width: '560px' }}>
              <Paragraph>
                You have selected to turn on the Okta Device Trust integration.
              </Paragraph>
              {buildModalContent(hasAppConfiguration)}
            </Flex>
          ),
        },
        footer: {
          children: (
            <Flex gap="sm">
              <Button kind="outline" onClick={handleClose}>
                Cancel
              </Button>
              <Button onClick={handleSubmit}>
                Yes, turn on Okta Device Trust
              </Button>
            </Flex>
          ),
        },
      }}
    />
  );
};

export default OktaDeviceTrustModal;
