import React, { useContext } from 'react';

import {
  Box,
  Button,
  DialogPrimitives as Dialog,
  Text,
} from '@kandji-inc/nectar-ui';

import { Context } from './context';

const Header = () => (
  <Dialog.Header>
    <Dialog.Title>There was a problem</Dialog.Title>
  </Dialog.Header>
);

const Content = () => (
  <Box p5>
    <Text>
      There was an issue submitting your request at this time. Please try again
      later.
    </Text>
  </Box>
);

const Footer = () => {
  const { onClose } = useContext(Context);
  return (
    <Dialog.Footer>
      <Button onClick={onClose} variant="primary">
        Close
      </Button>
    </Dialog.Footer>
  );
};

export default {
  header: <Header />,
  content: <Content />,
  footer: <Footer />,
};
