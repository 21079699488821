import React from 'react';

import { string } from 'prop-types';

import { Button } from '@kandji-inc/bumblebee';

import { Rule, useBuilder } from '.';

const Aggregator = (props) => {
  const { aggregator } = props;

  const { facetMap, rule, form, updateForm } = useBuilder();

  const ruleLimit = 20;

  const ruleList = form[aggregator];

  const addRule = () => updateForm(aggregator, [...ruleList, rule]);

  const onClick = () => addRule();

  // Whether or not there are enough Rule rows to select each Input option once
  const allInputOptionsAreUsed =
    ruleList?.length === Object.keys(facetMap).length;

  // Whether or not there is a rule that is missing an Input selection
  const isMissingInputValue = ruleList?.some((row) => !row.input);

  const isDisabled =
    allInputOptionsAreUsed ||
    isMissingInputValue ||
    ruleList?.length >= ruleLimit;

  // Loops through an aggregator's slice of form state while rendering the Rule component.
  // This will display the ruleset's state which may include existing rules.
  const loopRule = ruleList?.length > 0 && (
    <div className="k-rules-builder-aggregator-loop">
      {ruleList?.map(({ key }, index) => (
        <Rule key={key} index={index} aggregator={aggregator} />
      ))}
    </div>
  );

  return (
    <div className="k-rules-builder-aggregator">
      {loopRule}

      <div className="k-rules-builder-aggregator-action">
        <Button
          kind="link"
          icon="circle-plus"
          onClick={onClick}
          isDisabled={isDisabled}
        >
          Add rule
        </Button>
      </div>
    </div>
  );
};

Aggregator.propTypes = {
  aggregator: string.isRequired,
};

export default Aggregator;
