// import 'tippy.js/dist/tippy.css';
import './table.css';

import type {
  CSSProperties,
  MouseEventHandler,
  MutableRefObject,
  PropsWithChildren,
  ReactChildren,
  ReactElement,
  Reducer,
  ReducerState,
  RefObject,
} from 'react';
import React, {
  useState,
  useRef,
  useReducer,
  useMemo,
  useLayoutEffect,
  useEffect,
  forwardRef,
  isValidElement,
  Children,
} from 'react';

import { Loader, setClass } from '@kandji-inc/bumblebee';
import type { SomeReadonlyRecord } from '@shared/types/util.types';

import Row from './components/Row';
import TableNoContent from './components/TableNoContent';
import { Col } from './components/col';
import { TableContext } from './table.context';
import {
  COL_TYPE_MAP,
  getHasWidthOverflow,
  isValidTableColType,
} from './table.helpers';

import type {
  ColProps,
  ColSortDirection,
  ColType,
  ExpandableRowConfig,
  ExpandableRowGrid,
  GridColumnDetails,
  TableMetaData,
} from './table.types';

export type TableProps<DataRecord extends SomeReadonlyRecord> = Readonly<
  PropsWithChildren<{
    data: DataRecord[];
    dataKey: keyof DataRecord;
    title?: string;
    titleComponent?: ReactElement;
    totalCount?: number;
    rowActionsMenu?:
      | ((params: {
          rowData: DataRecord;
          Action: ReactElement;
        }) => ReactElement)
      | undefined;
    expandable?: ExpandableRowConfig<DataRecord>;
    expandableGrid?: ExpandableRowGrid;
    className?: string;
    width?: number | CSSProperties['width'];
    height?: number | string;
    maxWidth?: number | CSSProperties['maxWidth'];
    maxHeight?: number | CSSProperties['maxHeight'];
    isLoading?: boolean;
    emptyContent?: ReactElement;
    emptyContentClearFilters?: MouseEventHandler<HTMLButtonElement>;
    hideColHeaders?: boolean;
  }>
>;

export type TableState<DataRecord extends SomeReadonlyRecord> = Readonly<{
  data: DataRecord[];
  originalData: DataRecord[];
  dataKey: TableProps<DataRecord>['dataKey'];
  title: TableProps<DataRecord>['title'];
  totalCount: TableProps<DataRecord>['totalCount'];
  sortKey: keyof DataRecord | null;
  sortDirection: ColSortDirection;
  rowActionsMenu: TableProps<DataRecord>['rowActionsMenu'] | undefined;
  closePrevOpenActionsMenu: () => void;
  expandable: TableProps<DataRecord>['expandable'];
  isLoading: TableProps<DataRecord>['isLoading'];
  uiState: TableUiState;
}>;

type TableActionType = (typeof TABLE_ACTIONS)[keyof typeof TABLE_ACTIONS];

export type TableAction<DataRecord extends SomeReadonlyRecord> =
  | {
      type: Extract<TableActionType, typeof TABLE_ACTIONS.SET_TABLE_TITLE_DATA>;
      data: {
        originalData: DataRecord[];
        title: TableState<DataRecord>['title'];
        totalCount: TableState<DataRecord>['totalCount'];
      };
    }
  | {
      type: Extract<TableActionType, typeof TABLE_ACTIONS.SET_TABLE_LOADING>;
      data: {
        isLoading: TableState<DataRecord>['isLoading'];
      };
    }
  | {
      type: Extract<
        TableActionType,
        typeof TABLE_ACTIONS.SET_TABLE_CURRENT_SORT_KEY
      >;
      data: {
        sortKey: keyof DataRecord;
      };
    }
  | {
      type: Extract<TableActionType, typeof TABLE_ACTIONS.SET_TABLE_UI_STATE>;
      data: {
        uiState;
      };
    }
  | {
      type: Extract<
        TableActionType,
        typeof TABLE_ACTIONS.SET_CLOSE_PREV_OPEN_ACTIONS_MENU
      >;
      data: {
        closePrevOpenActionsMenu: () => void;
      };
    };

type TableReducer<DataRecord extends SomeReadonlyRecord> = Reducer<
  TableState<DataRecord>,
  TableAction<DataRecord>
>;

type ReactToArrayChildren = ReturnType<ReactChildren['toArray']>;

type TableSectionGroups<DataRecord extends SomeReadonlyRecord> = {
  preColsChildren: ReactToArrayChildren;
  postColsChildren: ReactToArrayChildren;
  frozenCols: ReactElement<ColProps<string, boolean, DataRecord[]>>[];
  cols: ReactElement<ColProps<string, boolean, DataRecord[]>>[];
  __lastColIdx: number;
};

type TableStateInitArg<DataRecord extends SomeReadonlyRecord> = Readonly<{
  colsChildren: ReactElement<ColProps<string, boolean, DataRecord[]>>[];
  initialSortKeyRef: MutableRefObject<keyof DataRecord | null>;
  initialSortDirectionRef: MutableRefObject<ColSortDirection>;
}> &
  Omit<
    TableState<DataRecord>,
    | 'originalData'
    | 'sortKey'
    | 'sortDirection'
    | 'closePrevOpenActionsMenu'
    | 'uiState'
  >;

type TableUiState = {
  isHoverColumnHeaders: boolean;
};

const AUTO_MAX_HEIGHT_THRESHOLD = 0.45;
const EXTRA_OFFSET_LEFT_PX = 24;
const TABLE_ACTIONS = {
  SET_TABLE_TITLE_DATA: 'set_table_title_data',
  SET_TABLE_LOADING: 'set_table_loading',
  SET_TABLE_CURRENT_SORT_KEY: 'set_table_current_sort_key',
  SET_TABLE_UI_STATE: 'set_table_ui_state',
  SET_CLOSE_PREV_OPEN_ACTIONS_MENU: 'set_close_prev_open_actions_menu',
} as const;

export const {
  SET_TABLE_TITLE_DATA,
  SET_TABLE_LOADING,
  SET_TABLE_CURRENT_SORT_KEY,
  SET_TABLE_UI_STATE,
  SET_CLOSE_PREV_OPEN_ACTIONS_MENU,
} = TABLE_ACTIONS;

const tableReducer = <DataRecord extends SomeReadonlyRecord>(
  state: TableState<DataRecord>,
  action: TableAction<DataRecord>,
) => {
  switch (action.type) {
    case SET_TABLE_TITLE_DATA: {
      const { originalData, title, totalCount } = action.data;

      return {
        ...state,
        originalData,
        title,
        totalCount,
      };
    }

    case SET_TABLE_LOADING: {
      const { isLoading } = action.data;

      return {
        ...state,
        isLoading,
      };
    }

    // currently only supports active sort on a single column instead of multi-column sort
    case SET_TABLE_CURRENT_SORT_KEY: {
      const { sortKey } = action.data;
      return { ...state, sortKey };
    }

    case SET_TABLE_UI_STATE: {
      const { uiState: currUiState } = action.data;
      return { ...state, uiState: { ...state.uiState, ...currUiState } };
    }

    case SET_CLOSE_PREV_OPEN_ACTIONS_MENU: {
      const { closePrevOpenActionsMenu } = action.data;
      return { ...state, closePrevOpenActionsMenu };
    }

    default:
      return state;
  }
};

function lazyInitTableState<DataRecord extends SomeReadonlyRecord>({
  colsChildren,
  data,
  dataKey,
  title,
  totalCount,
  initialSortKeyRef,
  initialSortDirectionRef,
  rowActionsMenu,
  expandable,
  isLoading,
}: {
  colsChildren: ReactElement<ColProps<string, boolean, DataRecord[]>>[];
  data: DataRecord[];
  initialSortKeyRef: MutableRefObject<keyof DataRecord | null>;
  initialSortDirectionRef: MutableRefObject<ColSortDirection>;
} & Omit<
  TableState<DataRecord>,
  'originalData' | 'sortKey' | 'sortDirection' | 'closePrevOpenActionsMenu'
>): ReducerState<TableReducer<DataRecord>> {
  Children.forEach(colsChildren, (col) => {
    const hasInitialSort =
      col.props.initialSortDirection &&
      col.props.initialSortDirection !== 'none';

    if (hasInitialSort) {
      initialSortKeyRef.current = col.props.colName;
      initialSortDirectionRef.current = col.props.initialSortDirection;
    }
  });

  return {
    data,
    originalData: data,
    dataKey,
    title,
    totalCount,
    sortKey: initialSortKeyRef.current,
    sortDirection: initialSortDirectionRef.current,
    rowActionsMenu,
    closePrevOpenActionsMenu: () => {},
    expandable,
    isLoading,
    uiState: {
      isHoverColumnHeaders: false,
    },
  };
}

export function Table<DataRecord extends SomeReadonlyRecord>(
  props: TableProps<DataRecord>,
  ref: RefObject<HTMLDivElement>,
) {
  const {
    data,
    dataKey,
    title,
    titleComponent: TitleComponent,
    totalCount,
    rowActionsMenu,
    expandable,
    expandableGrid,
    children,
    className,
    width,
    height,
    maxWidth,
    maxHeight,
    isLoading,
    emptyContent,
    emptyContentClearFilters,
    hideColHeaders = false,
  } = props;

  const tableRef = useRef<HTMLDivElement>(null);
  const tableOuterContainerRef = useRef<HTMLDivElement>(null);
  const tableInnerContainerRef = useRef<HTMLDivElement>(null);
  const initialTableHeight = useRef<number | '100%'>('100%');
  const initialSortKeyRef = useRef<keyof DataRecord | null>(null);
  const initialSortDirectionRef = useRef<ColSortDirection>('none');
  const rowExpandRef = useRef<HTMLDivElement>(null);
  const rowActionsMenuRef = useRef<HTMLDivElement>(null);
  const preColsSectionRef = useRef<HTMLDivElement | null>(null);
  const postColsSectionRef = useRef<HTMLDivElement>(null);
  const gridColumnDetailsRef = useRef<GridColumnDetails>({
    colTracks: '',
    fractionTrackList: [],
    pixelTrackList: [],
  });
  const outerRef = ref || tableOuterContainerRef;

  const tableMetaRef = useRef<TableMetaData>({
    tableOuterContainerRect: null,
    tableInnerContainerRect: null,
    tableRect: null,
    tableHeaderHeight: 0,
    refs: {
      tableRef,
      tableOuterContainerRef: outerRef,
      tableInnerContainerRef,
      rowExpandRef,
      preColsSectionRef,
      postColsSectionRef,
    },
  });

  const [allColRefs, setAllColRefs] = useState<Element[]>([]);
  const [expandedRowNumList, setExpandedRowNumList] = useState<
    Record<string, number>
  >({});
  const [isShowFrozenPane, setIsShowFrozenPane] = useState(false);

  const initialTableSectionGroups: TableSectionGroups<DataRecord> = {
    preColsChildren: [],
    postColsChildren: [],
    frozenCols: [],
    cols: [],
    __lastColIdx: -1,
  };

  const getGroupedTableSections = () => {
    const groupedSections = Children.toArray(children).reduceRight(
      (
        grouped: TableSectionGroups<DataRecord>,
        child: ReactToArrayChildren[number],
        idx,
      ) => {
        const lastColIdx = grouped.__lastColIdx;

        if (
          isValidElement(child) &&
          !isValidTableColType(child.type) &&
          lastColIdx === -1
        ) {
          grouped.__lastColIdx = idx;
          grouped.postColsChildren = [child, ...grouped.postColsChildren];

          return grouped;
        }

        if (
          isValidElement<ColProps<string, boolean, DataRecord[]>>(child) &&
          isValidTableColType(child.type)
        ) {
          const colKey = child.props.isFrozen ? 'frozenCols' : 'cols';
          grouped.__lastColIdx = idx;
          grouped[colKey] = [child, ...grouped[colKey]];
          return grouped;
        }

        grouped.preColsChildren = [child, ...grouped.preColsChildren];

        return grouped;
      },
      initialTableSectionGroups,
    );

    const { preColsChildren, frozenCols, cols, postColsChildren } =
      groupedSections as TableSectionGroups<DataRecord>;

    return {
      preColsChildren,
      colsChildren: [...frozenCols, ...cols],
      postColsChildren,
      numCols: frozenCols.length + cols.length,
      numFrozenCols: frozenCols.length,
    };
  };

  // TODO: memoize this as it's expensive to run on every render
  const {
    preColsChildren,
    colsChildren,
    postColsChildren,
    numCols,
    numFrozenCols,
  } = getGroupedTableSections();

  const initialTableState = {
    colsChildren,
    data,
    dataKey,
    title,
    totalCount,
    initialSortKeyRef,
    initialSortDirectionRef,
    rowActionsMenu,
    expandable,
    isLoading,
  };

  const [tableState, dispatch] = useReducer<
    TableReducer<DataRecord>,
    TableStateInitArg<DataRecord>
  >(tableReducer, initialTableState, lazyInitTableState);

  const hasRowExpand = Boolean(expandable);

  /**
   * Number of expandable actions
   */
  const numFrozenRowActions = [hasRowExpand].filter(Boolean).length;

  /**
   * Combination of explicitly frozen rows and the expand action button
   */
  const totalFrozenCols = numFrozenRowActions + numFrozenCols;

  useLayoutEffect(() => {
    const tableNode = tableRef.current;

    if (!tableNode || !data) {
      return;
    }

    const withColsMultiplier = hideColHeaders ? 1 : 2;
    const numColAndHeaderNodes = numCols * withColsMultiplier;
    const nthNodeOffset = numColAndHeaderNodes + totalFrozenCols;

    const colRefs = Array.from(
      tableNode.querySelectorAll(
        `div[role="gridcell"].__v-table-ns:nth-of-type(-n + ${nthNodeOffset})`,
      ),
    );

    const filterInHtmlElements = (el: Element | null): el is Element =>
      Boolean(el);

    const allRefs = [
      rowExpandRef.current,
      ...colRefs,
      rowActionsMenuRef.current,
    ].filter(filterInHtmlElements);

    const tableHeight = tableNode.offsetHeight ?? '100%';
    initialTableHeight.current = tableHeight;

    tableMetaRef.current.tableRect = tableNode.getBoundingClientRect();
    tableMetaRef.current.tableInnerContainerRect =
      tableInnerContainerRef.current?.getBoundingClientRect();
    tableMetaRef.current.tableOuterContainerRect =
      outerRef.current?.getBoundingClientRect?.();

    setAllColRefs(allRefs);
  }, [!!data && data?.length > 0, totalFrozenCols]);

  const gridAutoColumns = useMemo(() => {
    if (tableRef.current) {
      const tableWidth = tableRef.current.clientWidth;

      const colWidthsTuple = allColRefs.map<[number | string, number | null]>(
        (col) => {
          const manualColWidth = col.getAttribute('data-v-table-col-width');
          const autoWidthFrAttr = col.getAttribute(
            'data-v-table-col-auto-width-fr',
          );
          const autoWidthFr = autoWidthFrAttr === 'true';

          if (manualColWidth && autoWidthFr) {
            const colWidthInt = parseInt(manualColWidth, 10);
            const isPxValue = typeof colWidthInt === 'number';
            const colWidth = isPxValue ? colWidthInt : manualColWidth;
            const autoFraction = isPxValue ? colWidthInt / tableWidth : null;

            return [colWidth, autoFraction];
          }

          if (manualColWidth) {
            return [manualColWidth, null];
          }

          const isRowActionType = col.getAttribute(
            'data-v-table-row-action-type',
          );

          if (isRowActionType) {
            return [`${col.clientWidth + EXTRA_OFFSET_LEFT_PX}px`, null];
          }

          /* Below determines the auto-width sizing of table grid columns for
           * non-row action type columns and any `Col` type element in
           * `colsChildren` that do not have a `width` prop set. */

          const relatedColHeader = tableRef.current?.querySelector(
            `[role="columnheader"][data-v-table-col-num="${col.getAttribute(
              'data-v-table-col-num',
            )}"]`,
          );

          const allColGridcells = tableRef.current?.querySelectorAll(
            `[role="gridcell"][data-v-table-col-num="${col.getAttribute(
              'data-v-table-col-num',
            )}"]`,
          );

          const greatestAutoWidthGridcell = allColGridcells?.length
            ? [...allColGridcells].reduce((a, b) =>
                a.clientWidth > b.clientWidth ? a : b,
              )
            : col;

          const autoWidthCol =
            relatedColHeader &&
            relatedColHeader.clientWidth > greatestAutoWidthGridcell.clientWidth
              ? relatedColHeader
              : greatestAutoWidthGridcell;

          const isFrozen = col.getAttribute('data-v-table-frozen');
          const isColCell = col.getAttribute('role') === 'gridcell';
          if (isFrozen && isColCell) {
            return [
              `${
                autoWidthCol.clientWidth +
                parseInt(getComputedStyle(autoWidthCol).paddingLeft, 10)
              }px`,
              null,
            ];
          }

          const colAutoFraction = autoWidthCol.clientWidth / tableWidth;
          return [autoWidthCol.clientWidth, colAutoFraction];
        },
      );

      const colWidthsString = colWidthsTuple.map(([colWidth, colFraction]) => {
        if (colFraction) {
          return `minmax(${colWidth}px, ${colFraction * 100}fr)`;
        }

        return colWidth;
      });

      const fractionTrackList = colWidthsTuple.map(
        ([colWidth, colFraction]) => {
          if (colFraction) {
            return `${colFraction * 10}fr`;
          }

          if (typeof colWidth === 'number') {
            return `${colWidth}px`;
          }

          return colWidth;
        },
      );

      const pixelTrackList = colWidthsTuple.map(([colWidth]) => {
        if (typeof colWidth === 'number') {
          return `${colWidth}px`;
        }

        return colWidth;
      });

      const colTracks = colWidthsString.join(' ');

      gridColumnDetailsRef.current = {
        colTracks,
        fractionTrackList,
        pixelTrackList,
      };

      return colTracks;
    }

    return undefined;
  }, [allColRefs]);

  const gridTemplateAreas = useMemo(() => {
    const filterInStrings = (str: string | null): str is string => Boolean(str);

    /**
     * Loop over all columns and determine the table container's grid-template-areas
     */
    const headerGridAreas = allColRefs
      .map((colRef, colIdx) => {
        if (
          colIdx === allColRefs.length - 1 &&
          colRef.getAttribute('data-v-table-row-action-type') === 'actions-menu'
        ) {
          return `header-${Math.abs(colIdx - totalFrozenCols)}`;
        }

        const isRowFrozen =
          colRef.getAttribute('data-v-table-frozen') === 'true';

        if (colRef?.getAttribute('role') === 'gridcell') {
          return isRowFrozen
            ? `header-frozen-${colIdx - numFrozenRowActions}`
            : `header-${
                totalFrozenCols > 0
                  ? colIdx - totalFrozenCols + 1
                  : colIdx - totalFrozenCols
              }`;
        }

        return isRowFrozen ? 'header-frozen-0' : 'header-0';
      })
      .join(' ');

    const gridAreas = [headerGridAreas]
      .concat(
        data
          ?.flatMap((_, rowIdx) => [
            allColRefs
              .map((colRef) => {
                if (colRef?.getAttribute('role') === 'gridcell') {
                  const isFrozen =
                    colRef.getAttribute('data-v-table-frozen') === 'true';
                  return isFrozen ? `frozen-${rowIdx + 1}` : '.';
                }

                return `${colRef?.getAttribute(
                  'data-v-table-row-action-type',
                )}-${rowIdx + 1}`;
              })
              .join(' '),
            expandedRowNumList[rowIdx + 1]
              ? `expanded-${rowIdx + 1} `.repeat(allColRefs.length)
              : null,
          ])
          .filter(filterInStrings),
      )
      .map((areaValue) => `"${areaValue}"`)
      .join(' ');

    return gridAreas;
  }, [allColRefs, expandedRowNumList, data?.length]);

  const frozenLeftPosValues = useMemo(() => {
    // account for cell padding so frozen cell remains sticky when scrolling horizontally
    const lefts = allColRefs.slice(0, totalFrozenCols - 1).reduce(
      (trailingWidthSums, col) => {
        const prev = trailingWidthSums[trailingWidthSums.length - 1];
        return trailingWidthSums.concat(
          prev + col.clientWidth + EXTRA_OFFSET_LEFT_PX,
        );
      },
      [0],
    );

    return lefts;
  }, [allColRefs]);

  useEffect(() => {
    dispatch({
      type: SET_TABLE_TITLE_DATA,
      data: {
        originalData: data,
        title,
        totalCount,
      },
    });
  }, [data || totalCount || title]);

  useEffect(() => {
    dispatch({
      type: SET_TABLE_LOADING,
      data: { isLoading },
    });
  }, [isLoading]);

  useEffect(() => {
    const tableNode = tableRef.current;
    const tableScrollObserver = new ResizeObserver(
      ([tableScrollContainerEntry]) => {
        const hasWidthOverflow = getHasWidthOverflow(
          tableScrollContainerEntry.target,
        );

        setIsShowFrozenPane(hasWidthOverflow);
      },
    );

    if (tableNode) {
      tableScrollObserver.observe(tableNode);
    }

    return () => {
      if (tableNode) {
        tableScrollObserver.unobserve(tableNode);
      }
    };
  }, []);

  useEffect(() => {
    setExpandedRowNumList({});
  }, [data]);

  const value = useMemo(() => [tableState, dispatch] as const, [tableState]);

  const colHeaders = hideColHeaders
    ? null
    : colsChildren.map((colChild, colIdx) => {
        const {
          props: {
            colName,
            displayName,
            isFrozen,
            isSortable,
            isInitialSort,
            initialSortDirection,
            sorter,
            onSort,
            headerInfo,
            headerInfoIcon,
            headerComponent,
            headerInfoProps,
          },
        } = colChild;

        const colNum = numFrozenRowActions + colIdx + 1;
        const isLastCol = colNum === numCols + 1;
        const hasNextActionsMenuCol =
          colNum === numCols &&
          isValidTableColType(colsChildren[numCols - 1]?.type);

        const colRef = allColRefs[numFrozenRowActions + colIdx];
        const frozenLeftValue =
          frozenLeftPosValues[numFrozenRowActions + colIdx];
        const thisCol =
          colRef &&
          frozenLeftValue &&
          tableRef.current?.querySelector(
            `[role=gridcell][data-v-table-col-name=${colRef.getAttribute(
              'data-v-table-col-name',
            )}]`,
          );
        const paddingLeft =
          colRef && frozenLeftValue
            ? frozenLeftValue +
              (thisCol
                ? parseInt(getComputedStyle(thisCol).paddingLeft, 10)
                : 0)
            : undefined;

        const colType = isValidTableColType(colChild.type)
          ? (COL_TYPE_MAP.get(colChild.type) as ColType)
          : 'col';

        return (
          <Col
            key={`header-${colName}`}
            role="columnheader"
            colType={colType || 'col'}
            colNum={colNum}
            colName={colName}
            datum={displayName ?? colName}
            isFrozen={isFrozen}
            isSortable={isSortable || false}
            isLastCol={isLastCol}
            sorter={sorter}
            onSort={onSort}
            initialSortDirection={
              isInitialSort
                ? initialSortDirectionRef.current
                : initialSortDirection
            }
            component={headerComponent}
            headerInfo={headerInfo}
            headerInfoIcon={headerInfoIcon}
            headerInfoProps={headerInfoProps}
            className={setClass(
              'b-h4',
              hasNextActionsMenuCol && 'header--no-divider',
            )}
            tableMetaRef={tableMetaRef}
            style={
              isFrozen
                ? {
                    gridArea: `header-frozen-${colIdx}`,
                    left: '0',
                    paddingLeft:
                      typeof paddingLeft === 'number' ? `${paddingLeft}px` : '',
                    '--show-frozen-pane-border-shadow': isShowFrozenPane
                      ? 'var(--shadow-main-border-right)'
                      : 'var(--shadow-border-right)',
                    '--show-frozen-pane-border-height': isShowFrozenPane
                      ? '100%'
                      : 'var(--b-gap2)',
                  }
                : {
                    gridArea: `header-${colIdx}`,
                  }
            }
          />
        );
      });

  const offsetFrozenColLeft =
    3 * (frozenLeftPosValues[numFrozenRowActions] + EXTRA_OFFSET_LEFT_PX) +
    EXTRA_OFFSET_LEFT_PX;
  const isAutoMaxHeightThresholdMet =
    Boolean(allColRefs.length) &&
    !!tableRef.current &&
    (window.innerHeight - tableRef.current?.offsetTop) / window.innerHeight >
      AUTO_MAX_HEIGHT_THRESHOLD;

  return (
    // @ts-expect-error -- we have a more accurate generic type for `value` based
    // on `DataRecord` but it's not compatible with the `TableContext` type
    // because we cannot pass a generic type to a basic React context
    // without wrapping it in a custom provider component
    <TableContext.Provider value={value}>
      <div
        ref={outerRef}
        className={setClass(
          className,
          'b-txt',
          'v-table-outer',
          '__v-table-ns',
        )}
        style={
          {
            width,
            height,
            maxWidth,
            '--loading-opacity': isLoading ? 0.5 : 1,
          } as CSSProperties & { '--loading-opacity': number }
        }
      >
        <div ref={preColsSectionRef}>{preColsChildren}</div>

        <div
          ref={tableInnerContainerRef}
          className="v-table-container __v-table-ns"
        >
          {title && (
            <div className="v-table-title __v-table-ns">
              {TitleComponent || <h3 className="b-h3">{title}</h3>}
            </div>
          )}

          {isLoading && (
            <div
              role="presentation"
              data-testid="v-table-loader"
              className="v-table-loader-container __v-table-ns"
              style={
                {
                  '--centering-offset-top': `${preColsSectionRef.current?.offsetHeight}px`,
                } as CSSProperties & {
                  '--centering-offset-top': string;
                }
              }
            >
              <Loader cover={false} />
            </div>
          )}

          <div
            ref={tableRef}
            className="v-table __v-table-ns"
            role="grid"
            style={
              {
                gridTemplateAreas,
                gridAutoColumns: gridAutoColumns || undefined,
                // if `maxHeight` prop not specified, calculate a reasonable
                // height that table can expand to based on available space in
                // viewport
                maxHeight:
                  maxHeight ||
                  height ||
                  (isAutoMaxHeightThresholdMet
                    ? `calc(100vh - ${
                        tableRef.current?.offsetTop +
                        (postColsSectionRef.current?.offsetHeight ?? 0) +
                        EXTRA_OFFSET_LEFT_PX
                      }px)`
                    : window.innerHeight * 0.65),
                '--display-frozen-pane': isShowFrozenPane ? 1 : 0,
                '--last-frozen-col-num': totalFrozenCols,
                '--offset-frozen-col-left': offsetFrozenColLeft,
                '--current-table-width': tableRef.current?.clientWidth,
                '--post-layout-effect-full-width': allColRefs.length
                  ? '100%'
                  : undefined,
                // use fixed minHeight from first table rendered with data to
                // prevent layout shift when loading, expect when there is no
                // rows to show
                minHeight: isLoading ? initialTableHeight.current : undefined,
              } as CSSProperties & {
                '--display-frozen-pane': 1 | 0;
                '--last-frozen-col-num': number;
                '--offset-frozen-col-left': number;
                '--current-table-width': number;
                '--post-layout-effect-full-width': string | undefined;
              }
            }
          >
            <>
              {totalFrozenCols > 0 && !!data?.length ? (
                <div
                  className="frozen-pane-overlay-container __v-table-ns"
                  role="presentation"
                >
                  <div
                    className="frozen-pane-overlay __v-table-ns"
                    role="presentation"
                  />
                </div>
              ) : null}

              {!!data?.length && colHeaders}

              {data?.length ? (
                data.map((rowData, rowIdx) => (
                  <Row
                    key={`${rowData[dataKey]}-${rowIdx}`}
                    rowData={rowData}
                    rowNum={rowIdx + 1}
                    colsChildren={colsChildren}
                    rowExpandRef={rowExpandRef}
                    rowActionsMenuRef={rowActionsMenuRef}
                    setExpandedRowNumList={setExpandedRowNumList}
                    expandedRowNumList={expandedRowNumList}
                    expandableGrid={expandableGrid}
                    gridColumnDetails={gridColumnDetailsRef.current}
                    frozenLeftPosValues={frozenLeftPosValues}
                    numFrozenRowActions={numFrozenRowActions}
                    isLastRow={data.length === rowIdx + 1}
                    tableRef={tableRef}
                    tableMetaRef={tableMetaRef}
                  />
                ))
              ) : (
                <div className="v-table-no-content">
                  {isLoading || !data
                    ? null
                    : emptyContent || (
                        <TableNoContent onClick={emptyContentClearFilters} />
                      )}
                </div>
              )}
            </>
          </div>
        </div>
        <div ref={postColsSectionRef}>{postColsChildren}</div>
      </div>
    </TableContext.Provider>
  );
}

const ForwardedTable = forwardRef(Table);
export default ForwardedTable;
