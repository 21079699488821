import PasscodeService from './service/passcode-service';

const initialState = () => ({
  id: null,
  name: '',
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  isActive: true,
  supportsRules: true,
  iPhone: PasscodeService.IPHONE_CHECKBOX_FIELDS().reduce((acc, curr) => {
    acc[curr.apiProperty] = {
      checked: curr.defaults.checked,
      value: curr.defaults.value,
    };

    return acc;
  }, {}),
  iPad: PasscodeService.IPAD_CHECKBOX_FIELDS().reduce((acc, curr) => {
    acc[curr.apiProperty] = {
      checked: curr.defaults.checked,
      value: curr.defaults.value,
    };

    return acc;
  }, {}),
  Mac: PasscodeService.MAC_CHECKBOX_FIELDS().reduce((acc, curr) => {
    acc[curr.apiProperty] = {
      checked: curr.defaults.checked,
      value: curr.defaults.value,
    };

    return acc;
  }, {}),
});

export default initialState;
