import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import { getIconName } from '../../components/PrismNav/prismNavUtils';
import {
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  getFilteredDisabled as filterDisabled,
} from '../column-utils';
import { getScharedColumns } from './getSharedColumns';

export const getCertificatesColumns = ({
  certificatesSchema,
}): {
  columnDefs: any[];
  pinnedColumns: string[];
  alwaysHiddenColumns: string[];
} => {
  const { properties } = certificatesSchema.schema;
  const certificatesColumnHelper =
    createColumnHelper<z.infer<typeof properties>>();

  const columnDefs = [
    certificatesColumnHelper.accessor(
      (row) => row[`${certificatesSchema.uri}.common_name`],
      {
        id: `${certificatesSchema.uri}.common_name`,
        meta: {
          displayName: properties.common_name.title,
          displayIcon: getIconName(certificatesSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    certificatesColumnHelper.accessor(
      (row) => row[`${certificatesSchema.uri}.identity_certificate`],
      {
        id: `${certificatesSchema.uri}.identity_certificate`,
        cell: YesNoCell,
        meta: {
          displayName: properties.identity_certificate.title,
          displayIcon: getIconName(certificatesSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    // TODO: re-enable once we get certificates data
    // certificatesColumnHelper.accessor((row) => row[`${certificatesSchema.uri}.cert_data`], {
    //   id: `${certificatesSchema.uri}.cert_data`,
    //   meta: {
    //     displayName: properties.cert_data.title,
    //     displayIcon: getIconName(certificatesSchema.uri),
    //     filterType: 'string',
    //     filterIcon: 'text-size',
    //     filterDisabled,
    //   },
    // }),

    ...getScharedColumns(certificatesSchema),
  ];

  return { columnDefs, alwaysHiddenColumns: [], pinnedColumns: [] };
};
