import { Checkbox } from '@kandji-inc/bumblebee';
import React from 'react';

import { i18n } from 'src/i18n';
import Branding from '../blocks/branding';
import DescriptionWithDefault from '../blocks/description-with-default';
import Head from '../blocks/head';
import Information from '../blocks/information';

const WithOneDescriptionPreset = (props) => {
  const { update, setting } = props;

  return (
    <>
      <Head disableCustomIcon />

      <Branding {...props} disableCustomIcon />

      <div className="k-ss2-drawer-section --content">
        <h3 className="b-h3">{i18n.t(`Content`)}</h3>

        <DescriptionWithDefault
          {...props}
          fieldName="longDescription"
          label={i18n.t('Display a description')}
          helper={i18n.t(
            'The description is displayed in the item detail page.',
          )}
          placeholder={i18n.t('Add a custom description of the Library Item.')}
          maxLength={4000}
          bottomSlot={
            <div className="k-ss2-drawer-setting-extra">
              <p className="b-txt b-mb1">{i18n.t(`Additional option`)}</p>
              <Checkbox
                checked={setting.isRequireRead}
                onChange={() => update('isRequireRead', !setting.isRequireRead)}
                label={i18n.t(
                  'Require users to read the description before installing',
                )}
              />
            </div>
          }
        />
      </div>

      <Information {...props} />
    </>
  );
};

export default WithOneDescriptionPreset;
