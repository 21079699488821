import { i18n } from 'src/i18n';
import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';
import Arabesque from '../assets/Arabesque.png';
import Drift from '../assets/Drift.png';
import Flurry from '../assets/Flurry.png';
import Other from '../assets/Other.png';
import Shell from '../assets/Shell.png';
import WordOfTheDay from '../assets/WordOfTheDay.png';

class ScreenSaverService extends NewLibraryItemService {
  static getScreenSaverStartAfter = () => [
    { label: i18n.t('Never'), value: 0 },
    { label: i18n.common.durationMinutes(1), value: 60 },
    { label: i18n.common.durationMinutes(2), value: 120 },
    { label: i18n.common.durationMinutes(5), value: 300 },
    { label: i18n.common.durationMinutes(10), value: 600 },
    { label: i18n.common.durationMinutes(15), value: 900 },
    { label: i18n.common.durationMinutes(20), value: 1200 },
    { label: i18n.common.durationMinutes(30), value: 1800 },
    { label: i18n.common.durationHours(1), value: 3600 },
  ];

  static getScreenSaverOptions = () => [
    {
      label: i18n.t('Arabesque'),
      value: '/System/Library/Screen Savers/Arabesque.saver',
      src: Arabesque,
    },
    {
      label: i18n.t('Drift'),
      value: '/System/Library/Screen Savers/Drift.saver',
      src: Drift,
    },
    {
      label: i18n.t('Flurry'),
      value: '/System/Library/Screen Savers/Flurry.saver',
      src: Flurry,
    },
    {
      label: i18n.t('Shell'),
      value: '/System/Library/Screen Savers/Shell.saver',
      src: Shell,
    },
    {
      label: i18n.t('Word of the Day'),
      value: '/System/Library/Screen Savers/Word of the Day.saver',
      src: WordOfTheDay,
    },
    {
      isOther: true,
      label: i18n.t('Other...'),
      value: '',
      src: Other,
    },
  ];

  static OTHER_SS = Other;
}

export const screenSaverService = new ScreenSaverService();

export default ScreenSaverService;
