import { useCallback, useState } from 'react';

const useUniqValue = () => {
  const [value, setValue] = useState(0);

  const setNewValue = useCallback(() => setValue((prev) => prev + 1), []);

  return [value, setNewValue] as const;
};

export default useUniqValue;
