import React, { useContext } from 'react';

import { DialogPrimitives as Dialog } from '@kandji-inc/nectar-ui';

import { InterfaceContext } from 'contexts/interface';

import { Context } from './context';

import slug from './slug';

import formAutoApp from './form-auto-app';
import formFailure from './form-failure';
import formGeneral from './form-general';
import formHome from './form-home';
import formSuccess from './form-success';

const view = {
  [slug.FAILURE]: formFailure,
  [slug.SUCCESS]: formSuccess,
  [slug.GENERAL]: formGeneral,
  [slug.AUTO_APP]: formAutoApp,
};

export default () => {
  const { isOpenModalFeatureRequest } = useContext(InterfaceContext);
  const { wizard, onClose } = useContext(Context);

  const { type } = wizard;

  const parts = view[type] || formHome;

  return (
    <Dialog.Root open={isOpenModalFeatureRequest} onOpenChange={onClose}>
      <Dialog.Content
        onClose={onClose}
        onOpenAutoFocus={(e: Event) => e.preventDefault()}
        css={{
          width: '600px',
          height: 'auto',
        }}
      >
        {parts.header}
        {parts.content}
        {parts.footer}
      </Dialog.Content>
    </Dialog.Root>
  );
};
