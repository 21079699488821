import { Icon } from '@kandji-inc/bumblebee';
import { colors } from 'app/common/constants';
import { truncate } from 'app/common/style-utils';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import uuidv4 from 'uuid/v4';
import PlaceholderAvatar from '../../../assets/img/placeholder-avatar.svg';
import { useOnClickOutside } from '../common/hooks';

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  min-height: 2em;
  // padding: ${(props) => (props.sidebarOpen ? '0 32px' : '0')};
  padding: 0 15px 0 18px;
  margin: 10px 0 ${(props) => props.mBottom} 0;
  margin-top: 23px;
  margin-bottom: 30px;
`;

const Button = styled.section`
  display: flex;
  justify-content: center;
  padding: 0;
  align-items: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: ${(props) => (props.opened ? '25px' : '0')};
  border: ${(props) => (props.opened ? '2px' : '0')} solid
    ${colors['marengo-500']};
  z-index: ${(props) => (props.opened ? 9999 : 1)};
  background-color: ${(props) =>
    props.opened ? colors['marengo-699'] : colors['marengo-600']};
  min-height: 2em;
  text-align: center;
  cursor: pointer;
  border-radius: ${(props) => (props.opened ? '25px' : '50px')};
  color: white;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  &.opened {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: none;
  }
`;

const CompanyName = styled.section`
  ${truncate('100%')};
  cursor: pointer;
  margin-left: 16px;
  font-size: 14px;
  margin-top: 7px;
  font-weight: 400;
  color: rgb(134, 138, 147);
  font-family: var(--font-family-primary);
  font-style: normal;
  font-stretch: normal;
  &:hover,
  &.opened {
    color: white;
  }
`;

const Body = styled.section`
  z-index: 9998;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 348px;
  bottom: calc(100% - 2px);
  background: ${(props) => colors[props.opened ? 'marengo-699' : 'marengo-700']};
  color: ${colors['grey-400']};
  border-radius: ${(props) => (props.opened ? '25px' : '50px')};
  padding-top: 10px;
  padding-bottom: 10px;
  align-self: flex-start;
  border: 2px solid ${colors['marengo-500']};
  transition: max-height 0.3s cubic-bezier(0, 1, 0.5, 1);
  overflow: hidden;
  white-space: nowrap;
  max-height: 300px;
  &:not(.opened) {
    max-height: 0;
    width: 100%;
    box-shadow: none;
    padding: 0;
    border: none;
    opacity: 0;
  }
  &.opened {
    padding: 10px 30px;
    border-bottom-left-radius: 0;
    left: auto;
  }
`;

const OptionList = styled.section`
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${colors['grey-300']};
    border-radius: 4px;
  }
`;

const Option = styled.section`
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  color: ${(props) =>
    props.active ? colors['yellow-500'] : colors['grey-300']};
  padding: 12px 0;
  transition: 0.5s;
  text-align: left;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.2em;
  ${truncate('100%')};
  &:hover {
    color: ${(props) => (props.clickable ? 'white' : '')};
  }
  &:not(.opened) {
    color: transparent;
  }
`;

const OptionIcon = styled(Icon)`
  margin-right: 1em;
  font-size: 1rem;
`;

const OptionSubtitle = styled('p')`
  margin: 0;
  padding: 0;
  font-size: 12px;
  text-transform: none;
  color: ${colors.grey450};
`;

const Delimiter = styled.section`
  margin: 10px 0;
  width: 100%;
  height: 0;
  border-bottom: 1px solid ${colors['marengo-500']};
`;

const Name = styled.span`
  letter-spacing: 0.1em;
  color: #a5aab5;
`;

const CompanySwitcher = ({
  options,
  sidebarOpen,
  valueLabel,
  value,
  buttonIcon,
  mBottom,
}) => {
  const [opened, setOpened] = useState(false);
  const CompanySwitcherRef = useRef(null);

  const toggle = () => setOpened(!opened);

  const handleClickOutside = () => {
    if (opened) {
      toggle();
    }
  };

  useOnClickOutside(CompanySwitcherRef, handleClickOutside);

  const openedClass = opened ? 'opened' : null;
  return (
    <Wrapper
      sidebarOpen={sidebarOpen}
      mBottom={mBottom}
      ref={CompanySwitcherRef}
    >
      <Button
        opened={opened}
        sidebarOpen={sidebarOpen}
        className={`noselect ${openedClass}`}
        onClick={(e) => {
          toggle();
          e.stopPropagation();
        }}
      >
        {!buttonIcon && (
          <Name>
            {(valueLabel || '')
              .split(' ')
              .map((word) => word[0])
              .slice(0, 2)
              .join('')}
          </Name>
        )}
        {/*! !buttonIcon && <Icon className="ss-icon ss-user" opened={opened} /> */}
        {!!buttonIcon && (
          <span className="side-bar__profile-icon">
            <img src={PlaceholderAvatar} alt="Profile" />
          </span>
        )}
      </Button>
      {sidebarOpen && (
        <CompanyName
          id="sidebar-user-name"
          className={`noselect ${openedClass}`}
          onClick={(e) => {
            toggle();
            e.stopPropagation();
          }}
        >
          {valueLabel}
        </CompanyName>
      )}
      <Body opened={opened} className={openedClass} sidebarOpen={sidebarOpen}>
        <OptionList>
          {options.map((item) => (
            <React.Fragment key={item.value || uuidv4()}>
              {isEmpty(item) && <Delimiter />}
              {!isEmpty(item) && (
                <Option
                  clickable={!!item.action}
                  active={item.value === value}
                  className={openedClass}
                  onClick={(e) => {
                    if (item.action) {
                      item.action();
                      toggle();
                    }
                    e.stopPropagation();
                  }}
                >
                  {item.iconClass && <OptionIcon name={item.iconClass} />}
                  {item.label}
                  {item.subtitle && (
                    <OptionSubtitle>{item.subtitle}</OptionSubtitle>
                  )}
                </Option>
              )}
            </React.Fragment>
          ))}
        </OptionList>
      </Body>
    </Wrapper>
  );
};

CompanySwitcher.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      iconClass: PropTypes.string,
      label: PropTypes.node,
      subtitle: PropTypes.string,
      action: PropTypes.func,
    }),
  ).isRequired,
  sidebarOpen: PropTypes.bool.isRequired,
  value: PropTypes.string,
  valueLabel: PropTypes.string,
  buttonIcon: PropTypes.string,
  mBottom: PropTypes.string,
};

CompanySwitcher.defaultProps = {
  value: '',
  valueLabel: '',
  buttonIcon: '',
  mBottom: '0',
};

export default CompanySwitcher;
