import { TextInput } from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';
import { i18n } from 'src/i18n';

const CertificateNameRow = (props) => {
  const { isDisabled, setting, update } = props;

  return (
    <Setting.Row>
      <Setting.Title>
        <p className="b-txt">
          {i18n.ut(
            "Certificate name <span class='b-txt-light'>(optional)</span>",
          )}
        </p>
      </Setting.Title>
      <Setting.Helpers>
        <p className="b-txt-light">
          {i18n.t('The display name of the certificate.')}
        </p>
      </Setting.Helpers>
      <Setting.Controls>
        <TextInput
          value={setting.name}
          onChange={(e) => update('name', e.target.value)}
          disabled={isDisabled}
          placeholder={i18n.t('Honey, Inc. Certificate')}
          compact
          data-testid="cert-name"
        />
      </Setting.Controls>
    </Setting.Row>
  );
};

export default CertificateNameRow;
