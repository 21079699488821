import React, { useEffect, useContext, useState, useCallback } from 'react';

import {
  Button,
  DialogPrimitives as Dialog,
  Flex,
  Text,
  TextArea,
  TextField,
  styled,
} from '@kandji-inc/nectar-ui';

import { isValidUrl } from 'src/app/components/common/helpers';
import { descriptionCharCount } from './constants';
import { Context } from './context';

import slug from './slug';
import types from './type';

const initial = {
  tags: [types[slug.AUTO_APP].product],
  content: '',
  title: '',
  display_url: '',
};

const LabelText = styled(Text, {
  color: '$neutral70',
  fontWeight: '$medium',
});

const Header = () => (
  <Dialog.Header>
    <Dialog.Title>Auto App request</Dialog.Title>
  </Dialog.Header>
);

export const Content = () => {
  const { form, setForm, update } = useContext(Context);

  const [hasTitleError, setTitleError] = useState(false);
  const [hasDisplayUrlError, setDisplayUrlError] = useState(false);

  const handleText = useCallback(
    (e, property, charCount, error, validateUrl = false) => {
      const { length } = e.target.value;
      if (length > charCount) {
        error(true);
        return;
      }
      if (length === 0) {
        error(true);
      } else {
        error(false);
      }
      if (validateUrl && !isValidUrl(e.target.value)) {
        error(true);
      }
      update(property, e.target.value);
    },
    [update],
  );

  useEffect(() => {
    setForm(initial);
    return () => setForm({});
  }, []);

  return (
    <Flex p5 flow="column" gap="lg">
      <Text css={{ marginBottom: 16 }}>
        Request a new Auto App that would be valuable to your end users.
      </Text>
      <TextField
        label={<LabelText>Category</LabelText>}
        onChange={(e) =>
          handleText(e, 'title', descriptionCharCount, setTitleError)
        }
        maxLength={descriptionCharCount}
        hint={{
          ...(hasTitleError && { label: 'Required' }),
          charCounter: {
            count: form?.title?.length || 0,
            max: descriptionCharCount,
          },
        }}
        state={hasTitleError ? 'error' : 'default'}
        value={form.title}
        placeholder="Name of the application"
      />
      <TextField
        css={{ marginBottom: 16 }}
        label={<LabelText>Website URL</LabelText>}
        placeholder="https://"
        value={form.display_url}
        hint={{
          ...(hasDisplayUrlError && { label: 'Valid URL required' }),
        }}
        state={hasDisplayUrlError ? 'error' : 'default'}
        onChange={(e) => {
          handleText(
            e,
            'display_url',
            descriptionCharCount,
            setDisplayUrlError,
            true,
          );
        }}
        maxLength={descriptionCharCount}
      />
      <TextArea
        label={<LabelText>Additional details</LabelText>}
        minHeight={210}
        maxHeight={210}
        isOptional
        placeholder="Share your feedback here..."
        hint={{
          charCounter: {
            count: form?.content?.length || 0,
            max: descriptionCharCount,
          },
        }}
        value={form.content}
        onChange={(e) => {
          update('content', e.target.value);
        }}
        maxLength={descriptionCharCount}
      />
    </Flex>
  );
};

export const Footer = () => {
  const { form, isWorking, handleSave, onHome, onClose } = useContext(Context);

  const isDisabled =
    isWorking || !form?.title || !isValidUrl(form?.display_url || '');

  return (
    <>
      <div
        role="separator"
        aria-orientation="horizontal"
        style={{ height: 1, backgroundColor: '#d7e1ed', margin: 0 }}
      ></div>
      <Flex
        css={{
          padding: '16px 12px',
          justifyContent: 'space-between',
        }}
      >
        <Button variant="subtle" compact disabled={isWorking} onClick={onHome}>
          Back
        </Button>
        <Flex css={{ gap: 4 }}>
          <Button variant="subtle" disabled={isWorking} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={isDisabled}
            loading={isWorking}
            iconLeft
            icon={isWorking ? 'arrows-rotate' : undefined}
            onClick={handleSave}
          >
            Submit
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default {
  header: <Header />,
  content: <Content />,
  footer: <Footer />,
};
