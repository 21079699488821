/* istanbul ignore file */
import { useMutation } from '@tanstack/react-query';
import { api } from 'app/api/base';
import { URL_COMPUTER_CHANGE_BLUEPRINT } from 'app/api/urls';

const useChangeComputerBlueprint = () => {
  const { ...rest } = useMutation({
    mutationKey: ['computer-change-blueprint'],
    mutationFn: ({
      computers,
      blueprintId,
    }: { computers: Array<string>; blueprintId: string }) =>
      api(URL_COMPUTER_CHANGE_BLUEPRINT).patch({ computers, blueprintId }),
  });

  return {
    ...rest,
  };
};

export default useChangeComputerBlueprint;
