import Const from './Const';
import Util from './util';

let saveAs;

if (Util.canUseDOM()) {
  const filesaver = require('./filesaver');
  saveAs = filesaver.saveAs;
}

function toString(data, keys, separator, excludeCSVHeader) {
  let dataString = '';
  if (data.length === 0) {
    return dataString;
  }

  const headCells = [];
  let rowCount = 0;
  keys.forEach((key) => {
    if (key.row > rowCount) {
      rowCount = key.row;
    }
    // rowCount += (key.rowSpan + key.colSpan - 1);
    for (let index = 0; index < key.colSpan; index++) {
      headCells.push(key);
    }
  });

  const firstRow = excludeCSVHeader ? 1 : 0;
  for (let i = firstRow; i <= rowCount; i++) {
    dataString += `${headCells
      .map((x) => {
        if (x.row + (x.rowSpan - 1) === i) {
          return `"${x.header}"`;
        }
        if (x.row === i && x.rowSpan > 1) {
          return '';
        }
        return '';
      })
      .filter((key) => typeof key !== 'undefined')
      .join(separator)}\n`;
  }

  const validKeys = keys.filter((key) => key.field !== undefined);

  data.forEach((row) => {
    validKeys.forEach((col, i) => {
      const { field, format, extraData, type } = col;
      let value =
        typeof format !== 'undefined'
          ? format(row[field], row, extraData)
          : row[field];
      value = type === Const.CSV_NUMBER_TYPE ? Number(value) : `"${value}"`;
      const cell = typeof value !== 'undefined' ? value : '';
      dataString += cell;
      if (i + 1 < validKeys.length) {
        dataString += separator;
      }
    });

    dataString += '\n';
  });

  return dataString;
}

const exportCSV = function exportCSV(
  data,
  keys,
  filename,
  separator,
  noAutoBOM,
  excludeCSVHeader,
) {
  const dataString = toString(data, keys, separator, excludeCSVHeader);
  if (typeof window !== 'undefined') {
    const isNoAutoBOM = noAutoBOM === undefined ? true : noAutoBOM;
    if (typeof saveAs === 'function') {
      saveAs(
        new Blob(['\ufeff', dataString], { type: 'text/plain;charset=utf-8' }),
        filename,
        isNoAutoBOM,
      );
    }
  }
};

export default exportCSV;
