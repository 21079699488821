/* istanbul ignore file */
import { Button, Icon } from '@kandji-inc/bumblebee';
import classNames from 'classnames';
import { i18n } from 'i18n';
import get from 'lodash/get';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AccountContext } from 'src/contexts/account';
import styled from 'styled-components';
import instruction3new from '../../assets/img/mdm/new-cert-1.png';
import instruction4new from '../../assets/img/mdm/new-cert-2.png';
import instruction3 from '../../assets/img/mdm/renew-cert-1.png';
import instruction4 from '../../assets/img/mdm/renew-cert-2.png';
import instruction5 from '../../assets/img/mdm/renew-cert-3.png';
import history from '../router/history';
import { moment } from './common/helpers';
import {
  getIntegration,
  getPushCSR,
  uploadPushCSR,
} from './integrations/Apple/api';
import { H2 } from './interface/Typography';

const BaseBlock = styled('div')`
  background-color: white;
  margin: 20px 0 30px 0;
  padding: 20px 27px 20px 33px;
  display: grid;
  grid-gap: 15px;
  border-radius: 4px;
`;

const Title = styled(H2)`
  text-transform: none;
  font-size: var(--font-headings-m-size);
  line-height: var(--font-headings-m-line-height);
  font-weight: var(--font-headings-m-weight);
  letter-spacing: var(--font-headings-m-letter-spacing);
  color: var(--color-neutral-100);
`;

const Notification = styled('section')`
  background: #ffeecd;
  border-radius: 4px;
  height: 40px;
  padding: 11px 16px;

  font-family: var(--font-body-xs-family);
  font-weight: var(--font-body-xs-weight);
  font-size: var(--font-body-xs-size);
  line-height: var(--font-body-xs-line-height);
  letter-spacing: var(--font-body-xs-letter-spacing);

  color: #d98017;
`;

const NotificationIcon = styled(Icon)`
  font-size: 18px;
  color: #d98017;
  margin-right: 10px;
`;

const Card = styled('section')`
  background-color: ${(props) =>
    props.cardColor ? props.cardColor : '#F6F7F9'};
  border-radius: 4px;
  padding-right: 20px;
  display: grid;
  grid-template-areas: "icon header" "icon content";
  grid-template-columns: 86px auto;
  grid-template-rows: auto auto;
  color: #1a1d25;
  &.instruction {
    //grid-template-areas: "icon header" "icon content" "footer footer";
    //grid-template-columns: 86px auto;
    //grid-template-rows: auto auto 50px;
  }
  &.info {
    background: #eef0f6;
    color: #4d5a79;
  }
`;

const CardIcon = styled('div')`
  grid-area: icon;
  width: 33px;
  height: 33px;
  border-radius: 100%;
  background: #1a1d25;
  margin: 31px 13px auto 40px;

  font-family: var(--font-heading-s-family);
  font-weight: var(--font-heading-s-weight);
  font-size: var(--font-heading-s-size);
  line-height: var(--font-heading-s-line-height);
  letter-spacing: var(--font-heading-s-letter-spacing);
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardHeader = styled('div')`
  grid-area: header;
  margin: 35px 0 10px;
  font-family: var(--font-heading-m-family);
  font-weight: var(--font-heading-m-weight);
  font-size: var(--font-heading-m-size);
  line-height: var(--font-heading-m-line-height);
  letter-spacing: var(--font-heading-m-letter-spacing);
`;

const CardContent = styled('div')`
  grid-area: content;
  margin-bottom: 34px;

  font-family: var(--font-body-xs-family);
  font-weight: var(--font-body-xs-weight);
  font-size: var(--font-body-xs-size);
  line-height: var(--font-body-xs-line-height);
  letter-spacing: var(--font-body-xs-letter-spacing);
`;

const Text = styled('div')`
  max-width: ${(props) => (props.fullWidthText ? '100%' : '603px')};
`;

const BoldText = styled('b')`
  font-weight: 600;
`;

const Action = styled('div')`
  margin: 24px 0;
`;

const Instruction = styled('img')`
  margin: 24px 0;
  max-width: 600px;
`;

const ButtonsWrapper = styled('div')`
  display: grid;
  grid-template-columns: auto min-content min-content;
  grid-template-areas: ". button1 button2";
  grid-gap: 10px;
  padding-bottom: 50px;
`;

const UploadAreaWrapper = styled('section')`
  min-height: 185px;
  margin-right: 10px;
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  padding: 20px;
`;

const DashedArea = styled('div')`
  border: 2px dashed #d7dbe8;
  box-sizing: border-box;
  border-radius: 4px;
  min-height: 145px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.highlight {
    border: 2px dashed #d98017;
  }
`;

const UploadAreaIcon = styled('i')`
  font-size: 35px;
  color: #7d8dbd;
  margin-bottom: 19px;
`;

const UploadAreaText = styled('div')`
  font-family: var(--font-body-xs-family);
  font-weight: var(--font-body-xs-weight);
  font-size: var(--font-body-xs-size);
  line-height: var(--font-body-xs-line-height);
  letter-spacing: var(--font-body-xs-letter-spacing);
  text-align: center;

  color: #1a1d25;
`;

const Input = styled('input')`
  max-width: 280px;
  width: 100%;

  background: #ffffff;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
  height: 40px;
  padding: 12px 15px;

  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 17px;

  color: #1a1d25;
`;

const Link = ({ href, text }) => (
  <a
    href={href}
    style={{ color: '#618FE8', fontWeight: '500' }}
    rel="noopener noreferrer"
    target="_blank"
  >
    <u>{text}</u>
  </a>
);

export const StepCard = ({
  children,
  icon,
  title,
  instruction,
  action,
  fullWidthText,
  cardColor,
}) => (
  <Card className={classNames({ instruction })} cardColor={cardColor}>
    <CardIcon>{icon}</CardIcon>
    <CardHeader>{title}</CardHeader>
    <CardContent>
      <Text fullWidthText={fullWidthText}>{children}</Text>
      {action && <Action>{action}</Action>}
      {instruction &&
        instruction.map((element) => <Instruction src={element} alt={title} />)}
    </CardContent>
  </Card>
);

export function isCert(file) {
  const acceptedList = [
    'application/x-x509-ca-cert',
    'application/x-pem-file',
    // * Circumvent Windows bug
    '',
  ];
  return file && acceptedList.includes(file?.type);
}

export const UploadArea = ({ uploadError, setUploadError, file, setFile }) => {
  const fileInput = useRef(null);
  const dropArea = document.getElementById('drop-area');
  const preventDefaults = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const validateFile = (dfile) => {
    if (isCert(dfile)) {
      setFile(dfile);
      setUploadError(null);
    } else {
      setFile(null);
      setUploadError(i18n.t("This doesn't appear to be a valid certificate."));
    }
  };
  const highlight = () => dropArea.classList.add('highlight');
  const unhighlight = () => dropArea.classList.remove('highlight');
  const handleDrop = (e) => validateFile(e.dataTransfer.files[0]);
  if (dropArea) {
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
      dropArea.addEventListener(eventName, preventDefaults, false);
    });
    ['dragenter', 'dragover'].forEach((eventName) => {
      dropArea.addEventListener(eventName, highlight, false);
    });
    ['dragleave', 'drop'].forEach((eventName) => {
      dropArea.addEventListener(eventName, unhighlight, false);
    });
    dropArea.addEventListener('drop', handleDrop, false);
  }
  const handleFiles = (e) => validateFile(e.target.files[0]);
  const handleClick = () => fileInput.current && fileInput.current.click();
  return (
    <>
      <UploadAreaWrapper>
        <input
          hidden
          ref={fileInput}
          type="file"
          accept=".pem"
          onChange={handleFiles}
        />
        <DashedArea
          onClick={handleClick}
          data-testid="fileUpload"
          id="drop-area"
        >
          <UploadAreaIcon className="fal fa-file-alt" />
          <UploadAreaText>
            {get(file, 'name') || i18n.t('Drag here or click to upload')}
          </UploadAreaText>
        </DashedArea>
      </UploadAreaWrapper>
      {uploadError && (
        <Notification
          style={{ marginTop: 10, display: 'flex', justifyContent: 'center' }}
        >
          <NotificationIcon name="octagon-exclamation" />
          {uploadError}
        </Notification>
      )}
    </>
  );
};

const EmailInput = ({ onChange, error, ...rest }) => {
  const handleChange = useCallback((e) => onChange(e.target.value), [onChange]);
  return (
    <>
      <Input onChange={handleChange} {...rest} />
      {error && (
        <Notification
          style={{ marginTop: 10, display: 'flex', justifyContent: 'center' }}
        >
          <NotificationIcon name="octagon-exclamation" />
          {error}
        </Notification>
      )}
    </>
  );
};

const MDMIntegrationPage = () => {
  const isRenewPage = window.location.pathname.indexOf('renew') >= 0;
  const [integrationData, setIntegrationData] = useState({});
  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [file, setFile] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const { fetchAccount } = useContext(AccountContext);

  useEffect(() => {
    if (isRenewPage) {
      getIntegration()
        .then((integrationDataResult) => {
          if (integrationDataResult.days_left === null) {
            history.push('/my-company/integrations');
          }
          setIntegrationData(integrationDataResult);
        })
        .catch(() => history.push('/my-company/integrations'));
    }
  }, [setIntegrationData]);
  useEffect(() => {
    if (email) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
        setEmailError(i18n.t('Invalid Email Format'));
      } else {
        setEmailError(null);
      }
    }
  }, [email]);
  useEffect(() => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      uploadPushCSR(formData, { validate: true }).catch(() => {
        setFile(null);
        setUploadError(
          i18n.t("This doesn't appear to be a valid certificate."),
        );
      });
    }
  }, [file]);

  const handleUploadComplete = async () => {
    await fetchAccount();
    history.push('/my-company/integrations');
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('apple_id', email);
    return uploadPushCSR(formData)
      .then(handleUploadComplete)
      .catch(() => {
        setFile(null);
        setUploadError(
          i18n.t("This doesn't appear to be a valid certificate."),
        );
      });
  };
  const isMdmExpired = get(integrationData, 'days_left') < 0;
  const timeTillExpired = moment(get(integrationData, 'expire_at')).fromNow();
  return (
    <>
      <BaseBlock>
        <Title>
          {isRenewPage
            ? i18n.t('Renew APNs Integration')
            : i18n.t('APNs Integration')}
        </Title>
        {isRenewPage && get(integrationData, 'expire_at') && (
          <Notification>
            <NotificationIcon name="circle-info" />
            {isMdmExpired
              ? i18n.t('Your APNs certificate has expired.')
              : i18n.t(`Your APNs certificate is expiring {timeTillExpired}.`, {
                  timeTillExpired,
                })}
          </Notification>
        )}
        <StepCard
          icon={i18n.formatNumber(1)}
          title={i18n.t('Download the Kandji CSR')}
          action={
            <Button theme="dark" onClick={getPushCSR}>
              {i18n.t('Download push CSR')}
            </Button>
          }
        >
          {i18n.ut(`By default, the <b>kandji-mdm.csr</b> file will appear
          in your Downloads folder. You will upload this to Apple’s Push
          Certificate Portal in Step 4.`)}
        </StepCard>
        <StepCard
          icon={i18n.formatNumber(2)}
          title={i18n.t('Sign in with your Apple Account')}
        >
          {i18n.t('Visit')}{' '}
          <Link
            href="https://identity.apple.com/pushcert"
            text="identity.apple.com/pushcert"
          />{' '}
          {i18n.t(
            `and sign in using your Apple Account, we recommend that you use a
          dedicated Managed Apple Account from Apple Business Manager. Please
          reference this support article`,
          )}{' '}
          <Link
            href="https://support.kandji.io/support/solutions/articles/72000560508"
            text={i18n.t(i18n.t('learn more'))}
          />
          .
        </StepCard>
        <StepCard
          icon={i18n.formatNumber(3)}
          title={
            isRenewPage
              ? i18n.t('Renew your current APNs certificate')
              : i18n.t('Create a Push Certificate with Apple')
          }
          instruction={isRenewPage ? [instruction3] : [instruction3new]}
        >
          {isRenewPage && (
            <span>
              {i18n.ut(`Click the <b>Renew</b> button. You will be asked to
              review and accept Apple’s terms and conditions before continuing.`)}
            </span>
          )}
        </StepCard>
        <StepCard
          icon={i18n.formatNumber(4)}
          title={i18n.t('Upload Kandji CSR')}
          instruction={isRenewPage ? [instruction4] : [instruction4new]}
        >
          {i18n.ut(
            `Click the <b>Choose File</b> button and select the <b>kandji-mdm.csr</b> file you downloaded in Step 1. Then click the <b>Upload</b> button.`,
          )}
        </StepCard>
        <StepCard
          icon={i18n.formatNumber(5)}
          title={i18n.t('Download the Push Certificate')}
          instruction={[instruction5]}
        >
          {i18n.ut(
            `Click the <b>Download</b> button to download the Push
          Certificate that was just created. By default, the <b>MDM_ Kandji, Inc._Certificate.pem</b> file will
          appear in your Downloads folder.`,
          )}
        </StepCard>
        <StepCard
          icon={i18n.formatNumber(6)}
          title={i18n.t('Upload the Push Certificate to Kandji')}
          action={
            <UploadArea
              uploadError={uploadError}
              setUploadError={setUploadError}
              file={file}
              setFile={setFile}
            />
          }
        >
          {i18n.ut(`Upload the <b>MDM_Kandji, Inc._Certificate.pem</b> you
          downloaded in Step 5.`)}
        </StepCard>
        <StepCard
          icon={i18n.formatNumber(7)}
          title={i18n.t('Enter Apple Account')}
          action={
            <EmailInput
              type="email"
              value={email}
              onChange={setEmail}
              placeholder={i18n.t('Apple Account')}
              error={emailError}
            />
          }
        >
          {i18n.ut(
            `Enter the email address of the Apple Account you used to generate this
          Push Certificate. Then click the <b>Complete APNs Setup</b> button.`,
          )}
        </StepCard>
      </BaseBlock>
      <ButtonsWrapper>
        <Button
          disabled={!file || !email || emailError || uploadError}
          style={{ gridArea: 'button1' }}
          className="pendo-complete-apns"
          theme="dark"
          onClick={handleSubmit}
        >
          {isRenewPage
            ? i18n.t('Complete APNs renewal')
            : i18n.t('Complete APNs setup')}
        </Button>
        <Button
          style={{ gridArea: 'button2' }}
          theme="dark"
          kind="outline"
          onClick={() => history.push('/my-company/integrations')}
        >
          {i18n.t('Cancel')}
        </Button>
      </ButtonsWrapper>
    </>
  );
};

export default MDMIntegrationPage;
