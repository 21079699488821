import { Box, Button, Dialog, Flex, Select, Text } from '@kandji-inc/nectar-ui';
import { useQuery } from '@tanstack/react-query';
import { i18n } from 'i18n';
import { useEffect, useState } from 'react';

import { blueprintService } from 'src/features/library-items/data-service/blueprint/blueprint-service';

const devices = (count: number) =>
  i18n.t('{count, plural, one {device} other {devices}}', { count });

export type AssignBlueprintProps = {
  isOpen: boolean;
  currentBlueprintId: string;
  toggle: (boolean) => void;
  isMultiple: boolean;
  onConfirm: (blueprint: { name: string; id: string }) => void;
};

const AssignBlueprintModal = (props: AssignBlueprintProps) => {
  const { isOpen, toggle, onConfirm, currentBlueprintId, isMultiple } = props;
  const [selected, setSelected] = useState<{ label: string; value: string }>();
  const {
    data: blueprints,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['am-devices-blueprints'],
    queryFn: () => blueprintService.find(),
  });
  const pluralCount = isMultiple ? 2 : 1;

  const content = (
    <Flex gap="md" flow="column">
      <Text>
        {i18n.t(
          'Selected {devices} will begin moving as soon as this change is made. This may take some time.',
          { devices: devices(pluralCount) },
        )}
      </Text>

      <Box>
        <Select
          label={i18n.t('Assign {devices} to Blueprint:', {
            devices: devices(pluralCount),
          })}
          disabled={isLoading || isError}
          options={blueprints
            ?.filter(({ id }) => id !== currentBlueprintId)
            .map(({ name, id }) => ({
              value: id,
              label: name,
            }))}
          onChange={(_, { option }) =>
            setSelected({ label: option.label, value: option.value as string })
          }
          value={selected?.value}
          searchable
          size="full"
          data-testid="am-device-bp-select"
        />
      </Box>
    </Flex>
  );

  const footer = (
    <Flex gap="sm" justifyContent="end" wrap="wrap">
      <Button compact variant="subtle" onClick={() => toggle(false)}>
        {i18n.t('Cancel')}
      </Button>

      <Button
        compact
        variant="primary"
        disabled={!selected}
        onClick={() => {
          onConfirm({ name: selected?.label, id: selected?.value });
          toggle(false);
        }}
      >
        {i18n.t('Change')}
      </Button>
    </Flex>
  );

  useEffect(() => {
    setSelected(null);
  }, [isOpen]);

  return (
    <Dialog
      title={i18n.t('Assign Blueprint')}
      content={content}
      footer={footer}
      isOpen={isOpen}
      onOpenChange={() => toggle(false)}
      css={{ width: '560px', zIndex: 2000 }}
    />
  );
};

export default AssignBlueprintModal;
