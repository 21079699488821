import SshProfileService from './service/ssh-profile-service';

const initialState = () => ({
  id: '',
  name: '',
  isActive: true,
  blueprints: [],
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  general: {
    invalidationsMap: {},

    isLoginAvailability: false,
    isLoginAvailabilityEnabled: false,

    isChallengeResponseAuthentication: false,
    isChallengeResponseAuthenticationEnabled: true,

    isPublicKeyAuthentication: false,
    isPublicKeyAuthenticationEnabled: false,

    isRootLogin: false,
    isRootLoginEnabled: false,

    isSshLoginBanner: false,
    isSshLoginBannerEnabled: true,
    sshLoginBannerText: SshProfileService.DEFAULT_BANNER(),

    isGrace: false,
    graceValue: SshProfileService.DEFAULT_GRACE,

    isSessionTimeout: false,
    sessionTimeoutValue: SshProfileService.DEFAULT_TIMEOUT,

    isMaximumAliveCount: false,
    maximumAliveCountValue: SshProfileService.DEFAULT_MAX_ACCOUNTS,

    isMaximumAuthenticationAttempts: false,
    maximumAuthenticationAttemptsValue: SshProfileService.DEFAULT_MAX_ATTEMPTS,

    isPort: false,
    portValue: SshProfileService.DEFAULT_PORT,

    isLimitAccess: false,
    limitAccessValue: [{ type: null, name: '' }],

    isRemoveNonFipsCiphers: false,
    isRemoveNonFipsMacs: false,
    isSecureKeyExchangeAlgorithms: false,
  },

  supportsRules: true,
});

export default initialState;
