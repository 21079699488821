import React from 'react';

import { array, func, string } from 'prop-types';

import { MultiSelectSearch as BumblebeeMultiSelect } from '@kandji-inc/bumblebee';

const MultiSelect = ({
  fieldKey,
  options,
  updateField,
  getValue,
  ariaLabel,
  ...rest
}) => {
  const values = getValue(fieldKey, options, true);

  const onChange = (selection) =>
    updateField(
      fieldKey,
      selection?.map(({ value }) => value),
    );

  return (
    <BumblebeeMultiSelect
      {...rest}
      options={options}
      values={values}
      onChange={onChange}
      aria-label={ariaLabel}
    />
  );
};

MultiSelect.propTypes = {
  fieldKey: string.isRequired,
  options: array.isRequired,
  updateField: func.isRequired,
  getValue: func.isRequired,
  ariaLabel: string.isRequired,
};

export default MultiSelect;
