import {
  Banner,
  Checkbox,
  Chip,
  Flex,
  TextInput,
  getMinMaxValidator,
  onEmptyBlurValidator,
  useInputsValidators,
} from '@kandji-inc/bumblebee';
import cn from 'classnames';
import { i18n } from 'i18n';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import './general-card.css';

import { Setting } from 'features/library-items/template';

import AddableContent from './addable-content';
import LimitServerAccessItem from './limit-server-access-item';
import SimpleRow from './simple-row';
import ToggleRow from './toggle-row';
import ValueRow from './value-row';

const ChipGroup = ({ chips }) => (
  <Flex className="b-mt1" gapType="gap1">
    {chips.map((chip) => (
      <Chip key={chip} text={chip} kind="tertiary-light" />
    ))}
  </Flex>
);
ChipGroup.propTypes = {
  chips: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const GeneralCard = (props) => {
  const { setting, update, isDisabled, validationDep } = props;

  const fieldsToValidate = [
    'loginBanner',
    'sessionTimeoutValue',
    'maximumAuthenticationAttemptsValue',
    'portValue',
    'graceValue',
    'maximumAliveCountValue',
  ];
  const { refs, onInvalidate } = useInputsValidators(fieldsToValidate, update);

  useEffect(() => {
    if (
      setting.isSshLoginBanner &&
      setting.isSshLoginBannerEnabled &&
      !setting.sshLoginBannerText
    ) {
      onInvalidate('loginBanner')(true);
    } else {
      onInvalidate('loginBanner')(false);
    }
  }, [
    setting.isSshLoginBanner,
    setting.isSshLoginBannerEnabled,
    setting.sshLoginBannerText,
  ]);
  useEffect(() => {
    if (setting.isSessionTimeout && !+setting.sessionTimeoutValue) {
      onInvalidate('sessionTimeoutValue')(true);
    } else {
      onInvalidate('sessionTimeoutValue')(false);
    }
  }, [setting.isSessionTimeout, setting.sessionTimeoutValue]);
  useEffect(() => {
    if (
      setting.isMaximumAuthenticationAttempts &&
      !+setting.maximumAuthenticationAttemptsValue
    ) {
      onInvalidate('maximumAuthenticationAttemptsValue')(true);
    } else {
      onInvalidate('maximumAuthenticationAttemptsValue')(false);
    }
  }, [
    setting.isMaximumAuthenticationAttempts,
    setting.maximumAuthenticationAttemptsValue,
  ]);
  useEffect(() => {
    if (
      setting.isPort &&
      (+setting.portValue < 1 || +setting.portValue > 65535)
    ) {
      onInvalidate('portValue')(true);
    } else {
      onInvalidate('portValue')(false);
    }
  }, [setting.isPort, setting.portValue]);
  useEffect(() => {
    if (setting.isGrace && !setting.graceValue) {
      onInvalidate('graceValue')(true);
    } else {
      onInvalidate('graceValue')(false);
    }
  }, [setting.isGrace, setting.graceValue]);
  useEffect(() => {
    if (setting.isMaximumAliveCount && !setting.maximumAliveCountValue) {
      onInvalidate('maximumAliveCountValue')(true);
    } else {
      onInvalidate('maximumAliveCountValue')(false);
    }
  }, [setting.isMaximumAliveCount, setting.maximumAliveCountValue]);

  const trigger = ['onBlur', validationDep];

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('General')}</h3>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          {i18n.t(
            'Configure SSH server and client options. These configurations will be remediated automatically at each agent check-in.',
          )}{' '}
          <a
            href="https://support.kandji.io/support/solutions/articles/72000558735"
            rel="noopener noreferrer"
            target="_blank"
            className="b-alink"
          >
            {i18n.t('Learn More...')}
          </a>
        </p>
      </Setting.SubHeader>

      <Setting.Rows>
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              defaultChecked
              checked={setting.isLoginAvailability}
              label={i18n.t('SSH server availability')}
              onChange={() => update('isLoginAvailability', (p) => !p)}
              isDisabled={isDisabled}
            />
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t('Select to manage SSH server availability.')}
            </p>
            <ChipGroup chips={['NIST', 'STIG', 'CIS']} />
          </Setting.Helpers>
          {setting.isLoginAvailability && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow>
                <Setting.Helpers>
                  {setting.isLoginAvailability &&
                    !setting.isLoginAvailabilityEnabled && (
                      <Banner
                        theme="info"
                        kind="block"
                        className="mb-3"
                        style={{ minWidth: 'unset' }}
                      >
                        <p data-testid="is_login_available_banner">
                          {i18n.t(
                            'The SSH server will be off. You can still manage other settings. They will be applied if the server is started.',
                          )}
                        </p>
                      </Banner>
                    )}
                </Setting.Helpers>
                <Setting.Controls>
                  <button
                    data-testid="login_availability_disable_button"
                    onClick={() => update('isLoginAvailabilityEnabled', false)}
                    className={cn('k-ssh-gc-group-btn', {
                      '--active': !setting.isLoginAvailabilityEnabled,
                    })}
                    type="button"
                  >
                    {i18n.t('Off')}
                  </button>
                  <button
                    data-testid="login_availability_enable_button"
                    onClick={() => update('isLoginAvailabilityEnabled', true)}
                    className={cn('k-ssh-gc-group-btn', {
                      '--active': setting.isLoginAvailabilityEnabled,
                    })}
                    type="button"
                  >
                    {i18n.t('On')}
                  </button>
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>
        <ToggleRow
          checkboxFiled="isChallengeResponseAuthentication"
          toggleField="isChallengeResponseAuthenticationEnabled"
          label={i18n.t('Challenge-response authentication')}
          helper={
            <>
              {i18n.t(
                'Select to turn on or off challenge-response authentication for the SSH server.',
              )}
              <ChipGroup chips={['NIST', 'STIG']} />
            </>
          }
          {...props}
        />
        <ToggleRow
          checkboxFiled="isPublicKeyAuthentication"
          toggleField="isPublicKeyAuthenticationEnabled"
          label={i18n.t('Public-key authentication')}
          helper={i18n.t(
            'Select to turn on or off public-key authentication for the SSH server.',
          )}
          {...props}
        />
        <ToggleRow
          checkboxFiled="isRootLogin"
          toggleField="isRootLoginEnabled"
          label={i18n.t('Root login')}
          helper={
            <>
              {i18n.t(
                'Select to turn on root user login with a key pair or turn off root user altogether for the SSH server.',
              )}
              <ChipGroup chips={['NIST', 'STIG']} />
            </>
          }
          {...props}
        />

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              defaultChecked
              checked={setting.isSshLoginBanner}
              label={i18n.t('SSH login banner')}
              onChange={() => update('isSshLoginBanner', (p) => !p)}
              isDisabled={isDisabled}
            />
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.ut(
                'Select to turn on or off the SSH server&apos;s login banner and to specify its text.',
              )}
            </p>
            <ChipGroup chips={['NIST', 'STIG', 'ISO']} />
          </Setting.Helpers>

          {setting.isSshLoginBanner && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow className="k-ssh-gc-secondary-block-row">
                <div>
                  <button
                    data-testid="ssh_login_banner_disable_button"
                    onClick={() => update('isSshLoginBannerEnabled', false)}
                    className={cn('k-ssh-gc-group-btn', {
                      '--active': !setting.isSshLoginBannerEnabled,
                    })}
                    type="button"
                  >
                    {i18n.t('Off')}
                  </button>
                  <button
                    data-testid="ssh_login_banner_enable_button"
                    onClick={() => update('isSshLoginBannerEnabled', true)}
                    className={cn('k-ssh-gc-group-btn', {
                      '--active': setting.isSshLoginBannerEnabled,
                    })}
                    type="button"
                  >
                    {i18n.t('On')}
                  </button>
                </div>
                {setting.isSshLoginBannerEnabled && (
                  <div className="b-mt2">
                    <p className="b-txt b-mb1" ref={refs[0]}>
                      {i18n.t('Banner text')}
                    </p>
                    <TextInput
                      value={setting.sshLoginBannerText}
                      onChange={(e) =>
                        update('sshLoginBannerText', e.target.value)
                      }
                      placeholder={i18n.t('Banner text')}
                      textArea
                      disabled={isDisabled}
                      maxLength={5000}
                      validator={(v) => [onEmptyBlurValidator(v, { trigger })]}
                      onInvalidate={onInvalidate('loginBanner')}
                    />
                  </div>
                )}
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>
        <ValueRow
          checkboxFiled="isPort"
          label={i18n.t('SSH Server port')}
          helper={i18n.t(
            'Select to configure the port on which the SSH server listens.',
          )}
          valueField="portValue"
          valueLabel={i18n.t('Port number')}
          placeholder="65535"
          validator={getMinMaxValidator({ min: 1, max: 65535, trigger })}
          inputRef={refs[3]}
          onInvalidate={onInvalidate('portValue')}
          {...props}
        />
        <SimpleRow
          checkboxFiled="isLimitAccess"
          label={i18n.t('Limit SSH server login access')}
          helper={
            <>
              {i18n.t(
                'Select to manage which users and groups can log in to the SSH server.',
              )}
              {setting.isLimitAccess &&
                setting.isRootLogin &&
                setting.isRootLoginEnabled && (
                  <Banner theme="info" kind="block" className="b-mt">
                    <p data-testid="server_access_and_root">
                      {i18n.t(`Limiting SSH server access and enabling root login will
                      block SSH access for Root unless Root is explicitly
                      allowed access below.`)}
                    </p>
                  </Banner>
                )}
            </>
          }
          {...props}
        >
          {setting.isLimitAccess && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow className="k-ssh-gc-secondary-block-row b-mt1">
                <Flex gapType="gap2" className="b-mb1">
                  <p className="b-txt k-ssh-access-col">
                    {i18n.t('User or group')}
                  </p>
                  <p className="b-txt k-ssh-access-col">{i18n.t('Name')}</p>
                </Flex>
                <AddableContent
                  items={setting.limitAccessValue}
                  isDisabled={isDisabled}
                  getEmptyItem={() => ({ type: null, name: '' })}
                  onChange={(v) => update('limitAccessValue', v)}
                  ItemComponent={LimitServerAccessItem}
                  max={25}
                  getItemProps={(index) => ({
                    isDisabled,
                    item: setting.limitAccessValue[index],
                    update,
                    index,
                    validationDep,
                  })}
                  itemsClassName="k-ssh-access-items-container"
                  rowClassName="k-ssh-access-item-row"
                />
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </SimpleRow>
        <ValueRow
          checkboxFiled="isGrace"
          label={i18n.t('Login attempt grace period')}
          helper={
            <>
              {i18n.t(
                'Select to specify the amount of time after which the SSH server disconnects if the user has not successfully logged in.',
              )}
              <ChipGroup chips={['NIST', 'STIG', 'ISO']} />
            </>
          }
          valueField="graceValue"
          valueLabel={i18n.t('seconds')}
          valueFieldId="login_attempt_grace_period_input"
          placeholder="30"
          validator={(v) => [
            onEmptyBlurValidator(v, { trigger }),
            ...getMinMaxValidator({ min: 0, max: 86400, trigger })(v),
          ]}
          inputRef={refs[4]}
          onInvalidate={onInvalidate('graceValue')}
          {...props}
        />
        <ValueRow
          checkboxFiled="isSessionTimeout"
          label={i18n.t('Session timeout')}
          helper={
            <>
              {i18n.t(
                'Select to set the inactivity timeout for SSH connections. Applies to both client and server.',
              )}
              <ChipGroup chips={['NIST', 'STIG', 'ISO']} />
            </>
          }
          valueField="sessionTimeoutValue"
          valueLabel={i18n.t('seconds')}
          validator={(v) => [
            onEmptyBlurValidator(v, { trigger }),
            {
              message: i18n.t('Value must be greater than 0'),
              invalid: () => /^0+$/.test(v),
              trigger,
            },
          ]}
          inputRef={refs[1]}
          onInvalidate={onInvalidate('sessionTimeoutValue')}
          {...props}
        />
        <ValueRow
          checkboxFiled="isMaximumAliveCount"
          label={i18n.t('Maximum alive count')}
          helper={
            <>
              {i18n.t(
                'Select to specify the maximum number of CheckAlive messages that will be sent before terminating an SSH connection. Applies to both client and server.',
              )}
              <ChipGroup chips={['NIST', 'STIG', 'ISO']} />
            </>
          }
          valueField="maximumAliveCountValue"
          valueLabel={i18n.t('messages')}
          valueFieldId="max_alive_count_input"
          placeholder="0"
          validator={(v) => [
            onEmptyBlurValidator(v, { trigger }),
            ...getMinMaxValidator({ min: 0, max: 86400, trigger })(v),
          ]}
          inputRef={refs[5]}
          onInvalidate={onInvalidate('maximumAliveCountValue')}
          {...props}
        />
        <ValueRow
          checkboxFiled="isMaximumAuthenticationAttempts"
          label={i18n.t('Maximum authentication attempts')}
          helper={i18n.t(
            'Select to specify the maximum number of authentication attempts before the SSH server disconnects the client.',
          )}
          valueField="maximumAuthenticationAttemptsValue"
          valueLabel={i18n.t('attempts')}
          validator={(v) => [
            onEmptyBlurValidator(v, { trigger }),
            ...getMinMaxValidator({ min: 1, max: 100, trigger })(v),
          ]}
          inputRef={refs[2]}
          onInvalidate={onInvalidate('maximumAuthenticationAttemptsValue')}
          {...props}
        />

        <SimpleRow
          checkboxFiled="isRemoveNonFipsCiphers"
          label={i18n.t('Remove non-FIPS ciphers')}
          helper={
            <>
              {i18n.t(
                'Select to turn off non-FIPS-compliant ciphers. Applies to both client and server.',
              )}
              <ChipGroup chips={['NIST', 'STIG']} />
            </>
          }
          {...props}
        />
        <SimpleRow
          checkboxFiled="isRemoveNonFipsMacs"
          label={i18n.t('Remove non-FIPS Message Authentication Codes')}
          helper={
            <>
              {i18n.t(
                'Select to turn off non-FIPS-compliant Message Authentication Codes (MACs). Applies to both client and server.',
              )}
              <ChipGroup chips={['NIST', 'STIG']} />
            </>
          }
          {...props}
        />
        <SimpleRow
          checkboxFiled="isSecureKeyExchangeAlgorithms"
          label={i18n.t('Use secure key exchange algorithms')}
          helper={
            <>
              {i18n.t(
                'Select to use secure key exchange algorithms for the SSH server.',
              )}
              <ChipGroup chips={['NIST', 'STIG']} />
            </>
          }
          {...props}
        />
      </Setting.Rows>
    </Setting.Card>
  );
};

export default GeneralCard;
