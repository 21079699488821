import initialState from '../../../../initial-state';
import WifiService from '../../../wifi-service';

const proxyKeys = WifiService.keys.proxy;

const proxy = (data) => {
  const { proxy: proxyData = null } = data;

  if (!proxyData) {
    return initialState.proxy;
  }

  const { proxyType, isEnabled, manual, automatic } = proxyKeys;

  const { isManaged = false, settings = {} } = proxyData;
  const {
    ProxyType,
    ProxyServer,
    ProxyServerPort,
    ProxyUsername,
    ProxyPassword,
    ProxyPACURL,
    ProxyPACFallbackAllowed,
  } = settings;

  const isProxyServerPort = !Number.isNaN(parseInt(ProxyServerPort, 10));
  return {
    [isEnabled]: isManaged || false,
    [proxyType]: ProxyType || WifiService.proxyTypes.NONE,
    [automatic.proxyPacUrl]: ProxyPACURL || '',
    [automatic.allowProxyPacFallback]: ProxyPACFallbackAllowed || false,
    [manual.proxyServer]: ProxyServer || '',
    [manual.port]: isProxyServerPort ? String(ProxyServerPort) : '',
    [manual.proxyUsername]: ProxyUsername || '',
    [manual.proxyPassword]: ProxyPassword || '',
  };
};

export default proxy;
