import { func, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { AsyncMultiSelectSearch } from '@kandji-inc/bumblebee';

const MultiSelectApi = ({
  fieldKey,
  client,
  searchParam,
  getValue,
  onChange,
  getLabel,
  ...rest
}) => {
  const values = getValue(fieldKey);

  const [formattedValues, setFormattedValues] = useState([]);

  // Every time we receive new `values`, we must retrieve their display names
  useEffect(() => {
    const getFormattedValues = async () => {
      if (values?.length) {
        const params = { id__in: values.join(','), sizePerPage: values.length };
        const resp = await client.get(params);

        setFormattedValues(
          resp.data.results.map((option) => ({
            label: getLabel(option),
            value: option.id,
          })),
        );
      } else {
        setFormattedValues([]);
      }
    };
    getFormattedValues();
  }, [values]);

  // Load the available API options given an input value
  const loadOptions = (input) => {
    const params = { [searchParam]: input };
    return client.get(params).then((res) =>
      res.data.results.map((option) => ({
        label: getLabel(option),
        value: option.id,
      })),
    );
  };

  return (
    <AsyncMultiSelectSearch
      className="k-rule-api-multi-select"
      {...rest}
      values={formattedValues}
      loadOptions={loadOptions}
      onChange={onChange}
    />
  );
};

MultiSelectApi.propTypes = {
  fieldKey: string.isRequired,
  client: object.isRequired,
  searchParam: string.isRequired,
  getValue: func.isRequired,
  onChange: func.isRequired,
  getLabel: func.isRequired,
};

export default MultiSelectApi;
