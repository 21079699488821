import PropTypes from 'prop-types';
import React, { useState } from 'react';
import AwesomeTableHeader from './AwesomeTableHeader';

const AwesomeTableHeaderContainer = ({
  headers,
  onDragEnd,
  onResize,
  sortFunc,
  ordering,
}) => {
  const [isDragDisabled, setIsDragDisabled] = useState(false);
  const pinnedHeaders = headers.filter((h) => h.pinned);
  const draggableHeaders = headers.filter((h) => !h.pinned);
  return (
    <AwesomeTableHeader
      headers={headers}
      onDragEnd={onDragEnd}
      onResize={onResize}
      isDragDisabled={isDragDisabled}
      setIsDragDisabled={setIsDragDisabled}
      pinnedHeaders={pinnedHeaders}
      draggableHeaders={draggableHeaders}
      sortFunc={sortFunc}
      ordering={ordering}
    />
  );
};

AwesomeTableHeaderContainer.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDragEnd: PropTypes.func.isRequired,
  onResize: PropTypes.func.isRequired,
  sortFunc: PropTypes.func,
  ordering: PropTypes.string.isRequired,
};

AwesomeTableHeaderContainer.defaultProps = {
  sortFunc: null,
};

export default AwesomeTableHeaderContainer;
