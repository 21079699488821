import type { DropdownMenuOption } from '@kandji-inc/nectar-ui';
import {
  Box,
  DropdownMenuPrimitives as DropdownMenu,
  Flex,
  Tooltip,
} from '@kandji-inc/nectar-ui';
import type { UseMutationResult } from '@tanstack/react-query';
import { i18n } from 'i18n';
import React from 'react';
import PrismDeleteViewConfirmModal from './components/PrismDeleteViewConfirmModal';
import TableHeaderIconButton from './components/TableHeaderIconButton';

type PrismSavedViewsActionMenuProps = {
  appliedSavedView?: any;
  setRenameViewOpen: (isOpen) => void;
  deleteView: UseMutationResult<void, Error, void, unknown>;
};

const PrismSavedViewsActionMenu = ({
  appliedSavedView,
  setRenameViewOpen,
  deleteView,
}: PrismSavedViewsActionMenuProps) => {
  const [isConfirmNavigationModalOpen, setIsConfirmNavigationModalOpen] =
    React.useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  const onConfirmNavModalClose = () => setIsConfirmNavigationModalOpen(false);
  const onConfirmNavModalConfirm = () => {
    setIsConfirmNavigationModalOpen(false);
    setIsDropdownOpen(false);
    if (!appliedSavedView || !appliedSavedView?.id) {
      return;
    }
    deleteView.mutate();
  };

  const dropdownMenuActions: DropdownMenuOption[] = [
    {
      label: i18n.t('Edit view name'),
      onClick: () => setRenameViewOpen(true),
    },
    {
      label: i18n.t('Open view in new tab'),
      onClick: () => {
        window.open(window.location.href, '_blank');
      },
    },
    {
      id: 'delete',
      label: i18n.t('Delete view'),
      onClick: () => setIsConfirmNavigationModalOpen(true),
    },
  ];
  return (
    <>
      <PrismDeleteViewConfirmModal
        isOpen={isConfirmNavigationModalOpen}
        onClose={onConfirmNavModalClose}
        onConfirm={onConfirmNavModalConfirm}
        onOpenChange={setIsConfirmNavigationModalOpen}
        viewName={appliedSavedView?.name}
        categoryName={appliedSavedView?.category}
      />
      <Box>
        <DropdownMenu.Root
          open={isDropdownOpen}
          onOpenChange={setIsDropdownOpen}
          modal={false}
        >
          <Tooltip content={i18n.t('More actions')} side="top">
            <DropdownMenu.Trigger asChild>
              <TableHeaderIconButton
                role="button"
                name={i18n.t('Export')}
                icon="ellipsis"
              />
            </DropdownMenu.Trigger>
          </Tooltip>
          <DropdownMenu.Content
            css={{
              width: '240px',
              zIndex: 3,
            }}
            align="end"
          >
            {dropdownMenuActions.map((menuItem) => (
              <Flex
                css={{
                  py: '6px',
                  px: '$3',
                  cursor: 'pointer',
                  '&:hover': { background: '$neutral10' },
                  color: menuItem.id === 'delete' ? '$red50' : '',
                }}
                key={menuItem.label}
                onClick={menuItem.onClick}
              >
                {menuItem.label}
              </Flex>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </Box>
    </>
  );
};
export default PrismSavedViewsActionMenu;
