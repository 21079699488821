/* istanbul ignore file */
export const FACETS = [
  {
    value: 'composedMessage',
    label: 'Message',
  },
  {
    value: 'level',
    label: 'Level',
  },
  {
    value: 'category',
    label: 'Category',
  },
  {
    value: 'sender',
    label: 'Sender',
  },
  {
    value: 'configId',
    label: 'Predicate',
  },
  {
    value: 'type',
    label: 'Type',
  },
];

export const OPS = [
  {
    value: 'match_phrase',
    label: 'Is Exactly Like',
  },
  {
    value: 'prefix',
    label: 'Starts With',
  },
  {
    value: 'match',
    label: 'Is Like',
  },
  {
    value: 'fuzzy',
    label: 'Is Like (Fuzzy)',
  },
  {
    value: 'wildcard',
    label: 'Contains',
  },
];
