import {
  getRunsOnFromModel,
  getSelectedDevicesFromRunsOn,
} from 'src/features/library-items/data-service/library-item/devices';
import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import configs from '../../../library/library-item-configurations/items/profile';
import { configurationsModelProperty } from '../initial-state';

async function transformFromNewApi(apiData, preset) {
  const result = {
    ...preset,
    isActive: apiData.active,
    settings: {
      [configurationsModelProperty]: apiData.data,
    },
    rules: apiData.rules,
  };

  return result;
}

async function transformFromApi(apiData) {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const commonData = {
    id: apiData.id,
    name: apiData.name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
    devices: getSelectedDevicesFromRunsOn(apiData),
    rules: apiData.rules,
  };

  const transformer = transformFromNewApi;

  const result = await transformer(apiData, commonData);

  return {
    ...apiData,
    data: result,
  };
}

function transformToNewApi(model) {
  const toSend = {
    name: model.name,
    active: model.isActive,
    blueprints: model.selectedBlueprints.map((b) => b.value),
    is_all_blueprints: model.isAllBlueprints,
    data: model.settings[configurationsModelProperty].map(
      ({ ForceTLS, IPAddress, Port, PrinterName, ResourcePath }) => ({
        ForceTLS,
        IPAddress,
        Port,
        PrinterName,
        ResourcePath,
      }),
    ),
    rules: model.rules || null,
  };

  // Populate `runs_on_*` fields
  const runsOn = getRunsOnFromModel(model);
  Object.keys(runsOn).forEach((runsOnKey) => {
    toSend[runsOnKey] = runsOn[runsOnKey];
  });

  // Add necessary properties when creating new Profile:
  if (!model.id) {
    const itemConfig = configs.AirPrint;

    toSend.type = itemConfig.type;
    toSend.identifier = itemConfig.identifier;
  }

  return toSend;
}

function transformToApi(model) {
  const transformer = transformToNewApi;

  return transformer(model);
}

export { transformToApi, transformFromApi };
