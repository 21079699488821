import { newIntegrationsAxios } from 'app/api/base';
import type { AxiosResponse } from 'axios';
import type {
  DevicePlatformRequestBody,
  DevicePlatformResponse,
  ODTUpdateBody,
} from '../types';
import { oktaDeviceTrustSchema } from '../types/schema';

class OktaDeviceTrustService {
  service: NonNullable<typeof newIntegrationsAxios>;

  readonly resource: string;

  constructor() {
    this.resource = 'okta-device-trust/';

    /* istanbul ignore if */
    if (!newIntegrationsAxios) {
      throw new Error('new integration API not found');
    }

    this.service = newIntegrationsAxios;
  }

  public async createDevicePlatform(
    body: DevicePlatformRequestBody,
  ): Promise<AxiosResponse<DevicePlatformResponse> | null> {
    return this.service(`${this.resource}`).post(body);
  }

  public async verifyOkta(oktaURL: string, token: string) {
    return this.service(
      `okta-device-trust/verify/?oktaURL=${oktaURL}&token=${token}`,
    ).get();
  }

  public async getOktaDeviceTrustDetails() {
    const res = await this.service(this.resource).get();

    return oktaDeviceTrustSchema.safeParse(res.data);
  }

  public async getOktaVerifyLibraryItem() {
    return this.service(`${this.resource}library-item-verify/`).get();
  }

  public async updateOktaDeviceTrust(body: ODTUpdateBody, id: string) {
    return this.service(`${this.resource}${id}/`).patch(body);
  }

  public async deleteOktaDeviceTrust(id: string) {
    return this.service(`${this.resource}delete-integration/${id}/`).delete(id);
  }
}

const oktaDeviceTrustService = new OktaDeviceTrustService();

export default OktaDeviceTrustService;

export { oktaDeviceTrustService };
