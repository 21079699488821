import styled from 'styled-components';

const Input = styled('input')`
  margin-right: 10px;
  appearance: none;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  height: 22px;
  width: 22px;
  border-radius: 22px;
  cursor: pointer;
  position: relative;
  &:checked {
    &:before {
      content: '';
      position: absolute;
      height: 11px;
      width: 11px;
      border-radius: 11px;
      background-color: #0f0f0f;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &:active,
  &:focus {
    outline: none;
  }
  &:disabled {
    background: #f5f5f5;
    cursor: auto;
  }
`;

const Label = styled('label')`
  font-size: 14px;
  line-height: 17px;
  color: #333;
  margin: 4px 0 0 0;
  cursor: pointer;

  &.disabled {
    cursor: auto;
  }
`;

const Radio = {
  Input,
  Label,
};

export default Radio;
