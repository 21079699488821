/* istanbul ignore file */
import { Chip, Flex, Text, Tooltip } from '@kandji-inc/nectar-ui';
import { useEffect, useRef, useState } from 'react';
import { highlightedText } from 'src/pages/ADEListView/utils/highlightText';

const OverflowingChipList = (props: {
  chips: string[];
  containerWidth?: number;
  tooltipWidth?: number;
  searchTerm?: string | undefined;
}) => {
  const {
    chips,
    containerWidth = 400,
    tooltipWidth = 256,
    searchTerm = '',
  } = props;
  const [visibleChips, setVisibleChips] = useState(chips.length);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      let totalWidth = 0;
      let visibleCount = 0;

      for (let i = 0; i < chips.length; i++) {
        // @ts-ignore
        const chipWidth = container?.children[i]?.getBoundingClientRect().width;
        if (totalWidth + chipWidth > containerWidth) {
          break;
        }
        totalWidth += chipWidth;
        if (i < chips.length - 1) {
          totalWidth += 4; // Add gap size for all but the last chip, this maps to the "xs" gap size on the containerRef
        }
        visibleCount++;
      }

      setVisibleChips(visibleCount);
    }
  }, []);

  return (
    <Flex gap="xs">
      <Flex
        ref={containerRef}
        gap="xs"
        css={{ maxWidth: `${containerWidth}px` }}
      >
        {chips.slice(0, visibleChips).map((chip, index) => (
          <Chip
            key={index}
            label={
              <Text css={{ fontWeight: '$medium' }}>
                {highlightedText(chip, searchTerm)}
              </Text>
            }
            size="compact"
          />
        ))}
      </Flex>
      {visibleChips < chips.length && (
        <Tooltip
          side="bottom"
          theme="light"
          css={{ zIndex: 2, padding: '$3 $4', maxWidth: `${tooltipWidth}px` }}
          content={
            <Flex gap="sm" flow="row" wrap="wrap">
              {chips.slice(visibleChips).map((chip, index) => (
                <Chip
                  key={index}
                  label={
                    <Text css={{ fontWeight: '$medium' }}>
                      {highlightedText(chip, searchTerm)}
                    </Text>
                  }
                  size="compact"
                />
              ))}
            </Flex>
          }
        >
          <Chip label={`+${chips.length - visibleChips}`} size="compact" />
        </Tooltip>
      )}
    </Flex>
  );
};

export { OverflowingChipList };
