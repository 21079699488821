import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { i18n } from 'src/i18n';

import {
  Button,
  Flex,
  NoContent,
  Select,
  Table,
  TextInput,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import { Box, Checkbox, styled } from '@kandji-inc/nectar-ui';
import SystemExtensionService from '../../../service/system-extension-service';

const ExtensionsTableContext = createContext({});

const StyledTable = styled(Table, {
  '& .b-table__cell:last-child:has(button)': {
    justifyContent: 'center',
    alignItems: 'center',
  },
});

function getColumns({ onDelete, onUpdate }) {
  return [
    {
      header: 'ALLOWED SYSTEM EXTENSION',
      fieldName: 'BundleId',
      HeaderCell: () => <span>{i18n.t('Allowed system extension')}</span>,
      Cell: ({ item, index }) => {
        const { isDisabled, validationDep, refs } = useContext(
          ExtensionsTableContext,
        );

        return (
          <div className="pt-2 pb-2 flex-fill" ref={refs[index]}>
            <TextInput
              disabled={isDisabled}
              id={`bundle_id_${item.id}`}
              value={item.BundleId}
              placeholder="com.symantec.mes.systemextension"
              onChange={(e) => onUpdate('BundleId', item.id, e.target.value)}
              validator={(v) => [
                {
                  message: i18n.t('Required.'),
                  invalid: () => !v?.length,
                  trigger: ['onMount', 'onBlur', validationDep],
                },
              ]}
              onInvalidate={(isInvalid) =>
                onUpdate('BundleId', item.id, item.BundleId, isInvalid)
              }
            />
          </div>
        );
      },
    },
    {
      header: 'Name',
      fieldName: 'Name',
      HeaderCell: () => (
        <span>
          {i18n.ut('Name <span class="b-txt-light">(optional)</span>')}
        </span>
      ),
      Cell: ({ item }) => {
        const { isDisabled } = useContext(ExtensionsTableContext);

        return (
          <div className="pt-2 pb-2 flex-fill">
            <TextInput
              disabled={isDisabled}
              id={`display_name_${item.id}`}
              isOptional
              value={item.Name}
              placeholder={i18n.t('Symantec System Extension')}
              onChange={(e) => onUpdate('Name', item.id, e.target.value)}
            />
          </div>
        );
      },
    },
    {
      header: 'Can remove itself?',
      fieldName: 'canRemoveItself',
      ratio: 0.4,
      HeaderCell: () => (
        <span>
          {i18n.ut(
            'Can remove itself? <span class="b-txt-light">(optional)</span>',
          )}
        </span>
      ),
      Cell: ({ item }) => {
        const { isDisabled } = useContext(ExtensionsTableContext);

        return (
          <Box css={{ paddingTop: '18px' }}>
            <Checkbox
              size="2"
              disabled={isDisabled}
              data-testid={`remove_itself_${item.id}`}
              checked={item.canRemoveItself}
              onCheckedChange={(checked) =>
                onUpdate('canRemoveItself', item.id, checked)
              }
            />
          </Box>
        );
      },
    },
    {
      header: 'User can turn off (macOS 15+)',
      fieldName: 'userCanTurnOff',
      ratio: 0.4,
      HeaderCell: () => (
        <span>
          {i18n.ut(
            'User can turn off <span class="b-txt-light">(macOS 15+)</span>',
          )}
        </span>
      ),
      Cell: ({ item }) => {
        const { isDisabled } = useContext(ExtensionsTableContext);

        return (
          <Box css={{ paddingTop: '18px' }}>
            <Checkbox
              size="2"
              disabled={isDisabled}
              data-testid={`can_turn_off_${item.id}`}
              checked={item.userCanTurnOff}
              onCheckedChange={(checked) =>
                onUpdate('userCanTurnOff', item.id, checked)
              }
            />
          </Box>
        );
      },
    },
    {
      header: '',
      fieldName: 'ellipsis',
      ratio: 0.05,
      Cell: ({ item }) => {
        const { isDisabled } = useContext(ExtensionsTableContext);

        return (
          <Button
            isDisabled={isDisabled}
            style={{
              color: 'var(--color-neutral-50)',
              alignSelf: 'flex-start',
              marginTop: '9px',
            }}
            kind="link"
            icon="trash-can"
            onClick={() => onDelete(item)}
          />
        );
      },
    },
  ];
}

const ExtensionsTable = (props) => {
  const {
    extensions,
    onDelete,
    onUpdate,
    onAddRow,
    isDisabled,
    validationDep,
    updateTeams,
  } = props;

  const extensionsIdentifiers = new Array(20).fill(0).map((_, idx) => idx);
  const { refs, onInvalidate } = useInputsValidators(
    extensionsIdentifiers,
    updateTeams,
  );
  useRemoveValidationOnUnmount(extensionsIdentifiers, onUpdate);

  useEffect(() => {
    if (extensions) {
      extensionsIdentifiers.forEach((n) => {
        const curr = extensions[n];
        onInvalidate(n)(curr?.isInvalid);
      });
    }
  }, [extensions]);

  const columns = useMemo(
    () => getColumns({ isDisabled, onDelete, onUpdate, onAddRow }),
    [isDisabled],
  );

  const AddButton = () => (
    <Button
      isDisabled={isDisabled}
      kind="link"
      icon="circle-plus"
      onClick={onAddRow}
    >
      {i18n.t('Add More')}
    </Button>
  );

  return (
    <ExtensionsTableContext.Provider
      value={{ isDisabled, validationDep, refs }}
    >
      <StyledTable
        isLoading={false}
        columns={columns}
        data={extensions}
        noDataMessage={
          <Flex justify="center" align="center" direction="col">
            <NoContent
              className="b-pad"
              header={i18n.t('Allowed Extensions have not been configured')}
              text=""
            />
            <AddButton />
          </Flex>
        }
      />
      {extensions.length > 0 && (
        <div className="b-pad">
          <AddButton />
        </div>
      )}
    </ExtensionsTableContext.Provider>
  );
};

export default ExtensionsTable;
