/* istanbul ignore file */
import { Box, Flex, Text, styled } from '@kandji-inc/nectar-ui';
import React from 'react';
import { ReactSVG } from 'react-svg';
import { i18n } from 'src/i18n';
import personCircle from '../../assets/person-circle.svg';
import power from '../../assets/power.svg';
import webloginicon from '../../assets/weblogin.svg';
import apple from './assets/apple.png';
import wifi from './assets/wifi.png';

const ApplePowerMenu = styled(Flex, {
  position: 'absolute',
  top: '20px',
  right: '15px',
  width: '150px',
  borderRadius: '6px',
  backgroundColor: '#797268',
  outline: '0.5px solid #030302',
  boxShadow: 'rgba(0, 0, 0, 0.1) -1px 11px 12px',
  padding: '4px 0',
  color: '#fff',
});

const ApplePowerMenuSection = styled(Flex, {
  padding: '0 16px',
});

const OSPowerIcon = styled(Box, {
  '& div': {
    display: 'flex',
    alignItems: 'center',
    width: '18px',
    height: '14px',
  },
  '& svg': {
    width: '18px',
    height: '14px',
  },
  '& path': {
    fill: '#fff',
  },
});

export default function Header(props) {
  const { setting, isNewOs, isWebLoginTab, isWebLoginModeSelected } = props;

  const hasPowerButtonMenu =
    isNewOs &&
    (setting.isRestartButton ||
      setting.isShutdownButton ||
      setting.isSleepButton);
  return (
    <>
      <img
        style={{ display: 'none' }}
        draggable="false"
        src={apple}
        alt="apple"
        className="k-klog-apple"
      />
      <div className="k-klog-pheader">
        <div className="k-klog-pheader-inner">
          {!isNewOs && (
            <>
              <img
                hidden={!setting.isNetworkManager}
                draggable="false"
                src={wifi}
                alt="network"
              />

              <p>
                8<span className="k-klog-gg">:</span>39 AM
              </p>
            </>
          )}
          {isNewOs && (
            <img
              hidden={!setting.isNetworkManager}
              draggable="false"
              src={wifi}
              alt="network"
            />
          )}
          {isNewOs && !isWebLoginTab && isWebLoginModeSelected && (
            <img
              src={webloginicon}
              alt="person"
              draggable="false"
              style={{ top: 0, scale: '75%' }}
            />
          )}
          {isNewOs && isWebLoginTab && (
            <img
              src={personCircle}
              alt="person"
              draggable="false"
              style={{ top: 0 }}
            />
          )}
          {isNewOs && (
            <OSPowerIcon>
              <ReactSVG src={power} style={{ top: 0, fill: '#fff' }} />
            </OSPowerIcon>
          )}
        </div>

        {hasPowerButtonMenu && (
          <ApplePowerMenu flow="column" gap="xs">
            {setting.isRestartButton && (
              <ApplePowerMenuSection>
                <Text size="1">{i18n.t('Restart')}</Text>
              </ApplePowerMenuSection>
            )}
            {setting.isShutdownButton && (
              <ApplePowerMenuSection>
                <Text size="1">{i18n.t('Shut Down')}</Text>
              </ApplePowerMenuSection>
            )}
            {setting.isSleepButton && (
              <ApplePowerMenuSection>
                <Text size="1">{i18n.t('Sleep')}</Text>
              </ApplePowerMenuSection>
            )}
          </ApplePowerMenu>
        )}
      </div>
    </>
  );
}
