import { useIsChanged } from '@kandji-inc/bumblebee';
import { useEffect } from 'react';

export const useValidate = ({ error, onInvalidate, validationDep, reset }) => {
  useEffect(() => {
    onInvalidate(error);
  }, [error]);
  const canShowError = useIsChanged(validationDep, reset);
  return canShowError;
};
