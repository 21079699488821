import { api } from 'app/api/base';
import { URL_COMPANY_INFO } from 'app/api/urls';
import { downloadFile } from '../../common/helpers';

export const getIntegration = () =>
  api('apns/pushcert')
    .get()
    .then((res) => res.data);

export const deletePushCert = async () =>
  api('apns/pushcert')
    .delete()
    .then((res) => res.data);

export const getDepIntegration = () =>
  api('integrations/dep/account/info/')
    .get()
    .then((res) => res.data);

export const getAppleDeviceInformation = () =>
  api('dep/devices/counts/')
    .get()
    .then((res) => res.data);

export const getPushCSR = () =>
  api('apns/csr')
    .get()
    .then((res) => downloadFile(res.data, 'kandji-mdm.csr'));

export const getPushDepPEM = () =>
  api('integrations/dep/certificate/download/')
    .get()
    .then((res) => downloadFile(res.data, 'kandji-auto-enroll.pem'));

export const uploadPushCSR = (data, params = {}) =>
  api('apns/csr')
    .post(data, {
      params,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Content-Disposition': `attachment; filename=${data.name}`,
      },
    })
    .then((res) => res.data);

export const uploadDepSToken = (data, params = {}) =>
  api('integrations/dep/stoken/upload/')
    .post(data, {
      params,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Content-Disposition': `attachment; filename=${data.name}`,
      },
    })
    .then((res) => res.data);

export const getCompanyInfo = () =>
  api(URL_COMPANY_INFO)
    .get()
    .then((res) => res.data);

export const saveDEPAccountDefaults = async (data) =>
  api('integrations/dep/account/')
    .put(data)
    .then((res) => res.data);

export const disconnectAutoEnroll = async () =>
  api('integrations/dep/account/')
    .delete()
    .then((res) => res.data);

export const syncDevices = async () =>
  api('dep/devices/sync/')
    .post()
    .then((res) => res.data);

export const uploadVPPsToken = (data) => api('/vpp/stoken/upload/').post(data);
export const syncVPPData = () => api('/vpp/sync-now/').post();

export const removeAppsAndBooks = (data) =>
  api('/vpp/remove/')
    .post(data)
    .then((res) => res.data);

export const reclaimAppsAndBooks = () =>
  api('/vpp/reclaim/')
    .post()
    .then((res) => res.data);
