import { Code } from '@kandji-inc/nectar-ui';
import { createColumnHelper } from '@tanstack/react-table';
import {
  formatDate,
  getDeviceCell,
} from 'src/features/visibility/prism/utils/column-utils';
import type { PulseLoggedInUsersDeviceSchema } from 'src/features/visibility/pulse/types/pulse.types';
import type { PulseCheckDetailColumns } from '../utils/pulse-column-types';
import { PulseResultsTable } from './PulseResultsTable';

interface User {
  full_name: string;
  username: string;
  is_admin: boolean;
  is_hidden: boolean;
  node_location: string;
  uid: number;
  home_directory: string;
}

interface Results {
  user: User;
  type: string;
  timestamp: number;
}

interface PulseLoggedInUsersResultsColumns extends PulseCheckDetailColumns {
  results: Results;
}

const columnHelper = createColumnHelper<PulseLoggedInUsersResultsColumns>();

/*
  TODO:
  - Missing:
    - Session ID: session_id
    - Original Node Path: original_node
    - Auto Admin: is_auto_admin
    - Host: host
*/

const loggedInUsersColumns = [
  columnHelper.accessor((row) => row.device__name, {
    id: 'device__name',
    cell: (info) => getDeviceCell(info),
    header: () => 'Device',
  }),

  columnHelper.accessor((row) => row.device__user_name, {
    id: 'device__user_name',
    cell: (row) => row.getValue(),
    header: () => 'Device user',
  }),
  columnHelper.accessor((row) => row.results?.user?.full_name, {
    id: 'full_name',
    cell: (row) => row.getValue(),
    header: () => 'Full name',
  }),
  columnHelper.accessor((row) => row.results?.user?.username, {
    id: 'username',
    cell: (row) => row.getValue(),
    header: () => 'Username',
  }),
  columnHelper.accessor((row) => row.results?.user?.is_admin, {
    id: 'is_admin',
    cell: (row) => {
      const value = row.getValue();
      return value ? 'Admin' : 'Standard';
    },
    header: () => 'User type',
  }),
  columnHelper.accessor((row) => row.results?.user?.is_hidden, {
    id: 'is_hidden',
    cell: (row) => {
      const value = row.getValue();
      return value ? 'Yes' : 'No';
    },
    header: () => 'Hidden user',
  }),
  columnHelper.accessor((row) => row.results?.type, {
    id: 'type',
    cell: (row) => row.getValue(),
    header: () => 'Session type',
  }),
  columnHelper.accessor((row) => row.results?.timestamp, {
    id: 'timestamp',
    cell: (row) =>
      row.getValue() ? convertUnixToString(row.getValue()) : null,
    header: () => 'Login time',
  }),
  columnHelper.accessor((row) => row.updated_at, {
    id: 'updated_at',
    cell: (row) => formatDate(row.getValue()),
    header: () => 'Last Collected',
  }),
  columnHelper.accessor((row) => row.results?.user?.home_directory, {
    id: 'home_directory',
    cell: (row) => (row.getValue() ? <Code>{row.getValue()}</Code> : null),
    header: () => 'Home directory location',
  }),
];

function convertUnixToString(unix: number | string) {
  const date = new Date(unix);
  const formattedDate = `${
    date.getMonth() + 1
  }/${date.getDate()}/${date.getFullYear()} at ${date.toLocaleTimeString()}`;

  return formattedDate;
}

export const PulseLoggedInUsersResults = ({
  jobDevicesData,
  isLoading,
  onRerunJob,
}: {
  jobDevicesData: PulseLoggedInUsersDeviceSchema[];
  isLoading: boolean;
  onRerunJob: () => void;
}) => {
  return (
    <PulseResultsTable
      jobDevicesData={jobDevicesData}
      isLoading={isLoading}
      columns={loggedInUsersColumns}
      onRerunJob={onRerunJob}
    />
  );
};
