/* istanbul ignore file */
import { Button } from '@kandji-inc/bumblebee';
import PropTypes from 'prop-types';
import React from 'react';

import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth0 } from '../../../auth0';

const StyledButton = styled(Button)`
  padding: 12px 150px;
  margin-bottom: 28px;
`;

const LoginButton = ({ disabled, text }) => {
  const { loginWithRedirect } = useAuth0();
  const { state } = useLocation();

  return (
    <StyledButton
      onClick={() =>
        loginWithRedirect({
          appState: {
            returnTo: state?.from,
          },
        })
      }
      disabled={disabled}
    >
      {text}
    </StyledButton>
  );
};

LoginButton.propTypes = {
  disabled: PropTypes.bool,
  text: PropTypes.string,
};

LoginButton.defaultProps = {
  disabled: true,
  text: 'Log in',
};

export default LoginButton;
