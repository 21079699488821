import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import { getIconName } from '../../components/PrismNav/prismNavUtils';
import {
  NULL_VALUE_N_DASH,
  ON_OFF_CELL_FILTER_OPTIONS,
  OnOffCell,
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  capitalizeFirstLetter,
  getFilteredDisabled as filterDisabled,
  getEnumFilterOptions,
} from '../column-utils';
import { getScharedColumns } from './getSharedColumns';

export const getStartupSettingsColumns = ({
  startupSettingsSchema,
}): {
  columnDefs: any[];
  pinnedColumns: string[];
  alwaysHiddenColumns: string[];
} => {
  const { properties } = startupSettingsSchema.schema;
  const startupSettingsColumnHelper =
    createColumnHelper<z.infer<typeof properties>>();

  const columnDefs = [
    startupSettingsColumnHelper.accessor(
      (row) => row[`${startupSettingsSchema.uri}.sip`],
      {
        id: `${startupSettingsSchema.uri}.sip`,
        cell: OnOffCell,
        meta: {
          displayName: properties.sip.title,
          displayIcon: getIconName(startupSettingsSchema.uri),
          filterType: 'boolean',
          filterOptions: [...ON_OFF_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),
    startupSettingsColumnHelper.accessor(
      (row) => row[`${startupSettingsSchema.uri}.ssv`],
      {
        id: `${startupSettingsSchema.uri}.ssv`,
        cell: OnOffCell,
        meta: {
          displayName: properties.ssv.title,
          displayIcon: getIconName(startupSettingsSchema.uri),
          filterType: 'boolean',
          filterOptions: [...ON_OFF_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    startupSettingsColumnHelper.accessor(
      (row) => row[`${startupSettingsSchema.uri}.secure_boot_level`],
      {
        id: `${startupSettingsSchema.uri}.secure_boot_level`,
        cell: (info) => {
          const value = info.getValue();
          if (value === null) {
            return NULL_VALUE_N_DASH;
          }
          if (value) {
            return capitalizeFirstLetter(value);
          }
          return value;
        },
        meta: {
          displayName: properties.secure_boot_level.title,
          displayIcon: getIconName(startupSettingsSchema.uri),
          filterType: 'enum',
          filterOptions: getEnumFilterOptions(
            startupSettingsSchema.schema?.definitions.SecureBootLevel.enum ||
              [],
          ),
          filterIcon: 'list-check',
          filterDisabled,
        },
      },
    ),

    startupSettingsColumnHelper.accessor(
      (row) => row[`${startupSettingsSchema.uri}.external_boot_level`],
      {
        id: `${startupSettingsSchema.uri}.external_boot_level`,
        cell: (info) => {
          const value = info.getValue();
          if (value === null) {
            return NULL_VALUE_N_DASH;
          }
          if (value) {
            return capitalizeFirstLetter(value);
          }
          return value;
        },
        meta: {
          displayName: properties.external_boot_level.title,
          displayIcon: getIconName(startupSettingsSchema.uri),
          filterType: 'enum',
          filterOptions: getEnumFilterOptions(
            startupSettingsSchema.schema?.definitions.ExternalBootLevel.enum ||
              [],
          ),
          filterIcon: 'list-check',
          filterDisabled,
        },
      },
    ),

    startupSettingsColumnHelper.accessor(
      (row) => row[`${startupSettingsSchema.uri}.bootstrap_token_escrowed`],
      {
        id: `${startupSettingsSchema.uri}.bootstrap_token_escrowed`,
        cell: YesNoCell,
        meta: {
          displayName: properties.bootstrap_token_escrowed.title,
          displayIcon: getIconName(startupSettingsSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    startupSettingsColumnHelper.accessor(
      (row) => row[`${startupSettingsSchema.uri}.bootstrap_token_auth`],
      {
        id: `${startupSettingsSchema.uri}.bootstrap_token_auth`,
        cell: YesNoCell,
        meta: {
          defaultHidden: true,
          displayName: properties.bootstrap_token_auth.title,
          displayIcon: getIconName(startupSettingsSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    startupSettingsColumnHelper.accessor(
      (row) => row[`${startupSettingsSchema.uri}.kext_requires_bst`],
      {
        id: `${startupSettingsSchema.uri}.kext_requires_bst`,
        cell: YesNoCell,
        meta: {
          defaultHidden: true,
          displayName: properties.kext_requires_bst.title,
          displayIcon: getIconName(startupSettingsSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    startupSettingsColumnHelper.accessor(
      (row) => row[`${startupSettingsSchema.uri}.software_update_requires_bst`],
      {
        id: `${startupSettingsSchema.uri}.software_update_requires_bst`,
        cell: YesNoCell,
        meta: {
          defaultHidden: true,
          displayName: properties.software_update_requires_bst.title,
          displayIcon: getIconName(startupSettingsSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    startupSettingsColumnHelper.accessor(
      (row) => row[`${startupSettingsSchema.uri}.any_signed_os`],
      {
        id: `${startupSettingsSchema.uri}.any_signed_os`,
        cell: YesNoCell,
        meta: {
          defaultHidden: true,
          displayName: properties.any_signed_os.title,
          displayIcon: getIconName(startupSettingsSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    startupSettingsColumnHelper.accessor(
      (row) => row[`${startupSettingsSchema.uri}.mdm_manages_kext`],
      {
        id: `${startupSettingsSchema.uri}.mdm_manages_kext`,
        cell: YesNoCell,
        meta: {
          defaultHidden: true,
          displayName: properties.mdm_manages_kext.title,
          displayIcon: getIconName(startupSettingsSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    startupSettingsColumnHelper.accessor(
      (row) => row[`${startupSettingsSchema.uri}.user_manages_kext`],
      {
        id: `${startupSettingsSchema.uri}.user_manages_kext`,
        cell: YesNoCell,
        meta: {
          defaultHidden: true,
          displayName: properties.user_manages_kext.title,
          displayIcon: getIconName(startupSettingsSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    ...getScharedColumns(startupSettingsSchema),
  ];

  return { columnDefs, pinnedColumns: [], alwaysHiddenColumns: [] };
};
