import { fileToBase64 } from '@kandji-inc/bumblebee';
import { Summary } from 'features/library-items/template';
import React from 'react';

const Header = ({
  icon,
  isDisabled,
  name,
  isActive,
  isNameEditable,
  isIconEditable,
  setModel,
  isSubmitted,
  placeholder,
  defaultIcon,
  isEditing,
  quickActions,
}) => {
  return (
    <Summary.Header
      icon={icon}
      isDisabled={isDisabled}
      defaultIcon={defaultIcon}
      name={name}
      isActive={isActive}
      isNameEditable={isNameEditable}
      isIconEditable={isIconEditable}
      onChange={async (v) => {
        setModel((p) => ({
          ...p,
          name: v.name,
          isActive: v.isActive,
          isNameValid: v.isValid,
          nameValidationResult: v.validationResult,
          iconFile: v.iconFile,
          iconSrc: v.iconFile === null ? null : p.iconSrc,
        }));

        if (v.iconFile) {
          const iconSrc = `data:${v.iconFile.type};base64, ${await fileToBase64(
            v.iconFile,
          )}`;
          setModel((p) => ({
            ...p,
            iconSrc,
          }));
        }
      }}
      isSubmitted={isSubmitted}
      placeholder={placeholder}
      isEditing={isEditing}
      quickActions={quickActions}
    />
  );
};

export default React.memo(Header);
