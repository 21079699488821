import { updateComputer } from 'src/app/_actions/computer';
import { queryClient } from 'src/app/router';
import { fetchLostModeData } from '../../api';

export default function refreshLostModeData(props, dispatch) {
  const updateQueryData = (lost_mode) => {
    queryClient.setQueryData(
      ['single-computer', props.computer.id],
      (oldData) => {
        if (!oldData) {
          return {
            data: {
              ...props.computer,
              lost_mode,
            },
          };
        }

        return {
          ...oldData,
          data: {
            ...oldData.data,
            lost_mode,
          },
        };
      },
    );
  };

  fetchLostModeData(props.computer.id)
    .then(({ lost_mode }) => {
      updateQueryData(lost_mode);
      dispatch(
        updateComputer({
          ...props.computer,
          lost_mode,
        }),
      );
    })
    .catch(() => {
      updateQueryData(null);
      dispatch(
        updateComputer({
          ...props.computer,
          lost_mode: null,
        }),
      );
    });
}
