import { updateSetting as update } from '@kandji-inc/bumblebee';
import React, { useContext } from 'react';

import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import { i18n } from 'src/i18n';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import NewStatusTab from '../../common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from '../../common/new-status-tab/util';
import LibraryContext from '../../routes/library.context';
import ActivityTab from './activity-tab';
import GeneralCard from './general-card';
import initial from './initial-state';
import { sshProfileService } from './service/ssh-profile-service';
import { transformFromApi, transformToApi } from './service/transformers';
import useSshProfileService from './service/use-ssh-profile-service';

const SshProfilePage = (props) => {
  const { model, setModel, pageState } = props;
  const [validationDep, triggerValidation] = useUniqValue();
  const { itemConfig } = useContext(LibraryContext);
  const itemName = i18n.t(`{name} Configuration`, {
    name: itemConfig.getName(),
  });

  return (
    <LibraryItemPage
      {...props}
      crumb={model.name || itemName}
      summaryInfoProps={{
        name: itemName,
        description: (
          <>
            {itemConfig.getDescription()}
            <a
              href="https://support.kandji.io/support/solutions/articles/72000558735"
              rel="noopener noreferrer"
              target="_blank"
              className="b-alink"
            >
              {i18n.t(`Learn More...`)}
            </a>
          </>
        ),
        publisher: itemConfig.getPublisher(),
        devices: itemConfig.devices,
        requirements: itemConfig.getOsRequirements(),
      }}
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={sshProfileService}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      defaultIcon={itemConfig.icon}
      type={itemConfig.type}
      supportsRules
      supportsDuplication
    >
      <GeneralCard
        isDisabled={pageState.isDisabled}
        setting={model.general}
        update={update('general', setModel)}
        validationDep={validationDep}
      />
    </LibraryItemPage>
  );
};

const SshProfile = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState: initial(),
    useService: useSshProfileService,
  });
  const blueprintConflicts = useBlueprintConflicts();

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <SshProfilePage {...pageProps} blueprintConflicts={blueprintConflicts} />
  );
};

export default SshProfile;
