import { v4 as uuid } from 'uuid';
import EthernetService from './service/ethernet-service';

const { keys } = EthernetService;

const initialState = {
  id: '',
  isActive: true,
  name: '',
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,

  supportsRules: true,

  authentication: {
    [keys.authentication.interface]: EthernetService.interfaces.GLOBAL,
    [keys.authentication.useLoginWindow]: false,
    // EAP fields
    [keys.authentication.acceptedEapTypes.acceptedEapTypes]: [],
    [keys.authentication.acceptedEapTypes.authentication]: null,
    [keys.authentication.acceptedEapTypes.username]: '',
    [keys.authentication.acceptedEapTypes.promptForPassword]:
      EthernetService.promptPasswordValues.ONCE,
    [keys.authentication.acceptedEapTypes.password]: '',
    [keys.authentication.acceptedEapTypes.innerAuthentication]:
      EthernetService.innerAuthentications.MSCHAPv2,
    [keys.authentication.acceptedEapTypes.outerIdentity]: '',
    [keys.authentication.acceptedEapTypes.tlsMinimumVersion]:
      EthernetService.tlsVersions.v1_0,
    [keys.authentication.acceptedEapTypes.tlsMaximumVersion]:
      EthernetService.tlsVersions.v1_2,
    [keys.authentication.acceptedEapTypes.randValue]:
      EthernetService.randValues[3],
    [keys.authentication.acceptedEapTypes.usePac]: false,
    [keys.authentication.acceptedEapTypes.provisionPac]: false,
    [keys.authentication.acceptedEapTypes.provisionPacAnonymously]: false,
    [keys.authentication.acceptedEapTypes.twoFactorAuth]: false,

    [keys.authentication.identityCertificate]:
      EthernetService.identityCertificates.NONE,
    // SCEP fields
    [keys.authentication.scep.url]: '',
    [keys.authentication.scep.name]: '',
    [keys.authentication.scep.challenge]: '',
    [keys.authentication.scep.fingerprint]: '',
    [keys.authentication.scep.subject]: '',
    [keys.authentication.scep.subjectAlternativeNameType]:
      EthernetService.subjectAlternativeNameTypes.NONE,
    [keys.authentication.scep.withSan]: false,
    [keys.authentication.scep.san]: [{ _id: uuid(), type: null, value: '' }],
    [keys.authentication.scep.keySize]: EthernetService.keySizes.s1024,
    [keys.authentication.scep.keyUsage]: EthernetService.keyUsages.NONE,
    [keys.authentication.scep.withRetries]: true,
    [keys.authentication.scep.retries]: '3',
    [keys.authentication.scep.withRetryDelay]: true,
    [keys.authentication.scep.retryDelay]: '10',
    [keys.authentication.scep.disallowKeyExtraction]: true,
    [keys.authentication.scep.allowAccessToApps]: true,
    [keys.authentication.scep.withCertificateExpirationNotification]: true,
    [keys.authentication.scep.certificateExpirationNotification]: '14',
    [keys.authentication.scep.withAutoProfileRedistribution]: true,
    [keys.authentication.scep.autoProfileRedistribution]: '30',
    [keys.authentication.scep.updatedAt]: null,
    // PKCS12 fields
    [keys.authentication.pkcs.file]: '',
    [keys.authentication.pkcs.password]: '',
    [keys.authentication.pkcs.allowAccessToThePrivateKey]: false,
    [keys.authentication.pkcs.preventExtraction]: false,
    // AD CS fields
    [keys.authentication.adcs.name]: '',
    [keys.authentication.adcs.san]: [{ _id: uuid(), type: null, value: '' }],
    [keys.authentication.adcs.withSan]: false,
    [keys.authentication.adcs.keySize]: EthernetService.keySizes.s1024,
    [keys.authentication.adcs.server]: '',
    [keys.authentication.adcs.template]: '',
    [keys.authentication.adcs.subject]: '',
    [keys.authentication.adcs.allowAccessToApps]: false,
    [keys.authentication.adcs.preventExtraction]: false,
    [keys.authentication.adcs.updatedAt]: null,
  },
  trust: {
    [keys.trust.serverCertificateNames]: [{ keyField: '1', value: '' }],
    [keys.trust.specifyCertificates]: false,
    [keys.trust.certificates]: [],
    [keys.trust.specifyServerCertificateNames]: false,
  },
  proxy: {
    [keys.proxy.isEnabled]: false,
    [keys.proxy.proxyType]: EthernetService.proxyTypes.AUTOMATIC,
    [keys.proxy.proxyPacUrl]: '',
    [keys.proxy.allowProxyPacFallback]: false,
    [keys.proxy.proxyServer]: '',
    [keys.proxy.port]: '',
    [keys.proxy.proxyUsername]: '',
    [keys.proxy.proxyPassword]: '',
    [keys.proxy.automatic.allowProxyPacFallback]: false,
  },
};

export default initialState;
