/* istanbul ignore file */
import { Flex } from '@kandji-inc/nectar-ui';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { FACETS, OPS } from '../constants';
import { useLogs } from '../hooks/useLogs';
import { usePagination } from '../hooks/usePagination';
import { Table } from './table';
import './style.css';

export const LogsTable = ({ computers }) => {
  const [filter, setFilter] = React.useState([
    { id: uuidv4(), facet: FACETS[0].value, op: OPS[0].value, value: '' },
  ]);
  const [query, setQuery] = React.useState({});
  const { size, onPaginationChange, from, pagination } = usePagination();
  const { logs, events, count, isLoading, pageCount } = useLogs(
    query,
    from,
    size,
  );

  return (
    <Flex flow="column" mt4 className="agent-logs">
      <Table
        data={{ results: [...logs, ...events], computers }}
        onPaginationChange={onPaginationChange}
        pagination={pagination}
        pageCount={pageCount}
        count={count}
        filter={filter}
        setFilter={setFilter}
        setQuery={setQuery}
        query={query}
        isLoading={isLoading}
      />
    </Flex>
  );
};
