import { number, string } from 'prop-types';
import React, { useState, useEffect } from 'react';

import { Button as BumblebeeButton, Select } from '@kandji-inc/bumblebee';
import { Box, Button, Flex, Text } from '@kandji-inc/nectar-ui';

import { Fields, useBuilder } from '../..';
import { AM_DEVICE_SPECIFIC_OS_VERSION_FACETS } from '../../../constants';
import { findOption } from '../../../utilities';
import {
  DropdownIndicator,
  OptionwithIcon,
  SingleValueWithIcon,
} from './custom-select-components';

const AMOSVersionRule = (props) => {
  const { rule, osIndex, index, aggregator } = props;
  const { input } = rule;

  const { facetMap, form, updateForm } = useBuilder();

  const [label, setLabel] = useState('');

  const [dataType, setDataType] = useState('');

  const [operatorOptions, setOperatorOptions] = useState([]);

  const [valueValidation, setValueValidation] = useState({});

  const { name, key, data_type, supported_operators, validation } =
    facetMap[input] || {};

  const ruleList = form[aggregator];
  const osRuleList = ruleList[index]?.children;

  const inputOptions = AM_DEVICE_SPECIFIC_OS_VERSION_FACETS.map((osType) => ({
    label: facetMap[osType].name,
    value: osType,
    icon: 'sf-desktop-computer',
  }));

  const updateRuleData = () => {
    setLabel(name);
    setDataType(data_type);
    setOperatorOptions(supported_operators);
    setValueValidation(validation);
  };

  const updateInput = (newOs) => {
    const updatedOSRuleList = osRuleList;

    updatedOSRuleList[osIndex] = {
      input: newOs,
      operator: '',
      value: '',
      subject: 'computer',
    };

    ruleList[index].children = updatedOSRuleList;
    updateForm(aggregator, ruleList);
  };

  const updateField = (key, value) => {
    rule[key] = value;

    updateForm(aggregator, ruleList);
  };

  const updateIsInvalid = (isInvalid) => {
    updateField('isInvalid', isInvalid);
  };

  // If the deleted rule is the only rule, clear it. Otherwise, remove it from the list.
  const deleteRule = () => {
    if (osRuleList.length === 1) {
      updateInput(null);
    } else {
      const updatedOSRuleList = osRuleList.filter((_, idx) => idx !== osIndex);

      ruleList[index].children = updatedOSRuleList;
      updateForm(aggregator, ruleList);
    }
  };

  const getValue = (key, options) => {
    const value = rule[key];

    if (options) {
      return findOption(value, options);
    }

    return value;
  };

  const getInputValue = () => (key ? { label, value: key } : null);
  const getOperatorValue = () => getValue('operator', operatorOptions);
  const getValueValue = () => getValue('value');

  useEffect(() => {
    updateRuleData();
  }, [facetMap, rule]);

  return (
    <Flex flow="column">
      <Flex alignItems="start" css={{ gap: '6px' }}>
        <Box className="k-am-os-rule">
          <Select
            className="k-am-os-input"
            placeholder="Select OS"
            options={inputOptions}
            value={getInputValue()}
            onChange={({ value }) => updateInput(value)}
            components={{
              DropdownIndicator,
              SingleValue: SingleValueWithIcon,
              Option: OptionwithIcon,
            }}
            aria-label="Input"
          />

          <Select
            className="k-am-os-operator"
            placeholder="Operator"
            options={operatorOptions}
            value={getOperatorValue()}
            disabled={!getInputValue()}
            onChange={({ value }) => updateField('operator', value)}
            aria-label="Operator"
          />

          <Box className="k-am-os-value">
            <Fields
              fieldKey="value"
              placeholder="Value"
              searchPlaceholder="Search value..."
              type={dataType}
              operator={getOperatorValue()}
              value={getValueValue()}
              updateField={updateField}
              updateIsInvalid={updateIsInvalid}
              validation={valueValidation}
              aria-label="Value"
            />
          </Box>
        </Box>

        <Box css={{ paddingTop: '3px' }}>
          <BumblebeeButton
            theme="error"
            kind="link"
            icon="trash-can"
            disabled={!getInputValue() && osRuleList.length < 2}
            onClick={deleteRule}
          />
        </Box>
      </Flex>

      {/* Show 'or' beneath all rules except the last one */}
      {osRuleList.length > 0 && osIndex !== osRuleList.length - 1 && (
        <Text css={{ paddingTop: '$2', paddingLeft: '$2' }}>or</Text>
      )}
    </Flex>
  );
};

AMOSVersionRule.propTypes = {
  facet: string.isRequired,
  osIndex: number,
  index: number.isRequired,
  aggregator: string.isRequired,
};

const AMCustomOSVersion = ({ ...rest }) => {
  const { index, aggregator } = rest;

  const { form, updateForm } = useBuilder();

  const ruleList = form[aggregator];
  const rules = ruleList[index]?.children;

  const addRule = () => {
    rules.push({
      input: null,
      operator: '',
      value: '',
      subject: '',
    });
    updateForm(aggregator, ruleList);
  };

  return (
    <Box
      style={{
        marginLeft: '10px',
        marginTop: '14px',
        borderLeft: '1px solid #D7E1ED',
      }}
    >
      <Box className="k-am-os-rule-list">
        {rules.length > 0 ? (
          rules.map((rule, idx) => (
            <AMOSVersionRule rule={rule} osIndex={idx} {...rest} />
          ))
        ) : (
          <AMOSVersionRule
            rule={{ input: null, operator: '', value: '', subject: '' }}
            osIndex={0}
            {...rest}
          />
        )}

        <Flex justifyContent="end" css={{ width: '900px' }}>
          <Button
            onClick={addRule}
            icon={{ name: 'fa-plus-minus-small', position: 'left' }}
            css={{ padding: '6px 10px 6px 6px' }}
          >
            or
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default AMCustomOSVersion;
