import type { ReactElement } from 'react';

import type { SomeReadonlyRecord } from '@shared/types/util.types';
import type {
  ActionsMenuColProps,
  ColProps,
} from 'src/features/edr/vulnerability/old/components/table/table.types';

import Table from './Table';
import type { TableProps } from './Table';
import Filters from './components/Filters';
import Pagination from './components/Pagination';
import TableNoContent from './components/TableNoContent';
import Title from './components/Title';
import Cell from './components/cell';
import { ActionsMenuCol, Col } from './components/col';

type SubComponents = {
  Col: <
    ColName extends string,
    Sortable extends boolean,
    Data extends SomeReadonlyRecord[],
  >(
    props: ColProps<ColName, Sortable, Data, 'gridcell'>,
  ) => ReactElement;
  ActionsMenuCol: <Data extends SomeReadonlyRecord[]>(
    props: ActionsMenuColProps<Data>,
  ) => ReactElement;
  Cell: typeof Cell;
  Filters: typeof Filters;
  Title: typeof Title;
  Pagination: typeof Pagination;
};

interface CompoundTableExport extends SubComponents {
  <DataRecord extends SomeReadonlyRecord>(
    props: TableProps<DataRecord>,
  ): ReactElement;
}

const TableSubComponents = {
  Col,
  ActionsMenuCol,
  Cell,
  Filters,
  Title,
  Pagination,
} as const;

const TableExport = Object.assign(
  Table,
  TableSubComponents,
) as CompoundTableExport;

export default TableExport;
export { Cell, TableNoContent };
export * from './table.helpers';
