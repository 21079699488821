// istanbul ignore file
import { EDRSplitView } from '../../../../edr/common/components/EDRSplitView';
import type { ThreatDetail } from '../../threat.types';
import ThreatListSplitViewBody from './ThreatListSplitViewBody';
import ThreatListSplitViewHeader from './ThreatListSplitViewHeader';

const ThreatListSplitViewPM = (props: {
  selected: ThreatDetail;
  onClose: () => void;
  timezone: string;
  query: string | undefined;
  onReleaseThreat: () => void;
  onRecheckStatus: (threat: ThreatDetail) => void;
  isThreatBeingChecked: boolean;
}) => {
  const {
    selected,
    onClose,
    timezone,
    query,
    onReleaseThreat,
    onRecheckStatus,
    isThreatBeingChecked,
  } = props;

  return (
    <EDRSplitView
      header={
        <ThreatListSplitViewHeader
          threat={selected}
          tableSearchQuery={query}
          timezone={timezone}
          onReleaseThreat={onReleaseThreat}
          onRecheckStatus={onRecheckStatus}
          isThreatBeingChecked={isThreatBeingChecked}
        />
      }
      body={<ThreatListSplitViewBody threat={selected} query={query} />}
      onClose={onClose}
      css={{ height: '100%', position: 'unset' }}
    />
  );
};

export default ThreatListSplitViewPM;
