import { apiTypes } from '../library-items/library/common';
import { OTHER_PROFILE_TYPES, OTHER_SECURITY_TYPES } from './constants';
import type { Status } from './device-status-tab.types';

/**
 * Filters a list of Library Item / Parameter statuses by "type", ie. Custom App or Profile
 * @param statuses - a list of Library Item / Parameter status objects
 * @param typeFilter - a list of Library Item / Parameter types representing the state of the filter
 * @returns a list of Library Item / Parameter statuses filtered by "type"
 */
const filterByType = (statuses: Array<Status>, typeFilter: Array<string>) => {
  // Logic to include Parameters if selected in the filter
  const isParametersSelected = typeFilter.includes('parameter');
  const parameterStatuses = statuses.filter(
    ({ parameter_id }) => !!parameter_id,
  );

  // Logic to include other Profiles types if selected in the filter
  const isProfilesSelected = typeFilter.includes('profile');
  const isSecuritySelected = typeFilter.includes(
    apiTypes.THREAT_SECURITY_POLICY,
  );

  const filterList = [
    ...typeFilter,
    ...(isProfilesSelected ? OTHER_PROFILE_TYPES : []),
    ...(isSecuritySelected ? OTHER_SECURITY_TYPES : []),
  ];

  const statusesFilteredByType = statuses.filter(({ type }) =>
    filterList.includes(type),
  );

  const result = [
    ...statusesFilteredByType,
    ...(isParametersSelected ? parameterStatuses : []),
  ];

  return result;
};

/**
 * Filters a list of Library Item / Parameter statuses by "status", ie. "pass" or "pending"
 * @param statuses - a list of Library Item / Parameter status objects
 * @param statusFilter - a list of Library Item / Parameter statuses representing the state of the filter
 * @returns a list of Library Item / Parameter statuses filtered by "status"
 */
const filterByStatus = (
  statuses: Array<Status>,
  statusFilter: Array<string>,
) => {
  // Most of the status values are in all uppercase (such as "PASS"), while some are in all lowercase (such as "success"),
  // while there are both "PENDING" and "pending" values, so to catch all possible instances of any given status, both
  // uppercase and lowercase versions of each status value is added when that status is selected within the filter and both
  // are removed when unselected, for example: ["PENDING", "pending", "SUCCESS", "success", "PASS", "pass"]
  const filterList = [
    ...statusFilter,
    ...statusFilter.map((status) => status?.toLowerCase()),
  ];

  const result = statuses.filter(({ status }) => filterList.includes(status));

  return result;
};

/**
 * Filters a list of Library Item / Parameter statuses by a text search input
 * @param statuses - a list of Library Item / Parameter status objects
 * @param searchInput - a string value representing the search input's state provided by a user
 * @returns a list of Library Item / Parameter statuses filtered by a text search input
 */
const filterBySearch = (statuses: Array<Status>, searchInput: string) =>
  statuses.filter((s: Status) => {
    // Check if status meets Search Input criteria
    const matchesSearchInput = [s.name, s.instance_name, s.nameVerbose].some(
      (text) => {
        const sanitizedText = text?.trim().toLowerCase();

        return sanitizedText?.includes(searchInput);
      },
    );

    return matchesSearchInput;
  });

export { filterByType, filterByStatus, filterBySearch };
