import AirplaySecurityService from './service/airplay-security-service';

export default {
  name: '',
  isActive: true,
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,

  access: {
    type: AirplaySecurityService.accessTypes.ANY,
  },

  security: {
    type: AirplaySecurityService.securityTypes.PASSCODE_ALWAYS,
    password: '',
    repeatPassword: '',
    passwordUpdatedAt: null,
  },
  supportsRules: true,
};
