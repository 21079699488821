import { Checkbox, Chip, Select, TextInput } from '@kandji-inc/bumblebee';
import React from 'react';
import { i18n } from 'src/i18n';

import { Setting } from 'features/library-items/template';

import WifiService from '../../service/wifi-service';
import {
  securityTypeOptions,
  securityWithLoginWindowConfigurationValues,
  securityWithPasswordValues,
} from '../protocols-card.model';

const keys = WifiService.keys.authentication;

const AuthenticationTypeRow = ({ setting, update, isDisabled }) => {
  const isSecurityWithPassword = securityWithPasswordValues.includes(
    setting[keys.securityType],
  );
  const isSecurityWithLoginWindowConfiguration =
    securityWithLoginWindowConfigurationValues.includes(
      setting[keys.securityType],
    );

  return (
    <Setting.Row>
      <Setting.Title>
        <p className="b-txt">{i18n.t('Authentication type')}</p>
      </Setting.Title>
      <Setting.Helpers>
        <p className="b-txt-light">
          {i18n.t('Choose the authentication type of the wireless network.')}
        </p>
      </Setting.Helpers>
      <Setting.Controls>
        <Select
          placeholder={i18n.t('Select Option')}
          isDisabled={isDisabled}
          options={securityTypeOptions()}
          compact
          onChange={(v) => update(keys.securityType, v.value)}
          value={securityTypeOptions().find(
            (option) => option.value === setting[keys.securityType],
          )}
        />
      </Setting.Controls>
      {isSecurityWithPassword && (
        <Setting.SecondaryControls className="k-ade-secondary-ctrl-grid-gap-align">
          <Setting.SecondaryControlsRow>
            <div>
              <p className="b-txt">
                {i18n.ut(
                  'Password<span className="b-txt-light2"> (optional)</span>',
                )}
              </p>
            </div>
            <div>
              <p className="b-txt-light">
                {i18n.t(
                  'Specify the password (pre-shared key) required to join this network. Leave blank to prompt the user.',
                )}
              </p>
            </div>
            <div>
              <TextInput
                disabled={isDisabled}
                value={setting[keys.securityPassword]}
                onChange={(e) => update(keys.securityPassword, e.target.value)}
                compact
                isOptional
                type="password"
                placeholder={i18n.t('Password')}
              />
            </div>
          </Setting.SecondaryControlsRow>
        </Setting.SecondaryControls>
      )}
      {isSecurityWithLoginWindowConfiguration && (
        <Setting.SecondaryControls className="k-ade-secondary-ctrl-grid-gap-align">
          <Setting.SecondaryControlsRow>
            <div>
              <Checkbox
                checked={setting[keys.useLoginWindow]}
                onChange={() => update(keys.useLoginWindow, (p) => !p)}
                disabled={isDisabled}
                label={i18n.t('Use as a Login Window configuration')}
              />
            </div>
            <Setting.Helpers>
              <p className="b-txt-light">
                {i18n.t(`User logs in to authenticate the Mac to the network.`)}
              </p>
              <div className="b-flex-wrap-gtiny">
                <Chip
                  kind="tertiary-light"
                  text={i18n.t('macOS 10.11+')}
                  className="k-ade-chip--m0"
                />
              </div>
            </Setting.Helpers>
          </Setting.SecondaryControlsRow>
        </Setting.SecondaryControls>
      )}
    </Setting.Row>
  );
};

export default AuthenticationTypeRow;
