/* istanbul ignore file - legacy code */
import { libraryAxios } from 'app/api/base';

export const getLibraryItemStatusesForComputer = (computerId) =>
  libraryAxios('library-items/statuses')
    .get({ computer_id: computerId })
    .then((res) => res.data);

export const bulkFlushLibraryItemStatuses = (libraryItemId) =>
  libraryAxios(`library-items/statuses/bulk-flush`)
    .post({
      library_item_id: libraryItemId,
    })
    .then((res) => res.data);
