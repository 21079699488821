import { Banner, newc, setClass } from '@kandji-inc/bumblebee';
import { element, node, object, oneOfType, string } from 'prop-types';
import React from 'react';

import { Assign, AssignBlueprints, AssignDevices } from './assign';
import Header from './header';
import Info from './info';
import Instance from './instance';

const Summary = (props: any) => newc(props, 'b-library-summary');
const Body = (props: any) => newc(props, 'b-library-summary__body');
const ExtraInfo = (props: any) => {
  const { style, className, children, component: Component } = props;
  if (Component) {
    return <Component {...props} />;
  }
  return (
    <div style={style} className={setClass(className, 'b-mt3')}>
      <Banner theme="info" kind="block">
        <span>{children}</span>
      </Banner>
    </div>
  );
};

ExtraInfo.propTypes = {
  style: object,
  className: string,
  children: oneOfType([node, element]),
  component: oneOfType([node, element]),
};

ExtraInfo.defaultProps = {
  style: {},
  className: null,
  children: null,
  component: null,
};

Summary.Header = Header;
Summary.Body = Body;
Summary.Info = Info;
Summary.ExtraInfo = ExtraInfo;
Summary.Instance = Instance;
Summary.Assign = Assign;
Summary.AssignBlueprints = AssignBlueprints;
Summary.AssignDevices = AssignDevices;

export default Summary;

export {
  Header,
  Body,
  Info,
  ExtraInfo,
  Instance,
  Assign,
  AssignBlueprints,
  AssignDevices,
};
