/* istanbul ignore file */
import * as React from 'react';

export const useTemporaryCss = (
  selector: string,
  styles: React.CSSProperties,
) => {
  React.useEffect(() => {
    const el: HTMLElement = document.querySelector(selector);
    if (el) {
      Object.assign(el.style, styles);
    }

    return () => {
      if (el) {
        Object.keys(styles).forEach((key) => {
          el.style[key] = '';
        });
      }
    };
  }, [selector, styles]);
};
