import {
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import {
  Box,
  Button,
  Flex,
  Grid,
  Select,
  TextField,
  styled,
} from '@kandji-inc/nectar-ui';
/* istanbul ignore file */
import React, { useEffect } from 'react';
import { v4 } from 'uuid';

import { Setting } from 'features/library-items/template';
import { i18n } from 'src/i18n';
import RestrictionsService from '../service/restrictions-service';
import type { CheckboxRowProps } from './CheckboxRow';
import CheckboxRow from './CheckboxRow';

const ControlsRow = styled(Setting.Controls, {
  // Do this since we don't need helpers from the controls setup.
  gridColumn: '1 / -1 !important',
});

export type Bundle = { _id: string; id: string; name: string };
export type AppRestrictionsRowProps = CheckboxRowProps;

const SingleAppRestriction = (props) => {
  const {
    row,
    isDisabled,
    isSubmitted,
    onUpdateField,
    canTrash,
    onTrashRow,
    update,
    validationDep,
  } = props;

  const idField = `${row._id}_id`;
  const fieldsToValidate = [idField];
  const { refs, onInvalidate, invalidations } = useInputsValidators(
    fieldsToValidate,
    update,
  );
  useRemoveValidationOnUnmount(fieldsToValidate, update);

  useEffect(() => {
    onInvalidate(idField)(!row.id);
  }, [validationDep]);

  return (
    <Setting.SecondaryControlsRow key={row._id}>
      <ControlsRow>
        <Grid css={{ gridTemplateColumns: 'auto auto auto' }} gap="3">
          <TextField
            ref={refs[0]}
            disabled={isDisabled}
            placeholder={i18n.t('Bundle ID')}
            value={row.id}
            onChange={(e) => {
              onUpdateField(row, 'id')(e);
              onInvalidate(idField)(!e.target.value);
            }}
            state={isSubmitted && invalidations[idField] ? 'error' : 'default'}
          />
          <TextField
            disabled={isDisabled}
            placeholder={i18n.t('App Name (optional)')}
            value={row.name}
            onChange={onUpdateField(row, 'name')}
          />
          <Flex alignItems="center">
            <Button
              compact
              disabled={!canTrash}
              variant="subtle"
              icon={{ name: 'trash' }}
              onClick={onTrashRow}
            />
          </Flex>
        </Grid>
      </ControlsRow>
    </Setting.SecondaryControlsRow>
  );
};

const AppRestriction = (props: AppRestrictionsRowProps) => {
  const { isDisabled, isSubmitted, validationDep, helpers, updateHelpers } =
    props;
  const apps = helpers.appRestrictionsValues;

  const onAddRow = () =>
    updateHelpers('appRestrictionsValues', (prev) => [
      ...prev,
      {
        _id: v4(),
        id: '',
        name: '',
      },
    ]);

  const onUpdateField = (row, field) => (e) =>
    updateHelpers('appRestrictionsValues', (prev) => {
      const p = prev;
      const updateIdx = prev.findIndex((bundle) => bundle._id === row._id);
      p[updateIdx][field] = e.target.value;
      return [...p];
    });

  const onTrashRow = (row) =>
    updateHelpers('appRestrictionsValues', (prev) => {
      const updateIdx = prev.findIndex((bundle) => bundle._id === row._id);
      return [...prev.slice(0, updateIdx), ...prev.slice(updateIdx + 1)];
    });

  useEffect(() => {
    if (!apps.length) {
      onAddRow();
    }
  }, [helpers.appRestrictionsOption]);

  return (
    <Box>
      <Setting.SecondaryControls>
        <Setting.SecondaryControlsRow>
          <Setting.Controls>
            <Select
              disabled={isDisabled}
              options={RestrictionsService.appRestrictionsOptions()}
              value={helpers.appRestrictionsOption}
              onChange={(v) => updateHelpers('appRestrictionsOption', v)}
            />
          </Setting.Controls>
        </Setting.SecondaryControlsRow>
        {apps.map((row) => (
          <SingleAppRestriction
            key={row._id}
            row={row}
            onUpdateField={onUpdateField}
            isDisabled={isDisabled}
            isSubmitted={isSubmitted}
            canTrash={apps.length > 1}
            onTrashRow={() => onTrashRow(row)}
            update={updateHelpers}
            validationDep={validationDep}
          />
        ))}
      </Setting.SecondaryControls>
      <Flex pt3 justifyContent="end">
        <Button
          compact
          disabled={isDisabled}
          variant="primary"
          icon={{ position: 'left', name: 'circle-plus' }}
          onClick={onAddRow}
        >
          {i18n.t('Add More')}
        </Button>
      </Flex>
    </Box>
  );
};
const AppRestrictionsRow = (props: AppRestrictionsRowProps) => (
  <CheckboxRow
    {...props}
    // We override here since the set of keys for this restriction field are
    // managed using our _helpers.
    isChecked={props.helpers.isManageAppRestrictions}
    onCheckedChange={(checked) =>
      props.updateHelpers('isManageAppRestrictions', checked)
    }
  >
    {(isChecked) => isChecked && <AppRestriction {...props} />}
  </CheckboxRow>
);

export default AppRestrictionsRow;
