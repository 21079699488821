import { Button, Select } from '@kandji-inc/bumblebee';
import type { CSSProperties, ReactElement } from 'react';
import React, { useMemo, useContext, useReducer, useRef } from 'react';
import { TableContext } from '../table.context';

type PaginationProps = Readonly<{
  prevOffset: number | string | null;
  nextOffset: number | string | null;
  onPrevClick: unknown;
  onPrevHover?: unknown;
  onNextClick: unknown;
  onNextHover?: unknown;
  rowsPerPage: Array<number>;
  selectedRowsPerPage: number | string;
  onSelectRowsPerPage: unknown;
  leftControls?: ReactElement;
  hideOnNoData?: boolean;
}>;

const PREV_PAGE_LABEL = 'Previous page';
const NEXT_PAGE_LABEL = 'Next page';
const SELECT_ROWS_PER_PAGE_BASE_CHARS_COUNT = 6;
const PAGE_RANGE_BASE_CHARS_COUNT = 10;
const DEFAULT_PAGE_RANGE_TRACK_STEP = 4;

// util functions to identify null/undefined prev or next offset with 0 as valid
// offset or identify zero string values from query param string values for
// page navigation
const isNullish = (value: unknown) => value === null || value === undefined;
const isZeroLike = (value: unknown) => value === 0 || value === '0';

// util function to set page range width based on number of characters
// approximated to track value to prevent layout thrashing too often when
// number of characters change
const getPageRangeTrack = (
  charsCount: number,
  trackStep = DEFAULT_PAGE_RANGE_TRACK_STEP,
) => Math.ceil(charsCount / trackStep) * trackStep;

export default function Pagination(props: PaginationProps) {
  const {
    prevOffset,
    nextOffset,
    onPrevClick,
    onPrevHover,
    onNextClick,
    onNextHover,
    rowsPerPage,
    selectedRowsPerPage,
    onSelectRowsPerPage,
    leftControls,
    hideOnNoData = false,
  } = props;
  const [{ totalCount, isLoading, originalData }] = useContext(TableContext);

  const currRowsPerPage = rowsPerPage.includes(Number(selectedRowsPerPage))
    ? Number(selectedRowsPerPage)
    : Number(rowsPerPage[0]);

  const selectedRowsPerPageOption = {
    label: String(currRowsPerPage),
    value: Number(currRowsPerPage),
  };

  const savedNextOffset = usePreviousDistinct(nextOffset);
  const savedRowsPerPage = usePreviousDistinct(Number(currRowsPerPage));
  const currPageAction = useRef<{
    type: 'initial' | 'prevPage' | 'nextPage' | 'updateRowsPerPage';
    data?: {
      nextOffset: PaginationProps['nextOffset'];
      savedNextOffset: PaginationProps['nextOffset'];
    };
  }>({ type: 'initial' });

  const rowsPerPageOptions = useMemo(
    () =>
      rowsPerPage.map((rowsCount) => ({
        label: String(rowsCount),
        value: rowsCount,
      })),
    [rowsPerPage],
  );
  const pageRange = useMemo(() => {
    if (!totalCount) {
      return '';
    }

    const getStart = () => {
      if (currPageAction.current.type === 'updateRowsPerPage') {
        /* scenario: switched to `currRowsPerPage` that is greater than
         * `totalCount`, so start range at the beginning */
        if (isNullish(prevOffset)) {
          return 0;
        }

        /* scenario: switched to `currRowsPerPage` such that we are at the
         * last page, i.e. going to the next page would be gte the `totalCount`,
         * so start range at new `currRowsPerPage` past `prevOffset` */
        if (isNullish(nextOffset) && !isZeroLike(prevOffset)) {
          return Number(prevOffset) + currRowsPerPage;
        }

        /* scenario: previously at last page, so start range at
         * `currRowsPerPage` back from the new `nextOffset` */
        if (isNullish(savedNextOffset)) {
          return Number(nextOffset) - currRowsPerPage;
        }

        /* scenario: at last page after changing `currRowsPerPage` based on
         * most current `nextOffset` from `currPageAction` ref, use
         * `savedNextOffset` which is the most recent previous value of
         * `nextOffset` prop */
        if (isNullish(currPageAction.current.data.nextOffset)) {
          return savedNextOffset;
        }

        /* default scenario: start range at previous position before updating
         * `currRowsPerPage` */
        return Number(savedNextOffset) - savedRowsPerPage;
      }

      /* scenario: at beginning page after changing page */
      if (isNullish(prevOffset)) {
        return 0;
      }

      /* scenario: at the last page but `prevOffset` does not indicate current
       * offset, so use difference of `totalCount` and the number of rows
       * currently displayed in table to get the current offset */
      if (
        isNullish(nextOffset) &&
        (isNullish(savedNextOffset) ||
          (originalData && originalData.length < currRowsPerPage))
      ) {
        const tableRowsCount = originalData ? originalData.length : 1;
        return totalCount - tableRowsCount;
      }

      /* scenario: at last page after changing page, use `savedNextOffset`
       * which is the most recent previous value of `nextOffset` prop */
      if (isNullish(nextOffset)) {
        return savedNextOffset;
      }

      /* default scenario: start range at `currRowsPerPage` back from
       * `nextOffset` */
      return Number(nextOffset) - currRowsPerPage;
    };

    const start = Number(getStart());

    return `${start + 1}-${Math.min(
      start + currRowsPerPage,
      totalCount,
    )} of ${totalCount}`;
  }, [
    totalCount,
    originalData?.length,
    nextOffset,
    prevOffset,
    savedNextOffset,
  ]);

  /* `mostCharsRowsPerPage` and `charsTotalCount` are used to calculate optimal
   * pagination section widths to prevent layout thrashing on next/prev page */
  const mostCharsRowsPerPage = useMemo(
    () =>
      rowsPerPage.reduce(
        (mostChars, rpp) => Math.max(mostChars, String(rpp).length),
        0,
      ),
    rowsPerPage,
  );

  const prevCharsTotalCount = useRef(0);
  const mostCharsTotalCount = useMemo(() => {
    const totalCountChars = totalCount ? String(totalCount).length : 0;
    const max = Math.max(totalCountChars, prevCharsTotalCount.current);
    if (max !== prevCharsTotalCount.current) {
      prevCharsTotalCount.current = max;
    }
    return max;
  }, [totalCount]);

  const handleNextClick = (e) => {
    currPageAction.current = { type: 'nextPage' };
    // @ts-expect-error -- TODO
    onNextClick(e);
  };

  const handlePrevClick = (e) => {
    currPageAction.current = { type: 'prevPage' };
    // @ts-expect-error -- TODO
    onPrevClick(e);
  };

  const handleHover = (e) => {
    const currTargetLabel = e.currentTarget.getAttribute('aria-label');
    const currTargetDisabled = e.currentTarget.getAttribute('aria-disabled');

    const handler =
      currTargetLabel === PREV_PAGE_LABEL ? onPrevHover : onNextHover;
    // @ts-expect-error -- TODO
    handler?.(e, {
      disabled: currTargetDisabled === 'true' && true,
    });
  };

  const handleSelectRowsPerPage = (selected) => {
    currPageAction.current = {
      type: 'updateRowsPerPage',
      data: {
        nextOffset,
        savedNextOffset,
      },
    };
    // @ts-expect-error -- TODO
    onSelectRowsPerPage(selected.value);
  };

  if (hideOnNoData && !totalCount) {
    return null;
  }

  return (
    <div className="v-table-pagination __v-table-ns">
      <div className="v-table-pagination__left-controls __v-table-ns">
        {leftControls}
      </div>

      <div
        className="v-table-pagination__right-controls __v-table-ns"
        style={
          {
            '--pagination-template-columns': `max-content ${
              SELECT_ROWS_PER_PAGE_BASE_CHARS_COUNT + mostCharsRowsPerPage
            }ch ${
              PAGE_RANGE_BASE_CHARS_COUNT +
              getPageRangeTrack(mostCharsTotalCount)
            }ch
         max-content`,
          } as CSSProperties
        }
      >
        <span>Rows per page</span>

        <Select
          value={selectedRowsPerPageOption}
          options={rowsPerPageOptions}
          onChange={handleSelectRowsPerPage}
          compact
          testId="pagination-rowsperpage"
        />

        <span className="b-ml-tiny v-table-pagination__page-range __v-table-ns">
          {pageRange}
        </span>

        <div className="v-table-pagination__page-num-control __v-table-ns">
          <div
            onMouseEnter={handleHover}
            onFocus={handleHover}
            aria-disabled={isNullish(prevOffset)}
            aria-label={PREV_PAGE_LABEL}
            data-testid="prev-page-button"
          >
            <Button
              icon="angle-left"
              kind="link"
              disabled={isNullish(prevOffset)}
              onClick={handlePrevClick}
            />
          </div>

          <div
            onMouseEnter={handleHover}
            onFocus={handleHover}
            aria-disabled={isNullish(nextOffset)}
            aria-label={NEXT_PAGE_LABEL}
            data-testid="next-page-button"
          >
            <Button
              icon="angle-right"
              kind="link"
              disabled={isNullish(nextOffset)}
              onClick={handleNextClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function clientsidePaginationReducer(state, action) {
  switch (action.type) {
    case 'update_all_data': {
      const { allData } = action.data;

      // TODO: Update this logic, for now this just makes sure the allData and
      // pageData get updated.
      return {
        ...state,
        allData,
        currentPage: 1,
        pageData: allData.slice(0, state.rowsPerPage),
        prevOffset: null,
        nextOffset:
          state.rowsPerPage >= allData.length ? null : state.rowsPerPage,
      };
    }

    case 'change_per_page': {
      const { rowsPerPage } = action.data;

      return {
        ...state,
        rowsPerPage,
        currentPage: 1, // reset current page when per page changes
        pageData: state.allData.slice(0, rowsPerPage),
        prevOffset: null,
        nextOffset: rowsPerPage >= state.allData.length ? null : rowsPerPage,
      };
    }

    case 'change_page': {
      const { currentPage } = action.data;

      return {
        ...state,
        currentPage,
        pageData: state.allData.slice(
          (currentPage - 1) * state.rowsPerPage,
          currentPage * state.rowsPerPage,
        ),
        prevOffset:
          currentPage <= 1 ? null : (currentPage - 1) * state.rowsPerPage,
        nextOffset:
          currentPage * state.rowsPerPage >= state.allData.length // <-- check if next page exists
            ? null
            : currentPage * state.rowsPerPage,
      };
    }

    default:
      return state;
  }
}

export function useClientsidePagination({ allData, rowsPerPage }) {
  const [paginationState, dispatch] = useReducer(clientsidePaginationReducer, {
    currentPage: 1,
    rowsPerPage,
    prevOffset: null,
    nextOffset: rowsPerPage >= allData.length ? null : rowsPerPage,
    allData,
    pageData: allData.slice(0, rowsPerPage),
  });

  return {
    ...paginationState,
    setRowsPerPage: (newRowsPerPage) =>
      dispatch({
        type: 'change_per_page',
        data: { rowsPerPage: newRowsPerPage },
      }),
    setCurrentPage: (newCurrentPage) =>
      dispatch({ type: 'change_page', data: { currentPage: newCurrentPage } }),
    updateData: (data) =>
      dispatch({ type: 'update_all_data', data: { allData: data } }), // Possibly can be done in a useEffect, did this for now.
  };
}

type Predicate<T> = (prev: T | undefined, next: T) => boolean;

const strictEquals = <T,>(prev: T | undefined, next: T) => prev === next;

function usePreviousDistinct<T>(
  value: T,
  compare: Predicate<T> = strictEquals,
): T | undefined {
  const prevRef = useRef<T>();
  const currRef = useRef<T>(value);
  const isFirstMount = useRef(true);

  if (!isFirstMount.current && !compare(currRef.current, value)) {
    prevRef.current = currRef.current;
    currRef.current = value;
  }

  isFirstMount.current = false;

  return prevRef.current;
}
