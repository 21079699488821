// istanbul ignore file
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  askChat,
  getChat,
  getChatMessage,
  listChatSessions,
  newChat,
  rateChatMessage,
} from './ai-service';

/* 
  The flow:
  - useNewChatMutation - creates a new chat session, returns chat_session_id
  - useAskChatMutation - returns chat_response_id
  - useGetChatMessageQuery - we use chat_response_id and poll until status is "success"

*/

export const useListChatSessionsQuery = () => {
  return useQuery({
    queryKey: ['chat-sessions'],
    queryFn: listChatSessions,
  });
};

export const useNewChatMutation = () => {
  return useMutation({
    mutationKey: ['new-chat'],
    mutationFn: newChat,
  });
};

export const useGetChatQuery = (chat_session_id: string | null) => {
  return useQuery({
    queryKey: ['chat', chat_session_id],
    queryFn: () => getChat(chat_session_id),
    enabled: !!chat_session_id,
  });
};

// Keeping this around, may experiment with later
// export const askChatMutationOptions: UseMutationOptions<
//   ChatBotResponse,
//   unknown,
//   {
//     chat_session_id: string;
//     user_chat: string;
//   }
// > = {
//   mutationKey: ['ask-chat'],
//   mutationFn: ({
//     chat_session_id,
//     user_chat,
//   }: { chat_session_id: string; user_chat: string }) =>
//     askChat(chat_session_id, user_chat),
// };

export const useAskChatMutation = () => {
  return useMutation({
    mutationKey: ['ask-chat'],
    mutationFn: ({
      chat_session_id,
      user_chat,
    }: { chat_session_id: string; user_chat: string }) =>
      askChat(chat_session_id, user_chat),
  });
};

export const useGetChatMessageQuery = (chat_response_id: string | null) => {
  return useQuery({
    queryKey: ['chat-message', chat_response_id],
    queryFn: () => getChatMessage(chat_response_id),
    enabled: !!chat_response_id,
    refetchInterval: ({ state }) => {
      if (state.data?.status === 'in_progress') {
        return 1000;
      }

      return false;
    },
  });
};

export const useRateChatMessageMutation = (
  chat_session_id: string,
  message_id: string,
) => {
  return useMutation({
    mutationKey: ['rate-chat-message', chat_session_id, message_id],
    mutationFn: ({
      liked,
      optional_feedback,
    }: { liked: boolean; optional_feedback?: string | undefined }) =>
      rateChatMessage(chat_session_id, message_id, liked, optional_feedback),
  });
};
