/* istanbul ignore file */
import React, { memo, useEffect } from 'react';

import { useInputsValidators } from '@kandji-inc/bumblebee';
import {
  Flex,
  SelectV2,
  Text,
  TextArea,
  TextField,
} from '@kandji-inc/nectar-ui';
import { Setting } from 'src/features/library-items/template';
import { i18n } from 'src/i18n';
import type { SubjectProps } from '../../scep.types';
import ScepService from '../../service/scep-service';

const Subject = (props: SubjectProps) => {
  const { update, isDisabled, isSubmitted, settings } = props;

  const fieldsToValidate = ['SubjectAltName'];
  const { refs, invalidations, onInvalidate } = useInputsValidators(
    fieldsToValidate,
    update,
  );

  const altName = ScepService.SubjectAltNames().find(
    ({ value }) => settings.SubjectAlternativeNameType === value,
  );

  useEffect(() => {
    onInvalidate('SubjectAltName')(
      settings.SubjectAlternativeNameType !== 'None' &&
        !settings.SubjectAltName,
    );
  }, [settings.SubjectAlternativeNameType, settings.SubjectAltName]);

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('Subject')}</h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Title>
            <Flex gap="xs">
              <Text>{i18n.t('Subject')}</Text>
              <Text variant="description">{i18n.t('(required)')}</Text>
            </Flex>
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t(
                'The representation of a X.500 name represented as an array of OID and value. Typically used to identify the device within the CA.',
              )}
            </p>
          </Setting.Helpers>
          <Setting.Controls>
            <TextArea
              css={{ maxWidth: '400px', '& textarea': { minHeight: '120px' } }}
              value={settings.Subject}
              onChange={(e) => update('Subject', e.target.value)}
              readOnly={isDisabled}
              placeholder="CN=$SERIAL_NUMBER-$ASSET_TAG"
            />
          </Setting.Controls>
        </Setting.Row>

        <Setting.Row>
          <Setting.Title>
            <Flex gap="xs">
              <Text>{i18n.t('Subject Alternative Name Type')}</Text>
            </Flex>
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t(
                'Add additional Subject Alternative Names to the certificate request.',
              )}
            </p>
          </Setting.Helpers>
          <Setting.Controls>
            <SelectV2.Default
              options={ScepService.SubjectAltNames()}
              onValueChange={(value) =>
                update('SubjectAlternativeNameType', value)
              }
              value={settings.SubjectAlternativeNameType}
              triggerProps={{
                value: altName.label,
                variant: 'input',
                css: { width: '400px' },
                disabled: isDisabled,
              }}
            />
          </Setting.Controls>
        </Setting.Row>

        {settings.SubjectAlternativeNameType !== 'None' && (
          <Setting.SecondaryControls>
            <Setting.SecondaryControlsRow>
              <Setting.Title>
                <Flex gap="xs" ref={refs.SubjectAltName}>
                  <Text>{altName.label}</Text>
                  <Text variant="description">{i18n.t('(required)')}</Text>
                </Flex>
              </Setting.Title>
              <Setting.Helpers>
                <p className="b-txt-light">
                  {i18n.t('The value of the Subject Alternative Name.')}
                </p>
              </Setting.Helpers>
              <Setting.Controls>
                <TextField
                  css={{ maxWidth: '400px' }}
                  value={settings.SubjectAltName}
                  onChange={(e) => update('SubjectAltName', e.target.value)}
                  disabled={isDisabled}
                  placeholder={i18n.t('CN=$SERIAL_NUMBER')}
                  state={invalidations[0] && isSubmitted ? 'error' : undefined}
                />
              </Setting.Controls>
            </Setting.SecondaryControlsRow>

            <Setting.SecondaryControlsRow>
              <Setting.Title>
                <Flex gap="xs">
                  <Text>{i18n.t('NT Principal Name')}</Text>
                  <Text variant="description">{i18n.t('(optional)')}</Text>
                </Flex>
              </Setting.Title>
              <Setting.Helpers>
                <p className="b-txt-light">
                  {i18n.t('The value of the Subject Alternative Name.')}
                </p>
              </Setting.Helpers>
              <Setting.Controls>
                <TextField
                  css={{ maxWidth: '400px' }}
                  value={settings.ntPrincipalName}
                  onChange={(e) => update('ntPrincipalName', e.target.value)}
                  disabled={isDisabled}
                  placeholder="scep.sa@accuhive.io"
                />
              </Setting.Controls>
            </Setting.SecondaryControlsRow>
          </Setting.SecondaryControls>
        )}
      </Setting.Rows>
    </Setting.Card>
  );
};

export default memo(Subject);
