import { Button, setClass } from '@kandji-inc/bumblebee';
import React, { useEffect, useState } from 'react';

import { i18n } from 'src/i18n';
import newSuccessLink from './assets/new-success-link.svg';
import LinkConfigPanel from './link-config-panel';
import LinkPreview from './link-preview';
import './link-previews.css';
import SortableList from './sortable-list';

const MAX_LINKS = 4;

const LinkPreviews = (props) => {
  const {
    style,
    className,
    model,
    setModel,
    isDarkMode,
    isEditing,
    removeLink,
    onSelect,
    addLink,
    isOpen,
    onValidations = () => {},
  } = props;

  const { links } = model.successScreen;
  const [linksLen, setLinksLen] = useState(links.length);
  const [selected, setSelected] = useState(links[0]);

  /* setup validation result for each link */
  const linksValidObj = {};
  links.forEach((lk) => {
    linksValidObj[lk.uuid] = false;
  });
  const [linkValidations, setLinkValidations] = useState(linksValidObj);

  const onLinksValidation = (r) => {
    setLinkValidations((p) => ({ ...p, [r.uuid]: r.isInvalid }));
  };

  useEffect(() => {
    if (isOpen) {
      setSelected(links[links.length - 1] || {});
    }
  }, [isOpen]);

  useEffect(() => {
    if (linksLen !== links.length) {
      setSelected(links[links.length - 1] || {});
    }

    setLinksLen(links.length);
  }, [links]);

  /* when any validation happes on any link, update the result for each link */
  useEffect(() => {
    onValidations({
      isInvalid: Object.values(linkValidations).some(Boolean),
    });
  }, [linkValidations, links]);

  /* also, if a links is removed or added, update validation results */
  useEffect(() => {
    Object.keys(linkValidations).forEach((lv) => {
      if (!links.find((v) => v.uuid === lv)) {
        setLinkValidations((p) => {
          const c = { ...p };
          delete c[lv];
          return c;
        });
      }
    });
  }, [links]);

  useEffect(() => {
    if (onSelect) {
      onSelect(selected);
    }
  }, [selected]);

  const onSort = (sortedList) => {
    setModel((p) => ({
      ...p,
      successScreen: {
        ...p.successScreen,
        links: sortedList,
      },
    }));
  };

  return (
    <div
      style={style}
      className={setClass([
        'k-library-preview__links',
        isEditing ? 'k-library-preview__links--editing' : '',
        className,
      ])}
    >
      <SortableList
        isEnabled={isEditing}
        model={model.successScreen.links}
        onSort={onSort}
      >
        {links.map((l, index) => (
          <LinkPreview
            className={setClass([
              selected.uuid === l.uuid ? 'k-library-item-link--selected' : '',
              index === links.length - 1 && '--last-item',
            ])}
            key={`preview_${l.uuid}`}
            link={l}
            isDarkMode={isDarkMode}
            isEditing={isEditing}
            removeLink={removeLink}
            selected={selected}
            isInvalid={linkValidations[l.uuid]}
            onClick={() => {
              if (isEditing) {
                setSelected(l);
              }
            }}
          />
        ))}
      </SortableList>
      {isEditing && model.successScreen.links.length < MAX_LINKS && (
        <div className="k-library-success-link k-library-success-link--editing k-library-new-success-link">
          <div className="k-ks-new-link-card">
            <Button
              className="k-ks-add-new-link-button"
              onClick={() => {
                if (model.successScreen.links.length === MAX_LINKS) {
                  return;
                }
                addLink();
              }}
              size="small"
              kind="link"
              icon="circle-plus"
            >
              {i18n.t(`Add link`)}
            </Button>
            <img src={newSuccessLink} alt={i18n.t('Add Success Link')} />
          </div>
        </div>
      )}
      {links.map((l) => (
        <div key={`config_panel_${l.uuid}`}>
          {isEditing && (
            <div
              className="k-kandji-link-config-wrapper"
              style={{ display: selected.uuid === l.uuid ? null : 'none' }}
            >
              <LinkConfigPanel
                onValidations={onLinksValidation}
                model={model}
                setModel={setModel}
                link={l}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default LinkPreviews;
