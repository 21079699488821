export const fixedConfiguredPlatforms = (configuredPlatforms: string[]) => {
  const platformMap = {
    Mac: 'Mac',
    IOS: 'iOS',
  };
  return configuredPlatforms.map((platform) => {
    // performing this check in case some other devices end up in the list, the function will still work.
    if (!platformMap[platform]) {
      return platform;
    }
    return platformMap[platform];
  });
};
