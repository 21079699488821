import ActivityTab from 'features/library-items/common/activity-tab';
import LibraryContext from 'features/library-items/routes/library.context';
/* istanbul ignore */
import { useContext } from 'react';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import NewStatusTab from '../../common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from '../../common/new-status-tab/util';
import MessageConfig from './sections/MessageConfig';

import initialState from './initial-state';
import { lsmService } from './service/lsm-service';
import { transformFromApi, transformToApi } from './service/lsm-transformers';
import useService from './service/use-lsm-service';

const LockScreenMessage = (props) => {
  const { itemConfig } = useContext(LibraryContext);
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState,
    useService,
  });
  const blueprintConflicts = useBlueprintConflicts();

  /* istanbul ignore next */
  if (PlaceHolder) {
    return <PlaceHolder />;
  }
  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={itemConfig.name}
      summaryInfoProps={{
        name: `${itemConfig.name}`,
        description: itemConfig.description,
        publisher: itemConfig.publisher,
        devices: itemConfig.devices,
        requirements: itemConfig.osRequirements,
      }}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      transformFromApi={transformFromApi}
      transformToApi={transformToApi}
      service={lsmService}
      defaultIcon={itemConfig.icon}
      supportsDuplication
      supportsRules
      supportsInstallOn
      blueprintConflicts={blueprintConflicts}
      {...pageProps}
    >
      <MessageConfig isDisabled={false} />
    </LibraryItemPage>
  );
};

export default LockScreenMessage;
