import { createContext, useContext } from 'react';
import type { UseSyncUrlWithTableStateReturnType } from '../hooks/use-sync-url-with-table-state';
import { useSyncUrlWithTableState } from '../hooks/use-sync-url-with-table-state';

export interface PrismUrlContextType
  extends UseSyncUrlWithTableStateReturnType {}

export const PrismUrlContext = createContext<PrismUrlContextType>(null);

export const PrismUrlProvider = ({ children }) => {
  const {
    prismCategory,
    blueprints,
    deviceFamilies,
    filter,
    limit,
    offset,
    viewId,
    setViewId,
    setBlueprints,
    resetBlueprints,
    setDeviceFamilies,
    setFilter,
    removeFilter,
    setLimit,
    setOffset,
    resetBlueprintsAndDeviceFamilies,
    resetFilter,
    removeViewId,
    replaceFilter,
    setCategoryAndFilters,
  } = useSyncUrlWithTableState();

  return (
    <PrismUrlContext.Provider
      value={{
        prismCategory,
        blueprints,
        deviceFamilies,
        filter,
        limit,
        offset,
        viewId,
        setViewId,
        setBlueprints,
        resetBlueprints,
        setDeviceFamilies,
        setFilter,
        removeFilter,
        setLimit,
        setOffset,
        resetBlueprintsAndDeviceFamilies,
        resetFilter,
        removeViewId,
        replaceFilter,
        setCategoryAndFilters,
      }}
    >
      {children}
    </PrismUrlContext.Provider>
  );
};

// istanbul ignore next
export const usePrismUrlContext = () => useContext(PrismUrlContext);
