import PropTypes from 'prop-types';
import React, { useMemo, createContext, useContext, useEffect } from 'react';

import {
  Button,
  Flex,
  NoContent,
  Table,
  TextInput,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';

import Menu from 'features/self-service-new/menu';
import { i18n } from 'src/i18n';

const KernelExtensionBundlesContext = createContext({});

function getColumns({ onDelete, onUpdate }) {
  return [
    {
      header: i18n.t('KERNEL EXTENSION BUNDLE ID'),
      fieldName: 'BundleId',
      HeaderCell: () => <span>{i18n.t('Kernel Extension Bundle ID')}</span>,
      Cell: ({ item, index }) => {
        const { isDisabled, validationDep, refs } = useContext(
          KernelExtensionBundlesContext,
        );

        return (
          <div className="pt-2 pb-2 flex-fill" ref={refs[index]}>
            <TextInput
              disabled={isDisabled}
              id={`bundle_id_${item.id}`}
              value={item.BundleId}
              placeholder="com.vmware.kext.vmx86"
              onChange={(e) => onUpdate('BundleId', item.id, e.target.value)}
              validator={(v) => [
                {
                  message: i18n.t('Required.'),
                  invalid: () => !v?.length,
                  trigger: ['onMount', 'onBlur', validationDep],
                },
              ]}
              onInvalidate={(isInvalid) =>
                onUpdate('BundleId', item.id, item.BundleId, isInvalid)
              }
            />
          </div>
        );
      },
    },
    {
      header: i18n.t('Display Name'),
      fieldName: 'DisplayName',
      HeaderCell: () => <span>{i18n.t('Display Name')}</span>,
      Cell: ({ item }) => {
        const { isDisabled } = useContext(KernelExtensionBundlesContext);

        return (
          <div className="pt-2 pb-2 flex-fill">
            <TextInput
              disabled={isDisabled}
              id={`display_name_${item.id}`}
              isOptional
              value={item.DisplayName}
              placeholder={i18n.t('Display Name (optional)')}
              onChange={(e) => onUpdate('DisplayName', item.id, e.target.value)}
            />
          </div>
        );
      },
    },
    {
      header: '',
      fieldName: 'ellipsis',
      ratio: 0.05,
      Cell: ({ item }) => {
        const { isDisabled } = useContext(KernelExtensionBundlesContext);

        return (
          <Menu
            options={[
              {
                name: i18n.t('Delete'),
                icon: 'trash-can',
                theme: 'error',
                disabled: isDisabled,
                onClick: () => onDelete(item),
              },
            ]}
            placement="bottom"
            offset={[0, 0]}
          >
            <div className="b-mdf-action-btn">
              <Button
                isDisabled={isDisabled}
                style={{ color: 'var(--color-neutral-50)' }}
                kind="link"
                icon="ellipsis-vertical"
              />
            </div>
          </Menu>
        );
      },
    },
  ];
}

const BundleIdsTable = ({
  bundleIds,
  onDelete,
  onUpdate,
  onAddRow,
  isDisabled,
  validationDep,
  updateTeams,
}) => {
  const bundleIdsIdentifiers = new Array(20).fill(0).map((_, idx) => idx);
  const { refs, onInvalidate } = useInputsValidators(
    bundleIdsIdentifiers,
    updateTeams,
  );
  useRemoveValidationOnUnmount(bundleIdsIdentifiers, onUpdate);

  useEffect(() => {
    if (bundleIds) {
      bundleIdsIdentifiers.forEach((n) => {
        const curr = bundleIds[n];
        onInvalidate(n)(curr?.isInvalid);
      });
    }
  }, [bundleIds]);

  const columns = useMemo(
    () => getColumns({ isDisabled, onDelete, onUpdate, onAddRow }),
    [isDisabled],
  );

  const AddButton = () => (
    <Button
      isDisabled={isDisabled}
      kind="link"
      icon="circle-plus"
      onClick={onAddRow}
    >
      {i18n.t('Add Bundle ID')}
    </Button>
  );

  return (
    <KernelExtensionBundlesContext.Provider
      value={{ isDisabled, validationDep, refs }}
    >
      <Table
        isLoading={false}
        columns={columns}
        data={bundleIds}
        noDataMessage={
          <Flex justify="center" align="center" direction="col">
            <NoContent
              className="b-pad"
              header={i18n.t('Bundle IDs have not been configured')}
              text=""
            />
            <AddButton />
          </Flex>
        }
      />
      {bundleIds.length > 0 && (
        <div className="b-pad">
          <AddButton />
        </div>
      )}
    </KernelExtensionBundlesContext.Provider>
  );
};

BundleIdsTable.propTypes = {
  bundleIds: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onAddRow: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default BundleIdsTable;
