import {
  getRunsOnFromModel,
  getSelectedDevicesFromRunsOn,
} from 'src/features/library-items/data-service/library-item/devices';
import uuid from 'uuid';
import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import { setBlueprintsToFormData } from '../../../data-service/library-item/transformers';
import config from '../../../library/library-item-configurations/items/profile';

export const transformFromApi = async (apiData) => {
  try {
    const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
    const excludedBlueprints = await getAllBlueprintOptions(
      apiData.excluded_blueprints,
    );

    const data = apiData.data_with_file_urls || apiData.data;
    const result = {
      _data: data,
      id: apiData.id,
      name: apiData.name,
      iconSrc: apiData.app_icon,
      isActive: apiData.active,
      devices: getSelectedDevicesFromRunsOn(apiData),
      selectedBlueprints,
      excludedBlueprints,
      isAllBlueprints: apiData.is_all_blueprints,
      rules: apiData.rules,
    };

    result.general = {
      allowOpenFromManagedToUnmanaged:
        apiData.data.allowOpenFromManagedToUnmanaged,
      allowManagedToWriteUnmanagedContacts:
        apiData.data.allowManagedToWriteUnmanagedContacts,
      allowUnmanagedToReadManagedContacts:
        apiData.data.allowUnmanagedToReadManagedContacts,
      forceAirDropUnmanaged: apiData.data.forceAirDropUnmanaged,

      allowOpenFromUnmanagedToManaged:
        apiData.data.allowOpenFromUnmanagedToManaged,
      requireManagedPasteboard: apiData.data.requireManagedPasteboard,
      allowManagedAppsCloudSync: apiData.data.allowManagedAppsCloudSync,
      allowFilesNetworkDriveAccess: apiData.data.allowFilesNetworkDriveAccess,
      allowFilesUSBDriveAccess: apiData.data.allowFilesUSBDriveAccess,
    };

    result.managedDomains = {
      isUsingManagedDomains: apiData.data.isUsingManagedDomains,
      domains: apiData.data.managedDomains.map((domain) => ({
        ...domain,
        id: uuid(),
        isInvalid: false,
      })),
    };

    return {
      ...apiData,
      data: result,
    };
  } catch (e) {
    console.error(e);
    throw e.message;
  }
};

export const transformToApi = (model) => {
  const itemConfig = config['Managed Data Flow'];
  const toSend = new FormData();
  toSend.set('active', model.isActive);
  toSend.set('name', model.name);
  toSend.set('type', itemConfig.type);
  toSend.set('identifier', itemConfig.identifier);
  toSend.set('reassign_lib_item_to_bp', 'true');
  toSend.set('rules', JSON.stringify(model.rules) || null);

  setBlueprintsToFormData(toSend, model.selectedBlueprints);
  toSend.set('is_all_blueprints', model.isAllBlueprints);

  if (model.iconFile) {
    toSend.set('app_icon', model.iconFile);
  }
  if (!model.iconSrc) {
    toSend.set('app_icon', '');
  }

  const managedDomains = model.managedDomains.domains.map(
    ({ name, isEmailDomain, isManagedDomain, isSafariPassword }) => {
      return {
        name,
        isEmailDomain,
        isManagedDomain,
        isSafariPassword,
      };
    },
  );

  // Populate `runs_on_*` fields
  const runsOn = getRunsOnFromModel(model);
  Object.keys(runsOn).forEach((runsOnKey) => {
    toSend.set(runsOnKey, runsOn[runsOnKey]);
  });

  const data = {
    ...model.general,
    isUsingManagedDomains:
      model.managedDomains.isUsingManagedDomains && !!managedDomains.length,
    managedDomains,
  };

  if (model.skip_blueprint_conflict) {
    toSend.set('skip_blueprint_conflict', model.skip_blueprint_conflict);
  }

  toSend.set('data', JSON.stringify(data));
  return toSend;
};
