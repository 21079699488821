import { useEffect } from 'react';

const useAddBodyClass = (className: string, enabled: boolean = true) => {
  useEffect(() => {
    if (enabled) {
      document.body.classList.add(className); // define classes in _layout.scss
    }

    return () => {
      if (enabled) {
        document.body.classList.remove(className);
      }
    };
  }, [className, enabled]);
};

export default useAddBodyClass;
