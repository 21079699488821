import type { AllowedSelectValue } from '@kandji-inc/nectar-ui';
import { Select } from '@kandji-inc/nectar-ui';
import { Setting } from 'features/library-items/template';
/* istanbul ignore file */
import type React from 'react';
import type { CheckboxRowProps } from './CheckboxRow';
import CheckboxRow from './CheckboxRow';

export type SelectRowProps = {
  options: Array<{ label: string; value: AllowedSelectValue }>;
  onSelectChange: (value: AllowedSelectValue) => void;
  value: AllowedSelectValue;
  selectChildren?: React.ReactNode;
} & CheckboxRowProps;

const SelectRow = (props: SelectRowProps) => {
  const { isDisabled, options, onSelectChange, value, selectChildren } = props;

  return (
    <CheckboxRow {...props}>
      {(isChecked) =>
        isChecked && (
          <Setting.SecondaryControls>
            <Setting.SecondaryControlsRow>
              <Setting.Controls>
                <Select
                  disabled={isDisabled}
                  options={options}
                  value={value}
                  onChange={onSelectChange}
                  testId="select-row-select"
                />
              </Setting.Controls>
            </Setting.SecondaryControlsRow>
            {selectChildren}
          </Setting.SecondaryControls>
        )
      }
    </CheckboxRow>
  );
};

export default SelectRow;
