import type React from 'react';
import { useMemo, useRef } from 'react';

import { Button, setClass } from '@kandji-inc/bumblebee';
import type { SomeReadonlyRecord } from '@shared/types/util.types';

import type { IconNames } from '@kandji-inc/bumblebee/lib/atoms/icon/IconNames';
import type { ActionsMenuColProps, AllowedColName } from '../../table.types';
import ActionsMenu from './ActionsMenu';
import Col from './Col';

const ActionsMenuCol = <Data extends SomeReadonlyRecord[]>(
  props: ActionsMenuColProps<Data>,
) => {
  const {
    colName = '__actions-menu-col__',
    colType,
    colNum,
    rowNum,
    rowData,
    isLastCol,
    isRowExpanded,
    width = 'auto',
    className,
    style,
    children,
    menuOptions,
    menuPlacement = 'bottom-end',
    menuToggleIcon = 'ellipsis',
    menuToggleClassName,
    menuPopoverProps,
    actionsMenuClassName,
    actionsMenuStyle,
    isVisible = true,
    appendTo,
    reference,
    tableRef,
    tableMetaRef,
    zIndex,
    isDisabled,
  } = props;
  const toggleRef = useRef<HTMLButtonElement>(null);
  const isShown =
    typeof isVisible === 'function' ? isVisible(rowData) : isVisible;
  const computedMenuOptions = useMemo(
    () =>
      typeof menuOptions === 'function' ? menuOptions(rowData) : menuOptions,
    [menuOptions, rowData],
  );
  const visibilityStyle = useMemo(
    () => ({
      // use `visibility` CSS property instead of conditionally render the
      // `ActionMenu` toggle to fill all column grid space for table layout
      // consistency
      visibility: isShown ? ('visible' as const) : ('hidden' as const),
    }),
    [isShown],
  );
  return (
    <Col
      colName={colName as AllowedColName}
      colType={colType}
      colNum={colNum}
      rowNum={rowNum}
      rowData={rowData}
      isLastCol={isLastCol}
      isRowExpanded={isRowExpanded}
      width={width}
      className={className}
      style={style}
      component={
        (
          <ActionsMenu
            data={rowData}
            menuOptions={computedMenuOptions}
            menuPlacement={menuPlacement}
            menuPopoverProps={menuPopoverProps}
            className={actionsMenuClassName}
            style={actionsMenuStyle}
            appendTo={appendTo}
            reference={reference}
            tableMetaRef={tableMetaRef}
            toggleRef={toggleRef}
            zIndex={zIndex}
            isDisabled={isDisabled}
          >
            <div
              data-testid="action-ellipsis"
              style={visibilityStyle}
              aria-haspopup="menu"
            >
              {children || (
                <Button
                  innerRef={toggleRef}
                  kind="link"
                  icon={menuToggleIcon as IconNames}
                  className={setClass(
                    'actions-menu__toggle-btn',
                    menuToggleClassName,
                  )}
                  disabled={isDisabled}
                />
              )}
            </div>
          </ActionsMenu>
        ) as unknown as React.ReactElement
      }
    />
  );
};

ActionsMenuCol.displayName = 'ActionsMenuCol';

export default ActionsMenuCol;
