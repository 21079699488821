import { getAllBlueprintOptions } from 'features/library-items/data-service/blueprint/use-blueprint-service';
import configs from 'features/library-items/library/library-item-configurations/items/profile';

import type { PrivacyModel } from '../privacy.types';

interface PrivacyApiData {
  id: string;
  name: string;
  active: boolean;
  blueprints: ReadonlyArray<string>;
  excluded_blueprints: ReadonlyArray<string>;
  is_all_blueprints: boolean;
  rules: object | null;
  skip_blueprint_conflict: boolean;
  data: PrivacyModel['appAccess'];
}

async function transformFromApi(apiData: PrivacyApiData) {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const commonData = {
    id: apiData.id,
    name: apiData.name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
  };

  const result = {
    ...commonData,
    isActive: apiData.active,
    rules: apiData.rules,
    appAccess: apiData.data,
  };

  return {
    ...apiData,
    data: result,
  };
}

function transformToApi(model: PrivacyModel) {
  const toSend = {
    name: model.name,
    active: model.isActive,
    blueprints: model.selectedBlueprints.map((b) => b.value),
    excluded_blueprints: model.excludedBlueprints.map((b) => b.value),
    is_all_blueprints: model.isAllBlueprints,
    rules: model.rules || null,
    data: model.appAccess,
  };

  if (!model.id) {
    const itemConfig = configs.Privacy;

    Object.defineProperties(toSend, {
      type: {
        value: itemConfig.type,
        writable: false,
        enumerable: true,
        configurable: false,
      },
      identifier: {
        value: itemConfig.identifier,
        writable: false,
        enumerable: true,
        configurable: false,
      },
    });
  }

  if (model.skip_blueprint_conflict) {
    return Object.assign(toSend, {
      skip_blueprint_conflict: model.skip_blueprint_conflict,
    });
  }

  return toSend;
}

export { transformFromApi, transformToApi };
