import { getAllBlueprintOptions } from 'features/library-items/data-service/blueprint/use-blueprint-service';
import configs from 'features/library-items/library/library-item-configurations/items/profile';
import {
  getRunsOnFromModel,
  getSelectedDevicesFromRunsOn,
} from 'src/features/library-items/data-service/library-item/devices';

import { generateRowConfig } from '../sections/configurations/rows.config';
import VpnService from './vpn.service';

async function transformFromOldApi(apiData, preset) {
  const result = {
    ...preset,
    isActive: apiData.is_active,
    rules: apiData.rules,
  };

  result.configurations = {
    vpnConfigurations: apiData.data.map(generateRowConfig),
  };

  return result;
}

function transformToOldApi(model) {
  const result = {
    name: model.name,
    is_active: model.isActive,
    blueprints: model.selectedBlueprints.map((b) => b.value),
    is_all_blueprints: model.isAllBlueprints,
    rules: model.rules || null,
  };

  // Populate `runs_on_*` fields
  const runsOn = getRunsOnFromModel(model);
  Object.keys(runsOn).forEach((runsOnKey) => {
    result[runsOnKey] = runsOn[runsOnKey];
  });

  if (!model.id) {
    const itemConfig = configs.VPN;
    result.type = itemConfig.type;
    result.identifier = itemConfig.identifier;
  }

  result.settings = model.configurations.vpnConfigurations.map((config) => {
    const configuration = config;
    delete configuration.id;
    delete configuration.SharedSecretVerify;
    delete configuration.ProxyPasswordVerify;
    delete configuration.PasswordVerify;

    // Values must be null, not empty string.
    configuration.Account = configuration.Account || null;
    configuration.Password = configuration.Password || null;
    configuration.ProxyAccount = configuration.ProxyAccount || null;
    configuration.ProxyPassword = configuration.ProxyPassword || null;
    configuration.Url_to_autoconfig_file =
      configuration.Url_to_autoconfig_file || null;
    configuration.Server = configuration.Server || null;

    if (configuration.Port !== null) {
      configuration.Port = parseInt(configuration.Port, 10);
    }

    configuration.ProxySetup =
      configuration.ProxySetup?.value || VpnService.Proxys().NONE.value;
    configuration.VPNType =
      configuration.VPNType?.value ||
      VpnService.VPNTypes().L2TP_OVER_IPSEC.value;

    // Time fields for secrets/password updated at. The backend sets its own
    // time in these fields if the actual respective password fields are set
    const pwdLastUpdatedFields = [
      {
        key: 'SharedSecret',
        lastUpdatedKey: 'SHARED_SECRET_UPDATED_AT',
      },
      {
        key: 'Password',
        lastUpdatedKey: 'PASSWORD_UPDATED_AT',
      },
      {
        key: 'ProxyPassword',
        lastUpdatedKey: 'PROXY_PASSWORD_UPDATED_AT',
      },
    ];
    pwdLastUpdatedFields.forEach(({ lastUpdatedKey }) => {
      configuration[lastUpdatedKey] = null;
    });

    return { ...configuration };
  });

  return result;
}

async function transformFromNewApi(apiData, preset) {
  const result = {
    ...(await transformFromOldApi(apiData, preset)),
    isActive: apiData.active,
  };

  return result;
}

function transformToNewApi(model) {
  const toSend = transformToOldApi(model);
  toSend.active = toSend.is_active;
  delete toSend.is_active;
  toSend.data = toSend.settings;
  delete toSend.settings;

  return toSend;
}

async function transformFromApi(apiData) {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const commonData = {
    id: apiData.id,
    name: apiData.name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
    devices: getSelectedDevicesFromRunsOn(apiData),
    rules: apiData.rules,
  };

  const transformer = transformFromNewApi;

  const result = await transformer(apiData, commonData);

  return {
    ...apiData,
    data: result,
  };
}

function transformToApi(model) {
  const transformer = transformToNewApi;

  return transformer(model);
}

export { transformToApi, transformFromApi };
