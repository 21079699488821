import { Flex, Icon, Text, styled } from '@kandji-inc/nectar-ui';
import React from 'react';
import type { ColumnItemProps } from '../PrismColumnEditor';

// TODO: I promise we'll get to making a proper IconButton component in nectar-ui
const IconButton = styled('button', {
  border: 'none',
  outline: 'none',
  display: 'flex',
  alignItems: 'center',
  gap: '$1',
  color: '$button_subtle_icon',
  background: 'transparent',
  borderRadius: '$1',
  '& svg': {
    width: '20px',
    height: '20px',
  },
});

export const ColumnItem = ({
  displayName,
  onIconClick,
  pinned,
  icon,
}: ColumnItemProps) => (
  <Flex alignItems="center" wFull>
    <Flex alignItems="center" gap="sm" flex="1">
      {pinned && <Icon name="fa-thumbtack" size="sm" />}

      <Text
        css={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: '260px',
        }}
        title={displayName}
      >
        {displayName}
      </Text>
    </Flex>

    {!pinned && (
      <IconButton>
        <Icon name={icon} onClick={onIconClick} />
      </IconButton>
    )}
  </Flex>
);
