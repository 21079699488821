import { editor } from './action-types';

export const setEditor = (html) => ({
  type: editor.SET_EDITOR,
  html,
});

export const clearEditor = () => ({
  type: editor.CLEAR_EDITOR,
});
