import React, { useCallback, useContext, useEffect } from 'react';

import { Banner, updateSetting as update } from '@kandji-inc/bumblebee';
import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import ActivityTab from 'features/library-items/common/activity-tab';
import NewStatusTab from 'features/library-items/common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from 'features/library-items/common/new-status-tab/util';
import featureFlags from 'src/config/feature-flags';
import { i18n } from 'src/i18n';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import { populateSelectedDevices } from '../../data-service/library-item/devices';
import LibraryContext from '../../routes/library.context';
import initial from './initial-state';
import './passcode.css';
import DeviceFamilySettings from './sections/DeviceFamilySettings';
import PasscodeService, { passcodeService } from './service/passcode-service';
import { transformFromApi, transformToApi } from './service/transformers';
import usePasscodeService from './service/use-passcode-service';

const PasscodePage = (props) => {
  const { model, setModel, pageState } = props;
  const [, triggerValidation] = useUniqValue();

  const macUpdate = useCallback(update('Mac', setModel), []);
  const iPhoneUpdate = useCallback(update('iPhone', setModel), []);
  const iPadUpdate = useCallback(update('iPad', setModel), []);

  const { itemConfig } = useContext(LibraryContext);

  useEffect(() => populateSelectedDevices(model, setModel, itemConfig), []);

  const installPasscodeProfileEnabled = featureFlags.getFlag(
    'core-install-passcode-profile',
  );

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.getName()}
      summaryInfoProps={{
        name: `${itemConfig.getName()} ${i18n.t('Profile')}`,
        description: (
          <div>
            {itemConfig.getDescription()}
            {installPasscodeProfileEnabled && (
              <Banner
                className="passcode-info-banner"
                theme="info"
                kind="block"
              >
                <p>
                  {i18n.t(
                    'This library item will be installed prior to releasing the user from the Automated Device Enrollment screen, before user account creation on macOS or passcode creation on iOS.',
                  )}
                </p>
              </Banner>
            )}
          </div>
        ),
        publisher: itemConfig.getPublisher(),
        devices: itemConfig.devices,
        requirements: itemConfig.getOsRequirements(),
      }}
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={passcodeService}
      defaultIcon={itemConfig.icon}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      supportsRules
      supportsInstallOn
      supportsDuplication
    >
      <DeviceFamilySettings
        settings={model.Mac}
        isDisabled={pageState.isDisabled}
        update={macUpdate}
        deviceFamily={i18n.t('Mac')}
        checkboxFields={PasscodeService.MAC_CHECKBOX_FIELDS()}
      />
      <DeviceFamilySettings
        settings={model.iPhone}
        isDisabled={pageState.isDisabled}
        update={iPhoneUpdate}
        deviceFamily={i18n.t('iPhone')}
        checkboxFields={PasscodeService.IPHONE_CHECKBOX_FIELDS()}
      />
      <DeviceFamilySettings
        settings={model.iPad}
        isDisabled={pageState.isDisabled}
        update={iPadUpdate}
        deviceFamily={i18n.t('iPad')}
        checkboxFields={PasscodeService.IPAD_CHECKBOX_FIELDS()}
      />
    </LibraryItemPage>
  );
};

// PasscodePage.propTypes = {};

const Passcode = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState: initial(),
    useService: usePasscodeService,
  });

  const blueprintConflicts = useBlueprintConflicts();

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <PasscodePage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
      testId="passcode-v2"
    />
  );
};

export default Passcode;
