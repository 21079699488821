/* istanbul ignore file */
import { Flex } from '@kandji-inc/nectar-ui';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import {
  clearComputer,
  getComputer,
  getComputerMDMAgentLastCheckIn,
} from '../_actions/computer';
import { setEditor } from '../_actions/editor';
import SingleDeviceHeader from './computer/SingleDeviceHeader';
import SingleDeviceTabs from './computer/SingleDeviceTabs';
import { Loader } from './interface/Loader';

const SingleDevicePage = (props) => {
  const { setEditor } = props;
  const { id: computerId } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoadingComputer, setIsLoadingComputer] = useState(true);

  useEffect(() => {
    const oldBodyBgColor = document.body.style.backgroundColor;
    document.body.style.backgroundColor = '#fff';

    return () => {
      document.body.style.backgroundColor = oldBodyBgColor;
    };
  }, []);

  useEffect(() => {
    setIsLoadingComputer(true);
    Promise.all([
      dispatch(getComputer(computerId)),
      dispatch(getComputerMDMAgentLastCheckIn(computerId)),
    ])
      .catch(() => history.push('/whoops'))
      .finally(() => setIsLoadingComputer(false));

    return () => {
      clearComputer();
    };
  }, []);

  if (isLoadingComputer) {
    return <Loader />;
  }

  return (
    // The composition here is what allows the header sticky overlap. When
    // putting these elements in separate containers the stacking context makes
    // it impossible to get the effect.
    <SingleDeviceHeader>
      <SingleDeviceTabs setEditor={setEditor} />
    </SingleDeviceHeader>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setEditor,
      getComputer,
      getComputerMDMAgentLastCheckIn,
      clearComputer,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(SingleDevicePage);
export { SingleDevicePage };
