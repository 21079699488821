/* istanbul ignore file */
import React, { memo, useEffect } from 'react';

import { useInputsValidators } from '@kandji-inc/bumblebee';
import { Flex, Text, TextArea, TextField } from '@kandji-inc/nectar-ui';
import { Setting } from 'src/features/library-items/template';
import { i18n } from 'src/i18n';
import type { GeneralProps } from '../../scep.types';

const General = (props: GeneralProps) => {
  const { update, isDisabled, isSubmitted, settings } = props;

  const fieldsToValidate = ['URL'];
  const { refs, onInvalidate, invalidations } = useInputsValidators(
    fieldsToValidate,
    update,
  );

  useEffect(() => {
    onInvalidate('URL')(!settings.URL);
  }, [settings.URL]);

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('General')}</h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Title>
            <Flex gap="xs" ref={refs.URL}>
              <Text>{i18n.t('URL')}</Text>
              <Text variant="description">{i18n.t('(required)')}</Text>
            </Flex>
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t('The base URL for the SCEP server.')}
            </p>
          </Setting.Helpers>
          <Setting.Controls>
            <TextField
              css={{ maxWidth: '400px' }}
              value={settings.URL}
              onChange={(e) => update('URL', e.target.value)}
              disabled={isDisabled}
              placeholder="scep.accuhive.io"
              state={invalidations[0] && isSubmitted ? 'error' : undefined}
            />
          </Setting.Controls>
        </Setting.Row>

        <Setting.Row>
          <Setting.Title>
            <Flex gap="xs">
              <Text>{i18n.t('Name')}</Text>
              <Text variant="description">{i18n.t('(optional)')}</Text>
            </Flex>
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t(
                "A string that's understood by the SCEP server; for example, a domain name like example.org. If a certificate authority has multiple CA certificates, this field can be used to distinguish which is required.",
              )}
            </p>
          </Setting.Helpers>
          <Setting.Controls>
            <TextField
              css={{ maxWidth: '400px' }}
              value={settings.Name}
              onChange={(e) => update('Name', e.target.value)}
              disabled={isDisabled}
              placeholder="ca2.accuhive.io"
            />
          </Setting.Controls>
        </Setting.Row>

        <Setting.Row>
          <Setting.Title>
            <Flex gap="xs">
              <Text>{i18n.t('Challenge')}</Text>
              <Text variant="description">{i18n.t('(optional)')}</Text>
            </Flex>
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t('A pre-shared secret used for automatic enrollment.')}
            </p>
          </Setting.Helpers>
          <Setting.Controls>
            <TextField
              css={{ maxWidth: '400px' }}
              value={settings.Challenge}
              onChange={(e) => update('Challenge', e.target.value)}
              disabled={isDisabled}
              placeholder="Gz05FXUqZROYox:{SRrB@"
            />
          </Setting.Controls>
        </Setting.Row>

        <Setting.Row>
          <Setting.Title>
            <Flex gap="xs">
              <Text>{i18n.t('Fingerprint')}</Text>
              <Text variant="description">{i18n.t('(optional)')}</Text>
            </Flex>
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t(
                'The fingerprint (hex string) of the Certificate Authority certificate.',
              )}
            </p>
          </Setting.Helpers>
          <Setting.Controls>
            <TextArea
              css={{ maxWidth: '400px', '& textarea': { minHeight: '120px' } }}
              value={settings.CAFingerprint}
              onChange={(e) => update('CAFingerprint', e.target.value)}
              readOnly={isDisabled}
              placeholder="58 0F 80 47 92 AB C6 3B BB 80 15 4D 4D FD DD 8B 2E F2 67 4E"
            />
          </Setting.Controls>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default memo(General);
