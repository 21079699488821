import { Button, Flex, Loader, Text } from '@kandji-inc/nectar-ui';

export const Pending = () => (
  <Flex flow="column" gap="lg" alignItems="center" css={{ padding: '48px 0' }}>
    <Loader isShown size="lg" />
    <Text
      css={{
        fontWeight: '$medium',
        color: '$neutral90',
        textAlign: 'center',
      }}
    >
      Fetching device results now...
    </Text>
    {/* TODO: get the copy <Text
        css={{
          color: '$neutral70',
          textAlign: 'center',
        }}
      >
        Lorem ipsum dolor sit amet consectetur. Pellentesque in ornare dolor
        molestie adipiscing viverra posuere in.
      </Text> */}
  </Flex>
);

export const NoResultsReturned = ({ onRerun }: { onRerun?: () => void }) => (
  <Flex
    flow="column"
    gap="lg"
    alignItems="center"
    justifyContent="center"
    css={{ padding: '48px 0', width: '712px', margin: '0 auto' }}
  >
    <NoResultsFoundIcon />
    <Text
      css={{
        fontWeight: '$medium',
        color: '$neutral90',
        textAlign: 'center',
      }}
    >
      No devices returned a match for this search
    </Text>
    <Text
      css={{
        color: '$neutral70',
        textAlign: 'center',
      }}
    >
      All devices targeted for this report were queried and did not return a
      match to the file you were searching for. Check the status tab to view
      more details about the devices targeted in the report.
    </Text>
    <Flex gap="sm">
      {/* <Button>Modify report configuration</Button> */}
      <Button variant="primary" onClick={() => onRerun()}>
        Re-run report
      </Button>
    </Flex>
  </Flex>
);

export const NoResultsFoundIcon = () => (
  <svg
    width="164"
    height="120"
    viewBox="0 0 164 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_314_116705)">
      <path
        d="M4.60416 61.4799C3.65655 62.027 3.18617 62.7453 3.19301 63.4624H3.1875V68.0255H3.19733C3.24689 68.6973 3.71584 69.3606 4.60416 69.8734L52.2614 97.3884C54.143 98.4747 57.1938 98.4747 59.0754 97.3884L137.373 52.1834C138.281 51.6588 138.751 50.9769 138.782 50.2896H138.784V45.7264C138.771 45.0247 138.3 44.3253 137.373 43.7899L89.7155 16.2749C87.8339 15.1886 84.7831 15.1886 82.9015 16.2749L4.60416 61.4799Z"
        fill="#BCC9DE"
      />
      <path
        d="M11.459 71.6413C11.459 71.3247 11.2367 70.9396 10.9625 70.7813L7.98332 69.0613C7.7091 68.903 7.4868 69.0313 7.4868 69.348C7.4868 69.6646 7.7091 70.0497 7.98332 70.208L10.9625 71.928C11.2367 72.0863 11.459 71.958 11.459 71.6413Z"
        fill="white"
      />
      <path
        d="M16.4785 74.5743C16.4785 74.2576 16.2562 73.8726 15.982 73.7143L13.0029 71.9943C12.7286 71.8359 12.5063 71.9643 12.5063 72.2809C12.5063 72.5976 12.7286 72.9826 13.0029 73.1409L15.982 74.8609C16.2562 75.0193 16.4785 74.8909 16.4785 74.5743Z"
        fill="white"
      />
      <path
        d="M6.03811 66.2433C3.36458 64.6997 3.36459 62.1971 6.03812 60.6536L81.4678 17.1042C84.1413 15.5607 88.4759 15.5607 91.1495 17.1042L135.939 42.9635C138.613 44.5071 138.613 47.0097 135.939 48.5532L60.5094 92.1026C57.8358 93.6461 53.5012 93.6461 50.8277 92.1026L6.03811 66.2433Z"
        fill="#D9D9D9"
      />
      <path
        d="M4.66137 65.4484C2.7482 64.3439 2.7482 62.553 4.66137 61.4484L82.8445 16.3094C84.7577 15.2048 87.8596 15.2048 89.7727 16.3094L136.346 43.1984C138.795 44.6122 138.795 46.9045 136.346 48.3184L59.1326 92.8974C57.2195 94.002 54.1176 94.002 52.2044 92.8974L4.66137 65.4484Z"
        fill="#ECF1F7"
      />
      <g filter="url(#filter0_d_314_116705)">
        <path
          d="M33.5672 60.6475V63.899C33.5453 64.6049 34.0014 65.3136 34.9338 65.8521L102.566 104.9C104.388 105.951 107.342 105.951 109.164 104.9L158.239 76.5664C159.199 76.0121 159.657 75.2764 159.605 74.551V71.2995C159.593 70.6196 159.137 69.9425 158.239 69.4236L90.6066 30.3761C88.7845 29.3242 85.8304 29.3242 84.0083 30.3761L34.9338 58.7093C34.008 59.2441 33.5523 59.9472 33.5672 60.6475Z"
          fill="#BCC9DE"
        />
        <path
          d="M35.0989 62.6131C33.1857 61.5085 33.1857 59.7176 35.0989 58.6131L82.2077 31.4148C85.0243 29.7886 89.5909 29.7886 92.4075 31.4148L158.074 69.3273C159.987 70.4319 159.987 72.2227 158.074 73.3273L110.965 100.526C108.149 102.152 103.582 102.152 100.765 100.526L35.0989 62.6131Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83.5933 32.2148L36.4845 59.4131C35.3366 60.0758 35.3366 61.1503 36.4845 61.8131L102.151 99.7256C104.202 100.91 107.528 100.91 109.579 99.7256L156.688 72.5273C157.836 71.8646 157.836 70.79 156.688 70.1273L91.0219 32.2148C88.9705 31.0304 85.6447 31.0304 83.5933 32.2148ZM35.0989 58.6131C33.1857 59.7176 33.1857 61.5085 35.0989 62.6131L100.765 100.526C103.582 102.152 108.149 102.152 110.965 100.526L158.074 73.3273C159.987 72.2227 159.987 70.4319 158.074 69.3273L92.4075 31.4148C89.5909 29.7886 85.0243 29.7886 82.2077 31.4148L35.0989 58.6131Z"
          fill="#BCC9DE"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.1914 42.7891H86.1958C86.191 43.8585 86.8951 44.9287 88.3082 45.7446L129.05 69.2666C131.866 70.8927 136.433 70.8927 139.249 69.2666L157.704 58.6118C159.028 57.8473 159.73 56.8595 159.809 55.8583H159.816V55.6805C159.816 55.6718 159.816 55.663 159.816 55.6543V51.6736C159.825 50.6017 159.12 49.5284 157.704 48.7106L116.963 25.1885C114.146 23.5624 109.579 23.5624 106.763 25.1885L88.3083 35.8433C86.8993 36.6567 86.1951 37.723 86.1958 38.7892H86.1914V42.7891Z"
        fill="#BCC9DE"
      />
      <path
        d="M88.3069 41.7365C85.4903 40.1103 85.4903 37.4738 88.307 35.8476L106.762 25.1929C109.578 23.5667 114.145 23.5667 116.961 25.1929L157.703 48.7149C160.519 50.3411 160.519 52.9776 157.703 54.6038L139.248 65.2585C136.432 66.8847 131.865 66.8847 129.048 65.2585L88.3069 41.7365Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.147 25.9929L89.6926 36.6476C87.6412 37.832 87.6412 39.7521 89.6926 40.9365L130.434 64.4585C132.485 65.6428 135.811 65.6428 137.862 64.4585L156.317 53.8038C158.368 52.6194 158.368 50.6992 156.317 49.5149L115.576 25.9929C113.524 24.8085 110.198 24.8085 108.147 25.9929ZM88.307 35.8476C85.4903 37.4738 85.4903 40.1103 88.3069 41.7365L129.048 65.2585C131.865 66.8847 136.432 66.8847 139.248 65.2585L157.703 54.6038C160.519 52.9776 160.519 50.3411 157.703 48.7149L116.961 25.1929C114.145 23.5667 109.578 23.5667 106.762 25.1929L88.307 35.8476Z"
        fill="#BCC9DE"
      />
      <path
        d="M101.07 51.2087C101.07 50.9877 101.225 50.8982 101.417 51.0087L104.696 52.9019C104.887 53.0124 105.042 53.2811 105.042 53.5021C105.042 53.7231 104.887 53.8126 104.696 53.7021L101.417 51.8089C101.225 51.6984 101.07 51.4297 101.07 51.2087Z"
        fill="white"
      />
      <path
        d="M96.4512 48.4656C96.4512 48.2446 96.6063 48.1551 96.7977 48.2656L100.077 50.1588C100.268 50.2693 100.423 50.538 100.423 50.759C100.423 50.98 100.268 51.0695 100.077 50.959L96.7977 49.0658C96.6063 48.9553 96.4512 48.6866 96.4512 48.4656Z"
        fill="white"
      />
      <path
        d="M93.0117 46.5209C93.0117 46.2999 93.1669 46.2103 93.3583 46.3208L94.6513 47.0674C94.8427 47.1779 94.9978 47.4466 94.9978 47.6676C94.9978 47.8885 94.8427 47.9781 94.6513 47.8676L93.3583 47.1211C93.1669 47.0106 93.0117 46.7419 93.0117 46.5209Z"
        fill="white"
      />
      <path
        d="M99.3446 34.108C98.7962 33.7914 98.7962 33.278 99.3446 32.9613L105.303 29.5213C105.851 29.2047 106.741 29.2047 107.289 29.5213C107.837 29.838 107.837 30.3513 107.289 30.668L101.331 34.108C100.782 34.4246 99.8931 34.4246 99.3446 34.108Z"
        fill="#BCC9DE"
      />
      <path
        d="M146.879 62.7749C146.879 62.4582 147.101 62.0732 147.375 61.9149L150.355 60.1949C150.629 60.0366 150.851 60.1649 150.851 60.4815C150.851 60.7982 150.629 61.1832 150.355 61.3415L147.375 63.0616C147.101 63.2199 146.879 63.0915 146.879 62.7749Z"
        fill="white"
      />
      <path
        d="M132.781 89.5209C132.781 89.2042 133.004 88.8192 133.278 88.6609L136.257 86.9409C136.531 86.7825 136.753 86.9109 136.753 87.2275C136.753 87.5442 136.531 87.9292 136.257 88.0875L133.278 89.8075C133.004 89.9659 132.781 89.8375 132.781 89.5209Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_314_116705"
        x="25.5664"
        y="23.5872"
        width="142.043"
        height="92.1013"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0588235 0 0 0 0 0.0745098 0 0 0 0 0.0901961 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_314_116705"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_314_116705"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_314_116705">
        <rect
          width="163.2"
          height="120"
          fill="white"
          transform="translate(0.400391)"
        />
      </clipPath>
    </defs>
  </svg>
);
