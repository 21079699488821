import { styled } from '@kandji-inc/nectar-ui';
import type * as React from 'react';

import { Setting as LibraryItemSettingComponents } from 'features/library-items/template';

type TSetting = typeof LibraryItemSettingComponents;

type SettingComponentName = keyof typeof LibraryItemSettingComponents;

type SettingComponentType = Exclude<
  (typeof StyledSettingTuple)[number][number],
  string
>;

const StyledSettingTuple = Object.entries(LibraryItemSettingComponents).map(
  ([componentName, Component]: [
    SettingComponentName,
    React.ComponentType<{ className?: string }>,
  ]) => [componentName, styled(Component)],
);

type StyledSettingComponent = {
  [$key in keyof TSetting]: React.ComponentType<
    React.ComponentProps<TSetting[$key]>
  > &
    SettingComponentType;
};

export const StyledSetting: StyledSettingComponent =
  Object.fromEntries(StyledSettingTuple);
