import get from 'lodash/get';
import { createReducer } from 'redux-create-reducer';
import {
  blueprint,
  computer,
  depComputer,
  params as paramsActions,
} from '../_actions/action-types';
import { ParameterType } from '../common/constants';
import { sortSubCategories } from '../components/common/helpers';

const initialState = {
  computers: [],
  blueprints: [],
  blueprintNames: {},
  blueprintParams: {},
  blueprintParamsInitial: { parameters: [] },
  blueprintIsReady: false,
  parameterMeta: {},
  DEPComputers: [],
  scrollY: null,
  fetched: {
    computers: true,
    blueprints: false,
  },
  blueprintMessages: null,
};

const initBlueprint = (state) => {
  let initialValues = [];
  const params = JSON.parse(JSON.stringify(state.parameterMeta));
  const blParams = state.blueprintParams;

  Object.keys(params).forEach((param) => {
    const paramActivated = !!blParams[param];
    const helper = {
      ...params[param],
      isOn: paramActivated,
      details: paramActivated ? blParams[param].details : undefined,
      mute: paramActivated ? blParams[param].mute || false : false, // ???
      daily: paramActivated ? blParams[param].daily || false : false, // ???
    };
    if (param === '135b1c65-a665-431b-b919-39e984d6d29d' && !blParams[param]) {
      helper.mute = params[param].default.is_mute;
    }
    if (param === '1636e014-eee6-4858-b508-24594a4b521d' && !blParams[param]) {
      helper.mute = params[param].default.is_mute;
    }
    if (helper.input) {
      if (helper.input.type === 'number' || helper.input.type === 'numberV2') {
        const options = helper.input.options || {};
        const minValue = options.min || 0;
        helper.details = helper.details || helper.default.value || minValue;
      }
      if (helper.input.type === 'text') {
        helper.details = helper.details || helper.default.value;
      }
      if (helper.input.type === 'select') {
        const optionHelper = helper.input.options || {};
        const optionArray = Object.keys(optionHelper).map((title) => [
          title,
          optionHelper[title],
        ]);
        optionArray.sort((a, b) => a[1] - b[1]);
        helper.input.options = optionArray.map((el) => ({
          value: el[1],
          label: el[0],
        }));
        helper.details = helper.details || helper.default.value;
      }
      if (helper.input.type === 'checkbox') {
        helper.details = get(helper, 'details', helper.default.value);
      }
      if (helper.input.type === 'path' && !helper.details) {
        helper.details = helper.default.value || [];
      }
      if (helper.input.type === 'multi_user' && !helper.details) {
        helper.details = [''];
      }
      if (helper.input.type === 'text_or_file') {
        const optionHelper = helper.input.options || {};
        const optionArray = Object.keys(optionHelper).map((title) => [
          title,
          optionHelper[title],
        ]);
        optionArray.sort((a, b) => a[1] - b[1]);
        helper.input.options = optionArray.map((el) => ({
          value: el[1],
          label: el[0],
        }));
        helper.details = helper.details || helper.default.value;
        !helper.details.value
          ? (helper.details.value = helper.default.value.value)
          : null;
      }
      if (
        helper.input.type === 'multi_value' ||
        helper.input.type === 'application_blacklisting' ||
        helper.input.type === 'retention_for_security_auditing' ||
        helper.input.type === 'ntp_server' ||
        helper.input.type === 'hot_corner' ||
        helper.input.type === 'security_auditing_flags' ||
        helper.input.type === ParameterType.set_computer_name ||
        helper.input.type === 'secure_wifi_settings'
      ) {
        helper.details = helper.details || helper.default.value;
      } else if (helper.input.type === 'multi_array') {
        helper.details = helper.details || [];
      }
      if (helper.input.type === 'disable_with_restrictions') {
        const optionHelper = helper.input.options || {};
        const optionArray = Object.keys(optionHelper).map((title) => [
          title,
          optionHelper[title],
        ]);
        optionArray.sort((a, b) => a[1] - b[1]);
        helper.input.options = optionArray.map((el) => ({
          value: el[1],
          label: el[0],
        }));
        helper.details = {
          type: helper.details ? '1' : '0',
          ...(helper.details || { users: [], groups: [] }),
        };
      }
    }
    initialValues.push(helper);
  });

  initialValues = initialValues.sort(sortSubCategories);
  return { parameters: initialValues };
};

export default createReducer(initialState, {
  [computer.SET_COMPUTERS](state, action) {
    return { ...state, computers: action.computers };
  },

  /* istanbul ignore next */
  [computer.SET_PAGE_SCROLL](state, action) {
    return { ...state, scrollY: action.scrollY };
  },
  [depComputer.SET_DEP_COMPUTERS](state, action) {
    return { ...state, DEPComputers: action.DEPComputers };
  },
  [blueprint.CLEAR_BLUEPRINT_PARAMS](state) {
    return {
      ...state,
      blueprintParams: {},
      blueprintParamsInitial: { parameters: [] },
      blueprintIsReady: false,
      isBlueprintShowedFirstly: false,
    };
  },
  [blueprint.GET_BLUEPRINT_PARAMS](state) {
    return { ...state, blueprintIsReady: false };
  },

  [blueprint.SET_BLUEPRINT_MESSAGE](state, action) {
    return {
      ...state,
      blueprintMessages: {
        ...state.blueprintMessages,
        [action.payload.blueprintId]: action.payload.message,
      },
    };
  },

  [blueprint.TURN_ON_ENROLLMENT_CODE](state, action) {
    const blueprintIndex = state.blueprints.findIndex(
      (bp) => bp.id === action.payload.blueprintId,
    );
    const newBlueprints = state.blueprints.map((bp, index) => {
      if (index !== blueprintIndex) {
        return bp;
      }
      return {
        ...bp,
        enrollment_code: {
          ...bp.enrollment_code,
          ...action.payload.data,
        },
      };
    });

    return {
      ...state,
      blueprints: newBlueprints,
    };
  },
  [blueprint.CHANGE_ENROLLMENT_CODE](state, action) {
    const blueprintIndex = state.blueprints.findIndex(
      (bp) => bp.id === action.payload.blueprintId,
    );
    const newBlueprints = state.blueprints.map((bp, index) => {
      if (index !== blueprintIndex) {
        return bp;
      }
      return {
        ...bp,
        enrollment_code: {
          ...bp.enrollment_code,
          ...action.payload.data,
        },
      };
    });

    return {
      ...state,
      blueprints: newBlueprints,
    };
  },
  [blueprint.SET_BLUEPRINT_PARAMS](state, action) {
    let { blueprintParamsInitial } = state;
    if (action.payload.initial || !!Object.keys(state.parameterMeta).length) {
      blueprintParamsInitial = initBlueprint({
        ...state,
        blueprintParams: action.payload.params,
      });
    }
    return {
      ...state,
      blueprintParams: action.payload.params,
      blueprintParamsInitial,
      blueprintIsReady: true,
    };
  },
  [computer.CLEAR_COMPUTERS](state) {
    return { ...state, computers: [] };
  },
  [depComputer.CLEAR_DEP_COMPUTERS](state) {
    return { ...state, DEPComputers: [] };
  },
  /* istanbul ignore next */
  [blueprint.SET_BLUEPRINTS](state, action) {
    const blueprintNames = {};
    const blueprints = [];
    Object.keys(action.objects).map(
      (el) => (blueprints[el] = action.objects[el]),
    );
    blueprints.map((o) => (blueprintNames[o.id] = o.name));
    return {
      ...state,
      blueprints: action.objects,
      blueprintNames,
      fetched: { ...state.fetched, blueprints: true },
    };
  },
  [blueprint.ADD_BLUEPRINT](state, action) {
    const newBlueprints = [
      ...state.blueprints,
      { ...action.object, computerCount: 0 },
    ];
    return {
      ...state,
      blueprints: newBlueprints,
      blueprintNames: {
        ...state.blueprintNames,
        [action.object.id]: action.object.name,
      },
      isBlueprintShowedFirstly: true,
    };
  },
  [blueprint.DELETE_BLUEPRINT](state, action) {
    const newBlueprintNames = state.blueprintNames;
    delete newBlueprintNames[action.id];
    return {
      ...state,
      blueprints: state.blueprints.filter((bp) => bp.id !== action.id),
      blueprintNames: newBlueprintNames,
    };
  },
  [paramsActions.SET_PARAM_META](state, action) {
    let { blueprintParamsInitial } = state;
    if (state.blueprintIsReady) {
      blueprintParamsInitial = initBlueprint({
        ...state,
        parameterMeta: action.data.paramsMap,
      });
    }
    return {
      ...state,
      parameterMeta: action.data.paramsMap,
      blueprintParamsInitial,
    };
  },
});
