import { minWidthForDockedSidebar } from 'app/common/constants';
import cn from 'classnames';
import { InterfaceContext } from 'contexts/interface';
import React, { useCallback, useContext } from 'react';
import Icon from '../../../theme/components/atoms/Icon';
import {
  KandjiBeeLight,
  KandjiLogoHorizontalLight,
} from './SidebarHeaderIcons';

const SidebarHeader = () => {
  const { sidebarDocked, sidebarOpened, setSidebarOpened, setSidebarDocked } =
    useContext(InterfaceContext);
  const toggle = useCallback(
    () => setSidebarOpened(!sidebarOpened),
    [sidebarOpened],
  );
  const togglePin = useCallback(
    () => setSidebarDocked(!sidebarDocked),
    [sidebarDocked],
  );

  return (
    <div className="d-flex flex-dir-row header">
      <div className="d-flex flex-dir-row flex-nowrap">
        {sidebarOpened ? (
          <span className="logo logo-text" onClick={toggle}>
            <KandjiLogoHorizontalLight />
          </span>
        ) : (
          <span className="logo logo-icon" onClick={toggle}>
            <KandjiBeeLight />
          </span>
        )}
      </div>
      {sidebarOpened &&
        (window.innerWidth > minWidthForDockedSidebar || sidebarDocked) && (
          <span
            role="presentation"
            alt="pin-icon"
            className="pin-icon"
            onClick={togglePin}
          >
            <Icon icon="pin" className={cn({ pinned: sidebarDocked })} />
          </span>
        )}
    </div>
  );
};

export default SidebarHeader;
