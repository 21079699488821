import { bool, func, number, oneOfType, shape, string } from 'prop-types';
import React from 'react';

import { Flex, Icon, setClass } from '@kandji-inc/bumblebee';

const ValueContainer = (props: any) => {
  const { disabled, isOpen, placeholder, toggleOpen, value } = props;

  const renderValues = () => {
    if (!value) {
      return (
        <p className="b-select-multi-search__inactive-msg">{placeholder}</p>
      );
    }

    return (
      <span
        className={setClass([
          'b-txt',
          'b-select-search__value',
          disabled ? 'b-select-search__value--disabled' : '',
        ])}
      >
        {value.label}
      </span>
    );
  };

  return (
    <div
      tabIndex={0}
      className="b-select-multi__value-container-toggle"
      onClick={toggleOpen}
    >
      <Flex
        justify="flex-start"
        align="center"
        className={setClass([
          'b-select-multi__value-container',
          disabled ? 'b-select-multi__value-container--disabled' : '',
          isOpen ? 'b-select-multi__value-container--active' : '',
        ])}
        wrap
      >
        {renderValues()}

        <Icon
          name="angle-down"
          size="sm"
          className={setClass([
            'b-select__icon',
            isOpen ? '' : 'b-select__icon--closed',
          ])}
        />
      </Flex>
    </div>
  );
};

ValueContainer.propTypes = {
  disabled: bool,
  isOpen: bool,
  placeholder: string,
  toggleOpen: func.isRequired,
  value: shape({ label: string, value: oneOfType([string, number]) }),
};

ValueContainer.defaultProps = {
  disabled: false,
  isOpen: false,
  placeholder: 'Select Option',
  value: null,
};

export default ValueContainer;
