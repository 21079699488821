import AppLockService from './service/app-lock-service';

export default () => ({
  id: null,
  name: '',
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  isActive: true,

  appIdentifier: {
    value: undefined,
    validationsMap: {},
  },

  appOptions: Object.keys(AppLockService.getAppOptionsMap()).reduce(
    (acc, val) => {
      acc[val] = AppLockService.getAppOptionsMap()[val].defaultValue;
      return acc;
    },
    {},
  ),

  userOptions: Object.keys(AppLockService.getUserOptionsMap()).reduce(
    (acc, val) => {
      acc[val] = false;
      return acc;
    },
    {},
  ),

  supportsRules: true,
});
