import moment from 'moment';
import uuid from 'uuid';

import { i18n } from 'src/i18n';
import VpnService from '../../service/vpn.service';

const requiredValidator = (v, triggerDeps) => ({
  message: i18n.t('Required.'),
  invalid: () => !v || !v?.length,
  trigger: ['onBlur', ...triggerDeps],
});

const passwordVerifyValidator = (v, triggerDeps, password) => ({
  message: i18n.t('Passwords must match.'),
  invalid: () => v !== password,
  trigger: ['onBlur', 'onInput', ...triggerDeps],
});

const numberValidator = (v, triggerDeps) => ({
  message: i18n.t('Port must be a number.'),
  invalid: () => Number.isNaN(parseInt(v, 10)),
  trigger: ['onBlur', ...triggerDeps],
});

const getInvalidationKeyForConfig = (configuration, key) =>
  `${configuration.id}_${key}`;

const allKeys = (configuration) => ({
  UserDefinedName: {
    key: 'UserDefinedName',
    validationKey: getInvalidationKeyForConfig(
      configuration,
      'UserDefinedName',
    ),
    value: configuration.UserDefinedName || '',
  },
  VPNType: {
    key: 'VPNType',
    value: VpnService.VPNTypes().L2TP_OVER_IPSEC,
  },
  Hostname: {
    key: 'Hostname',
    validationKey: getInvalidationKeyForConfig(configuration, 'Hostname'),
    value: configuration.Hostname || '',
  },
  SharedSecret: {
    key: 'SharedSecret',
    validationKey: getInvalidationKeyForConfig(configuration, 'SharedSecret'),
    value: configuration.SharedSecret || '',
  },
  SharedSecretVerify: {
    key: 'SharedSecretVerify',
    validationKey: getInvalidationKeyForConfig(
      configuration,
      'SharedSecretVerify',
    ),
    value: configuration.SharedSecret || '',
  },
  SendAllThroughVPN: {
    key: 'SendAllThroughVPN',
    value: configuration.SendAllThroughVPN || false,
  },
  ProxySetup: {
    key: 'ProxySetup',
    value:
      Object.values(VpnService.Proxys()).find(
        ({ value }) => value === configuration.ProxySetup,
      ) || VpnService.Proxys().NONE,
  },
  Url_to_autoconfig_file: {
    key: 'Url_to_autoconfig_file',
    validationKey: getInvalidationKeyForConfig(
      configuration,
      'Url_to_autoconfig_file',
    ),
    value: configuration.Url_to_autoconfig_file || '',
  },
  Server: {
    key: 'Server',
    validationKey: getInvalidationKeyForConfig(configuration, 'Server'),
    value: configuration.Server || '',
  },
  Port: {
    key: 'Port',
    validationKey: getInvalidationKeyForConfig(configuration, 'Port'),
    value: configuration.Port?.toString() || '',
  },
  ProxyAccount: {
    key: 'ProxyAccount',
    value: configuration.ProxyAccount || '',
  },
  ProxyPassword: {
    key: 'ProxyPassword',
    validationKey: getInvalidationKeyForConfig(configuration, 'ProxyPassword'),
    value: configuration.ProxyPassword || '',
  },
  ProxyPasswordVerify: {
    key: 'ProxyPasswordVerify',
    validationKey: getInvalidationKeyForConfig(
      configuration,
      'ProxyPasswordVerify',
    ),
    value: configuration.ProxyPassword || '',
  },
  Account: {
    key: 'Account',
    value: configuration.Account || '',
  },
  Password: {
    key: 'Password',
    validationKey: getInvalidationKeyForConfig(configuration, 'Password'),
    value: configuration.Password || '',
  },
  PasswordVerify: {
    key: 'PasswordVerify',
    validationKey: getInvalidationKeyForConfig(configuration, 'PasswordVerify'),
    value: configuration.Password || '',
  },
});

const getDate = (date) => {
  if (!date) {
    return null;
  }
  return moment.unix(date).format('MMMM DD, YYYY');
};

const generateRowConfig = (withData = {}) => ({
  id: uuid(),
  ...Object.values(allKeys(withData)).reduce(
    (a, c) => ({ ...a, [c.key]: c.value }),
    {},
  ),

  /* Passive fields not updated by any fields, instead used by the BE and shown
    on the FE */
  PASSWORD_UPDATED_AT: withData.PASSWORD_UPDATED_AT || null,
  PROXY_PASSWORD_UPDATED_AT: withData.PROXY_PASSWORD_UPDATED_AT || null,
  SHARED_SECRET_UPDATED_AT: withData.SHARED_SECRET_UPDATED_AT || null,
});

export {
  generateRowConfig,
  requiredValidator,
  numberValidator,
  passwordVerifyValidator,
  getDate,
};
export default allKeys;
