import { updateSetting as update } from '@kandji-inc/bumblebee';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import ActivityTab from 'features/library-items/common/activity-tab';
import NewStatusTab from 'features/library-items/common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from 'features/library-items/common/new-status-tab/util';
import useUniqValue from 'src/components/common/hooks/use-uniq-value/use-uniq-value';

import { populateSelectedDevices } from 'features/library-items/data-service/library-item/devices';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import LibraryContext from '../../routes/library.context';
import type { BookmarkDrawerStatus, BookmarksProps } from './bookmarks.types';
import { DuplicatesModal } from './components';
import initial from './initial-state';
import ManageBookmarks from './sections/ManageBookmarks';
import { bookmarksService } from './service/bookmarks-service';
import { transformFromApi, transformToApi } from './service/transformers';
import useBookmarksService from './service/use-bookmarks-service';

const BookmarksPage = (props: BookmarksProps) => {
  const { model, setModel, pageState } = props;
  const [, triggerValidation] = useUniqValue();

  const manageBookmarksUpdate = useCallback(
    update('manageBookmarks', setModel),
    [],
  );

  const drawerStatus = useState<BookmarkDrawerStatus>('closed');

  const { itemConfig } = useContext(LibraryContext);

  useEffect(() => populateSelectedDevices(model, setModel, itemConfig), []);

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.getName()}
      summaryInfoProps={{
        name: `${itemConfig.getName()}`,
        description: itemConfig.getDescription(),
        publisher: itemConfig.getPublisher(),
        devices: itemConfig.devices,
        requirements: itemConfig.getOsRequirements(),
      }}
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={bookmarksService}
      defaultIcon={itemConfig.icon}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      customModal={
        /* istanbul ignore next */ ({ onSave }) => (
          <DuplicatesModal
            isOpen={model.manageBookmarks?.bookmark_conflicts?.length > 0}
            duplicates={model.manageBookmarks?.bookmark_conflicts}
            update={manageBookmarksUpdate}
            skipBookmarksConflict={
              model.manageBookmarks.skip_bookmarks_conflict
            }
            onClose={(isSavingDuplicates) => {
              // clear the bookmark conflicts so the modal closes:
              manageBookmarksUpdate('bookmark_conflicts', []);

              // Automatically retry saving the library item if the user accepted the duplicates:
              if (isSavingDuplicates) {
                onSave();
              }
            }}
          />
        )
      }
      supportsRules
      supportsInstallOn
    >
      <ManageBookmarks
        settings={model.manageBookmarks}
        isDisabled={pageState.isDisabled}
        update={manageBookmarksUpdate}
        drawerStatus={drawerStatus}
      />
    </LibraryItemPage>
  );
};

// BookmarksPage.propTypes = {};

const Bookmarks = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState: initial,
    useService: useBookmarksService,
  });

  const blueprintConflicts = useBlueprintConflicts();

  /* istanbul ignore next */
  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <BookmarksPage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
      testId="bookmarks-v2"
    />
  );
};

export default Bookmarks;
