import { useMemo } from 'react';
import type { AllowBlockEvent, AllowBlockFilters } from '../../avert.types';

const getFilteredEvents = (
  events: AllowBlockEvent[],
  filters: AllowBlockFilters,
): AllowBlockEvent[] => {
  const filtered = events
    .filter(
      ({ item_type: itemType }) =>
        filters.itemType === 'all' || itemType === filters.itemType,
    )
    .filter(
      ({ event_type: eventType }) =>
        filters.eventType === 'all' || eventType === filters.eventType,
    )
    .filter(({ name, item_type: itemType, details }) => {
      const searchLower = filters.search.toLowerCase();
      return (
        name.toLowerCase().includes(searchLower) ||
        details.toLowerCase().includes(searchLower) ||
        itemType.toLowerCase().includes(searchLower)
      );
    })
    .sort((a, b) => {
      const isAscending = filters.sortDirection === 'asc';
      const isNonSorted = filters.sortDirection === 'none';
      const aVal = a[filters.sortByColumn];
      const bVal = b[filters.sortByColumn];

      if (isNonSorted) {
        return 0;
      }

      if (aVal < bVal) {
        return isAscending ? -1 : 1;
      }

      if (aVal > bVal) {
        return isAscending ? 1 : -1;
      }

      return 0;
    });

  return filtered;
};

const getPagedEvents = (
  filteredEvents: AllowBlockEvent[],
  page: number,
  pageSize: number,
): AllowBlockEvent[] => {
  const filtered = filteredEvents.filter((_, i) => {
    const start = (page - 1) * pageSize;
    const end = start + pageSize;
    return i >= start && i < end;
  });

  return filtered;
};

type UseFilterEvents = (
  events: AllowBlockEvent[],
  page: number,
  pageSize: number,
  filters: AllowBlockFilters,
  defaultFilters: AllowBlockFilters,
) => {
  pagedEvents: AllowBlockEvent[];
  pagedEventsCount: number;
  filteredEventsCount: number;
  isFiltering: boolean;
  hasFilteredEvents: boolean;
  hasUnfilteredEvents: boolean;
};

const useFilterEvents: UseFilterEvents = (
  events,
  page,
  pageSize,
  filters,
  defaultFilters,
) => {
  const unfilteredEvents = useMemo(() => events ?? [], [events]);
  const filteredEvents = useMemo(
    () => getFilteredEvents(unfilteredEvents, filters),
    [unfilteredEvents, filters],
  );
  const pagedEvents = useMemo(
    () => getPagedEvents(filteredEvents, page, pageSize),
    [filteredEvents, page, pageSize],
  );

  const unfilteredEventsCount = unfilteredEvents.length;
  const filteredEventsCount = filteredEvents.length;
  const pagedEventsCount = pagedEvents.length;

  const hasFilteredEvents = Boolean(filteredEventsCount);
  const hasUnfilteredEvents = Boolean(unfilteredEventsCount);

  /*
  We want to exclude the sorting fields from the filters in
  the comparison for determining if we are filtering or not.
  */
  const defaultFiltersIgnoringSorting = {
    ...defaultFilters,
    sortByColumn: filters.sortByColumn,
    sortDirection: filters.sortDirection,
  };

  const isFiltering =
    JSON.stringify(defaultFiltersIgnoringSorting) !== JSON.stringify(filters);

  return {
    pagedEvents,
    pagedEventsCount,
    filteredEventsCount,
    isFiltering,
    hasFilteredEvents,
    hasUnfilteredEvents,
  };
};

export default useFilterEvents;
