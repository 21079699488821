/* istanbul ignore file */

import {
  getRunsOnFromModel,
  getSelectedDevicesFromRunsOn,
} from 'features/library-items/data-service/library-item/devices';
import { wallpaperService } from 'features/library-items/items/wallpaper/service/wallpaper-service';
import configs from 'features/library-items/library/library-item-configurations/items/profile';
import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';

type itemData = Readonly<{
  active: boolean;
  lockScreenS3Key: string;
  homeScreenS3Key: string;
}>;

type SendToApi = {
  name: string;
  active: string;
  blueprints: string[];
  is_all_blueprints: boolean;
  type?: string;
  skip_blueprint_conflict: boolean;
  reassign_lib_item_to_bp: boolean;
  data: {
    iPad: itemData;
    iPhone: itemData;
  };
  rules: object;
};

function transformSettings(settings) {
  return {
    iPhone: {
      active: settings.iPhone.active,
      lockScreenUrl: settings.iPhone.lockScreenUrl,
      lockScreenS3Key: settings.iPhone.lockScreenS3Key,
      homeScreenUrl: settings.iPhone.homeScreenUrl,
      homeScreenS3Key: settings.iPhone.homeScreenS3Key,
    },
    iPad: {
      active: settings.iPad.active,
      lockScreenUrl: settings.iPad.lockScreenUrl,
      lockScreenS3Key: settings.iPad.lockScreenS3Key,
      homeScreenUrl: settings.iPad.homeScreenUrl,
      homeScreenS3Key: settings.iPad.homeScreenS3Key,
    },
  };
}

async function transformFromApi(apiData) {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const commonData = {
    id: apiData.id,
    name: apiData.name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
    devices: getSelectedDevicesFromRunsOn(apiData),
  };

  const result = {
    ...commonData,
    isActive: apiData.active,
    rules: apiData.rules,
    ...transformSettings(apiData.data),
  };

  return {
    ...apiData,
    data: result,
  };
}

async function transformToApi(model) {
  if (model.iPad.lockScreen?.dataUrl) {
    const { dataUrl, name } = model.iPad?.lockScreen;
    await wallpaperService.uploadWallpaperImage(dataUrl, name).then((res) => {
      model.iPad.lockScreenS3Key = res.fields.key;
      return Promise.resolve();
    });
  }

  if (model.iPad.homeScreen?.dataUrl) {
    const { dataUrl, name } = model.iPad?.homeScreen;
    await wallpaperService.uploadWallpaperImage(dataUrl, name).then((res) => {
      model.iPad.homeScreenS3Key = res.fields.key;
      return Promise.resolve();
    });
  }

  if (model.iPhone.lockScreen?.dataUrl) {
    const { dataUrl, name } = model.iPhone?.lockScreen;
    await wallpaperService.uploadWallpaperImage(dataUrl, name).then((res) => {
      model.iPhone.lockScreenS3Key = res.fields.key;
      return Promise.resolve();
    });
  }

  if (model.iPhone.homeScreen?.dataUrl) {
    const { dataUrl, name } = model.iPhone?.homeScreen;
    await wallpaperService.uploadWallpaperImage(dataUrl, name).then((res) => {
      model.iPhone.homeScreenS3Key = res.fields.key;
      return Promise.resolve();
    });
  }

  const runsOn = getRunsOnFromModel(model);

  const toSend: SendToApi = {
    name: model.name,
    active: model.isActive,
    blueprints: model.selectedBlueprints.map((b) => b.value),
    is_all_blueprints: model.isAllBlueprints,
    skip_blueprint_conflict: model.skip_blueprint_conflict,
    reassign_lib_item_to_bp: true,
    data: {
      iPad: {
        active: runsOn.runs_on_ipad,
        lockScreenS3Key: model.iPad.lockScreenS3Key,
        homeScreenS3Key: model.iPad.homeScreenS3Key,
      },
      iPhone: {
        active: runsOn.runs_on_iphone,
        lockScreenS3Key: model.iPhone.lockScreenS3Key,
        homeScreenS3Key: model.iPhone.homeScreenS3Key,
      },
    },
    rules: model.rules || null,
  };

  // Populate `runs_on_*` fields
  Object.keys(runsOn).forEach((runsOnKey) => {
    toSend[runsOnKey] = runsOn[runsOnKey];
  });

  if (model.skip_blueprint_conflict) {
    toSend.skip_blueprint_conflict = model.skip_blueprint_conflict;
  }

  if (model.reassign_lib_item_to_bp) {
    toSend.reassign_lib_item_to_bp = model.reassign_lib_item_to_bp;
  }

  if (!model.id) {
    const itemConfig = configs.Wallpaper;
    toSend.type = itemConfig.type;
  }

  return toSend;
}

export { transformFromApi, transformToApi };
