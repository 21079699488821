import { AwesomeTableConstants } from 'app/common/constants';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AwesomeTable from './AwesomeTable';

const AwesomeTableContainer = ({
  headers,
  data,
  expandComponent,
  sortFunc,
  ordering,
  loadingDataView,
  noDataView,
  isLoading,
  sidebarDocked,
  clickableCellsToExpand,
  isExpandableRow,
}) => {
  if (!isLoading && isEmpty(data)) {
    return noDataView;
  }

  const [isReady, setIsReady] = useState(false);
  const [tableWidth, setTableWidth] = useState(0);
  const [currentHeaders, setCurrentHeaders] = useState(headers || []);
  const [pinnedHeadersCount, setPinnedHeadersCount] = useState(0);

  const changeHeadersWidth = useCallback(() => {
    const headerWrapperElm = document.getElementById('header-wrapper');
    if (!headerWrapperElm) {
      return;
    }
    const tWidth = headerWrapperElm.offsetWidth;
    const newHeaders = currentHeaders.map((header) => {
      if (isReady) {
        const width = (tWidth * parseInt(header.percentWidth, 10)) / 100;
        return { ...header, width };
      }
      const width = (tWidth * parseInt(header.width, 10)) / 100;
      return { ...header, percentWidth: header.width, width };
    });
    setPinnedHeadersCount(headers.filter((h) => h.pinned).length);
    setTableWidth(tWidth);
    setCurrentHeaders(newHeaders);
  }, [currentHeaders]);

  // Table resize
  useEffect(() => {
    window.addEventListener('resize', changeHeadersWidth);
    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', changeHeadersWidth);
    };
  }, []);

  useEffect(() => {
    changeHeadersWidth();
  }, [document.body.offsetWidth, sidebarDocked]);
  //

  useEffect(() => {
    changeHeadersWidth();
    setIsReady(true);
  }, []);

  const reorder = (list, sourceIndex, destinationIndex) => {
    const result = cloneDeep(list);
    const [removed] = result.splice(sourceIndex + pinnedHeadersCount, 1);
    result.splice(destinationIndex + pinnedHeadersCount, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newOrder = reorder(
      currentHeaders,
      result.source.index,
      result.destination.index,
    );
    setCurrentHeaders(newOrder);
  };

  const onResize = (currHeader, deltaX) => {
    const clonedHeaders = cloneDeep(currentHeaders);
    const currHeaderIndex = clonedHeaders.findIndex(
      (h) => h.name === currHeader.name,
    );
    const nextHeader = clonedHeaders[currHeaderIndex + 1];
    if (
      !(currHeader.width + deltaX > AwesomeTableConstants.columnMinWidth) ||
      !(
        nextHeader &&
        nextHeader.width - deltaX > AwesomeTableConstants.columnMinWidth
      )
    ) {
      return null;
    }
    const newHeaders = clonedHeaders.map((header) => {
      if (header.name === currHeader.name) {
        return { ...header, width: header.width + deltaX };
      }
      if (nextHeader && header.name === nextHeader.name) {
        return { ...header, width: header.width - deltaX };
      }
      return header;
    });
    const newTableWidth = newHeaders.reduce(
      (acc, header) => acc + header.width,
      0,
    );
    setTableWidth(newTableWidth);
    setCurrentHeaders(newHeaders);
    return null;
  };
  return (
    <AwesomeTable
      headers={currentHeaders}
      data={data}
      sortFunc={sortFunc}
      ordering={ordering}
      tableWidth={tableWidth}
      onDragEnd={onDragEnd}
      onResize={onResize}
      isReady={isReady}
      expandComponent={expandComponent}
      loadingDataView={loadingDataView}
      clickableCellsToExpand={clickableCellsToExpand}
      isExpandableRow={isExpandableRow}
    />
  );
};

const mapStateToProps = (state) => ({
  sidebarDocked: state.ui.sidebar.docked,
});

AwesomeTableContainer.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  expandComponent: PropTypes.func.isRequired,
  sortFunc: PropTypes.func,
  ordering: PropTypes.string.isRequired,
  clickableCellsToExpand: PropTypes.arrayOf(PropTypes.string),
  isExpandableRow: PropTypes.func,
};

AwesomeTableContainer.defaultProps = {
  data: [],
  sortFunc: null,
  clickableCellsToExpand: null,
  isExpandableRow: null,
};

export default connect(mapStateToProps)(AwesomeTableContainer);
