import { Flex, Heading, Icon, Link, Text } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';

import { FeedbackDropdown } from '../../components/feedback-dropdown';
import type { ShowToast } from '../../hooks/use-vulnerability-toast';
import type { Vulnerability } from '../../vulnerability.types';

const Header = (props: {
  selectedVulnerability: Vulnerability;
  showToast: ShowToast;
}) => {
  const { selectedVulnerability, showToast } = props;
  const { cve_id, cve_link } = selectedVulnerability;

  return (
    <Flex
      justifyContent="space-between"
      alignItems="start"
      css={{ flexGrow: 1 }}
    >
      <Flex justifyContent="start" alignItems="center" gap="sm">
        <Flex
          p2
          css={{
            backgroundColor: '$red10',
            borderRadius: '$round',
            height: '40px',
            width: '40px',
          }}
          alignItems="center"
          justifyContent="center"
        >
          <Icon name="malware" color="var(--colors-red60)" />
        </Flex>
        <Flex flow="column">
          <Flex gap="sm">
            <Heading size="3" css={{ fontWeight: '$medium' }}>
              {cve_id}
            </Heading>
          </Flex>
          <Flex gap="xs">
            {cve_link && (
              <Link
                href={cve_link}
                target="_blank"
                rel="noopener noreferrer"
                css={{
                  '&:hover': {
                    textDecorationColor: '$neutral70',
                  },
                }}
              >
                <Flex flow="row" gap="xs">
                  <Icon name="nvd" size="sm" />
                  <Text css={{ color: '$neutral70' }}>
                    {i18n.t('Search NVD')}
                  </Text>
                </Flex>
              </Link>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex>
        <FeedbackDropdown
          vulnerability={selectedVulnerability}
          showToast={showToast}
        />
      </Flex>
    </Flex>
  );
};

export { Header };
