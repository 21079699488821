/* istanbul ignore file */
import {
  Button,
  Callout,
  Code,
  DialogPrimitives as Dialog,
  Flex,
  Text,
} from '@kandji-inc/nectar-ui';
import useWhiteBg from 'features/util/hooks/use-white-bg';
import { useHistory } from 'react-router-dom';
import { links } from 'src/app/common/constants';
import { CreateButton } from './components/CreateButton';
import { DeviceTargets } from './components/DeviceTargets';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { LabelText } from './components/LabelText';
import { TargetsPanel } from './components/TargetsPanel';

export const CreateUsersCheck = ({
  onCreate,
  isCreating,
  createJobError,
  reportName,
  setReportName,
  reportNameError,
  setReportNameError,
  reportDescription,
  setReportDescription,
  selectedDevices,
  setSelectedDevices,
  selectedBlueprints,
  setSelectedBlueprints,
}) => {
  useWhiteBg();
  const history = useHistory();

  return (
    <Flex flow="column" pt4>
      <Header
        reportType="logged_in_users"
        reportName={reportName}
        setReportName={setReportName}
        reportNameError={reportNameError}
        setReportNameError={setReportNameError}
        reportDescription={reportDescription}
        setReportDescription={setReportDescription}
      />
      <TargetsPanel>
        <DeviceTargets
          selectedDevices={selectedDevices}
          setSelectedDevices={setSelectedDevices}
          selectedBlueprints={selectedBlueprints}
          setSelectedBlueprints={setSelectedBlueprints}
        />
        <Flex flow="column" p4 gap="md">
          <LabelText>I want to search and return results for</LabelText>
          <Flex gap="lg" alignItems="center">
            <Text>the</Text>
            <Code css={{ fontWeight: '$medium' }}>logged_in_users</Code>
            <Text>on the devices targeted above.</Text>
          </Flex>
          {selectedDevices.length === 0 && selectedBlueprints.length === 0 && (
            <Callout
              showCloseButton={false}
              text="You do not have any devices targeted. For faster results, target by device, blueprint or device family."
              theme="warning"
            />
          )}
          {createJobError && (
            <Callout
              showCloseButton={false}
              text={createJobError}
              theme="warning"
            />
          )}
        </Flex>
        <Dialog.Footer>
          <Button
            variant="subtle"
            onClick={() => {
              history.push(links.pulse);
            }}
          >
            Cancel
          </Button>
          <CreateButton
            isCreating={isCreating}
            onClick={() => {
              if (reportName === '') {
                setReportNameError('Required');
                return;
              }
              onCreate({
                checkType: 'logged_in_users',
              });
            }}
          />
        </Dialog.Footer>
      </TargetsPanel>
      <Footer />
    </Flex>
  );
};
