import React from 'react';
import { colors } from 'src/app/common/constants';
import { formatTime } from 'src/app/components/common/helpers';
import useLostMode from 'src/hooks/useLostMode';
import styled from 'styled-components';

const Header = styled.div`
  &.lost-mode__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 1px solid ${colors['grey-200']};

    .lost-mode__header-text {
      font-size: 16px;
      font-weight: bold;
    }

    .lost-mode__date-and-attribution {
      font-family:
        Atlas Grotesk Web,
        sans-serif;
      font-size: 12px;
      color: #888e9d;
    }
  }
`;

function LostModeHeader(props) {
  const { computer } = props;
  const { lostModeEnabledBy, lostModeEnabledDatetime } = useLostMode(computer);

  return (
    <Header className="lost-mode__header">
      <span className="lost-mode__header-text">Lost Mode</span>

      {(lostModeEnabledBy || lostModeEnabledDatetime) && (
        <span className="lost-mode__date-and-attribution">
          Command issued {lostModeEnabledBy && `by ${lostModeEnabledBy} `}{' '}
          {lostModeEnabledDatetime &&
            `on ${formatTime(lostModeEnabledDatetime, null, null, null, true)}`}
        </span>
      )}
    </Header>
  );
}

export default LostModeHeader;
