import uuid from 'uuid';

import { getAllBlueprintOptions } from 'features/library-items/data-service/blueprint/use-blueprint-service';
import { getSelectedDevicesFromRunsOn } from 'src/features/library-items/data-service/library-item/devices';

import authentication from './authentication';
import certificateTrust from './certificate-trust';
import fastlane from './fastlane';
import general from './general';
import proxy from './proxy';

const transformFromApi = async (apiData) => {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const result = {
    _data: apiData,
    id: apiData.id,
    name: apiData.name,
    iconSrc: apiData.app_icon,
    isActive: apiData.active,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
    devices: getSelectedDevicesFromRunsOn(apiData),
    rules: apiData.rules,
  };

  if (apiData.data?.length > 1) {
    result.legacyConfigurations = {
      configurations: apiData.data.map((d) => {
        const curr = { id: uuid() };
        curr.general = general(d);
        curr.authentication = authentication(d);
        curr.proxy = proxy(d);
        curr.trust = certificateTrust(d);
        curr.fastLaneMarking = fastlane(d);
        return curr;
      }),
    };
  } else {
    const [data] = apiData.data;

    result.general = general(data);
    result.authentication = authentication(data);
    result.proxy = proxy(data);
    result.trust = certificateTrust(data);
    result.fastLaneMarking = fastlane(data);
    result.legacyConfigurations = { configurations: [] };
  }

  return {
    ...apiData,
    data: result,
  };
};

export default transformFromApi;
