/* istanbul ignore file - legacy code, moved */
import PropTypes from 'prop-types';
import React from 'react';

export const IPAFormCopy = ({ copy }) => (
  <div className="theme-form-block theme-form-block-copy theme-form-block-managed">
    <div className="theme-form-copy">
      {typeof copy === 'string' ? <p>{copy}</p> : copy}
    </div>
  </div>
);

IPAFormCopy.propTypes = {
  copy: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};
