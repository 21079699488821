import { Checkbox } from '@kandji-inc/bumblebee';
import PropTypes from 'prop-types';
import React from 'react';

import { Setting } from 'features/library-items/template';
import { i18n } from 'src/i18n';

const Logging = ({ settings, isDisabled, update }) => (
  <Setting.Card>
    <Setting.Header>
      <h3 className="b-h3">{i18n.t('Logging')}</h3>
    </Setting.Header>

    <Setting.Rows>
      <Setting.Row>
        <Setting.Controls>
          <Checkbox
            label={i18n.t('Ensure Firewall is configured to log')}
            id="enable_logging"
            testId="enable_logging_checkbox"
            checked={settings.EnableFirewallLogging}
            onChange={() =>
              update('EnableFirewallLogging', (checked) => !checked)
            }
            disabled={isDisabled}
          />
        </Setting.Controls>
        <Setting.Helpers>
          <p className="b-txt-light">
            {i18n.t(
              'Configure the macOS socketfilter firewall to log in order to monitor which access is allowed and denied.',
            )}
          </p>
        </Setting.Helpers>
      </Setting.Row>
      <Setting.Row>
        <Setting.Controls>
          <Checkbox
            label={i18n.t('Ensure detailed firewall logging')}
            id="enable_detailed_logging"
            testId="enable_detailed_logging_checkbox"
            checked={settings.EnableDetailedFirewallLogging}
            onChange={() =>
              update('EnableDetailedFirewallLogging', (checked) => !checked)
            }
            disabled={isDisabled}
          />
        </Setting.Controls>
        <Setting.Helpers>
          <p className="b-txt-light">
            {i18n.t(
              'Enable detailed socketfilter firewall logging. Enabling this option sets the socketfilter firewall logging option to detailed.',
            )}
          </p>
        </Setting.Helpers>
      </Setting.Row>
    </Setting.Rows>
  </Setting.Card>
);

Logging.propTypes = {
  settings: PropTypes.shape({
    EnableFirewallLogging: PropTypes.bool,
    EnableDetailedFirewallLogging: PropTypes.bool,
  }).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  update: PropTypes.func.isRequired,
};

export default Logging;
