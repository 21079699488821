import { Icon, setClass } from '@kandji-inc/bumblebee';
import Tippy from '@tippyjs/react';
import React, { useState } from 'react';
import './menu.css';

const Menu = (props) => {
  const { options, onClick, children, className, style, ...rest } = props;
  const [isVisible, setIsVisible] = useState(false);

  const themeClasses = {
    error: 'b-btn-error-link',
    action: 'b-btn-action-link',
  };

  return (
    <Tippy
      content={
        <div className={setClass('b-menu', className)} style={style || {}}>
          {options.map((opt, idx) => {
            if (opt.type === 'line') {
              return (
                <div
                  key={`line_${idx}`}
                  style={{
                    height: '1px',
                    padding: 0,
                    background: 'var(--color-neutral-20)',
                  }}
                />
              );
            }
            return (
              <a
                key={opt.name}
                className={`${themeClasses[opt.theme] || themeClasses.action} ${
                  opt.icon ? 'btn-icon btn-icon-left' : ''
                } b-txt ${
                  opt.disabled ? 'b-menu__item-disabled' : ''
                } b-menu__link`}
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  if (!opt.disabled && (opt.onClick || onClick)) {
                    Promise.resolve(opt.onClick ? opt.onClick() : onClick(opt))
                      .then(() => setIsVisible(false))
                      .catch(() => setIsVisible(false));
                  } else {
                    setIsVisible(false);
                  }
                }}
              >
                {opt.icon && (
                  <Icon
                    name={opt.icon}
                    className={`${opt.loading ? 'b-menu__icon-spin' : ''}`}
                  />
                )}
                {opt.name}
              </a>
            );
          })}
        </div>
      }
      visible={isVisible}
      onClickOutside={() => setIsVisible(false)}
      placement="bottom"
      animation={false}
      interactive
      theme="light"
      arrow={false}
      {...rest}
    >
      {React.cloneElement(children, {
        ...children.props,
        onClick: (e) => {
          e.preventDefault();
          if (children.props.onClick) {
            children.props.onClick(e);
          }
          setIsVisible((prev) => !prev);
        },
      })}
    </Tippy>
  );
};

export default Menu;
