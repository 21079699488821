import { Button } from '@kandji-inc/nectar-ui';
import React, { memo } from 'react';

import type { SpecificExtensionsProps } from '../../safari-extensions.types';
import SafariExtensionsService from '../../service/safari-extensions-service';
import Extension from './Extension';

const SpecificExtensions = (props: SpecificExtensionsProps) => {
  const { update, isDisabled, settings, validationDep, isSubmitted } = props;

  const onAddExtension = () => {
    update('extensions', (prev) => [
      ...prev,
      SafariExtensionsService.generateExtension(),
    ]);
  };

  return (
    <>
      {settings.extensions.map((extension) => (
        <Extension
          key={extension.id}
          extension={extension}
          update={update}
          isDisabled={isDisabled}
          validationDep={validationDep}
          isSubmitted={isSubmitted}
        />
      ))}

      <Button
        disabled={isDisabled}
        variant="primary"
        icon={{ name: 'circle-plus' }}
        onClick={onAddExtension}
        css={{ marginTop: '$3' }}
      >
        Add configuration for a specific extension
      </Button>
    </>
  );
};

export default memo(SpecificExtensions);
