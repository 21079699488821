import type { History } from 'history';
import type { Dispatch, SetStateAction } from 'react';
import MultiSelectApplyBottomControlHoc from './MultiSelectApplyBottomControlHoc';

export type ParamsByType = Parameters<
  InstanceType<typeof SearchParamsUiHelper>['onParamsChange']
>[0];

export type SelectOptions = Array<{
  label: string;
  value: string | number | boolean;
}>;

// unique symbol used to indicate empty state without defaulting to
// corresponding value in URL params
export const emptyUiStateSymbol = Symbol('SearchParamsUiHelper.emptyUiState');

/**
 * SearchParamsUiHelper extends the browser URLSearchParams class to provide
 * convenience methods for syncing state between specific Bumblebee UI
 * components and the search params within the URL.
 */
export default class SearchParamsUiHelper extends URLSearchParams {
  private search: string;

  private uiConfig: Record<
    string,
    {
      options?: SelectOptions;
      handleOnChange?: (
        value:
          | InputEvent
          | SelectOptions
          | {
              from: Date;
              to: Date;
            },
        setValue: (
          val: InputEvent | SelectOptions | { from: Date; to: Date },
        ) => ParamsByType,
        instance: InstanceType<typeof SearchParamsUiHelper>,
      ) => ParamsByType;
      handleOnApply?: (
        value: SelectOptions,
        setValue: (val: SelectOptions) => ParamsByType,
        instance: InstanceType<typeof SearchParamsUiHelper>,
      ) => void;
      handleOnClear?: (
        value: SelectOptions,
        setValue: (val: SelectOptions) => ParamsByType,
        instance: InstanceType<typeof SearchParamsUiHelper>,
      ) => void;
    }
  >;

  // controlled state value and setter for components that use internal UI
  // state props for render updates and do not sync with URL search params on
  // change, e.g. `multiSelectApplyProps`
  private uiControlledState: Record<string, unknown>;

  private setUiControlledState: Dispatch<
    SetStateAction<Record<string, string>>
  >;

  private onParamsChange: (
    instance: InstanceType<typeof SearchParamsUiHelper>,
    updatedKeyValue: {
      updatedKey?: string | string[];
      updatedValue?: string | string[];
    },
  ) => void;

  protected history: History;

  public revoke: ReturnType<ProxyConstructor['revocable']>['revoke'];

  static fallbackNoop() {}

  // proxy returns default fallback if key cannot be accessed (i.e. the param is not present in the url search params)
  // this is to prevent runtime errors when using a key's UI props preset for a TextInput, MultiSelectSearch, etc. component
  static fallbackParam = {
    key: undefined,
    value: undefined,
    update: SearchParamsUiHelper.fallbackNoop,
    textInputProps: {
      value: '',
      onChange: SearchParamsUiHelper.fallbackNoop,
    },
    multiSelectProps: {
      values: [],
      onChange: SearchParamsUiHelper.fallbackNoop,
    },
    dateRangeSelectProps: {
      value: undefined,
      onChange: SearchParamsUiHelper.fallbackNoop,
    },
    chipSelectProps: {
      selected: undefined,
      setSelected: SearchParamsUiHelper.fallbackNoop,
    },
  };

  static asSelectOptions = (value, options) =>
    options
      ? value
          .split(',')
          .find((val) =>
            options.find(({ value: optValue }) => optValue === val),
          )
      : undefined;

  static asMultiSelectOptions = (uiValues, options) => {
    if (!options) {
      return null;
    }

    if (uiValues === emptyUiStateSymbol) {
      return [];
    }

    const uiSelectedOptions =
      options && uiValues
        ? uiValues
            .split(',')
            .map((val) =>
              options.find(({ value: optValue }) => optValue === val),
            )
            .filter(Boolean)
        : null;

    return uiSelectedOptions;
  };

  static asMultiSelectApplyOptions = (uiValues, options) => {
    const uiSelectedOptions = SearchParamsUiHelper.asMultiSelectOptions(
      uiValues,
      options,
    );

    return uiSelectedOptions;
  };

  static asChipSelectOptions = (value, options) =>
    options
      ? value
          .split(',')
          .map(
            (val) =>
              options.find(({ value: optValue }) => optValue === val)?.value,
          )
      : [];

  static asDateRange = (value, allTimeValue = 'all') => {
    if (!value) {
      return undefined;
    }

    if (value === allTimeValue) {
      return {
        label: 'All Time',
      };
    }

    const [from, to] = value.split(',');
    return { from: new Date(from), to: new Date(to) };
  };

  static proxyHandlers = {
    get(target, key) {
      const config = target.uiConfig;
      const isMethod = typeof target[key] === 'function';
      if (isMethod) {
        return target[key].bind(target);
      }

      const hasParam = target.has(key);
      if (!hasParam) {
        return SearchParamsUiHelper.fallbackParam;
      }

      const value = target.get(key);

      return {
        key,
        value,
        update: (newValue) => {
          target.set(key, newValue);
          target.updateSearchUrl({ updatedKey: key, updatedValue: newValue });
        },
        textInputProps: {
          value,
          onChange: target.setTextInputValue(
            key,
            config?.[key]?.handleOnChange,
          ),
        },

        selectProps: {
          value: SearchParamsUiHelper.asSelectOptions(
            value,
            config?.[key]?.options,
          ),
          onChange: target.setSelectValue(key, config?.[key]?.handleOnChange),
        },

        multiSelectProps: {
          values:
            SearchParamsUiHelper.asMultiSelectOptions(
              target.uiControlledState?.[key],
              config?.[key]?.options,
            ) || [],
          onChange: target.setMultiSelectValues(
            key,
            config?.[key]?.handleOnChange,
          ),
        },

        multiSelectApplyProps: {
          values:
            SearchParamsUiHelper.asMultiSelectApplyOptions(
              target.uiControlledState?.[key],
              config?.[key]?.options,
            ) || [],

          onChange: target.setMultiSelectApplyValues(
            key,
            config?.[key]?.handleOnChange,
          ),
          bottomControl: MultiSelectApplyBottomControlHoc({
            onApply: target.multiSelectApplyOnApply(
              key,
              config?.[key]?.handleOnApply,
            ),
            onClear: target.multiSelectApplyOnClear(
              key,
              config?.[key]?.handleOnClear,
            ),
          }),
        },

        chipSelectProps: {
          selected: SearchParamsUiHelper.asChipSelectOptions(
            value,
            config?.[key]?.options,
          ),
          setSelected: target.setChipSelectValues(
            key,
            config?.[key]?.handleOnChange,
          ),
        },

        dateRangeSelectProps: {
          value: SearchParamsUiHelper.asDateRange(
            value,
            config?.[key]?.allTimeValue,
          ),
          onChange: target.setDateRangeSelectValue(
            key,
            config?.[key]?.handleOnChange,
            config?.[key]?.allTimeValue,
          ),
        },
      };
    },

    set(target, key, value, receiver) {
      return Reflect.set(target, key, value, receiver);
    },
  };

  constructor({
    search,
    history,
    uiControlledState,
    setUiControlledState,
    uiConfig,
    onParamsChange,
  }: {
    search: string;
    history: History;
    uiControlledState: Record<string, unknown>;
    setUiControlledState: Dispatch<SetStateAction<Record<string, string>>>;
    uiConfig: InstanceType<typeof SearchParamsUiHelper>['uiConfig'];
    onParamsChange: InstanceType<typeof SearchParamsUiHelper>['onParamsChange'];
  }) {
    super(search);
    this.search = search;
    this.history = history;
    this.setUiControlledState = setUiControlledState;
    this.uiControlledState = uiControlledState;
    this.uiConfig = uiConfig;
    this.onParamsChange = onParamsChange;

    const instanceProxy = Proxy.revocable(
      this,
      SearchParamsUiHelper.proxyHandlers,
    );

    this.revoke = instanceProxy.revoke;

    return instanceProxy.proxy;
  }

  getParamsObj() {
    return Object.fromEntries(this.entries());
  }

  getSearchString() {
    return `?${this.toString()}`;
  }

  updateSearchUrl(updatedKeyValue = {}) {
    this.sort();

    this.onParamsChange?.(this, updatedKeyValue);
    this.history.push(this.getSearchString());

    return this;
  }

  setAll(params: Record<string, string>) {
    Object.entries(params).forEach(([key, value]) => this.set(key, value));
  }

  setTextInputValue = (key, handleOnChange) => (inputEvent) => {
    const setValue = (event) => {
      const { value } = event.target;
      this.set(key, value);
      return this.updateSearchUrl({ updatedKey: key, updatedValue: value });
    };

    if (handleOnChange) {
      return handleOnChange(inputEvent, setValue, this);
    }

    return setValue(inputEvent);
  };

  setSelectValue = (key, handleOnChange) => (selectValue) => {
    const setValue = ({ value }) => {
      this.set(key, value);
      return this.updateSearchUrl({ updatedKey: key, updatedValue: value });
    };

    if (handleOnChange) {
      return handleOnChange(selectValue, setValue, this);
    }

    return setValue(selectValue);
  };

  setMultiSelectValues = (key, handleOnChange) => (selectValues) => {
    const setValue = (values) => {
      const paramValueString = values?.map(({ value }) => value).join(',');

      this.setUiControlledState((prev) => ({
        ...prev,
        [key]: values.length ? paramValueString : emptyUiStateSymbol,
      }));

      this.set(key, paramValueString);
    };

    if (handleOnChange) {
      return handleOnChange(selectValues, setValue, this);
    }

    return setValue(selectValues);
  };

  setMultiSelectApplyValues = (key, handleOnChange) => (selectValues) => {
    const setValue = (values) => {
      this.setUiControlledState((prev) => ({
        ...prev,
        [key]: values.length
          ? values.map((v) => v.value).join(',')
          : emptyUiStateSymbol,
      }));
    };

    if (handleOnChange) {
      return handleOnChange(selectValues, setValue, this);
    }

    return setValue(selectValues);
  };

  multiSelectApplyOnApply =
    (key, handleOnApply) =>
    ({ values, setIsOpen }) =>
    () => {
      const setValue = (selectValues) => {
        const paramValueString = selectValues.map((v) => v.value).join(',');
        this.set(key, paramValueString);
        return this.updateSearchUrl({
          updatedKey: key,
          updatedValue: paramValueString,
        });
      };

      setIsOpen(false);

      if (handleOnApply) {
        return handleOnApply(values, setValue, this);
      }

      return setValue(values);
    };

  multiSelectApplyOnClear =
    (key, handleOnClear) =>
    ({ values, clearValue, setIsOpen }) =>
    () => {
      const setValue = () => {
        clearValue();
        this.set(key, '');
        return this.updateSearchUrl({ updatedKey: key, updatedValue: '' });
      };

      setIsOpen(false);

      if (handleOnClear) {
        return handleOnClear(values, setValue, this);
      }

      return setValue();
    };

  setChipSelectValues = (key, handleOnChange) => (selectValues) => {
    const setValue = (val) => {
      this.set(key, val.value);
      return this.updateSearchUrl({ updatedKey: key, updatedValue: val.value });
    };

    if (handleOnChange) {
      return handleOnChange(selectValues, setValue, this);
    }

    return setValue(selectValues);
  };

  setDateRangeSelectValue =
    (key, handleOnChange, allTimeValue = 'all') =>
    (dateRange) => {
      const setValue = ({ from, to }) => {
        const toDate = to?.toISOString();
        const fromDate = from?.toISOString();

        if (!toDate || !fromDate) {
          this.set(key, allTimeValue);
          return this.updateSearchUrl({
            updatedKey: key,
            updatedValue: allTimeValue,
          });
        }

        const dateRangeValue = `${fromDate},${toDate}`;
        this.set(key, dateRangeValue);
        return this.updateSearchUrl({
          updatedKey: key,
          updatedValue: dateRangeValue,
        });
      };

      if (handleOnChange) {
        return handleOnChange(dateRange, setValue, this);
      }
      return setValue(dateRange);
    };

  hasSome = (...keys) => {
    if (!keys || !keys.length) {
      return false;
    }

    return keys.some((key) => this.has(key) && this.get(key) !== '');
  };
}
