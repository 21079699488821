import type { Dispatch } from 'react';
import { createContext } from 'react';

import type { SomeReadonlyRecord } from '@shared/types/util.types';

import type { TableAction, TableState } from './Table';

export type TableContextType = readonly [
  TableState<SomeReadonlyRecord>,
  Dispatch<TableAction<SomeReadonlyRecord>>,
];

export const TableContext = createContext<TableContextType | null>(null);
