import CustomPrinterService from './service/custom-printer-service';

const initialState = {
  id: '',
  name: '',
  iconSrc: '',
  isActive: true,
  blueprints: [],
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  supportsRules: true,

  installation: {
    type: CustomPrinterService.installationTypes.INSTALL_ONCE,
  },

  selfService: {
    invalidationsMap: {},
    isEnabled: false, // false => item.data.self_service_data === undefined
    category: '', // item.data.self_service_data.category
    isRecommended: false, // item.data.self_service_data.isRecommended
    displayName: null, // item.data.self_service_data.displayName
    shortDescription: null, // item.data.self_service_data.description
    longDescription: null, // item.data.self_service_data.bodyText
    isRequireRead: false, // item.data.self_service_data.mustViewAgreement
    iconUrl: null, // item.data.self_service_data.iconUrl
    iconFile: null,
    installBtnText: null, // ??
    removeBtnText: '', // ??
    size: '', // ??
    version: '', // ??
  },
  general: {
    invalidationsMap: {},
    displayName: '', // item.data.description
    printerName: '', // item.data.printer_name
    location: '', // item.data.location
    uri: '', // item.data.address
    ppdType: CustomPrinterService.ppdTypes.CUSTOM, // item.data.ppd_type
    customPpdPath: '', // ui-only field
    ppdPath: '', // item.data.ppd_path
    /*
item.data.ppd_file_name
item.data.ppd_file_s3key
item.data.ppd_file_sha256
item.data.ppd_file_size
item.data.ppd_file_uploaded
item.data.ppd_file_url
     */
    ppdFile: null, // {name, size, sha256, downloadLink, file}
  },
  printerFile: {
    hasFile: false, // item.data.has_file
    /*
item.data.file_name
item.data.file_s3key
item.data.file_sha256
item.data.file_size
item.data.file_uploaded
item.data.file_url
     */
    file: null, // {name, size, sha256, downloadLink, file}
    preInstallScript: '', // item.data.preinstall_script
    postInstallScript: '', // item.data.postinstall_script
  },
};

export default initialState;
