import configs from 'features/library-items/library/library-item-configurations/items';
import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import initialState from '../initial-state';
import type {
  SafariExtensionsModel,
  SafariExtensionsModelSettings,
} from '../safari-extensions.types';
import type {
  SafariExtensionsApiPayloadSettings,
  SafariExtensionsApiRequestPayload,
  SafariExtensionsApiResponsePayload,
} from './transformers.types';

function transformSettings(
  settings: SafariExtensionsApiPayloadSettings,
): SafariExtensionsModelSettings {
  const allExtensions = settings.ManagedExtensions.find(
    (ext) => ext.BundleId === '*',
  );
  const specificExtensions = settings.ManagedExtensions.filter(
    (ext) => ext.BundleId !== '*',
  );

  return {
    // @todo parse and transform settings from the API to ui-compatible structure
    allExtensions: /* istanbul ignore next */ allExtensions
      ? {
          ...allExtensions,
          isManaged: allExtensions.isManaged,
        }
      : {
          ...initialState.allExtensions,
        },
    specificExtensions: {
      extensions: specificExtensions,
    },
  };
}

async function transformFromApi(apiData: SafariExtensionsApiResponsePayload) {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const commonData = {
    id: apiData.id,
    name: apiData.name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
    rules: apiData.rules,
  };

  const result: SafariExtensionsModel = {
    ...commonData,
    isActive: apiData.active,
    ...transformSettings(apiData.data),
  };

  return {
    ...apiData,
    data: result,
  };
}

function transformToApi(
  model: SafariExtensionsModel,
): SafariExtensionsApiRequestPayload {
  const allExtensions = model.allExtensions;
  delete allExtensions.invalidationsMap;

  const toSend: SafariExtensionsApiRequestPayload = {
    name: model.name,
    active: model.isActive,
    blueprints: model.selectedBlueprints.map((b) => b.value),
    is_all_blueprints: model.isAllBlueprints,
    data: {
      ManagedExtensions: [
        model.allExtensions,
        ...model.specificExtensions.extensions,
      ].map((ext) => {
        const bothStateAlwaysOff =
          ext.State === 'AlwaysOff' && ext.PrivateBrowsing === 'AlwaysOff';

        return {
          ...(Object.hasOwnProperty.call(ext, 'isManaged')
            ? { isManaged: ext.isManaged }
            : {}),
          ...(Object.hasOwnProperty.call(ext, 'id') ? { id: ext.id } : {}),
          BundleId: ext.BundleId,
          TeamId: ext.TeamId,
          State: ext.State,
          PrivateBrowsing: ext.PrivateBrowsing,
          AllowedDomains:
            bothStateAlwaysOff || ext.isManaged === false
              ? []
              : ext.AllowedDomains,
          DeniedDomains:
            bothStateAlwaysOff || ext.isManaged === false
              ? []
              : ext.DeniedDomains,
        };
      }),
    },
    rules: model.rules || null,
  };

  /* istanbul ignore next */
  if (model.skip_blueprint_conflict) {
    toSend.skip_blueprint_conflict = model.skip_blueprint_conflict;
  }

  if (!model.id) {
    const itemConfig = configs['Safari Extensions'];

    toSend.type = itemConfig.type;
    toSend.identifier = itemConfig.identifier;
  }

  return toSend;
}

export { transformToApi, transformFromApi };
