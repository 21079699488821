import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import configs from '../../../library/library-item-configurations/items/security';
import type {
  DeviceControlModel,
  DeviceControlModelSetting,
  DeviceControlModelSettings,
} from '../devicecontrol.types';
import type {
  DeviceControlApiPayloadSetting,
  DeviceControlApiPayloadSettings,
  DeviceControlApiRequestPayload,
  DeviceControlApiResponsePayload,
} from './transformers.types';

function defaultTransformSettings(
  setting: DeviceControlApiPayloadSetting,
): DeviceControlModelSetting {
  return {
    isManaged: setting.is_managed,
    userAccess: setting.user_access,
    appliesTo: setting.applies_to,
    deviceAlerts: setting.device_alerts,
  };
}

function transformSettings(
  settings: DeviceControlApiPayloadSettings,
): DeviceControlModelSettings {
  const {
    volume_mounts: volumeMounts,
    disk_images: diskImages,
    network_mounts: networkMounts,
  } = settings;

  return {
    volumeMounts: {
      ...defaultTransformSettings(volumeMounts),
      requireAdminPassword: volumeMounts.require_admin_password,
      requireEncryption: volumeMounts.require_encryption,
    },
    diskImages: {
      ...defaultTransformSettings(diskImages),
      requireAdminPassword: diskImages.require_admin_password,
    },
    networkMounts: defaultTransformSettings(networkMounts),
  };
}

async function transformFromApi(apiData: DeviceControlApiResponsePayload) {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const commonData = {
    id: apiData.id,
    name: apiData.name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
  };

  const result: DeviceControlModel = {
    ...commonData,
    isActive: apiData.active,
    ...transformSettings(apiData.data),
  };

  return {
    ...apiData,
    data: result,
  };
}

function defaultTransformApiSetting(
  setting: DeviceControlModelSetting,
): DeviceControlApiPayloadSetting {
  return {
    is_managed: setting.isManaged,
    user_access: setting.userAccess,
    applies_to: setting.appliesTo,
    device_alerts: Boolean(setting.deviceAlerts),
  };
}

function transformToApi(
  model: DeviceControlModel,
): DeviceControlApiRequestPayload {
  const toSend: DeviceControlApiRequestPayload = {
    name: model.name,
    active: model.isActive,
    blueprints: model.selectedBlueprints.map((b) => b.value),
    is_all_blueprints: model.isAllBlueprints,
    data: {
      volume_mounts: {
        ...defaultTransformApiSetting(model.volumeMounts),
        require_admin_password: Boolean(
          model.volumeMounts.requireAdminPassword,
        ),
        require_encryption: Boolean(model.volumeMounts.requireEncryption),
      },
      disk_images: {
        ...defaultTransformApiSetting(model.diskImages),
        require_admin_password: Boolean(model.diskImages.requireAdminPassword),
      },
      network_mounts: defaultTransformApiSetting(model.networkMounts),
    },
  };

  if (model.skip_blueprint_conflict) {
    toSend.skip_blueprint_conflict = model.skip_blueprint_conflict;
  }

  if (!model.id) {
    const itemConfig = configs.DeviceControl;

    toSend.type = itemConfig.type;
  }

  return toSend;
}

export { transformToApi, transformFromApi };
