import { libraryItemService } from 'features/library-items/data-service/library-item/library-item-service';
import { useRef, useState } from 'react';

const useBlueprintConflicts = () => {
  const initialBpConflictsState = {
    showModal: false,
    conflicts: [],
    itemSelections: [],
    resolved: [],
    isChecking: false,
    allBlueprintConflict: null,
  };

  const [conflictsState, setConflictsState] = useState(initialBpConflictsState);
  const resolveContinue = useRef(null);

  const shouldSave = (canSave, resolvedBps) => {
    const shouldContinue = resolveContinue?.current;
    if (shouldContinue) {
      shouldContinue({
        canSave,
        resolvedBps,
      });
    }
  };

  /* istanbul ignore next - tested manually and via implementation */
  const bpConflictsModalOn = (
    conflicts,
    type,
    allBlueprintConflict,
    isAllBlueprints,
    setBlueprints,
    itemId,
  ) =>
    setConflictsState((p) => ({
      ...p,
      showModal: true,
      conflicts,
      itemSelections: Array.from({ length: conflicts.length }, () => {
        // Pre-select the Library Item creating the conflict if it is an All Blueprints Library Item
        if (isAllBlueprints) {
          return itemId || 'new-item-id';
        }
        return null;
      }),
      type,
      setBlueprints,
      allBlueprintConflict,

      // Pre-select the Library Item creating the conflict if it is an All Blueprints Library Item
      ...(isAllBlueprints && {
        resolved: conflicts?.map(({ blueprint_id, blueprint_name }) => ({
          label: blueprint_name,
          value: blueprint_id,
        })),
      }),
    }));

  const finishBpConflicts = (canSave, resolvedBps = []) => {
    setConflictsState(initialBpConflictsState);

    shouldSave(canSave, resolvedBps);
  };

  const selectBpConflict = (itemSelections, resolved) => {
    setConflictsState((p) => ({
      ...p,
      itemSelections,
      resolved,
    }));
  };

  /* istanbul ignore next - tested manually and via implementation */
  const checkConflicts = ({
    type,
    identifier,
    managedLibraryItemId,
    blueprints,
    isAllBlueprints,
    itemId,
    setBlueprints,
    onError,
  }) => {
    setConflictsState((p) => ({
      ...p,
      isChecking: true,
    }));

    const blueprintIds = blueprints.map((bp) => bp.value || bp.id || bp);
    const args = {
      blueprintIds,
      type,
      identifier,
      itemId,
      managedLibraryItemId,
      isAllBlueprints,
    };

    return libraryItemService
      .checkConflicts(args)
      .then(({ conflicts, all_blueprint_conflict: allBlueprintConflict }) => {
        // If no conflicts are present and there is not an All Blueprints conflict, do not show the conflict modal
        if (conflicts?.length <= 0 && !allBlueprintConflict) {
          return [true, blueprints];
        }

        bpConflictsModalOn(
          conflicts,
          type,
          allBlueprintConflict,
          isAllBlueprints,
          setBlueprints,
          itemId,
        );

        return new Promise((r) => {
          resolveContinue.current = r;
        })
          .then(({ canSave, resolvedBps }) => [canSave, resolvedBps])
          .catch((e) => {
            throw e;
          });
      })
      .catch(() => {
        onError();
        return [false, blueprints];
      })
      .finally(() => {
        setConflictsState((p) => ({
          ...p,
          isChecking: false,
        }));
      });
  };

  return {
    conflictsState,
    bpConflictsModalOn,
    finishBpConflicts,
    selectBpConflict,
    checkConflicts,
  };
};

export default useBlueprintConflicts;
