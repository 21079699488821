/* istanbul ignore file */
import { Select } from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';

import { i18n } from 'src/i18n';
import AirplaySecurityService from '../service/airplay-security-service';
import Passwords from './passwords';

const SecurityCard = (props) => {
  const { setting, isDisabled, update } = props;

  const securityTypes = [
    {
      value: AirplaySecurityService.securityTypes.PASSCODE_ALWAYS,
      label: i18n.t('Require a passcode every time'),
    },
    {
      value: AirplaySecurityService.securityTypes.PASSCODE_ONCE,
      label: i18n.t('Require a passcode once per device'),
    },
    {
      value: AirplaySecurityService.securityTypes.PASSWORD,
      label: i18n.t('Specify a password'),
    },
  ];

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('Security')}</h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Title>
            <p className="b-txt">{i18n.t('Security policy')}</p>
          </Setting.Title>
          <Setting.Helpers className="b-txt-light k-li-helper--compact">
            {i18n.t(`Require a passcode to initiate an AirPlay connection to Apple TV.
            You can require a random passcode to be presented onscreen at every
            connection, once per device, or specify a custom password. Custom
            passwords will not be shown on screen during an AirPlay connection
            initiation.`)}
          </Setting.Helpers>
          <Setting.Controls>
            <Select
              disabled={isDisabled}
              options={securityTypes}
              compact
              onChange={({ value }) => update('type', value)}
              value={securityTypes.find(({ value }) => value === setting.type)}
            />
          </Setting.Controls>

          {setting.type === AirplaySecurityService.securityTypes.PASSWORD && (
            <Passwords {...props} />
          )}
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default SecurityCard;
