import * as React from 'react';

export const useFilterErrors = (numOfSelectedDeviceFamilies: number) => {
  const [filterErrors, setFilterErrors] = React.useState<{
    deviceFamilies: boolean;
    blueprints: boolean;
  }>({
    deviceFamilies: false,
    blueprints: false,
  });

  const setDeviceFamilyErrors = React.useCallback(
    (value: boolean) => {
      setFilterErrors((prev) => ({ ...prev, deviceFamilies: value }));
    },
    [setFilterErrors],
  );

  React.useEffect(() => {
    if (numOfSelectedDeviceFamilies > 0) {
      setDeviceFamilyErrors(false);
    }
  }, [numOfSelectedDeviceFamilies, setDeviceFamilyErrors]);

  return {
    filterErrors,
    setDeviceFamilyErrors,
  };
};
