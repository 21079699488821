import { AirPrintSetting } from './sections/configurations/configuration.constants';

export const configurationsModelProperty = 'configurations';

export default {
  name: '',
  isActive: true,

  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,

  supportsRules: true,

  settings: {
    [configurationsModelProperty]: [AirPrintSetting],
    invalidationsMap: {},
  },
};
