/* istanbul ignore file */
import React from 'react';

const useDeviceModals = () => {
  const [modalKind, setModalKind] = React.useState({
    is: false,
    kind: null,
  });

  const setIsOpen = (isOpen, kind) =>
    setModalKind((prev) => {
      const is = isOpen === undefined ? !prev : isOpen;
      return {
        ...prev,
        is,
        kind: is ? kind : null,
      };
    });

  return [modalKind.kind, modalKind.is, setIsOpen];
};

export { useDeviceModals };
