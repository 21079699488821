import {
  Banner,
  Box,
  Button,
  Dialog,
  Flex,
  Icon,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Text,
  TextArea,
  TextField,
} from '@kandji-inc/nectar-ui';
import React, { useCallback, useMemo, useState } from 'react';
import type { FieldValues } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

import { i18n } from 'src/i18n';
import type {
  AllowBlockEvent,
  AllowBlockListSetting,
  GeneralSetting,
} from '../../../avert.types';
import {
  EventTypeLabel,
  EventTypeValue,
  ItemTypeValue,
  PostureValue,
} from '../../../avert.types';
import AvertService from '../../../service/avert.service';
import './allow-block-modal.css';

type EventFormModel = Omit<AllowBlockEvent, 'item_type'> & {
  item_type: ItemTypeValue | '';
};

const getNewEventFormModel = (): EventFormModel => ({
  id: uuid(),
  name: '',
  item_type: '',
  details: '',
  event_type: EventTypeValue.ALLOW,
});

type AllowBlockModalProps = {
  isOpen: boolean;
  onAdd: (item: FieldValues, keepOpen: boolean) => void;
  onCancel: () => void;
  generalSettings: GeneralSetting;
  settings: AllowBlockListSetting;
  itemId: string;
};

const AllowBlockModal = (props: AllowBlockModalProps) => {
  const { isOpen, onCancel, onAdd, settings, generalSettings, itemId } = props;

  const [shouldAddAnother, setShouldAddAnother] = useState<boolean>(false);
  const [addAnotherCount, setAddAnotherCount] = useState<number>(0);

  const event = useMemo(
    () =>
      itemId
        ? settings.events.find(({ id }) => id === itemId)
        : getNewEventFormModel(),
    [itemId, settings.events],
  );

  const {
    control: formControl,
    setValue,
    formState: { errors },
    handleSubmit,
    watch,
    register,
    clearErrors,
    reset,
  } = useForm<EventFormModel>({
    mode: 'onChange',
    defaultValues: {
      id: event?.id,
      name: event?.name,
      item_type: event?.item_type,
      details: event?.details,
      event_type: event?.event_type,
    },
  });

  const watchItemType = watch('item_type');
  const watchDetails = watch('details');
  const watchEventType = watch('event_type');
  const watchName = watch('name');

  const isEditing = Boolean(itemId);
  const isPath = watchItemType === ItemTypeValue.PATH;
  const isHash = watchItemType === ItemTypeValue.HASH;
  const pathHasWildcard = isPath && watchDetails?.includes('*');

  const handleAddSave = useCallback(() => {
    handleSubmit((model) => {
      onAdd(model, shouldAddAnother);
      if (shouldAddAnother) {
        setAddAnotherCount(addAnotherCount + 1);
        reset(getNewEventFormModel());
      }
    })();
  }, [addAnotherCount, handleSubmit, onAdd, reset, shouldAddAnother]);

  const rules = useMemo(
    () => ({
      name: {
        required: i18n.t('Required.'),
        validate: (value: string) => {
          const isDuplicate = Boolean(
            settings.events.find((r) =>
              itemId ? itemId !== r.id && value === r.name : value === r.name,
            ),
          );
          if (isDuplicate) {
            return i18n.t('Duplicate found. Names must be unique.');
          }
          return true;
        },
      },
      hash: {
        required: i18n.t('Required.'),
        validate: (value: string) => {
          const isDuplicate = Boolean(
            settings.events.find((r) =>
              itemId
                ? itemId !== r.id &&
                  r.item_type === ItemTypeValue.HASH &&
                  value === r.details
                : r.item_type === ItemTypeValue.HASH && value === r.details,
            ),
          );
          if (isDuplicate) {
            return i18n.t('Duplicate Hash ID exists already.');
          }
          if (!/^[a-fA-F0-9]{64}$/.test(value)) {
            return i18n.t('Invalid hash.');
          }
          return true;
        },
      },
      path: {
        required: i18n.t('Required.'),
        validate: (value: string) => {
          const isDuplicate = Boolean(
            settings.events.find((r) =>
              itemId
                ? itemId !== r.id &&
                  r.item_type === ItemTypeValue.PATH &&
                  value === r.details
                : r.item_type === ItemTypeValue.PATH && value === r.details,
            ),
          );
          if (isDuplicate) {
            return i18n.t('Duplicate Path exists already.');
          }
          if (!/^\S(.*\S)?$/.test(value)) {
            return i18n.t('Invalid path.');
          }
          return true;
        },
      },
      itemType: {
        required: i18n.t('Required.'),
      },
    }),
    [itemId, settings.events],
  );

  const content = (
    <Flex flow="column" gap="lg">
      <Text>
        {isEditing
          ? i18n.t('Edit the name, item details and the specific permission.')
          : i18n.t(
              'Enter the name, item type, item detail, and select the specific permission.',
            )}
      </Text>
      <TextField
        {...register('name', {
          shouldUnregister: true,
          ...rules.name,
        })}
        autoFocus
        label={i18n.t('Name')}
        placeholder={i18n.t('Enter a descriptive name for the item')}
        maxLength={64}
        state={errors?.name?.message ? 'error' : 'default'}
        hint={{
          ...(errors?.name?.message && { label: errors.name.message }),
          charCounter: {
            count: watchName?.length || 0,
            max: 64,
          },
        }}
        data-testid="name"
      />
      <Box className="k-hline" />
      <Controller
        control={formControl}
        name="item_type"
        rules={rules.itemType}
        render={({
          field: { onChange, onBlur, value, ref },
          fieldState: { invalid, error },
        }) => (
          <Select
            placeholder={i18n.t('Specify the item type')}
            size="full"
            label={i18n.t('Item type')}
            options={AvertService.itemTypeOptions()}
            onChange={(v) => {
              onChange(v);
              setValue('details', '');
              clearErrors('details');
            }}
            onBlur={onBlur}
            ref={ref}
            error={invalid && error?.message}
            value={value}
            // `key` is necessary to reset select
            key={value}
            testId="item-type"
          />
        )}
      />
      {isHash && (
        <TextArea
          {...register('details', {
            shouldUnregister: true,
            ...rules.hash,
          })}
          label={i18n.t('Hash ID')}
          // @ts-expect-error -- TODO fix this
          placeholder={i18n.t('Enter the Hash unique identifier')}
          resize
          state={errors?.details?.message ? 'error' : 'default'}
          hint={{
            ...(errors?.details?.message && { label: errors.details.message }),
            charCounter: {
              count: watchDetails?.length || 0,
              max: 64,
            },
          }}
          data-testid="hash-id"
        />
      )}
      {isPath && (
        <>
          <TextField
            {...register('details', {
              shouldUnregister: true,
              ...rules.path,
            })}
            label={i18n.t('Path')}
            placeholder={i18n.t('Enter the path')}
            state={errors?.details?.message ? 'error' : 'default'}
            hint={{ label: errors?.details?.message }}
            data-testid="path"
          />
          {pathHasWildcard && (
            <Banner
              theme="warning"
              className="b-mt-tiny"
              text={i18n.t('This path contains a wildcard value.')}
            />
          )}
        </>
      )}
      <Flex flow="column" gap="md">
        <Text>
          {i18n.t(`Specify whether this item should be allowed or blocked:`)}
        </Text>
        <Controller
          control={formControl}
          name="event_type"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <RadioGroup
              ref={ref}
              value={value}
              onValueChange={onChange}
              onBlur={onBlur}
              orientation="vertical"
              data-testid="event-type"
            >
              <Radio label={i18n.t('Allowed')} value={EventTypeValue.ALLOW} />
              <Radio label={i18n.t('Blocked')} value={EventTypeValue.BLOCK} />
            </RadioGroup>
          )}
        />
        {watchEventType === EventTypeValue.ALLOW ? (
          <Banner
            theme="default"
            text={i18n.t(
              'If this item is detected on a device, it will be reported by the Threat agent and allowed access.',
            )}
          />
        ) : (
          <>
            <Banner
              theme="default"
              text={i18n.t(
                'Blocked items are treated as Malware in the system, and adhere to the Malware security posture specified in the General section.',
              )}
            />
            {generalSettings.malware_posture === PostureValue.DETECT && (
              <Banner
                theme="warning"
                text={i18n.t(
                  'The Malware security posture must be in Protect mode for this item to be automatically quarantined on a device.',
                )}
              />
            )}
          </>
        )}
      </Flex>
    </Flex>
  );

  const addAnotherItemSwitch = (
    <Switch
      labelRight={i18n.t('Add another item')}
      onCheckedChange={() => setShouldAddAnother(!shouldAddAnother)}
      data-testid="add-another"
    />
  );

  const addedItemsInfo = (
    <Flex gap="xs" alignItems="center">
      <Icon name="circle-check" size="md" color="var(--color-green-50)" />
      <Text css={{ color: '$neutral70' }}>
        {i18n.t('{numItems} added', {
          numItems: i18n.common.numItems(addAnotherCount),
        })}
      </Text>
    </Flex>
  );

  const footer = (
    <Flex justifyContent="space-between" className="b-ml1 b-mr1">
      <Flex gap="md">
        {!isEditing && addAnotherItemSwitch}
        {!isEditing && addAnotherCount > 0 && addedItemsInfo}
      </Flex>
      <Flex gap="sm">
        <Button onClick={onCancel} variant="subtle">
          {i18n.t(`Cancel`)}
        </Button>
        <Button variant="primary" onClick={handleAddSave}>
          {isEditing ? i18n.t('Save') : i18n.t('Add')}
        </Button>
      </Flex>
    </Flex>
  );

  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={onCancel}
      css={{ width: '560px' }}
      title={isEditing ? i18n.t('Edit item') : i18n.t('Add new item')}
      content={content}
      footer={footer}
    />
  );
};

export default AllowBlockModal;
