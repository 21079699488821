/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query';
import { rootApi } from 'src/app/api/base';

export type Quote = {
  macOsDeviceCount: number;
  macOsUnitPrice: number;
  macOsPriceTotal: number;
  edrEnabled: boolean;
  edrPriceTotal: number;
  iosAppleTvDeviceCount: number;
  iosAppleTvDeviceUnitPrice: number;
  iosAppleTvPriceTotal: number;
  quoteTotal: number;
  quoteTotalCurrency: string;
  quoteContractTermMonths: number;
  quoteUrl: string;
  quoteRef: string;
};

export const useGetQuote = () => {
  return useQuery<Quote>({
    queryKey: ['get-quote'],
    refetchOnWindowFocus: true,
    queryFn: async () => {
      const apiRes = await rootApi('trial/v1/quotes/latest').get(null);
      const quote = apiRes.data.quote;

      return {
        macOsDeviceCount: quote.mac_os_device_count,
        macOsUnitPrice: quote.mac_os_unit_price,
        macOsPriceTotal: quote.mac_os_price_total,
        edrEnabled: quote.edr_enabled,
        edrPriceTotal: quote.edr_price_total,
        iosAppleTvDeviceCount: quote.ios_apple_tv_device_count,
        iosAppleTvDeviceUnitPrice: quote.ios_apple_tv_device_unit_price,
        iosAppleTvPriceTotal: quote.ios_apple_tv_price_total,
        quoteTotal: quote.quote_total,
        quoteTotalCurrency: quote.quote_total_currency,
        quoteContractTermMonths: quote.quote_contract_term_months,
        quoteUrl: quote.quote_url,
        quoteRef: quote.quote_ref,
      } as Quote;
    },
  });
};
