// findByProperty - scans array until it finds an exact match and then stops, ignoring any remaining objects in the array
export const findByProperty = (array, name, value) => {
  if (!array) {
    return {};
  }
  const object = array.find((obj) => obj[name] === value); // returns undefined if no match
  return object || {}; // returns only one object from the array or an empty object if no match
};

// excludeByProperty - returns a new array containing all the original objects except for any objects matching the property (used to remove an object from an array)
export const excludeByProperty = (array, name, value) =>
  array.filter((obj) => obj[name] !== value);
