import type { Rule, RuleData } from 'features/rules-modal/types';
import { operatorHasMultipleValues } from 'features/rules-modal/utils';
import {
  AM_OS_VERSION_PARENT_KEY,
  LI_DEVICE_SPECIFIC_OS_VERSION_FACETS,
  LI_OS_VERSION_DEFAULT_OPERATOR,
  OS_VERSION_PARENT_KEY,
  TEXT_TYPES,
} from 'features/rules/constants';
import { createRule, extractRuleData } from 'features/rules/transformers';

export const transformRulesToJSONLogic = (rules, facetMap): RuleData => {
  const result = [];

  rules.and.forEach((rule) => {
    const input = rule.input;
    const ruleData = rule[input];

    // special case: library item os version
    if (input === OS_VERSION_PARENT_KEY) {
      Object.keys(ruleData).forEach((childInput) => {
        const childRuleData = ruleData[childInput];
        const {
          operator: childOperator,
          value: childValue,
          multi_value,
        } = childRuleData;
        if (childOperator !== LI_OS_VERSION_DEFAULT_OPERATOR) {
          result.push(
            createRule(
              childInput,
              childOperator,
              'computer',
              childOperator === TEXT_TYPES.BETWEEN ? multi_value : childValue,
            ),
          );
        }
      });
      return;
    }

    // special case: assignment map os version
    if (input === AM_OS_VERSION_PARENT_KEY) {
      const amOSVersionRules = [];
      ruleData.children.forEach((childRuleData) => {
        const childInput = childRuleData.input;
        const {
          operator: childOperator,
          value: childValue,
          multi_value,
        } = childRuleData;

        amOSVersionRules.push(
          createRule(
            childInput,
            childOperator,
            'computer',
            childOperator === TEXT_TYPES.BETWEEN ? multi_value : childValue,
          ),
        );
      });
      result.push({ or: amOSVersionRules });
      return;
    }

    // standard rule
    const { subject, data_type } = facetMap[input];
    const { operator, value, multi_value } = ruleData;
    const parsedValue = operatorHasMultipleValues(data_type, operator)
      ? JSON.parse(/* istanbul ignore next */ multi_value || '[]')
      : value;
    result.push(createRule(input, operator, subject, parsedValue));
  });

  /* istanbul ignore else */
  if (result.length) {
    return { and: result };
  }

  /* istanbul ignore next */
  return null;
};

export const transformJSONLogicToRules = (jsonLogic, facetMap): RuleData => {
  const rules = [];

  jsonLogic.and.forEach((rule) => {
    // special case: assignment map os versions
    if (rule.or) {
      const amOSVersionRule = {
        input: AM_OS_VERSION_PARENT_KEY,
        [AM_OS_VERSION_PARENT_KEY]: {
          children: [],
        },
      };
      rule.or.forEach((orRule) => {
        const { input, operator, value } = extractRuleData(orRule);
        const ruleData: Rule = { input, operator };
        const { data_type } = facetMap[input];
        if (operatorHasMultipleValues(data_type, operator)) {
          ruleData.multi_value = value;
        } else if (operator !== TEXT_TYPES.IS_ANY) {
          ruleData.value = value;
        }

        amOSVersionRule[AM_OS_VERSION_PARENT_KEY].children.push(ruleData);
      });
      rules.push(amOSVersionRule);
      return;
    }

    const { input, operator, value } = extractRuleData(rule);

    // special case: library os versions
    if (LI_DEVICE_SPECIFIC_OS_VERSION_FACETS.includes(input)) {
      const osVersionRuleIndex = rules.findIndex(
        (r) => r.input === OS_VERSION_PARENT_KEY,
      );
      // if we already have an os version rule, add to it
      if (osVersionRuleIndex !== -1) {
        rules[osVersionRuleIndex][OS_VERSION_PARENT_KEY][input] = {
          operator,
          // value,
          ...(operator === TEXT_TYPES.BETWEEN
            ? { multi_value: value }
            : { value }),
        };
      }
      // otherwise create a new os version rule
      else {
        rules.push({
          input: OS_VERSION_PARENT_KEY,
          [OS_VERSION_PARENT_KEY]: {
            [input]: {
              operator,
              ...(operator === TEXT_TYPES.BETWEEN
                ? { multi_value: value }
                : { value }),
            },
          },
        });
      }
      return;
    }

    // basic rule
    const { data_type } = facetMap[input];
    if (operatorHasMultipleValues(data_type, operator)) {
      rules.push({
        input,
        [input]: {
          operator,
          multi_value: JSON.stringify(value),
        },
      });
    } else {
      rules.push({
        input,
        [input]: {
          operator,
          value,
        },
      });
    }
  });

  return { and: rules };
};
