import { Tooltip, TooltipProvider } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import * as React from 'react';
import TableHeaderIconButton from './components/TableHeaderIconButton';

type FleetNavCollapseButtonProps = {
  onClick: () => void;
  isOpen: boolean;
};
const FleetNavCollapseButton = ({
  onClick,
  isOpen,
}: FleetNavCollapseButtonProps) => (
  <TooltipProvider>
    <Tooltip
      content={
        !isOpen ? i18n.t('Collapse table view') : i18n.t('Expand table view')
      }
      side="top"
    >
      <TableHeaderIconButton
        onClick={onClick}
        icon={!isOpen ? 'arrow-right-to-line' : 'arrow-left-from-line'}
      />
    </Tooltip>
  </TooltipProvider>
);

export default FleetNavCollapseButton;
