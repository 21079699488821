export const MAX_DEVICE_FAMILIES = 4;
export const GLOBAL_SIDEBAR_OPEN_WIDTH_PX = 256;
export const GLOBAL_SIDEBAR_CLOSED_WIDTH_PX = 78;

/**
 * Height of the prism content area
 *  304px measures the height of the Prism header, tabs container,
 *  and the global filter bar, as well as the space we need to afford
 *  the bottom of the content area to the top of the HubSpot Bubble x_x
 */
export const PRISM_CONTENT_HEIGHT = 'calc(100vh - 304px)';
export const PRISM_CONTENT_OFFSET = 230;

/**
 * Height of the prism content area
 *  454px measures the navigation & HubSpot height calculations (above) as well as
 *  category filters and pagination controls
 */
export const PRISM_TABLE_HEIGHT = 'calc(100vh - 454px)';
export const PRISM_TABLE_OFFSET = 380;
