import {
  Button,
  Flex,
  Heading,
  Paragraph,
  styled,
} from '@kandji-inc/nectar-ui';
import React from 'react';
import wrench from '../assets/img/wrench.png';

const Wrap = styled(Flex, {
  alignContent: 'stretch',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: 'calc(100vh - 50px)',
});

const Wrench = styled('img', {
  marginBottom: '24px',
});

const Maintenance = () => (
  <Wrap>
    <Wrench src={wrench} />
    <Heading size="2">Under Maintenance</Heading>
    <Paragraph size="1">
      The page you are looking for is under maintenance and will be back
      shortly.
    </Paragraph>
    <Flex
      justifyContent="space-between"
      css={{ padding: '24px', width: '300px' }}
    >
      <Button
        variant="subtle"
        css={{ border: '2px solid $blue50', color: '$blue50' }}
        onClick={() => window.open('https://support.kandji.io/', '_blank')}
      >
        Learn More
      </Button>
      <Button onClick={() => window.location.reload(true)}>Refresh Page</Button>
    </Flex>
  </Wrap>
);

export default Maintenance;
