import { useEffect, useState } from 'react';
import { selfServiceService } from './self-service.service';
import { selfServiceFromApi, selfServiceToApi } from './transformers';

const useSelfService = (transformer = selfServiceFromApi) => {
  const [data, setData] = useState();
  const [err, setErr] = useState();

  const patch = (model, transformerTo = selfServiceToApi) =>
    selfServiceService.patch(transformerTo(model)).then((r) => {
      setData(transformer(r.data));
      return r;
    });

  useEffect(() => {
    selfServiceService
      .get()
      .then((r) => setData(transformer(r.data)))
      .catch(() => setErr(true));
  }, []);

  return [err, data, patch];
};

export default useSelfService;
