import uuid from 'uuid';

import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import configs from '../../../library/library-item-configurations/items/profile';

/**
 * Returns object model used by the UI to read/create/update the Library Item's settings
 * NOTE: Each Team ID has two properties (AllowUserOverrides, AllowNonAdminUserApprovals) that have the same values for all Team IDs
 * @param {Array} settings List of Team IDs
 * @returns {Object}
 */
function transformSettings(settings) {
  // NOTE: `AllowUserOverrides` and `AllowNonAdminUserApprovals` values are the same on all Team IDs
  // They need to be pulled out of one of the Team IDs for the checkbox values.

  const { AllowUserOverrides, AllowNonAdminUserApprovals } = settings[0];

  return {
    ...settings,
    general: {
      AllowUserOverrides: Boolean(AllowUserOverrides),
      AllowNonAdminUserApprovals: Boolean(AllowNonAdminUserApprovals),
    },
    // Add client-side `id`s so we can track each unique team ID and Bundle ID for `key` props and deleting:
    teamIds: {
      teams: settings.map((teamId) => ({
        ...teamId,
        id: uuid(),
        AllowedKernelExtensions: teamId.AllowedKernelExtensions.map(
          (bundleId) => ({ ...bundleId, id: uuid() }),
        ),
      })),
    },
  };
}

async function transformFromApi(apiData) {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const commonData = {
    id: apiData.id,
    name: apiData.name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
    rules: apiData.rules,
  };

  const result = {
    ...commonData,
    isActive: apiData.active,
    ...transformSettings(apiData.data),
  };

  return {
    ...apiData,
    data: result,
  };
}

function transformToApi(model) {
  const teams = model.teamIds.teams.map((teamId) => ({
    // Set the current General settings on each Team ID:
    AllowUserOverrides: model.general.AllowUserOverrides,
    AllowNonAdminUserApprovals: model.general.AllowNonAdminUserApprovals,

    // Team ID-specific properties:
    AllowedTeamIdentifiers: teamId.AllowedTeamIdentifiers,
    DisplayName: teamId.DisplayName,
    AllowedKernelExtensions: teamId.AllowedKernelExtensions.map((bundle) => ({
      // Pick specific properties since we don't need everything (ex: `id` is only used on client-side)
      BundleId: bundle.BundleId,
      DisplayName: bundle.DisplayName,
    })),
  }));

  const toSend = {
    name: model.name,
    active: model.isActive,
    blueprints: model.selectedBlueprints.map((b) => b.value),
    is_all_blueprints: model.isAllBlueprints,
    rules: model.rules || null,
    data: teams,
  };

  // Add necessary properties when creating new Profile:
  if (!model.id) {
    const itemConfig = configs['Kernel Extension'];

    toSend.type = itemConfig.type;
    toSend.identifier = itemConfig.identifier;
  }

  return toSend;
}

export { transformToApi, transformFromApi };
