// istanbul ignore file
import type { CSS } from '@kandji-inc/nectar-ui';
import { Icon, styled } from '@kandji-inc/nectar-ui';
import * as React from 'react';
import * as ResizablePrimitive from 'react-resizable-panels';

const StyledPanelGroup = styled(ResizablePrimitive.PanelGroup, {
  display: 'flex',
  height: '100%',
  width: '100%',

  '& [data-panel-group-direction=vertical]': {
    flexDirection: 'column',
  },
});

const ResizablePanelGroup = React.forwardRef<
  ResizablePrimitive.ImperativePanelGroupHandle,
  React.ComponentProps<typeof ResizablePrimitive.PanelGroup> & CSS
>(({ ...props }, ref) => <StyledPanelGroup {...props} ref={ref} />);

const ResizablePanel = ResizablePrimitive.Panel;

const StyledResizableHandle = styled(ResizablePrimitive.PanelResizeHandle, {
  // Base styles
  position: 'relative',
  display: 'flex',
  width: '1px', // Assuming w-px is a custom class for width: 1px;
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'border', // You'll need to define this color in your theme or replace it with an actual color value

  // Pseudo-element styles for ::after
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '50%',
    width: '1px',
    transform: 'translateX(-50%)',
  },

  // Focus-visible styles
  '&:focus-visible': {
    outline: 'none',
    boxShadow: '0 0 0 1px $blue50, 0 0 0 3px $blue50', // Adjust the ring color based on your theme or preferences
  },

  // Data attribute selectors for [panel-group-direction="vertical"]
  '&[data-panel-group-direction="vertical"]': {
    height: '1px',
    width: '100%',

    '&::after': {
      left: '0',
      height: '1px',
      width: '100%',
      transform: 'translateX(0) translateY(-50%)',
    },
  },

  // Child div rotation based on data attribute
  '&[data-panel-group-direction="vertical"] > div': {
    transform: 'rotate(90deg)',
  },
});

const HandleWrapper = styled('div', {
  // Base styles
  position: 'relative',
  zIndex: '10',
  display: 'flex',
  height: '16px',
  width: '12px',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '$rounded',
  border: '1px solid $neutral70',
  backgroundColor: 'border',
  '& svg': {
    height: '0.625rem',
    width: '0.625rem',
  },
});

const ResizableHandle = ({
  withHandle,
  className,
  ...props
}: React.ComponentProps<typeof ResizablePrimitive.PanelResizeHandle> & {
  withHandle?: boolean;
}) => (
  <StyledResizableHandle className={className} {...props}>
    {withHandle && (
      <HandleWrapper>
        <Icon name="grip-dots-vertical" />
      </HandleWrapper>
    )}
  </StyledResizableHandle>
);

export { ResizablePanelGroup, ResizablePanel, ResizableHandle };
