import * as React from 'react';

export const usePrismPendo = ({
  attributeKey,
  columnVisibility,
  columnOrder,
  columnSizing,
  columnSizingInfo,
}: {
  attributeKey?: string | undefined;
  columnVisibility: Record<string, boolean>;
  columnOrder: string[];
  columnSizing: Record<string, number>;
  columnSizingInfo: {
    isResizingColumn: boolean | string;
  };
}) => {
  React.useEffect(() => {
    /* istanbul ignore next - not testing pendo */
    if (process.env.NODE_ENV !== 'test') {
      // @ts-expect-error -- pendo is injected in Meta.js
      pendo?.track('[Prism] Edit Column Visibility', {
        CATEGORY_NAME: attributeKey,
        ...columnVisibility,
      });
    }
  }, [columnVisibility]);

  React.useEffect(() => {
    /* istanbul ignore next - not testing pendo */
    if (process.env.NODE_ENV !== 'test') {
      // @ts-expect-error -- pendo is injected in Meta.js
      pendo?.track('[Prism] Edit Column Order', {
        CATEGORY_NAME: attributeKey,
        ...Object.fromEntries(columnOrder.map((col, idx) => [col, idx])),
      });
    }
  }, [columnOrder]);

  React.useEffect(() => {
    /* istanbul ignore next - not testing pendo */
    if (columnSizingInfo.isResizingColumn) {
      return;
    }

    /* istanbul ignore next - not testing pendo */
    if (process.env.NODE_ENV !== 'test') {
      // @ts-expect-error -- pendo is injected in Meta.js
      pendo?.track('[Prism] Edit Column Sizing', {
        CATEGORY_NAME: attributeKey,
        ...Object.fromEntries(
          Object.entries(columnSizing).map(([col, size]) => [
            col,
            String(size),
          ]),
        ),
      });
    }
  }, [columnSizingInfo]);
};
