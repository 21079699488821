/* istanbul ignore file */
import { api, publicApi } from 'app/api/base';
import {
  URL_ENROLLMENT_PORTAL_STATUS,
  URL_FEATURE_REQUEST,
  URL_USERS,
} from '../api/urls';
import { app } from './action-types';

export const setAppDataReady = (ready = true) => ({
  type: app.APP_DATA_READY,
  ready,
});

export const forgotPassword = (data) =>
  publicApi(`${URL_USERS}resetpassword/sendemail`).post(data);

export const validateResetPasswordToken = (token) => (dispatch) =>
  publicApi(`${URL_USERS}resetpassword`)
    .get({ token })
    .then((response) => {
      dispatch({
        type: app.RESET_PASSWORD_TOKEN_VALIDATED,
        payload: true,
      });
      return Promise.resolve(response);
    })
    .catch((error) => {
      dispatch({
        type: app.RESET_PASSWORD_TOKEN_VALIDATED,
        payload: false,
      });
      return Promise.reject(error);
    });

export const resetPassword = (form) =>
  publicApi(`${URL_USERS}resetpassword`).post(form);

export const validateRegistrationToken = (token) =>
  publicApi(`${URL_USERS}invite/validate-sign-up-token`)
    .post({ token })
    .then((response) => response.data);

export const registerUser = (form) =>
  publicApi(`${URL_USERS}invite/confirm/email`).post(form);

export const checkEnrollmentPortalStatus = () =>
  api(URL_ENROLLMENT_PORTAL_STATUS).get();

export const sendFeatureRequest = (data) => api(URL_FEATURE_REQUEST).post(data);
