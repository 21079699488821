export default {
  id: null,
  name: '',
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  isActive: true,
  supportsRules: true,
  supportsInstallOn: true,

  manageBookmarks: {
    bookmarks: [],
  },
};
