import {
  Banner,
  Checkbox,
  TextInput,
  setClass,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
/* istanbul ignore file */
import React, { useEffect } from 'react';

import { Setting } from 'features/library-items/template';
import { i18n } from 'src/i18n';

import {
  requiredField,
  requiredFieldAndMatchPassword,
} from '../input-validations';
import { PRIMARY_ACCOUNT_TYPES } from './account-type-row';

const LocalAdministratorRow = ({
  setting,
  update,
  isDisabled,
  isSaving,
  beforeEditModel,
  setModel,
}) => {
  const isForceCheck = [
    PRIMARY_ACCOUNT_TYPES.SKIP,
    PRIMARY_ACCOUNT_TYPES.STANDARD,
  ].includes(setting.accountType);

  const fieldsToValidate = [
    'fullName',
    'shortName',
    'password',
    'verifyPassword',
  ];
  const { refs, onInvalidate, invalidations } = useInputsValidators(
    fieldsToValidate,
    update,
  );
  useRemoveValidationOnUnmount(fieldsToValidate, update);

  useEffect(() => {
    if (!setting.provisionLocalAdminAccount) {
      for (let i = 0; i < fieldsToValidate.length; i++) {
        onInvalidate(i)(false);
      }
    } else {
      setModel((p) => ({
        ...p,
        mac: {
          ...p.mac,
          password: beforeEditModel.mac.password,
          verifyPassword: beforeEditModel.mac.verifyPassword,
          shortName: beforeEditModel.mac.shortName,
          fullName: beforeEditModel.mac.fullName,
        },
      }));
    }
  }, [setting.provisionLocalAdminAccount]);

  useEffect(() => {
    if (isForceCheck) {
      update('provisionLocalAdminAccount', true);
    }
  }, [isForceCheck]);

  return (
    <Setting.Row>
      <Setting.Controls>
        <Checkbox
          checked={setting.provisionLocalAdminAccount || isForceCheck}
          label={i18n.t('Provision local administrator account')}
          onChange={() => update('provisionLocalAdminAccount', (p) => !p)}
          disabled={isDisabled || isForceCheck}
        />
      </Setting.Controls>
      <Setting.Helpers>
        <p className="b-txt-light">
          {i18n.t(`Create a local administrator on Mac computers during the enrollment
          process. This is required if the Standard Account or Skip Account
          Creation option is selected above.`)}
        </p>
      </Setting.Helpers>
      {(setting.provisionLocalAdminAccount || isForceCheck) && (
        <Setting.SecondaryControls className="k-ade-secondary-ctrl-grid-gap-align">
          <Setting.SecondaryControlsRow className="k-ade-org-activation-lock k-ade-fullname-row">
            <div>
              <p className="b-txt">{i18n.t('Full name')}</p>
            </div>
            <Setting.Helpers>
              <p className="b-txt-light">
                {i18n.t(
                  'Provide the full name to be used for the local administrator account during Setup Assistant. Cannot exceed 32 characters.',
                )}
              </p>
            </Setting.Helpers>
            <div ref={refs[0]}>
              <TextInput
                value={setting.fullName || ''}
                onChange={(e) => update('fullName', e.target.value)}
                placeholder={i18n.t('Ex: John Doe')}
                disabled={isDisabled}
                compact
                maxLength={32}
                fieldsGrid="k-ade-max-input"
                validator={requiredField}
                onInvalidate={onInvalidate(0)}
                runValidationOn={[isSaving]}
                name="fullName"
              />
            </div>
          </Setting.SecondaryControlsRow>
          <Setting.SecondaryControlsRow className="k-ade-shortname-row">
            <div>
              <p className="b-txt">{i18n.t('Short name')}</p>
            </div>
            <Setting.Helpers>
              <p className="b-txt-light">
                {i18n.t(
                  'Provide the user name to be used for the local administrator account during Setup Assistant. Cannot exceed 64 characters.',
                )}
              </p>
            </Setting.Helpers>
            <div ref={refs[1]}>
              <TextInput
                value={setting.shortName || ''}
                onChange={(e) => update('shortName', e.target.value)}
                placeholder={i18n.t('Ex: jdoe')}
                disabled={isDisabled}
                compact
                maxLength={64}
                fieldsGrid="k-ade-max-input"
                validator={(v) => [
                  requiredField(v),
                  {
                    message: i18n.t('Short name cannot have spaces.'),
                    invalid: () => /\s/g.test(v),
                    trigger: ['onBlur', 'onInput'],
                  },
                ]}
                onInvalidate={onInvalidate(1)}
                runValidationOn={[isSaving]}
                name="shortName"
              />
            </div>
          </Setting.SecondaryControlsRow>
          <Setting.SecondaryControlsRow className="k-ade-password-row">
            <div>
              <p className="b-txt">{i18n.t('Password')}</p>
            </div>
            <Setting.Helpers>
              <p className="b-txt-light">
                {i18n.t(
                  'Provide the local administrator account password to be used during Setup Assistant. This password can be changed remotely.',
                )}
              </p>
            </Setting.Helpers>
            <div ref={refs[2]} className="k-ade-pwd-input">
              <TextInput
                value={setting.password || ''}
                onChange={(e) => update('password', e.target.value)}
                disabled={isDisabled}
                compact
                type="password"
                validator={requiredFieldAndMatchPassword(
                  setting.verifyPassword,
                )}
                onInvalidate={onInvalidate(2)}
                runValidationOn={[setting.verifyPassword, isSaving]}
                name="password"
                maxLength={31}
              />
            </div>
          </Setting.SecondaryControlsRow>
          <Setting.SecondaryControlsRow>
            <div
              ref={refs[3]}
              className={setClass(invalidations[3] && 'b-mb2')}
            >
              <p className="b-txt b-mb1">{i18n.t('Verify password')}</p>
              <TextInput
                value={setting.verifyPassword || ''}
                onChange={(e) => update('verifyPassword', e.target.value)}
                disabled={isDisabled}
                compact
                type="password"
                validator={requiredFieldAndMatchPassword(setting.password)}
                onInvalidate={onInvalidate(3)}
                runValidationOn={[setting.password, isSaving]}
                name="verifyPassword"
                maxLength={31}
              />
            </div>
          </Setting.SecondaryControlsRow>
          <Setting.SecondaryControlsRow>
            <div>
              <Checkbox
                checked={setting.hideAccount}
                label={i18n.t('Hide account')}
                onChange={() => update('hideAccount', (p) => !p)}
                disabled={isDisabled}
              />
            </div>
            <div>
              <p className="b-txt-light">
                {i18n.t(
                  'Hide the local administrator account in System Preferences to prevent end users from interfering with the managed account.',
                )}
              </p>
            </div>
          </Setting.SecondaryControlsRow>
          <Setting.SecondaryControlsRow>
            <div>
              <Checkbox
                checked={setting.makeAutoAdmin}
                label={i18n.t('Make auto admin the MDM enabled user')}
                onChange={() => update('makeAutoAdmin', (p) => !p)}
                disabled={isDisabled}
              />
            </div>
            <div>
              <p className="b-txt-light b-mb1">
                {i18n.t(
                  'Make the additional local administrator account the MDM enabled user.',
                )}
              </p>
              <Banner theme="info" kind="block" style={{ minWidth: 'unset' }}>
                <p>
                  {i18n.t(`Having the auto admin account as the MDM enabled user will
                  prevent the user account created during Setup Assistant from
                  being MDM enabled.`)}
                </p>
              </Banner>
            </div>
          </Setting.SecondaryControlsRow>
        </Setting.SecondaryControls>
      )}
    </Setting.Row>
  );
};

export default LocalAdministratorRow;
