import { forwardRef, useMemo } from 'react';
import type { ReactNode, Ref } from 'react';

import { Grid } from '@kandji-inc/nectar-ui';
import type { CSS } from '@kandji-inc/nectar-ui';

import { LAYOUT_CONFIG } from '../common';

interface ArrangementLayoutProps {
  readonly children: ReactNode;
  readonly css?: CSS;
}

const { height: heightCss } = LAYOUT_CONFIG;

const ArrangementLayoutComponent = (
  { children, css = {} }: ArrangementLayoutProps,
  ref: Ref<HTMLDivElement>,
) => {
  const layoutCss = useMemo(
    () => ({
      [heightCss.portrait.styledVar]: heightCss.portrait.px,
      [heightCss.landscape.styledVar]: heightCss.landscape.px,
      [heightCss.styledVar]: heightCss.portrait.px,

      gridTemplateColumns: '400px 1fr',
      ...css,
    }),
    [css],
  );

  return (
    <Grid ref={ref} gap={5} columns={2} css={layoutCss}>
      {children}
    </Grid>
  );
};

const ArrangementLayout = forwardRef<HTMLDivElement, ArrangementLayoutProps>(
  ArrangementLayoutComponent,
);

export default ArrangementLayout;
