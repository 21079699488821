import deepcopy from 'deepcopy';
import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import initialState from '../initial-state';
import EnergySaverService from './energy-saver.service';

const getSettingFrom =
  (setting, rootKey, defaults) =>
  (key, transformer = (v) => v, ignoreDefault) => {
    const data = setting?.[rootKey] || (ignoreDefault ? {} : defaults);

    if (!(key in data)) {
      return ignoreDefault ? null : defaults[key];
    }

    return transformer(data[key]);
  };

const isNullish = (x) => x === undefined || x === null;

async function transformFromApi(apiData) {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const desktops = getSettingFrom(
    apiData.data,
    'com.apple.EnergySaver.desktop.ACPower',
    deepcopy(initialState.desktops),
  );
  const battery = getSettingFrom(
    apiData.data,
    'com.apple.EnergySaver.portable.BatteryPower',
    deepcopy(initialState.battery),
  );
  const powerAdapter = getSettingFrom(
    apiData.data,
    'com.apple.EnergySaver.portable.ACPower',
    deepcopy(initialState.powerAdapter),
  );
  const schedule = getSettingFrom(
    apiData.data,
    'com.apple.EnergySaver.desktop.Schedule',
    deepcopy(initialState.schedule),
  );

  const result = {
    id: apiData.id,
    name: apiData.name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
    rules: apiData.rules,
    isActive: apiData.active,

    general: {
      SleepDisabled: apiData.data.SleepDisabled,
      DestroyFVKeyOnStandby: apiData.data.DestroyFVKeyOnStandby,
    },

    desktops: {
      'Display Sleep Timer': desktops('Display Sleep Timer'),
      isTurnDisplayOff: !isNullish(
        desktops('Display Sleep Timer', Boolean, true),
      ),

      'System Sleep Timer': desktops('System Sleep Timer', (v) => (v ? 10 : v)),
      isSystemSleepTimer: !isNullish(
        desktops('System Sleep Timer', Boolean, true),
      ),

      'Disk Sleep Timer': desktops('Disk Sleep Timer'),
      isDiskSleepTimer: !isNullish(desktops('Disk Sleep Timer', Boolean, true)),

      'Wake on LAN': desktops('Wake on LAN'),
      isWakeOnLan: !isNullish(desktops('Wake on LAN', Boolean, true)),

      'Automatic Restart On Power Loss': desktops(
        'Automatic Restart On Power Loss',
      ),
      isRestartOnPowerLoss: !isNullish(
        desktops('Automatic Restart On Power Loss', Boolean, true),
      ),

      DarkWakeBackgroundTasks: desktops('DarkWakeBackgroundTasks'),
      isPowerNap: !isNullish(
        desktops('DarkWakeBackgroundTasks', Boolean, true),
      ),
    },

    battery: {
      'Display Sleep Timer': battery('Display Sleep Timer'),
      isTurnDisplayOff: !isNullish(
        battery('Display Sleep Timer', Boolean, true),
      ),

      'Disk Sleep Timer': battery('Disk Sleep Timer'),
      isDiskSleepTimer: !isNullish(battery('Disk Sleep Timer', Boolean, true)),

      ReduceBrightness: battery('ReduceBrightness'),
      isReduceBrightness: !isNullish(
        battery('ReduceBrightness', Boolean, true),
      ),

      DarkWakeBackgroundTasks: battery('DarkWakeBackgroundTasks'),
      isPowerNap: !isNullish(battery('DarkWakeBackgroundTasks', Boolean, true)),
    },

    powerAdapter: {
      'Display Sleep Timer': powerAdapter('Display Sleep Timer'),
      isTurnDisplayOff: !isNullish(
        powerAdapter('Display Sleep Timer', Boolean, true),
      ),

      'System Sleep Timer': powerAdapter('System Sleep Timer', (v) =>
        v ? 10 : v,
      ),
      isSystemSleepTimer: !isNullish(
        powerAdapter('System Sleep Timer', Boolean, true),
      ),

      'Disk Sleep Timer': powerAdapter('Disk Sleep Timer'),
      isDiskSleepTimer: !isNullish(
        powerAdapter('Disk Sleep Timer', Boolean, true),
      ),

      'Wake on LAN': powerAdapter('Wake on LAN'),
      isWakeOnLan: !isNullish(powerAdapter('Wake on LAN', Boolean, true)),

      'Automatic Restart On Power Loss': powerAdapter(
        'Automatic Restart On Power Loss',
      ),
      isRestartOnPowerLoss: !isNullish(
        powerAdapter('Automatic Restart On Power Loss', Boolean, true),
      ),

      DarkWakeBackgroundTasks: powerAdapter('DarkWakeBackgroundTasks'),
      isPowerNap: !isNullish(
        powerAdapter('DarkWakeBackgroundTasks', Boolean, true),
      ),
    },

    schedule: {
      RepeatingPowerOn: {
        ...schedule('RepeatingPowerOn'),
        weekdays: EnergySaverService.weekDayToDays(
          schedule('RepeatingPowerOn')?.weekdays,
        ),
      },
      isRepeatingPowerOn: !isNullish(
        schedule('RepeatingPowerOn', Boolean, true),
      ),

      RepeatingPowerOff: {
        ...schedule('RepeatingPowerOff'),
        weekdays: EnergySaverService.weekDayToDays(
          schedule('RepeatingPowerOff')?.weekdays,
        ),
      },
      isRepeatingPowerOff: !isNullish(
        schedule('RepeatingPowerOff', Boolean, true),
      ),
    },
  };

  return {
    ...apiData,
    data: result,
  };
}

const removeNulls = (o) => {
  const nObj = Object.keys(o).reduce(
    (a, c) => ({
      ...a,
      ...(o[c] === null ? {} : { [c]: o[c] }),
    }),
    {},
  );

  return Object.keys(nObj).length ? nObj : null;
};

function transformToApi(model) {
  const toSend = {
    name: model.name,
    active: model.isActive,
    type: 'profile',
    identifier: 'com.kandji.profile.energysaver',
    blueprints: model.selectedBlueprints.map((b) => b.value),
    is_all_blueprints: model.isAllBlueprints,
    data: removeNulls({
      SleepDisabled: model.general.SleepDisabled,
      DestroyFVKeyOnStandby: model.general.DestroyFVKeyOnStandby,
      'com.apple.EnergySaver.desktop.ACPower': removeNulls({
        'Display Sleep Timer': model.desktops.isTurnDisplayOff
          ? model.desktops['Display Sleep Timer']
          : null,
        'System Sleep Timer': model.desktops.isSystemSleepTimer
          ? model.desktops['System Sleep Timer'] +
            // only add the Display Sleep Timer value if this setting is set to "Allow computer to sleep" and Display Sleep Timer is configured:
            (model.desktops['System Sleep Timer'] > 0 &&
            model.desktops.isTurnDisplayOff
              ? model.desktops['Display Sleep Timer']
              : 0)
          : null, // TODO:
        'Disk Sleep Timer': model.desktops.isDiskSleepTimer
          ? model.desktops['Disk Sleep Timer']
          : null,
        'Wake on LAN': model.desktops.isWakeOnLan
          ? model.desktops['Wake on LAN']
          : null,
        'Automatic Restart On Power Loss': model.desktops.isRestartOnPowerLoss
          ? model.desktops['Automatic Restart On Power Loss']
          : null,
        DarkWakeBackgroundTasks: model.desktops.isPowerNap
          ? model.desktops.DarkWakeBackgroundTasks
          : null, // TODO:
      }),
      'com.apple.EnergySaver.portable.BatteryPower': removeNulls({
        'Display Sleep Timer': model.battery.isTurnDisplayOff
          ? model.battery['Display Sleep Timer']
          : null,
        'Disk Sleep Timer': model.battery.isDiskSleepTimer
          ? model.battery['Disk Sleep Timer']
          : null,
        ReduceBrightness: model.battery.isReduceBrightness
          ? model.battery.ReduceBrightness
          : null,
        DarkWakeBackgroundTasks: model.battery.isPowerNap
          ? model.battery.DarkWakeBackgroundTasks
          : null, // TODO:
      }),
      'com.apple.EnergySaver.portable.ACPower': removeNulls({
        'Display Sleep Timer': model.powerAdapter.isTurnDisplayOff
          ? model.powerAdapter['Display Sleep Timer']
          : null,
        'System Sleep Timer': model.powerAdapter.isSystemSleepTimer
          ? model.powerAdapter['System Sleep Timer'] +
            // only add the Display Sleep Timer value if this setting is set to "Allow computer to sleep" and Display Sleep Timer is configured:
            (model.powerAdapter['System Sleep Timer'] > 0 &&
            model.powerAdapter.isTurnDisplayOff
              ? model.powerAdapter['Display Sleep Timer']
              : 0)
          : null, // TODO:
        'Disk Sleep Timer': model.powerAdapter.isDiskSleepTimer
          ? model.powerAdapter['Disk Sleep Timer']
          : null,
        'Wake on LAN': model.powerAdapter.isWakeOnLan
          ? model.powerAdapter['Wake on LAN']
          : null,
        'Automatic Restart On Power Loss': model.powerAdapter
          .isRestartOnPowerLoss
          ? model.powerAdapter['Automatic Restart On Power Loss']
          : null,
        DarkWakeBackgroundTasks: model.powerAdapter.isPowerNap
          ? model.powerAdapter.DarkWakeBackgroundTasks
          : null, // TODO:
      }),
      'com.apple.EnergySaver.desktop.Schedule': removeNulls({
        RepeatingPowerOn: model.schedule.isRepeatingPowerOn
          ? removeNulls({
              eventtype: model.schedule.RepeatingPowerOn.eventtype,
              time: model.schedule.RepeatingPowerOn.time,
              weekdays: EnergySaverService.daysToWeekdays(
                model.schedule.RepeatingPowerOn.weekdays,
              ),
            })
          : null,
        RepeatingPowerOff: model.schedule.isRepeatingPowerOff
          ? removeNulls({
              eventtype: model.schedule.RepeatingPowerOff.eventtype,
              time: model.schedule.RepeatingPowerOff.time,
              weekdays: EnergySaverService.daysToWeekdays(
                model.schedule.RepeatingPowerOff.weekdays,
              ),
            })
          : null,
      }),
    }),
    rules: model.rules || null,
  };

  if (model.skip_blueprint_conflict) {
    toSend.skip_blueprint_conflict = model.skip_blueprint_conflict;
  }

  return toSend;
}

export { transformToApi, transformFromApi };
