import React from 'react';
import styled from 'styled-components';

export const LineLoader = (props) => {
  const { height, isDelayed } = props;

  return (
    <LoaderContainer
      height={height}
      isDelayed={isDelayed}
      className="loader-with-text2"
    >
      <div className="loader-with-text2-line" />
      <div className="loader-with-text-data">
        <i className="fas fa-spinner fa-spin" />
        <span>Loading...</span>
      </div>
      <div className="loader-with-text2-line" />
    </LoaderContainer>
  );
};

const LoaderContainer = styled.div`
  &.loader-with-text2 {
    position: relative;
    display: flex;
    flex-flow: nowrap row;
    align-content: center;
    align-items: center;
    justify-content: center;

    height: ${({ height }) => height ?? '4rem'};
    width: 100%;

    opacity: 0;
    animation: delay 1ms ease;
    animation-delay: ${({ isDelayed }) => (isDelayed ? '1s' : '1ms')};
    animation-fill-mode: forwards;

    .loader-with-text2-line {
      height: 1px;
      background-color: $grey-200;
      width: 50%;
    }
  }
`;
