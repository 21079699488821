import { keepPreviousData, useQuery } from '@tanstack/react-query';
// istanbul ignore file
import { useMemo } from 'react';
import { z } from 'zod';
import { ThreatService } from '../../data-service';
import type { GetStatusCountsResponse } from '../../threat.types';

const StatusCountsSchema = z.object({
  malware: z.object({
    total: z.number(),
    quarantined: z.number(),
    not_quarantined: z.number(),
    released: z.number(),
    resolved: z.number(),
  }),
  pup: z.object({
    total: z.number(),
    quarantined: z.number(),
    not_quarantined: z.number(),
    released: z.number(),
    resolved: z.number(),
  }),
});

type UseGetStatusCounts = (
  dateRange: { from: string; to: string },
  enabled?: boolean,
) => {
  data: GetStatusCountsResponse | undefined;
  isLoading: boolean;
  isError: boolean;
  refetch: () => Promise<void>;
};

/**
 * Retrieves the status counts for malware and PUP threats.
 *
 * @returns An object containing the status counts data and a boolean indicating if the data is currently being loaded.
 */
const useGetStatusCounts: UseGetStatusCounts = (dateRange, enabled = true) => {
  const threatService = useMemo(() => new ThreatService(), []);
  const { data, isPlaceholderData, isLoading, refetch, isError } = useQuery({
    queryKey: ['status-counts', dateRange],
    queryFn: async () => {
      const data = await threatService.getStatusCounts({
        detection_date_from: dateRange.from,
        detection_date_to: dateRange.to,
      });

      if (!StatusCountsSchema.safeParse(data?.data).success) {
        throw new Error('Invalid schema');
      }

      return data;
    },
    placeholderData: keepPreviousData,
    retry: 2,
    retryDelay: 1000,
    enabled,
  });

  return {
    data: data?.data,
    isLoading: isLoading || isPlaceholderData,
    isError,
    refetch: refetch as unknown as () => Promise<void>,
  };
};

export default useGetStatusCounts;
