import { Button } from '@kandji-inc/bumblebee';
import { colors } from 'app/common/constants';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { assignDEPComputersUser as callAssignDEPComputersUser } from '../../../_actions/DEPComputer';
import { getUsers as callGetUsers } from '../../../_actions/gSuite';
import { LineLoader } from '../LineLoader';
import SearchString from '../SearchString';
import { ModalWrapper } from './ModalWrapper';
import { ButtonsWrapper, CancelButtonWrapper } from './elements';

const UsersTable = styled.div`
  display: flex;
  flex-direction: column;
  height: 300px;
  width: 100%;
  margin: 15px 0;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    margin-left: 4px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${colors.black};
    border-radius: 4px;
  }
`;

const UserElement = styled.div`
  display: flex;
  flex-direction: row;
  width: 95%;
  border-radius: 5px;
  font-family: var(--font-family-primary);
  font-weight: 400;
  min-height: 60px;
  margin: 4px 0;
  padding: 5px;
  &:hover {
    background-color: ${colors['grey-150']};
    cursor: pointer;
  }
  &:active {
    cursor: pointer;
  }
  ${(props) =>
    props.active ? `background-color: ${colors['grey-150']}` : null};
`;

const PhotoWrapper = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  overflow: hidden;
  margin-right: 20px;
`;

const Photo = styled.img`
  width: 100%;
  height: 100%;
`;

const NoPhotoWrapper = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: 1px solid ${colors['grey-150']};
  overflow: hidden;
  margin-right: 20px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoPhotoIconWrapper = styled.div`
  width: 50%;
  height: 50%;
  text-align: center;
`;

const NoPhotoIcon = styled.i`
  font-size: 20px;
`;

const UserInfoWrapper = styled.div`
  padding-top: 5px;
  display: flex;
  flex-direction: column;
`;

const Name = styled.div``;

const Email = styled.div`
  font-size: 0.8rem;
  color: ${colors['grey-500']};
`;

const NoResults = styled.div`
  text-align: center;
  font-family: var(--font-family-primary);
  font-weight: 400;
`;

export class ComputerChangeUser extends ModalWrapper {
  constructor(props) {
    super(props);
    this.modalName = 'ComputerUserEdit';
    this.state = {
      isLoading: false,
      chosenUser: this.props.computerRecord.user,
      searchString: '',
    };
    this.onAssign = this.onAssign.bind(this);
  }

  componentDidMount() {
    const { getUsers } = this.props;
    this.setState(
      {
        isLoading: true,
      },
      () => {
        getUsers({ is_archived: false, sizePerPage: 9999 }).then(() =>
          this.setState({ isLoading: false }),
        );
      },
    );
  }

  onAssign() {
    const { assignDEPComputersUser, info, setSnackbar } = this.props;
    const { chosenUser } = this.state;
    const ids = info.computers;
    if (!(ids && ids.length) && !info.computerId) {
      return setSnackbar('No Device Selected');
    }
    if (!chosenUser) {
      return setSnackbar('No User Selected');
    }
    let payload;
    chosenUser === 'No info' ? (payload = null) : (payload = chosenUser.id);
    this.setState({ isLoading: true });
    assignDEPComputersUser(ids, payload)
      .then(() => {
        this.setState({ isLoading: false });
        setSnackbar('User was assigned successfully');
        this.onHide();
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        setSnackbar(err.message);
      });
    return '';
  }

  renderHeader = () => 'Assign this Device';

  renderBody() {
    const { searchString, isLoading, chosenUser } = this.state;
    const { gSuiteUsers } = this.props;
    const filteredUsers = gSuiteUsers
      .filter(
        (user) =>
          user.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1 ||
          user.email.toLowerCase().indexOf(searchString.toLowerCase()) > -1,
      )
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
    if (isLoading) {
      return <LineLoader />;
    }
    return (
      <>
        <SearchString
          label="Search Users..."
          searchFunc={(e) => this.setState({ searchString: e })}
        />
        {!isEmpty(filteredUsers) && (
          <UsersTable>
            {filteredUsers.map((user) => (
              <UserElement
                key={user.id}
                onClick={() => {
                  if (chosenUser && chosenUser.id === user.id) {
                    this.setState({ chosenUser: 'No info' });
                  } else {
                    this.setState({ chosenUser: user });
                  }
                }}
                active={chosenUser && chosenUser.id === user.id}
              >
                {user.photo && (
                  <PhotoWrapper>
                    <Photo src={user.photo} alt="" />
                  </PhotoWrapper>
                )}
                {!user.photo && (
                  <NoPhotoWrapper>
                    <NoPhotoIconWrapper>
                      <NoPhotoIcon className="ss-icon ss-user" />
                    </NoPhotoIconWrapper>
                  </NoPhotoWrapper>
                )}
                <UserInfoWrapper>
                  <Name>{user.name}</Name>
                  <Email>{user.email}</Email>
                </UserInfoWrapper>
              </UserElement>
            ))}
          </UsersTable>
        )}
        {isEmpty(filteredUsers) && (
          <UsersTable>
            <NoResults>No users found</NoResults>
          </UsersTable>
        )}
      </>
    );
  }

  renderFooter() {
    const { isLoading, chosenUser } = this.state;
    return (
      <ButtonsWrapper>
        <CancelButtonWrapper>
          <Button type="button" onClick={this.onHide} kind="outline">
            Cancel
          </Button>
        </CancelButtonWrapper>
        <Button
          type="button"
          disabled={isLoading || !chosenUser}
          onClick={this.onAssign}
        >
          Assign
        </Button>
      </ButtonsWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  gSuiteUsers: state.gSuite.gSuiteUsers,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUsers: callGetUsers,
      assignDEPComputersUser: callAssignDEPComputersUser,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ComputerChangeUser);
