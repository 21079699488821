import React from 'react';
import SelectInput from '../SelectInput';

export const ParamNameFilter = (props) => {
  const { style, onChange, selected, parameterMeta, onFetch } = props;

  let options = Object.keys(parameterMeta).map((e) => [
    e,
    parameterMeta[e].name,
  ]);
  options.sort((a, b) => (a[0] > b[0] ? 1 : -1));
  options = ['Parameters', ['ALL', 'All Parameters'], ''].concat(options);

  return (
    <SelectInput
      options={options}
      selected={selected}
      inlineSearch
      style={style}
      size="sm"
      title="Select Parameter"
      titlePlural="param"
      onChange={onChange}
      onFetch={onFetch}
      multiple
      showCheck
    />
  );
};

export default ParamNameFilter;
