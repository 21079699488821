import { colors } from 'app/common/constants';
import classNames from 'classnames';
import has from 'lodash/has';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Icon from '../../../theme/components/atoms/Icon';
import { useOnClickOutside } from './hooks';

const Wrapper = styled('div')`
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  user-select: none;
  position: relative;
  width: 230px;
  height: 40px;
  background: white;
  border: 2px solid ${(props) => (props.error ? 'red' : '#E5E5E5')};
  box-sizing: border-box;
  border-radius: 4px;
  font-family: var(--font-body-xs-family);
  font-weight: var(--font-body-xs-weight);
  font-size: var(--font-body-xs-size);
  line-height: var(--font-body-xs-line-height);
  letter-spacing: var(--font-body-xs-letter-spacing);
  color: var(--color-neutral-100);
  font-style: normal;
  &.listOpen {
    border: 2px solid #1a1d25;
  }
`;

const Header = styled('div')`
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  display: flex;
  width: 100%;
  align-items: center;
  height: 100%;
  position: relative;
  background-color: white;

  i {
    position: absolute;
    right: 15px;
  }
`;

const HeaderTitle = styled('div')`
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  margin-left: 15px;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: var(--font-body-xs-family);
  font-weight: 400;
  font-size: 14px;
  line-height: var(--font-body-xs-line-height);
  letter-spacing: var(--font-body-xs-letter-spacing);
  font-style: normal;
  color: ${(props) => (props.grey ? 'grey' : '#1A1D25')};
  position: relative;
  top: 1px;
`;

const List = styled('ul')`
  margin-top: 10px;
  z-index: 160;
  position: absolute;
  width: 100%;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 0 10px rgba(148, 148, 150, 0.4);
  padding: 15px 0;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  // &::-webkit-scrollbar {
  //   width: 4px;
  // }
  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c9cdd9;
    border-radius: 4px;
  }
`;

const Item = styled('li')`
  display: flex;
  align-items: center;
  position: relative;

  font-family: var(--font-body-xs-family);
  font-weight: 400;
  font-size: 14px;
  line-height: var(--font-body-xs-line-height);
  letter-spacing: var(--font-body-xs-letter-spacing);
  color: var(--color-neutral-100);
  font-style: normal;
  cursor: pointer;
  width: 100%;
  padding: 11px 24px;
  white-space: nowrap;

  ${(props) =>
    props.separate &&
    `&:after {
    content: '';
    height: 10px;
    width: calc(100% - 48px);
    position: absolute;
    right: 24px;
    bottom: 0;
    border-bottom: 1px solid rgba(26, 29, 37, 0.05);
    }`};

  &.selected {
    background-color: #f6f7f9;
  }

  &:hover {
    background-color: #f6f7f9;
  }
`;

const Label = styled('div')`
  white-space: initial;
  line-height: 20px;
  ${(props) => props.active && 'font-weight: 500;'};
`;

const Count = styled.div`
  font-family: var(--font-family-primary);
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 31px;
  height: 21px;
  margin-left: ${(props) => (props.header ? '10px' : 'auto')};
  background-color: ${(props) =>
    props.active ? '#1A1D25' : 'rgb(201, 205, 217, 0.4)'};
  color: ${(props) => (props.active ? colors.white : 'rgb(26, 29, 37, 0.6)')};
  border-radius: 21px;
  text-align: center;
  font-size: 12px;
  letter-spacing: 0;
`;

const FilterSelect = ({
  list,
  selected,
  title,
  resetThenSet,
  disabled,
  className,
  withCount,
  error,
  separateItems,
}) => {
  const selectRef = useRef(null);
  const [listOpen, setListOpen] = useState(false);
  // TODO: refactor hardcode
  const [headerTitle, setHeaderTitle] = useState(
    selected
      ? selected.label || list.find(({ value }) => value === selected)
        ? list.find(({ value }) => value === selected)?.label
        : ''
      : title,
  );

  const close = () => setListOpen(false);

  useEffect(() => {
    if (listOpen) {
      window.addEventListener('click', close);
      return () => {
        window.removeEventListener('click', close);
      };
    }
  }, [list, selected, title, resetThenSet]);

  useEffect(() => {
    const findSelect = list.find((option) =>
      has(selected, 'value')
        ? option.value === selected.value
        : option.value === selected,
    );
    setHeaderTitle(findSelect ? findSelect.label : title);
  }, [selected]);

  useEffect(() => () => window.removeEventListener('click', close), []);

  const selectItem = (itemTitle, id) => {
    setHeaderTitle(itemTitle);
    setListOpen(false);
    resetThenSet(id);
  };

  const toggleList = () => {
    if (!disabled) {
      setListOpen((prev) => !prev);
    }
  };

  useOnClickOutside(selectRef, () => listOpen && toggleList());

  /**
   * Renders list of select dropdown options with or without an icon and/or
   * count.
   *
   * @typedef Item
   * @property {string} label - Displays select dropdown label text
   * @property {string} value - Dropdown value used for select handler
   * @property {string} [icon] - Icon string key for `Icon` component
   * @property {number} [count] - Count number to display within select
   * dropdown element
   *
   * @param {Item} item - Values and other options for rendering select
   * dropdown element
   *
   * @returns {JSX.Element}
   */
  const renderItem = ({ label, value, icon, count }) => {
    const hasIcon = !!icon;

    const itemWithoutIcon = (
      <Item
        key={value}
        separate={separateItems.includes(value)}
        onClick={() => selectItem(label, value)}
      >
        <Label title={label} active={value === selected}>
          {`${label} `}
        </Label>
        {withCount && <Count active={value === selected}>{count || 0}</Count>}
      </Item>
    );

    const itemWithIcon = (
      <Item
        key={value}
        separate={separateItems.includes(value)}
        onClick={() => selectItem(label, value)}
      >
        <Label title={label} active={value === selected} className="strategy">
          <Icon icon={icon} />
          <span className="label">{label} </span>
        </Label>
        {withCount && <Count active={value === selected}>{count || 0}</Count>}
      </Item>
    );

    return hasIcon ? itemWithIcon : itemWithoutIcon;
  };

  const activeCount = withCount
    ? list.find(({ value }) => value === selected).count
    : 0;
  return (
    <Wrapper
      ref={selectRef}
      className={classNames(className, { listOpen })}
      disabled={disabled}
      error={error}
    >
      <Header onClick={toggleList} disabled={disabled}>
        <HeaderTitle disabled={disabled} grey={title === headerTitle}>
          {headerTitle}
        </HeaderTitle>
        {withCount && (
          <Count header active>
            {activeCount || 0}
          </Count>
        )}
        {listOpen ? (
          <i className="fas fa-sort-up" style={{ top: '14px' }} />
        ) : (
          <i className="fas fa-sort-down" style={{ top: '9px' }} />
        )}
      </Header>
      {listOpen && (
        <List onClick={(e) => e.stopPropagation()}>
          {list.map((item) =>
            renderItem({
              label: item.label,
              value: item.value,
              icon: item.icon,
              count: item.count,
            }),
          )}
        </List>
      )}
    </Wrapper>
  );
};

FilterSelect.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      icon: PropTypes.string,
      count: PropTypes.number,
    }),
  ),
  separateItems: PropTypes.arrayOf(PropTypes.any),
  selected: PropTypes.node,
  title: PropTypes.string,
  resetThenSet: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  withCount: PropTypes.bool,
  error: PropTypes.bool,
};

FilterSelect.defaultProps = {
  list: [],
  selected: null,
  title: 'Select...',
  disabled: false,
  className: null,
  withCount: true,
  error: false,
  separateItems: [],
};

export default FilterSelect;
