/* takes an object and sets all empty values to null in place recursively */
const isNullish = (v) => v === '' || v == null;
const isPlainObject = (v) =>
  v != null && Object.prototype.toString.call(v) === '[object Object]';

const defaultComparator = isNullish;

const dataReplaceRec = (obj, replaceWith, comparator) => {
  Object.keys(obj).forEach((k) => {
    if (Array.isArray(obj[k])) {
      obj[k].forEach((kv) => dataReplaceRec(kv, replaceWith, comparator));
    } else if (isPlainObject(obj[k])) {
      dataReplaceRec(obj[k], replaceWith, comparator);
    } else if (isNullish(obj[k])) {
      obj[k] = replaceWith;
    }
  });

  return obj;
};

const dataReplace = (
  input,
  replaceWith = null,
  comparator = defaultComparator,
) => {
  if (!isPlainObject(input)) {
    return null;
  }
  return dataReplaceRec(input, replaceWith, comparator);
};

export default dataReplace;
