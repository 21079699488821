import { Checkbox, Select } from '@kandji-inc/bumblebee';
import React, { memo } from 'react';

import { Setting } from 'features/library-items/template';

import { i18n } from 'src/i18n';
import GatekeeperService from '../service/gatekeeper-service';

const GeneralCard = (props) => {
  const { isDisabled, setting, update } = props;

  const appsFromOptions = [
    {
      value: GatekeeperService.appsFrom.MAC_ONLY,
      label: i18n.t('Mac app store only'),
    },
    {
      value: GatekeeperService.appsFrom.MAC_AND_DEVELOPERS,
      label: i18n.t('Mac app store and identified developers'),
    },
    {
      value: GatekeeperService.appsFrom.ANYWHERE,
      label: i18n.t('Anywhere (disable Gatekeeper)'),
    },
  ];

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('General')}</h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Title>
            <p className="b-txt">{i18n.t('Allow apps downloaded from')}</p>
          </Setting.Title>
          <Setting.Helpers className="b-txt-light k-li-helper--compact">
            {i18n.t(
              'Configure Gatekeeper to assess quarantined files and allowed app sources. Setting this option to Anywhere will disable Gatekeeper.',
            )}
          </Setting.Helpers>
          <Setting.Controls>
            <Select
              disabled={isDisabled}
              options={appsFromOptions}
              compact
              onChange={({ value }) => update('appsFrom', value)}
              value={appsFromOptions.find(
                ({ value }) => value === setting.appsFrom,
              )}
            />
          </Setting.Controls>
          {setting.appsFrom !== GatekeeperService.appsFrom.ANYWHERE && (
            <Setting.SecondaryControls className="k-ade-secondary-ctrl-grid-gap-align">
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <Checkbox
                    checked={setting.disableOverride}
                    onChange={() => update('disableOverride', (p) => !p)}
                    disabled={isDisabled}
                    label={i18n.t(
                      'Disallow users from overriding gatekeeper settings',
                    )}
                  />
                </Setting.Controls>
                <Setting.Helpers>
                  <p className="b-txt-light">
                    {i18n.t(
                      'Prevent users from bypassing Gatekeeper by using the Finder contextual menu item.',
                    )}
                  </p>
                </Setting.Helpers>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        <Setting.Row>
          <Setting.Helpers className="b-txt-light k-li-helper--compact">
            {i18n.t(
              'Disallow XProtect malware upload in macOS 15 and later. Requires a supervised device.',
            )}
          </Setting.Helpers>
          <Setting.Controls>
            <Checkbox
              label={i18n.t('Disallow XProtect malware upload')}
              checked={!setting.EnableXProtectMalwareUpload}
              disabled={isDisabled}
              onChange={() => update('EnableXProtectMalwareUpload', (p) => !p)}
              testId="disallow-xprotect"
            />
          </Setting.Controls>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default memo(GeneralCard);
