import * as React from 'react';
import { toggleResizingClass } from '../utils';

import '../css/column-resize-override.css';

export const useToggleResizingClass = (
  isResizingColumn: string | boolean | undefined,
) => {
  React.useEffect(() => {
    toggleResizingClass(!!isResizingColumn);
  }, [isResizingColumn]);
};
