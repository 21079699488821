/* istanbul ignore file */
import {
  Box,
  Flex,
  Icon,
  Pagination,
  SelectV2 as Select,
  Tooltip,
  TooltipProvider,
} from '@kandji-inc/nectar-ui';
import {
  type ColumnDef,
  type SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React from 'react';
import { Link } from 'react-router-dom';
import { links } from 'src/app/common/constants';

import { AccountContext } from 'contexts/account';
import { Loader } from 'src/app/components/interface/Loader';
import { getDeviceIconByFamily } from 'src/features/visibility/prism/utils/column-utils';

import { isoToDateString } from '../utils';
import { Filter } from './filter';

export type Log = {
  tenant_id: string;
  device_id: string;
  composedMessage: string | Record<string, any>;
  configId: string;
  formatString: string;
  category: string;
  level: string;
  sender: string;
  date: string;
  type: string;
};

const Sizes = ({ table, sizes, pagination }) => (
  <Select.Default
    value={pagination.pageSize}
    onValueChange={(e) => table.setPageSize(parseInt(e, 10))}
    triggerProps={{
      value: pagination.pageSize,
    }}
    options={sizes.map((option) => ({
      value: option,
      label: option,
    }))}
  />
);

export const Table = (props) => {
  const {
    data,
    onPaginationChange,
    pagination,
    pageCount,
    count,
    filter,
    setFilter,
    setQuery,
    query,
    isLoading,
  } = props;

  const computers = data.computers;

  const { userSettings } = React.useContext(AccountContext);

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const columns = React.useMemo<ColumnDef<Log>[]>(
    () => [
      {
        accessorKey: 'device_id',
        cell: (props) => {
          const device = computers.find(
            (c) => c.id === props.row.original.device_id,
          ) || { name: 'unknown' };
          return (
            <Flex alignItems="center" gap="sm">
              <img
                height="20"
                width="20"
                src={getDeviceIconByFamily(device.model)}
                alt={device.model}
              />
              <Link
                className="device-name"
                to={`${links.devices}/${device.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {device.name}
              </Link>
            </Flex>
          );
        },
        header: 'Device',
        size: 50,
      },
      {
        accessorKey: 'type',
        cell: (props) => props.row.original.type || 'log',
        header: 'Type',
        size: 30,
      },
      {
        accessorKey: 'configId',
        cell: (props) => props.row.original.configId,
        header: 'Predicate',
        size: 50,
      },
      {
        accessorKey: 'category',
        cell: (props) => props.row.original.category,
        header: 'Category',
        size: 50,
      },
      {
        accessorKey: 'level',
        cell: (props) => props.row.original.level,
        header: 'Level',
        size: 50,
      },
      {
        accessorKey: 'sender',
        cell: (props) => props.row.original.sender,
        header: 'Sender',
        size: 50,
      },
      {
        accessorKey: 'composed_message',
        cell: (props) => {
          const message =
            typeof props.row.original.composedMessage === 'object'
              ? JSON.stringify(props.row.original.composedMessage, null, 2)
              : props.row.original.composedMessage;
          return (
            <TooltipProvider>
              <Tooltip
                side="bottom"
                content={message}
                shadow="shadow"
                theme="light"
                css={{ zIndex: 5, padding: '12px 16px', color: '$neutral90' }}
                maxWidth="320px"
                align="start"
                trigger="click"
                interactive
              >
                <div title={message} className="log-message">
                  {message}
                </div>
              </Tooltip>
            </TooltipProvider>
          );
        },
        header: 'Message',
        enableSorting: false,
        size: 200,
      },
      {
        accessorKey: 'date',
        cell: (props) =>
          isoToDateString(
            props.row.original.date,
            userSettings?.timezone,
            true,
          ),
        header: 'Date',
        size: 50,
      },
    ],
    [computers],
  );

  const table = useReactTable({
    columns,
    data: data.results,
    debugTable: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    onSortingChange: setSorting,
    manualPagination: true,
    onPaginationChange,
    pageCount,
    state: {
      pagination,
      sorting,
    },
  });

  return (
    <div>
      <Filter
        filter={filter}
        setFilter={setFilter}
        setQuery={setQuery}
        query={query}
      />
      <table
        style={{
          width: '100%',
          maxWidth: '100%',
          marginTop: '20px',
          tableLayout: 'fixed',
        }}
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    title="agent logs"
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      width: `${header.getSize()}px`,
                      maxWidth: `${header.getSize()}px`,
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        style={{
                          cursor: header.column.getCanSort()
                            ? 'pointer'
                            : 'default',
                        }}
                        onClick={header.column.getToggleSortingHandler()}
                        title={
                          header.column.getCanSort()
                            ? header.column.getNextSortingOrder() === 'asc'
                              ? 'Sort ascending'
                              : header.column.getNextSortingOrder() === 'desc'
                                ? 'Sort descending'
                                : 'Clear sort'
                            : undefined
                        }
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {header.column.getCanSort()
                          ? {
                              asc: (
                                <>
                                  &nbsp;
                                  <Icon name="arrow-up" size="sm" />
                                </>
                              ),
                              desc: (
                                <>
                                  &nbsp;
                                  <Icon name="arrow-down" size="sm" />
                                </>
                              ),
                            }[header.column.getIsSorted() as string] ?? (
                              <>
                                &nbsp;
                                <Icon name="arrow-down-arrow-up" size="sm" />
                              </>
                            )
                          : null}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {isLoading && (
            <tr
              style={{
                backgroundColor: 'rgba(202, 213, 229, 0.3)',
              }}
            >
              <td title="loading" colSpan={columns.length}>
                <Loader />
              </td>
            </tr>
          )}
          {table.getRowModel().rows.length > 0 &&
            table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td title={cell.getContext().getValue()} key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          {table.getRowModel().rows.length === 0 && !isLoading && (
            <tr>
              <td colSpan={columns.length}>No logs found</td>
            </tr>
          )}
        </tbody>
      </table>
      {table.getRowModel().rows.length > 0 && (
        <Box pt4>
          <Pagination
            currentPage={pagination.pageIndex + 1}
            totalPages={pageCount}
            totalItems={count || 0}
            itemsPerPage={pagination.pageSize}
            onPageChange={(page) => table.setPageIndex(page - 1)}
          />
          <Sizes
            table={table}
            sizes={[100, 300, 500, 1000]}
            pagination={pagination}
          />
        </Box>
      )}
    </div>
  );
};
