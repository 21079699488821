import { Icon } from '@kandji-inc/bumblebee';
import { defaultActivityFilters, defaultFilters } from 'app/common/constants';
import isEqual from 'lodash/isEqual';
import React, { Component } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { Input } from 'reactstrap';
import history from '../../router/history';

const classNames = require('classnames');

export class SelectInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      showCalendar: false,
      from: undefined,
      to: undefined,
      filterString: '',
      selected: props.selected,
    };
    this.onSelect = this.onSelect.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.onChangeInline = this.onChangeInline.bind(this);
  }

  handleDayClick(day) {
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);
  }

  renderOptions = () => {
    let { options, selected, showCheck, inlineSearch, multiple } = this.props;
    const { filterString } = this.state;
    if (multiple) {
      selected = this.state.selected;
    }

    const current = !selected
      ? []
      : typeof selected === 'object'
        ? selected
        : [selected];
    return options.map((el, idx) => {
      let element;
      if (!el) {
        element = <div key={idx} className="dropdown-divider" />;
      } else if (typeof el === 'string') {
        element = inlineSearch ? (
          <div key={idx} className="dropdown-header-search">
            <h6 className="dropdown-header">{el}</h6>
            {filterString && (
              <Icon
                name="circle-xmark"
                className="search-icon"
                onClick={() => this.setState({ filterString: '' })}
              />
            )}
            <Input
              type="text"
              className="filter-inline-search"
              value={this.state.filterString}
              placeholder="Search..."
              autoComplete="off"
              onChange={this.onChangeInline}
            />
          </div>
        ) : (
          <h6 key={idx} className="dropdown-header">
            {el}
          </h6>
        );
      } else {
        const [value, name, icon, type] = el;
        if (inlineSearch && filterString && value !== 'ALL') {
          if (name.toLowerCase().indexOf(filterString) === -1) {
            return null;
          }
        }
        let className = 'dropdown-item';
        const isSelected =
          value.indexOf('@') === -1 && current.indexOf(value.toString()) > -1;
        if (isSelected) {
          className += ' selected';
        }
        if (type === 'link') {
          return (
            <li
              key={idx}
              className={className}
              onClick={() => {
                this.onToggle();
                history.push(value);
              }}
            >
              {icon && (
                <i
                  className={`${icon} fa-fw text-primary`}
                  style={{ marginRight: 15, fontSize: 20 }}
                />
              )}
              {name.length > 40 ? `${name.substring(0, 39)}...` : name}
            </li>
          );
        }
        element = (
          <li
            key={idx}
            className={className}
            onClick={(e) => this.onSelect(value)}
          >
            {icon && (
              <i className={`${icon} fa-fw`} style={{ marginRight: '15px' }} />
            )}
            {name && name.length > 40 ? `${name.substring(0, 39)}...` : name}
            {isSelected && showCheck && <Icon name="check" />}
          </li>
        );
      }
      return element;
    });
  };

  onToggle() {
    console.log(this.state.isOpen);
    const { to, from, isOpen } = this.state;
    const { hasCustomRange, selected, multiple, onChange, isActivityPeriod } =
      this.props;

    this.setState(
      {
        isOpen: !isOpen,
        showCalendar: false,
      },
      () => {
        !to && !from && isOpen && hasCustomRange && !(selected > -1)
          ? this.onSelect(
              isActivityPeriod
                ? defaultActivityFilters.period
                : defaultFilters.period,
            )
          : null;
      },
    );
    if (multiple && this.state.isOpen) {
      onChange(this.state.selected);
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, true);
  }

  handleClickOutside = (event) => {
    const { to, from, isOpen } = this.state;
    const { hasCustomRange, selected, multiple, onChange, isActivityPeriod } =
      this.props;

    if (this.selectFilter.contains(event.target)) {
      return;
    }

    this.setState(
      {
        isOpen: false,
        showCalendar: false,
      },
      () => {
        !to && !from && isOpen && hasCustomRange && !(selected > -1)
          ? this.onSelect(
              isActivityPeriod
                ? defaultActivityFilters.period
                : defaultFilters.period,
            )
          : null;
      },
    );
    if (multiple && isOpen) {
      onChange(this.state.selected);
    }
  };

  onSelect(value) {
    const optionsExcludeAll = this.props.options.filter(
      (option) =>
        option &&
        typeof option === 'object' &&
        option[0] !== 'ALL' &&
        option[0] !== 'ERROR,WARNING,MUTE',
    );
    const allOptionValue = this.props.options.find(
      (option) =>
        option &&
        typeof option === 'object' &&
        (option[0] === 'ALL' || option[0] === 'ERROR,WARNING,MUTE'),
    );
    let result;
    const current = this.props.multiple
      ? this.state.selected
      : this.props.selected;
    if (this.props.multiple) {
      result = current ? [...current] : [];
      const index = result.indexOf(value);
      if (index >= 0) {
        result.splice(index, 1);
      } else {
        if (value === 'ALL' || value === 'ERROR,WARNING,MUTE') {
          // second value same ALL for alerts page
          result = [value];
        } else {
          const allSelected =
            result.indexOf('ALL') >= 0 ||
            result.indexOf('ERROR,WARNING,MUTE') >= 0;
          if (allSelected) {
            result.splice(result.indexOf('ALL'), 1);
            result.splice(result.indexOf('ERROR,WARNING,MUTE'), 1);
            result.push(value);
          } else {
            result.push(value);
          }
        }
        if (result.length === optionsExcludeAll.length) {
          result = [allOptionValue[0]];
        }
      }
      result = result.length ? result : null;
    } else {
      result = value === current ? null : value;
      this.setState(() => {
        if (result && result.toString().indexOf('@') > 0) {
          return { isOpen: false };
        }
        return { isOpen: false, from: undefined, to: undefined };
      });
    }
    if (!this.props.multiple) {
      this.props.onChange(result);
    }
    if (this.props.multiple) {
      this.setState({ selected: result });
    }
  }

  getTitle = () => {
    const { from, to } = this.state;
    let { title, selected, options, multiple, titlePlural, hasCustomRange } =
      this.props;
    if (multiple) {
      selected = this.state.selected;
    }

    let currentSelected = [];
    currentSelected = multiple
      ? selected || currentSelected
      : selected
        ? [selected]
        : [];
    let finalTitle = title;
    const selectedCount = currentSelected.length;
    if (selectedCount === 1) {
      const namesMap = {};
      options.forEach((el) =>
        typeof el === 'object' ? (namesMap[el[0]] = el[1]) : null,
      );
      finalTitle = namesMap[currentSelected[0]];

      if (hasCustomRange && selected.toString().indexOf('@') > 0) {
        // finalTitle =  (!from || !to) ? 'Custom Range' : from.toLocaleDateString()+' - '+to.toLocaleDateString()
        finalTitle = 'Custom Range';
      }
    } else if (selectedCount > 1) {
      finalTitle = `${selectedCount} ${titlePlural} selected`;
    }
    return finalTitle;
  };

  renderCustomDate = () => {
    let { from, to, showCalendar } = this.state;
    const { options, selected, showCheck } = this.props;

    const current = !selected
      ? []
      : typeof selected === 'object'
        ? selected
        : [selected];

    let className = 'dropdown-item';

    if (!(from && to) && selected.toString().indexOf('@') > 0) {
      const dateFromSelected = selected.split('@');
      from = new Date(dateFromSelected[0]);
      to = new Date(dateFromSelected[1]);
    }

    const isSelected =
      from && to
        ? current.indexOf(`${from.toISOString()}@${to.toISOString()}`) >= 0
        : false;
    if (isSelected) {
      className += ' selected';
    }

    return showCalendar ? (
      <li key="DayPickerSelector" className="DayPickerSelector">
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          <button
            className="btn btn-primary"
            style={{ marginRight: '10px' }}
            disabled={!(from && to)}
            onClick={() => {
              if (from && to) {
                this.setState({ showCalendar: false }, () => {
                  from.setHours(0, 0, 0, 0);
                  to.setHours(23, 59, 59, 999);
                  this.onSelect(`${from.toISOString()}@${to.toISOString()}`);
                  // console.log('from', from.toISOString(), 'to', to.toISOString());
                });
              }
            }}
          >
            Select
          </button>
          <button
            className="btn btn-primary"
            disabled={!(from || to)}
            onClick={() => {
              this.setState({ from: undefined, to: undefined });
            }}
          >
            Reset
          </button>
        </div>
        <DayPicker
          selectedDays={[from, { from, to }]}
          toMonth={new Date()}
          modifiers={{ start: from, end: to, disabled: { after: new Date() } }}
          onDayClick={this.handleDayClick}
        />
      </li>
    ) : (
      <li
        className={className}
        onClick={() => this.setState({ showCalendar: !showCalendar })}
      >
        {from && to
          ? `${from.toLocaleDateString('en-US')} - ${to.toLocaleDateString(
              'en-US',
            )}`
          : 'Custom Range'}
        {isSelected && showCheck && <Icon name="check" />}
      </li>
    );
  };

  UNSAFE_componentWillReceiveProps(next) {
    this.setState({ isFetching: next.selected !== this.props.selected });
    if (next.multiple && !isEqual(next.selected, this.state.selected)) {
      this.setState({ selected: next.selected });
    }
    if (next.selected.toString().indexOf('@') > 0) {
      const nextCustomPeriod = next.selected.split('@');
      this.setState({
        from: new Date(nextCustomPeriod[0]),
        to: new Date(nextCustomPeriod[1]),
      });
    }
  }

  onChangeInline(e) {
    this.setState({ filterString: e.target.value.toLowerCase() });
  }

  render() {
    const { isOpen } = this.state;
    let {
      size,
      width,
      title,
      dropdownWidth,
      style,
      display,
      hasCustomRange,
      btnColor,
      selected,
      onFetch,
      options,
      multiple,
      inlineSearch,
    } = this.props;
    if (multiple) {
      selected = this.state.selected;
    }
    let btnClass = 'btn dropdown-toggle-button';
    if (size) {
      btnClass += ` btn-${size}`;
    }
    if (btnColor && !selected) {
      btnClass += ` text-${btnColor}`;
    }

    const btnStyle = { width };
    if (display === 'block') {
      btnStyle.borderRadius = 0;
      btnStyle.width = '100%';
      btnStyle.textAlign = 'left';
      dropdownWidth = dropdownWidth || '100%';
      style = { ...style, display: 'block', width: dropdownWidth || '100%' };
    } else if (display === 'none') {
      style = { ...style, display: 'none' };
    }

    let dropdownMenuStyles = {
      width: dropdownWidth,
    };

    if (this.props.isCompanySwitcher) {
      dropdownMenuStyles = {
        ...dropdownMenuStyles,
        left: 'auto',
        right: '-20px',
        margin: '4px 20px 0',
      };
    }

    return (
      <div
        className="dropdown dropdown-button dropdown-select"
        style={style}
        ref={(node) => (this.selectFilter = node)}
      >
        <button
          id="button-selector-for-disable-click"
          className={isOpen ? `${btnClass} show` : btnClass}
          style={btnStyle}
          type="button"
          onClick={() => this.onToggle()}
        >
          <div>{this.getTitle()}</div>
        </button>
        <div
          className={classNames('dropdown-menu', {
            show: isOpen,
            'inline-search': inlineSearch,
            'multiple-select': options.length > 15,
          })}
          style={dropdownMenuStyles}
        >
          {this.renderOptions()}
          {hasCustomRange ? this.renderCustomDate() : ''}
        </div>
      </div>
    );
  }
}

SelectInput.propTypes = {};
SelectInput.defaultProps = {
  options: {},
  selected: [],
  width: 'auto',
  multiple: false,
  size: 'sm',
  title: 'Select',
  dropdownWidth: 'auto',
  showCheck: true,
  style: {},
  titlePlural: 'items',
  display: 'inline',
  inlineSearch: false,
};

export default SelectInput;
