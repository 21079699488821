const initialState = {
  categories: [],
  recommended: {
    icon: '',
    name: 'Recommended',
    description: 'A list of recommended apps and tools.',
  },
  all: {
    icon: '',
    name: 'All',
    description: 'All applications and tools available through Self Service.',
  },
  branding: {
    header: 'Self Service',
    subheader: 'Easily install apps and tools provided by your IT department.',
    logoUrl: null,
    logoS3Key: null,
    logoFile: null,
    darkLogoUrl: null,
    darkLogoS3Key: null,
    darkLogoFile: null,
    isCustomLogo: false,
    isCustomDarkLogo: false,
    isInitialCustomization: true,
  },
  addEditCategory: {
    name: '',
    id: '',
    description: '',
    icon: '',
    iconImg: '',
    customName: '',
    isCustomNameSelected: false,
  },
};

export default initialState;
