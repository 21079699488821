import { getNullValue, isNullOrNotNullOperator } from '@kandji-inc/nectar-ui';
import type { Column } from '@tanstack/react-table';
import { store } from 'app/_store/rootStore';
import { moment } from 'src/app/components/common/helpers';
import { z } from 'zod';
import type { PrismCategoryFilterProperties as ColFilters } from '../../../types/filter.types';
import type {
  CategorySchema,
  PrismCategorySchema,
  PrismCategoryUri,
} from '../../../types/prism.types';

export function parseJsonSchemaDefinitionFromRefPath<
  Definitions extends Record<
    string,
    { title?: string; type?: string; enum?: [string, ...string[]] }
  >,
>(refPath: string | undefined, definitions: Definitions | undefined) {
  const DEFINITIONS_JSON_SCHEMA_KEY = '#/definitions/';
  if (typeof refPath !== 'string' || !definitions) {
    return undefined;
  }

  const [, parsedRef] = refPath.split(DEFINITIONS_JSON_SCHEMA_KEY);
  return parsedRef ? definitions[parsedRef] : undefined;
}

export function parseFilterableFromSchema(
  prismCategory: PrismCategorySchema,
): Array<ColFilters['filterableColumnSchema']> {
  return Object.entries(prismCategory.schema.properties)
    .map(([key, value]) => {
      if (!value.$ref) {
        const schema: ColFilters['filterableColumnSchema'] = {
          value: key,
          label: value.title,
          type: value.format === 'date-time' ? 'date-time' : value.type,
        };

        return schema;
      }

      const schemaDef = parseJsonSchemaDefinitionFromRefPath(
        value?.$ref,
        prismCategory.schema.definitions,
      );

      if (schemaDef) {
        const schema: ColFilters['filterableColumnSchema'] = {
          value: key,
          label: schemaDef.title,
          type: 'enum',
          multi: schemaDef.type !== 'string',
          enum: schemaDef.enum,
          enumOptions: schemaDef.enum?.map((enumVal) => ({
            label: enumVal,
            value: enumVal,
          })),
        };

        return schema;
      }

      return undefined;
    })
    .filter<ColFilters['filterableColumnSchema']>(
      (schema): schema is ColFilters['filterableColumnSchema'] => !!schema,
    );
}

export function getNewFilterByType(
  key: string,
  filterMenuOption: ColFilters['menuOption'] | undefined,
  schema?: ColFilters['filterableColumnSchema'],
): ColFilters['unionOf']['details'] | undefined {
  if (!filterMenuOption) {
    return undefined;
  }

  switch (filterMenuOption.meta.filterType) {
    case 'string': {
      return {
        key,
        type: 'string',
        label: filterMenuOption.label,
        operator: '',
        value: [],
      };
    }

    case 'enum': {
      const enumCol: ColFilters['enumMenuOption'] = filterMenuOption;

      if (!Array.isArray(enumCol.meta.filterOptions)) {
        if (schema?.enumOptions) {
          enumCol.meta.filterOptions = schema.enumOptions;
        } else {
          return undefined;
        }
      }

      return {
        key,
        type: 'enum',
        label: filterMenuOption.label,
        operator: '',
        value: [],
        multi: false,
        enumOptions: enumCol.meta.filterOptions,
      };
    }

    case 'date-time': {
      return {
        key,
        type: 'date-time',
        label: filterMenuOption.label,
        operator: '',
        value: '',
        dateRange: {
          from: null,
          to: null,
        },
        selectedDate: null,
      };
    }

    case 'boolean': {
      const boolCol: ColFilters['boolMenuOption'] = filterMenuOption;
      if (!Array.isArray(boolCol.meta.filterOptions)) {
        return undefined;
      }

      return {
        key,
        type: 'boolean',
        label: boolCol.label,
        operator: 'equals',
        value: undefined,
        boolOptions: boolCol.meta.filterOptions,
      };
    }

    case 'number': {
      return {
        key,
        type: 'number',
        label: filterMenuOption.label,
        operator: '',
        value: [],
      };
    }

    case 'time': {
      return {
        key,
        type: 'time',
        label: filterMenuOption.label,
        operator: '',
        value: [],
        units: 'minutes',
      };
    }

    case 'version': {
      return {
        key,
        type: 'version',
        label: filterMenuOption.label,
        operator: '',
        value: [],
      };
    }

    default:
      return undefined as never;
  }
}

export function createApplyFilter(
  attributeKey: PrismCategoryUri | undefined,
  filter: ColFilters['unionOf']['details'],
  setFilter: any,
) {
  return (filterToApply) => {
    if (!attributeKey) {
      return undefined;
    }

    // istanbul ignore next
    const track = (filterProps) => {
      if (process.env['NODE_ENV'] !== 'test') {
        // @ts-expect-error -- pendo is injected in Meta.js
        pendo?.track('[Prism] Category Filter applied', {
          CATEGORY_NAME: attributeKey,
          COLUMN_NAME: filter.key,
          ...filterProps,
        });
      }
    };

    const quickFilters = new Set([
      'last_24_hours',
      'last_48_hours',
      'last_7_days',
      'last_30_days',
    ]);

    const nullAccessor =
      filter.type === 'boolean' ? 'filterValue' : 'operatorValue';
    if (['is_null', 'is_not_null'].includes(filterToApply[nullAccessor])) {
      const filters =
        filterToApply[nullAccessor] === 'is_null'
          ? { is_null: true }
          : { is_null: false };

      /* istanbul ignore next - not testing pendo */
      track({
        filter_type: 'string',
        operator: filterToApply.operatorValue,
        value: filterToApply[nullAccessor],
      });

      return setFilter({
        [filter.key]: filters,
      });
    }

    if (filter.type !== 'boolean') {
      const nonQuickFilterHasNoOperatorValue =
        !quickFilters.has(filterToApply.filterValue) &&
        !filterToApply.operatorValue;

      const hasInvalidFilterValue =
        (Array.isArray(filterToApply.filterValue) &&
          !filterToApply.filterValue.length) ||
        !filterToApply.filterValue;

      const hasInvalidFilter =
        nonQuickFilterHasNoOperatorValue || hasInvalidFilterValue;

      if (hasInvalidFilter) {
        return setFilter({
          [filter.key]: null,
        });
      }
    }

    switch (filter.type) {
      case 'string': {
        const filters = {
          [filterToApply.operatorValue]: filterToApply.filterValue.map(
            (val) => val,
          ),
        } as ColFilters['unionOf']['operation'];

        /* istanbul ignore next - not testing pendo */
        track({
          filter_type: 'string',
          operator: filterToApply.operatorValue,
          value: Array.isArray(filterToApply.filterValue)
            ? filterToApply.filterValue.join(',')
            : filterToApply.filterValue,
        });

        return setFilter({
          [filter.key]: filters,
        });
      }

      case 'enum': {
        const filters = Array.isArray(filterToApply.filterValue)
          ? ({
              [filterToApply.operatorValue]: filterToApply.filterValue,
            } as ColFilters['unionOf']['operation'])
          : ({
              [filterToApply.operatorValue]: [filterToApply.filterValue],
            } as ColFilters['unionOf']['operation']);

        /* istanbul ignore next - not testing pendo */
        track({
          filter_type: 'enum',
          operator: filterToApply.operatorValue,
          value: filters[filterToApply.operatorValue],
        });

        return setFilter({
          [filter.key]: filters,
        });
      }

      case 'date-time': {
        if (quickFilters.has(filterToApply.filterValue)) {
          const formattedFilterValue = getDateTimeByQuickFilter(
            filterToApply.filterValue,
          );
          const filters: { relative: string } = {
            relative: formattedFilterValue || '',
          };

          /* istanbul ignore next - not testing pendo */
          track({
            filter_type: 'date-quick-filter',
            operator: formattedFilterValue,
            relative: formattedFilterValue,
          });

          return setFilter({
            [filter.key]: filters,
          });
        }

        if (filterToApply.filterValue === 'custom_relative_date_range') {
          const filters = {} as Record<string, string | string[]>;
          if (filterToApply.operatorValue === 'ib') {
            filters['between'] = [
              filterToApply.dateRange?.from,
              filterToApply.dateRange?.to,
            ];
          } else {
            filters[filterToApply.operatorValue] =
              filterToApply.selectedDate || '';
          }
          /* istanbul ignore next - not testing pendo */
          track({
            filter_type: 'relative-date-custom-range',
            operator: filterToApply.operatorValue,
            value: filters['between'] || filters[filterToApply.operatorValue],
          });

          return setFilter({
            [filter.key]: filters,
          });
        }

        if (
          filterToApply.operatorValue === 'eq' &&
          filterToApply.selectedDate
        ) {
          const filters = {
            gte: getUTCDayTime(filterToApply.selectedDate, 'start'),
            lte: getUTCDayTime(filterToApply.selectedDate, 'end'),
          };

          /* istanbul ignore next - not testing pendo */
          track({
            filter_type: 'date-custom-range',
            operator: 'eq',
            value: filters.gte,
          });

          return setFilter({
            [filter.key]: filters,
          });
        }

        if (
          filterToApply.operatorValue === 'dne' &&
          filterToApply.selectedDate
        ) {
          const start = new Date(filterToApply.selectedDate);

          const plusOneDay = new Date(start.getTime());
          plusOneDay.setDate(start.getDate() + 1);

          const filters = {
            or: {
              gt: getUTCDayTime(filterToApply.selectedDate, 'end'),
              lt: getUTCDayTime(filterToApply.selectedDate, 'start'),
            },
          };

          /* istanbul ignore next - not testing pendo */
          track({
            filter_type: 'date-custom-range',
            operator: 'dne',
            value: filters.or.lt,
          });

          return setFilter({
            [filter.key]: filters,
          });
        }

        if (filterToApply.operatorValue !== 'ib') {
          let selectedDate;
          if (['gt', 'lte'].includes(filterToApply.operatorValue)) {
            selectedDate = getUTCDayTime(filterToApply.selectedDate, 'end');
          } else {
            selectedDate = getUTCDayTime(filterToApply.selectedDate, 'start');
          }
          const filters = {
            [filterToApply.operatorValue]: selectedDate,
          } as ColFilters['unionOf']['operation'];

          /* istanbul ignore next - not testing pendo */
          track({
            filter_type: 'date-custom-range',
            operator: filterToApply.operatorValue,
            value: selectedDate,
          });

          return setFilter({
            [filter.key]: filters,
          });
        }

        if (filterToApply.operatorValue === 'ib') {
          const filters = {
            gte: getUTCDayTime(filterToApply.dateRange?.from, 'start'),
            lte: getUTCDayTime(filterToApply.dateRange?.to, 'end'),
          };

          /* istanbul ignore next - not testing pendo */
          track({
            filter_type: 'date-custom-range',
            operator: 'ib',
            date_from: filters.gte,
            date_to: filters.lte,
          });

          return setFilter({
            [filter.key]: filters,
          });
        }

        return undefined;
      }

      case 'boolean': {
        const filters = {
          eq: filterToApply.filterValue,
        };

        /* istanbul ignore next - not testing pendo */
        track({
          filter_type: 'boolean',
          value: filterToApply.filterValue,
        });

        return setFilter({
          [filter.key]: filters,
        });
      }

      case 'number': {
        const filters = getNumberFilter(filterToApply) as
          | ColFilters['unionOf']['operation']
          | false;

        if (!filters) {
          return undefined;
        }

        /* istanbul ignore next - not testing pendo */
        track({
          filter_type: 'number',
          operator: filterToApply.operatorValue,
          value: Array.isArray(filterToApply.filterValue)
            ? filterToApply.filterValue.join(',')
            : filterToApply.filterValue,
        });

        return setFilter({
          [filter.key]: filters,
        });
      }

      case 'time': {
        const filters = getTimeFilter(filterToApply) as
          | ColFilters['unionOf']['operation']
          | false;

        if (!filters) {
          return undefined;
        }

        /* istanbul ignore next - not testing pendo */
        track({
          filter_type: 'time',
          operator: filterToApply.operatorValue,
          value: Array.isArray(filterToApply.filterValue)
            ? filterToApply.filterValue.join(',')
            : filterToApply.filterValue,
          units: filterToApply.filterUnits,
        });

        return setFilter({
          [filter.key]: filters,
        });
      }

      case 'version': {
        const filters = getVersionFilter(filterToApply) as
          | ColFilters['unionOf']['operation']
          | false;

        if (!filters) {
          return undefined;
        }

        /* istanbul ignore next - not testing pendo */
        track({
          filter_type: 'time',
          operator: filterToApply.operatorValue,
          value: Array.isArray(filterToApply.filterValue)
            ? filterToApply.filterValue.join(',')
            : filterToApply.filterValue,
        });

        return setFilter({
          [filter.key]: filters,
        });
      }

      default:
        return undefined as never;
    }
  };
}

export function createRemoveFilter(
  attributeKey: PrismCategoryUri | undefined,
  filter: ColFilters['unionOf']['details'],
  removeFilter: any,
  setColumnFilters?: (
    update: (
      filters: Array<ColFilters['unionOf']['details']>,
    ) => Array<ColFilters['unionOf']['details']>,
  ) => void,
) {
  return () => {
    if (!attributeKey) {
      return undefined;
    }

    // istanbul ignore next
    if (filter.key) {
      removeFilter(filter.key);
    }

    if (setColumnFilters) {
      return setColumnFilters((prev) =>
        prev.filter((filterToRemove) => filterToRemove.key !== filter.key),
      );
    }
  };
}

export function createClearFilter(
  attributeKey: PrismCategoryUri | undefined,
  filter: ColFilters['unionOf']['details'],
  removeFilter: any,
) {
  return () => {
    if (!attributeKey) {
      return undefined;
    }

    // istanbul ignore next
    if (filter.key) {
      removeFilter(filter.key);
    }

    return undefined;
  };
}

export function userToSystemDate(date) {
  const userTimeZone = getUserTimeZone();
  const userTimeOffset = moment.tz.zone(userTimeZone).utcOffset(Date.now());
  const systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const systemTimeOffset = moment.tz.zone(systemTimeZone).utcOffset(Date.now());
  const timeDiff = userTimeOffset - systemTimeOffset;
  return new Date(date.getTime() - timeDiff * 60000);
}

export function getUserTimeZone() {
  const userSettings = store.getState().account.user.settings as {
    timezone: string;
  };
  return (
    userSettings.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
  );
}

export function getUTCDayTime(
  selectedDate: Date | null | undefined,
  direction: 'start' | 'end',
) {
  if (!selectedDate) {
    return '';
  }
  const userTimeZone = getUserTimeZone();
  const timeZoneOffset = moment().tz(userTimeZone).format('Z');
  const time = direction === 'start' ? '00:00:00.000' : '23:59:59.999';
  const utcDate = new Date(
    Date.parse(`${selectedDate.toDateString()} ${time} UTC${timeZoneOffset}`),
  );

  return utcDate.toISOString();
}

export function getDateTimeByQuickFilter(quickFilter: string): string | null {
  let result: string | null = null;

  switch (quickFilter) {
    case 'last_24_hours':
      result = '24hours';
      break;
    case 'last_48_hours':
      result = '48hours';
      break;
    case 'last_7_days':
      result = '7days';
      break;
    case 'last_30_days':
      result = '30days';
      break;
    default:
      // istanbul ignore next -- will never happen, this fn is protected by a check for quickFilters.has
      break;
  }

  return result;
}

export function getNectarExpectedRelativeValue(
  dateTime: string,
): string | null {
  let result: string | null = null;

  switch (dateTime) {
    case '24hours':
      result = 'last_24_hours';
      break;
    case '48hours':
      result = 'last_48_hours';
      break;
    case '7days':
      result = 'last_7_days';
      break;
    case '30days':
      result = 'last_30_days';
      break;
    default:
      // istanbul ignore next -- will never happen, this fn is protected by a check for quickFilters.has
      break;
  }

  return result;
}

export function parseFilterOptionMetaFromColumnDefs({
  columns,
  columnSchema,
  columnFilters,
  globalFilters,
}: {
  columns: Array<Column<CategorySchema>>;
  columnSchema: Array<ColFilters['filterableColumnSchema']> | undefined;
  columnFilters: Array<ColFilters['unionOf']['details']>;
  globalFilters?: {
    deviceFamilies?: Array<string>;
    blueprintIds?: Array<string>;
  };
}) {
  const filterOptions = columns
    ?.map((col) => {
      const colMeta = col.columnDef?.meta;

      if (!colMeta?.filterType || !colMeta?.filterIcon) {
        return undefined;
      }
      const selected = columnFilters.some((filter) => filter.key === col.id);

      const disabled =
        colMeta?.filterDisabled?.({
          filterKey: col.id,
          columnFilters,
          globalFilters,
        }) ?? false;

      return {
        label: colMeta.displayName as string,
        value: col.id,
        icon: colMeta.filterIcon,
        disabled,
        tooltip: selected ? 'Already an active filter' : null,
        meta: {
          ...colMeta,
          ...(colMeta.filterType === 'enum'
            ? {
                filterOptions:
                  colMeta.filterOptions ||
                  columnSchema?.find((schema) => schema?.value === col.id)
                    ?.enumOptions,
              }
            : {}),
        },
      };
    })
    .filter(Boolean);

  const sectionedOptions = Array.from(
    filterOptions
      .reduce(
        (acc, curr) => {
          const colMeta = curr?.meta;

          if (!colMeta) {
            return acc;
          }

          if (!colMeta.filterMenuPriority) {
            acc.get(Infinity).push(curr);
            return acc;
          }

          if (!acc.get(colMeta.filterMenuPriority)) {
            acc.set(colMeta.filterMenuPriority, []);
          }

          acc.get(colMeta.filterMenuPriority).push(curr);
          return acc;
        },
        new Map().set(Infinity, []),
      )
      .entries(),
  )
    .sort(([aPriority], [bPriority]) => aPriority - bPriority)
    .map(([menuPriority, sectionOpts]) => ({
      section: `section-${
        menuPriority === Infinity ? 'general' : menuPriority
      }`,
      options: sectionOpts.sort((a, b) =>
        a.label.localeCompare(b.label, undefined, {
          sensitivity: 'base',
          ignorePunctuation: true,
        }),
      ),
    }));

  return [sectionedOptions, filterOptions] as const;
}

export function getNumberFilter(filterToApply) {
  const numberFilterSchema = z
    .object({
      filterValue: z.array(z.coerce.number()).or(z.coerce.number()),
      operatorValue: z.enum([
        'eq',
        'dne',
        'gt',
        'gte',
        'lt',
        'lte',
        'in', // handle same as eq
        'not_in', // handle same as dne
      ]),
    })
    .passthrough()
    .transform((parsed) => {
      switch (parsed.operatorValue) {
        case 'eq':
        case 'in': {
          return {
            in: parsed.filterValue,
          };
        }

        case 'dne':
        case 'not_in': {
          return {
            not_in: parsed.filterValue,
          };
        }

        case 'gt':
        case 'gte': {
          return {
            [parsed.operatorValue]: parsed.filterValue,
          };
        }

        case 'lt':
        case 'lte': {
          return {
            [parsed.operatorValue]: parsed.filterValue,
          };
        }

        default:
          return false as never;
      }
    });

  const parsed = numberFilterSchema.safeParse(filterToApply);

  if (parsed.success) {
    return parsed.data;
  }

  return false;
}

export function getTimeFilter(filterToApply) {
  const units = filterToApply.filterUnits || 'seconds';
  let factor = 1;
  if (units === 'hours') {
    factor = 3600;
  } else if (units === 'minutes') {
    factor = 60;
  }
  const toSeconds = (time) =>
    Array.isArray(time) ? time.map((t) => t * factor) : time * factor;
  const numberFilterSchema = z
    .object({
      filterValue: z.array(z.coerce.number()).or(z.coerce.number()),
      operatorValue: z.enum([
        'eq',
        'dne',
        'gt',
        'gte',
        'lt',
        'lte',
        'in', // handle same as eq
        'not_in', // handle same as dne
      ]),
    })
    .passthrough()
    .transform((parsed) => {
      switch (parsed.operatorValue) {
        case 'eq':
        case 'in': {
          return {
            in: toSeconds(parsed.filterValue),
          };
        }

        case 'dne':
        case 'not_in': {
          return {
            not_in: toSeconds(parsed.filterValue),
          };
        }

        case 'gt':
        case 'gte': {
          return {
            [parsed.operatorValue]: toSeconds(parsed.filterValue),
          };
        }

        case 'lt':
        case 'lte': {
          return {
            [parsed.operatorValue]: toSeconds(parsed.filterValue),
          };
        }

        default:
          return false as never;
      }
    });

  const parsed = numberFilterSchema.safeParse(filterToApply);

  if (parsed.success) {
    return parsed.data;
  }

  return false;
}

export function getVersionFilter(filterToApply) {
  const versionFilterSchema = z
    .object({
      filterValue: z.array(z.any()),
      operatorValue: z.enum([
        'like',
        'not_like',
        'eq',
        'dne',
        'gt',
        'gte',
        'lt',
        'lte',
        'in', // handle same as eq
        'not_in', // handle same as dne
        'ib',
      ]),
    })
    .passthrough()
    .transform((parsed) => {
      switch (parsed.operatorValue) {
        case 'like': {
          return {
            like: parsed.filterValue,
          };
        }
        case 'not_like': {
          return {
            not_like: parsed.filterValue,
          };
        }
        case 'eq':
        case 'in': {
          return {
            in: parsed.filterValue,
          };
        }

        case 'dne':
        case 'not_in': {
          return {
            not_in: parsed.filterValue,
          };
        }

        case 'gt':
        case 'gte': {
          return {
            [parsed.operatorValue]: parsed.filterValue[0],
          };
        }

        case 'lt':
        case 'lte': {
          return {
            [parsed.operatorValue]: parsed.filterValue[0],
          };
        }

        case 'ib': {
          return {
            gte: parsed.filterValue[0],
            lte: parsed.filterValue[1],
          };
        }

        default:
          return false as never;
      }
    });

  const parsed = versionFilterSchema.safeParse(filterToApply);

  if (parsed.success) {
    return parsed.data;
  }

  return false;
}

const datetimeFilterTransformSchemas = {
  eq_or_in_between: z
    .object({
      lte: z.coerce.date(),
      gte: z.coerce.date(),
    })
    .transform((range) => {
      const ONE_DAY_IN_MS = 86_400_000;
      const lte = userToSystemDate(range.lte);
      const gte = userToSystemDate(range.gte);
      const timeDiffMs = lte.getTime() - gte.getTime();
      const andDatesFilterSchema = z
        .number()
        .lt(ONE_DAY_IN_MS)
        .transform<{
          operator: 'eq' | 'ib';
          value: 'custom_date_range';
          selectedDate: Date | null;
          dateRange: {
            from: Date | null;
            to: Date | null;
          };
        }>(() => ({
          operator: 'eq',
          value: 'custom_date_range',
          selectedDate: gte,
          dateRange: {
            from: null,
            to: null,
          },
        }))
        .catch({
          operator: 'ib',
          value: 'custom_date_range',
          selectedDate: null,
          dateRange: {
            from: gte,
            to: lte,
          },
        });

      return andDatesFilterSchema.parse(timeDiffMs);
    }),
  relative_between: z
    .object({
      between: z.array(z.string()).length(2),
    })
    .transform((range) => ({
      operator: 'ib',
      value: 'custom_relative_date_range',
      selectedDate: null,
      dateRange: {
        from: range.between[0],
        to: range.between[1],
      },
    })),
  not_equal: z
    .object({
      or: z.object({
        gt: z.coerce.date(),
        lt: z.coerce.date(),
      }),
    })
    .transform((range) => ({
      operator: 'dne',
      value: 'custom_date_range',
      selectedDate: userToSystemDate(range.or.lt),
      dateRange: {
        from: null,
        to: null,
      },
    })),
  relative_equals: z
    .object({
      eq: z.string(),
    })
    .transform((range) => ({
      operator: 'eq',
      value: 'custom_relative_date_range',
      selectedDate: range.eq,
      dateRange: {
        from: null,
        to: null,
      },
    })),
  greater_than: z
    .object({
      gt: z.coerce.date(),
    })
    .transform((range) => ({
      operator: 'gt',
      value: 'custom_date_range',
      selectedDate: userToSystemDate(range.gt),
      dateRange: {
        from: null,
        to: null,
      },
    })),
  relative_greater_than: z
    .object({
      gt: z.string(),
    })
    .transform((range) => ({
      operator: 'gt',
      value: 'custom_relative_date_range',
      selectedDate: range.gt,
      dateRange: {
        from: null,
        to: null,
      },
    })),
  greater_than_equal: z
    .object({
      gte: z.coerce.date(),
    })
    .transform((range) => ({
      operator: 'gte',
      value: 'custom_date_range',
      selectedDate: userToSystemDate(range.gte),
      dateRange: {
        from: null,
        to: null,
      },
    })),
  relative_greater_than_equal: z
    .object({
      gte: z.string(),
    })
    .transform((range) => ({
      operator: 'gte',
      value: 'custom_relative_date_range',
      selectedDate: range.gte,
      dateRange: {
        from: null,
        to: null,
      },
    })),
  less_than: z
    .object({
      lt: z.coerce.date(),
    })
    .transform((range) => ({
      operator: 'lt',
      value: 'custom_date_range',
      selectedDate: userToSystemDate(range.lt),
      dateRange: {
        from: null,
        to: null,
      },
    })),
  relative_less_than: z
    .object({
      lt: z.string(),
    })
    .transform((range) => ({
      operator: 'lt',
      value: 'custom_relative_date_range',
      selectedDate: range.lt,
      dateRange: {
        from: null,
        to: null,
      },
    })),
  less_than_equal: z
    .object({
      lte: z.coerce.date(),
    })
    .transform((range) => ({
      operator: 'lte',
      value: 'custom_date_range',
      selectedDate: userToSystemDate(range.lte),
      dateRange: {
        from: null,
        to: null,
      },
    })),
  relative_less_than_equal: z
    .object({
      lte: z.string(),
    })
    .transform((range) => ({
      operator: 'lte',
      value: 'custom_relative_date_range',
      selectedDate: range.lte,
      dateRange: {
        from: null,
        to: null,
      },
    })),
  is_null: z
    .object({
      is_null: z.boolean(),
    })
    .transform((range) => ({
      operator: 'is_null',
      value: range.is_null.toString(),
      selectedDate: null,
      dateRange: {
        from: null,
        to: null,
      },
    })),
  relative: z
    .object({
      relative: z.string(),
    })
    .transform((range) => ({
      operator: 'eq',
      value: getNectarExpectedRelativeValue(range.relative),
      selectedDate: null,
      dateRange: {
        from: null,
        to: null,
      },
    })),
};

const datetimeFilterUnion = z.union([
  datetimeFilterTransformSchemas.eq_or_in_between,
  datetimeFilterTransformSchemas.relative_between,
  datetimeFilterTransformSchemas.not_equal,
  datetimeFilterTransformSchemas.greater_than,
  datetimeFilterTransformSchemas.greater_than_equal,
  datetimeFilterTransformSchemas.less_than,
  datetimeFilterTransformSchemas.less_than_equal,
  datetimeFilterTransformSchemas.relative_equals,
  datetimeFilterTransformSchemas.relative_greater_than,
  datetimeFilterTransformSchemas.relative_greater_than_equal,
  datetimeFilterTransformSchemas.relative_less_than,
  datetimeFilterTransformSchemas.relative_less_than_equal,
  datetimeFilterTransformSchemas.is_null,
  datetimeFilterTransformSchemas.relative,
]);

function getDatetimeFilterOption(datetimeParams) {
  const parsed = datetimeFilterUnion.safeParse(datetimeParams);

  if (parsed.success) {
    return parsed.data;
  }

  return {};
}

export function getInitialFilterOptions(
  filterOptions: Array<ColFilters['menuOption'] | undefined>,
) {
  return ([key, value]) => {
    const option = filterOptions.find((opt) => opt?.value === key);
    const newFilter = getNewFilterByType(key as string, option);

    if (!newFilter) {
      return undefined;
    }
    const filterValue = Object.entries(value)[0];

    if (newFilter.type === 'version' && value.lte && value.gte) {
      newFilter.operator = 'ib';
      newFilter.value = [value.gte, value.lte];
      return newFilter;
    }
    if (newFilter.type === 'time' && Object.keys(value).length > 0) {
      newFilter.units = 'seconds';
    }

    if (filterValue) {
      newFilter.operator =
        filterValue[0] as ColFilters['unionOf']['details']['operator'] as string;
      if (isNullOrNotNullOperator(newFilter.operator)) {
        switch (newFilter.type) {
          case 'string':
          case 'number':
          case 'version':
          case 'enum':
          case 'time':
            newFilter.operator = getNullValue({
              is_null: filterValue[1].toString(),
            });
            break;
          case 'boolean':
            newFilter.value = getNullValue({
              is_null: filterValue[1].toString(),
            });
            break;
        }
      } else {
        newFilter.value =
          filterValue[1] as ColFilters['unionOf']['details']['value'];
      }
    }

    if (newFilter.type === 'date-time') {
      const dateOptionDetails = getDatetimeFilterOption(value);
      if (isNullOrNotNullOperator(newFilter.operator)) {
        dateOptionDetails.value = getNullValue({
          is_null: filterValue[1].toString(),
        });
      }

      return {
        ...newFilter,
        ...dateOptionDetails,
      };
    }

    return newFilter;
  };
}
