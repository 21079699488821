/* istanbul ignore file */
import { Banner, Box, Flex } from '@kandji-inc/nectar-ui';
import { Setting } from 'src/features/library-items/template';
import SkipScreens, {
  useSkipScreensValidation,
} from '../../auto-enroll/skip-screens';

const getPanelConfig = (device) => ({
  title: `Specify Setup Assistant screens for ${device}`,
  helper: 'Select the checkbox for a Setup Assistant screen to skip it.',
  selectAllLabel: `Skip all screens for ${device}`,
});

const osTypeMap = {
  Mac: 'macOS',
  iPhone: 'iOS',
  iPad: 'iPadOS',
};

const getDescriptionCopy = (device) =>
  `Customize and configure the ${osTypeMap[device]} Setup Assistant screens for after an update or upgrade.`;

const DeviceSection = (props) => {
  const {
    setting,
    update,
    isDisabled,
    isSaving,
    device,
    validationDep,
    isDeviceInstallOnSupported,
  } = props;

  const panelConfig = getPanelConfig(device);

  const skipScreensValidationSettings = {
    isEnabled: setting.isEnabled,
    canSkip: true,
    screens: setting.screens,
    isSkipAll: false,
    isSaving,
  };

  const { skipScreensRef, isSkipScreenInvalid } = useSkipScreensValidation(
    skipScreensValidationSettings,
    update,
    [validationDep, setting.isEnabled],
  );

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{device}</h3>
        <Setting.HeaderToggle
          isEnabled={setting.isEnabled}
          onChange={() => update('isEnabled', (p) => !p)}
          isDisabled={isDisabled}
          isHideChip
        />
      </Setting.Header>
      <Setting.SubHeader>
        <Flex flow="column" gap="xs">
          <p className="b-txt">{getDescriptionCopy(device)}</p>
          {!isDeviceInstallOnSupported && setting.isEnabled && (
            <Banner
              theme="warning"
              text={`Setup Assistant for ${osTypeMap[device]} will not be customized because ${device} was excluded in Install On for this library item.`}
            />
          )}
        </Flex>
      </Setting.SubHeader>
      {setting.isEnabled && (
        <Setting.Rows>
          <Box ref={skipScreensRef} css={{ position: 'relative' }}>
            <SkipScreens
              panelConfig={panelConfig}
              screens={setting.screens}
              setScreens={(screens) => update('screens', screens)}
              disabled={isDisabled}
              hasError={isSkipScreenInvalid}
            />
          </Box>
        </Setting.Rows>
      )}
    </Setting.Card>
  );
};

export default DeviceSection;
