import { Button } from '@kandji-inc/bumblebee';
import { Box, Dialog, Flex, Text, TextField } from '@kandji-inc/nectar-ui';
/* istanbul ignore file */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { i18n } from 'src/i18n';

type AddCustomVariableModalProps = {
  isOpen: boolean;
  onSave: (text: string) => void;
  toggle: (isOpen) => void;
  withValue: string;
  kind: 'device' | 'hostname';
};

const AddCustomVariableModal = (props: AddCustomVariableModalProps) => {
  const { isOpen, onSave, toggle, withValue, kind } = props;
  const {
    register,
    handleSubmit,
    formState: {
      errors: { text: textError },
    },
    resetField,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      text: '',
    },
  });
  const isEditing = withValue;

  useEffect(() => {
    if (isOpen) {
      resetField('text', { defaultValue: withValue || '' });
    }
  }, [isOpen, withValue]);

  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={toggle}
      css={{ width: '560px' }}
      title={i18n.t('Add a custom device variable')}
      content={
        <Flex flow="column" gap="xl">
          <Text>
            {kind === 'device'
              ? i18n.t(
                  'Enter the desired custom text for the device name. Device name can contain capital letters, numbers, spaces and special characters.',
                )
              : i18n.t(
                  'Enter the desired custom text for the hostname. The hostname can only contain letters, numbers and hyphens. Spaces are not allowed.',
                )}
          </Text>
          <Box css={{ width: '360px' }}>
            <TextField
              autoFocus
              placeholder={i18n.t('Enter custom text')}
              state={textError?.message ? 'error' : 'default'}
              hint={textError?.message ? { label: textError.message } : null}
              {...register('text', {
                required: {
                  value: true,
                  message: i18n.t('Required.'),
                },
                validate: {
                  value: (value) => {
                    if (kind === 'hostname') {
                      const isAlphaNumWithHeiphen =
                        value.match(/^[\p{L}\p{N}-]*$/u);
                      const hasSpaces = value.match(/\s/);

                      if (hasSpaces) {
                        return i18n.t('Spaces not allowed.');
                      }
                      if (!isAlphaNumWithHeiphen) {
                        return i18n.t('Value must be alphanumeric.');
                      }
                    }
                    return null;
                  },
                },
              })}
            />
          </Box>
        </Flex>
      }
      footer={
        <Flex justifyContent="end">
          <Flex gap="md">
            <Button onClick={() => toggle(false)} kind="outline">
              {i18n.t(`Cancel`)}
            </Button>
            <Button
              onClick={() => handleSubmit((model) => onSave(model.text))()}
            >
              {isEditing ? i18n.t('Save') : i18n.t('Add')}
            </Button>
          </Flex>
        </Flex>
      }
    />
  );
};

export default AddCustomVariableModal;
