/* istanbul ignore file */
import { Flex, Text, Tooltip } from '@kandji-inc/nectar-ui';
import { useEffect, useRef, useState } from 'react';
import {
  doesTextHaveMatch,
  highlightedText,
} from 'src/pages/ADEListView/utils/highlightText';

const HighlightedTextWithTooltip = (props: {
  text: string;
  searchTerm: string;
  width?: number;
}) => {
  const { text, searchTerm, width = 100 } = props;
  const [isOverflowing, setIsOverflowing] = useState(false);

  const [isMatchInOverflow, setIsMatchInOverflow] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);
  const hiddenTextRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textRef.current && hiddenTextRef.current) {
      const isOverflowing =
        hiddenTextRef.current.scrollWidth > textRef.current.clientWidth;
      setIsOverflowing(isOverflowing);

      // Determines if there is a match in the overflowed text. This determines whether or not the ellipsis should be highlighted.
      if (isOverflowing) {
        const visibleTextWidth = textRef.current.clientWidth;
        const fullText = hiddenTextRef.current.textContent || '';
        const visibleText = fullText.slice(0, Math.floor(visibleTextWidth / 8)); // Approximate character width
        const overflowingText = fullText.slice(visibleText.length);

        setIsMatchInOverflow(
          searchTerm && doesTextHaveMatch(overflowingText, searchTerm),
        );
      }
    }
  }, [text, searchTerm]);

  const pathDisplay = (
    <Text
      css={{
        display: 'flex',
      }}
    >
      <Text
        ref={textRef}
        css={{
          maxWidth: `${width}px`,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {highlightedText(text, searchTerm)}
      </Text>
      <Text
        ref={hiddenTextRef}
        css={{
          position: 'absolute',
          visibility: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {text}
      </Text>

      {/* Manually add ellipsis when the text is overflowing so that we have 
      control over whether or not it is highlighted upon search. */}
      {isOverflowing && (
        <Text
          css={{
            background: isMatchInOverflow ? '$yellow30' : '',
          }}
        >
          ...
        </Text>
      )}
    </Text>
  );

  return (
    <Flex alignItems="center" gap="xs" css={{ maxWidth: `${width}px` }}>
      {isOverflowing ? (
        <Tooltip
          content={<Text>{highlightedText(text, searchTerm)}</Text>}
          theme="dark"
          side="bottom"
          css={{ zIndex: 2, maxWidth: 'auto' }}
        >
          {pathDisplay}
        </Tooltip>
      ) : (
        pathDisplay
      )}
    </Flex>
  );
};

export { HighlightedTextWithTooltip };
