import React, { useContext } from 'react';

import { Icon } from '@kandji-inc/bumblebee';
import { Box, DialogPrimitives as Dialog, Text } from '@kandji-inc/nectar-ui';

import { Context } from './context';

import slug from './slug';
import type from './type';

const { GENERAL, AUTO_APP } = slug;

const types = [type[GENERAL], type[AUTO_APP]];

const Header = () => (
  <Dialog.Header>
    <Dialog.Title>Feature request</Dialog.Title>
  </Dialog.Header>
);

const Content = () => {
  const { setWizard } = useContext(Context);

  const loopType = types.map(({ label, description, icon, slug: typeSlug }) => (
    <article key={typeSlug} className="fr-tile">
      <button
        type="button"
        className="tile"
        onClick={() =>
          setWizard({
            type: typeSlug,
          })
        }
      >
        <Icon name={icon} className="fr-icon" />
        <span className="label b-h3">{label}</span>
        <p className="description b-txt">{description}</p>
      </button>
    </article>
  ));

  return (
    <Box p5>
      <Text>
        We value your feedback. Share a new feature request, recommend
        improvements to existing functionality, or request a new Auto App.
      </Text>
      <div className="fr-tile-loop">{loopType}</div>
    </Box>
  );
};

export default {
  header: <Header />,
  content: <Content />,
  footer: null,
};
