import { Flex } from '@kandji-inc/nectar-ui';
import { Pagination } from 'src/components/ui';
import type { PageSize, PaginationState } from '../../../threat.types';

type ThreatListTableProps = Readonly<{
  dataCount: number | undefined;
  pagination: PaginationState;
  itemsPerPageOptions: number[];
  onPaginationChange: (page: number, pageSize: PageSize) => void;
  bulkActions?: React.ReactNode;
  isPageSizeShown: boolean;
}>;

const ThreatListTablePaginationBar = (props: ThreatListTableProps) => {
  const {
    dataCount = 0,
    pagination: { page = 1, pageSize = 10 },
    itemsPerPageOptions,
    onPaginationChange,
    bulkActions,
    isPageSizeShown,
  } = props;

  return (
    <Flex data-testid="threats-table-pagination" flex={1}>
      <Pagination
        currentPage={page}
        totalItems={dataCount}
        itemsPerPage={pageSize}
        itemsPerPageOptions={itemsPerPageOptions}
        onPageChange={
          /*istanbul ignore next*/ (newPage) =>
            onPaginationChange(newPage, pageSize)
        }
        onItemsPerPageChange={
          /*istanbul ignore next*/ (itemsPerPage) =>
            onPaginationChange(1, itemsPerPage as PageSize)
        }
        bulkActions={!isPageSizeShown ? bulkActions : null}
      />
    </Flex>
  );
};

export default ThreatListTablePaginationBar;
