import { Button } from '@kandji-inc/bumblebee';
import React from 'react';
import styled from 'styled-components';
import AwesomeInput from '../AwesomeInput';
import { ModalWrapper } from './ModalWrapper';
import { ButtonsWrapper, CancelButtonWrapper } from './elements';

const InputWrapper = styled.div`
  padding-bottom: 32px;
`;

export class ComputerChangeAssetTag extends ModalWrapper {
  constructor(props) {
    super(props);
    this.modalName = 'ComputerAssetTagEdit';
    this.state = {
      isLoading: false,
      assetTag: '',
    };
    this.onSave = this.onSave.bind(this);
  }

  onSave() {
    const { info, setSnackbar, changeDEPComputersAssetTag } = this.props;
    const { assetTag } = this.state;
    const ids = info.computers;
    if (!(ids && ids.length) && !info.computerId) {
      return setSnackbar('No Device Selected');
    }
    this.setState({ isLoading: true });
    changeDEPComputersAssetTag(ids, assetTag)
      .then(() => {
        this.setState({ isLoading: false });
        setSnackbar('Asset Tag was updated successfully');
        this.onHide();
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        setSnackbar(err.message);
      });
    return '';
  }

  renderHeader = () => 'Assign Asset Tag';

  renderBody() {
    const { assetTag } = this.state;
    return (
      <>
        <p>
          Assign an Asset Tag to help identify this device in your organization.
        </p>
        <InputWrapper>
          <AwesomeInput
            name="assetTag"
            placeholder="Asset Tag"
            value={assetTag}
            innerRef={(el) => {
              this.assetTagInput = el;
            }}
            onChange={this.onChange}
          />
        </InputWrapper>
      </>
    );
  }

  renderFooter() {
    const { isLoading, assetTag } = this.state;
    return (
      <ButtonsWrapper>
        <CancelButtonWrapper>
          <Button type="button" onClick={this.onHide} kind="outline">
            Cancel
          </Button>
        </CancelButtonWrapper>
        <Button
          type="button"
          disabled={isLoading || !assetTag}
          onClick={this.onSave}
        >
          Save
        </Button>
      </ButtonsWrapper>
    );
  }
}

export default ComputerChangeAssetTag;
