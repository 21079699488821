import { Button } from '@kandji-inc/bumblebee';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { integrationLogin as callIntegrationLogin } from '../../../_actions/gSuite';
import { H6 } from '../Typography';
import { ModalWrapper } from './ModalWrapper';
import { ButtonsWrapper, CancelButtonWrapper } from './elements';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Description = styled.p`
  font-family: var(--font-family-primary);
`;

const StepList = styled.div`
  font-family: var(--font-family-primary);
  margin-top: 15px;
`;

const Step = styled.li`
  margin: 10px 0;
`;

export class Office365Login extends ModalWrapper {
  constructor(props) {
    super(props);
    this.modalName = 'Office365Login';
  }

  renderHeader = () => 'Office365';

  renderBody = () => (
    <Wrapper>
      <Description>
        In order for Kandji to import your employees, we need administrative
        access to your Office365 account.
      </Description>
      <H6>Perform the following three steps:</H6>
      <StepList>
        <Step>
          Check that your Office365 account has API access enabled. In
          Office365, go to API Reference settings and make sure the Enable API
          access box is checked.
        </Step>
        <Step>
          Make sure that you have the admin permission to read users on your
          domain.
        </Step>
        <Step>
          When Kandji asks for permission to read users on your domain, click
          Allow.
        </Step>
      </StepList>
    </Wrapper>
  );

  renderFooter() {
    const { integrationLogin, info } = this.props;
    return (
      <ButtonsWrapper>
        <CancelButtonWrapper>
          <Button type="button" onClick={this.onHide} kind="outline">
            Cancel
          </Button>
        </CancelButtonWrapper>
        <Button
          type="button"
          onClick={() => integrationLogin('/office365/auth')}
          name="Login"
        >
          Login
        </Button>
      </ButtonsWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      integrationLogin: callIntegrationLogin,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(Office365Login);
