/* istanbul ignore file */
import { Button } from '@kandji-inc/bumblebee';
import { i18n } from 'i18n';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import {
  deleteArchivedUsers as callDeleteArchivedUsers,
  getUsersCounts as callGetUsersCounts,
} from '../../../_actions/gSuite';
import { setSnackbar as callSetSnackbar } from '../../../_actions/ui';
import { LineLoader } from '../LineLoader';
import { ModalWrapper } from './ModalWrapper';
import { ButtonsWrapper, CancelButtonWrapper } from './elements';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const BodyText = styled.p`
  font-family: var(--font-family-primary);
  font-weight: 400;
`;

const BodyTextBold = styled(BodyText)`
  font-weight: 500;
`;

export class DeleteGSuiteUsers extends ModalWrapper {
  constructor(props) {
    super(props);
    this.modalName = 'DeleteGSuiteUsers';
    this.state = {
      isLoading: false,
    };
  }

  onDelete = () => {
    const { deleteArchivedUsers, setSnackbar, getUsersCounts } = this.props;
    const { selectedRows, onDelete } = this.props.info;
    const data = [...selectedRows].map((id) => id);
    const fields = [
      'archived_g_suite_users',
      'device_g_suite_users',
      'without_devices_g_suite_users',
    ];
    const userVerb =
      selectedRows.size === 1 ? i18n.t('User was') : i18n.t('Users were');
    const snackbarText = i18n.t('{userVerb} deleted successfully', {
      userVerb,
    });
    this.setState(
      {
        isLoading: true,
      },
      () => {
        deleteArchivedUsers({ ids: data })
          .then(() => {
            getUsersCounts(fields);
          })
          .then(() => {
            setSnackbar(snackbarText);
            onDelete();
            this.onHide();
          })
          .catch((err) => {
            setSnackbar(err.message);
          })
          .finally(() => this.setState({ isLoading: false }));
      },
    );
  };

  renderHeader = () => i18n.t('Delete archived users');

  renderBody() {
    const { isLoading } = this.state;
    if (isLoading) {
      return <LineLoader />;
    }
    return (
      <Wrapper>
        <BodyTextBold>
          {i18n.t('Are you sure you want to delete chosen users?')}
        </BodyTextBold>
        <BodyText>
          {i18n.t(
            'By deleting the selected users this cannot be undone. You may also need to ensure that the user is removed in your User directory as well or in case of SCIM, the user should be removed from scope of the SCIM app in your IdP.',
          )}
        </BodyText>
        <br />
      </Wrapper>
    );
  }

  renderFooter() {
    const { isLoading } = this.state;
    return (
      <ButtonsWrapper>
        <CancelButtonWrapper>
          <Button type="button" onClick={this.onHide} kind="outline">
            {i18n.t('Cancel')}
          </Button>
        </CancelButtonWrapper>
        <Button type="button" disabled={isLoading} onClick={this.onDelete}>
          {i18n.t('Confirm')}
        </Button>
      </ButtonsWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteArchivedUsers: callDeleteArchivedUsers,
      setSnackbar: callSetSnackbar,
      getUsersCounts: callGetUsersCounts,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(DeleteGSuiteUsers);
