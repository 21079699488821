import {
  Banner,
  Chip,
  Radio,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import { Box } from '@kandji-inc/nectar-ui';
/* istanbul ignore file */
import React, { useEffect } from 'react';

import { devices } from 'features/library-items/data-service/library-item/devices';
import {
  i18nOsRequirements,
  osRequirements,
} from 'features/library-items/library/common';
import { Setting } from 'features/library-items/template';
import { i18n } from 'src/i18n';

import SetComputerName from '../dnd/SetComputerName';
import VariablePreview from '../dnd/VariablePreview';
import DeviceNameService from '../service/device-name-service';

const Hostname = (props: any) => {
  const {
    update,
    isDisabled,
    isSubmitted,
    setting,
    deviceNameSetting,
    devices: runsOnDevices,
  } = props;

  const fieldsToValidate = ['variables'];
  const { onInvalidate, invalidations, refs } = useInputsValidators(
    fieldsToValidate,
    update,
  );
  useRemoveValidationOnUnmount(fieldsToValidate, update);

  useEffect(() => {
    const hasVariables = setting.variables?.length > 0;
    onInvalidate('variables')(!hasVariables);
  }, [setting.variables]);

  const isMacExcluded = !runsOnDevices?.find(
    ({ value }) => value === devices.MAC,
  );

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('Hostname')}</h3>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          {i18n.t(
            'The hostname is how a device is identified on the network and is only customizable for Mac computers. iOS, iPadOS, and tvOS automatically generate hostnames. Devices with Private Address enabled will not display a hostname on the network.',
          )}{' '}
          <a
            href="https://support.kandji.io/support/solutions/articles/72000560507-setting-macos-and-ios-device-names"
            rel="noopener noreferrer"
            target="_blank"
            className="b-alink"
          >
            {i18n.t('Learn more')}
          </a>
        </p>
        {isMacExcluded && (
          <Banner
            kind="block"
            theme={isDisabled ? 'info' : 'warning'}
            icon={isDisabled && 'circle-info'}
            className="b-mt-micro"
            style={{ padding: '8px' }}
          >
            <span>
              {i18n.t(
                'Hostname for macOS will not be managed or customized because Mac was excluded in the device family assignment for this Library item.',
              )}
            </span>
          </Banner>
        )}
      </Setting.SubHeader>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Title>
            <p className="b-txt-bold">
              {i18n.t('Select how the hostname should be managed for macOS')}
            </p>
          </Setting.Title>
          <Setting.Controls>
            <Box>
              <Box>
                <Radio
                  label={i18n.t('Use device name pattern for hostname')}
                  checked={setting.useDeviceNamePattern}
                  onChange={() => {
                    update('useDeviceNamePattern', (p) => !p);
                    update(
                      'variables',
                      DeviceNameService.convertDeviceNameToHostName(
                        deviceNameSetting.variables,
                      ),
                    );
                  }}
                  disabled={isMacExcluded || isDisabled}
                />
              </Box>
              <Radio
                label={i18n.t('Customize hostname')}
                checked={!setting.useDeviceNamePattern}
                onChange={() => update('useDeviceNamePattern', (p) => !p)}
                disabled={isMacExcluded || isDisabled}
              />
            </Box>
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t(
                'Choose to have the hostname match the device name pattern specified above, or customize the hostname for Mac computers.',
              )}
            </p>
            <div className="b-flex-vc b-flex-g1">
              <Chip
                kind="tertiary-light"
                text={i18nOsRequirements(osRequirements.MAC_10_13)}
              />
            </div>
          </Setting.Helpers>
          {setting.useDeviceNamePattern && !isMacExcluded && (
            <Box css={{ gridColumn: '1 / -1', marginTop: '16px' }}>
              <VariablePreview
                kind="hostname"
                selected={setting.variables}
                onDevices={[{ device: devices.MAC }]}
                title={i18n.t('Hostname')}
                previewLabel={i18n.t('Hostname')}
              />
            </Box>
          )}
        </Setting.Row>
        {!setting.useDeviceNamePattern && (
          <Setting.Row>
            <Setting.Title>
              <p className="b-txt-bold">{i18n.t('Set a hostname for macOS')}</p>
              <p className="b-txt">
                {i18n.t(
                  'Drag variables into the hostname field to create the desired pattern. Additionally, you can add custom text between variables.',
                )}
              </p>
            </Setting.Title>
            <Setting.Helpers>
              <p className="b-txt-light">
                {i18n.t(
                  'Hostnames can only contain letters, numbers and hyphens. On macOS spaces are automatically replaced with hyphens. Apple allows for 63 character hostnames, but some networks limit total characters to 31.',
                )}
              </p>
            </Setting.Helpers>
            <Box css={{ gridColumn: '1 / -1' }} ref={refs.variables}>
              <SetComputerName
                kind="hostname"
                title="Hostname"
                previewLabel={i18n.t('Hostname')}
                onDevices={[{ device: devices.MAC, disabled: false }]}
                selectedVariables={setting.variables}
                setSelectedVariables={(vars) => update('variables', vars)}
                isDisabled={isDisabled || isMacExcluded}
                hasError={invalidations[0] && isSubmitted}
              />
            </Box>
          </Setting.Row>
        )}
      </Setting.Rows>
    </Setting.Card>
  );
};

export default Hostname;
