/* istanbul ignore file */
import { Select } from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';

import { i18n } from 'src/i18n';
import AirplaySecurityService from '../service/airplay-security-service';

const AccessCard = (props) => {
  const { setting, isDisabled, update } = props;

  const accessOptions = [
    {
      value: AirplaySecurityService.accessTypes.ANY,
      label: i18n.t('Allow connections from all devices'),
    },
    {
      value: AirplaySecurityService.accessTypes.WIFI_ONLY,
      label: i18n.t('Require devices to be on same network as Apple TV '),
    },
  ];

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('Access')}</h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Title>
            <p className="b-txt">{i18n.t('Access policy')}</p>
          </Setting.Title>
          <Setting.Helpers className="b-txt-light k-li-helper--compact">
            {i18n.t(
              'Allow all devices to initiate AirPlay connections to Apple TV, or require that devices be on the same network as Apple TV.',
            )}
          </Setting.Helpers>
          <Setting.Controls>
            <Select
              disabled={isDisabled}
              options={accessOptions}
              compact
              onChange={({ value }) => update('type', value)}
              value={accessOptions.find(({ value }) => value === setting.type)}
            />
          </Setting.Controls>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default AccessCard;
