import { z } from 'zod';

const BehavioralThreatsGroupSchema = z.object({
  data: z.unknown(),
  sus_count: z.number(),
  mal_count: z.number(),
  count: z.number(),
  limit: z.number(),
  offset: z.number(),
});

export default BehavioralThreatsGroupSchema;
