import { Button, Dialog, Flex } from '@kandji-inc/nectar-ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const WideDialog = ({
  onHide,
  text,
  caption,
  cancelText,
  cancelAction,
  effectText,
  effectAction,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onCancelClick = () => {
    if (cancelAction) {
      setIsLoading(true);
      cancelAction()
        .then(() => setIsLoading(false))
        .then(() => onHide());
    } else {
      onHide();
    }
  };
  const onEffectClick = () => {
    if (effectAction) {
      setIsLoading(true);
      effectAction()
        .then(() => setIsLoading(false))
        .then(() => onHide());
    } else {
      onHide();
    }
  };

  return (
    <Dialog
      isOpen
      closeOnEscape
      closeOnOutsideClick
      onOpenChange={onHide}
      title={text}
      content={caption}
      footer={
        <Flex gap="md" justifyContent="end" wrap="wrap">
          <Button variant="subtle" onClick={onCancelClick}>
            {cancelText}
          </Button>
          <Button variant="primary" loading={isLoading} onClick={onEffectClick}>
            {effectText}
          </Button>
        </Flex>
      }
      css={{ zIndex: 2000 }}
    />
  );
};

WideDialog.propTypes = {
  onHide: PropTypes.func.isRequired,
  text: PropTypes.string,
  caption: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  cancelText: PropTypes.string,
  cancelAction: PropTypes.func,
  effectText: PropTypes.string,
  effectAction: PropTypes.func,
};

WideDialog.defaultProps = {
  text: 'Are you sure?',
  caption: null,
  cancelText: 'Cancel',
  cancelAction: null,
  effectText: 'Delete',
  effectAction: null,
};

export default WideDialog;
