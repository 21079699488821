import ThreatEmptyFilterIcon from '../../../assets/threat-no-filtered-results.svg';
import ThreatEmptyIconV3 from '../../../assets/threat-no-threats-v3.png';
import ThreatListEmptyTable from '../ThreatListEmptyTable';

export type ThreatListEmptyStatesProps = {
  isFiltering: boolean;
  displayDetectionPeriod?: string;
  displayDetectionSubject?: string;
};

const ThreatListEmptyStates = ({
  isFiltering,
  displayDetectionPeriod,
  displayDetectionSubject,
}: ThreatListEmptyStatesProps) => (
  <>
    {isFiltering ? (
      <ThreatListEmptyTable
        icon={ThreatEmptyFilterIcon}
        header="No results found"
        body="Try changing the filter or search with different keywords."
        compact
      />
    ) : (
      <ThreatListEmptyTable
        icon={ThreatEmptyIconV3}
        iconWidth={50}
        header="No threats detected"
        body={
          displayDetectionPeriod
            ? `${displayDetectionPeriod}, no ${displayDetectionSubject} have occurred in your Mac fleet.`
            : `No ${displayDetectionSubject} have occurred in your Mac fleet within the specified detection period.`
        }
        compact
      />
    )}
  </>
);

export default ThreatListEmptyStates;
