import { styled } from '@kandji-inc/nectar-ui';
import type { CSS } from '@kandji-inc/nectar-ui';
import { useEffect, useState } from 'react';
import type { HTMLAttributes } from 'react';

import { APP_ICON_CONFIG } from './common';

import type { App } from '../../home-screen-layout.types';

interface AppIconProps extends HTMLAttributes<HTMLElement> {
  readonly app: Partial<App>;
  readonly disabled: boolean;
  readonly count?: number;
  readonly variant?: 'default' | 'dragging' | 'noDrop';
  readonly multi?: boolean;
  readonly draggable?: boolean;
  readonly isSelected?: boolean;
  readonly hideCount?: boolean;
  readonly css?: CSS;
  readonly hideName?: boolean;
}

const AppIcon = (props: AppIconProps) => {
  const {
    app,
    disabled = false,
    variant = 'default',
    count = 0,
    multi = false,
    draggable = undefined,
    css = null,
    isSelected = false,
    hideCount = false,
    hideName = false,
    ...restHtmlAttr
  } = props;

  const [isImageError, setIsImageError] = useState(false);

  const isDragVariant = variant === 'dragging' || variant === 'noDrop';
  const selected = count > 0 || isSelected;
  const multiSelected = count > 1;
  const showCountBadge = multi ? multiSelected : selected;

  useEffect(() => {
    // istanbul ignore next -- temp ignore to get initial hsl tests merged
    if (app.icon) {
      const checkImgErr = new Image();
      checkImgErr.src = app.icon;
      // istanbul ignore next -- temp ignore to get initial hsl tests merged
      checkImgErr.onerror = () => {
        setIsImageError(true);
      };
    } else {
      setIsImageError(true);
    }
  }, [app.icon]);

  return (
    <StyledAppContainer
      variant={variant}
      draggable={draggable}
      disabled={disabled}
      selected={selected}
      multi={multi}
      css={css}
      {...restHtmlAttr}
    >
      <StyledImageContainer>
        {
          // istanbul ignore next -- temp ignore to get initial hsl tests merged
          !app.icon || isImageError ? (
            <ErrorImagePlaceholder>{app.name[0]}</ErrorImagePlaceholder>
          ) : (
            <StyledImage
              src={app.icon}
              alt={app.name}
              draggable={draggable ?? !disabled}
              data-dnd-focusable
            />
          )
        }
      </StyledImageContainer>

      {!isDragVariant && !hideName && (
        <figcaption data-dnd-tooltip>{app.name}</figcaption>
      )}

      {showCountBadge && !hideCount && (
        <CountBadge role="status" aria-label={`App count number ${count}`}>
          {count}
        </CountBadge>
      )}
    </StyledAppContainer>
  );
};

const imgStyles = {
  width: APP_ICON_CONFIG.iconImageSize,
  height: APP_ICON_CONFIG.iconImageSize,
  borderRadius: '10px',
};

const StyledImage = styled('img', imgStyles);

const StyledImageContainer = styled('div', {
  $$selectedStyle: '0 0 0 2px $colors$blue50, $shadows$elevation2',
  $$borderStyle: '0 0 0 1px rgba(32, 38, 45, 0.08)',
  $$iconForegroundDisplay: 'none',
  $$iconForegroundColor: 'rgba(255, 255, 255, 0.30)',
  $$iconForegroundOpacity: '1',

  position: 'relative',
  margin: '0 auto',
  boxShadow: '$$borderStyle',
  transition: 'box-shadow 50ms ease',
  ...imgStyles,

  '&::after': {
    display: '$$iconForegroundDisplay',
    content: '',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    backgroundColor: '$$iconForegroundColor',
    opacity: '$$iconForegroundOpacity',
  },

  '&:hover': {
    $$iconForegroundDisplay: 'block',
    boxShadow: '$$borderStyle, $elevation3',
  },
});

const StyledAppContainer = styled('figure', {
  position: 'relative',
  textAlign: 'center',
  userSelect: 'none',
  outline: 'none',
  margin: 0,
  pt: 2,

  figcaption: {
    fontSize: '10px',
    lineHeight: '$1',
    color: '$neutral70',
    mt: 4,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  variants: {
    variant: {
      default: {},
      dragging: {
        cursor: 'grabbing',

        [`& ${StyledImageContainer}`]: {
          $$iconForegroundDisplay: 'none',
          boxShadow: '$$selectedStyle',
        },
      },
      noDrop: {
        cursor: 'no-drop',
      },
    },
    draggable: {
      false: {
        cursor: 'not-allowed',
      },
    },
    disabled: {
      true: {
        cursor: 'not-allowed',

        [`& ${StyledImageContainer}`]: {
          $$iconForegroundDisplay: 'block',
          $$iconForegroundColor: '$colors$neutral0',
          $$iconForegroundOpacity: '0.6',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
    selected: {
      true: {
        [`& ${StyledImageContainer}`]: {
          boxShadow: '$$selectedStyle',
        },
        '& figcaption': {
          padding: '0px $1',
          borderRadius: '4px',
          color: '$blue50',
          backgroundColor: '$blue10',
        },
        false: {
          [`& ${StyledImageContainer}`]: {
            boxShadow: 'none',
          },
        },
      },
    },
    multi: {
      true: {
        [`& ${StyledImageContainer}`]: {
          '&::before': {
            content: '',
            position: 'absolute',
            top: 0,
            left: 'auto',
            width: APP_ICON_CONFIG.iconImageSize,
            height: APP_ICON_CONFIG.iconImageSize,
            borderRadius: '10px',
            boxShadow: '$$selectedStyle',
            transform: 'rotate(7.5deg)',
          },
        },
      },
    },
  },

  defaultVariants: {
    variant: 'default',
  },
});

const ErrorImagePlaceholder = styled('div', {
  $$appleUnknownIconColor: '#98999e',

  ...imgStyles,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 28,
  textTransform: 'uppercase',
  color: '$neutral0',
  backgroundColor: '$$appleUnknownIconColor',
  margin: '0 auto',
});

const CountBadge = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: `calc((${APP_ICON_CONFIG.iconImageSize}px / 4) * -1)`,
  right: 0,
  width: `calc(${APP_ICON_CONFIG.iconImageSize}px / 2)`,
  height: `calc(${APP_ICON_CONFIG.iconImageSize}px / 2)`,
  color: '$neutral0',
  backgroundColor: '$blue50',
  borderRadius: '100%',
  fontSize: '$2',
  fontWeight: '$medium',
  fontVariantNumeric: 'lining-nums tabular-nums',
  lineHeight: '$2',
  boxShadow: '$elevation2',
});

export default AppIcon;
