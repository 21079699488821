import styled from 'styled-components';

export const CancelButtonWrapper = styled.section`
  margin-right: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ButtonsWrapper = styled.section`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  // ! I would do grid, but I cannot remember the exact syntax for what I have in mind
  // * Will have to revisit this
  .b-btn-action-outline {
    margin-right: 16px;
  }
`;

export const ButtonsRightWrapper = styled(ButtonsWrapper)`
  justify-content: flex-end;
`;

export const CloseModalIcon = styled.i`
  position: absolute;
  right: 20px;
  top: 15px;
  cursor: pointer;
  font-size: 27px;
  line-height: 20px;
  z-index: 2052;
  &:before {
    content: '\f00d';
    display: inline-block;
    font-family: 'Font Awesome 5 Pro', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
  }
`;
