import { rulesApi } from 'app/api/base';

class RuleService {
  constructor() {
    this.facetPrefix = 'facets';
  }

  /**
   * Get all rule facets.
   * @returns a list of facets, see https://kandji.atlassian.net/wiki/spaces/MEAD/pages/1873903628/GET+Facets.
   */
  async get() {
    const client = rulesApi(this.facetPrefix);
    return client.get();
  }
}

const ruleService = new RuleService();

export default RuleService;
export { ruleService };
