import { NumberParam, withDefault } from 'use-query-params';

function createPaginationParam<Page, PageSize>(
  defaultPage: Page | undefined,
  defaultPageSize: PageSize | undefined,
  storageKey?: string,
) {
  // istanbul ignore next
  const initialPageSize = storageKey
    ? Number(localStorage.getItem(storageKey)) || defaultPageSize
    : defaultPageSize;

  const PageParam = withDefault(NumberParam, defaultPage);
  const PageSizeParam = withDefault(NumberParam, initialPageSize);

  return {
    paginationConfig: { page: PageParam, pageSize: PageSizeParam },
    updateStoredPageSize: /* istanbul ignore next */ (pageSize: PageSize) => {
      if (storageKey) {
        localStorage.setItem(storageKey, String(pageSize));
      }
    },
  };
}

export default createPaginationParam;
