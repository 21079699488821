/* istanbul ignore file */
import { i18n } from 'i18n';
import React from 'react';
import history from '../../../router/history';
import UniversalAlert from './UniversalAlert';

export const VPPWarningBanner = ({ manager }) => (
  <UniversalAlert
    type="danger"
    text={
      manager
        ? i18n.t(
            `Kandji no longer manages your Apps and Books. They are currently being managed by {manager}.`,
            { manager },
          )
        : i18n.t('Kandji no longer manages your Apps and Books.')
    }
    button={{
      text: i18n.t('RECLAIM APPS AND BOOKS'),
      onClick: () =>
        history.push({
          pathname: '/my-company/integrations',
          state: {
            anchor: 'apps-and-books',
          },
        }),
    }}
  />
);
