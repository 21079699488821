/* prettier-ignore */
import { securityWithLoginWindowConfigurationValues } from 'features/library-items/items/wifi/protocols-card/protocols-card.model';
import WifiService from '../../../wifi-service';

const { keys } = WifiService;
const generalKeys = keys.general;
const authKeys = keys.authentication;

const stripEncryptionType = (st) =>
  !st ? 'None' : st.replace(/-PERSONAL|-ENTERPRISE|DYNAMIC-/, '');

const getGeneral = (model) => {
  const generalModel = model.general;
  const authenticationModel = model.authentication;
  const isEnterprise = securityWithLoginWindowConfigurationValues.includes(
    authenticationModel[authKeys.securityType],
  );

  return {
    SSID_STR: generalModel[generalKeys.ssid],
    EnableIPv6: generalModel[generalKeys.withIpV6]
      ? generalModel[generalKeys.ipv6]
      : null,
    AutoJoin: generalModel[generalKeys.autoJoin],
    HIDDEN_NETWORK: generalModel[generalKeys.hiddenNetwork],
    DisableAssociationMACRandomization:
      generalModel[generalKeys.disableMacAddressRandomization],
    CaptiveBypass: generalModel[generalKeys.disableCaptiveNetworkPriority],
    Priority: generalModel[generalKeys.networkPriority],

    // Backwards compatibility, include these here instead of the authentication
    // object.
    EncryptionType: stripEncryptionType(
      authenticationModel[authKeys.securityType],
    ),
    Password:
      !isEnterprise &&
      authenticationModel[authKeys.securityType] &&
      authenticationModel[authKeys.securityPassword]
        ? authenticationModel[authKeys.securityPassword]
        : null,

    // PASSWORD_UPDATED_AT: Date.now(),
  };
};

export default getGeneral;
