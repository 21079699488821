import { Box, Button, Dialog, Flex } from '@kandji-inc/nectar-ui';
import type { UseQueryResult } from '@tanstack/react-query';
import { i18n } from 'i18n';
import * as React from 'react';
import type {
  Blueprint,
  DeviceFamily,
} from 'src/features/visibility/prism/hooks';
import { MAX_DEVICE_FAMILIES } from 'src/features/visibility/prism/utils/constants';
import { deviceFamilyIdToName } from 'src/features/visibility/prism/utils/deviceFamilyUtils';
import FilterChips from './components/FilterChips';
import GlobalFilterModalContent from './components/GlobalFilterModalContent';
import GlobalFilterModalFooter from './components/GlobalFilterModalFooter';
import { useFilterErrors } from './hooks/use-filter-errors';

type GlobalFilterBarProps = {
  blueprintsQuery: UseQueryResult<Blueprint[], unknown>;
  globalFilters: any;
  onDeviceFamilyFilterChange: (deviceFamilies: DeviceFamily[]) => void;
  onBlueprintFilterChange: any;
  onResetFilters: () => void;
  editViewModalOpen: boolean;
  setEditViewModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const GlobalFilterBar = ({
  blueprintsQuery,
  globalFilters,
  onDeviceFamilyFilterChange,
  onBlueprintFilterChange,
  onResetFilters,
  editViewModalOpen,
  setEditViewModalOpen,
}: GlobalFilterBarProps) => {
  const [selectedBlueprints, setSelectedBlueprints] = React.useState<
    Blueprint[]
  >([]);
  const [blueprintFilterOption, setBlueprintFilterOption] =
    React.useState('all_blueprints');
  const [selectedDeviceFamilies, setSelectedDeviceFamilies] = React.useState<
    Array<DeviceFamily>
  >(['Mac', 'iPhone', 'iPad', 'AppleTV']);

  const { filterErrors, setDeviceFamilyErrors } = useFilterErrors(
    selectedDeviceFamilies.length,
  );

  const handleApplyfilters = () => {
    if (selectedDeviceFamilies.length === 0) {
      setDeviceFamilyErrors(true);
      return;
    }
    setDeviceFamilyErrors(false);
    onDeviceFamilyFilterChange(selectedDeviceFamilies);
    onBlueprintFilterChange(selectedBlueprints);
    setEditViewModalOpen(false);

    /* istanbul ignore next - not testing pendo */
    // @ts-expect-error -- pendo is injected in Meta.js
    pendo?.track('[Prism] Global Filters applied', {
      mac_device_family: selectedDeviceFamilies.includes('Mac'),
      iphone_device_family: selectedDeviceFamilies.includes('iPhone'),
      ipad_device_family: selectedDeviceFamilies.includes('iPad'),
      appletv_device_family: selectedDeviceFamilies.includes('AppleTV'),
      all_blueprints: selectedBlueprints.length === 0,
    });
  };

  return (
    <>
      <Flex gap="md">
        <Flex flow="column" gap="sm">
          <FilterChips
            label={i18n.t('Device family')}
            chipLabel={i18n.t('Device families')}
            chipCss={{ paddingTop: 6, paddingBottom: 6 }}
            filterKey="device_families"
            selectedFilters={
              globalFilters.deviceFamilies?.map((d) => ({
                id: d,
                name: deviceFamilyIdToName(d),
              })) ?? []
            }
          />
        </Flex>

        <Flex flow="column" gap="sm">
          <FilterChips
            label={i18n.t('Blueprint')}
            chipLabel={i18n.t('Blueprints')}
            chipCss={{ paddingTop: 6, paddingBottom: 6 }}
            filterKey="blueprints"
            selectedFilters={globalFilters.blueprints?.map(
              (b: Blueprint | string) => {
                if (typeof b === 'string') {
                  const matchingBlueprint = blueprintsQuery.data?.find(
                    (bp) => bp.id === b,
                  );
                  return {
                    id: b,
                    name: matchingBlueprint?.name ?? 'Loading...',
                  };
                }
                return {
                  id: b.id,
                  name: b.name,
                };
              },
            )}
          />
        </Flex>
      </Flex>
      <Box css={{ mt: 'auto', flexShrink: 0 }}>
        <Dialog
          isOpen={editViewModalOpen}
          onOpenChange={setEditViewModalOpen}
          closeIcon="fa-xmark-large-close"
          content={
            <GlobalFilterModalContent
              blueprints={blueprintsQuery.data?.map((blueprint) => ({
                label: blueprint.name,
                value: blueprint.id,
              }))}
              globalFilters={globalFilters}
              selectedBlueprints={selectedBlueprints}
              setSelectedBlueprints={setSelectedBlueprints}
              selectedDeviceFamilies={selectedDeviceFamilies}
              setSelectedDeviceFamilies={setSelectedDeviceFamilies}
              blueprintFilterOption={blueprintFilterOption}
              setBlueprintFilterOption={setBlueprintFilterOption}
              errors={{
                deviceFamilies: filterErrors.deviceFamilies
                  ? i18n.t('Please select a device family')
                  : '',
                // TODO: determine when to show this error:
                // blueprints: filterErrors.blueprints ? 'Please select a blueprint' : '',
              }}
            />
          }
          footer={
            <GlobalFilterModalFooter
              handleApplyfilters={handleApplyfilters}
              setEditViewModalOpen={setEditViewModalOpen}
              setSelectedBlueprints={setSelectedBlueprints}
              setSelectedDeviceFamilies={setSelectedDeviceFamilies}
              setBlueprintFilterOption={setBlueprintFilterOption}
            />
          }
          title={i18n.t('Global filter options')}
          closeOnEscape
          closeOnOutsideClick
          css={{
            h1: {
              fontWeight: '$medium',
            },
          }}
        >
          <Button variant="default" compact>
            {i18n.t('Edit')}
          </Button>
        </Dialog>
        {(globalFilters.blueprints?.length > 0 ||
          (globalFilters.deviceFamilies?.length > 0 &&
            globalFilters.deviceFamilies?.length < MAX_DEVICE_FAMILIES)) && (
          <Button
            compact
            variant="subtle"
            css={{ ml: '4px' }}
            onClick={onResetFilters}
          >
            {i18n.t('Reset')}
          </Button>
        )}
      </Box>
    </>
  );
};

export default GlobalFilterBar;
