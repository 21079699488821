import { updateSetting } from '@kandji-inc/bumblebee';
/* istanbul ignore file */
import React, { useCallback, useContext, useEffect } from 'react';

import ActivityTab from 'features/library-items/common/activity-tab';
import NewStatusTab from 'features/library-items/common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from 'features/library-items/common/new-status-tab/util';
import useUniqValue from 'src/components/common/hooks/use-uniq-value/use-uniq-value';
import { i18n } from 'src/i18n';

import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import { populateSelectedDevices } from '../../data-service/library-item/devices';
import LibraryContext from '../../routes/library.context';
import initial from './initial-state';
import { Computername, Devicename, Hostname } from './sections';
import { deviceNameService } from './service/device-name-service';
import { transformFromApi, transformToApi } from './service/transformers';
import useService from './service/use-device-name-service';

const DeviceNamePage = (props) => {
  const { model, setModel, pageState } = props;
  const [, triggerValidation] = useUniqValue();

  const updateDeviceName = useCallback(
    updateSetting('deviceName', setModel),
    [],
  );
  const updateHostName = useCallback(updateSetting('hostName', setModel), []);

  const updateDeviceMod = useCallback(
    updateSetting('deviceNameModification', setModel),
    [],
  );

  const { itemConfig } = useContext(LibraryContext);

  useEffect(() => populateSelectedDevices(model, setModel, itemConfig), []);

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.getName()}
      summaryInfoProps={{
        name: `${itemConfig.getName()}`,
        description: itemConfig.getDescription(),
        publisher: itemConfig.getPublisher(),
        devices: itemConfig.devices,
        requirements: itemConfig
          .getOsRequirements()
          .map((os) => i18n.t(`{os} (Requires Supervision)`, { os })),
      }}
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={deviceNameService}
      defaultIcon={itemConfig.icon}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      supportsRules
      supportsInstallOn
    >
      <Computername
        setting={model.deviceName}
        hostNameSetting={model.hostName}
        devices={model.devices}
        update={updateDeviceName}
        updateHostName={updateHostName}
        isDisabled={pageState.isDisabled}
        isSubmitted={pageState.isSubmitted}
      />
      <Hostname
        setting={model.hostName}
        deviceNameSetting={model.deviceName}
        devices={model.devices}
        update={updateHostName}
        isDisabled={pageState.isDisabled}
        isSubmitted={pageState.isSubmitted}
      />
      <Devicename
        setting={model.deviceNameModification}
        update={updateDeviceMod}
        isDisabled={pageState.isDisabled}
      />
    </LibraryItemPage>
  );
};

const DeviceName = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState: initial,
    useService,
  });

  const blueprintConflicts = useBlueprintConflicts();

  /* istanbul ignore next */
  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <DeviceNamePage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
      testId="device-name"
    />
  );
};

export default DeviceName;
