/* istanbul ignore file */

import { i18n } from 'i18n';
import React from 'react';

import { formatTime } from '../../common/helpers';

const entry = ({ details }, { connectorList }) => {
  const { connector } = details;

  const { name, bound_domain } =
    connectorList?.find(({ id }) => id === connector) || {};

  return {
    icon: 'circle-plus',
    iconText: i18n.t('Connector added'),
    text: i18n.t('Connector added'),
    description:
      name || bound_domain || `${i18n.t('Connector details are pending')}...`,
  };
};

const detail = ({ action_type, details }, { connectorList }) => {
  const { connector, created_by, created_on } = details;

  const { name, bound_domain } =
    connectorList?.find(({ id }) => id === connector) || {};

  const pendingMessage = `${i18n.t('Connector details are pending')}...`;

  return (
    <div className={`activity-entry-details ${action_type}`}>
      <p>
        {i18n.t('Created on:')} {formatTime(created_on)}
      </p>
      <p>
        {i18n.t('Created by:')} {created_by}
      </p>
      <p>
        {i18n.t('Connector name:')} {name || bound_domain || pendingMessage}
      </p>
    </div>
  );
};

export default { entry, detail };
