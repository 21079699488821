const update = (setting, setModel) => (key, value) =>
  setModel((p) => ({
    ...p,
    [setting]: {
      ...p[setting],
      [key]: typeof value === 'function' ? value(p[setting][key]) : value,
    },
  }));

export default update;
