import { usePermissions } from 'contexts/account';
import React from 'react';
import Actions from '../actions/actions';
import { HelpScreen, InstallScreen, SuccessScreen } from '../screens';
import General from './general';
import './settings.css';

const Settings = (props) => {
  const {
    beforeEditModel,
    setBeforeEditModel,
    model,
    setModel,
    initialStateCopy,
    isLoadingBpOptions,
    blueprintConflicts,
    isDeleteModalOpen,
    onDeleteModalOpen,
    onDeleteModalClose,
  } = props;

  const permissions = usePermissions();

  return (
    <div className="b-grid-library-settings k-grid-library-settings--kandji-setup">
      <General
        initialStateCopy={initialStateCopy}
        model={model}
        setModel={setModel}
      />

      <InstallScreen
        initialStateCopy={initialStateCopy}
        model={model}
        setModel={setModel}
        beforeEditModel={beforeEditModel}
      />

      <HelpScreen
        initialStateCopy={initialStateCopy}
        model={model}
        setModel={setModel}
        beforeEditModel={beforeEditModel}
      />

      <SuccessScreen
        initialStateCopy={initialStateCopy}
        model={model}
        setModel={setModel}
        beforeEditModel={beforeEditModel}
      />

      <Actions
        setBeforeEditModel={setBeforeEditModel}
        beforeEditModel={beforeEditModel}
        model={model}
        setModel={setModel}
        isLoadingBpOptions={isLoadingBpOptions}
        blueprintConflicts={blueprintConflicts}
        canEdit={permissions.canManageBlueprints}
        isDeleteModalOpen={isDeleteModalOpen}
        onDeleteModalOpen={onDeleteModalOpen}
        onDeleteModalClose={onDeleteModalClose}
      />
    </div>
  );
};

export default Settings;
