import { useDraggable } from '@dnd-kit/core';
import { Box, Tooltip, TooltipProvider } from '@kandji-inc/nectar-ui';
import { useRef } from 'react';
import type { ReactNode } from 'react';

import { PREVIEW_CONFIG, getTruncatedAppLabelStatus } from './common';

import type { App } from '../../home-screen-layout.types';

interface DraggableAppProps {
  readonly id: string;
  readonly app: App;
  readonly disabled: boolean;
  readonly onSelect: (app: App) => void;
  readonly children: ReactNode;
}

export function DraggableApp({
  id,
  app,
  disabled,
  onSelect,
  children,
}: DraggableAppProps) {
  const internalNodeRef = useRef<HTMLButtonElement | null>(null);

  const {
    common: {
      dndContext: { draggableApp },
    },
  } = PREVIEW_CONFIG;

  const { attributes, listeners, setNodeRef } = useDraggable({
    id,
    data: {
      dndContext: draggableApp,
      item: app,
      nodeRef: internalNodeRef,
    },
    disabled,
    attributes: {
      roleDescription: `draggable ${app.name} app`,
    },
  });

  const handleSelect = () => onSelect(app);

  const handleTooltipOpen = (open: boolean) => {
    if (!open || disabled) {
      return Promise.resolve();
    }

    const [tooltipNode, { isTruncated }] = getTruncatedAppLabelStatus({
      parentNode: internalNodeRef.current,
      selector: '[data-dnd-tooltip]',
    });

    if (!tooltipNode || !isTruncated) {
      return Promise.resolve();
    }

    return Promise.reject();
  };

  return (
    <TooltipProvider>
      <Tooltip
        onOpen={handleTooltipOpen}
        side={disabled ? 'top' : 'bottom'}
        openDelay={500}
        content={
          disabled ? `${app.name} has already been added to a page` : app.name
        }
      >
        <Box
          data-dnd-draggable-app
          aria-label={app.name}
          ref={(r) => {
            setNodeRef(r);
            internalNodeRef.current = r;
          }}
          as="button"
          disabled={disabled}
          // additional disabled data attribute for use as a `css` style selector
          // and more reliable query attribute during tests to check for disabled
          // state since the `.toBeDisabled()` jest-dom matcher has issues detecting
          // disabled state on polymorphic button elements created via the `as`
          // Stitches styled component prop (`Box` as button in this case)
          data-disabled={disabled}
          onClick={handleSelect}
          css={{
            // specificity hack to override `_reboot.scss` button styles
            '&[data-dnd-draggable-app][data-dnd-draggable-app]': {
              display: 'block',
              outline: 'none',
              touchAction: 'none',
              userSelect: 'none',

              cursor: 'grab',

              '&:[data-disabled=true]': {
                cursor: 'not-allowed',
              },

              '&:focus-within': {
                '[data-dnd-focusable=true]': {
                  boxShadow: '$$focusedAppShadow',
                },
              },
            },
          }}
          {...listeners}
          {...attributes}
        >
          {children}
        </Box>
      </Tooltip>
    </TooltipProvider>
  );
}
