import type { PaginationState } from '@tanstack/react-table';
import * as React from 'react';

const DEFAULT_PAGE_SIZE = 50;

export const usePagination = ({
  storageKey = '',
  defaultPageSize = DEFAULT_PAGE_SIZE,
}: {
  storageKey?: string;
  defaultPageSize?: number;
} = {}): {
  paginationState: PaginationState;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  resetPagination: () => void;
} => {
  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>(() => {
      if (storageKey) {
        const pageLimit = localStorage.getItem(storageKey);
        return {
          pageIndex: 0,
          pageSize: pageLimit ? parseInt(pageLimit) : defaultPageSize,
        };
      }
      return {
        pageIndex: 0,
        pageSize: defaultPageSize,
      };
    });

  const resetPageIndex = React.useCallback(() => {
    const pageSize = storageKey
      ? parseInt(localStorage.getItem(storageKey) || defaultPageSize.toString())
      : defaultPageSize;
    setPagination({ pageIndex: 0, pageSize });
  }, [storageKey]);

  React.useEffect(() => {
    if (storageKey) {
      localStorage.setItem(storageKey, pageSize.toString());
    }
  }, [pageSize, storageKey]);

  return {
    paginationState: { pageIndex, pageSize },
    setPagination,
    resetPagination: resetPageIndex,
  };
};
