import { Checkbox, Chip, TextInput } from '@kandji-inc/bumblebee';
import React, { useEffect } from 'react';
import { i18n } from 'src/i18n';

import { Setting } from 'features/library-items/template';
import AddableContent from 'src/features/library-items/common/addable-content';
import uuid from 'uuid';
import EthernetService from '../../../service/ethernet-service';
import SanRaw from './san-raw';

const keys = EthernetService.keys.authentication.scep;

const renderTag = (tag) => (
  <Chip
    kind="tertiary-light"
    text={tag}
    className="k-ade-chip--m0 k-ethernetv2-protocols-general__tag"
    key={tag}
  />
);

const Subject = ({
  update,
  setting,
  refs,
  onInvalidate,
  triggerValidate,
  isVisible,
}) => {
  useEffect(() => {
    const sans = setting[EthernetService.keys.authentication.scep.san];
    if (sans) {
      const moreThanOneNtPrincipal = sans.filter(
        ({ type }) => type === EthernetService.subjectAlternativeNameTypes.UPN,
      );

      onInvalidate(EthernetService.keys.authentication.scep.sanUpn)(
        moreThanOneNtPrincipal.length > 1,
      );
    }
  }, [setting]);

  const subjectTags = [
    i18n.t('macOS 10.11+'),
    i18n.t('iPadOS 13+'),
    i18n.t('tvOS 9+'),
    i18n.t('iOS 4.0+'),
    'CIS',
    'NIST',
    'STIG',
    i18n.t('Supervised'),
  ];

  return (
    <>
      <Setting.Header
        testId="scep-subject"
        className="--no-padding --no-shadow"
      >
        <h3 className="b-h3">Subject</h3>
      </Setting.Header>
      <Setting.Row>
        <Setting.Title>
          <p className="b-txt">
            {i18n.t('Subject')}
            <span className="b-txt-light2"> {i18n.t('(optional)')}</span>
          </p>
        </Setting.Title>
        <Setting.Helpers>
          <p className="b-txt-light">
            {i18n.t(
              'The representation of an X.500 name represented as an array of OID and value.',
            )}
          </p>
          <div className="b-flex-wrap-gtiny">{subjectTags.map(renderTag)}</div>
        </Setting.Helpers>
        <Setting.Controls>
          <div>
            <TextInput
              value={setting[keys.subject]}
              onChange={(e) => update(keys.subject, e.target.value)}
              compact
              isOptional
              placeholder="CN=Duke, OU=JavaSoft, O=Sun Microsystems, C=US"
              textArea
              data-testid="subject-input"
            />
          </div>
        </Setting.Controls>
      </Setting.Row>
      <Setting.Row>
        <Setting.Helpers>
          <p className="b-txt-light">
            {i18n.t(
              'Choose to include Subject Alternative Names by selecting the checkbox and adding the alternative names by type.',
            )}
          </p>
        </Setting.Helpers>
        <Setting.Title style={{ marginBottom: 'var(--b-gap1)' }}>
          <Checkbox
            checked={setting[keys.withSan]}
            label={i18n.t('Specify Subject Alternative Names (SAN)')}
            onChange={() => update(keys.withSan, (p) => !p)}
            testId="scep-san"
          />
        </Setting.Title>
        {setting[keys.withSan] && (
          <Setting.SecondaryControls>
            <Setting.SecondaryControlsRow>
              <div>
                <AddableContent
                  max={25}
                  items={setting[keys.san]}
                  onChange={(newItems) => update(keys.san, newItems)}
                  getEmptyItem={() => ({
                    _id: uuid(),
                    type: null,
                    value: '',
                  })}
                  itemsClassName="k-ethernet-li__san-container"
                  rowClassName="k-ethernet-li__san-row"
                  renderItem={(index) => {
                    const updateSan = (prop, value) => {
                      const newSan = [...setting[keys.san]];
                      newSan[index] = {
                        ...newSan[index],
                        [prop]: value,
                      };

                      update(keys.san, newSan);
                    };

                    return (
                      <SanRaw
                        allSettings={setting[keys.san]}
                        setting={setting[keys.san][index]}
                        updateValidation={update}
                        validationDep={triggerValidate}
                        refs={refs}
                        update={updateSan}
                        isVisible={isVisible}
                        allowOnlySingleNtPrincipal
                      />
                    );
                  }}
                  keyField="_id"
                  addButtonText={i18n.t('Add SAN Type')}
                />
              </div>
            </Setting.SecondaryControlsRow>
          </Setting.SecondaryControls>
        )}
      </Setting.Row>
    </>
  );
};

export default Subject;
