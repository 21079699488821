import {
  Button,
  DropdownMenuPrimitives as Dropdown,
  Icon,
  Text,
  styled,
  useToast_UNSTABLE as useToast,
} from '@kandji-inc/nectar-ui';
// istanbul ignore file
import * as React from 'react';
import { useHistory } from 'react-router';
import { InterfaceContext } from 'src/contexts/interface';
import { useDeletePulseJob } from '../views/PulseCheckDetails/hooks';
import { DeleteConfirmation } from './delete-confirmation';

export const ReportHeaderDropdown = ({
  onReRunReport,
  reportName,
  pulseJobId,
}: {
  onReRunReport: () => void;
  reportName: string;
  pulseJobId: string;
}) => {
  const { sidebarOpened } = React.useContext(InterfaceContext);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const history = useHistory();
  const { toast } = useToast();
  // istanbul ignore next
  const deletePulseJobMutation = useDeletePulseJob({
    onSuccess: () => {
      setDeleteModalOpen(false);
      history.push('/devices/pulse');
    },
    onError: () => {
      toast({
        title: 'Failed to delete report',
        variant: 'error',
        content:
          'There was an error while trying to delete the report. Please try again.',
        style: {
          left: sidebarOpened ? '264px' : '100px',
          bottom: '12px',
          position: 'absolute',
        },
      });
    },
  });

  // istanbul ignore next
  const handleDeleteReport = async () => {
    await deletePulseJobMutation.deletePulseJob(pulseJobId);
  };

  return (
    <>
      <Dropdown.Root>
        <Dropdown.Trigger asChild>
          <Button
            aria-label="Report actions"
            css={{ '& svg': { height: '20px', width: '20px' } }}
          >
            <Icon name="ellipsis" />
          </Button>
        </Dropdown.Trigger>
        <Dropdown.Portal>
          <Dropdown.Content css={{ width: 249 }} align="end">
            <Item onClick={onReRunReport}>
              <Text>Re-run report</Text>
            </Item>
            <Dropdown.Divider />
            <Item
              variant="destructive"
              onClick={() => setDeleteModalOpen(true)}
            >
              <Text>Delete report</Text>
            </Item>
          </Dropdown.Content>
        </Dropdown.Portal>
      </Dropdown.Root>
      <DeleteConfirmation
        reportName={reportName}
        onDelete={handleDeleteReport}
        deletePending={deletePulseJobMutation.deletePending}
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
      />
    </>
  );
};

const Item = styled(Dropdown.Item, {
  variants: {
    variant: {
      destructive: {
        color: '$red50',
      },
    },
  },
});
