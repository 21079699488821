import { EventEmitter } from 'events';
import Const from './Const';

export class Filter extends EventEmitter {
  constructor(data) {
    super(data);
    this.currentFilter = {};
  }

  handleFilter(dataField, value, type, filterObj) {
    const filterType = type || Const.FILTER_TYPE.CUSTOM;

    const props = {
      cond: filterObj.condition, // Only for select and text filter
    };

    if (value !== null && typeof value === 'object') {
      const isAnyFilterValueEmpty = Object.keys(value).some(
        (prop) => !value[prop] || value[prop] === '',
      );

      if (isAnyFilterValueEmpty) {
        delete this.currentFilter[dataField];
      } else {
        this.currentFilter[dataField] = {
          value,
          props,
          type: filterType,
        };
      }
    } else if (!value || value.trim() === '') {
      delete this.currentFilter[dataField];
    } else {
      this.currentFilter[dataField] = {
        value: value.trim(),
        type: filterType,
        props,
      };
    }
    this.emit('onFilterChange', this.currentFilter);
  }
}
