import type { MutableRefObject } from 'react';
import { useCallback } from 'react';
import type { ShowToast, ToastConfigs } from '../../threat.types';

type UseThreatsToast = (toastRef: MutableRefObject<any>) => {
  showToast: ShowToast;
};

/**
 * A custom hook for displaying threat toasts.
 *
 * @param {MutableRefObject<any>} toastRef - The reference to the toast component.
 * @returns {Object} - An object containing the showToast function.
 */

const toastConfigs: ToastConfigs = {
  'status-checking': {
    duration: 30000,
    variant: 'progress-indeterminate',
    title: 'Checking status',
    content:
      'Determining if the threat is still present at the file path location.',
  },
  'status-resolved': {
    duration: 5000,
    variant: 'success',
    title: 'Status updated to resolved',
    content: 'The threat is no longer present at the file path location.',
  },
  'status-undeleted': {
    duration: 5000,
    variant: 'error',
    title: 'Status not updated',
    content: 'The threat is still present at the file path location.',
  },
  'status-timeout': {
    duration: 5000,
    variant: 'error',
    title: 'Something went wrong',
    content:
      'Unable to recheck threat status, possibly due to pending tasks on the device or a communications issue. Please check again later.',
  },
  'status-error': {
    duration: 5000,
    variant: 'error',
    title: 'Something went wrong',
    content:
      'Unable to recheck threat status, possibly due to pending tasks on the device or a communications issue. Please check again later.',
  },
  'release-details-fetch-error': {
    duration: 5000,
    variant: 'error',
    title: 'Something went wrong',
    content: 'Unable to get threat release details, please try again.',
  },
  'export-preparing': {
    duration: 5000,
    variant: 'progress-indeterminate',
    title: 'Threats',
    content: 'Preparing file for download...',
  },
  'export-complete': {
    duration: 5000,
    variant: 'success',
    title: 'Threats',
    content: 'Export complete!',
  },
  'export-error': {
    duration: 5000,
    variant: 'error',
    title: 'Something went wrong',
    content: 'Unable to export, please try again.',
  },
  'release-complete': {
    duration: 5000,
    variant: 'success',
    title: 'Threat released',
    content: '',
  },
  'release-error': {
    duration: 5000,
    variant: 'error',
    title: 'Something went wrong',
    content: 'Unable to release threat, please try again.',
  },
  'select-all-error': {
    duration: 5000,
    variant: 'error',
    title: 'Something went wrong',
    content: 'Unable to select all threats, please try again.',
  },
};

const useThreatsToast: UseThreatsToast = (toastRef) => {
  const showToast = useCallback<ShowToast>(
    (event, override) => {
      const config = toastConfigs[event];
      if (config && toastRef.current) {
        toastRef.current?.updateToast({
          open: true,
          ...config,
          ...override,
        });
      }
    },
    [toastRef],
  );

  return { showToast };
};

export default useThreatsToast;
