/*
  a  >  b => 1
  a === b => 0
  a  <  b => -1
 */
export const compareVersions = (a, b) => {
  const toArrayOfNumbers = (version) =>
    version.split('.').map((strNum) => parseInt(strNum, 10));
  const aInts = toArrayOfNumbers(a);
  const bInts = toArrayOfNumbers(b);
  const maxLength = Math.max(aInts.length, bInts.length);

  for (let index = 0; index < maxLength; index++) {
    const aSubVersion = aInts[index] || 0;
    const bSubVersion = bInts[index] || 0;
    if (aSubVersion > bSubVersion) {
      return 1;
    }
    if (aSubVersion < bSubVersion) {
      return -1;
    }
  }

  return 0;
};

export default compareVersions;
