import { getRunsOnFromModel } from 'src/features/library-items/data-service/library-item/devices';

import getAuthentication from './get-authentication';
import getCertificate from './get-certificate';
import getProxy from './get-proxy';

import configs from 'src/features/library-items/library/library-item-configurations/items/profile';
import EthernetService from '../../ethernet-service';

const authenticationKey = EthernetService.keys.authentication;

const transformToApi = (model) => {
  const ethernetConfig = configs.Ethernet;

  let toSendData = {};

  toSendData = {
    Interface: model.authentication[authenticationKey.interface],
    certificate: getCertificate(model),
    proxy: getProxy(model),
    authentication: getAuthentication(model),
  };

  const { name, isActive, selectedBlueprints, isAllBlueprints } = model;
  const result = {
    data: [toSendData],
    type: ethernetConfig.type,
    identifier: ethernetConfig.identifier,
    name,
    active: isActive,
    blueprints: selectedBlueprints.map(({ value }) => value),
    is_all_blueprints: isAllBlueprints,
    rules: model.rules || null,
  };

  // Populate `runs_on_*` fields
  const runsOn = getRunsOnFromModel(model);
  Object.keys(runsOn).forEach((runsOnKey) => {
    result[runsOnKey] = runsOn[runsOnKey];
  });

  return result;
};

export default transformToApi;
