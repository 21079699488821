import { RSREnforcementKeys } from './updates-card.types';
import type { UpdatesSetting } from './updates-card.types';

export function applyRSREnforcementOptions(data: any, updates: UpdatesSetting) {
  const dataCopy = { ...data };
  const rsrEnforcementValue = updates.rsrEnforcement.value;

  dataCopy.rsr_enforcement = rsrEnforcementValue;

  switch (rsrEnforcementValue) {
    case RSREnforcementKeys.AUTOMATICALY:
      dataCopy.rsr_enforcement_time = updates.rsrEnforcementTime.value;
      dataCopy.rsr_enforcement_delay = updates.rsrEnforcementDelay.value;
      break;
    case RSREnforcementKeys.DO_NOT_ENFORCE:
    default:
      dataCopy.rsr_enforcement_time = null;
      dataCopy.rsr_enforcement_delay = null;
      break;
  }

  return dataCopy;
}
