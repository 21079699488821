import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
/* auto scroll:
https://github.com/clauderic/dnd-kit/pull/140
*/
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import React from 'react';
// import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
// modifiers={[restrictToHorizontalAxis]}

import SortableItem from './sortable-item';

function SortableList(props) {
  const {
    model,
    onSort,
    strategy = 'horizontal',
    children,
    isEnabled = true,
  } = props;
  const idList = model.map((v) => String(v.id || v.uuid));

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id && onSort) {
      const oldIndex = model.findIndex(
        (v) => v.id === active.id || v.uuid === active.id,
      );
      const newIndex = model.findIndex(
        (v) => v.id === over.id || v.uuid === over.id,
      );

      const items = arrayMove(model, oldIndex, newIndex);
      onSort(items);
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  return (
    <>
      {isEnabled ? (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          onDragStart={() => {}}
          autoScroll={false}
        >
          <SortableContext
            items={idList}
            strategy={
              strategy === 'horizontal'
                ? horizontalListSortingStrategy
                : verticalListSortingStrategy
            }
          >
            {idList.map((id, idx) => (
              <SortableItem key={id} id={id}>
                {children[idx]}
              </SortableItem>
            ))}
          </SortableContext>
        </DndContext>
      ) : (
        children
      )}
    </>
  );
}

export default SortableList;
