export default {
  id: null,
  name: '',
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  isActive: true,

  deviceName: {
    variables: [],
  },
  hostName: {
    useDeviceNamePattern: true,
    variables: [],
  },
  deviceNameModification: {
    prevent: false,
  },
  supportsRules: true,
};
