import { TooltipProvider } from '@kandji-inc/nectar-ui';
import React from 'react';
import { AccountProvider } from './account';
import { DataProvider } from './data';
import { EnvironmentProvider } from './environment';
import { InterfaceProvider } from './interface';

const RootProvider = ({ children }) => (
  <EnvironmentProvider>
    <AccountProvider>
      <DataProvider>
        <InterfaceProvider>
          <TooltipProvider>{children}</TooltipProvider>
        </InterfaceProvider>
      </DataProvider>
    </AccountProvider>
  </EnvironmentProvider>
);

export default RootProvider;
