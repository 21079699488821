import appleSystemApps from './service/apple-system-apps';

export default {
  id: null,
  name: '',
  selectedBlueprints: [],
  isAllBlueprints: false,
  isActive: true,

  iPad: {
    isEnabled: true,
    currentPage: 0,
    Dock: [],
    currentFolderId: null,
    currentFolderPage: 0,
    // include non-removable `alwaysInPreview` iPad system apps in initial state
    // for new home screen layout library items
    Pages: [[appleSystemApps.Settings]],
  },
  iPhone: {
    isEnabled: true,
    currentPage: 0,
    currentFolderId: null,
    currentFolderPage: 0,
    Dock: [],
    // include non-removable `alwaysInPreview` iPhone system apps in initial
    // state for new home screen layout library items
    Pages: [[appleSystemApps.Phone, appleSystemApps.Settings]],
  },

  supportsRules: true,
  rules: null,
};
