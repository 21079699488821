import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { useLockBodyScroll } from '../../common/hooks';

const ModalWrapper = styled.div`
  display: block;
  z-index: 2050;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity 0.3s;
  &.show {
    opacity: 1;
  }
`;

const Modal = styled.section`
  position: fixed;
  background: white;
  overflow: hidden;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.2s ease 0.2s,
    top 0.5s ease 0.2s;
  &.show {
    opacity: 1;
    visibility: visible;
    top: 100px;
  }
`;

export const StyledModalWrapper = ({ onHide, children }) => {
  const [isInit, setInit] = useState(false);
  // Call hook to lock body scroll
  useLockBodyScroll();

  useLayoutEffect(() => {
    setInit(true);
    return () => setInit(false);
  }, []);

  return (
    <ModalWrapper className={classNames({ show: isInit })} onClick={onHide}>
      <Modal
        className={classNames({ show: isInit })}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </Modal>
    </ModalWrapper>
  );
};

StyledModalWrapper.propTypes = {
  onHide: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
