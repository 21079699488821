import {
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import { Box, Flex, Text, TextField } from '@kandji-inc/nectar-ui';
import { Setting } from 'features/library-items/template';
import type React from 'react';
import { useEffect, useMemo } from 'react';
import { v4 } from 'uuid';
import type { CheckboxRowProps } from './CheckboxRow';
import CheckboxRow from './CheckboxRow';

export type NumberRowProps = {
  onInputChange: (v: number) => void;
  value: number;
  numberChildren?: React.ReactNode;
  minimum: number;
  maximum: number;
  helper: string;
  id?: string;
} & CheckboxRowProps;

const Row = (props: NumberRowProps) => {
  const {
    id,
    isDisabled,
    onInputChange,
    value,
    numberChildren,
    minimum,
    maximum,
    helper,
    updateHelpers,
    validationDep,
    isSubmitted,
  } = props;

  const idField = `${id}_number`;
  const fieldsToValidate = [idField];
  const { refs, onInvalidate, invalidations } = useInputsValidators(
    fieldsToValidate,
    updateHelpers,
  );
  useRemoveValidationOnUnmount(fieldsToValidate, updateHelpers);

  const validateNumberField = (num) => {
    const isNotNumber = Boolean(Number.isNaN(num));
    onInvalidate(idField)(isNotNumber);
    if (!isNotNumber) {
      const failsMinimum = num < minimum;
      const failsMaximum = num > maximum;
      onInvalidate(idField)(failsMinimum || failsMaximum);
    }
  };
  useEffect(() => {
    validateNumberField(value);
  }, [validationDep]);

  const isError = isSubmitted && invalidations[idField];

  const getErrorText = () => {
    if (!value) {
      return 'Required.';
    }

    if (Number.isInteger(minimum) && Number.isInteger(maximum)) {
      return `Number must be between ${minimum} and ${maximum}.`;
    }
    if (Number.isInteger(minimum)) {
      return `Number must be greater than ${minimum}.`;
    }
    if (Number.isInteger(maximum)) {
      return `Number must be less than ${maximum}.`;
    }

    return '';
  };

  return (
    <Setting.SecondaryControls>
      <Setting.SecondaryControlsRow>
        <Setting.Controls>
          <Flex alignItems="center" gap="md">
            <Box css={{ flex: 0.5 }}>
              <TextField
                ref={refs[0]}
                type="number"
                disabled={isDisabled}
                value={value}
                onChange={(e) => {
                  onInputChange(parseInt(e.target.value, 10));
                  validateNumberField(e.target.value);
                }}
                min={minimum}
                max={maximum}
                state={isError ? 'error' : 'default'}
                hint={isError && { label: getErrorText() }}
                data-testid="number-row-input"
              />
            </Box>
            {helper && <Text>{helper}</Text>}
          </Flex>
        </Setting.Controls>
      </Setting.SecondaryControlsRow>
      {numberChildren}
    </Setting.SecondaryControls>
  );
};

const NumberRow = (props: NumberRowProps) => {
  const id = useMemo(v4, []);

  return (
    <CheckboxRow {...props}>
      {(isChecked) => isChecked && <Row {...props} id={id} />}
    </CheckboxRow>
  );
};

export default NumberRow;
