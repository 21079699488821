import React from 'react';

import { Setting } from 'features/library-items/template';
import { i18n } from 'src/i18n';
import HiveSvg from '../../assets/hive-svg';
import AddBackgroundItem from './background-items-add';

const Empty = (props) => {
  const { setModel, isDisabled } = props;
  return (
    <Setting.Rows>
      <Setting.Row
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <HiveSvg />
        <p className="b-txt b-txt-bold b-mb1" style={{ fontSize: '16px' }}>
          {i18n.t(`No Background Items Configured`)}
        </p>
        <AddBackgroundItem isDisabled={isDisabled} setModel={setModel} />
      </Setting.Row>
    </Setting.Rows>
  );
};

export default Empty;
