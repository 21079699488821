import { Flex, styled } from '@kandji-inc/nectar-ui';
import useAddBodyClass from 'src/features/util/hooks/use-add-body-class';
import useWhiteBg from 'src/features/util/hooks/use-white-bg';

import HeroSection from './HeroSection';
import OneAgentSection from './OneAgentSection';
import WhyKandjiSection from './WhyKandjiSection';
import BackgroundImage from './assets/background.jpg';

const Wrapper = styled(Flex, {
  justifyContent: 'center',
  backgroundImage: `url(${BackgroundImage})`,
  backgroundPositionX: 'calc(50% + 146px)',
  backgroundPositionY: 'top',
  backgroundSize: '1548px',
  backgroundRepeat: 'no-repeat',
});

const Sections = styled(Flex, {
  width: 1256,
  padding: '100px 60px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 100,
});

const EdrTrial = () => {
  useAddBodyClass('edr-trial');
  useWhiteBg();

  return (
    <Wrapper>
      <Sections>
        <HeroSection />
        <OneAgentSection />
        <WhyKandjiSection />
      </Sections>
    </Wrapper>
  );
};

export default EdrTrial;
