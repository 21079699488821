/* istanbul ignore file */
import { visibilityApi } from 'app/api/base';
import { logError } from 'features/visibility/shared/utils';
import {
  createPulseTemplateRequestSchema,
  patchPulseNameAndDescriptionRequestSchema,
  pulseCheckDetailsResponseSchema,
  pulseCheckJobSchema,
  pulseCheckResponseSchema,
  pulseCheckType,
  pulseExportRequestSchema,
  pulseExportResponseSchema,
  pulseFileCheckDeviceResponseSchema,
  pulseLoggedInUsersDeviceResponseSchema,
  pulsePreferencesDeviceResponseSchema,
  pulseProcessCheckDeviceResponseSchema,
  pulseTemplateResponseSchema,
} from '../types/pulse.schema';
import type {
  CreatePulseCheckRequestSchema,
  PatchPulseNameAndDescriptionRequestSchema,
  PulseCheckTypes,
  PulseExportRequestSchema,
  PulseTemplateResponseSchema,
} from '../types/pulse.types';

/*
 * Usage:
 *
 * import { pulseService } from "src/features/visibility/data-service/visibility-service";
 * pulseService.getQuery(schemas, 'device_information', { limit: 10, offset: 10 }).then(console.log)
 */

class PulseService {
  service: NonNullable<typeof visibilityApi>;

  public static resourceBase = {
    pulse: 'pulse',
  } as const;

  public static resourceNames = {
    jobs: 'jobs',
    export: 'export',
  } as const;

  constructor() {
    if (!visibilityApi) {
      throw new Error('Visibility API not found');
    }
    this.service = visibilityApi;
  }

  public async createPulseTemplate(body: CreatePulseCheckRequestSchema) {
    const parsedRequest = createPulseTemplateRequestSchema.parse(body);
    const client = visibilityApi?.(`v1/${PulseService.resourceBase.pulse}`);

    if (!client) {
      throw new Error('Visibility API not found');
    }

    const {
      devices,
      blueprints,
      device_families,
      agent_filters,
      name,
      type,
      description,
    } = parsedRequest;
    const res = await client.post({
      blueprints,
      devices,
      device_families,
      agent_filters,
      name,
      type,
      description,
    });

    return pulseTemplateResponseSchema.parse(res.data);
  }

  public async createPulseJob(
    body: PulseTemplateResponseSchema & { pulseCheckId: string },
  ) {
    const parsedRequest = pulseTemplateResponseSchema.parse(body);

    const client = visibilityApi?.(
      `v1/${PulseService.resourceBase.pulse}/${body.pulseCheckId}/${PulseService.resourceNames.jobs}`,
    );

    if (!client) {
      throw new Error('Visibility API not found');
    }

    const { devices, blueprints, device_families, agent_filters } =
      parsedRequest;

    const res = await client.post({
      blueprints,
      devices,
      device_families,
      agent_filters,
    });
    try {
      return pulseCheckJobSchema.parse(res.data);
    } catch (e) {
      logError(e, 'PulseService.createPulseJob');
      throw e;
    }
  }

  public async patchPulseNameAndDescription(
    body: PatchPulseNameAndDescriptionRequestSchema,
  ) {
    const parsedRequest = patchPulseNameAndDescriptionRequestSchema.parse(body);
    const { pulse_check_id } = parsedRequest;
    const client = visibilityApi?.(
      `v1/${PulseService.resourceBase.pulse}/${pulse_check_id}`,
    );

    if (!client) {
      throw new Error('Visibility API not found');
    }

    const updatedBody: {
      name?: string;
      description?: string | null | undefined;
    } = {
      description: parsedRequest.description,
    };

    if (parsedRequest.name) {
      updatedBody.name = parsedRequest.name;
    }

    const res = await client.patch(updatedBody);

    try {
      return pulseTemplateResponseSchema.parse(res.data);
    } catch (e) {
      logError(e, 'PulseService.patchPulseNameAndDescription');
      throw e;
    }
  }

  public async getAllPulseJobs({
    filters,
    limit = 1000,
  }: {
    filters?: Record<string, any>;
    limit?: number;
  }) {
    const client = visibilityApi?.(
      `v1/${PulseService.resourceBase.pulse}/${PulseService.resourceNames.jobs}`,
    );

    if (!client) {
      throw new Error('Visibility API not found');
    }

    const res = await client.get({ limit, filter: filters });

    try {
      return pulseCheckResponseSchema.parse(res.data);
    } catch (err) {
      logError(err, 'PulseService.getAllPulseJobs');
      return res.data;
    }
  }

  public async getPulseById(id: string) {
    const client = visibilityApi?.(
      `v1/${PulseService.resourceBase.pulse}/${PulseService.resourceNames.jobs}/${id}`,
    );

    if (!client) {
      throw new Error('Visibility API not found');
    }

    const res = await client.get({});

    try {
      return pulseCheckDetailsResponseSchema.parse(res.data);
    } catch (e) {
      logError(e, 'PulseService.getPulseById');
      throw e;
    }
  }

  public async getPulseDevicesById(
    id: string,
    jobType: PulseCheckTypes,
    pagination: { limit: number; offset: number },
  ) {
    const parsedJobType = pulseCheckType.parse(jobType);
    const client = visibilityApi?.(
      `v1/${PulseService.resourceBase.pulse}/${PulseService.resourceNames.jobs}/${id}/devices`,
    );

    if (!client) {
      throw new Error('Visibility API not found');
    }

    const res = await client.get({
      limit: pagination.limit,
      offset: pagination.offset,
    });

    try {
      if (parsedJobType === 'logged_in_users') {
        return pulseLoggedInUsersDeviceResponseSchema.parse(res.data);
      }

      if (parsedJobType === 'processes') {
        return pulseProcessCheckDeviceResponseSchema.parse(res.data);
      }

      if (parsedJobType === 'file_check') {
        return pulseFileCheckDeviceResponseSchema.parse(res.data);
      }

      if (parsedJobType === 'preferences') {
        return pulsePreferencesDeviceResponseSchema.parse(res.data);
      }

      throw new Error(`Unknown job type: ${parsedJobType}`);
    } catch (error: any) {
      logError(error, 'PulseService.getPulseDevicesById');
      throw new Error('Error parsing response');
    }
  }

  public async deletePulseJob(id: string) {
    const client = visibilityApi?.(
      `v1/${PulseService.resourceBase.pulse}/${PulseService.resourceNames.jobs}/${id}`,
    );

    if (!client) {
      throw new Error('Visibility API not found');
    }

    try {
      const res = await client.delete({});
      return res.data;
    } catch (error) {
      logError(error, 'PulseService.deletePulseJob');
      throw new Error('Error deleting pulse job');
    }
  }

  public async exportPulseJob(body: PulseExportRequestSchema) {
    const parsedRequest = pulseExportRequestSchema.parse(body);
    const client = visibilityApi?.(
      `v1/${PulseService.resourceBase.pulse}/${PulseService.resourceNames.export}/`,
    );

    if (!client) {
      throw new Error('Visibility API not found');
    }

    const res = await client.post({
      pulse_job_id: parsedRequest.pulse_job_id,
      with_results_only: parsedRequest.with_results_only,
    });

    try {
      return pulseExportResponseSchema.parse(res.data);
    } catch (e) {
      logError(e, 'PulseService.exportPulseJob');
      throw e;
    }
  }

  public async getExportStatus(exportId: string) {
    const client = visibilityApi?.(
      `v1/${PulseService.resourceBase.pulse}/${PulseService.resourceNames.export}/${exportId}`,
    );

    if (!client) {
      throw new Error('Visibility API not found');
    }

    const res = await client.get({});

    try {
      return pulseExportResponseSchema.parse(res.data);
    } catch (e) {
      logError(e, 'PulseService.getExportStatus');
      throw e;
    }
  }
}

const pulseService = new PulseService();
export default PulseService;
export { pulseService };
