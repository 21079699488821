import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import {
  NULL_VALUE_N_DASH,
  ON_OFF_CELL_FILTER_OPTIONS,
  OnOffCell,
  extractFilterableColumns,
  getFilteredDisabled as filterDisabled,
} from '../column-utils';
import { getGlobalColumns } from './globalColumns';

export const getGatekeeperAndXprotectColumns = (schemas) => {
  const globalSchema = schemas.find((schema) => schema.uri === 'globals');
  const gatekeeperAndXprotectSchema = schemas.find(
    (schema) => schema.uri === 'gatekeeper_and_xprotect',
  );

  if (!gatekeeperAndXprotectSchema || !globalSchema) {
    return { columnDefs: [], filterableColumns: [] };
  }

  const combinedProperties = {
    ...globalSchema.schema.properties,
    ...gatekeeperAndXprotectSchema.schema.properties,
  };

  const gatekeeperAndXprotectColumnHelper =
    createColumnHelper<z.infer<typeof combinedProperties>>();

  const globalColumns = getGlobalColumns(
    gatekeeperAndXprotectColumnHelper,
    combinedProperties,
  );

  const columnDefs = [
    ...globalColumns.startColumnDefs,

    gatekeeperAndXprotectColumnHelper.accessor((row) => row.gatekeeper_status, {
      id: 'gatekeeper_status',
      cell: OnOffCell,
      meta: {
        displayName: combinedProperties.gatekeeper_status.title,
        filterType: 'boolean',
        filterOptions: [...ON_OFF_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    gatekeeperAndXprotectColumnHelper.accessor(
      (row) => row.trusted_developers,
      {
        id: 'trusted_developers',
        cell: (info) => {
          const cellValue = info.getValue();
          if (cellValue === null) {
            return NULL_VALUE_N_DASH;
          }
          return cellValue
            ? 'App Store and identified developers'
            : 'App Store';
        },
        meta: {
          displayName: combinedProperties.trusted_developers.title,
          filterType: 'boolean',
          filterOptions: [
            { value: 'true', label: 'App Store and identified developers' },
            { value: 'false', label: 'App Store' },
            { type: 'divider' },
            { value: 'is_null', label: 'is blank' },
            { value: 'is_not_null', label: 'is not blank' },
          ],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    gatekeeperAndXprotectColumnHelper.accessor(
      (row) => row.gatekeeper_version,
      {
        id: 'gatekeeper_version',
        meta: {
          displayName: combinedProperties.gatekeeper_version.title,
          filterType: 'version',
          filterIcon: 'hashtag',
          filterDisabled,
        },
      },
    ),

    gatekeeperAndXprotectColumnHelper.accessor(
      (row) => row.gatekeeper_opaque_version,
      {
        id: 'gatekeeper_opaque_version',
        meta: {
          displayName: combinedProperties.gatekeeper_opaque_version.title,
          filterType: 'version',
          filterIcon: 'hashtag',
          filterDisabled,
        },
      },
    ),

    gatekeeperAndXprotectColumnHelper.accessor((row) => row.xprotect_version, {
      id: 'xprotect_version',
      meta: {
        displayName: combinedProperties.xprotect_version.title,
        filterType: 'version',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    gatekeeperAndXprotectColumnHelper.accessor(
      (row) => row.malware_removal_tool_version,
      {
        id: 'malware_removal_tool_version',
        meta: {
          displayName: combinedProperties.malware_removal_tool_version.title,
          filterType: 'version',
          filterIcon: 'hashtag',
          filterDisabled,
        },
      },
    ),

    ...globalColumns.endColumnDefs,
  ];

  const filterableColumns = extractFilterableColumns(columnDefs);

  return { columnDefs, filterableColumns };
};
