// Note: This is structured as an object instead of array so we can easily apply UI logic based on each option

import { i18n } from 'src/i18n';

// i.e. (value === ENFORCEMENT_OPTIONS.ALLOW_DEFERRAL) instead of (value === ENFORCEMENT_OPTIONS[1])
const getEnforcementOptions = () => ({
  ENFORCE_IMMEDIATELY: {
    label: i18n.t('Enforce immediately upon next login'),
    value: 'enforce_immediately',
  },
  ALLOW_DEFERRAL: {
    label: i18n.t('Allow user deferral before enforcing'),
    value: 'allow_deferral',
  },
});

// Note: This is structured as an object instead of array so we can easily apply UI logic based on each option
const getRestartOptions = () => ({
  FORCE: {
    label: i18n.t('Force a restart after'),
    value: 'force_restart_after',
  },
  REMIND: {
    label: i18n.t('Remind to restart every'),
    value: 'remind_restart_every',
  },
});

const getForceRestartOptions = () => [
  {
    label: i18n.common.durationMinutes(5),
    value: 5,
  },
  {
    label: i18n.common.durationMinutes(15),
    value: 15,
  },
  {
    label: i18n.common.durationMinutes(30),
    value: 30,
  },
  {
    label: i18n.common.durationHours(1),
    value: 60,
  },
  {
    label: i18n.common.durationHours(4),
    value: 240,
  },
];

const getRemindRestartOptions = () => [
  {
    label: i18n.common.durationHours(1),
    value: 1,
  },
  {
    label: i18n.common.durationHours(4),
    value: 4,
  },
  {
    label: i18n.common.durationHours(24),
    value: 24,
  },
  {
    label: i18n.common.durationHours(48),
    value: 48,
  },
  {
    label: i18n.common.durationDays(7),
    value: 168,
  },
];

const getLoginAttemptOptions = () => [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
  {
    label: '6',
    value: 6,
  },
  {
    label: '7',
    value: 7,
  },
  {
    label: '8',
    value: 8,
  },
  {
    label: '9',
    value: 9,
  },
  {
    label: '10',
    value: 10,
  },
];

export {
  getEnforcementOptions,
  getRestartOptions,
  getForceRestartOptions,
  getRemindRestartOptions,
  getLoginAttemptOptions,
};
