import { Icon } from '@kandji-inc/bumblebee';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

const classNames = require('classnames');

const Input = styled('input')`
  font-weight: ${(props) =>
    props.styles && props.styles.fontWeight ? props.styles.fontWeight : '500'};
  margin-top: ${(props) =>
    props.styles && props.styles.marginTop ? props.styles.marginTop : null};
`;

const SearchIcon = () => <Icon name="magnifying-glass" size="sm" />;

const SearchString = ({
  label,
  searchFunc,
  iconPosition,
  className,
  keyDownFunc,
  defaultValue,
  placeholderText,
  styleText,
  rowId,
}) => {
  const [active, setActive] = useState(!!defaultValue);

  const handleOnFocus = useCallback(() => setActive(true), []);
  const handleOnBlur = useCallback(
    (e) => !e.target.value && setActive(false),
    [],
  );
  const handleOnChange = useCallback((e) => {
    const val = e.target.value.trim();
    searchFunc(val);
  }, []);

  return (
    <div
      className={classNames('search-string-container', className, {
        'search-string-container-icon-right': iconPosition === 'right',
        'search-string-container-icon-none ': iconPosition === 'none',
      })}
    >
      {iconPosition === 'left' && <SearchIcon />}
      <label
        htmlFor={rowId}
        className={classNames('blueprint-search-input', { active })}
      >
        {label}
      </label>
      <Input
        id={rowId}
        defaultValue={defaultValue}
        className="input-style"
        maxLength="256"
        autoComplete="off"
        styles={styleText}
        style={{ width: '100%' }}
        type="text"
        placeholder={placeholderText}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onKeyDown={(e) => {
          if (keyDownFunc) {
            keyDownFunc(e);
          }
        }}
      />
      {iconPosition === 'right' && <SearchIcon />}
    </div>
  );
};

SearchString.propTypes = {
  label: PropTypes.string,
  searchFunc: PropTypes.func.isRequired,
  keyDownFunc: PropTypes.func,
  iconPosition: PropTypes.string,
  className: PropTypes.string,
  placeholderText: PropTypes.string,
  defaultValue: PropTypes.string,
  styleText: PropTypes.object,
  rowId: PropTypes.string,
};

SearchString.defaultProps = {
  label: 'Search...',
  iconPosition: 'right',
  className: '',
  placeholderText: '',
  keyDownFunc: null,
  defaultValue: '',
  styleText: {},
  rowId: null,
};

export default SearchString;
