import { hasAuthToken } from 'app/auth';
import { useEffect } from 'react';
import useAuth0 from './useAuth0';
import { defaultReturnTo } from './utils';

/**
 *
 * When components utilize this hook
 * if not authenticated they will be redirected to the login page and
 * returned to the page they were redirected from after login.
 *
 * If an admin is using a kandji auth token this is skipped
 */
const useAuthenticationRequired = (options = {}) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const { returnTo = defaultReturnTo, loginOptions = {} } = options;

  useEffect(() => {
    if (hasAuthToken() || isLoading || isAuthenticated) {
      return;
    }
    const opts = {
      ...loginOptions,
      appState: {
        ...loginOptions.appState,
        returnTo: typeof returnTo === 'function' ? returnTo() : returnTo,
      },
    };
    (async () => {
      await loginWithRedirect(opts);
    })();
  }, [isLoading, isAuthenticated, loginWithRedirect, loginOptions, returnTo]);
};

export default useAuthenticationRequired;
