import { Checkbox } from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';
import { i18n } from 'src/i18n';

import WifiService from '../../service/wifi-service';

const keys = WifiService.keys.authentication.acceptedEapTypes;

const Pac = ({
  setting,
  isDisabled,
  update,
  showProvisionPac,
  showProvisionPacAnonym,
}) => (
  <Setting.SecondaryControlsRow>
    <Setting.Title>
      <p className="b-txt">EAP-FAST</p>
    </Setting.Title>
    <Setting.Helpers>
      <p className="b-txt-light">
        {i18n.t(`Configure Protected Access Credentials. If using PAC, Provision PAC must
        be selected. There are known exploits of anonymous PAC provisioning.`)}
      </p>
    </Setting.Helpers>
    <Setting.Controls className="k-wifi__eap-fast-control">
      <Checkbox
        checked={setting[keys.usePac]}
        onChange={() => update(keys.usePac, (p) => !p)}
        disabled={isDisabled}
        label={i18n.t('Use PAC')}
      />
    </Setting.Controls>
    {showProvisionPac && (
      <Setting.SecondaryControls className="k-ade-secondary-ctrl-grid-gap-align">
        <Setting.SecondaryControlsRow>
          <div className="b-grid-controls">
            <Checkbox
              checked={setting[keys.provisionPac]}
              onChange={() => update(keys.provisionPac, (p) => !p)}
              disabled={isDisabled}
              label={i18n.t('Provision PAC')}
            />
            {showProvisionPacAnonym && (
              <Checkbox
                checked={setting[keys.provisionPacAnonymously]}
                onChange={() => update(keys.provisionPacAnonymously, (p) => !p)}
                disabled={isDisabled}
                label={i18n.t('Provision PAC anonymously')}
              />
            )}
          </div>
        </Setting.SecondaryControlsRow>
      </Setting.SecondaryControls>
    )}
  </Setting.SecondaryControlsRow>
);

export default React.memo(Pac);
