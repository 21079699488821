/* istanbul ignore file - config only */
import { blueprintLibraryCategories } from 'features/blueprints/common';
import { i18n } from 'i18n';
import React from 'react';
import FeatureFlags from 'src/config/feature-flags';
import InHouseApp from 'src/features/library-items/items/in-house-apps/in-house-apps';
import { IpaSupportedDevices } from 'src/features/library-items/items/in-house-apps/in-house-apps.types';
import { IPALibraryItem } from 'src/old-library/IPALibraryItem';
import {
  apiTypes,
  canAddLibraryTypes,
  categories,
  deviceTypes,
  osRequirements,
  publishers,
} from '../../common';
import svgs from '../icons';
import LibraryItemConfig from './item-config.class';

const ipaApps = {
  'In-House Apps': new LibraryItemConfig({
    getName: () => i18n.t('In-House Apps'),
    getDescription: () => '',
    name: 'In-House Apps',
    description: ``,
    type: apiTypes.IPA_APP,
    publisher: null,
    devices: [deviceTypes.MAC],
    osRequirements: [],
    category: categories.IN_HOUSE_APPS,
    blueprintCategory: blueprintLibraryCategories.INSTALLERS_SCRIPTS,
    icon: '',
    canAdd: canAddLibraryTypes.CANNOT_ADD,
    identifier: '',
    routeType: 'ipa-apps',
    getUrl: ({ id }) => `/library/ipa-apps/${id}`,
    getAddUrl: () => null,
    component: () => <IPALibraryItem />,
  }),

  'In-House Apps V2': new LibraryItemConfig({
    getName: () => i18n.t('In-House App'),
    getDescription: () =>
      i18n.t(
        'Distribute a proprietary in-house app using Kandji. Upload a .ipa file for Kandji to host.',
      ),
    name: 'In-House Apps',
    isHidden: () => !FeatureFlags.getFlag('core-08182024-ipa-apps-v2'),
    description: `Distribute a proprietary in-house app using Kandji. Upload a .ipa file for Kandji to host.`,
    type: apiTypes.IPA_APP_V2,
    publisher: publishers.KANDJI,
    devices: IpaSupportedDevices,
    osRequirements: [
      osRequirements.IOS_7_0,
      osRequirements.IPAD_13_0,
      osRequirements.TV_10_0,
    ],
    category: categories.GENERAL,
    blueprintCategory: blueprintLibraryCategories.INSTALLERS_SCRIPTS,
    icon: svgs['IpaApp'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: '',
    routeType: 'ipa-apps-v2',
    getUrl: ({ id }) => `/library/ipa-apps-v2/${id}`,
    getAddUrl: () => '/library/ipa-apps-v2/add',
    component: (props) => <InHouseApp {...props} />,
    singleBlueprintAllowed: true,
  }),
};

export default ipaApps;
