import React from 'react';
import './style.css';

import { Provider } from './context';
import Wizard from './wizard';

export default () => (
  <Provider>
    <Wizard />
  </Provider>
);
