import {
  Button,
  LazyScriptEditor,
  Uploader,
  setClass,
  useInputsValidators,
} from '@kandji-inc/bumblebee';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState, memo, useContext } from 'react';
import './profile-details-card.css';

import { Setting } from 'features/library-items/template';
import { UserRoles } from 'src/app/common/constants';
import { AccountContext } from 'src/contexts/account';
import { i18n } from 'src/i18n';

import { customProfileService } from '../service/custom-profile-service';
import MobileConfigImg from './mobileconfig.png';

function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n) {
    n -= 1;
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

const ProfileDetailsCard = (props) => {
  const { setting, update, isDisabled, isSubmitted } = props;
  const { userRole } = useContext(AccountContext);
  const isAuditor = userRole === UserRoles.auditor;
  const [uploadCancelFn, setUploadCancelFn] = useState();
  const fieldsToValidate = ['file'];
  const { refs, onInvalidate, invalidations } = useInputsValidators(
    fieldsToValidate,
    update,
  );
  useEffect(() => {
    if (!setting.mobileconfig) {
      onInvalidate(0)('Required');
    } else {
      onInvalidate(0)(false);
    }
  }, [setting.mobileconfig]);

  const forceWithFile = useMemo(
    () =>
      (setting.mobileconfig && {
        file: {
          name: setting.mobileconfig.name,
          size: setting.mobileconfig.size,
        },
      }) ||
      null,
    [setting.mobileconfig],
  );

  const onDownload = () => {
    const file =
      setting.mobileconfig.file ||
      dataURLtoFile(
        `data:text/plain;base64,${setting.mobileconfig.data}`,
        setting.mobileconfig.name,
      );

    const url = window.URL.createObjectURL(file);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = setting.mobileconfig.name;
    document.body.appendChild(a);
    a.click();
  };

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('Profile Details')}</h3>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          {i18n.t(`Create a custom configuration profile using tools like Apple
          Configurator or Profile Manager, and then upload as a .mobileconfig
          file below.`)}
        </p>
      </Setting.SubHeader>
      <Setting.Rows>
        <Setting.Row>
          <div style={{ gridColumn: '1/3' }}>
            <p ref={refs[0]} className="b-txt-bold b-mb1">
              {i18n.t('Profile')}
            </p>
            <Uploader
              withError={(isSubmitted && invalidations[0]) || ''}
              onError={(error) =>
                onInvalidate(0)(
                  error || (!setting.mobileconfig && i18n.t('Required')),
                )
              }
              renderInfo={({ currentFile }) => (
                <div className="k-mobile-config-info">
                  <span
                    className={setClass(
                      'b-txt-bold',
                      isDisabled && 'b-txt-light',
                    )}
                  >
                    {currentFile.file.name}
                  </span>
                  {setting.mobileconfig && (
                    <>
                      <span className="b-txt">
                        {i18n.t('Uploaded')}{' '}
                        {DateTime.fromSeconds(
                          Math.round(setting.mobileconfig.updatedAt),
                        ).toFormat('MMMM d, yyyy')}
                      </span>
                      {
                        // istanbul ignore next
                        !isAuditor && (
                          <Button
                            onClick={onDownload}
                            kind="link"
                            className="k-mobile-config-info__download"
                          >
                            {i18n.t('Download')}
                          </Button>
                        )
                      }
                    </>
                  )}
                </div>
              )}
              forceWithFile={forceWithFile}
              onUpload={(file, updateProgress) => {
                const { cancel, upload } =
                  customProfileService.getConfigUploader(file, updateProgress, {
                    name: '',
                    isActive: true,
                  });
                setUploadCancelFn(() => cancel);

                return upload().then((res) => {
                  const {
                    data,
                    plist,
                    scope,
                    payload_uuid,
                    file_name,
                    updated_at,
                    is_signed,
                  } = res.settings;

                  update('mobileconfig', {
                    file,
                    preview: plist,
                    uuid: payload_uuid,
                    name: file_name,
                    scope,
                    data,
                    updatedAt: updated_at,
                    isSigned: is_signed,
                  });
                });
              }}
              icon={
                <img
                  src={MobileConfigImg}
                  alt="mobileconfig file icon"
                  className="k-mobile-config-icon"
                />
              }
              onCancel={uploadCancelFn}
              onDelete={() => {
                setUploadCancelFn(null);
                update('mobileconfig', null);
                onInvalidate('Required');
              }}
              allowedTypes={['.mobileconfig']}
              isDisabled={isDisabled}
              uploadInstructions={
                <span>
                  {i18n.ut(
                    'Drag file here or <span class="b-alink">click to upload</span>',
                  )}
                </span>
              }
            />
            {setting.mobileconfig && (
              <>
                <p className="b-txt-bold b-mb1 b-mt2">
                  XML {i18n.t('Preview')}
                </p>
                <LazyScriptEditor
                  value={setting.mobileconfig.preview}
                  options={{ readOnly: true }}
                  className="k-mobile-config-info__preview"
                />
              </>
            )}
          </div>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default memo(ProfileDetailsCard);
