import {
  Banner,
  Button,
  Dialog,
  Flex,
  Separator,
  Text,
  TextField,
  styled,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import { useEffect, useState } from 'react';

const List = styled('ul', {
  padding: '0 $5',
  marginBottom: 0,
});

export type DeleteDeviceModalProps = {
  isOpen: boolean;
  isMultiple: boolean;
  toggle: (boolean) => void;
  onConfirm: () => void;
};

const records = (count: number) =>
  i18n.t('{count, plural, one {record} other {records}}', { count });
const devices = (count: number) =>
  i18n.t('{count, plural, one {device} other {devices}}', { count });

const DeleteDeviceModal = (props: DeleteDeviceModalProps) => {
  const { isOpen, isMultiple, toggle, onConfirm } = props;
  const [confirmText, setConfirmText] = useState('');
  const EXPECTED_CONFIRM_TEXT = i18n.t('DELETE');
  const pluralCount = isMultiple ? 2 : 1;

  const content = (
    <Flex gap="md" flow="column">
      <Banner
        theme="warning"
        text={i18n.t('Deleting the device {records} cannot be undone.', {
          records: records(pluralCount),
        })}
      />

      <Flex flow="column" gap="xl">
        <Text>
          {i18n.t(
            'All history will be lost, and the {devices} will need to be re-enrolled into Kandji for future use.',
            { devices: devices(pluralCount) },
          )}
        </Text>
        <Text>
          {i18n.t(
            'All device secrets that may prevent anyone from using {these} {devices} will be lost, including:',
            {
              these: i18n.t('{count, plural, one {this} other {these}}', {
                count: pluralCount,
              }),
              devices: devices(pluralCount),
            },
          )}
        </Text>
        <List>
          <li>
            <Text>{i18n.t('Device lock PIN code')}</Text>
          </li>
          <li>
            <Text>{i18n.t('Recovery password')}</Text>
          </li>
          <li>
            <Text>{i18n.t('FileVault Recovery Key')}</Text>
          </li>
          <li>
            <Text>{i18n.t('Activation Lock Bypass Code')}</Text>
          </li>
        </List>
      </Flex>

      <Separator css={{ width: '100% !important', background: '$neutral20' }} />

      <Text>
        {i18n.ut(
          'Type <b>DELETE</b> to permanently delete the device {records}',
          {
            records: records(pluralCount),
          },
        )}
        .
      </Text>

      <TextField
        placeholder={EXPECTED_CONFIRM_TEXT}
        onChange={(e) => setConfirmText(e.target.value)}
        value={confirmText}
        hint={{
          icon: 'omit',
          label: i18n.t(
            'I understand that the selected device {records}, all related device record information, and all device secrets will be destroyed. This cannot be undone.',
            { records: records(pluralCount) },
          ),
        }}
        data-testid="am-delete-device-confirm"
      />
    </Flex>
  );

  const footer = (
    <Flex gap="sm" justifyContent="end" wrap="wrap">
      <Button compact variant="subtle" onClick={() => toggle(false)}>
        {i18n.t('Cancel')}
      </Button>

      <Button
        compact
        variant="danger"
        disabled={i18n.t(confirmText) !== EXPECTED_CONFIRM_TEXT}
        onClick={() => {
          onConfirm();
          toggle(false);
        }}
        data-testid="delete-device-record-action"
      >
        {i18n.t('Delete device {records}', { records: records(pluralCount) })}
      </Button>
    </Flex>
  );

  useEffect(() => {
    setConfirmText('');
  }, [isOpen]);

  return (
    <Dialog
      title={i18n.t('Delete device {records}', {
        records: records(pluralCount),
      })}
      content={content}
      footer={footer}
      isOpen={isOpen}
      onOpenChange={() => {
        toggle(false);
        setConfirmText('');
      }}
      css={{ width: '560px', zIndex: 2000 }}
    />
  );
};

export default DeleteDeviceModal;
