import PropTypes from 'prop-types';
import React from 'react';

const ButtonGroup = (props) => {
  const { className, sizeClass, children, ...rest } = props;
  return (
    <div
      className={`btn-group ${sizeClass} ${className}`}
      role="group"
      {...rest}
    >
      {children}
    </div>
  );
};

ButtonGroup.propTypes = {
  sizeClass: PropTypes.string,
  className: PropTypes.string,
};

ButtonGroup.defaultProps = {
  sizeClass: 'btn-group-sm',
  className: '',
};

export default ButtonGroup;
