import {
  Flex,
  Icon,
  SeparatorPrimitive,
  styled,
  theme,
} from '@kandji-inc/nectar-ui';
import * as React from 'react';
import { COLUMN_EDITOR_DIMENSIONS as DIMENSIONS } from '../../utils/columnEditorUtils';

const StyledColumnSeparator = styled(SeparatorPrimitive.Root, {
  height: '100%',
  width: 1,
  background: '$neutral20',
});
export const ColumnSeparator = () => (
  <Flex flow="column" alignItems="center" mx2>
    <StyledColumnSeparator orientation="vertical" />

    <Icon
      name="arrow-right-arrow-left"
      size={DIMENSIONS.COLUMN_SEPARATOR_ICON_SIZE_PX}
      color={theme.colors.neutral20 as unknown as string}
    />

    <StyledColumnSeparator orientation="vertical" />
  </Flex>
);
