import { appsTypes } from '../../../fast-lane-card/fast-lane-card.model';
import WifiService from '../../wifi-service';

const fastlaneKeys = WifiService.keys.fastlane;

const getApps = (fastLaneMarking) => {
  const apps = fastLaneMarking[fastlaneKeys.addedApps];

  if (!apps) {
    return [];
  }

  return apps.map?.((app) => {
    if (app.type === appsTypes.CUSTOM) {
      return { name: app.name, bundleID: app.bundleId, isCustom: true };
    }
    return { bundleID: app.id, isCustom: false, name: null };
  });
};

const getFastlaneMarking = (model) => {
  const { fastLaneMarking } = model;

  const isEnabled = fastLaneMarking[fastlaneKeys.isEnabled];
  const QoSMarkingEnabled =
    fastLaneMarking[fastlaneKeys.qos] === WifiService.qosValues.ALLOW;
  const QoSMarkingAppleAudioVideoCalls = QoSMarkingEnabled
    ? fastLaneMarking[fastlaneKeys.markMediaApps]
    : null;
  const QoSMarkingAllowListAppIdentifiers = getApps(fastLaneMarking);

  return {
    isManaged: isEnabled,
    apps: QoSMarkingAllowListAppIdentifiers,
    settings: {
      QoSMarkingPolicy: isEnabled
        ? {
            QoSMarkingEnabled,
            QoSMarkingAppleAudioVideoCalls,
            QoSMarkingAllowListAppIdentifiers: QoSMarkingEnabled
              ? QoSMarkingAllowListAppIdentifiers.map(
                  ({ bundleID }) => bundleID,
                )
              : null,
          }
        : null,
    },
  };
};

export default getFastlaneMarking;
