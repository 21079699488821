export const descriptionCharCount = 9999;

export const subcategoryCharCount = 50;

export const USER_TAG_CATEGORY = 'Other';

export const TAGS = [
  {
    label: 'Compliance',
    value: 'Compliance',
  },
  {
    label: 'Reporting & Insights',
    value: 'Reporting & Insights',
  },
  {
    label: 'End-user Onboarding',
    value: 'End-user Onboarding',
  },
  {
    label: 'Integrations',
    value: 'Integrations',
  },
  {
    label: 'Library Item Configuration',
    value: 'Library Item Configuration',
  },
  {
    label: 'Device Information',
    value: 'Device Information',
  },
  {
    label: 'API Enhancements',
    value: 'API Enhancements',
  },
  {
    label: 'Device Enrollment',
    value: 'Device Enrollment',
  },
  {
    label: 'Blueprint Configuration',
    value: 'Blueprint Configuration',
  },
  {
    label: 'End-user Self Service',
    value: 'End-user Self Service',
  },
  {
    label: 'Authentication & Access Management',
    value: 'Authentication & Access Management',
  },
  {
    label: 'End-user Notification',
    value: 'End-user Notification',
  },
  {
    label: 'Endpoint Security',
    value: 'Endpoint Security',
  },
  {
    label: USER_TAG_CATEGORY,
    value: USER_TAG_CATEGORY,
  },
];

export const TAGS_SORTED = TAGS.sort((a, b) => {
  const labelA = a.label.toLowerCase();
  const labelB = b.label.toLowerCase();

  if (labelA === USER_TAG_CATEGORY.toLowerCase()) {
    return 1;
  }
  if (labelA < labelB) {
    return -1;
  }
  if (labelA > labelB) {
    return 1;
  }
  return 0;
});
