import { useMemo } from 'react';
import useListIntegrations from 'src/features/integrations/components-for-specific-types/hooks/use-list-integrations';
import { INT_TYPES } from 'src/features/integrations/constants';

interface BaseIntegrationItem {
  type: string;
}

interface MsDeviceComplianceIntegration {
  type: 'ms_compliance';
  email: string | null;
  id: number;
  synced_at: string | null;
  name: string;
  display_name: string;
  icon: string | null;
  uuid: string;
}

type ListApiData<Item> =
  | {
      data: { results: Item[] };
    }
  | undefined;

type MsdcConfigurationStatus = 'loading' | 'configured' | 'unconfigured';

function selectIntegrationList<IntegrationItem extends BaseIntegrationItem>(
  apiData: ListApiData<IntegrationItem>,
): readonly IntegrationItem[] {
  return apiData?.data?.results || [];
}

export function useMsdcConfigurationStatus() {
  const {
    data: integrationList,
    isLoading,
    isSuccess,
    ...restQueryResult
  } = useListIntegrations({
    integrationType: INT_TYPES.msDeviceCompliance,
    initialData: {
      data: { results: [] },
    },
    select: selectIntegrationList,
  });

  const [msdcConfigStatus, integration] = useMemo<
    readonly [MsdcConfigurationStatus, MsDeviceComplianceIntegration | null]
  >(() => {
    if (isLoading) {
      /* istanbul ignore next */
      return ['loading', null] as const;
    }

    const foundIntegration = integrationList.find(
      (integration: { type: string }) =>
        integration.type === INT_TYPES.msDeviceCompliance,
    );

    const isFound = Boolean(foundIntegration);

    if (isSuccess && isFound) {
      return ['configured', foundIntegration] as const;
    }

    return ['unconfigured', null] as const;
  }, [isLoading, isSuccess, integrationList]);

  return {
    msdcConfigStatus,
    integration,
    isLoading,
    isSuccess,
    ...restQueryResult,
  };
}
