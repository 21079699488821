import { DateTime } from 'luxon';

const iso8601ToFormat = (
  iso8601Date: string,
  options?: {
    format: string;
    fallback: string;
    timezone?: string;
    isUtc?: boolean;
  },
) => {
  const {
    format = 'MM/dd/yyyy',
    fallback = '–',
    timezone = null,
    isUtc = true,
  } = options || {};

  const baseTimeZoneDate = DateTime.fromISO(iso8601Date, {
    zone: isUtc ? 'utc' : null,
  });

  const formattedLocalDate = baseTimeZoneDate
    .setZone(timezone)
    .toFormat(format);

  const isInvalid =
    !iso8601Date ||
    // @ts-expect-error -- `@types/luxon` typedefs for v1.26 not accurate about
    // `luxonDate` shape at runtime here
    baseTimeZoneDate.invalid !== null ||
    formattedLocalDate?.toLowerCase() === 'invalid datetime';
  return isInvalid ? fallback : formattedLocalDate;
};

export { iso8601ToFormat };
