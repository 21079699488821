import { Button } from '@kandji-inc/bumblebee';
import Menu from 'features/self-service-new/menu';
import React from 'react';
import styled from 'styled-components';

import { i18n } from 'src/i18n';
import AddBackgroundItem from './background-items-add';

const RowContainer = styled.div`
  && {
    padding-bottom: 0;
    font-family: 'Atlas Grotesk Web', sans-serif;
  }
  .background-items__header,
  .background-items__row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    font-size: 14px;
    box-shadow: inset 0px -1px 0px rgba(26, 29, 37, 0.05);

    .background-items__header-item,
    .background-items__row-item {
      font-weight: 500;
      padding-left: 24px;
    }
  }

  .background-items__header {
    height: 44px;
    font-weight: 500;
    .background-items__header-comments {
      position: relative;

      .line {
        position: absolute;
        width: 1px;
        height: 24px;
        background: rgb(26 29 37 / 10%);
        left: -24px;
        top: -2px;
      }
    }
  }

  .background-items__row {
    height: 56px;
    .background-items__row-comment {
      margin-right: 24px;
      display: flex;
      justify-content: space-between;
    }
  }

  .background-items__add {
    display: flex;
    align-items: center;
    height: 68px;
    padding-left: 20px;
  }
`;

const BackgroundItemsList = (props) => {
  const { items, setModel, isDisabled } = props;
  return (
    <RowContainer>
      <div className="background-items__header">
        <div className="background-items__header-item">{i18n.t('Item')}</div>
        <div>{i18n.t('Identifier Type')}</div>
        <div className="background-items__header-comments">
          <div className="line" />
          {i18n.t('Comment')}
        </div>
      </div>
      {items.map((item, idx) => (
        <div key={item.identifier} className="background-items__row ">
          <div className="background-items__row-item">{item.identifier}</div>
          <div className="b-txt-light">{item.identifierType?.label}</div>
          <div className="background-items__row-comment b-txt-light">
            {item.comment ? item.comment : '--'}
            <Menu
              disabled={isDisabled}
              options={[
                {
                  name: i18n.t('Edit Item'),
                  icon: 'pencil',
                  onClick: () => {
                    setModel((prev) => ({
                      ...prev,
                      isModalOpen: true,
                      editItem: item,
                      itemIdx: idx,
                      isExisting: true,
                    }));
                  },
                },
                {
                  name: i18n.t('Delete Item'),
                  icon: 'trash-can',
                  theme: 'error',
                  onClick: () => {
                    setModel((prev) => ({
                      ...prev,
                      items: prev.items.filter(
                        (_, innerIdx) => innerIdx !== idx,
                      ),
                    }));
                  },
                },
              ]}
              placement="bottom-end"
            >
              <div className="b-ml-tiny li-library-list__category-button">
                <Button isDisabled={isDisabled} kind="link" icon="ellipsis" />
              </div>
            </Menu>
          </div>
        </div>
      ))}
      <div className="background-items__add">
        <AddBackgroundItem isDisabled={isDisabled} setModel={setModel} />
      </div>
    </RowContainer>
  );
};

export default BackgroundItemsList;
