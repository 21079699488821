import { EnvironmentContext } from 'contexts/environment';
import { useContext, useEffect } from 'react';
import { injectPendo } from '../config/pendo';

// TODO: add react-helmet and refactor to return Helmet elements
const Meta = () => {
  const { environment } = useContext(EnvironmentContext);
  useEffect(() => {
    if (environment) {
      document.title = `${document.title} ${environment}`;
    }
  }, [environment]);

  useEffect(() => {
    // production tracking
    if (!environment) {
      // hubspot
      const _hsq = (window._hsq = window._hsq || []);
      _hsq.push(['setPath', window.location.pathname]);
      _hsq.push(['trackPageView']);
    }
  }, [environment]);

  // only run on first render
  useEffect(() => {
    injectPendo();
  }, []);
  return null;
};

export default Meta;
