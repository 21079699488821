import { Button } from '@kandji-inc/bumblebee';
import { editBlueprint } from 'app/_actions/blueprint';
import trim from 'lodash/trim';
import trimStart from 'lodash/trimStart';
import React from 'react';
import styled from 'styled-components';
import AwesomeIconCircle from '../AwesomeIconCircle';
import { AwesomeIconEditor } from '../AwesomeIconEditor';
import PrettyInput from '../inputs/PrettyInput';
import { ModalWrapper } from './ModalWrapper';
import { StyledModalWrapper } from './StyledModalWrapper';
import { ButtonsWrapper, CancelButtonWrapper } from './elements';

const BodyStyled = styled.section`
  min-height: 500px;
  width: 438px;
  padding: 30px 53px 30px 51px;
`;

const CloseModalIcon = styled.i`
  position: absolute;
  right: 20px;
  top: 15px;
  cursor: pointer;
  font-size: 27px;
  line-height: 20px;
  z-index: 2052;
`;

const IconWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const InputWrapper = styled.section`
  width: 100%;
  padding-top: 24px;
`;

const TextAreaWrapper = styled.section`
  width: 100%;
  margin-top: 20px;
`;

const BackButtonWrapper = styled.section`
  position: absolute;
  left: 20px;
  top: 20px;
  cursor: pointer;
`;

const IconChooserBodyWrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 900px;
  height: 516px;
`;

export class BlueprintEdit extends ModalWrapper {
  constructor(props) {
    super(props);
    const { id, name, description, icon, color } = props.info;
    this.state = {
      id,
      name,
      description,
      iconClass: icon,
      color,
      initIconClass: icon,
      initColor: color,
      editingIcon: false,
    };
    this.onSave = this.onSave.bind(this);
  }

  onSave() {
    const { id, name, description, iconClass, color } = this.state;
    const {
      onHide,
      setSnackbar,
      blueprints,
      getBlueprints,
      startGetBlueprint,
    } = this.props;

    const editedName = trim(name);
    if (
      this.isDuplicatedName(
        blueprints.filter((el) => el.id !== id),
        editedName,
      )
    ) {
      setSnackbar('Blueprint name already exists');
      return null;
    }
    this.setState({ isLoading: true });
    editBlueprint({
      blueprintId: id,
      data: {
        name: editedName,
        description,
        icon: iconClass,
        color,
      },
    })
      .then(() => {
        this.setState({ isLoading: false });
        setSnackbar('Blueprint was Updated');
        onHide();
        getBlueprints();
        startGetBlueprint(id);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        setSnackbar(err.message);
      });
    return null;
  }

  renderBody() {
    const { name, description, isLoading, iconClass, color } = this.state;
    return (
      <BodyStyled>
        <IconWrapper>
          <AwesomeIconCircle
            editable
            color={color}
            icon={`ss-icon ${iconClass}`}
            size="125px"
            onClick={() => this.setState({ editingIcon: true })}
          />
        </IconWrapper>
        <InputWrapper>
          <PrettyInput
            value={name}
            label="Blueprint Name"
            placeholder="Blueprint Name"
            maxLength="100"
            onChange={(nameVal) => this.setState({ name: trimStart(nameVal) })}
          />
        </InputWrapper>
        <TextAreaWrapper>
          <PrettyInput
            value={description}
            label="Blueprint Description"
            placeholder="Blueprint Description"
            onChange={(desc) => this.setState({ description: trimStart(desc) })}
            type="textarea"
          />
        </TextAreaWrapper>

        <ButtonsWrapper>
          <CancelButtonWrapper>
            <Button kind="outline" onClick={this.onHide}>
              Cancel
            </Button>
          </CancelButtonWrapper>
          <Button disabled={isLoading || !name} onClick={this.onSave}>
            Save
          </Button>
        </ButtonsWrapper>
      </BodyStyled>
    );
  }

  renderIconChooser() {
    const { iconClass, initIconClass, initColor, color } = this.state;

    return (
      <IconChooserBodyWrapper>
        <BackButtonWrapper>
          <Button
            name="Back"
            icon="arrow-left"
            kind="link"
            onClick={() => this.setState({ editingIcon: false })}
          >
            Back
          </Button>
        </BackButtonWrapper>
        <AwesomeIconEditor
          color={color}
          onColorChange={(c) => this.setState({ color: c })}
          icon={iconClass}
          onIconChange={(i) => this.setState({ iconClass: i })}
          onRevertClick={() =>
            this.setState({ iconClass: initIconClass, color: initColor })
          }
        />
      </IconChooserBodyWrapper>
    );
  }

  render() {
    const { editingIcon } = this.state;
    const { onHide } = this.props;
    return (
      <StyledModalWrapper {...this.props} onHide={() => {}}>
        <CloseModalIcon className="far fa-times" onClick={onHide} />
        {editingIcon ? this.renderIconChooser() : this.renderBody()}
      </StyledModalWrapper>
    );
  }
}

export default BlueprintEdit;
