import { Banner, Button, modal as Modal } from '@kandji-inc/bumblebee';
import React, { useState, useEffect } from 'react';

type Props = {
  isOpen: boolean;
  onClose: (isSavingDuplicates: boolean) => void;
  duplicates: Array<{
    title: string;
    url: string;
  }>;
  update: any;
  skipBookmarksConflict: boolean;
};

const DuplicatesModal = (props: Props) => {
  const {
    isOpen,
    onClose,
    duplicates = [],
    update,
    skipBookmarksConflict,
  } = props;

  const [hasAccepted, setHasAccepted] = useState(false);

  /* istanbul ignore next */
  useEffect(() => {
    // We need this check before closing and retrying the save because the model update
    // sometimes (race condition) doesn't happen before the onSave runs
    if (skipBookmarksConflict && hasAccepted) {
      onClose(true);
      setHasAccepted(false);
    }
  }, [onClose, skipBookmarksConflict, hasAccepted]);

  const handleClose = (isSavingDuplicates: boolean) => {
    // Set the property so the backend knows whether to ignore the conflicts or not:
    update('skip_bookmarks_conflict', isSavingDuplicates);

    if (!isSavingDuplicates) {
      onClose(isSavingDuplicates);
    } else {
      setHasAccepted(true);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={/* istanbul ignore next */ () => onClose(false)}
      parts={{
        header: {
          text: 'Save Duplicate Bookmarks?',
        },
        content: {
          children: (
            <div>
              <Banner kind="block" theme="warning" className="b-mb">
                <p>You have duplicate bookmarks. Do you want to save them?</p>
              </Banner>
              <p>
                The following bookmark URLs are currently active in other
                assigned Blueprints.
              </p>
              <ul
                style={{
                  paddingLeft: 'var(--b-gap2)',
                }}
              >
                {duplicates.map((dupe) => (
                  <li
                    style={{
                      wordBreak: 'break-all',
                    }}
                    key={dupe.title}
                  >
                    <strong className="b-txt-bold">{dupe.title} — </strong>
                    <span className="b-txt-light">{dupe.url}</span>
                  </li>
                ))}
              </ul>
            </div>
          ),
        },
        footer: {
          children: (
            <div className="b-flex-g">
              <Button kind="outline" onClick={() => handleClose(false)}>
                No, Cancel
              </Button>
              <Button onClick={() => handleClose(true)}>
                Yes, Save Duplicates
              </Button>
            </div>
          ),
        },
      }}
    />
  );
};

export default DuplicatesModal;
