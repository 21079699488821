import { Button } from '@kandji-inc/bumblebee';
import type { ChangeEvent } from 'react';
import React from 'react';
import type { ParamsByType, SelectOptions } from './SearchParamsUiHelper.class';

type Props = {
  values: SelectOptions;
  clearValue: () => void;
  setIsOpen: (openOrClose: boolean) => void;
};

type HocParams = {
  onApply: (args: {
    values: Props['values'];
    setIsOpen: Props['setIsOpen'];
  }) => (e: ChangeEvent<HTMLButtonElement>) => ParamsByType;
  onClear: (args: {
    values: Props['values'];
    clearValue: Props['clearValue'];
    setIsOpen: Props['setIsOpen'];
  }) => (e: ChangeEvent<HTMLButtonElement>) => ParamsByType;
};

export default function MultiSelectApplyBottomControlHoc({
  onApply,
  onClear,
}: HocParams) {
  return ({ values, clearValue, setIsOpen }: Props) => {
    const onApplyClick = onApply({ values, setIsOpen });
    const onClearClick = onClear({ values, clearValue, setIsOpen });

    return (
      <div className="b-flex-btw">
        <Button size="small" kind="link" onClick={onApplyClick}>
          Apply
        </Button>

        <Button
          size="small"
          kind="link"
          className="b-mr-micro"
          onClick={onClearClick}
        >
          Clear
        </Button>
      </div>
    );
  };
}
