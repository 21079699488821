/* istanbul ignore file - legacy code, moved */
import HubSpotHandler from 'components/common/hubspot-handler';
import { InterfaceContext } from 'contexts/interface';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';

const sidebar = {
  width: {
    opened: '256px',
    closed: '77px',
  },
};

const fullViewWidthMinus = (width) => `calc(100vw - ${width})`;

export const IPAAnchor = ({
  element: Element,
  section,
  container,
  id,
  className,
  children,
  full,
  noHubSpotLogic,
}) => {
  const { sidebarDocked, sidebarOpened } = useContext(InterfaceContext);

  const styles = useMemo(() => {
    if (full) {
      return {};
    }

    return {
      marginLeft:
        sidebarDocked && sidebarOpened
          ? sidebar.width.opened
          : sidebar.width.closed,
      width:
        sidebarDocked && sidebarOpened
          ? fullViewWidthMinus(sidebar.width.opened)
          : fullViewWidthMinus(sidebar.width.closed),
    };
  }, [full, sidebarDocked && sidebarOpened]);

  return (
    <>
      {!noHubSpotLogic && <HubSpotHandler />}
      <Element
        id={id}
        className={`${section} block ${className || 'no-class'} theme-base`}
      >
        <div className="dock" style={styles} data-testid="anchor">
          <div className="case">
            <div className={container}>
              <div className="area">{children}</div>
            </div>
          </div>
        </div>
        <div className="zone">
          <div className="stub" />
        </div>
      </Element>
    </>
  );
};

IPAAnchor.propTypes = {
  element: PropTypes.string,
  section: PropTypes.string,
  container: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  full: PropTypes.bool,
  noHubSpotLogic: PropTypes.bool,
  children: PropTypes.node,
};

IPAAnchor.defaultProps = {
  element: 'footer',
  section: 'anchor',
  container: 'container-fluid',
  id: undefined,
  className: undefined,
  full: false,
  noHubSpotLogic: false,
  children: undefined,
};
