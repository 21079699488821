import { useEffect, useState } from 'react';
import { useMap } from 'react-mapkit';

/**
 * react-mapkit has a provider and a hook
 * I'm using its useMap hook to get the mapkit obj
 * I then instantiate two different objects, geo and coord
 * I call a method on geo of reverse lookup to get the address
 * I opt for the fomattedAddress, but the obj has other data as well
 */
export default function useAddress(props) {
  const { latitude, longitude } = props;

  const [address, setAddress] = useState('');
  const { mapkit } = useMap();

  useEffect(() => {
    if (mapkit && latitude && longitude) {
      const geo = new mapkit.Geocoder({
        language: 'en',
        getsUserLocation: false,
      });
      const coord = new mapkit.Coordinate(latitude, longitude);
      geo?.reverseLookup(coord, (err, data) => {
        const res = data?.results;
        if (Array.isArray(res)) {
          const obj = res[0];
          if (obj?.formattedAddress) {
            setAddress(obj.formattedAddress);
          }
        }
      });
    }
  }, [mapkit, latitude, longitude]);

  return address;
}
