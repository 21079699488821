import { theme } from 'app/common/constants';
import { cutLongText } from 'app/common/style-utils';
import { getModelID } from 'components/common/image-device/utilities';
import { i18n } from 'src/i18n';
import styled from 'styled-components';
import getDeviceImage from '../../../../components/common/image-device';
import { getTextStatusComputersColorClass } from '../../common/helpers';

const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  min-width: 230px;
  min-height: 255px;
  background-color: white;
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 0;
  box-shadow: 0 4px 3px 0 ${theme.colors['grey-300-transparent-300']};

  ${({ clickable }) =>
    clickable &&
    `
      cursor: pointer;
      transition: 0.3s cubic-bezier(0, 1, 0.5, 1), box-shadow 0.5s ease;
      &:hover {
        transform: translateY(-1px);
        box-shadow: 0 18px 35px rgba(50, 50, 93, 0.1),
          0 8px 15px rgba(0, 0, 0, 0.07);
      }
      &:active {
        background: $grey-50;
        transform: translateY(2px);
        box-shadow: 0 11px 20px rgba(50, 50, 93, 0.1),
          0 6px 11px rgba(0, 0, 0, 0.08);
      }
  `}
`;

const ImageAndIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-top: 12px;
  // max-width: 180px;
  // min-width: 130px;
  width: 100%;
  height: 85px;
  .status-icon {
    position: absolute;
    font-size: 32px;
    border: 3px solid ${theme.colors['grey-300']};
    border-radius: 50%;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  img {
    max-width: 100%;
    height: 100%;
  }
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const InfoWrapper = styled.div`
  width: 100%;
  padding-top: 10px;
  text-align: center;
  font-size: 14px;
`;

const Name = styled.div`
  ${cutLongText}
  text-align: center;
  padding-bottom: 10px;
  font-color: ${theme.colors['grey-999']};
  font-weight: 500;
`;

const Serial = styled.div`
  ${cutLongText}
`;

const OS = styled.div`
  ${cutLongText}
  font-weight: 500;
`;

const Model = styled.div`
  ${cutLongText}
  padding-bottom: 4px;
`;

const FullStatus = styled.div`
  ${cutLongText}
  font-weight: 700;
  letter-spacing: 0.1rem;
  padding-top: 4px;
  border-top: 1px solid ${theme.colors['grey-300']};
`;

const getFullStatus = (status) => {
  const mapping = {
    PASS: i18n.t('ALL CLEAR'),
    REMEDIATED: i18n.t('ALL CLEAR'),
    ERROR: i18n.t('ALERT'),
    WARNING: i18n.t('ALERT'),
    INCOMPATIBLE: i18n.t('INCOMPATIBLE'),
    EMPTY: i18n.t('NO HISTORY'),
    STOPPED: i18n.t('STOPPED'),
  };
  return mapping[status] || mapping.EMPTY;
};

const GSuiteUserComputerCard = ({ onComputerClick, clickable, computer }) => {
  const modelID = getModelID(computer);
  const fullStatus = getFullStatus(computer.status);
  const imageDevice = getDeviceImage(computer.device_family, modelID);
  const statusColorClass = getTextStatusComputersColorClass(computer.status);
  return (
    <Wrapper onClick={onComputerClick} clickable={clickable}>
      <Name>{computer.name}</Name>
      <ImageAndIconWrapper>
        <ImageWrapper>
          <img src={imageDevice} alt={i18n.t('device')} />
        </ImageWrapper>
      </ImageAndIconWrapper>
      <InfoWrapper>
        <Serial>{computer.serial_number}</Serial>
        <OS>{computer.info ? computer.info.system_version : null}</OS>
        <Model title={computer.model}>{computer.model}</Model>
        <FullStatus className={statusColorClass}>{fullStatus}</FullStatus>
      </InfoWrapper>
    </Wrapper>
  );
};

export default GSuiteUserComputerCard;
