const isoToDateString = (
  isoDate: string,
  timeZone?: string,
  withTime: boolean = false,
  options?: Intl.DateTimeFormatOptions,
) => {
  if (!isoDate) {
    return '';
  }

  // Behavoiral detection dates are in UTC, but file detection dates are in local time.
  // Some endpoint dates lack 'Z' suffix, causing browsers to interpret them as local time.
  const date = new Date(
    isoDate.endsWith('Z') || isoDate.endsWith('+00:00')
      ? isoDate
      : `${isoDate}Z`,
  );

  const formatOptions: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    ...options,
  };

  // istanbul ignore next
  if (timeZone) {
    formatOptions.timeZone = timeZone;
  }

  if (!withTime) {
    delete formatOptions.hour;
    delete formatOptions.minute;
  }

  return Intl.DateTimeFormat('en-US', formatOptions).format(date);
};

export default isoToDateString;
