import getDateRange from '../../../common/utils/getDateRange';
import getSortBy from '../../../common/utils/getSortBy';
import type {
  FiltersState,
  GetBehavioralThreatDetailsRequestParams,
  PaginationState,
  SortColumnState,
  ThreatClassification,
} from '../../../threat.types';

const toRequestFormat = (
  filters: FiltersState,
  sort: SortColumnState,
  pagination: PaginationState,
  deviceId?: string,
): GetBehavioralThreatDetailsRequestParams => {
  const limit = String(pagination.pageSize);
  const offset = String(((pagination.page || 1) - 1) * Number(limit));
  const classifications =
    filters.classification?.toLowerCase() as ThreatClassification;
  let statuses = (filters.status || undefined)
    ?.map((item) => item.toLowerCase())
    .join(',');

  if (statuses?.includes('blocked')) {
    statuses = `${statuses},blocked_parent`;
  }

  const newDeviceId = deviceId || '';
  const sortBy = getSortBy(sort);
  const { from, to } = getDateRange(filters.detectionDate);
  const search_term = filters.query || '';

  const filtersTransformedToApi: GetBehavioralThreatDetailsRequestParams = {
    limit,
    offset,
    sort_by: sortBy,
    device_id: newDeviceId,
    statuses,
    classifications,
    search_term,
    detection_from: from,
    detection_to: to,
  };

  return filtersTransformedToApi;
};

export default toRequestFormat;
