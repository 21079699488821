import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import { getIconName } from '../../components/PrismNav/prismNavUtils';
import {
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  getFilteredDisabled as filterDisabled,
  getCodeSnippetCell,
} from '../column-utils';
import { getScharedColumns } from './getSharedColumns';

export const getInstalledProfilesColumns = ({
  installedProfilesSchema,
}): {
  columnDefs: any[];
  pinnedColumns: string[];
  alwaysHiddenColumns: string[];
} => {
  const { properties } = installedProfilesSchema.schema;
  const installedProfilesColumnHelper =
    createColumnHelper<z.infer<typeof properties>>();

  const columnDefs = [
    installedProfilesColumnHelper.accessor(
      (row) => row[`${installedProfilesSchema.uri}.profile_display_name`],
      {
        id: `${installedProfilesSchema.uri}.profile_display_name`,
        meta: {
          displayName: properties.profile_display_name.title,
          displayIcon: getIconName(installedProfilesSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    installedProfilesColumnHelper.accessor(
      (row) => row[`${installedProfilesSchema.uri}.payload_identifier`],
      {
        id: `${installedProfilesSchema.uri}.payload_identifier`,
        meta: {
          displayName: properties.payload_identifier.title,
          displayIcon: getIconName(installedProfilesSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    installedProfilesColumnHelper.accessor(
      (row) => row[`${installedProfilesSchema.uri}.managed`],
      {
        id: `${installedProfilesSchema.uri}.managed`,
        cell: YesNoCell,
        meta: {
          displayName: properties.managed.title,
          displayIcon: getIconName(installedProfilesSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    installedProfilesColumnHelper.accessor(
      (row) => row[`${installedProfilesSchema.uri}.organization`],
      {
        id: `${installedProfilesSchema.uri}.organization`,
        meta: {
          displayName: properties.organization.title,
          displayIcon: getIconName(installedProfilesSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    installedProfilesColumnHelper.accessor(
      (row) => row[`${installedProfilesSchema.uri}.profile_data`],
      {
        id: `${installedProfilesSchema.uri}.profile_data`,
        cell: (info) => {
          const cellValue = info.getValue();
          const profileDataJSON = JSON.stringify(cellValue, null, 2);
          return getCodeSnippetCell(info, profileDataJSON);
        },
        meta: {
          displayName: properties.profile_data.title,
          displayIcon: getIconName(installedProfilesSchema.uri),
        },
      },
    ),

    installedProfilesColumnHelper.accessor(
      (row) => row[`${installedProfilesSchema.uri}.payload_uuid`],
      {
        id: `${installedProfilesSchema.uri}.payload_uuid`,
        meta: {
          displayName: properties.payload_uuid.title,
          displayIcon: getIconName(installedProfilesSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
          defaultHidden: true,
        },
      },
    ),

    installedProfilesColumnHelper.accessor(
      (row) => row[`${installedProfilesSchema.uri}.removal_passcode`],
      {
        id: `${installedProfilesSchema.uri}.removal_passcode`,
        cell: YesNoCell,
        meta: {
          displayName: properties.removal_passcode.title,
          displayIcon: getIconName(installedProfilesSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
          defaultHidden: true,
        },
      },
    ),

    installedProfilesColumnHelper.accessor(
      (row) => row[`${installedProfilesSchema.uri}.encrypted`],
      {
        id: `${installedProfilesSchema.uri}.encrypted`,
        cell: YesNoCell,
        meta: {
          displayName: properties.encrypted.title,
          displayIcon: getIconName(installedProfilesSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
          defaultHidden: true,
        },
      },
    ),

    installedProfilesColumnHelper.accessor(
      (row) => row[`${installedProfilesSchema.uri}.profile_description`],
      {
        id: `${installedProfilesSchema.uri}.profile_description`,
        meta: {
          defaultHidden: true,
          displayName: properties.profile_description.title,
          displayIcon: getIconName(installedProfilesSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    installedProfilesColumnHelper.accessor(
      (row) => row[`${installedProfilesSchema.uri}.removable`],
      {
        id: `${installedProfilesSchema.uri}.removable`,
        cell: YesNoCell,
        meta: {
          displayName: properties.removable.title,
          displayIcon: getIconName(installedProfilesSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
          defaultHidden: true,
        },
      },
    ),

    installedProfilesColumnHelper.accessor(
      (row) => row[`${installedProfilesSchema.uri}.signed`],
      {
        id: `${installedProfilesSchema.uri}.signed`,
        cell: YesNoCell,
        meta: {
          defaultHidden: true,
          displayName: properties.signed.title,
          displayIcon: getIconName(installedProfilesSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    ...getScharedColumns(installedProfilesSchema),
  ];

  return { columnDefs, pinnedColumns: [], alwaysHiddenColumns: [] };
};
