import { Icon, setClass } from '@kandji-inc/bumblebee';
import React, { useState } from 'react';

import { i18n } from 'src/i18n';
import reorderIcon from './assets/reorder-icon.svg';
import './link-preview.css';

const LinkPreview = (props) => {
  const {
    isDarkMode,
    style,
    className,
    isEditing,
    removeLink,
    link,
    onClick,
    selected,
    isInvalid,
  } = props;
  const { icon, iconUrl, title, helperText, buttonText } = link;
  const [isHover, setIsHover] = useState(false);

  const isLinkActive = selected.uuid === link.uuid;

  return (
    <>
      <div
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        style={style}
        onClick={onClick}
        className={setClass([
          'k-ks-link-preview',
          isDarkMode && 'k-ks-link-preview--dark',
          className,
          isEditing && 'k-ks-link-preview--editing',
          isInvalid && isEditing && 'k-ks-link-preview--invalid',
          isEditing && link.type === 'generic' ? 'k-ks-rocket-link' : '',
        ])}
      >
        {isEditing ? (
          <div className="k-ks-link-preview__actions">
            <img
              className="k-ks-link-preview__reorder"
              src={reorderIcon}
              alt=""
            />
            <a
              href=""
              className="k-ks-link-preview__delete"
              onClick={(e) => removeLink(e, link)}
            >
              {isHover || isLinkActive ? <Icon name="trash-can" /> : null}
            </a>
          </div>
        ) : null}
        <img src={iconUrl || icon.dataUrl} alt="" />
        {title ? (
          <h3>{title}</h3>
        ) : (
          <h3 style={{ color: 'var(--color-neutral-50)' }}>
            {i18n.t(`Title`)}
          </h3>
        )}
        {helperText ? (
          <p>{helperText}</p>
        ) : (
          <p>
            {i18n.t(
              `Add a short description that will be helpful for your end users.`,
            )}
          </p>
        )}
        <span className="b-alink">
          <span>{buttonText || i18n.t('Action')}</span>
        </span>
      </div>
    </>
  );
};

export default LinkPreview;
