import {
  ScrollAreaPrimitive,
  SelectV2Primitives as Select,
  styled,
} from '@kandji-inc/nectar-ui';

export const ValueContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
});

export const TriggerContainer = styled('div', ValueContainer, {
  width: '80%',
  maxWidth: '80%',
});

export const StyledContent = styled(Select.Content, {
  width: 'var(--radix-select-trigger-width)',
  margin: '4px 0',
  maxHeight: 320,
  zIndex: 9999,
});

export const StyledTrigger = styled(Select.Trigger, {
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-start',
});

export const StyledIcon = styled(Select.Icon, {
  marginLeft: 'auto',
});

export const SearchContainer = styled('div', {
  padding: '6px 12px',
});

export const ValueName = styled('span', {
  width: 'calc(var(--radix-select-trigger-width) - 64px)',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

export const TriggerValue = styled('span', ValueName, {
  maxWidth: 'calc(100% - 32px)',
});

export const NotAssigned = styled('span', {
  fontStyle: 'italic',
  color: '$neutral60',
});

export const SelectItemsContainer = styled('div', {
  maxHeight: 320,
  overflow: 'auto',
});

const StyledScrollAreaRoot = styled(ScrollAreaPrimitive.Root, {
  width: '100%',
  height: '100%',
});

const StyledScrollAreaViewport = styled(
  ScrollAreaPrimitive.Viewport,
  StyledScrollAreaRoot,
);

const StyledScrollAreaScrollbar = styled(ScrollAreaPrimitive.Scrollbar, {
  width: 4,
  padding: '5px 2px',
});

const StyledScrollAreaThumb = styled(ScrollAreaPrimitive.Thumb, {
  background: 'rgba(0, 0, 0, 0.3)',
  borderRadius: 3,
});

export const ScrollArea = {
  Root: StyledScrollAreaRoot,
  Viewport: StyledScrollAreaViewport,
  Scrollbar: StyledScrollAreaScrollbar,
  Thumb: StyledScrollAreaThumb,
};
