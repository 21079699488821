import { TextInput, Toggle, useInputsValidators } from '@kandji-inc/bumblebee';
import React, { useEffect } from 'react';

import { i18n } from 'src/i18n';
import { useCustomOrDefaultValue } from '../hooks';

const Information = (props) => {
  const { update, setting, needReset, triggerValidate } = props;
  const { installBtnText } = setting;

  const [isDefaultText, setIsDefaultText] = useCustomOrDefaultValue(
    'Install',
    installBtnText,
    (v) => update('installBtnText', v),
    needReset,
  );

  const fieldsToValidate = ['btnText'];
  const { refs, onInvalidate } = useInputsValidators(fieldsToValidate, update);
  useEffect(() => {
    onInvalidate(0)(!isDefaultText && !installBtnText);
  }, [isDefaultText]);

  return (
    <div className="k-ss2-drawer-section --info">
      <h3 className="b-h3">{i18n.t('Button')}</h3>
      <div className="k-ss2-drawer-setting">
        <div className="k-ss2-drawer-setting-with-toggle">
          <div>
            <p className="b-txt">{i18n.t('Customize button text')}</p>
            <p className="b-txt-light">
              {i18n.t('Override the default button text for installation.')}
            </p>
          </div>
          <div>
            <Toggle
              checked={!isDefaultText}
              onToggle={() => setIsDefaultText(!isDefaultText)}
            />
          </div>
        </div>
        {!isDefaultText && (
          <div ref={refs[0]} className="k-ss2-drawer-setting-extra">
            <TextInput
              placeholder={i18n.t('Install')}
              maxLength={12}
              fieldsGrid="k-ss2-input-grid"
              value={installBtnText}
              onChange={(e) => update('installBtnText', e.target.value)}
              validator={(v) => [
                {
                  message: i18n.t('Add a custom text or turn the toggle off.'),
                  invalid: () => !isDefaultText && !v?.length,
                  trigger: ['onBlur', triggerValidate],
                },
              ]}
              onInvalidate={onInvalidate(0)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Information;
