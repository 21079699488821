import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import configs from '../../../library/library-item-configurations/items/profile';
import SystemPreferencesService from './system-preferences-service';

function transformSettings(settings) {
  return {
    preVentura: {
      isActive: settings.DisabledPreferencePanes
        ? true
        : Boolean(settings?.preVenturaActive),
      DisabledPreferencePanes: SystemPreferencesService.serializeGeneralOptions(
        settings.DisabledPreferencePanes
          ? settings.DisabledPreferencePanes
          : settings?.preVentura?.DisabledPreferencePanes || [],
      ),
    },
    postVentura: {
      isActive: Boolean(settings?.postVenturaActive),
      DisabledPreferencePanes: SystemPreferencesService.serializeGeneralOptions(
        settings?.postVentura?.DisabledSystemSettings || [],
        true,
      ),
    },
  };
}

function transformFromNewApi(apiData, preset) {
  const result = {
    ...preset,
    isActive: apiData.active,
    ...transformSettings(apiData.data),
  };

  return result;
}

async function transformFromApi(apiData) {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const commonData = {
    id: apiData.id,
    name: apiData.name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
    rules: apiData.rules,
  };

  const result = transformFromNewApi(apiData, commonData);

  return {
    ...apiData,
    data: result,
  };
}

function transformToApi(model) {
  const toSend = {
    name: model.name,
    active: model.isActive,
    blueprints: model.selectedBlueprints.map((b) => b.value),
    is_all_blueprints: model.isAllBlueprints,
    rules: model.rules || null,
    data: {
      preVenturaActive: model.preVentura.isActive,
      preVentura: {
        DisabledPreferencePanes:
          SystemPreferencesService.deserializeGeneralOptions(
            model.preVentura.DisabledPreferencePanes,
          ),
      },
      postVenturaActive: model.postVentura.isActive,
      postVentura: {
        DisabledSystemSettings:
          SystemPreferencesService.deserializeGeneralOptions(
            model.postVentura.DisabledPreferencePanes,
          ),
      },
    },
  };

  if (model.skip_blueprint_conflict) {
    toSend.skip_blueprint_conflict = model.skip_blueprint_conflict;
  }

  // Add necessary properties when creating new Profile:
  if (!model.id) {
    const itemConfig = configs['System Preferences Panes'];

    toSend.type = itemConfig.type;
    toSend.identifier = itemConfig.identifier;
  }

  return toSend;
}

export { transformToApi, transformFromApi };
