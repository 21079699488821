import { bool, node, object, oneOf, string } from 'prop-types';
import React, { useState, useRef } from 'react';
import useOutsideClick from '../hooks/use-outside-click';

const positionStyle = {
  bottom: { top: '50px' },
  left: { right: '50px' },
  right: { left: '50px' },
  top: { bottom: '50px' },
};

const Menu = ({
  className,
  menuClassName,
  style,
  menuStyle,
  children,
  position,
  isDisabled,
}) => {
  const triggerRef = useRef();
  const menuRef = useRef();
  const [isShowing, setIsShowing] = useState(false);

  useOutsideClick(triggerRef, (e) => {
    if (!(e.path || e.composedPath()).includes(menuRef.current)) {
      setIsShowing(false);
    }
  });

  const arrChildren = React.Children.toArray(children);
  const options = arrChildren[0];
  const trigger = arrChildren[1];
  return (
    <div className={className} style={{ ...style, position: 'relative' }}>
      {trigger &&
        React.cloneElement(trigger, {
          ...trigger.props,
          onClick: (e) => {
            if (!isDisabled) {
              setIsShowing(true);
            }
            if (trigger.props.onClick) {
              trigger.props.onClick(e);
            }
          },
          ref: triggerRef,
        })}
      {isShowing && (
        <div
          ref={menuRef}
          className={`b-menu ${menuClassName || ''}`}
          style={{
            ...menuStyle,
            position: 'absolute',
            ...positionStyle[position],
            zIndex: 10,
          }}
        >
          {React.Children.map(
            options.props.children,
            (child) =>
              child &&
              React.cloneElement(child, {
                ...child.props,
                onClick: (e) => {
                  if (child.props.onClick) {
                    child.props.onClick(e);
                  }
                  setIsShowing(false);
                },
                className:
                  child.props.className || 'b-btn-action-link btn-icon b-txt',
              }),
          )}
        </div>
      )}
    </div>
  );
};

Menu.propTypes = {
  className: string,
  menuClassName: string,
  style: object,
  menuStyle: object,
  children: node,
  position: oneOf(['top', 'right', 'bottom', 'left']),
  isDisabled: bool,
};

Menu.defaultProps = {
  className: '',
  style: {},
  menuClassName: '',
  menuStyle: {},
  children: <></>,
  position: 'bottom',
  isDisabled: false,
};
export default Menu;
