const initialState = {
  id: '',
  name: '',
  isActive: true,
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  message: {
    checked: false,
    value: '',
  },
  supportsRules: true,
};

export default initialState;
