import React from 'react';

export const Ellipsis = () => (
  <svg
    width="14"
    height="4"
    viewBox="0 0 14 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 2.32397C10.5 1.51147 11.1562 0.823975 12 0.823975C12.8125 0.823975 13.5 1.51147 13.5 2.32397C13.5 3.16772 12.8125 3.82397 12 3.82397C11.1562 3.82397 10.5 3.16772 10.5 2.32397ZM5.5 2.32397C5.5 1.51147 6.15625 0.823975 7 0.823975C7.8125 0.823975 8.5 1.51147 8.5 2.32397C8.5 3.16772 7.8125 3.82397 7 3.82397C6.15625 3.82397 5.5 3.16772 5.5 2.32397ZM3.5 2.32397C3.5 3.16772 2.8125 3.82397 2 3.82397C1.15625 3.82397 0.5 3.16772 0.5 2.32397C0.5 1.51147 1.15625 0.823975 2 0.823975C2.8125 0.823975 3.5 1.51147 3.5 2.32397Z"
      fill="#1A1D25"
      fillOpacity="0.6"
    />
  </svg>
);
