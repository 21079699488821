// istanbul ignore file
export const ThumbsUpIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <rect width="28" height="28" rx="4" fill="white" fillOpacity="0.01" />
    <path
      d="M9.83334 12.3096H8.16668C7.70652 12.3096 7.33334 12.6828 7.33334 13.1195V18.9294C7.33334 19.3896 7.70652 19.7393 8.16668 19.7393H9.83334C10.2935 19.7393 10.6667 19.3662 10.6667 18.9294V13.143C10.6667 12.7055 10.2943 12.3096 9.83334 12.3096ZM20.6667 13.2445C20.6667 12.2839 19.8841 11.5018 18.9227 11.5018H16.3221C16.5703 10.8513 16.7083 10.2888 16.7083 9.95808C16.7083 9.07787 16.0261 8.16693 14.8839 8.16693C13.6701 8.16693 13.338 9.01172 13.0711 9.69037C12.2466 11.7867 11.5 11.4193 11.5 12.1245C11.5 12.4589 11.7698 12.7492 12.1255 12.7492C12.2624 12.7492 12.4003 12.7044 12.5154 12.6122C14.5143 11.0141 14.025 9.41693 14.8839 9.41693C15.3026 9.41693 15.4583 9.74896 15.4583 9.95834C15.4583 10.1511 15.2606 10.9883 14.793 11.8227C14.7397 11.9176 14.7131 12.0228 14.7131 12.1279C14.7131 12.4904 15.0108 12.7294 15.3381 12.7294H18.9215C19.1953 12.7523 19.4167 12.9737 19.4167 13.2445C19.4167 13.5 19.2144 13.7141 18.9565 13.7318C18.6268 13.7542 18.3742 14.0287 18.3742 14.3552C18.3742 14.7581 18.6708 14.7706 18.6708 15.1081C18.6708 15.7688 17.7586 15.4294 17.7586 16.2057C17.7586 16.4979 17.9251 16.5451 17.9251 16.7852C17.9251 17.3753 17.1498 17.1435 17.1498 17.8435C17.1498 17.9611 17.1787 17.9987 17.1787 18.0897C17.1787 18.3619 16.9569 18.5832 16.6847 18.5832H15.3167C14.6495 18.5832 13.9881 18.3628 13.4548 17.9632L12.5001 17.2468C12.3877 17.1623 12.2561 17.1215 12.1256 17.1215C11.7649 17.1215 11.4998 17.4178 11.4998 17.7475C11.4998 17.9373 11.5861 18.1231 11.7497 18.2457L12.7046 18.9624C13.4531 19.5232 14.3802 19.8331 15.3177 19.8331H16.6857C17.6057 19.8331 18.3617 19.1172 18.4253 18.2133C18.888 17.8948 19.1761 17.3667 19.1761 16.7854C19.1761 16.7061 19.1704 16.6264 19.1594 16.5479C19.624 16.2315 19.9219 15.6994 19.9219 15.1083C19.9219 14.97 19.9052 14.8338 19.873 14.701C20.3516 14.3669 20.6667 13.8487 20.6667 13.2445Z"
      fill="#505E71"
    />
  </svg>
);

export const ThumbsDownIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <rect width="28" height="28" rx="4" fill="white" fillOpacity="0.01" />
    <path
      d="M10.6667 14.833V9.00044C10.6667 8.54028 10.2935 8.19054 9.83334 8.19054H8.16668C7.70652 8.19054 7.33334 8.56372 7.33334 9.00044V14.8103C7.33334 15.2705 7.70652 15.6202 8.16668 15.6202H9.83334C10.2943 15.6663 10.6667 15.2939 10.6667 14.833ZM19.8724 13.2991C19.9045 13.1665 19.9212 13.0304 19.9212 12.8918C19.9212 12.3007 19.6233 11.7687 19.1587 11.4523C19.1697 11.3737 19.1754 11.294 19.1754 11.2147C19.1754 10.6335 18.8874 10.1053 18.4246 9.78684C18.3611 8.88294 17.6051 8.16705 16.6851 8.16705H15.3171C14.3811 8.16705 13.4538 8.47617 12.7051 9.03711L11.75 9.75304C11.5863 9.8757 11.5001 10.0616 11.5001 10.2512C11.5001 10.5835 11.7681 10.8773 12.1259 10.8773C12.2564 10.8773 12.3881 10.8365 12.5004 10.752L13.4551 10.0356C13.9884 9.63609 14.6499 9.41552 15.317 9.41552H16.685C16.9571 9.41552 17.179 9.63679 17.179 9.90901C17.179 10.0002 17.1501 10.0377 17.1501 10.1553C17.1501 10.8576 17.9254 10.6217 17.9254 11.2136C17.9254 11.4546 17.7589 11.499 17.7589 11.793C17.7589 12.1568 18.0415 12.3646 18.2688 12.4073C18.502 12.4509 18.6712 12.6542 18.6712 12.8907C18.6712 13.2303 18.3745 13.2389 18.3745 13.6435C18.3745 13.9701 18.6272 14.2446 18.9568 14.267C19.2136 14.2627 19.4167 14.4997 19.4167 14.7314C19.4167 15.003 19.1949 15.2244 18.9227 15.2244H15.3386C15.0146 15.2244 14.7136 15.4853 14.7136 15.826C14.7136 15.9311 14.7401 16.0363 14.7934 16.1312C15.2604 17.0127 15.4583 17.8486 15.4583 18.0413C15.4583 18.2508 15.3025 18.5827 14.8839 18.5827C14.5576 18.5827 14.5122 18.5591 14.2344 17.8517C13.5899 16.2135 12.6266 15.2502 12.1255 15.2502C11.7662 15.2502 11.5 15.5437 11.5 15.8749C11.5 16.0583 11.5803 16.24 11.7348 16.3635C13.5339 17.8017 12.8386 19.833 14.8828 19.833C16.0261 19.833 16.7083 18.9215 16.7083 18.0413C16.7083 17.7106 16.5696 17.1244 16.3221 16.4963H18.9227C19.862 16.4971 20.6667 15.7158 20.6667 14.7314C20.6667 14.1507 20.3516 13.609 19.8724 13.2991Z"
      fill="#505E71"
    />
  </svg>
);
