import { createReducer } from 'redux-create-reducer';
import { params } from '../_actions/action-types';

const initialState = {
  tabsIsLoaded: false,
  isScroll: false,
  tags: [],
};

export default createReducer(initialState, {
  [params.SET_TABS_STATUS](state, action) {
    return { ...state, tabsIsLoaded: action.tabsIsLoaded };
  },
  [params.SET_TABS_SCROLL](state, action) {
    return { ...state, isScroll: action.isScroll };
  },
  [params.TAGS_FETCHED](state, action) {
    return {
      ...state,
      tags: action.payload,
    };
  },
});
