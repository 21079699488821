import { getComputersForFilters } from 'app/_actions/computer';
import { colors, filters, operators } from 'app/common/constants';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, FieldArray, blur, change, reduxForm, reset } from 'redux-form';
import styled from 'styled-components';
import {
  getFiltersFromUrl,
  parseDateFromFilter,
  setFiltersToUrl,
} from '../common/helpers';
import AwesomeSelect from './AwesomeSelect';
import CircleButton from './buttons/CircleButton';

const classNames = require('classnames');

const WrapperFilters = styled.section`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  background-color: var(--color-neutral-20);
  border-bottom: 1px solid ${colors['grey-250']};
  border-top: 1px solid ${colors['grey-250']};
  padding: 10px 3rem 10px 50px;
  margin-right: -3rem;
  margin-left: -50px;
  > * {
    margin: 0 10px 10px 0;
  }
`;

const FilterGroupWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  ${(props) => (props.big ? 'width: calc(100% - 80px)' : '')}
`;

const FilterGroup = styled.section`
  display: flex;
  flex-flow: nowrap row;
  align-items: center;
  background-color: ${colors.white};
  border: 1px solid ${colors['grey-230']};
  border-radius: 5px;
  height: ${(props) => (props.big ? '57px' : '35px')};
  > * {
    border-right: 1px solid ${colors['grey-230']};
  }
  ${(props) =>
    !props.big ? 'box-shadow: 0px 15px 30px -5px rgba(0, 0, 0, 0.2)' : ''};
`;

const ExistFilterGroup = styled.section`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background-color: ${colors['marengo-120']};
  min-height: 35px;
  font-family: var(--font-family-primary);
  font-size: 12px;
  color: ${colors['grey-999']};
  border-radius: 5px;
  cursor: pointer;
`;

const ExistFilterName = styled.section`
  padding: 0 5px 0 15px;
  white-space: nowrap;
`;

const ExistFilterOperator = styled.section`
  font-weight: 600;
  white-space: nowrap;
`;

const ExistFilterValue = styled.section`
  display: flex;
  flex-flow: row wrap;
  padding: 0 10px 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  div {
    padding-left: 2px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 5px;
    }
  }
`;

const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: ${(props) => (props.isCheckIcon ? '14px' : '16px')};
  ${(props) => (props.big ? '' : 'border: none')};
  height: ${(props) => (props.big ? '55px' : '35px')}!important;
  width: ${(props) => (props.big ? '50px' : '35px')}!important;
  padding: ${(props) => (props.big ? '0 17px' : '0 12px')};
  color: ${(props) => (props.big ? colors['marengo-700'] : colors.white)};
  background-color: ${(props) => props.bColor};
  text-align: center;
  &:last-of-type {
    border-right: none;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }
`;

const ActionButtonFlex = styled(ActionButton)`
  height: initial !important;
  align-self: normal;
`;

const StyledRenderInput = styled.input`
  outline: none;
  border: none;
  border-right: 1px solid ${colors['grey-230']};
  height: ${(props) => (props.big ? '55px' : '35px')};
  padding: 0 15px;
  font-family: var(--font-family-primary);
  font-size: ${(props) => (props.big ? '14px' : '12px')};
  color: ${colors['grey-999']};
`;

const blankOperators = ['isBlank', 'isCurrent', 'isNotCurrent'];

const isDateRangeValid = (dates) => {
  if (dates && dates.toString().indexOf('@') > -1) {
    const splittedValues = dates.split('@');
    return (
      splittedValues.length === 2 &&
      !splittedValues.includes('') &&
      parseDateFromFilter(splittedValues[0]) &&
      parseDateFromFilter(splittedValues[1])
    );
  }
  return false;
};

const dateRangeInput = ({ input, type, currentForm, index, big }) => {
  let fromDateRef;
  let toDateRef;
  let from = '';
  let to = '';
  if (input.value && input.value.toString().indexOf('@') > -1) {
    const splittedValues = input.value.split('@');
    from = splittedValues[0];
    to = splittedValues[1];
  }

  const changeReduxFormValue = (value, flag) => {
    const reduxValue = currentForm.values.filters[index].value;
    let splittedValues;
    if (reduxValue && reduxValue.toString().indexOf('@') > -1) {
      splittedValues = reduxValue.split('@');
      if (flag === 'from') {
        input.onChange(`${value}@${splittedValues[1]}`);
      } else if (flag === 'to') {
        input.onChange(`${splittedValues[0]}@${value}`);
      }
    } else {
      if (flag === 'from') {
        input.onChange(`${value}@`);
      }
      if (flag === 'to') {
        input.onChange(`@${value}`);
      }
    }
  };

  return (
    <div className="d-flex">
      <StyledRenderInput
        ref={(node) => (fromDateRef = node)}
        defaultValue={from || ''}
        placeholder="8/20/17, 7 days ago"
        autoComplete="off"
        onChange={(e) => {
          changeReduxFormValue(e.target.value, 'from');
          toDateRef.style = `border: none; border-right: 1px solid ${colors['grey-230']}`;
          fromDateRef.style = `border: none; border-right: 1px solid ${colors['grey-230']}`;
          const fromDate = parseDateFromFilter(fromDateRef.value);
          const toDate = parseDateFromFilter(toDateRef.value);
          if (!toDate) {
            toDateRef.style = 'border: 1px solid red';
          }
          if (!fromDate) {
            fromDateRef.style = 'border: 1px solid red';
          }
          if (fromDate && toDate) {
            if (isDateRangeValid(e.target.value)) {
              toDateRef.style = `border: none; border-right: 1px solid ${colors['grey-230']}`;
              fromDateRef.style = `border: none; border-right: 1px solid ${colors['grey-230']}`;
            } else {
              fromDateRef.style = 'border: 1px solid red';
              toDateRef.style = 'border: 1px solid red';
            }
          }
        }}
        big={big}
      />

      <StyledRenderInput
        ref={(node) => (toDateRef = node)}
        defaultValue={to || ''}
        placeholder="8/20/17, 7 days ago"
        type={type}
        autoComplete="off"
        onChange={(e) => {
          changeReduxFormValue(e.target.value, 'to');
          toDateRef.style = `border: none; border-right: 1px solid ${colors['grey-230']}`;
          fromDateRef.style = `border: none; border-right: 1px solid ${colors['grey-230']}`;
          const fromDate = parseDateFromFilter(fromDateRef.value);
          const toDate = parseDateFromFilter(toDateRef.value);
          if (!toDate) {
            toDateRef.style = 'border: 1px solid red';
          }
          if (!fromDate) {
            fromDateRef.style = 'border: 1px solid red';
          }
          if (fromDate && toDate) {
            if (isDateRangeValid(e.target.value)) {
              toDateRef.style = `border: none; border-right: 1px solid ${colors['grey-230']}`;
              fromDateRef.style = `border: none; border-right: 1px solid ${colors['grey-230']}`;
            }
          }
        }}
        big={big}
      />
    </div>
  );
};

const dateInput = ({ input, big }) => {
  let dateRef;
  return (
    <StyledRenderInput
      {...input}
      value={!(input.value.indexOf('@') > -1) ? input.value : ''}
      placeholder="8/20/17, 7 days ago"
      autoComplete="off"
      ref={(node) => (dateRef = node)}
      big={big}
      onChange={(e) => {
        input.onChange(e);
        const date = parseDateFromFilter(e.target.value);
        if (!date) {
          dateRef.style = 'border: 1px solid red';
        } else {
          dateRef.style = `border: none; border-right: 1px solid ${colors['grey-230']}`;
        }
      }}
    />
  );
};

const renderSelect = ({
  input,
  multi,
  disabled,
  options,
  index,
  isOperatorSelect,
  change,
  noSelectText,
  selectState,
}) => {
  if (input.name.indexOf('.name') > 0) {
    input.value = filters
      .filter(({ isHidden }) => !isHidden)
      .find((f) => f.value === input.value);
  }
  const shouldChooseDefaultValue =
    isOperatorSelect && options && options[0] && !input.value;
  if (shouldChooseDefaultValue) {
    change(input.name, options[0].value);
  }
  return (
    <AwesomeSelect
      value={get(input, 'value.value', input.value)}
      options={options}
      lightBody
      multi={multi}
      noSelectText={noSelectText}
      disabled={disabled}
      variant={selectState === 'edit' ? 'filter' : 'filterBig'}
      onChange={(e) => {
        if (isOperatorSelect && blankOperators.includes(e.value)) {
          change(`filters[${index}].value`, null);
        }
        input.onChange(e.value);
      }}
    />
  );
};

const mapStateToPropsRenderSelect = (state) => ({
  feature_configuration: state.account?.company?.feature_configuration,
});
const mapDispatchToPropsRenderSelect = (dispatch) =>
  bindActionCreators({}, dispatch);

const RenderSelect = connect(
  mapStateToPropsRenderSelect,
  mapDispatchToPropsRenderSelect,
)(renderSelect);

const renderInput = ({ input, big }) => (
  <StyledRenderInput
    {...input}
    value={
      /* istanbul ignore next -- falsely picked up */
      typeof input.value === 'string' ? input.value : ''
    }
    autoComplete="off"
    big={big}
  />
);

const getFilterValues = (
  filterName,
  blueprintNames,
  blueprintId,
  allComputers,
) => {
  if (filterName === undefined || filterName === null) {
    return [];
  }
  const computers = blueprintId
    ? allComputers.filter((comp) => comp.blueprint_id === blueprintId)
    : allComputers;
  switch (filterName) {
    case undefined:
      return [];
    case 'blueprint': {
      const options = [];
      Object.entries(blueprintNames).map((blueprint) => {
        options.push({ value: blueprint[0], label: blueprint[1] });
      });
      return options;
    }
    case 'status':
      return [
        { value: 'PASS,REMEDIATED', label: 'All Clear' },
        { value: 'WARNING,ERROR', label: 'Alert' },
        { value: 'EMPTY', label: 'No History' },
        { value: 'DEFERRED', label: 'Awaiting First Run' },
        { value: 'MISSING', label: 'Offline' },
        { value: 'DUPLICATE', label: 'Duplicates Found' },
      ];
    case 'computerName':
      return computers
        .filter((computer) => computer.name)
        .map((computer) => {
          if (computer.name) {
            return { value: computer.name, label: computer.name };
          }
        });
    case 'model': {
      const models = computers
        .filter((computer) => computer.model)
        .map((computer) => ({ value: computer.model, label: computer.model }));
      return uniqBy(models, 'value');
    }
    case 'serial':
      return computers.map((computer) => ({
        value: computer.serial_number,
        label: computer.serial_number,
      }));
    case 'os': {
      const osList = computers
        .filter((computer) => computer.OS && computer.OS !== 'No info')
        .sort((computer, nextComputer) => {
          if (computer.OS < nextComputer.OS) {
            return -1;
          }
          if (computer.OS > nextComputer.OS) {
            return 1;
          }
          return 0;
        })
        .map((computer) => ({ value: computer.OS, label: computer.OS }));
      return uniqBy(osList, 'value');
    }
    case 'assetUser': {
      const users = computers
        .filter((computer) => computer.assetUser && computer.assetUser !== null)
        .map((computer) => ({
          value: computer.assetUser,
          label: computer.assetUser,
        }));
      return uniqBy(users, 'value');
    }
    case 'assetTag': {
      const ids = computers
        .filter(
          (computer) => computer.assetTag && computer.assetTag !== 'No info',
        )
        .map((computer) => ({
          value: computer.assetTag,
          label: computer.assetTag,
        }));
      return uniqBy(ids, 'value');
    }
    case 'agentVersion': {
      const agentVersions = computers
        .filter(
          (computer) =>
            computer.agent_version && computer.agent_version !== 'No info',
        )
        .map((computer) => ({
          value: computer.agentVersion,
          label: computer.agentVersion,
        }));
      return uniqBy(agentVersions, 'value');
    }
  }
};

const getOperators = (filterName) => {
  if (!filterName) {
    return [];
  }

  const filter = filters
    .filter(({ isHidden }) => !isHidden)
    .find((filter) => filter.value === filterName);
  if (filter) {
    return filter.operators.map((operator) => {
      const currentOperator = operators.find((elem) => elem.value === operator);
      return { value: currentOperator.value, label: currentOperator.label };
    });
  }
};

const getAvailableFilters = (filters, blueprintId) => {
  if (blueprintId) {
    return filters.filter((elem) => elem.value !== 'blueprint');
  }
  return filters;
};

const getFilterValueType = (operator) => {
  if (!operator) {
    return 'select';
  }
  return operators.find((elem) => elem.value === operator).type;
};

const renderFilters = ({
  fields,
  currentForm,
  blueprintNames,
  allComputers,
  change,
  blur,
  blueprintId,
  initialize,
  reset,
}) => {
  if (!fields.getAll()) {
    return null;
  }
  if (!currentForm) {
    return null;
  }

  return (
    <>
      <CircleButton
        disabled={!!fields.getAll().some((f) => !f.state)}
        icon="plus"
        onClick={() => fields.unshift({})}
        color={colors.white}
        fontSize="13px"
        size="58px"
        isNewFilterExist={fields.getAll().some((filter) => !filter.state)}
        background={colors['button-primary-hover-bg']}
      />
      {fields.map((filter, index) => {
        const fieldData = fields.get(index);
        const filterName = fieldData.name;
        const { operator } = fieldData;
        const filterValueType = getFilterValueType(operator);
        const availableFilters = getAvailableFilters(
          filters.filter(({ isHidden }) => !isHidden),
          blueprintId,
        );
        if (fieldData.state === 'exist') {
          const currentFilter = availableFilters.find(
            (f) => f.value === filterName,
          );
          return (
            <ExistFilterGroup
              key={index}
              onClick={() => change(`${filter}.state`, 'edit')}
            >
              <ExistFilterName>{currentFilter.label}</ExistFilterName>
              <ExistFilterOperator>
                {
                  getOperators(filterName).find(
                    /* istanbul ignore next -- falsely picked up */
                    (o) => o.value === operator,
                  ).label
                }
              </ExistFilterOperator>
              <ExistFilterValue>
                {filterValueType === 'select' &&
                  getFilterValues(
                    filterName,
                    blueprintNames,
                    blueprintId,
                    allComputers,
                  )
                    .filter((v) => fieldData.value.includes(v.value))
                    .map((v, i, array) =>
                      i === array.length - 1 ? (
                        <div>{v.label}</div>
                      ) : (
                        <div>{`${v.label},`}</div>
                      ),
                    )}
                {filterValueType === 'input' && fieldData.value}
                {filterValueType === 'date' &&
                  fieldData.value.replace('@', ' and ')}
              </ExistFilterValue>
              <ActionButtonFlex
                role="presentation"
                isCheckIcon
                bColor={colors['marengo-310']}
                onClick={() => {
                  if (
                    currentForm.values.filters[index].value ||
                    blankOperators.includes(
                      currentForm.values.filters[index].operator,
                    )
                  ) {
                    setFiltersToUrl(
                      currentForm.values.filters.filter(
                        (item, i) => i !== index,
                      ),
                    );
                  } else {
                    fields.remove(index);
                  }
                }}
              >
                {/* <Icon name='x' /> */}
                <i className="far fa-times" />
              </ActionButtonFlex>
            </ExistFilterGroup>
          );
        }

        return (
          <FilterGroupWrapper big={fieldData.state !== 'edit'}>
            <FilterGroup
              key={`${filterName}${operator}${index}`}
              big={fieldData.state !== 'edit'}
            >
              <Field
                name={`${filter}.name`}
                component={RenderSelect}
                options={availableFilters}
                placeholder="Filter"
                disabled={!!filterName}
                currentForm={currentForm}
                index={index}
                change={change}
                noSelectText="Filter"
                selectState={fieldData.state}
              />
              <Field
                name={`${filter}.operator`}
                component={RenderSelect}
                options={getOperators(filterName)}
                placeholder="Operator"
                currentForm={currentForm}
                disabled={!filterName}
                index={index}
                isOperatorSelect
                change={change}
                operator={operator}
                noSelectText="Operator"
                selectState={fieldData.state}
              />
              {filterValueType === 'select' && operator && (
                <Field
                  name={`${filter}.value`}
                  component={RenderSelect}
                  style={{
                    minWidth: 200,
                    width: 'auto',
                    maxWidth: '50%',
                  }}
                  options={getFilterValues(
                    filterName,
                    blueprintNames,
                    blueprintId,
                    allComputers,
                  )}
                  placeholder="Value"
                  multi
                  disabled={
                    !(filterName !== undefined && operator !== undefined)
                  }
                  currentForm={currentForm}
                  index={index}
                  change={change}
                  noSelectText="Value"
                  selectState={fieldData.state}
                />
              )}
              {filterValueType === 'input' && operator && (
                <Field
                  name={`${filter}.value`}
                  component={renderInput}
                  options={getFilterValues(
                    filterName,
                    blueprintNames,
                    blueprintId,
                    allComputers,
                  )}
                  placeholder="Value"
                  multi
                  disabled={
                    !(filterName !== undefined && operator !== undefined)
                  }
                  currentForm={currentForm}
                  index={index}
                  change={change}
                  big={fieldData.state !== 'edit'}
                />
              )}
              {filterValueType === 'date' &&
                operator &&
                operator === 'between' && (
                  <Field
                    name={`${filter}.value`}
                    component={dateRangeInput}
                    placeholder="Value"
                    disabled={
                      !(filterName !== undefined && operator !== undefined)
                    }
                    change={change}
                    currentForm={currentForm}
                    index={index}
                    initialize={initialize}
                    big={fieldData.state !== 'edit'}
                  />
                )}
              {filterValueType === 'date' &&
                operator &&
                operator !== 'between' && (
                  <Field
                    name={`${filter}.value`}
                    component={dateInput}
                    placeholder="Value"
                    disabled={
                      !(filterName !== undefined && operator !== undefined)
                    }
                    change={change}
                    currentForm={currentForm}
                    index={index}
                    blur={blur}
                    initialize={initialize}
                    big={fieldData.state !== 'edit'}
                  />
                )}

              <ActionButton
                big={fieldData.state !== 'edit'}
                bColor={
                  fieldData.state === 'edit'
                    ? colors['marengo-680']
                    : colors.white
                }
                role="presentation"
                isCheckIcon
                onClick={() => {
                  if (
                    filterValueType === 'date' &&
                    operator &&
                    operator === 'between'
                  ) {
                    if (isDateRangeValid(fieldData.value)) {
                      setFiltersToUrl(currentForm.values.filters);
                      change(`${filter}.state`, 'exist');
                    }
                  } else if (
                    filterValueType === 'date' &&
                    operator &&
                    operator !== 'between'
                  ) {
                    if (parseDateFromFilter(fieldData.value)) {
                      setFiltersToUrl(currentForm.values.filters);
                      change(`${filter}.state`, 'exist');
                    }
                  } else if (filterValueType && operator) {
                    if (
                      (!fieldData.value || isEmpty(fieldData.value)) &&
                      !blankOperators.includes(operator)
                    ) {
                      return;
                    }
                    setFiltersToUrl(currentForm.values.filters);
                    change(`${filter}.state`, 'exist');
                  }
                }}
              >
                {/* <Icon name='check' /> */}
                <i className="far fa-check" />
              </ActionButton>

              <ActionButton
                big={fieldData.state !== 'edit'}
                bColor={
                  fieldData.state === 'edit'
                    ? colors['marengo-380']
                    : colors.white
                }
                role="presentation"
                isCheckIcon={false}
                onClick={() => {
                  if (fieldData.state === 'edit') {
                    change(`${filter}.state`, 'exist');
                  } else if (
                    currentForm.values.filters[index].value ||
                    blankOperators.includes(
                      currentForm.values.filters[index].operator,
                    )
                  ) {
                    setFiltersToUrl(
                      currentForm.values.filters.filter(
                        (item, i) => i !== index,
                      ),
                    );
                    fields.remove(index);
                  } else {
                    fields.remove(index);
                  }
                  reset();
                }}
              >
                {/* <Icon name='x' /> */}
                <i className="far fa-times" />
              </ActionButton>
            </FilterGroup>
          </FilterGroupWrapper>
        );
      })}
    </>
  );
};

const mapStateToPropsRenderFilters = (state) => ({
  feature_configuration: state.account?.company?.feature_configuration,
});
const mapDispatchToPropsRenderFilters = (dispatch) =>
  bindActionCreators({}, dispatch);

const RenderFilters = connect(
  mapStateToPropsRenderFilters,
  mapDispatchToPropsRenderFilters,
)(renderFilters);

export class TableFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (!isEqual(next.initialValues, this.props.initialValues)) {
      this.props.onFetch && this.props.onFetch(next.initialValues.filters);
    }
  }

  render() {
    const {
      change,
      blueprintId,
      currentForm,
      blueprintNames,
      computers,
      allComputers,
      initialize,
      hidden,
      reset,
    } = this.props;
    return (
      <>
        <WrapperFilters className={classNames({ 'd-none': hidden })}>
          <FieldArray
            name="filters"
            component={RenderFilters}
            currentForm={currentForm}
            blueprintNames={blueprintNames}
            computers={computers}
            allComputers={allComputers}
            change={change}
            blur={blur}
            reset={reset}
            initialize={initialize}
            blueprintId={blueprintId}
          />
        </WrapperFilters>
        <section
          className={classNames('pb-3', { 'd-none': hidden })}
          style={{
            backgroundColor: 'var(--color-neutral-10)',
            marginLeft: -10,
            marginRight: -10,
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    filters: getFiltersFromUrl(ownProps.location, false),
  },
  currentForm: state.form.TableFilters,
  blueprintNames: state.data.blueprintNames,
  allComputers: state.filters.computers,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      change,
      blur,
      reset,
      getComputersForFilters,
    },
    dispatch,
  );

TableFilters = reduxForm({
  form: 'TableFilters',
  enableReinitialize: true,
})(TableFilters);

TableFilters = connect(mapStateToProps, mapDispatchToProps)(TableFilters);

export default TableFilters;
