import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import SearchString from './SearchString';

const BlueprintTabTypeMixin = `
  width: 100%;
  margin: 0;
  padding: 0 24px;
`;

const Wrapper = styled.div`
  display: flex;
  z-index: 1;
  justify-content: space-between;
  align-items: center !important;
  ${(props) => props.sticky && 'position: sticky;'};
  width: calc(100% + 20px);
  margin: 0 -10px;
  padding: 0 10px;
  ${(props) => props.sticky && `top: ${props.top}px;`};
  height: 80px;
  ${(props) => (props.blueprintTabType ? BlueprintTabTypeMixin : null)}
`;

const FiltersWrapper = styled.div`
  margin: 25px 0;
  height: 55px;
  display: flex;
  align-items: center;
  width: 100%;
  //margin-left: -.5rem;
`;

const SearchStringWrapper = styled.div`
  margin: 25px 0;
  height: 55px;
  display: flex;
  align-items: center;
  width: 350px;
`;

const AwesomeTableToolBar = ({
  btnGroup,
  defaultValue,
  searchLabel,
  searchIconPosition,
  searchFunc,
  sticky,
  blueprintTabType,
  top,
}) => (
  <Wrapper sticky={sticky} blueprintTabType={blueprintTabType} top={top}>
    <FiltersWrapper>{btnGroup}</FiltersWrapper>
    <SearchStringWrapper>
      <SearchString
        defaultValue={defaultValue}
        label={searchLabel}
        iconPosition={searchIconPosition}
        searchFunc={searchFunc}
      />
    </SearchStringWrapper>
  </Wrapper>
);

AwesomeTableToolBar.propTypes = {
  btnGroup: PropTypes.any,
  defaultValue: PropTypes.string,
  searchLabel: PropTypes.string,
  searchIconPosition: PropTypes.string,
  searchFunc: PropTypes.func,
  sticky: PropTypes.bool,
  blueprintTabType: PropTypes.bool,
  top: PropTypes.number,
};

AwesomeTableToolBar.defaultProps = {
  defaultValue: null,
  sticky: false,
  blueprintTabType: false,
  top: 0,
};

export default AwesomeTableToolBar;
