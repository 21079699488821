import {
  Box,
  Button,
  Flex,
  Heading,
  Paragraph,
  styled,
} from '@kandji-inc/nectar-ui';
import EmptyIcon from 'features/visibility/prism/assets/bp-filter-empty-state.svg';
import NoDevicesIcon from 'features/visibility/prism/assets/no-devices.svg';
import { i18n } from 'i18n';

const NoResultsImg = styled('img', {
  mb: '$5',
});

const StyledLink = styled('a', {
  color: '$blue50',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
});

const ViewsTableEmpty = ({
  attributeFilters,
  computerCount,
  onNavigateToAddDevice,
  search,
}: {
  attributeFilters: Record<string, unknown>;
  computerCount: number | undefined;
  onNavigateToAddDevice: () => void;
  search: string;
}) => {
  const noResultsCopy = getNoResultsMessage({
    attributeFilters,
    computerCount,
    search,
  });
  return (
    <Flex
      flow="column"
      alignItems="center"
      justifyContent="center"
      css={{ height: '100%' }}
    >
      <NoResultsImg src={noResultsCopy.image} alt={i18n.t('Empty Icon')} />
      <Flex flow="column" alignItems="center" css={{ width: '100%' }}>
        <Heading
          as="h4"
          size="4"
          css={{
            color: '$neutral90',
            fontWeight: '$medium',
          }}
        >
          {noResultsCopy.header}
        </Heading>
        <Paragraph
          css={{
            color: '$neutral90',
            whiteSpace: 'normal',
            textAlign: 'center',
          }}
        >
          {noResultsCopy.body}
          {!computerCount && (
            <Box>
              <StyledLink
                href="https://support.kandji.io/support/solutions/articles/72000560543-enrolling-devices"
                target="_blank"
                rel="noreferrer noopener"
              >
                {i18n.t('Learn more...')}
              </StyledLink>
            </Box>
          )}
        </Paragraph>
        {!computerCount && (
          <Button
            onClick={onNavigateToAddDevice}
            css={{ mt: '24px' }}
            icon={{
              name: 'circle-plus',
              position: 'left',
            }}
          >
            {i18n.t('Add devices')}
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default ViewsTableEmpty;

export function getNoResultsMessage({
  attributeFilters,
  computerCount,
  search,
}: {
  attributeFilters: Record<string, any>;
  computerCount: number | undefined;
  search: string;
}): {
  header: string;
  body: string;
  image: string;
} {
  if (!computerCount) {
    return {
      header: i18n.t('No devices to display'),
      body: i18n.t(
        'Add devices to Kandji to view device information. You can add devices manually in the Add Devices section or through Automated Device Enrollment.',
      ),
      image: NoDevicesIcon,
    };
  }

  const attributeFiltersEmpty =
    (attributeFilters === undefined ||
      !attributeFilters ||
      Object.keys(attributeFilters).length === 0) &&
    search === '';

  if (attributeFiltersEmpty) {
    return {
      header: i18n.t('No data available'),
      body: i18n.t("There's no data to display for devices in this view."),
      image: EmptyIcon,
    };
  }

  return {
    header: i18n.t(
      'No data is available in this view that matches your applied filters',
    ),
    body: i18n.t(
      "We couldn't find a match. Edit or remove any applied filters.",
    ),
    image: EmptyIcon,
  };
}
