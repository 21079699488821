import NewLibraryItemService from 'features/library-items/data-service/library-item/new-library-item-service';
import { i18n } from 'src/i18n';

class VpnService extends NewLibraryItemService {
  static VPNTypes = () => ({
    L2TP_OVER_IPSEC: { label: i18n.t('L2TP Over IPSec'), value: 'L2TP' },
  });

  static Proxys = () => ({
    NONE: { label: i18n.t('None'), value: 'None' },
    AUTOMATIC: { label: i18n.t('Automatic'), value: 'Auto' },
    MANUAL: { label: i18n.t('Manual'), value: 'Manual' },
  });
}

export const vpnService = new VpnService();

export default VpnService;
