import { Flex } from '@kandji-inc/nectar-ui';
import { LabelText } from './LabelText';

const EmptyIcon = () => (
  <svg
    width="163"
    height="102"
    viewBox="0 0 163 102"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group 8660">
      <g id="Group 8653">
        <path
          id="Union"
          d="M3.60514 47.48C2.65753 48.0271 2.18714 48.7454 2.19398 49.4624H2.18848V54.0256H2.1983C2.24787 54.6973 2.71682 55.3606 3.60514 55.8735L51.2624 83.3884C53.144 84.4748 56.1948 84.4748 58.0764 83.3884L136.374 38.1834C137.282 37.6589 137.752 36.9769 137.783 36.2896H137.785V31.7264C137.772 31.0247 137.301 30.3253 136.374 29.7899L88.7165 2.27498C86.8349 1.18861 83.7841 1.18861 81.9025 2.27498L3.60514 47.48Z"
          fill="#BCC9DE"
        />
        <path
          id="Rectangle 1234"
          d="M10.46 57.6412C10.46 57.3246 10.2377 56.9395 9.96344 56.7812L6.9843 55.0612C6.71008 54.9029 6.48777 55.0312 6.48777 55.3479C6.48777 55.6645 6.71008 56.0495 6.9843 56.2079L9.96344 57.9279C10.2377 58.0862 10.46 57.9579 10.46 57.6412Z"
          fill="white"
        />
        <path
          id="Rectangle 1259"
          d="M15.4795 60.5743C15.4795 60.2577 15.2572 59.8726 14.983 59.7143L12.0038 57.9943C11.7296 57.836 11.5073 57.9643 11.5073 58.281C11.5073 58.5976 11.7296 58.9827 12.0038 59.141L14.983 60.861C15.2572 61.0193 15.4795 60.891 15.4795 60.5743Z"
          fill="white"
        />
        <path
          id="Rectangle 1244"
          d="M5.03811 52.2434C2.36458 50.6998 2.36459 48.1972 5.03812 46.6536L80.4678 3.10429C83.1413 1.56073 87.4759 1.56073 90.1495 3.10429L134.939 28.9636C137.613 30.5071 137.613 33.0097 134.939 34.5533L59.5094 78.1026C56.8358 79.6462 52.5012 79.6462 49.8277 78.1026L5.03811 52.2434Z"
          fill="#D9D9D9"
        />
        <path
          id="Rectangle 1246"
          d="M3.66137 51.4485C1.7482 50.3439 1.7482 48.5531 3.66137 47.4485L81.8445 2.30943C83.7577 1.20486 86.8596 1.20486 88.7727 2.30943L135.346 29.1984C137.795 30.6123 137.795 32.9046 135.346 34.3184L58.1326 78.8975C56.2195 80.0021 53.1176 80.0021 51.2044 78.8975L3.66137 51.4485Z"
          fill="#ECF1F7"
        />
      </g>
      <g id="Group 8654" filter="url(#filter0_d_120_11596)">
        <path
          id="Union_2"
          d="M32.5662 46.6475V49.899C32.5443 50.6049 33.0004 51.3136 33.9328 51.8521L101.565 90.8996C103.387 91.9515 106.341 91.9515 108.163 90.8996L157.238 62.5664C158.198 62.0121 158.656 61.2764 158.604 60.5509V57.2995C158.592 56.6196 158.136 55.9425 157.238 55.4235L89.6056 16.3761C87.7835 15.3242 84.8294 15.3242 83.0073 16.3761L33.9328 44.7093C33.007 45.2441 32.5513 45.9472 32.5662 46.6475Z"
          fill="#BCC9DE"
        />
        <path
          id="Rectangle 1226"
          d="M34.0979 48.613C32.1847 47.5085 32.1847 45.7176 34.0979 44.613L81.2067 17.4147C84.0233 15.7886 88.5899 15.7886 91.4066 17.4147L157.073 55.3273C158.986 56.4318 158.986 58.2227 157.073 59.3273L109.964 86.5256C107.148 88.1517 102.581 88.1517 99.7643 86.5256L34.0979 48.613Z"
          fill="white"
        />
        <path
          id="Rectangle 1226 (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.5924 18.2147L35.4835 45.413C34.3356 46.0758 34.3356 47.1503 35.4835 47.813L101.15 85.7256C103.201 86.9099 106.527 86.9099 108.579 85.7256L155.687 58.5273C156.835 57.8645 156.835 56.79 155.687 56.1273L90.0209 18.2147C87.9696 17.0304 84.6437 17.0304 82.5924 18.2147ZM34.0979 44.613C32.1847 45.7176 32.1847 47.5085 34.0979 48.613L99.7643 86.5256C102.581 88.1517 107.148 88.1517 109.964 86.5256L157.073 59.3273C158.986 58.2227 158.986 56.4318 157.073 55.3273L91.4066 17.4147C88.5899 15.7886 84.0233 15.7886 81.2067 17.4147L34.0979 44.613Z"
          fill="#BCC9DE"
        />
      </g>
      <g id="Group 8655">
        <path
          id="Union_3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M85.1914 28.789H85.1958C85.191 29.8583 85.8951 30.9285 87.3082 31.7444L128.05 55.2664C130.866 56.8926 135.433 56.8926 138.249 55.2664L156.704 44.6117C158.028 43.8471 158.73 42.8593 158.809 41.8581H158.816V41.6804C158.816 41.6716 158.816 41.6629 158.816 41.6541V37.6734C158.825 36.6016 158.12 35.5282 156.704 34.7104L115.963 11.1884C113.146 9.56221 108.579 9.56221 105.763 11.1884L87.3083 21.8431C85.8993 22.6566 85.1951 23.7229 85.1958 24.7891H85.1914V28.789Z"
          fill="#BCC9DE"
        />
        <path
          id="Rectangle 1228"
          d="M87.3079 27.7364C84.4913 26.1103 84.4913 23.4737 87.3079 21.8476L105.762 11.1928C108.579 9.56664 113.146 9.56664 115.962 11.1928L156.704 34.7148C159.52 36.341 159.52 38.9775 156.704 40.6037L138.249 51.2584C135.432 52.8846 130.866 52.8846 128.049 51.2584L87.3079 27.7364Z"
          fill="white"
        />
        <path
          id="Rectangle 1228 (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M107.148 11.9928L88.6936 22.6476C86.6422 23.8319 86.6422 25.7521 88.6936 26.9364L129.435 50.4584C131.486 51.6428 134.812 51.6428 136.863 50.4584L155.318 39.8037C157.369 38.6194 157.369 36.6992 155.318 35.5148L114.577 11.9928C112.525 10.8085 109.199 10.8085 107.148 11.9928ZM87.3079 21.8476C84.4913 23.4737 84.4913 26.1103 87.3079 27.7364L128.049 51.2584C130.866 52.8846 135.432 52.8846 138.249 51.2584L156.704 40.6037C159.52 38.9775 159.52 36.341 156.704 34.7148L115.962 11.1928C113.146 9.56664 108.579 9.56664 105.762 11.1928L87.3079 21.8476Z"
          fill="#BCC9DE"
        />
        <path
          id="Rectangle 1230"
          d="M100.072 37.2085C100.072 36.9875 100.227 36.8979 100.419 37.0084L103.698 38.9016C103.889 39.0121 104.044 39.2808 104.044 39.5018C104.044 39.7228 103.889 39.8124 103.698 39.7019L100.419 37.8087C100.227 37.6982 100.072 37.4295 100.072 37.2085Z"
          fill="white"
        />
        <path
          id="Rectangle 1231"
          d="M95.4512 34.4656C95.4512 34.2446 95.6063 34.155 95.7977 34.2655L99.0768 36.1587C99.2682 36.2692 99.4234 36.5379 99.4234 36.7589C99.4234 36.9799 99.2682 37.0695 99.0768 36.959L95.7977 35.0658C95.6063 34.9553 95.4512 34.6866 95.4512 34.4656Z"
          fill="white"
        />
        <path
          id="Rectangle 1232"
          d="M92.0127 32.5207C92.0127 32.2998 92.1678 32.2102 92.3592 32.3207L93.6523 33.0672C93.8437 33.1777 93.9988 33.4465 93.9988 33.6674C93.9988 33.8884 93.8436 33.9779 93.6523 33.8675L92.3592 33.121C92.1678 33.0105 92.0127 32.7417 92.0127 32.5207Z"
          fill="white"
        />
        <path
          id="Rectangle 1233"
          d="M98.3456 20.1078C97.7971 19.7911 97.7971 19.2777 98.3456 18.9611L104.304 15.5211C104.852 15.2044 105.742 15.2044 106.29 15.5211C106.838 15.8377 106.838 16.3511 106.29 16.6677L100.332 20.1078C99.7832 20.4244 98.894 20.4244 98.3456 20.1078Z"
          fill="#BCC9DE"
        />
        <path
          id="Rectangle 1225"
          d="M145.879 48.7748C145.879 48.4581 146.101 48.0731 146.375 47.9147L149.355 46.1947C149.629 46.0364 149.851 46.1648 149.851 46.4814C149.851 46.7981 149.629 47.1831 149.355 47.3414L146.375 49.0614C146.101 49.2197 145.879 49.0914 145.879 48.7748Z"
          fill="white"
        />
        <path
          id="Rectangle 1234_2"
          d="M131.783 75.5208C131.783 75.2042 132.006 74.8192 132.28 74.6608L135.259 72.9408C135.533 72.7825 135.755 72.9109 135.755 73.2275C135.755 73.5441 135.533 73.9292 135.259 74.0875L132.28 75.8075C132.006 75.9658 131.783 75.8375 131.783 75.5208Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_120_11596"
        x="24.5654"
        y="9.58716"
        width="142.043"
        height="92.1013"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0588235 0 0 0 0 0.0745098 0 0 0 0 0.0901961 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_120_11596"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_120_11596"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const Footer = () => (
  <Flex
    justifyContent="start"
    alignItems="center"
    flow="column"
    css={{
      mx: 'auto',
      textAlign: 'center',
    }}
    gap="lg"
  >
    <EmptyIcon />
    <Flex flow="column">
      <LabelText>
        Specify your Pulse query parameters and device target above
      </LabelText>
    </Flex>
  </Flex>
);
