/* istanbul ignore file */
export const footerLinks = [
  { label: 'Privacy Policy', link: 'https://www.kandji.io/legal/privacy/' },
  { label: 'Cookie Policy', link: 'https://www.kandji.io/legal/privacy/' },
  {
    label: 'California Privacy Notice',
    link: 'https://www.kandji.io/legal/privacy/',
  },
  { label: 'Accessibility', link: 'https://www.kandji.io/legal/privacy/' },
  { label: 'Legal', link: 'https://www.kandji.io/legal/privacy/' },
  { label: 'Patent', link: 'https://www.kandji.io/legal/privacy/' },
];
