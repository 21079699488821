import {
  TextInput,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';

import { onEmptyBlurValidator } from 'src/app/common/validators';
import { i18n } from 'src/i18n';
import CertificateCheckboxRows from '../common/certificate-checkbox-rows';
import CertificateFileRow from '../common/certificate-file-row';
import CertificateNameRow from '../common/certificate-name-row';

const fieldsToValidate = ['password'];

const Pkcs12Rows = (props) => {
  const { isDisabled, setting, update, validationDep } = props;

  const { refs, onInvalidate } = useInputsValidators(fieldsToValidate, update);
  useRemoveValidationOnUnmount(fieldsToValidate, update);

  return (
    <>
      <CertificateNameRow {...props} />

      <Setting.Row>
        <Setting.Title>
          <p className="b-txt">{i18n.t('Certificate password')}</p>
        </Setting.Title>
        <Setting.Helpers>
          <p ref={refs.password} className="b-txt-light">
            {i18n.t('The password used to secure certificate credentials.')}
          </p>
        </Setting.Helpers>
        <Setting.Controls>
          <TextInput
            value={setting.password}
            type="password"
            onChange={(e) => update('password', e.target.value)}
            disabled={isDisabled}
            // placeholder=""
            compact
            validator={(v) => [
              onEmptyBlurValidator(v, { trigger: ['onBlur', validationDep] }),
            ]}
            onInvalidate={onInvalidate('password')}
            data-testid="pkcs12-password"
          />
        </Setting.Controls>
      </Setting.Row>

      <CertificateFileRow {...props} allowedTypes={['.p12', '.pfx']} />

      <CertificateCheckboxRows {...props} />
    </>
  );
};

export default Pkcs12Rows;
