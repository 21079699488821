/* istanbul ignore file */
export const isoToDateString = (
  isoDate: string,
  timeZone?: string,
  withTime: boolean = false,
  options?: Intl.DateTimeFormatOptions,
) => {
  if (!isoDate) {
    return '';
  }

  // Some endpoint dates lack 'Z' suffix, causing browsers to interpret them as local time.
  const date = new Date(
    /Z$|\+\d{2}:\d{2}$/.test(isoDate) ? isoDate : `${isoDate}Z`,
  );

  const dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    ...options,
  };

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    ...options,
  };

  if (timeZone) {
    dateOptions.timeZone = timeZone;
    timeOptions.timeZone = timeZone;
  }

  const formattedDate = date.toLocaleDateString('en-US', dateOptions);
  const formattedTime = withTime
    ? date.toLocaleTimeString('en-US', timeOptions)
    : '';

  return `${formattedDate} ${formattedTime}`.trim();
};

export default isoToDateString;
