import * as React from 'react';
import { ExportButton } from '../../shared/components/export';

export const PulseExport = ({
  reportName = 'Report name',
  jobId,
}: {
  reportName?: string;
  jobId: string;
}) => {
  const [exportType, setExportType] = React.useState('with_results_only');

  return (
    <ExportButton
      exportDataType="pulse"
      title={`Export ${reportName}`}
      exportType={exportType}
      setExportType={setExportType}
      helpText={getHelpText(exportType)}
      exportTypeOptions={[
        {
          label: 'Devices with results only',
          type: 'with_results_only',
        },
        {
          label: 'All device information',
          type: 'all_devices',
        },
      ]}
      onExport={() => {
        return handleExportPayload(
          jobId,
          reportName,
          exportType === 'with_results_only',
        );
      }}
    />
  );
};

export const handleExportPayload = (
  pulseJobId: string,
  reportName: string,
  withResultsOnly: boolean,
) => ({
  pulse_job_id: pulseJobId,
  with_results_only: withResultsOnly,
  report_name: reportName,
});

export const getHelpText = (exportType: string) => {
  if (exportType === 'with_results_only') {
    return (
      <>
        CSV will <strong>only</strong> include devices that returned a result in
        the report. It will not include devices that did not return a result or
        had a failed or unknown status.
      </>
    );
  }
  return (
    <>
      Export will include all information for devices that were targeted in the
      report and device results.
    </>
  );
};
