import { colors } from './constants';

export const truncate = (width) => `
    width: ${width};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;

export const prettyScroll = ({
  scrollWidth = 4,
  scrollColor = 'black',
} = {}) => `
    &::-webkit-scrollbar {
      width: ${scrollWidth}px;
    }
    &::-webkit-scrollbar-track {
      border-radius: ${scrollWidth}px;
    }
    &::-webkit-scrollbar-thumb {
      background: ${colors[scrollColor]};
      border-radius: ${scrollWidth}px;
    }
  `;

export const uppercaseStyle = `
  font-size: 1.8rem;
  text-transform: uppercase;
  letter-spacing: .2em;
  font-weight: 600;
  font-family: var(--font-family-primary);
`;

export const cutLongText = `
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
