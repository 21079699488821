// istanbul ignore file
const convertToCSV = (data: any[]) => {
  if (data.length === 0) return '';

  const header = Object.keys(data[0]);
  const rows = data.map((row) => {
    return header
      .map((field) => {
        const value = row[field];
        if (value === null) return '';
        if (Array.isArray(value)) return value.join('; ');
        return String(value);
      })
      .join(',');
  });

  return [header.join(','), ...rows].join('\r\n');
};

export default convertToCSV;
