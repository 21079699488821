import DiskManagementService from './service/disk-management-service';

export default {
  id: null,
  name: '',
  selectedBlueprints: [],
  isAllBlueprints: false,
  isActive: true,
  supportsRules: true,
  management: {
    externalVolumes: DiskManagementService.volumeTypes.ALLOWED,
    internalVolumes: DiskManagementService.volumeTypes.ALLOWED,
  },
};
