import { Toaster as toaster } from '@kandji-inc/bumblebee';
import { Button, Dialog, Flex, Text } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import { useState } from 'react';
import { msDeviceComplianceService } from 'src/features/integrations/components-for-specific-types/ms-device-compliance/services';

const ResetMsDeviceRegistrationModal = (props) => {
  const { info, onHide } = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleReset = async () => {
    setIsLoading(true);
    try {
      toaster(
        i18n.t(
          `Command sent to reset Microsoft Device Registration for ${info.deviceName}.`,
        ),
      );
      await msDeviceComplianceService.resetMsDeviceRegistration(
        info.deviceId,
        info.integrationId,
      );
    } catch {
      /* istanbul ignore next */
      toaster(i18n.t('Registration not found.'));
    } finally {
      setIsLoading(false);
      onHide();
    }
  };

  const content = (
    <Flex flow="column" gap="md" css={{ minWidth: '$15' }}>
      <Text>
        {i18n.t(
          "This action resets the Microsoft Device Compliance registration status in Kandji allowing the end-user to perform the device registration flow again. If you're unsure if you need to do this, please contact support.",
        )}
      </Text>
    </Flex>
  );

  const footer = (
    <Flex gap="md" justifyContent="end" wrap="wrap">
      <Button variant="subtle" onClick={onHide}>
        {i18n.t('Cancel')}
      </Button>
      <Button variant="primary" loading={isLoading} onClick={handleReset}>
        {i18n.t('Reset Device Registration')}
      </Button>
    </Flex>
  );

  return (
    <Dialog
      isOpen
      closeOnEscape
      closeOnOutsideClick
      onOpenChange={onHide}
      title={i18n.t('Reset Microsoft Device Registration')}
      content={content}
      footer={footer}
    />
  );
};

export default ResetMsDeviceRegistrationModal;
