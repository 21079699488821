import { Icon } from '@kandji-inc/bumblebee';
import cn from 'classnames';
import React from 'react';
import HoverTippy from 'src/features/util/components/hover-tippy';
import styled from 'styled-components';

const CustomCheckbox = (props) => {
  const {
    isDisabled,
    disabled,
    checked,
    name,
    onChange,
    className,
    style,
    label,
    testId,
    id = '',
    hoverText = '',
  } = props;

  return (
    <Label
      style={style}
      className={cn([
        'b-checkbox',
        'b-txt',
        className,
        {
          'b-not-allowed': disabled || isDisabled,
        },
      ])}
      id={`wrapper-${id}`}
      htmlFor={id}
    >
      <input
        type="checkbox"
        name={name}
        className="b-checkbox-input"
        id={id}
        disabled={isDisabled || disabled}
        checked={checked}
        onClick={(e) => {
          e.preventDefault();
          onChange(e);
        }}
      />
      <HoverTippy
        text={hoverText}
        maxWidth={297}
        disabled={!(disabled || isDisabled) || !hoverText}
        placement="left"
      >
        <button
          className={cn('b-checkbox-square', {
            checked,
            disabled: disabled || isDisabled,
          })}
          type="button"
          data-testid={`${id}-button`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onChange(e);
          }}
        >
          <Icon name="check" />
        </button>
      </HoverTippy>
      {label && (
        <span
          className={cn({ 'b-link-disabled': disabled || isDisabled })}
          data-testid={testId}
        >
          {label}
        </span>
      )}
    </Label>
  );
};

const Label = styled.label`
  &.unselected {
    padding-bottom: 0;
  }

  .b-checkbox-input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  .b-checkbox-square {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border: 2px solid rgba(26, 29, 37, 0.15);
    background-color: #fff;

    svg {
      color: #fff;
      font-size: 11px;
    }

    &.checked {
      border-color: #0057da;
      background-color: #0057da;
    }

    &.disabled {
      pointer-events: none;
      &:hover {
        cursor: not-allowed;
      }
    }

    &.checked.disabled {
      background-color: rgba(26, 29, 37, 0.05);
      border-color: rgba(26, 29, 37, 0.05);

      svg {
        color: rgba(26, 29, 37, 0.4);
      }
    }
  }
`;

export default CustomCheckbox;
