/* istanbul ignore file */
import React from 'react';

export default () => (
  <svg
    width="13"
    height="16"
    viewBox="0 0 13 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.743164 13.98V2.19482C0.743164 1.65251 0.916341 1.21501 1.2627 0.882324C1.61361 0.549642 2.07161 0.383301 2.63672 0.383301H10.2725C10.8376 0.383301 11.2933 0.549642 11.6396 0.882324C11.9906 1.21501 12.166 1.65251 12.166 2.19482V13.98C12.166 14.5177 11.9906 14.953 11.6396 15.2856C11.2933 15.6183 10.8376 15.7847 10.2725 15.7847H2.63672C2.07161 15.7847 1.61361 15.6183 1.2627 15.2856C0.916341 14.953 0.743164 14.5177 0.743164 13.98ZM1.84375 13.7954C1.84375 14.0734 1.92578 14.2899 2.08984 14.4448C2.25391 14.6043 2.48177 14.6841 2.77344 14.6841H10.1357C10.4274 14.6841 10.6553 14.6043 10.8193 14.4448C10.9834 14.2899 11.0654 14.0734 11.0654 13.7954V2.37939C11.0654 2.09684 10.9834 1.87809 10.8193 1.72314C10.6553 1.56364 10.4274 1.48389 10.1357 1.48389H2.77344C2.48177 1.48389 2.25391 1.56364 2.08984 1.72314C1.92578 1.87809 1.84375 2.09684 1.84375 2.37939V13.7954ZM4.5166 14.1646C4.42546 14.1646 4.35026 14.1349 4.29102 14.0757C4.23177 14.0164 4.20215 13.939 4.20215 13.8433C4.20215 13.7476 4.23177 13.6701 4.29102 13.6108C4.35026 13.5562 4.42546 13.5288 4.5166 13.5288H8.39258C8.48828 13.5288 8.56576 13.5562 8.625 13.6108C8.68424 13.6701 8.71387 13.7476 8.71387 13.8433C8.71387 13.939 8.68424 14.0164 8.625 14.0757C8.56576 14.1349 8.48828 14.1646 8.39258 14.1646H4.5166Z"
      fill="#35414E"
    />
  </svg>
);
