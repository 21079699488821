import { colors } from 'app/common/constants';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.section.attrs(({ bColor }) => ({
  backgroundColor: colors[bColor],
}))`
  font-family: var(--font-family-primary);
  line-height: 10px;
  text-transform: uppercase;
  color: ${(props) => colors[props.color]};
  border: 1px solid ${colors['grey-250']};
  letter-spacing: 0.05rem;
  height: 18px;
  padding: 4px 7px;
  margin-right: 7px;
  border-radius: 2px;
  font-size: 10px;
  font-weight: 700;
  background-color: ${(props) => props.backgroundColor};
`;

const SimpleIndicator = ({ text, bColor, color }) => (
  <Wrapper bColor={bColor} color={color}>
    {text}
  </Wrapper>
);

SimpleIndicator.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  bColor: PropTypes.string,
  color: PropTypes.string,
};

SimpleIndicator.defaultProps = {
  bColor: 'white',
  color: 'grey-400',
};

export default SimpleIndicator;
