// Transition - Slide
export const slide = {
  from: { transform: 'translate3d(100%, 0, 0)' },
  enter: { transform: 'translate3d(0, 0, 0)' },
  leave: { transform: 'translate3d(100%, 0, 0)' },
};

// Transition - Fade
export const fade = {
  from: { opacity: 0 },
  enter: { opacity: 1 },
  leave: { opacity: 0 },
};

// Transition - Appear
export const appear = {
  from: { opacity: 0 },
  enter: { opacity: 1 },
  leave: { opacity: 0 },
};
