import { theme } from 'app/common/constants';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  display: flex;
  padding-top: 35px;
`;

const PhotoWrapper = styled.section`
  height: 100px;
  width: 100px;
  border-radius: 50px;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
  }
`;

const NameAndEmailWrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-top: 20px;
`;

const Name = styled.section`
  font-family: var(--font-family-primary);
  font-size: 24px;
  font-weight: 500;
`;

const Email = styled.section`
  font-family: var(--font-family-primary);
  font-size: 18px;
  font-weight: 500;
  color: grey;
`;

const NoPhotoWrapper = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border: 1px solid ${(props) => props.theme.colors['grey-150']};
  overflow: hidden;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconWrapper = styled.div`
  width: 50%;
  height: 50%;
  text-align: center;
`;

const Icon = styled.i`
  font-size: 40px;
`;

const GSuiteUserSummaryCard = ({ name, email, photoUrl }) => (
  <Wrapper>
    {photoUrl && (
      <PhotoWrapper>
        <img src={photoUrl} alt="" />
      </PhotoWrapper>
    )}
    {!photoUrl && (
      <NoPhotoWrapper theme={theme}>
        <IconWrapper>
          <Icon className="ss-icon ss-user" />
        </IconWrapper>
      </NoPhotoWrapper>
    )}
    <NameAndEmailWrapper>
      <Name>{name}</Name>
      <Email>{email}</Email>
    </NameAndEmailWrapper>
  </Wrapper>
);

export default GSuiteUserSummaryCard;
