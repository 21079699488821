import configs from 'features/library-items/library/library-item-configurations/items';
import {
  getRunsOnFromModel,
  getSelectedDevicesFromRunsOn,
} from 'src/features/library-items/data-service/library-item/devices';
import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import { PREVIEW_CONFIG } from '../sections/common';
import appleSystemApps from './apple-system-apps';

import type { HomeScreenLayoutModel } from '../home-screen-layout.types';
import type {
  HomeScreenLayoutApiRequestPayload,
  HomeScreenLayoutApiResponsePayload,
} from './transformers.types';

function transformSettings(settings: HomeScreenLayoutApiResponsePayload) {
  const {
    data,
    runs_on_ipad: isEnabledIpad,
    runs_on_iphone: isEnabledIphone,
  } = settings;
  const { iPad, iPhone } = data;

  return {
    iPad: {
      isEnabled: isEnabledIpad,
      currentPage: 0,
      ...iPad,
    },
    iPhone: {
      isEnabled: isEnabledIphone,
      currentPage: 0,
      ...iPhone,
    },
    is_preview_chip: false,
  };
}

async function transformFromApi(apiData: HomeScreenLayoutApiResponsePayload) {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const commonData = {
    id: apiData.id,
    name: apiData.name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
    rules: apiData.rules,
  };

  const result = {
    ...commonData,
    isActive: apiData.active,
    devices: getSelectedDevicesFromRunsOn(apiData),
    ...transformSettings(apiData),
  };

  return {
    ...apiData,
    data: result,
  };
}

function transformToApi(
  model: HomeScreenLayoutModel,
): HomeScreenLayoutApiRequestPayload {
  const {
    currentPage: _currPageIpad,
    isEnabled: enabledIpad,
    ...iPad
  } = model.iPad;
  const {
    currentPage: _currPageIphone,
    isEnabled: enabledIphone,
    ...iPhone
  } = model.iPhone;

  iPad.Pages = trimEmptyPages(iPad.Pages);
  iPhone.Pages = trimEmptyPages(iPhone.Pages);

  iPad.Pages = addMissingApps('ipad', iPad.Pages, iPad.Dock);
  iPhone.Pages = addMissingApps('iphone', iPhone.Pages, iPhone.Dock);

  const toSend: HomeScreenLayoutApiRequestPayload = {
    name: model.name,
    active: model.isActive,
    blueprints: model.selectedBlueprints.map((b) => b.value),
    is_all_blueprints: model.isAllBlueprints,
    data: {
      iPad,
      iPhone,
      is_preview_chip: false,
    },
    runs_on_ipad: enabledIpad,
    runs_on_iphone: enabledIphone,
    runs_on_ipod: false,
    rules: model.rules || null,
  };

  if (model.skip_blueprint_conflict) {
    toSend.skip_blueprint_conflict = model.skip_blueprint_conflict;
  }

  if (!model.id) {
    const itemConfig = configs['Home Screen Layout'];

    toSend.type = itemConfig.type;
    toSend.identifier = itemConfig.identifier;
  }

  const runsOn = getRunsOnFromModel(model);

  Object.keys(runsOn).forEach((runsOnKey) => {
    toSend[runsOnKey] = runsOn[runsOnKey];
  });

  return toSend;
}

function trimEmptyPages(pages) {
  const trimmedPages = pages.filter((page) => !!page.length);

  if (trimmedPages.length === 0) {
    return [[]];
  }

  return trimmedPages;
}

export function addMissingApps(kind, pages, dock) {
  let newPages = [...pages];

  const missingApps = {
    settings: true,
    phone: true,
  };

  [...pages, dock].forEach((page) => {
    page.forEach((item) => {
      if (item.Pages) {
        item.Pages.forEach((folderPage) =>
          folderPage.forEach((folderPageItem) => {
            if (folderPageItem.id === appleSystemApps.Settings.id) {
              missingApps.settings = false;
            }
            if (folderPageItem.id === appleSystemApps.Phone.id) {
              missingApps.phone = false;
            }
          }),
        );
      }

      if (item.id === appleSystemApps.Settings.id) {
        missingApps.settings = false;
      }

      if (item.id === appleSystemApps.Phone.id) {
        missingApps.phone = false;
      }
    });
  });

  if (kind === 'iphone' && (missingApps.settings || missingApps.phone)) {
    const maxItemsPerPage = PREVIEW_CONFIG[kind].maxPerPage;
    const spotsNeeded = Object.values(missingApps).filter(Boolean).length;

    // Check for any open spots in existing pages:
    let foundSpotForMissingApps = false;

    newPages = newPages.map((page) => {
      // Skip pages that don't have space for the missing apps:
      if (page.length > maxItemsPerPage - spotsNeeded) {
        return page;
      }

      // This page should have space for the missing apps, add them:
      if (!foundSpotForMissingApps) {
        page.push(
          ...[
            missingApps.settings ? appleSystemApps.Settings : undefined,
            missingApps.phone ? appleSystemApps.Phone : undefined,
          ].filter(Boolean),
        );
      }

      // Set this so we don't add the missing apps to multiple pages:
      foundSpotForMissingApps = true;

      return page;
    });

    if (!foundSpotForMissingApps) {
      newPages.push([
        ...[
          missingApps.settings ? appleSystemApps.Settings : undefined,
          missingApps.phone ? appleSystemApps.Phone : undefined,
        ].filter(Boolean),
      ]);
    }
  }

  if (kind === 'ipad' && missingApps.settings) {
    const maxItemsPerPage = PREVIEW_CONFIG[kind].maxPerPage;
    const spotsNeeded = 1;

    // Check for any open spots in existing pages:
    let foundSpotForMissingApps = false;

    newPages = newPages.map((page) => {
      // Skip pages that don't have space for the missing apps:
      if (page.length > maxItemsPerPage - spotsNeeded) {
        return page;
      }

      // The page should have space for the missing apps, add them:
      if (!foundSpotForMissingApps) {
        page.push(appleSystemApps.Settings);
      }

      foundSpotForMissingApps = true;

      return page;
    });

    if (!foundSpotForMissingApps) {
      newPages.push([appleSystemApps.Settings]);
    }
  }

  return newPages;
}

export { transformFromApi, transformToApi };
