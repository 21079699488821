import { i18n } from 'src/i18n';
import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';
import type {
  AppliesToUsersRadioOptions,
  UserAccessSelectOptions,
} from '../devicecontrol.types';
import {
  AppliesToUsersValue,
  UserAccessValue,
  getUserAccessLabel,
} from '../devicecontrol.types';

class DeviceControlService extends NewLibraryItemService {
  static getUserAccessSelectOptions = (): UserAccessSelectOptions => [
    {
      label: getUserAccessLabel().READ_WRITE,
      value: UserAccessValue.READ_WRITE,
    },
    {
      label: getUserAccessLabel().READ_ONLY,
      value: UserAccessValue.READ_ONLY,
    },
    {
      label: getUserAccessLabel().BLOCK,
      value: UserAccessValue.BLOCK,
    },
  ];

  static getUserAccessSelectOptionsNetworkMounts =
    (): UserAccessSelectOptions => [
      {
        label: getUserAccessLabel().READ_WRITE,
        value: UserAccessValue.READ_WRITE,
      },
      {
        label: getUserAccessLabel().BLOCK,
        value: UserAccessValue.BLOCK,
      },
    ];

  static getAppliesToUsersRadioOptions = (): AppliesToUsersRadioOptions => [
    {
      label: i18n.t('All users'),
      value: AppliesToUsersValue.ALL,
    },
    {
      label: i18n.t('Standard users'),
      value: AppliesToUsersValue.STANDARD,
    },
  ];
}

export const deviceControlService = new DeviceControlService();

export default DeviceControlService;
