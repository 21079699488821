/* istanbul ignore file */
// Just merging all the reducers together here
/* istanbul ignore file */
import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { account as accountActionTypes } from '../_actions/action-types';

import account from './account';
import app from './app';
import auth0 from './auth0';
import blueprintRecord from './blueprintRecord';
import company from './company';
import computerRecord from './computerRecord';
import data from './data';
import editor from './editor';
import filters from './filters';
import gSuite from './gSuite';
import parameters from './parameters';
import templates from './templates';
import ui from './ui';
import users from './users';

const appReducer = combineReducers({
  // cleaned
  account,
  auth0,
  ui,
  // old
  app,
  data,
  form,
  users,
  parameters,
  editor,
  blueprintRecord,
  computerRecord,
  company,
  filters,
  gSuite,
  templates,
});

const rootReducer = (state, action) => {
  if (action.type === accountActionTypes.ACCOUNT_LOGOUT) {
    // auth0 is our only persistent state regardless of users state
    state = { auth0: state.auth0 };
  }
  return appReducer(state, action);
};

export default rootReducer;
