/* istanbul ignore file */
// Just a bunch of support functions for redux

import { api } from 'app/api/base';
import { i18n } from 'i18n';
/* istanbul ignore file */
import moment from 'moment';
import { URL_BLUEPRINTS } from '../api/urls';
import { cleanStorageForPaginationAndFilters } from '../components/common/helpers';
import { blueprint } from './action-types';
import { setSnackbar } from './ui';

export const addBlueprint = (object) => ({
  type: blueprint.ADD_BLUEPRINT,
  object,
});

export const setBlueprint = (blueprintToSet) => ({
  type: blueprint.SET_BLUEPRINT,
  blueprint: blueprintToSet,
});

export const setBlueprints = (objects) => ({
  type: blueprint.SET_BLUEPRINTS,
  objects,
});

export const setBlueprintMessage = (blueprintId, message) => ({
  type: blueprint.SET_BLUEPRINT_MESSAGE,
  payload: {
    blueprintId,
    message,
  },
});

export const setBlueprintWithoutChangingTab = (blueprintToSet, activeTab) => ({
  type: blueprint.SET_BLUEPRINT_WITHOUT_CHANGING_TAB,
  blueprint: blueprintToSet,
  activeTab,
});

export const updateBlueprint = (updates) => {
  if (updates.activeTab) {
    cleanStorageForPaginationAndFilters();
  }
  return {
    type: blueprint.UPDATE_BLUEPRINT,
    updates,
  };
};

export const decreaseBlueprintComputersCount = (numberToDecrease) => ({
  type: blueprint.DECREASE_BLUEPRINT_COMPUTERS_COUNT,
  numberToDecrease,
});

export const annotateBlueprint = (blueprintToAnnotate) => ({
  ...blueprintToAnnotate,
  companyId: blueprintToAnnotate.company_id || 'No info',
  computersCount: blueprintToAnnotate.computers_count,
  missingComputersCount: blueprintToAnnotate.missing_computers_count,
  parametersCount: Object.keys(blueprintToAnnotate.params).length,
});

export const getBlueprints = () => (dispatch) =>
  api(URL_BLUEPRINTS)
    .get()
    .then((res) => {
      const annotatedBlueprints = res.data.map((annBlueprint) =>
        annotateBlueprint(annBlueprint),
      );
      dispatch(setBlueprints(annotatedBlueprints));
      return Promise.resolve(annotatedBlueprints);
    })
    .catch(() => {
      dispatch(setBlueprints([]));
    });

export const startAddBlueprint = (data) => (dispatch) =>
  api(URL_BLUEPRINTS)
    .post(data)
    .then((res) => {
      dispatch(addBlueprint(res.data));
      return Promise.resolve(res.data);
    });

export const startGetBlueprint = (id) => (dispatch, getState) => {
  const { activeTab } = getState().blueprintRecord;
  return api(URL_BLUEPRINTS + id)
    .get()
    .then((res) => {
      const blueprintData = res.data;
      const computers = blueprintData.computers || [];
      const monthAgoTimestamp = moment().subtract(30, 'days').unix();
      let missing = 0;
      for (const c in computers) {
        if (computers[c].infoTimestamp < monthAgoTimestamp) {
          missing += 1;
        }
      }
      if (activeTab === 'computers') {
        dispatch(setBlueprint({ ...blueprintData, missing }));
      } else {
        dispatch(
          setBlueprintWithoutChangingTab(
            { ...blueprintData, missing },
            activeTab,
          ),
        );
      }
    });
};

export const deleteBlueprint = (id) => (dispatch) =>
  api(URL_BLUEPRINTS + id)
    .delete()
    .then(() => {
      dispatch({
        type: blueprint.DELETE_BLUEPRINT,
        id,
      });
      return Promise.resolve(true);
    });

export const editBlueprint = ({ blueprintId, data }) =>
  api(`${URL_BLUEPRINTS}${blueprintId}`).patch(data);

export const duplicateBlueprint = (blueprintID, data) => (dispatch) =>
  api(URL_BLUEPRINTS)
    .post({ source: { type: 'blueprint', id: blueprintID }, ...data })
    .then((res) => {
      dispatch(addBlueprint(res.data));
      return Promise.resolve(res.data);
    });

export const createBlueprintFromTemplate = (data) => (dispatch) =>
  api(URL_BLUEPRINTS)
    .post(data)
    .then((res) => {
      dispatch(addBlueprint(res.data));
      return Promise.resolve(res.data);
    });

export const getBlueprintParams = (blueprintId) => (dispatch) => {
  dispatch({
    type: blueprint.GET_BLUEPRINT_PARAMS,
  });
  return api(`${URL_BLUEPRINTS}${blueprintId}/parameters`)
    .get()
    .then((response) => {
      dispatch({
        type: blueprint.SET_BLUEPRINT_PARAMS,
        payload: { params: response.data },
      });
      return Promise.resolve(response.data);
    });
};

export const enableEnrollmentCode = (blueprintId, data) => (dispatch) => {
  dispatch({
    type: blueprint.TURN_ON_ENROLLMENT_CODE,
    payload: { blueprintId, data },
  });
  return api(`${URL_BLUEPRINTS}${blueprintId}/turn-on-enrollment-code`).patch(
    data,
  );
};

export const generateNewEnrollmentCode = (blueprintId) => (dispatch) =>
  api(`${URL_BLUEPRINTS}${blueprintId}/change-enrollment-code`)
    .patch()
    .then((response) => {
      dispatch({
        type: blueprint.CHANGE_ENROLLMENT_CODE,
        payload: { blueprintId, data: response.data },
      });
      return Promise.resolve();
    })
    .catch(() => {
      dispatch(setSnackbar(i18n.common.error()));
      return Promise.reject(new Error(i18n.common.error()));
    });
