import type { ChangeEvent } from 'react';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Toaster as toaster } from '@kandji-inc/bumblebee';
import { Flex } from '@kandji-inc/nectar-ui';

import { getBlueprintParams as callGetBlueprintParams } from 'src/app/_actions/blueprint';
import { queryParamHistory } from 'src/app/_actions/parameters';
import { getLibraryItemStatusesForComputer } from 'src/app/components/library/api';

import type {
  MapStateToProps,
  Status,
  StatusTabProps,
} from './device-status-tab.types';

import { i18n } from 'i18n';
import { STATUS_MAP } from './constants';
import Empty from './empty';
import { filterBySearch, filterByStatus, filterByType } from './filters';
import {
  allowStatusesByType,
  annotateParameterResults,
  getParameterStatusesRequestQuery,
  getParametersWithoutStatuses,
  getStatusOptions,
  getTypeOptions,
} from './helpers';
import { noData } from './images';
import StatusSearchFilters from './search-filters';
import { StatusTables } from './tables';

const StatusTab = (props: StatusTabProps) => {
  const {
    computer,
    blueprints,
    blueprintNames,
    blueprintParameters,
    parameterMetadata,
    getBlueprintParams,
  } = props;

  const [libraryItemStatuses, setLibraryItemStatuses] = useState([]);
  const [parameterStatuses, setParameterStatuses] = useState([]);

  const [hasFetchedLibraryItemStatuses, setHasFetchedLibraryItemStatuses] =
    useState(false);
  const [hasFetchedParameterStatuses, setHasFetchedParameterStatuses] =
    useState(false);
  const [hasFetchedBlueprintParameters, setHasFetchedBlueprintParameters] =
    useState(false);

  const [searchInput, setSearchInput] = useState('');
  const [typeFilter, setTypeFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);

  const { allowedStatuses, allowedTypes } = allowStatusesByType(
    libraryItemStatuses,
    parameterStatuses,
  );

  const typeOptions = getTypeOptions(allowedTypes);
  const { statusOptions, defaultStatusFilterOptions } =
    getStatusOptions(allowedStatuses);

  // Set initial state of the "Type" and "Status" filters to "All" after statuses have been fetched
  useEffect(() => {
    if (hasFetchedLibraryItemStatuses && hasFetchedParameterStatuses) {
      const { statusValues } = getStatusOptions(allowedStatuses);
      const statusWithoutExcluded = statusValues.filter(
        (status) => STATUS_MAP[status] !== STATUS_MAP.EXCLUDED,
      );

      setTypeFilter(allowedTypes);
      setStatusFilter(statusWithoutExcluded);
    }
  }, [
    libraryItemStatuses,
    parameterStatuses,
    hasFetchedLibraryItemStatuses,
    hasFetchedParameterStatuses,
  ]);

  const hasStatusData = allowedStatuses?.length;

  const getLibraryItemStatuses = () => {
    getLibraryItemStatusesForComputer(computer.id)
      .then((data: Array<Status>) => {
        setLibraryItemStatuses(data);
        setHasFetchedLibraryItemStatuses(true);
      })
      .catch((err: any) => {
        toaster(i18n.t('An error occurred while loading records.'));
      });
  };

  // Retrieve Library Item statuses on first render
  useEffect(() => {
    getLibraryItemStatuses();
  }, []);

  const getParameterStatuses = () => {
    // Get parameters if device is a Mac and is assigned to a Blueprint
    if (computer.device_family === 'Mac' && computer.blueprint_id) {
      const requestQuery = getParameterStatusesRequestQuery(computer);

      queryParamHistory(requestQuery).then(
        ({ results: parametersWithStatuses }) => {
          const parametersWithoutStatuses = getParametersWithoutStatuses(
            blueprintParameters,
            parametersWithStatuses,
          );

          const allParameterStatuses = [
            ...(parametersWithStatuses ?? []),
            ...(parametersWithoutStatuses ?? []),
          ];

          const annotatedParameterStatuses = annotateParameterResults(
            blueprintNames,
            parameterMetadata,
            allParameterStatuses,
          );

          setParameterStatuses(annotatedParameterStatuses);
          setHasFetchedParameterStatuses(true);
        },
      );
    } else {
      setHasFetchedParameterStatuses(true);
    }
  };

  // Retrieve Blueprint Parameters on first render
  useEffect(() => {
    if (blueprints.find((bp) => bp.id === computer.blueprint_id)) {
      getBlueprintParams(computer.blueprint_id);
    }

    setHasFetchedBlueprintParameters(true);
  }, []);

  // Retrieve Parameter statuses after Blueprint Parameters have been retrieved
  useEffect(() => {
    if (hasFetchedBlueprintParameters) {
      getParameterStatuses();
    }
  }, [blueprintParameters, hasFetchedBlueprintParameters]);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) =>
    setSearchInput(e.target.value.trim().toLowerCase());

  const filterStatuses = (statuses: Array<Status>) => {
    const statusesFilteredByType = filterByType(statuses, typeFilter);
    const statusesFilteredByStatus = filterByStatus(
      statusesFilteredByType,
      statusFilter,
    );
    const result = filterBySearch(statusesFilteredByStatus, searchInput);

    return result;
  };

  const filteredStatuses = filterStatuses(allowedStatuses);
  const computerBlueprint = blueprints.find(
    ({ id }) => id === computer.blueprint_id,
  );

  if (hasFetchedLibraryItemStatuses && hasFetchedParameterStatuses) {
    const computerWithBlueprint = {
      ...computer,
      blueprint: computerBlueprint,
    };

    return hasStatusData ? (
      <Flex flow="column" gap="xl">
        <StatusSearchFilters
          typeOptions={typeOptions}
          typeFilter={typeFilter}
          setTypeFilter={setTypeFilter}
          statusOptions={statusOptions}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          defaultStatusFilterOptions={defaultStatusFilterOptions}
          handleSearchInputChange={handleSearchInputChange}
        />

        <StatusTables
          computer={computerWithBlueprint}
          filteredStatuses={filteredStatuses}
        />
      </Flex>
    ) : (
      <Empty
        image={noData}
        title="There are no Library Items or Parameters in this Device's Blueprint"
        message="Visit the assigned Blueprint to start configuring."
        link={{
          label: 'View Blueprint',
          route: `/blueprints/${computer?.blueprint_id}`,
          show: !!computer?.blueprint_id,
        }}
      />
    );
  }

  return null;
};

const mapStateToProps = (state: MapStateToProps) => ({
  computer: state.computerRecord,
  blueprints: state.data.blueprints,
  blueprintNames: state.data.blueprintNames,
  blueprintParameters: state.data.blueprintParams,
  parameterMetadata: state.data.parameterMeta,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ getBlueprintParams: callGetBlueprintParams }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StatusTab);
