import { Button, Table } from '@kandji-inc/bumblebee';
import React, { useMemo } from 'react';

import WifiService from 'features/library-items/items/wifi/service/wifi-service';
import { i18n } from 'src/i18n';
import GenericAppIcon from '../../../assets/generic-app-icon.svg';
import { appsTypes, getTags } from '../../../fast-lane-card.model';

const keys = WifiService.keys.fastlane;

const renderTag = (tag, idx) => (
  <span key={`wifi-app-${idx}-tag-${tag}`} className="content__info-tag">
    <p className="b-txt">{tag}</p>
  </span>
);

const AppNameCell = ({ item }) => {
  const { icon, name, tags } = item;
  return (
    <div className="k-wifiv2-fastlane-table__name-row">
      <div className="k-wifiv2-fastlane-table__name-row-logo">
        <img src={icon} alt="Icon" />
      </div>
      <div className="k-wifiv2-fastlane-table__name-row-name">
        <p className="b-txt-bold">{name}</p>
      </div>
      {tags && (
        <div className="k-wifiv2-fastlane-table__name-row-tags">
          {tags.map(renderTag)}
        </div>
      )}
    </div>
  );
};

const BundleIdCell = ({ item }) => {
  const bundleId = item?.bundleId;
  return <p className="b-txt">{bundleId}</p>;
};

const ActionsCell = ({ setIsVisible, isDisabled }) => (
  <Button
    kind="link"
    disabled={isDisabled}
    icon="ellipsis-vertical"
    theme="dark"
    onClick={() => setIsVisible(true)}
  />
);

const getColumns = ({ setIsVisible, isDisabled }) => [
  {
    fieldName: 'name',
    header: i18n.t('App Name'),
    className: 'k-wifiv2-fastlane-table__name-column',
    Cell: AppNameCell,
  },
  {
    fieldName: 'bundle',
    header: i18n.t('Bundle ID'),
    className: 'k-wifiv2-fastlane-table__bundle-column',
    Cell: BundleIdCell,
  },
  {
    fieldName: 'actions',
    header: '',
    className: 'k-wifiv2-fastlane-table__actions-column',
    Cell: () => (
      <ActionsCell setIsVisible={setIsVisible} isDisabled={isDisabled} />
    ),
  },
];

export const getProcessedApps = (apps, addedApps) => {
  const processedApps = addedApps
    .map((addedApp) => {
      if (addedApp.type === appsTypes.CUSTOM) {
        const foundApp = apps.find(
          (app) => app.data.bundle_id === addedApp.bundleId,
        );
        return {
          ...addedApp,
          icon: foundApp?.data?.icon || GenericAppIcon,
          tags: foundApp && getTags(foundApp),
        };
      }
      if (addedApp.type === appsTypes.VPP) {
        const foundApp = apps.find(
          (app) => app?.data?.bundle_id === addedApp.id,
        );
        if (!foundApp) {
          return null;
        }
        return {
          ...addedApp,
          ...foundApp,
          tags: getTags(foundApp),
          icon: foundApp?.data.icon || GenericAppIcon,
          bundleId: foundApp?.data.bundle_id,
        };
      }
      // in case if somehow type isnt defined
      return null;
    })
    .filter(Boolean);

  return processedApps;
};

const Apps = ({
  setIsVisible,
  setting,
  apps,
  update,
  isDisabled,
  isLoadingApps,
}) => {
  const columns = useMemo(
    () => getColumns({ setIsVisible, isDisabled }),
    [setIsVisible, isDisabled],
  );
  const addedApps = setting[keys.addedApps] || [];
  const processedApps = useMemo(
    () => getProcessedApps(apps, addedApps),
    [apps, addedApps],
  );
  return (
    <>
      <div className="k-wifiv2-fastlane-table">
        {!isLoadingApps && <Table columns={columns} data={processedApps} />}
      </div>
      <div className="k-wifiv2-fastlane-table__btn">
        <Button
          kind="link"
          isProgress={isLoadingApps}
          icon={isLoadingApps ? 'arrows-rotate' : 'circle-plus'}
          onClick={() => setIsVisible(true)}
          isDisabled={isDisabled}
        >
          {i18n.t(`Add App`)}
        </Button>
      </div>
    </>
  );
};

export default Apps;
