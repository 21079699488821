import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class CustomPrinterService extends NewLibraryItemService {
  static installationTypes = {
    INSTALL_ONCE: 'install_once',
    CONTINUOUSLY_ENFORCE: 'continuously_enforce',
    NO_ENFORCEMENT: 'no_enforcement',
  };

  static ppdTypes = {
    CUSTOM: 'custom',
    GENERIC: 'generic',
    AIRPRINT: 'airprint',
    UPLOAD: 'upload',
  };

  static ppdPath = {
    [CustomPrinterService.ppdTypes.GENERIC]:
      '/System/Library/Frameworks/ApplicationServices.framework/Versions/A/Frameworks/PrintCore.framework/Resources/Generic.ppd',
    [CustomPrinterService.ppdTypes.AIRPRINT]:
      '/System/Library/Frameworks/ApplicationServices.framework/Versions/A/Frameworks/PrintCore.framework/Resources/AirPrint.ppd',
  };
}

export const customPrinterService = new CustomPrinterService();
export default CustomPrinterService;
