import Snackbar from '@material-ui/core/Snackbar';
import { clearSnackbar } from 'app/_actions/ui';
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseIcon } from './IconsSVG';

const UiSnackbar = ({ open, message, sidebarOpened, clearSnackbar }) => (
  <div style={{ zIndex: 1000000, position: 'absolute' }}>
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={open}
      classes={{
        root: classNames('snackbar-root', { 'sidebar-opened': sidebarOpened }),
      }}
      message={message}
      autoHideDuration={3000}
      transitionDuration={400}
      onClose={clearSnackbar}
    >
      <div>
        {message}
        <i onClick={clearSnackbar}>{CloseIcon}</i>
      </div>
    </Snackbar>
  </div>
);

const mapStateToProps = (state) => ({
  message: state.ui.snackbar.message,
  open: state.ui.snackbar.open,
  sidebarOpened: state.ui.sidebar.opened,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ clearSnackbar }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UiSnackbar);
