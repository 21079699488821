import React, { useEffect } from 'react';

import { bool, func, string } from 'prop-types';

import { Flex, modal as Modal } from '@kandji-inc/bumblebee';
import { Banner, Button, Icon, Text } from '@kandji-inc/nectar-ui';

import RulesBuilder, { useBuilder } from '../builder';

function BuilderModal(props) {
  const {
    origin = 'library',
    isOpen,
    setIsOpen,
    withoutCloseButton = false,
    disableCloseOnOutsideClick = false,
  } = props;

  const { resetForm, fetchRuleset, updateRuleset, isConfirmDisabled } =
    useBuilder();

  const isBlueprintFlow = origin === 'flow';

  const onClose = (closed) => {
    setIsOpen(false);

    if (closed) {
      setTimeout(() => closed(), 500);
    }
  };

  const onCancel = (event) => {
    /* istanbul ignore next - browser event methods */
    if (isBlueprintFlow && event) {
      event.preventDefault();
      event.stopPropagation();
    }

    onClose(resetForm);
  };

  const onConfirm = (event) => {
    /* istanbul ignore next - browser event methods */
    if (isBlueprintFlow) {
      event.preventDefault();
      event.stopPropagation();
    }

    updateRuleset();

    onClose(resetForm);
  };

  useEffect(() => {
    if (isOpen) {
      fetchRuleset();
    }
    return () => {
      resetForm();
    };
  }, [isOpen]);

  return (
    <Modal
      classes={{ root: 'k-rules-library-modal' }}
      isOpen={isOpen}
      onClose={onCancel}
      withoutCloseButton={withoutCloseButton}
      disableCloseOnOutsideClick={disableCloseOnOutsideClick}
      animationDuration={250}
      parts={{
        header: {
          text: 'Assignment Rules',
        },
        content: {
          children: (
            <>
              <p className="b-txt">
                {origin === 'flow'
                  ? `Use rules to limit which devices assigned to the Blueprint will be assigned the Library Items in this assignment node. Library Items are assigned when all criteria below are met.`
                  : `Use rules to limit which devices assigned to a Classic Blueprint will be assigned this Library Item. The Library Item is assigned when all criteria below are met.`}
              </p>

              {origin === 'library' && (
                <Banner
                  css={{
                    alignItems: 'center',
                    marginTop: '$4',
                    '& svg': {
                      width: '14px',
                      height: '14px',
                    },
                  }}
                  text={
                    <Text>
                      Rules configured here for the Library Item are independent
                      of any rules configured on an{' '}
                      <a
                        href="https://support.kandji.io/support/solutions/articles/72000627627"
                        rel="noopener noreferrer"
                        target="_blank"
                        className="b-alink"
                      >
                        Assignment Map{' '}
                        <Icon name="arrow-up-right-from-square" size="xs" />
                      </a>
                      , and do not apply there.
                    </Text>
                  }
                />
              )}

              <div className="k-rules-library-builder b-mt">
                <RulesBuilder />
              </div>
            </>
          ),
        },
        footer: {
          children: (
            <Flex>
              <Button variant="subtle" onClick={onCancel}>
                Cancel
              </Button>

              <Button
                variant="primary"
                className="l-ml"
                onClick={onConfirm}
                disabled={isConfirmDisabled}
                id="for-pendo__rules-confirm-btn"
              >
                Confirm
              </Button>
            </Flex>
          ),
        },
      }}
    />
  );
}

BuilderModal.propTypes = {
  origin: string,
  isOpen: bool.isRequired,
  setIsOpen: func.isRequired,
  withoutCloseButton: bool,
  disableCloseOnOutsideClick: bool,
};

export default BuilderModal;
