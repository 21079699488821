import { i18n } from 'src/i18n';

export const bundleValidator = (v) =>
  v && !/^[A-Z0-9.-]+\.[A-Z0-9.-]+\.[A-Z0-9.-]+$/i.test(v)
    ? 'Invalid Bundle Identifier'
    : undefined;

export const devIDValidator = (v) =>
  v && !/^[A-Z0-9]+$/i.test(v) ? 'Invalid Developer ID format' : undefined;

export const fullPathValidator = (v) => {
  if (v && !v.startsWith('/')) {
    return 'Path must start with a /';
  }
  return undefined;
};

export const onEmptyBlurValidator = (
  v,
  { message = i18n.t('Required'), trigger = ['onBlur', 'onInput'] } = {},
) => ({
  invalid: () => typeof v !== 'string' || !v.length,
  message,
  trigger,
});
