import { i18n } from 'src/i18n';

const defaultValidations = (v) => {
  const r1 = {
    message: i18n.t('Required'),
    invalid: () => !v.length,
    trigger: ['onBlur', 'onInput'],
  };
  return r1;
};

export default defaultValidations;
