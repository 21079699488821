import { Button } from '@kandji-inc/bumblebee';
import trim from 'lodash/trim';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { duplicateBlueprint as callDuplicateBlueprint } from '../../../_actions/blueprint';
import history from '../../../router/history';
import AwesomeInput from '../AwesomeInput';
import AwesomeTextArea from '../AwesomeTextArea';
import { ModalWrapper } from './ModalWrapper';
import { ButtonsWrapper, CancelButtonWrapper } from './elements';

const InputWrapper = styled.div`
  padding-bottom: 32px;
`;

export class DuplicateBlueprint extends ModalWrapper {
  constructor(props) {
    super(props);
    this.modalName = 'BlueprintEdit';
    const { id, name, description } = props.info;
    this.state = {
      id,
      name,
      description,
    };
    this.onSave = this.onSave.bind(this);
  }

  onSave() {
    const { id, name, description } = this.state;
    const { info, duplicateBlueprint, blueprints, setSnackbar, getBlueprints } =
      this.props;
    const duplicateName = trim(name);
    if (!duplicateName || duplicateName === info.name) {
      return this.nameInput.focus();
    }
    if (
      this.isDuplicatedName(
        blueprints.filter((el) => el.id !== id),
        duplicateName,
      )
    ) {
      setSnackbar('Blueprint name already exists');
      return this.nameInput.focus();
    }
    this.setState({ isLoading: true });
    duplicateBlueprint(id, { name: duplicateName, description })
      .then((res) => {
        this.setState({ isLoading: false });
        setSnackbar('Blueprint was duplicated');
        history.push(`/blueprints/${res.id}`);
        this.onHide();
        getBlueprints();
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        setSnackbar(err.message);
      });
    return null;
  }

  renderHeader = () => 'Duplicate Blueprint';

  renderBody() {
    const { name, description } = this.state;
    return (
      <div>
        <p className="break-long-words">
          {`All Parameter settings from ${this.props.info.name} will be duplicated.
          Device records will NOT be moved.`}
        </p>
        <InputWrapper>
          <AwesomeInput
            name="name"
            placeholder="Blueprint Name"
            value={name}
            innerRef={(el) => {
              this.nameInput = el;
            }}
            onChange={this.onChange}
          />
        </InputWrapper>
        <AwesomeTextArea
          rows="5"
          name="description"
          placeholder="Blueprint Description"
          value={description}
          innerRef={(el) => {
            this.descriptionInput = el;
          }}
          onChange={this.onChange}
          maxLength="500"
        />
      </div>
    );
  }

  renderFooter() {
    const { name, isLoading } = this.state;
    const { info } = this.props;
    return (
      <ButtonsWrapper>
        <CancelButtonWrapper>
          <Button type="button" onClick={this.onHide} kind="outline">
            Cancel
          </Button>
        </CancelButtonWrapper>
        <Button
          type="button"
          disabled={isLoading || !name || trim(name) === info.name}
          onClick={this.onSave}
        >
          Duplicate
        </Button>
      </ButtonsWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      duplicateBlueprint: callDuplicateBlueprint,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(DuplicateBlueprint);
