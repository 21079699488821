import {
  MultiSelectSearch,
  useInputsValidators,
  useIsChanged,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import React, { useEffect } from 'react';

import { Setting } from 'features/library-items/template';
import { i18n } from 'src/i18n';

import WifiService from '../../service/wifi-service';
import { protocolOptions } from '../protocols-card.model';

const fieldName =
  WifiService.keys.authentication.acceptedEapTypes.acceptedEapTypes;

const fieldsToValidate = [fieldName];

const AcceptedEapTypes = (props) => {
  const { setting, update, isDisabled, validationDep } = props;
  const { refs, onInvalidate, invalidations } = useInputsValidators(
    fieldsToValidate,
    update,
  );
  useRemoveValidationOnUnmount(fieldsToValidate, update);
  const showError = useIsChanged(validationDep);

  useEffect(() => {
    onInvalidate(fieldName)(setting[fieldName]?.length === 0 && 'Required');
  }, [setting[fieldName]]);

  return (
    <>
      <Setting.Title>
        <p className="b-txt">{i18n.t(`Accepted EAP Types`)}</p>
      </Setting.Title>
      <Setting.Helpers>
        <p ref={refs[fieldName]} className="b-txt-light">
          {i18n.t(
            `The authentication protocol(s) used to connect to the network.`,
          )}
        </p>
      </Setting.Helpers>
      <Setting.Controls>
        <MultiSelectSearch
          placeholder={i18n.t('Select Option')}
          isDisabled={isDisabled}
          disabled={isDisabled}
          options={protocolOptions}
          compact
          onChange={(v) => update(fieldName, v)}
          values={setting[fieldName]}
          searchPlaceholder={i18n.t('Search')}
          errorText={showError && invalidations[fieldName]}
          // testId="eap-types-select"
          extra={{ testId: 'eap-types-select' }}
        />
      </Setting.Controls>
    </>
  );
};

export default AcceptedEapTypes;
