import { Button } from '@kandji-inc/bumblebee';
import React, { useEffect, useState } from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { links } from 'src/app/common/constants';

import useLostMode from 'src/hooks/useLostMode';
import styled from 'styled-components';
import HiveIcon from '../HiveIcon';

const PendingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;

  .pending-tab__main-header {
    font-weight: bold;
    font-size: 16px;
    padding: 14px 0 14px 0;
  }

  .pending-tab__main-description {
    max-width: 440px;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    font-family:
      Atlas Grotesk Web,
      sans-serif;
    font-weight: 400;
  }
`;

function PendingLostModeTab(props) {
  const { getComputer, computer, setSnackbar } = props;
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [timeoutId, setTimeoutId] = useState('');
  const match = useRouteMatch();

  const { isLostModePending = true } = useLostMode(props?.computer);

  useEffect(() => () => window.clearTimeout(timeoutId), [timeoutId]);

  const handleRefreshPage = (e) => {
    e.preventDefault();
    getComputer(computer.id, 'lost-mode')
      .then(() => {
        setSnackbar('Page refreshed, waiting for device to respond.');
        setIsButtonDisabled(true);
        const id = window.setTimeout(() => {
          setIsButtonDisabled(false);
        }, 3000);
        setTimeoutId(id);
      })
      .catch((error) => {
        setSnackbar(error.message);
      });
  };

  if (!isLostModePending) {
    return <Redirect to={`${links.devices}/${match.params.id}/status`} />;
  }

  return (
    <PendingContainer>
      <HiveIcon />
      <div className="pending-tab__main-header">Lost Mode Pending...</div>
      <p className="pending-tab__main-description">
        We’ve sent a command to activate Lost Mode on {computer?.name}. Once it
        receives the command, the device will be locked and you will be able to
        track its location here.
      </p>

      <Button
        onClick={handleRefreshPage}
        disabled={isButtonDisabled}
        icon="arrow-rotate-right"
        kind="link"
      >
        Refresh Page
      </Button>
    </PendingContainer>
  );
}

export default PendingLostModeTab;
