/* istanbul ignore next */
const testRegex = (regex, value) => {
  const r = new RegExp(regex);

  return r.test(value);
};

const characterLimitValidator = (v, limit, message) => ({
  invalid: () => v.length > limit,
  trigger: ['onBlur', 'onInput'],
  message,
});

const regexValidator = (v, regex, message) => ({
  invalid: () => v && !testRegex(regex, v),
  trigger: ['onBlur', 'onInput'],
  message,
});

export { characterLimitValidator, regexValidator };
