/* istanbul ignore file */
import { func, shape, string } from 'prop-types';
import React from 'react';

import { getValueClient } from 'src/features/rules/use-rule-service';

import useBuilder from '../../use-builder';
import MultiSelectApi from './multi-select-api';

const MultiSelectAPI = ({
  fieldKey,
  apiData,
  updateField,
  getValue,
  ariaLabel,
  ...rest
}) => {
  const { type, endpoint, search_param: searchParam } = apiData;
  const client = getValueClient(type, endpoint);

  const { countOfUserDirectoryIntegrations } = useBuilder();

  const onChange = (selection) =>
    updateField(
      fieldKey,
      selection?.map(({ value }) => value),
    );

  const props = {
    client,
    fieldKey,
    searchParam,
    getValue,
    onChange,
    ariaLabel,
    ...rest,
  };

  const defaultGetLabel = (option) => option.name;

  switch (apiData.type) {
    case 'integrations': {
      const getLabel = (option) => {
        if (countOfUserDirectoryIntegrations > 1) {
          return `${option.integration_name} - ${option.name}`;
        }
        return option.name;
      };
      return <MultiSelectApi {...props} getLabel={getLabel} />;
    }
    default:
      return <MultiSelectApi {...props} getLabel={defaultGetLabel} />;
  }
};

MultiSelectAPI.propTypes = {
  fieldKey: string.isRequired,
  apiData: shape({ type: string, endpoint: string, search_param: string })
    .isRequired,
  updateField: func.isRequired,
  getValue: func.isRequired,
  ariaLabel: string.isRequired,
};

export default MultiSelectAPI;
