import { Setting } from 'features/library-items/template';
import React from 'react';

const LicensesCard = (props) => {
  const { setting } = props;

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">Licenses</h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <div className="b-txt">
            {setting.used} of {setting.total} licenses used.
          </div>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default LicensesCard;
