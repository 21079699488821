import { Code, Flex, styled } from '@kandji-inc/nectar-ui';
import EDRCopyButton from 'src/features/edr/common/components/EDRCopyButton';
import ThreatListHighlightedText from './ThreatListHighlightedText';

const StyleCode = styled(Code, {
  padding: '2px 4px',
  fontSize: 12,
  lineHeight: '12px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflowWrap: 'break-word',
  flexGrow: 1,
  flexShrink: 1,
});

const HashCell = styled(Flex, {
  lineHeight: '18px',
  width: 'fit-content',
  marginRight: 15,
  flexGrow: 1,
  '& > div:last-child': {
    visibility: 'hidden',
  },
  '&:hover': {
    '& > div': {
      visibility: 'visible',
    },
  },
});

const ThreatListHashPathCode = ({
  value,
  truncate = false,
  copy = true,
  highlight,
  lineHeight,
}: {
  value: string | undefined;
  truncate?: boolean;
  copy?: boolean;
  highlight?: string | undefined;
  lineHeight?: string;
}) => (
  <HashCell gap="xs" alignItems="center" title={value}>
    <StyleCode
      css={{
        width: truncate ? 1 : undefined,
        lineHeight: lineHeight ? lineHeight : undefined,
      }}
    >
      <ThreatListHighlightedText search={highlight}>
        {value}
      </ThreatListHighlightedText>
    </StyleCode>
    {copy && <EDRCopyButton value={value} />}
  </HashCell>
);

export default ThreatListHashPathCode;
