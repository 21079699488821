import { useInputsValidators } from '@kandji-inc/bumblebee';
import React, { useState } from 'react';
import { i18n } from 'src/i18n';

import WifiService from 'features/library-items/items/wifi/service/wifi-service';
import Drawer from '../../../../components/drawer';
import { useGetApps } from '../../../fast-lane-card.hooks';
import DrawerContent from '../drawer-content';
import Apps from './apps';
import EmptyApps from './empty-apps';

const keys = WifiService.keys.fastlane;

const fieldsToValidate = [];
const SpecificApps = ({ setting, update, validationDep, isDisabled }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { onInvalidate } = useInputsValidators(fieldsToValidate, update);
  const [apps, appsLoaded] = useGetApps();
  const addedApps = setting[keys.addedApps] || [];
  const showEmptyApps = !addedApps.length;
  const showApps = addedApps.length > 0;
  const renderDrawer = appsLoaded;
  return (
    <div className="k-li-top-border">
      {showEmptyApps && (
        <EmptyApps
          setIsVisible={setIsVisible}
          isDisabled={isDisabled}
          isLoadingApps={!appsLoaded}
        />
      )}
      {showApps && (
        <Apps
          setIsVisible={setIsVisible}
          setting={setting}
          apps={apps}
          update={update}
          isDisabled={isDisabled}
          isLoadingApps={!appsLoaded}
        />
      )}
      {renderDrawer && (
        <Drawer
          update={update}
          defaults={{}}
          setting={setting}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          validationDep={validationDep}
          onInvalidate={onInvalidate}
          DrawerContent={DrawerContent}
          title={i18n.t('Allow apps for Fast Lane')}
          externalFields={[]}
          apps={apps}
          appsLoaded={appsLoaded}
        />
      )}
    </div>
  );
};

export default SpecificApps;
