import { MANAGE_MEDIA_ACCESS_FIELDS } from './sections/manage-media-access/constants';

const manageMediaAccessValues = MANAGE_MEDIA_ACCESS_FIELDS.reduce(
  (acc, val) => {
    acc[val.value] = 'allow';
    return acc;
  },
  {},
);

export default {
  id: null,
  name: '',
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  isActive: true,
  general: {
    DisconnectAllMediaAtLogout: false,
    DisableMediaAutoActions: false,
    isManagingDiscBurning: false,
    ManageDiscBurning: 'off',
  },
  manageMediaAccess: manageMediaAccessValues,
  supportsRules: true,
};
