import React, { createContext, useState, useContext, useEffect } from 'react';

import { array, bool, func, node, number, object } from 'prop-types';

import uuid from 'uuid/v4';

import { LI_OS_VERSION_DEFAULT_OPERATOR, TEXT_TYPES } from '../constants';

import {
  transformJsonLogicToRuleBuilder,
  transformRuleBuilderToJsonLogic,
} from '../transformers';

/* istanbul ignore next - these are the default values which are set as part of the BuilderProvider */
const BuilderContext = createContext({
  facetMap: {},

  rule: {}, // initial state of a "rule" row as defined below in the BuilderProvider
  rules: null,

  form: {},
  setForm: () => {},
  updateForm: () => {},
  resetForm: () => {},

  supportedDeviceFamilies: [],
  installOnDeviceFamilies: [],

  setModel: () => {},

  fetchRuleset: () => {},
  updateRuleset: () => {},
  deleteRuleset: () => {},

  isConfirmDisabled: false,

  countOfUserDirectoryIntegrations: 0,
  hasUserDirectoryIntegration: true,
});

const BuilderProvider = ({
  facetMap,
  rules,
  setModel,
  supportedDeviceFamilies,
  installOnDeviceFamilies,
  countOfUserDirectoryIntegrations,
  hasUserDirectoryIntegration,
  children,
  value,
}) => {
  const rule = {
    key: uuid(),
    subject: '',
    input: '',
    operator: '',
    value: '',
  };

  const initial = {
    and: [rule],
  };

  const [form, setForm] = useState(initial);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(true);

  const { and } = form;

  // Removes the facets that are not included within 'supportedDeviceFamilies'
  const refinedFacetMap = {};
  Object.keys(facetMap).forEach((facet) => {
    const { device_families: deviceFamilies } = facetMap[facet];

    deviceFamilies?.forEach((deviceFamily) => {
      if (supportedDeviceFamilies?.includes(deviceFamily)) {
        refinedFacetMap[facet] = facetMap[facet];
      }
    });
  });

  const updateForm = (k, v) => setForm((p) => ({ ...p, [k]: v }));

  const resetForm = () => {
    setForm(initial);
  };

  const fetchRuleset = () => {
    if (rules) {
      setForm(transformJsonLogicToRuleBuilder(rules));
    }
  };

  /* istanbul ignore next */
  const updateRuleset = () =>
    setModel((p) => ({
      ...p,
      rules: transformRuleBuilderToJsonLogic(form),
    }));

  /* istanbul ignore next */
  const deleteRuleset = () => {
    setModel((p) => ({
      ...p,
      rules: null,
    }));
  };

  // Check that a given rule is filled out and is not breaking validation checks
  const checkRuleValidity = (row) => {
    let hasCompleteValue =
      row.value ||
      row.operator === LI_OS_VERSION_DEFAULT_OPERATOR ||
      row.operator === TEXT_TYPES.IS_BLANK ||
      row.operator === TEXT_TYPES.IS_NOT_BLANK;

    // If the Value is an Array, ensure that it has entries and that none of them are empty
    if (Array.isArray(row.value)) {
      hasCompleteValue = row.value.length && !row.value.includes('');
    }

    return row.operator && hasCompleteValue && !row.isInvalid;
  };

  useEffect(() => {
    const invalidRowExists = !and.every((row) => {
      if (row.children) {
        // Check that each child is filled out and not breaking validation checks
        const allChildrenAreValid = Object.keys(row.children).every((child) =>
          checkRuleValidity(row.children[child]),
        );

        // Checks that there is at least one non default child
        const hasAtLeastOneNonDefaultChild = Object.keys(row.children).some(
          (child) =>
            row.children[child].operator !== LI_OS_VERSION_DEFAULT_OPERATOR,
        );

        return allChildrenAreValid && hasAtLeastOneNonDefaultChild;
      }
      return checkRuleValidity(row);
    });

    setIsConfirmDisabled(invalidRowExists);
  }, [form]);

  return (
    <BuilderContext.Provider
      value={{
        facetMap: refinedFacetMap,

        rule,
        rules,

        form,
        setForm,
        updateForm,
        resetForm,

        supportedDeviceFamilies,
        installOnDeviceFamilies,

        setModel,

        fetchRuleset,
        updateRuleset,
        deleteRuleset,

        isConfirmDisabled,

        countOfUserDirectoryIntegrations,
        hasUserDirectoryIntegration,

        ...value,
      }}
    >
      {children}
    </BuilderContext.Provider>
  );
};

BuilderProvider.propTypes = {
  facetMap: object.isRequired,
  rules: object,
  setModel: func.isRequired,
  supportedDeviceFamilies: array.isRequired,
  installOnDeviceFamilies: array.isRequired,
  countOfUserDirectoryIntegrations: number.isRequired,
  hasUserDirectoryIntegration: bool.isRequired,
  children: node.isRequired,
  value: object,
};

BuilderProvider.defaultProps = {
  rules: null,
  value: {},
};

const useBuilder = () => useContext(BuilderContext);

export default useBuilder;
export { BuilderContext, BuilderProvider };
