import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { slugify } from '../../function';

const Dropdown = ({
  type,
  alignment,
  attachment,
  caret,
  children,
  disabled,
  element: Element,
  label,
  name,
  onDropdownClose,
  onDropdownOpen,
  render,
  style,
  className,
  withArrow,
}) => {
  const [toggle, setToggle] = useState(false);
  const [forceAttachment, setForceAttachment] = useState(null);
  const isDropdown = useRef();
  const id = slugify(name);
  const labelEl = useRef();
  // explanation of usage useCallback
  // https://medium.com/welldone-software/usecallback-might-be-what-you-meant-by-useref-useeffect-773bc0278ae
  const menuRef = useCallback((menuEl) => {
    if (!menuEl) {
      return;
    }
    if (menuEl.getBoundingClientRect().bottom > window.innerHeight) {
      setForceAttachment(Dropdown.attachments.bottom);
    }
  });

  const theSetToggle = useCallback(
    (newValue) => {
      if (newValue === toggle) {
        return;
      }
      setToggle(newValue);
      if (newValue) {
        onDropdownOpen();
      } else {
        setForceAttachment(null);
        onDropdownClose();
      }
    },
    [toggle, onDropdownOpen, onDropdownClose],
  );

  const onClick = useCallback(() => {
    theSetToggle(!toggle);
  }, [toggle, theSetToggle]);

  useEffect(() => {
    const onBlur = (event) => {
      if (!isDropdown.current.contains(event.target)) {
        theSetToggle(false);
      }
    };
    if (toggle) {
      document.addEventListener('click', onBlur);
    }
    return () => document.removeEventListener('click', onBlur);
  }, [toggle, theSetToggle]);

  return (
    <Element
      className={`dropdown dropdown-${type} ${
        !disabled && toggle ? 'show' : 'hide'
      }`}
      ref={isDropdown}
    >
      <button
        ref={labelEl}
        type="button"
        id={`${id}-dropdown`}
        className={`dropdown-toggle ${caret ? 'caret' : 'no-caret'}`}
        aria-haspopup="true"
        aria-expanded={!disabled && toggle}
        disabled={disabled}
        onClick={onClick}
      >
        {label}
      </button>
      {!disabled && toggle && (
        <div
          className={`dropdown-menu dropdown-menu-${alignment} dropdown-menu-${
            forceAttachment || attachment
          } show${className ? ` ${className}` : ''}${
            withArrow ? ' dropdown-menu-with-arrow' : ''
          }`}
          aria-labelledby={`${id}-dropdown`}
          style={style}
          ref={menuRef}
        >
          {render ? render(toggle, theSetToggle) : children}
        </div>
      )}
    </Element>
  );
};

Dropdown.attachments = {
  top: 'top',
  bottom: 'bottom',
};

Dropdown.propTypes = {
  type: PropTypes.string,
  alignment: PropTypes.string,
  attachment: PropTypes.oneOf(Object.values(Dropdown.attachments)),
  element: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  caret: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onDropdownClose: PropTypes.func,
  onDropdownOpen: PropTypes.func,
  render: PropTypes.func,
  children: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.string,
  withArrow: PropTypes.bool,
};

const noop = () => null;

Dropdown.defaultProps = {
  type: 'default',
  alignment: 'left',
  attachment: Dropdown.attachments.top,
  element: 'div',
  label: 'Dropdown',
  caret: false,
  disabled: false,
  onDropdownClose: noop,
  onDropdownOpen: noop,
  render: null,
  children: undefined,
  style: {},
  className: '',
  withArrow: false,
};

export default Dropdown;
