import { EDRSplitView } from 'src/features/edr/common/components/EDRSplitView';
import type { Vulnerability } from '../../vulnerability.types';
import { Body } from './body';
import { Header } from './header';

const SplitView = (props: {
  selectedVulnerability: Vulnerability;
  clearSelectedVulnerability: () => void;
}) => {
  const { selectedVulnerability, clearSelectedVulnerability } = props;

  return (
    <EDRSplitView
      header={<Header vulnerability={selectedVulnerability} />}
      body={<Body vulnerability={selectedVulnerability} />}
      onClose={clearSelectedVulnerability}
    />
  );
};

export { SplitView };
