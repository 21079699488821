import React, { createContext, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setAppDataReady as callSetAppDataReady } from '../app/_actions/app';
import { getBlueprints as callGetBlueprints } from '../app/_actions/blueprint';
import { getParamMeta as callGetParamMeta } from '../app/_actions/parameters';
import { AccountContext } from './account';

const defaultValues = {
  appData: undefined,
  appDataReady: false,
  setAppDataReady: () => {},
  getBlueprints: () => {},
  getParamMeta: () => {},
};

const DataContext = createContext(defaultValues);

const DataProviderConnect = ({
  appData,
  appDataReady,
  setAppDataReady,
  getBlueprints,
  getParamMeta,
  children,
  value,
}) => {
  const { accountReady } = useContext(AccountContext);
  useEffect(() => {
    if (accountReady) {
      const promises = [getBlueprints(), getParamMeta()];
      Promise.all(promises).then(setAppDataReady).catch(console.log);
    }
  }, [accountReady]);
  return (
    <DataContext.Provider
      value={{
        ...defaultValues,
        appData,
        appDataReady,
        setAppDataReady,
        getBlueprints,
        getParamMeta,
        ...value,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

const mapStateToProps = ({ app, data }) => ({
  appData: data,
  appDataReady: app.appDataReady,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setAppDataReady: callSetAppDataReady,
      getBlueprints: callGetBlueprints,
      getParamMeta: callGetParamMeta,
    },
    dispatch,
  );

const DataProvider = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DataProviderConnect);

export { DataContext, DataProvider };
