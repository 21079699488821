/* istanbul ignore file */
import { PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import type * as DndCore from '@dnd-kit/core';
import { hasSortableData } from '@dnd-kit/sortable';
import { useCallback, useEffect, useState } from 'react';

import { APP_ICON_CONFIG, PREVIEW_CONFIG } from '../common';
import { MultiSelectKeyboardSensor } from './MultiSelectKeyboardSensor';
import * as dnd from './dnd-helpers';

import type {
  App as ApplicationListApp,
  BaseHomeScreenLayoutDeviceProps,
  Folder,
  HomeScreenLayoutDeviceModelSettings,
  HomeScreenLayoutItem,
} from '../../../home-screen-layout.types';
import type { ActiveProps } from './dnd-helpers';

type PagesArray = HomeScreenLayoutDeviceModelSettings['Pages'];
type _PageItem = PagesArray[number][number];
type DockArray = HomeScreenLayoutDeviceModelSettings['Dock'];
type _DockItem = DockArray[number];
type _FolderArray = Folder['Pages'];
type _FolderItem = _FolderArray[number][number];

interface UseHomeScreenLayoutDndArgs {
  readonly update: BaseHomeScreenLayoutDeviceProps['update'];
  readonly currentPage: number;
  readonly currentFolder: string;
  readonly currentFolderPage: number;
}

const ACTIVATION_CONSTRAINT_DISTANCE = 4;

export function useHomeScreenLayoutDnd({
  update,
  currentPage,
  currentFolder,
  currentFolderPage,
  kind,
}: UseHomeScreenLayoutDndArgs) {
  const [activeItem, setActiveItem] = useState<{
    id: ApplicationListApp['id'] | null;
    props: ActiveProps | null;
  }>({ id: null, props: null });

  const [overItem, setOverItem] = useState<DndCore.Over | null>(null);
  const [selectedItems, setSelectedItems] = useState<HomeScreenLayoutItem[]>(
    [],
  );
  const [dragActivationEvent, setDragActivationEvent] = useState<Event | null>(
    null,
  );

  useEffect(() => {
    if (activeItem === null) {
      setOverItem(null);
    }
  }, [activeItem]);

  const onDragStart = ({ active }: DndCore.DragStartEvent) => {
    const itemId = active?.data?.current?.item?.id;
    setActiveItem({
      id: itemId,
      get props() {
        return { ...active };
      },
    });

    const hasMultiSelected = selectedItems.length > 0;
    const hasActiveInSelected = selectedItems.some(
      (item) => item.id === itemId,
    );
    const isKeyDownEvent = dragActivationEvent?.type === 'keydown';
    const isAddActiveToSelected =
      hasMultiSelected && !hasActiveInSelected && !isKeyDownEvent;

    // istanbul ignore next -- temp ignore to get initial hsl tests merged
    if (isAddActiveToSelected) {
      setSelectedItems((prevItems) => [...prevItems, active.data.current.item]);
    }
  };

  const onDragCancel = () => {
    setActiveItem({ id: null, props: null });
    setSelectedItems([]);
    setDragActivationEvent(null);
  };

  const onDragOver = ({ over }: DndCore.DragOverEvent) => {
    setOverItem(over);
  };

  const onDragEnd = ({ active, over }: DndCore.DragEndEvent) => {
    setActiveItem({ id: null, props: null });
    setSelectedItems([]);
    setDragActivationEvent(null);

    const actionType = dnd.getDragEndActionType(active, over);
    if (!actionType) {
      return false;
    }

    const {
      data: {
        current: { item: currActiveItem },
      },
    }: ActiveProps = active;

    const hasMultiSelected = selectedItems.length > 1;

    switch (actionType) {
      /**
       * Handle case when {@link ApplicationListApp} is dragged from
       * Application List to empty area of Preview Dock zone (i.e. not dropped
       * over any sortable item on the Page).
       *
       * --> Add new {@link ApplicationListApp} at the end of current
       * {@link PagesArray}.
       */
      case 'append_app_to_page': {
        /**
         * Handle adding multiple {@link selectedItems} apps.
         */
        if (hasMultiSelected) {
          return update('Pages', (prevPages) =>
            dnd.appendItemsToPage({
              pages: prevPages,
              toPage: over?.data?.current?.pageIndex,
              items: selectedItems,
            }),
          );
        }

        return update('Pages', (prevPages) =>
          dnd.appendItemsToPage({
            pages: prevPages,
            toPage: over?.data?.current?.pageIndex,
            items: [currActiveItem],
          }),
        );
      }

      case 'move_app_between_page': {
        return update('Pages', (prevPages) =>
          dnd.moveItemsBetweenPages({
            pages: prevPages,
            fromPage: active.data.current.pageIndex,
            toPage: over.data.current.pageIndex,
            items: [currActiveItem],
          }),
        );
      }

      case 'sort_app_between_page': {
        return update('Pages', (prevPages) =>
          dnd.sortItemsBetweenPages({
            pages: prevPages,
            fromPage: active.data.current.pageIndex,
            toPage: over.data.current.pageIndex,
            items: [currActiveItem],
            insertIndex: over.data.current.sortable.index,
          }),
        );
      }

      /**
       * Handle case when {@link ApplicationListApp} is dragged from
       * Application List to Preview Page zone and dropped over any sortable
       * {@link _PageItem} on the current Page.
       *
       * --> Insert new {@link ApplicationListApp} into current
       * {@link PagesArray} at the position between the item(s) over which it
       * was dropped.
       */
      case 'sort_app_to_page': {
        if (!hasSortableData(over)) {
          return false;
        }

        const {
          data: {
            current: {
              sortable: { index: overIdx },
            },
          },
        } = over;

        /**
         * Handle adding multiple {@link selectedItems} apps.
         */
        if (hasMultiSelected) {
          return update('Pages', (prevPages) =>
            dnd.insertItemsToPage({
              pages: prevPages,
              toPage: over.data.current.pageIndex,
              items: selectedItems,
              insertIndex: overIdx,
            }),
          );
        }

        return update('Pages', (prevPages) =>
          dnd.insertItemsToPage({
            pages: prevPages,
            toPage: over.data.current.pageIndex,
            items: [currActiveItem],
            insertIndex: overIdx,
          }),
        );
      }

      /**
       * Handle case when {@link ApplicationListApp} is dragged from
       * Application list to Preview Page's open folder zone
       * --> Add new {@link ApplicationListApp} at the end of {@link PagesArray}
       */
      case 'append_app_to_folder': {
        /**
         * Handle adding multiple {@link selectedItems} apps.
         */
        if (hasMultiSelected) {
          return update('Pages', (prevPages) =>
            dnd.appendItemsToFolder({
              pages: prevPages,
              currentPage,
              currentFolder,
              currentFolderPage: currentFolderPage || 0,
              items: selectedItems,
            }),
          );
        }

        return update('Pages', (prevPages) =>
          dnd.appendItemsToFolder({
            pages: prevPages,
            currentPage,
            currentFolder:
              currentFolder === null
                ? over.data?.current?.item?.id
                : currentFolder,
            currentFolderPage: currentFolderPage || 0,
            items: [currActiveItem],
          }),
        );
      }

      /**
       * Handle case when app is dragged from
       * a page or dock onto a folder
       */
      case 'move_app_to_folder': {
        /* istanbul ignore next */
        if (hasMultiSelected) {
          return update('Pages', (prevPages) =>
            dnd.moveItemsIntoFolder({
              pages: prevPages,
              toFolderId: over.data.current.item.id,
              items: selectedItems,
              kind,
            }),
          );
        }

        return update('Pages', (prevPages) =>
          dnd.moveItemsIntoFolder({
            pages: prevPages,
            toFolderId: over.data.current.item.id,
            items: [currActiveItem],
            kind,
          }),
        );
      }

      /**
       * Handle case when {@link ApplicationListApp} is dragged from
       * Application List to Preview Page's open folder zone and dropped over
       * any sortable {@link _FolderItem} on the open folder page.
       *
       * --> Insert new {@link ApplicationListApp} into current
       * {@link _FolderArray} at the position between the item(s) over which it
       * was dropped.
       */
      case 'sort_app_to_folder': {
        if (!hasSortableData(over)) {
          return false;
        }

        const {
          data: {
            current: {
              sortable: { index: overIdx },
            },
          },
        } = over;

        /**
         * Handle adding multiple {@link selectedItems} apps.
         */
        if (hasMultiSelected) {
          return update('Pages', (prevPages) =>
            dnd.insertItemsToFolder({
              pages: prevPages,
              currentPage,
              currentFolder,
              currentFolderPage,
              items: selectedItems,
              insertIndex: overIdx,
            }),
          );
        }

        return update('Pages', (prevPages) =>
          dnd.insertItemsToFolder({
            pages: prevPages,
            currentPage,
            currentFolder,
            currentFolderPage,
            items: [currActiveItem],
            insertIndex: overIdx,
          }),
        );
      }

      /**
       * Handle case when {@link ApplicationListApp} is dragged from
       * Application List to empty area of Preview Dock zone (i.e. not dropped
       * over any sortable {@link _DockItem} in the Dock).
       *
       * --> Add new {@link ApplicationListApp} at the end of {@link DockArray}.
       */
      case 'append_app_to_dock': {
        /**
         * Handle adding multiple {@link selectedItems} apps.
         */
        if (hasMultiSelected) {
          return update('Dock', (prevDock) =>
            dnd.appendItemsToDock({
              dock: prevDock,
              items: selectedItems,
            }),
          );
        }

        return update('Dock', (prevDock) =>
          dnd.appendItemsToDock({
            dock: prevDock,
            items: [currActiveItem],
          }),
        );
      }

      /**
       * Handle case when {@link ApplicationListApp} is dragged from
       * Application List to Preview Dock zone and dropped over any sortable
       * {@link _DockItem} in the Dock.
       *
       * --> Insert new {@link ApplicationListApp} into {@link DockArray} at
       * the position between the item(s) over which it was dropped.
       */
      case 'sort_app_to_dock': {
        if (active.data.current.item.Type === 'Folder') {
          return false;
        }
        if (!hasSortableData(over)) {
          return false;
        }

        const {
          data: {
            current: {
              sortable: { index: overIdx },
            },
          },
        } = over;

        /**
         * Handle adding multiple {@link selectedItems} apps.
         */
        if (hasMultiSelected) {
          return update('Dock', (prevDock) =>
            dnd.insertItemsToDock({
              dock: prevDock,
              items: selectedItems,
              insertIndex: overIdx,
            }),
          );
        }

        return update('Dock', (prevDock) =>
          dnd.insertItemsToDock({
            dock: prevDock,
            items: [currActiveItem],
            insertIndex: overIdx,
          }),
        );
      }

      /**
       * Handle case when {@link _PageItem} is dragged from Preview Page zone to
       * empty area of Preview Dock zone (i.e. not dropped over any sortable
       * {@link _DockItem} in the Dock).
       *
       * --> Add active dragged {@link _PageItem} at the end of
       * {@link DockArray} and remove same {@link _PageItem} from the current
       * {@link PagesArray}.
       */
      case 'append_swap_page_to_dock': {
        if (active.data.current.item.Type === 'Folder') {
          return false;
        }

        update('Dock', (prevDock) =>
          dnd.appendItemsToDock({
            dock: prevDock,
            items: [currActiveItem],
          }),
        );

        return update('Pages', (prevPages) =>
          dnd.removeItemFromPage({
            pages: prevPages,
            currentPage: active.data.current.pageIndex,
            removeId: currActiveItem.id,
          }),
        );
      }

      /**
       * Handle case when {@link _PageItem} is dragged from Preview Page zone to
       * Preview Dock zone and dropped over any sortable {@link _DockItem} in
       * the Dock.
       *
       * --> Insert active dragged {@link _PageItem} into {@link DockArray} at
       * the position between the item(s) over which it was dropped and remove
       * {@link _PageItem} from the current {@link PageArray}.
       */
      case 'sort_swap_page_to_dock': {
        if (
          !hasSortableData(over) ||
          active.data.current.item.Type === 'Folder'
        ) {
          return false;
        }

        const {
          data: {
            current: {
              sortable: { index: overIdx },
            },
          },
        } = over;

        update('Dock', (prevDock) =>
          dnd.insertItemsToDock({
            dock: prevDock,
            items: [currActiveItem],
            insertIndex: overIdx,
          }),
        );

        return update('Pages', (prevPages) =>
          dnd.removeItemFromPage({
            pages: prevPages,
            currentPage: active.data.current.pageIndex,
            removeId: currActiveItem.id,
          }),
        );
      }

      /**
       * Handle case when {@link _DockItem} is dragged from Preview Dock zone to
       * empty area of Preview Page zone (i.e. not dropped over any sortable
       * item on the Page).
       *
       * --> Add active dragged {@link _DockItem} at the end of current
       * {@link PageArray} and remove {@link _DockItem} from the
       * {@link DockArray}.
       */
      case 'append_swap_dock_to_page': {
        update('Pages', (prevPages) =>
          dnd.appendItemsToPage({
            pages: prevPages,
            toPage: over.data.current.pageIndex,
            items: [currActiveItem],
          }),
        );

        return update('Dock', (prevDock) =>
          dnd.removeItemFromDock({
            dock: prevDock,
            removeId: currActiveItem.id,
          }),
        );
      }

      /**
       * Handle case when {@link _DockItem} is dragged from Preview Dock zone to
       * Preview Page zone and dropped over any sortable items on the Page.
       *
       * --> Insert active dragged {@link _DockItem} into current
       * {@link PageArray} at the position between the item(s) over which it
       * was dropped and remove {@link _DockItem} from the {@link DockArray}.
       */
      case 'sort_swap_dock_to_page': {
        if (!hasSortableData(over)) {
          return false;
        }

        const {
          data: {
            current: {
              sortable: { index: overIdx },
            },
          },
        } = over;

        update('Pages', (prevPages) =>
          dnd.insertItemsToPage({
            pages: prevPages,
            toPage: over.data.current.pageIndex,
            items: [currActiveItem],
            insertIndex: overIdx,
          }),
        );

        return update('Dock', (prevDock) =>
          dnd.removeItemFromDock({
            dock: prevDock,
            removeId: currActiveItem.id,
          }),
        );
      }

      /**
       * Handle case when dragging + sorting {@link _PageItem} within Preview
       * Page zone to new position.
       *
       * --> Move active dragged {@link _PageItem} to its new position within
       * the current {@link PageArray}.
       */
      case 'sort_within_page': {
        if (!hasSortableData(over) || !hasSortableData(active)) {
          return false;
        }

        const {
          data: {
            current: {
              sortable: { index: activeIdx },
            },
          },
        } = active;

        const {
          data: {
            current: {
              sortable: { index: overIdx },
            },
          },
        } = over;

        return update('Pages', (prevPages) =>
          dnd.sortItemsWithinPage({
            pages: prevPages,
            currentPage: over.data.current.pageIndex,
            fromIndex: activeIdx,
            toIndex: overIdx,
          }),
        );
      }

      /**
       * Handle case when sorting {@link _DockItem} within Preview Dock zone to
       * new position.
       *
       * --> Move active dragged {@link _DockItem} to its new position within
       * the {@link DockArray}.
       */
      case 'sort_within_dock': {
        if (!hasSortableData(over) || !hasSortableData(active)) {
          return false;
        }

        const {
          data: {
            current: {
              sortable: { index: activeIdx },
            },
          },
        } = active;

        const {
          data: {
            current: {
              sortable: { index: overIdx },
            },
          },
        } = over;

        return update('Dock', (prevDock) =>
          dnd.sortItemsWithinDock({
            dock: prevDock,
            fromIndex: activeIdx,
            toIndex: overIdx,
          }),
        );
      }

      /**
       * Handle case when dragging + sorting {@link _FolderItem} within Preview
       * Folder zone with current {@link folderId} to new position.
       *
       * --> Find the current folder in the {@link PagesArray} with matching
       *  {@link folderId} and move active dragged {@link _FolderItem} to
       *  its new position within the current folder page number of the {@link _FolderArray}.
       * --> Update the folder with {@link folderId} on the {@link currentPage}
       * number page with the newly sorted folder page data.
       */
      case 'sort_within_folder': {
        if (!hasSortableData(over) || !hasSortableData(active)) {
          return false;
        }

        const {
          data: {
            current: {
              sortable: { index: activeIdx },
            },
          },
        } = active;

        const {
          data: {
            current: {
              sortable: { index: overIdx },
            },
          },
        } = over;

        return update('Pages', (prevPages) =>
          dnd.sortItemsWithinFolder({
            currentFolder,
            currentFolderPage,
            pages: prevPages,
            currentPage,
            fromIndex: activeIdx,
            toIndex: overIdx,
          }),
        );
      }

      default:
        return false;
    }
  };

  const cancelDrop = ({ over }: DndCore.DragCancelEvent) =>
    dnd.getDropContainerAtMax({ over, selectedItems });

  const onSelect = useCallback(
    (item: HomeScreenLayoutItem) => {
      const filteredOutExisting = selectedItems.filter(
        (selectedItem) => selectedItem.id !== item.id,
      );
      const isDeselect = filteredOutExisting.length < selectedItems.length;
      return setSelectedItems((prevItems) =>
        isDeselect ? filteredOutExisting : [...prevItems, item],
      );
    },
    [selectedItems],
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: ACTIVATION_CONSTRAINT_DISTANCE,
      },

      onActivation: ({ event }) => setDragActivationEvent(event),
    }),

    // istanbul ignore next -- temp ignore to get initial hsl tests merged
    useSensor(
      MultiSelectKeyboardSensor,
      // istanbul ignore next
      {
        scrollBehavior: 'smooth',

        onActivation: (
          { event },
          {
            src,
            context,
          }: {
            src: 'onKeyDownSelectItemWithoutActivation' | undefined;
            context: { active: ActiveProps } | undefined;
          } = {
            src: undefined,
            context: undefined,
          },
        ) => {
          setDragActivationEvent(event);
          if (src === 'onKeyDownSelectItemWithoutActivation' && context) {
            const selectedItem = context.active.data.current.item;
            if (selectedItem) {
              onSelect(selectedItem);
            }
          }
        },

        coordinateGetter: (event, args) => {
          const { pageDroppableContainerId, dockDroppableContainerId } =
            PREVIEW_CONFIG.common;
          const { containerSize } = APP_ICON_CONFIG;

          const delta = containerSize;
          const { key, shiftKey } = event;
          const { currentCoordinates, context } = args;
          const { collisions, droppableRects } = context;

          const getNextDroppableContainerCoords = () => {
            const isDraggingOverPage = collisions.some(
              ({ id }) => id === pageDroppableContainerId,
            );
            const isDraggingOverDock = collisions.some(
              ({ id }) => id === dockDroppableContainerId,
            );

            const toggleRectTuple = [
              isDraggingOverPage &&
                droppableRects.get(dockDroppableContainerId),
              isDraggingOverDock &&
                droppableRects.get(pageDroppableContainerId),
            ] as const;

            const droppableRect = toggleRectTuple.find(Boolean);
            return droppableRect
              ? {
                  x: droppableRect.left,
                  y: droppableRect.top,
                }
              : undefined;
          };

          switch (key) {
            case 'ArrowUp':
              return shiftKey
                ? getNextDroppableContainerCoords()
                : {
                    ...currentCoordinates,
                    y: currentCoordinates.y - delta,
                  };

            case 'ArrowDown':
              return shiftKey
                ? getNextDroppableContainerCoords()
                : {
                    ...currentCoordinates,
                    y: currentCoordinates.y + delta,
                  };

            case 'ArrowLeft':
              return {
                ...currentCoordinates,
                x: currentCoordinates.x - delta,
              };

            case 'ArrowRight':
              return {
                ...currentCoordinates,
                x: currentCoordinates.x + delta,
              };

            default:
              return undefined;
          }
        },
      },
    ),
  );

  return {
    activeItem,
    overItem,
    selectedItems,
    onSelect:
      // istanbul ignore next -- temp ignore to get initial hsl tests merged
      onSelect,
    dragActivationEvent,
    onDragStart,
    onDragCancel,
    onDragOver,
    onDragEnd,
    cancelDrop,
    sensors,
  };
}
