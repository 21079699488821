/* istanbul ignore file */
import {
  Button,
  Callout,
  DialogPrimitives as Dialog,
  Flex,
  Select,
  Text,
  TextField,
} from '@kandji-inc/nectar-ui';
import useWhiteBg from 'features/util/hooks/use-white-bg';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { links } from 'src/app/common/constants';
import { CreateButton } from './components/CreateButton';
import { DeviceTargets } from './components/DeviceTargets';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { LabelText } from './components/LabelText';
import { TargetsPanel } from './components/TargetsPanel';

export const CreateFileCheck = ({
  onCreate,
  isCreating,
  createJobError,
  reportName,
  setReportName,
  reportNameError,
  setReportNameError,
  reportDescription,
  setReportDescription,
  selectedDevices,
  setSelectedDevices,
  selectedBlueprints,
  setSelectedBlueprints,
}) => {
  useWhiteBg();
  const history = useHistory();
  const [fileCheckType, setFileCheckType] = useState('Path');
  const [path, setPath] = useState('');
  const [hash, setHash] = useState('');

  return (
    <Flex flow="column" pt4>
      <Header
        reportType="file_check"
        reportName={reportName}
        setReportName={setReportName}
        reportNameError={reportNameError}
        setReportNameError={setReportNameError}
        reportDescription={reportDescription}
        setReportDescription={setReportDescription}
      />
      <TargetsPanel>
        <DeviceTargets
          selectedDevices={selectedDevices}
          setSelectedDevices={setSelectedDevices}
          selectedBlueprints={selectedBlueprints}
          setSelectedBlueprints={setSelectedBlueprints}
        />
        <Flex flow="column" p4 gap="md">
          <LabelText>I want to search and return results for</LabelText>
          <Flex gap="md" css={{ paddingBottom: '$4' }}>
            <Text css={{ paddingTop: 10, flexShrink: 0 }}>File type</Text>
            <Select
              onChange={(value) => {
                setFileCheckType(value);
              }}
              options={[
                { value: 'Path', label: 'Path' },
                { value: 'Hash', label: 'Hash' },
                { value: 'Hash and Path', label: 'Hash and Path' },
              ]}
              size="xs"
              value={fileCheckType}
            />
            <Select
              options={[{ value: 'equals', label: 'equals' }]}
              onChange={() => {}}
              size="xs"
              value="equals"
            />
            {(fileCheckType === 'Hash' ||
              fileCheckType === 'Hash and Path') && (
              <TextField
                css={{ flexBasis: '100%', height: '36px' }}
                placeholder="Example: 17933a01970fb39fb39f0372c101f6568fa5576"
                hint={{ label: 'Accepts SHA-256 only' }}
                value={hash}
                onChange={(e) => setHash(e.target.value)}
              />
            )}
            {(fileCheckType === 'Path' ||
              fileCheckType === 'Hash and Path') && (
              <TextField
                css={{ flexBasis: '100%', height: '36px' }}
                placeholder="Enter file path"
                value={path}
                onChange={(e) => setPath(e.target.value)}
              />
            )}
          </Flex>
          {fileCheckType === 'Hash and Path' && (
            <Callout
              showCloseButton={false}
              text="This query will return whether the specified hash exists at the exact path of the file provided on the devices you target."
            />
          )}
          {selectedDevices.length === 0 && selectedBlueprints.length === 0 && (
            <Callout
              showCloseButton={false}
              text="You do not have any devices targeted. For faster results, target by device, blueprint or device family."
              theme="warning"
            />
          )}
          {createJobError && (
            <Callout
              showCloseButton={false}
              text={createJobError}
              theme="warning"
            />
          )}
        </Flex>
        <Dialog.Footer>
          <Button
            variant="subtle"
            onClick={() => {
              history.push(links.pulse);
            }}
          >
            Cancel
          </Button>
          <CreateButton
            isCreating={isCreating}
            onClick={() => {
              if (reportName === '') {
                setReportNameError('Required');
                return;
              }
              onCreate({
                checkType: 'file_check',
                agentFilters: {
                  collect_metadata: true,
                  hashes: hash ? [hash] : [],
                  paths: path ? [path] : [],
                },
              });
            }}
          />
        </Dialog.Footer>
      </TargetsPanel>
      <Footer />
    </Flex>
  );
};
