import type { CSSProperties, MouseEventHandler } from 'react';
import React, { useMemo } from 'react';

import { Button, setClass } from '@kandji-inc/bumblebee';

import { ARIA_SORT_MAP } from '../../table.helpers';
import type { ColSortDirection } from '../../table.types';

type Props = {
  direction: ColSortDirection;
  onClick: MouseEventHandler<HTMLButtonElement>;
  ascColor?: CSSProperties['color'];
  descColor?: CSSProperties['color'];
  noSortColor?: CSSProperties['color'];
  isVisible?: boolean;
  isDisabled?: boolean;
  className?: string;
};

function SortIndicator(props: Props) {
  const {
    direction,
    onClick,
    ascColor = 'var(--b-link-blue-normal)',
    descColor = 'var(--b-link-blue-normal)',
    noSortColor = 'var(--b-primary-marengo-dark)',
    isVisible = true,
    isDisabled = false,
    className = '',
  } = props;

  const sortDirection = direction || 'none';

  const directionColorMap = useMemo(
    () => ({
      asc: {
        ascIndicator: ascColor,
        descIndicator: noSortColor,
      },
      desc: {
        ascIndicator: noSortColor,
        descIndicator: descColor,
      },
      none: {
        ascIndicator: noSortColor,
        descIndicator: noSortColor,
      },
    }),
    [ascColor, descColor, noSortColor],
  );

  return (
    <Button
      kind="link"
      onClick={onClick}
      disabled={isDisabled}
      className={setClass('v-table-sort-indicator__toggle-btn', className)}
    >
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        className={setClass(
          'v-table-sort-indicator__icon',
          `v-table-sort-indicator__icon--${className}`,
        )}
        style={
          {
            '--icon-opacity': isVisible ? '1' : '0',
          } as CSSProperties
        }
        aria-label={`Sorted by: ${ARIA_SORT_MAP[sortDirection]}`}
        aria-hidden={!isVisible}
      >
        <path
          d="M11.3132 14.6453L9.58208 16.5482V5.76437C9.58208 5.34241 9.24158 5 8.81835 5C8.39512 5 8.05462 5.34241 8.05462 5.76437V16.5489L6.32445 14.673C6.17428 14.5099 5.96932 14.4273 5.76374 14.4273C5.57879 14.4273 5.3927 14.494 5.24568 14.6302C4.93542 14.9159 4.91664 15.3997 5.20291 15.7102L8.22919 19.0229C8.51757 19.3354 9.06262 19.3354 9.35092 19.0229L12.3772 15.7102C12.6636 15.3997 12.6447 14.916 12.3344 14.6302C12.0801 14.3398 11.5964 14.3621 11.3132 14.6453Z"
          fill={directionColorMap[sortDirection].descIndicator}
        />
        <path
          d="M18.7971 8.54705L15.7708 5.23437C15.4824 4.92188 14.9374 4.92188 14.6491 5.23437L11.5671 8.54705C11.2807 8.85757 11.2996 9.34133 11.6099 9.62709C11.757 9.76332 11.943 9.82996 12.1279 9.82996C12.3338 9.82996 12.5388 9.74741 12.6886 9.58433L14.4184 7.71013V18.4946C14.4184 18.9147 14.762 19.2584 15.1821 19.2584C15.6021 19.2584 15.9458 18.916 15.9458 18.494V7.71013L17.676 9.58604C17.9604 9.89656 18.4435 9.91731 18.7548 9.62881C19.0644 9.3426 19.0835 8.85891 18.7971 8.54705Z"
          fill={directionColorMap[sortDirection].ascIndicator}
        />
      </svg>
    </Button>
  );
}

export default SortIndicator;
