import { colors } from 'app/common/constants';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import SearchString from './SearchString';

const BlueprintTabTypeMixin = `
  width: 100%;
  margin: 0;
  padding: 0 24px;
`;

const Title = styled.div`
  grid-area: title;
`;

const Wrapper = styled.div`
  background-color: var(--color-neutral-10);
  display: flex;
  z-index: 1;
  justify-content: space-between;
  align-items: center !important;
  background: ${colors['button-secondary-new-bg']};
  ${(props) => props.sticky && 'position: sticky;'};
  width: calc(100% + 20px);
  margin: 0 -10px;
  padding: 0 10px;
  ${(props) => props.sticky && `top: ${props.top}px;`};
  height: 87px;
  background-color: var(--b-neutrals-grey-ultralight);
  ${(props) => (props.blueprintTabType ? BlueprintTabTypeMixin : null)};
  @media (max-width: 1280px) {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 60px 55px 1fr;
    grid-template-areas: "title search" "filter filter" "table table";
    grid-row-gap: 10px;
    height: auto;
  }
  ${(props) => (props.bgWhite ? `background: var(--color-neutral-0)` : null)};
`;

const FiltersWrapper = styled.div`
  grid-area: filter;
  margin: 25px 0;
  height: 55px;
  display: flex;
  align-items: center;
  ${(props) => props.marginRight && 'margin-right: 10px'};
  @media (max-width: 1280px) {
    margin: 0;
    justify-self: end;
  }
`;

const SearchStringWrapper = styled.div`
  grid-area: search;
  margin: 25px 0 25px auto;
  height: 55px;
  display: flex;
  align-items: center;
  width: 230px;
  ${(props) => props.marginRight && 'margin-right: 10px'};
`;

const AwesomeTableToolBarNew = ({
  title,
  btnGroup,
  searchLabel,
  searchIconPosition,
  searchFunc,
  sticky,
  blueprintTabType,
  top,
  rightBtnGroup,
  searchDefaultValue,
  bgWhite,
}) => (
  <Wrapper
    sticky={sticky}
    blueprintTabType={blueprintTabType}
    top={top}
    bgWhite={bgWhite}
  >
    <Title>{title}</Title>
    {!rightBtnGroup && (
      <>
        <FiltersWrapper marginRight={!rightBtnGroup}>{btnGroup}</FiltersWrapper>
        <SearchStringWrapper marginRight={rightBtnGroup}>
          <SearchString
            label={searchLabel}
            iconPosition={searchIconPosition}
            searchFunc={searchFunc}
            defaultValue={searchDefaultValue}
          />
        </SearchStringWrapper>
      </>
    )}
    {rightBtnGroup && (
      <>
        <SearchStringWrapper marginRight={rightBtnGroup}>
          <SearchString
            label={searchLabel}
            iconPosition={searchIconPosition}
            searchFunc={searchFunc}
            defaultValue={searchDefaultValue}
          />
        </SearchStringWrapper>
        <FiltersWrapper marginRight={!rightBtnGroup}>{btnGroup}</FiltersWrapper>
      </>
    )}
  </Wrapper>
);

AwesomeTableToolBarNew.propTypes = {
  btnGroup: PropTypes.any,
  title: PropTypes.any,
  searchLabel: PropTypes.string,
  searchIconPosition: PropTypes.string,
  searchFunc: PropTypes.func,
  sticky: PropTypes.bool,
  blueprintTabType: PropTypes.bool,
  top: PropTypes.number,
  rightBtnGroup: PropTypes.bool,
};

AwesomeTableToolBarNew.defaultProps = {
  sticky: false,
  blueprintTabType: false,
  top: 0,
  rightBtnGroup: false,
};

export default AwesomeTableToolBarNew;
