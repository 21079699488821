export const getModelID = (computer) => {
  const { device_family, info, mdm_info, model } = computer;

  if (info) {
    return info['Model Identifier'];
  }
  if (
    device_family === 'AppleTV' ||
    device_family === 'iPad' ||
    device_family === 'iPhone' ||
    device_family === 'iPod' ||
    device_family === 'RealityDevice'
  ) {
    if (mdm_info && mdm_info.ProductName) {
      return mdm_info.ProductName;
    }
    return model;
  }
  return null;
};
