import { i18n } from 'src/i18n';
/* istanbul ignore file */
import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class RestrictionsService extends NewLibraryItemService {
  static appRestrictionsOptions = () => [
    {
      label: i18n.t("Don't allow specific apps"),
      value: 'disallow',
    },
    {
      label: i18n.t('Only allow specific apps'),
      value: 'allow',
    },
  ];
}

export const restrictionsService = new RestrictionsService();

export default RestrictionsService;
