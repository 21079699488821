import type { DetectionDateFilterFields } from 'src/features/edr/common/common.types';

const FALLBACK_DETECTION_PERIOD_MESSAGE = 'During the selected period';

const getMessage = (period: string): string => `${period}`;

const getDisplayPeriod = (detectionDate: DetectionDateFilterFields): string => {
  const { value } = detectionDate;

  if (value === 'custom_date_range') {
    return '';
  }

  if (value === 'all_time') {
    return 'Across all time';
  }

  if (value?.startsWith('last_')) {
    const days = value.split('_')[1];
    const period = value.split('_')[2];
    return `In the last ${days} ${period}`;
  }

  return FALLBACK_DETECTION_PERIOD_MESSAGE;
};

const getDisplayDetectionPeriod = (
  detectionDate: DetectionDateFilterFields | undefined,
): string => {
  try {
    if (!detectionDate) {
      throw new Error('Detection date filter fields are required');
    }

    const period = getDisplayPeriod(detectionDate);

    return getMessage(period);
  } catch {
    return getMessage(FALLBACK_DETECTION_PERIOD_MESSAGE);
  }
};

export default getDisplayDetectionPeriod;
