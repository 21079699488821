import { Button, Flex, styled } from '@kandji-inc/nectar-ui';
import React from 'react';

export type NavigationProps = {
  categories: Array<{ category: string; key: string }>;
};

const ControlNavButton = styled(Button, {
  height: 'min-content',
  justifyContent: 'start',
  width: '100%',
  padding: '$2 $2 $2 $5',
  fontWeight: '$regular',
  color: '$neutral90 !important',
});

const NavigationFlex = styled(Flex, {
  position: 'sticky',
  top: '0',
  overflow: 'auto',
  height: 'calc(100vh - 76px)',
  padding: '2px',
});

const Navigation = (props: NavigationProps) => {
  const { categories } = props;

  const scrollTo = (key) => () => {
    const el = document.getElementById(`${key.replace(/-/g, '')}`);
    if (el) {
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <NavigationFlex flow="column">
      {categories.map((category) => (
        <ControlNavButton
          key={category.key}
          variant="subtle"
          onClick={scrollTo(category.key)}
          data-testid={category.key}
        >
          {category.category}
        </ControlNavButton>
      ))}
    </NavigationFlex>
  );
};

export default Navigation;
