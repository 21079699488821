import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import configs from '../../../library/library-item-configurations/items/profile';
import initialState from '../initial-state';
import GatekeeperService from './gatekeeper-service';

const getGeneralSettings = (apiPayload) => {
  const general = {};

  if (!apiPayload.EnableAssessment) {
    general.appsFrom = GatekeeperService.appsFrom.ANYWHERE;
    general.disableOverride = false;
  } else {
    general.disableOverride = apiPayload.DisableOverride;
    if (apiPayload.AllowIdentifiedDevelopers) {
      general.appsFrom = GatekeeperService.appsFrom.MAC_AND_DEVELOPERS;
    } else {
      general.appsFrom = GatekeeperService.appsFrom.MAC_ONLY;
    }
  }

  if ('EnableXProtectMalwareUpload' in apiPayload) {
    general.EnableXProtectMalwareUpload =
      apiPayload.EnableXProtectMalwareUpload;
  } else {
    general.EnableXProtectMalwareUpload =
      initialState.general.EnableXProtectMalwareUpload;
  }

  return general;
};

async function transformFromNewApi(apiData, preset) {
  const result = {
    ...preset,
    isActive: apiData.active,
    general: getGeneralSettings(apiData.data),
  };

  return result;
}

async function transformFromApi(apiData) {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const commonData = {
    id: apiData.id,
    name: apiData.name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
    rules: apiData.rules,
  };

  const result = await transformFromNewApi(apiData, commonData);

  return {
    ...apiData,
    data: result,
  };
}

function transformToOldApi(model) {
  const result = {
    name: model.name,
    is_active: model.isActive,
    blueprints: model.selectedBlueprints.map((b) => b.value),
    is_all_blueprints: model.isAllBlueprints,
    rules: model.rules || null,
    settings: {},
  };

  if (model.general.appsFrom === GatekeeperService.appsFrom.ANYWHERE) {
    result.settings.EnableAssessment = false;
  } else {
    result.settings.EnableAssessment = true;
    result.settings.DisableOverride = model.general.disableOverride;
    if (model.general.appsFrom === GatekeeperService.appsFrom.MAC_ONLY) {
      result.settings.AllowIdentifiedDevelopers = false;
    } else {
      result.settings.AllowIdentifiedDevelopers = true;
    }
  }

  result.settings.EnableXProtectMalwareUpload =
    model.general.EnableXProtectMalwareUpload;

  // Add necessary properties when creating new Profile:
  if (!model.id) {
    const itemConfig = configs.Gatekeeper;

    result.type = itemConfig.type;
    result.identifier = itemConfig.identifier;
  }

  return result;
}

function transformToNewApi(model) {
  const toSend = transformToOldApi(model);
  toSend.active = toSend.is_active;
  delete toSend.is_active;
  toSend.data = toSend.settings;
  delete toSend.settings;

  if (model.skip_blueprint_conflict) {
    toSend.skip_blueprint_conflict = model.skip_blueprint_conflict;
  }

  return toSend;
}

export { transformFromApi, transformToNewApi };
