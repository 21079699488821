import {
  Box,
  Button,
  Dialog,
  Flex,
  Label,
  Paragraph,
  Text,
  TextArea,
  TextField,
  styled,
} from '@kandji-inc/nectar-ui';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import useGetReleaseDetails from '../../common/hooks/use-get-release-details';
import { ThreatService } from '../../data-service';
import ThreatListLoader from './ThreatListLoader';

const FieldGroup = styled(Flex, {
  gap: '$2',
  flexDirection: 'column',
  mb: '$2',
});

const SeparatorLine = styled(Box, {
  height: 1,
  backgroundColor: '$neutral20',
  width: '100%',
});

const LoaderWrapper = styled(Flex, {
  position: 'absolute',
  inset: '60px 0px 0px 0px',
  backgroundColor: '$neutral0',
});

type EventFormModel = {
  itemName: string;
  note?: string;
  consent?: string;
};

type ThreatListReleaseModalProps = {
  name: string;
  fileHash: string;
  deviceId: string;
  libraryItemId: string | undefined;
  onClose: () => void;
  onRelease: (deviceCount: number) => void;
  onError: (type: string) => void;
};

const ThreatListReleaseModal = (props: ThreatListReleaseModalProps) => {
  const {
    name,
    fileHash,
    deviceId,
    libraryItemId,
    onClose,
    onRelease,
    onError,
  } = props;

  const {
    data: dataThreatDetails,
    isLoading: isLoadingDetails,
    isError: isErrorFetchingDetails,
  } = useGetReleaseDetails(deviceId, fileHash);

  if (isErrorFetchingDetails) {
    onError('release-details-fetch-error');
    return null;
  }

  const libraryItemName = dataThreatDetails?.library_item_name;
  const isAlreadyAllowed = dataThreatDetails?.library_item_is_threat_allowed;
  const devicesToBeReleased = dataThreatDetails?.device_ids;
  const deviceCount = dataThreatDetails?.device_count || 0;

  const threatService = useMemo(() => new ThreatService(), []);
  const [isReleasing, setIsReleasing] = useState<boolean>(false);

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
  } = useForm<EventFormModel>({
    mode: 'onChange',
  });

  const handleRelease = () => {
    setIsReleasing(true);

    if (!fileHash || !devicesToBeReleased) {
      onError('release-error');
      return;
    }

    handleSubmit((model) => {
      threatService
        .createThreatRelease({
          is_adding_to_allow_list: !isAlreadyAllowed,
          descriptive_name: model.itemName || '',
          file_hash: fileHash,
          device_ids: devicesToBeReleased,
          release_note: model.note || '',
        })
        .then(() => {
          onRelease(deviceCount);
        })
        .catch(() => onError('release-error'))
        .finally(() => {
          setIsReleasing(false);
        });
    })();
  };

  if (!name || !fileHash) {
    return null;
  }

  const libraryItemDescription = `${deviceCount} ${
    deviceCount > 1 ? 'devices' : 'device'
  } impacted`;

  const headerDescription = isAlreadyAllowed
    ? 'This threat has already been added to the Allow list.'
    : 'To release this threat from quarantine, you will need to add it to your Allow list.';

  const footerDescription = isAlreadyAllowed
    ? `I understand this threat will be released from quarantine on all devices assigned to Blueprints containing the Avert Library Item titled ${libraryItemName}.`
    : `I understand this file hash will be added to the Allow list in the Avert Library Item titled ${libraryItemName} and released from quarantine.`;

  const content = (
    <Flex flow="column" gap="lg" mt3>
      <Paragraph css={{ mb: '$1' }}>{headerDescription}</Paragraph>
      <FieldGroup>
        <Label above>Threat name</Label>
        <Text>{name}</Text>
      </FieldGroup>
      <FieldGroup>
        <Label above>File hash</Label>
        <Text>{fileHash}</Text>
      </FieldGroup>
      <FieldGroup>
        <Label above>Avert Library Item</Label>
        <Text>
          <Link
            to={`/library/avert/${libraryItemId}`}
            target="_blank"
            style={{ color: 'var(--colors-blue50)' }}
          >
            {libraryItemName}
          </Link>{' '}
          | {libraryItemDescription}
        </Text>
      </FieldGroup>
      {!isAlreadyAllowed && (
        <TextField
          {...register('itemName', {
            shouldUnregister: true,
            required: 'Required.',
          })}
          autoFocus
          label="Item name"
          placeholder="Enter a descriptive name for the item."
          maxLength={64}
          state={errors?.itemName?.message ? 'error' : 'default'}
          hint={{
            ...(errors?.itemName?.message && {
              label: errors.itemName.message,
            }),
          }}
          data-testid="item-name-field"
        />
      )}
      <TextArea
        {...register('note', {
          shouldUnregister: true,
          required: 'Required.',
        })}
        label="Note"
        placeholder="Describe why the file is being released. For internal use only."
        maxLength={256}
        state={errors?.note?.message ? 'error' : 'default'}
        hint={{
          ...(errors?.note?.message && { label: errors.note.message }),
        }}
        data-testid="note-field"
      />
      <SeparatorLine />
      <TextField
        {...register('consent', {
          shouldUnregister: true,
          required: 'Required.',
          validate: (value: string | undefined) =>
            value === 'RELEASE' || 'Invalid value.',
        })}
        label="Type RELEASE to release this threat"
        placeholder="RELEASE"
        maxLength={16}
        state={errors?.consent?.message ? 'error' : 'default'}
        hint={{
          ...(errors?.consent?.message && { label: errors.consent.message }),
        }}
        data-testid="consent-field"
      />
      <Paragraph variant="subtle">{footerDescription}</Paragraph>
      {isLoadingDetails && (
        <LoaderWrapper justifyContent="center" alignItems="center">
          <ThreatListLoader label="Loading threat details" />
        </LoaderWrapper>
      )}
    </Flex>
  );

  const footer = (
    <Flex justifyContent="end" mx3>
      <Flex gap="sm">
        <Button onClick={onClose} variant="subtle">
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleRelease}
          disabled={!isValid || isReleasing}
        >
          {!isReleasing
            ? isAlreadyAllowed
              ? 'Release'
              : 'Add and Release'
            : 'Releasing...'}
        </Button>
      </Flex>
    </Flex>
  );

  return (
    <Dialog
      isOpen
      onOpenChange={onClose}
      css={{ width: 592 }}
      title="Release threat"
      content={content}
      footer={footer}
    />
  );
};

export default ThreatListReleaseModal;
