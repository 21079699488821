import { arrayOf, shape, string } from 'prop-types';
import React, { memo } from 'react';
import './expanded-activity-row.css';

const ExpandedActivityRow = ({ data }) => (
  <div className="expanded-activity-row">
    {data.map(({ label, values }) => (
      <div key={label} className="expanded-activity-row__item">
        <div className="expanded-activity-row__label">{label}</div>
        <div className="expanded-activity-row__values">
          {values.map((value) => (
            <div key={value} className="expanded-activity-row__value">
              {value}
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>
);

ExpandedActivityRow.propTypes = {
  data: arrayOf(
    shape({
      label: string.isRequired,
      values: arrayOf(string).isRequired,
    }),
  ).isRequired,
};

export default memo(ExpandedActivityRow);
