/* istanbul ignore file */
import { i18n } from 'src/i18n';
import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class FirewallService extends NewLibraryItemService {
  /** @note These options are inverted in the UI since we want users to check
   *  to allow these settings instead of check to block.
   */
  static getManagePfOptions = () => [
    {
      label: i18n.t('AFP'),
      serverField: 'afp_packets_blocked',
      defaultValue: false,
    },
    {
      label: i18n.t('Bonjour'),
      serverField: 'bonjour_packets_blocked',
      defaultValue: false,
    },
    {
      label: i18n.t('Finger'),
      serverField: 'finger_packets_blocked',
      defaultValue: false,
    },
    {
      label: i18n.t('FTP'),
      serverField: 'ftp_packets_blocked',
      defaultValue: false,
    },
    {
      label: i18n.t('HTTP'),
      serverField: 'http_packets_blocked',
      defaultValue: false,
    },
    {
      label: i18n.t('ICMP'),
      serverField: 'icmp_packets_blocked',
      defaultValue: false,
    },
    {
      label: i18n.t('IMAP'),
      serverField: 'imap_packets_blocked',
      defaultValue: false,
    },
    {
      label: i18n.t('IMAPS'),
      serverField: 'imaps_packets_blocked',
      defaultValue: false,
    },
    {
      label: i18n.t('iTunes sharing'),
      serverField: 'itunes_packets_blocked',
      defaultValue: false,
    },
    {
      label: i18n.t('mDNSResponder'),
      serverField: 'mdnsresponder_packets_blocked',
      defaultValue: false,
    },
    {
      label: i18n.t('NFS'),
      serverField: 'nfs_packets_blocked',
      defaultValue: false,
    },
    {
      label: i18n.t('Optical drive sharing'),
      serverField: 'optical_packets_blocked',
      defaultValue: false,
    },
    {
      label: i18n.t('POP'),
      serverField: 'pop_packets_blocked',
      defaultValue: false,
    },
    {
      label: i18n.t('POPS'),
      serverField: 'pops_packets_blocked',
      defaultValue: false,
    },
    {
      label: i18n.t('Printer sharing'),
      serverField: 'printer_packets_blocked',
      defaultValue: false,
    },
    {
      label: i18n.t('Screen sharing'),
      serverField: 'screensharing_packets_blocked',
      defaultValue: false,
    },
    {
      label: i18n.t('SMB'),
      serverField: 'smb_packets_blocked',
      defaultValue: false,
    },
    {
      label: i18n.t('SMTP'),
      serverField: 'smtp_packets_blocked',
      defaultValue: false,
    },
    {
      label: i18n.t('SSH'),
      serverField: 'ssh_packets_blocked',
      defaultValue: false,
    },
    {
      label: i18n.t('Telnet'),
      serverField: 'telnet_packets_blocked',
      defaultValue: false,
    },
    {
      label: i18n.t('TFTP'),
      serverField: 'tftp_packets_blocked',
      defaultValue: false,
    },
    {
      label: i18n.t('UUCP'),
      serverField: 'uucp_packets_blocked',
      defaultValue: false,
    },
  ];
}

export const firewallService = new FirewallService();
export default FirewallService;
