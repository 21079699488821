/* istanbul ignore file */
import {
  getRunsOnFromModel,
  getSelectedDevicesFromRunsOn,
} from 'src/features/library-items/data-service/library-item/devices';
import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import configs from '../../../library/library-item-configurations/items/profile';
import { configMapping } from '../config/allConfigs';
import initialState from '../initial-state';

/** Helper for running through a given list of transformers and executing in
 * order. */
export const executeTransformers = (
  transformers: Array<(...args: any[]) => void>,
  value: any,
  model: any,
  key: string,
) => {
  let transforming = value;
  transformers.forEach((transform) => {
    transforming = transform(transforming, { model, key });
  });
  return transforming;
};

function transformSettings(settings, helpers) {
  const { keyToDescription } = configMapping();
  const _helpers = helpers;
  const _initialState = initialState();

  const restrictions = Object.keys(_initialState.restrictions).reduce(
    (a, rKey) => {
      const restriction = settings[rKey];
      const rConfig = keyToDescription[rKey];

      // If the key on the UI doesn't exist in the API data, fill it in.
      if (!(rKey in settings)) {
        return { ...a, [rKey]: _initialState.restrictions[rKey] };
      }

      if (!rConfig) {
        console.error(
          new Error(
            `The key '${rKey}' was found in the API response data, but has no corresponding configuration.`,
          ),
        );
        return a;
      }

      const transformedRestriction = rConfig.transformersFromAPI
        ? executeTransformers(
            rConfig.transformersFromAPI,
            restriction,
            { restrictions: settings, _helpers },
            rKey,
          )
        : restriction;

      if (typeof transformedRestriction === 'boolean') {
        return {
          ...a,
          [rKey]: { value: transformedRestriction },
        };
      }

      return { ...a, [rKey]: transformedRestriction };
    },
    {},
  );

  return {
    restrictions,
    _helpers,
  };
}

async function transformFromApi(apiData) {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const commonData = {
    id: apiData.id,
    name: apiData.name,
    rules: apiData.rules,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints || false,
    devices: getSelectedDevicesFromRunsOn(apiData),
  };

  const result = {
    ...commonData,
    isActive: apiData.active,
    ...transformSettings(
      apiData.data,
      apiData._helpers || initialState()._helpers,
    ),
  };

  return {
    ...apiData,
    data: result,
  };
}

function transformToApi(model) {
  const toSend: Record<string, any> = {
    name: model.name,
    active: model.isActive,
    blueprints: model.selectedBlueprints.map((b) => b.value),
    excluded_blueprints: model.excludedBlueprints.map((b) => b.value),
    is_all_blueprints: model.isAllBlueprints,
    data: {},
    rules: model.rules || null,
  };

  const { keyToDescription } = configMapping();

  const restrictions = Object.keys(model.restrictions).reduce((a, rKey) => {
    const restriction = model.restrictions[rKey];
    const rConfig = keyToDescription[rKey];

    if (!rConfig) {
      console.error(
        new Error(
          `The key '${rKey}' was found in the model, but has no corresponding configuration.`,
        ),
      );
      return a;
    }

    /** Updated value based on transformation, if any. */
    const transformedRestriction = rConfig.transformersToAPI
      ? executeTransformers(rConfig.transformersToAPI, restriction, model, rKey)
      : restriction;

    if ('checked' in restriction) {
      return {
        ...a,
        [rKey]: transformedRestriction,
      };
    }

    return {
      ...a,
      [rKey]: transformedRestriction.value,
    };
  }, {});

  const runsOn = getRunsOnFromModel(model);
  Object.keys(runsOn).forEach((runsOnKey) => {
    toSend[runsOnKey] = runsOn[runsOnKey];
  });

  toSend.data = restrictions;

  // Add necessary properties when creating new Profile:
  if (!model.id) {
    const itemConfig = configs.Restrictions;

    toSend.type = itemConfig.type;
    toSend.identifier = itemConfig.identifier;
  }

  return toSend;
}

export { transformToApi, transformFromApi };
