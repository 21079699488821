import { publicApi } from 'app/api/base';
import { URL_ENROLLMENT_OTA, URL_ENROLLMENT_PORTAL } from 'app/api/urls';
import { API_BASE } from 'src/app/api/domains';
import { getAuth0Client } from 'src/auth0';

export async function getAndApplyIdToken(url: string) {
  const urlWithToken = new URL(url);

  try {
    const idTokenClaims = await getAuth0Client().getIdTokenClaims();
    urlWithToken.searchParams.append('id_token', idTokenClaims.__raw);
  } catch {
    console.log('Error authenticating');
  }

  return urlWithToken.toString();
}

export const validateEnrollmentCode = (code) =>
  publicApi(`${URL_ENROLLMENT_PORTAL}validate-code/${code}`)
    .get()
    .then((res) => res.data);

export async function fetchEncodedConfig(
  selectedBlueprint: string,
  validatedAccessCode: string,
  authenticatedDownloadUrl?: string,
) {
  let url = `${API_BASE}${URL_ENROLLMENT_OTA(
    selectedBlueprint,
    validatedAccessCode,
  )}`;

  if (authenticatedDownloadUrl != null) {
    url = await getAndApplyIdToken(authenticatedDownloadUrl);
  }

  const { data } = await publicApi(url).get({});

  return data;
}
