import { updateSetting } from '@kandji-inc/bumblebee';
import { i18n } from 'i18n';
/* istanbul ignore file */
import React, { useCallback, useEffect, useState } from 'react';

import ActivityTab from 'features/library-items/common/activity-tab';
import NewStatusTab from 'features/library-items/common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from 'features/library-items/common/new-status-tab/util';
import useUniqValue from 'src/components/common/hooks/use-uniq-value/use-uniq-value';

import {
  devices,
  populateSelectedDevices,
} from 'features/library-items/data-service/library-item/devices';
import config from 'features/library-items/library/library-item-configurations/items/profile';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import InfoBanners from '../automatic-app/info-banners';
import initialState from './initial-state';
import { CustomizeIpad, CustomizeIphone } from './sections';
import { transformFromApi, transformToApi } from './service/transformers';
import useService from './service/use-wallpaper-service';
import { wallpaperService } from './service/wallpaper-service';

const WallpaperPage = (props) => {
  const { model, setModel, pageState, handlers } = props;
  const [, triggerValidation] = useUniqValue();

  const updateIpad = useCallback(updateSetting('iPad', setModel), []);

  const updateIphone = useCallback(updateSetting('iPhone', setModel), []);

  const itemConfig = config.Wallpaper;
  useEffect(() => populateSelectedDevices(model, setModel, itemConfig), []);

  const setIsSaveEnabled = handlers.onValidate((isValid) => isValid);
  const [showIPadValidation, setShowIPadValidation] = useState(false);
  const [showIPhoneValidation, setShowIPhoneValidation] = useState(false);

  useEffect(() => {
    let iPadError = false;
    let iPhoneError = false;

    const withIphone = !!model.devices?.find(
      ({ value }) => value === devices.IPHONE,
    );

    const withIpad = !!model.devices?.find(
      ({ value }) => value === devices.IPAD,
    );

    if (
      !model.iPad.lockScreen &&
      !model.iPad.lockScreenUrl &&
      !model.iPad.homeScreen &&
      !model.iPad.homeScreenUrl
    ) {
      iPadError = true;
      setShowIPadValidation(true);
    } else {
      iPadError = false;
      setShowIPadValidation(false);
    }

    if (
      !model.iPhone.lockScreen &&
      !model.iPhone.lockScreenUrl &&
      !model.iPhone.homeScreen &&
      !model.iPhone.homeScreenUrl
    ) {
      iPhoneError = true;
      setShowIPhoneValidation(true);
    } else {
      iPhoneError = false;
      setShowIPhoneValidation(false);
    }

    if ((iPadError && withIpad) || (iPhoneError && withIphone)) {
      setIsSaveEnabled(false);
    } else {
      setIsSaveEnabled(true);
    }
  }, [model.iPad, model.iPhone, model.devices]);

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.getName()}
      summaryInfoProps={{
        name: `${itemConfig.getName()}`,
        description: itemConfig.getDescription(),
        publisher: itemConfig.getPublisher(),
        devices: itemConfig.devices,
        requirements: itemConfig
          .getOsRequirements()
          .map((os) => `${os} (${i18n.t('Requires Supervision')})`),
        extra: (
          <InfoBanners
            requiresRosetta={false}
            warning={i18n.t(
              'Kandji supports the installation of a profile to restrict user modification of the wallpaper. On assigned iOS & iPadOS devices, a restrictions profile will be installed to disallow the user from changing the wallpaper image.',
            )}
          />
        ),
      }}
      triggerValidation={triggerValidation}
      transformFromApi={transformFromApi}
      transformToApi={transformToApi}
      service={wallpaperService}
      defaultIcon={itemConfig.icon}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      supportsRules
      supportsInstallOn
    >
      <CustomizeIpad
        setting={model.iPad}
        devices={model.devices}
        update={updateIpad}
        isDisabled={pageState.isDisabled}
        showValidation={showIPadValidation}
      />
      <CustomizeIphone
        setting={model.iPhone}
        devices={model.devices}
        update={updateIphone}
        isDisabled={pageState.isDisabled}
        showValidation={showIPhoneValidation}
      />
    </LibraryItemPage>
  );
};

const Wallpaper = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState,
    useService,
  });
  const blueprintConflicts = useBlueprintConflicts();

  /* istanbul ignore next */
  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <WallpaperPage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
      testId="wallpaper"
    />
  );
};

export default Wallpaper;
