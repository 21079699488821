import { useDebounce } from 'features/util/hooks/use-debounce';
import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';

const useDebouncedState = <T>(
  defaultValue,
  debounceFor = 100,
): [T, Dispatch<SetStateAction<T>>, T] => {
  const [value, setValue] = useState<T>(defaultValue);
  const [dValue] = useDebounce([value], debounceFor);

  return [dValue, setValue, value];
};

export default useDebouncedState;
