import { createReducer } from 'redux-create-reducer';

import { users } from '../_actions/action-types';

const initialState = {
  usersList: [],
  currentUser: null,
};

export default createReducer(initialState, {
  [users.COMPANY_USER_FETCHED](state, action) {
    return {
      ...state,
      currentUser: action.payload,
    };
  },
  [users.COMPANY_USERS_FETCHED](state, action) {
    return { ...state, usersList: action.payload };
  },
  [users.COMPANY_USER_UPDATED](state, action) {
    const updatedClientsList = [...state.usersList];
    for (let i = 0; i < updatedClientsList.length; i++) {
      if (updatedClientsList[i].id === action.payload.id) {
        updatedClientsList[i] = action.payload;
        break;
      }
    }
    return {
      ...state,
      currentUser: action.payload,
      usersList: updatedClientsList,
    };
  },
  [users.COMPANY_USER_DELETED](state, action) {
    const updatedClientsList = [...state.usersList];
    for (let i = 0; i < updatedClientsList.length; i++) {
      if (updatedClientsList[i].id === action.payload.id) {
        updatedClientsList.splice(i, 1);
        break;
      }
    }
    return {
      ...state,
      usersList: updatedClientsList,
    };
  },
});
