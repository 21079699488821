import type { SortingState } from '@tanstack/react-table';

export function getSortString(sorts: SortingState): string | null {
  if (sorts.length === 0) {
    return null;
  }

  const sort = sorts[0];

  if (!sort) {
    return null;
  }

  if (sort.desc) {
    return `-${sort.id}`;
  }
  return `${sort.id}`;
}

export function validateSortString(
  sorts: SortingState,
  schemaProperties: Record<string, unknown> | undefined,
) {
  if (!schemaProperties || !sorts || sorts?.length <= 0) {
    return null;
  }

  const [sorting] = sorts;
  const isValid = sorting && schemaProperties[sorting.id];

  return isValid ? getSortString(sorts) : null;
}

export function getNextSortState(sortState: 'none' | 'asc' | 'desc') {
  switch (sortState) {
    case 'none':
      return 'asc';
    case 'asc':
      return 'desc';
    case 'desc':
      return 'asc';
    default:
      return 'none';
  }
}
