import { Button } from '@kandji-inc/bumblebee';
import { i18n } from 'i18n';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setSnackbar as callSetSnackbar } from '../../../_actions/ui';
import { deleteUser as callDeleteUser } from '../../../_actions/users';
import AwesomeCheckbox from '../AwesomeCheckbox';
import { ModalWrapper } from './ModalWrapper';
import { ButtonsWrapper, CancelButtonWrapper } from './elements';

/* istanbul ignore next */
export class UserDelete extends ModalWrapper {
  constructor(props) {
    super(props);
    this.modalName = 'UserDelete';
    this.state.isAccepted = false;
    this.idsToDelete = props.info.idsToDelete;
    this.delete = props.info.delete;
    this.onDelete = this.onDelete.bind(this);
  }

  onDelete() {
    const { setSnackbar } = this.props;
    const { isAccepted } = this.state;
    if (!isAccepted) {
      return null;
    }
    this.setState({ isLoading: true }, () => {
      this.delete(this.idsToDelete)
        .then(() => {
          setSnackbar(
            i18n.t('Selected {userText} deleted', {
              userText:
                this.idsToDelete.length === 1
                  ? i18n.t('user was')
                  : i18n.t('users were'),
            }),
          );
          this.onHide();
        })
        .catch((err) => {
          setSnackbar(i18n.common.error());
        })
        .finally(() => this.setState({ isLoading: false }));
    });
    return null;
  }

  renderHeader = () => <p>{i18n.t('Delete User')}</p>;

  renderBody() {
    const { isAccepted } = this.state;
    const text =
      this.idsToDelete.length > 1 ? i18n.t('these users') : i18n.t('this user');
    return (
      <>
        <p style={{ color: '#f05b7e' }}>
          {i18n.t('You are about to delete {text}', { text })}
        </p>
        <p>
          {i18n.t(
            'This cannot be undone. Please confirm you want to delete {text}.',
            { text },
          )}
        </p>
        <AwesomeCheckbox
          label={i18n.t(
            'I understand {text}, its records and all related info will be deleted. This action cannot be undone.',
            { text },
          )}
          onCheck={() => this.setState({ isAccepted: !isAccepted })}
          checked={isAccepted}
          keyId="deleteCheckbox"
          margin={0}
          checkboxPosition="start"
        />
      </>
    );
  }

  renderFooter() {
    const { isLoading, isAccepted } = this.state;
    return (
      <ButtonsWrapper>
        <CancelButtonWrapper>
          <Button type="button" onClick={this.onHide} kind="outline">
            {i18n.t('Cancel')}
          </Button>
        </CancelButtonWrapper>
        <Button
          type="button"
          disabled={isLoading || !isAccepted}
          onClick={this.onDelete}
          theme="error"
        >
          {!isLoading ? i18n.t('Delete') : i18n.t('Deleting')}
        </Button>
      </ButtonsWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSnackbar: callSetSnackbar,
      deleteUser: callDeleteUser,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserDelete);
