import { Select, TextInput } from '@kandji-inc/bumblebee';
import React from 'react';
import styled from 'styled-components';

import { i18n } from 'src/i18n';

const ModalBodyContainer = styled.div`
  label {
    padding: 24px 0 12px 0;
    margin: 0;
  }

  label:first-of-type {
    padding: 12px 0;
  }
`;

const placeholderMap = {
  BundleIdentifier: 'com.example.backgrounditem',
  BundleIdentifierPrefix: 'com.example',
  Label: 'com.example.backgrounditem',
  LabelPrefix: 'com.example',
  TeamIdentifier: 'ABCDE12345',
};

const ModalBody = (props) => {
  const { setModel, model } = props;

  const handleOnChange = (name, isEventObj?) => (data) => {
    if (isEventObj) {
      if (
        name === 'identifier' &&
        model.editItem.identifierType?.value === 'TeamIdentifier'
      ) {
        data = data.target.value.toUpperCase();
      } else {
        data = data.target.value;
      }
    }
    if (name === 'identifierType') {
      return setModel((prev) => ({
        ...prev,
        editItem: {
          ...prev.editItem,
          identifier: '',
          [name]: data,
        },
      }));
    }
    setModel((prev) => ({
      ...prev,
      editItem: {
        ...prev.editItem,
        [name]: data,
      },
    }));
  };

  return (
    <ModalBodyContainer>
      <label htmlFor="identifier-type">{i18n.t('Identifier Type')}</label>
      <Select
        inputId="identifier-type"
        placeholder={i18n.t('Select an identifier type')}
        options={[
          { label: i18n.t('Bundle Identifier'), value: 'BundleIdentifier' },
          {
            label: i18n.t('Bundle Identifier Prefix'),
            value: 'BundleIdentifierPrefix',
          },
          { label: i18n.t('Label'), value: 'Label' },
          { label: i18n.t('Label Prefix'), value: 'LabelPrefix' },
          { label: i18n.t('Team Identifier'), value: 'TeamIdentifier' },
        ]}
        onChange={handleOnChange('identifierType')}
        value={model.editItem.identifierType}
      />
      <label htmlFor="identifier">{i18n.t('Identifier')}</label>
      <TextInput
        id="identifier"
        placeholder={
          placeholderMap[model.editItem?.identifierType?.value] || 'com.example'
        }
        value={model.editItem.identifier}
        onChange={handleOnChange('identifier', true)}
      />
      <label htmlFor="comment">{i18n.t('Comment (optional)')}</label>
      <TextInput
        id="comment"
        placeholder={i18n.t('Add a comment')}
        value={model.editItem.comment}
        onChange={handleOnChange('comment', true)}
      />
    </ModalBodyContainer>
  );
};

export default ModalBody;
