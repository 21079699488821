/* istanbul ignore file - legacy code, moved */
import FilterSelect from 'app/components/common/FilterSelect';
import React from 'react';

export const OldAppStoreStatusFilter = ({
  statuses,
  filteredStatus,
  setFilteredStatus,
  successLabel,
}) => {
  const counts = {
    all: statuses.length,
    pending: statuses.filter((s) =>
      ['PENDING', 'pending', 'INSTALLING'].includes(s.status),
    ).length,
    incompatible: statuses.filter((s) => s.status === 'INCOMPATIBLE').length,
    success: statuses.filter((s) => ['success', 'PASS'].includes(s.status))
      .length,
    remediated: statuses.filter((s) => s.status === 'REMEDIATED').length,
    failed: statuses.filter((s) => ['failed', 'ERROR'].includes(s.status))
      .length,
  };
  return (
    <FilterSelect
      title="Select Status"
      selected={filteredStatus}
      list={[
        { label: 'All Statuses', value: 'all', count: counts.all },
        { label: 'Pending', value: 'PENDING', count: counts.pending },
        {
          label: 'Incompatible',
          value: 'INCOMPATIBLE',
          count: counts.incompatible,
        },
        { label: successLabel, value: 'PASS', count: counts.success },
        { label: 'Remediated', value: 'REMEDIATED', count: counts.remediated },
        { label: 'Error', value: 'ERROR', count: counts.failed },
      ]}
      resetThenSet={setFilteredStatus}
    />
  );
};
