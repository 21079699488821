import { setClass } from '@kandji-inc/bumblebee';
import React from 'react';

const ToggleButton = (props) => {
  const { isActive, onClick, className, style, children } = props;
  return (
    <button
      style={style}
      type="button"
      onClick={onClick}
      className={setClass(
        className,
        'k-ks-toggle-button',
        isActive && '--active',
      )}
    >
      {children}
    </button>
  );
};

export default ToggleButton;
