import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { customSelectComponents } from 'theme/components/atoms/Select';

const SelectAsyncPagination = ({
  value,
  onChange,
  className,
  error,
  load,
  sizePerPage,
  handleResponse,
  components,
  ...rest
}) => {
  const loadOptions = useCallback(
    async (search, prevOptions) => {
      const page = prevOptions.length / sizePerPage + 1;
      const response = await load({ page, sizePerPage, search });
      const { hasMore, options } = handleResponse(response);

      return {
        options,
        hasMore,
      };
    },
    [load, sizePerPage, handleResponse],
  );

  return (
    <AsyncPaginate
      value={value}
      loadOptions={loadOptions}
      onChange={onChange}
      className={cn('kandji-select-container', className, {
        'kandji-select__error': error,
      })}
      classNamePrefix="kandji-select"
      components={{ ...customSelectComponents, ...components }}
      {...rest}
    />
  );
};

const optType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
});

SelectAsyncPagination.propTypes = {
  value: optType,
  onChange: PropTypes.func,
  className: PropTypes.string,
  error: PropTypes.string,
  load: PropTypes.func.isRequired,
  handleResponse: PropTypes.func.isRequired,
  sizePerPage: PropTypes.number,
  components: PropTypes.object,
};

SelectAsyncPagination.defaultProps = {
  value: undefined,
  onChange: () => {},
  className: undefined,
  error: undefined,
  sizePerPage: 10,
  components: {},
};

export default memo(SelectAsyncPagination);
