import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Flex,
  Heading,
  Icon,
  styled,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';

import { useState } from 'react';
import AnalyzeThreatEventsImage from '../assets/analyze-threat-events.png';
import OneAgentWithPopupImage from '../assets/one-agent-with-popup.png';
import ReleaseFilesImage from '../assets/release-files.png';

const AccordionItemStyled = styled(AccordionItem, {
  borderBottom: '1px solid $neutral20',
  '&:last-child': {
    borderBottom: 'none',
  },
});

const AccordionContentStyled = styled(AccordionContent, {
  pr: '$5',
  pb: '$2',
  color: '$neutral90',
});

const AccordionTriggerStyled = styled(AccordionTrigger, {
  fontSize: 20,
  lineHeight: '39px',
  letterSpacing: '-0.8px',
  justifyContent: 'space-between',
  '& div.stateIcon': {
    p: 0,
    width: 20,
    height: 20,
    '& svg': {
      verticalAlign: 6,
    },
  },
});

const AngleDownIcon = () => (
  <Icon name="fa-angle-down-small" style={{ width: 20, height: 20 }} />
);

const OneAgentSection = () => {
  const [oneAgentImage, setOneAgentImage] = useState<string>('item-1');

  return (
    <Flex
      wFull
      flow="column"
      alignItems="center"
      css={{
        gap: 45,
      }}
    >
      <Heading size="2" css={{ lineHeight: '36px', letterSpacing: '-0.8px' }}>
        {i18n.t('One agent. The most powerful protection for Mac.')}
      </Heading>
      <Flex
        css={{
          alignItems: 'center',
          gap: 59,
          alignSelf: 'stretch',
          minHeight: 320,
        }}
      >
        <Flex css={{ width: 430 }}>
          <Accordion
            collapsible
            type="single"
            css={{ width: '100%' }}
            defaultValue="item-1"
            onValueChange={(value: string) => {
              setOneAgentImage(value || 'item-1');
            }}
          >
            <AccordionItemStyled value="item-1">
              <AccordionTriggerStyled customIcon={<AngleDownIcon />}>
                {i18n.t('Detect and stop any threat')}
              </AccordionTriggerStyled>
              <AccordionContentStyled>
                {i18n.t(
                  'EDR detects and contains threats via behavioral detections and file analysis. Process monitoring recognizes deviations from expected behavior and can kill malicious processes instantly. This delivers real-time protection against known and unknown threats.',
                )}
              </AccordionContentStyled>
            </AccordionItemStyled>
            <AccordionItemStyled value="item-2">
              <AccordionTriggerStyled customIcon={<AngleDownIcon />}>
                {i18n.t('Analyze threat events')}
              </AccordionTriggerStyled>
              <AccordionContentStyled>
                {i18n.t(
                  'See information such as the threat name, classification, along with any relevant actions, affected Mac computers, and time stamps of key events.',
                )}
              </AccordionContentStyled>
            </AccordionItemStyled>
            <AccordionItemStyled value="item-3">
              <AccordionTriggerStyled customIcon={<AngleDownIcon />}>
                {i18n.t('Release files from quarantine')}
              </AccordionTriggerStyled>
              <AccordionContentStyled>
                {i18n.t(
                  'If a quarantined file needs to be released, use a streamlined workflow to add the file to an allow list and release it across all devices.',
                )}
              </AccordionContentStyled>
            </AccordionItemStyled>
          </Accordion>
        </Flex>
        <Flex justifyContent="center" css={{ width: 645 }}>
          {oneAgentImage === 'item-1' && (
            <img
              src={OneAgentWithPopupImage}
              style={{
                width: 669,
                marginLeft: -24,
              }}
              alt="one-agent"
            />
          )}
          {oneAgentImage === 'item-2' && (
            <img
              src={AnalyzeThreatEventsImage}
              style={{ width: 525 }}
              alt="analyze-threat-events"
            />
          )}
          {oneAgentImage === 'item-3' && (
            <img
              src={ReleaseFilesImage}
              style={{ width: 525 }}
              alt="release-files"
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export { OneAgentSection };
