/* istanbul ignore file */

const ASSIGNMENT_MAP_DEVICE_LOOKUP_PARAM = 'device_lookup';

const paths = {
  root: '/blueprints',
  blueprint: (id) => `/blueprints/${id}`,
  flowBlueprint: (id, tab = 'assignments') => `/blueprints/maps/${id}/${tab}`,
  parameters: (id) => `/blueprints/${id}/parameters`,
  library: (id) => `/blueprints/${id}/library`,
  devices: (id) => `/blueprints/${id}/devices`,
  activity: (id) => `/blueprints/${id}/activity`,
  notes: (id) => `/blueprints/${id}/notes`,
  getBlueprintRouteByType: (blueprint) => {
    if (blueprint.type === 'flow') {
      return paths.flowBlueprint(blueprint.id);
    }

    return paths.blueprint(blueprint.id);
  },
};

const blueprintLibraryCategories = {
  ENROLLMENT: 'Enrollment',
  INSTALLERS_SCRIPTS: 'Installers & Scripts',
  PROFILES: 'Profiles',
  SECURITY: 'Endpoint Security',
} as const;

export {
  paths,
  blueprintLibraryCategories,
  ASSIGNMENT_MAP_DEVICE_LOOKUP_PARAM,
};
