import uuid from 'uuid/v4';

import featureFlags from 'src/config/feature-flags';
import {
  AM_DEVICE_SPECIFIC_OS_VERSION_FACETS,
  AM_OS_VERSION_PARENT_KEY,
  AM_OS_VERSION_RULE_TEMPLATE,
  LI_DEVICE_SPECIFIC_OS_VERSION_FACETS,
  LI_OS_VERSION_DEFAULT_OPERATOR,
  LI_OS_VERSION_RULE_TEMPLATE,
  OS_VERSION_PARENT_KEY,
  TEXT_TYPES,
} from './constants';

/**
 * Transform Facet data into a map indexed by facet key
 * @param {Array} data - a list of facets. See: https://kandji.atlassian.net/wiki/spaces/MEAD/pages/1873903628/GET+Facets
 * @returns a map of facet data indexed by facet key
 */
const transformFacetDataFromApi = (data, source = 'library-item') => {
  const facetMap = {};
  data.forEach((facet) => {
    if (
      (LI_DEVICE_SPECIFIC_OS_VERSION_FACETS.includes(facet.key) &&
        source !== 'library-item') ||
      (AM_DEVICE_SPECIFIC_OS_VERSION_FACETS.includes(facet.key) &&
        source !== 'assignment-map') ||
      // Do not include the tags facet if the feature flag is not enabled
      (facet.key === 'tags' && !featureFlags.getFlag('dc-05302024-tags'))
    ) {
      return;
    }

    facetMap[facet.key] = facet;
  });
  return facetMap;
};

/**
 * Create a JsonLogic rule given the correct data
 * @param {String} input - the facet input, ex. "enrollment_type"
 * @param {String} operator - the rule operator, ex. "=="
 * @param {String} subject - the object the rule applies to, ex. "computer"
 * @param {String or Array} value - the rule value, ex. "4" or ["1", "2", "3"]
 * @returns a JsonLogic rule in format { operator: [{ var: subject.input }, value]}
 */
export const createRule = (input, operator, subject, value) => {
  const { IS_BLANK, IS_NOT_BLANK } = TEXT_TYPES;

  let rule = {
    [operator]: [{ var: `${subject}.${input}` }, value],
  };

  // Rules using the truthy/non-truthy operators `!` and `!!` do not need to provide a value
  if (operator === IS_BLANK || operator === IS_NOT_BLANK) {
    rule = {
      [operator]: [{ var: `${subject}.${input}` }],
    };
  }

  return rule;
};

/**
 * Transform rule data from the RuleBuilder component into JsonLogic
 * @param {Object} ruleBuilderData - an Object with property "and" that refers to an Array of
 *                                   Objects that each describe a rule's input, operator, and value
 * @returns a JsonLogic representation of the rules created in the RuleBuilder component
 */
const transformRuleBuilderToJsonLogic = (ruleBuilderData) => {
  const ruleBuilderRules = ruleBuilderData.and;
  const jsonLogicRules = [];
  ruleBuilderRules.forEach((rule) => {
    // If the rule is a grouping of rules with children rules, as
    // is the case for OS Version, add children rules individually
    if (rule.input === OS_VERSION_PARENT_KEY) {
      Object.keys(rule.children).forEach((child) => {
        const input = child;
        const { operator, subject, value } = rule.children[child];

        // If the operator is a default operator, the rule is a 'dummy rule'
        // and should not be included in the JsonLogic
        if (operator !== LI_OS_VERSION_DEFAULT_OPERATOR) {
          jsonLogicRules.push(createRule(input, operator, subject, value));
        }
      });
    } else if (rule.input === AM_OS_VERSION_PARENT_KEY) {
      const amOSVersionRules = [];
      rule.children.forEach((child) => {
        const { input, operator, subject, value } = child;
        amOSVersionRules.push(createRule(input, operator, subject, value));
      });

      jsonLogicRules.push({ or: amOSVersionRules });
    } else {
      const { input, operator, subject, value } = rule;

      jsonLogicRules.push(createRule(input, operator, subject, value));
    }
  });

  // Only return a jsonLogic object if rules were added, otherwise return null
  if (jsonLogicRules.length) {
    return { and: jsonLogicRules };
  }
  return null;
};

/**
 * Extracts key pieces of data from a rule for various uses.
 * @param {Object} rule - a rule in JsonLogic format, ex. {"==": [{ var: 'computer.enrollment_type'}, '4']}
 * @returns key pieces of data from a rule, namely its key, operator, subject, input, and value
 */
const extractRuleData = (rule) => {
  // ex. 'ca3a7379-90ec-4601-a818-765479e2a5f5' (necessary for rendering list items in React)
  const key = uuid();

  // ex.  '=='
  const operator = Object.keys(rule)[0];

  // ex. [{ var: 'computer.enrollment_type' }, '4']
  const data = rule[operator];

  // ex. 'computer.enrollment_type'
  const subjectAndInput = data[0].var.split('.');

  // ex. 'computer'
  const subject = subjectAndInput[0];

  // ex. 'enrollment_type'
  const input = subjectAndInput[1];

  // ex. '4'
  const value = data[1];

  return { key, operator, subject, input, value };
};

/**
 * Transforms JsonLogic rule data into a format that can be consumed by the RuleBuilder.
 * @param {Object} jsonLogic - the JsonLogic representation of rules
 * @returns rules in a format that the RuleBuilder can understand, ex. an object with an
 *          aggregator 'and' key that contains an Array of Objects, each with an Input,
 *          Operator, Subject, and Value property
 */
const transformJsonLogicToRuleBuilder = (jsonLogic) => {
  const ruleBuilderRules = [];

  let addedOsVersionRule = false;

  // Make a deepcopy of the template
  const LibraryItemOSVersionRule = JSON.parse(
    JSON.stringify(LI_OS_VERSION_RULE_TEMPLATE),
  );
  const amOSVersionRule = JSON.parse(
    JSON.stringify(AM_OS_VERSION_RULE_TEMPLATE),
  );

  jsonLogic.and.forEach((rule) => {
    if (rule.or) {
      rule.or.forEach((orRule) => {
        const jsonLogicRule = extractRuleData(orRule);
        const { input, operator, subject, value } = jsonLogicRule;

        amOSVersionRule.children.push({ input, operator, subject, value });
      });

      ruleBuilderRules.push(amOSVersionRule);
    } else {
      // Extract the inidividual rule pieces from the JsonLogic
      const jsonLogicRule = extractRuleData(rule);
      const { input, operator, subject, value } = jsonLogicRule;

      // If the rule we are dealing with is an OS version rule, add it to its parent grouping
      if (LI_DEVICE_SPECIFIC_OS_VERSION_FACETS.includes(input)) {
        addedOsVersionRule = true;
        LibraryItemOSVersionRule.children[input] = { operator, subject, value };
      } else {
        ruleBuilderRules.push(jsonLogicRule);
      }
    }
  });

  // Only provide the OS Version rule if we received one in the JsonLogic (do not just add the template)
  if (addedOsVersionRule) {
    ruleBuilderRules.push(LibraryItemOSVersionRule);
  }

  return { and: ruleBuilderRules };
};

/**
 * Given the devices the Library Item supports and the devices each facet supports,
 * construct a list of rules that apply to the Library Item
 * @param {Object} rules - Library Item assignment rules in JsonLogic format
 * @param {Array} devices - Devices the library item supports in Select Option
 *                          format (ex. [{ label: 'Mac', value: 'Mac' }])
 * @param {Object} facetMap - a mapping of facet names to facet-specific info
 * @returns a list of rules that will actually apply to the Library Item within an 'and' aggregator
 */
const filterApplicableRules = (rules, devices, facetMap) => {
  const deviceList = devices.map((device) => device.value);
  const applicableRules = [];
  let aggregateApplicableRules = null;

  rules?.and.forEach((rule) => {
    // ex. filevault_status
    const { input } = extractRuleData(rule);

    // ex. ['Mac']
    const facetSupportedDevices = facetMap[input].device_families;

    // Whether or not there is a shared device between what devices the
    // facet supports and what devices the library item supports
    const isApplicable = facetSupportedDevices.some((supportedDevice) =>
      deviceList.includes(supportedDevice),
    );

    if (isApplicable) {
      applicableRules.push(rule);
    }
  });

  if (applicableRules.length) {
    aggregateApplicableRules = { and: applicableRules };
  }

  return aggregateApplicableRules;
};

export {
  extractRuleData,
  transformFacetDataFromApi,
  transformJsonLogicToRuleBuilder,
  transformRuleBuilderToJsonLogic,
  filterApplicableRules,
};
