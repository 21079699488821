import {
  TextInput,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
/* istanbul ignore file */
import React from 'react';
import uuidv4 from 'uuid/v4';

import AddableContent from 'features/library-items/common/addable-content';
import { i18n } from 'src/i18n';

const TextField = (props) => {
  const {
    setting,
    validationDep,
    update,
    isDisabled,
    placeholder,
    updateValidation,
    isRequired = true,
  } = props;
  const { _id, value } = setting;
  const fieldsToValidate = [`add-text-field-value_${_id}`];
  const { refs, onInvalidate } = useInputsValidators(
    fieldsToValidate,
    updateValidation,
  );

  useRemoveValidationOnUnmount(fieldsToValidate, updateValidation);

  return (
    <div ref={refs[0]} style={{ width: '100%' }}>
      <TextInput
        removeValidationOnUnmount
        wrapperClassName="b-mr1"
        disabled={isDisabled}
        value={value}
        onChange={(e) => update(e.target.value)}
        placeholder={placeholder}
        onInvalidate={onInvalidate(0)}
        validator={
          isRequired
            ? (v) => [
                {
                  message: i18n.t('Required.'),
                  invalid: () => !v,
                  trigger: ['onBlur', validationDep],
                },
              ]
            : undefined
        }
      />
    </div>
  );
};

const AddableTextField = (props) => {
  const {
    validationDep,
    isDisabled,
    update,
    items,
    settingKey,
    placeholder,
    isRequired,
    addButtonText,
  } = props;

  return (
    <AddableContent
      rowClassName="b-flex-vc"
      itemsClassName="b-flex-vg1"
      max={25}
      items={items}
      onChange={(newItems) => update(settingKey, newItems)}
      getEmptyItem={() => ({ _id: uuidv4(), value: '' })}
      isDisabled={isDisabled}
      renderItem={(index) => {
        const updateValue = (v) => {
          const newValue = [...items];
          newValue[index] = {
            ...newValue[index],
            value: v,
          };

          update(settingKey, newValue);
        };

        return (
          <TextField
            setting={items[index]}
            update={updateValue}
            updateValidation={update}
            isDisabled={isDisabled}
            validationDep={validationDep}
            placeholder={placeholder}
            isRequired={isRequired}
          />
        );
      }}
      keyField="_id"
      addButtonText={addButtonText}
    />
  );
};

export default AddableTextField;
