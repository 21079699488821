import { datadogRum } from '@datadog/browser-rum';
import * as constants from '../constants';

const applicationId = constants.REACT_APP_DATADOG_APP_ID;
const clientToken = constants.REACT_APP_DATADOG_CLIENT_TOKEN;
const env = constants.REACT_APP_DATADOG_ENV;

const isDatadogEnabledEnv = env === 'production' || env === 'dev';

const rum = {
  startSessionReplayRecording: () => {},
};

if (isDatadogEnabledEnv) {
  datadogRum.init({
    applicationId,
    clientToken,
    site: 'datadoghq.com',
    service: 'kandji-web',
    env,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: '1.0.0',
    sessionSampleRate: 100,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask',
    allowedTracingUrls: [
      /https:\/\/.*\.main-backend\..*\.internal-kandji\.io/,
      /https:\/\/.*\.clients\..*\.kandji\.io/,
    ],
  });

  rum.startSessionReplayRecording = datadogRum.startSessionReplayRecording;
}

export function logError(error: Error, context?: object) {
  if (isDatadogEnabledEnv) {
    datadogRum.addError(error, context);
  }
}

export default rum;
