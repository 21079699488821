import {
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { Flex, useDialog } from '@kandji-inc/nectar-ui';
/* istanbul ignore file */
import React, { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import AddCustomVariableModal from './AddCustomVariable.modal';
import CustomChip from './CustomChip';
import VariableBank from './VariableBank';
import VariableDropzone from './VariableDropzone';
import VariablePreview from './VariablePreview';
import { deviceVariables } from './common';

const DROPZONE_ID = 'dropzone_device_name';
const BANK_ID = 'bank';
const SetComputerName = (props: any) => {
  const {
    selectedVariables,
    setSelectedVariables,
    title,
    previewLabel,
    onDevices,
    kind = 'device',
    isDisabled,
    hasError,
  } = props;
  const [isOpen, toggle] = useDialog(false);
  const [editCustomVar, setEditCustomVar] = useState<string>();
  const [activeId, setActiveId] = useState<string>('');
  const activeVar =
    deviceVariables().find(({ value }) => activeId === value)?.name || activeId;

  const onDragStart = ({ active }) => {
    const itemId = active?.data?.current?.value;
    setActiveId(itemId);
  };

  const onDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  const onDragEnd = ({ active, over }) => {
    setActiveId(null);

    const droppingOver = over?.id;
    const itemValue = active?.data?.current?.value;
    const itemId = active?.data?.current?.itemId;
    const isCustom = Boolean(active?.data?.current?.isCustom);
    const isItemSelected = selectedVariables.find((v) => v.id === itemId);
    const deviceVariable = deviceVariables().find((v) => v.value === itemValue);

    if (itemValue && droppingOver?.includes(DROPZONE_ID)) {
      if (isItemSelected) {
        const oldIdx = active?.data?.current?.sortable?.index;
        const newIdx = over?.data?.current?.sortable?.index;
        if (!Number.isNaN(newIdx) && !Number.isNaN(oldIdx)) {
          setSelectedVariables((prev) => arrayMove(prev, oldIdx, newIdx));
        }
      } else {
        const sVar = {
          id: itemId,
          value: itemValue,
          isCustom,
          deviceVariable,
        };
        setSelectedVariables((prev) => [...prev, sVar]);
      }
    } else if (itemValue && droppingOver?.includes(BANK_ID)) {
      setSelectedVariables((prev) => prev.filter((v) => v.id !== itemId));
    }
  };

  const onCustomVariableSave = (text) => {
    if (editCustomVar) {
      setSelectedVariables((prev) =>
        prev.map((sv) => ({
          ...sv,
          value: sv.id === editCustomVar ? text : sv.value,
        })),
      );
      setEditCustomVar('');
    } else {
      setSelectedVariables((prev) => [
        ...prev,
        { id: uuidv4(), value: text, isCustom: true },
      ]);
    }
    toggle();
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  return (
    <DndContext
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragCancel={onDragCancel}
      sensors={sensors}
    >
      <Flex gap="lg">
        <VariableBank
          id={BANK_ID}
          selected={selectedVariables}
          onAddCustom={() => toggle()}
          isDisabled={isDisabled}
          kind={kind}
        />
        <Flex flex="1" flow="column" gap="md">
          <VariableDropzone
            id={DROPZONE_ID}
            title={title}
            variables={selectedVariables}
            onManualRemove={(id) =>
              setSelectedVariables((prev) => prev.filter((v) => v.id !== id))
            }
            onEditCustomVariable={(id) => {
              toggle();
              setEditCustomVar(id);
            }}
            isDisabled={isDisabled}
            hasError={hasError}
          />
          <VariablePreview
            kind={kind}
            title={title}
            selected={selectedVariables}
            previewLabel={previewLabel}
            onDevices={onDevices}
          />
        </Flex>
      </Flex>

      <DragOverlay dropAnimation={null}>
        {activeId ? (
          <CustomChip
            css={{
              cursor: 'grabbing',
            }}
            label={activeVar}
          />
        ) : null}
      </DragOverlay>

      <AddCustomVariableModal
        isOpen={isOpen}
        kind={kind}
        toggle={(o) => {
          toggle(o);
          setEditCustomVar('');
        }}
        withValue={
          selectedVariables.find((sVar) => sVar.id === editCustomVar)?.value
        }
        onSave={onCustomVariableSave}
      />
    </DndContext>
  );
};

export default SetComputerName;
