import React from 'react';
import { InfoBannerElement, WarningBannerElement } from './banners.styles';

export const InfoBanner = ({
  text,
  title,
  margin,
  styles = {},
  supportLink,
}) => (
  <InfoBannerElement
    margin={margin}
    background={styles.background}
    width={styles.width}
    maxWidth={styles.maxWidth}
    padding={styles.padding}
  >
    <InfoBannerElement.Icon name="circle-info" />
    <InfoBannerElement.Text>
      {title && <InfoBannerElement.Title>{title}</InfoBannerElement.Title>}
      {text}
      {supportLink && supportLink}
    </InfoBannerElement.Text>
  </InfoBannerElement>
);

export const WarningBanner = ({ text, margin, padding }) => (
  <WarningBannerElement margin={margin} padding={padding}>
    <WarningBannerElement.Icon name="octagon-exclamation" />
    <WarningBannerElement.Text>{text}</WarningBannerElement.Text>
  </WarningBannerElement>
);
