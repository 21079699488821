import type { SortingState } from '@tanstack/react-table';

function sortingValueFromId(id: string): string {
  switch (id) {
    case 'mdm_device__computer__enrollment_status':
      return 'mdm_device__computer__enrolled_at';
    default:
      return id;
  }
}

export function fromSortingState(sorting: SortingState): string {
  return sorting
    .map(({ id, desc }) => `${desc ? '-' : ''}${sortingValueFromId(id)}`)
    .join(',');
}
