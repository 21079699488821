/* istanbul ignore file */
export const lottieLoaderAnimation = {
  nm: 'balls falling loading',
  ddd: 0,
  h: 1080,
  w: 1080,
  meta: {
    g: '@lottiefiles/toolkit-js 0.33.2',
  },
  layers: [
    {
      ty: 3,
      nm: 'Null 1',
      sr: 1,
      st: 0,
      op: 50,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: {
          a: 0,
          k: [50, 50, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [234.5, 234.5, 100],
          ix: 6,
        },
        sk: {
          a: 0,
          k: 0,
        },
        p: {
          a: 1,
          k: [
            {
              o: {
                x: 0.333,
                y: 0,
              },
              i: {
                x: 0.667,
                y: 1,
              },
              s: [554.07, 737.91, 0],
              t: 0,
              ti: [0, 52.802, 0],
              to: [0, -52.802, 0],
            },
            {
              s: [554.07, 421.1, 0],
              t: 10,
            },
          ],
          ix: 2,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        sa: {
          a: 0,
          k: 0,
        },
        o: {
          a: 0,
          k: 0,
          ix: 11,
        },
      },
      ef: [],
      ind: 1,
    },
    {
      ty: 4,
      nm: 'ball 3',
      sr: 1,
      st: 0,
      op: 50,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1,
        },
        s: {
          a: 1,
          k: [
            {
              o: {
                x: 0.167,
                y: 0.167,
              },
              i: {
                x: 0.833,
                y: 0.833,
              },
              s: [100, 100, 100],
              t: 16,
            },
            {
              o: {
                x: 0.167,
                y: 0.167,
              },
              i: {
                x: 0.833,
                y: 0.833,
              },
              s: [110, 100, 100],
              t: 19,
            },
            {
              o: {
                x: 0.167,
                y: 0.167,
              },
              i: {
                x: 0.833,
                y: 0.833,
              },
              s: [90, 100, 100],
              t: 25,
            },
            {
              o: {
                x: 0.167,
                y: 0.167,
              },
              i: {
                x: 0.833,
                y: 0.833,
              },
              s: [105, 100, 100],
              t: 31,
            },
            {
              o: {
                x: 0.167,
                y: 0.167,
              },
              i: {
                x: 0.833,
                y: 0.833,
              },
              s: [95, 100, 100],
              t: 37,
            },
            {
              s: [100, 100, 100],
              t: 43,
            },
          ],
          ix: 6,
        },
        sk: {
          a: 0,
          k: 0,
        },
        p: {
          a: 1,
          k: [
            {
              o: {
                x: 0.167,
                y: 0.167,
              },
              i: {
                x: 0.833,
                y: 0.833,
              },
              s: [124.2, -22.2, 0],
              t: 13,
              ti: [0, -19.333, 0],
              to: [0, 22.733, 0],
            },
            {
              o: {
                x: 0.167,
                y: 0.167,
              },
              i: {
                x: 0.833,
                y: 0.833,
              },
              s: [124.2, 114.2, 0],
              t: 19,
              ti: [0, 0, 0],
              to: [0, 19.333, 0],
            },
            {
              o: {
                x: 0.167,
                y: 0.167,
              },
              i: {
                x: 0.833,
                y: 0.833,
              },
              s: [124.2, 93.8, 0],
              t: 25,
              ti: [0, -1.883, 0],
              to: [0, 0, 0],
            },
            {
              o: {
                x: 0.167,
                y: 0.167,
              },
              i: {
                x: 0.833,
                y: 0.833,
              },
              s: [124.2, 114.2, 0],
              t: 31,
              ti: [0, 0, 0],
              to: [0, 1.883, 0],
            },
            {
              o: {
                x: 0.167,
                y: 0.167,
              },
              i: {
                x: 0.833,
                y: 0.833,
              },
              s: [124.2, 105.1, 0],
              t: 37,
              ti: [0, -1.517, 0],
              to: [0, 0, 0],
            },
            {
              s: [124.2, 114.2, 0],
              t: 43,
            },
          ],
          ix: 2,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        sa: {
          a: 0,
          k: 0,
        },
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 1',
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, -10.162],
                    [10.162, 0],
                    [0, 10.162],
                    [-10.162, 0],
                  ],
                  o: [
                    [0, 10.162],
                    [-10.162, 0],
                    [0, -10.162],
                    [10.162, 0],
                  ],
                  v: [
                    [18.4, 0],
                    [0, 18.4],
                    [-18.4, 0],
                    [0, -18.4],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: {
                a: 0,
                k: [0.0627, 0.4157, 0.949],
                ix: 4,
              },
              r: 1,
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
            },
            {
              ty: 'tr',
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 2,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
            },
          ],
        },
      ],
      ind: 2,
      parent: 1,
    },
    {
      ty: 4,
      nm: 'ball 2',
      sr: 1,
      st: 0,
      op: 50,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1,
        },
        s: {
          a: 1,
          k: [
            {
              o: {
                x: 0.167,
                y: 0.167,
              },
              i: {
                x: 0.833,
                y: 0.833,
              },
              s: [100, 100, 100],
              t: 18,
            },
            {
              o: {
                x: 0.167,
                y: 0.167,
              },
              i: {
                x: 0.833,
                y: 0.833,
              },
              s: [110, 100, 100],
              t: 21,
            },
            {
              o: {
                x: 0.167,
                y: 0.167,
              },
              i: {
                x: 0.833,
                y: 0.833,
              },
              s: [90, 100, 100],
              t: 27,
            },
            {
              o: {
                x: 0.167,
                y: 0.167,
              },
              i: {
                x: 0.833,
                y: 0.833,
              },
              s: [105, 100, 100],
              t: 33,
            },
            {
              o: {
                x: 0.167,
                y: 0.167,
              },
              i: {
                x: 0.833,
                y: 0.833,
              },
              s: [95, 100, 100],
              t: 39,
            },
            {
              s: [100, 100, 100],
              t: 45,
            },
          ],
          ix: 6,
        },
        sk: {
          a: 0,
          k: 0,
        },
        p: {
          a: 1,
          k: [
            {
              o: {
                x: 0.167,
                y: 0.167,
              },
              i: {
                x: 0.833,
                y: 0.833,
              },
              s: [46.6, -22.2, 0],
              t: 15,
              ti: [0, -19.333, 0],
              to: [0, 22.733, 0],
            },
            {
              o: {
                x: 0.167,
                y: 0.167,
              },
              i: {
                x: 0.833,
                y: 0.833,
              },
              s: [46.6, 114.2, 0],
              t: 21,
              ti: [0, 0, 0],
              to: [0, 19.333, 0],
            },
            {
              o: {
                x: 0.167,
                y: 0.167,
              },
              i: {
                x: 0.833,
                y: 0.833,
              },
              s: [46.6, 93.8, 0],
              t: 27,
              ti: [0, -1.883, 0],
              to: [0, 0, 0],
            },
            {
              o: {
                x: 0.167,
                y: 0.167,
              },
              i: {
                x: 0.833,
                y: 0.833,
              },
              s: [46.6, 114.2, 0],
              t: 33,
              ti: [0, 0, 0],
              to: [0, 1.883, 0],
            },
            {
              o: {
                x: 0.167,
                y: 0.167,
              },
              i: {
                x: 0.833,
                y: 0.833,
              },
              s: [46.6, 105.1, 0],
              t: 39,
              ti: [0, -1.517, 0],
              to: [0, 0, 0],
            },
            {
              s: [46.6, 114.2, 0],
              t: 45,
            },
          ],
          ix: 2,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        sa: {
          a: 0,
          k: 0,
        },
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 1',
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, -10.162],
                    [10.162, 0],
                    [0, 10.162],
                    [-10.162, 0],
                  ],
                  o: [
                    [0, 10.162],
                    [-10.162, 0],
                    [0, -10.162],
                    [10.162, 0],
                  ],
                  v: [
                    [18.4, 0],
                    [0, 18.4],
                    [-18.4, 0],
                    [0, -18.4],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: {
                a: 0,
                k: [0.0627, 0.4157, 0.949],
                ix: 4,
              },
              r: 1,
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
            },
            {
              ty: 'tr',
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 2,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
            },
          ],
        },
      ],
      ind: 3,
      parent: 1,
    },
    {
      ty: 4,
      nm: 'ball ',
      sr: 1,
      st: 0,
      op: 50,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1,
        },
        s: {
          a: 1,
          k: [
            {
              o: {
                x: 0.167,
                y: 0.167,
              },
              i: {
                x: 0.833,
                y: 0.833,
              },
              s: [100, 100, 100],
              t: 19,
            },
            {
              o: {
                x: 0.167,
                y: 0.167,
              },
              i: {
                x: 0.833,
                y: 0.833,
              },
              s: [110, 100, 100],
              t: 22,
            },
            {
              o: {
                x: 0.167,
                y: 0.167,
              },
              i: {
                x: 0.833,
                y: 0.833,
              },
              s: [90, 100, 100],
              t: 28,
            },
            {
              o: {
                x: 0.167,
                y: 0.167,
              },
              i: {
                x: 0.833,
                y: 0.833,
              },
              s: [105, 100, 100],
              t: 34,
            },
            {
              o: {
                x: 0.167,
                y: 0.167,
              },
              i: {
                x: 0.833,
                y: 0.833,
              },
              s: [95, 100, 100],
              t: 40,
            },
            {
              s: [100, 100, 100],
              t: 46,
            },
          ],
          ix: 6,
        },
        sk: {
          a: 0,
          k: 0,
        },
        p: {
          a: 1,
          k: [
            {
              o: {
                x: 0.167,
                y: 0.167,
              },
              i: {
                x: 0.833,
                y: 0.833,
              },
              s: [-31, -22.2, 0],
              t: 16,
              ti: [0, -19.333, 0],
              to: [0, 22.733, 0],
            },
            {
              o: {
                x: 0.167,
                y: 0.167,
              },
              i: {
                x: 0.833,
                y: 0.833,
              },
              s: [-31, 114.2, 0],
              t: 22,
              ti: [0, 0, 0],
              to: [0, 19.333, 0],
            },
            {
              o: {
                x: 0.167,
                y: 0.167,
              },
              i: {
                x: 0.833,
                y: 0.833,
              },
              s: [-31, 93.8, 0],
              t: 28,
              ti: [0, -1.883, 0],
              to: [0, 0, 0],
            },
            {
              o: {
                x: 0.167,
                y: 0.167,
              },
              i: {
                x: 0.833,
                y: 0.833,
              },
              s: [-31, 114.2, 0],
              t: 34,
              ti: [0, 0, 0],
              to: [0, 1.883, 0],
            },
            {
              o: {
                x: 0.167,
                y: 0.167,
              },
              i: {
                x: 0.833,
                y: 0.833,
              },
              s: [-31, 105.1, 0],
              t: 40,
              ti: [0, -1.517, 0],
              to: [0, 0, 0],
            },
            {
              s: [-31, 114.2, 0],
              t: 46,
            },
          ],
          ix: 2,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        sa: {
          a: 0,
          k: 0,
        },
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 1',
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, -10.162],
                    [10.162, 0],
                    [0, 10.162],
                    [-10.162, 0],
                  ],
                  o: [
                    [0, 10.162],
                    [-10.162, 0],
                    [0, -10.162],
                    [10.162, 0],
                  ],
                  v: [
                    [18.4, 0],
                    [0, 18.4],
                    [-18.4, 0],
                    [0, -18.4],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: {
                a: 0,
                k: [0.0627, 0.4157, 0.949],
                ix: 4,
              },
              r: 1,
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
            },
            {
              ty: 'tr',
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 2,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
            },
          ],
        },
      ],
      ind: 4,
      parent: 1,
    },
    {
      ty: 4,
      nm: 'line',
      sr: 1,
      st: 0,
      op: 50,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
        },
        sk: {
          a: 0,
          k: 0,
        },
        p: {
          a: 0,
          k: [46.6, 67.4, 0],
          ix: 2,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        sa: {
          a: 0,
          k: 0,
        },
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 1',
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [37.445, 0],
                    [0, 0],
                    [0, 37.445],
                    [0, 0],
                    [-37.445, 0],
                    [0, 0],
                    [0, -37.445],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-37.445, 0],
                    [0, 0],
                    [0, -37.445],
                    [0, 0],
                    [37.445, 0],
                    [0, 0],
                    [0, 37.445],
                  ],
                  v: [
                    [151.8, 67.8],
                    [-151.8, 67.8],
                    [-219.6, 0],
                    [-219.6, 0],
                    [-151.8, -67.8],
                    [151.8, -67.8],
                    [219.6, 0],
                    [219.6, 0],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 10,
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 0,
                k: 6,
                ix: 5,
              },
              c: {
                a: 0,
                k: [0.0627, 0.4157, 0.949],
                ix: 3,
              },
            },
            {
              ty: 'tr',
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 2,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
            },
          ],
        },
        {
          ty: 'tm',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Filter - Trim',
          nm: 'Trim Paths 1',
          ix: 2,
          e: {
            a: 1,
            k: [
              {
                o: {
                  x: 0.333,
                  y: 0,
                },
                i: {
                  x: 0.667,
                  y: 1,
                },
                s: [78.8],
                t: 10,
              },
              {
                s: [29.4],
                t: 20,
              },
            ],
            ix: 2,
          },
          o: {
            a: 0,
            k: 0,
            ix: 3,
          },
          s: {
            a: 1,
            k: [
              {
                o: {
                  x: 0.333,
                  y: 0,
                },
                i: {
                  x: 0.667,
                  y: 1,
                },
                s: [50],
                t: 10,
              },
              {
                s: [0.6],
                t: 20,
              },
            ],
            ix: 1,
          },
          m: 1,
        },
      ],
      ind: 5,
      parent: 1,
    },
  ],
  v: '5.8.1',
  fr: 24,
  op: 50,
  ip: 0,
  assets: [],
};
