import { useInvalidations } from '@kandji-inc/bumblebee';
import screenFormDefaultValidations from './screen-form-default-validations';

const useScreenFormValidation = ({ inputs } = {}) => {
  if (!inputs) {
    throw new Error('inputs is required.');
  }
  const { invalidations, onInvalidate } = useInvalidations({ inputs });
  const validator = (maxLength) => (v) =>
    screenFormDefaultValidations(v, maxLength);

  const isFormInvalid = invalidations.some(Boolean);

  return {
    isFormInvalid,
    validator,
    onInvalidate,
  };
};

export default useScreenFormValidation;
