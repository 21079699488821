import { getActivationLockColumns } from './getActivationLockColumns';
import { getApplicationFirewallColumns } from './getApplicationFirewallColumns';
import { getAppsColumns } from './getAppsColumns';
import { getCertificatesColumns } from './getCertificatesColumns';
import { getDesktopAndScreensaverColumns } from './getDesktopAndScreensaverColumns';
import { getFileVaultColumns } from './getFileVaultColumns';
import { getGatekeeperAndXprotectColumns } from './getGatekeeperAndXprotectColumns';
/* istanbul ignore file */
// import { getDeviceInfoColumns } from './getDeviceInfoColumns';
import { getInstalledProfilesColumns } from './getInstalledProfilesColumns';
import { getKernelExtensionsColumns } from './getKernelExtensionsColumns';
import { getLaunchAgentsDaemonsColumns } from './getLaunchAgentsDaemonsColumns';
import { getLocalUsersColumns } from './getLocalUsersColumns';
import { getStartupSettingsColumns } from './getStartupSettingsColumns';
import { getSystemExtensionsColumns } from './getSystemExtensionsColumns';
import { getTransparencyDatabaseColumns } from './getTransparencyDatabaseColumns';

export const getColumnsForPrismCategory = (schema) => {
  switch (schema.uri) {
    // case 'device_information':
    //   return getDeviceInfoColumns(schema);
    case 'activation_lock':
      return getActivationLockColumns({ activationLockSchema: schema });
    case 'application_firewall':
      return getApplicationFirewallColumns({
        applicationFirewallSchema: schema,
      });
    case 'apps':
      return getAppsColumns({ appsSchema: schema });
    case 'certificates':
      return getCertificatesColumns({ certificatesSchema: schema });
    case 'desktop_and_screensaver':
      return getDesktopAndScreensaverColumns({
        desktopAndScreensaverSchema: schema,
      });
    case 'filevault':
      return getFileVaultColumns({ fileVaultSchema: schema });
    case 'gatekeeper_and_xprotect':
      return getGatekeeperAndXprotectColumns({
        gatekeeperAndXprotectSchema: schema,
      });
    case 'installed_profiles':
      return getInstalledProfilesColumns({ installedProfilesSchema: schema });
    case 'kernel_extensions':
      return getKernelExtensionsColumns({ kernelExtensionsSchema: schema });
    case 'launch_agents_and_daemons':
      return getLaunchAgentsDaemonsColumns({
        launchAgentsDaemonsSchema: schema,
      });
    case 'local_users':
      return getLocalUsersColumns({ localUsersSchema: schema });
    case 'startup_settings':
      return getStartupSettingsColumns({ startupSettingsSchema: schema });
    case 'system_extensions':
      return getSystemExtensionsColumns({ systemExtensionsSchema: schema });
    case 'transparency_database':
      return getTransparencyDatabaseColumns({
        transparencyDatabaseSchema: schema,
      });

    default:
      return {
        columnDefs: [],
        pinnedColumns: [],
        alwaysHiddenColumns: [],
      };
  }
};
