import { rootApi } from 'app/api/base';
/* istanbul ignore file */
import { useEffect, useState } from 'react';
import { useValidate } from '../ethernet.hooks';
import EthernetService from '../service/ethernet-service';

const keys = EthernetService.keys.authentication.acceptedEapTypes;

export const useShowCategories = ({ validationDep, setting, onInvalidate }) => {
  const [adcsServers, setAdcsServers] = useState();

  useEffect(() => {
    rootApi('/integrations/v1/adcs/authority/')
      .get()
      .then(({ data: { results } }) =>
        setAdcsServers(
          results.map(({ name, id }) => ({
            value: id,
            label: name,
          })),
        ),
      )
      .catch((_) => {
        // Not much to do here, we can't log to console. In this there was an
        // error retrieving adcs servers.
      });
  }, []);

  const showAcceptedEapTypesCategory = (values = [], only = false) => {
    const acceptedEapTypes = setting[keys.acceptedEapTypes] || [];

    if (only) {
      return !acceptedEapTypes.some(
        ({ value }) => !values.some((v) => v === value),
      );
    }
    return !!values.find(
      (value) => !!acceptedEapTypes.find((opt) => opt.value === value),
    );
  };

  const showAuthentication = showAcceptedEapTypesCategory([
    EthernetService.protocols.TTLS,
    EthernetService.protocols.LEAP,
    EthernetService.protocols.PEAP,
    EthernetService.protocols.EAP_FAST,
  ]);
  const showInnerAuthentication = showAcceptedEapTypesCategory([
    EthernetService.protocols.TTLS,
  ]);
  const showOuterIdentity = showAcceptedEapTypesCategory([
    EthernetService.protocols.TTLS,
    EthernetService.protocols.PEAP,
    EthernetService.protocols.EAP_FAST,
  ]);
  const showTlsMinimumVersion = showAcceptedEapTypesCategory([
    EthernetService.protocols.TTLS,
    EthernetService.protocols.TLS,
    EthernetService.protocols.PEAP,
    EthernetService.protocols.EAP_FAST,
  ]);
  const showTlsMaximumVersion = showAcceptedEapTypesCategory([
    EthernetService.protocols.TTLS,
    EthernetService.protocols.TLS,
    EthernetService.protocols.PEAP,
    EthernetService.protocols.EAP_FAST,
  ]);
  const showAuthenticationCredentials =
    setting[keys.authentication] ===
    EthernetService.authentications.USERNAME_AND_PASSWORD;
  const showPac = showAcceptedEapTypesCategory([
    EthernetService.protocols.EAP_FAST,
  ]);
  const showTlsUsername = showAcceptedEapTypesCategory(
    [EthernetService.protocols.TLS],
    true,
  );

  const showProvisionPac = showPac && setting[keys.usePac];
  const showProvisionPacAnonym = showProvisionPac && setting[keys.provisionPac];

  const showTwoFactorAuth =
    showAcceptedEapTypesCategory([
      EthernetService.protocols.TTLS,
      EthernetService.protocols.PEAP,
      EthernetService.protocols.EAP_FAST,
    ]) && !setting.useLoginWindow;
  const canShowInnerAuthenticationError = useValidate({
    error:
      showInnerAuthentication &&
      !setting[keys.innerAuthentication] &&
      'Required',
    onInvalidate: onInvalidate(keys.innerAuthentication),
    reset: showInnerAuthentication,
    validationDep,
  });
  const canShowMinimumTlsVersionError = useValidate({
    error:
      showTlsMinimumVersion && !setting[keys.tlsMinimumVersion] && 'Required',
    onInvalidate: onInvalidate(keys.tlsMinimumVersion),
    reset: showTlsMinimumVersion,
    validationDep,
  });
  const canShowMaximumTlsVersionError = useValidate({
    error:
      showTlsMaximumVersion && !setting[keys.tlsMaximumVersion] && 'Required',
    onInvalidate: onInvalidate(keys.tlsMaximumVersion),
    reset: showTlsMaximumVersion,
    validationDep,
  });

  const isTls = showAcceptedEapTypesCategory([EthernetService.protocols.TLS]);
  const canShowIdentityCertificateError = useValidate({
    error:
      isTls &&
      !setting[EthernetService.keys.authentication.identityCertificate] &&
      'Required',
    onInvalidate: onInvalidate(
      EthernetService.keys.authentication.identityCertificate,
    ),
    reset: isTls,
    validationDep,
  });

  const showConfigureScep =
    setting.identityCertificate === EthernetService.identityCertificates.SCEP;
  const showConfigurePkcs =
    setting.identityCertificate === EthernetService.identityCertificates.PKCS12;
  const showConfigureAdcs =
    setting.identityCertificate === EthernetService.identityCertificates.ADCS;

  return {
    showAuthentication,
    showInnerAuthentication,
    showOuterIdentity,
    showTlsMinimumVersion,
    showTlsMaximumVersion,
    showAuthenticationCredentials,
    showTlsUsername,
    showProvisionPac,
    showProvisionPacAnonym,
    showTwoFactorAuth,
    canShowInnerAuthenticationError,
    canShowMinimumTlsVersionError,
    canShowMaximumTlsVersionError,
    canShowIdentityCertificateError,
    showPac,
    showConfigureScep,
    showConfigurePkcs,
    showConfigureAdcs,
    adcsServers,
  };
};
