/* istanbul ignore file */
import { i18n } from 'src/i18n';

/**
 * Maps known Restrictions categories to their human readable variant.
 */
export const getRestrictionCategories = () => {
  const categories = {
    apps: i18n.t('Apps'),
    blackandwhitelist: i18n.t('Manage App Restrictions'),
    autonomoussingleappmode: i18n.t('Autonomous Single App Mode'),
    passcodeandauthentication: i18n.t('Passcode & Authentication'),
    lockscreen: i18n.t('Lock Screen'),
    accountsandpasswords: i18n.t('Accounts & Passwords'),
    datasegregation: i18n.t('Data Segregation'),
    icloud: i18n.t('iCloud'),
    network: i18n.t('Network'),
    cellular: i18n.t('Cellular'),
    airdrop: i18n.t('AirDrop'),
    airplay: i18n.t('AirPlay'),
    airprint: i18n.t('AirPrint'),
    applewatch: i18n.t('Apple Watch'),
    diagnostics: i18n.t('Diagnostics'),
    files: i18n.t('Files'),
    findmy: i18n.t('Find My'),
    gamecenter: i18n.t('Game Center'),
    keyboard: i18n.t('Keyboard'),
    media: i18n.t('Media'),
    osupdates: i18n.t('OS Updates'),
    safari: i18n.t('Safari'),
    siri: i18n.t('Siri'),
    osandgeneral: i18n.t('OS & General'),
    sharing: i18n.t('Sharing'),
    appleIntelligence: i18n.t('Apple Intelligence'),
  };

  return categories;
};
