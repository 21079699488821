import { WallpaperUploader } from 'components/common/s3-upload';
/* istanbul ignore file */
import imageToFile from 'features/self-service-new/api/tofile';
import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class WallpaperService extends NewLibraryItemService {
  async uploadWallpaperImage(dataUrl, filename) {
    const file = imageToFile(dataUrl, filename);

    if (!file) {
      return null;
    }

    const s3Data = await WallpaperUploader.getS3UploadData([
      { name: file.name },
    ]);

    await WallpaperUploader.upload({
      file,
      s3Data: s3Data.data.files[0].s3_post_data,
    });

    return s3Data.data.files[0].s3_post_data;
  }
}

export const wallpaperService = new WallpaperService();
