import { getTopOffset } from 'app/components/common/helpers';
import React, { createContext, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  setSidebarDocked as callSetSidebarDocked,
  setSidebarOpened as callSetSidebarOpened,
  setSnackbar as callSetSnackbar,
} from '../app/_actions/ui';

// TODO: revisit and possibly rework when the sidebar and global layout is refactored
// TODO: reference Offcanvas width and offsetting of other regions

const defaultValues = {
  bannerTopOffset: 0,
  isEditingLibraryItem: undefined,
  modal: undefined,
  offcanvas: undefined,
  confirmValue: undefined,
  sidebarDocked: false,
  sidebarOpened: false,
  isOpenModalFeatureRequest: false,
  modalData: {},
  setIsEditingLibraryItem: () => {},
  setModal: () => {},
  setModalData: () => {},
  setOffcanvas: () => {},
  setSidebarDocked: () => {},
  setSidebarOpened: () => {},
  setSnackbar: () => {},
  setConfirmValue: () => {},
  Banner: null,
  setBanner: (Banner) => {},
  setBannerTopOffset: () => {},
  setIsOpenModalFeatureRequest: () => {},
  onModalOpen: () => {},
  onModalClose: () => {},
  onOffcanvasOpen: () => {},
  onOffcanvasClose: () => {},
};

const InterfaceContext = createContext(defaultValues);

const InterfaceProviderConnect = ({
  sidebarDocked,
  sidebarOpened,
  setSidebarDocked,
  setSidebarOpened,
  setSnackbar,
  children,
  value,
}) => {
  // Banner
  const [Banner, setBanner] = useState(null);
  const [bannerTopOffset, setBannerTopOffset] = useState(0);
  const handleBannerOffsetChange = () => setBannerTopOffset(getTopOffset());

  // Modal
  const [modal, setModal] = useState(undefined);
  const [modalData, setModalData] = useState({});
  const onModalOpen = (name) => setModal({ [name]: true });
  const onModalClose = () => setModal({}); // might need to modify this to only close a specific modal per "name" similar to onModalOpen above

  // Modal - Feature Request
  const [isOpenModalFeatureRequest, setIsOpenModalFeatureRequest] =
    useState(false);

  // Offcanvas
  const [offcanvas, setOffcanvas] = useState(undefined);
  const onOffcanvasOpen = (name) => setOffcanvas({ [name]: true });
  const onOffcanvasClose = () => setOffcanvas({}); // might need to modify this to only close a specific offcanvas per "name" similar to onOffcanvasOpen above

  // Confirm
  const [confirmValue, setConfirmValue] = useState('');

  const [isEditingLibraryItem, setIsEditingLibraryItem] = useState(false);

  return (
    <InterfaceContext.Provider
      value={{
        ...defaultValues,
        bannerTopOffset,
        isEditingLibraryItem,
        modal,
        offcanvas,
        confirmValue,
        sidebarDocked,
        sidebarOpened,
        isOpenModalFeatureRequest,
        modalData,
        setIsEditingLibraryItem,
        setModal,
        setModalData,
        setOffcanvas,
        setSidebarDocked,
        setSidebarOpened,
        setSnackbar,
        setConfirmValue,
        Banner,
        setBanner,
        setBannerTopOffset: handleBannerOffsetChange,
        setIsOpenModalFeatureRequest,
        onModalOpen,
        onModalClose,
        onOffcanvasOpen,
        onOffcanvasClose,
        ...value,
      }}
    >
      {children}
    </InterfaceContext.Provider>
  );
};

const mapStateToProps = ({ ui }) => ({
  sidebarDocked: ui.sidebar.docked,
  sidebarOpened: ui.sidebar.opened,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSidebarDocked: callSetSidebarDocked,
      setSidebarOpened: callSetSidebarOpened,
      setSnackbar: callSetSnackbar,
    },
    dispatch,
  );

const InterfaceProvider = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InterfaceProviderConnect);

export { InterfaceContext, InterfaceProvider };
