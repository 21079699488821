export const PREVIEW_CONFIG = {
  ipad: {
    maxPages: 11,
    maxPerPage: 30,
    maxPerFolder: 16,
    maxDock: 12,
    maxDockVisible: 5,
    pageWidth: {
      portrait: 525,
      landscape: 685,
    },
    pageHeight: {
      portrait: 685,
      landscape: 525,
    },
    dockWidth: {
      portrait: 464,
      landscape: 464,
    },
    dockHeight: {
      portrait: 62,
      landscape: 62,
    },
  },

  iphone: {
    maxPages: 11,
    maxPerPage: 24,
    maxPerFolder: 9,
    maxDock: 4,
    maxDockVisible: 5,
    pageWidth: {
      portrait: 340,
      landscape: undefined,
    },
    pageHeight: {
      portrait: 690,
      landscape: undefined,
    },
    dockWidth: {
      portrait: 328,
      landscape: undefined,
    },
    dockHeight: {
      portrait: 62,
      landscape: undefined,
    },
  },

  common: {
    pageDroppableContainerId: 'page_drop_container',
    dockDroppableContainerId: 'dock_drop_container',
    folderDroppableContainerId: 'folder_drop_container',
    dndContext: {
      draggableApp: 'draggable_app',
      droppablePage: 'droppable_page',
      droppableDock: 'droppable_dock',
      droppableFolder: 'droppable_folder',
      sortablePageItem: 'sortable_page_item',
      sortablePageFolder: 'sortable_page_folder',
      sortableDockItem: 'sortable_dock_item',
      sortableFolderItem: 'sortable_folder_item',
    },
    get prefixSortablePageItemId() {
      return `${this.dndContext.sortablePageItem}_`;
    },
    get prefixSortablePageFolderId() {
      return `${this.dndContext.sortablePageFolder}_`;
    },
    get prefixSortableDockItemId() {
      return `${this.dndContext.sortableDockItem}_`;
    },
    get prefixSortableFolderItemId() {
      return `${this.dndContext.sortableFolderItem}_`;
    },
  },
} as const;

export const LAYOUT_CONFIG = {
  height: {
    styledVar: '$$listHeight',
    cssVar: '---listHeight',
    portrait: {
      value: 701,
      px: '701px',
      styledVar: '$$listHeightPortrait',
      cssVar: '---listHeightPortrait',
    },
    landscape: {
      value: 572,
      px: '572px',
      styledVar: '$$listHeightLandscape',
      cssVar: '---listHeightLandscape',
    },
  },
} as const;

export const APP_ICON_CONFIG = {
  containerSize: 62,
  iconImageSize: 48,
} as const;

export function getTruncatedAppLabelStatus({
  parentNode,
  selector,
}: {
  parentNode: HTMLElement;
  selector: string;
}) {
  const appLabelNode = parentNode?.querySelector(selector);

  if (!appLabelNode) {
    return [
      null,
      {
        isTruncated: false,
      },
    ] as const;
  }

  const { clientWidth, scrollWidth } = appLabelNode;
  const isTruncated = clientWidth >= scrollWidth;

  return [
    appLabelNode,
    {
      isTruncated,
    },
  ] as const;
}
