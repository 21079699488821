import { createColumnHelper } from '@tanstack/react-table';
import { formatBytes, formatTime } from 'src/app/components/common/helpers';
import { getDeviceCell } from 'src/features/visibility/prism/utils/column-utils';
import type { PulseProcessCheckDeviceSchema } from 'src/features/visibility/pulse/types/pulse.types';
import type { PulseCheckDetailColumns } from '../utils/pulse-column-types';
import { JsonDownloadButton } from './JsonDownloadButton';
import { PulseResultsTable } from './PulseResultsTable';

interface Results {
  name: string;
  user: string;
  state: string;
  threads: number;
  child_processes: unknown;
  real_memory_size: number;
  pid: number;
  ppid: number;
  cpu_time: number;
  cpu_percentage: number;
}

interface PulseProcessResultsColumns extends PulseCheckDetailColumns {
  results: Results;
}

const columnHelper = createColumnHelper<PulseProcessResultsColumns>();

/*
  TODO:
  - Missing:
    - User email: user_email
    - GPU time: gpu_time
    - GPU percentage: gpu_percentage
    - Idle wake ups: idle_wake_ups
*/

export function formatMillisecondsToDecimalHours(milliseconds: number): string {
  const totalMinutes = milliseconds / 60000;
  const hours = totalMinutes / 60;

  if (hours < 1) {
    if (totalMinutes.toFixed(2) === '1.00') {
      return `${totalMinutes.toFixed(2)} minute`;
    }
    return `${totalMinutes.toFixed(2)} minutes`;
  }

  if (hours.toFixed(2) === '1.00') {
    return `${hours.toFixed(2)} hour`;
  }

  return `${hours.toFixed(2)} hours`;
}

const processesColumns = [
  columnHelper.accessor((row) => row.device__name, {
    id: 'device__name',
    cell: (info) => getDeviceCell(info),
    header: () => 'Device',
  }),

  columnHelper.accessor((row) => row.device__user_name, {
    id: 'device__user_name',
    cell: (row) => row.getValue(),
    header: () => 'Device user',
  }),
  columnHelper.accessor((row) => row.results?.name, {
    id: 'name',
    cell: (row) => row.getValue(),
    header: () => 'Process name',
  }),
  columnHelper.accessor((row) => row.results?.cpu_percentage, {
    id: 'cpu_percentage',
    cell: (row) => {
      const pct = row.getValue();
      return pct != null ? `${pct.toFixed(2)}%` : '';
    },
    header: () => 'CPU percentage',
  }),
  columnHelper.accessor((row) => row.results?.state, {
    id: 'state',
    cell: (row) => row.getValue(),
    header: () => 'Process state',
  }),
  columnHelper.accessor((row) => row.results?.threads, {
    id: 'threads',
    cell: (row) => {
      const pct = row.getValue();
      return pct != null ? `${pct}%` : '';
    },
    header: () => 'Threads',
  }),
  columnHelper.accessor((row) => row.results?.user, {
    id: 'user',
    cell: (row) => row.getValue(),
    header: () => 'Owned by',
  }),
  columnHelper.accessor((row) => row.results?.child_processes, {
    id: 'child_processes',
    cell: (row) => <JsonDownloadButton data={row.getValue()} />,
    header: () => 'Child processes',
  }),
  columnHelper.accessor((row) => row.updated_at, {
    id: 'updated_at',
    cell: (row) => formatTime(row.getValue(), false, '', true),
    header: () => 'Last Collected',
  }),
  columnHelper.accessor((row) => row.results?.cpu_time, {
    id: 'cpu_time',
    cell: (row) => {
      const time = row.getValue();
      return time != null ? formatMillisecondsToDecimalHours(time) : '';
    },
    header: () => 'CPU time',
  }),
  columnHelper.accessor((row) => row.results?.pid, {
    id: 'pid',
    cell: (row) => row.getValue(),
    header: () => 'Process ID',
  }),
  columnHelper.accessor((row) => row.results?.ppid, {
    id: 'ppid',
    cell: (row) => row.getValue(),
    header: () => 'Parent process ID',
  }),
  columnHelper.accessor((row) => row.results?.real_memory_size, {
    id: 'real_memory_size',
    cell: (row) => formatBytes(row.getValue()),
    header: () => 'Real memory size',
  }),
];

export const PulseProcessResults = ({
  jobDevicesData,
  isLoading,
  onRerunJob,
}: {
  jobDevicesData: PulseProcessCheckDeviceSchema[];
  isLoading: boolean;
  onRerunJob: () => void;
}) => {
  return (
    <PulseResultsTable
      columns={processesColumns}
      jobDevicesData={jobDevicesData}
      isLoading={isLoading}
      onRerunJob={onRerunJob}
    />
  );
};
