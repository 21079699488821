/* istanbul ignore file */
import { formatDateTime, formatNumber } from '@kandji-inc/nectar-i18n';

export const format = {
  number: (num: number) => formatNumber(num),
  date: (date: Date) => formatDateTime(date, { dateStyle: 'short' }),
  dateMedium: (date: Date) => formatDateTime(date, { dateStyle: 'medium' }),
  dateLong: (date: Date) => formatDateTime(date, { dateStyle: 'long' }),
  time: (date: Date) => formatDateTime(date, { timeStyle: 'short' }),
  timeMedium: (date: Date) => formatDateTime(date, { timeStyle: 'medium' }),
  timeLong: (date: Date) => formatDateTime(date, { timeStyle: 'long' }),
  datetime: (date: Date) =>
    formatDateTime(date, { dateStyle: 'short', timeStyle: 'short' }),
  datetimeMedium: (date: Date) =>
    formatDateTime(date, { dateStyle: 'medium', timeStyle: 'medium' }),
  datetimeLong: (date: Date) =>
    formatDateTime(date, { dateStyle: 'long', timeStyle: 'long' }),
  datetimeCustom: (date: Date, dateStyle: string, timeStyle: string) =>
    formatDateTime(date, { dateStyle, timeStyle }),
};
