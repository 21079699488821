import { Setting } from 'features/library-items/template';
import React from 'react';
import featureFlags from 'src/config/feature-flags';
import { i18n } from 'src/i18n';
import { LanguageRegionSetting } from '../general/LanguageRegionSetting';
import MinimumOSVersionRow from '../minimum-os-verstion-row';
import AccountTypeRow from './account-type-row';
import ActivationLockRow from './activation-lock-row';
import LocalAdministratorRow from './local-administrator-row';
import SkipScreensRow from './skip-screens-row';

const Mac = (props) => {
  const { setting, update, isDisabled } = props;

  const languageRegionEnabled = featureFlags.getFlag('dl-language-region');
  const showLanguageRegionSetting =
    languageRegionEnabled && setting.canSkip && setting.isAutoAdvance;

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('Mac')}</h3>
        <Setting.HeaderToggle
          isEnabled={setting.isEnabled}
          onChange={() => update('isEnabled', (p) => !p)}
          isDisabled={isDisabled}
          isHideChip
        />
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          {i18n.t(
            'Customize and configure the macOS Setup Assistant screens, Auto Admin account, Activation Lock, and Auto Advance.',
          )}
        </p>
      </Setting.SubHeader>
      {setting.isEnabled && (
        <Setting.Rows>
          <SkipScreensRow {...props} />
          <ActivationLockRow {...props} />
          <AccountTypeRow {...props} />
          <LocalAdministratorRow {...props} />
          <MinimumOSVersionRow {...props} typeOS={i18n.t('macOS')} os="macOS" />
          {/* <PackagesRow {...props} /> */}
          {showLanguageRegionSetting && (
            <LanguageRegionSetting
              deviceType={i18n.t('Mac')}
              setting={setting}
              isDisabled={isDisabled}
              update={update}
            />
          )}
        </Setting.Rows>
      )}
    </Setting.Card>
  );
};

export default Mac;
