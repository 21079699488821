import {
  Checkbox,
  TextInput,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';
import { i18n } from 'src/i18n';

const LockMessageSection = (props) => {
  const { isDisabled, update, setting, validationDep } = props;

  const validationKeys = ['LoginwindowText'];
  const { refs, onInvalidate } = useInputsValidators(validationKeys, update);
  useRemoveValidationOnUnmount(validationKeys, update);

  const lockMessageField = setting.LoginwindowText;
  return (
    <Setting.Card>
      <Setting.Header>
        <h3 ref={refs.LoginwindowText} className="b-h3">
          {i18n.t('Lock message')}
        </h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              isDisabled={isDisabled}
              checked={lockMessageField.checked}
              label={i18n.t('Set lock message')}
              onChange={() => {
                if (lockMessageField.checked) {
                  onInvalidate('LoginwindowText')(false);
                }
                update('LoginwindowText', (p) => ({
                  ...p,
                  checked: !p.checked,
                }));
              }}
              testId="LoginwindowText"
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            {i18n.t(`Display a custom message at the bottom of the login window and lock
            screen. It is recommended to limit the length of this message, as
            macOS displays only a few lines of text at a time.`)}
          </Setting.Helpers>
          {lockMessageField?.checked && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <TextInput
                    disabled={isDisabled}
                    compact
                    textArea
                    value={lockMessageField.value}
                    placeholder={i18n.t(
                      'Property of AccuHive. Authorized use only.',
                    )}
                    onChange={(e) => {
                      const { value } = e.target;
                      update('LoginwindowText', (p) => ({ ...p, value }));
                    }}
                    validator={(v) => [
                      {
                        message: i18n.t('Required.'),
                        invalid: () => !v.length,
                        trigger: ['onBlur', validationDep],
                      },
                    ]}
                    onInvalidate={onInvalidate('LoginwindowText')}
                  />
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default LockMessageSection;
