import {
  getRunsOnFromModel,
  getSelectedDevicesFromRunsOn,
} from 'src/features/library-items/data-service/library-item/devices';
import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import configs from '../../../library/library-item-configurations/items/profile';

function transformSettings(settings) {
  return {
    Mac: {
      ...settings.Mac,
      // Set the single value properties to objects for consistency:
      forcePIN: {
        checked: settings.Mac.forcePIN,
      },
      allowSimple: {
        checked: settings.Mac.allowSimple,
      },
      requireAlphanumeric: {
        checked: settings.Mac.requireAlphanumeric,
      },
      changeAtNextAuth: {
        checked: settings.Mac.changeAtNextAuth,
      },
      minLength: {
        ...settings.Mac.minLength,
        value:
          settings.Mac.minLength.value > 16 ? 16 : settings.Mac.minLength.value,
      },
    },
    iPhone: {
      ...settings.iPhone,
      // Set the single value properties to objects for consistency:
      allowSimple: {
        checked: settings.iPhone.allowSimple,
      },
      forcePIN: {
        checked: settings.iPhone.forcePIN,
      },
      requireAlphanumeric: {
        checked: settings.iPhone.requireAlphanumeric,
      },
      minLength: {
        ...settings.iPhone.minLength,
        value:
          settings.iPhone.minLength.value > 16
            ? 16
            : settings.iPhone.minLength.value,
      },
    },
    iPad: {
      ...settings.iPad,
      // Set the single value properties to objects for consistency:
      allowSimple: {
        checked: settings.iPad.allowSimple,
      },
      forcePIN: {
        checked: settings.iPad.forcePIN,
      },
      requireAlphanumeric: {
        checked: settings.iPad.requireAlphanumeric,
      },
      minLength: {
        ...settings.iPad.minLength,
        value:
          settings.iPad.minLength.value > 16
            ? 16
            : settings.iPad.minLength.value,
      },
    },
  };
}

async function transformFromApi(apiData) {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const commonData = {
    id: apiData.id,
    name: apiData.name,
    devices: getSelectedDevicesFromRunsOn(apiData),
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
  };

  // const result = await transformer(apiData, commonData);
  const result = {
    ...commonData,
    isActive: apiData.active,
    ...transformSettings(apiData.data),
  };

  result.rules = apiData.rules;

  return {
    ...apiData,
    data: result,
  };
}

function transformToApi(model) {
  const toSend = {
    name: model.name,
    active: model.isActive,
    blueprints: model.selectedBlueprints.map((b) => b.value),
    is_all_blueprints: model.isAllBlueprints,
    data: {
      Mac: getMacSettingsForApi(model.Mac),
      iPhone: getIPhoneSettingsForApi(model.iPhone),
      iPad: getIPadSettingsForApi(model.iPad),
    },
  };

  // Add necessary properties when creating new Profile:
  if (!model.id) {
    const itemConfig = configs.Passcode;

    toSend.type = itemConfig.type;
    toSend.identifier = itemConfig.identifier;
  }

  toSend.rules = model.rules || null;

  if (model.skip_blueprint_conflict) {
    toSend.skip_blueprint_conflict = model.skip_blueprint_conflict;
  }

  // Populate `runs_on_*` fields
  const runsOn = getRunsOnFromModel(model);
  Object.keys(runsOn).forEach((runsOnKey) => {
    toSend[runsOnKey] = runsOn[runsOnKey];
  });

  return toSend;
}

function getMacSettingsForApi(settings) {
  return {
    ...settings,
    forcePIN: settings.forcePIN.checked,
    allowSimple: settings.allowSimple.checked,
    requireAlphanumeric: settings.requireAlphanumeric.checked,
    changeAtNextAuth: settings.changeAtNextAuth.checked,
  };
}

function getIPhoneSettingsForApi(settings) {
  return {
    ...settings,
    allowSimple: settings.allowSimple.checked,
    forcePIN: settings.forcePIN.checked,
    requireAlphanumeric: settings.requireAlphanumeric.checked,
  };
}

function getIPadSettingsForApi(settings) {
  return {
    ...settings,
    allowSimple: settings.allowSimple.checked,
    forcePIN: settings.forcePIN.checked,
    requireAlphanumeric: settings.requireAlphanumeric.checked,
  };
}

export { transformToApi, transformFromApi };
