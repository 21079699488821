import React from 'react';
import { useBlueprints } from 'src/features/visibility/prism/hooks';
import type {
  PulseCheckAgentFilterSchema,
  PulseCheckJobSchema,
  PulseCheckTypes,
  PulseFileCheckDeviceResponseSchema,
  PulseLoggedInUsersDeviceResponseSchema,
  PulsePreferencesDeviceResponseSchema,
  PulseProcessCheckDeviceResponseSchema,
} from 'src/features/visibility/pulse/types/pulse.types';
import { useJobDevicesQuery } from './use-job-devices-query';
import { useJobMetadataQuery } from './use-job-metadata-query';

export interface UsePulseCheckDetailsResult {
  agentFilters: PulseCheckAgentFilterSchema;
  jobDevicesData:
    | PulseFileCheckDeviceResponseSchema
    | PulseProcessCheckDeviceResponseSchema
    | PulseLoggedInUsersDeviceResponseSchema
    | PulsePreferencesDeviceResponseSchema;
  jobMetadata: PulseCheckJobSchema;
  jobType: PulseCheckTypes;
  name: string;
  refetchJobDevices: () => void;
  showPending: boolean;
  status: string;
  totalFinished: number;
  totalTargeted: number;
  jobMetadataIsPending: boolean;
  dataUpdatedAt: number;
  isJobDevicesRefetching: boolean;
  targetedBlueprints: { name: string; id: string }[];
  targetedDevices: { name: string; id: string }[];
  description: string;
}

export interface UsePulseCheckDetails {
  (
    pulseJobId: string,
    jobDevicesPagination: { limit: number; offset: number },
  ): UsePulseCheckDetailsResult;
}

export const usePulseCheckDetails: UsePulseCheckDetails = (
  pulseJobId: string,
  jobDevicesPagination: { limit: number; offset: number },
) => {
  const [showPending, setShowPending] = React.useState(true);

  const { data: jobMetadata, isPending: jobMetadataIsPending } =
    useJobMetadataQuery(pulseJobId);

  const jobType = jobMetadata?.type;
  const totalTargeted = jobMetadata?.total_devices_targeted ?? 0;
  const totalFinished = jobMetadata?.total_devices_finished ?? 0;
  const status = jobMetadata?.status;
  const name = jobMetadata?.name;
  const description = jobMetadata?.description ?? '';

  const agentFilters = React.useMemo<PulseCheckAgentFilterSchema>(() => {
    const defaultFilters =
      jobType === 'file_check'
        ? {
            hashes: [],
            paths: [],
          }
        : {};
    if (jobMetadata?.agent_filters) {
      try {
        return JSON.parse(jobMetadata.agent_filters);
      } catch (error) {
        console.error('Error parsing agent filters:', error);
        return defaultFilters;
      }
    }
    return defaultFilters;
  }, [jobMetadata?.agent_filters, jobType]);

  const blueprintQueryEnabled = Boolean(jobMetadata?.blueprints?.length);

  const { data: blueprintsData } = useBlueprints(blueprintQueryEnabled);

  const {
    data: jobDevicesData,
    isPending: jobDevicesIsPending,
    refetch: refetchJobDevices,
    dataUpdatedAt,
    isRefetching: isJobDevicesRefetching,
  } = useJobDevicesQuery(
    pulseJobId,
    jobType,
    totalFinished,
    jobDevicesPagination,
  );

  React.useEffect(() => {
    if (
      dataUpdatedAt > 0 &&
      !isJobDevicesRefetching &&
      !jobDevicesIsPending &&
      !jobMetadataIsPending &&
      (jobMetadata.status === 'complete' || totalFinished > 0)
    ) {
      setShowPending(false);
    } else {
      setShowPending(true);
    }
  }, [
    dataUpdatedAt,
    isJobDevicesRefetching,
    jobDevicesIsPending,
    jobMetadataIsPending,
    totalFinished,
    jobMetadata?.status,
  ]);

  const { targetedBlueprints = [], targetedDevices = [] } =
    React.useMemo(() => {
      const specificTargetedDeviceIds = jobMetadata?.devices ?? [];
      const specificTargetedBlueprintIds = jobMetadata?.blueprints ?? [];
      const blueprints = (blueprintsData as { id: string; name: string }[])
        ?.filter((b) => specificTargetedBlueprintIds.includes(b.id))
        .map((b) => ({ name: b.name, id: b.id }));

      const devices = jobDevicesData?.data
        ?.filter((d) => specificTargetedDeviceIds.includes(d.device_id))
        .map((d) => ({ name: d.device__name, id: d.device_id }));

      return {
        targetedBlueprints: blueprints,
        targetedDevices: devices,
      };
    }, [blueprintsData, jobDevicesData, jobMetadata]);

  return {
    agentFilters,
    jobDevicesData,
    jobMetadata,
    jobType,
    name,
    refetchJobDevices,
    showPending,
    status,
    totalFinished,
    totalTargeted,
    jobMetadataIsPending,
    dataUpdatedAt,
    isJobDevicesRefetching,
    targetedBlueprints,
    targetedDevices,
    description,
  };
};
