/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { billingTypes } from 'src/app/common/constants';
import { useAuth0 } from 'src/auth0';
import { webApiAudience } from 'src/config';

export const useIsFirstTrialUserSession = () => {
  const { getIdTokenClaims } = useAuth0();
  const { ownerId, currentUserId, billingType } = useSelector((state: any) => ({
    ownerId: state.account?.company?.owner,
    currentUserId: state.account?.user?.id,
    billingType: state.account?.company?.billing_type,
  }));

  const isOwner = !!ownerId && !!currentUserId && ownerId === currentUserId;
  const shouldFetchClaims =
    isOwner && billingType === billingTypes.AUTOMATED_TRIAL;

  const { data: isFirstTrialUserSession = false } = useQuery({
    queryKey: ['isFirstTrialUserSession', currentUserId],
    queryFn: async () => {
      const claims = await getIdTokenClaims();
      const userLoginCount = claims[`${webApiAudience}claims/user/loginsCount`];
      return userLoginCount === 1;
    },
    enabled: shouldFetchClaims,
    staleTime: Infinity, // Since this shouldn't change during a session
    retry: false, // Don't retry on failure since this is auth-related
  });

  return isFirstTrialUserSession;
};
