/* istanbul ignore file - legacy code, moved */
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink, Route, Switch, useRouteMatch } from 'react-router-dom';
import Icon from 'theme/components/atoms/Icon';

const Item = ({ icon, label, slug, url }) => (
  <li className={`menu-item menu-item-${slug}`}>
    <NavLink
      className="menu-link"
      activeClassName="active"
      to={slug === 'root' ? url : `${url}/${slug}`}
      exact={slug === 'root'}
    >
      {icon && <Icon icon={icon} />}
      {label && <span className="label">{label}</span>}
    </NavLink>
  </li>
);

export const IPAMenu = ({ id, className, menu, view, children }) => {
  const { url } = useRouteMatch();
  const loopMenu =
    menu &&
    menu.map(
      ({ icon, label, slug }) =>
        view &&
        Object.prototype.hasOwnProperty.call(view, slug) && (
          <Item
            key={`link-${slug}`}
            icon={icon}
            label={label}
            slug={slug}
            url={url}
          />
        ),
    );
  return (
    <ul id={id} className={`menu ${className || 'no-class'}`}>
      {children}
      {loopMenu}
    </ul>
  );
};

IPAMenu.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  menu: PropTypes.array,
  view: PropTypes.object,
  children: PropTypes.node,
};

IPAMenu.defaultProps = {
  id: undefined,
  className: undefined,
  menu: undefined,
  view: undefined,
  children: undefined,
};

export const IPAView = ({ menu, view }) => {
  const { path } = useRouteMatch();
  const loopView =
    menu &&
    menu.map(
      ({ slug }) =>
        view &&
        Object.prototype.hasOwnProperty.call(view, slug) && (
          <Route
            key={`view-${slug}`}
            path={slug === 'root' ? path : `${path}/${slug}`}
            exact={slug === 'root'}
            component={view[slug]}
          />
        ),
    );
  return <Switch>{loopView}</Switch>;
};

IPAView.propTypes = {
  menu: PropTypes.array.isRequired,
  view: PropTypes.object.isRequired,
};
